<form class="row object-module-html" [formGroup]="individualForm" id="individualForm">
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label" *ngIf="!(!individualForm.controls['object_name'].valid &&
              (individualForm.controls['object_name']?.touched || isSubmitClicked) && individualForm.invalid )">
          {{'objectModule.newObjecttype' | translate}}</label>
        <label class="touched-warning" *ngIf="!individualForm.controls['object_name'].valid
                     && (individualForm.controls['object_name']?.touched || isSubmitClicked)
                     && individualForm.invalid ">
          {{'objectModule.newObjecttype_error' | translate}}
        </label>
        <input type="text" maxlength="100" class="input" formControlName="object_name"
          placeholder="{{'objectModule.newObjecttype' | translate}}" title="{{individualForm.controls['object_name'].value}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.LaufendeNummer' | translate }}
        </label>
        <input
        *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
        type="number" maxlength="5" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',5)" numbersOnly (paste)="typeNumber($event, 'paste',5)"  class="input numberText" formControlName="sequence_number" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
      <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" numbersOnly type="text" maxlength="5" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)"   class="input numberText" formControlName="sequence_number" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'update-user.Vorname_label' | translate }}
        </label>
        <input type="text" maxlength="25" class="input" (keypress)="nameValidation($event,25)" (paste)="nameValidation($event,25)"
          formControlName="first_name" placeholder="{{'update-user.Vorname_label' | translate }}"
          title="{{individualForm.controls['first_name'].value}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'update-user.Nachname_label' | translate }}
        </label>
        <input type="text" maxlength="25" (keypress)="nameValidation($event,25)" (paste)="nameValidation($event,25)" class="input"
          formControlName="last_name" placeholder="{{'update-user.Nachname_label' | translate }}"
          title="{{individualForm.controls['last_name'].value}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6" id="datePicker1">
        <label *ngIf="!birthdayError" for="" class="label">
          {{'objectModule.Date_of_birth_label' | translate}}
        </label>
        <label *ngIf="birthdayError" for="" class="label touched-warning ellipsis">
          {{'objectModule.Date_of_birth_label' | translate}} : {{'objectModule.Invalid_date' | translate}}
        </label>
        <mat-form-field  class="date-picker" appearance="fill" id="datePicker">
        <span>
          <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
        </span>
        <input matInput (dateChange)="dateClicked()" [matDatepicker]="datepicker1" class="date-input" autocomplete="off" (keypress)="dateChange($event)"
         (paste)="dateChange($event)" (blur)="onDateBlur($event)"
          placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" formControlName="date_of_birth" [max]="today">
        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker1">
          <mat-icon matDatepickerToggleIcon class="datePickerArrow">
            {{'claims.keyboard-arrow-down_text' | translate}}
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #datepicker1></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label">
        {{'objectModule.Occupation_label' | translate }}
      </label>
      <mat-select class="dropdownStyle" (click)="focusDropdown()" (keyup.enter)="focusDropdown()" placeholder="{{'objectModule.select_lable' | translate}}"
        formControlName="occupation_id" (selectionChange)="occupationChanged($event)" #occuptaionDD (openedChange)="occupationControl.setValue('')">
      <input type="text" id="occupationSearch" class="searchYear" maxlength="20"autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
       [formControl]="occupationControl">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let ele of occupation" [value]="ele.id" matTooltip="{{ele.desc}}" >{{ele.desc}}</mat-option>
      </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.Occupational_group_label' | translate }}
        </label>
        <br>
        <mat-radio-group formControlName="professional_group">
            <mat-radio-button value="A">
              <span [className]="professionGroup == 'A'  ? 'selectedRadioButton' : '' ">
                {{'objectModule.GroupA' | translate}}
              </span>
            </mat-radio-button>
            <mat-radio-button value="B">
              <span [className]="professionGroup == 'B'  ? 'selectedRadioButton' : '' ">
                {{'objectModule.GroupB' | translate}}
              </span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.Gender_label' | translate }}
        </label>
        <br>
        <mat-radio-group formControlName="gender">
            <mat-radio-button value="m"><span>{{'objectModule.male_label' | translate}}</span></mat-radio-button>
            <mat-radio-button value="w"><span>{{'objectModule.female_label' | translate}}</span></mat-radio-button>
            <mat-radio-button value="d"><span>{{'objectModule.other_label' | translate}}</span></mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">
          {{'objectModule.health_questionnaire' | translate}}
      </label>
      <div style="display: flex;">
          <div class="documentUploadInput">
              <input class="input" readonly title="{{individualForm.controls['health_questionnaire'].value}}" formControlName="health_questionnaire"
                  placeholder="{{'objectModule.health_questionnaire' | translate}}">
          </div>

          <div class="documentUploadButton">
              <button class="docButton" type="button" (click)="uploadDoc()">
                  <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
              </button>
          </div>
      </div>
  </div>
  <app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray"
                        [showDocumentPopup]="showDocumentPopup" (hide)="hidePopup($event)"></app-upload-document>
</form>
