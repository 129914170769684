export const FILTER = {
    HEIGHT: '55px',
    FILTER_OFFSET: [25, 50, 75, 100, 125],
    DEFAULT_SELECTED: 25
};

export const NUMERIC = {
    CONTRACT_LOOKUP_ID: 105,
    MAX_HUNDRED: 100,
    MAX_TWENTY: 20,
    MAX_THIRTY: 30,
    MAX_FIFTEEN: 15,
    MAX_FIVE_THOUSAND: 5000,
    MAX_FIVE: 5
};

export const LENGTH = {
    FOUR: 4
};
export const KEYCODES = {
    INSERT: 45,
    ZERO: 48,
    DELETE: 46,
    NINE: 57
};

export const GENERIC_FORM_TYPES = {
    NUMERIC: 1,
    TEXT_FIELD: 3,
    RADIO_BUTTON: 5,
    DROPDOWN: 12,
    DATE_FIELD: 8,
    CHECKBOX: 9
};
