<div class="modal modelBackground" style="display: table;">
    <div class="modal-dialog spacing">
        <div class="modal-content">
            <div class="modal-header header-content">
                <div class="text-center" >
                    <img alt="" (click)="hideModal()" height="65px" src="../../../assets/images/Group 3235.svg">
                </div>
            </div>
            <div class="modal-body body-text">
                <span class="delete-heading">{{ warningMsg.header | translate}}</span>
                <br />
                <div class="delete-text">{{ warningMsg.body | translate}}</div>
            </div>
            <div class="modal-footer ml-auto mr-auto">
                <button (click)="hideModal()" 
                    class="custom-btn cancel-btn">
                    {{ warningMsg.ok | translate}}
                </button>
                <button class="custom-btn ml-2 save-btn" 
                    (click)="continueModal()">
                    {{ warningMsg.cancel | translate}}
                </button>
                <button class="custom-btn ml-2 save-btn" 
                    (click)="saveAndContinue()"
                    [disabled]="disableSave">
                    {{ warningMsg.save_and_continue | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
