<div>
  <span class="criteria-heading">{{'objectModule.photovoltaic-inside' | translate}}</span>
</div>
<form 
  *ngIf="selectLang$ | async as locale" 
  class="row object-module-html form-align" 
  [formGroup]="photovoltaicForm" 
  id="photovoltaicForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}" for="performance_of_the_plant" class="long-label">{{'objectModule.photovoltaic.performace_of_the_plant' |
            translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="6" class="input" formControlName="performace_of_the_plant" placeholder="{{'objectModule.photovoltaic.performace_of_the_plant' |
            translate}}" (keydown)="validationService.SequenceNumberValidation($event, 'keydown',6)" (paste)="validationService.SequenceNumberValidation($event, 'paste',6)" (blur)="validationService.sqmValidation(photovoltaicForm, $event)"
      (focus)="validationService.sqmValidation(photovoltaicForm, $event)" numbersOnly>
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*" (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
      (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)" class="input numberText" formControlName="performace_of_the_plant" placeholder="{{'objectModule.photovoltaic.performace_of_the_plant' |
            translate}}" (blur)="validationService.sqmValidation(photovoltaicForm, $event)" (focus)="validationService.sqmValidation(photovoltaicForm, $event)" numbersOnly>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}" for="type_of_firing" class="long-label">{{'objectModule.photovoltaic.owner' | translate}}</label>
    <input type="text" id="owner" class="input" maxlength="100" autocomplete="off" placeholder="{{'objectModule.photovoltaic.owner' | translate}}" matInput formControlName="owner" title="{{photovoltaicForm.controls['owner'].value}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">

    <div class="radio-group-align-long">
      <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'objectModule.photovoltaic.invertor_free_from_combustible_materials' | translate}}</label>
      <div class="radio-group-align-long">

        <mat-radio-group aria-label="" formControlName="invertor_free_from_combustible_materials">
          <mat-radio-button value="true">
            {{'objectModule.power_supply_object.yes' | translate}}
          </mat-radio-button>
          <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

  </div>
  <div class="col-md-3 col-sm-6 col-6">

    <div class="radio-group-align-long">
      <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'objectModule.photovoltaic.PV_system_included_in_electrorivision' | translate}}</label>

      <div class="radio-group-align-long">

        <mat-radio-group aria-label="" formControlName="PV_system_included_in_electrorivision">
          <mat-radio-button value="true">
            {{'objectModule.power_supply_object.yes' | translate}}
          </mat-radio-button>
          <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

  </div>

  <div [ngClass]="photovoltaicForm.value.fire_compartment_seperation_lifted ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="fire_compartment_seperation_lifted">
        {{'objectModule.photovoltaic.fire_compartment_seperation_lifted'
                | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align" *ngIf="photovoltaicForm.value.fire_compartment_seperation_lifted">
      <div class="col-md-3 col-sm-6 col-6">
        <label [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}" for="remark" class="long-label">{{'objectModule.photovoltaic.remark' |
                    translate}}</label>
        <input type="text" id="remark" class="input" maxlength="100" autocomplete="off" placeholder="{{'objectModule.photovoltaic.remark' | translate}}" matInput formControlName="remark" title="{{photovoltaicForm.controls['remark'].value}}">
      </div>
    </div>
  </div>
  <div [ngClass]="photovoltaicForm.value.photovoltaic_system_included_in_erevision ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="photovoltaic_system_included_in_erevision">
        {{'objectModule.photovoltaic.photovoltaic_system_included_in_erevision'
                | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align" *ngIf="photovoltaicForm.value.photovoltaic_system_included_in_erevision">
      <div class="col-md-3 col-sm-6 col-6">
        <label [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}" for="" class="long-label">
          {{'objectModule.photovoltaic.acceptance_protocol_upload' | translate}}
        </label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly title="{{photovoltaicForm.controls['AcceptanceProtocolPhotovoltaic'].value}}" formControlName="AcceptanceProtocolPhotovoltaic"
              placeholder="{{'objectModule.photovoltaic.acceptance_protocol_upload' | translate}}">
          </div>

          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadDoc()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup" (hide)="hidePopup($event)"></app-upload-document>
</form>
