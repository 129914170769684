<div *ngIf="!contactPersonAddEdit">
    <app-contact-person 
        (iscontactPersonAddEdit)="isContactPersonAddEdit($event)"
        (contactPersonId)="getContactPersonId($event)" 
        [message]="sendMessage">
    </app-contact-person>
</div>
<div *ngIf="contactPersonAddEdit">
    <app-add-contact-person
        (isContactPersonAddEdit)="isContactPersonAddEdit($event)"
        [contactPersonId]="contactPersonId"
        (message)="getMessage($event)"
        [addContactPerson]="addContactPersonEvent"
        (isContactPersonAdd)="isContactPersonAdd($event)">
    </app-add-contact-person>
</div>