import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-value-calculator',
  templateUrl: './value-calculator.component.html',
  styleUrls: ['./value-calculator.component.css']
})
export class ValueCalculatorComponent implements OnInit {
  maskStepperForm: FormGroup;
  @Input('maskGenFormData') maskGenFormData;
  @Input('isAutoPopulatedSelected') isAutoPopulatedSelected;

  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();

  constructor(private maskStepperBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maskGenFormData) {
      this.initializeForm();
    }
  }
  initializeForm() {
    this.assignAttributeValues(this.maskGenFormData);
  }
  assignAttributeValues(formData) {
    const optional_field = new FormControl({ value: formData ? formData.optional_field : '', disabled: false }, []);
    const type_of_coverage = new FormControl({ value: formData ? formData.type_of_coverage : '', disabled: false }, []);
    const max_prison_time = new FormControl({ value: formData ? formData.max_prison_time : '', disabled: false }, []);
    const deductible_type = new FormControl({ value: formData ? formData.deductible_type : '', disabled: false }, []);
    const deductiable_in = new FormControl({ value: formData ? formData.deductiable_in : '', disabled: false }, []);
    const deductible_min = new FormControl({ value: formData ? formData.deductible_min : '', disabled: false }, []);
    const deductible_max = new FormControl({ value: formData ? formData.deductible_max : '', disabled: false }, []);
    const days_for_Deductible = new FormControl({ value: formData ? formData.days_for_Deductible : '', disabled: false }, []);
    const maximisation = new FormControl({ value: formData ? formData.maximisation : '', disabled: false }, []);

    this.maskStepperForm = this.maskStepperBuilder.group({
      FormOptionalField: optional_field,
      FormTypeOfCoverage: type_of_coverage,
      FormMaxPrisonTime: max_prison_time,
      FormDeductibleType: deductible_type,
      FormDeductiableIn: deductiable_in,
      FormDeductibleMin: deductible_min,
      FormDeductibleMax: deductible_max,
      FormDaysForDeductible: days_for_Deductible,
      FormMaximisation: maximisation
    });

    this.maskStepperForm.valueChanges.subscribe((value) => {
      this.emitFormValue.emit('hii');
    });
  }


  openValueCal(){
    this.emitFormValue.emit('hii');
  }
}
