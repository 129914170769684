export const COLUMN_TYPES = {
    STRING : 1,
    ACTION: 2,
    TWO_ROWS: 3,
    DOCUMENT_CHECKBOX: 4,
    COMPONENT: 5,
};

export const TOOLTIP_PLACEMENT = {
    TOP: 'top',
    BOTTOM: 'bottom'
};
