import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Attendee } from '@shared/models/attendee.model';
import { Api_URL } from '@shared/constants/url.constants';
import { Api } from '@shared/constants/api.constants';

@Injectable({ providedIn: 'root' })
export class AttendeeApiService {
  constructor(private _http: HttpClient) {}

  getAttendees(): Observable<Attendee[]> {
    return this._http.get<Attendee[]>(`${Api_URL.IC_API_URL}${Api.ATTENDEES}`);
  }
}
