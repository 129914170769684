import { Pipe, PipeTransform } from '@angular/core';
import { FilterSelectOption } from '../components/table/filter-section/filter-section.component';

@Pipe({
  name: 'filterOptions'
})
export class FilterOptionsPipe implements PipeTransform {
  transform(items: FilterSelectOption[], searchText: string): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }

    return items.filter(it => it.label.toLowerCase().includes(searchText.toLowerCase()));
  }
}
