import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Avatar,
  AvatarSizeEnum,
  measureUnit,
} from '@shared/models/avatar.model';

@Component({
  selector: 'app-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.scss'],
})
export class AvatarGroupComponent implements OnChanges {
  @Input() avatars: Avatar[] = [];
  @Input() size: keyof typeof AvatarSizeEnum = 'xxs';
  @Input() maxAvatarsCount = 4;

  @Output() actionClicked: EventEmitter<void> = new EventEmitter();

  avatarSizes = AvatarSizeEnum;
  fontSize = 14;
  gap = measureUnit - 1;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.avatars.previousValue) {
      return;
    }

    const previousLength = changes.avatars.previousValue?.length;
    const currentLength = changes.avatars.currentValue?.length;

    const previousSize = changes.size.previousValue;
    const currentSize = changes.size.currentValue;

    if (previousLength !== currentLength || previousSize != currentSize) {
      this.fontSize =
        this.avatarSizes[this.size] / 2 +
        (this.avatars.length > this.maxAvatarsCount ? -2 : 2);

      const maxCountForFontSize = 99;
      const fontCoefficient = 0.8;

      if (this.avatars.length > maxCountForFontSize + this.maxAvatarsCount) {
        this.fontSize = this.fontSize * fontCoefficient;
      }
    }
  }

  clickToAction(): void {
    this.actionClicked.emit();
  }
}
