export const INTERMEDIARY_COLUMN_NAMES = {
  INTERMEDIARY_ID: 'intermediary_id',
  INTERMEDIARY_NAME: 'intermediary_name',
  INTERMEDIARY_TYPE: 'intermediary_type',
  STATUS: 'status',
  DIVISION: 'division_name',
  CREATED_BY: 'created_by',
  UPDATED_BY: 'updated_by',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
  ACTION: 'action',
};

export const INTERMEDIARY_COLUMN_HEADINGS = {
  TITLE: 'intermediary_management.title',
  INTERMEDIARY_ID: 'intermediary_management.intermediary_id',
  INTERMEDIARY_NAME: 'intermediary_management.intermediary_name',
  INTERMEDIARY_TYPE: 'intermediary_management.intermediary_type',
  STATUS: 'intermediary_management.status',
  DIVISION: 'intermediary_management.division_name',
  CREATED_BY: 'intermediary_management.created_by',
  UPDATED_BY: 'intermediary_management.updated_by',
  ACTION: 'intermediary_management.action',
  CREATED_AT: 'intermediary_management.created_at',
  UPDATED_AT: 'intermediary_management.updated_at',
  ADD_NEW_INTERMEDIARY: 'intermediary_management.add_new_intermediary',
  CANNOT_DELETE_HEADING: 'intermediary_management.cannot_delete_heading',
  CANNOT_DELETE_SUBHEADING: 'intermediary_management.cannot_delete_subheading',
};

export const INT_FORM_CONTROL = {
  id: 'intermediary_id',
  name: 'intermediary_name',
  status: 'status',
  division: 'division',
  filter_text: 'intermediary_management.filter_text',
  filter_by_intermediary_id:
    'intermediary_management.filter_by_intermediary_id',
  filter_by_intermediary_name:
    'intermediary_management.filter_by_intermediary_name',
  filter_by_status: 'intermediary_management.filter_by_status',
  filter_by_division: 'intermediary_management.filter_by_division',
};
export const INTERMEDIARY_COLUMNS = [
  INTERMEDIARY_COLUMN_NAMES.INTERMEDIARY_ID,
  INTERMEDIARY_COLUMN_NAMES.INTERMEDIARY_NAME,
  INTERMEDIARY_COLUMN_NAMES.INTERMEDIARY_TYPE,
  INTERMEDIARY_COLUMN_NAMES.STATUS,
  INTERMEDIARY_COLUMN_NAMES.DIVISION,
  INTERMEDIARY_COLUMN_NAMES.CREATED_AT,
  INTERMEDIARY_COLUMN_NAMES.UPDATED_AT,
  INTERMEDIARY_COLUMN_NAMES.ACTION,
];

export const METHOD_CALL = {
  EDIT_INTERMEDIARY: 'editIntermediary',
  DELETE_INTERMEDIARY: 'deleteintermediary',
};

export const API_ENDPOINT_URL = {
  GET_INTERMEDIARY_TABLE_DATA: '/api/v1/intermediary/overview',
  UPDATE_ADD_INTERMEDIARY: '/api/v1/intermediary',
  GET_INTERMEDIARY_DATA_BY_ID: '/api/v1/intermediary/',
  GET_INTERMEDIARY_FILTER_LIST: '/api/v1/intermediary/filter_data',
  GET_STATUS_TYPE:
    '/api/v1/product/lookup_data_2?lookup_type_name=insurer_status',
  GET_DIVISION: '/api/v1/get_divisions?all_required=false',
  GET_MAIN_INTERMEDIARY: '/api/v1/intermediary/main_intermediary',
  GET_INTERMEDIARY_ACTION_BAR: '/api/v1/intermediary/action_bar',
  GET_INTERMEDIARY_TYPE:
    '/api/v1/product/lookup_data_2?lookup_type_name=intermediary_type',
  GET_IC_MAIN_CONTACT_LIST: '/api/v1/intermediary/main_contacts',
  GET_DIVISION_BY_ROLE: '/api/v1/get_divisions/',
};

export const INTERMEDIARY_HEADINGS = {
  INTERMEDIARY_MAPPING_PAGE_HEADING:
    'intermediary_management.intermediary_mapping_headingg',
  NUMBER_OF_INSURER_MAPPING:
    'intermediary_management.number_of_intermediary_mapping',
  GENERAL_INTERMEDIARY_DETAILS:
    'intermediary_management.general_intermediary_details',
  PAGE_BREADCRUMB_TEXT: 'intermediary_management.title',
  SOURCE_FROM: 'intermediary',
  COMMISION: 'intermediary_management.commission',
  COMMISSION_TYPE: 'intermediary_management.commissionType',
};
export const INTERMEDIARY_TABS = {
  GENERAL_INTERMEDIARY_DETAILS: 'General Intermediary Details',
  CONTACT_PERSON: 'Contact Person',
  COMMUNICATION: 'Communication',
  BANK_ACCOUNT_DETAILS: 'Bank Account Details',
  COMMISSION: 'Commission',
  DOCUMENT_MANAGEMENT: 'Document management',
  APPOINTMENTS: 'Appointments',
};

export const IMAGE_CONST = {
  GENERAL_INTERMEDIARY:
    'assets/images/intermediary-management/General_Intermediary_Details.svg',
  CONTACT_PERSON:
    'assets/images/intermediary-management/Contact_Person_Insurer.svg',
  COMMUNICATION:
    'assets/images/intermediary-management/Commuication_Insurer.svg',
  BANK_ACCOUNT_DETAILS:
    'assets/images/intermediary-management/Bank_Account_Details.svg',
  COMMISSION: 'assets/images/intermediary-management/Commission.svg',
};

export const GF_FORM_FIELDS_CONST = {
  STATUS: 'status',
  INTERMEDIARY_ID: 'intermediary_id',
  INTERMEDIARY_TYPE: 'intermediary_type',
  INTERMEDIARY_NAME: 'intermediary_name',
  ADDRESS: 'address',
  STREET: 'street',
  HOUSE_NUMBER: 'house_number',
  POST_CODE: 'zipcode',
  CITY: 'city',
  DIVISION_ID: 'division_id',
  DIVISION: 'division_name',
  MAIN_INTERMEDIARY: 'main_parent_id',
  REGISTRATION_ID: 'registration_id',
  VALID_FROM: 'valid_from',
  VALID_TO: 'valid_to',
  IC_MAIN_CONTACT: 'main_contact_id',
  DESCRIPTION: 'description',
};
