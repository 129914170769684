<app-header (emitUserData)="userDataReceived($event)" (emitCustomerData)="customerDataReceived($event)"></app-header>
<div class="container-ic addObject">
  <div style="display: flex;">
    <app-breadcrumbs style="width: 100%" [isCustomerUser]="isCustomerUser" [isCustomerSelected]="isCustomerSelected"
      [pageBreadcrumbText]="pageBreadcrumbText" [pageBreadcrumbSubText]="pageBreadcrumbSubText"
      [divisionName]="divisionName" [customerName]="customerName$ | async"></app-breadcrumbs>
    <div style="display: flex">
      <button class="save-button" (click)="saveObject()">
        <img alt="" height="29px" src="assets/images/save-button.svg" />
      </button>
      <button class="save-button" (click)="closeObject()">
        <img alt="" class="close-button" src="assets/images/closeIcon.png" />
      </button>
    </div>
  </div>

  <div class="updated_info" *ngIf="object_data">
    <span>
      {{'objectModule.last_Updated_on' | translate }} : {{object_data.last_updated_on | dateFormat:'dd.MM.yyyy HH:mm'}}
      <span *ngIf="!object_data.last_updated_on">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> ,
    </span>
    &nbsp;<span>{{'objectModule.last_Updated_by' | translate }} : {{object_data.updated_by}}</span>
    <br />
  </div>

  <mat-accordion>
    <mat-expansion-panel class="mt-2" (opened)="customerSectionToggle = true" (closed)="customerSectionToggle = false"
      [expanded]="customerSectionToggle" hideToggle>
      <mat-expansion-panel-header class="px-3 border-bottom">
        <mat-panel-title class="expansion-panel-top-margin">
          <div class="flex-center g-7">
            <button class="action-btn">
              <mat-icon *ngIf="customerSectionToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="!customerSectionToggle" class="arrowButtons va-bottom">keyboard_arrow_right</mat-icon>
            </button>
            <span class="expansion-panel-heading-text">
              {{'objectModule.form1_Header' | translate}}
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <mat-checkbox [(ngModel)]="isCustomerChange">
          <span *ngIf="!object_data">{{'objectModule.changeCompanyQAdd' | translate}}</span>
          <span *ngIf="object_data">{{'objectModule.changeCompanyQEdit' | translate}}</span>
        </mat-checkbox>
      </div>
      <div class="row formContainer" [formGroup]="newObjectForm" autocomplete="off">
        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="label required">{{'user-data.customer_name_small' | translate}}</label>
          <mat-select (selectionChange)="getSelectedCustomer($event)" class="dropdownStyle  inputy switcher "
            id="list" placeholder="{{'user-data.customer_name_small' | translate}}" formControlName="customer"
            [disabled]="!isCustomerChange">
            <mat-option *ngFor="let sub of subsidiaries" matTooltip="{{sub.customer_name}} - {{sub.type}}"
              [value]="sub.customer_name">
              <span *ngIf="sub.customer_name " class="noSafariTooltip">{{sub.customer_name}} -
                {{sub.type | uppercase}}</span>
              <span *ngIf="!sub.customer_name " class="noSafariTooltip">{{sub.customer_name}} -
                {{sub.type |
                uppercase}}</span>
            </mat-option>
          </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="label required" *ngIf="!(!newObjectForm.controls['objectType'].valid &&
                      (newObjectForm.controls['objectType']?.touched || isSubmitClicked) && newObjectForm.invalid)">
            {{'objectModule.new_objectType' | translate}}</label>
          <label class="touched-warning" *ngIf="!newObjectForm.controls['objectType'].valid
                            && (newObjectForm.controls['objectType']?.touched || isSubmitClicked)
                            && newObjectForm.invalid">
            {{'objectModule.newObjecttype_error' | translate}}
          </label>
          <mat-select *ngIf="!object_data" class="dropdownStyle  inputy switcher"
            placeholder="{{'objectModule.object_placeholder' | translate}}" formControlName="objectType"
            (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')"
            (selectionChange)="search.value = '';" (openedChange)="search.value = ''">
            <input [hidden]="object_type_data.length <= 6" type="text" maxlength="20" preventSpace 
              class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #search>
            <mat-option *ngFor="let ele of validationService._filter(object_type_data, '', search.value)"
              (onSelectionChange)="addressValidation(ele,$event.isUserInput);" [value]="ele"
              [ngClass]="{'active-option': ele === newObjectForm.value.objectType && !newObjectForm.value.objectType}">
              {{ele}}
            </mat-option>
          </mat-select>
          <input type="text" *ngIf="object_data" class="input" formControlName="objectType"
            [value]="object_data.object_type" disabled>
        </div>
        <ng-container *ngIf="newObjectForm.value.objectType !== 'Standort'">
          <div class="col-md-3 col-sm-6 col-6" style="position:relative;">
            <label for="" class="label" [ngClass]="isAddressValidation ?'required': ''" *ngIf="!(!newObjectForm.controls['Address'].valid &&
              (newObjectForm.controls['Address']?.touched || isSubmitClicked) && newObjectForm.invalid)">
              {{'objectModule.new_selectAddress' | translate}}</label>
            <label class="touched-warning" *ngIf="!newObjectForm.controls['Address'].valid
              && (newObjectForm.controls['Address']?.touched || isSubmitClicked)
              && newObjectForm.invalid">
              {{'objectModule.new_selectAddress1_error' | translate}}
            </label>
            <mat-select [disabled]="newObjectForm.value.objectType === 'Standort'"
              class="dropdownStyle  inputy switcher" placeholder="{{'objectModule.new_selectAddress' | translate}}"
              formControlName="Address" [title]="newObjectForm.controls['Address'].value">
              <mat-option style="opacity:0.5" [value]="'objectModule.select_lable' | translate"
                (click)="selectedLocation('')">
                {{'objectModule.select_lable' | translate}}
              </mat-option>
              <mat-option *ngFor="let location of filterlocation;index as i" (click)="selectedLocation(location)"
                [value]="location.address_line" [title]="location.address_line">{{location.address_line}}
              </mat-option>
            </mat-select>
          </div>
          <div class="col-md-3 col-sm-6 col-6">
            <zurs-common-component [address]="getSelectedLocAddress()" (zursAddress)="setZursAddress($event)">
            </zurs-common-component>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion *ngIf="newObjectForm.value.objectType">
    <mat-expansion-panel
      class="my-3"
      (opened)="detailsSectionToggle = true" (closed)="detailsSectionToggle = false"
      [expanded]="detailsSectionToggle" hideToggle>
      <mat-expansion-panel-header class="px-3 border-bottom">
        <mat-panel-title class="expansion-panel-top-margin">
        <div class="flex-center g-7">
          <button class="action-btn">
            <mat-icon *ngIf="detailsSectionToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!detailsSectionToggle" class="arrowButtons va-bottom">keyboard_arrow_right</mat-icon>
          </button>
          <span class="expansion-panel-heading-text">
              {{newObjectForm.value.objectType}} {{'objectModule.new_details' | translate}}
          </span>
        </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row m-0">
        <app-building-form class="formContainer2" *ngIf="newObjectForm.value.objectType === 'Gebäude/Grundstück'"
          (emitFormValue)="formValues($event)" (emitBuldingFormValue)="emitBuldingFormValue($event)"
          [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType" [isSubmitClicked]="isSubmitClicked"
          [newCustomerFormReq]="newCustomerFormReq" [selectedLiztu]="selected_customer_liztu"
          [buildingParentListener]="buildingValueChanges" [object_data]="object_data" style="width: 100%;">
        </app-building-form>
        <app-electronics-form class="formContainer" *ngIf="newObjectForm.value.objectType === 'Elektronik'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" style="width: 100%;"
          [selectedLiztu]="selected_customer_liztu"></app-electronics-form>
        <app-operating-equipment class="formContainer"
          *ngIf="newObjectForm.value.objectType === 'Betriebseinrichtung'" (emitFormValue)="formValues($event)"
          [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType" [isSubmitClicked]="isSubmitClicked"
          [object_data]="object_data" style="width: 100%;"></app-operating-equipment>
        <app-machine-plants-form class="formContainer"
          *ngIf="newObjectForm.value.objectType === 'Maschinen/Anlagen (stationär)'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" class="formContainer" style="width: 100%;"
          [selectedLiztu]="selected_customer_liztu"></app-machine-plants-form>
        <app-machine-plants-form-mobi class="formContainer"
          *ngIf="newObjectForm.value.objectType === 'Maschinen/Anlagen (mobil)'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" class="formContainer" style="width: 100%;"
          [selectedLiztu]="selected_customer_liztu"></app-machine-plants-form-mobi>
        <app-vehicle class="formContainer" *ngIf="newObjectForm.value.objectType === 'Fahrzeug'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" class="formContainer"
          [selectedLiztu]="selected_customer_liztu"></app-vehicle>
        <app-individual class="formContainer" *ngIf="newObjectForm.value.objectType === 'Person (Einzel)'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" class="formContainer" style="width: 100%;"
          [selectedLiztu]="selected_customer_liztu"></app-individual>
        <app-group-of-people class="formContainer" *ngIf="newObjectForm.value.objectType === 'Person (Gruppe)'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" class="formContainer" style="width: 100%;"
          [selectedLiztu]="selected_customer_liztu"></app-group-of-people>
        <app-goods-inventories class="formContainer" *ngIf="newObjectForm.value.objectType === 'Waren/Vorräte'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [object_data]="object_data" style="width: 100%;" [isSubmitClicked]="isSubmitClicked">
        </app-goods-inventories>
        <app-photovoltaic class="formContainer" *ngIf="newObjectForm.value.objectType === 'Photovoltaik'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" style="width: 100%;"
          [selectedLiztu]="selected_customer_liztu" [selectedCompany]="selectedCustomer"></app-photovoltaic>
        <app-model-sample-tools class="formContainer"
          *ngIf="newObjectForm.value.objectType === 'Modelle/Muster/Werkzeuge'" (emitFormValue)="formValues($event)"
          [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType" [isSubmitClicked]="isSubmitClicked"
          [object_data]="object_data" style="width: 100%;"></app-model-sample-tools>
        <app-miscellaneous class="formContainer" *ngIf="newObjectForm.value.objectType === 'Sonstiges'"
          (emitFormValue)="formValues($event)" [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType"
          [isSubmitClicked]="isSubmitClicked" [object_data]="object_data" style="width: 100%;"></app-miscellaneous>
        <app-location class="formContainer2" *ngIf="newObjectForm.value.objectType === 'Standort'"
          (emitFormValue)="formValues($event)" (emitlocationFormValue)="emitBuldingFormValue($event)"
          [resetForm]="resetForm" [objectType]="newObjectForm.value.objectType" [isSubmitClicked]="isSubmitClicked"
          [newCustomerFormReq]="newCustomerFormReq" [locationParentListener]="locationValueChanges"
          [object_data]="object_data" style="width: 100%;"></app-location>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="horizonttally-center py-2">
    <button class="custom-btn save-btn" (click)="saveObject()">
      {{'objectModule.new_save' | translate}}
    </button>
  </div>
</div>

<div class="modal modelBackground" [class.showModel]="openaddressModal">
  <div class="modal-dialog" style="position:relative;min-width:700px;margin-top:50px">
    <div class="modal-content" style="height: calc(100% - 40px);">
      <div class="modal-header header-style">
        <span class="header-title">{{'objectModule.new_AddNewAddress' | translate}}</span>
        <button type="button" class="close" data-dismiss="modal" (click)="hideaddressModal()">
          <img alt="" src="../../../assets/images/Group 3114.svg">
        </button>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;text-align: center;padding:0">
        <app-address-popup *ngIf="selectedCustomer" [customer]="selectedCustomer.customer_name"
          [customerID]="selectedCustomer.customer_id" (closePopup)="hideaddressModal($event)"
          [openaddressModal]="openaddressModal"></app-address-popup>
      </div>
    </div>
  </div>
</div>

<div class="modal modelBackground" [class.showModel]="showSuccessModel">
  <div class="modal-dialog" style="margin-top:13%;width:32%">
    <div class="modal-content" style="border-radius: 8px;    box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);    border: solid 1px #bbb;
         background-color: #fff;padding: 40px 0;    width: 400px;">
      <!-- Modal Header -->
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">

        <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
          <mat-icon>
            <img alt="" height="65px" (click)="hideModal()" src="../../../assets/images/Check_icon.svg">
          </mat-icon>
        </div>
        <div>
          <button style="position: absolute;
          top: 11px;
          right: 11px;
          outline: none;
          border: none;
          height: 32px;
          width: 36px;">
            <mat-icon style="
            font-size: 16px;
            height: 0px;
            line-height: 1.2;
            background-color: initial;
          " (click)="hideModal()">close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
        <span style="font-size: 18px;color: #272727;" *ngIf="!object_data">
          {{'objectModule.new_success_text' | translate}}
        </span>
        <span style="font-size: 18px;color: #272727;" *ngIf="object_data">
          {{'objectModule.new_success_text_update' | translate}}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="modal modelBackground" [class.showModel]="showFailureModel">
  <div class="modal-dialog" style="margin-top:13%;width:32%">
    <div class="modal-content" style="border-radius: 8px;    box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);    border: solid 1px #bbb;
         background-color: #fff;padding: 40px 0;    width: 400px;">
      <!-- Modal Header -->
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
        <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
          <mat-icon>
            <img alt="" height="65px" (click)="hideFailureModel()" src="../../../assets/images/Group 3235.svg">
          </mat-icon>
        </div>
        <div>
          <button style="position: absolute;
          top: 11px;
          right: 11px;
          outline: none;
          border: none;
          height: 32px;
          width: 36px;">
            <mat-icon style="
            font-size: 16px;
            height: 0px;
            line-height: 1.2;
            background-color: initial;
          " (click)="hideFailureModel()">close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
        <span style="font-size: 18px;color: #272727;">
          {{responseText}}
        </span>
      </div>
    </div>
  </div>
</div>

<div id="snackbar" [class.show]="showSnackBar" *ngIf="!failureText"> {{'objectModule.new_address_save' | translate}}
</div>
<div id="snackbar" [class.show]="showSnackBar" *ngIf="failureText"> {{failureText}}</div>
<close-alert-dialog *ngIf="showClosePopUp" (handlePopup)="handlePopup($event)"></close-alert-dialog>