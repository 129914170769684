import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-recycling',
  templateUrl: './recycling.component.html',
  styleUrls: ['./recycling.component.css'],
})
export class RecyclingComponent implements OnInit {
  recyclingForm: FormGroup;
  goodsList = [];
  procedureList = [];
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService,
    public validationService: ValidationService
  ) {
    this.serviceCall
      .getLookupData(23)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.goodsList = data.data;
      });
    this.serviceCall
      .getLookupData(22)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.procedureList = data.data;
      });
  }

  ngOnInit(): void {
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  initForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[BuildingConstants.RECYCLING] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.RECYCLING]
          .saved_criteria
      );
    this.assignAttributeValues(formData);
    this.isFormValueUpdated = false;
    this.recyclingForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.recyclingForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  assignAttributeValues(formData: any) {
    const type_of_recycling = new FormControl(
      {
        value: formData ? formData.recycling.type_of_recycling : null,
        disabled: false,
      },
      []
    );
    const goods = new FormControl(
      { value: formData ? formData.recycling.goods : null, disabled: false },
      []
    );
    const procedure = new FormControl(
      {
        value: formData ? formData.recycling.procedure : null,
        disabled: false,
      },
      []
    );
    const is_fireproof_partition = new FormControl(
      {
        value: formData ? formData.recycling.is_fireproof_partition : null,
        disabled: false,
      },
      []
    );
    const is_magnetic_separator = new FormControl(
      {
        value: formData ? formData.recycling.is_magnetic_separator : null,
        disabled: false,
      },
      []
    );
    const is_pneumatic_conveyor_line = new FormControl(
      {
        value: formData ? formData.recycling.is_pneumatic_conveyor_line : null,
        disabled: false,
      },
      []
    );
    const annotations = new FormControl(
      {
        value: formData ? formData.recycling.annotations : null,
        disabled: false,
      },
      []
    );
    const is_annotation = new FormControl(
      {
        value: formData ? formData.recycling.is_annotation : null,
        disabled: false,
      },
      []
    );
    this.recyclingForm = this.fb.group({
      type_of_recycling,
      goods,
      procedure,
      is_fireproof_partition,
      is_magnetic_separator,
      is_pneumatic_conveyor_line,
      annotations,
      is_annotation,
    });
  }
  buildFormObject() {
    const formValuesToPersist = {
      recycling: this.recyclingForm.value,
    };
    this.request = {
      criteria_id: BuildingConstants.RECYCLING,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.recyclingForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }
  scrollValue(key) {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById(key).focus();
  }
}
