<div *ngIf="!isIvm && !isResetInvite" class="modal modelBackground" [style.display]="'table'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content" style="border-radius: 8px;
  box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
  border: solid 1px #bbb;
  background-color: #fff;width:408px">
            <!-- Modal Header -->
            <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                    <mat-icon (click)="hideModal()">
                        <img alt="" height="65px" src="../../../../assets/images/Group 3235.svg" >
                    </mat-icon>
                </div>
            </div>
            <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
              <span style="font-weight: 600;font-size: 21px;">{{'objectModule.Delete_popup_heading' | translate}}</span>
              <br/><br/>
              <span style="
     font-size: 18px;
     font-weight: 600;
     color: #272727;"> <span style="font-weight: 600;font-size: 21px;"> {{'user-data.Attention_label' | translate}}
          </span> {{'user-data.Möchten_Sie' | translate}} </span>
            </div>
            <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                <button type="button" style="font-size: 15px;color: #272727;border-radius: 2px;padding: 0px 22px 0px 22px;
      border: solid 1px #272727;font-weight: 600;" mat-button (click)="hideModal()">
        {{'contact-manager.NEIN_text' | translate}}</button>
                <button class="ml-2" type="button" style="font-size: 15px;color: #272727;background: #ff9000;border-radius: 2px;padding: 0px 22px 0px 22px;font-weight: 600;border: solid 1px #ff9000;" (click)="deleteDocument()" mat-button> {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isIvm && !isResetInvite" class="modal modelBackground" [style.display]="'table'">
    <div class="modal-dialog" style="top:220px">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header" style="border-bottom: none; justify-content: end;padding: 8px;">
            <button (click)="hideModal()" class="action-btn">
              <img alt="" src="assets/images/close icon .svg">
            </button>
          </div>
          <div class="modal-body" style="font-size:12px; overflow: auto;padding: 0 1rem 2rem 1rem;">
            <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
              <mat-icon (click)="hideModal()">
                <img alt="" height="75px" src="../../../assets/images/Icon_for_delete.svg" >
              </mat-icon>
            </div>
            <br>
            <div style="
         font-size: 18px;
         font-weight: 600;
         color: #272727;
         margin-top: 2rem;text-align: center;">
              <div style="margin-bottom: 6px;"> {{'user-data.sorry' | translate}} </div> {{'user-data.deleteIvmPopupText' |
              translate}}
            </div>
          </div>
    
        </div>
      </div>
</div>
<div *ngIf="isResetInvite && !isIvm" class="modal modelBackground" [style.display]="'table'">
    <div class="modal-dialog" style="top:220px">
        <div class="modal-content" style="border-radius: 8px;
        box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
        border: solid 1px #bbb;
        background-color: #fff;">
          <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
            <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
              <mat-icon (click)="hideModal()">
                <img alt="" height="65px" src="../../../assets/images/Path 91988 (1).svg" >
              </mat-icon>
            </div>
          </div>
          <div class="modal-body" style="font-size:12px; overflow: auto;text-align: center;">
            <span style="margin-top: 1rem;
          font-size: 24px;
          font-weight: 600;
          color: #272727;"> {{'user-data.Sind_Sie' | translate}} </span><br>
            <span style="
         font-size: 18px;
         font-weight: 600;
         color: #272727;"> {{'user-data.dass_Sie' | translate}} </span>
          </div>
          <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
    
            <button type="button" style="color: #272727;border-radius: 4px;width: 94.1px;
            height: 36.9px;
            border: solid 1px #272727;font-weight: 600;" mat-button (click)="hideModal()"> {{'user-data.Nein' | translate}}
            </button>
            <button class="ml-2" type="button" style="color: #272727;background: #ff9000;width: 124.1px;
            height: 36.9px;font-weight: 600;" mat-button (click)="invitationRevert()">
              {{'user-data.EINLADUNGZURÜCKSETZEN_tooltip' | translate}} </button>
    
          </div>
        </div>
      </div>
</div>