import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { takeUntil } from 'rxjs/operators';
import { SparteShareService } from '@core/services/sparte-share.service';
export interface sparteName {
  name: string;
}
export interface SparteTableElement {
  id: string;
  groupName: string;
  is_create: any;
  is_delete: any;
  is_read: any;
  is_removable: any;
  division_id: any;
  sparte: any;
  is_update: any;
}
export interface Spartearray {
  action: string;
  fixer_code: any;
  sparte_id: any;
  sparte_name: string;
}
export interface spartelist {
  sparte_name: any;
  sparte_id: any;
  fixer_code: any;
  is_add_button_show: boolean;
}
@Component({
  selector: 'app-new-sparte-group',
  templateUrl: './new-sparte-group.component.html',
  styleUrls: ['./new-sparte-group.component.css'],
})
export class NewSparteGroupComponent extends BaseComponent implements OnInit {
  sparte_table_data = [];
  new_sparte_table_data = [];
  groupName: any;
  sparte_array = [];
  sparte_list = [];
  message: boolean;
  sparte: any;
  showModal: boolean;
  checkSparte: any;
  sparte_group_message = false;
  button_disable: boolean;
  groupNameArray = [];
  sparteControl = new FormControl();
  options = [];
  groupNameError: boolean;
  spartes = [];
  sparte_group_duplicate_message = false;
  isSparteChanged = false;

  constructor(
    public dialogRef: MatDialogRef<NewSparteGroupComponent>,
    private serviceCall: ApiCallsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public supported_languages: any,
    private readonly _userService: UserService,
    private readonly _sparteShareService: SparteShareService
  ) {
    super();
    if (data) {
      this._sparteShareService.sparte = data.sparte;
      this.sparte = data.sparte;
      this.sparte_table_data = [];
      this.sparte_table_data = [...data.sparte];
      this.groupName = data.sparte_group_name;
    }
    // below code will execute when sparte search value getting change
    this.sparteControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(selectedValue => {
        this._filter(selectedValue);
      });
  }

  ngOnInit(): void {
    this.loadSparte();
  }
  // funtion for filter the sparte value according search result
  private _filter(value: string) {
    if (value) {
      const filterValue = value.toLowerCase();
      this.sparte_list =
        this.sparte_list.filter(
          option => option.sparte_name.toLowerCase().indexOf(filterValue) === 0
        ) || [];
    }
  }
  // load sparte array
  loadSparte() {
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.serviceCall
      .getSparte(
        this._userService.getDivisionId(),
        null,
        this._userService.getUserRole()
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        for (let i = 0; i < data.sparte.length; i++) {
          // condition for is_sparte_group false array
          this.spartes.push(data.sparte[i].description);
          if (!data.sparte[i].is_sparte_group) {
            this.sparte_array.push(data.sparte[i]);
            const sparteList: spartelist = {
              sparte_name: data.sparte[i].description,
              sparte_id: data.sparte[i].sparte_id,
              fixer_code: data.sparte[i].fixer_code,
              is_add_button_show:
                this.data.fromWhich == 'edit' &&
                this.sparte_table_data.find(
                  sparte => sparte.sparte_name === data.sparte[i].description
                ) !== undefined
                  ? false
                  : true,
            };
            this.sparte_list.push(sparteList);
          }
        }
      });
  }
  // on click cross button
  onNoClick() {
    if (
      this.data.sparte_group_name !== this.groupName ||
      this.isSparteChanged
    ) {
      this.showModal = true;
    } else {
      this._sparteShareService.sparte = null;
      this.dialogRef.close();
    }
  }
  // for compare 2 array
  arrayEquals(a, b) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }
  hideModal() {
    this.showModal = false;
  }
  discard() {
    this.showModal = false;
    this._sparteShareService.sparte = null;
    this.dialogRef.close();
  }
  addSparte(id) {
    this.message = false;
    this.button_disable = false;
    for (let i = 0; i < this.sparte_array.length; i++) {
      if (this.sparte_array[i].sparte_id == id) {
        const new_sparte_row: Spartearray = {
          action: 'add',
          fixer_code: this.sparte_array[i].fixer_code,
          sparte_id: this.sparte_array[i].sparte_id,
          sparte_name: this.sparte_array[i].description,
        };
        for (let k = 0; k < this.new_sparte_table_data.length; k++) {
          if (
            this.new_sparte_table_data[k].sparte_id == new_sparte_row.sparte_id
          ) {
            this.new_sparte_table_data.splice(k, 1);
          }
        }
        this.new_sparte_table_data.push(new_sparte_row);
        this.sparte_table_data.push(new_sparte_row);
        for (let j = 0; j < this.sparte_list.length; j++) {
          if (this.sparte_list[j].sparte_id == id) {
            this.sparte_list[j].is_add_button_show = false;
          }
        }
      }
    }

    this.isSparteChanged = true;
  }
  removeSparte(id) {
    this.button_disable = false;
    for (let i = 0; i < this.sparte_table_data.length; i++) {
      if (this.sparte_table_data[i].sparte_id === id) {
        const new_sparte_row: Spartearray = {
          action: 'remove',
          fixer_code: this.sparte_table_data[i].fixer_code,
          sparte_id: this.sparte_table_data[i].sparte_id,
          sparte_name: this.sparte_table_data[i].sparte_name,
        };
        this.sparte_table_data.splice(i, 1);
        for (let k = 0; k < this.new_sparte_table_data.length; k++) {
          if (
            this.new_sparte_table_data[k].sparte_id == new_sparte_row.sparte_id
          ) {
            this.new_sparte_table_data.splice(k, 1);
          }
        }
        this.new_sparte_table_data.push(new_sparte_row);
        for (let j = 0; j < this.sparte_list.length; j++) {
          if (this.sparte_list[j].sparte_id == id) {
            this.sparte_list[j].is_add_button_show = true;
          }
        }
      }
    }

    this.isSparteChanged = true;
  }
  typeGroupName(text) {
    this.groupNameError = false;
    this.sparte_group_message = false;
    this.message = false;
    this.button_disable = false;
    this.groupName = text;
  }
  save() {
    this.sparte_group_duplicate_message = false;
    this.checkSparte = this.data.smData;
    // create a group name array so we can compare the name of array so can avoid same name
    for (let i = 0; i < this.checkSparte.length; i++) {
      this.groupNameArray.push(this.checkSparte[i].groupName);
    }
    if (this.sparte_table_data.length > 0 && this.groupName !== '') {
      if (this.groupName.replace(/\s/g, '').length !== 0) {
        this.groupNameError = false;
        if (this.groupName !== this.data.sparte_group_name) {
          if (
            this.groupNameArray.includes(this.groupName) ||
            this.spartes.includes(this.groupName)
          ) {
            this.sparte_group_message = this.groupNameArray.includes(
              this.groupName
            )
              ? true
              : false;
            this.sparte_group_duplicate_message = this.spartes.includes(
              this.groupName
            )
              ? true
              : false;
            this.button_disable = true;
            this.groupName = '';
          } else {
            this.button_disable = false;
            this.data.sparte = this.new_sparte_table_data;
            this.data.sparte_group_name = this.groupName;
            this._sparteShareService.sparte = null;
            this.dialogRef.close(this.data);
            this.sparte_group_message = false;
          }
        } else {
          this.data.sparte = this.new_sparte_table_data;
          this.data.sparte_group_name = this.groupName;
          this._sparteShareService.sparte = null;
          this.dialogRef.close(this.data);
        }
      } else {
        this.groupNameError = true;
      }
    } else {
      this.message = true;
    }
  }
  groupArrayOfObjects(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
