<app-user-management
  *ngIf="!editCustomer"
  [inviteUserFlag]="inviteUser"
  (emitinviteUser)="emitInviteUser($event)"
  (editCustomerPage)="editCustomerUserManagement($event)"
  (updateUserInfo)="updateUserData($event)"
></app-user-management>
<app-edit-customer
  *ngIf="editCustomer"
  [userDataEdit]="userDataEdit"
  (editCustomerPage)="editCustomerUserManagement($event)"
></app-edit-customer>
