<app-generate-table [tableDetails]="tableStructure" 
    [tableData]="tableData" [tableConfig]="tableConfig"
    [filterSection]="filterSectionConfig" 
    (emitMethodDetails)="callMethod($event)"
    (emitFilterMethodDetails)="filterTable($event)">
</app-generate-table>

<app-delete-confirmation *ngIf="showConfirmDeletePopup" 
    [inputData]="sendDeleteReq" (emitData)="deleteCPPostConfirmation($event)">
</app-delete-confirmation>
