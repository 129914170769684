import { LOCK_STATUS } from '@shared/constants/general/lock-status.constants';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { SidebarService } from '@core/services/sidebar.service';
import { ToastService } from '@core/services/toast.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import {
  animateText,
  onMainContentChange,
  onSideNavChange,
} from '../../animations';
import {
  CONTRACTS_ROUTES,
  CUSTOMER_MANAGEMENT_ROUTES,
  CUSTOMER_MANAGEMENT_ROUTES_ASSOCIATION,
  ROUTES_PATH,
} from '@shared/constants/router.constants';
import { AddNewContractDialogComponent } from '@modules/new-contract/add-new-contract-dialog/add-new-contract-dialog.component';
import {
  ENTITYTYPEID,
  IVMTYPE,
  OutboundEntryRequest,
} from '@shared/constants/ivm-data.constants';
import {
  ACTION_BAR,
  ACTION_BAR_KEYS,
  ACTION_BAR_MULTILINGUAL,
} from '@shared/constants/image-paths/action-bar.constant';
import { STATIC_TEXT } from '@shared/constants/string.constants';
import { DocumentManagementService } from '@core/services/document-management.service';
import { takeUntil } from 'rxjs/operators';
import { DocumentSource } from '@shared/models/document-management.model';
import { UploadDocumentData } from '@shared/components/upload-document-popup/upload-document-popup.model';
import { LocksApiService } from '@core/services/httpcalls/locks-api.service';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { SourceType } from '@shared/models/source-type.model';
import { ActionBarItem } from '@shared/interfaces/action-bar.interface';
import { CustomerManagementSharedService } from './customer-management-shared-service';
import { Tab } from '@shared/interfaces/tab.interface';
import { WriteLetterEmailComponent } from '@shared/components/write-letter-email-popup/write-letter-email-popup.component';
import { PopupType } from '@shared/components/write-letter-email-popup/write-letter-email-popup.model';
import { IC_ICONS } from '@shared/constants/image-paths/ic-icons.constant';

export interface SendResource {
  contract_id: any;
  report_uri: any;
  user_id: any;
  document_type_id: any;
  customer_id: any;
  liztu_vs_idnr: any;
  liztu_vn_idnr: any;
  document_description: any;
  save_document: any;
  date_from?: any;
  date_to?: any;
}

enum CUSTOMER_MANAGEMENT_TABS {
  GENERAL_DATA = 'GENERAL_DATA',
  CUSTOMER_DETAILS = 'CUSTOMER_DETAILS',
  CUSTOMER_RELATIONS = 'CUSTOMER_RELATIONS',
  COMMUNICATION = 'COMMUNICATION',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  BANK_ACCOUNT_DETAILS = 'BANK_ACCOUNT_DETAILS',
  CONTACT_PERSON = 'CONTACT_PERSON',
  DOCUMENT_MANAGEMENT = 'DOCUMENT_MANAGEMENT',
  APPOINTMENTS = 'APPOINTMENTS',
}

@Component({
  selector: 'app-customers',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss'],
  animations: [onSideNavChange, animateText, onMainContentChange],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomerManagementComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  readonly links = CUSTOMER_MANAGEMENT_ROUTES_ASSOCIATION;
  readonly tabs = CUSTOMER_MANAGEMENT_TABS;
  leftTabs: Tab<CUSTOMER_MANAGEMENT_ROUTES, CUSTOMER_MANAGEMENT_TABS>[] = [];
  ivm_sync = new FormControl({ value: false, disabled: true });
  is_ivm_sync: boolean;
  lock_state: number = LOCK_STATUS.UNLOCKED;
  state_warning_ivm_hidden = true;
  state_warning_protect_hidden = true;
  user_role: USER_ROLES;
  divisionName: any;
  pageBreadcrumbText = 'customer-management.customer-management';
  sideNavState = true;
  active: CUSTOMER_MANAGEMENT_TABS;
  linkText = true;
  defaultActions: ActionBarItem[] = [];
  extraActions: ActionBarItem[] = [];
  activeLabel: any;
  isCustomerUser = false;
  isICUser = false;
  customer_id: string;
  userDataValue: any;
  liztu: string;
  showDocTemplateModal = false;
  selected_template = null;
  avaliable_templates = [];
  docDescription = new FormControl();
  selectedTemplateUrl: any;
  showPDFViewer: boolean;
  pdfLink: any;
  pdfName: any;
  blob: any;
  sourceTypes = SourceType;
  isProdMode = false;
  salutationNotCustomer = false;
  customerDivisionId: string;
  customerName$ = this._customerSharedService.selectedCustomerName$;

  constructor(
    private serviceCall: ApiCallsService,
    private router: Router,
    private route: ActivatedRoute,
    public validationService: ValidationService,
    private _sidenavService: SidebarService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private _toastService: ToastService,
    private readonly _documentManagementService: DocumentManagementService,
    private readonly _locksService: LocksApiService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._customerManagementSharedService.saveEditCustomerOperation(true);
  }

  ngOnInit(): void {
    const liztu = this.route.snapshot.queryParams.liztu;
    this.liztu = liztu ? atob(liztu) : null;

    if (
      !liztu &&
      !this.router.url.includes(CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA)
    ) {
      this.router.navigate([
        ROUTES_PATH.CUSTOMER_MANAGEMENT,
        CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA,
      ]);
    }

    this._watchOnUrlChanging();
    this._watchOnIsSourceIVM();
    this._watchOnUserRole();
    this._watchOnIvmSync();
    this._watchOnIvmToggle();
    this._watchOnSalutation();
    this._watchOnChangingDivisionId();
    this._watchOnCustomerId();
    this._setActiveTab();
    this.activeLabel = this.setActiveLabel(this.active);
    // remove searchItemPath from session to resolve multi discard popup issue
    sessionStorage.removeItem('searchItemPath');
    if (window.location.origin === STATIC_TEXT.PROD_URL) {
      this.isProdMode = true;
    }
    this.ivm_sync.disable({ emitEvent: false });

    if (this.router.url.toLocaleLowerCase().indexOf('liztu') !== -1) {
      // means existing customer, not a creating operation
      this._setIvmSync();
    }

    this._setTabs();
    this._setActions();
  }

  navigateToTab(tab: CUSTOMER_MANAGEMENT_ROUTES): void {
    this.router.routeReuseStrategy.shouldReuseRoute = (future, curr) =>
      future.routeConfig === curr.routeConfig;
    this.router.navigate([tab], {
      relativeTo: this.route,
      queryParams: this.route.snapshot.queryParams,
    });
  }

  actionBarClick(key: string): void {
    switch (key) {
      case ACTION_BAR_KEYS.UPLOAD_DOCUMENT: {
        this._upload();
        return;
      }
      case ACTION_BAR_KEYS.TOGGLE_PROTECT_LOCK: {
        this._changeLockState();
        return;
      }
      case ACTION_BAR_KEYS.CREATE_DOCUMENT_FROM_TEMPLATE: {
        this._getTemplates();
        return;
      }
      case ACTION_BAR_KEYS.WRITE_LETTER: {
        this.dialog.open(WriteLetterEmailComponent, {
          data: {
            type: PopupType.LETTER,
            ...this._getRequiredFieldsToUpload(),
          },
        });
        return;
      }
      case ACTION_BAR_KEYS.WRITE_EMAIL: {
        this.dialog.open(WriteLetterEmailComponent, {
          data: { type: PopupType.EMAIL, ...this._getRequiredFieldsToUpload() },
        });
        return;
      }
      case ACTION_BAR_KEYS.CREATE_CONTRACT: {
        this._addContractDialog();
        return;
      }
      case ACTION_BAR_KEYS.PROVIDE_DATA_TO_IVM: {
        this._saveDataToIVM();
        return;
      }
      case ACTION_BAR_KEYS.ADD_COMMUNICATION: {
        this._customerManagementSharedService.addCommunication$.next(null);
        return;
      }

      case ACTION_BAR_KEYS.ADD_BANK_DETAILS: {
        this._customerManagementSharedService.addBank$.next(null);
        return;
      }

      case ACTION_BAR_KEYS.ADD_CUSTOMER_RELATION: {
        this._customerManagementSharedService.addCustomerRelation$.next(null);
        return;
      }

      case ACTION_BAR_KEYS.INVITE_NEW_USER: {
        this._customerManagementSharedService.inviteNewUser$.next(null);
        return;
      }

      case ACTION_BAR_KEYS.ADD_CONTACT_PERSON: {
        this._customerManagementSharedService.addContactPerson$.next(null);
        return;
      }
    }
  }

  tabChange(event: {
    activeId: CUSTOMER_MANAGEMENT_TABS;
    nextId: CUSTOMER_MANAGEMENT_TABS;
  }): void {
    this.active = event.nextId;
    this.activeLabel = this.setActiveLabel(this.active);
    this._setActions();
  }

  setActiveLabel(active_tab: CUSTOMER_MANAGEMENT_TABS): string {
    return {
      [CUSTOMER_MANAGEMENT_TABS.GENERAL_DATA]:
        'customer-management.left-panel.general-data',
      [CUSTOMER_MANAGEMENT_TABS.CUSTOMER_DETAILS]:
        'customer-management.left-panel.company-details',
      [CUSTOMER_MANAGEMENT_TABS.CUSTOMER_RELATIONS]:
        'customer-management.left-panel.customer-relations',
      [CUSTOMER_MANAGEMENT_TABS.COMMUNICATION]:
        'customer-management.communication-details.communication_details',
      [CUSTOMER_MANAGEMENT_TABS.USER_MANAGEMENT]:
        'customer-management.left-panel.user-management',
      [CUSTOMER_MANAGEMENT_TABS.BANK_ACCOUNT_DETAILS]:
        'customer-management.left-panel.bank-account',
      [CUSTOMER_MANAGEMENT_TABS.CONTACT_PERSON]:
        'customer-management.left-panel.contact-person',
      [CUSTOMER_MANAGEMENT_TABS.DOCUMENT_MANAGEMENT]:
        'customer-management.left-panel.document-management',
      [CUSTOMER_MANAGEMENT_TABS.APPOINTMENTS]: 'appointments.title',
    }[active_tab];
  }

  userDataReceived(data): void {
    this.userDataValue = data;
    this.divisionName = data.user_profile.customer_details.name;
    if (this.route.snapshot.queryParams.liztu) {
      this._userService.saveDeliztu(
        atob(this.route.snapshot.queryParamMap.get('liztu'))
      );
    }
  }

  onSinenavToggle(): void {
    this.sideNavState = !this.sideNavState;
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200);
    this._sidenavService.sideNavState$.next(this.sideNavState);
  }

  changeState(event): void {
    const data = {
      is_ivm_sync: !!event,
      liztu_vn_kdnr: this.liztu,
    };
    this.serviceCall.IVMsyn(data).pipe(takeUntil(this._destroy$)).subscribe();
  }

  saveDocTemplate(save_document): void {
    this.showDocTemplateModal = false;
    const data: SendResource = {
      contract_id: null,
      report_uri: this.selectedTemplateUrl,
      user_id: this.userDataValue.user_profile.user_data.user_id,
      document_type_id: 2,
      customer_id: Number(this.customer_id),
      liztu_vs_idnr: null,
      liztu_vn_idnr: this.liztu,
      document_description: this.docDescription.value,
      save_document,
    };
    this.serviceCall
      .createPolicy(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data) {
          if (data.size > 0) {
            if (save_document) {
              this._toastService.showToastMessage(
                document,
                this.translate.instant(
                  'new-contract.general_information.create_save_succesfull_message'
                )
              );
            } else {
              this._toastService.showToastMessage(
                document,
                this.translate.instant(
                  'new-contract.general_information.create_succesfull_message'
                )
              );
            }
            const fileURL = window.URL.createObjectURL(data);
            this.showPDFViewer = true;
            this.pdfLink = fileURL;
            this.pdfName = this.selectedTemplateUrl.split('/reports/')[1];
            this.blob = data;
          }
        }
      });
    this.selected_template = '';
    this.docDescription.reset();
  }

  scrollValue(key): void {
    document.getElementById(key) && document.getElementById(key).focus();
  }

  closeDocModal(): void {
    this.showDocTemplateModal = false;
  }

  selectedTemplate(event): void {
    this.selectedTemplateUrl = event.value;
    this.docDescription.reset();
  }

  onClosePdf(event): void {
    this.showPDFViewer = event;
  }

  private _setIvmSync(): void {
    this.serviceCall
      .GetIVMsync(this.liztu)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.is_ivm_sync = data.is_ivm_sync;
        this.lock_state = data.lock_state;
        this._handleLockState(this.lock_state);
      });
  }

  private _IVMoff(event): void {
    if (event && this.lock_state !== LOCK_STATUS.LOCKED_BY_ERP) {
      this.ivm_sync.setValue(false);
      this.changeState(false);
    }
  }

  private _upload(): void {
    this._documentManagementService.setUploadingDocsFields(
      this._getRequiredFieldsToUpload()
    );
    this._documentManagementService.openDialog();
  }

  private _handleLockState(state: number): void {
    switch (state) {
      case LOCK_STATUS.UNLOCKED:
        this.ivm_sync.setValue(this.is_ivm_sync);
        this.state_warning_ivm_hidden = true;
        this.state_warning_protect_hidden = true;
        // this.ivm_sync.enable({ emitEvent: false });
        this.ivm_sync.disable({ emitEvent: false });
        break;
      case LOCK_STATUS.LOCKED_BY_ERP:
        this.ivm_sync.setValue(true);
        this.ivm_sync.disable({ emitEvent: false });
        this.state_warning_ivm_hidden = false;
        this.state_warning_protect_hidden = true;
        break;
      case LOCK_STATUS.LOCKED_BY_IC:
        this.ivm_sync.setValue(false);
        this.ivm_sync.disable({ emitEvent: false });
        this.state_warning_ivm_hidden = true;
        this.state_warning_protect_hidden = false;
        break;
    }
  }

  private _saveDataToIVM(): void {
    const body: OutboundEntryRequest = {
      entity_type_id: ENTITYTYPEID.CUSTOMER,
      entity_type: IVMTYPE.CUSTOMER,
      entity_protect_id: Number(this.customer_id),
      entity_ivm_id: Number(this.liztu),
      division_id: Number(this.customerDivisionId),
      liztu: null,
    };
    this.serviceCall
      .saveOutboundEntry(body)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._toastService.showToastMessage(
          document,
          this.translate.instant('product-management.added_success_msg')
        );
      });
  }

  private _addContractDialog(): void {
    this.dialog
      .open(AddNewContractDialogComponent)
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data) {
          const prev = this.router.routeReuseStrategy.shouldReuseRoute;
          const prevOSN = this.router.onSameUrlNavigation;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router
            .navigate(
              [
                `${ROUTES_PATH.ADD_NEW_CONTRACT}/${CONTRACTS_ROUTES.GENERAL_DATA}`,
              ],
              {
                queryParams: {
                  liztu: this.route.snapshot.queryParams.liztu,
                },
              }
            )
            .then(() => {
              this.router.routeReuseStrategy.shouldReuseRoute = prev;
              this.router.onSameUrlNavigation = prevOSN;
            });
        }
      });
  }

  private _changeLockState(): void {
    const state =
      this.lock_state === LOCK_STATUS.UNLOCKED
        ? LOCK_STATUS.LOCKED_BY_IC
        : LOCK_STATUS.UNLOCKED;
    const lockData = {
      id: +this.liztu,
      lock_state: +state,
      updatedByUserId: this._userService.getUserId(),
    };
    this._locksService
      .updateCustomerLockState(lockData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.status == 'success') {
          this.lock_state = state;
          this._handleLockState(this.lock_state);
        } else {
          this._toastService.showToastMessage(
            document,
            this.translate.instant('generic_message.change_lock_state_error')
          );
        }
      });
  }

  private _getRequiredFieldsToUpload(): UploadDocumentData {
    return {
      userId: this.userDataValue.user_profile.user_data.user_id,
      VN_KDNR: +this.liztu,
      VS_IDNR: null,
      SA_IDNR: null,
      contractId: null,
      source: DocumentSource.CUSTOMER,
      customerId: +this.customer_id,
      policyNumber: null,
      documentNumber: null,
      insurerId: null,
      damageId: null,
      documentCreationDate: null,
      documentIdentifier: null,
      sourceUser: null,
      targetUser: null,
      intermediaryId: null,
      isVisibleForCustomer: false,
    };
  }

  private _initRoleFields(): void {
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._initRoleFields();
      });
  }

  private _getTemplates(): void {
    this.showDocTemplateModal = true;
    this.serviceCall
      .getProductSpecificTemplateCustomer(27)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.avaliable_templates = data.data));
  }

  private _getDefaultActions(): ActionBarItem[] {
    const isEditCustomerValue =
      this._customerManagementSharedService.getEditCustomerOperation();
    const doesLiztuExist = !!this.liztu;

    return [
      {
        // imgSrc: ACTION_BAR.CREATE_DOCUMENT_FROM_TEMPLATE,
        imgSrc: IC_ICONS.DOCUMENTS,
        desc: this.translate.instant(
          ACTION_BAR_MULTILINGUAL.CREATE_DOCUMENT_FROM_TEMPLATE
        ),
        key: ACTION_BAR_KEYS.CREATE_DOCUMENT_FROM_TEMPLATE,
        disabled: !isEditCustomerValue || !doesLiztuExist,
      },
      {
        // imgSrc: ACTION_BAR.WRITE_LETTER,
        imgSrc: IC_ICONS.WRITE_LETTER,
        desc: this.translate.instant(ACTION_BAR_MULTILINGUAL.WRITE_LETTER),
        key: ACTION_BAR_KEYS.WRITE_LETTER,
        disabled: !isEditCustomerValue || !doesLiztuExist,
      },
      {
        // imgSrc: ACTION_BAR.WRITE_EMAIL,
        imgSrc: IC_ICONS.WRITE_AN_EMAIL,
        desc: this.translate.instant(ACTION_BAR_MULTILINGUAL.WRITE_EMAIL),
        key: ACTION_BAR_KEYS.WRITE_EMAIL,
        disabled: !isEditCustomerValue || !doesLiztuExist,
      },
      {
        // imgSrc: ACTION_BAR.CREATE_CONTRACT,
        imgSrc: IC_ICONS.CREATE_CONTRACT,
        desc: this.translate.instant(ACTION_BAR_MULTILINGUAL.CREATE_CONTRACT),
        key: ACTION_BAR_KEYS.CREATE_CONTRACT,
        disabled: !isEditCustomerValue || !doesLiztuExist,
      },
      {
        imgSrc: IC_ICONS.TO_ERP,
        desc: this.translate.instant(
          ACTION_BAR_MULTILINGUAL.PROVIDE_DATA_TO_IVM
        ),
        key: ACTION_BAR_KEYS.PROVIDE_DATA_TO_IVM,
        hidden: this.isProdMode,
        disabled: this.lock_state > 0 || !doesLiztuExist,
      },
    ];
  }

  private _getExtraActions(): ActionBarItem[] {
    const isEditCustomerValue =
      this._customerManagementSharedService.getEditCustomerOperation();
    const doesLiztuExist = !!this.liztu;
    switch (this.active) {
      case CUSTOMER_MANAGEMENT_TABS.GENERAL_DATA:
        return [
          {
            imgSrc: IC_ICONS.GENERAL_DATA, //ACTION_BAR.TOGGLE_PROTECT_LOCK,
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.TOGGLE_PROTECT_LOCK
            ),
            key: ACTION_BAR_KEYS.TOGGLE_PROTECT_LOCK,
            disabled:
              this.lock_state === LOCK_STATUS.LOCKED_BY_ERP || !doesLiztuExist,
            hidden: this.isProdMode,
          },
        ];
      case CUSTOMER_MANAGEMENT_TABS.CUSTOMER_RELATIONS:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.ADD_CUSTOMER_RELATION
            ),
            imgSrc: IC_ICONS.CUSTOMER_RELATIONS, //ACTION_BAR.ADD_CUSTOMER_RELATION,
            key: ACTION_BAR_KEYS.ADD_CUSTOMER_RELATION,
            disabled: !isEditCustomerValue || !doesLiztuExist,
            hidden: this.isProdMode,
          },
        ];
      case CUSTOMER_MANAGEMENT_TABS.COMMUNICATION:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.ADD_COMMUNICATION
            ),
            imgSrc: IC_ICONS.COMMUNICATION, //ACTION_BAR.ADD_COMMUNICATION,
            key: ACTION_BAR_KEYS.ADD_COMMUNICATION,
            disabled: !isEditCustomerValue || !doesLiztuExist,
            hidden: this.isProdMode,
          },
        ];
      case CUSTOMER_MANAGEMENT_TABS.USER_MANAGEMENT:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.INVITE_NEW_USER
            ),
            imgSrc: IC_ICONS.USER_MANAGEMENT, //ACTION_BAR.INVITE_NEW_USER,
            key: ACTION_BAR_KEYS.INVITE_NEW_USER,
            disabled: false || !doesLiztuExist,
            hidden: this.isProdMode,
          },
        ];
      case CUSTOMER_MANAGEMENT_TABS.BANK_ACCOUNT_DETAILS:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.ADD_BANK_DETAILS
            ),
            imgSrc: IC_ICONS.BANK, //ACTION_BAR.ADD_BANK_DETAILS,
            key: ACTION_BAR_KEYS.ADD_BANK_DETAILS,
            disabled: !isEditCustomerValue || !doesLiztuExist,
            hidden: this.isProdMode,
          },
        ];
      case CUSTOMER_MANAGEMENT_TABS.CONTACT_PERSON:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.ADD_CONTACT_PERSON
            ),
            imgSrc: IC_ICONS.CONTACT_PERSON, //ACTION_BAR.ADD_CONTACT_PERSON,
            key: ACTION_BAR_KEYS.ADD_CONTACT_PERSON,
            disabled: !isEditCustomerValue || !doesLiztuExist,
            hidden: this.isProdMode,
          },
        ];
      case CUSTOMER_MANAGEMENT_TABS.DOCUMENT_MANAGEMENT:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.UPLOAD_DOCUMENT
            ),
            imgSrc: IC_ICONS.DOCUMENTS, //ACTION_BAR.UPLOAD_DOCUMENT,
            key: ACTION_BAR_KEYS.UPLOAD_DOCUMENT,
            disabled: !this.isICUser || !doesLiztuExist,
          },
        ];
      default:
        return [];
    }
  }

  private _getTabs(): Tab<
    CUSTOMER_MANAGEMENT_ROUTES,
    CUSTOMER_MANAGEMENT_TABS
  >[] {
    const isEditOperation = !!this.route.snapshot.queryParams.liztu;
    return [
      {
        name: this.links.GENERAL_DATA,
        disabled: false,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.general-data'
        ),
        // img: '../../../assets/images/new-icons/General_Data.svg',
        img: IC_ICONS.GENERAL_DATA,
        tab: this.tabs.GENERAL_DATA,
      },
      {
        name: this.links.CUSTOMER_DETAILS,
        disabled: this.salutationNotCustomer || !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.company-details'
        ),
        // img: '../../../assets/images/new-icons/Company_Details.svg',
        img: IC_ICONS.CUSTOMER_DETAILS,
        tab: this.tabs.CUSTOMER_DETAILS,
      },
      {
        name: this.links.CUSTOMER_RELATIONS,
        disabled: !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.customer-relations'
        ),
        // img: '../../../assets/images/new-icons/Customer_Relations.svg',
        img: IC_ICONS.CUSTOMER_RELATIONS,
        tab: this.tabs.CUSTOMER_RELATIONS,
      },
      {
        name: this.links.COMMUNICATION,
        disabled: !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.communication'
        ),
        // img: '../../../assets/images/new-icons/Commuication.svg',
        img: IC_ICONS.COMMUNICATION,
        tab: this.tabs.COMMUNICATION,
      },
      {
        name: this.links.USER_MANAGEMENT,
        disabled: !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.user-management'
        ),
        // img: '../../../assets/images/new-icons/User_Management.svg',
        img: IC_ICONS.USER_MANAGEMENT,
        tab: this.tabs.USER_MANAGEMENT,
      },
      {
        name: this.links.BANK_ACCOUNT_DETAILS,
        disabled: !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.bank-account'
        ),
        // img: '../../../assets/images/new-icons/Bank_Account_Details.svg',
        img: IC_ICONS.BANK,
        tab: this.tabs.BANK_ACCOUNT_DETAILS,
      },
      {
        name: this.links.CONTACT_PERSON,
        disabled: !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.contact-person'
        ),
        // img: '../../../assets/images/new-icons/Contact_Person.svg',
        img: IC_ICONS.CONTACT_PERSON,
        tab: this.tabs.CONTACT_PERSON,
      },
      {
        name: this.links.DOCUMENT_MANAGEMENT,
        disabled: !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.document-management'
        ),
        // img: '../../../assets/images/documents_overview.svg',
        img: IC_ICONS.DOCUMENTS,
        tab: this.tabs.DOCUMENT_MANAGEMENT,
      },
      {
        name: this.links.APPOINTMENTS,
        disabled: !isEditOperation,
        sourceTitle: this.translate.instant(
          'customer-management.left-panel.appointments'
        ),
        // img: '../../../assets/images/appointments-tab.svg',
        img: IC_ICONS.APPOINTMENTS,
        tab: this.tabs.APPOINTMENTS,
      },
    ];
  }

  private _setActions(): void {
    this.defaultActions = this._getDefaultActions();
    this.extraActions = this._getExtraActions();
  }

  private _setTabs(): void {
    this.leftTabs = this._getTabs();
  }

  private _watchOnIvmSync(): void {
    this.ivm_sync.valueChanges
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(result => {
        this._customerManagementSharedService.saveEditCustomerOperation(
          !result
        );
        this._setActions();
      });
  }

  private _watchOnIvmToggle(): void {
    this._customerManagementSharedService.IVMSyncToggle$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(value => this._IVMoff(value));
  }

  private _watchOnSalutation(): void {
    this._customerManagementSharedService.isSalutationNotCustomerChosen$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.salutationNotCustomer = value;
        this._setTabs();
      });
  }

  private _watchOnChangingDivisionId(): void {
    this._customerSharedService.selectedCustomerDivisionId$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.customerDivisionId = value));
  }

  private _watchOnCustomerId(): void {
    this._customerSharedService.selectedCustomerId$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => (this.customer_id = value));
  }

  private _watchOnUrlChanging(): void {
    this.router.events.pipe(takeUntil(this._destroy$)).subscribe(value => {
      if (value instanceof NavigationEnd) {
        this._setActiveTab();
        this.activeLabel = this.setActiveLabel(this.active);
        this._setActions();
      }
    });
  }

  private _setActiveTab(): void {
    for (const key in CUSTOMER_MANAGEMENT_ROUTES_ASSOCIATION) {
      if (
        this.router.url.includes(CUSTOMER_MANAGEMENT_ROUTES_ASSOCIATION[key])
      ) {
        this.active = CUSTOMER_MANAGEMENT_TABS[key];
        return;
      }
    }
  }

  private _watchOnIsSourceIVM(): void {
    this._customerManagementSharedService.isSourceIvm$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (value && this.lock_state === LOCK_STATUS.UNLOCKED) {
          // this.ivm_sync.enable({ emitEvent: false });
          this.ivm_sync.disable({ emitEvent: false });
        }
      });
  }
}
