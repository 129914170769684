<!--Simple breadcrumb with company name,division name, page heading after company selection i.e claims page-->
<ng-container *ngIf="isCustomerSelected">
    <div *ngIf="isCustomerUser" class="user-name">
        <strong>{{ customerName }}&nbsp;&nbsp;
            <span>/</span>&nbsp;&nbsp;
            {{ pageBreadcrumbText | translate}}
            &nbsp;&nbsp;
            <span *ngIf="pageBreadcrumbSubText"><span>/</span>&nbsp;&nbsp;
                {{pageBreadcrumbSubText | translate}}&nbsp;&nbsp; </span></strong>
    </div>
    <div *ngIf="!isCustomerUser" class="user-name">
        <strong>
            <span (click)="navigateToDashboard()" class="user-name--active">{{divisionName}}</span>
            <span *ngIf="customerName">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
            <span 
                (click)="navigateToCustomer()" 
                class="user-name--active">{{ customerName }}</span>&nbsp;&nbsp;
            <span *ngIf="contractInfo">
                <span>/</span>&nbsp;&nbsp;
                <span (click)="navigateToContract()" class="user-name--active">
                    <ng-container *ngIf="contractInfo.contractLabel">
                        {{contractInfo.contractLabel}}</ng-container>
                    <ng-container *ngIf="contractInfo.assignedProduct">
                        [{{ contractInfo.assignedProduct }}] </ng-container>
                    <ng-container *ngIf="contractInfo.policyNumber">
                        [{{ contractInfo.policyNumber }}]</ng-container>
                </span>
                &nbsp;&nbsp;
            </span>
            <span *ngIf="pageBreadcrumbText">
                <span>/</span>&nbsp;&nbsp;
                {{ pageBreadcrumbText | translate }} 
            </span>            
            <span
                *ngIf="pageBreadcrumbSubText"><span>/</span>&nbsp;&nbsp;
                {{pageBreadcrumbSubText | translate}} &nbsp;&nbsp;</span> <span
                *ngIf="activeLable"><span>/</span>&nbsp;&nbsp;
                {{activeLable | translate}}&nbsp;&nbsp; </span><span *ngIf="tempActiveLable"><span>/</span>&nbsp;&nbsp;
                {{tempActiveLable | translate}}&nbsp;&nbsp; </span></strong>
    </div>
</ng-container>
<!--Simple breadcrumb with company name, page heading when company has not selected i.e settings page-->
<ng-container *ngIf="!isCustomerSelected">
    <div class="user-name">
        <strong>{{ customerName }}&nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;{{ pageBreadcrumbText | translate }}&nbsp;&nbsp;
            <span *ngIf="pageBreadcrumbSubText"><span>/</span>&nbsp;&nbsp;
                {{pageBreadcrumbSubText | translate}} &nbsp;&nbsp;</span><span
                *ngIf="activeLable"><span>/</span>&nbsp;&nbsp;
                {{activeLable | translate}}&nbsp;&nbsp; </span><span *ngIf="tempActiveLable"><span>/</span>&nbsp;&nbsp;
                {{tempActiveLable | translate}}&nbsp;&nbsp; </span></strong>
    </div>
</ng-container>