import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { CalamitiesTabsComponent } from '../calamities-tabs/calamities-tabs.component';
import { ZursGeoComponent } from '../zurs-geo.component';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';

export interface analysisData {
  user_id: any;
  division_id: any;
  is_snow: any;
  is_flood: any;
  is_earthquake: any;
  snow_price: any;
  flood_price: any;
  earthhquake_price: any;
  gross_price: any;
  vat_percent: any;
  net_price: any;
  lat: any;
  lon: any;
  zip_code: any;
  city: any;
  street: any;
  house_number: any;
  address: any;
}

@Component({
  selector: 'app-zurs-geo-payment',
  templateUrl: './zurs-geo-payment.component.html',
  styleUrls: ['./zurs-geo-payment.component.css'],
})
export class ZursGeoPaymentComponent extends BaseComponent implements OnInit {
  displayedColumns = ['service', 'details', 'charge'];
  dataSource = new MatTableDataSource();
  readonly selectLang$ = this._userService.locale$;
  flood_price: number;
  earthquack_price: number;
  snow_price: number;
  netPrice: number;
  percentage: number;
  grossPrice: number;

  constructor(
    public dialogRef: MatDialogRef<ZursGeoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceCall: ApiCallsService,
    private dialog: MatDialog,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getPayment();
  }

  getPayment(): void {
    this.serviceCall
      .getZursPayment(this.data.flood, this.data.earth, this.data.snow)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => this.formatPricingData(data));
  }

  formatPricingData(prices): void {
    this.flood_price = prices.flood_price;
    this.earthquack_price = prices.earthquake_price;
    this.snow_price = prices.snow_price;
    this.netPrice = this.flood_price + this.earthquack_price + this.snow_price;
    this.percentage = this.netPrice * 0.19;
    this.grossPrice = this.netPrice + this.percentage;
  }

  floodCheckPayment(event): void {
    this.data.flood = !!event.checked;
    this.serviceCall
      .getZursPayment(this.data.flood, this.data.earth, this.data.snow)
      .subscribe(data => this.formatPricingData(data));
  }

  earthCheckPayment(event): void {
    this.data.earth = !!event.checked;
    this.serviceCall
      .getZursPayment(this.data.flood, this.data.earth, this.data.snow)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => this.formatPricingData(data));
  }

  snowCheckPayment(event): void {
    this.data.snow = !!event.checked;
    this.serviceCall
      .getZursPayment(this.data.flood, this.data.earth, this.data.snow)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => this.formatPricingData(data));
  }

  save(): void {
    const { snow, flood, earth } = this.data;
    if (snow || flood || earth) {
      const data: analysisData = {
        user_id: this._userService.getUserId(),
        division_id: this._userService.getDivisionId(),
        is_snow: this.data.snow,
        is_flood: this.data.flood,
        is_earthquake: this.data.earth,
        snow_price: this.snow_price,
        flood_price: this.flood_price,
        earthhquake_price: this.earthquack_price,
        gross_price: this.grossPrice,
        vat_percent: this.percentage,
        net_price: this.netPrice,
        lat: this.data.latitude,
        lon: this.data.longitude,
        zip_code: this.data.zip,
        city: this.data.city,
        street: this.data.street,
        house_number: this.data.house,
        address: this.data.address,
      };
      const zursCalamitiesDialog = this.dialog.open(CalamitiesTabsComponent, {
        width: '45%',
        panelClass: 'zursAnalysis',
        data,
        disableClose: true,
      });
      zursCalamitiesDialog
        .afterClosed()
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.dialogRef.close());
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
