import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ShareDataService } from '@core/services/share-data.service';
import { ToastService } from '@core/services/toast.service';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ROLES } from '@shared/constants/roles.constants';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { DateTimeConvertService } from '@core/services/datetime/date-time-convert.service';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { takeUntil } from 'rxjs/operators';
import { ContractsMainComponentBaseService } from './contracts.component-base-service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { ContractApiService } from '@core/services/httpcalls/contract-api-calls.service';
import { ContractElement } from './contracts.component.model';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsMainComponent
  extends ContractsMainComponentBaseService
  implements OnInit, AfterViewInit
{
  constructor(
    public dialog: MatDialog,
    public dateFormatPipe: DateFormatPipe,
    public translate: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public serviceCall: ApiCallsService,
    public _contractApiService: ContractApiService,
    public fb: FormBuilder,
    public validationService: ValidationService,
    public toastService: ToastService,
    public readonly _documentsApiService: DocumentManagementApiService,
    public readonly _dateTimeService: DateTimeConvertService,
    public readonly _userService: UserService,
    public readonly _customerSharedService: CustomerSharedService,
    public readonly _shareDataService: ShareDataService
  ) {
    super(
      dialog,
      dateFormatPipe,
      translate,
      router,
      route,
      serviceCall,
      _contractApiService,
      fb,
      validationService,
      toastService,
      _documentsApiService,
      _dateTimeService,
      _userService,
      _customerSharedService,
      _shareDataService
    );

    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    this.dataSource.sort = this.sort;
    this.formMsg = this.fb.group({
      subject: this.subject,
      msg: this.msg,
    });
    this.dataSource.filterPredicate = ((data, filter) => {
      const sparte = data.sparte_id;
      const c =
        !filter.KfzVerträge ||
        (sparte.indexOf('5') != 0 && sparte.length === 3);
      const d =
        !filter.contractNumber ||
        data.contract_number.indexOf(filter.contractNumber) !== -1 ||
        data.contract_number === filter.contractNumber;
      return c && d;
    }) as (PeriodicElement, str) => boolean;
    this.formFilter = this.fb.group({
      KfzVerträge: [null],
      contractNumber: '',
      wholeGroup: [''],
      status: this.fb.array([]),
    });
  }

  ngOnInit() {
    this._watchOnUserRole();
    this._watchOnSort();

    this._customerSharedService.customerGeneralData$
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.is_source_ivm = data?.is_source_ivm;
        this.customer_id = data?.customer_info?.customer_id;
        this.type = data?.customer_status.type;
      });
  }
  ngOnChanges(): void {
    this.setQueryParams();
    if (
      this.customerTypes.includes(this.type) ||
      this.customerTypes.includes(this.receivedType)
    ) {
      this.formFilter.controls.KfzVerträge.reset();
      this.formFilter.controls.status.reset();
      this.uncheck = false;
      this.isWholeGroup = false;
      this.formFilter.controls.wholeGroup.reset();
    }
    if (
      this.type === CUSTOMER_TYPES.MUTTER ||
      this.receivedType === CUSTOMER_TYPES.MUTTER
    ) {
      this.formFilter.controls.KfzVerträge.reset();
      this.formFilter.controls.status.reset();
      this.showWholeGroupCheck = true;
    } else {
      this.showWholeGroupCheck = false;
    }
    if (
      this.encoded_customer_id === null &&
      this.encoded_liztu_vn_kdnr === null &&
      this.encoded_contract === null
    ) {
      this.loadContractData();
    } else if (
      this.encoded_customer_id !== null &&
      this.encoded_liztu_vn_kdnr !== null &&
      this.encoded_contract === null
    ) {
      this.loadContractData(true);
    } else if (
      this.encoded_customer_id !== null &&
      this.encoded_liztu_vn_kdnr !== null &&
      this.encoded_contract !== null &&
      this.encoded_date === null
    ) {
      this.contract_id_all = this.contract;
      if (this.encoded_contract !== 'bnVsbA==') {
        this.showContract = true;
      }
      this.loadContractData(true);
    } else if (
      this.encoded_customer_id !== null &&
      this.encoded_liztu_vn_kdnr !== null &&
      this.encoded_contract !== null &&
      this.encoded_date !== null
    ) {
      this.loadContractData(true);
    }
    this.dataSource.sort = this.sort;
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  switchChildContracts(contract: ContractElement): void {
    const parentIndex = this.ContractData.findIndex(
      item => item.contract_id === contract.contract_id
    );
    const master = {
      ...this.ContractData[parentIndex],
      isOpened: !contract.isOpened,
    };
    let newContractsArray = [];

    if (!contract.isOpened) {
      const childContracts = this.formatTableData(contract.childContracts).map(
        item => ({
          ...item,
          parentId: contract.contract_id,
          isOpened: true,
        })
      );
      newContractsArray = [
        ...this.ContractData.slice(0, parentIndex),
        master,
        ...childContracts,
        ...this.ContractData.slice(parentIndex + 1),
      ];
    } else {
      const amountOfChildren = this.ContractData.filter(
        item => item.parentId === contract.contract_id
      ).length;
      newContractsArray = [
        ...this.ContractData.slice(0, parentIndex),
        master,
        ...this.ContractData.slice(parentIndex + amountOfChildren + 1),
      ];
    }

    this.ContractData = newContractsArray;
    this.dataSource.data = newContractsArray;
  }

  setQueryParams() {
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu_vn_kdnr = this.encoded_liztu_vn_kdnr
      ? atob(this.encoded_liztu_vn_kdnr)
      : this.encoded_liztu_vn_kdnr;
    this.encoded_contract = this.route.snapshot.queryParamMap.get('contract');
    this.encoded_date = this.route.snapshot.queryParamMap.get('date');
    this.contract = this.encoded_contract
      ? Number(atob(this.encoded_contract))
      : null;
  }
  userDataReceived(data) {
    this.userDataValue = data;
    this.user_data = this.userDataValue?.user_profile?.user_data;
    this.customer_details = this.userDataValue?.user_profile?.customer_details;
    this.divisionName = data.user_profile.customer_details.name;
    this.setQueryParams();
    this.user_id = data.user_profile.user_data.user_id;
    this.user_idnr_local = data.user_profile.user_data.user_idnr;

    if (
      (!this.logo && this.encoded_customer_id === null) ||
      this.encoded_customer_id !== null
    ) {
      this.serviceCall
        .getLogo(this.customer_id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data) {
            this.logo = window.URL.createObjectURL(data);
          }
        });
    }
    if (this.customerTypes.includes(this.type)) {
      this.uncheck = false;
      this.isWholeGroup = false;
      this.formFilter.controls.wholeGroup.reset();
    }
    if (this.type === CUSTOMER_TYPES.MUTTER) {
      this.showWholeGroupCheck = true;
    } else {
      this.showWholeGroupCheck = false;
    }
    if (this.previousFilters) {
      const statuses = [];
      if (this.previousFilters.status) {
        for (
          let count = 0;
          count < this.previousFilters.status.length;
          count++
        ) {
          statuses.push(this.previousFilters.status[count]);
        }
      }
      this.formFilter = this.fb.group({
        KfzVerträge: this.previousFilters.KfzVerträge,
        contractNumber: this.previousFilters.contractNumber,
        wholeGroup: this.previousFilters.wholeGroup,
        status: this.fb.array(statuses),
      });
      this.getFormsValue(this.previousFilters, true);
    } else {
      if (
        !this.encoded_customer_id &&
        !this.encoded_liztu_vn_kdnr &&
        !this.encoded_contract
      ) {
        this.loadContractData();
      } else {
        this.loadContractData(true);
      }
    }
    this.dataSource.sort = this.sort;
    if (this.type === CUSTOMER_TYPES.MUTTER) {
      this.showWholeGroupCheck = true;
    } else {
      this.showWholeGroupCheck = false;
    }
    if (this.customerTypes.includes(this.type)) {
      this.isWholeGroup = false;
    }
    this.dataSource.paginator = this.paginator;
    if (this.previousFilters) {
      const statuses = [];
      if (this.previousFilters.status) {
        for (
          let count = 0;
          count < this.previousFilters.status.length;
          count++
        ) {
          statuses.push(this.previousFilters.status[count]);
        }
      }
      this.formFilter = this.fb.group({
        KfzVerträge: this.previousFilters.KfzVerträge,
        contractNumber: this.previousFilters.contractNumber,
        wholeGroup: this.previousFilters.wholeGroup,
        status: this.fb.array(statuses),
      });
    } else {
      this.formFilter.reset();
      this.formFilter = this.fb.group({
        KfzVerträge: [null],
        contractNumber: '',
        wholeGroup: [''],
        status: this.fb.array([]),
      });
    }
    this.receivedType = this._shareDataService.messageType;
    if (this.receivedType === CUSTOMER_TYPES.MUTTER) {
      this.showWholeGroupCheck = true;
    }
  }
  getFormsValue(value, isString?) {
    this.isFilterApplied = true;
    const statusData: string[] = [];
    const sorting = this.sort.direction
      ? {
          sortedColumns: [this.sort.active],
          sortDirection: this.sort.direction,
        }
      : null;
    if (null !== value) {
      if (value.status.length > 0) {
        for (let count = 0; count < value.status.length; count++) {
          statusData.push(value.status[count].label);
        }
      }
      this._contractApiService
        .getContracts(
          this.customer_id,
          String(this.user_data.user_id),
          value.KfzVerträge,
          this.isWholeGroup,
          statusData,
          this.tablePage,
          this.selectedCountOfRows,
          sorting
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.totalContracts = data.totalContractCount;
          if (!data.HasErrors) {
            this.total_elements = data.totalContractCount;
            this.formatContractData(data);
          }
        });
    } else {
      this._contractApiService
        .getContracts(
          this.customer_id,
          String(this.user_data.user_id),
          false,
          this.isWholeGroup,
          [],
          this.tablePage,
          this.selectedCountOfRows,
          sorting
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.totalContracts = data.totalContractCount;
          if (!data.HasErrors) {
            this.total_elements = data.totalContractCount;
            this.formatContractData(data);
          }
        });
    }
  }
  tablePageChanged(event) {
    this.tablePage = event;
    this.getData();
  }
  numberOfRowsChanged() {
    this.tablePage = 1;
    this.getData();
  }
  getData() {
    if (this.loadContractDataFlag && !this.isFilterApplied) {
      this.loadContractData();
    } else if (this.isFilterApplied) {
      this.getFormsValue(this.formFilter.value);
    } else if (this.loadUserContractDataFlag && !this.isFilterApplied) {
      this.loadContractData(true);
    }
  }
  // display contracts data function loadDocumentData
  displayContractsData() {
    this.dataSource.data = [...this.ContractData];
    if (this.previousFilters) {
      this.getFormsValue(this.previousFilters, true);
    }
  }
  resetFilters() {
    this.isWholeGroup = false;
    this.getFormsValue(null);
    this.formFilter.reset();
    this.formFilter.value.status = [];
    this.statusAvailable.forEach(status => {
      status.isSelected = false;
    });
    this.statusValues = '';
    this.isFilterApplied = false;
  }

  showWholeGroup(isChecked?) {
    this.isWholeGroup = isChecked ? isChecked : !this.isWholeGroup;
  }
  isNotANumber(value) {
    if (+value === 0) {
      return true;
    }
    return isNaN(value);
  }
  hide() {
    this.formMsg.reset();
  }
  // FUNCTION IS TO EXPORT TABLE DATA TO EXCEL SHEET
  async exportExcelData(value) {
    const statusData = [];
    if (value.status.length > 0) {
      for (const status of value.status) {
        statusData.push(status.label);
      }
    }
    let contractData: any;
    contractData = {
      user_idnr: this.user_id,
      customer_id: this.customer_id,
      liztu_vs_idnr: this.isWholeGroup ? null : this.contract_id_all,
      whole_group: this.isWholeGroup,
      is_source_ivm: this.is_source_ivm,
      liztu_vn_kdnr: this.liztu_vn_kdnr,
      hide_vehicle: !value.KfzVerträge ? null : value.KfzVerträge,
      contract_status: statusData,
      is_export: true,
      role: this.user_role,
    };
    this.serviceCall
      .getContract(contractData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.contractexport = data.contracts;
        this.totalContracts = data.total_element;
        if (!data.error) {
          this.exportExcel();
        }
      });
  }
  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  private _initRoleFields(): void {
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
  }

  private _watchOnSort(): void {
    this.sort.sortChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.getData();
    });
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._initRoleFields();
      });
  }
}
