import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LOCALS } from '@shared/constants/local.constants';
import { hammerjs } from '../../node_modules/hammerjs';
import { URLS } from './shared/constants/url.constants';
import { ROUTES_PATH } from './shared/constants/router.constants';
import { ApiCallsService } from './core/services/httpcalls/api-calls.service';
import { KeycloakService } from './core/services/keycloak/keycloak.service';
import { AuthService } from './core/services/httpcalls/auth.service';
import { UserService } from './core/user.service';
import { BaseComponent } from './shared/components/base.component';
import { map, takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnChanges {
  hammerjs = hammerjs;
  showUserDeactivatedModal = false;
  showUserNotFoundModal = false;
  statusText: string;
  isLangSet$ = this._translateService.onLangChange.pipe(map(() => true));

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _apiCallService: ApiCallsService,
    private _userService: UserService,
    private _translateService: TranslateService,
    private readonly _securityApiService: AuthService,
    private readonly _keycloakService: KeycloakService
  ) {
    super();
  }
  ngOnChanges(): void {
    if (
      this._route.snapshot.queryParamMap.get('is_deactivated') !== null &&
      this._route.snapshot.queryParamMap.get('is_deactivated') === 'true'
    ) {
      this.showUserDeactivatedModal = true;
      
    }
  }

  ngOnInit(): void {
    this._initKeycloak();
    // this._initUserData();
  }

  logout(): void {
    this._securityApiService
      .logout()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => (document.location.href = URLS.ic_logout_url));
  }

  hide(): void {
    this.showUserDeactivatedModal = false;
  }

  private _initKeycloak(): void {
    this._keycloakService.init().then(token => {
      if (token) {
        this._securityApiService
          .login(token)
          .pipe(takeUntil(this._destroy$))
          .subscribe(() => this._initUserData());
      }
    });
  }

  private _initUserData(): void {
    this._apiCallService
      .getUser()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        const role = data.user_profile.user_data.role;
        this._userService.saveUserRole(role);
        this._userService.saveLoggedInUser(data);
        this._setLocale(data.user_profile.user_data.language_preference);

        if (data.HasErrors && data.status === '999') {
          this.showUserNotFoundModal = true;
          this.statusText = data.message;
        } else if (data.user_profile.user_data.is_deactivated) {
          this.showUserDeactivatedModal = true;
        }

        this._navigateFurther(data.mfa_required);
      });
  }

  private _navigateFurther(isMfaRequired: boolean): void {
    if (isMfaRequired) {
      this._router.navigateByUrl(ROUTES_PATH.OTP);
      return;
    }

    this._router.navigate([], {
      queryParams: {
        ...this._route.snapshot.queryParams,
        state: null,
        scope: null,
        session_state: null,
        code: null,
      },
    });
  }

  private _setLocale(locale: string): void {
    this._translateService.addLangs([LOCALS.ENGLISH, LOCALS.GERMAN]);
    this._userService.saveLocale(locale);
    this._translateService.setDefaultLang(locale);
    this._translateService.use(locale);
  }
}
