<app-modal width="600" (close)="closeModal()">
  <ng-container title>{{ 'rcs-setup.ms-template-management.upload_ms_template' | translate }}</ng-container>

  <div content class="upload-ms-template">
    <form [formGroup]="msTemplateForm">

      <div class="upload-ms-template__row">
        <mat-label>{{ 'rcs-setup.ms-template-management.category_label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="upload-ms-template__control upload-ms-template__field">
          <mat-select
            formControlName="templateType"
            [placeholder]="'rcs-setup.ms-template-management.category_placeholder' | translate">
            <mat-option *ngFor="let option of templateTypeOptions" [value]="option.value">
              {{ option.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="upload-ms-template__row">
        <mat-label>{{ 'rcs-setup.ms-template-management.document_target_label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="upload-ms-template__control upload-ms-template__field">
          <mat-select
            formControlName="documentTarget"
            [placeholder]="'rcs-setup.ms-template-management.document_target_placeholder' | translate"
            [compareWith]="isDocumentTargetOptionEqual">
            <mat-option *ngFor="let option of documentTargetOptions" [value]="option">
              {{ option.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="upload-ms-template__row">
        <mat-label>{{ 'rcs-setup.ms-template-management.document_type_label' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="upload-ms-template__control upload-ms-template__field">
          <mat-select
            formControlName="documentType"
            [placeholder]="'rcs-setup.ms-template-management.document_type_placeholder' | translate">
            <mat-option *ngFor="let option of documentTypeOptions" [value]="option.value">
              {{ option.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="upload-ms-template__row">
        <mat-label>{{ 'rcs-setup.ms-template-management.description_label' | translate }}</mat-label>
        <textarea
          rows="5"
          class="upload-ms-template__control upload-ms-template__textarea"
          formControlName="description"
          [placeholder]="'rcs-setup.ms-template-management.description_label' | translate">
        </textarea>
      </div>

      <div class="upload-ms-template__row">
        <mat-label>{{ 'rcs-setup.ms-template-management.visibility_label' | translate }}</mat-label>
        <mat-checkbox
          class="upload-ms-template__control upload-ms-template__field custom-checkBox"
          formControlName="isVisible">
        </mat-checkbox>
      </div>

      <div *ngIf="!isEdit" class="upload-ms-template__row input-file__wrapper">
        <mat-label>{{ 'rcs-setup.ms-template-management.upload_document_label' | translate }}</mat-label>
        <div class="upload-ms-template__field">
          <label *ngIf="!msTemplateForm.controls.file.value; else uploadInfo" class="input-file__label" for="file-upload">
            {{ 'rcs-setup.ms-template-management.choose_file_text' | translate }}
          </label>
          <ng-template #uploadInfo>
            <span>{{msTemplateForm.controls.file.value.name | cutString}}</span>
            <button class="input-file__close close" type="button" (click)="removeFile()">
              <mat-icon>close</mat-icon>
            </button>
           </ng-template>
          <input id="file-upload" type="file" (click)="clickFile($event)" (change)="selectFile($event)">
        </div>
      </div>

    </form>
  </div>

  <ng-container actions>
    <app-button secondary (click)="closeModal()">{{ 'confirm_popup.cancel' | translate }}</app-button>
    <app-button (click)="confirm()" [disabled]="msTemplateForm.invalid">
      {{ isEdit
        ? ('rcs-setup.ms-template-management.edit' | translate)
        : ('rcs-setup.ms-template-management.upload' | translate) }}
    </app-button>
  </ng-container>
</app-modal>
