import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableStructure } from '@shared/models/column-details.model';
import { TableConfiguration } from '@shared/models/table.model';
import {
  DeleteConfirmationRequest,
  DeleteConfirmationResponse,
  DeleteServiceRequest,
} from '@shared/interfaces/delete-confirm.interface';
import { TranslateService } from '@ngx-translate/core';
import { MULTILINGUAL_CONSTS } from '../models/contact-person.constants';
import {
  BANK_COLUMNS,
  BANK_DETAILS_ACTION_METHOD_NAMES,
  BD_COLUMN_HEADINGS,
  COLUMN_NAMES,
} from '../models/bank-details.constants';
import {
  COLUMN_TYPES,
  TOOLTIP_PLACEMENT,
} from '@shared/constants/column-types.constants';
import { BankDetailsApiService } from '@core/services/httpcalls/bank-details-api.service';
import {
  BankDetailOverviewResponse,
  BankDetailsTable,
} from '../models/bank-details.models';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { ToastService } from '@core/services/toast.service';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { DeleteService } from '@core/services/shared/delete.service';
import { DateTimeConvertService } from '@core/services/datetime/date-time-convert.service';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { TABLE_MAX_HEIGHT } from '@shared/constants/general/table.constants';

@Component({
  selector: 'bank-details-overview',
  templateUrl: './bank-details-overview.component.html',
  styleUrls: ['./bank-details-overview.component.css'],
})
export class BankDetailsOverviewComponent
  extends BaseComponent
  implements OnInit
{
  @Input() message;
  @Input() insurerId;
  @Input() sourceType;
  @Output() isBankDetailsAddEdit = new EventEmitter<any>();
  @Output() bankDetailsId = new EventEmitter<any>();
  tableStructure: TableStructure;
  tableData: BankDetailsTable[] = [];
  tableConfig: TableConfiguration;
  showConfirmDeletePopup = false;
  sendDeleteReq: DeleteConfirmationRequest;
  is_IC_USER = false;

  constructor(
    private _translateService: TranslateService,
    private _bankAPIService: BankDetailsApiService,
    private _toastService: ToastService,
    private _deleteService: DeleteService,
    private _dateService: DateTimeConvertService,
    private readonly _userService: UserService
  ) {
    super();
    this.prepareTableConfiguration();
    this.tableStructure = this.prepareTableStructure();
  }

  ngOnInit(): void {
    this.is_IC_USER =
      this._userService.getUserRole() === USER_ROLES.IC_USER;
    if (this.message) {
      this._toastService.showToastMessage(document, this.message);
    }
    this.getBankDetailsOverview();
  }

  getBankDetailsOverview() {
    this._bankAPIService
      .getBankDetailsOverview(this.insurerId, this.sourceType)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        result =>
          (this.tableData =
            (result?.bankDetails &&
              this.prepareTableData(
                this.prepareTableRequest(result?.bankDetails)
              )) ||
            [])
      );
  }

  prepareTableConfiguration() {
    this.tableConfig = {
      isFilterSection: false,
      isPagination: false,
      isHeadingAboveFilter: true,
      headingText: this._translateService.instant(
        'customer-management.left-panel.bank-account'
      ),
    };
  }

  toUpperCase(string) {
    return string.toUpperCase();
  }

  prepareTableStructure(): TableStructure {
    const structure: TableStructure = {
      displayColumns: BANK_COLUMNS,
      columnDetails: [
        {
          matColumnDef: COLUMN_NAMES.BANK_NAME,
          colHeading: this.toUpperCase(
            this._translateService.instant(BD_COLUMN_HEADINGS.BANK_NAME)
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.IBAN,
          colHeading: this.toUpperCase(
            this._translateService.instant(BD_COLUMN_HEADINGS.IBAN)
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.BIC,
          colHeading: this.toUpperCase(
            this._translateService.instant(BD_COLUMN_HEADINGS.BIC)
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.VALID_FROM,
          colHeading: this.toUpperCase(
            this._translateService.instant(BD_COLUMN_HEADINGS.VALID_FROM)
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.SEPA_MANDAT,
          colHeading: this.toUpperCase(
            this._translateService.instant(BD_COLUMN_HEADINGS.SEPA_MANDAT)
          ),
          columnType: COLUMN_TYPES.DOCUMENT_CHECKBOX,
        },
        {
          matColumnDef: COLUMN_NAMES.ACTION,
          colHeading: this.toUpperCase(
            this._translateService.instant(BD_COLUMN_HEADINGS.ACTION)
          ),
          columnType: COLUMN_TYPES.ACTION,
        },
      ],
      optionalStyles: {
        maxHeight: TABLE_MAX_HEIGHT,
      },
    };
    return structure;
  }

  prepareTableRequest(
    data: BankDetailOverviewResponse[]
  ): BankDetailOverviewResponse[] {
    const banks: BankDetailOverviewResponse[] = [];
    data.forEach((bankDetails: BankDetailOverviewResponse) => {
      const bank: BankDetailOverviewResponse = {
        bank_account_detail_id: bankDetails.bank_account_detail_id,
        bank_name: bankDetails.bank_name,
        iban: bankDetails.iban,
        bic: bankDetails.bic,
        valid_from: bankDetails.valid_from,
        company_id: bankDetails.company_id,
        is_ivm: bankDetails.is_ivm,
        sepa_mandate: bankDetails.sepa_mandate,
      };
      banks.push(bank);
    });
    return banks;
  }

  prepareTableData(
    bankDetailsData: BankDetailOverviewResponse[]
  ): BankDetailsTable[] {
    const bankDetails: BankDetailsTable[] = bankDetailsData.map(
      (ele: BankDetailOverviewResponse) => {
        const bank: BankDetailsTable = {
          id: ele.bank_account_detail_id,
          bank_name: ele.bank_name,
          iban: ele.iban,
          bic: ele.bic,
          valid_from: this._dateService.convertToDate(ele.valid_from), // ele.valid_from && moment(ele.valid_from)?.format(STATIC_TEXT.DATE_FORMAT) || STATIC_TEXT.EMPTY_STRING,
          sepa_mandate: ele.sepa_mandate,
          action: [
            {
              iconLink: ACTION_BUTTON.EDIT_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                BANK_DETAILS_ACTION_METHOD_NAMES.EDIT_BANK_DETAILS,
              isDisabled: false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.EDIT_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
            {
              iconLink: ACTION_BUTTON.DELETE_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                BANK_DETAILS_ACTION_METHOD_NAMES.DELETE_BANK_DETAILS,
              isDisabled: this.is_IC_USER || false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.DELETE_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
          ],
        };
        return bank;
      }
    );
    return bankDetails;
  }

  callMethod(data: any): void {
    if (data && data.methodName) {
      switch (data.methodName) {
        case BANK_DETAILS_ACTION_METHOD_NAMES.EDIT_BANK_DETAILS:
          this.editBankDetails(data.element);
          break;
        case BANK_DETAILS_ACTION_METHOD_NAMES.DELETE_BANK_DETAILS:
          this.deleteBankDetails(data.element);
          break;
      }
    }
  }

  editBankDetails(bankDetails) {
    this.bankDetailsId.emit(bankDetails.id);
    this.isBankDetailsAddEdit.emit(true);
  }

  deleteBankDetails(bankDetails) {
    if (bankDetails) {
      const config: DeleteServiceRequest = {
        id: bankDetails.id,
      };
      this.showConfirmDeletePopup = true;
      this.sendDeleteReq = this._deleteService.deleteConfig(config);
    }
  }

  deleteBankPostConfirmation(deleteReq: DeleteConfirmationResponse): void {
    this.showConfirmDeletePopup = deleteReq?.showPopup;
    if (deleteReq.isAllowed) {
      this.deleteBank(deleteReq.id);
    }
  }

  deleteBank(bank_details_id: any) {
    this._bankAPIService
      .deleteBankdetailsById(bank_details_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this._toastService.showToastMessage(document, response.message);
        if (!response.HasErrors) {
          this.getBankDetailsOverview();
        }
      });
  }
}
