<app-modal width="600" (close)="closeModal()">
    <!-- TODO: translations -->
    <ng-container title>Create New Task</ng-container>
  
    <div content class="create-task">
      <form [formGroup]="taskForm">
        <div class="create-task__row">
          <mat-label>{{ 'tasks.task_name' | translate }}</mat-label>
          <input
            matInput 
            type="text" 
            class="create-task__control create-task__input" 
            formControlName="title"
            [placeholder]="'tasks.task_name' | translate" />
        </div>
        <div class="create-task__row">
          <mat-label>{{ 'tasks.description' | translate }}</mat-label>
          <textarea
            rows="5"
            class="create-task__control create-task__textarea"
            formControlName="description"
            [placeholder]="'tasks.description' | translate"></textarea>
        </div>
        <div class="create-task__row">
            <mat-label>{{ 'tasks.priority' | translate }}</mat-label>
  
            <mat-form-field appearance="outline" class="create-task__control create-task__field">
              <mat-select
                formControlName="priority"
                [placeholder]="'tasks.select_priority' | translate">
                <mat-select-trigger>
                  <app-priority [priority]="taskForm.get('priority').value"></app-priority>
                </mat-select-trigger>
                <mat-option *ngFor="let option of priorityOptions" [value]="option.value">
                  <app-priority [priority]="option.value"></app-priority>
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="create-task__row">
          <mat-label>{{ 'tasks.due_date' | translate }}</mat-label>
            <mat-form-field appearance="outline" class="create-task__due-date create-task__field create-task__control">
                <input 
                  matInput
                  [matDatepicker]="datepicker"
                  class="date-input"
                  formControlName="dueDate">
                <mat-datepicker-toggle matSuffix [for]="datepicker">
                <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                    keyboard_arrow_down
                </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>
        </div>
        <!-- TODO: uncomment after implemented -->
        <!-- <div class="create-task__row">
          <mat-label>{{ 'tasks.assign_task_to' | translate }}</mat-label>
  
          <mat-form-field appearance="outline" class="create-task__control create-task__field">
            <mat-select
              (selectionChange)="resetSearch()"
              (openedChange)="resetSearch()"
              formControlName="assignees"
              [placeholder]="'tasks.select_assignee' | translate"
              multiple>
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let employee of taskForm.get('assignees')?.value" [value]="employee">
                    <div class="create-task__guest-chip">
                      <app-guest-option [option]="employee"></app-guest-option>
                    </div>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <input 
                type="text" 
                maxlength="20"
                class="searchSelect"
                preventSpace
                [placeholder]="'header.Suche_tooltip' | translate"
                [formControl]="searchControl">
              <mat-option *ngFor="let option of assignedToOptions | filterOptions:searchControl.value" [value]="option">
                <app-guest-option [option]="option"></app-guest-option>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="create-task__row">
          <mat-label></mat-label>
          <mat-checkbox formControlName="isNotify" class="create-task__control custom-checkBox">
            {{ 'tasks.notify' | translate }}
          </mat-checkbox>
        </div> -->
      </form>
    </div>
  
    <ng-container actions>
      <app-button secondary (click)="closeModal()">{{ 'confirm_popup.cancel' | translate }}</app-button>
      <app-button (click)="confirm()">{{ 'confirm_popup.confirm' | translate }}</app-button>
    </ng-container>
  </app-modal>