import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-created-by',
    template: `
        <div class="custom-ellipsis noSafariTooltip">
            {{element.createdBy}}
        </div>
        <div class="table-below-text">{{element.createdAt | dateFormat:'dd.MM.yyyy HH:mm'}}</div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CreatedByComponent {
    @Input() element: any;
}
