import { Component, Input, OnInit } from '@angular/core';
import { ButtonSize } from './button.model';

@Component({
    selector: 'app-button',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.scss']
})

export class ButtonComponent implements OnInit {
    @Input() type = 'button';
    @Input() disabled = false;
    @Input() secondary: boolean;
    @Input() fontSize?: ButtonSize = ButtonSize.LARGE;
    @Input() icon: boolean;

    constructor() { }

    ngOnInit() {
        this.secondary = this.secondary !== undefined;
        this.icon = this.icon !== undefined;
    }
}
