import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CLAIM_ROUTES } from '@shared/constants/router.constants';
import { ClaimAppointmentsPageComponent } from './claim-appointments-page/claim-appointments-page.component';
import { ClaimContactPersonPageComponent } from './claim-contact-person-page/claim-contact-person-page.component';
import { ClaimDocumentsPageComponent } from './claim-documents-page/claim-documents-page.component';
import { DamageDetailsComponent } from './damage-details/damage-details.component';
import { GeneralClaimDataComponent } from './general-claim-data/general-claim-data.component';
import { NewClaimsComponent } from './new-claims.component';
import { ClaimPaymentDetailsPageComponent } from './claim-payment-details-page/claim-payment-details-page.component';

const routes: Routes = [
  {
    path: '',
    component: NewClaimsComponent,
    children: [
      {
        path: CLAIM_ROUTES.GENERAL_DATA,
        component: GeneralClaimDataComponent,
      },
      {
        path: CLAIM_ROUTES.DAMAGE_DETAILS,
        component: DamageDetailsComponent,
      },
      {
        path: CLAIM_ROUTES.PAYMENT_MANAGEMENT,
        component: ClaimPaymentDetailsPageComponent,
      },
      {
        path: CLAIM_ROUTES.DOCUMENT_MANAGEMENT,
        component: ClaimDocumentsPageComponent,
      },
      {
        path: CLAIM_ROUTES.CONTACT_PERSON,
        component: ClaimContactPersonPageComponent,
      },
      {
        path: CLAIM_ROUTES.APPOINTMENTS,
        component: ClaimAppointmentsPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewClaimsRoutingModule {}
