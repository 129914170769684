import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ShareDataService {
  readonly creatingContractInfo$: BehaviorSubject<
    Record<'sparteCode' | 'sparteName' | 'productId' | 'productName', string>
  > = new BehaviorSubject(null);
  private subject = new Subject<any>();
  private subjectObject = new Subject<any>();
  private subjectDiscard = new Subject<any>();

  private _isCollectedGroup: boolean | null = null;
  private _reload: boolean | null = null;

  private _isCustomerManagementEdit: boolean = null;
  get isCustomerManagementEdit(): boolean {
    return this._isCustomerManagementEdit;
  }
  set isCustomerManagementEdit(value: boolean) {
    this._isCustomerManagementEdit = value;
  }

  private _productManagementTouched: boolean = null;
  get productManagementTouched(): boolean {
    return this._productManagementTouched;
  }
  set productManagementTouched(value: boolean) {
    this._productManagementTouched = value;
  }

  private _isInitialProductSave = false;
  get isInitialProductSave(): boolean {
    return this._isInitialProductSave;
  }
  set isInitialProductSave(value: boolean) {
    this._isInitialProductSave = value;
  }

  private _currencySelectedFormGenerator: any = null;
  get currencySelectedFormGenerator(): any {
    return this._currencySelectedFormGenerator;
  }
  set currencySelectedFormGenerator(value: any) {
    this._currencySelectedFormGenerator = value;
  }

  private _messageType: string = null;
  get messageType(): string {
    return this._messageType;
  }
  set messageType(value: string) {
    this._messageType = value;
  }

  private _deType: string = null;
  get deType(): string {
    return this._deType;
  }
  set deType(value: string) {
    this._deType = value;
  }

  private _type: string = null;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
  }

  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  sendProfileClickEvent() {
    this.subject.next();
  }
  getProfileClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  sendCustomerClickEvent() {
    this.subject.next();
  }
  getCustomerClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  getObjectClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  sendObjectClickEvent() {
    this.subject.next();
  }
  getIsObjectDiscarded(): Observable<any> {
    return this.subjectObject.asObservable();
  }
  setIsObjectDiscarded() {
    this.subjectObject.next();
  }
  getIsDiscard(): Observable<any> {
    return this.subjectDiscard.asObservable();
  }
  setIsDiscard() {
    this.subjectDiscard.next();
  }

  saveCollectedGroup(isCollectedGroup: boolean): void {
    this._isCollectedGroup = isCollectedGroup;
  }

  getCollectedGroup(): boolean | null {
    return this._isCollectedGroup;
  }

  saveReloadValue(reload: boolean | null): void {
    this._reload = reload;
  }

  getReloadValue(): boolean | null {
    return this._reload;
  }
}
