import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { UploadTypeConstants } from '@shared/constants/building.constants';
import { Dictionary } from '@shared/models/dictionary.model';
import { GenericCriteriaConstants } from '@shared/constants/generic.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { LOCALS } from '@shared/constants/local.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { ValidationSharedService } from '@core/services/validation-shared.service';
import { ObjectModuleSharedService } from '../services/object-module-shared.service';
import { SelectedDocument } from '@shared/models/upload-data.model';

@Component({
  selector: 'app-photovoltaic',
  templateUrl: './photovoltaic.component.html',
  styleUrls: ['./photovoltaic.component.css'],
})
export class PhotovoltaicComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @ViewChild('fileUpload') fileUpload: any;
  yearControl = new FormControl();
  yearControl1 = new FormControl();
  docDescription = new FormControl();
  photovolaticForm: FormGroup;
  years = [];
  valueTypes: any = [];
  constructionTypes: any = [];
  objectSystem = [];
  validations;
  hasDragOver = false;
  uploader: FileUploader;
  loading = true;
  thumbnail = [];
  element: HTMLCollectionOf<Element>;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  @Input('object_data') object_data;
  @Input('selectedLiztu') selectedLiztu;
  @Input('selectedCompany') selectedCompany;
  uploadObjectDetails: UploadObjectDetails;
  currentYear: number;
  errorNewValue = false;
  formError = 'false';
  filteredOptions;
  tempVal: any;
  actualnumber = 0;
  actualneuValue = 0;
  errorNewValue1 = false;
  yearsCon: any[];
  selectLang: LOCALS;
  encoded_liztu_vn_kdnr: any;
  liztu_vn_kdnr: string;
  ObjectTypes: any;
  object_type_id: any;
  sequence_number: any;
  selectedDocumentsArray: Dictionary<SelectedDocument> = {};
  browser: string;
  tooltipTest: any;
  showDocumentPopup = false;
  NoclickText: boolean;
  currencyTypes: any = [];
  currancySymbol = null;
  currencyType = new FormControl();
  currencyTypetemp: any;
  powerUnit: any;
  installtionLocation: any;
  powerUnitValues: any;
  installtionLocationValues: any;
  powerUnitControl = new FormControl();
  installationLocationControl = new FormControl();
  errorPower = false;
  errorCount = false;
  description: any;
  manufacturer: any;
  model: any;
  model_manufacturer: any;
  inverter_manufacturer: any;
  selectedCurrency: any;
  descDisable = true;
  saveIcon = false;
  desTitle: any;
  documents = [];
  files_array = [];
  public secondaryColour = '#d6d1d170';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  deleteItemId: any;
  documentId: any;
  @ViewChild('manufacturerDD', { static: true })
  manufacturerDropdown: MatSelect;
  @ViewChild('newValueDD', { static: true }) newValueDropdown: MatSelect;
  @ViewChild('currencyDD', { static: true }) currencyDropdown: MatSelect;
  showDocumentAcquisitionPopup: boolean;
  showDocumentRiskPopup: boolean;
  uploadedAcquisitionDocsIds: any[] = [];
  uploadedRiskDocsIds: any[] = [];
  uploadedDocsIds: any[] = [];
  selectedAcquisitionArray: Dictionary<SelectedDocument> = {};
  selectedRiskArray: Dictionary<SelectedDocument> = {};
  allselectedDocumentsArray: Dictionary<SelectedDocument> = {};
  thisYear = new Date().getFullYear();
  lastYear;
  errorPowerNewValue: any;
  errorPowerNewValue1: boolean;
  errorWert: boolean;
  constructor(
    private serviceCall: ApiCallsService,
    private route: ActivatedRoute,
    private _currencyCustomPipe: CurrencyCustomPipe,
    public validation: ValidationService,
    private readonly _userService: UserService,
    private readonly _validationSharedService: ValidationSharedService,
    private readonly _objectModuleSharedService: ObjectModuleSharedService
  ) {
    super();
    this.lastYear = this.thisYear - 1;
    this.uploader = new FileUploader({
      url: '',
      disableMultipart: true,
      autoUpload: true,
    });
    this.browser = this.detectBrowser();
    this.serviceCall
      .getYearsData()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.years = data.factor_years;
          this._objectModuleSharedService.saveYears(this.years);
          if (!this.object_data) {
            this.photovolaticForm.patchValue({
              newValuePerYear: this.years[this.years.length - 1],
            });
          }
        }
      });
    this.serviceCall
      .getCurrencyTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (null !== data.currency_types) {
          this.currencyTypes = data.currency_types;
          this.currencyTypetemp = this.currencyTypes;
          if (
            this.object_data &&
            this.object_data.criteria_response &&
            this.object_data.criteria_response.jsonb
          ) {
            const parsedData = JSON.parse(
              this.object_data.criteria_response.jsonb
            );
            if (parsedData.currency_type_id) {
              this.currancySymbol = this.currencyTypes.find(
                obj => obj.id == parsedData.currency_type_id
              ).cur_code;
              this.selectedCurrency = this.currencyTypes.find(
                obj => obj.id == parsedData.currency_type_id
              ).cur_symbol;
            }
          } else {
            this.currancySymbol = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).cur_code;
            this.selectedCurrency = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).cur_symbol;
            const tempId = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).id;
            this.photovolaticForm.controls.currency_type_id.setValue(tempId);
          }
        }
      });
    this.currencyType.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterCurrency(value);
      });
    this.yearControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filter(value);
      });
    this.yearControl1.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filtercon(value);
      });
    this.powerUnitControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterpowerUnit(value);
      });
    this.installationLocationControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterinstallationLocation(value);
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm) {
      this.onFormReset();
    }
    if (this.photovolaticForm && changes.isSubmitClicked !== undefined) {
      this.photovolaticForm.reset();
    }
    if (this.photovolaticForm && changes.objectType) {
      this.photovolaticForm.patchValue({
        objectType: changes.objectType.currentValue,
      });
      this.browser = this.detectBrowser();
      this.initializeForm();
    }
    if (this.photovolaticForm && changes.selectedCompany) {
      this.photovolaticForm.controls.owner.setValue(
        changes.selectedCompany.currentValue.company_name
      );
    }
  }
  onFormReset() {
    this.selectedDocumentsArray = {};
    this.selectedAcquisitionArray = {};
    this.selectedRiskArray = {};
    this.allselectedDocumentsArray = {};
    this.initializeForm();
  }
  ngOnInit(): void {
    this._watchOnLocale();
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu_vn_kdnr = atob(this.encoded_liztu_vn_kdnr);
    const year = new Date().getFullYear();
    this.currentYear = year;
    this.yearsCon = [];
    this.years = [];
    if (this.selectedLiztu !== undefined) {
      this.CompulsoryAPICalls(this.selectedLiztu);
    } else {
      this.CompulsoryAPICalls(this.liztu_vn_kdnr);
    }
    for (let i = 100; i >= 1; i--) {
      this.yearsCon.push(year - i);
    }
    this.yearsCon.push(year);
    this.yearsCon.push(year + 1);
    this._objectModuleSharedService.saveYearsCon(this.yearsCon);
    this.initializeForm();
    this.browser = this.detectBrowser();
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  private _filterpowerUnit(value: any) {
    this.powerUnit = this.powerUnitValues;
    if (value !== '') {
      if (
        this.powerUnit.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.powerUnit = this.powerUnit.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        );
      }
    }
  }
  private _filterinstallationLocation(value: any) {
    this.installtionLocation = this.installtionLocationValues;
    if (value !== '') {
      if (
        this.installtionLocation.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.installtionLocation = this.installtionLocation.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        );
      }
    }
  }
  _filter(value: string) {
    this.years = this._objectModuleSharedService.getYears();
    if (value !== '') {
      this.years =
        this.years.filter(option => option.toString().includes(value) === 0) !==
        null
          ? this.years.filter(option => option.toString().includes(value))
          : this._objectModuleSharedService.getYears();
    }
  }
  private _filtercon(value: any) {
    this.yearsCon = this._objectModuleSharedService.getYearsCon();
    if (value !== '') {
      this.yearsCon =
        this.yearsCon.filter(
          option => option.toString().includes(value) === 0
        ) !== null
          ? this.yearsCon.filter(option => option.toString().includes(value))
          : this._objectModuleSharedService.getYearsCon();
    }
  }
  yearSelected() {
    this.yearControl.reset();
    this.years = this._objectModuleSharedService.getYears();
    setTimeout(() => this.newValueDropdown.focus(), 150);
  }
  yearSelectedCon() {
    this.yearControl1.reset();
    this.yearsCon = this._objectModuleSharedService.getYearsCon();
    setTimeout(() => this.manufacturerDropdown.focus(), 150);
  }
  CompulsoryAPICalls(liztu) {
    this.serviceCall
      .getObjectType('')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.object_types !== null) {
          this.ObjectTypes = data.object_types;
          if (!this.object_data) {
            for (let i = 0; i < this.ObjectTypes.length; i++) {
              if (this.ObjectTypes[i].object_name == this.objectType) {
                this.object_type_id = this.ObjectTypes[i].object_type_id;

                this.serviceCall
                  .getSequenceNumber(
                    liztu,
                    this.object_type_id,
                    GenericCriteriaConstants[11]
                  )
                  .pipe(takeUntil(this._destroy$))
                  .subscribe(data => {
                    if (data.HasErrors == false) {
                      this.sequence_number = data.sequence_number;
                      this.photovolaticForm.controls.sequenceNumber.setValue(
                        this.sequence_number
                      );
                    }
                  });
              }
            }
          }
          for (let i = 0; i < this.ObjectTypes.length; i++) {
            if (this.ObjectTypes[i].object_name == this.objectType) {
              this.object_type_id = this.ObjectTypes[i].object_type_id;
              this.serviceCall
                .getExtinguishingSystems(this.object_type_id)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  if (!data.HasErrors) {
                    this.objectSystem = data.extinguishing_systems;
                  }
                });
            }
          }
          this.serviceCall
            .getLookupData(8)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              this.powerUnit = data.data;
              this.powerUnitValues = this.powerUnit;
            });
          this.serviceCall
            .getLookupData(9)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              this.installtionLocation = data.data;
              this.installtionLocationValues = this.installtionLocation;
            });
        }
      });
  }
  initializeForm() {
    const formData =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb
        ? JSON.parse(this.object_data.criteria_response.jsonb)
        : '';
    const objectTypeValidation = new FormControl({
      value: this.object_data ? this.object_data.object_name : this.objectType,
      disabled: false,
    });
    const sequenceNumberValidation = new FormControl(
      { value: formData ? formData.sequence_number : '', disabled: false },
      []
    );
    const designation = new FormControl(
      { value: formData ? formData.description : '', disabled: false },
      []
    );
    const manufacturer = new FormControl(
      { value: formData ? formData.manufacturer : '', disabled: false },
      []
    );
    const model = new FormControl(
      { value: formData ? formData.model : '', disabled: false },
      []
    );
    const objectNameValidation = new FormControl({
      value: this.object_data ? this.object_data.object_name : this.objectType,
      disabled: false,
    });
    const power = new FormControl(
      {
        value: formData.power
          ? this._currencyCustomPipe
              .transform(formData.power)
              ?.replace('€', '')
              ?.split(',')[0]
              .trim()
          : null,
        disabled: false,
      },
      []
    );
    const constructionYear = new FormControl(
      { value: formData ? formData.construction_year : '', disabled: false },
      []
    );
    const newValue = new FormControl(
      {
        value: formData.new_value
          ? this._currencyCustomPipe
              .transform(formData.new_value)
              ?.replace('€', '')
              ?.split(',')[0]
              .trim()
          : '',
        disabled: false,
      },
      []
    );
    const newValuePerYear = new FormControl(
      {
        value: formData ? formData.new_value_in_year : this.lastYear,
        disabled: false,
      },
      []
    );
    const number = new FormControl(
      {
        value: formData.count
          ? this._currencyCustomPipe
              .transform(formData.count.toString()?.replaceAll('.', ''))
              ?.replace(',00', '')
              ?.replace('€', '')
              ?.trim()
          : '',
        disabled: false,
      },
      []
    );
    const objectTypeName = new FormControl('photovolatic');
    const modelManufacturer = new FormControl(
      { value: formData.model_manufacturer, disabled: false },
      []
    );
    const inverterManufacturer = new FormControl(
      { value: formData.inverter_manufacturer, disabled: false },
      []
    );
    const powerUnit = new FormControl(
      { value: formData.power_unit_id, disabled: false },
      []
    );
    const eRivision = new FormControl(
      { value: formData.is_erevision, disabled: false },
      []
    );
    const intallationLocation = new FormControl(
      { value: formData.installation_location_id, disabled: false },
      []
    );
    const owner = new FormControl(
      {
        value:
          formData && formData.owner
            ? formData.owner
            : this.selectedCompany
              ? this.selectedCompany.company_name
              : '',
        disabled: true,
      },
      []
    );
    const currencyTypeValidation = new FormControl(
      { value: formData ? formData.currency_type_id : null, disabled: false },
      []
    );
    const doc_id = new FormControl(
      { value: formData ? formData.doc_id : null, disabled: false },
      []
    );
    const acceptanceProtocol = new FormControl('', []);
    const acquisitionInvoice = new FormControl('', []);
    const riskQuestionnaire = new FormControl('', []);
    const wert_371_validation = new FormControl(
      {
        value: formData
          ? this._currencyCustomPipe
              .transform(formData.wert_371)
              ?.replace('€', '')
              ?.split(',')[0]
              .trim()
          : null,
        disabled: false,
      },
      []
    );
    this.photovolaticForm = new FormGroup({
      object_name: objectNameValidation,
      objectTypeName,
      objectType: objectTypeValidation,
      sequenceNumber: sequenceNumberValidation,
      description: designation,
      manufacturer,
      modelManufacturer,
      inverterManufacturer,
      model,
      power,
      powerUnit,
      constructionYear,
      newValue,
      newValuePerYear,
      count: number,
      eRivision,
      intallationLocation,
      owner,
      currency_type_id: currencyTypeValidation,
      doc_id,
      acceptanceProtocol,
      acquisitionInvoice,
      riskQuestionnaire,
      wert_371: wert_371_validation,
    });
    if (this.object_data) {
      this.serviceCall
        .getDocumentPhotovoltik(
          this.object_data.criteria_response.record_id,
          11
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.HasErrors == false) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (document.document_type === UploadTypeConstants.PHOTOVOLTAIK) {
                this.selectedDocumentsArray[document.document_name] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.PHOTOVOLTAIK_ACQUISITION
              ) {
                this.selectedAcquisitionArray[document.document_name] =
                  document;
              }
              if (
                document.document_type === UploadTypeConstants.PHOTOVOLTAIK_RISK
              ) {
                this.selectedRiskArray[document.document_name] = document;
              }
            });
            this.photovolaticForm.controls.acceptanceProtocol.setValue(
              Object.keys(this.selectedDocumentsArray).join(', ')
            );
            this.photovolaticForm.controls.acquisitionInvoice.setValue(
              Object.keys(this.selectedAcquisitionArray).join(', ')
            );
            this.photovolaticForm.controls.riskQuestionnaire.setValue(
              Object.keys(this.selectedRiskArray).join(', ')
            );
          }
        });
    }
    if (formData) {
      this.emitFormValue.emit(this.photovolaticForm);
    }
    this.photovolaticForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.emitFormValue.emit(this.photovolaticForm);
      });
    if (!this.photovolaticForm.touched) {
      this.emitFormValue.emit(this.photovolaticForm);
    }
  }
  scroll() {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById('manufactureSearch').focus();
  }
  scrollNewValue() {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById('newValueSearch').focus();
  }
  onTypeNewValue(event, key) {
    if (key == 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length == 0) {
        if (charCode == '45' || charCode == '48') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        this.errorNewValue = false;
        if (
          charCode != 46 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualneuValue = event.target.value.length;
      } else {
        this.actualneuValue = this.actualneuValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,.]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualneuValue == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  changeNumber(event, name) {
    const count = event.split(',').length - 1;
    if (count > 1) {
      this.errorNewValue1 = true;
    } else {
      this.errorNewValue1 = false;
    }
    const tempValue = event?.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 9) {
      if (name == 'newValue') {
        this.errorNewValue = true;
      } else if (name == 'wert371') {
        this.errorWert = true;
      }
    } else {
      this.errorNewValue = false;
      this.errorWert = false;
    }
    if (this.errorNewValue || this.errorNewValue1 || this.errorWert) {
      this.formError = 'true';
      this._validationSharedService.saveFormError(this.formError);
    } else {
      this.formError = 'false';
      this._validationSharedService.saveFormError(this.formError);
    }
    if (event) {
      if (name == 'newValue') {
        this.photovolaticForm.controls.newValue.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString().replaceAll('.', '').replaceAll(',', '.')
            )
            .replace('€', '')
            ?.split(',')[0]
            ?.trim()
        );
      } else if (name == 'wert371') {
        this.photovolaticForm.controls.wert_371.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString().replaceAll('.', '').replaceAll(',', '.')
            )
            .replace('€', '')
            ?.split(',')[0]
            .trim()
        );
      }
    }
  }
  changePowerNumber(event, name) {
    const count = event.split(',').length - 1;
    if (count > 1) {
      this.errorPowerNewValue1 = true;
    } else {
      this.errorPowerNewValue1 = false;
    }
    const tempValue = event?.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 6) {
      if (name == 'power') {
        this.errorPowerNewValue = true;
      }
    } else {
      this.errorPowerNewValue = false;
    }
    this._validationSharedService.saveFormErrorPower(
      this.errorPowerNewValue || this.errorPowerNewValue1 ? 'true' : 'false'
    );
    this.photovolaticForm.controls.power.setValue(
      this._currencyCustomPipe
        .transform(event.toString()?.replaceAll('.', '')?.replaceAll(',', '.'))
        ?.replace('€', '')
        .trim()
    );
  }
  focusManufacturerSearch() {
    document.getElementById('manufactureSearch').focus();
  }
  focusCurrencySearch() {
    document.getElementById('currencySearch').focus();
  }
  uploadDoc() {
    this.showDocumentPopup = true;
    this.allselectedDocumentsArray = { ...this.selectedDocumentsArray };
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 11,
      documentType: 'Abnahmeprotokoll',
    };
  }
  uploadDoc_Acquisition() {
    this.showDocumentAcquisitionPopup = true;
    this.allselectedDocumentsArray = { ...this.selectedAcquisitionArray };
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 11,
      documentType: 'Anschaffungsrechnung',
    };
  }
  uploadDoc_Risk() {
    this.showDocumentRiskPopup = true;
    this.allselectedDocumentsArray = { ...this.selectedRiskArray };
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 11,
      documentType: 'Risikofragebogen',
    };
  }
  onNoclick() {
    this.NoclickText = true;
  }
  HideNoclick() {
    this.NoclickText = false;
  }
  typePower(event, key, length) {
    this.validation.NumberValidation(event, key, length);
  }
  typeNumberBrowserSpecificPower(event, key, length) {
    this.validation.NumberValidationBrowserSpecific(event, key, length);
  }
  germanNumberFormate(event, name, length) {
    if (name == 'power') {
      this.errorPower = this.validation.germanNumberFormate(
        event,
        name,
        length
      )[0];
      this.photovolaticForm.controls.power.setValue(
        this.validation.germanNumberFormate(event, name, length)[1]
      );
    } else if (name == 'count') {
      this.errorCount = this.validation.germanNumberFormate(
        event,
        name,
        length
      )[0];
      this.photovolaticForm.controls.count.setValue(
        this.validation.germanNumberFormate(event, name, length)[1]
      );
    }
  }
  currancyChanged(event) {
    this.currancySymbol = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_code;
    this.selectedCurrency = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => this.currencyDropdown.focus(), 200);
  }
  private _filterCurrency(value: any) {
    this.currencyTypes = this.currencyTypetemp;
    if (value !== '') {
      if (
        this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.currencyTypes = this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.currencyTypes = this.currencyTypetemp;
      }
    } else {
      this.currencyTypes = this.currencyTypetemp;
    }
  }
  resetList() {
    this.currencyType.setValue('');
  }
  hide(selectedDocumentsArray) {
    if (this.showDocumentPopup) {
      this.showDocumentPopup = false;
      this.selectedDocumentsArray = { ...selectedDocumentsArray };
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploadedDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.photovolaticForm.controls.acceptanceProtocol.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
    }
    if (this.showDocumentAcquisitionPopup) {
      this.showDocumentAcquisitionPopup = false;
      this.selectedAcquisitionArray = { ...selectedDocumentsArray };
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploadedAcquisitionDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.photovolaticForm.controls.acquisitionInvoice.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
    }
    if (this.showDocumentRiskPopup) {
      this.showDocumentRiskPopup = false;
      this.selectedRiskArray = { ...selectedDocumentsArray };
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploadedRiskDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.photovolaticForm.controls.riskQuestionnaire.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
    }
    this.photovolaticForm.controls.doc_id.setValue([
      ...new Set([
        ...this.uploadedDocsIds,
        ...this.uploadedAcquisitionDocsIds,
        ...this.uploadedRiskDocsIds,
      ]),
    ]);
    this.allselectedDocumentsArray = {};
  }

  calculateNewValue(form, event) {
    if (event.target.value) {
      let yearlyFactor;
      const yearF = this.photovolaticForm.controls.newValuePerYear.value;
      this.serviceCall
        .getFactorBasedOnYear(yearF == null ? '' : yearF)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.sum_of_factor !== null) {
            yearlyFactor = data.sum_of_factor;
            if (yearlyFactor != null && yearlyFactor != undefined) {
              this.photovolaticForm.controls.newValue.setValue(
                this.validation.calculateNewValue(form, event, yearlyFactor)
              );
              this.changeNumber(
                this.photovolaticForm.value.newValue,
                'newValue'
              );
            }
          } else {
            this.resetWert();
            this.showToastMessage();
          }
        });
    } else {
      this.resetWert();
    }
  }
  calculateValue(event) {
    if (event.target.value) {
      let yearlyFactor;
      const yearF = this.photovolaticForm.controls.newValuePerYear.value;
      this.serviceCall
        .getFactorBasedOnYear(yearF == null ? '' : yearF)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.sum_of_factor !== null) {
            yearlyFactor = data.sum_of_factor;
            if (yearlyFactor != null && yearlyFactor != undefined) {
              const result: any = this._currencyCustomPipe
                .transform(
                  Math.round(
                    this.validation.calculateWert371(event, yearlyFactor)
                  )
                )
                ?.replace('€', '')
                .trim()
                .split(',')[0];
              this.changeNumber(result, 'wert371');
              this.photovolaticForm.controls.wert_371.setValue(result);
            }
          } else {
            this.resetWert();
            if (event.target.value) {
              this.showToastMessage();
            }
          }
        });
    } else {
      this.resetWert();
    }
  }
  yearChange(event) {
    let yearlyFactor;
    if (this.photovolaticForm.controls.wert_371.value !== '') {
      this.serviceCall
        .getFactorBasedOnYear(event == null ? '' : event)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          yearlyFactor = data.sum_of_factor;
          if (data.sum_of_factor !== null) {
            const calculatedNewValue =
              yearlyFactor *
              Number(
                this.photovolaticForm.controls.wert_371.value
                  .toString()
                  ?.replaceAll('.', '')
                  ?.replaceAll(',', '.')
                  .trim()
              );
            const result = this._currencyCustomPipe
              .transform(Math.round(calculatedNewValue))
              .replace('€', '')
              ?.split(',')[0]
              ?.trim();
            this.photovolaticForm.controls.newValue.setValue(result);
            this.changeNumber(this.photovolaticForm.value.newValue, 'newValue');
          } else {
            this.photovolaticForm.controls.newValue.setValue('');
            this.photovolaticForm.controls.wert_371.setValue('');
            this.showToastMessage();
          }
        });
    }
  }
  showToastMessage() {
    const message =
      this.selectLang === LOCALS.GERMAN
        ? 'Für das gewählte Jahr ist keine Faktorsumme verfügbar, daher werden die Werte zurückgesetzt'
        : 'There is no sum of factor available for the selected year, resetting the values';
    const x = document.getElementById('snackbar');
    x.textContent = message;
    x.className = 'show';
    setTimeout(() => {
      x.className = x.className.replace('show', '');
    }, 3000);
  }
  resetWert() {
    this.photovolaticForm.controls.newValue.setValue('');
    this.photovolaticForm.controls.wert_371.setValue('');
    this.errorNewValue = false;
    this.errorNewValue1 = false;
    this.errorWert = false;
    this.formError = 'false';
    this._validationSharedService.saveFormError(this.formError);
  }

  private _watchOnLocale(): void {
    this._userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(locale => (this.selectLang = locale));
  }
}
