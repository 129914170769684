<!-- class="contact-manager"-->
<div style="padding: 0px 10px">
  <!-- class="d-flex align-items-center justify-content-between" -->
  <div class="space-between" *ngIf="!isICOrCustomerUserRole">
    <div class="flex-grow-1">
      <span *ngIf="showMessage" class="common-bold-text">
        {{errorMessage | multilingual | translate}}
      </span>
    </div>
    <button class="custom-btn save-btn add-new-feature-btn"
      (click)="addNewProcess(searchForm.value)">
      <img [src]="FORM_ICONS.ADD" alt="">
      <span>{{'contact-manager.NEUERVORGANG_text' | translate}}</span>
    </button>
  </div>

  <div class="filter-distance">
      <mat-accordion>
        <mat-expansion-panel 
          class="filter-section" 
          (opened)="filterToggle = true" 
          (closed)="filterToggle = false"
          [expanded]="filterToggle" hideToggle>
            <mat-expansion-panel-header [expandedHeight]="customFilterExpandedHeight"
                class="filter-section-header p-unset">
                <mat-panel-title class="filter-section-title-alignment filter-title">
                  <div class="flex-center g-7">
                    <button class="action-btn">
                      <mat-icon *ngIf="filterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
                      <mat-icon *ngIf="!filterToggle" class="arrowButtons va-bottom">keyboard_arrow_right
                      </mat-icon>
                    </button>
                    <span>
                      <strong>FILTER</strong>
                    </span>
                  </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>
              <form name="search" [formGroup]="searchForm" class="f-13"
                (ngSubmit)="Search(searchForm.value, selectedLiztuVnKdnr)" >
                  <div class="d-flex gap-3 my-2">
                    <div class="vertically-center g-7 width-33">
                      <span class="label-width">{{'contact-manager.Mandant_text' | translate}}</span>
                      <mat-select *ngIf="divisionPreSelected == false" formControlName="mandant"
                        placeholder=" {{'contact-manager.Mandant_placeholder' | translate}}"
                        (selectionChange)="getMandantId($event); search.value = ''"
                        (openedChange)="search.value = ''">
                        <input type="text" maxlength="20" class="searchSelect"
                          placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                        <mat-option
                          *ngFor="let manadant of validationService._filter(division_list, 'division_name', search.value)"
                          [value]='manadant.division_id'>
                          {{manadant.division_name}}
                        </mat-option>
                      </mat-select>
                      <mat-select *ngIf="divisionPreSelected == true" [(ngModel)]="selectedDivisionId"
                        formControlName="mandant" placeholder=" {{'contact-manager.Mandant_placeholder' | translate}}"
                        (selectionChange)="getMandantId($event); search.value = ''"
                        (openedChange)="search.value = ''">
                        <input type="text" maxlength="20" class="searchSelect"
                          placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                        <mat-option
                          *ngFor="let manadant of validationService._filter(division_list, 'division_name', search.value)"
                          [value]='manadant.division_id'>
                          {{manadant.division_name}}
                        </mat-option>
                      </mat-select>
                    </div>
                    <div class="vertically-center g-7 width-33">
                      <span class="label-width"> {{'contact-manager.Empfängername_text' | translate}}</span>
                      <mat-select *ngIf="Actor_PreSelected == false" formControlName="actor"
                        placeholder="{{'contact-manager.Empfängername_placeholder' | translate}}"
                        [disabled]="actor_disable == true" (selectionChange)="saveActor($event)"
                        (openedChange)="search.value = ''">
                        <input [hidden]="actors.length < 6" type="text" maxlength="20" class="searchSelect"
                          placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                        <mat-option title="{{actor.actor_name}}"
                          *ngFor="let actor of validationService._filter(actors, 'actor_name', search.value)"
                          [value]='actor.actor_id'>
                          <span class="custom-ellipsis">{{actor.actor_name}}</span>
                        </mat-option>
                      </mat-select>
                      <mat-select *ngIf="Actor_PreSelected == true" formControlName="actor" [(ngModel)]="selectedActorId"
                        placeholder="{{'contact-manager.Empfängername_placeholder' | translate}}"
                        [disabled]="actor_disable == true" (selectionChange)="saveActor($event)"
                        (openedChange)="search.value = ''">
                        <input [hidden]="actors.length < 6" type="text" maxlength="20" class="searchSelect"
                          placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                        <mat-option *ngFor="let actor of validationService._filter(actors, 'actor_name', search.value)"
                          [value]='actor.actor_id'>
                          {{actor.actor_name}}
                        </mat-option>
                      </mat-select>
                    </div>
                    <div class="vertically-center g-7 width-33">
                      <span class="label-width">{{'contact-manager.VORGÄNGE_text' | translate}}</span>
                      <mat-select formControlName="vorgange"
                        placeholder="{{'contact-manager.Vorgänge_placeholder' | translate}}"
                        (selectionChange)="saveVorgange($event)">
                        <mat-option *ngFor="let process of processes" [value]='process.processes_id'>
                          {{process.operation_name}}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                  <div class="d-flex gap-3 my-2">
                    <div class="vertically-center g-7 width-33">
                      <span class="label-width">{{'contact-manager.Empfängerkreis_text' | translate}}</span>
                      <mat-select *ngIf="Actor_type_PreSelected == false" formControlName="actorType"
                        placeholder="{{'contact-manager.Empfängerkreis_placeholder' | translate}}"
                        (selectionChange)="actor($event)" [disabled]="actor_type_disable ==  true">
                        <mat-option *ngFor="let actorType of actorTypes" [value]='actorType.actorTypeId'>
                          {{actorType.actor_type_name}}
                        </mat-option>
                      </mat-select>
                      <mat-select *ngIf="Actor_type_PreSelected == true" [(ngModel)]="selectedActorTypeId"
                        formControlName="actorType"
                        placeholder="{{'contact-manager.Empfängerkreis_placeholder' | translate}}"
                        (selectionChange)="actor($event)" [disabled]="actor_type_disable ==  true">
                        <mat-option *ngFor="let actorType of actorTypes" [value]='actorType.actorTypeId'>
                          {{actorType.actor_type_name}}
                        </mat-option>
                      </mat-select>
                    </div>
                    <div class="vertically-center g-7 width-33">
                      <span class="label-width"> {{'contact-manager.Tochtergesellschaft_text' | translate}}</span>
                      <mat-select (selectionChange)="saveSubsidiary($event)" formControlName="subsidiary"
                        placeholder="{{'contact-manager.Tochtergesellschaft_placeholder' | translate}}"
                        [disabled]="subsidiary_disable ==  true"
                        (openedChange)="search.value = ''">
                        <input [hidden]="customers.length < 6" type="text" maxlength="20" class="searchSelect"
                          placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                        <mat-option title="{{subsidiaryType.customer_name}}"
                          *ngFor="let subsidiaryType of validationService._filter(customers, 'customer_name', search.value)"
                          [value]='subsidiaryType.liztu_vn_kdnr' [id]="subsidiaryType.liztu_vn_kdnr">
                          <span class="custom-ellipsis">{{subsidiaryType.customer_name}}</span>
                        </mat-option>
                      </mat-select>
                    </div>
                    <div class="vertically-center g-7 width-33">
                      <span class="label-width"> {{'contact-manager.SPARTE_text' | translate}}</span>
                      <mat-select id="vorgange" placeholder="{{'contact-manager.Section_placeholder' | translate}}"
                        formControlName="sparte" (selectionChange)="saveSparte($event);searchSparte.value = ''"
                        (openedChange)="searchSparte.value = ''">
                        <input type="text" maxlength="20" class="searchSelect"
                          placeholder="{{'header.Suche_tooltip' | translate}}" matInput #searchSparte>
                        <mat-option title="{{sparte.description}}"
                          *ngFor="let sparte of validationService._filter(sparte_array, 'description', searchSparte.value)"
                          matTooltip="{{getSparteNamesList(sparte.group_data)}}"
                          [ngClass]="{'redStrip': sparte.is_sparte_group === true}"
                          [value]="sparte.is_sparte_group === true ? sparte.sparte_id : sparte.fixer_code">
                          <span class="custom-ellipsis">{{sparte.description}}</span>
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                  <div class="d-flex gap-3">
                    <div class="f-13 bold-text flex-grow-1">
                      {{message | multilingual | translate}}
                    </div>
                    <div class="flex-center filter-btn-gap">
                      <button type="submit" class="custom-btn save-btn">
                        {{'user-data.Filter_text' | translate}}
                      </button>
                      <button class="custom-btn save-btn" 
                        placement="top"
                        tooltipClass="generic-tooltip"
                        ngbTooltip="{{'new-document.Filter_löschen' | translate}}"
                        (click)="resetFilter()">
                        <img alt="" height="16px" src="assets/images/Icon material-refresh.png" />
                      </button>
                    </div>
                  </div>
              </form>
            </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
  </div>

  <div class="table-outer-container">
      <div class="infiniteScrollTable">
        <mat-table class="custom-table" [dataSource]="dataSource">
          <ng-container matColumnDef="VORGANG">
            <mat-header-cell *matHeaderCellDef>{{'contact-manager.VORGÄNGE_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class=" custom-ellipsis noSafariTooltip" [title]="element.process">{{element.process}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="SPARTE">
            <mat-header-cell *matHeaderCellDef>{{'contact-manager.SPARTE_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class=" custom-ellipsis noSafariTooltip" [title]="element.sparte">{{element.sparte}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="PRODUKT">
            <mat-header-cell *matHeaderCellDef>{{'contact-manager.PRODUKT_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class=" custom-ellipsis noSafariTooltip" [title]="element.product">{{element.product}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="BEREICH">
            <mat-header-cell *matHeaderCellDef>{{'contact-manager.BEREICH_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class=" custom-ellipsis noSafariTooltip" [title]="element.cm_area">{{element.cm_area}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="VON">
            <mat-header-cell *matHeaderCellDef>{{'contact-manager.VON_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="">{{element.from}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="BIS">
            <mat-header-cell style="width: 70px;" *matHeaderCellDef>{{'contact-manager.BIS_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="">{{element.to}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="EXTERN">
            <mat-header-cell style="width: 70px;" *matHeaderCellDef>{{'contact-manager.EXTERN_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="">{{element.extern}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="BEARBEITER">
            <mat-header-cell *matHeaderCellDef>{{'contact-manager.BEARBEITER_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class=" custom-ellipsis noSafariTooltip" [title]="element.editor">{{element.editor}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="EMAIL">
            <mat-header-cell *matHeaderCellDef>EMAIL</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class=" custom-ellipsis noSafariTooltip" [title]="element.email">{{element.email}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="TELEFON">
            <mat-header-cell *matHeaderCellDef>{{'contact-manager.TELEFON_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class=" custom-ellipsis noSafariTooltip" [title]="element.telephone">{{element.telephone}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ACTION">
            <mat-header-cell style="text-align: center;width: 130px;"*matHeaderCellDef>{{'user-data.AKTION_text' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="control-section" style="text-align: center;">
                <div class="subscribe">
                  <button class="hide-action-button myDIV">&#183;&#183;&#183;</button>
                  <div class="hide">
                    <div class="action-btn-align">
                      <button *ngIf="!isICOrCustomerUserRole"
                        class="action-btn"
                        placement="top"
                        tooltipClass="generic-tooltip"
                        ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                        (click)="editProcess(dataSource.data,element.contact_manager_id)">
                          <img alt="" src='assets/images/action/edit.svg'>
                      </button>
                      <button *ngIf="!isICOrCustomerUserRole" 
                        class="action-btn"
                        placement="top"
                        tooltipClass="generic-tooltip"
                        ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                        (click)="deleteProcessButton(element.contact_manager_id)">
                          <img alt="" src="assets/images/action/delete.svg">
                      </button>  
                    </div>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
  </div>
</div>

<div class="modal modelBackground" [style.display]="showModal ? 'table' : 'none'">
  <div class="modal-dialog spacing">
    <div class="modal-content">
      <div class="d-flex header-content p-unset">
          <div class="pointer">
            <img alt="" height="65px" (click)="hideModal()" src="assets/images/Group 3235.svg">
          </div>
      </div>
      <div class="modal-body body-text">
        <span class="delete-heading">{{'contact-manager.Löschen_text' | translate}}</span>
      </div>
      <div class="modal-footer ml-auto mr-auto">
        <button class="custom-btn cancel-btn" (click)="hideModal()">
          {{'contact-manager.NEIN_text' | translate}}
        </button>
        <button class="custom-btn ml-2 save-btn" (click)="deleteProcess(deleteItemId)">
          {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
