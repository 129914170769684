import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';
import {
  DocumentTargetDropdownItem, DropdownItem, GetMsTemplatesParams,
  GetMsTemplatesResponse, MsTemplate, SelectMsTemplateBody, UploadMsTemplateBody
} from '@shared/models/ms-template.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MsTemplateManagementApiService {
  constructor(private _http: HttpClient) {}

  getMsTemplates(requestParams: GetMsTemplatesParams): Observable<GetMsTemplatesResponse> {
    let params = new HttpParams();

    Object.keys(requestParams).forEach(key => {
      params = params.append(key, requestParams[key]);
    });

    return this._http.get<GetMsTemplatesResponse>(`${Api_URL.IC_API_URL}${Api.MS_TEMPLATES}`, { params });
  }

  getMsTemplateById(id: number): Observable<MsTemplate> {
    return this._http.get<MsTemplate>(`${Api_URL.IC_API_URL}${Api.MS_TEMPLATES}/${id}`);
  }

  uploadMsTemplate(body: UploadMsTemplateBody): Observable<string> {
    const formData = new FormData();
    Object.keys(body).forEach(key => formData.append(key, body[key]));
    return this._http.post(`${Api_URL.IC_API_URL}${Api.MS_TEMPLATES}`, formData, { responseType: 'text' });
  }

  updateMsTemplate(id: number, body: UploadMsTemplateBody): Observable<void> {
    return this._http.patch<void>(`${Api_URL.IC_API_URL}${Api.MS_TEMPLATES}/${id}`, body);
  }

  deleteMsTemplate(id: string): Observable<void> {
    return this._http.delete<void>(`${Api_URL.IC_API_URL}${Api.MS_TEMPLATES}/${id}`);
  }

  getMsTemplateTypes(): Observable<DropdownItem[]> {
    return this._http.get<DropdownItem[]>(`${Api_URL.IC_API_URL}${Api.DROPDOWN_VALUES}/template-types`);
  }

  getMsTemplateTargets(): Observable<DocumentTargetDropdownItem[]> {
    return this._http.get<DocumentTargetDropdownItem[]>(`${Api_URL.IC_API_URL}${Api.DROPDOWN_VALUES}/document-targets`);
  }

  selectTemplate(body: SelectMsTemplateBody): Observable<boolean> {
    let params = new HttpParams();

    Object.keys(body).forEach(key => {
      params = params.append(key, body[key]);
    });

    return this._http.patch<boolean>(`${Api_URL.IC_API_URL}${Api.MS_TEMPLATES}/update-visibility`, {}, { params });
  }

  downloadMsTemplate(id: number): Observable<any> {
    return this._http.get(`${Api_URL.IC_API_URL}${Api.MS_TEMPLATES}/download/${id}`, { responseType: 'blob' });
  }

}
