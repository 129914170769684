import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AZURE } from '@shared/constants/azure-service.constants';
import { LocationConstants } from '@shared/constants/location.constants';
import { NewCustomerFormRequest } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
import { Country } from '@shared/interfaces/country.interface';

@Component({
  selector: 'app-basic-location-details',
  templateUrl: './basic-location-details.component.html',
  styleUrls: ['./basic-location-details.component.css'],
})
export class BasicLocationDetailsComponent
  extends BaseComponent
  implements OnInit
{
  basicLocationForm: FormGroup;
  browser: string;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  filterlocation: any = [];
  public keyUp = new Subject<string>();
  showList = false;
  request: { criteria_id: number; jsonb: string };
  countries$: Observable<Country[]>;
  isSelectedAddressChange = true;
  newCustomerFormReq: NewCustomerFormRequest = {};
  selectedAddress: any = null;

  constructor(
    private fb: FormBuilder,
    public validationService: ValidationService,
    private serviceCall: ApiCallsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
    this.browser = this.detectBrowser();
    this.initForm();

    this.countries$ = this.serviceCall.getCountries();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  initForm() {
    this.basicLocationForm = this.fb.group({
      address: [null],
      address_description: [null],
      street: [null],
      house_no: [null],
      postal_code: [null],
      city: [null],
      country: [null],
      lat: [null],
      lon: [null],
      zurs: [null],
    });
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        LocationConstants.BASIC_LOCATION_DETAILS
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          LocationConstants.BASIC_LOCATION_DETAILS
        ].saved_criteria
      );

    if (formData && formData['basic-location-details']) {
      if (!formData['basic-location-details'].zurs) {
        formData['basic-location-details'].zurs = null;
      }
      this.basicLocationForm.setValue(formData['basic-location-details']);
      this.selectedAddress = JSON.stringify(formData['basic-location-details']);

      if (
        formData['basic-location-details'].lat &&
        formData['basic-location-details'].lon
      ) {
        this.isSelectedAddressChange = false;
      }
    }
    this.isFormValueUpdated = false;
    this.basicLocationForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isFormValueUpdated = true;
        this.buildFormObject();
        this.setZursId();
        const pristine = this.basicLocationForm.pristine;
        this.saveCriteriaRequest.emit({
          request: this.request,
          isSave: false,
          pristine,
        });
      });

    merge(
      this.basicLocationForm.get('country').valueChanges,
      this.basicLocationForm.get('city').valueChanges,
      this.basicLocationForm.get('street').valueChanges
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.manualLocation();
      });

    this.basicLocationForm
      .get('postal_code')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.basicLocationForm.controls.postal_code.setValue(
          this.basicLocationForm.controls.postal_code.value
            ? Number(this.basicLocationForm.controls.postal_code.value)
            : null,
          { emitEvent: false }
        );
        this.manualLocation();
      });
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  buildFormObject() {
    const formValuesToPersist = {
      'basic-location-details': this.basicLocationForm.value,
    };
    formValuesToPersist['basic-location-details'].country = formValuesToPersist[
      'basic-location-details'
    ].country
      ? formValuesToPersist['basic-location-details'].country
      : null;
    this.request = {
      criteria_id: LocationConstants.BASIC_LOCATION_DETAILS,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  autoComplete(value) {
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          this.filterAddressFromResults(res.results);
          this.showList = true;
        });
    } else {
      this.isSelectedAddressChange = true;
    }
  }

  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      obj.address.lat = obj.position.lat;
      obj.address.lon = obj.position.lon;
      return obj.address;
    });
  }

  onFocusOut(event) {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  selectedLocation(location) {
    this.basicLocationForm.patchValue({
      address: location.freeformAddress,
      country: location.country,
      city: location.localName ? location.localName : null,
      postal_code: location.postalCode ? Number(location.postalCode) : null,
      house_no: location.streetNumber ? location.streetNumber : null,
      street: location.streetName ? location.streetName : null,
      lat: location.lat,
      lon: location.lon,
    });
    this.isSelectedAddressChange = false;
  }

  manualLocation() {
    this.isSelectedAddressChange = true;
    this.basicLocationForm.controls.lat.setValue(null);
    this.basicLocationForm.controls.lon.setValue(null);
  }
  scrollValue(key) {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById(key).focus();
  }

  // zurs related changes added

  ngOnDestroy() {
    if (this.isFormValueUpdated && this.basicLocationForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }

  setZursAddress(event) {
    this.newCustomerFormReq.zursAddress = event.address;
    this.newCustomerFormReq = Object.assign({}, this.newCustomerFormReq);
    this.setZursId();
  }

  setZursId() {
    const zursResp =
      this.newCustomerFormReq && this.newCustomerFormReq.zursAddress;
    if (zursResp) {
      const zursArray = zursResp.split('|') || [];
      if (zursArray.length > 0 && zursArray[0]) {
        const zurseId = zursArray[0];
        if (!this.request || !this.request.jsonb) {
          this.buildFormObject();
        }

        if (this.request.jsonb) {
          const formValuesToPersist = JSON.parse(this.request.jsonb);
          formValuesToPersist['basic-location-details'].zurs = Number(zurseId);
          this.request.jsonb = JSON.stringify(formValuesToPersist);
          const pristine = this.basicLocationForm.pristine;
          this.saveCriteriaRequest.emit({
            request: this.request,
            isSave: false,
            pristine,
          });
        }
      }
    }
  }
}
