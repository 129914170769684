import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { ShareDataService } from '@core/services/share-data.service';

@Component({
  selector: 'app-add-new-contract-dialog',
  templateUrl: './add-new-contract-dialog.component.html',
  styleUrls: ['./add-new-contract-dialog.component.scss'],
})
export class AddNewContractDialogComponent
  extends BaseComponent
  implements OnInit
{
  division_id: any;
  spartes: any[];
  selectedSparteId: any;
  selectedSparteFixerCode: any;
  selectedSparteName: any;
  selected_product: any;
  selected_product_name: any;
  avaliable_products = [];

  constructor(
    private serviceCall: ApiCallsService,
    public validationService: ValidationService,
    public dialogRef: MatDialogRef<AddNewContractDialogComponent>,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _shareDataService: ShareDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.division_id =
      +this._customerSharedService.getSelectedCustomerDivisionId();
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.serviceCall
      .getSparte(this.division_id, null, this._userService.getUserRole())
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.sparte.length) {
          this.spartes = data.sparte.filter(
            object => object.is_sparte_group == false
          );
          const indexOfAlle = this.spartes.findIndex(
            data => data.fixer_code == 0
          );
          this.spartes.splice(indexOfAlle, 1);
        }
      });
  }
  focusDropdown(key: string): void {
    document.getElementById(key)?.focus();
  }
  sparteChange(event): void {
    this.selectedSparteId = event.value.sparte_id;
    this.selectedSparteName = event.value.description;
    this.selectedSparteFixerCode = event.value.fixer_code;
    if (this.selectedSparteFixerCode) {
      this.serviceCall
        .getAvailableProducts(this.selectedSparteFixerCode, this.division_id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.selected_product = '';
          this.selected_product_name = '';
          this.avaliable_products = data.data;
        });
    }
  }
  selectedProduct(event): void {
    this.selected_product = event.value.id;
    this.selected_product_name = event.value.desc;
  }
  hidePopup(): void {
    this.dialogRef.close();
  }
  getContractDetails(): void {
    this._shareDataService.creatingContractInfo$.next({
      sparteCode: this.selectedSparteFixerCode,
      sparteName: this.selectedSparteName,
      productId: this.selected_product,
      productName: this.selected_product_name,
    });
    this.dialogRef.close({
      sparteId: this.selectedSparteId,
      sparteName: this.selectedSparteName,
      sparteFixerCode: this.selectedSparteFixerCode,
      productId: this.selected_product,
      productName: this.selected_product_name,
    });
  }
  isContinueDisabled(): boolean {
    return this.selected_product && this.selectedSparteId;
  }
}
