<div>
  <span class="criteria-heading">{{'objectModule.recycling_object.recycling' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="recyclingForm" id="recyclingForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.recycling_object.type_of_recycling' |
      translate}}</label>
    <input type="text" id="type_of_recycling" class="input" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.recycling_object.type_of_recycling' | translate}}" matInput
      formControlName="type_of_recycling" title="{{recyclingForm.value.type_of_recycling}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.recycling_object.goods' |
      translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="goods"  (click)="scrollValue('searchSelectGoods')" (keyup.enter)="scrollValue('searchSelectGoods')"
    (openedChange)="searchGoods.value = ''" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="goods" multiple>
      <input type="text" id="searchSelectGoods" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" preventSpace matInput #searchGoods>
      <mat-option [hidden]="goods.hide" [value]="goods.id" *ngFor="let goods of validationService._filterMultiSelect(goodsList, 'desc', searchGoods.value)"
      [ngClass]="{'active-option': goods.id === recyclingForm.value.goods && !recyclingForm.value.goods}">{{goods.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.recycling_object.procedure' |
      translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="procedure" (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')"
      (openedChange)="search.value = ''" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="procedure" multiple>
      <input [hidden]="procedureList.length <= 6" type="text" maxlength="20" id="searchSelect" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" preventSpace matInput #search>
      <mat-option [hidden]="procedure.hide" [value]="procedure.id" *ngFor="let procedure of validationService._filterMultiSelect(procedureList, 'desc', search.value)"
      [ngClass]="{'active-option': procedure.id === recyclingForm.value.procedure && !recyclingForm.value.procedure}">{{procedure.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_fireproof_partition" class="long-label">{{'objectModule.recycling_object.is_fireproof_partition'
      | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_fireproof_partition" id="is_fireproof_partition">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.recycling_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.recycling_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_magnetic_separator" class="long-label">{{'objectModule.recycling_object.is_magnetic_separator'
      | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_magnetic_separator" id="is_magnetic_separator">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.recycling_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.recycling_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_pneumatic_conveyor_line"
      class="long-label">{{'objectModule.recycling_object.is_pneumatic_conveyor_line'
      | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_pneumatic_conveyor_line" id="is_pneumatic_conveyor_line">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.recycling_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.recycling_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
