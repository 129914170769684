<div class="customer-details">
    <h6 class="customer-management-page-heading">
        {{'customer-management.customer_relations.customer_relations' | translate}}
    </h6>
    <div class="filter-distance">
        <mat-accordion>
            <mat-expansion-panel class="filter-section" (opened)="customerRelationFilterToggle = true"
                (closed)="customerRelationFilterToggle = false" [expanded]="customerRelationFilterToggle" hideToggle>
                <mat-expansion-panel-header #panelH [expandedHeight]="customFilterExpandedHeight"
                    class="filter-section-header">
                    <mat-panel-title class="filter-section-title-alignment">
                        <div class="flex-center">
                            <button mat-icon-button *ngIf="customerRelationFilterToggle == true">
                                <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="customerRelationFilterToggle == false">
                                <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                            </button>
                            <span style="margin-top: 3px;">
                                <span> &nbsp; <strong>FILTER </strong></span>
                            </span>
                        </div>
                        <div class="paginator-dropdown-container" (click)="$event.stopPropagation()">
                            <mat-select (selectionChange)="numberOfRowsChanged($event)" panelClass="rowChange"
                                class="paginator-dropdown filter-section-dropdown" [(ngModel)]="selectedCountOfRows"
                                disableOptionCentering>
                                <mat-option *ngFor="let row of numberOfRows" [value]="row">
                                    {{row}}
                                </mat-option>
                            </mat-select>
                            <span class="results-per-page">
                                {{'claims.results_per_page' | translate}}
                            </span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container>
                    <div name="search" style="font-size: 13px;">
                        <div class="row">
                            <div class="col-md-3 col-sm-3">
                                <mat-select
                                    *ngIf="locale$ | async as locale"
                                    [(ngModel)]="selected_filter_value" #RelationControl
                                    (openedChange)="search.value = ''" (click)="focusSearch('searchRelation')"
                                    (keyup.enter)="focusSearch('searchRelation')"
                                    (selectionChange)="relationValueChanged()"
                                    placeholder="{{'customer-management.customer_relations.select_relationship' | translate}}">
                                    <input type="text" maxlength="20" class="searchSelect" preventSpace
                                        placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                        id="searchRelation" #search>
                                    <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}
                                    </mat-option>
                                    <mat-option [value]="customer_relation.relationshipId"
                                        *ngFor="let customer_relation of validationService._filter(customer_relations, selectField , search.value)">
                                        <span
                                            title="{{locale === 'English(EN)' ? customer_relation.nameEn : customer_relation.name}}">
                                                {{locale === 'English(EN)' ? customer_relation.nameEn1 : customer_relation.name1}}
                                        </span>
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="col-md-6 col-sm-6"></div>
                            <div class="col-md-3 flex-center">
                                <button class="filterBtn" style="margin-left: auto;margin-right: 8px;"
                                    (click)="filterCustomerRelations()">
                                    {{'user-data.Filter_text' | translate}}
                                </button>
                                <button class="filterBtn" (click)="resetFilters()" placement="top"
                                    ngbTooltip=" {{'new-document.Filter_löschen' |translate}}"
                                    tooltipClass="generic-tooltip">
                                    <img alt="" src="../../../assets/images/Icon material-refresh.png"
                                        style="height: 16px;">
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<div class="table-outer-container insured-object-section">
    <div class="infiniteScrollDiv">
        <mat-table class="custom-table" [dataSource]="dataSource" cellpadding="5" cellspacing="0" matSort>
            <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef>
                    <span>{{'user-data.customer_name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="display: flex; align-items:baseline;">
                    <span class="pill fromIvm" *ngIf="element.is_source_ivm"
                        style="padding: 0px 10px;margin-right: 5px;">ERP</span>
                    <span class="pill fromRcs" *ngIf="!element.is_source_ivm">ICP</span>
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.customer_name}}"
                        style="margin-top: 4px;">
                        {{element.customer_name}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="branch_name">
                <mat-header-cell *matHeaderCellDef>
                    <span>{{'customer-management.customer_relations.branch_name' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.branch_name}}">
                        {{element.branch_name}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="relationship_of_company">
                <mat-header-cell *matHeaderCellDef>
                    <span>{{'customer-management.customer_relations.relationship_of_company' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.relationship}}">
                        {{element.relationship}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="customer_number">
                <mat-header-cell *matHeaderCellDef style="width: 13%;">
                    <span>{{'customer-management.customer_relations.customer_number' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.customer_number}}">
                        {{element.customer_number}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="city">
                <mat-header-cell *matHeaderCellDef style="width: 8%;">
                    <span>{{'customer-management.customer_relations.city' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.city}}">
                        {{element.city}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="country">
                <mat-header-cell *matHeaderCellDef style="width: 10%;">
                    <span>{{'customer-management.customer_relations.country' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.country}}">
                        {{element.country}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef style="padding-left: 29px; width: 130px;">
                    <span>{{'customer-management.bank_details.action' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="control-section" style="text-align: center;">
                        <div class="subscribe">
                            <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                            <div class="hide">
                                <div class="action-btn-align">
                                    <button
                                      class="action-btn"
                                      (click)="editCustomerRelation(element)"
                                      placement="top"
                                      ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                                      tooltipClass="generic-tooltip"
                                      [disabled]="!(editCustomer$ | async)"
                                    >
                                        <img alt="" [src]="actionButton.EDIT_WO_BACKGROUND">
                                    </button>
                                    <button
                                      class="action-btn"
                                      placement="top"
                                      ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                                      tooltipClass="generic-tooltip"
                                      [disabled]="!(editCustomer$ | async)"
                                      (click)="openModal(element.id, element.customer_id)"
                                    >
                                        <img alt="" [src]="actionButton.DELETE_WO_BACKGROUND">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
    <div class="row table-footer">
        <div style="display: flex;justify-content: center;align-items: center;position: relative;">
            <div id="customer-relations">
                <ngb-pagination [collectionSize]="total_elements" (pageChange)="tablePageChanged($event)" [maxSize]=5
                    [(page)]="tablePage" [pageSize]="selectedCountOfRows" [boundaryLinks]="false">
                    <ng-template ngbPaginationPrevious>
                        <i class="fa fa-angle-left"></i>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <i class="fa fa-angle-right"></i>
                    </ng-template>
                </ngb-pagination>
            </div>
            <div class="footer-text">
                <span>
                    {{'customer-management.customer_relations.number_of_customer_relations' | translate}} : {{
                    total_elements }}
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal modelBackground" [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog spacing">
        <div class="modal-content p-3">
                <div class="d-flex header-content p-unset">
                    <div class="pointer">
                        <img alt="" (click)="hideModal()" height="65px" src="assets/images/Group 3235.svg">
                    </div>
                </div>
                <div class="modal-body body-text">
                    <span class="delete-heading">{{'objectModule.Delete_popup_heading' | translate}}</span>
                    <br /><br />
                    <span class="delete-text">{{'objectModule.Delete_popup_text' | translate}}</span>
                </div>
                <div class="modal-footer ml-auto mr-auto">
                    <button (click)="hideModal()" class="custom-btn cancel-btn">
                        {{'contact-manager.NEIN_text' | translate}}
                    </button>
                    <button class="ml-2 custom-btn save-btn" (click)="delete(delete_relation_id, relative_customer_id)">
                        {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}
                    </button>
                </div>
        </div>
    </div>
</div>
