import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ComponentCanDeactivate } from '@shared/models/component-can-deactivate.model';
import { ShareDataService } from '@core/services/share-data.service';
import { ToastService } from '@core/services/toast.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { URLS } from '@shared/constants/url.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { UserService } from '@core/user.service';
import { AZURE } from '@shared/constants/azure-service.constants';
import { ConfirmedValidator } from '../confirmed.validator';
import { Country } from '@shared/interfaces/country.interface';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil, tap } from 'rxjs/operators';
import { filterNil } from '@shared/utils/filter-nil.pipe';

import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@core/services/httpcalls/auth.service';

export interface SendingElement {
  currentPassword: string;
  newPassword: string;
  confirmation: string;
}
export interface UpdateElement {
  first_name: string;
  last_name: string;
  mobile_number: string;
  city: string;
  email: string;
  country: string;
  language: string;
  zip_code: string;
  house: string;
  street: string;
  salutation: string;
  user_id: string;
  lat: any;
  lon: any;
}
export interface SendElement {
  first_name: string;
  last_name: string;
  mobile_number: string;
  city: string;
  email: string;
  country: string;
  language: string;
  zip_code: string;
  house: string;
  street: string;
  salutation_id: number;
  user_id: number;
  role: USER_ROLES;
}
export interface SendElementFromConstr {
  first_name: string;
  last_name: string;
  mobile_number: string;
  city: string;
  email: string;
  country: string;
  language: string;
  zip_code: string;
  house: string;
  street: string;
  salutation_id: number;
  salutation: string;
  user_id: string;
  role: string;
}
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent
  extends BaseComponent
  implements ComponentCanDeactivate
{
  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService,
    public translate: TranslateService,
    private userService: UserService,
    private modalService: NgbModal,
    private shareservice: ShareDataService,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    private readonly _securityApiService: AuthService,
    private readonly _userService: UserService
  ) {
    super();

    this.clickEventsubscription = this.shareservice
      .getProfileClickEvent()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (
          this.user_data.saluation == 'Frau' ||
          this.user_data.saluation == 'Mrs'
        ) {
          this.salutation_id = 1;
        } else if (
          this.user_data.saluation == 'Herr' ||
          this.user_data.saluation == 'Mr'
        ) {
          this.salutation_id = 2;
        }
      });
    if (this.url == '') {
      this.editmode = true;
    }
    this.formControl = this.fb.group({
      salutation: ['', [Validators.required]],
      first_name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[A-Z@~`!@#$%^&*()_=+\\\\"\\/?>.<,-äöüäuÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ]*$'
          ),
        ],
      ],
      last_name: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[A-Z@~`!@#$%^&*()_=+\\\\"\\/?>.<,-äöüäuÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ]*$'
          ),
        ],
      ],
      zip_code: ['', [Validators.required]],
      city: ['', [Validators.required]],
      email: ['', [Validators.required]],
      country: [''],
      language: ['', [Validators.required]],
      mobile_number: [''],
      house: ['', [Validators.required]],
      street: ['', [Validators.required]],
    });
    this.formControl2 = this.fb.group(
      {
        current: ['', [Validators.required]],
        new: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$'
            ),
          ],
        ],
        confirm: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('new', 'confirm'),
      }
    );
  }
  get f() {
    return this.formControl2.controls;
  }
  get form1() {
    return this.formControl.controls;
  }
  get fis() {
    return this.formControl.controls;
  }
  updateprofileData;
  finalLang: any;
  userDataValue: any;
  confirmTextType: boolean;
  newTextType: boolean;
  currentTextType: boolean;
  readonly selectLang$ = this._userService.locale$;
  salutationId: any;
  salId: any;
  salutationUserData: string;
  customerName: string;
  interval: NodeJS.Timeout;
  apiCall: Subscription;

  isDirtyProfile = false;
  Alllocations: any;
  filterlocation;
  selectedaddress: any;
  snack2 = false;
  snack3 = false;
  current;
  new;
  confirm;
  resetForm = false;
  user_id;
  userid;
  user_role: USER_ROLES;
  user_data;
  salutation_id;
  salutation_disable = true;
  formControl: FormGroup;
  formControl2: FormGroup;
  disable = false;
  message2;
  postalCode;
  country;
  language;
  user_image = '';
  is_customer = true;
  public editEnabled = true;
  public picurl: string;
  isICUser: boolean;
  showActive = false;
  clickEventsubscription: Subscription;
  uploader: FileUploader;
  hasDragOver = false;
  disableCustomer = false;
  public editmode = false;
  url;
  supportedLanguages = ['German(DE)', 'English(EN)'];
  urlChange;
  @ViewChild('phone') public phone;
  @ViewChild('profile', { read: ElementRef, static: false }) eRef: ElementRef;
  @ViewChild('language', { read: ElementRef, static: false }) eRef1: ElementRef;
  isCustomerSelected = false;
  pageBreadcrumbText = 'settings.EINSTELLUNGEN_text';
  pageBreadcrumbSubText = 'user-profile.PersönlicheDaten_text';
  readonly countries$ = this.serviceCall
    .getCountries()
    .pipe(tap((data: Country[]) => (this._countries = data)));
  private _countries: Country[] = [];
  canDeactivate(): boolean {
    return !this.isDirtyProfile;
  }
  public clear() {
    this.picurl = '';
  }
  onCountrySelected(event) {
    const selected =
      event.currentTarget.options[event.currentTarget.selectedIndex].text;
    this.formControl.get('zip_code').clearValidators();
    this.formControl.get('zip_code').updateValueAndValidity();
    for (let j = 0; j < this._countries.length; j++) {
      if (selected === this._countries[j].countryName) {
        this.formControl
          .get('zip_code')
          .setValidators([
            Validators.required,
            Validators.pattern(this._countries[j].postalCodeRegex),
          ]);
        this.formControl.get('zip_code').updateValueAndValidity();
      }
    }
    this.country = event.name;
  }

  autoComplete(value): void {
    this.Alllocations = [];
    this.filterlocation = [];
    this.showActive = false;
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          this.Alllocations = res.results;
          this.filterAddressFromResults(this.Alllocations);
        });
    }
  }
  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
  }
  selectedLocation(e) {
    const val = e.target.value;
    this.selectedaddress = this.filterlocation.filter(obj => {
      return obj.freeformAddress == val;
    });

    if (this.selectedaddress.length == 1) {
      if (this.selectedaddress[0].streetNumber !== undefined) {
        this.user_data.house = this.selectedaddress[0].streetNumber;
      } else {
        this.user_data.house = '';
      }
      if (this.selectedaddress[0].streetName !== undefined) {
        this.user_data.street = this.selectedaddress[0].streetName;
      } else {
        this.user_data.street = '';
      }
      if (this.selectedaddress[0].postalCode !== undefined) {
        this.user_data.zip_code = this.selectedaddress[0].postalCode;
      } else {
        this.user_data.zip_code = '';
      }
      if (this.selectedaddress[0].localName !== undefined) {
        this.user_data.city = this.selectedaddress[0].localName;
      } else {
        this.user_data.city = '';
      }
      if (this.selectedaddress[0].country !== undefined) {
        this.user_data.country = this.selectedaddress[0].country;
      } else {
        this.user_data.country = '';
      }
      for (let i = 0; i < this.Alllocations.length; i++) {
        if (this.Alllocations[i].address.freeformAddress == val) {
          if (this.Alllocations[i].position.lat !== undefined) {
            this.user_data.lat = this.Alllocations[i].position.lat;
          } else {
            this.user_data.lat = '';
          }
          if (this.Alllocations[i].position.lon !== undefined) {
            this.user_data.lon = this.Alllocations[i].position.lon;
          } else {
            this.user_data.lon = '';
          }
        }
      }
      if (this.user_data.lat !== '' && this.user_data.lon !== '') {
        this.showActive = true;
      } else {
        this.showActive = false;
      }
    }
  }

  public fileOver(e: any): void {
    this.hasDragOver = e;
  }

  ngOnInit(): void {
    this._watchOnUserRole();
  }

  userDataReceived(data) {
    this.userDataValue = data;
    this.customerName = data.user_profile.customer_details.name;
    this.serviceCall.getUserProfilePhoto().subscribe(data => {
      const objectURL = window.URL.createObjectURL(data);
      this.url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    });
    this.loadUserData();
  }

  public ngAfterViewChecked() {
    try {
      this.phone.elRef.nativeElement.firstChild.children[0].disabled = 'true';
    } catch (e) {}
  }
  currentPsw() {
    this.currentTextType = !this.currentTextType;
  }
  newPsw() {
    this.newTextType = !this.newTextType;
  }
  confirmPsw() {
    this.confirmTextType = !this.confirmTextType;
  }
  onSelectFile(event) {
    this.urlChange = event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!this.urlChange.type.match(pattern)) {
      return;
    }
    this.url = this.urlChange.name.split('.').pop();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(this.urlChange);
    this.save(this.urlChange);
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    this.url = reader.result;
  }
  loadUserData() {
    this.salutation_id =
      this.userDataValue.user_profile.user_data.salutation_id;
    if (this.salutation_id == null) {
      this.salutation_disable = false;
    }
    this.userid = this.userDataValue.user_profile.user_data.user_id;
    // this.url = this.userDataValue.user_profile.user_data.profile_image_url;
    if (this.salutation_id == 1) {
      if (
        this.userDataValue.user_profile.user_data.language_preference ==
        'German(DE)'
      ) {
        this.salutationUserData = 'Frau';
      } else if (
        this.userDataValue.user_profile.user_data.language_preference ==
        'English(EN)'
      ) {
        this.salutationUserData = 'Mrs';
      }
    } else if (this.salutation_id == 2) {
      if (
        this.userDataValue.user_profile.user_data.language_preference ==
        'German(DE)'
      ) {
        this.salutationUserData = 'Herr';
      } else if (
        this.userDataValue.user_profile.user_data.language_preference ==
        'English(EN)'
      ) {
        this.salutationUserData = 'Mr';
      }
    }
    const user_data: UpdateElement = {
      salutation: this.salutationUserData,
      first_name: this.userDataValue.user_profile.user_data.first_name,
      last_name: this.userDataValue.user_profile.user_data.last_name,
      mobile_number: this.userDataValue.user_profile.user_data.mobile_number,
      city: this.userDataValue.user_profile.user_data.city,
      email: this.userDataValue.user_profile.user_data.email_id,
      country: this.countrySet(
        this.userDataValue.user_profile.user_data.country
      ),
      language: this.userDataValue.user_profile.user_data.language_preference,
      zip_code: this.userDataValue.user_profile.user_data.zip_code,
      house: this.userDataValue.user_profile.user_data.house,
      street: this.userDataValue.user_profile.user_data.street,
      user_id: this.userDataValue.user_profile.user_data.user_id,
      lat: this.userDataValue.user_profile.user_data.lat,
      lon: this.userDataValue.user_profile.user_data.lon,
    };
    this.user_data = user_data;
  }
  countrySet(country) {
    if (null == country || country == '') {
      return null;
    }
    const countryReturn = this._countries.find(
      obj => obj.countryName == country || obj.countryCode == country
    );
    if (undefined == countryReturn || null == countryReturn) {
      return null;
    }
    return countryReturn.countryName;
  }
  save(picture) {
    this.user_id = this.userDataValue.user_profile.user_data.user_id;
    const formData = new FormData();
    formData.append('file', picture);
    formData.append('userId', this.user_id);
    formData.append('is_customer', JSON.stringify(this.is_customer));
    this.serviceCall
      .userImage(formData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.HasErrors == false) {
          this.toastService.showToastMessage(
            document,
            this.translate.instant('user-profile.Profilbild_info')
          );
        }
      });
  }

  setTransLanguage(item) {
    this.finalLang = item.value;
    this.isDirtyProfile = true;
  }

  updateProfile(e) {
    this.updateprofileData = e;
    this.isDirtyProfile = false;
    this.translate.use(this.finalLang);
    if (e.salutation == 'Frau' || e.salutation == 'Mrs') {
      e.salutation_id = 1;
    } else if (e.salutation == 'Herr' || e.salutation == 'Mr') {
      e.salutation_id = 2;
    }
    const formdata: SendElement = {
      salutation_id: e.salutation_id,
      first_name: e.first_name,
      last_name: e.last_name,
      mobile_number: e.mobile_number,
      city: e.city,
      email: e.email,
      country: e.country,
      language: this.finalLang,
      zip_code: e.zip_code,
      house: e.house,
      street: e.street,
      user_id: this.userid,
      role: this.user_role,
    };
    this.userService.saveLocale(this.finalLang);
    this.serviceCall
      .updateProfile(formdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.HasErrors == false) {
          const userData = this._userService.getLoggedInUser();
          userData.user_profile.user_data = {
            ...userData.user_profile.user_data,
            ...formdata,
            language_preference: this.finalLang,
          };
          this._userService.saveLoggedInUser(userData);

          this.toastService.showToastMessage(
            document,
            this.translate.instant('user-profile.Nutzerprofil_info')
          );
        }
      });
  }

  updatePassword(e) {
    this.formControl2.reset();
    const data: SendingElement = {
      currentPassword: e.current,
      newPassword: e.new,
      confirmation: e.confirm,
    };
    this.serviceCall
      .resetPassword(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          this.snack3 = false;
          this.snack2 = true;
          if (!data.HasErrors) {
            this._securityApiService
              .logout()
              .pipe(takeUntil(this._destroy$))
              .subscribe(
                () => (document.location.href = URLS.ic_logout_url)
              );
          }
        },
        () => {
          this.snack3 = true;
          this.snack2 = false;
        }
      );
  }

  resetPassword(content) {
    this.resetForm = true;
    this.modalService.open(content, { centered: true, size: 'sm' });
  }

  clearvalidation() {
    this.formControl2.reset();
    this.snack2 = false;
    this.snack3 = false;
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._updateConsiderRole();
      });
  }

  private _updateConsiderRole(): void {
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
    if (this.isICUser) {
      this.is_customer = false;
    }
    if (this.isICUser) {
      this.disable = true;
      this.disableCustomer = true;
    }
    if (ROLES.CUSTOMER_ROLES.includes(this.user_role)) {
      this.disableCustomer = true;
    }
  }
}
