import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';

@Component({
  selector: 'app-clean-rooms',
  templateUrl: './clean-rooms.component.html',
  styleUrls: [
    './clean-rooms.component.css',
    '../../new-object-module.component.css',
  ],
})
export class CleanRoomsComponent implements OnInit {
  CleanRoomForm: FormGroup;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;

  isFormValueUpdated: boolean;
  request: BuildingObject;

  constructor(private serviceCall: ApiCallsService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[BuildingConstants.CLEAN_ROOMS] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.CLEAN_ROOMS]
          .saved_criteria
      );

    this.CleanRoomForm = new FormGroup({
      is_smoke_tight_separation: new FormControl(
        {
          value: formData
            ? formData.clean_rooms.is_smoke_tight_separation
            : null,
          disabled: false,
        },
        []
      ),
      is_fire_alarm_system: new FormControl(
        {
          value: formData ? formData.clean_rooms.is_fire_alarm_system : null,
          disabled: false,
        },
        []
      ),
      is_co2_protection: new FormControl(
        {
          value: formData ? formData.clean_rooms.is_co2_protection : null,
          disabled: false,
        },
        []
      ),
      annotations: new FormControl(
        {
          value: formData ? formData.clean_rooms.annotations : null,
          disabled: false,
        },
        []
      ),
      is_annotation: new FormControl(
        {
          value: formData ? formData.clean_rooms.is_annotation : null,
          disabled: false,
        },
        []
      ),
    });

    this.isFormValueUpdated = false;

    this.CleanRoomForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.CleanRoomForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  buildFormObject() {
    const formValuesToPersist = {
      clean_rooms: this.CleanRoomForm.value,
    };
    this.request = {
      criteria_id: BuildingConstants.CLEAN_ROOMS,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }

  ngOnDestroy() {
    if (this.isFormValueUpdated && this.CleanRoomForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
