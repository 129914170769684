<span class="if-else-section">
  <span class="if-section" [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'">If <span
      class="ifelseBracket margin-right">(</span>
    <app-lhs-rhs *ngIf="inputDataOriginal.isLHSIfCondition" [operators]="inputDataOriginal.operatorsIfElse"
      [data_source_types]="inputDataOriginal.data_source_types" [data_source_data]="inputDataOriginal.data_source_data"
      [i]="inputDataOriginal" (changeMenuItemData)="changeMenuItemData($event)" [stepper_id]="stepper_id"
      [formula_id]="formula_id" (changeOperatorData)="changeOperatorData($event)"
      [is_disabled]="inputDataOriginal.is_disabled || is_calculation" [row]="row"
      [product_info_id]="inputDataOriginal.product_info_id" [isformUpdated]="inputDataOriginal.isformUpdated"
      (isformUpdatedData)="inputChanged($event)" (data_sourceData)="data_sourceData($event)"
      [from]="inputDataOriginal.fromIFBrackets">
    </app-lhs-rhs>

    <span *ngIf="!inputDataOriginal.isLHSIfCondition" class="margin-right"
      [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="menu"
      [matMenuTriggerData]="{index: inputDataOriginal.i}" title="{{'value-calculator.Select-Pattern' | translate}}"><img
        alt="" class="ifelsePlusButton" src="../../../../assets/images/new-icons/Pattern_Icon_MG.svg"></span>
    <span class="ifelseBracket margin-left">)</span>

    <span class="ifelseBracket margin-right margin-left">{{ '{' }}</span><br /><br />
    <span [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'" *ngIf="inputDataOriginal.if_formula_number">
      <input type="number" *ngIf="inputDataOriginal.if_formula_number"
        placeholder="{{'value-calculator.enter-number' | translate}}" (change)="inputChanged($event)"
        class="underlined-input" inputmode="numeric"
        [(ngModel)]="inputDataOriginal.row.condition.if_formula.number_value"
        [disabled]="inputDataOriginal.is_disabled || is_calculation"> <span *ngIf="inputDataOriginal.if_formula_number"
        [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
        [matMenuTriggerFor]="ifmenu" [matMenuTriggerData]="{index: inputDataOriginal.i}">
        <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;">
      </span><br /><br />
    </span>
    <span [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'" *ngIf="inputDataOriginal.if_formula_text">
      <input type="text" *ngIf="inputDataOriginal.if_formula_text"
        placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)"
        class="underlined-input" [(ngModel)]="inputDataOriginal.row.condition.if_formula.text_value"
        title="{{inputDataOriginal.row.condition.if_formula.text_value}}"
        [disabled]="inputDataOriginal.is_disabled || is_calculation">
      <span *ngIf="inputDataOriginal.if_formula_text"
        [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
        [matMenuTriggerFor]="ifmenu" [matMenuTriggerData]="{index: inputDataOriginal.i}">
        <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;">
      </span>
      <br /><br />
    </span>
    <span class="nestedIfElse" id="nestedIfElse" *ngIf="nestedIfDataExists(inputDataOriginal)">
      <nested-if-test-maker [is_calculation]="is_calculation"
        [InputValues]="inputDataOriginal.row.nested_formula?.nestedIf" (changeMenuItemData)="changeMenuItemData($event)"
        (data_sourceData)="data_sourceData($event)" (changeOperatorData)="changeOperatorData($event)"
        (inputChanged)="inputChanged($event)" (getSourceData)="getNestedSourceData($event)" [stepper_id]="stepper_id"
        [formula_id]="formula_id" (changeMenuItemIFElseCondition)="changeNestedMenuItemIFElseCondition($event)"
        (changeOperatorIFElseCondition)="changeNestedOperatorIFElseCondition($event)"
        (changeMenu)="changeNestedMenu($event)"></nested-if-test-maker>
    </span>
    <span *ngIf="isIfLeftDataAvailable(inputDataOriginal) && isIfRightDataAvailable(inputDataOriginal)">
      <!--if condition LHS-RHS-->
      <div [style.padding-left]="(inputDataOriginal.i) * 20 + 'px'">
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.If}"
          *ngIf="inputDataOriginal.row.condition.if_formula.left_data.label && 
          !inputDataOriginal.row.condition.if_formula.left_data.is_number && !inputDataOriginal.row.condition.if_formula.left_data.is_text">
          {{'objectModule.select_lable' | translate}}
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.if_formula.left_data.is_data_source"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.If}"
          (click)="getSourceData(inputDataOriginal.row.condition.if_formula.left_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.if_formula.left_data.data_source}}</span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.if_formula.left_data.is_data_source_data"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.If}"
          (click)="getSourceData(inputDataOriginal.row.condition.if_formula.left_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.if_formula.left_data.value}}</span>
        <span *ngIf="inputDataOriginal.row.condition.if_formula.left_data.is_number">
          <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}"
            (change)="inputChanged($event)" class="underlined-input" inputmode="numeric"
            [(ngModel)]="inputDataOriginal.row.condition.if_formula.left_data.number"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>
        <span *ngIf="inputDataOriginal.row.condition.if_formula.left_data.is_text">
          <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)"
            class="underlined-input" [(ngModel)]="inputDataOriginal.row.condition.if_formula.left_data.text"
            title="{{inputDataOriginal.row.condition.if_formula.left_data.text}}"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.If}">
          <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;">
        </span>

        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="operatorSelectIf" [matMenuTriggerData]="{index: inputDataOriginal.i,name:IfElseTypes.If}"
          style="margin: 0px 20px; font-size: 17px;" *ngIf="inputDataOriginal.row.condition.if_formula.operator">
          {{inputDataOriginal.row.condition.if_formula.operator.operator}}
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.if_formula.operator == null"
          [matMenuTriggerData]="{index: inputDataOriginal.i,name:IfElseTypes.If}" [matMenuTriggerFor]="operatorSelectIf"
          style="margin: 0px 20px;">
          <img alt="" src="../../../../assets/images/new-icons/Maths_Operators_Icon.svg">
        </span>

        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.If}" *ngIf="inputDataOriginal.row.condition.if_formula.right_data.label && !inputDataOriginal.row.condition.if_formula.right_data.is_number
          && !inputDataOriginal.row.condition.if_formula.right_data.is_text">
          {{'objectModule.select_lable' | translate}}
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.if_formula.right_data.is_data_source"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.If}"
          (click)="getSourceData(inputDataOriginal.row.condition.if_formula.right_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.if_formula.right_data.data_source}}</span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.if_formula.right_data.is_data_source_data"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.If}"
          (click)="getSourceData(inputDataOriginal.row.condition.if_formula.right_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.if_formula.right_data.value}}</span>
        <span *ngIf="inputDataOriginal.row.condition.if_formula.right_data.is_number">
          <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}"
            (change)="inputChanged($event)" class="underlined-input" inputmode="numeric"
            [(ngModel)]="inputDataOriginal.row.condition.if_formula.right_data.number"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>
        <span *ngIf="inputDataOriginal.row.condition.if_formula.right_data.is_text">
          <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)"
            class="underlined-input" [(ngModel)]="inputDataOriginal.row.condition.if_formula.right_data.text"
            title="{{inputDataOriginal.row.condition.if_formula.right_data.text}}"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>

        <!-- <span *ngIf="inputDataOriginal.row.isIfElse_selected">
          <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}"
            (change)="inputChanged($event)" class="underlined-input" inputmode="numeric"
            [(ngModel)]="inputDataOriginal.row.condition.if_formula.right_data.number"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span> -->
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.If}">
          <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;">
        </span><span *ngIf="inputDataOriginal.if_formula_LHS_RHS"
          [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="ifmenu" [matMenuTriggerData]="{index: inputDataOriginal.i}"><img alt=""
            src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;"></span>
      </div>

      <span *ngIf="inputDataOriginal.if_formula_LHS_RHS"><br /></span>
    </span>
    <span
      *ngIf="inputDataOriginal.isLHSIfCondition && !inputDataOriginal.if_formula_number && !inputDataOriginal.if_formula_text && !inputDataOriginal.if_formula_LHS_RHS && !nestedIfDataExists(inputDataOriginal)"
      [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
      [matMenuTriggerFor]="ifmenu" [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'"
      [matMenuTriggerData]="{index: inputDataOriginal.i}" title="{{'value-calculator.Select-Pattern' | translate}}"><img
        alt="" src="../../../../assets/images/new-icons/Pattern_Icon_MG.svg"><br /><br /></span>

    <span class="ifelseBracket" [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'">{{ '}' }}</span>
  </span>
  <br />
  <span class="else-section" [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'">Else <span
      class="ifelseBracket margin-right margin-left">{{ '{' }} </span>
    <br /><br />
    <span *ngIf="inputDataOriginal.row.condition?.else_formula?.is_number_row">
      <input type="number" *ngIf="inputDataOriginal.else_formula_number"
        placeholder="{{'value-calculator.enter-number' | translate}}" (change)="inputChanged($event)"
        class="underlined-input" inputmode="numeric"
        [(ngModel)]="inputDataOriginal.row.condition.else_formula.number_value"
        [disabled]="inputDataOriginal.is_disabled || is_calculation"><span *ngIf="inputDataOriginal.else_formula_number"
        [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
        [matMenuTriggerFor]="elsemenu" [matMenuTriggerData]="{index: inputDataOriginal.i}">
        <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg"
          style="margin: 0px 15px;"></span><br /><br /></span>
    <span *ngIf="inputDataOriginal.row.condition?.else_formula?.is_text_row">
      <input type="text" *ngIf="inputDataOriginal.else_formula_text"
        placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)"
        class="underlined-input" [(ngModel)]="inputDataOriginal.row.condition.else_formula.text_value"
        title="{{inputDataOriginal.row.condition.else_formula.text_value}}"
        [disabled]="inputDataOriginal.is_disabled || is_calculation"><span *ngIf="inputDataOriginal.else_formula_text"
        [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
        [matMenuTriggerFor]="elsemenu" [matMenuTriggerData]="{index: inputDataOriginal.i}">
        <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg"
          style="margin: 0px 15px;"></span><br /><br /></span>
    <span class="nestedIfElse" id="nestedIfElse" *ngIf="nestedElseDataExists(inputDataOriginal)">
      <nested-if-test-maker [is_calculation]="is_calculation"
        [InputValues]="inputDataOriginal.row.nested_formula?.nestedElse"
        (changeMenuItemData)="changeMenuItemData($event)" (data_sourceData)="data_sourceData($event)"
        (changeOperatorData)="changeOperatorData($event)" (inputChanged)="inputChanged($event)"
        (getSourceData)="getNestedSourceData($event)" [stepper_id]="stepper_id" [formula_id]="formula_id"
        (changeMenuItemIFElseCondition)="changeNestedMenuItemIFElseCondition($event)"
        (changeOperatorIFElseCondition)="changeNestedOperatorIFElseCondition($event)"
        (changeMenu)="changeNestedMenu($event)"></nested-if-test-maker>
    </span>
    <span *ngIf="isElseLeftDataAvailable(inputDataOriginal) && isElseRightDataAvailable(inputDataOriginal)">
      <!--else LHS-RHS condition-->
      <div [style.padding-left]="(inputDataOriginal.i) * 20 + 'px'">
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.Else}"
          *ngIf="inputDataOriginal.row.condition.else_formula.left_data.label && !inputDataOriginal.row.condition.else_formula.left_data.is_number && !inputDataOriginal.row.condition.else_formula.left_data.is_text">
          {{'objectModule.select_lable' | translate}}
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.else_formula.left_data.is_data_source"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.Else}"
          (click)="getSourceData(inputDataOriginal.row.condition.else_formula.left_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.else_formula.left_data.data_source}}</span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.else_formula.left_data.is_data_source_data"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.Else}"
          (click)="getSourceData(inputDataOriginal.row.condition.else_formula.left_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.else_formula.left_data.value}}</span>
        <span *ngIf="inputDataOriginal.row.condition.else_formula.left_data.is_number">
          <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}"
            (change)="inputChanged($event)" class="underlined-input" inputmode="numeric"
            [(ngModel)]="inputDataOriginal.row.condition.else_formula.left_data.number"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>
        <span *ngIf="inputDataOriginal.row.condition.else_formula.left_data.is_text">
          <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)"
            class="underlined-input" [(ngModel)]="inputDataOriginal.row.condition.else_formula.left_data.text"
            title="{{inputDataOriginal.row.condition.else_formula.left_data.text}}"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from:ROWSIDES.LEFT_ROW,name:IfElseTypes.Else}">
          <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;">
        </span>

        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="operatorSelectIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i,name:IfElseTypes.Else}"
          style="margin: 0px 20px; font-size: 17px;" *ngIf="inputDataOriginal.row.condition.else_formula.operator">
          {{inputDataOriginal.row.condition.else_formula.operator.operator}}
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.else_formula.operator == null"
          [matMenuTriggerData]="{index: inputDataOriginal.i,name:IfElseTypes.Else}"
          [matMenuTriggerFor]="operatorSelectIf" style="margin: 0px 20px;">
          <img alt="" src="../../../../assets/images/new-icons/Maths_Operators_Icon.svg">
        </span>

        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.Else}"
          *ngIf="inputDataOriginal.row.condition.else_formula.right_data.label && !inputDataOriginal.row.condition.else_formula.right_data.is_number && !inputDataOriginal.row.condition.else_formula.right_data.is_text">
          {{'objectModule.select_lable' | translate}}
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.else_formula.right_data.is_data_source"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.Else}"
          (click)="getSourceData(inputDataOriginal.row.condition.else_formula.right_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.else_formula.right_data.data_source}}</span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          *ngIf="inputDataOriginal.row.condition.else_formula.right_data.is_data_source_data"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.Else}"
          (click)="getSourceData(inputDataOriginal.row.condition.else_formula.right_data.data_source_id, inputDataOriginal.product_info_id, inputDataOriginal.i, inputDataOriginal)"
          [matMenuTriggerFor]="dataSourceDataIf"
          (menuOpened)="focusSearch('searchSelect')">{{inputDataOriginal.row.condition.else_formula.right_data.value}}</span>
        <span *ngIf="inputDataOriginal.row.condition.else_formula.right_data.is_number">
          <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}"
            (change)="inputChanged($event)" class="underlined-input" inputmode="numeric"
            [(ngModel)]="inputDataOriginal.row.condition.else_formula.right_data.number"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>
        <span *ngIf="inputDataOriginal.row.condition.else_formula.right_data.is_text">
          <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)"
            class="underlined-input" [(ngModel)]="inputDataOriginal.row.condition.else_formula.right_data.text"
            title="{{inputDataOriginal.row.condition.else_formula.right_data.text}}"
            [disabled]="inputDataOriginal.is_disabled || is_calculation">
        </span>
        <span [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="leftMenuIf"
          [matMenuTriggerData]="{index: inputDataOriginal.i, from: ROWSIDES.RIGHT_ROW,name:IfElseTypes.Else}">
          <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;">
        </span><span *ngIf="inputDataOriginal.else_formula_LHS_RHS"
          [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
          [matMenuTriggerFor]="elsemenu" [matMenuTriggerData]="{index: inputDataOriginal.i}"><img alt=""
            src="../../../../assets/images/new-icons/Select_Menu_MG.svg" style="margin: 0px 15px;"></span>
      </div>
      <span *ngIf="inputDataOriginal.else_formula_LHS_RHS"><br /></span>
    </span>
    <span
      *ngIf="inputDataOriginal.isLHSIfCondition && !inputDataOriginal.else_formula_number && !inputDataOriginal.else_formula_text && !inputDataOriginal.else_formula_LHS_RHS  && !nestedElseDataExists(inputDataOriginal)"
      [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'"
      [ngClass]="inputDataOriginal.is_disabled || is_calculation ? 'no-pointer' : 'pointer'"
      [matMenuTriggerFor]="elsemenu" [matMenuTriggerData]="{index: inputDataOriginal.i}"
      title="{{'value-calculator.Select-Pattern' | translate}}"><img alt=""
        src="../../../../assets/images/new-icons/Pattern_Icon_MG.svg"><br /><br /></span>

    <span class="ifelseBracket" [style.padding-left]="(inputDataOriginal.i) * 15 + 'px'">{{ '}' }}</span>
    <br />
  </span>
</span>

<mat-menu #menu="matMenu" class='custom-mat-menu'>
  <ng-template matMenuContent let-index="index" style="padding: 0px;">
    <span mat-menu-item *ngFor="let item of inputDataOriginal.row_types"
      [class]="item.is_heading ? 'dropdown-section-heading' : '' " value="item.label"
      (click)="changeMenu(item.id, index, item.is_heading, $event, '', inputDataOriginal)"
      title="{{item.label}}">{{item.label}}</span>
  </ng-template>
</mat-menu>

<mat-menu #leftMenuIf="matMenu" class='custom-mat-menu'>
  <ng-template matMenuContent let-index="index" let-from="from" let-name="name">
    <span mat-menu-item *ngFor="let item of inputDataOriginal.data_source_types"
      [class]="item.is_heading ? 'dropdown-section-heading' : '' "
      (click)="changeMenuItemIFElseCondition(item.id,from, index, item.is_heading, $event, false,name, inputDataOriginal)"
      title="{{item.label}}">{{item.label}} </span>
  </ng-template>
</mat-menu>
<mat-menu #operatorSelectIf="matMenu" class='custom-mat-menu'>
  <ng-template matMenuContent let-index="index" let-name="name">
    <span mat-menu-item *ngFor="let item of inputDataOriginal.operators"
      [class]="item.is_heading ? 'dropdown-section-heading' : '' "
      (click)="changeOperatorIFElseCondition(item.id, index, item.is_heading, $event,name, inputDataOriginal)">
      {{item.label}} </span>
  </ng-template>
</mat-menu>
<mat-menu #dataSourceDataIf="matMenu" class='custom-mat-menu'>
  <ng-template matMenuContent let-index="index" let-from="from" let-name="name">
    <input type="text" maxlength="50" preventSpace class="searchSelect" style="height: 28px;font-size: 12px;"
      placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search (click)="$event.stopPropagation()">
    <span mat-menu-item
      *ngFor="let item of validationService._filter(inputDataOriginal.data_source_data, 'dropdown', search.value)"
      (click)="changeMenuItemIFElseCondition(item.id, from, index, false, $event, true,name, inputDataOriginal)"
      title="{{item.dropdown}}">
      {{item.dropdown}} </span>
  </ng-template>
</mat-menu>
<mat-menu #ifmenu="matMenu" class='custom-mat-menu'>
  <ng-template matMenuContent let-index="index" style="padding: 0px;">
    <span mat-menu-item *ngFor="let item of defaultRow_types"
      [class]="item.is_heading ? 'dropdown-section-heading' : '' " value="item.label"
      (click)="changeMenu(item.id, index, item.is_heading, $event, 'if', inputDataOriginal)"
      title="{{item.label}}">{{item.label}}</span>
  </ng-template>
</mat-menu>
<mat-menu #elsemenu="matMenu" class='custom-mat-menu'>
  <ng-template matMenuContent let-index="index" style="padding: 0px;">
    <span mat-menu-item *ngFor="let item of defaultRow_types"
      [class]="item.is_heading ? 'dropdown-section-heading' : '' " value="item.label"
      (click)="changeMenu(item.id, index, item.is_heading, $event, 'else', inputDataOriginal)"
      title="{{item.label}}">{{item.label}}</span>
  </ng-template>
</mat-menu>