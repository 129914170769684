<div class="object-module-html custom-form sparte-forms">
    <form [formGroup]="myFormGrp" *ngIf="myFormGrp">
        <div class="formGroupWrap" [formGroupName]="fg.formGrpName" *ngFor="let fg of FORM_DATA">
            <div class="block">
                <div class="container-heading">
                    <div class="m-0 fw-bold">{{fg.formGrpLabel}}</div>
                </div>
                <div class="block-content">
                    <div class="contracts-tabs-content row" style="align-items: baseline;">
                        <div class="col-md-3 col-sm-6 col-6" *ngFor="let fc of fg.formControls">
                            <ng-container [ngSwitch]="fc.type">
                                <span *ngSwitchCase="formFieldType.NUMERIC">
                                    <span *ngIf="!fc.is_currency">
                                        <label
                                            class="custom-label"
                                            [title]="_showError.getBillionErrorTooltip(myFormGrp.controls[fg.formGrpName], fc.name, fc.label, false)"
                                            [ngClass]="_showError.showBillionOrReqLabelError(myFormGrp.controls[fg.formGrpName], fc.name)">{{fc.label}}
                                            <span class="custom-label-required" *ngIf="fc.required"></span> {{ _showError.getBillionErrorMessage(myFormGrp.controls[fg.formGrpName], fc.name) }}
                                        </label>
                                        <input type="text" [formControlName]="fc.name" 
                                            class="custom-input-field" [placeholder]="fc.placeholder"
                                            [ngClass]="_showError.showInputError(myFormGrp.controls[fg.formGrpName], fc.name)"
                                            GermanFormattingRequired BillionCheck>
                                    </span>
                                    <span *ngIf="fc.is_currency">
                                        <div style="width: 100%;">
                                            <label
                                                class="custom-label"
                                                [title]="_showError.getBillionErrorTooltip(myFormGrp.controls[fg.formGrpName], fc.name, fc.label, false)"
                                                [ngClass]="_showError.showBillionOrReqLabelError(myFormGrp.controls[fg.formGrpName], fc.name)">{{fc.label}}
                                                <span class="custom-label-required" *ngIf="fc.required"></span> {{ _showError.getBillionErrorMessage(myFormGrp.controls[fg.formGrpName], fc.name) }}
                                            </label>
                                            <div class="d-flex"> 
                                                <mat-select 
                                                    class="custom-currency-dropdown"
                                                    [ngClass]="_showError.showInputCurrencyError(myFormGrp.controls[fg.formGrpName], fc.name)"
                                                    (selectionChange)="currancyChanged($event,fc.name,fg.formGrpName)"
                                                    [disableOptionCentering]="true"
                                                    title="{{currency_selected[fg.formGrpName][fc.name] ? currency_selected[fg.formGrpName][fc.name].cur_symbol : ''}}"
                                                    panelClass="currencyTypeDropdown" #currencyDropdown
                                                    [value]="currency_selected[fg.formGrpName][fc.name] ? currency_selected[fg.formGrpName][fc.name].cur_id : null">
                                                    <mat-select-trigger>
                                                        <span>{{currency_selected[fg.formGrpName][fc.name] ?
                                                            currency_selected[fg.formGrpName][fc.name].cur_code :
                                                            ''}}</span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let ele of currencyTypes" [value]="ele.id"
                                                        matTooltip="{{ele.cur_symbol}}">
                                                        <span style="display: flex;">
                                                            <span
                                                                style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                                                            <span
                                                                style="justify-content: end;">{{ele.cur_symbol}}</span>
                                                        </span>
                                                    </mat-option>
                                                </mat-select>
                                                <input type="text" [formControlName]="fc.name" 
                                                    class="custom-input-field currencyValueInput" [placeholder]="fc.placeholder"
                                                    [ngClass]="_showError.showInputError(myFormGrp.controls[fg.formGrpName], fc.name)"
                                                    GermanFormattingRequired BillionCheck>
                                            </div>
                                        </div>
                                    </span>
                                </span>
                                <span *ngSwitchCase="formFieldType.TEXT_FIELD">
                                    <label style="text-transform: initial;" for="" class="long-label">{{fc.label}}<span
                                            style="color:red" *ngIf="fc.required"> *</span></label><br />
                                    <input type="text" class="input"
                                        [ngClass]="{'red' : fc.required && isSubmitted && myFormGrp.controls[fg.formGrpName].get(fc.name).hasError('required') }"
                                        formControlName="{{fc.name}}" placeholder="{{fc.placeholder}}">
                                </span>
                                <span *ngSwitchCase="formFieldType.RADIO_BUTTON">
                                    <label style="text-transform: initial;" for="" class="long-label">{{fc.label}}<span
                                            style="color:red" *ngIf="fc.required"> *</span></label><br />
                                    <div class="radio-group-align-long">
                                        <mat-radio-group aria-label="" formControlName="{{fc.name}}"
                                            [ngClass]="{'redRadio' : fc.required && isSubmitted && myFormGrp.controls[fg.formGrpName].get(fc.name).hasError('required') }">
                                            <mat-radio-button [value]="fc.radio.first">{{fc.radio.first}}
                                            </mat-radio-button>
                                            <mat-radio-button [value]="fc.radio.second">{{fc.radio.second}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </span>
                                <span *ngSwitchCase="formFieldType.DROPDOWN">
                                    <label style="text-transform: initial;" for="" class="long-label">{{fc.label}}<span
                                            style="color:red" *ngIf="fc.required"> *</span></label><br />
                                    <mat-select multiple="{{fc.is_single ? false: true}}"
                                        [ngClass]="{'red' : fc.required && isSubmitted && myFormGrp.controls[fg.formGrpName].get(fc.name).hasError('required') }"
                                        class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                                        formControlName="{{fc.name}}">
                                        <mat-option *ngFor="let item of dropdownsData[fc.name]"
                                            [value]="item.dropdownDetailsId">
                                            <span>{{item.description}}</span>
                                        </mat-option>
                                    </mat-select>
                                </span>
                                <span *ngSwitchCase="formFieldType.DATE_FIELD">
                                    <label style="text-transform: initial;" for="" class="long-label">{{fc.label}}<span
                                            style="color:red" *ngIf="fc.required"> *</span></label><br />
                                    <mat-form-field appearance="fill"
                                        [ngClass]="{'red' : fc.required && isSubmitted && myFormGrp.controls[fg.formGrpName].get(fc.name).hasError('required') }"
                                        class="custom-date-picker" >
                                        <span>
                                            <img alt="" class="img-calender" src="assets/images/ic-icons/Calendar.svg">
                                        </span>
                                        <input matInput (dateChange)="dateClicked($event,fg.formGrpName, fc.name)"
                                            [matDatepicker]="datepicker1" class="date-input" autocomplete="off"
                                            (blur)="onDateBlur(fg.formGrpName, fc.name, fc.required)"
                                            [ngClass]="{'red' : fc.required && isSubmitted && myFormGrp.controls[fg.formGrpName].get(fc.name).hasError('required') }"
                                            placeholder="{{fc.placeholder}}" formControlName="{{fc.name}}">
                                        <mat-datepicker-toggle matSuffix [for]="datepicker1">
                                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                                {{'claims.keyboard-arrow-down_text' | translate}}
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #datepicker1></mat-datepicker>
                                    </mat-form-field>
                                </span>
                                <span *ngSwitchCase="formFieldType.CHECKBOX">
                                    <label style="text-transform: initial;" for="" class="long-label">{{fc.label}}<span
                                            style="color:red" *ngIf="fc.required"> *</span></label><br />
                                    <div class="d-flex align-items-center sparte-checkbox h-39">
                                        <mat-checkbox class="custom-checkBox check1"
                                            [ngClass]="{'redCheckbox' : fc.required && isSubmitted && myFormGrp.controls[fg.formGrpName].get(fc.name).hasError('required') }"
                                            formControlName="{{fc.name}}"
                                            (change)="onChechboxChecked($event,fg.formGrpName, fc.name, fc.required)"
                                            title="{{fc.placeholder}}">
                                            {{fc.placeholder}}</mat-checkbox>
                                    </div>
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="save-container" *ngIf="FORM_DATA.length>0">
    <button class="custom-btn cancel-btn" (click)="cancelBtnClick()">
        {{'template-management.cancel_label' | translate}}
    </button>
    <button class="custom-btn save-btn" (click)="saveFormDetails()">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>
<app-delete-confirmation *ngIf="showGuardPopup" 
    [inputData]="sendGuardReq" (emitData)="hideModal($event)">
</app-delete-confirmation>