import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
} from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { AZURE } from '@shared/constants/azure-service.constants';
import { ZursGeoPaymentComponent } from './zurs-geo-payment/zurs-geo-payment.component';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
export interface AddressData {
  address: any;
  street: any;
  house: any;
  zip: any;
  city: any;
  flood: boolean;
  earth: boolean;
  snow: boolean;
  latitude: any;
  longitude: any;
}
@Component({
  selector: 'app-zurs-geo',
  templateUrl: './zurs-geo.component.html',
  styleUrls: ['./zurs-geo.component.css'],
})
export class ZursGeoComponent extends BaseComponent implements OnInit {
  Alllocations: any;
  filterlocation = [];
  selectedaddress: any;
  showActive = false;
  house_num: any;
  house = false;
  street_num: any;
  street = false;
  zip_code: any;
  zip = false;
  city_name: any;
  city = false;
  lat: any;
  lon: any;
  address: string;
  selectedLoc = '';
  firefoxBrowser: any;
  chargableTextShow = false;
  floodChecked = false;
  earthChecked = false;
  snowChecked = false;
  address_blank = false;
  message = false;
  showList = false;
  public keyUp = new Subject<string>();
  readonly selectLang$ = this._userService.locale$;
  @Input('isCloseButtonHidden') isCloseButtonHidden;
  constructor(
    private serviceCall: ApiCallsService,
    private dialog: MatDialog,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.firefoxBrowser = navigator.userAgent.indexOf('Firefox') != -1;
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
  }

  onFocusOut(): void {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  autoComplete(value) {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          this.Alllocations = res.results;
          this.filterAddressFromResults(this.Alllocations);
          this.filterPostCode(this.filterlocation);
          this.showList = true;
        });
    }
  }
  filterPostCode(Alllocations: any) {
    this.filterlocation = Alllocations.filter(key => {
      return key.postalCode;
    });
  }
  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
  }

  selectedLocation(e) {
    this.showList = false;
    this.selectedaddress = [this.filterlocation[e]];
    this.selectedLoc = this.filterlocation[e].freeformAddress;
    if (this.selectedaddress.length == 1) {
      if (this.selectedaddress[0].streetNumber !== undefined) {
        this.house_num = this.selectedaddress[0].streetNumber;
        this.house = false;
      } else {
        this.house_num = '';
      }
      if (
        this.selectedaddress[0].streetName !== undefined ||
        this.selectedaddress[0].municipalitySubdivision !== undefined
      ) {
        if (this.selectedaddress[0].streetName !== undefined) {
          this.street_num = this.selectedaddress[0].streetName;
        } else {
          this.street_num = this.selectedaddress[0].municipalitySubdivision;
        }
        this.street = false;
      } else {
        this.street_num = '';
      }
      if (this.selectedaddress[0].postalCode !== undefined) {
        this.zip_code = this.selectedaddress[0].postalCode;
        this.zip = false;
      } else {
        this.zip_code = '';
      }
      if (
        this.selectedaddress[0].localName !== undefined ||
        this.selectedaddress[0].municipality !== undefined
      ) {
        if (this.selectedaddress[0].localName !== undefined) {
          this.city_name = this.selectedaddress[0].localName;
        } else {
          this.city_name = this.selectedaddress[0].municipality;
        }
        this.city = false;
      } else {
        this.city_name = '';
      }
      for (const element of this.Alllocations) {
        if (element.address.freeformAddress == this.selectedLoc) {
          if (element.position.lat !== undefined) {
            this.lat = element.position.lat;
          } else {
            this.lat = '';
          }
          if (element.position.lon !== undefined) {
            this.lon = element.position.lon;
          } else {
            this.lon = '';
          }
        }
      }
      if (this.lat !== '' && this.lon !== '') {
        this.showActive = true;
      } else {
        this.showActive = false;
      }
    }
  }
  selectedLocationMozilla(e) {
    const val = e;
    this.selectedaddress = this.filterlocation.filter(obj => {
      return obj.freeformAddress == val;
    });

    if (this.selectedaddress.length == 1) {
      if (this.selectedaddress[0].streetNumber !== undefined) {
        this.house_num = this.selectedaddress[0].streetNumber;
        this.house = false;
      } else {
        this.house_num = '';
      }
      if (
        this.selectedaddress[0].streetName !== undefined ||
        this.selectedaddress[0].municipalitySubdivision !== undefined
      ) {
        if (this.selectedaddress[0].streetName !== undefined) {
          this.street_num = this.selectedaddress[0].streetName;
        } else {
          this.street_num = this.selectedaddress[0].municipalitySubdivision;
        }
        this.street = false;
      } else {
        this.street_num = '';
      }
      if (this.selectedaddress[0].postalCode !== undefined) {
        this.zip_code = this.selectedaddress[0].postalCode;
        this.zip = false;
      } else {
        this.zip_code = '';
      }
      if (
        this.selectedaddress[0].localName !== undefined ||
        this.selectedaddress[0].municipality !== undefined
      ) {
        if (this.selectedaddress[0].localName !== undefined) {
          this.city_name = this.selectedaddress[0].localName;
        } else {
          this.city_name = this.selectedaddress[0].municipality;
        }
        this.city = false;
      } else {
        this.city_name = '';
      }
      for (const element of this.Alllocations) {
        if (element.address.freeformAddress == val) {
          if (element.position.lat !== undefined) {
            this.lat = element.position.lat;
          } else {
            this.lat = '';
          }
          if (element.position.lon !== undefined) {
            this.lon = element.position.lon;
          } else {
            this.lon = '';
          }
        }
      }
      if (this.lat !== '' && this.lon !== '') {
        this.showActive = true;
      } else {
        this.showActive = false;
      }
      this.address = val;
      this.selectedLoc = val;
    }
  }
  floodCheck(event) {
    if (event.checked) {
      this.message = false;
      this.floodChecked = true;
    } else {
      this.floodChecked = false;
    }
    if (event.checked && !this.earthChecked && !this.snowChecked) {
      this.message = false;
      this.chargableTextShow = true;
    } else if (!event.checked && !this.earthChecked && !this.snowChecked) {
      this.chargableTextShow = false;
    }
  }
  earthCheck(event) {
    if (event.checked) {
      this.message = false;
      this.earthChecked = true;
    } else {
      this.earthChecked = false;
    }
    if (event.checked && !this.floodChecked && !this.snowChecked) {
      this.message = false;
      this.chargableTextShow = true;
    } else if (!event.checked && !this.floodChecked && !this.snowChecked) {
      this.chargableTextShow = false;
    }
  }
  snowCheck(event) {
    if (event.checked) {
      this.message = false;
      this.snowChecked = true;
    } else {
      this.snowChecked = false;
    }
    if (event.checked && !this.floodChecked && !this.earthChecked) {
      this.message = false;
      this.chargableTextShow = true;
    } else if (!event.checked && !this.floodChecked && !this.earthChecked) {
      this.chargableTextShow = false;
    }
  }
  check() {
    const data: AddressData = {
      address: this.selectedLoc,
      street: this.street_num,
      house: this.house_num,
      zip: this.zip_code,
      city: this.city_name,
      flood: this.floodChecked,
      snow: this.snowChecked,
      earth: this.earthChecked,
      latitude: this.lat,
      longitude: this.lon,
    };
    if (
      this.street_num !== '' &&
      this.zip_code !== '' &&
      this.city_name !== '' &&
      this.house_num !== '' &&
      this.street_num !== undefined &&
      this.zip_code !== undefined &&
      this.city_name !== undefined &&
      this.house_num !== undefined
    ) {
      this.street = false;
      this.city = false;
      this.zip = false;
      this.house = false;
      if (this.floodChecked || this.snowChecked || this.earthChecked) {
        const zursGeoPaymentDialog = this.dialog.open(ZursGeoPaymentComponent, {
          width: '45%',
          panelClass: 'zursPayment',
          data,
          disableClose: true,
        });
      } else {
        this.message = true;
      }
    }
    if (this.street_num == undefined || this.street_num == '') {
      this.street = true;
    }
    if (this.city_name == undefined || this.city_name == '') {
      this.city = true;
    }
    if (this.zip_code == undefined || this.zip_code == '') {
      this.zip = true;
    }
    if (this.house_num == undefined || this.house_num == '') {
      this.house = true;
    }
  }
  onNoClick() {}
}
