import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { STATIC_TEXT } from '@shared/constants/string.constants';

@Injectable({
  providedIn: 'root',
})
export class DropdownTitleService {
  getDropdownTitle(
    formControlName: string,
    formGroup: FormGroup,
    dropdownData: any[],
    searchColumnName: string,
    getColumnName: string
  ) {
    const control = formGroup?.get(formControlName);
    if (control) {
      const resultObject = dropdownData?.find(
        e => e[searchColumnName] === control.value
      );
      return resultObject
        ? resultObject[getColumnName]
        : STATIC_TEXT.EMPTY_STRING;
    }
    return STATIC_TEXT.EMPTY_STRING;
  }
}
