import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PRESENT_CONFIG } from '../../constants/general/present.config';

const WIDTH = 50;
const BOX_WIDTH = 60;

@Component({
  selector: 'app-present',
  templateUrl: './present.component.html',
  styleUrls: ['./present.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresentComponent implements OnInit {
  @Input() bottom?: string;
  @Input() top?: string;
  @Input() left?: string;
  @Input() right?: string;
  @Input('box') isBox?: boolean;

  width = WIDTH;
  config = PRESENT_CONFIG;

  constructor() {}

  ngOnInit(): void {
    this.isBox = this.isBox !== undefined;

    if (this.isBox) {
      this.width = BOX_WIDTH;
    }
  }
}
