import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-lithium-batteries',
  templateUrl: './lithium-batteries.component.html',
  styleUrls: ['./lithium-batteries.component.css'],
})
export class LithiumBatteriesComponent implements OnInit {
  lithiumBatteriesForm: FormGroup;
  lithiumBatteriesStorage = [];
  browser: string;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  actualnewValue = 0;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    private _currencyCustomPipe: CurrencyCustomPipe,
    public validationService: ValidationService,
    private serviceCall: ApiCallsService
  ) {
    this.browser = this.detectBrowser();
    this.serviceCall
      .getLookupData(27)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.lithiumBatteriesStorage = data.data;
      });
  }

  ngOnInit(): void {
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  initForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.LITHIUM_BATTERIES
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          BuildingConstants.LITHIUM_BATTERIES
        ].saved_criteria
      );
    this.assignAttributeValues(formData);
    this.isFormValueUpdated = false;
    this.lithiumBatteriesForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.lithiumBatteriesForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  assignAttributeValues(formData: any) {
    const storage_of_lithium_batteries = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries'].storage_of_lithium_batteries
          : null,
        disabled: false,
      },
      []
    );
    const is_safety_rules_according_to_manufacturer_complied_with =
      new FormControl(
        {
          value: formData
            ? formData['lithium-batteries']
                .is_safety_rules_according_to_manufacturer_complied_with
            : null,
          disabled: false,
        },
        []
      );
    const is_warehouse_fire_resistant_or_spatially_separated = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries']
              .is_warehouse_fire_resistant_or_spatially_separated
          : null,
        disabled: false,
      },
      []
    );
    const is_fire_alarm_system = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries'].is_fire_alarm_system
          : null,
        disabled: false,
      },
      []
    );
    const is_extinguishing_system = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries'].is_extinguishing_system
          : null,
        disabled: false,
      },
      []
    );
    const is_extinguishing_system_with_extinguishing_agent_according_to_safety_data_sheet =
      new FormControl(
        {
          value: formData
            ? formData['lithium-batteries']
                .is_extinguishing_system_with_extinguishing_agent_according_to_safety_data_sheet
            : null,
          disabled: false,
        },
        []
      );
    const is_storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets =
      new FormControl(
        {
          value: formData
            ? formData['lithium-batteries']
                .is_storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets
            : null,
          disabled: false,
        },
        []
      );
    const is_mixed_storage_with_other_products = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries'].is_mixed_storage_with_other_products
          : null,
        disabled: false,
      },
      []
    );

    const storage_area = new FormControl(
      {
        value: formData
          ? this.validationService.setSqmValue(
              formData['lithium-batteries']
                .storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets
                .storage_area
            )
          : null,
        disabled: false,
      },
      []
    );
    const storage_height_top_edge_of_stored_goods_in_m = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries']
              .storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets
              .storage_height_top_edge_of_stored_goods_in_m
          : null,
        disabled: false,
      },
      []
    );

    const remark_of_mixed_storage_with_other_products = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries'].mixed_storage_with_other_products
              .remark_of_mixed_storage_with_other_products
          : null,
        disabled: false,
      },
      []
    );
    const storage_area_BMA = new FormControl(
      {
        value: formData
          ? formData['lithium-batteries'].mixed_storage_with_other_products
              .storage_area_BMA
          : null,
        disabled: false,
      },
      []
    );
    const approx_storage_height = new FormControl(
      {
        value: formData
          ? Number(
              formData['lithium-batteries'].mixed_storage_with_other_products
                .approx_storage_height
            )
          : null,
        disabled: false,
      },
      []
    );

    const annotations = new FormControl(
      {
        value: formData ? formData['lithium-batteries'].annotations : null,
        disabled: false,
      },
      []
    );
    const is_annotation = new FormControl(
      {
        value: formData ? formData['lithium-batteries'].is_annotation : null,
        disabled: false,
      },
      []
    );

    this.lithiumBatteriesForm = this.fb.group({
      storage_of_lithium_batteries,
      is_safety_rules_according_to_manufacturer_complied_with,
      is_warehouse_fire_resistant_or_spatially_separated,
      is_fire_alarm_system,
      is_extinguishing_system,
      is_extinguishing_system_with_extinguishing_agent_according_to_safety_data_sheet,
      is_storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets,
      is_mixed_storage_with_other_products,
      storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets:
        this.fb.group({
          storage_area,
          storage_height_top_edge_of_stored_goods_in_m,
        }),
      mixed_storage_with_other_products: this.fb.group({
        remark_of_mixed_storage_with_other_products,
        storage_area_BMA,
        approx_storage_height,
      }),
      annotations,
      is_annotation,
    });
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }

  buildFormObject() {
    const lithiumBatteriesObjectValue = this.lithiumBatteriesForm.value;

    const formatValue =
      lithiumBatteriesObjectValue.mixed_storage_with_other_products
        .approx_storage_height;
    lithiumBatteriesObjectValue.mixed_storage_with_other_products.approx_storage_height =
      lithiumBatteriesObjectValue.mixed_storage_with_other_products
        .approx_storage_height
        ? Number(formatValue)
        : null;

    const storage_area =
      lithiumBatteriesObjectValue
        .storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets
        .storage_area;
    lithiumBatteriesObjectValue.storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets.storage_area =
      storage_area
        ? Number(this.validationService.removeSymbols(storage_area))
        : null;

    const formValuesToPersist = {
      'lithium-batteries': lithiumBatteriesObjectValue,
    };
    this.request = {
      criteria_id: BuildingConstants.LITHIUM_BATTERIES,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }

  ngOnDestroy() {
    if (this.isFormValueUpdated && this.lithiumBatteriesForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  sqmValidation(fromCtrl: FormGroup, event) {
    if (event.type === 'blur') {
      const value = this._currencyCustomPipe
        .transform(event.target.value)
        ?.replace('€', '')
        .trim()
        .split(',')[0];
      fromCtrl
        .get(
          'storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets.storage_area'
        )
        .setValue(value);
    } else if (event.type === 'focus') {
      const value = event.target.value.toString().split('.');
      fromCtrl
        .get(
          'storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets.storage_area'
        )
        .setValue(value.join(''));
    }
  }

  validateNumberAsFloat(event) {
    const numberText = event.target.value.toString();

    if (numberText.includes('.')) {
      this.lithiumBatteriesForm.patchValue({
        mixed_storage_with_other_products: { approx_storage_height: '' },
      });
      return;
    }

    const numberEntered = event.target.value.toString().replace(',', '.');
    const numberEnteredArray = numberEntered.split('.');

    if (numberEntered > 100 || numberEntered === 0) {
      this.lithiumBatteriesForm.patchValue({
        mixed_storage_with_other_products: { approx_storage_height: '' },
      });
    } else if (isNaN(numberEntered)) {
      this.lithiumBatteriesForm.patchValue({
        mixed_storage_with_other_products: { approx_storage_height: '' },
      });
    } else if (numberEnteredArray[1] && numberEnteredArray[1].length > 2) {
      this.lithiumBatteriesForm.patchValue({
        mixed_storage_with_other_products: { approx_storage_height: '' },
      });
    } else if (numberEnteredArray[1] && numberEnteredArray[1] === '') {
      this.lithiumBatteriesForm.patchValue({
        mixed_storage_with_other_products: { approx_storage_height: '' },
      });
    }
  }

  formatToGermanValue(formatValue) {
    if (formatValue) {
      return formatValue.toString().replace('.', ',');
    }
  }

  formatToEnglishValue(formatValue) {
    if (formatValue) {
      return formatValue.toString().replace(',', '.');
    }
  }

  onTypeNewValue(event, key) {
    if (key === 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length === 0) {
        if (charCode === '45' || charCode === '48' || charCode === '46') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        if (
          charCode != 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        this.onTypeDecimalNumber(event);
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualnewValue = event.target.value.length;
      } else {
        this.actualnewValue = this.actualnewValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualnewValue === 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }

  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length === 2) {
        event.preventDefault();
      }
    }
  }
}
