import { Component } from '@angular/core';
import { SourceType } from '@shared/models/source-type.model';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Component({
  selector: 'app-customer-appointments',
  templateUrl: './customer-appointments.component.html',
})
export class CustomerAppointmentsComponent {
  source = SourceType.CUSTOMER;
  customerId$ = this._customerShareService.selectedCustomerId$;
  divisionId$ = this._customerShareService.selectedCustomerDivisionId$;

  constructor(private _customerShareService: CustomerSharedService) {}
}
