<div class="tasks">
  <div class="tasks__header">
    <h6 class="tasks__title">{{ 'tasks.title' | translate }}</h6>

    <app-button (click)="openCreateTaskPopup()">
      <img src="assets/images/form/add.svg" alt="">
      {{ 'tasks.create_new_task' | translate }}
    </app-button>
  </div>

  <app-table
    [tableConfig]="tableConfig" 
    [pagination]="pagination" 
    (pageChanged)="changePage($event)" 
    [data]="data"
    (actionClicked)="handleAction($event)"
    (rowClicked)="clickRow($event)">
  </app-table>
</div>