import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { UserService } from '@core/user.service';
import { LOCALS } from '@shared/constants/local.constants';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.css'],
})
export class InsuranceDetailsComponent extends BaseComponent implements OnInit {
  @Input('IVMSynch') IVMSynch;
  @Input('generaldata') generaldata;
  @Input('data') data;
  @Input('contractId') contractId;
  @Output('insurerFormValue') insurerFormValue = new EventEmitter<any>();
  InsuranceForm: any;
  userDataValue: any;
  YearPremiumFrequencyLabels: any = {};
  collectioTypes: any = [];
  YearPremiumFrequencies: any = [];
  browser: string;
  actualnewValue = 0;
  allStatusValues = [];
  collectionTypes: any = [];
  paymentModes: any = [];
  bankDetails: any = [];
  errorNewValue = false;
  selected_payment_type: number;

  constructor(
    private _currencyCustomPipe: CurrencyCustomPipe,
    private userService: UserService,
    private serviceCall: ApiCallsService,
    public validationService: ValidationService
  ) {
    super();
    this.selected_payment_type = 6;
  }
  ngOnInit(): void {
    this.buildData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildData();
  }

  buildData() {
    this.setLanguageForPremiumFreqLabels();
    this.browser = this.detectBrowser();

    this.InsuranceForm = new FormGroup({
      GrossPremium: new FormControl(''),
      Tax: new FormControl(''),
      NetPremium: new FormControl(''),
      PaymentNetPremium: new FormControl(''),
      PaymentType: new FormControl(''),
      BrokerCollection: new FormControl(''),
      amount: new FormControl(''),
      collectiontype: new FormControl(''),
      bankdetailsforcollection: new FormControl(''),
      modeofpayment: new FormControl(''),
      commission: new FormControl(''),
      commissionaccordingtomodeofpayment: new FormControl(''),
      yearly_commission: new FormControl(''),
    });
    this.loadDropDownData();
    this.setFormData(this.data);
    this.InsuranceForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (this.InsuranceForm.touched) {
          this.buildFormObject();
        }
      });
  }

  buildFormObject() {
    this.data.gross_premium_annual = this.convertToNumeric(
      this.InsuranceForm.value.GrossPremium
    );
    this.data.tax_in_per = this.convertToNumeric(this.InsuranceForm.value.Tax);
    this.data.as_amount = this.convertToNumeric(
      this.InsuranceForm.value.amount
    );
    this.data.net_annual_premium = this.convertToNumeric(
      this.InsuranceForm.value.NetPremium
    );
    this.data.mode_of_payment = this.InsuranceForm.value.modeofpayment;
    this.data.gross_premium_Freq = this.convertToNumeric(
      this.InsuranceForm.value.PaymentNetPremium
    );
    this.data.payment_type = this.selected_payment_type;
    this.data.collection_type_id = this.InsuranceForm.value.collectiontype;
    this.data.bank_details_id =
      this.InsuranceForm.value.bankdetailsforcollection;
    this.data.commission_percntage = this.convertToNumeric(
      this.InsuranceForm.value.commission
    );
    this.data.commission_according_to_mop = this.convertToNumeric(
      this.InsuranceForm.value.commissionaccordingtomodeofpayment
    );
    this.data.yearly_commission = this.convertToNumeric(
      this.InsuranceForm.value.yearly_commission
    );
    if (this.InsuranceForm.touched) {
      this.insurerFormValue.emit(this.data);
    }
  }

  convertToGerman(value) {
    return value
      ? value.toString().includes(',')
        ? value
        : this._currencyCustomPipe
            .transform(value, 'EUR')
            ?.replace('€', '')
            ?.replace(',00', '')
            ?.trim()
      : null;
  }

  convertToGermanString(value) {
    let originalValue = this.InsuranceForm.value[value];
    originalValue =
      value && value.toString().includes(',')
        ? value
        : this.convertToNumeric(originalValue);
    const valuToSet = this.convertToGerman(originalValue);
    this.InsuranceForm.controls[value].setValue(valuToSet);
  }

  convertToNumeric(value) {
    return (
      (value &&
        Number(
          value.toString().replaceAll('.', '').replaceAll(',', '.').trim()
        )) ||
      null
    );
  }

  calculateTax() {
    if (this.generaldata?.insurer_count == 1) {
      if (
        this.InsuranceForm.value.GrossPremium &&
        this.InsuranceForm.value.NetPremium
      ) {
        const amount =
          this.convertToNumeric(this.InsuranceForm.value.GrossPremium) -
          this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        this.fillTaxAndAmount(this.InsuranceForm.value.NetPremium, amount);
      } else {
        this.InsuranceForm.controls.Tax.setValue('');
        this.InsuranceForm.controls.amount.setValue('');
      }
    } else {
      if (
        this.InsuranceForm.value.GrossPremium &&
        this.InsuranceForm.value.NetPremium &&
        !this.InsuranceForm.value.Tax
      ) {
        const amount =
          this.convertToNumeric(this.InsuranceForm.value.GrossPremium) -
          this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        const tax =
          (amount /
            this.convertToNumeric(this.InsuranceForm.value.NetPremium)) *
          100;
      }
    }
  }

  fillTaxAndAmount(value, amount) {
    if (amount > 0) {
      const tax = (amount / this.convertToNumeric(value)) * 100;
      this.InsuranceForm.controls.Tax.setValue(this.convertToGerman(tax));
      this.InsuranceForm.controls.amount.setValue(this.convertToGerman(amount));
    } else {
      this.InsuranceForm.controls.Tax.setValue(0);
      this.InsuranceForm.controls.amount.setValue(0);
    }
  }

  calculateAndPopulate(updateColumn) {
    if (
      updateColumn === 'GrossPremium' &&
      this.InsuranceForm.value.GrossPremium
    ) {
      if (this.InsuranceForm.value.NetPremium) {
        const amount =
          this.convertToNumeric(this.InsuranceForm.value.GrossPremium) -
          this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        this.fillTaxAndAmount(this.InsuranceForm.value.NetPremium, amount);
      } else if (this.InsuranceForm.value.Tax) {
        const value =
          (this.convertToNumeric(this.InsuranceForm.value.GrossPremium) /
            (this.convertToNumeric(this.InsuranceForm.value.Tax) + 100)) *
          100;
        this.InsuranceForm.controls.NetPremium.setValue(
          this.convertToGerman(value)
        );
      }
    } else if (updateColumn === 'GrossPremium') {
      this.InsuranceForm.controls.Tax.setValue('');
      this.InsuranceForm.controls.amount.setValue('');
    }

    if (updateColumn === 'NetPremium' && this.InsuranceForm.value.NetPremium) {
      if (this.InsuranceForm.value.GrossPremium) {
        const amount =
          this.convertToNumeric(this.InsuranceForm.value.GrossPremium) -
          this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        this.fillTaxAndAmount(this.InsuranceForm.value.NetPremium, amount);
      } else if (this.InsuranceForm.value.Tax) {
        const value =
          this.convertToNumeric(this.InsuranceForm.value.NetPremium) *
          (this.convertToNumeric(this.InsuranceForm.value.Tax) / 100);
        const grossValue =
          value + this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        this.InsuranceForm.controls.GrossPremium.setValue(
          this.convertToGerman(grossValue)
        );
      }
    } else if (updateColumn === 'NetPremium') {
      this.InsuranceForm.controls.Tax.setValue('');
      this.InsuranceForm.controls.amount.setValue('');
    }

    if (updateColumn === 'Tax' && this.InsuranceForm.value.Tax) {
      if (this.InsuranceForm.value.GrossPremium) {
        const value =
          (this.convertToNumeric(this.InsuranceForm.value.GrossPremium) /
            (this.convertToNumeric(this.InsuranceForm.value.Tax) + 100)) *
          100;
        this.InsuranceForm.controls.NetPremium.setValue(
          this.convertToGerman(value)
        );
        const amount =
          this.convertToNumeric(this.InsuranceForm.value.GrossPremium) -
          this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        this.InsuranceForm.controls.amount.setValue(
          this.convertToGerman(amount)
        );
      } else if (this.InsuranceForm.value.NetPremium) {
        const value =
          this.convertToNumeric(this.InsuranceForm.value.NetPremium) *
          (this.convertToNumeric(this.InsuranceForm.value.Tax) / 100);
        const grossValue =
          value + this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        this.InsuranceForm.controls.GrossPremium.setValue(
          this.convertToGerman(grossValue)
        );
        const amount =
          this.convertToNumeric(this.InsuranceForm.value.GrossPremium) -
          this.convertToNumeric(this.InsuranceForm.value.NetPremium);
        this.InsuranceForm.controls.amount.setValue(
          this.convertToGerman(amount)
        );
      }
    }
    this.calculateCommissionModeOfPayment();
    this.calculatPaymentFrequence();
    this.calculateYearlyCommisionOnLoad();
    this.calculateTax();
  }

  paymentChange(event) {
    this.selected_payment_type = (event && event.value) || 6;
  }

  setFormData(data: any) {
    if (data && Object.keys(data).length !== 0) {
      data.gross_premium_annual &&
        this.InsuranceForm.controls.GrossPremium.setValue(
          this.convertToGerman(data.gross_premium_annual)
        );
      data.tax_in_per &&
        this.InsuranceForm.controls.Tax.setValue(
          this.convertToGerman(data.tax_in_per)
        );
      data.as_amount &&
        this.InsuranceForm.controls.amount.setValue(
          this.convertToGerman(data.as_amount)
        );
      data.net_annual_premium &&
        this.InsuranceForm.controls.NetPremium.setValue(
          this.convertToGerman(data.net_annual_premium)
        );
      data.mode_of_payment &&
        this.InsuranceForm.controls.modeofpayment.setValue(
          data.mode_of_payment
        );
      this.InsuranceForm.controls.PaymentType.setValue(data.payment_type);
      this.selected_payment_type = data.payment_type ? data.payment_type : 6;
      this.InsuranceForm.controls.bankdetailsforcollection.setValue(
        data.bank_details_id
      );
      this.InsuranceForm.controls.collectiontype.setValue(
        data.collection_type_id
      );
      data.commission_percntage &&
        this.InsuranceForm.controls.commission.setValue(
          this.convertToGerman(data.commission_percntage)
        );
      data.commission_according_to_mop &&
        this.InsuranceForm.controls.commissionaccordingtomodeofpayment.setValue(
          this.convertToGerman(data.commission_according_to_mop)
        );
      data.yearly_commission &&
        this.InsuranceForm.controls.yearly_commission.setValue(
          this.convertToGerman(data.yearly_commission)
        );
      this.calculateCommissionModeOfPayment();
      this.calculatPaymentFrequence();
      this.calculateYearlyCommisionOnLoad();
      this.calculateTax();
    }
  }

  changeNumber(event, name) {
    const count = event.split(',').length - 1;
    if (count > 1) {
      if (name == 'newValue') {
        this.errorNewValue = true;
      }
    } else {
      if (name == 'newValue') {
        this.errorNewValue = false;
      }
    }
    const tempValue = event.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 9) {
      if (name == 'newValue') {
        this.errorNewValue = true;
      }
    } else {
      if (name == 'newValue') {
        this.errorNewValue = false;
      }
    }
    if (name == 'newValue') {
      this.InsuranceForm.controls.yearly_commission.setValue(
        this._currencyCustomPipe
          .transform(
            event.toString()?.replaceAll('.', '')?.replaceAll(',', '.'),
            'EUR'
          )
          ?.replace('€', '')
          .replace(',00', '')
          ?.trim()
      );
    }
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }

  mapFormValue(formfield, formValue) {
    this.InsuranceForm.controls[formfield].setValue(formValue);
  }

  loadDropDownData() {
    this.serviceCall
      .getProductLookups('collection_type')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data?.HasErrors) {
          this.collectionTypes = [];
          data?.data.forEach(collectionType => {
            this.collectionTypes.push({
              id: collectionType.id,
              desc: collectionType.desc,
            });
          });
        }
      });
    this.serviceCall
      .getProductLookups('payment_mode')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data?.HasErrors) {
          this.paymentModes = [];
          data?.data.forEach(paymentMode => {
            this.paymentModes.push({
              id: paymentMode.id,
              desc: paymentMode.desc,
            });
          });
        }
      });
    const liztuId = this.userService.getDeliztu();
    if (liztuId) {
      this.serviceCall
        .getCustomerBankDetails(liztuId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data?.HasErrors) {
            this.bankDetails = [];
            data?.bank_details.forEach(bankDetail => {
              this.bankDetails.push({
                id: bankDetail.bank_account_detail_id,
                desc: bankDetail.bank_name + '\t' + bankDetail.iban,
              });
            });
          }
        });
    }
  }

  setLanguageForPremiumFreqLabels() {
    // Please move this labels when translation will be added.
    // translation will be done for all the attributes in the page
    if (this.userService.getLocale() === LOCALS.ENGLISH) {
      this.YearPremiumFrequencyLabels = {
        1: 'Yearly',
        2: 'Half yearly',
        4: 'Quarter yearly',
        12: 'Monthly',
      };

      this.collectioTypes = ['Direct Collection', 'Agency Collection'];
      this.YearPremiumFrequencies = [
        'Select',
        'Yearly',
        'Quarterly',
        'Biannual',
        'Monthly',
      ];
    } else {
      this.YearPremiumFrequencyLabels = {
        1: 'Jährlich',
        2: 'Halbjährlich',
        4: 'Vierteljährlich',
        12: 'Monatlich',
      };

      this.collectioTypes = ['Direktinkasso', 'Agenturinkasso'];
      this.YearPremiumFrequencies = [
        'Select',
        'Yearly',
        'Quarterly',
        'Biannual',
        'Monthly',
      ];
    }
  }

  calculatPaymentFrequence() {
    if (this.InsuranceForm.value.NetPremium) {
      let premiumNet = this.InsuranceForm.value.NetPremium.toString()
        .replaceAll('.', '')
        .replaceAll(',', '.');
      if (this.InsuranceForm.value.modeofpayment) {
        premiumNet = this.getEffectiveValue(premiumNet);
      } else {
        premiumNet = null;
      }
      const result = premiumNet
        ? this._currencyCustomPipe
            .transform(premiumNet.toString(), 'EUR')
            .replace('€', '')
        : null;
      this.InsuranceForm.controls.PaymentNetPremium.setValue(result);
    } else {
      this.InsuranceForm.controls.PaymentNetPremium.setValue(null);
      this.InsuranceForm.controls.commissionaccordingtomodeofpayment.setValue(
        null
      );
      this.InsuranceForm.controls.yearly_commission.setValue(null);
    }
    this.buildFormObject();
  }

  calculateCommissionModeOfPayment() {
    if (
      this.InsuranceForm.value.commission &&
      this.InsuranceForm.value.NetPremium
    ) {
      const premiumNet = this.InsuranceForm.value.NetPremium.toString()
        .replaceAll('.', '')
        .replaceAll(',', '.');
      const value = this.InsuranceForm.value.commission
        .toString()
        .replaceAll('.', '')
        .replaceAll(',', '.');
      let effective_Value = (premiumNet * value) / 100;
      const PaymentNetPremium = premiumNet;
      if (this.InsuranceForm.value.modeofpayment) {
        effective_Value = this.getEffectiveValue(effective_Value);
      } else {
        effective_Value = null;
      }
      const result = effective_Value
        ? this._currencyCustomPipe
            .transform(effective_Value.toString(), 'EUR')
            .replace('€', '')
        : null;
      this.InsuranceForm.controls.commissionaccordingtomodeofpayment.setValue(
        result
      );
    } else if (!this.InsuranceForm.value.commission) {
      this.InsuranceForm.controls.commissionaccordingtomodeofpayment.setValue(
        null
      );
    }
    this.buildFormObject();
  }

  getEffectiveValue(value) {
    if (this.InsuranceForm.value.modeofpayment) {
      const id = this.InsuranceForm.value.modeofpayment;
      switch (id) {
        case 83:
          // yearly
          break;
        case 84:
          // quarterly
          value = value / 4;
          break;
        case 85:
          // one time
          break;
        case 86:
          // monthly
          value = value / 12;
          break;
        case 87:
          // bianuualy
          value = value / 2;
          break;
      }
    }
    return value;
  }

  calculateYearlyCommision(event) {
    if (event.target.value && this.InsuranceForm.value.NetPremium) {
      const premiumNet = this.InsuranceForm.value.NetPremium.replaceAll(
        '.',
        ''
      ).replaceAll(',', '.');
      const value = event.target.value.replaceAll('.', '').replaceAll(',', '.');
      const effective_Value = (premiumNet * value) / 100;
      const result = this._currencyCustomPipe
        .transform(effective_Value.toString(), 'EUR')
        .replace('€', '');
      this.InsuranceForm.controls.yearly_commission.setValue(result);
    } else if (!event.target.value) {
      this.InsuranceForm.controls.yearly_commission.setValue(null);
    }
    this.buildFormObject();
  }

  calculateYearlyCommisionOnLoad() {
    if (
      this.InsuranceForm.value.NetPremium &&
      this.InsuranceForm.value.commission
    ) {
      const premiumNet = this.InsuranceForm.value.NetPremium.replaceAll(
        '.',
        ''
      ).replaceAll(',', '.');
      const value = this.InsuranceForm.value.commission.replaceAll(',', '.');
      const effective_Value = (premiumNet * value) / 100;
      const result = this._currencyCustomPipe
        .transform(effective_Value.toString(), 'EUR')
        .replace('€', '');
      this.InsuranceForm.controls.yearly_commission.setValue(result);
    }
    this.buildFormObject();
  }

  validateNumberAsFloat(event, field?) {
    const numberEntered = event.target.value.toString().replace(',', '.');
    const numberEnteredArray = numberEntered.split('.');

    if (numberEntered > 100 || numberEntered == 0) {
      this.patchVla(event, field);
    } else if (isNaN(numberEntered)) {
      this.patchVla(event, field);
    } else if (numberEnteredArray[1] && numberEnteredArray[1].length > 2) {
      this.patchVla(event, field);
    } else if (numberEnteredArray[1] && numberEnteredArray[1] == '') {
      this.patchVla(event, field);
    } else {
      const value = this._currencyCustomPipe
        .transform(
          event.target.value
            .toString()
            .replaceAll('.', '')
            .replaceAll(',', '.'),
          'EUR'
        )
        .replace('€', '')
        ?.replace(',00', '')
        ?.trim();
      event.target.value = value;
    }
  }
  patchVla(event, field?) {
    if (field) {
      this.InsuranceForm.patchValue({ [field]: '' });
    } else {
      event.target.value = '';
    }
  }
  validateNumberAsFloatEightDigit(event, field?) {
    const numberEntered = event.target.value
      .toString()
      .replaceAll('.', '')
      .replaceAll(',', '.');
    const numberEnteredArray = numberEntered.split('.');
    if (numberEntered > 1000000000 || numberEntered == 0) {
      this.patchVla(event, field);
    } else if (isNaN(numberEntered)) {
      this.patchVla(event, field);
    } else if (numberEnteredArray[1] && numberEnteredArray[1].length > 2) {
      this.patchVla(event, field);
    } else if (numberEnteredArray[1] && numberEnteredArray[1] == '') {
      this.patchVla(event, field);
    } else {
      const value = this._currencyCustomPipe
        .transform(
          event.target.value
            .toString()
            .replaceAll('.', '')
            .replaceAll(',', '.'),
          'EUR'
        )
        .replace('€', '')
        ?.replace(',00', '')
        ?.trim();
      event.target.value = value;
    }
  }

  onTypeNewValue(event, key) {
    if (key == 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length == 0) {
        if (charCode == '45' || charCode == '48' || charCode == '46') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        if (
          charCode != 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        this.onTypeDecimalNumber(event);
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualnewValue = event.target.value.length;
      } else {
        this.actualnewValue = this.actualnewValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualnewValue == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }

  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length == 2) {
        event.preventDefault();
      }
    }
  }
}
