import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import {
  DataSourceTypes,
  RowSides,
  RowTypes,
} from '../../../constants/formula.constants';
import { ProductWarningMsg } from '../../../models/product-management.model';
import { FormulaMakerBaseComponent } from './formula-maker-base.component';
import {
  ifelse_input,
  if_else_condition_object,
  if_formula,
  row_object,
} from './formula.model';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-formula-maker',
  templateUrl: './formula-maker.component.html',
  styleUrls: ['./formula-maker.component.css'],
})
export class FormulaMakerComponent
  extends FormulaMakerBaseComponent
  implements OnInit
{
  @Input('show_formula_popup') showFormulaPopup;
  @Input('object_data') object_data;
  @Output('hide') hide = new EventEmitter<any>();
  @Input('data') data;
  @Input('targetVariableParentName') targetVariableParentName;
  @Output('is_saved') is_saved = new EventEmitter<any>();
  @Output('message') message = new EventEmitter<any>();
  @Input('is_calculation') is_calculation: boolean;
  inputDataValues: ifelse_input;
  isformUpdated: boolean;
  showWarningPopUp = false;
  targetVariableAttribute: any = [];
  selectedAttribute: any;
  maskGenerator = 'maskGenerator';
  formula_id = null;
  formulaName;
  prefered_formula;
  skip_calculation = false;
  is_disabled = false;
  key_value_pairs = [];
  data_source_data;
  created_by: any = null;
  validateForm = false;
  validateAttribute = false;
  selectedString: any;
  productWarningMsg: ProductWarningMsg;
  product_info_id: any = null;
  control: FormControl;
  ifElseRow = false;
  ifElseMultiRow = false;
  isUpdate = false;
  ifElse = false;
  objectList = new Map<String, Set<String>>();
  resultList = new Map<Number, Set<String>>();
  selectedIndexes = new Map<String, Number>();
  selectedResults = new Map<Number, Set<String>>();
  customEvaluatedValue: string;
  selectedLable = [];
  row_type_alternative: {
    id: number;
    label: any;
    is_heading: boolean;
    is_if_else: boolean;
  }[];
  row_type_alternative_with_out_if_else: {
    id: number;
    label: any;
    is_heading: boolean;
    is_if_else: boolean;
  }[];
  condition: if_else_condition_object;
  if_formula: if_formula = {
    row_type_id: null,
    is_number_row: null,
    is_text_row: null,
  };

  else_formula: if_formula = {
    row_type_id: null,
    is_number_row: null,
    is_text_row: null,
  };
  currentPath: string[];

  private _userId: string;

  constructor(
    private _apiCallService: ApiCallsService,
    public validationService: ValidationService,
    public _translate: TranslateService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private readonly _userService: UserService
  ) {
    super(_translate);
    this.productWarningMsg = {
      cancel: 'product-management.stepper_common_msg.continue',
      ok: 'product-management.stepper_common_msg.discard',
      save_and_continue:
        'product-management.stepper_common_msg.save_and_continue',
      header: 'save-changes.Nicht_gespeicherte',
      body: 'save-changes.object_unsaved_message',
    };
  }

  ngOnInit(): void {
    this.customEvaluatedValue = '';
    this._watchOnUserId();
    this.data_source_types = [];
    this._apiCallService
      .getValueCalcMenu()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors && data.value_calculator_menus) {
          data.value_calculator_menus.forEach(value => {
            if (value.is_show && value.menu_item) {
              this.data_source_types.push({
                id: Math.random(),
                label: value.desc,
                is_heading: true,
              });
              value.menu_item.forEach(subValue => {
                this.data_source_types.push({
                  id: subValue.datasource_id,
                  label: subValue.desc,
                  is_heading: false,
                });
              });
            }
          });
        }
        this.buildData();
      });
  }

  handleCheckboxChange(event: any): void {
    this.isformUpdated = true;
    if (event.checked) {
      this.operators.push({
        id: 18,
        label: this._translate.instant('value-calculator.design-Operators'),
        is_heading: true,
      });
      this.operators.push({ id: 19, label: '<br>', is_heading: false });
    } else {
      if (this.operators.length == 9) {
        this.operators.pop();
        this.operators.pop();
      }
    }
  }

  buildData(): void {
    this.row_type_alternative = this.row_types;
    this.row_type_alternative_with_out_if_else = this.row_types.filter(
      row => row.id != 4
    );
    this.is_disabled = this.is_calculation;
    this.control = new FormControl();
    this.isformUpdated = false;
    this.showWarningPopUp = false;
    this.created_by = this.data?.created_by;
    this.formula_id = this.data?.id;
    this.selectedAttribute = this.data?.target_variable_attribute;
    this.formulaName = this.data?.formula_name ? this.data.formula_name : null;
    this.prefered_formula = this.data?.prefered_formula || null;
    this.skip_calculation = this.data?.skip_calculation || null;
    this.product_info_id = this.data.product_info_id;
    if (this.data?.ui_formula) {
      this.row_types = [...this.row_type_alternative_with_out_if_else];
      this.isUpdate = true;
      const parsedValues = JSON.parse(this.data.ui_formula);
      if (parsedValues.ifElseRow) {
        this.formula_object[0] = parsedValues.row;
        if (this.formula_object.length == 2) {
          this.formula_object.pop();
        }
        this.inputDataValues = parsedValues;
      } else {
        this.formula_object = parsedValues;
        const {
          is_add_row_button,
          is_number_row,
          is_text_row,
          is_operator_row,
          is_plus_button,
          row_type_id,
        } = parsedValues[0];
        if (
          !is_add_row_button &&
          !is_number_row &&
          !is_text_row &&
          !is_operator_row &&
          !is_plus_button &&
          row_type_id == 4
        ) {
          this.row_types = this.row_types.filter(function (val) {
            return val.id == 2 || val.id == 3;
          });
          this.ifElseRow = true;
          this.bundleDataforIfElse(
            0,
            this.formula_object[0],
            true,
            RowTypes.If_Else
          );
        } else {
          this.row_types = [...this.row_type_alternative];
        }
      }
    } else {
      this.isUpdate = false;
    }

    if (this.data.attributes) {
      const tempValue =
        this.data.attributes &&
        this.data.attributes.reduce((c, e) => {
          if (!c[e.key]) {
            c[e.key] = e;
          }
          return c;
        }, {});
      this.key_value_pairs = tempValue && Object.values(tempValue);
      for (let i = 0; i < this.key_value_pairs.length; i++) {
        if (
          this.key_value_pairs[i].multipleObjectsExists == true &&
          this.key_value_pairs[i].value
        ) {
          const values: Set<String> =
            this.objectList.get(this.key_value_pairs[i].objectType) ||
            new Set<String>();
          for (let k = 0; k < this.key_value_pairs[i].value.length; k++) {
            values.add(
              this.key_value_pairs[i].value[k].objectName +
                ':' +
                parseInt(this.key_value_pairs[i].value[k].objectModuleId)
            );
            const selectedValue = this.key_value_pairs[i].value[k].objectValue;
            const resultValue: Set<String> =
              this.resultList.get(
                this.key_value_pairs[i].value[k].objectModuleId
              ) || new Set<String>();
            const tempValue =
              this.key_value_pairs[i].key + ' = ' + selectedValue;
            resultValue.add(tempValue);
            this.resultList.set(
              this.key_value_pairs[i].value[k].objectModuleId,
              resultValue
            );
          }
          this.objectList.set(this.key_value_pairs[i].objectType, values);
        } else {
          this.key_value_pairs[i].value = Number(this.key_value_pairs[i].value)
            ? this._currencyCustomPipe
                .transform(parseInt(this.key_value_pairs[i].value))
                .replace('€', '')
            : this.key_value_pairs[i].value;
        }
      }
      this.preFillSelection();
    }
    this.callTargetAttributeApi();
  }

  preFillSelection(): void {
    if (this.prefered_formula && this.prefered_formula.product_modules) {
      this.prefered_formula.product_modules.forEach((value, index) =>
        this.selectedIndexes.set(index, parseInt(value))
      );
      const selectedValues = [...this.selectedIndexes.values()];
      this.selectedResults = new Map<Number, Set<String>>();
      this.resultList.forEach((value, key) => {
        if (selectedValues.includes(key)) {
          this.selectedResults.set(key, value);
        }
      });
    }
    this.getSelectedIndex();
  }

  getSelectedIndex(): void {
    let count = 0;
    this.objectList &&
      this.objectList.forEach((value, key) => {
        value &&
          value.forEach(element => {
            const indexV = element && element.split(':')[1];
            if (
              indexV &&
              this.prefered_formula &&
              this.prefered_formula.product_modules &&
              this.prefered_formula.product_modules.includes(Number(indexV))
            ) {
              this.selectedLable.push(indexV);
            }
            count++;
          });
        if (this.selectedLable.length == count) {
          this.selectedLable.push('');
        }
        count++;
      });
  }

  onSelection(index, objectModuleId): void {
    this.customEvaluatedValue = '';

    objectModuleId.value == 'select_lable'
      ? this.selectedIndexes.delete(index)
      : this.selectedIndexes.set(index, parseInt(objectModuleId.value));
    const selectedValues = [...this.selectedIndexes.values()];
    this.selectedResults = new Map<Number, Set<String>>();
    this.resultList.forEach((value, key) => {
      if (selectedValues.includes(key)) {
        this.selectedResults.set(key, value);
      }
    });
  }

  inputChanged(): void {
    this.isformUpdated = true;
  }

  callTargetAttributeApi(): void {
    this._apiCallService
      .getTargetAttribute()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        data.data.map(element =>
          this.targetVariableAttribute.push(element.name)
        );
      });
  }

  hideModal(): void {
    this.showFormulaPopup = false;
  }

  handleWarningPopup(event): void {
    this.showWarningPopUp = false;
    if (!event) {
      this.isformUpdated = false;
      this.hide.emit(false);
    }
  }

  hidePopup(): void {
    if (this.isformUpdated) {
      this.showWarningPopUp = true;
    } else {
      this.selectedAttribute != this.control.value
        ? (this.showWarningPopUp = true)
        : this.hide.emit(false);
    }
  }

  onTypeName(): void {
    this.isformUpdated = true;
    this.validateForm = false;
  }

  addNewRow(): void {
    this.isformUpdated = true;
    const length = this.formula_object.length - 1;
    const new_row: row_object = {
      is_plus_button: true,
      row_type_id: null,
      is_add_row_button: false,
      is_operator_row: false,
      is_number_row: false,
      is_text_row: false,
      index: length,
    };
    if (this.formula_object.length < 2) {
      this.formula_object.splice(length, 0, new_row);
    } else if (this.formula_object.length >= 2) {
      this.row_types = this.row_types.filter(function (val) {
        return val.id !== 4;
      });
      const new_row_operator: row_object = {
        is_plus_button: false,
        row_type_id: null,
        is_add_row_button: false,
        is_operator_row: true,
        operator_id_row: null,
        operator_value: null,
        is_number_row: false,
        is_text_row: false,
        index: length,
      };
      new_row.index = length + 1;
      this.formula_object.splice(length, 0, new_row_operator);
      this.formula_object.splice(length + 1, 0, new_row);
    }
  }

  targetValue(event): void {
    this.selectedAttribute = event;
    if (event) {
      const found = this.targetVariableAttribute.find(
        element => element == event
      );
      if (event !== null && found !== event) {
        const targetAttribute = {
          data: {
            name: this.selectedAttribute,
            name_de: this.selectedAttribute,
            is_show: true,
            is_form_level: false,
          },
        };
        this._apiCallService
          .addTargetAttribute(targetAttribute)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            if (!data.HasErrors) {
              this.selectedAttribute = data.data.name;
              this.callTargetAttributeApi();
            }
          });
      }

      this.isformUpdated = true;
      this.validateAttribute = false;
    }
  }

  changeMenu1(event): void {
    const currentPath = (event.currentPath && [...event.currentPath]) || [];
    const value = this.getValue(this.inputDataValues, event, currentPath);
    this.changeMenu(
      event.id,
      this.inputDataValues.index,
      event.is_heading,
      event.event,
      event.fromMenu,
      value,
      false
    );
    // this.updateValue(this.inputDataValues, event, value, event.id);
    this.inputDataValues = { ...this.inputDataValues };
  }

  getValue(
    originalFormula: ifelse_input,
    event,
    currentPathParam
  ): ifelse_input {
    const currentPath = [...currentPathParam];
    if (originalFormula && originalFormula.i == event.level) {
      this.currentPath =
        (originalFormula &&
          originalFormula.currentPath && [...originalFormula.currentPath]) ||
        [];
      return originalFormula;
    } else if (originalFormula) {
      const routeToTake = currentPath.shift() || event.fromMenu;

      if (routeToTake == 'if' && originalFormula.row.nested_formula) {
        return this.getValue(
          originalFormula.row.nested_formula.nestedIf,
          event,
          currentPath
        );
      }
      if (routeToTake == 'else' && originalFormula.row.nested_formula) {
        return this.getValue(
          originalFormula.row.nested_formula.nestedElse,
          event,
          currentPath
        );
      }
      if (!routeToTake && originalFormula.row.nested_formula) {
        return this.getValue(
          originalFormula.row.nested_formula.nestedIf,
          event,
          currentPath
        );
      }
    }
  }

  // updateValue(originalFormula: ifelse_input, event, updateValue, id) {
  //   if (originalFormula && originalFormula.i == event.level) {
  //     this.updateFinalValue(id, originalFormula, updateValue)
  //   } else if (originalFormula) {
  //     if (event.fromMenu == 'if' && originalFormula.row.nested_formula) {
  //       this.updateValue(originalFormula.row.nested_formula.nestedIf, event.level, updateValue, id);
  //     } else if (event.fromMenu == 'else' && originalFormula.row.nested_formula) {
  //       this.updateValue(originalFormula.row.nested_formula.nestedElse, event.level, updateValue, id);
  //     } else {
  //       this.updateValue(originalFormula.row.nested_formula, event.level, updateValue, id);
  //     }
  //   }
  // }

  updateCurrentPath(rowObject: ifelse_input, fromMenu: string): void {
    const currentPath = this.currentPath || [];
    if (fromMenu) {
      currentPath.push(fromMenu);
    }
    rowObject.currentPath = [...currentPath];
  }

  changeMenu(
    id,
    index,
    is_heading,
    event,
    fromMenu,
    inputData,
    isNotNested = true
  ): void {
    const rowObject: row_object = isNotNested
      ? this.formula_object[index]
      : inputData && inputData.row;
    if (is_heading) {
      event.stopPropagation();
    } else {
      this.isformUpdated = true;
      if (id == RowTypes.NUMBER) {
        if (this.ifElseRow || (inputData && inputData.ifElseRow)) {
          this.ifElseRow = true;
          if (fromMenu == 'if') {
            if (inputData) {
              this.ifElseMultiRow = true;
              inputData.ifElseMultiRow = true;
              inputData.if_formula_number = true;
              inputData.if_formula_text = false;
              inputData.if_formula_LHS_RHS = false;
            }
            if (!isNotNested) {
              rowObject.condition.if_formula.left_data = null;
              rowObject.condition.if_formula.right_data = null;
              rowObject.condition.if_formula.row_type_id = id;
              rowObject.condition.if_formula.is_number_row = true;
              rowObject.condition.if_formula.number_value = null;
              rowObject.condition.if_formula.is_text_row = false;
              rowObject.condition.if_formula.text_value = null;
            }
            this.bundleDataforIfElse(index, rowObject, isNotNested, id);
            if (!isNotNested && inputData) {
              inputData.if_formula_number = true;
              inputData.if_formula_text = false;
              inputData.if_formula_LHS_RHS = false;
            }
          } else if (fromMenu == 'else') {
            if (inputData) {
              this.ifElseMultiRow = true;
              inputData.ifElseMultiRow = true;
              inputData.else_formula_number = true;
              inputData.else_formula_text = false;
              inputData.else_formula_LHS_RHS = false;
            }
            if (!isNotNested) {
              rowObject.condition.else_formula.left_data = null;
              rowObject.condition.else_formula.right_data = null;
              rowObject.condition.else_formula.row_type_id = id;
              rowObject.condition.else_formula.is_number_row = true;
              rowObject.condition.else_formula.number_value = null;
              rowObject.condition.else_formula.is_text_row = false;
              rowObject.condition.else_formula.text_value = null;
            }
            this.bundleDataforIfElse(index, rowObject, isNotNested, id);
            if (!isNotNested && inputData) {
              inputData.else_formula_number = true;
              inputData.else_formula_text = false;
              inputData.else_formula_LHS_RHS = false;
            }
          }
        } else {
          rowObject.row_type_id = id;
          rowObject.is_plus_button = false;
          rowObject.is_number_row = true;
          rowObject.number_value = null;
          rowObject.is_text_row = false;
          rowObject.text_value = null;
          this.bundleDataforIfElse(index, rowObject, isNotNested, id);
        }
      }
      if (id == RowTypes.TEXT) {
        if (this.ifElseRow || (inputData && inputData.ifElseRow)) {
          this.ifElseRow = true;
          if (fromMenu == 'if') {
            if (inputData) {
              this.ifElseMultiRow = true;
              inputData.ifElseMultiRow = true;
              inputData.if_formula_text = true;
              inputData.if_formula_number = false;
              inputData.if_formula_LHS_RHS = false;
            }
            if (!isNotNested) {
              rowObject.condition.if_formula.left_data = null;
              rowObject.condition.if_formula.right_data = null;
              rowObject.condition.if_formula.row_type_id = id;
              rowObject.condition.if_formula.is_text_row = true;
              rowObject.condition.if_formula.text_value = null;
              rowObject.condition.if_formula.is_number_row = false;
              rowObject.condition.if_formula.number_value = null;
            }
            this.bundleDataforIfElse(index, rowObject, isNotNested, id);
            if (!isNotNested && inputData) {
              inputData.if_formula_text = true;
              inputData.if_formula_number = false;
              inputData.if_formula_LHS_RHS = false;
            }
          } else if (fromMenu == 'else') {
            if (inputData) {
              this.ifElseMultiRow = true;
              inputData.ifElseMultiRow = true;
              inputData.else_formula_text = true;

              inputData.else_formula_number = false;
              inputData.else_formula_LHS_RHS = false;
            }
            if (!isNotNested) {
              rowObject.condition.else_formula.left_data = null;
              rowObject.condition.else_formula.right_data = null;
              rowObject.condition.else_formula.row_type_id = id;
              rowObject.condition.else_formula.is_text_row = true;
              rowObject.condition.else_formula.text_value = null;
              rowObject.condition.else_formula.is_number_row = false;
              rowObject.condition.else_formula.number_value = null;
            }
            this.bundleDataforIfElse(index, rowObject, isNotNested, id);
            if (!isNotNested && inputData) {
              inputData.else_formula_text = true;
              inputData.else_formula_number = false;
              inputData.else_formula_LHS_RHS = false;
            }
          }
        } else {
          rowObject.row_type_id = id;
          rowObject.is_plus_button = false;
          rowObject.is_number_row = false;
          rowObject.number_value = null;
          rowObject.is_text_row = true;
          rowObject.text_value = null;
          this.bundleDataforIfElse(index, rowObject, isNotNested, id);
        }
      }
      if (id == RowTypes.LHS_RHS) {
        const left_data = {
          is_number: false,
          is_text: false,
          label: true,
          is_data_source: false,
          data_source: null,
          data_source_id: null,
          is_data_source_data: false,
          value: null,
          evaluated_id: null,
          value_id: null,
          question_id: null,
        };
        const right_data = {
          is_number: false,
          is_text: false,
          label: true,
          is_data_source: false,
          data_source: null,
          data_source_id: null,
          is_data_source_data: false,
          value: null,
          evaluated_id: null,
          value_id: null,
          question_id: null,
        };
        if (this.formula_object.length < 3) {
          this.row_types = this.row_type_alternative;
          if (inputData) {
            inputData.row_types = this.row_type_alternative;
          }
        }

        if (inputData?.ifElseRow) {
          this.ifElseRow = true;
          if (inputData) {
            this.ifElseMultiRow = true;
            inputData.ifElseMultiRow = true;
            inputData.ifElseRow = true;
            inputData.isLHSIfCondition = true;
          }
          if (fromMenu == 'if' || fromMenu == 'else') {
            this.changeLHSRHSmenu(
              fromMenu,
              id,
              index,
              left_data,
              right_data,
              inputData,
              isNotNested
            );
          } else {
            rowObject.condition.row_type_id = id;
            rowObject.condition.left_data = left_data;
            rowObject.condition.right_data = right_data;
          }
        } else {
          this.ifElseRow = false;
          if (inputData) {
            inputData.ifElseRow = false;
            inputData.isLHSIfCondition = false;
          }
          rowObject.row_type_id = id;
          rowObject.is_plus_button = false;
          rowObject.left_data = left_data;
          rowObject.right_data = right_data;
        }
        this.bundleDataforIfElse(index, rowObject, isNotNested, id);
      }
      if (id == RowTypes.If_Else) {
        this.resetNumberLHSRHS(inputData, rowObject, fromMenu);
        const condition = {
          row_type_id: null,
          left_data: null,
          right_data: null,
          operator: null,
          if_formula: { ...this.if_formula },
          else_formula: { ...this.else_formula },
        };
        if (
          (!inputData && this.ifElseMultiRow) ||
          (inputData && !inputData.ifElseMultiRow) ||
          isNotNested
        ) {
          rowObject.condition = { ...condition };
          rowObject.row_type_id = id;
          rowObject.is_plus_button = false;
          rowObject.is_number_row = false;
          rowObject.is_text_row = false;
          this.row_types = this.row_types.filter(function (val) {
            return val.id == 2 || val.id == 3;
          });
          if (isNotNested) {
            this.formula_object[index + 1].is_add_row_button = false;
          }
          this.bundleDataforIfElse(index, rowObject, isNotNested, id);
          if (inputData) {
            inputData.isLHSIfCondition = false;
            inputData.row_types = this.row_types;
          }
          if (inputData && !isNotNested) {
            inputData.row_types = this.row_types;
            inputData.ifElseRow = true;
          }
          if (isNotNested && this.inputDataValues) {
            this.inputDataValues.row_types = this.row_types;
            this.inputDataValues.ifElseRow = true;
          }
        } else {
          this.ifElseMultiRow = true;
          if (inputData) {
            this.buildMultiIfElse(
              inputData,
              condition,
              rowObject,
              isNotNested,
              id,
              fromMenu
            );
          }
        }
      }
    }
  }

  buildMultiIfElse(
    inputData,
    condition,
    rowObject,
    isNotNested,
    id,
    fromMenu
  ): void {
    const nestedIf: row_object = {
      condition: { ...condition },
      row_type_id: id,
      is_add_row_button: false,
      is_operator_row: false,
      is_plus_button: false,
      is_number_row: false,
      is_text_row: false,
      index: inputData.index,
    };
    this.row_types = this.row_types.filter(val => val.id == 2 || val.id == 3);
    inputData.row_types = this.row_types;
    this.bundleDataforMultiIfElse(
      inputData.i,
      nestedIf,
      rowObject,
      isNotNested,
      fromMenu
    );
  }

  bundleDataforMultiIfElse(index, nestedIf, rowObject, isNotNested, fromMenu) {
    const data = this.buildGenericData(index + 1, nestedIf, true);
    // rowObject.isIfElse_selected = true;
    if (fromMenu == 'if') {
      rowObject.nested_formula
        ? (rowObject.nested_formula.nestedIf = { ...data })
        : (rowObject.nested_formula = { nestedIf: { ...data } });
      this.updateCurrentPath(rowObject.nested_formula.nestedIf, fromMenu);
    }
    if (fromMenu == 'else') {
      rowObject.nested_formula
        ? (rowObject.nested_formula.nestedElse = { ...data })
        : (rowObject.nested_formula = { nestedElse: { ...data } });
      this.updateCurrentPath(rowObject.nested_formula.nestedElse, fromMenu);
    }
    if (!isNotNested) {
      return;
    }
    this.inputDataValues.row = { ...rowObject };
  }

  bundleDataforIfElse(index, rowObject, isNotNested, id): void {
    if (!isNotNested) {
      return;
    }
    const inputData = this.buildGenericData(index, rowObject, this.ifElseRow);
    if (this.inputDataValues || id == RowTypes.If_Else) {
      this.inputDataValues = { ...inputData };
    }
  }

  buildGenericData(index, rowObject, isMutiIfElse): ifelse_input {
    const inputData: ifelse_input = {
      i: index,
      ifElseRow: isMutiIfElse,
      index: this.formula_object.length - 2,
    };
    inputData.operatorsIfElse = this.operatorsIfElse;
    inputData.data_source_types = this.data_source_types;
    inputData.is_disabled = this.is_disabled;
    inputData.product_info_id = this.product_info_id;
    inputData.isformUpdated = this.isformUpdated;
    inputData.fromIFBrackets = this.fromIFBrackets;
    inputData.operators = this.operators;
    inputData.row_types = this.row_types;
    inputData.data_source_data = this.data_source_data;
    inputData.row = { ...rowObject };
    return inputData;
  }

  changeLHSRHSmenu(
    fromMenu,
    id,
    index,
    left_data,
    right_data,
    inputData,
    isNotNested
  ): void {
    const rowObject: row_object = inputData.row;
    if (fromMenu == 'if') {
      inputData.if_formula_LHS_RHS = true;
      inputData.if_formula_number = false;
      inputData.if_formula_text = false;
      rowObject.condition.if_formula.row_type_id = id;
      rowObject.condition.if_formula.is_number_row = false;
      rowObject.condition.if_formula.is_text_row = false;
      rowObject.condition.if_formula.left_data = left_data;
      rowObject.condition.if_formula.right_data = right_data;
      rowObject.condition.if_formula.operator = null;
    } else if (fromMenu == 'else') {
      inputData.else_formula_LHS_RHS = true;
      inputData.else_formula_number = false;
      inputData.else_formula_text = false;
      rowObject.condition.else_formula.row_type_id = id;
      rowObject.condition.else_formula.is_number_row = false;
      rowObject.condition.else_formula.is_text_row = false;
      rowObject.condition.else_formula.left_data = left_data;
      rowObject.condition.else_formula.right_data = right_data;
      rowObject.condition.else_formula.operator = null;
    }
  }

  resetNumberLHSRHS(inputData, rowObject, fromMenu): void {
    if (inputData) {
      if (fromMenu == 'if') {
        inputData.if_formula_LHS_RHS = false;
        inputData.if_formula_number = false;
        inputData.if_formula_text = false;
        if (
          rowObject &&
          rowObject.condition &&
          rowObject.condition.if_formula
        ) {
          rowObject.condition.if_formula.right_data = null;
          rowObject.condition.if_formula.left_data = null;
        }
      }
      if (fromMenu == 'else') {
        inputData.else_formula_LHS_RHS = false;
        inputData.else_formula_number = false;
        inputData.else_formula_text = false;
        if (
          rowObject &&
          rowObject.condition &&
          rowObject.condition.else_formula
        ) {
          rowObject.condition.else_formula.right_data = null;
          rowObject.condition.else_formula.left_data = null;
        }
      }
    }
  }

  changeMenuItemData(event): void {
    const value = this.getValue(
      this.inputDataValues,
      event,
      event.currentPath || []
    );
    this.changeMenuItem(
      event.id,
      event.fromWhere,
      event.index,
      event.is_heading,
      event.event,
      event.is_data,
      value
    );
  }

  changeMenuItem(
    id,
    fromWhere,
    index,
    is_heading,
    event,
    is_data,
    inputData
  ): void {
    if (is_heading) {
      event.stopPropagation();
    } else {
      let formulaObj;
      if (inputData?.ifElse) {
        formulaObj =
          inputData.condition || this.formula_object[index].condition;
      } else {
        formulaObj = inputData?.row.condition || this.formula_object[index];
      }
      if (inputData) {
        inputData.isformUpdated = true;
      }
      this.isformUpdated = true;
      if (fromWhere == RowSides.LEFT_ROW) {
        formulaObj.left_data.label = false;
        if (id == DataSourceTypes.NUMBER && !is_data) {
          formulaObj.left_data.is_number = true;
          formulaObj.left_data.is_text = false;
          formulaObj.left_data.is_data_source = false;
          formulaObj.left_data.is_data_source_data = false;
        } else if (id == DataSourceTypes.TEXT && !is_data) {
          formulaObj.left_data.is_text = true;
          formulaObj.left_data.is_number = false;
          formulaObj.left_data.is_data_source = false;
          formulaObj.left_data.is_data_source_data = false;
        } else if (is_data) {
          formulaObj.left_data.is_number = false;
          formulaObj.left_data.is_text = false;
          formulaObj.left_data.is_data_source = false;
          formulaObj.left_data.is_data_source_data = true;
          formulaObj.left_data.value = this.data_source_data.find(
            row => row.id == id
          ).dropdown;
          formulaObj.left_data.evaluated_id = this.data_source_data.find(
            row => row.id == id
          ).evaluated_id;
          formulaObj.left_data.question_id = id;
        } else {
          formulaObj.left_data.is_number = false;
          formulaObj.left_data.is_text = false;
          formulaObj.left_data.is_data_source = true;
          formulaObj.left_data.is_data_source_data = false;
          formulaObj.left_data.data_source = this.data_source_types.find(
            row => row.id == id
          ).label;
          formulaObj.left_data.data_source_id = id;
        }
      } else if (fromWhere == RowSides.RIGHT_ROW) {
        formulaObj.right_data.label = false;
        if (id == DataSourceTypes.NUMBER && !is_data) {
          formulaObj.right_data.is_number = true;
          formulaObj.right_data.is_text = false;
          formulaObj.right_data.is_data_source = false;
          formulaObj.right_data.is_data_source_data = false;
        } else if (id == DataSourceTypes.TEXT && !is_data) {
          formulaObj.right_data.is_number = false;
          formulaObj.right_data.is_text = true;
          formulaObj.right_data.is_data_source = false;
          formulaObj.right_data.is_data_source_data = false;
        } else if (is_data) {
          formulaObj.right_data.is_number = false;
          formulaObj.right_data.is_text = false;
          formulaObj.right_data.is_data_source = false;
          formulaObj.right_data.is_data_source_data = true;
          formulaObj.right_data.value = this.data_source_data.find(
            row => row.id == id
          ).dropdown;
          formulaObj.right_data.evaluated_id = this.data_source_data.find(
            row => row.id == id
          ).evaluated_id;
          formulaObj.right_data.question_id = id;
        } else {
          formulaObj.right_data.is_number = false;
          formulaObj.right_data.is_text = false;
          formulaObj.right_data.is_data_source = true;
          formulaObj.right_data.is_data_source_data = false;
          formulaObj.right_data.data_source = this.data_source_types.find(
            row => row.id == id
          ).label;
          formulaObj.right_data.data_source_id = id;
        }
      }
    }
  }

  changeMenuItemIFElseCondition1(event): void {
    const value = this.getValue(
      this.inputDataValues,
      event,
      event.currentPath || []
    );
    this.changeMenuItemIFElseCondition(
      event.id,
      event.fromWhere,
      event.index,
      event.is_heading,
      event.event,
      event.is_data,
      event.name,
      value
    );
  }

  changeMenuItemIFElseCondition(
    id,
    fromWhere,
    index,
    is_heading,
    event,
    is_data,
    name,
    inputData
  ): void {
    if (is_heading) {
      event.stopPropagation();
    } else {
      this.isformUpdated = true;
      let formulaObj;
      if (name == this.IfElseTypes.If) {
        formulaObj =
          inputData.row.condition.if_formula ||
          this.formula_object[index].condition.if_formula;
      } else if (name == this.IfElseTypes.Else) {
        formulaObj =
          inputData.row.condition.else_formula ||
          this.formula_object[index].condition.else_formula;
      }
      if (fromWhere == RowSides.LEFT_ROW) {
        formulaObj.left_data.label = false;
        if (id == DataSourceTypes.NUMBER && !is_data) {
          formulaObj.left_data.is_number = true;
          formulaObj.left_data.is_text = false;
          formulaObj.left_data.is_data_source = false;
          formulaObj.left_data.is_data_source_data = false;
        } else if (id == DataSourceTypes.TEXT && !is_data) {
          formulaObj.left_data.is_text = true;
          formulaObj.left_data.is_number = false;
          formulaObj.left_data.is_data_source = false;
          formulaObj.left_data.is_data_source_data = false;
        } else if (is_data) {
          formulaObj.left_data.is_number = false;
          formulaObj.left_data.is_text = false;
          formulaObj.left_data.is_data_source = false;
          formulaObj.left_data.is_data_source_data = true;
          formulaObj.left_data.value = this.data_source_data.find(
            row => row.id == id
          ).dropdown;
          formulaObj.left_data.evaluated_id = this.data_source_data.find(
            row => row.id == id
          ).evaluated_id;
          formulaObj.left_data.question_id = id;
        } else {
          formulaObj.left_data.is_text = false;
          formulaObj.left_data.is_number = false;
          formulaObj.left_data.is_data_source = true;
          formulaObj.left_data.is_data_source_data = false;
          formulaObj.left_data.data_source = this.data_source_types.find(
            row => row.id == id
          ).label;
          formulaObj.left_data.data_source_id = id;
        }
      } else if (fromWhere == RowSides.RIGHT_ROW) {
        formulaObj.right_data.label = false;
        if (id == DataSourceTypes.NUMBER && !is_data) {
          formulaObj.right_data.is_number = true;
          formulaObj.right_data.is_text = false;
          formulaObj.right_data.is_data_source = false;
          formulaObj.right_data.is_data_source_data = false;
        } else if (id == DataSourceTypes.TEXT && !is_data) {
          formulaObj.right_data.is_text = true;
          formulaObj.right_data.is_number = false;
          formulaObj.right_data.is_data_source = false;
          formulaObj.right_data.is_data_source_data = false;
        } else if (is_data) {
          formulaObj.right_data.is_text = false;
          formulaObj.right_data.is_number = false;
          formulaObj.right_data.is_data_source = false;
          formulaObj.right_data.is_data_source_data = true;
          formulaObj.right_data.value = this.data_source_data.find(
            row => row.id == id
          ).dropdown;
          formulaObj.right_data.evaluated_id = this.data_source_data.find(
            row => row.id == id
          ).evaluated_id;
          formulaObj.right_data.question_id = id;
        } else {
          formulaObj.right_data.is_text = false;
          formulaObj.right_data.is_number = false;
          formulaObj.right_data.is_data_source = true;
          formulaObj.right_data.is_data_source_data = false;
          formulaObj.right_data.data_source = this.data_source_types.find(
            row => row.id == id
          ).label;
          formulaObj.right_data.data_source_id = id;
        }
      }
    }
  }

  changeOperatorIFElseCondition1(event): void {
    const value = this.getValue(
      this.inputDataValues,
      event,
      event.currentPath || []
    );
    this.changeOperatorIFElseCondition(
      event.id,
      event.index,
      event.is_heading,
      event.event,
      event.name,
      value
    );
  }

  changeOperatorIFElseCondition(
    id,
    index,
    is_heading,
    event,
    name,
    inputData
  ): void {
    if (is_heading) {
      event.stopPropagation();
    } else {
      let formulaObj;
      if (name == this.IfElseTypes.If) {
        formulaObj =
          inputData.row.condition.if_formula ||
          this.formula_object[index].condition.if_formula;
      } else {
        formulaObj =
          inputData.row.condition.else_formula ||
          this.formula_object[index].condition.else_formula;
      }
      this.isformUpdated = true;
      const op = {
        operator_id: id,
        operator: this.operators.find(item => item.id == id).label,
      };
      formulaObj.operator = op;
    }
  }

  data_sourceData(event): void {
    this.data_source_data = event;
  }

  changeOperatorData(event): void {
    const value = this.getValue(
      this.inputDataValues,
      event,
      event.currentPath || []
    );
    if (event.from == 'fromIFBrackets') {
      this.changeComparisonOperator(
        event.id,
        event.index,
        event.is_heading,
        event.event,
        value
      );
    } else {
      this.changeOperator(event.id, event.index, event.is_heading, event.event);
    }
  }

  changeComparisonOperator(id, index, is_heading, event, value): void {
    if (is_heading) {
      event.stopPropagation();
    } else {
      this.isformUpdated = true;
      const op = {
        operator_id: id,
        operator: this.operatorsIfElse.find(item => item.id == id).label,
      };
      value.row.condition.operator = op;
    }
  }

  changeOperator(id, index, is_heading, event): void {
    if (is_heading) {
      event.stopPropagation();
    } else {
      this.isformUpdated = true;
      if (!this.formula_object[index].is_operator_row) {
        const op = {
          operator_id: id,
          operator: this.operators.find(item => item.id == id).label,
        };
        this.formula_object[index].operator = op;
      } else {
        this.formula_object[index].operator_id_row = id;
        this.formula_object[index].operator_value = this.operators.find(
          item => item.id == id
        ).label;
      }
    }
  }

  resetRow(index): void {
    this.inputDataValues = null;
    this.isformUpdated = true;
    this.ifElseRow = false;
    this.ifElseMultiRow = false;
    const default_row = {
      is_plus_button: true,
      row_type_id: null,
      is_add_row_button: false,
      is_operator_row: false,
      is_number_row: false,
      is_text_row: false,
      index: this.formula_object.length - 1,
    };
    this.formula_object[index] = default_row;

    if (this.formula_object.length == 1) {
      this.formula_object[1] = { ...default_row };
      this.formula_object[1].is_plus_button = false;
      this.formula_object[1].is_add_row_button = true;
      this.formula_object[1].index = 1;
    } else if (this.formula_object.length == 2) {
      this.formula_object[1] = { ...default_row };
      this.formula_object[1].is_plus_button = false;
      this.formula_object[1].is_add_row_button = true;
      this.formula_object[1].index = 1;
    }

    if (this.formula_object.length < 3) {
      this.row_types = this.row_type_alternative;
    } else {
      this.row_types = [...this.row_type_alternative_with_out_if_else];
    }

    if (this.ifElse) {
      this.formula_object[index + 1].is_add_row_button = true;
      this.ifElseRow = false;
      this.ifElse = false;
      this.if_formula = {
        row_type_id: null,
        is_number_row: null,
        is_text_row: null,
      };
      this.else_formula = {
        row_type_id: null,
        is_number_row: null,
        is_text_row: null,
      };
    }
  }

  deleteRow(index): void {
    this.inputDataValues = null;
    this.isformUpdated = true;
    this.ifElseMultiRow = false;
    this.ifElseRow = false;
    if (this.formula_object[index]) {
      if (index == 0 && this.formula_object.length <= 2) {
        this.formula_object.splice(index, 1);
      } else if (index == 0 && this.formula_object.length > 2) {
        this.formula_object.splice(index + 1, 1);
        this.formula_object.splice(index, 1);
      } else {
        this.formula_object.splice(index - 1, 1);
        this.formula_object.splice(index - 1, 1);
      }
    }

    if (!this.formula_object[0]) {
      const default_row = {
        is_plus_button: false,
        row_type_id: null,
        is_add_row_button: true,
        is_operator_row: false,
        is_number_row: false,
        is_text_row: false,
        index: 0,
      };
      this.formula_object[0] = default_row;
    }

    if (this.formula_object && this.formula_object.length == 1) {
      this.formula_object[0].is_add_row_button = true;
    }
    if (this.ifElse) {
      this.formula_object[index].is_add_row_button = true;
      this.ifElseRow = false;
      this.ifElse = false;
      this.if_formula = {
        row_type_id: null,
        is_number_row: null,
        is_text_row: null,
      };
      this.else_formula = {
        row_type_id: null,
        is_number_row: null,
        is_text_row: null,
      };
    }
    if (this.formula_object.length < 3) {
      this.row_types = this.row_type_alternative;
    }
  }

  getSourceData1(event): void {
    this.getSourceData(event.id, event.product_info_id, event);
  }

  getSourceData(id, product_info_id, event = null): void {
    this.data_source_data = [];
    if (id == DataSourceTypes.CUSTOM_CREATED_ATTRIBUTE) {
      if (this.data && this.data.stepper_form_detail_id) {
        this._apiCallService
          .getFormulaListByStepperId(this.data.stepper_form_detail_id)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            if (!data.HasErrors) {
              this.data_source_data = data.data;
              this.data_source_data = this.data_source_data.filter(
                dataValue => dataValue.id != this.data.id
              );
              if (event != null) {
                const currentPath =
                  event?.inputDataOriginal?.row?.currentPath ||
                  event?.inputDataOriginal?.currentPath ||
                  [];
                const value = this.getValue(
                  this.inputDataValues,
                  event,
                  currentPath
                );
                if (value) {
                  value.data_source_data = data.data;
                  value.data_source_data = value.data_source_data.filter(
                    dataValue => dataValue.id != this.data.id
                  );
                }
              }
            }
          });
      }
    } else {
      this._apiCallService
        .getDataSourceDetails(id, product_info_id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.data_source_data = data.data;
          if (event != null) {
            const currentPath =
              event?.inputDataOriginal?.row?.currentPath ||
              event?.inputDataOriginal?.currentPath ||
              [];
            const value = this.getValue(
              this.inputDataValues,
              event,
              currentPath
            );
            if (value) {
              value.data_source_data = data.data;
            }
          }
        });
    }
  }

  addUpdate(): void {
    this.objectList?.size ? this.saveFormula() : this.addUpdateFormula();
  }

  addUpdateFormula(): void {
    this.selectedAttribute = this.control.value;
    this.showWarningPopUp = false;
    if (this.formulaName && this.control.value) {
      const request = {
        data: {
          created_by: this.formula_id ? this.created_by : this._userId,
          evaluated_formula: '{}',
          formula_name: this.formulaName,
          skip_calculation: this.skip_calculation,
          id: this.formula_id,
          is_validated: false,
          stepper_form_detail_id: this.data.stepper_form_detail_id
            ? this.data.stepper_form_detail_id
            : null,
          target_variable_attribute: this.selectedAttribute,
          ui_formula:
            this.ifElseMultiRow || (this.isUpdate && this.inputDataValues)
              ? JSON.stringify(this.inputDataValues)
              : JSON.stringify(this.formula_object),
          updated_by: this.formula_id ? this._userId : null,
        },
      };
      this._apiCallService
        .addUpdateFormula(request)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.is_saved.emit(data.HasErrors);
            this.hideModal();
          }
          this.message.emit(data.message);
        });
    } else {
      if (!this.formulaName) {
        this.validateForm = true;
      }
      if (!this.control.value) {
        this.validateAttribute = true;
      }
    }
  }

  focusSearch(key): void {
    document.getElementById(key) && document.getElementById(key).focus();
  }

  calculateCustomFormula(): void {
    const selectedValues = [];
    this.selectedIndexes.forEach((value, key) => {
      selectedValues.push(value);
    });

    const request = {
      product_info_id: this.data.product_info_id,
      contract_id: Number(this.data.contractId),
      formula_id: this.data.id,
      stepper_form_detail_id: this.data.stepper_form_detail_id,
      product_modules: selectedValues,
      contract_insurer_object_id: this.data.contract_insurer_object_id,
    };

    this._apiCallService
      .evaluateFormulat(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.customEvaluatedValue = data.data;
        }
      });
  }

  saveFormula(): void {
    const selectedValues = [];
    this.selectedIndexes.forEach((value, key) => selectedValues.push(value));

    const request = {
      product_info_id: this.data.product_info_id,
      contract_id: Number(this.data.contractId),
      formula_id: this.data.id,
      stepper_form_detail_id: this.data.stepper_form_detail_id,
      product_modules: selectedValues,
      contract_insurer_object_id: this.data.contract_insurer_object_id,
    };

    this._apiCallService
      .saveFormula(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.message.emit(data.message);
          this.showFormulaPopup = false;
          this.hide.emit(false);
        }
      });
  }

  shouldEnableCalculateCustomButton(): boolean {
    return (
      this.objectList &&
      this.selectedIndexes &&
      this.objectList.size == this.selectedIndexes.size
    );
  }

  private _watchOnUserId(): void {
    this._userService.userId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((userId: string) => (this._userId = userId));
  }
}
