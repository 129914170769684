import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {
	parse(value: any): Date | null {
		if ((typeof value === 'string') && ((value.indexOf('.') > -1) || (value.indexOf('/') > -1))) {
			let str: any = [];
			if (value.indexOf('/') > -1) {
				str = value.split('/');
			}
			if (value.indexOf('.') > -1) {
				str = value.split('.');
			}
			const year = Number(str[2]);
			const month = Number(str[1]) - 1;
			const date = Number(str[0]);

			return new Date(year, month, date);
		}else{
			value = value.replace(/[\[\]&]+/g, '');
			if (value.length == 8){
				const year = Number(value.substr(4, 4));
				const month = Number(value.substr(2, 2)) - 1;
				const date = Number(value.substr(0, 2));
				return new Date(year, month, date);
			}else{
				return null;
			}
		}
	}

	format(date: Date, displayFormat: Object): string {
		date = new Date(Date.UTC(
			date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
			date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
		displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });
		const dtf = new Intl.DateTimeFormat('DE', displayFormat);
		let formattedDate = dtf.format(date).replace(/[\u200e\u200f]/g, '');

		if (/[a-zA-Z]/g.test(formattedDate)){
		  /* do something if letters are found in your string */
		} else {
			formattedDate = this.changeDisplayDate(formattedDate);
		}
		return formattedDate;
	}

	private changeDisplayDate(formattedDate?) {
		const str = formattedDate.split('.');
		const year = Number(str[2]);
		const month = Number(str[1]);
		const date = Number(str[0]);
		formattedDate = ('0' + date).slice(-2) + '.' + ('0' + (month)).slice(-2) + '.' + year;
		return formattedDate;
	}
}
