import { Component, OnInit } from '@angular/core';
import { SidebarService } from '@core/services/sidebar.service';
import {
  animateText,
  onMainContentChange,
  onSideNavChange,
} from '../../animations';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
@Component({
  selector: 'app-value-module',
  templateUrl: './value-module.component.html',
  styleUrls: ['./value-module.component.css'],
  animations: [onSideNavChange, animateText, onMainContentChange],
})
export class ValueModuleComponent extends BaseComponent implements OnInit {
  active = 'one';
  disabled = true;
  userDataValue: any;
  activeLable;
  addNewPage = false;
  isCustomerUser: boolean;
  divisionName: any;
  user_role: USER_ROLES;
  isICUser: boolean;
  onSideNavChange: boolean;
  sideNavState = true;
  linkText = true;
  pageBreadcrumbText = 'value-module.valueModule';
  isCustomerSelected = true;
  customerName$ = this._customerSharedService.selectedCustomerName$;

  constructor(
    private _sidenavService: SidebarService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
    this._sidenavService.sideNavState$
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => (this.onSideNavChange = res));
  }

  ngOnInit(): void {
    this._watchOnUserRole();
  }
  userDataReceived(data) {
    this.userDataValue = data;
    this.divisionName = data.user_profile.customer_details.name;
  }
  fetchName(lable) {
    this.activeLable = lable;
  }
  onSinenavToggle() {
    this.sideNavState = !this.sideNavState;

    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200);
    this._sidenavService.sideNavState$.next(this.sideNavState);
  }

  private _initRoleFields(): void {
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._initRoleFields();
      });
  }
}
