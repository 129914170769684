import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { STATIC_TEXT } from '@shared/constants/string.constants';
// let moment = require('moment-timezone');
@Injectable({
  providedIn: 'root',
})
export class DateTimeConvertService {
  constructor() {}
  convertToDateTime(dateTime: string) {
    if (dateTime) {
      return moment
        .tz(dateTime, 'Europe/Berlin')
        .format(STATIC_TEXT.DATE_FORMAT_WITH_TIME);
    } else {
      return STATIC_TEXT.EMPTY_STRING;
    }
  }
  convertToDate(date: string) {
    if (date) {
      return moment(date).format(STATIC_TEXT.DATE_FORMAT);
    } else {
      return STATIC_TEXT.EMPTY_STRING;
    }
  }
}
