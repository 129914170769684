import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, forkJoin, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ComponentCanDeactivate } from '@shared/models/component-can-deactivate.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { SalutationService } from '@core/services/salutation.service';
import { ShareDataService } from '@core/services/share-data.service';
import { ToastService } from '@core/services/toast.service';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { LOCALS } from '@shared/constants/local.constants';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { AZURE } from '@shared/constants/azure-service.constants';
import { REGEX } from '@shared/constants/general/regex.constants';
import { Country } from '@shared/interfaces/country.interface';
import { WidgetsApiService } from '@core/services/httpcalls/widgets-api.service';
import {
  WidgetAuthorization,
  Widget,
  WidgetId,
  WidgetAuthorizationId,
} from '@shared/models/widget.model';
import * as _ from 'lodash-es';
import { BaseComponent } from '@shared/components/base.component';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';

export interface Customer {
  name: string;
  id: number;
  liztu_vn_kdnr: number;
  type: string;
  has_admin: boolean;
  is_selected: Boolean;
  is_new_customer: boolean;
}
export interface Sparte {
  sparte: String;
  contracts: Contract[];
  panelOpenState: boolean;
  isCheckedAll: boolean;
  isFutureSelected: boolean;
  sparte_fixer_code: number;
}
export interface selectedCustomersWithData {
  liztu_vn_kdnr: number;
  sparte: Sparte[];
  is_saved: Boolean;
}
export interface Contract {
  contract_number: String;
  liztu_vs_idnr: number;
  is_authorised: boolean;
  object: String;
  product_name: string;
}
export interface User {
  customer_id: string;
  user_id: string;
  user_name: string;
  first_name: any;
  last_name: any;
  Telefon: string;
  email: string;
  customer: string;
  salutation: string;
  status: string;
  role: string;
  division: string;
  childCustomer: string;
  otp: string;
  house: string;
  street: string;
  zip_code: string;
  city: string;
  country: string;
  supported_languages: any;
  viaemail: string;
  vialetter: string;
  is_vip: boolean;
  is_invite_to_be_sent: boolean;
  is_invited_to_rcs: boolean;
  is_invite_reset: boolean;
  is_source_ivm: boolean;
  title: string;
  invitation_language: string;
  buttonCancelName: string;
  buttonConfirmName: string;
  newUser: boolean;
  otpTo: string;
  customer_address_lat_lon_set: boolean;
  lat: any;
  lon: any;
  address: String;
  customer_name: String;
  isLoggedIn: boolean;
  liztu_vn_kdnr: number;
  user_idnr: number;
  type: string;
  is_group: boolean;
  role_id: any;
}
export interface Contracts {
  contract_number: String;
  is_authorised: Boolean;
  liztu_vs_idnr: Number;
}
export interface CustomerWiseSparte {
  customer_name: string;
  sparte: Spartes[];
}
export interface Spartes {
  sparte_name: string;
  contracts: ContractName[];
}
export interface ContractName {
  contract_name: string;
}

type FormattedWidget = {
  id: WidgetId;
  name: string;
  isSelected: boolean;
  widgetAuthorizationId: WidgetAuthorizationId | null;
};

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.css'],
})
export class UpdateCustomerComponent
  extends BaseComponent
  implements OnInit, ComponentCanDeactivate, OnDestroy
{
  errorMessageArray = [];
  salutation = false;
  firstname = false;
  lastname = false;
  email = false;
  city = false;
  house = false;
  street = false;
  zip = false;
  customer = false;
  emailunique = false;
  showActive = false;
  langDisable = false;
  userInfoToggle = true;
  contractDetailsToggle = true;
  widgetConfogurationToggle = true;
  telefon_error = false;
  Nachname_validation: string;
  Vorname_validation: string;
  Telefon_validation: string;
  email_validation: string;
  Hausnummer_validation: string;
  Stadt_validation: string;
  strabe: string;
  postalCodeValidation: string;
  streetValidation: string;
  placeholder: any;
  emailvalidation: any;
  Alllocations: any;
  selectedaddress: any;
  postalregexppattern: any;
  customers: any[] = [];
  sparte: Sparte[] = [];
  User_role_list = [];
  filterlocation = [];
  otpTo = ['Email', 'Mobile'];
  country = '';
  data: User;
  userData;
  inviteUserData;
  inviter_username;
  local: LOCALS;
  mutterCustomer = false;
  user_role: USER_ROLES;
  customerRoles = false;
  addressChanged = false;
  resetUser = false;
  isTelephonefilled = false;
  is_group: boolean;
  lastSelectedCustomer: String;
  allSelectedCompanies = [];
  selectedCustomerLiztuVnKdnr: number;
  isCustomerSelected: Boolean = false;
  selectedCustomersWithData: selectedCustomersWithData[] = [];
  newSelectedCustomersWithData: selectedCustomersWithData[] = [];
  associatedCustomer = [];
  customerWiseSparte: CustomerWiseSparte[] = [];
  contractInformation: any[] = [];
  contractInformationNew: any[] = [];
  userId: any;
  userIdnr: any;
  isCustomerUser: boolean;
  isICUser: boolean;
  onlyOneCustomer = false;
  customerType: any;
  userDataValue: any;
  customerName: any;
  openUnsavedDialog = false;
  newCustomer = false;
  isCheckedAllContractsForThisCustomer = false;
  isCheckedAllFutureContractsForThisCustomer = false;
  customerDropdownVisible = true;
  isContractsSelected = false;
  clickEventsubscription: Subscription;
  isComingFromCanDeactivate = false;

  public keyUp = new Subject<string>();
  showList = false;
  selectedLoc = '';

  private readonly _widgets$: BehaviorSubject<FormattedWidget[]> =
    new BehaviorSubject([]);
  readonly selectedWidgets$ = this._widgets$
    .asObservable()
    .pipe(map(items => items.filter(widget => widget.isSelected)));
  readonly widgets$ = this._widgets$
    .asObservable()
    .pipe(
      map((items: FormattedWidget[]) =>
        items.filter(widget => !widget.isSelected)
      )
    );
  readonly countries$ = this.serviceCall
    .getCountries()
    .pipe(tap((data: Country[]) => (this._countries = data)));
  private _countries: Country[];
  private _userId: string;

  canDeactivate(): boolean {
    this.isComingFromCanDeactivate = true;
    this.checkChanges(
      this.selectedCustomersWithData,
      this.selectedCustomerLiztuVnKdnr,
      this.sparte
    );
    this.checkChanges(
      this.newSelectedCustomersWithData,
      this.selectedCustomerLiztuVnKdnr,
      this.sparte
    );
    this.isComingFromCanDeactivate = false;
    return !this.isContractsSelected;
  }

  constructor(
    private serviceCall: ApiCallsService,
    private router: Router,
    private salutationService: SalutationService,
    private shareservice: ShareDataService,
    private toastService: ToastService,
    private readonly _widgetsService: WidgetsApiService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();

    this.userData = this.router.getCurrentNavigation().extras.state;
    this.clickEventsubscription = this.shareservice
      .getCustomerClickEvent()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.SaveCustomer(this.selectedCustomerLiztuVnKdnr));
  }

  ngOnInit(): void {
    this._watchOnLocale();
    this._watchOnUserRole();
    this._watchOnUserId();
    if (this.userData.type == CUSTOMER_TYPES.MUTTER) {
      this.mutterCustomer = true;
    }
    if (this.userData.is_invite_reset) {
      this.resetUser = true;
    }
    this.loadUserRoles();
    this._initWidgets();

    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
  }

  userDataReceived(data): void {
    this.userDataValue = data;
    this.customerName = this.userDataValue.user_profile.customer_details.name;
    let salutation = this.salutationService.getSalutation(
      data.user_profile.user_data.salutation_id,
      this.local
    );
    salutation = salutation == undefined ? '' : salutation;
    this.inviter_username =
      salutation +
      ' ' +
      data.user_profile.user_data.first_name +
      ' ' +
      data.user_profile.user_data.last_name;
    this.userIdnr = data.user_profile.user_data.user_idnr;
    this.userId = data.user_profile.user_data.user_id;
    this.customerType = data.user_profile.customer_details.type;
  }

  getContracts(): void {
    this.serviceCall
      .getContractsForuser(this.data.user_idnr, this.userData.liztu_vn_kdnr)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => this.getContractsData(data.sparteWiseContracts));
  }

  getContractsData(data): void {
    for (let i = 0; i < data.length; i++) {
      let isChecked = false;
      isChecked = this.isCheckedAllContracts(data[i]);
      const sparte = this.setSparte(
        data[i].sparte,
        data[i].contracts,
        false,
        isChecked,
        data[i].include_for_future,
        data[i].sparte_fixer_code
      );
      this.sparte.push(sparte);
    }
    this.isCheckedAllContractsForThisCustomer =
      this.isCheckedAllContractsForCustomer(this.sparte);
    this.isCheckedAllFutureContractsForThisCustomer =
      this.isCheckedAllFutureContractsForCustomer(this.sparte);
  }

  isCheckedAllContracts(data): boolean {
    return data.contracts.every(val => val.is_authorised == true);
  }

  CheckAllOptions(sparte): void {
    if (sparte.contracts.every(val => val.is_authorised == true)) {
      sparte.contracts.forEach(value => (value.is_authorised = false));
      sparte.isCheckedAll = false;
    } else {
      sparte.contracts.forEach(value => (value.is_authorised = true));
      sparte.isCheckedAll = true;
    }
    this.isCheckedAllContractsForThisCustomer =
      this.isCheckedAllContractsForCustomer(this.sparte);
    this.isCheckedAllFutureContractsForThisCustomer =
      this.isCheckedAllFutureContractsForCustomer(this.sparte);
  }

  contractClick(contract, sparte): void {
    contract.is_authorised = !contract.is_authorised;
    if (sparte.contracts.every(val => val.is_authorised == true)) {
      sparte.isCheckedAll = true;
    }
    const result = sparte.contracts.find(val => val.is_authorised != true);
    sparte.isCheckedAll = result === undefined;
    this.isCheckedAllContractsForThisCustomer =
      this.isCheckedAllContractsForCustomer(this.sparte);
    this.isCheckedAllFutureContractsForThisCustomer =
      this.isCheckedAllFutureContractsForCustomer(this.sparte);
  }

  addWidget(id: WidgetId): void {
    const userIdForUpdating = +this.data.user_id;
    this._widgetsService
      .addWidgetAuthorization(userIdForUpdating, id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((widget: WidgetAuthorization) => {
        this._updateWidgetAuthorizationValue(id, widget.widgetAuthorizationId);
        this._changeIsSelectedValue(id, true);
      });
  }

  removeWidget(
    id: WidgetId,
    widgetAuthorizationId?: WidgetAuthorizationId
  ): void {
    if (widgetAuthorizationId) {
      this._widgetsService
        .removeWidgetAuthorization(widgetAuthorizationId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this._updateWidgetAuthorizationValue(id, null);
          this._changeIsSelectedValue(id, false);
        });
    }
  }

  validateLastname(e): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Nachname_validation') != -1
        ? true
        : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.NAME_VALIDATION);
    serchfind = regexp.test(e);
    this.Nachname_validation = 'Nachname_validation';
    this.lastname = !e.length || !serchfind;
    if (serchfind == false && isSalutation == false) {
      this.lastname = true;
      this.errorMessageArray.push(this.Nachname_validation);
    } else if (serchfind == false && isSalutation == true) {
      const index: number = this.errorMessageArray.indexOf(
        this.Nachname_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
        this.errorMessageArray.push(this.Nachname_validation);
      }
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Nachname_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  validateFirstname(e): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Vorname_validation') != -1 ? true : false;
    let serchfind: boolean;

    const regexp = new RegExp(REGEX.NAME_VALIDATION);

    serchfind = regexp.test(e);
    this.Vorname_validation = 'Vorname_validation';
    this.firstname = !e.length || !serchfind;
    if (serchfind == false && isSalutation == false) {
      this.firstname = true;
      this.errorMessageArray.push(this.Vorname_validation);
    } else if (serchfind == false && isSalutation == true) {
      const index: number = this.errorMessageArray.indexOf(
        this.Vorname_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
        this.errorMessageArray.push(this.Vorname_validation);
      }
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Vorname_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  validatePhoneNumber(e): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Telefon_validation') != -1 ? true : false;
    let serchfind: boolean;

    const regexp = new RegExp(REGEX.NON_EMPTY_STRING);

    serchfind = regexp.test(e);
    this.Telefon_validation = 'Telefon_validation';
    if (e.target.value.length == 0) {
      this.telefon_error = true;
    } else {
      this.telefon_error = false;
    }
    if (serchfind == false && isSalutation == false) {
      this.errorMessageArray.push(this.Telefon_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Telefon_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  CountryChangedEvent(input): void {
    this.placeholder = input.placeHolder;
  }

  validateEmail(e): void {
    let serchfind: boolean;
    const isEmail =
      this.errorMessageArray.indexOf('email_validation') != -1 ? true : false;
    const regexp = new RegExp(REGEX.EMAIL_VALIDATION);

    serchfind = regexp.test(e);
    this.email_validation = 'email_validation';
    if (e.length == 0 || serchfind == false) {
      this.email = true;
    } else {
      this.email = false;
      this.serviceCall
        .check_is_unique('emailId', e)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.status !== 'unique') {
            this.emailunique = true;
            this.emailvalidation = data.message;
          }
        });
    }
    if (serchfind == false && isEmail == false) {
      this.emailunique = false;
      this.email = true;
      this.errorMessageArray.push(this.email_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.email_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  getCustomers(): void {
    if (this.is_group == false) {
      this.serviceCall
        .getSubsidiaries(
          this.data.customer_id,
          this.data.user_idnr,
          this.data.role,
          this.data.is_source_ivm
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.associatedCustomer = data.associated_customers;
          this.onlyOneCustomer =
            this.associatedCustomer.length == 1 ? true : false;
          this.customers = [];
          this.getUserContracts(this.data.liztu_vn_kdnr);
          for (let i = 0; i < data.associated_customers.length; i++) {
            const customer: Customer = {
              id: null,
              name: '',
              liztu_vn_kdnr: null,
              type: '',
              has_admin: false,
              is_selected: false,
              is_new_customer: false,
            };
            customer.id = data.associated_customers[i].customer_id;
            customer.name = data.associated_customers[i].customer_name;
            customer.liztu_vn_kdnr = data.associated_customers[i].liztu_vn_kdnr;
            customer.type = data.associated_customers[i].type;
            customer.has_admin = data.associated_customers[i].has_admin;
            customer.is_selected = data.associated_customers[i].has_contracts;
            const tempcustomer = this.selectedCustomersWithData.find(
              obj =>
                obj.liztu_vn_kdnr == data.associated_customers[i].liztu_vn_kdnr
            );
            if (tempcustomer != undefined) {
              customer.is_selected =
                data.associated_customers[i].has_contracts == null
                  ? true
                  : data.associated_customers[i].has_contracts;
            }
            if (this.customers.length == 0) {
              this.customers.push(customer);
            } else {
              const index = this.customers.findIndex(
                obj => obj.liztu_vn_kdnr == customer.liztu_vn_kdnr
              );
              if (index == -1) {
                this.customers.push(customer);
              }
            }
          }
        });
    } else {
      this.serviceCall
        .getUniqueSubsidiaries(
          this.data.customer_id,
          this.is_group,
          this.user_role
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.onlyOneCustomer =
            data.associated_customers.length == 1 ? true : false;
          for (let i = 0; i < data.associated_customers.length; i++) {
            const customer: Customer = {
              id: null,
              name: '',
              liztu_vn_kdnr: null,
              type: '',
              has_admin: false,
              is_selected: false,
              is_new_customer: true,
            };
            customer.id = data.associated_customers[i].customer_id;
            customer.name = data.associated_customers[i].customer_name;
            customer.liztu_vn_kdnr = data.associated_customers[i].liztu_vn_kdnr;
            customer.type = data.associated_customers[i].type;
            customer.has_admin = data.associated_customers[i].has_admin;
            const tempcustomer = this.selectedCustomersWithData.find(
              obj =>
                obj.liztu_vn_kdnr == data.associated_customers[i].liztu_vn_kdnr
            );
            if (tempcustomer != undefined) {
              customer.is_selected = tempcustomer.is_saved;
            }
            if (this.customers.length == 0) {
              this.customers.push(customer);
            } else {
              const index = this.customers.findIndex(
                obj => obj.liztu_vn_kdnr == customer.liztu_vn_kdnr
              );
              if (index == -1) {
                this.customers.push(customer);
              }
            }
          }
        });
    }
  }

  loadUserRoles(): void {
    this.serviceCall
      .getUserRole()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.User_role_list = data));
  }

  autoComplete(value): void {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .subscribe(res => {
          this.Alllocations = res.results;
          this.filterAddressFromResults(this.Alllocations);
          this.showList = true;
        });
    }
  }

  filterAddressFromResults(Alllocations: any): void {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
  }

  onFocusOut(): void {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  selectedLocation(index): void {
    this.showList = false;
    this.selectedaddress = [this.filterlocation[index]];
    this.selectedLoc = this.filterlocation[index].freeformAddress;
    if (this.selectedaddress.length == 1) {
      if (this.selectedaddress[0].streetNumber !== undefined) {
        this.data.house = this.selectedaddress[0].streetNumber;
        this.house = false;
      } else {
        this.data.house = '';
      }
      this.data.address = this.selectedLoc ?? '';

      if (
        this.selectedaddress[0].streetName !== undefined ||
        this.selectedaddress[0].municipalitySubdivision !== undefined
      ) {
        this.data.street =
          this.selectedaddress[0].streetName ??
          this.selectedaddress[0].municipalitySubdivision;
        this.street = false;
      } else {
        this.data.street = '';
      }

      if (this.selectedaddress[0].postalCode !== undefined) {
        this.data.zip_code = this.selectedaddress[0].postalCode;
        this.zip = false;
      } else {
        this.data.zip_code = '';
      }

      if (this.selectedaddress[0].localName !== undefined) {
        this.data.city = this.selectedaddress[0].localName;
        this.city = false;
      } else {
        this.data.city = '';
      }
      this.data.country = this.selectedaddress[0].country ?? '';
      for (let i = 0; i < this.Alllocations.length; i++) {
        this.data.lat = this.Alllocations[i].position.lat ?? '';
        this.data.lon = this.Alllocations[i].position.lon ?? '';
      }
      this.showActive = this.data.lat !== '' && this.data.lon !== '';
    }
  }

  validateTown(e): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Stadt_validation') != -1 ? true : false;
    let serchfind: boolean;

    const regexp = new RegExp(REGEX.TOWN_VALIDATION);
    serchfind = regexp.test(e);
    this.Stadt_validation = 'Stadt_validation';
    this.city = !e.length || !serchfind;
    if (serchfind == false && isSalutation == false) {
      this.city = true;
      this.errorMessageArray.push(this.Stadt_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Stadt_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  validateCountry(e): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Land_validation') != -1;
    let serchfind: boolean;

    const regexp = new RegExp(REGEX.COUNTRY_VALIDATION);

    serchfind = regexp.test(e);
    const Land_validation = 'Land_validation';
    if (serchfind == false && isSalutation == false) {
      this.errorMessageArray.push(Land_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(Land_validation);

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  validateHouseNumber(e): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Hausnummer_validation') != -1;
    let serchfind: boolean;

    const regexp = new RegExp(REGEX.NON_EMPTY_STRING);

    serchfind = regexp.test(e);
    this.Hausnummer_validation = 'Hausnummer_validation';
    this.house = !e.length || !serchfind;

    if (serchfind == false && isSalutation == false) {
      this.house = false;
      this.errorMessageArray.push(this.Hausnummer_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Hausnummer_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  validateStreet(e): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Straße_validation') != -1;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.NON_EMPTY_STRING);
    serchfind = regexp.test(e);
    this.streetValidation = 'Straße_validation';
    this.strabe = this.streetValidation;
    this.street = !e.length || !serchfind;

    if (serchfind == false && isSalutation == false) {
      this.street = true;
      this.errorMessageArray.push(this.streetValidation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.streetValidation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  onCountrySelected(event): void {
    const selected = event;
    for (let j = 0; j < this._countries.length; j++) {
      if (selected === this._countries[j].countryName) {
        this.postalregexppattern = this._countries[j].postalCodeRegex;
        const zip_val = this.data.zip_code;
        this.validatePostalCode(zip_val, selected);
      }
    }
  }

  findCountries(country): boolean {
    return country.countryName === 'Germany';
  }

  validatePostalCode(e, country): void {
    const isSalutation =
      this.errorMessageArray.indexOf('Postleitzahl_validation') != -1
        ? true
        : false;
    let serchfind: boolean;
    for (let j = 0; j < this._countries.length; j++) {
      if (country === this._countries[j].countryName) {
        this.postalregexppattern = this._countries[j].postalCodeRegex;
      }
    }
    const regexp = new RegExp(this.postalregexppattern);
    serchfind = regexp.test(e);
    this.postalCodeValidation = 'Postleitzahl_validation';
    if (e.length == 0 || serchfind == false) {
      this.zip = true;
      this.errorMessageArray.push(this.postalCodeValidation);
    } else {
      this.zip = false;
    }

    if (serchfind == false && isSalutation == false) {
      this.zip = true;
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.postalCodeValidation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  saveCustomer(data, sparte, selectedCustomerLiztuVnKdnr) {
    const userDetails = data;
    if (
      data.customer_name == '' ||
      data.first_name == '' ||
      data.last_name == '' ||
      data.email == '' ||
      data.salutation == '' ||
      data.house == '' ||
      data.street == '' ||
      data.zip_code == '' ||
      data.city == '' ||
      this.zip == true
    ) {
      if (data.first_name == '') {
        this.firstname = true;
        this.Vorname_validation = 'Vorname_validation';
      }
      if (data.customer_name == '') {
        this.customer = true;
      }
      if (data.salutation == '') {
        this.salutation = true;
      }
      if (data.last_name == '') {
        this.lastname = true;
        this.Nachname_validation = 'Nachname_validation';
      }
      if (data.email == '') {
        this.email = true;
        this.email_validation = 'email_validation';
      }
      if (data.house == '') {
        this.house = true;
        this.Hausnummer_validation = 'Hausnummer_validation';
      }
      if (data.street == '') {
        this.street = true;
        this.streetValidation = 'Straße_validation';
        this.strabe = this.streetValidation;
      }
      if (data.zip_code == '') {
        this.zip = true;
        this.postalCodeValidation = 'Postleitzahl_validation';
      }
      if (data.city == '') {
        this.city = true;
        this.Stadt_validation = 'Stadt_validation';
      }
    } else {
      this.contractInformation = [];
      this.contractInformationNew = [];
      this.checkChanges(
        this.selectedCustomersWithData,
        selectedCustomerLiztuVnKdnr,
        sparte
      );
      this.checkChanges(
        this.newSelectedCustomersWithData,
        selectedCustomerLiztuVnKdnr,
        sparte
      );

      const selectedCompaniesIndex = this.selectedCustomersWithData.findIndex(
        obj => obj.liztu_vn_kdnr == selectedCustomerLiztuVnKdnr
      );
      const newSelectedCompaniesIndex =
        this.newSelectedCustomersWithData.findIndex(
          obj => obj.liztu_vn_kdnr == selectedCustomerLiztuVnKdnr
        );
      if (selectedCompaniesIndex == -1 && newSelectedCompaniesIndex == -1) {
        for (let i = 0; i < sparte.length; i++) {
          for (let j = 0; j < sparte[i].contracts.length; j++) {
            if (sparte[i].contracts[j].is_authorised == true) {
              this.openUnsavedDialog = true;
              break;
            }
          }
        }
      }
      if (this.openUnsavedDialog == false) {
        for (let i = 0; i < this.selectedCustomersWithData.length; i++) {
          const sparteWiseContracts: any[] = [];
          for (
            let j = 0;
            j < this.selectedCustomersWithData[i].sparte.length;
            j++
          ) {
            const contracts: any[] = [];
            for (
              let k = 0;
              k < this.selectedCustomersWithData[i].sparte[j].contracts.length;
              k++
            ) {
              const contract: any = {
                liztu_vs_idnr:
                  this.selectedCustomersWithData[i].sparte[j].contracts[k]
                    .liztu_vs_idnr,
                is_authorised:
                  this.selectedCustomersWithData[i].sparte[j].contracts[k]
                    .is_authorised,
              };
              contracts.push(contract);
            }
            sparteWiseContracts.push({
              sparte_fixer_code:
                this.selectedCustomersWithData[i].sparte[j].sparte_fixer_code,
              include_for_future:
                this.selectedCustomersWithData[i].sparte[j].isFutureSelected,
              contracts,
            });
          }
          const contractInformation: any = {
            liztu_vn_kdnr: this.selectedCustomersWithData[i].liztu_vn_kdnr,
            sparteWiseContracts,
          };
          this.contractInformation.push(contractInformation);
          for (let i = 0; i < this.newSelectedCustomersWithData.length; i++) {
            const sparteWiseContracts: any[] = [];
            for (
              let j = 0;
              j < this.newSelectedCustomersWithData[i].sparte.length;
              j++
            ) {
              const contracts: any[] = [];
              for (
                let k = 0;
                k <
                this.newSelectedCustomersWithData[i].sparte[j].contracts.length;
                k++
              ) {
                const contract: any = {
                  liztu_vs_idnr:
                    this.newSelectedCustomersWithData[i].sparte[j].contracts[k]
                      .liztu_vs_idnr,
                  is_authorised:
                    this.newSelectedCustomersWithData[i].sparte[j].contracts[k]
                      .is_authorised,
                };
                contracts.push(contract);
              }
              sparteWiseContracts.push({
                sparte_fixer_code:
                  this.newSelectedCustomersWithData[i].sparte[j]
                    .sparte_fixer_code,
                include_for_future:
                  this.newSelectedCustomersWithData[i].sparte[j]
                    .isFutureSelected,
                contracts,
              });
            }
            const contractInformation: any = {
              liztu_vn_kdnr: this.newSelectedCustomersWithData[i].liztu_vn_kdnr,
              sparteWiseContracts,
              include_for_future: this._customerSharedService.includeForFuture,
            };
            const index = this.contractInformationNew.findIndex(
              obj =>
                obj.liztu_vn_kdnr ==
                this.newSelectedCustomersWithData[i].liztu_vn_kdnr
            );
            index !== -1
              ? (this.contractInformationNew[index] = contractInformation)
              : this.contractInformationNew.push(contractInformation);
          }
        }
        this.contractInformation = [...this.contractInformationNew];
        userDetails.salutation_id = this.salutationService.getSalutationId(
          userDetails.salutation,
          this.local
        );
        const selectedWidgets = this._widgets$.value
          .filter(item => item.isSelected)
          .map(({ isSelected, id }) => ({
            is_authorised: isSelected,
            widget_id: id,
          }));
        if (this.isICUser) {
          this.inviteUserData = {
            address: userDetails.address,
            city: userDetails.city,
            customer_id: userDetails.customer_id,
            customer_name: userDetails.customer_name,
            country: userDetails.country,
            user_id: userDetails.user_id,
            user_name: userDetails.user_name,
            email: userDetails.email,
            first_name: userDetails.first_name,
            house: userDetails.house,
            invitation_language: null,
            is_email_sent: userDetails.is_invite_to_be_sent,
            is_invited_to_rcs: userDetails.is_invited_to_rcs,
            is_source_ivm: false,
            is_temporary: true,
            is_updated: true,
            is_vip: userDetails.is_vip,
            last_name: userDetails.last_name,
            lat: userDetails.lat,
            lon: userDetails.lon,
            mfa_mode: userDetails.otpTo == 'Mobile' ? 1 : 2,
            role: userDetails.role,
            salutation_id: userDetails.salutation_id,
            status: userDetails.status == 'Eingeladen' ? true : false,
            street: userDetails.street,
            telefon: userDetails.Telefon,
            type: userDetails.type,
            username: userDetails.user_name,
            widgets_information: selectedWidgets,
            contract_information: this.contractInformation,
            // contract_information_new: this.contractInformationNew,
            zip_code: userDetails.zip_code,
            inviter_username: this.inviter_username,
            is_invite_reset: false,
            actor_id: this.userId,
            actor_role: this.user_role,
            actor_customer_type: null,
          };
        }
        if (this.isCustomerUser) {
          this.inviteUserData = {
            address: userDetails.address,
            city: userDetails.city,
            customer_id: userDetails.customer_id,
            customer_name: userDetails.customer_name,
            country: userDetails.country,
            user_id: userDetails.user_id,
            user_name: userDetails.user_name,
            email: userDetails.email,
            first_name: userDetails.first_name,
            house: userDetails.house,
            invitation_language: null,
            is_email_sent: userDetails.is_invite_to_be_sent,
            is_invited_to_rcs: userDetails.is_invited_to_rcs,
            is_source_ivm: false,
            is_temporary: true,
            is_updated: true,
            is_vip: userDetails.is_vip,
            last_name: userDetails.last_name,
            lat: userDetails.lat,
            lon: userDetails.lon,
            mfa_mode: userDetails.otpTo == 'Mobile' ? 1 : 2,
            role: userDetails.role,
            salutation_id: userDetails.salutation_id,
            status: userDetails.status == 'Eingeladen' ? true : false,
            street: userDetails.street,
            telefon: userDetails.Telefon,
            type: userDetails.type,
            username: userDetails.user_name,
            widgets_information: selectedWidgets,
            contract_information: this.contractInformation,
            // contract_information_new: this.contractInformationNew,
            zip_code: userDetails.zip_code,
            inviter_username: this.inviter_username,
            is_invite_reset: false,
            actor_id: this.userIdnr,
            actor_role: this.user_role,
            actor_customer_type: this.customerType,
          };
        }
        this.serviceCall
          .updateInviteUser(this.inviteUserData)
          .pipe(takeUntil(this._destroy$))
          .subscribe(result => {
            this.toastService.showToastMessage(document, result.message);
            this.newSelectedCustomersWithData = [];
            this.lastSelectedCustomer = this.data.customer_name;
            this.selectedCustomerLiztuVnKdnr = this.data.liztu_vn_kdnr;
            this.data.is_group = false;
            this.is_group = false;
            this.sparte = [];
            this.customers = [];
            this.getCustomers();
            this.getContracts();
          });
      }
    }
  }

  onaddressChange(): void {
    this.addressChanged = true;
    this.showActive = false;
    this.data.lat = null;
    this.data.lon = null;
    this.data.customer_address_lat_lon_set = null;
  }

  mobileChange(Telefon): void {
    if (Telefon == '') {
      this.data.otpTo = 'Email';
    }
  }

  customerClick(sparte, selectedCustomerLiztuVnKdnr): void {
    this.customerDropdownVisible = false;
    this.checkChanges(
      this.selectedCustomersWithData,
      selectedCustomerLiztuVnKdnr,
      sparte
    );
    this.checkChanges(
      this.newSelectedCustomersWithData,
      selectedCustomerLiztuVnKdnr,
      sparte
    );
    this.customerDropdownVisible = true;
  }

  customerChange(cust): void {
    if (this.data.is_group == true) {
      this.lastSelectedCustomer = cust.name;
      this.selectedCustomerLiztuVnKdnr = cust.liztu_vn_kdnr;
      const customer = this.selectedCustomersWithData.find(
        obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
      );
      this.isCustomerSelected =
        customer == undefined ? false : customer.is_saved;
      this.selectedCustomerLiztuVnKdnr = cust.liztu_vn_kdnr;
      const index = this.selectedCustomersWithData.findIndex(
        obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
      );
      const index1 = this.newSelectedCustomersWithData.findIndex(
        obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
      );
      const associatedIndex = this.associatedCustomer.findIndex(
        obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
      );
      if (index == -1 && associatedIndex == -1 && index1 == -1) {
        this.getCustomerWiseContracts(cust.liztu_vn_kdnr);
      } else if (
        index != -1 &&
        (associatedIndex == -1 || associatedIndex != -1)
      ) {
        this.sparte = [];
        for (
          let j = 0;
          j < this.selectedCustomersWithData[index].sparte.length;
          j++
        ) {
          const contracts: Contract[] = [];
          for (
            let k = 0;
            k <
            this.selectedCustomersWithData[index].sparte[j].contracts.length;
            k++
          ) {
            const contract = this.setContracts(
              this.selectedCustomersWithData[index].sparte[j].contracts[k]
                .contract_number,
              this.selectedCustomersWithData[index].sparte[j].contracts[k]
                .liztu_vs_idnr,
              this.selectedCustomersWithData[index].sparte[j].contracts[k]
                .is_authorised,
              this.selectedCustomersWithData[index].sparte[j].contracts[k]
                .product_name,
              this.selectedCustomersWithData[index].sparte[j].contracts[k]
                .object
            );
            contracts.push(contract);
          }
          let isChecked = false;
          isChecked = this.isCheckedAllContracts(
            this.selectedCustomersWithData[index].sparte[j]
          );
          const sparte = this.setSparte(
            this.selectedCustomersWithData[index].sparte[j].sparte,
            contracts,
            false,
            isChecked,
            this.selectedCustomersWithData[index].sparte[j].isFutureSelected,
            this.selectedCustomersWithData[index].sparte[j].sparte_fixer_code
          );
          this.sparte.push(sparte);
        }
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(this.sparte);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(this.sparte);
        const resultIndex = this.customers.findIndex(
          obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
        );
        if (resultIndex != -1) {
          this.customers[resultIndex].is_selected =
            this.checkAnyContractIsSelected(this.sparte);
        }
      } else if (index == -1 && associatedIndex != -1) {
        this.sparte = [];
        this.serviceCall
          .getContractsForuser(this.data.user_idnr, cust.liztu_vn_kdnr)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => this.getContractsData(data.sparteWiseContracts));
      } else if (index == -1 && index1 != -1) {
        this.sparte = [];
        for (
          let j = 0;
          j < this.newSelectedCustomersWithData[index1].sparte.length;
          j++
        ) {
          const contracts: Contract[] = [];
          for (
            let k = 0;
            k <
            this.newSelectedCustomersWithData[index1].sparte[j].contracts
              .length;
            k++
          ) {
            const contract = this.setContracts(
              this.newSelectedCustomersWithData[index1].sparte[j].contracts[k]
                .contract_number,
              this.newSelectedCustomersWithData[index1].sparte[j].contracts[k]
                .liztu_vs_idnr,
              this.newSelectedCustomersWithData[index1].sparte[j].contracts[k]
                .is_authorised,
              this.newSelectedCustomersWithData[index1].sparte[j].contracts[k]
                .product_name,
              this.newSelectedCustomersWithData[index1].sparte[j].contracts[k]
                .object
            );
            contracts.push(contract);
          }
          let isChecked = false;
          isChecked = this.isCheckedAllContracts(
            this.newSelectedCustomersWithData[index1].sparte[j]
          );
          const sparte = this.setSparte(
            this.newSelectedCustomersWithData[index1].sparte[j].sparte,
            contracts,
            false,
            isChecked,
            this.newSelectedCustomersWithData[index1].sparte[j]
              .isFutureSelected,
            this.newSelectedCustomersWithData[index1].sparte[j]
              .sparte_fixer_code
          );
          this.sparte.push(sparte);
        }
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(this.sparte);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(this.sparte);
        const resultIndex = this.customers.findIndex(
          obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
        );
        if (resultIndex != -1) {
          this.customers[resultIndex].is_selected =
            this.checkAnyContractIsSelected(this.sparte);
        }
      }
    } else {
      this.sparte = [];
      this.lastSelectedCustomer = cust.name;
      this.selectedCustomerLiztuVnKdnr = cust.liztu_vn_kdnr;
      const spartes: Sparte[] = [];
      const ind = this.selectedCustomersWithData.findIndex(
        obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
      );
      if (ind == -1) {
        this.serviceCall
          .getContractsForuser(this.data.user_idnr, cust.liztu_vn_kdnr)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            for (let j = 0; j < data.sparteWiseContracts.length; j++) {
              const contracts: Contract[] = [];
              for (
                let k = 0;
                k < data.sparteWiseContracts[j].contracts.length;
                k++
              ) {
                const contract = this.setContracts(
                  data.sparteWiseContracts[j].contracts[k].contract_number,
                  data.sparteWiseContracts[j].contracts[k].liztu_vs_idnr,
                  data.sparteWiseContracts[j].contracts[k].is_authorised,
                  data.sparteWiseContracts[j].contracts[k].product_name,
                  data.sparteWiseContracts[j].contracts[k].object
                );
                contracts.push(contract);
              }
              let isChecked = false;
              isChecked = this.isCheckedAllContracts(
                data.sparteWiseContracts[j]
              );
              const sparte = this.setSparte(
                data.sparteWiseContracts[j].sparte,
                contracts,
                false,
                isChecked,
                data.sparteWiseContracts[j].include_for_future,
                data.sparteWiseContracts[j].sparte_fixer_code
              );
              this.sparte.push(sparte);
            }
            for (let j = 0; j < this.sparte.length; j++) {
              const contracts: Contract[] = [];
              for (let k = 0; k < this.sparte[j].contracts.length; k++) {
                const contract = this.setContracts(
                  this.sparte[j].contracts[k].contract_number,
                  this.sparte[j].contracts[k].liztu_vs_idnr,
                  this.sparte[j].contracts[k].is_authorised,
                  this.sparte[j].contracts[k].product_name,
                  this.sparte[j].contracts[k].object
                );
                contracts.push(contract);
              }
              let isChecked = false;
              isChecked = this.isCheckedAllContracts(this.sparte[j]);
              const sparte = this.setSparte(
                this.sparte[j].sparte,
                contracts,
                false,
                isChecked,
                this.sparte[j].isFutureSelected,
                this.sparte[j].sparte_fixer_code
              );
              spartes.push(sparte);
            }
            const selectedCustomer: selectedCustomersWithData = {
              liztu_vn_kdnr: cust.liztu_vn_kdnr,
              sparte: spartes,
              is_saved: false,
            };
            this.isCheckedAllContractsForThisCustomer =
              this.isCheckedAllContractsForCustomer(this.sparte);
            this.isCheckedAllFutureContractsForThisCustomer =
              this.isCheckedAllFutureContractsForCustomer(this.sparte);
            const resultIndex = this.customers.findIndex(
              obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
            );
            if (resultIndex != -1) {
              this.customers[resultIndex].is_selected =
                this.checkAnyContractIsSelected(this.sparte);
            }
            const tempIndex = this.selectedCustomersWithData.findIndex(
              obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
            );
            if (tempIndex == -1) {
              this.selectedCustomersWithData.push(selectedCustomer);
            } else {
              this.selectedCustomersWithData[tempIndex] = selectedCustomer;
            }
          });
      } else {
        for (
          let j = 0;
          j < this.selectedCustomersWithData[ind].sparte.length;
          j++
        ) {
          const contracts: Contract[] = [];
          for (
            let k = 0;
            k < this.selectedCustomersWithData[ind].sparte[j].contracts.length;
            k++
          ) {
            const contract = this.setContracts(
              this.selectedCustomersWithData[ind].sparte[j].contracts[k]
                .contract_number,
              this.selectedCustomersWithData[ind].sparte[j].contracts[k]
                .liztu_vs_idnr,
              this.selectedCustomersWithData[ind].sparte[j].contracts[k]
                .is_authorised,
              this.selectedCustomersWithData[ind].sparte[j].contracts[k]
                .product_name,
              this.selectedCustomersWithData[ind].sparte[j].contracts[k].object
            );
            contracts.push(contract);
          }
          let isChecked = false;
          isChecked = this.isCheckedAllContracts(
            this.selectedCustomersWithData[ind].sparte[j]
          );
          const sparte = this.setSparte(
            this.selectedCustomersWithData[ind].sparte[j].sparte,
            contracts,
            false,
            isChecked,
            this.selectedCustomersWithData[ind].sparte[j].isFutureSelected,
            this.selectedCustomersWithData[ind].sparte[j].sparte_fixer_code
          );
          this.sparte.push(sparte);
        }
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(this.sparte);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(this.sparte);
        const resultIndex = this.customers.findIndex(
          obj => obj.liztu_vn_kdnr == cust.liztu_vn_kdnr
        );
        if (resultIndex != -1) {
          this.customers[resultIndex].is_selected =
            this.checkAnyContractIsSelected(this.sparte);
        }
      }
    }
  }

  isGroupChange(): void {
    if (this.is_group == true) {
      this.lastSelectedCustomer = this.data.customer_name;
      this.selectedCustomerLiztuVnKdnr = this.data.liztu_vn_kdnr;
      const index = this.selectedCustomersWithData.findIndex(
        obj => obj.liztu_vn_kdnr == this.selectedCustomerLiztuVnKdnr
      );
      this.sparte = [];
      for (
        let j = 0;
        j < this.selectedCustomersWithData[index].sparte.length;
        j++
      ) {
        const contracts: Contract[] = [];
        for (
          let k = 0;
          k < this.selectedCustomersWithData[index].sparte[j].contracts.length;
          k++
        ) {
          const contract = this.setContracts(
            this.selectedCustomersWithData[index].sparte[j].contracts[k]
              .contract_number,
            this.selectedCustomersWithData[index].sparte[j].contracts[k]
              .liztu_vs_idnr,
            this.selectedCustomersWithData[index].sparte[j].contracts[k]
              .is_authorised,
            this.selectedCustomersWithData[index].sparte[j].contracts[k]
              .product_name,
            this.selectedCustomersWithData[index].sparte[j].contracts[k].object
          );
          contracts.push(contract);
        }
        let isChecked = false;
        isChecked = this.isCheckedAllContracts(
          this.selectedCustomersWithData[index].sparte[j]
        );
        const sparte = this.setSparte(
          this.selectedCustomersWithData[index].sparte[j].sparte,
          contracts,
          false,
          isChecked,
          this.selectedCustomersWithData[index].sparte[j].isFutureSelected,
          this.selectedCustomersWithData[index].sparte[j].sparte_fixer_code
        );
        this.sparte.push(sparte);
      }
      this.isCheckedAllContractsForThisCustomer =
        this.isCheckedAllContractsForCustomer(this.sparte);
      this.isCheckedAllFutureContractsForThisCustomer =
        this.isCheckedAllFutureContractsForCustomer(this.sparte);
      const resultIndex = this.customers.findIndex(
        obj => obj.liztu_vn_kdnr == this.selectedCustomerLiztuVnKdnr
      );
      if (resultIndex != -1) {
        this.customers[resultIndex].is_selected =
          this.checkAnyContractIsSelected(this.sparte);
      }
      const tempLiztuArray = [];
      this.newSelectedCustomersWithData.forEach(function (obj) {
        obj.is_saved == true;
        tempLiztuArray.push(obj.liztu_vn_kdnr);
      });
      // This code is for removing new companies whose contracts are not selected And
      // is group checkbox is unselected.
      for (let i = 0; i < this.customers.length; i++) {
        const ind = this.customers.findIndex(function (obj) {
          if (obj.is_new_customer == true) {
            return obj;
          }
        });
        this.customers.splice(ind, 1);
      }
      this.is_group = false;
    } else {
      this.is_group = true;
      this.getCustomers();
    }
  }

  getCustomerWiseContracts(liztu_vn_kdnr): void {
    this.sparte = [];
    const spartes: Sparte[] = [];
    this.serviceCall
      .getContractsForCustomer(liztu_vn_kdnr)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        for (let j = 0; j < data.sparteWiseContracts.length; j++) {
          const contracts: Contract[] = [];
          for (
            let k = 0;
            k < data.sparteWiseContracts[j].contracts.length;
            k++
          ) {
            const contract = this.setContracts(
              data.sparteWiseContracts[j].contracts[k].contract_number,
              data.sparteWiseContracts[j].contracts[k].liztu_vs_idnr,
              data.sparteWiseContracts[j].contracts[k].is_authorised,
              data.sparteWiseContracts[j].contracts[k].product_name,
              data.sparteWiseContracts[j].contracts[k].object
            );
            contracts.push(contract);
          }
          let isChecked = false;
          isChecked = this.isCheckedAllContracts(data.sparteWiseContracts[j]);
          const sparte = this.setSparte(
            data.sparteWiseContracts[j].sparte,
            contracts,
            false,
            isChecked,
            data.sparteWiseContracts[j].include_for_future,
            data.sparteWiseContracts[j].sparte_fixer_code
          );
          spartes.push(sparte);
        }
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(spartes);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(spartes);
        const selectedCustomer: selectedCustomersWithData = {
          liztu_vn_kdnr,
          sparte: spartes,
          is_saved: false,
        };
        const index = this.newSelectedCustomersWithData.findIndex(
          obj => obj.liztu_vn_kdnr == liztu_vn_kdnr
        );
        if (index == -1) {
          this.newSelectedCustomersWithData.push(selectedCustomer);
        } else {
          this.newSelectedCustomersWithData[index] = selectedCustomer;
        }
        this.getContractsData(data.sparteWiseContracts);
      });
  }

  includeFutureContractsClick(sparte): void {
    sparte.isFutureSelected = !sparte.isFutureSelected;
    const result = this.sparte.every(obj => obj.isFutureSelected == true);
    this.isCheckedAllFutureContractsForThisCustomer = result;
  }

  SaveCustomer(selectedCustomerLiztuVnKdnr): void {
    this.customers.forEach(obj => {
      if (
        selectedCustomerLiztuVnKdnr == obj.liztu_vn_kdnr &&
        obj.is_new_customer == false
      ) {
        obj.is_selected = this.checkAnyContractIsSelected(this.sparte);
        const spartes: Sparte[] = [];
        for (let j = 0; j < this.sparte.length; j++) {
          const contracts: Contract[] = [];
          for (let k = 0; k < this.sparte[j].contracts.length; k++) {
            const contract = this.setContracts(
              this.sparte[j].contracts[k].contract_number,
              this.sparte[j].contracts[k].liztu_vs_idnr,
              this.sparte[j].contracts[k].is_authorised,
              this.sparte[j].contracts[k].product_name,
              this.sparte[j].contracts[k].object
            );
            contracts.push(contract);
          }
          let isChecked = false;
          isChecked = this.isCheckedAllContracts(this.sparte[j]);
          const sparte = this.setSparte(
            this.sparte[j].sparte,
            contracts,
            false,
            isChecked,
            this.sparte[j].isFutureSelected,
            this.sparte[j].sparte_fixer_code
          );
          spartes.push(sparte);
        }
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(spartes);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(spartes);
        const selectedCustomer: selectedCustomersWithData = {
          liztu_vn_kdnr: selectedCustomerLiztuVnKdnr,
          sparte: spartes,
          is_saved: true,
        };
        const resultIndex = this.customers.findIndex(
          obj => obj.liztu_vn_kdnr == selectedCustomerLiztuVnKdnr
        );
        if (resultIndex != -1) {
          this.customers[resultIndex].is_selected =
            this.checkAnyContractIsSelected(this.sparte);
        }
        if (this.selectedCustomersWithData.length !== 0) {
          const x = this.selectedCustomersWithData.findIndex(
            obj => obj.liztu_vn_kdnr == selectedCustomerLiztuVnKdnr
          );
          if (x >= 0) {
            this.isCustomerSelected = true;
            this.selectedCustomersWithData[x] = selectedCustomer;
            this.openUnsavedDialog = false;
          } else {
            this.isCustomerSelected = true;
            this.selectedCustomersWithData.push(selectedCustomer);
            this.openUnsavedDialog = false;
          }
        } else {
          this.isCustomerSelected = true;
          this.selectedCustomersWithData.push(selectedCustomer);
          this.openUnsavedDialog = false;
        }
      } else if (
        selectedCustomerLiztuVnKdnr == obj.liztu_vn_kdnr &&
        obj.is_new_customer == true
      ) {
        obj.is_selected = true;
        const spartes: Sparte[] = [];
        for (let j = 0; j < this.sparte.length; j++) {
          const contracts: Contract[] = [];
          for (let k = 0; k < this.sparte[j].contracts.length; k++) {
            const contract = this.setContracts(
              this.sparte[j].contracts[k].contract_number,
              this.sparte[j].contracts[k].liztu_vs_idnr,
              this.sparte[j].contracts[k].is_authorised,
              this.sparte[j].contracts[k].product_name,
              this.sparte[j].contracts[k].object
            );
            contracts.push(contract);
          }
          let isChecked = false;
          isChecked = this.isCheckedAllContracts(this.sparte[j]);
          const sparte = this.setSparte(
            this.sparte[j].sparte,
            contracts,
            false,
            isChecked,
            this.sparte[j].isFutureSelected,
            this.sparte[j].sparte_fixer_code
          );
          spartes.push(sparte);
        }
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(spartes);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(spartes);
        const selectedCustomer: selectedCustomersWithData = {
          liztu_vn_kdnr: selectedCustomerLiztuVnKdnr,
          sparte: spartes,
          is_saved: true,
        };
        const resultIndex = this.customers.findIndex(
          obj => obj.liztu_vn_kdnr == selectedCustomerLiztuVnKdnr
        );
        if (resultIndex != -1) {
          this.customers[resultIndex].is_selected =
            this.checkAnyContractIsSelected(this.sparte);
        }
        if (this.newSelectedCustomersWithData.length !== 0) {
          const x = this.newSelectedCustomersWithData.findIndex(
            obj => obj.liztu_vn_kdnr == selectedCustomerLiztuVnKdnr
          );
          if (x >= 0) {
            this.isCustomerSelected = true;
            this.newSelectedCustomersWithData[x] = selectedCustomer;
            this.openUnsavedDialog = false;
          } else {
            this.isCustomerSelected = true;
            this.newSelectedCustomersWithData.push(selectedCustomer);
            this.openUnsavedDialog = false;
          }
        } else {
          this.isCustomerSelected = true;
          this.newSelectedCustomersWithData.push(selectedCustomer);
          this.openUnsavedDialog = false;
        }
      }
      this.toastService.showToastMessage(
        document,
        this.local === LOCALS.ENGLISH
          ? 'Changes Saved Successfully'
          : 'Erfolgreich gespeicherte Änderungen'
      );
    });
    this.isContractsSelected = false;
  }

  setSelectedCustomers(): void {
    for (let i = 0; i < this.associatedCustomer.length; i++) {
      const spartes: Sparte[] = [];
      this.serviceCall
        .getContractsForuser(
          this.data.user_idnr,
          this.associatedCustomer[i].liztu_vn_kdnr
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          for (let j = 0; j < data.sparteWiseContracts.length; j++) {
            const contracts: Contract[] = [];
            for (
              let k = 0;
              k < data.sparteWiseContracts[j].contracts.length;
              k++
            ) {
              const contract = this.setContracts(
                data.sparteWiseContracts[j].contracts[k].contract_number,
                data.sparteWiseContracts[j].contracts[k].liztu_vs_idnr,
                data.sparteWiseContracts[j].contracts[k].is_authorised,
                data.sparteWiseContracts[j].contracts[k].product_name,
                data.sparteWiseContracts[j].contracts[k].object
              );
              contracts.push(contract);
            }
            let isChecked = false;
            isChecked = this.isCheckedAllContracts(data.sparteWiseContracts[j]);
            const sparte = this.setSparte(
              data.sparteWiseContracts[j].sparte,
              contracts,
              false,
              isChecked,
              data.sparteWiseContracts[j].include_for_future,
              data.sparteWiseContracts[j].sparte_fixer_code
            );
            spartes.push(sparte);
          }
          this.isCheckedAllContractsForThisCustomer =
            this.isCheckedAllContractsForCustomer(spartes);
          this.isCheckedAllFutureContractsForThisCustomer =
            this.isCheckedAllFutureContractsForCustomer(spartes);
        });
      const selectedCustomer: selectedCustomersWithData = {
        liztu_vn_kdnr: this.associatedCustomer[i].liztu_vn_kdnr,
        sparte: spartes,
        is_saved: false,
      };
      this.selectedCustomersWithData.push(selectedCustomer);
    }
  }

  hideUnsavedDialog(): void {
    this.openUnsavedDialog = false;
  }

  setContracts(
    contract_number,
    liztu_vs_idnr,
    is_authorised,
    product_name,
    object
  ): Contract {
    const contractReturn: Contract = {
      contract_number,
      liztu_vs_idnr,
      is_authorised,
      product_name,
      object,
    };
    return contractReturn;
  }

  setSparte(
    sparte,
    contracts,
    panelOpenState,
    isCheckedAll,
    isFutureSelected,
    sparte_fixer_code
  ): Sparte {
    const sparteReturn: Sparte = {
      sparte,
      contracts,
      panelOpenState,
      isCheckedAll,
      isFutureSelected,
      sparte_fixer_code,
    };
    return sparteReturn;
  }

  checkChanges(customersWithData, selectedCustomerLiztuVnKdnr, sparte): void {
    for (let i = 0; i < customersWithData.length; i++) {
      if (selectedCustomerLiztuVnKdnr == customersWithData[i].liztu_vn_kdnr) {
        this.newCustomer = false;
        const index = customersWithData.findIndex(
          obj => obj.liztu_vn_kdnr == selectedCustomerLiztuVnKdnr
        );
        if (index != -1) {
          if (sparte.length == customersWithData[index].sparte.length) {
            for (let i = 0; i < sparte.length; i++) {
              for (let j = 0; j < sparte[i].contracts.length; j++) {
                if (
                  customersWithData[index].sparte[i].contracts[j]
                    .is_authorised != sparte[i].contracts[j].is_authorised
                ) {
                  this.newCustomer = false;
                  if (this.isComingFromCanDeactivate == true) {
                    this.isContractsSelected = true;
                  } else {
                    this.openUnsavedDialog = true;
                  }
                  break;
                }
                if (
                  customersWithData[index].sparte[i].isFutureSelected !=
                  sparte[i].isFutureSelected
                ) {
                  this.newCustomer = false;
                  if (this.isComingFromCanDeactivate == true) {
                    this.isContractsSelected = true;
                  } else {
                    this.openUnsavedDialog = true;
                  }
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  setAllSpartes(selectedCustomers): Sparte[] {
    const spartes: Sparte[] = [];
    for (let j = 0; j < selectedCustomers.length; j++) {
      const contracts: Contract[] = [];
      for (let k = 0; k < selectedCustomers[j].contracts.length; k++) {
        const contract = this.setContracts(
          selectedCustomers[j].contracts[k].contract_number,
          selectedCustomers[j].contracts[k].liztu_vs_idnr,
          selectedCustomers[j].contracts[k].is_authorised,
          selectedCustomers[j].contracts[k].product_name,
          selectedCustomers[j].contracts[k].object
        );
        contracts.push(contract);
      }
      let isChecked = false;
      isChecked = this.isCheckedAllContracts(selectedCustomers[j]);
      const sparte = this.setSparte(
        selectedCustomers[j].sparte,
        contracts,
        false,
        isChecked,
        selectedCustomers[j].include_for_future,
        selectedCustomers[j].sparte_fixer_code
      );
      spartes.push(sparte);
    }
    this.isCheckedAllContractsForThisCustomer =
      this.isCheckedAllContractsForCustomer(spartes);
    this.isCheckedAllFutureContractsForThisCustomer =
      this.isCheckedAllFutureContractsForCustomer(spartes);
    return spartes;
  }

  getUserContracts(liztu_vn_kdnr): number {
    const spartes: Sparte[] = [];
    this.serviceCall
      .getContractsForuser(this.data.user_idnr, liztu_vn_kdnr)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        for (let j = 0; j < data.sparteWiseContracts.length; j++) {
          const contracts: Contract[] = [];
          for (
            let k = 0;
            k < data.sparteWiseContracts[j].contracts.length;
            k++
          ) {
            const contract = this.setContracts(
              data.sparteWiseContracts[j].contracts[k].contract_number,
              data.sparteWiseContracts[j].contracts[k].liztu_vs_idnr,
              data.sparteWiseContracts[j].contracts[k].is_authorised,
              data.sparteWiseContracts[j].contracts[k].product_name,
              data.sparteWiseContracts[j].contracts[k].object
            );
            contracts.push(contract);
          }
          let isChecked = false;
          isChecked = this.isCheckedAllContracts(data.sparteWiseContracts[j]);
          const sparte = this.setSparte(
            data.sparteWiseContracts[j].sparte,
            contracts,
            false,
            isChecked,
            data.sparteWiseContracts[j].include_for_future,
            data.sparteWiseContracts[j].sparte_fixer_code
          );
          spartes.push(sparte);
        }
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(spartes);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(spartes);
      });
    const selectedCustomer: selectedCustomersWithData = {
      liztu_vn_kdnr,
      sparte: spartes,
      is_saved: false,
    };
    const index = this.selectedCustomersWithData.findIndex(
      obj => obj.liztu_vn_kdnr == liztu_vn_kdnr
    );
    if (index == -1) {
      this.selectedCustomersWithData.push(selectedCustomer);
    } else {
      this.selectedCustomersWithData[index] = selectedCustomer;
    }
    return selectedCustomer.liztu_vn_kdnr;
  }

  checkAnyContractIsSelected(sparte): boolean {
    if (sparte.length > 0) {
      const result = sparte.find(obj =>
        obj.contracts.find(obj => obj.is_authorised == true)
      );
      return result != undefined ? true : false;
    } else {
      return false;
    }
  }

  checkAllContractsForCustomer(sparte): void {
    if (sparte.length > 0) {
      if (this.isCheckedAllContractsForThisCustomer == false) {
        sparte.map(function (obj) {
          obj.isCheckedAll = true;
          obj.contracts.map(obj => (obj.is_authorised = true));
        });
      } else {
        sparte.map(function (obj) {
          obj.isCheckedAll = false;
          obj.contracts.map(obj => (obj.is_authorised = false));
        });
      }
      this.isCheckedAllContractsForThisCustomer =
        !this.isCheckedAllContractsForThisCustomer;
    }
  }

  isCheckedAllContractsForCustomer(sparte): boolean {
    if (sparte.length > 0) {
      const result = sparte.every(function (obj) {
        const res = obj.contracts.every(obj => obj.is_authorised == true);
        return res;
      });
      return result;
    } else {
      return false;
    }
  }

  checkAllFutureContractsForCustomer(sparte): void {
    if (sparte.length > 0) {
      if (this.isCheckedAllFutureContractsForThisCustomer == false) {
        sparte.map(function (obj) {
          obj.isFutureSelected = true;
        });
      } else {
        sparte.map(obj => (obj.isFutureSelected = false));
      }
    }
    this.isCheckedAllFutureContractsForThisCustomer =
      !this.isCheckedAllFutureContractsForThisCustomer;
    this._customerSharedService.includeForFuture =
      this.isCheckedAllFutureContractsForThisCustomer;
  }

  isCheckedAllFutureContractsForCustomer(sparte): boolean {
    return sparte.length
      ? sparte.every(obj => obj.isFutureSelected === true)
      : false;
  }

  private _initWidgets(): void {
    const userIdForUpdating = +this.data.user_id;
    forkJoin([
      this._widgetsService.getWidgetAuthorizations(userIdForUpdating),
      this._widgetsService.getWidgets(true, userIdForUpdating),
    ])
      .pipe(
        map(items => this._formatWidgets(items[0], items[1])),
        takeUntil(this._destroy$)
      )
      .subscribe(items => this._widgets$.next(items));
  }

  private _formatWidgets(
    selectedWidgets: WidgetAuthorization[],
    widgets: Widget[]
  ): FormattedWidget[] {
    const selectedWidgetsFormatted = selectedWidgets.map(
      widget => widget.widget
    );
    return widgets.map(({ id, widgetName, widgetNameDE }) => {
      const selectedWidget = selectedWidgets.find(
        item => item.widget.id === id
      );
      return {
        id,
        name: this.local === LOCALS.GERMAN ? widgetNameDE : widgetName,
        isSelected: !!selectedWidgetsFormatted.find(item => item.id === id),
        widgetAuthorizationId: selectedWidget
          ? selectedWidget.widgetAuthorizationId
          : null,
      };
    });
  }

  private _changeIsSelectedValue(id: WidgetId, isSelected: boolean): void {
    const widgetsArray = [...this._widgets$.value];
    const searchedIndex = widgetsArray.findIndex(widget => widget.id === id);
    if (searchedIndex !== -1) {
      const widget = widgetsArray[searchedIndex];
      const start = widgetsArray.slice(0, searchedIndex);
      const end = widgetsArray.slice(searchedIndex + 1);
      this._widgets$.next([
        ...start,
        {
          ...widget,
          isSelected,
        },
        ...end,
      ]);
    }
  }

  private _updateWidgetAuthorizationValue(
    widget: WidgetId,
    widgetAuthorizationValue: WidgetAuthorizationId | null
  ): void {
    const index = this._widgets$.value.findIndex(
      widgetItem => widget === widgetItem.id
    );
    if (index !== -1) {
      this._widgets$.value[index].widgetAuthorizationId =
        widgetAuthorizationValue;
    }
  }

  private _watchOnUserId(): void {
    this._userService.userId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(userId => (this._userId = userId));
  }

  private _initRoleFields(): void {
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
    if (ROLES.CUSTOMER_ROLES.includes(this.user_role)) {
      this.customerRoles = true;
    }
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._initRoleFields();
      });
  }

  private _initData(): void {
    this.userData.salutation = this.salutationService.getSalutation(
      this.userData.salutation_id,
      this.local
    );
    this.data = {
      customer_id: this.userData.customer_id,
      user_id: this.userData.user_id,
      user_name: this.userData.user_name,
      Telefon: this.userData.Telefon,
      first_name: this.userData.first_name,
      last_name: this.userData.last_name,
      email: this.userData.email,
      customer: this.userData.customer,
      salutation: this.userData.salutation,
      status: this.userData.status,
      supported_languages: this.userData.supported_languages,
      role: this.userData.role,
      division: this.userData.division,
      childCustomer: '',
      otp: this.userData.otp,
      house: this.userData.house,
      invitation_language:
        this.userData.invitation_language == ''
          ? 'German(DE)'
          : this.userData.invitation_language,
      street: this.userData.street,
      address: this.userData.address,
      zip_code: this.userData.zip_code,
      city: this.userData.city,
      country: this.userData.country,
      viaemail: this.userData.viaemail,
      vialetter: this.userData.vialetter,
      is_invite_to_be_sent: this.userData.is_invite_to_be_sent,
      is_invited_to_rcs: this.userData.is_invited_to_rcs,
      is_invite_reset: this.userData.is_invite_reset,
      is_source_ivm: this.userData.is_source_ivm,
      is_vip: this.userData.is_vip,
      newUser: true,
      otpTo: this.userData.otpTo == '' ? 'Mobile' : this.userData.otpTo,
      title: '',
      buttonCancelName: '',
      buttonConfirmName: '',
      customer_address_lat_lon_set: this.userData.customer_address_lat_lon_set,
      lat: this.userData.lat,
      lon: this.userData.lon,
      customer_name: this.userData.customer_name,
      isLoggedIn: false,
      liztu_vn_kdnr: this.userData.liztu_vn_kdnr,
      user_idnr: this.userData.user_idnr,
      type: this.userData.type,
      is_group: false,
      role_id: this.userData.role_id,
    };
    this.getContracts();
    this.getCustomers();
    this.lastSelectedCustomer = this.data.customer_name;
    this.Telefon_validation = 'Telefon_validation';
    this.is_group = this.data.is_group;

    this.selectedCustomerLiztuVnKdnr = this.data.liztu_vn_kdnr;
    if (
      this.userData.customer_address_lat_lon_set == true ||
      (this.userData.lat !== '' &&
        this.userData.lat !== null &&
        this.userData.lat !== undefined &&
        this.userData.lon !== '' &&
        this.userData.lon !== null &&
        this.userData.lon !== undefined)
    ) {
      this.showActive = true;
    }
  }

  private _watchOnLocale(): void {
    this._userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.local = role;
        this._initData();
      });
  }
}
