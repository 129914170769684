<div>
    <span class="criteria-heading">{{'objectModule.object_details_group.object_details' | translate}}</span>
</div>
<form class="row object-module-html form-align" *ngIf="objectDetailsFormGroup" [formGroup]="objectDetailsFormGroup">
    <!-- Row starting -->
    <div class="col-md-3 col-sm-6 col-6">
        <label class="long-label">
            {{'objectModule.object_details_group.name' | translate}}
        </label>
        <input type="text" maxlength="100" class="input" formControlName="obd_name"
            placeholder="{{'objectModule.object_details_group.name' | translate}}"
            title="{{objectDetailsFormGroup.controls['obd_name'].value}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6" style="display: flex;">
      <div style="width: 100%;">
        <label for="" *ngIf="!errorNewValue && !errorNewValue1" class="label">{{'objectModule.new_value' | translate }}</label>
        <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
            *ngIf="errorNewValue || errorNewValue1">{{'objectModule.new_value' | translate}} : {{'objectModule.limitError' | translate}}</label>
        <div style="display: flex;">
          <mat-select id="currencyDropdown" (selectionChange)="currancyChanged($event)" title="{{selectedCurrency}}"
              class="dropdownStyle currencyDrop currecnyStyle" formControlName="obd_currency_id"
              [disableOptionCentering]="true"
              (click)="focusCurrencySearch()" (keyup.enter)="focusCurrencySearch()"
              panelClass="currencyTypeDropdown"
              #currencyTypeDrop>
            <mat-select-trigger>
              <span>{{currancySymbol}}</span>
            </mat-select-trigger>
            <input type="text" class="searchYear" id="currencySearch" maxlength="5" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
              [formControl]="currencyType">
            <mat-option style="opacity:0.5;display: none;">{{'objectModule.select_lable' | translate}}</mat-option>
            <mat-option *ngFor="let ele of currencyTypes" [value]="ele.id" matTooltip="{{ele.cur_symbol}}" >
              <span style="display: flex;">
                  <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                  <span style="justify-content: end;">{{ele.cur_symbol}}</span>
              </span>
            </mat-option>
          </mat-select>
          <input  type="text" style="border-radius: 0px;
          height: 39px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="changeNumber($event.target.value, 'newValue')" formControlName="obd_new_value" placeholder="{{'objectModule.new_value' | translate}}">
          <input  type="text" style="border-radius: 0px;
          border-top-left-radius: 0px;
          height: 39px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="changeNumber($event.target.value, 'newValue')" formControlName="obd_new_value" placeholder="{{'objectModule.new_value' | translate}}">
         <mat-select id="valueDropdown" class="dropdownStyle valueTypeStyle ellipsis" [title]="getValue"
          placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="obd_value_type">
          <mat-option (click)="resetValueType()" style="opacity:0.5">
            {{'objectModule.select_lable' | translate}}
          </mat-option>
          <mat-option *ngFor="let item of valueTypes" [value]="item.id">
            <span>{{item.name}}</span>
          </mat-option>
        </mat-select>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label class="label ellipsis" style="display: inline-block;" title="{{'objectModule.value_in_year' | translate }}"> {{'objectModule.value_in_year' | translate }} </label>
      <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
        formControlName="obd_new_value_year" (click)="scrollNewValue()" (keyup.enter)="scrollNewValue()" (selectionChange)="yearSelected()" #tempControl>
      <input type="text" maxlength="4" id="searchYear1" class="searchYear" placeholder="{{'header.Suche_tooltip' | translate}}" matInput [formControl]="yearControl">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let year of years" [ngClass]="{'active-option': year === currentYear && !objectDetailsFormGroup.value.obd_new_value_year}" [value]="year" >{{year}}</mat-option>
      </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.type_of_construction' | translate}} </label>
      <mat-select class="dropdownStyle"
        placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="obd_type_of_construction">
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let item of typeOfConstructionList" [value]="item.id" matTooltip="{{item.name}}" >
            <span>{{item.name}}</span>
          </mat-option>
      </mat-select>
    </div>

    <!-- Row starting -->
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.construction_year' | translate}} </label>
      <mat-select class="dropdownStyle"
        placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="obd_year_of_construction"
        (click)="scrollConstructionYearValue()" (keyup.enter)="scrollConstructionYearValue()" (selectionChange)="constructionYearSelected()" #constructionYearControl>
        <input type="text" maxlength="4" id="searchYear2" class="searchYear" placeholder="{{'header.Suche_tooltip' | translate}}" matInput [formControl]="constructionYearFormControl">
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let year of yearsCon" [ngClass]="{'active-option': year === currentYear && !objectDetailsFormGroup.value.obd_year_of_construction}" [value]="year" >
            <span>{{year}}</span>
          </mat-option>
      </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.monument_protection' | translate}}</label>
      <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="obd_monument_protection" >
          <mat-radio-button value="true">{{'objectModule.Yes' | translate}}</mat-radio-button>
          <mat-radio-button value="false">{{'objectModule.No' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.site_plan' | translate}}</label>
      <div style="display: flex;">
      <div class="documentUploadInput">
          <input class="input" readonly placeholder="{{'objectModule.image_upload' | translate}}"
          title="{{objectDetailsFormGroup.controls['obd_site_plan_docs'].value}}" formControlName="obd_site_plan_docs">
      </div>
      <div class="documentUploadButton">
      <button class="docButton" type="button" (click)="uploadsitePlanDocumentPopup()">
        <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
      </button>
      </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.type_of_use' | translate}} </label>
      <mat-select class="dropdownStyle"
        placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="obd_type_of_use" (selectionChange)="typeOfUseChanged($event)">
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let item of buildingTypesList" [value]="item.id" >
          <span>{{item.name}}</span>
        </mat-option>
      </mat-select>
    </div>

     <!-- Row starting -->
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.operating_mode' | translate}} </label>
      <mat-select class="dropdownStyle"
      #operatingModeControl (openedChange)="operatingModeFormControl.setValue('')"
      (click)="focusoperatingModeSearch()" (keyup.enter)="focusoperatingModeSearch()" (selectionChange)="valueChanged()"
          placeholder="{{'objectModule.select_lable' | translate}}" formControlName="obd_operation_mode" >
        <input type="text" id="operatingModeSearch" class="searchYear" maxlength="20" autocomplete="off"
        placeholder="{{'header.Suche_tooltip' | translate}}" matInput
        [formControl]="operatingModeFormControl">
        <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
        <mat-option *ngFor="let ele of operatingModeListFiltered" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
      </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.operating_mode_GGP' | translate}} </label>
      <mat-select class="dropdownStyle"
      #operatingModeControl (openedChange)="operatingModeFormControl.setValue('')"
      (click)="focusoperatingModeSearch()" (keyup.enter)="focusoperatingModeSearch()" (selectionChange)="valueChanged()"
          placeholder="{{'objectModule.select_lable' | translate}}" formControlName="obd_operation_mode_ggp" >
        <input type="text" id="operatingModeSearch" class="searchYear" maxlength="20" autocomplete="off"
        placeholder="{{'header.Suche_tooltip' | translate}}" matInput
        [formControl]="operatingModeFormControl">
        <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
        <mat-option *ngFor="let ele of operatingModeGGPList" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
      </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.vacancy' | translate}}</label>
      <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="obd_vacancy" >
          <mat-radio-button value="true">{{'objectModule.Yes' | translate}}</mat-radio-button>
          <mat-radio-button value="false">{{'objectModule.No' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label class="label" *ngIf="!errorRentalValue && !errorNewValue1">
          {{'objectModule.object_details_group.rental_value' | translate}}
      </label>
      <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
        *ngIf="errorRentalValue || errorNewValue1">{{'objectModule.object_details_group.rental_value' | translate}} : {{'objectModule.limitError' | translate}}</label>
      <input  type="text" style="border-bottom-left-radius: 0px;
          border-top-left-radius: 0px;
          height: 39px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input"
          (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
          (blur)="changeNumber($event.target.value, 'rentalValue')" formControlName="obd_rental_value" placeholder="{{'objectModule.object_details_group.rental_value' | translate}}">
          <input  type="text" style="border-bottom-left-radius: 0px;
          border-top-left-radius: 0px;
          height: 39px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input"
          (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
          (blur)="changeNumber($event.target.value, 'rentalValue')" formControlName="obd_rental_value" placeholder="{{'objectModule.object_details_group.rental_value' | translate}}">
    </div>

   

    <!-- Row starting -->
    <div class="col-md-3 col-sm-6 col-6">
      <label class="long-label">
        {{'objectModule.object_details_group.living_space_in_sqm' | translate}}
      </label>
      <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" formControlName="obd_living_space"
        type="text" class="input" maxlength="6" type="text" pattern="[0-9]*"
        (keydown)="typeNumber($event, 'keydown',6)" (paste)="typeNumber($event, 'paste',6)"
        (blur)="validation.sqmValidation(objectDetailsFormGroup, $event)" (focus)="validation.sqmValidation(objectDetailsFormGroup, $event)"
        placeholder="{{'objectModule.object_details_group.living_space_in_sqm' | translate}}"
        numbersOnly>
      <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*" formControlName="obd_living_space"
        (paste)="typeNumberBrowserSpecific($event, 'paste',6)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',6)"
        (blur)="validation.sqmValidation(objectDetailsFormGroup, $event)" (focus)="validation.sqmValidation(objectDetailsFormGroup, $event)"  numbersOnly
        placeholder="{{'objectModule.object_details_group.living_space_in_sqm' | translate}}">
  </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label class="long-label">
          {{'objectModule.object_details_group.number_of_residential_units' | translate}}
      </label>
      <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" formControlName="obd_no_residentail_units"
        type="text" class="input" maxlength="3" type="text" pattern="[0-9]*"
        (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)"
        (blur)="validation.sqmValidation(objectDetailsFormGroup, $event)" (focus)="validation.sqmValidation(objectDetailsFormGroup, $event)"  numbersOnly
        placeholder="{{'objectModule.object_details_group.number_of_residential_units' | translate}}">
      <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="3" ng-pattern="[0-9]*" formControlName="obd_no_residentail_units"
        (paste)="typeNumberBrowserSpecific($event, 'paste',3)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)"
        (blur)="validation.sqmValidation(objectDetailsFormGroup, $event)" (focus)="validation.sqmValidation(objectDetailsFormGroup, $event)"  numbersOnly
        placeholder="{{'objectModule.object_details_group.number_of_residential_units' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6 date-picker-container" id="datePicker1">
      <label *ngIf="!recentFactoryVisitDateError" for="" class="label">
        {{'objectModule.object_details_group.recent_factory_visit' | translate}}
      </label>
      <label *ngIf="recentFactoryVisitDateError" for="" class="label touched-warning ellipsis" style="display: inline-block;">
        {{'objectModule.object_details_group.recent_factory_visit' | translate}} : {{'objectModule.Invalid_date' | translate}}
      </label>
      <mat-form-field  class="date-picker" appearance="fill" id="datePicker">
      <span>
        <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
      </span>
      <input matInput (dateChange)="dateClicked('recentFactoryVisit')" [matDatepicker]="datepicker1" class="date-input" autocomplete="off" (keypress)="dateChange($event,'recentFactoryVisit')"
        (paste)="dateChange($event,'recentFactoryVisit')" (blur)="onDateBlur($event,'recentFactoryVisit')"
        placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" formControlName="obd_recent_factory_visit" [max]="today">
      <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker1">
        <mat-icon matDatepickerToggleIcon class="datePickerArrow">
          {{'claims.keyboard-arrow-down_text' | translate}}
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datepicker1></mat-datepicker>
    </mat-form-field>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.type_of_factory_tour' | translate}} </label>
      <mat-select class="dropdownStyle"
        placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="obd_type_of_factory_tour">
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let item of buildingObjectTypesList" [value]="item.id" >
            <span>{{item.desc}}</span>
          </mat-option>
      </mat-select>
    </div>
    

    <!-- Row starting -->
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.audit_obligation' | translate}}</label>
      <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="obd_audit_obligation">
          <mat-radio-button value="true" (change)="auditObligationChange(true)">{{'objectModule.Yes' | translate}}</mat-radio-button>
          <mat-radio-button value="false" (change)="auditObligationChange(false)">{{'objectModule.No' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6 date-picker-container" id="datePicker2">
      <label *ngIf="!auditDateError" for="" class="label">
        {{'objectModule.object_details_group.date_of_audit' | translate}}
      </label>
      <label *ngIf="auditDateError" for="" class="label touched-warning ellipsis" style="display: inline-block;">
        {{'objectModule.object_details_group.date_of_audit' | translate}} : {{'objectModule.Invalid_date' | translate}}
      </label>
      <mat-form-field  class="date-picker" appearance="fill" id="datePicker">
      <span>
        <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
      </span>
      <input matInput (dateChange)="dateClicked('auditDate')" [matDatepicker]="datePicker2" class="date-input" autocomplete="off" (keypress)="dateChange($event,'auditDate')"
        (paste)="dateChange($event,'auditDate')" (blur)="onDateBlur($event,'auditDate')"
        placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" formControlName="obd_audit_date" [max]="today">
      <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datePicker2">
        <mat-icon matDatepickerToggleIcon class="datePickerArrow">
          {{'claims.keyboard-arrow-down_text' | translate}}
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datePicker2></mat-datepicker>
    </mat-form-field>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.date_of_audit_document' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.image_upload' | translate}}"
            title="{{objectDetailsFormGroup.controls['obd_audit_doc'].value}}" formControlName="obd_audit_doc">
        </div>
        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadAuditDocumentPopup()" 
        [disabled]="!objectDetailsFormGroup.value.obd_audit_obligation || objectDetailsFormGroup.controls['obd_audit_doc'].disabled">
          <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.object_details_group.defects' | translate}} </label>
      <mat-select class="dropdownStyle"
        placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="obd_defects">
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let item of buildingDefectsList" [value]="item.id" >
            <span>{{item.desc}}</span>
          </mat-option>
      </mat-select>
    </div>
   
    <!-- Row starting -->
    <div class="col-md-3 col-sm-6 col-6 date-picker-container" id="datePicker3">
      <label *ngIf="!eliminationDateError" for="" class="label">
        {{'objectModule.object_details_group.elimination_date' | translate}}
      </label>
      <label *ngIf="eliminationDateError" for="" class="label touched-warning ellipsis" style="display: inline-block;">
        {{'objectModule.object_details_group.elimination_date' | translate}} : {{'objectModule.Invalid_date' | translate}}
      </label>
      <mat-form-field  class="date-picker" appearance="fill" id="datePicker">
        <span>
          <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
        </span>
        <input 
          matInput
          (dateChange)="dateClicked('eliminationDate')" 
          [matDatepicker]="datePicker3" 
          class="date-input" 
          autocomplete="off" 
          (keypress)="dateChange($event,'eliminationDate')"
          (paste)="dateChange($event,'eliminationDate')" 
          (blur)="onDateBlur($event,'eliminationDate')"
          placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" 
          formControlName="obd_elimination_date">
        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datePicker3">
          <mat-icon matDatepickerToggleIcon class="datePickerArrow">
            {{'claims.keyboard-arrow-down_text' | translate}}
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #datePicker3></mat-datepicker>
      </mat-form-field>
    </div>

</form>
<app-upload-document [object_data]="parentObjectData" [object_type]="uploadObjectDetails"
    [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)">
</app-upload-document>
<div id="successToast"></div>
