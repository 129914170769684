import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StepFourCustomerInviteComponent } from '../step-four-customer-invite/step-four-customer-invite.component';
import { StepTwoCustomerInviteComponent } from '../step-two-customer-invite/step-two-customer-invite.component';
import { UpdateUserComponent } from '../update-user.component';
import { BehaviorSubject } from 'rxjs';
import { WidgetsApiService } from '@core/services/httpcalls/widgets-api.service';
import { map, takeUntil } from 'rxjs/operators';
import { Widget, WidgetId } from '@shared/models/widget.model';
import { LOCALS } from '@shared/constants/local.constants';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { SparteShareService } from '@core/services/sparte-share.service';

type FormattedWidget = { id: WidgetId; name: string; isSelected: boolean };

@Component({
  selector: 'app-step-three-customer-invite',
  templateUrl: './step-three-customer-invite.component.html',
  styleUrls: ['./step-three-customer-invite.component.css'],
})
export class StepThreeCustomerInviteComponent
  extends BaseComponent
  implements OnInit
{
  full_name: String;
  customerType: string;
  user;
  customer: String;
  private readonly _widgets$: BehaviorSubject<FormattedWidget[]> =
    new BehaviorSubject([]);
  readonly selectedWidgets$ = this._widgets$
    .asObservable()
    .pipe(map(items => items.filter(widget => widget.isSelected)));
  readonly widgets$ = this._widgets$
    .asObservable()
    .pipe(
      map((items: FormattedWidget[]) =>
        items.filter(widget => !widget.isSelected)
      )
    );

  constructor(
    public dialogRef: MatDialogRef<StepThreeCustomerInviteComponent>,
    private dialog: MatDialog,
    private readonly _widgetsService: WidgetsApiService,
    private readonly _userService: UserService,
    private readonly _sparteShareService: SparteShareService
  ) {
    super();
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.user = this._userService.userFormData;
    this.full_name = this.user.firstName + ' ' + this.user.lastName;
    this.customerType = this.user.type;
    this.customer = this.user.customerName;
    this._initWidgets();
  }

  onNoClick(): void {
    this._sparteShareService.sparte = null;
    this._userService.mutterCustomer = null;
    this._userService.widgets = null;
    this._userService.showActiveFlag = null;
    this._userService.selectedCustomers = null;
    this._userService.userFormData = null;
    this._userService.customerLiztuVnKdnr = null;
    this._userService.inviteUserContext = null;
    this.dialogRef.close();
  }

  onOneClick(): void {
    this._saveWidgets();
    const user = this._userService.userToUpdate;
    this.dialog.open(UpdateUserComponent, {
      width: '800px',
      data: user,
    });
    this.dialogRef.close();
  }

  backClick(): void {
    this._saveWidgets();
    this.dialog.open(StepTwoCustomerInviteComponent, {
      width: '800px',
    });
    this.dialogRef.close();
  }

  changeWidgetSelection(id: WidgetId, isSelected: boolean): void {
    const widgetsArray = [...this._widgets$.value];
    const searchedIndex = widgetsArray.findIndex(widget => widget.id === id);
    if (searchedIndex !== -1) {
      const widget = widgetsArray[searchedIndex];
      const start = widgetsArray.slice(0, searchedIndex);
      const end = widgetsArray.slice(searchedIndex + 1);
      this._widgets$.next([
        ...start,
        {
          ...widget,
          isSelected,
        },
        ...end,
      ]);
    }
  }

  onSaveClick(): void {
    this._saveWidgets();
    this.dialog.open(StepFourCustomerInviteComponent, {
      width: '800px',
    });
    this.dialogRef.close();
  }

  private _saveWidgets(): void {
    this._userService.widgets = this._widgets$.value
      .filter(item => item.isSelected)
      .map(({ id, name }) => ({ id, name }));
  }

  private _initWidgets(): void {
    this._widgetsService
      .getWidgets(true)
      .pipe(
        map(items => this._formatWidgets(items)),
        takeUntil(this._destroy$)
      )
      .subscribe(items => this._widgets$.next(items));
  }

  private _formatWidgets(widgets: Widget[]): FormattedWidget[] {
    return widgets.map(({ id, widgetName, widgetNameDE }) => {
      return {
        id,
        name:
          this._userService.getLocale() === LOCALS.GERMAN
            ? widgetNameDE
            : widgetName,
        isSelected: false,
      };
    });
  }
}
