import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export const filterNil =
  <T>() =>
  (source: Observable<T>): Observable<NonNullable<T>> =>
    source.pipe(
      filter<T, NonNullable<T>>(
        (value: T): value is NonNullable<T> =>
          value !== null && value !== undefined
      )
    );
