import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductWarningMsg } from '@shared/models/product-management.model';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';

@Component({
  selector: 'template-product-viewer',
  templateUrl: './template-product-list.component.html',
  styleUrls: ['./template-product-list.component.css'],
})
export class TemplateProductListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @Input('availableTemplates') availableValues;
  @Output('deleteTemplate') deleteTemplate = new EventEmitter<any>();

  productListAvailable;

  dataValuesAvailable = new MatTableDataSource<any>();
  dataValuesSelected = new MatTableDataSource<any>();
  availableTempSelection = new SelectionModel<any>(true, []);
  selectedTempSelection = new SelectionModel<any>(true, []);
  productWarningMsg: ProductWarningMsg;
  enableDeletePopup = false;
  enableProductPopup = false;
  templateUrlToDelete = '';
  displayedAvailableColumns: string[] = [
    'template_name',
    'document_type',
    'created_at',
    'last_updated_on',
    'ACTION',
  ];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private serviceCall: ApiCallsService,
    private translate: TranslateService,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog
  ) {
    this.availableTempSelection.clear();
    this.selectedTempSelection.clear();
    this.productWarningMsg = {
      cancel: 'template-management.cancel_label',
      ok: 'template-management.yes_delete',
      header: 'objectModule.Delete_popup_heading',
      body: 'template-management.delete_template_warning',
    };
  }

  ngOnInit(): void {
    this.dataValuesAvailable.data = this.availableValues;
    this.dataValuesAvailable.sort = this.sort;
    this.enableDeletePopup = false;
    this.enableProductPopup = false;
    this.templateUrlToDelete = '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableValues) {
      this.ngOnInit();
    }
  }

  onTemplateDelete(template_id, templateUrl: string) {
    this.templateUrlToDelete = templateUrl;
    this.getProductListAndProcedToDelete(template_id);
  }

  getProductListAndProcedToDelete(template_id) {
    this.serviceCall
      .getTemplateSpecificProducts(template_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          if (!data.HasErrors) {
            this.productListAvailable = data.data || [];
            if (this.productListAvailable.length > 0) {
              this.formValidMessage();
            } else {
              this.enableDeletePopup = true;
            }
          } else {
            this.enableDeletePopup = true;
          }
        },
        error => {
          this.enableDeletePopup = true;
        }
      );
  }

  formValidMessage() {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(
          'template-management.delete_template_error'
        ),
      },
      disableClose: false,
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  detachProd(info) {
    this.serviceCall
      .deleteProductConfig(
        info.resourceUri,
        info.productInfoId,
        info.templateId
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          if (!data.HasErrors) {
            this.showToastMessage(
              'template-management.detach_product_success_msg'
            );
          } else {
            this.showToastMessage(
              'template-management.detach_product_failure_msg'
            );
          }
          this.getProductList(info.templateId);
        },
        error => {
          this.getProductList(info.templateId);
          this.showToastMessage(
            'template-management.detach_product_failure_msg'
          );
        }
      );
  }

  showToastMessage(message) {
    const x = document.getElementById('successToast');
    if (x) {
      x.textContent = this.translate.instant(message);
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }
  handleDeleteTemplatePopup($event) {
    this.enableDeletePopup = false;
    if (!$event) {
      this.deleteTemplate.emit(this.templateUrlToDelete);
    }
  }

  onProductView(templateUrl: string) {
    this.templateUrlToDelete = templateUrl;
    this.enableProductPopup = true;
    this.getProductList(templateUrl);
  }

  getProductList(template_id) {
    this.serviceCall
      .getTemplateSpecificProducts(template_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.productListAvailable = data.data;
        }
      });
  }

  handleProductViewPopup() {
    this.enableProductPopup = false;
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
