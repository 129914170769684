import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LOCALS } from '@shared/constants/local.constants';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import { SelectedCustomers } from '../components/update-user/step-two-customer-invite/step-two-customer-invite.component';
import { UserManagementComponent } from '../modules/customer-management/user-management-page/user-management/user-management.component';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly _userId$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly userId$ = this._userId$.asObservable();

  private readonly _userRole$: BehaviorSubject<USER_ROLES | null> =
    new BehaviorSubject<USER_ROLES | null>(null);
  readonly userRole$ = this._userRole$.asObservable();

  private readonly _locale$: BehaviorSubject<LOCALS | null> =
    new BehaviorSubject<LOCALS | null>(null);
  readonly locale$ = this._locale$.asObservable();

  private readonly _parentId$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly parentId$ = this._parentId$.asObservable();

  private readonly _divisionId$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly divisionId$ = this._divisionId$.asObservable();

  private readonly _deliztu$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly deliztu$ = this._deliztu$.asObservable();

  private readonly _userIdnr$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly userIdnr$ = this._userIdnr$.asObservable();

  private readonly _loggedInUser$: BehaviorSubject<LoggedInUser | null> =
    new BehaviorSubject<LoggedInUser | null>(null);
  readonly loggedInUser$ = this._loggedInUser$.asObservable();

  private readonly _inviteCustomerLiztuVnKdnr$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly inviteCustomerLiztuVnKdnr$ =
    this._inviteCustomerLiztuVnKdnr$.asObservable();

  private _mutterCustomer: any = null;
  get mutterCustomer(): any {
    return this._mutterCustomer;
  }
  set mutterCustomer(value: any) {
    this._mutterCustomer = value;
  }

  private _userToUpdate: any = null;
  get userToUpdate(): any {
    return this._userToUpdate;
  }
  set userToUpdate(value: any) {
    this._userToUpdate = value;
  }

  private _userFormData: any = null;
  get userFormData(): any {
    return this._userFormData;
  }
  set userFormData(value: any) {
    this._userFormData = value;
  }

  private _widgets: any[] = null;
  get widgets(): any[] {
    return this._widgets;
  }
  set widgets(value: any[]) {
    this._widgets = value;
  }

  private _customerLiztuVnKdnr: number = null;
  get customerLiztuVnKdnr(): number {
    return this._customerLiztuVnKdnr;
  }
  set customerLiztuVnKdnr(value: number) {
    this._customerLiztuVnKdnr = value;
  }

  private _showActiveFlag: boolean = null;
  get showActiveFlag(): boolean {
    return this._showActiveFlag;
  }
  set showActiveFlag(value: boolean) {
    this._showActiveFlag = value;
  }

  private _selectedCustomers: SelectedCustomers = null;
  get selectedCustomers(): SelectedCustomers {
    return this._selectedCustomers;
  }
  set selectedCustomers(value: SelectedCustomers) {
    this._selectedCustomers = value;
  }

  private _isFutureSelected: boolean = null;
  get isFutureSelected(): boolean {
    return this._isFutureSelected;
  }
  set isFutureSelected(value: boolean) {
    this._isFutureSelected = value;
  }

  private _inviteUserContext: UserManagementComponent = null;
  get inviteUserContext(): UserManagementComponent {
    return this._inviteUserContext;
  }
  set inviteUserContext(value: UserManagementComponent) {
    this._inviteUserContext = value;
  }

  saveUserId(userId: string): void {
    this._userId$.next(userId);
  }

  getUserId(): string | null {
    return this._userId$.value;
  }

  saveUserRole(role: USER_ROLES): void {
    this._userRole$.next(role?.toUpperCase() as USER_ROLES);
  }

  getUserRole(): USER_ROLES | null {
    return this._userRole$.value;
  }

  saveLocale(locale: LOCALS): void {
    return this._locale$.next(locale);
  }

  getLocale(): LOCALS | null {
    return this._locale$.value;
  }

  saveParentId(parentId: string | null): void {
    this._parentId$.next(parentId);
  }

  getParentId(): string | null {
    return this._parentId$.value;
  }

  saveDivisionId(divisionId: string): void {
    this._divisionId$.next(divisionId);
  }

  getDivisionId(): string | null {
    return this._divisionId$.value;
  }

  saveDeliztu(deliztu: string | null): void {
    this._deliztu$.next(deliztu);
  }

  getDeliztu(): string | null {
    return this._deliztu$.value;
  }

  saveUserIdnr(id: string | null): void {
    this._userIdnr$.next(id);
  }

  getUserIdnr(): string | null {
    return this._userIdnr$.value;
  }

  saveLoggedInUser(user: LoggedInUser | null): void {
    user.user_profile.user_data.role =
      user.user_profile.user_data.role?.toUpperCase() as USER_ROLES;

    this._loggedInUser$.next(user);
  }

  getLoggedInUser(): LoggedInUser | null {
    return this._loggedInUser$.value;
  }

  saveInviteCustomerLiztuVnKdnr(
    inviteCustomerLiztuVnKdnr: string | null
  ): void {
    this._inviteCustomerLiztuVnKdnr$.next(inviteCustomerLiztuVnKdnr);
  }

  getInviteCustomerLiztuVnKdnr(): string | null {
    return this._inviteCustomerLiztuVnKdnr$.value;
  }
}
