export const CLAIM_CONTACT_PERSON_FIELDS = {
  SALUTATION: 'salutation',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ROLE: 'role',
  RECEIVABLE_AMOUNT: 'receivable_amount',
  RECEIVABLE_AMOUNT_CURRENCY: 'receivable_amount_currency',
  CLAIM_DESCRIPTION: 'claim_description',
  REMARK: 'remark',
};

export const ROLES_IDS = {
  CLAIMANT: 107,
};

export const CLAIM_CP_WARNINIG = {
  CANCEL: 'product-management.stepper_common_msg.discard',
  OK: 'product-management.stepper_common_msg.cancel_label',
  SAVE: 'product-management.stepper_common_msg.save_and_continue',
  HEADING: 'save-changes.Nicht_gespeicherte',
  SUBHEADING: 'save-changes.object_unsaved_message',
};

export enum CLAIM_TABS {
  GENERAL_DATA = 'GENERAL_DATA',
  DAMAGE_DETAILS = 'DAMAGE_DETAILS',
  DOCUMENT_MANAGEMENT = 'DOCUMENT_MANAGEMENT',
  CONTACT_PERSON = 'CONTACT_PERSON',
  PAYMENT_MANAGEMENT = 'PAYMENT_MANAGEMENT',
  APPOINTMENTS = 'APPOINTMENTS',
}
