<button [hidden]="isCloseButtonHidden" class="close-button" style="background: transparent;" (click)="onNoClick()">
    <img alt=""   style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
</button>
<!-- <div style="overflow-y: auto;
      height: 230px;font-size: 11px;font-weight: 600;color:#272727;position: relative;padding: 0px 5px;" class="zurs-widget"> -->
<div style="overflow-y: auto;
      height: 230px;font-size: 11px;color:#272727;position: relative;padding: 0px 5px;" class="zurs-widget">
    <form>
        <div class="language address-field" style="padding: 4px 0px 0px 0px;">
            <label>
                <span>{{'master-data.Addresse_label' | translate}}</span>
            </label>
            <input type=text [(ngModel)]="selectedLoc" placeholder="{{'master-data.Addresse_label' | translate}}"
                [ngClass]="showActive == false ? 'greyLocationPinIcon' : 'greenLocationPinIcon'" name="location-list" #loc
                id="location" [readonly]="!isCloseButtonHidden"
                class="input" required autocomplete="off" (focus)="showList = true" (focusout)="onFocusOut()" (keyup)="keyUp.next($event)">
            <ul class="statusFilter" id="locationList"   *ngIf="isCloseButtonHidden && showList && filterlocation.length">
                <li class="listStyle" *ngFor="let location of filterlocation;index as i" (click)="selectedLocation(i)">
                  {{location.freeformAddress}}
                </li>
              </ul>
        </div>

        <div [ngClass]="{'red': street === true}" style="padding: 4px 0px 0px 0px;">
            <label><span *ngIf="street == false">{{'master-data.Street_label' | translate}}<span style="color:#FF1010;">*</span></span><span
                    style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="street == true">{{'master-data.Street_label' | translate}}*</span></label>
            <input class="input" required name="street" placeholder="{{'master-data.Street_label' | translate}}" [(ngModel)]="street_num" [readonly]="!isCloseButtonHidden">
        </div>
        <div style="width: 100%;">
            <div [ngClass]="{'red': house === true}" style="padding: 4px 0px 0px 0px;width: 30%;float: left;">
                <label><span *ngIf="house == false">{{'master-data.Housenumber_label' | translate}}<span style="color:#FF1010;">*</span></span><span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="house == true">{{'master-data.Housenumber_label' | translate}}*</span></label>
                <input class="input" placeholder="{{'master-data.Housenumber_label' | translate}}" required style="border-radius: 1px;" name="house"
                    [(ngModel)]="house_num" [readonly]="!isCloseButtonHidden">
            </div>
            <div [ngClass]="{'red': zip === true}"
                style="padding: 4px 0px 0px 0px;width: 30%;float: left;margin-left: 5%;">
                <label><span *ngIf="zip == false">{{'master-data.Postcode_label' | translate}}<span style="color:#FF1010;">*</span></span><span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="zip == true">{{'master-data.Postcode_label' | translate}}*</span></label>
                <input class="input" placeholder="{{'master-data.Postcode_label' | translate}}" required style="border-radius: 1px;" name="zip"
                    [(ngModel)]="zip_code" [readonly]="!isCloseButtonHidden">
            </div>
            <div [ngClass]="{'red':city === true}"
                style="padding: 4px 0px 0px 0px;width: 30%;float: left;margin-left: 5%;">
                <label><span *ngIf="city == false">{{'master-data.City_label' | translate}}<span style="color:#FF1010;">*</span></span> <span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="city == true">{{'master-data.City_label' | translate}}*</span></label>
                <input class="input" placeholder="{{'master-data.City_label' | translate}}" required style="border-radius: 1px;" name="city"
                    [(ngModel)]="city_name" [readonly]="!isCloseButtonHidden">
            </div>
        </div>
        <div *ngIf="selectLang$ | async as selectLang" style="width: 100%;">
            <div style="padding: 10px 0px 0px 0px;float: left;"
                [ngStyle]="{'width': selectLang === 'English(EN)'  ? '12%' : '28%' }">
                <mat-checkbox labelPosition="before" (change)="floodCheck($event);"
                    id="flood" name="flood">{{'zurs-geo.Flood_label' | translate }}
                </mat-checkbox>
            </div>
            <div style="padding: 10px 0px 0px 25px;float: left;"
                [ngStyle]="{'width': selectLang === 'English(EN)'  ? '22%' : '28%' }">
                <mat-checkbox labelPosition="before" (change)="earthCheck($event);"
                    id="earth" name="earth" id="earthquake" name="earthquake">{{ 'zurs-geo.Earthquake_label' | translate }}
                </mat-checkbox>
            </div>
            <div style="width: 19%;float: left;" [ngStyle]="{'padding': selectLang === 'English(EN)' ? '10px 0px 0px 38px' : '10px 0px 0px 16px' }">
                <mat-checkbox labelPosition="before" (change)="snowCheck($event);"
                id="snow" name="snow" >{{ 'zurs-geo.Snow_label' | translate }}
                </mat-checkbox>
            </div>
        </div>
        <p style="clear:both;font-weight: 600;" *ngIf="chargableTextShow"><img alt="" style="height: 13px;margin-right: 4px;"
                src="../../../../assets/images/info-sign.svg">{{ 'zurs-geo.chargable_text' | translate }}</p>
        <p style="clear:both;color:#FF1010;font-weight: 600;" *ngIf="message && !chargableTextShow"><img alt="" style="height: 13px;margin-right: 4px;"
            src="../../../../assets/images/info-sign.svg">{{ 'zurs-geo.Climaty_text' | translate }}</p>
        <div style="text-align: center;clear:both;left: 0%;
        right: 0%;position: absolute;bottom: 0px;
        ">
        <!-- <button type="submit" (click)="check()" style="background: #ff9000;
        border: none;
        box-shadow: none;
        padding: 0px 22px 0px 22px;
        height: 30px;
        border-radius: 2px;
        font-weight: 600;font-size: 12px;">{{ 'zurs-geo.Submit_label' | translate }}</button> -->
        <!-- Removed font-weight 600 property from the button -->
        <button type="submit" (click)="check()" style="background: #ff9000;
        border: none;
        box-shadow: none;
        padding: 0px 22px 0px 22px;
        height: 30px;
        border-radius: 2px;
        font-size: 12px;">{{ 'zurs-geo.Submit_label' | translate }}</button>
        </div>
    </form>
</div>