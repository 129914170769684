import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ClaimContactPersonData,
  ContactPersonTableData,
} from '../../models/table.model';
import {
  COLUMNS,
  COLUMN_NAMES,
  CONTACT_PERSON_ACTION_METHOD_NAMES,
  CP_COLUMN_HEADINGS,
  CP_FORM_CONTROL,
  MULTILINGUAL_CONSTS,
} from '../../models/table.constants';
import {
  COLUMN_TYPES,
  TOOLTIP_PLACEMENT,
} from '@shared/constants/column-types.constants';
import { TableStructure } from '@shared/models/column-details.model';
import { TranslateService } from '@ngx-translate/core';
import {
  CanNotDeleteServiceRequest,
  DeleteConfirmationRequest,
  DeleteConfirmationResponse,
  DeleteServiceRequest,
} from '@shared/interfaces/delete-confirm.interface';
import {
  API_CONST,
  PATHS,
  STATIC_TEXT,
} from '@shared/constants/string.constants';
import { IMG_URL } from '@shared/constants/image-urls.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { LookupResponse } from '@shared/models/response.model';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import { ToastService } from '@core/services/toast.service';
import { NumberValidationsService } from '@core/services/validations/number-validations.service';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { DeleteService } from '@core/services/shared/delete.service';
import { COMMON_IMAGES } from '@shared/constants/image-paths/common.constants';
import { takeUntil } from 'rxjs/operators';
import { TABLE_MAX_HEIGHT } from '@shared/constants/general/table.constants';
import { BaseComponent } from '@shared/components/base.component';
import { ClaimsShareService } from '@modules/new-claims/claims-share.service';
import { ActivatedRoute } from '@angular/router';
import { ActioButtons, FilterSectionConfig, TableConfiguration } from '@shared/models/table.model';

@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
})
export class ContactPersonComponent extends BaseComponent implements OnInit {
  @Input() message: string;
  @Output() iscontactPersonAddEdit = new EventEmitter<boolean>();
  @Output() contactPersonId = new EventEmitter<string>();
  tableStructure: TableStructure;
  tableData: ClaimContactPersonData[] = [];
  showConfirmDeletePopup = false;
  sendDeleteReq: DeleteConfirmationRequest;
  tableConfig: TableConfiguration;
  filterSectionConfig: FilterSectionConfig;
  contactPersonRoles: LookupResponse[] = [];
  tableDataCopy: ClaimContactPersonData[] = [];
  isIvmSynced: boolean;

  constructor(
    private _translateService: TranslateService,
    private _apiCallsService: ApiCallsService,
    private _claimsAPI: ClaimsManagementApiService,
    private _toastService: ToastService,
    private _numberValidations: NumberValidationsService,
    private _deleteService: DeleteService,
    private readonly _claimsSharedService: ClaimsShareService,
    private readonly _activatedRoute: ActivatedRoute
  ) {
    super();
    this._getRolesData();
    this._prepareTableConfiguration();
    this.tableStructure = this._prepareTableStructure();
  }

  ngOnInit(): void {
    this._getContactPersonOverview();
    this._watchOnIvmChanging();
    if (this.message) {
      this._toastService.showToastMessage(document, this.message);
    }
  }

  callMethod(data: any): void {
    if (data && data.methodName) {
      switch (data.methodName) {
        case CONTACT_PERSON_ACTION_METHOD_NAMES.EDIT_CONTACT_PERSON:
          this._editContactPerson(data.element);
          break;
        case CONTACT_PERSON_ACTION_METHOD_NAMES.DELETE_CONTACT_PERSON:
          this._deleteContactPerson(data.element);
          break;
      }
    }
  }

  deleteCPPostConfirmation(deleteReq: DeleteConfirmationResponse): void {
    this.showConfirmDeletePopup = deleteReq?.showPopup;
    if (deleteReq.isAllowed) {
      this._deleteCP(+deleteReq.id);
    }
  }

  filterTable(event): void {
    const roleData = event?.data[CP_FORM_CONTROL.ROLE];
    this.tableData =
      event?.isReset || !roleData
        ? this.tableDataCopy
        : this.tableDataCopy.filter(ele => ele.role_id === roleData);
  }

  private _prepareTableStructure(): TableStructure {
    const structure: TableStructure = {
      displayColumns: COLUMNS,
      columnDetails: [
        {
          matColumnDef: COLUMN_NAMES.NAME,
          colHeading: this._translateService.instant(CP_COLUMN_HEADINGS.NAME),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.ROLE,
          colHeading: this._translateService.instant(CP_COLUMN_HEADINGS.ROLE),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.RECEIVABLE_AMOUNT,
          colHeading: this._translateService.instant(
            CP_COLUMN_HEADINGS.RECEIVABLE_AMOUNT
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.CLAIM_DESCRIPTION,
          colHeading: this._translateService.instant(
            CP_COLUMN_HEADINGS.CLAIM_DESCRIPTION
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.REMARK,
          colHeading: this._translateService.instant(CP_COLUMN_HEADINGS.REMARK),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.ACTION,
          colHeading: this._translateService.instant(CP_COLUMN_HEADINGS.ACTION),
          columnType: COLUMN_TYPES.ACTION,
        },
      ],
      optionalStyles: {
        maxHeight: TABLE_MAX_HEIGHT,
      },
    };
    return structure;
  }

  private _prepareTableData(
    requst: ContactPersonTableData[]
  ): ClaimContactPersonData[] {
    const contactPersonData: ClaimContactPersonData[] = requst.map(
      (ele: ContactPersonTableData) => {
        const contactPerson: ClaimContactPersonData = {
          id: ele.Id,
          name: ele.Name,
          role: ele.Role,
          role_id: ele.RoleId,
          receivable_amount: ele.ReceivableAmount,
          claim_description: ele.ClaimDescription,
          remark: ele.Remark,
          action: [
            {
              iconLink: ACTION_BUTTON.EDIT_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                CONTACT_PERSON_ACTION_METHOD_NAMES.EDIT_CONTACT_PERSON,
              isDisabled: false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.EDIT_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
            {
              iconLink: ACTION_BUTTON.DELETE_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                CONTACT_PERSON_ACTION_METHOD_NAMES.DELETE_CONTACT_PERSON,
              isDisabled: false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.DELETE_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
          ],
        };
        return contactPerson;
      }
    );
    return contactPersonData;
  }

  private _prepareTableRequest(data): ContactPersonTableData[] {
    const req: ContactPersonTableData[] = [];
    data.forEach((cp: any) => {
      const currencySymbol = cp.receivable_amount
        ? cp.currency_symbol
        : STATIC_TEXT.EMPTY_STRING;
      const contactPerson: ContactPersonTableData = {
        Id: cp.id,
        Name: `${cp.last_name} ${cp.first_name}`,
        Role: cp.role,
        ReceivableAmount: `${this._numberValidations.convertIntToGermanFormatWithDecimal(cp.receivable_amount)} ${currencySymbol}`,
        ClaimDescription: cp.claim_description,
        Remark: cp.remark,
        RoleId: cp.role_id,
        ReceivableAmountCurrency: cp.currency_symbol,
      };
      req.push(contactPerson);
    });
    return req;
  }

  private _editContactPerson(cp): void {
    this.contactPersonId.emit(cp.id);
    this.iscontactPersonAddEdit.emit(true);
  }

  private _deleteContactPerson(deleteObject): void {
    if (deleteObject) {
      const config: DeleteServiceRequest = {
        id: deleteObject.id,
      };
      this.showConfirmDeletePopup = true;
      this.sendDeleteReq = this._deleteService.deleteConfig(config);
    }
  }

  private _prepareFilterSectionConfig(): void {
    this.filterSectionConfig = {
      heading: STATIC_TEXT.FILTER,
      defaultOpen: true,
      isPagination: false,
      elements: [
        {
          elementType: 1, // 1 for dropdown,
          isLabel: false,
          placeholder: this._translateService.instant(
            MULTILINGUAL_CONSTS.SELECT_ROLE
          ),
          data: this.contactPersonRoles,
          isSearchable: true,
          isSelectShow: true,
          controlName: CP_FORM_CONTROL.ROLE,
          value: null,
        },
      ],
      isFilterBtn: true,
      isResetBtn: true,
      resetBtnIconLink: `${PATHS.IMG}${IMG_URL.REFRESH}`,
      resetTooltip: {
        tooltipText: this._translateService.instant(
          MULTILINGUAL_CONSTS.RESET_TEXT
        ),
        tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
      },
      isFirstTimeLoad: true,
    };
  }

  private _getContactPersonOverview(): void {
    this._claimsAPI
      .getContactPersonOverviewData(
        atob(this._activatedRoute.snapshot.queryParams.claim)
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.tableData = data?.data
          ? this._prepareTableData(this._prepareTableRequest(data?.data))
          : [];
        this.tableDataCopy = [...this.tableData];
        this._toggleActionButtons(this.isIvmSynced);
      });
  }

  private _getRolesData(): void {
    this._apiCallsService
      .getProductLookups(API_CONST.CP_CLAIM_ROLES)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.contactPersonRoles = data.data;
        this._prepareFilterSectionConfig();
      });
  }

  private _deleteCP(id: number): void {
    this._claimsAPI
      .deleteContactPerson(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: any) => {
        if (!response.is_valid) {
          this._canNotBeDeletedPopupRequest();
        } else {
          this._toastService.showToastMessage(document, response.message);
          this._getContactPersonOverview();
        }
      });
  }

  private _canNotBeDeletedPopupRequest(): void {
    const canNotDeleteConfig: CanNotDeleteServiceRequest = {
      is_success: false,
      heading: this._translateService.instant(
        MULTILINGUAL_CONSTS.CANNOT_DELETE_HEADING
      ),
      subHeading: this._translateService.instant(
        MULTILINGUAL_CONSTS.CANNOT_DELETE_SUBHEADING
      ),
      logoLink: COMMON_IMAGES.CAUTION,
    };
    this.showConfirmDeletePopup = true;
    this.sendDeleteReq =
      this._deleteService.canNotDeletePopupConfig(canNotDeleteConfig);
  }

  private _prepareTableConfiguration(): void {
    this.tableConfig = {
      isFilterSection: true,
      isPagination: false,
      isHeadingAboveFilter: true,
      headingText: this._translateService.instant(MULTILINGUAL_CONSTS.CP),
    };
  }

  private _toggleActionButtons(flag: boolean): void {
    this.tableData.map((row: ClaimContactPersonData) => {
      row.action.map((actionElemen: ActioButtons) => {
        return (actionElemen.isDisabled = flag);
      });
      return row;
    });
  }

  private _watchOnIvmChanging(): void {
    this._claimsSharedService.isIvmSynced$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.isIvmSynced = value;
        this._toggleActionButtons(this.isIvmSynced);
      });
  }
}
