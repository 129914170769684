<div class="mask-generator-root">
  <form class="row object-module-html form-align" [formGroup]="maskStepperForm">
    <div class="col-md-6 col-sm-6 col-6">
      <label for="optionalField" class="label">{{'product-management.mask_generator.optional_field'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="optionalField" id="optionalField"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated">
        <mat-option style="opacity:0.5" value="required">Vorgeschrieben</mat-option>
        <mat-option style="opacity:0.5" value="optional">Optional</mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="maximumCompensation" class="label">{{'product-management.mask_generator.maximum_compensation'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="maximumCompensation" id="maximumCompensation"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="fullValueInsuredAmount" class="label">{{'product-management.mask_generator.full_value_insured_amount'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="fullValueInsuredAmount" id="fullValueInsuredAmount"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="maximization" class="label">{{'product-management.mask_generator.maximisation'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="maximization" id="maximization"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="typeOfCoverage" class="label">{{'product-management.mask_generator.type_of_coverage' | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="typeOfCoverage" id="typeOfCoverage"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="deductibleType" class="label">{{'product-management.mask_generator.deductible_type'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="deductibleType" id="deductibleType"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated"
                  placeholder="{{'objectModule.select_lable' | translate}}">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="deductibleAmount" class="label">{{'product-management.mask_generator.deductible_amount'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="deductibleAmount" id="deductibleAmount"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated || configData?.sb_type_id !== 35">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="deductibleIn" class="label">{{'product-management.mask_generator.deductiable_in'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="deductibleIn" id="deductibleIn"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated || configData?.sb_type_id !== 36">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="deductibleMin" class="label">{{'product-management.mask_generator.deductible_min'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="deductibleMin" id="deductibleMin"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated || configData?.sb_type_id !== 36">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="deductibleMax" class="label">{{'product-management.mask_generator.deductible_max'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="deductibleMax" id="deductibleMax"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated || configData?.sb_type_id !== 36">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="daysForDeductible" class="label">{{'product-management.mask_generator.days_for_deductible'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="daysForDeductible" id="daysForDeductible"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated || configData?.sb_type_id !== 37">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="indemnityPeriod" class="label">{{'product-management.mask_generator.indemnity_period'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="indemnityPeriod" id="indemnityPeriod"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="premiumRate" class="label">{{'product-management.mask_generator.premium_rate'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="premiumRate" id="premiumRate"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated"
                  placeholder="{{'objectModule.select_lable' | translate}}">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="premium" class="label">{{'product-management.mask_generator.premium'  | translate}}</label>
      <mat-select class="dropdownStyle" formControlName="premium" id="premium"
                  [disabled]="isAutoPopulatedSelected || isProductDeactivated"
                  placeholder="{{'objectModule.select_lable' | translate}}">
        <mat-option *ngFor="let visibilityOption of visibilityOptions" [value]="visibilityOption.value">
          {{visibilityOption.displayValue}}
        </mat-option>
      </mat-select>
    </div>
  </form>
</div>
