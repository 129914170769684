<app-modal width="600" (close)="hidePopup()">
  <ng-container title>{{'new-contract.new_contract_heading' | translate}}</ng-container>

  <div content class="row product-module-html">
    <div class="col-md-6 col-sm-12 col-12 common-field">
        <label for="" class="long-label required">{{'new-contract.sparte_lable' | translate}}</label>
        <mat-select class="dropdownStyle" (click)="focusDropdown('searchSparte')"
            (keyup.enter)="focusDropdown('searchSparte')"
            (selectionChange)="searchSparte.value = ''; sparteChange($event);"
            placeholder="{{'objectModule.select_lable' | translate}}" #sparteControl>
            <input type="text" preventSpace class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
                 #searchSparte>
            <mat-option *ngFor="let item of validationService._filter(spartes, 'description', searchSparte.value)"
                title="{{item.description}}" [value]="item">
                <span>{{item.description}}</span>
            </mat-option>
        </mat-select>
    </div>
    <div class="col-md-6 col-sm-12 col-12 common-field">
        <label for="" class="long-label required">{{'new-contract.product_lable' | translate}}</label>
        <mat-select class="dropdownStyle" (click)="focusDropdown('searchProduct')"
            (keyup.enter)="focusDropdown('searchProduct')"
            placeholder="{{'new-contract.product_mapping.please_select' | translate}}" id="products"
            (openedChange)="searchProduct.value = ''"
            [disabled]="!avaliable_products || avaliable_products.length == 0"
            (selectionChange)="searchProduct.value = '';selectedProduct($event)">
            <input type="text" maxlength="20" preventSpace class="searchSelect"
                placeholder="{{'header.Suche_tooltip' | translate}}" #searchProduct>
            <mat-option *ngFor="let item of validationService._filter(avaliable_products, 'desc', searchProduct.value)"
                [value]="item" title="{{item.desc}}">
                {{item.desc}}
            </mat-option>
        </mat-select>
    </div>
  </div>

  <ng-container actions>
    <app-button secondary (click)="hidePopup()">{{ 'confirm_popup.cancel' | translate }}</app-button>
    <app-button (click)="getContractDetails()" [disabled]="!isContinueDisabled()">{{'main_component.modal_button_text' | translate}}</app-button>
  </ng-container>
</app-modal>