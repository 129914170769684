import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css'],
})
export class DeleteUserComponent implements OnInit {
  @Output('onHideModal') onHideModal = new EventEmitter<any>();
  @Output('onDelete') onDelete = new EventEmitter<any>();
  @Output('resetInvite') resetInvite = new EventEmitter<any>();
  @Input('isIvm') isIvm;
  @Input('isResetInvite') isResetInvite;
  constructor() {}

  ngOnInit(): void {}
  hideModal() {
    this.onHideModal.emit(true);
  }
  deleteDocument() {
    this.onDelete.emit(true);
    this.onHideModal.emit(true);
  }
  invitationRevert() {
    this.onHideModal.emit(true);
    this.resetInvite.emit(true);
  }
}
