<div>
  <span class="criteria-heading">{{'objectModule.location.additional-general-information' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="additionalGeneralInformationForm" id="additionalGeneralInformationForm">

  <div class="col-md-3 col-sm-6 col-6">
    <label for="insuredProp" class="long-label">{{'objectModule.location.additional_general_information.owner_of_insured_property' | translate}}</label>
    <input type="text" id="insuredProp" class="input pad-right" maxlength="100" autocomplete="off" placeholder="{{'objectModule.location.additional_general_information.owner_of_insured_property' | translate}}" matInput
      formControlName="owner_of_insured_property" title="{{additionalGeneralInformationForm.value.owner_of_insured_property}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="ownerBuilding" class="long-label">{{'objectModule.location.additional_general_information.owner_of_building' | translate}}</label>
    <input type="text" id="ownerBuilding" class="input pad-right" maxlength="100" autocomplete="off" placeholder="{{'objectModule.location.additional_general_information.owner_of_building' | translate}}" matInput formControlName="owner_of_building"
      title="{{additionalGeneralInformationForm.value.owner_of_building}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="address" class="long-label">{{'objectModule.location.additional_general_information.type_of_use' | translate}}</label>
    <input type="text" id="address" class="input pad-right" maxlength="100" autocomplete="off" placeholder="{{'objectModule.location.additional_general_information.type_of_use' | translate}}" matInput formControlName="type_of_use"
      title="{{additionalGeneralInformationForm.value.type_of_use}}">
  </div>



  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.location.additional_general_information.site_plan' | translate}}</label>
    <div style="display: flex;">
      <div class="documentUploadInput">
        <input class="input" readonly title="{{additionalGeneralInformationForm.value.site_plan}}" placeholder="{{'objectModule.location.additional_general_information.site_plan' | translate}}" formControlName="site_plan">
      </div>
      <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadSitePlanDoc()">
          <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="selectLang$ | async as locale" class="col-md-3 col-sm-6 col-6">
    <label 
      for="" 
      class="label" 
      [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}"> 
        {{'objectModule.location.additional_general_information.property_location' | translate}} </label>
    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="property_location">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let item of property_location" [value]="item.id">
        <span>{{item.desc}}</span>
      </mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.location.additional_general_information.fire_department' | translate}} </label>
    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="FireDepartment($event)" formControlName="fire_department">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let item of fire_department" [value]="item.id">
        <span>{{item.desc}}</span>
      </mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.location.additional_general_information.fire_department_plans' | translate}}</label>
    <div style="display: flex;">
      <div class="documentUploadInput">
        <input class="input" readonly title="{{additionalGeneralInformationForm.value.fire_department_plans}}" placeholder="{{'objectModule.location.additional_general_information.fire_department_plans' | translate}}"
          formControlName="fire_department_plans">
      </div>
      <div class="documentUploadButton">
        <button class="docButton" type="button" [disabled]="buttonDisable" (click)="uploadFireDepartmentDoc()">
          <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">
      {{'objectModule.location.additional_general_information.distance_in_km' | translate}}
    </label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',2)" (paste)="typeNumber($event, 'paste',2)"
      formControlName="distance_in_km" placeholder="{{'objectModule.location.additional_general_information.distance_in_km_placeholder' | translate}}" numbersOnly>
    <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',2)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',2)"
      formControlName="distance_in_km" placeholder="{{'objectModule.location.additional_general_information.distance_in_km_placeholder' | translate}}" numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">
      {{'objectModule.location.additional_general_information.arrival_time' | translate}}
    </label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',2)" (paste)="typeNumber($event, 'paste',2)"
      formControlName="arrival_time" placeholder="{{'objectModule.location.additional_general_information.arrival_time_placeholder' | translate}}" numbersOnly>
    <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',2)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',2)"
      formControlName="arrival_time" placeholder="{{'objectModule.location.additional_general_information.arrival_time_placeholder' | translate}}" numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.location.additional_general_information.fire_water_supply' | translate}} </label>
    <mat-select multiple class="dropdownStyle" (selectionChange)="valueFireWaterSupply($event)" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="fire_water_supply">
      <mat-option *ngFor="let item of fire_water_supply" [value]="item.id" matTooltip="{{item.desc}}">
        <span>{{item.desc}}</span>
      </mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">
      {{'objectModule.location.additional_general_information.nominal_width_water_line' | translate}}
    </label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)"
      formControlName="nominal_width_water_line" placeholder="{{'objectModule.location.additional_general_information.nominal_width_water_line_placeholder' | translate}}" numbersOnly>
    <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',3)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)"
      formControlName="nominal_width_water_line" placeholder="{{'objectModule.location.additional_general_information.nominal_width_water_line_placeholder' | translate}}" numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6" style="display:flex;align-items: baseline;">
    <div style="width: 60%;margin-right: 2%;">
      <label for="" class="long-label" style="line-break: anywhere;">
        {{'objectModule.location.additional_general_information.size_of_fire_water' | translate}}
      </label>
      <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" pattern="[0-9]*" (keydown)="validation.SequenceNumberValidation($event, 'keydown',6)"
        (paste)="validation.SequenceNumberValidation($event, 'paste',6)" (blur)="validation.sqmValidation(additionalGeneralInformationForm, $event)" (focus)="validation.sqmValidation(additionalGeneralInformationForm, $event)"
        formControlName="size_of_fire_water" placeholder="{{'objectModule.location.additional_general_information.size_of_fire_water_placeholder' | translate}}" numbersOnly>
      <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="validation.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
        (keypress)="validation.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)" (blur)="validation.sqmValidation(additionalGeneralInformationForm, $event)" (focus)="validation.sqmValidation(additionalGeneralInformationForm, $event)"
        formControlName="size_of_fire_water" placeholder="{{'objectModule.location.additional_general_information.size_of_fire_water_placeholder' | translate}}" numbersOnly>
    </div>
    <div style="width:38%;">
      <label for="" class="long-label">
        {{'objectModule.location.additional_general_information.unit' | translate}}
      </label>
      <mat-select class="dropdownStyle" [title]="unitName" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="unit">
        <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
        <mat-option *ngFor="let item of unit" [value]="item.id" matTooltip="{{item.desc}}">
          <span>{{item.desc}}</span>
        </mat-option>
      </mat-select>

    </div>
  </div>


  <div [ngClass]="additionalGeneralInformationForm.value.is_operating_hours ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_operating_hours">{{'objectModule.location.additional_general_information.is_operating_hours' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="additionalGeneralInformationForm.value.is_operating_hours" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.location.additional_general_information.day_per_week' | translate}}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',1)" (paste)="typeNumber($event, 'paste',2)"
          formControlName="day_per_week" placeholder="{{'objectModule.location.additional_general_information.day_per_week_placeholder' | translate}}" oninput="if(!this.value.match('^[1-7]*$'))this.value='';">
        <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',1)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',2)"
          formControlName="day_per_week" placeholder="{{'objectModule.location.additional_general_information.day_per_week_placeholder' | translate}}" oninput="if(!this.value.match('^[1-7]*$'))this.value='';">
      </div>
      <div class="col-md-3 col-sm-6 col-6" style="display:flex;">
        <div style="width: 47%;
                margin-right: 3%;
                display: inline-block;">
          <label for="" class="long-label">
            {{'objectModule.location.additional_general_information.from' | translate}}
          </label>
          <div style="display: flex;">
            <input style="margin-right: 2%;" formControlName="fromHH" type="number" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',2);" (paste)="typeNumber($event, 'paste',2);" placeholder="HH"
              oninput="if(this.value > 23)this.value='';"> <span style="margin-top: 7%;
                       font-weight: 700;">:</span>
            <input class="input" style="margin-left: 2%;" formControlName="fromMM" type="number" ng-pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',2)" (paste)="typeNumber($event, 'paste',2)" placeholder="MM"
              oninput="if(this.value > 59)this.value='';">
          </div>
        </div>
        <div style="    width: 47%;
                margin-left: 3%;
                display: inline-block;">
          <label for="" class="long-label">
            {{'objectModule.location.additional_general_information.to' | translate}}
          </label>
          <div style="display: flex;">
            <input type="number" style="margin-right: 2%;" formControlName="toHH" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',2)" (paste)="typeNumber($event, 'paste',2)" placeholder="HH"
              oninput="if(this.value > 23)this.value='';"> <span style="margin-top: 7%;
                       font-weight: 700;">:</span>
            <input class="input" type="number" style="margin-left: 2%;" formControlName="toMM" ng-pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',2)" (paste)="typeNumber($event, 'paste',2)" placeholder="MM"
              oninput="if(this.value > 59)this.value='';">
          </div>

        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.location.additional_general_information.work_shifts' | translate}}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',1)" (paste)="typeNumber($event, 'paste',1)"
          formControlName="work_shifts" placeholder="{{'objectModule.location.additional_general_information.work_shifts' | translate}}" oninput="if(!this.value.match('^[1-9]*$'))this.value='';">
        <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',1)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',1)"
          formControlName="work_shifts" placeholder="{{'objectModule.location.additional_general_information.work_shifts' | translate}}" oninput="if(!this.value.match('^[1-9]*$'))this.value='';">
      </div>
    </div>
  </div>
  <div [ngClass]="additionalGeneralInformationForm.value.is_regulatory_req ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_regulatory_req">{{'objectModule.location.additional_general_information.is_regulatory_req' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="additionalGeneralInformationForm.value.is_regulatory_req" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="address" class="long-label">{{'objectModule.location.additional_general_information.regulatory_restriction_desc' | translate}}</label>
        <input type="text" id="address" class="input pad-right" maxlength="100" autocomplete="off" placeholder="{{'objectModule.location.additional_general_information.regulatory_restriction_desc_placeholder' | translate}}" matInput
          formControlName="regulatory_restriction_desc" title="{{additionalGeneralInformationForm.value.regulatory_restriction_desc}}">
      </div>

    </div>
  </div>
  <div [ngClass]="additionalGeneralInformationForm.value.is_outdoor_hydrant ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_outdoor_hydrant">{{'objectModule.location.additional_general_information.is_outdoor_hydrant' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="additionalGeneralInformationForm.value.is_outdoor_hydrant" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.location.additional_general_information.count' | translate}}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',2)" (paste)="typeNumber($event, 'paste',1)"
          formControlName="count" placeholder="{{'objectModule.location.additional_general_information.count' | translate}}" numbersOnly>
        <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',2)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',1)"
          formControlName="count" placeholder="{{'objectModule.location.additional_general_information.count' | translate}}" numbersOnly>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.location.additional_general_information.nominal_diameter' | translate}}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" type="number" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)"
          formControlName="nominal_diameter" placeholder="{{'objectModule.location.additional_general_information.nominal_diameter_placeholder' | translate}}" numbersOnly>
        <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',3)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)"
          formControlName="nominal_diameter" placeholder="{{'objectModule.location.additional_general_information.nominal_diameter_placeholder' | translate}}" numbersOnly>
      </div>
    </div>
  </div>



</form>
<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)">
</app-upload-document>
