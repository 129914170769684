<div class="custom-form-container">
    <div class="custom-form-heading">
        {{'customer-management.left-panel.bank-account' | translate}}
    </div>
    <div class="custom-form">
        <form *ngIf="bankDetailsForm" [formGroup]="bankDetailsForm">
            <div class="row">
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'customer-management.bank_details.description' | translate}}
                    </label> 
                    <input type="text" 
                        [formControlName]="formFieldsName.DESCRIPTION"
                        class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.bank_details.description' | translate}}"
                        [title]="bankDetailControls[formFieldsName.DESCRIPTION].value">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'customer-management.bank_details.bank_name' | translate}}
                    </label>
                    <input type="text" 
                        [formControlName]="formFieldsName.BANK_NAME"
                        class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.bank_details.bank_name' | translate}}"
                        [title]="bankDetailControls[formFieldsName.BANK_NAME].value">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label custom-label-required"
                        [ngClass]="_showError.showBillionOrReqLabelError(bankDetailsForm, formFieldsName.IBAN)">
                        {{'customer-management.bank_details.IBAN' | translate}}
                    </label>
                    <input type="text" 
                        [formControlName]="formFieldsName.IBAN"
                        class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.bank_details.IBAN' | translate}}"
                        [title]="bankDetailControls[formFieldsName.IBAN].value"
                        [ngClass]="_showError.showInputError(bankDetailsForm, formFieldsName.IBAN)">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'customer-management.bank_details.BIC' | translate}}
                    </label>
                    <input type="text" 
                        [formControlName]="formFieldsName.BIC"
                        class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.bank_details.BIC' | translate}}"
                        [title]="bankDetailControls[formFieldsName.BIC].value">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'customer-management.bank_details.standard_bank_connection' | translate}}
                    </label>
                    <div class="radio-group-align-long">
                        <mat-radio-group aria-label=""  
                            [formControlName]="formFieldsName.STANDARD_BANK_ACCOUNT">
                            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
                            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'customer-management.bank_details.differing_account_holder' | translate}}
                    </label>
                    <input type="text" 
                        [formControlName]="formFieldsName.DIFFERING_ACCOUNT_HOLDER" 
                        class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.bank_details.differing_account_holder' | translate}}"
                        [title]="bankDetailControls[formFieldsName.DIFFERING_ACCOUNT_HOLDER].value"
                        >
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'customer-management.bank_details.sepa_mandate' | translate}}
                    </label>
                    <div class="radio-group-align-long">
                        <mat-radio-group aria-label="" 
                            [formControlName]="formFieldsName.SEPA_MANDATE">
                            <mat-radio-button [value]="true" class="checkBox" (change)="sepaMandateChange(true)">{{'objectModule.Yes' | translate}}</mat-radio-button>
                            <mat-radio-button [value]="false" class="checkBox" (change)="sepaMandateChange(false)">{{'objectModule.No' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3 custom-date-picker-container">
                    <label for="" 
                        *ngIf="!bankDetailsForm.get(formFieldsName.SEPA_MANDATE_DATE).hasError(errorConstant.INVALID_DATE)" 
                        class="custom-label">
                        {{'customer-management.bank_details.sepa_mandate_date' | translate}}
                    </label>
                    <label for="" 
                    *ngIf="bankDetailsForm.get(formFieldsName.SEPA_MANDATE_DATE).hasError(errorConstant.INVALID_DATE)" 
                        title="{{'customer-management.bank_details.sepa_mandate_date' | translate}} : {{'objectModule.Invalid_date' | translate}}" 
                        class="custom-label label-error ellipsis">
                        {{'customer-management.bank_details.sepa_mandate_date' | translate}} : {{'objectModule.Invalid_date' | translate}}
                    </label>

                    <mat-form-field appearance="fill" class="custom-date-picker">
                        <span>
                            <img alt="" [src]="formImages.CALENDER">
                        </span>
                        <input matInput
                            [matDatepicker]="datePickerId" class="date-input" autocomplete="off"
                            [formControlName]="formFieldsName.SEPA_MANDATE_DATE"
                            [max]="today"
                            maxDateToday
                            placeholder="{{'claim-reporting.datePlaceHolder' | translate }}">
                        <mat-datepicker-toggle matSuffix [for]="datePickerId">
                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                {{arrows.DOWN_ARROW}}
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datePickerId></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'customer-management.bank_details.sepa_mandate_reference' | translate}}
                    </label>
                    <input type="text" 
                        [formControlName]="formFieldsName.SEPA_MANDATE_REFERENCE"
                        class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.bank_details.sepa_mandate_reference' | translate}}"
                        [title]="bankDetailControls[formFieldsName.SEPA_MANDATE_REFERENCE].value">
                </div>
                <div class="col-md-3 col-sm-6 col-6">
                    <label for="" class="custom-label">{{'customer-management.bank_details.sepa_mandate_document' | translate}}</label>
                    <div class="custom-document-upload-container">
                      <div class="custom-document-upload-input">
                        <input class="custom-input-field" readonly 
                            placeholder="{{'customer-management.bank_details.sepa_mandate_document' | translate}}"
                            [formControlName]="formFieldsName.SEPA_DOCUMENT"
                            [title]="bankDetailControls[formFieldsName.SEPA_DOCUMENT].value">
                      </div>
                    <button class="custom-document-upload-button flex-center" 
                        type="button" (click)="uploadSepaDocument()" 
                        [disabled]="!bankDetailControls[formFieldsName.SEPA_MANDATE].value">
                        <img alt="" src="assets/images/ic-icons/Upload.svg">
                    </button>
                    </div>
                </div>
            </div>
        </form>
    </div>    
</div>

<div class="save-container" *ngIf="bankDetailsForm">
    <button class="custom-btn save-btn" (click)="saveBankDetails()" [disabled]="!isValueChangedInForm">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>

<app-upload-document [object_type]="uploadDocumentDetails"
    [selectedDocumentsArray]="selectedDocumentsArray" [is_disable]="is_IC_USER"
    [showDocumentPopup]="showDocumentPopup()" (hide)="hideDocumentPopup($event)">
</app-upload-document>