<div>
    <span class="criteria-heading">{{'objectModule.other_increases_in_risk.other_increases_in_risk' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="otherIncreasesInRiskForm" id="otherIncreasesInRiskForm">
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.other_increases_in_risk.plants_according_to_Bimschg' | translate}}</label>
      <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"
            title="{{otherIncreasesInRiskForm.controls['plants_bimschg_documents'].value}}" formControlName="plants_bimschg_documents">
        </div>

        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadPantsDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.other_increases_in_risk.not_approved_parking' | translate}}</label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="not_approved_parking" >
        <mat-radio-button [value]="true" (change)="onYesclick()">
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.other_increases_in_risk.burglar_resistant_glazing' | translate}}</label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="burglar_resistant_bars" >
        <mat-radio-button [value]="true">
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.other_increases_in_risk.secured_Windows' | translate}}</label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="secured_windows" >
        <mat-radio-button [value]="true">
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.other_increases_in_risk.are_flammable_hazardous_substances' | translate}}</label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="are_hazardus_substances" >
        <mat-radio-button [value]="true">
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
    </div>

    <!-- First -->
    <div [ngClass]="otherIncreasesInRiskForm.value.is_thermal_oil_system ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_thermal_oil_system">{{'objectModule.other_increases_in_risk.thermal_oil_system' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="otherIncreasesInRiskForm.value.is_thermal_oil_system" class="row toggle-section-content">
          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.thermal_oil_systems_document' | translate}}</label>
            <div style="display: flex;">
            <div class="documentUploadInput">
                <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"
                  title="{{otherIncreasesInRiskForm.controls['upload_document_thermal_oil_systems'].value}}" formControlName="upload_document_thermal_oil_systems">
            </div>

            <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadThermalOilDocumentPopup()">
             <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
            </div>
          </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.count' | translate}}
            </label>
            <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" class="input" maxlength="3" numbersOnly pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)"
              formControlName="count" placeholder="{{'objectModule.other_increases_in_risk.count' | translate}}">
            <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',3)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)"
              formControlName="count" placeholder="{{'objectModule.other_increases_in_risk.count' | translate}}" numbersOnly>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.scope_of_Application' | translate}}
            </label>
            <input type="text" maxlength="100" class="input" formControlName="scope_of_application"
              placeholder="{{'objectModule.other_increases_in_risk.scope_of_Application' | translate}}"
              title="{{otherIncreasesInRiskForm.controls['scope_of_application'].value}}">
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.maximum_oil_temperature' | translate}}
            </label>
            <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',4)" (paste)="typeNumber($event, 'paste',4)"
            formControlName="max_oil_temp" placeholder="{{'objectModule.other_increases_in_risk.maximum_oil_temperature' | translate}}"
            (blur)="validation.sqmValidation(otherIncreasesInRiskForm , $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm , $event)"  numbersOnly>
            <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',4)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',4)"
            formControlName="max_oil_temp" placeholder="{{'objectModule.other_increases_in_risk.maximum_oil_temperature' | translate}}"
            (blur)="validation.sqmValidation(otherIncreasesInRiskForm , $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm , $event)"  numbersOnly>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.maximum_amount_of_oil' | translate}}
            </label>
            <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" maxlength="6" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',6)" (paste)="typeNumber($event, 'paste',6)"
            formControlName="max_amount_oil" placeholder="{{'objectModule.other_increases_in_risk.maximum_amount_of_oil' | translate}}"
            (blur)="validation.sqmValidation(otherIncreasesInRiskForm , $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm , $event)"  numbersOnly>
            <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',6)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',6)"
            formControlName="max_amount_oil" placeholder="{{'objectModule.other_increases_in_risk.maximum_amount_of_oil' | translate}}"
            (blur)="validation.sqmValidation(otherIncreasesInRiskForm , $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm , $event)"  numbersOnly>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.pressure_temperature_monitoring' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="pressure_temperature" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.combustible_materials_distance' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="distance_cumbustible" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.heater_fireproof_disconnected' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="heater_fire_proof" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.co2_protection_heater' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="co2Protection" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>


          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.largest_Fire_compartment' | translate}}
            </label>
            <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" maxlength="5" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)"
            formControlName="largest_Fire_compartment" placeholder="{{'objectModule.other_increases_in_risk.largest_Fire_compartment' | translate}}"
            (blur)="validation.sqmValidation(otherIncreasesInRiskForm , $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm , $event)"  numbersOnly>
            <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)"
            formControlName="largest_Fire_compartment" placeholder="{{'objectModule.other_increases_in_risk.largest_Fire_compartment' | translate}}"
            (blur)="validation.sqmValidation(otherIncreasesInRiskForm , $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm , $event)"  numbersOnly>
          </div>


        </div>
    </div>

    <!-- Second -->
    <div [ngClass]="otherIncreasesInRiskForm.value.is_oil_hydraulic_system ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_oil_hydraulic_system">{{'objectModule.other_increases_in_risk.oil_hydraulic_systems' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="otherIncreasesInRiskForm.value.is_oil_hydraulic_system" class="row toggle-section-content">
          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.oil_Hydraulic_systems_document' | translate}}</label>
            <div style="display: flex;">
            <div class="documentUploadInput">
                <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"
                 title="{{otherIncreasesInRiskForm.controls['doc_upload_oil_hydraulic'].value}}" formControlName="doc_upload_oil_hydraulic">
            </div>

            <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadHydraulicSystemDocumentPopup()">
             <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
            </div>
          </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.emergency_stop_switch' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="emergency_stop_switch" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.pressure_monitoring' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="pressure_monitoring" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.object_protection_system' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="object_protection_system" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.quick_shut_off_valve' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="quick_shut_off" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>
        </div>
    </div>

    <!-- Third -->
    <div [ngClass]="otherIncreasesInRiskForm.value.is_spark_errosion_system ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_spark_errosion_system">{{'objectModule.other_increases_in_risk.spark_erosion_system' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="otherIncreasesInRiskForm.value.is_spark_errosion_system" class="row toggle-section-content">
          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.spark_erosion_system_document' | translate}}</label>
            <div style="display: flex;">
            <div class="documentUploadInput">
                <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"
                 title="{{otherIncreasesInRiskForm.controls['doc_upload_spark_erosion'].value}}" formControlName="doc_upload_spark_erosion">
            </div>

            <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadSparkErosionDocumentPopup()">
             <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
            </div>
          </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.fireproof_separated' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="fireproof_seperated" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.monitoring' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="monitoring" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.extinguishing_systems' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="extinguishing" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.automatic_shutdown' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="automatic_shutdown" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.supervision_during_operation' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="supervision_during" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label"> {{'objectModule.other_increases_in_risk.inserted_dielectric' | translate}} </label>
            <mat-select class="dropdownStyle"
              placeholder="{{'objectModule.select_lable' | translate}}" formControlName="inserted_dielectric">
              <mat-option style="opacity:0.5">
                {{'objectModule.select_lable' | translate}}
              </mat-option>
              <mat-option *ngFor="let item of insertedDielectricData" [value]="item.id" >
                  <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
          </div>
        </div>
    </div>

    <!-- Fourth -->
    <div [ngClass]="otherIncreasesInRiskForm.value.is_pneumatic_material ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_pneumatic_material">{{'objectModule.other_increases_in_risk.pneumatic_material_transport' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="otherIncreasesInRiskForm.value.is_pneumatic_material" class="row toggle-section-content">
          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.type_of_material' | translate}}
            </label>
            <input type="text" maxlength="100" class="input" formControlName="type_of_material"
              placeholder="{{'objectModule.other_increases_in_risk.type_of_material' | translate}}"
              title="{{otherIncreasesInRiskForm.controls['type_of_material'].value}}">
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.automatic_shutdown_BMA' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="automatic_shutdown_BMA" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.spark_extinguishing' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="spark_ext_or_discharge" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.filtering_systems' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="filtering_systems" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.regular_maintenance' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="is_regular_maintanance" >
                <mat-radio-button [value]="true" (change)="regularMaintananceClicked(true)">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false" (change)="regularMaintananceClicked(false)">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label"> {{'objectModule.other_increases_in_risk.regular_maintenance_by' | translate}} </label>
            <mat-select class="dropdownStyle"
              placeholder="{{'objectModule.select_lable' | translate}}" formControlName="regular_maintanance_by">
              <mat-option style="opacity:0.5">
                {{'objectModule.select_lable' | translate}}
              </mat-option>
              <mat-option *ngFor="let item of regularMaintananceByWhomData" [value]="item.id" >
                  <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
          </div>
        </div>
    </div>

    <!-- Fifth -->
    <div [ngClass]="otherIncreasesInRiskForm.value.is_check_rounds ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_check_rounds">{{'objectModule.other_increases_in_risk.check_rounds' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="otherIncreasesInRiskForm.value.is_check_rounds" class="row toggle-section-content">
          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.who_carries_out_patrols' | translate}}
            </label>
            <input type="text" maxlength="100" class="input" formControlName="who_carries_patrols"
              placeholder="{{'objectModule.other_increases_in_risk.who_carries_out_patrols_placeholder' | translate}}"
              title="{{otherIncreasesInRiskForm.controls['who_carries_patrols'].value}}">
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label"> {{'objectModule.other_increases_in_risk.where_patrols_carried_out' | translate}} </label>
            <mat-select multiple class="dropdownStyle"
              placeholder="{{'objectModule.select_lable' | translate}}" formControlName="where_patrol_carried_out">
              <mat-option *ngFor="let item of patrolCarriedOutData" [value]="item.id" >
                  <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
          </div>

          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.other_increases_in_risk.capture_time' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="capture_time_clock" >
                <mat-radio-button [value]="true">
                    {{'objectModule.power_supply_object.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.power_supply_object.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
          </div>
        </div>
    </div>

    <!-- Sixth -->
    <div [ngClass]="otherIncreasesInRiskForm.value.is_ghost_shifts ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_ghost_shifts">{{'objectModule.other_increases_in_risk.ghost_shifts' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="otherIncreasesInRiskForm.value.is_ghost_shifts" class="row toggle-section-content">
          <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
              {{'objectModule.other_increases_in_risk.safety_precautions' | translate}}
            </label>
            <input type="text" maxlength="100" class="input" formControlName="safety_precautions"
              placeholder="{{'objectModule.other_increases_in_risk.safety_precautions' | translate}}"
              title="{{otherIncreasesInRiskForm.controls['safety_precautions'].value}}">
          </div>
        </div>
    </div>

    <div class="modal upload-logo dialog" [style.display]="yesClickText ? 'block' : 'none'">
      <div class="modal-dialog">
        <div class="modal-content modalContent">
          <!-- Modal Header -->
            <button type="button" class="close" style="text-align: right;" data-dismiss="modal" (click)="hideYesclick()">
              <img alt="" src="../../../../../assets/images/Group 3114.svg" >
            </button>

            <!-- Modal body -->
            <div class="modal-body" >
              <div style="text-align:justify">
                  {{'objectModule.other_increases_in_risk.reported_to_the_insurer' | translate}}
              </div>
            </div>
        </div>
      </div>
    </div>

</form>
<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails"
  [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()"
  (hide)="hidePopup($event)">
</app-upload-document>