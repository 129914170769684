<div>
  <span class="criteria-heading">{{'objectModule.mill_installation_object.mill_installation' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="millinstallationsForm" id="millinstallationsForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="drive_type" class="long-label">{{'objectModule.mill_installation_object.drive_type' |
      translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="drive_type"
      placeholder="{{'objectModule.select_lable' | translate}}" formControlName="drive_type">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option [value]="type.id" *ngFor="let type of driveTypes">{{type.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="annual_grinding_in_tons"
      class="long-label">{{'objectModule.mill_installation_object.annual_grinding_in_tons' |
      translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="6"
      class="input" formControlName="annual_grinding_in_tons"
      placeholder="{{'objectModule.mill_installation_object.annual_grinding_in_tons' | translate}}"
      (keydown)="validationService.SequenceNumberValidation($event, 'keydown',6)"
      (paste)="validationService.SequenceNumberValidation($event, 'paste',6)"
      (blur)="validationService.sqmValidation(millinstallationsForm, $event)" (focus)="validationService.sqmValidation(millinstallationsForm, $event)"
      numbersOnly>
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*"
      (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
      (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)"
      class="input numberText" formControlName="annual_grinding_in_tons"
      placeholder="{{'objectModule.mill_installation_object.annual_grinding_in_tons' | translate}}"
      (blur)="validationService.sqmValidation(millinstallationsForm, $event)" (focus)="validationService.sqmValidation(millinstallationsForm, $event)"
      numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_fireproof_floor_covering"
      class="long-label">{{'objectModule.mill_installation_object.fire_proof_floor_covering' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_fireproof_floor_covering" id="is_fireproof_floor_covering">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.mill_installation_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.mill_installation_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_single_machine_drive_available"
      class="long-label">{{'objectModule.mill_installation_object.single_machine_drive_available' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_single_machine_drive_available" id="is_single_machine_drive_available">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.mill_installation_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.mill_installation_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_fire_resistant_cleaning"
      class="long-label">{{'objectModule.mill_installation_object.fire_resistant_cleaning' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_fire_resistant_cleaning" id="is_fire_resistant_cleaning">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.mill_installation_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.mill_installation_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_extraction_of_flammable_vapors"
      class="long-label">{{'objectModule.mill_installation_object.extraction_of_flamable_vapors_dust' |
      translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_extraction_of_flammable_vapors" id="is_extraction_of_flammable_vapors">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.mill_installation_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.mill_installation_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_speed_monitoring_on_elevators"
      class="long-label">{{'objectModule.mill_installation_object.speed_monitoring_on_elevators' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_speed_monitoring_on_elevators" id="is_speed_monitoring_on_elevators">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.mill_installation_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.mill_installation_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
