import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AZURE } from '@shared/constants/azure-service.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { BaseComponent } from '@shared/components/base.component';
import { Country } from '@shared/interfaces/country.interface';

@Component({
  selector: 'app-address-popup',
  templateUrl: './address-popup.component.html',
  styleUrls: [
    './address-popup.component.css',
    '../new-object-module.component.css',
    '../../object-module.component.scss',
  ],
})
export class AddressPopupComponent extends BaseComponent implements OnInit {
  AddressForm: FormGroup;
  filterlocation: any = [];
  public keyUp = new Subject<string>();
  showList = false;
  statusDisabled: boolean;
  original_liztu = true;
  selectedAddress: any = null;

  isSelectedAddressChange = true;
  isSubmitClicked = false;

  countries$: Observable<Country[]>;

  @Input('customer') customer_name;
  @Input('customerID') customer_id;
  @Input('openaddressModal') openaddressModal;

  @Output('closePopup') closePopup = new EventEmitter<any>();

  constructor(private serviceCall: ApiCallsService) {
    super();
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.AddressForm) {
      this.resetValues();
    }
    // changes.prop contains the old and the new value...
  }

  ngOnInit(): void {
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));

    this.countries$ = this.serviceCall.getCountries();
  }

  initializeForm() {
    const AddressValidation = new FormControl('', [Validators.required]);
    const AddressDescValidation = new FormControl('', [Validators.required]);
    const HouseNumberValidation = new FormControl('', [Validators.required]);
    const PostalCodeValidation = new FormControl('', [Validators.required]);
    const StreetValidation = new FormControl('', [Validators.required]);
    const cityValidation = new FormControl('', [Validators.required]);
    const landValidation = new FormControl('', [Validators.required]);

    this.AddressForm = new FormGroup({
      address: AddressValidation,
      address_description: AddressDescValidation,
      house_number: HouseNumberValidation,
      postal_code: PostalCodeValidation,
      street: StreetValidation,
      city: cityValidation,
      country: landValidation,
    });

    merge(
      this.AddressForm.get('city').valueChanges,
      this.AddressForm.get('street').valueChanges,
      this.AddressForm.get('postal_code').valueChanges,
      this.AddressForm.get('country').valueChanges
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isSelectedAddressChange = true;
      });
  }

  public hasFormError = (controlName: string, errorName: string): boolean => {
    return true;
  }

  autoComplete(value) {
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          res => {
            this.filterAddressFromResults(res.results);
            this.showList = true;
          },
          err => {}
        );
    }
  }

  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      obj.address.lat = obj.position.lat;
      obj.address.lon = obj.position.lon;
      return obj.address;
    });
  }

  onFocusOut(event) {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  selectedLocation(location) {
    this.AddressForm.patchValue({
      address: location.freeformAddress,
      country: location.country,
      city: location.localName ? location.localName : '',
      postal_code: location.postalCode ? location.postalCode : '',
      house_number: location.streetNumber ? location.streetNumber : '',
      street: location.streetName ? location.streetName : '',
    });

    this.selectedAddress = location;
    this.isSelectedAddressChange = false;
  }

  saveFormData() {
    if (this.AddressForm.invalid) {
      this.isSubmitClicked = true;
      return;
    }

    const inputDataObject = this.AddressForm.value;

    inputDataObject.address_on = 'company';
    inputDataObject.address_table_pk = this.customer_id;

    inputDataObject.lat = !this.isSelectedAddressChange
      ? this.selectedAddress.lat
      : '';
    inputDataObject.lon = !this.isSelectedAddressChange
      ? this.selectedAddress.lon
      : '';

    this.serviceCall
      .saveAddress(inputDataObject)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        res => {
          if (res.HasErrors) {
            this.closePopup.emit({ isClose: true, msg: res.message });
          } else {
            this.closePopup.emit({ isClose: true, msg: '' });
          }
          this.resetValues();
        },
        err => {
          this.resetValues();
        }
      );
  }

  resetValues() {
    this.AddressForm.reset();
    this.showList = false;
    this.selectedAddress = null;
    this.filterlocation = [];
    this.isSubmitClicked = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
