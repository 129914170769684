import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { CommunicationApiCallsService } from './api-calls';
import { ADD_COMMUNICATION_REQUEST } from './constants';
import { UserService } from '@core/user.service';
@Component({
  selector: 'app-generic-communication',
  templateUrl: './generic-communication.component.html',
  styleUrls: ['./generic-communication.component.css'],
})
export class GenericCommunicationComponent implements OnInit {
  @Input('addCommunication') addCommunication;
  @Input('communicationId') communicationId;
  @Input('source_type') source_type;
  @Input('source_id') source_id;
  @Output('iscommunicationAddEdit') iscommunicationAddEdit =
    new EventEmitter<any>();
  @Output('isAddCommunication') isAddCommunication = new EventEmitter<any>();
  @Output('toastMessage') toastMessage = new EventEmitter<any>();
  @Input('is_disable') is_disable;
  communicationForm: FormGroup;
  communicationData: any;
  communication_types: any[] = [];
  isValueChangedInForm = false;
  initialFormState: any;
  user_id: number;

  constructor(
    private _httpCallService: ApiCallsService,
    private communicationAPIcalls: CommunicationApiCallsService,
    private readonly _userService: UserService
  ) {
    if (this.addCommunication) {
      this.communicationForm.reset();
    }
    this._userService.loggedInUser$.subscribe(data => {
      this.user_id = data.user_profile.user_data.user_id;
    });
  }

  ngOnInit(): void {
    const requests = [];
    requests.push(
      this._httpCallService
        .getProductLookups('communication_type')
        .pipe(catchError(error => of(error)))
    );
    if (!this.addCommunication) {
      requests.push(
        this.communicationAPIcalls
          .getCommunicationData(this.communicationId)
          .pipe(catchError(error => of(error)))
      );
    }
    forkJoin(requests).subscribe((data: any) => {
      if (!data[0].hasErrors && data[0].data) {
        this.setCommunicationType(data[0].data);
      }
      if (!this.addCommunication) {
        if (!data[1].hasErrors && data[1].communication_details) {
          this.setCommunicationData(data[1].communication_details);
        }
      }
      this.initializeForm();
    });
  }
  setCommunicationData(data) {
    this.communicationData = data[0];
  }
  initializeForm() {
    const formData = this.communicationData;
    this.communicationForm = new FormGroup({
      communicationType: new FormControl(
        formData ? formData.communication_type_id : ''
      ),
      contact: new FormControl(formData ? formData.contact : ''),
      description: new FormControl(formData ? formData.description : ''),
    });
    if (this.is_disable) {
      this.communicationForm.disable();
    }
    if (formData) {
      this.selectCommunication(formData?.communication_type_id);
    }
    this.communicationForm
      .get('communicationType')
      .valueChanges.subscribe(value => {
        this.selectCommunication(value);
      });
    this.initialFormState = this.communicationForm.value;
    this.communicationForm?.valueChanges?.subscribe(changedValues => {
      this.isValueChangedInForm =
        JSON.stringify(this.initialFormState) !=
        JSON.stringify(this.setControls());
    });
  }
  setControls() {
    const controlValues = {};
    Object.keys(this.communicationForm.controls).forEach(control => {
      controlValues[control] = this.communicationForm.get(control).value;
    });
    return controlValues;
  }
  setCommunicationType(data) {
    this.communication_types = data;
  }
  saveCommunication() {
    const request: ADD_COMMUNICATION_REQUEST = {
      id: this.communicationId ? this.communicationId : null,
      contact: this.communicationForm.controls.contact.value,
      description: this.communicationForm.controls.description.value,
      communication_type_id:
        this.communicationForm.controls.communicationType.value,
      communication_type: this.communication_types.filter(
        item =>
          item.id == this.communicationForm.controls.communicationType.value
      )[0]?.desc,
      source_id: this.source_id,
      source_type: this.source_type,
      updated_by: this.user_id,
    };
    this.communicationAPIcalls
      .addEditCommunicationData(request)
      .subscribe(data => {
        this.iscommunicationAddEdit.emit(false);
        this.isAddCommunication.emit(false);
        this.toastMessage.emit(data.message);
      });
  }
  selectCommunication(value) {
    switch (value) {
      case 78:
        this.communicationForm
          .get('contact')
          .setValidators(
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
          );
        break;
      case 77:
      case 76:
      case 75:
        this.communicationForm
          .get('contact')
          .setValidators(Validators.pattern('^[0-9]{0,20}$'));
        break;
      case 79:
      case 80:
        this.communicationForm
          .get('contact')
          .setValidators(
            Validators.pattern('^[0-9a-zA-ZÀ-ž/.,\'&*~`!@^()_<>]{0,100}')
          );
    }
  }
}
