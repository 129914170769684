import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { SourceType } from '@shared/models/source-type.model';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './claim-appointments-page.component.html',
})
export class ClaimAppointmentsPageComponent {
  sourceTypes = SourceType;
  readonly claimId$ = this._activatedRoute.queryParams.pipe(
    map(item => atob(item.claim))
  );
  readonly customerId$ = this._customerSharedService.selectedCustomerId$;
  readonly divisionId$ =
    this._customerSharedService.selectedCustomerDivisionId$;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _customerSharedService: CustomerSharedService
  ) {}
}
