import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  LocationConstants,
  UploadTypeConstants,
} from '@shared/constants/location.constants';
import { Criteria } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-protection-factory-premises',
  templateUrl: './protection-factory-premises.component.html',
  styleUrls: ['./protection-factory-premises.component.css'],
})
export class ProtectionFactoryPremisesComponent implements OnInit {
  @Input('objectType') objectType;
  @Input('object_data') object_data;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;

  browser: string;
  isFormValueUpdated: boolean;
  request: { document_ids: any; criteria_id: number; jsonb: string };
  protectionFactoryPremisesForm: FormGroup;
  selectedDocumentsArray = {};
  showConstantGateSurveillancePopup: boolean;
  uploadObjectDetails: {
    documentSource: any;
    objectTypeId: number;
    documentType: string;
  };
  selectedConstantGateSurveillanceArray = [];
  constantGateSurveillanceIds = [];
  selectedAccessControlArray = [];
  showAccessControlPopup: boolean;
  showEnclosurePopup: boolean;
  selectedEnclosureArray = [];
  showIlluminationPremisesPopup: boolean;
  selectedIlluminationPremisesArray = [];
  showIlluminationenclosurePopup: boolean;
  selectedIlluminationenclosureArray = [];
  selectedTerrainClearArray = [];
  showTerrainClearPopup: boolean;
  constantAccessControlIds = [];
  constantEnclosureIds = [];
  constantIlluminationPremisesIds = [];
  constantTerrainClearIds = [];
  constantIlluminationenclosureIds = [];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    public validationService: ValidationService,
    private serviceCall: ApiCallsService,
    private httpService: HttpClient
  ) {}

  ngOnInit(): void {
    this.browser = this.detectBrowser();
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  initForm() {
    this.protectionFactoryPremisesForm = this.fb.group({
      is_surveillance_of_the_premises: [null],
      is_factory_security: [null],
      is_constant_gate_surveillance: [null],
      constant_gate_surveillance: this.fb.group({
        constant_gas_surveillance: [],
      }),
      is_access_control: [null],
      access_control_doc: this.fb.group({
        doc: [],
      }),
      is_enclosure_2m_high: [null],
      enclosure_2m_high_doc: this.fb.group({
        doc: [],
      }),
      is_illumination_premises: [null],
      illumination_premises_doc: this.fb.group({
        doc: [],
      }),
      is_illumination_outer_walls_of_the_building_doc: [null],
      illumination_outer_walls_of_the_building_doc: this.fb.group({
        doc: [],
      }),
      is_terrain_is_clear: [null],
      terrain_is_clear: this.fb.group({
        doc: [],
      }),
    });
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        LocationConstants.PROTECTION_OF_THE_FACTORY_PREMISES
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;
    if (formData && formData['protection-factory-premises']) {
      this.protectionFactoryPremisesForm.setValue(
        formData['protection-factory-premises']
      );
    }
    this.isFormValueUpdated = false;
    this.protectionFactoryPremisesForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.protectionFactoryPremisesForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
    this.getDocumentUploadData(record_id);
  }
  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 12)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.POTFP_CONSTANT_GAS_SURVEILLANCE
              ) {
                this.selectedConstantGateSurveillanceArray[
                  document.document_name
                ] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.POTFP_ACCESS_CONTROL
              ) {
                this.selectedAccessControlArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.POTFP_CLIMB_OVER_PROTECTION
              ) {
                this.selectedEnclosureArray[document.document_name] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.POTFP_ILLUMINATION_OF_PERMISES
              ) {
                this.selectedIlluminationPremisesArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.POTFP_TERRAIN_CLEAR
              ) {
                this.selectedTerrainClearArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.POTFP_OUTER_WALLS_BUILDING
              ) {
                this.selectedIlluminationenclosureArray[
                  document.document_name
                ] = document;
              }
            });
            if (data.documents) {
              this.protectionFactoryPremisesForm
                .get('constant_gate_surveillance.constant_gas_surveillance')
                .setValue(
                  Object.keys(this.selectedConstantGateSurveillanceArray).join(
                    ', '
                  )
                );
              this.protectionFactoryPremisesForm
                .get('access_control_doc.doc')
                .setValue(
                  Object.keys(this.selectedAccessControlArray).join(', ')
                );
              this.protectionFactoryPremisesForm
                .get('enclosure_2m_high_doc.doc')
                .setValue(Object.keys(this.selectedEnclosureArray).join(', '));
              this.protectionFactoryPremisesForm
                .get('illumination_premises_doc.doc')
                .setValue(
                  Object.keys(this.selectedIlluminationPremisesArray).join(', ')
                );
              this.protectionFactoryPremisesForm
                .get('terrain_is_clear.doc')
                .setValue(
                  Object.keys(this.selectedTerrainClearArray).join(', ')
                );
              this.protectionFactoryPremisesForm
                .get('illumination_outer_walls_of_the_building_doc.doc')
                .setValue(
                  Object.keys(this.selectedIlluminationenclosureArray).join(
                    ', '
                  )
                );
            }
          }
        });
    }
  }

  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  uploadConstantGateSurveillanceDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedConstantGateSurveillanceArray,
    };
    this.showConstantGateSurveillancePopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: 'potfp_constant_gas_surveillance',
    };
  }
  uploadTerrainClearDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedTerrainClearArray };
    this.showTerrainClearPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: 'potfp_terrain_clear',
    };
  }
  uploadIlluminationenclosureDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedIlluminationenclosureArray,
    };
    this.showIlluminationenclosurePopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: 'potfp_outer_walls_building',
    };
  }
  uploadIlluminationPremisesDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedIlluminationPremisesArray };
    this.showIlluminationPremisesPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: 'potfp_illumination_of_permises',
    };
  }
  uploadEnclosureDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedEnclosureArray };
    this.showEnclosurePopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: 'potfp_climb_over_protection',
    };
  }
  uploadAccessControlDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedAccessControlArray };
    this.showAccessControlPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: 'potfp_access_control',
    };
  }

  showDocumentPopup() {
    return (
      this.showConstantGateSurveillancePopup ||
      this.showAccessControlPopup ||
      this.showEnclosurePopup ||
      this.showIlluminationPremisesPopup ||
      this.showTerrainClearPopup ||
      this.showIlluminationenclosurePopup
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showConstantGateSurveillancePopup) {
      this.constantGateSurveillanceIds = [];
      this.selectedConstantGateSurveillanceArray = {
        ...selectedDocumentsArray,
      };
      this.showConstantGateSurveillancePopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.constantGateSurveillanceIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.protectionFactoryPremisesForm
        .get('constant_gate_surveillance.constant_gas_surveillance')
        .setValue(Object.keys(selectedDocumentsArray).join(', '));
      this.protectionFactoryPremisesForm
        .get('constant_gate_surveillance.constant_gas_surveillance')
        .markAsTouched();
    }
    if (this.showAccessControlPopup) {
      this.constantAccessControlIds = [];
      this.selectedAccessControlArray = { ...selectedDocumentsArray };
      this.showAccessControlPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.constantAccessControlIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.protectionFactoryPremisesForm
        .get('access_control_doc.doc')
        .setValue(Object.keys(selectedDocumentsArray).join(', '));
      this.protectionFactoryPremisesForm
        .get('access_control_doc.doc')
        .markAsTouched();
    }
    if (this.showEnclosurePopup) {
      this.constantEnclosureIds = [];
      this.selectedEnclosureArray = { ...selectedDocumentsArray };
      this.showEnclosurePopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.constantEnclosureIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.protectionFactoryPremisesForm
        .get('enclosure_2m_high_doc.doc')
        .setValue(Object.keys(selectedDocumentsArray).join(', '));
      this.protectionFactoryPremisesForm
        .get('enclosure_2m_high_doc.doc')
        .markAsTouched();
    }
    if (this.showIlluminationPremisesPopup) {
      this.constantIlluminationPremisesIds = [];
      this.selectedIlluminationPremisesArray = { ...selectedDocumentsArray };
      this.showIlluminationPremisesPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.constantIlluminationPremisesIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.protectionFactoryPremisesForm
        .get('illumination_premises_doc.doc')
        .setValue(Object.keys(selectedDocumentsArray).join(', '));
      this.protectionFactoryPremisesForm
        .get('illumination_premises_doc.doc')
        .markAsTouched();
    }
    if (this.showTerrainClearPopup) {
      this.constantTerrainClearIds = [];
      this.selectedTerrainClearArray = { ...selectedDocumentsArray };
      this.showTerrainClearPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.constantTerrainClearIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.protectionFactoryPremisesForm
        .get('terrain_is_clear.doc')
        .setValue(Object.keys(selectedDocumentsArray).join(', '));
      this.protectionFactoryPremisesForm
        .get('terrain_is_clear.doc')
        .markAsTouched();
    }
    if (this.showIlluminationenclosurePopup) {
      this.constantIlluminationenclosureIds = [];
      this.selectedIlluminationenclosureArray = { ...selectedDocumentsArray };
      this.showIlluminationenclosurePopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.constantIlluminationenclosureIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.protectionFactoryPremisesForm
        .get('illumination_outer_walls_of_the_building_doc.doc')
        .setValue(Object.keys(selectedDocumentsArray).join(', '));
      this.protectionFactoryPremisesForm
        .get('illumination_outer_walls_of_the_building_doc.doc')
        .markAsTouched();
    }

    this.selectedDocumentsArray = {};
  }
  buildFormObject() {
    const formValuesToPersist = {
      'protection-factory-premises': this.protectionFactoryPremisesForm.value,
    };
    this.request = {
      document_ids: [
        ...new Set([
          ...this.constantGateSurveillanceIds,
          ...this.constantAccessControlIds,
          ...this.constantEnclosureIds,
          ...this.constantIlluminationPremisesIds,
          ...this.constantTerrainClearIds,
          ...this.constantIlluminationenclosureIds,
        ]),
      ],
      criteria_id: LocationConstants.PROTECTION_OF_THE_FACTORY_PREMISES,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.protectionFactoryPremisesForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
