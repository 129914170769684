<div class="communication-overview" *ngIf="!is_edit">
    <h6 class="customer-management-page-heading">{{'customer-management.communication-details.communication_details' |
        translate}}</h6>
    <div class="table-outer-container insured-object-section">
        <div class="infiniteScrollDiv">
            <mat-table class="custom-table" style="table-layout: fixed" mat-table [dataSource]="dataSource"
                cellpadding="5" cellspacing="0" matSort>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'user-data.customer_name' | translate | uppercase}}</span>
                    </mat-header-cell>
                    <mat-cell class="custom-checkbox-container" *matCellDef="let element">
                        <div style="display: flex; align-items: baseline;">
                            <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.customer_name}}"
                                style="margin-top: 4px;">
                                {{element.customer_name}}
                            </span>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="communication_type">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'customer-management.communication-details.Communication-type' | translate | uppercase}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="padding: 8px 5px 8px 5px;">
                        <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.Communication_type}}">
                            {{element.communication_type}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="contact">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'customer-management.communication-details.Contact' | translate | uppercase}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="padding: 8px 5px 8px 5px;">
                        <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.contact}}">
                            {{element.contact}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="desc" style="padding: 8px 5px 8px 5px;">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'customer-management.communication-details.Description' | translate | uppercase}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.description}}">
                            {{element.description}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef style="text-align: center; width: 130px;">
                        <span>{{'user-data.AKTION_text' | translate | uppercase}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="control-section" style="text-align: center;">
                            <div class="subscribe">
                                <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                                <div class="hide">
                                    <div class="action-btn-align">
                                        <button
                                          class="action-btn"
                                          [disabled]="!editCustomer"
                                          placement="top"
                                          ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                                          tooltipClass="generic-tooltip"
                                          (click)="editDetails(element)"
                                        >
                                            <img alt="" [src]="actionButton.EDIT_WO_BACKGROUND">
                                        </button>
                                        <button
                                          class="action-btn"
                                          [disabled]="!editCustomer"
                                          placement="top"
                                          ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                                          tooltipClass="generic-tooltip"
                                          (click)="openModal(element.id)"
                                        >
                                            <img alt="" [src]="actionButton.DELETE_WO_BACKGROUND">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
    </div>
</div>
<div class="edit-block" *ngIf="is_edit">
    <div class="communication-edit">
        <div class="contracts-tabs-heading">
            <h6 style="font-weight: 600;" *ngIf="!isAddCommunication">
                {{'customer-management.communication-details.edit_Communication' | translate}}</h6>
            <h6 style="font-weight: 600;" *ngIf="isAddCommunication">
                {{'customer-management.communication-details.add_communication' | translate}}</h6>
        </div>
        <div class="form">
            <form class="general-form product-module-html" [formGroup]="communicationForm" id="form">
                <div class="row">
                    <div class="col-md-3 col-sm-3">
                        <label for="" class="label"
                            style="text-transform: initial;">{{'customer-management.communication-details.communication-type'
                            | translate}} <span class="text-danger">*</span></label><br />
                        <mat-select class="dropdownStyle" placeholder="{{'header.Suche_tooltip' | translate}}"
                            (selectionChange)="selectCommunication($event)" formControlName="communicationType">
                            <mat-option [value]="communication.id" *ngFor="let communication of communication_types">
                                {{communication.desc}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <label for="" class="label"
                            style="text-transform: initial;">{{'customer-management.communication-details.contact' |
                            translate}} <span class="text-danger">*</span></label><br />
                        <input type="text" [class.is-invalid]="communicationForm.get('contact').invalid" class="input"
                            maxlength="100" formControlName="contact"
                            placeholder="{{'customer-management.communication-details.contact' | translate}}">
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <label for="" class="label"
                            style="text-transform: initial;">{{'customer-management.communication-details.description' |
                            translate}} <span class="text-danger">*</span></label><br />
                        <input type="text" class="input" maxlength="100"
                            title="{{communicationForm.controls['description'].value}}" formControlName="description"
                            placeholder="{{'customer-management.communication-details.description' | translate}}">
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="action-section">
        <button class="custom-btn cancel-btn" (click)="close()">
            {{'template-management.cancel_label' | translate}}
        </button>
        <button class="custom-btn save-btn" (click)="saveCommunication()"
            [disabled]="communicationForm.get('contact').invalid">
            {{'update-customer.Speichern_text' | translate}}
        </button>
    </div>
</div>

<div class="modal modelBackground" [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog spacing">
        <div class="modal-content p-3">
            <div class="d-flex header-content p-unset">
                <div class="pointer">
                    <img (click)="hideModal()" alt="" height="65px" src="../../../assets/images/Group 3235.svg">
                </div>
            </div>
            <div class="modal-body body-text">
                <span class="delete-heading">{{'objectModule.Delete_popup_heading' | translate}}</span>
                <br /><br />
                <span class="delete-text">{{'objectModule.Delete_popup_text' | translate}}</span>
            </div>
            <div class="modal-footer ml-auto mr-auto">
                <button type="button"
                    (click)="hideModal()" class="custom-btn cancel-btn">
                    {{'contact-manager.NEIN_text' | translate}}</button>
                <button class="ml-2 custom-btn save-btn" type="button"
                    (click)="deleteCommunication(delete_item_id)">
                    {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<app-warning-popup *ngIf="showWarningPopUp" [customerWarningMsg]="customerWarningMsg"
    (savedata)="saveCommunicationData($event)" (handlePopup)="handleWarningPopup($event)"></app-warning-popup>
