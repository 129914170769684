<mat-horizontal-stepper (selectionChange)="selectionChange($event)" linear #stepper labelPosition="bottom">
  <mat-step *ngFor="let step of allStepsArray; let valIndex = index; let last = last;" [stepControl]="step.stepperForm" 
    [editable]="allStepsArray[valIndex].isEditable" [completed]="valIndex != stepper.selectedIndex" [label]="allStepsArray[valIndex].name">
    <ng-container>
      <app-new-product *ngIf="valIndex == 0 && stepper.selectedIndex == 0" (emitFormValue)="formValues($event)"
        (originalFormData) = "getOriginalFormData($event)" [isFormSubmitted]="isFormSubmitted"> </app-new-product>
      <app-cover-bundles-stepper *ngIf="valIndex == 1 && stepper.selectedIndex == 1 && isStepLoaded" (isProductdeleteSuccess)="isProductdeleteSuccess($event)" 
        (emitFormValue)="formValues($event)" (saveFormValue)="saveValues($event)" [refreshStepData]="refreshStepData"
        (updatedNodeSelection)="updatedNodeSelection($event)" [step]="step.index" [consolidateData]="consolidateData">
      </app-cover-bundles-stepper>
      <app-covering-stepper *ngIf="valIndex == 2 && stepper.selectedIndex == 2 && isStepLoaded" (emitFormValue)="formValues($event)"
        (saveFormValue)="saveValues($event)" [refreshStepData]="refreshStepData" (isProductdeleteSuccess)="isProductdeleteSuccess($event)"
        (updatedNodeSelection)="updatedNodeSelection($event)" [step]="step.index" [consolidateData]="consolidateData">
      </app-covering-stepper>
      <app-damage-type-stepper *ngIf="valIndex == 3 && stepper.selectedIndex == 3 && isStepLoaded" (emitFormValue)="formValues($event)"
        (saveFormValue)="saveValues($event)" [refreshStepData]="refreshStepData" (isProductdeleteSuccess)="isProductdeleteSuccess($event)"
        (updatedNodeSelection)="updatedNodeSelection($event)" [step]="step.index" [consolidateData]="consolidateData">
      </app-damage-type-stepper>
      <app-mask-generator-stepper *ngIf="valIndex == 4 && stepper.selectedIndex == 4 && isStepLoaded"
        (emitFormValue)="formValues($event)" (saveFormValue)="saveValues($event)" (saveFormulaValue)="saveFormulaValue($event)" [refreshStepData]="refreshStepData"
        (updatedNodeSelection)="updatedNodeSelection($event)" [step]="step.index" [consolidateData]="consolidateData">
      </app-mask-generator-stepper>
      <sparte-details-stepper *ngIf="valIndex == 5 && stepper.selectedIndex == 5 && isStepLoaded" (updateisFormTouchedValue)="formsUpdated($event)"
      [consolidateData]="consolidateData">
      </sparte-details-stepper>
      <app-summary-stepper *ngIf="valIndex == 6 && stepper.selectedIndex == 6 && isStepLoaded"
        [isFormUpdated]="isFormUpdated()" (versionDetails)="setVersionDetails($event)" (emitFormValue)="formValues($event)">
      </app-summary-stepper>
    </ng-container>
    <div class="mat-custom-buttons" *ngIf="isStepLoaded">
      <button *ngIf="valIndex !== 0" class="btn btn-default back-button-props" type="button"
        (click)="prevStep(valIndex)">{{'invite-user.Zuruck_text' | translate}}</button>
      <button *ngIf="!last" class="btn button-props" type="button"
        (click)="nextStep(valIndex)">{{'update-user.Next_text' | translate }}</button>
      <button *ngIf="last" class="btn button-props" type="submit"
        (click)="submitProduct(valIndex)">{{'product-management.stepper_common_msg.save' | translate}}</button>
    </div>
  </mat-step>

  <ng-template matStepperIcon="done" let-index="index">
    {{index+1}}
  </ng-template>
  <ng-template matStepperIcon="selected" let-index="index">
    {{index+1}}<mat-icon class="panoramaFishStyle">panorama_fish_eye</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="edit" let-index="index">
    {{index+1}}<mat-icon class="panoramaFishStyle">panorama_fish_eye</mat-icon>
  </ng-template>
</mat-horizontal-stepper>
<div id="successToast"></div>
<product-close-alert-dialog *ngIf="showWarningPopUp" [productwarnmsg]="productWarningMsg" (savedata)="saveModalValue($event)" (handlePopup)="handlePopup($event)"></product-close-alert-dialog>
<product-close-alert-dialog *ngIf="isProductSaveWarning" [productwarnmsg]="productStateWarningMsg" (savedata)="saveModalValue($event)" (handlePopup)="handlePopup($event)"></product-close-alert-dialog>