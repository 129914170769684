import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';

@Component({
  selector: 'app-foundary',
  templateUrl: './foundary.component.html',
  styleUrls: ['./foundary.component.css'],
})
export class FoundaryComponent implements OnInit {
  foundaryForm: FormGroup;
  firingType = [];
  dedustingLocated = [];
  filterElements = [];
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService
  ) {
    this.serviceCall
      .getLookupData(31)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.firingType = data.data;
      });
    this.serviceCall
      .getLookupData(30)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.dedustingLocated = data.data;
      });
    this.serviceCall
      .getLookupData(29)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.filterElements = data.data;
      });
  }

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[BuildingConstants.FOUNDRY] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.FOUNDRY]
          .saved_criteria
      );
    this.assignAttributeValues(formData);
    this.isFormValueUpdated = false;
    this.foundaryForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.foundaryForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  assignAttributeValues(formData: any) {
    const type_of_firing = new FormControl(
      {
        value: formData ? formData.foundary.type_of_firing : null,
        disabled: false,
      },
      []
    );
    const is_refractory_lining = new FormControl(
      {
        value: formData ? formData.foundary.is_refractory_lining : null,
        disabled: false,
      },
      []
    );
    const is_it_possible_to_switch_off_the_fuel_supply_from_a_safe_place =
      new FormControl(
        {
          value: formData
            ? formData.foundary
                .is_it_possible_to_switch_off_the_fuel_supply_from_a_safe_place
            : null,
          disabled: false,
        },
        []
      );
    const is_there_redundant_temperature_monitoring_with_emergency_shutdown =
      new FormControl(
        {
          value: formData
            ? formData.foundary
                .is_there_redundant_temperature_monitoring_with_emergency_shutdown
            : null,
          disabled: false,
        },
        []
      );
    const do_furnaces_have_drip_pans_for_melt = new FormControl(
      {
        value: formData
          ? formData.foundary.do_furnaces_have_drip_pans_for_melt
          : null,
        disabled: false,
      },
      []
    );
    const where_are_the_filters_for_the_furnace_dedusting_located =
      new FormControl(
        {
          value: formData
            ? formData.foundary
                .where_are_the_filters_for_the_furnace_dedusting_located
            : null,
          disabled: false,
        },
        []
      );
    const are_the_filter_elements_combustible = new FormControl(
      {
        value: formData
          ? formData.foundary.are_the_filter_elements_combustible
          : null,
        disabled: false,
      },
      []
    );
    const is_light_metals_are_also_cast = new FormControl(
      {
        value: formData
          ? formData.foundary.is_light_metals_are_also_cast
          : null,
        disabled: false,
      },
      []
    );
    const basin_for_finishing = new FormControl(
      {
        value: formData ? formData.foundary.basin_for_finishing : null,
        disabled: false,
      },
      []
    );
    const annotations = new FormControl({
      value: formData ? formData.foundary.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.foundary.is_annotation : null,
      disabled: false,
    });
    this.foundaryForm = this.fb.group({
      type_of_firing,
      is_refractory_lining,
      is_it_possible_to_switch_off_the_fuel_supply_from_a_safe_place,
      is_there_redundant_temperature_monitoring_with_emergency_shutdown,
      do_furnaces_have_drip_pans_for_melt,
      where_are_the_filters_for_the_furnace_dedusting_located,
      are_the_filter_elements_combustible,
      is_light_metals_are_also_cast,
      basin_for_finishing,
      annotations,
      is_annotation,
    });
  }
  buildFormObject() {
    const formValuesToPersist = {
      foundary: this.foundaryForm.value,
    };
    formValuesToPersist.foundary.type_of_firing = formValuesToPersist.foundary
      .type_of_firing
      ? formValuesToPersist.foundary.type_of_firing
      : null;
    formValuesToPersist.foundary.where_are_the_filters_for_the_furnace_dedusting_located =
      formValuesToPersist.foundary
        .where_are_the_filters_for_the_furnace_dedusting_located
        ? formValuesToPersist.foundary
            .where_are_the_filters_for_the_furnace_dedusting_located
        : null;
    formValuesToPersist.foundary.are_the_filter_elements_combustible =
      formValuesToPersist.foundary.are_the_filter_elements_combustible
        ? formValuesToPersist.foundary.are_the_filter_elements_combustible
        : null;
    this.request = {
      criteria_id: BuildingConstants.FOUNDRY,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.foundaryForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
