<form class="row object-module-html" [formGroup]="modelSamplesToolsForm" id="modelSampleForm">
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label" *ngIf="!(!modelSamplesToolsForm.controls['object_name'].valid &&
              (modelSamplesToolsForm.controls['object_name']?.touched || isSubmitClicked) && modelSamplesToolsForm.invalid )">
          {{'objectModule.newObjecttype' | translate}}</label>
        <label class="touched-warning" *ngIf="!modelSamplesToolsForm.controls['object_name'].valid
                     && (modelSamplesToolsForm.controls['object_name']?.touched || isSubmitClicked)
                     && modelSamplesToolsForm.invalid ">
          {{'objectModule.newObjecttype_error' | translate}}
        </label>
        <input type="text" maxlength="100" class="input" formControlName="object_name" title="{{modelSamplesToolsForm.controls['object_name'].value}}" placeholder="{{'objectModule.newObjecttype' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6" style="display: flex;">
      <div style="width: 100%;">
        <label for="" *ngIf="!errorNewValue && !errorNewValue1" class="label">{{'objectModule.value_label' | translate }}</label>
        <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
        *ngIf="errorNewValue || errorNewValue1">{{'objectModule.value_label' | translate}} : {{'objectModule.limitError' | translate}}"</label>
         <div style="display: flex;">
        <mat-select id="currencyDropdown" (selectionChange)="currancyChanged($event)"
            (click)="focusCurrency()" (keyup.enter)="focusCurrency()"
            class="dropdownStyle currencyDrop currecnyStyle" formControlName="currency_type_id"
            [disableOptionCentering]="true"  title="{{selectedCurrency}}"
            panelClass="currencyTypeDropdown"
            #currencyDropdown
            >
            <mat-select-trigger>
                <span>{{currancySymbol}}</span>
              </mat-select-trigger>
            <input type="text" id="currencyDD" class="searchYear" maxlength="5" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                [formControl]="currencyType">
            <mat-option *ngFor="let ele of currencyTypes" [value]="ele.id" matTooltip="{{ele.cur_symbol}}" >
                <span style="display: flex;">
                    <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                    <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                </span>
            </mat-option>
        </mat-select>
        <input  type="text" style="border-radius: 0px;
          height: 39px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="changeNumber($event.target.value, 'newValue')" formControlName="value" placeholder="{{'objectModule.value_label' | translate}}">
          <input  type="text" style="border-radius: 0px;
          height: 39px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="changeNumber($event.target.value, 'newValue')" formControlName="value" placeholder="{{'objectModule.value_label' | translate}}">
         <mat-select id="valueDropdown" class="dropdownStyle valueTypeStyle ellipsis" placeholder="{{'objectModule.select_lable' | translate}}"
           formControlName="value_type_id" (selectionChange)="valueTypeChange($event)" [title]="getValue">
         <mat-option style="opacity:0.5" (click)="resetValueType()">{{'objectModule.select_lable' | translate}}</mat-option>
         <mat-option *ngFor="let ele of valueTypes" [value]="ele.id" matTooltip="{{ele.name}}" >{{ele.name}}</mat-option>
         </mat-select>
      </div>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.Description_label' | translate }}
        </label>
        <input type="text" maxlength="100" class="input" title="{{modelSamplesToolsForm.controls['description'].value}}" formControlName="description" placeholder="{{'objectModule.Description_label' | translate }}">
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="owner"
        class="label">{{'objectModule.owner' | translate}}</label>
      <div class="radio-group-align-long">
        <mat-radio-group formControlName="owner" id="owner">
          <mat-radio-button class="radio-pad" value="self">{{'objectModule.self' | translate}}
          </mat-radio-button>
          <mat-radio-button class="radio-pad" value="foreign">{{'objectModule.foreign' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">
          {{'objectModule.warehousing_contracts' | translate}}
      </label>
      <div style="display: flex;">
          <div class="documentUploadInput">
              <input class="input" readonly title="{{modelSamplesToolsForm.controls['warehousing_contracts'].value}}" formControlName="warehousing_contracts"
                  placeholder="{{'objectModule.warehousing_contracts' | translate}}">
          </div>

          <div class="documentUploadButton">
              <button class="docButton" type="button" (click)="uploadDoc()">
                  <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
              </button>
          </div>
      </div>
  </div>
  <app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray"
  [showDocumentPopup]="showDocumentPopup" (hide)="hidePopup($event)"></app-upload-document>
</form>
