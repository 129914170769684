import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.css']
})
export class FileViewerComponent implements OnInit {
  searchTextRight = '';
  searchTextLeft = '';
  @ViewChild(MatSort) sort: MatSort;
  @Input('availableTemplates') availableValues;
  @Input('selectedTemplates') selectedValues;
  @Output('modifiedSelectedValues') modifiedSelectedValues = new EventEmitter<any>();

  dataValuesAvailable = new MatTableDataSource<any>();
  dataValuesSelected = new MatTableDataSource<any>();
  availableTempSelection = new SelectionModel<any>(true, []);
  selectedTempSelection = new SelectionModel<any>(true, []);

  displayedAvailableColumns: string[] = [
    'select',
    'template_name',
    'created_on',
    'last_updated_on'
  ];

  displayedSelectedColumns: string[] = [ ...this.displayedAvailableColumns, 'created_by' ];
  constructor() {
  }

  ngOnInit(): void {
    const localSelected = this.selectedValues.map(values => values.id) || [];
    this.availableValues = this.availableValues.filter(avalValue => !localSelected.includes(avalValue.id));
    this.dataValuesAvailable.data = this.availableValues;
    this.dataValuesSelected.data = this.selectedValues;
    this.modifiedSelectedValues.emit(this.selectedValues);
    this.availableTempSelection.clear();
    this.selectedTempSelection.clear();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableValues) {
      this.ngOnInit();
    }
  }

  moveLeftToRight() {
    const tempAvailableValues = [...this.availableValues];
    tempAvailableValues.forEach((value) => {
      if (this.availableTempSelection.isSelected(value)) {
        this.selectedValues.push(value);
        const index = this.availableValues.findIndex(d => d.id === value.id);
        this.availableValues.splice(index, 1);
      }
    });
    this.availableTempSelection.clear();
    this.dataValuesAvailable.data = this.availableValues;
    this.dataValuesSelected.data = this.selectedValues;
    this.modifiedSelectedValues.emit(this.selectedValues);
  }

  moveRightToLeft() {
    const tempSelectedValues = [...this.selectedValues];
    tempSelectedValues.forEach((value) => {
      if (this.selectedTempSelection.isSelected(value)) {
        this.availableValues.push(value);
        const index = this.selectedValues.findIndex(d => d.id === value.id);
        this.selectedValues.splice(index, 1);
      }
    });
    this.selectedTempSelection.clear();
    this.dataValuesAvailable.data = this.availableValues;
    this.dataValuesSelected.data = this.selectedValues;
    this.modifiedSelectedValues.emit(this.selectedValues);
  }

  isAllSelected(valuesInTable, selection) {
    const numSelected = selection.selected.length;
    const numRows = valuesInTable.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(valuesInTable, selection) {
    this.isAllSelected(valuesInTable, selection) ?
        selection.clear() :
        valuesInTable.forEach(row => selection.select(row));
  }

  applyAvailableDataFilter(event: Event, dataValues) {
    const filterValue = (event.target as HTMLInputElement).value;
    dataValues.filter = filterValue.trim().toLowerCase();
  }
}
