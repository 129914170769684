import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';

@Component({
  selector: 'app-printing-companies',
  templateUrl: './printing-companies.component.html',
  styleUrls: [
    './printing-companies.component.css',
    '../../new-object-module.component.css',
  ],
})
export class PrintingCompaniesComponent implements OnInit {
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;

  isFormValueUpdated: boolean;
  PrintingForm: FormGroup;

  request: BuildingObject;

  constructor(private serviceCall: ApiCallsService) {}
  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.PRINTING_COMPANIES
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          BuildingConstants.PRINTING_COMPANIES
        ].saved_criteria
      );

    this.PrintingForm = new FormGroup({
      is_fire_resistant_separated_paper_store: new FormControl(
        {
          value: formData
            ? formData.printing_companies
                .is_fire_resistant_separated_paper_store
            : null,
          disabled: false,
        },
        []
      ),
      is_offset_printing: new FormControl(
        {
          value: formData
            ? formData.printing_companies.is_offset_printing
            : null,
          disabled: false,
        },
        []
      ),
      is_rotary_printing: new FormControl(
        {
          value: formData
            ? formData.printing_companies.is_rotary_printing
            : null,
          disabled: false,
        },
        []
      ),
      is_use_of_flammable_solvents: new FormControl(
        {
          value: formData
            ? formData.printing_companies.is_use_of_flammable_solvents
            : null,
          disabled: false,
        },
        []
      ),
      is_roller_laundry: new FormControl(
        {
          value: formData
            ? formData.printing_companies.is_roller_laundry
            : null,
          disabled: false,
        },
        []
      ),
      is_mixing_and_stirring_system: new FormControl(
        {
          value: formData
            ? formData.printing_companies.is_mixing_and_stirring_system
            : null,
          disabled: false,
        },
        []
      ),
      is_solvent_recovery_plant: new FormControl(
        {
          value: formData
            ? formData.printing_companies.is_solvent_recovery_plant
            : null,
          disabled: false,
        },
        []
      ),
      is_extraction_of_flammable_vapour: new FormControl(
        {
          value: formData
            ? formData.printing_companies.is_extraction_of_flammable_vapour
            : null,
          disabled: false,
        },
        []
      ),
      annotations: new FormControl(
        {
          value: formData ? formData.printing_companies.annotations : null,
          disabled: false,
        },
        []
      ),
      is_annotation: new FormControl(
        {
          value: formData ? formData.printing_companies.is_annotation : null,
          disabled: false,
        },
        []
      ),
    });

    this.isFormValueUpdated = false;

    this.PrintingForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.PrintingForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }

  buildFormObject() {
    const formValuesToPersist = {
      printing_companies: this.PrintingForm.value,
    };
    this.request = {
      criteria_id: BuildingConstants.PRINTING_COMPANIES,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }

  ngOnDestroy() {
    if (this.isFormValueUpdated && this.PrintingForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
