<div *ngIf="!bankAddEdit">
  <app-bank-details
    (isBankAddEdit)="isBankAddEdit($event)"
    (bankId)="getBankId($event)"
  ></app-bank-details>
</div>
<div *ngIf="bankAddEdit">
  <app-add-bank
    [bankId]="editBankId"
    [addCustomer]="add_customer"
    (isBankAddEdit)="isBankAddEdit($event)"
    (message)="showMessage($event)"
    (isBankAdd)="isBankAdd($event)"
  ></app-add-bank>
</div>
