export enum WidgetIds {
  MESSAGE_OF_THE_DAY = 1,
  SALES_TARGET = 2,
  TODO_LIST = 3,
  INVENTORY_CHANGE_CONTROLLING = 4,
  CUSTOMER_STRUCTURE_BY_PREMIUM = 5,
  CUSTOMER_STRUCTURE_BY_INDUSTRY = 6,
  INVENTORY_PENETRATION_AP = 7,
  MARKET_PENETRATION = 8,
  TOP_TEN_CUSTOMERS = 9,
  NEW_CLAIMS = 10,
  LOSS_RATIO_PER_INSURER = 11,
  PORTFOLIO_PER_INSURER = 12,
  WEATHER = 13,
  OUTLOOK_WIDGET = 14,
  USAGE_BEHAVIOR_AP = 15,
  RSS_NEWSFEED = 16,
  INVENTORY_DISTRIBUTION_ACCORDING_AP = 17,
  RCS_BACKEND_MONITORING = 23,
  ZURS_WIDGET = 25,
  DSGVO = 26,
  LOCKS = 27,
  RECENT_USER_LOGINS = 28,
  IC_ACADEMY = 29
}

export type WidgetImages = { [key: number]: Record<'imgDE' | 'imgEN', string> };

export const widgetImages: WidgetImages = {
    [WidgetIds.MESSAGE_OF_THE_DAY]: {
        imgEN: '../../../assets/images/Nachricht des Tages_global_en.svg',
        imgDE: '../../../assets/images/Nachricht des Tages.svg'
    },
    [WidgetIds.SALES_TARGET]: {
        imgEN: '../../../assets/images/SalesTarget.svg',
        imgDE: null
    },
    [WidgetIds.TODO_LIST]: {
        imgEN: '../../../assets/images/Aufgabenliste_global_en.svg',
        imgDE: '../../../assets/images/Aufgabenliste.svg'
    },
    [WidgetIds.INVENTORY_CHANGE_CONTROLLING]: {
        imgEN: '../../../assets/images/Neuproduktion.svg',
        imgDE: null
    },
    [WidgetIds.CUSTOMER_STRUCTURE_BY_PREMIUM]: {
        imgEN: '../../../assets/images/Kundenstruktur nach Pramie_user_english.svg',
        imgDE: '../../../assets/images/Kundenstruktur nach Pramie_icuser_De.svg'
    },
    [WidgetIds.CUSTOMER_STRUCTURE_BY_INDUSTRY]: {
        imgEN: '../../../assets/images/Kundenstruktur nach Branche_global_en.svg',
        imgDE: '../../../assets/images/Kundenstruktur nach branche_icuser_De.svg'
    },
    [WidgetIds.INVENTORY_PENETRATION_AP]: {
        imgEN: '../../../assets/images/Bestandsdurchdringung_user_english.svg',
        imgDE: '../../../assets/images/image 18.svg'
    },
    [WidgetIds.MARKET_PENETRATION]: {
        imgEN: '../../../assets/images/Marktdurchdringung_global_en.svg',
        imgDE: '../../../assets/images/Marktdurchdringung.svg'
    },
    [WidgetIds.TOP_TEN_CUSTOMERS]: {
        imgEN: '../../../assets/images/Top 10 Kunden_user_english.svg',
        imgDE: '../../../assets/images/image 21.svg'
    },
    [WidgetIds.NEW_CLAIMS]: {
        imgEN: '../../../assets/images/Neue Schaden-English.svg',
        imgDE: '../../../assets/images/Neue Schaden-German.svg'
    },
    [WidgetIds.LOSS_RATIO_PER_INSURER]: {
        imgEN: '../../../assets/images/Schadenquote pro Versicherer_global_en.svg',
        imgDE: '../../../assets/images/image 9.svg'
    },
    [WidgetIds.PORTFOLIO_PER_INSURER]: {
        imgEN: '../../../assets/images/Portfolio pro Versicherer_global_en.svg',
        imgDE: '../../../assets/images/Porfolio pro Versicherer.svg'
    },
    [WidgetIds.WEATHER]: {
        imgEN: '../../../assets/images/Wetter_global_en.svg',
        imgDE: '../../../assets/images/Wetter .svg'
    },
    [WidgetIds.OUTLOOK_WIDGET]: {
        imgEN: '../../../assets/images/Outlook_global_en.svg',
        imgDE: '../../../assets/images/Outlook Widget.svg'
    },
    [WidgetIds.USAGE_BEHAVIOR_AP]: {
        imgEN: '../../../assets/images/Nutzungsverhalten_global_en.svg',
        imgDE: '../../../assets/images/Usage behaviour IC German.png'
    },
    [WidgetIds.RSS_NEWSFEED]: {
        imgEN: '../../../assets/images/Nachrichtenticker_global_en.svg',
        imgDE: '../../../assets/images/Nachrichtenticker.svg'
    },
    [WidgetIds.INVENTORY_DISTRIBUTION_ACCORDING_AP]: {
        imgEN: '../../../assets/images/Bestandsverteilung_global_en.svg',
        imgDE: '../../../assets/images/Bestandsverteilung_globaladmin_DE.svg'
    },
    [WidgetIds.RCS_BACKEND_MONITORING]: {
        imgEN: '../../../assets/images/rcs backend_global_en.svg',
        imgDE: '../../../assets/images/RCS Backend monitoring.svg'
    },
    [WidgetIds.ZURS_WIDGET]: {
        imgEN: '../../../assets/images/Real-time ZURS-En.svg',
        imgDE: '../../../assets/images/Real-time ZURS-De.svg'
    },
    [WidgetIds.DSGVO]: {
        imgEN: '../../../../assets/images/IC_Customer_ENG.svg',
        imgDE: '../../../../assets/images/IC_Customer_DE.svg'
    },
    [WidgetIds.LOCKS]: {
        imgEN: '../../../../assets/images/locks-widget.png',
        imgDE: null
    },
    [WidgetIds.RECENT_USER_LOGINS]: {
        imgEN: '../../../../assets/images/recent_user_logins_de.png',
        imgDE: null
    },
    [WidgetIds.IC_ACADEMY]: {
        imgEN: '../../../../assets/images/ic-academy.png',
        imgDE: null
    }
};

