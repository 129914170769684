<mat-toolbar class="row header-first top-header">
  <div *ngIf="!userLogo" class="col-lg-1 col-xl-1 col-md-1">
    <span>
      <span class="no-logo-container" *ngIf="isICRoles">
        {{'header.Kein_Kund' | translate}}
      </span>
      <img alt="" *ngIf="logo" class="img-new" [src]="logo">
    </span>
  </div>
  <div *ngIf="userLogo" class="col-lg-1 col-xl-1 col-md-1">
    <span>
      <img alt="" *ngIf="logo" class="img-new" [src]="logo">
    </span>
    <span *ngIf="isICRoles">
      <span class="no-logo-container" *ngIf="!logo">
        {{'header.Bitte_einLogo' | translate}}
      </span>
    </span>
  </div>
  <div *ngIf="isCustomerRoles" class="col-lg-2 col-xl-2 col-md-2">
    <span class="f-13"> {{ greeting | translate }}, </span>
    <br>
    <span *ngIf="user_data" class="f-15-b">{{user_data.first_name}} {{user_data.last_name}}</span>
  </div>
  <span *ngIf="user_data" class="col-md-3 col-lg-3 col-xl-3 col-sm-8 col-8"></span>
  <span *ngIf="isCustomerRoles" class="col-lg-1 col-xl-1 col-md-1"></span>
  <span *ngIf="isICRoles" class="col-lg-2 col-xl-2 col-md-2"></span>
  <div
    [ngClass]="[isICRoles ? 'col-md-3 col-lg-3 col-xl-3 col-sm-8 col-8' : 'col-md-3 col-lg-3 col-xl-3 col-sm-6 col-6']">
  </div>
  <div *ngIf="isCustomerRoles" class="title1 col-md-2 col-lg-2 col-xl-2 col-2 col-sm-2 ">
    <img alt="" [src]="headerIcons.IC_LOGO">
  </div>
  <div *ngIf="isICRoles" style="padding-left: 2rem;margin-left: auto;align-items: center;"
    class="title1 col-md-3 col-lg-3 col-xl-3 col-2 col-sm-2 d-flex">
    <div style="margin-left: auto;">
      <span class="f-13"> {{ greeting | translate }}, </span>
      <br>
      <span *ngIf="user_data" class="f-15-b">{{user_data.first_name}} {{user_data.last_name}}</span>
    </div> &nbsp; &nbsp; &nbsp;
    <div class="title1">
      <img alt="" [src]="headerIcons.IC_LOGO">
    </div>
  </div>
</mat-toolbar>

<mat-toolbar class="menu-bar">
  <nav class="navbar navbar-expand-lg navbar-light">
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <div [ngbCollapse]="isMenuCollapsed" [ngClass]="{'responsive-header' : isMenuCollapsed == false}"
      class="collapse navbar-collapse show header-bar-container">
      <ul class="navbar-nav menu1" *ngIf="isICRoles">
        <li class="nav-item" routerLinkActive="active" #rla="routerLinkActive" placement="top"
          ngbTooltip="{{'header.Startseite_tooltip'  | translate}}" tooltipClass="yellow-tooltip">
          <a class="nav-link" (click)="isMenuCollapsed = true; homeNavigate();" routerLink="/dashboard">
            <!-- <img alt="" class="h-23" [src]="rla.isActive ? headerIcons.HOME_ACTIVE : headerIcons.HOME" /> -->
             <i-feather 
              name="grid" 
              [class.fill-active]="rla.isActive"
              [class.fill]="!rla.isActive"
              class="fill-stroke"> 
            </i-feather>
          </a>
        </li>
        <li class="nav-item dropdown" [class.active]="isCustomerManagementActive()" routerLinkActive="active" #customerManagement="routerLinkActive" placement="top"
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen' : 'customer-management.customer-management') | translate}}"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip' : 'header-tooltip'">
          <a [class.disabled]="disbaleMenuIcon"
            class="nav-link"
            style="cursor: pointer;"
            (click)="navigateToCustomerManagement()">
            <!-- <img alt="" id="customerIcon" class="h-23"
              [src]="isCustomerManagementActive() ? IC_ICONS.USER_MANAGEMENT_SELECTED : IC_ICONS.USER_MANAGEMENT_ENABLE" /> -->
            <img alt="" id="customerIcon" class="h-23"
              [src]="isCustomerManagementActive() ? IC_ICONS.USER_MANAGEMENT_SELECTED : IC_ICONS.USER_MANAGEMENT_SELECTED" />
            <!-- <i-feather 
              name="grid" 
              [class.fill-active]="isCustomerManagementActive()"
              [class.fill]="!isCustomerManagementActive()"
              [class.fill-disabled]="disbaleMenuIcon"
              class="fill-stroke"> 
            </i-feather>  -->
          </a>
          <ul>
            <li class="dropdown-menu hover-menu">
              <a (click)="navigateToCreatingNewCustomer()" class="dropdown-menu-item" routerLinkActive="active"
                #addNewCustomer="routerLinkActive" [ngClass]="addNewCustomer.isActive ? 'active-menu-item' : ''">
                {{'customer-management.action-bar.add_customer' | translate}}
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item document-menu" routerLinkActive="active" [class.active]="isLinkActive('/more-document')"
          placement="top" 
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen' : 'header.MehrDokument_tooltip') | translate}}"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip' : 'header-tooltip'">
          <a [routerLink]="['/more-document']" class="nav-link" routerLinkActive="active"
            [queryParams]="{liztu: liz}"
            [class.disabled]="disbaleMenuIcon" (click)="isMenuCollapsed = true">
            <!-- <img alt="" class="h-23"
              [src]="disbaleMenuIcon ? IC_ICONS.DOCUMENT_MANAGEMENT_DISABLE : (DocumentActive ? IC_ICONS.DOCUMENT_MANAGEMENT_SELECTED : IC_ICONS.DOCUMENT_MANAGEMENT_ENABLE )" /> -->
            <img alt="" class="h-23"
              [src]="DocumentActive ? IC_ICONS.DOCUMENT_MANAGEMENT_SELECTED : IC_ICONS.DOCUMENT_MANAGEMENT_SELECTED" />
          </a>
        </li>
        <li class="nav-item dropdown" routerLinkActive="active"
          [class.active]="isContractsActive()" placement="top"
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen': 'header.contract_management') | translate}}"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip': 'header-tooltip'">
          <a [routerLink]="['/contract']" class="nav-link" routerLinkActive="active"
            [queryParams]="{liztu: liz}"
            [class.disabled]="disbaleMenuIcon" (click)="isMenuCollapsed = true">
            <!-- <img alt="" class="h-23"
              [src]="disbaleMenuIcon ? IC_ICONS.CONTRACT_DISABLE : (isContractsActive() ? IC_ICONS.CONTRACT_SELECTED : IC_ICONS.CONTRACT_ENABLE)" /> -->
            <img alt="" class="h-23"
              [src]="isContractsActive() ? IC_ICONS.CONTRACT_SELECTED : IC_ICONS.CONTRACT_SELECTED" />
          </a>
          <ul *ngIf="!disbaleMenuIcon">
            <li class="dropdown-menu hover-menu" routerLinkActive="active" [class.active]="isLinkActive('/contract')">
              <a [ngClass]="CActactive == true ? 'CActactive' : ''" [routerLink]="['/contract']"
                class="dropdown-menu-item" routerLinkActive="active"
                [queryParams]="{liztu: liz}">
                {{'header.show_contracts' | translate}}
              </a>
              <a [ngClass]="CActCreateactive == true ? 'CActCreateactive' : ''" class="dropdown-menu-item"
                style="margin-top: 3px;" (click)="isMenuCollapsed = true; onNewContract();">
                {{'header.Angebot_hinzufügen' | translate}}
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" routerLinkActive="active"
          [class.active]="isLinkActive('/object-module') || isLinkActive('/add-new-object')" placement="top"
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen': 'header.object_management') | translate}}"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip': 'header-tooltip'">
          <a [routerLink]="['/object-module']"
            [queryParams]="{liztu: liz}"
            [class.disabled]="disbaleMenuIcon" class="nav-link" (click)="isMenuCollapsed = true">
            <!-- <img alt="" class="h-23"
              [src]="disbaleMenuIcon ? IC_ICONS.OBJECT_MANAGEMENT_DISABLE : (objectActive ? IC_ICONS.OBJECT_MANAGEMENT_SELECTED : IC_ICONS.OBJECT_MANAGEMENT_ENABLE)" /> -->
            <img alt="" class="h-23"
              [src]="objectActive ? IC_ICONS.OBJECT_MANAGEMENT_SELECTED : IC_ICONS.OBJECT_MANAGEMENT_SELECTED" />
          </a>
          <ul *ngIf="!disbaleMenuIcon">
            <li class="dropdown-menu hover-menu">
              <a [routerLink]="['/object-module']" [class.active]="isLinkActive('/object-module')"
                [ngClass]="OBactive == true ? 'OBactive' : ''" routerLinkActive="active"
                [queryParams]="{liztu: liz}"
                [class.disabled]="disbaleMenuIcon" class="dropdown-menu-item">
                {{'header.object_module' | translate}}
              </a>
              <a style="margin-top: 3px;" [routerLink]="['/value-module']"
                [class.active]="isLinkActive('/value-module')" [ngClass]="OBnewactive == true ? 'OBnewactive' : ''"
                routerLinkActive="active"
                [queryParams]="{liztu: liz}"
                [class.disabled]="disbaleMenuIcon" class="dropdown-menu-item">
                {{'header.value_module' | translate}}
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" placement="top"
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen': 'header.claims_management') | translate}}"
          [ngClass]="isClaimActive() ? 'link-active' : ''"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip': 'header-tooltip'">
          <a [routerLink]="['/claims']"
            [queryParams]="{liztu: liz}"
            [class.disabled]="disbaleMenuIcon" class="nav-link" (click)="isMenuCollapsed = true">
            <!-- <img alt="" class="h-23"
              [src]="disbaleMenuIcon ? IC_ICONS.CLAIM_DISABLE : (isClaimActive() ? IC_ICONS.CLAIM_SELECTED : IC_ICONS.CLAIM_ENABLE)" /> -->
            <img alt="" class="h-23"
              [src]="isClaimActive() ? IC_ICONS.CLAIM_SELECTED : IC_ICONS.CLAIM_SELECTED " />
          </a>
          <ul *ngIf="!disbaleMenuIcon">
            <li class="dropdown-menu hover-menu">
              <a [routerLink]="['/claims']" [ngClass]="isCreateClaimActive() ? 'active-menu-item' : ''"
                [queryParams]="{liztu: liz}"
                [class.disabled]="disbaleMenuIcon" class="dropdown-menu-item">
                {{'header.Ansprüche_anzeigen' | translate}}
              </a>
              <a [ngClass]="CRactive == true ? 'CRactive' : ''" class="dropdown-menu-item" style="margin-top: 3px;"
                (click)="navigateToClaimsReporting()">
                {{'header.Ansprüche_registrieren' | translate}}
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive="active" #rlaSearch="routerLinkActive" placement="top"
          ngbTooltip="{{'header.Suche_tooltip' | translate}}" tooltipClass="header-tooltip">
          <a class="nav-link" (click)="isMenuCollapsed = true; onSearch();">
            <!-- <img alt="" class="h-23" [src]="headerIcons.SEARCH" /> -->
            <i-feather 
              name="search"
              class="fill fill-stroke"> 
            </i-feather>
          </a>
        </li>
        <span *ngIf="user_data">
          <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <mat-form-field appearance="outline" class="input1 inputy switcher">
              <mat-icon matPrefix>
                <img alt="" style="vertical-align: baseline;" [src]="IC_ICONS.ORG_TREE" />
              </mat-icon>
              &nbsp;
              <mat-select [disabled]="disbaleMenuIcon" class="disabled-dropdown" (selectionChange)="navigateTo($event)"
                id="list" [(ngModel)]="defaultSelectedCustomerValue">
                <mat-option *ngFor="let sub of subsidiaries" matTooltip="{{sub.customer_name}} - {{sub.type}}"
                  [value]="sub">
                  <span>{{sub.customer_name}} - {{sub.type | uppercase}}</span>
                </mat-option>
              </mat-select>
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            </mat-form-field>
          </li>
        </span>
      </ul>

      <ul class="navbar-nav menu1" *ngIf="isCustomerRoles">
        <li class="nav-item" routerLinkActive="active" #rla="routerLinkActive" placement="top"
          ngbTooltip="{{'header.Startseite_tooltip' | translate}}" tooltipClass="yellow-tooltip">
          <a class="nav-link" (click)="isMenuCollapsed = true;" routerLink="/dashboard">
            <img alt="" class="h-23" [src]="rla.isActive ? headerIcons.HOME_ACTIVE : headerIcons.HOME" />
          </a>
        </li>
        <li [class.active]="isLinkActive('/users') || isLinkActive('/customer-edit')"
          *ngIf="user_role == userRoles.CUSTOMER_USER" class="nav-item" routerLinkActive="active" placement="top"
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen' : 'user-data.heading_label') | translate}}"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip' : 'header-tooltip'">
          <a [class.disabled]="disbaleMenuIcon" [routerLink]="['/users']" class="nav-link"
            [queryParams]="{liztu: liz}"
            (click)="isMenuCollapsed = true;">
            <img alt="" class="h-23"
              [src]="masterActive ? headerIcons.CUSTOMER_MANAGEMENT_ACTIVE : headerIcons.CUSTOMER_MANAGEMENT" />
          </a>
        </li>
        <li [class.active]="isLinkActive('/users')  || isLinkActive('/customer-edit')"
          *ngIf="user_role === userRoles.CUSTOMER_ADMIN && !customer_id" class="nav-item" routerLinkActive="active"
          placement="top"
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen' : 'user-data.heading_label') | translate}}"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip' : 'header-tooltip'">
          <a [class.disabled]="disbaleMenuIcon" [routerLink]="['/users']" [queryParams]="{liztu: liz}" class="nav-link"
            (click)="isMenuCollapsed = true;">
            <img alt="" class="h-23"
              [src]="masterActive ? headerIcons.CUSTOMER_MANAGEMENT_ACTIVE : headerIcons.CUSTOMER_MANAGEMENT" />
          </a>
        </li>
        <li [class.active]="isLinkActive('/users')  || isLinkActive('/customer-edit')"
          *ngIf="user_role === userRoles.CUSTOMER_ADMIN && customer_id" class="nav-item" routerLinkActive="active"
          placement="top"
          ngbTooltip="{{(disbaleMenuIcon ? 'header.Bitte_wählen' : 'user-data.heading_label') | translate}}"
          [tooltipClass]="disbaleMenuIcon ? 'yellow-tooltip' : 'header-tooltip'">
          <a [class.disabled]="disbaleMenuIcon" class="nav-link" (click)="isMenuCollapsed = true;navigateToUsers()">
            <img alt="" class="h-23"
              [src]="masterActive ? headerIcons.CUSTOMER_MANAGEMENT_ACTIVE : headerIcons.CUSTOMER_MANAGEMENT" />
          </a>
        </li>
        <li class="nav-item document-menu" routerLinkActive="active" [class.active]="isLinkActive('/more-document')"
          placement="top" ngbTooltip="{{'header.MehrDokument_tooltip' | translate}}" tooltipClass="header-tooltip">
          <a class="nav-link" (click)="isMenuCollapsed = true;navigateToDocument()">
            <img alt="" class="h-23"
              [src]="DocumentActive ? headerIcons.DOCUMENT_MANAGEMENT_ACTIVE : headerIcons.DOCUMENT_MANAGEMENT" />
          </a>
        </li>
        <li class="nav-item" 
          routerLinkActive="active" 
          [class.active]="isContractsActive()" placement="top"
          ngbTooltip="{{'header.contract_management' | translate}}" tooltipClass="header-tooltip">
          <a class="nav-link" (click)="isMenuCollapsed = true;navigateToContracts()">
            <img alt="" class="h-23"
              [src]="contractsActive ? headerIcons.CONTRACT_MANAGEMENT_ACTIVE : headerIcons.CONTRACT_MANAGEMENT" />
          </a>
        </li>
        <li class="nav-item dropdown" routerLinkActive="active"
          ngbTooltip="{{'header.claims_management' | translate}}" tooltipClass="header-tooltip"
          [class.active]="isLinkActive('/claims') || isLinkActive('/claim-reporting')">
          <a class="nav-link" (click)="isMenuCollapsed = true;navigateToClaims()">
            <img alt="" class="h-23"
              [src]="claimsActive ? headerIcons.CLAIMS_MANAGEMENT_ACTIVE : headerIcons.CLAIMS_MANAGEMENT" />
          </a>
          <ul>
            <li class="dropdown-menu hover-menu">
              <a [ngClass]="CLactive == true ? 'CLactive' : ''" [class.disabled]="disbaleMenuIcon"
                class="dropdown-menu-item" (click)="navigateToClaims()">
                {{'header.Ansprüche_anzeigen' | translate}}
              </a>

              <a [ngClass]="CRactive == true ? 'CRactive' : ''" class="dropdown-menu-item" style="margin-top: 3px;"
                (click)="navigateToClaimsReporting()"> {{'header.Einen_Anspruch' | translate}} </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" routerLinkActive="active" placement="top"
          ngbTooltip="{{'header.Suche_tooltip' | translate}}" tooltipClass="header-tooltip"
          #rlaSearch="routerLinkActive">
          <a class="nav-link" (click)="isMenuCollapsed = true; onSearch();">
            <img alt="" class="h-23" [src]="headerIcons.SEARCH" />
          </a>
        </li>
        <span *ngIf="user_data">
          <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <mat-form-field appearance="outline" class="input1 inputy switcher">
              <mat-icon matPrefix>
                <img alt="" style="vertical-align: baseline;" [src]="IC_ICONS.ORG_TREE" />
              </mat-icon>
              &nbsp;
              <mat-select [disabled]="disbaleMenuIcon" class="disabled-dropdown" (selectionChange)="navigateTo($event)"
                id="list" [(ngModel)]="defaultSelectedCustomerValue">
                <mat-option *ngFor="let sub of subsidiaries" matTooltip="{{sub.customer_name}} - {{sub.type}}"
                  [value]="sub">
                  <span>{{sub.customer_name}} - {{sub.type | uppercase}}</span>
                </mat-option>
              </mat-select>
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            </mat-form-field>
          </li>
        </span>
      </ul>

      <ul class="navbar-nav menu2">
        <li *ngIf="isICRoles" class="nav-item dropdown" routerLinkActive="active" #insurerActive="routerLinkActive"
          placement="top" ngbTooltip="{{'insurer_management.insurer_management_text' | translate}}"
          [ngClass]="insurerActive.isActive ? 'link-active' : '' " tooltipClass="yellow-tooltip">
          <a class="nav-link" [routerLink]="['/' + routesPath.INSURER_MANAGEMENT]" (click)="isMenuCollapsed = true">
            <img alt="" class="h-23"
              [src]="insurerActive.isActive ? IC_ICONS.INSURER_MANAGEMENT_SELECTED : IC_ICONS.INSURER_MANAGEMENT_SELECTED" />
          </a>
          <ul>
            <li class="dropdown-menu hover-menu">
              <a [routerLink]="routesPath.INSURER_OVERVIEW" routerLinkActive="active"
                #insurerOverviewActive="routerLinkActive"
                [ngClass]="insurerOverviewActive.isActive ? 'active-menu-item' : ''" class="dropdown-menu-item">
                {{'insurer_management.overview.insurer_overview' | translate}}
              </a>
              <a [routerLink]="routesPath.INSURER_MAPPING" routerLinkActive="active"
                #insurerMappingActive="routerLinkActive"
                [ngClass]="insurerMappingActive.isActive ? 'active-menu-item' : ''" class="dropdown-menu-item"
                style="margin-top: 3px">
                {{'insurer_management.insurer_mapping' | translate}}
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="isICRoles" class="nav-item" placement="top" routerLinkActive="active" #idm="routerLinkActive"
          ngbTooltip="{{'header.intermediary_management' | translate}}" tooltipClass="yellow-tooltip">
          <a class="nav-link" [routerLink]="['/' + routesPath.INTERMEDIARY_MANAGEMENT]">
            <img alt="" class="h-23"
              [src]="idm.isActive ? IC_ICONS.INTERMEDIARY_MANAGEMENT_SELECTED : IC_ICONS.INTERMEDIARY_MANAGEMENT_SELECTED" />
          </a>
        </li>
        <li *ngIf="isICRoles" class="nav-item dropdown" routerLinkActive="active" #todoActive="routerLinkActive"
          placement="top" ngbTooltip="{{'header.todo' | translate}}"
          [ngClass]="todoActive.isActive ? 'link-active' : '' " tooltipClass="yellow-tooltip">
          <a class="nav-link" [routerLink]="['/' + todoRoutes.GENERAL]" (click)="isMenuCollapsed = true">
            <img alt="" class="h-23"
              [src]="todoActive.isActive ? IC_ICONS.APPOINTMENT_SELECTED : IC_ICONS.APPOINTMENT_SELECTED" />
          </a>
          <ul>
            <li class="dropdown-menu hover-menu">
              <a [routerLink]="routesPath.APPOINTMENTS_MANAGEMENT" routerLinkActive="active"
                #appointmentsActive="routerLinkActive"
                [ngClass]="appointmentsActive.isActive ? 'active-menu-item' : ''" class="dropdown-menu-item">
                {{'appointments.title' | translate}}
              </a>
              <a [routerLink]="routesPath.TASKS_MANAGEMENT" routerLinkActive="active"
                #tasksActive="routerLinkActive"
                [ngClass]="tasksActive.isActive ? 'active-menu-item' : ''" class="dropdown-menu-item"
                style="margin-top: 3px">
                {{'tasks.title' | translate}}
              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item" placement="top"
          ngbTooltip="{{(isCustomerRoles ? 'header.Leitfaden_tooltip' : 'header.Leitfaden_employee_tooltip') | translate}}"
          tooltipClass="header-tooltip">
          <a *ngIf="isCustomerRoles" class="nav-link" (click)="getHelpDocument(2);isMenuCollapsed = true"
            target="_blank">
            <img alt="" class="h-23" [src]="headerIcons.INFO_DOCUMENT">
          </a>
          <a *ngIf="isICRoles" class="nav-link" (click)="getHelpDocument(1);" target="_blank">
            <img alt="" class="h-23" [src]="headerIcons.INFO_DOCUMENT">
          </a>
        </li> -->
        <li class="nav-item" routerLinkActive="active" #rlaSetting="routerLinkActive">
          <div class="dropdown">
            <a class="nav-link">
              <!-- <img alt="" class="h-23"
                [src]="rlaSetting.isActive ? headerIcons.SETTINGS_ACTIVE : headerIcons.SETTINGS" /> -->
              <i-feather 
                name="settings" 
                [class.fill-active]="rlaSetting.isActive"
                [class.fill]="!rlaSetting.isActive"
                class="fill-stroke"> 
              </i-feather>
            </a>
            <div class="dropdown-menu drpdwnSettings" aria-labelledby="dropdownMenuButton">
              <a routerLink="/rcs-setup" *ngIf="isICRoles" class="dropdown-item">
                <img alt="" [src]="IC_ICONS.IC_SETUP">{{'settings.divisionManagementShort' | translate}}</a>
              <a class="dropdown-item" routerLink="/settings">
                <i-feather 
                  name="user"
                  class="fill-stroke"> 
                </i-feather>
                 &nbsp; {{'header.Profil' | translate}}
              </a>
              <a class="dropdown-item" (click)="logout()">
                <i-feather 
                  name="log-out"
                  class="fill-stroke"> 
                </i-feather>
                 &nbsp; {{'header.Logout' | translate}}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</mat-toolbar>