import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { ObjectTypesID } from '@shared/constants/generic.constants';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-goods-inventories',
  templateUrl: './goods-inventories.component.html',
  styleUrls: ['./goods-inventories.component.css'],
})
export class GoodsInventoriesComponent extends BaseComponent implements OnInit {
  inventoryGoodsForm: FormGroup;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  @Input('object_data') object_data;

  allCurrencyValues: any = [];
  allValueTypes: any = [];
  currancySymbol = null;
  currencyType = new FormControl();
  currencyTypetemp: any;
  browser: string;
  maxStorageError = false;
  maxStorageError1 = false;
  maxAvgError = false;
  maxAvgError1 = false;
  formError = false;
  actualneuValue = 0;
  readonly selectLang$ = this._userService.locale$;
  selectedCurrency: any;

  @ViewChild('currencyTypemaxStorage', { static: true })
  currencyTypeMaxStorageDropdown: MatSelect;
  @ViewChild('currencyTypeAverageStock', { static: true })
  currencyTypeAverageStockDropdown: MatSelect;
  constructor(
    private serviceCall: ApiCallsService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    public validationService: ValidationService,
    private readonly _userService: UserService
  ) {
    super();
    this.browser = this.detectBrowser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm) {
      this.onFormReset();
    }
    if (this.inventoryGoodsForm && changes.objectType) {
      this.inventoryGoodsForm.patchValue({
        objectType: changes.objectType.currentValue,
      });
      this.callValueAPI();
    }
  }

  onFormReset() {
    this.initializeForm();
  }

  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnInit(): void {
    this.callValueAPI();
    this.currencyType.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterCurrency(value);
      });
  }

  callValueAPI() {
    const requestObject = [];
    requestObject.push(
      this.serviceCall.getCurrencies().pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall
        .getValueType(ObjectTypesID.GOODS_INVENTORY)
        .pipe(catchError(error => of(error)))
    );

    forkJoin(requestObject)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.allCurrencyValues =
          !data[0].hasErrors && data[0].currency_types
            ? data[0].currency_types
            : [];
        this.currencyTypetemp = Object.assign([], this.allCurrencyValues);
        this.allValueTypes =
          !data[1].hasErrors && data[1].object_value_types
            ? data[1].object_value_types
            : [];
        this.initializeForm();
      });
  }

  initializeForm() {
    const formData =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb
        ? JSON.parse(this.object_data.criteria_response.jsonb)
        : '';
    const objectTypeName = new FormControl('goods');

    this.inventoryGoodsForm = new FormGroup({
      objectTypeName,
      objectType: new FormControl(
        {
          value: this.object_data
            ? this.object_data.object_name
            : this.objectType,
          disabled: false,
        },
        []
      ),
      maximum_storage_value: new FormControl(
        {
          value: formData.maximum_storage_value
            ? this._currencyCustomPipe
                .transform(formData.maximum_storage_value)
                ?.replace('€', '')
                ?.replace(',00', '')
                ?.trim()
            : '',
          disabled: false,
        },
        []
      ),
      average_stock_value: new FormControl(
        {
          value: formData.average_stock_value
            ? this._currencyCustomPipe
                .transform(formData.average_stock_value)
                ?.replace('€', '')
                ?.replace(',00', '')
                ?.trim()
            : '',
          disabled: false,
        },
        []
      ),
      value_type_id: new FormControl(
        { value: formData ? formData.value_type_id : 5, disabled: false },
        []
      ),
      description: new FormControl(
        { value: formData ? formData.description : '', disabled: false },
        []
      ),
      currency_type_id: new FormControl(
        {
          value: formData
            ? formData.currency_type_id
            : this.currencyTypetemp?.find(obj => obj.cur_symbol == 'EUR').id,
          disabled: false,
        },
        []
      ),
    });
    if (!formData) {
      this.emitFormValues();
      this.currancyChanged();
    } else {
      this.currancySymbol = this.allCurrencyValues.find(
        obj => obj.id == formData.currency_type_id
      ).cur_code;
      this.selectedCurrency = this.allCurrencyValues.find(
        obj => obj.id == formData.currency_type_id
      ).cur_symbol;
      this.currencyType.setValue('');
    }
    this.inventoryGoodsForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.emitFormValues();
      });
    if (!this.inventoryGoodsForm.touched) {
      this.emitFormValues();
    }
  }

  currancyChanged(event?) {
    const value = event
      ? event.value
      : this.allCurrencyValues.find(obj => obj.cur_symbol == 'EUR').id;
    this.currancySymbol = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_code;
    this.selectedCurrency = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => this.currencyTypeMaxStorageDropdown.focus(), 200);
  }
  currancyChangedTwo(event?) {
    const value = event
      ? event.value
      : this.allCurrencyValues.find(obj => obj.cur_symbol == 'EUR').id;
    this.currancySymbol = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_code;
    this.selectedCurrency = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => this.currencyTypeAverageStockDropdown.focus(), 200);
  }
  resetList() {
    this.currencyType.setValue('');
  }

  private _filterCurrency(value: any) {
    this.allCurrencyValues = this.currencyTypetemp;

    if (value !== '') {
      if (
        this.allCurrencyValues.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.allCurrencyValues = this.allCurrencyValues.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.allCurrencyValues = this.currencyTypetemp;
      }
    } else {
      this.allCurrencyValues = this.currencyTypetemp;
    }
  }

  emitFormValues() {
    const formValues = this.inventoryGoodsForm.value;
    formValues.average_stock_value =
      +this.removeSymbols(formValues.average_stock_value) === 0
        ? null
        : Number(this.removeSymbols(formValues.average_stock_value));
    formValues.maximum_storage_value =
      +this.removeSymbols(formValues.maximum_storage_value) === 0
        ? null
        : Number(this.removeSymbols(formValues.maximum_storage_value));
    formValues.isInValid = this.formError;
    this.emitFormValue.emit(this.inventoryGoodsForm);
  }

  removeSymbols(message: string): string {
    if (message) {
      message = message.replaceAll('.', '');
      message = message.replaceAll(',', '.');
      message = message.trim();
    }
    return message;
  }

  public hasFormError = (controlName: string, errorName: string): boolean => {
    return true;
  };

  // Validayion functions
  onTypeNewValue(event, key, name?) {
    if (key == 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length == 0) {
        if (charCode == '45' || charCode == '48') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        if (name == 'maximum_storage_value') {
          this.maxStorageError = false;
        }
        if (name == 'average_stock_value') {
          this.maxAvgError = false;
        }

        if (
          charCode != 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        this.onTypeDecimalNumber(event);
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualneuValue = event.target.value.length;
      } else {
        this.actualneuValue = this.actualneuValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,.]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualneuValue == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }

  changeNumber(event, name) {
    const count = event.split(',').length - 1;
    if (name == 'maximum_storage_value') {
      if (count > 1) {
        this.maxStorageError1 = true;
      } else {
        this.maxStorageError1 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name == 'maximum_storage_value') {
        this.maxStorageError = true;
      } else {
        this.maxStorageError = false;
      }

      if (
        this.maxStorageError ||
        this.maxStorageError1 ||
        this.maxAvgError ||
        this.maxAvgError1
      ) {
        this.formError = true;
      } else {
        this.formError = false;
      }

      if (event.toString()) {
        this.inventoryGoodsForm.controls.maximum_storage_value.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString()?.replaceAll('.', '')?.replaceAll(',', '.')
            )
            ?.replace('€', '')
            ?.replace(',00', '')
            ?.trim()
        );
      }
    }

    if (name == 'average_stock_value') {
      if (count > 1) {
        this.maxAvgError1 = true;
      } else {
        this.maxAvgError1 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name == 'average_stock_value') {
        this.maxAvgError = true;
      } else {
        this.maxAvgError = false;
      }

      if (
        this.maxStorageError ||
        this.maxStorageError1 ||
        this.maxAvgError ||
        this.maxAvgError1
      ) {
        this.formError = true;
      } else {
        this.formError = false;
      }

      if (event.toString()) {
        this.inventoryGoodsForm.controls.average_stock_value.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString()?.replaceAll('.', '')?.replaceAll(',', '.')
            )
            ?.replace('€', '')
            ?.replace(',00', '')
            ?.trim()
        );
      }
    }
    this.emitFormValues();
  }

  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length == 2) {
        event.preventDefault();
      }
    }
  }

  currencyTypeOneSearch() {
    setTimeout(
      () => document.getElementById('currencyTypeOneSearch').focus(),
      200
    );
  }
  currencyTypeTwoSearch() {
    setTimeout(
      () => document.getElementById('currencyTypeTwoSearch').focus(),
      200
    );
  }
}
