<div class="d-flex building">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical" (navChange)="onClick($event)">
    <li *ngFor="let item of allCriteriaArray" [ngStyle]="{'display' : item.display === 'block' ? 'block' : 'none'}"
      ngbNavItem="{{item.number}}">
      <a ngbNavLink> {{item.name | multilingual | translate}} </a>
      <ng-template ngbNavContent>
        <app-clean-rooms *ngIf="item.name == 'clean-rooms'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-clean-rooms>
        <app-power-supply *ngIf="item.name == 'power_supply'" [isresetForm]="isresetForm" [objectType]="objectType"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-power-supply>
        <app-power-supply-facility *ngIf="item.name == 'power_supply_facility'" [isresetForm]="isresetForm"
          [objectType]="objectType" [criteriaRequestDetails]="criteriaRequestDetails"
          (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-power-supply-facility>
        <app-other-increase-risk *ngIf="item.name == 'other-increase-risk'" [isresetForm]="isresetForm"
          [objectType]="objectType" [criteriaRequestDetails]="criteriaRequestDetails"
          (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-other-increase-risk>
        <app-electroplating *ngIf="item.name == 'electroplating'" [isresetForm]="isresetForm" [objectType]="objectType"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-electroplating>
        <app-fire-protection *ngIf="item.name == 'fire_protection'" [isresetForm]="isresetForm"
          [objectType]="objectType" [criteriaRequestDetails]="criteriaRequestDetails"
          (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-fire-protection>
        <app-printing-companies *ngIf="item.name == 'printing-companies'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-printing-companies>
        <app-foil-packaging *ngIf="item.name == 'foil-packaging'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-foil-packaging>
        <app-foundary *ngIf="item.name == 'foundary'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-foundary>
        <app-woodworking-processing *ngIf="item.name == 'woodworking-processing'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-woodworking-processing>
        <app-plastic *ngIf="item.name == 'plastic'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-plastic>
        <app-warehouse *ngIf="item.name == 'warehouse'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-warehouse>
        <app-lithium-batteries *ngIf="item.name == 'lithium-batteries'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-lithium-batteries>
        <app-soldering *ngIf="item.name == 'soldering'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-soldering>
        <app-mill-installation *ngIf="item.name == 'mill-installation'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-mill-installation>
        <app-pellete-plant *ngIf="item.name == 'pellete-plant'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-pellete-plant>
        <app-photovoltaic-inside *ngIf="item.name == 'photovoltaic-inside'" [isresetForm]="isresetForm"
          [objectType]="objectType" [criteriaRequestDetails]="criteriaRequestDetails"
          (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-photovoltaic-inside>
        <app-recycling *ngIf="item.name == 'recycling'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-recycling>
        <app-grinding-polishing *ngIf="item.name == 'grinding-polishing'" [isresetForm]="isresetForm"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)">
        </app-grinding-polishing>
        <app-object-details *ngIf="item.name == 'object_details'" [isresetForm]="isresetForm" [objectType]="objectType"
          [criteriaRequestDetails]="criteriaRequestDetails" (saveCriteriaRequest)="saveCriteriaRequest($event)"
          [newCustomerFormReq]="newCustomerFormReq"></app-object-details>
      </ng-template>
    </li>
    <li class="addNew" (click)="showCriteriaModal()"><a>+ {{'objectModule.add_new_criteria' | translate}}</a></li>
  </ul>

  <div [ngbNavOutlet]="nav" class="ml-4 right-panel"></div>
</div>
<div class="modal" id="buildingForm" [style.display]="showModal ? 'table' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <span>{{'objectModule.new_criteria_heading' | translate}} </span>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">
          <img alt="" src="../../../assets/images/Group 3114.svg">
        </button>
      </div>
      <!-- Modal Header -->

      <div class="modal-body innerclass modal-design-criteria">
        <div class="form-group has-search" style="margin: -10px -7px 15px;">
          <img alt="" src="../../../assets/images/ic-icons/Search.svg" class="form-control-feedback">
          <input type="text" #search class="form-control" placeholder="{{'searchComponent.search_text' | translate}}"
            aria-label="Search" (keyup)="defaultSearch($event)" [(ngModel)]="searchText">
        </div>
        <ul>
          <li *ngFor="let item of filter(criterias)">
            <mat-slide-toggle [id]="'toggle'+item.number" [checked]='item.switchCriteria'
              (change)="changeState(item.id,$event,item.number)" class="toggle-button">{{item.name | multilingual |
              translate}}
            </mat-slide-toggle>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button class="update-button" type="button" (click)="addNewCriteria()" mat-button>
          {{'objectModule.continue_text' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div id="successToast"></div>