import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ERRORS, STATIC_TEXT } from '@shared/constants/string.constants';

@Injectable({
  providedIn: 'root',
})
export class ShowErrorService {
  constructor(private _translateService: TranslateService) {}

  showBillionOrReqLabelError(
    form: FormGroup | AbstractControl,
    formControl: string
  ) {
    const selectedFormControl = form?.get(formControl);
    if (
      selectedFormControl?.touched &&
      (selectedFormControl?.hasError(ERRORS.OVER_BILLION_ERROR) ||
        selectedFormControl?.hasError(ERRORS.REQUIRED))
    ) {
      return 'custom-label-error';
    }
    return STATIC_TEXT.EMPTY_STRING;
  }

  showInputCurrencyError(
    form: FormGroup | AbstractControl,
    formControl: string
  ) {
    const selectedFormControl = form?.get(formControl);
    if (
      selectedFormControl?.touched &&
      (selectedFormControl?.hasError(ERRORS.OVER_BILLION_ERROR) ||
        selectedFormControl.hasError(ERRORS.REQUIRED))
    ) {
      return 'custom-currency-dropdown-error';
    }
    return STATIC_TEXT.EMPTY_STRING;
  }

  showInputError(form: FormGroup | AbstractControl, formControl: string) {
    const selectedFormControl = form?.get(formControl);
    if (
      selectedFormControl?.touched &&
      (selectedFormControl?.hasError(ERRORS.OVER_BILLION_ERROR) ||
        selectedFormControl?.hasError(ERRORS.REQUIRED) ||
        selectedFormControl?.hasError(ERRORS.INVALID_ID))
    ) {
      return 'custom-input-error';
    }
    return STATIC_TEXT.EMPTY_STRING;
  }

  getBillionErrorMessage(
    form: FormGroup | AbstractControl,
    formControl: string
  ) {
    const selectedFormControl = form.get(formControl);
    if (selectedFormControl.touched) {
      if (selectedFormControl.hasError(ERRORS.OVER_BILLION_ERROR)) {
        return `: ${this._translateService.instant('objectModule.limitError')}`;
      }
    }
    return STATIC_TEXT.EMPTY_STRING;
  }

  getBillionErrorTooltip(
    form: FormGroup | AbstractControl,
    formControl: string,
    error: string,
    isMultilingual?: boolean
  ) {
    const selectedFormControl = form.get(formControl);
    if (selectedFormControl.touched) {
      if (selectedFormControl.hasError(ERRORS.OVER_BILLION_ERROR)) {
        if (isMultilingual == false) {
          return `${error} : ${this._translateService.instant('objectModule.limitError')}`;
        } else {
          return `${this._translateService.instant(error)} : ${this._translateService.instant('objectModule.limitError')}`;
        }
      }
    }
    return STATIC_TEXT.EMPTY_STRING;
  }

  checkInvalid(form: FormGroup, formControl: string) {
    const selectedFormControl = form?.get(formControl);
    if (selectedFormControl?.touched && selectedFormControl.invalid) {
      return 'custom-input-error';
    }
    return STATIC_TEXT.EMPTY_STRING;
  }
  checkInvalidLabel(form: FormGroup, formControl: string) {
    const selectedFormControl = form?.get(formControl);
    if (selectedFormControl?.touched && selectedFormControl.invalid) {
      return 'custom-label-error';
    }
    return STATIC_TEXT.EMPTY_STRING;
  }

  showNameInputError(form: FormGroup, formControl: string) {
    const selectedFormControl = form?.get(formControl);
    if (
      selectedFormControl?.touched &&
      (selectedFormControl?.hasError(ERRORS.NAME_ERROR) ||
        selectedFormControl?.hasError(ERRORS.REQUIRED))
    ) {
      return 'custom-input-error';
    }
    return STATIC_TEXT.EMPTY_STRING;
  }
}
