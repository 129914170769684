export const CP_API_URLS = {
    CONTACT_PERSON         : '/api/v1/contact_person',
    CONTACT_PERSON_ROLE    : '/api/v1/contact_person/roles',
    CONTACT_PERSON_OVERVIEW: '/api/v1/contact_person/overview',
};

export const CP_MULTILINGUAL = {
    CONTACT_PERSON     : 'insurer_management.contact_person.contact_person',
    SALUTATION         : 'insurer_management.contact_person.salutation',
    FIRST_NAME         : 'insurer_management.contact_person.first_name',
    LAST_NAME          : 'insurer_management.contact_person.last_name',
    ROLE               : 'insurer_management.contact_person.role',
    MAIN_CONTACT_PERSON: 'insurer_management.contact_person.main_contact_person',
    COMMENT            : 'insurer_management.contact_person.comments',
    SELECT_ROLE        : 'insurer_management.contact_person.select_role',
    ADD_CONTACT_PERSON : 'insurer_management.contact_person.add_contact_person',
    EDIT_CONTACT_PERSON : 'insurer_management.contact_person.edit_contact_person',
};
