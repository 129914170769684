<div class="modal modelBackground" [style.display]="'table'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content" style="border-radius: 8px;
  box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
  border: solid 1px #bbb;
  background-color: #fff;width:415px">
            <!-- Modal Header -->
            <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                    <mat-icon (click)="cancleModal()">
                        <img alt="" height="65px" src="../../../../assets/images/Group 3235.svg" >
                    </mat-icon>
                </div>
            </div>
            <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
                <span style="font-weight: 600;font-size: 21px;">{{ productMsg.header | translate}}</span>
                <br /><br />
                <span style="font-weight: 600;font-size: 16px;margin-top:5px">{{ productMsg.body | translate}}</span>
            </div>
            <div *ngIf="!productMsg.save_and_continue" class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                <button type="button" style="font-size: 15px;color: #272727;border-radius: 2px;padding: 0px 22px 0px 22px;
      border: solid 1px #272727;font-weight: 600;" mat-button (click)="cancleModal()">
                    {{ productMsg.cancel | translate}}</button>
                <button class="ml-2" type="button"
                    style="font-size: 15px;color: #272727;background: #ff9000;border-radius: 2px;padding: 0px 22px 0px 22px;font-weight: 600;border: solid 1px #ff9000;"
                    (click)="hideModal()" mat-button> {{ productMsg.ok | translate}}</button>
            </div>
            <div *ngIf="productMsg.save_and_continue" class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                <button class="col-md-2 submit-button-base button-black" type="button"
                    (click)="cancleModal()" mat-button>{{ productMsg.cancel | translate}}</button>
                <button *ngIf="productMsg.ok" class="col-md-3 ml-1 submit-button-base button-orange" type="button"
                    (click)="hideModal()" mat-button>{{ productMsg.ok | translate}}</button>
                <button *ngIf="productMsg.save_and_continue"
                    class="col-md-6 ml-1 submit-button-base button-orange" type="button"
                    (click)="saveModal()" mat-button>{{ productMsg.save_and_continue | translate}}</button>
            </div>
        </div>
    </div>
</div>