import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { API_CONST } from '@shared/constants/string.constants';
import { NUMERIC } from '@shared/constants/numeric.constants';
import { ProductWarningMsg } from '@shared/models/product-management.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'sparte-details-stepper',
  templateUrl: './sparte-details-stepper.component.html',
  styleUrls: ['./sparte-details-stepper.component.css'],
})
export class SparteDetailsStepperComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @Output('updateisFormTouchedValue') updateisFormTouchedValue =
    new EventEmitter<any>();
  @Input('consolidateData') consolidateData;
  availableMapping: { [key: string]: number } = {};
  isFormTouched = false;
  isDisabled = false;
  productWarningMsg: ProductWarningMsg;
  showWarningPopUp: boolean;

  searchTextRight = '';
  searchTextLeft = '';
  dataValuesAvailable = new MatTableDataSource<any>();
  dataValuesSelected = new MatTableDataSource<any>();

  dataValuesAvail = [];
  selectedDataValues = [];
  dropdownData: any[] = [];
  assignFormTo = null;
  displayedAvailableColumns: string[] = ['template_name', 'plus_icon'];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private serviceCall: ApiCallsService,
    private translate: TranslateService
  ) {
    this.selectedDataValues = [];
    this.dataValuesAvail = [];
    this.isFormTouched = false;
    this.productWarningMsg = {
      cancel: 'product-management.stepper_common_msg.continue',
      ok: 'product-management.stepper_common_msg.discard',
      save_and_continue:
        'product-management.stepper_common_msg.save_and_continue',
      header: 'objectModule.Delete_popup_heading',
      body: 'product-management.stepper_common_msg.product_form_discard_warning_msg',
    };
    this.showWarningPopUp = false;
  }

  handlePopup(event) {
    this.showWarningPopUp = false;
    if (!event) {
      this.getAvailableForms();
      const data = this.getFormData();
      this.updateisFormTouchedValue.emit({
        data,
        isFormUpdate: false,
        saveFrom: false,
        assignFormTo: this.assignFormTo,
      });
    } else {
      this.assignFormTo = this.dropdownData?.find(
        ele => ele.id != this.assignFormTo
      )?.id;
    }
  }

  saveModalValue(event) {
    // Need to rest the form selection to old selection before proceed for save
    this.assignFormTo = this.dropdownData?.find(
      ele => ele.id != this.assignFormTo
    )?.id;
    this.showWarningPopUp = false;
    this.saveForm();
    // Need to rest the form selection to get New selection before proceed for save
    this.assignFormTo = this.dropdownData?.find(
      ele => ele.id != this.assignFormTo
    )?.id;
    this.getAvailableForms();
  }

  unZipData() {
    if (
      this.consolidateData &&
      [2, 3].includes(this.consolidateData.productStateId)
    ) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }
  getSelectedForms(product_info_id) {
    this.serviceCall
      .getSelectedForms(product_info_id, this.assignFormTo)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (!value.HasErrors) {
          let modifiedSelectedValues = [];
          const selectedMapping = [];

          value.product_to_form_mappings &&
            value.product_to_form_mappings.forEach(values => {
              modifiedSelectedValues.push({
                form_details_id: values.form_details_id,
                product_to_form_mapping_id: values.product_to_form_mapping_id,
                name: values.form_name,
                sequence: values.sequence,
              });
              selectedMapping.push(values.form_details_id);
            });
          modifiedSelectedValues = modifiedSelectedValues.sort((a, b) =>
            a.sequence < b.sequence ? -1 : 1
          );
          this.selectedDataValues = modifiedSelectedValues;
          this.dataValuesSelected.data = modifiedSelectedValues;

          this.dataValuesAvail = this.dataValuesAvail.filter(
            data => !selectedMapping.includes(data.form_details_id)
          );
          this.dataValuesAvailable.data = this.dataValuesAvail;
        }
      });
  }

  getAvailableForms() {
    this.serviceCall
      .getAvailableForms()
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (!value.HasErrors) {
          this.dataValuesAvail = value.available_forms;
          this.dataValuesAvail.forEach(element => {
            this.availableMapping[element.form_details_id] = element.name;
          });
          this.dataValuesAvailable.data = value.available_forms;
        }
        if (this.consolidateData && this.consolidateData.productId) {
          this.getSelectedForms(this.consolidateData.productId);
        }
        this.isFormTouched = false;
      });
  }

  addForm(element) {
    if (this.isDisabled) {
      return;
    }
    this.dataValuesAvail = this.dataValuesAvail.filter(
      data => data.form_details_id != element.form_details_id
    );
    this.selectedDataValues.push({
      form_details_id: element.form_details_id,
      name: element.name,
    });
    this.dataValuesAvailable.data = this.dataValuesAvail;
    this.dataValuesSelected.data = this.selectedDataValues;
    this.emitFormEventChangeState();
  }

  removeForm(element) {
    if (this.isDisabled) {
      return;
    }
    this.selectedDataValues = this.selectedDataValues.filter(
      data => data.form_details_id != element.form_details_id
    );
    this.dataValuesAvail.push({
      form_details_id: element.form_details_id,
      name: element.name,
    });
    this.dataValuesAvailable.data = this.dataValuesAvail;
    this.dataValuesSelected.data = this.selectedDataValues;
    this.emitFormEventChangeState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.consolidateData && !this.selectedDataValues) {
      this.ngOnInit();
      this.unZipData();
    }
  }

  ngOnInit(): void {
    if (this.consolidateData && this.consolidateData.productId) {
      this.getFormsOnData();
      // this.getAvailableForms();
      this.unZipData();
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    this.emitFormEventChangeState();
    const previousIndex = this.dataValuesSelected.data.findIndex(
      row => row === event.item.data
    );
    moveItemInArray(
      this.dataValuesSelected.data,
      previousIndex,
      event.currentIndex
    );
    this.table.renderRows();
  }

  applyAvailableDataFilter(event: Event, dataValues) {
    const filterValue = (event.target as HTMLInputElement).value;
    dataValues.filter = filterValue.trim().toLowerCase();
  }

  emitFormEventChangeState() {
    this.isFormTouched = true;
    const data = this.getFormData();
    this.updateisFormTouchedValue.emit({
      data,
      isFormUpdate: true,
      saveFrom: false,
      assignFormTo: this.assignFormTo,
    });
  }

  saveForm() {
    this.isFormTouched = false;
    const data = this.getFormData();
    this.updateisFormTouchedValue.emit({
      data,
      isFormUpdate: true,
      saveFrom: true,
      assignFormTo: this.assignFormTo,
    });
  }

  getFormData() {
    const valuesToSave = [];
    this.dataValuesSelected.data.forEach((value, key) => {
      valuesToSave.push({
        form_details_id: value.form_details_id,
        product_info_id: this.consolidateData.productId,
        sequence: key + 1,
      });
    });
    return {
      data: valuesToSave,
    };
  }
  getFormsOnData() {
    this.serviceCall
      .getProductLookups(API_CONST.FORM_GENERATOR_TABLES)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        if (!response.HasErrors) {
          this.dropdownData = response.data;
          this.assignFormTo =
            this.dropdownData?.find(ele => ele.id == NUMERIC.CONTRACT_LOOKUP_ID)
              ?.id || null;
          this.getAvailableForms();
        }
      });
  }
  assignFormChange(event) {
    if (event.value) {
      // this.isFormTouched = true;
      if (this.isFormTouched) {
        this.showWarningPopUp = true;
      } else {
        this.getAvailableForms();
      }
    }
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
