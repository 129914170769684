<div class="modal" id="myModal1" style="z-index: 10;" [style.display]="showPopup ? 'block' : 'none'">
    <div class="modal-dialog document-dialog">
        <div class="modal-content" style="border-radius: 4px; border: none">
            <div class="modal-header" style="padding:0.9rem;align-items: center;">
                <h4 class="modal-title" style="font-size: 16px; font-weight: 600;">
                    {{ (comingFrom === ProductModuleConstants.clause ?
                    'product-management.general_information.clauses' :
                    (comingFrom === ProductModuleConstants.exclusion ?
                    'product-management.general_information.exclusions'
                    : 'new-contract.general_information.additionsideagreement'))
                    | translate }}
                </h4>
                <button type="button" *ngIf="comingFrom !== ProductModuleConstants.additionAgreement" class="close"
                    style="padding: 5px;outline: none;" data-dismiss="modal" (click)="hidePopup()">
                    <img alt="" src="../../../../assets/images/close icon .svg">
                </button>
                <button *ngIf="comingFrom === ProductModuleConstants.additionAgreement" type="button" class="close"
                    style="padding: 5px;outline: none;" data-dismiss="modal" (click)="continueClick()">
                    <img alt="" src="../../../../assets/images/close icon .svg">
                </button>
            </div>
            <div class="modal-body modal-body-container">
                <div class="row" style="height: 400px;">
                    <div class="col-md-5 left-panel-container">
                        <div class="add-button-container">
                            <p class="sub-heading">{{ (comingFrom === ProductModuleConstants.clause ?
                                'product-management.general_information.masterlist_of_clauses' :
                                (comingFrom === ProductModuleConstants.exclusion ?
                                'product-management.general_information.masterlist_of_exclusions' :
                                'new-contract.general_information.masterlist_of_additional_agreement'))
                                | translate }}</p>
                            <button
                                [disabled]="(comingFrom === ProductModuleConstants.clause && route === 'contract') || is_disable"
                                class="add-new-button" (click)="openAddForm()">
                                + {{ (comingFrom === ProductModuleConstants.clause ?
                                'product-management.general_information.add_new_clause' : (comingFrom ===
                                ProductModuleConstants.exclusion ?
                                'product-management.general_information.add_new_exclusion' :
                                'new-contract.general_information.add_new_additional_agreement'))
                                | translate }}
                            </button>
                        </div>
                        <div style="margin-top:3px">
                            <div *ngFor="let clause of clauses_exclusions">
                                <div class="allignment" id="div{{clause.id}}">
                                    <div class="align-clause-exclusion">
                                        <div>
                                            <label class="listLabel">
                                                <input style="cursor: pointer;display: none;" type="checkbox"
                                                    class="checkField"
                                                    (change)="clauseOrExclusionSelect(clause.id,$event)"
                                                    [checked]="clause.is_selected" [disabled]="is_disable">
                                                <span class="mark"></span>
                                            </label>
                                        </div>
                                        <div class="ellipsis" title="{{clause.name}}"
                                            (click)="setClause(clause);showDetails();setActiveElement(clause.id)">
                                            <span>{{clause.name}}</span>
                                        </div>
                                    </div>
                                    <div class="showButtons">
                                        <button (click)="editClauseOrExclusion(clause);setActiveElement(clause.id)"
                                            [disabled]="(comingFrom === ProductModuleConstants.clause && route === 'contract') || is_disable"
                                            class="icon-buttons"><img alt="" class="icon-align icon-height"
                                                src="../../../../assets/images/new-icons/edit_icon.svg"></button>
                                        <button (click)="showDeleteModal(clause.id)"
                                            [disabled]="(comingFrom === ProductModuleConstants.clause && route === 'contract') || is_disable"
                                            class="icon-buttons"><img alt="" class="icon-height"
                                                src="../../../../assets/images/new-icons/Delete icon.svg"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="right-panel-heading-container">
                            <div class="right-panel-heading">
                                <span class="sub-heading">{{ (comingFrom === ProductModuleConstants.clause ?
                                    'product-management.general_information.clauses_details' : (comingFrom ===
                                    ProductModuleConstants.exclusion ?
                                    'product-management.general_information.exclusions_details' :
                                    'new-contract.general_information.add_new_additional_details'))
                                    | translate }}</span>
                                <button *ngIf="showClause" (click)="copyToClipbboard()" class="icon-btn">
                                    <img alt="" style="border-radius: 3px;"
                                        src="../../../../assets/images/new-icons/Copy_Icon.svg">
                                </button>
                                <button *ngIf="showAddForm" (click)="save()" class="icon-btn">
                                    <img alt="" src="../../../../assets/images/save-button.svg">
                                </button>
                            </div>
                            <div *ngIf="showClause" class="sub-heading-container ellipsis">
                                <span class="sub-heading-name" title="{{clause_name}}">
                                    {{clause_name}}
                                </span>
                            </div>
                        </div>
                        <div *ngIf="showClause" style="height: 300px;overflow-y: scroll;">
                            <div style="word-break: break-word">
                                <pre class="pre-text" [innerHTML]="clause_description_display"></pre>
                            </div>
                        </div>
                        <div *ngIf="showAddForm">
                            <form [formGroup]="addEntryForm">
                                <input matInput class="input custom-input" (blur)="checkInvalidInput()"
                                    (keyup)="checkInvalidInput()" (focus)="checkInvalidInput()" maxlength="100"
                                    type="text" formControlName="name" placeholder="{{ (comingFrom === ProductModuleConstants.clause ? 
                                    'product-management.general_information.label_of_new_clause' : (comingFrom === ProductModuleConstants.exclusion ? 
                                    'product-management.general_information.label_of_new_exclusion' : 'new-contract.general_information.label_of_new_additional_agreement')) 
                                | translate }}">
                                <div class="error-text">
                                    <span *ngIf="showRequiredError">*
                                        {{'product-management.general_information.required_field' | translate}}</span>
                                </div>
                                <textarea class="custom-textarea" maxlength="5000" matInput cols="100"
                                    formControlName="description" placeholder="{{ (comingFrom === ProductModuleConstants.clause ? 
                                'product-management.general_information.clauses_description' : (comingFrom === ProductModuleConstants.exclusion ?
                                'product-management.general_information.exclusions_description' : 'new-contract.general_information.additional_side_agreement_Description')) 
                            | translate }}">
                            </textarea>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer custom-modal-footer">
                <button (click)="continueClick();hidePopup()"
                    *ngIf="comingFrom !== ProductModuleConstants.additionAgreement" class="ml-2 continue-btn"
                    type="button">
                    {{'product-management.general_information.continue' | translate}}
                </button>
                <button (click)="continueClick();" *ngIf="comingFrom === ProductModuleConstants.additionAgreement"
                    class="ml-2 continue-btn" type="button">
                    {{'product-management.general_information.continue' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal" [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog modal-dialog-new">
        <div class="modal-content">
            <div class="modal-header modal-header-new">
                <div class="text-center">
                    <mat-icon (click)="hideModal()">
                        <img alt="" height="65px" src="../../../assets/images/Group 3235.svg" >
                    </mat-icon>
                </div>
            </div>
            <div class="modal-body modal-body-new" *ngIf="comingFrom !== ProductModuleConstants.additionAgreement">
                <span style="font-weight: 600;font-size: 21px;">{{'objectModule.Delete_popup_heading' |
                    translate}}</span>
                <br />
                <div style="display: grid;justify-content: center;text-align: center;">
                    <p class="popup-message">
                        {{'product-management.general_information.delete_clause_message' | translate}}
                    </p>
                    <p class="popup-message" style="margin-top: 0px;">
                        {{'product-management.general_information.confirmation_message' | translate}}
                    </p>
                </div>
            </div>
            <div class="modal-body modal-body-new" *ngIf="comingFrom === ProductModuleConstants.additionAgreement">
                <span
                    style="font-weight: 600;font-size: 21px;">{{'new-contract.general_information.Delete_popup_heading'
                    | translate}}</span>
                <br />
                <div style="display: grid;justify-content: center;text-align: center;">
                    <p class="popup-message">
                        {{'new-contract.general_information.delete_message' | translate}}
                    </p>
                </div>
            </div>
            <div class="modal-footer ml-auto mr-auto modal-footer-new">
                <button type="button" class="cancel-button" mat-button (click)="hideModal()">
                    {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp;
                <button class="ml-2 save-button" type="button" mat-button (click)="deleteClauseOrExclusion(deleteId)">
                    {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" [style.display]="showDiscardPopup ? 'table' : 'none'">
    <div class="modal-dialog modal-dialog-new">
        <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                    <mat-icon (click)="hideModal()">
                        <img alt="" height="65px" src="../../../../assets/images/icon_for_warning_popup.png" >
                    </mat-icon>
                </div>
            </div>
            <div class="modal-body modal-body-new" style="white-space: break-spaces;">
                <div style="display: grid;justify-content: center;text-align: center;">
                    <p class="popup-message">
                        {{ 'save-changes.object_unsaved_message' | translate}}
                    </p>
                </div>
            </div>
            <div class="modal-footer ml-auto mr-auto modal-footer-new">
                <button type="button" class="cancel-button" mat-button (click)="hideDiscardPopup('false')">
                    {{'save-changes.discard' | translate}}</button>&nbsp;&nbsp;&nbsp;
                <button class="ml-2 save-button" type="button" mat-button (click)="hideDiscardPopup('true')">
                    {{ 'product-management.stepper_common_msg.save_and_continue' | translate}}</button>
            </div>
        </div>
    </div>
</div>