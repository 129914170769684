<div class="update-heading">
    <h2 matDialogTitle class="mat-headline center-align heading">
      {{'update-user.invite_new_user' | translate}}
    </h2>
    <button class="close-icon" style="background: transparent;" (click)="onNoClick()">
          <img alt="" style="vertical-align: sub;" src="assets/images/close icon .svg">
    </button>
</div>
<div>
    <mat-dialog-content>
      <div>
          <input value="{{full_name}}" class="staticInput" disabled>
          <input value="{{customer}}-{{customerType | uppercase}}" class="staticInput" disabled style="margin-left: 2%">
      </div>
    </mat-dialog-content>
    <mat-dialog-content id="step-two">
    <mat-form-field appearance="fill" class="companyDropdown">
      <mat-select style="font-size: 13px; color: gray;" placeholder="Tochterunternehmen" [value]= "lastSelectedCustomer" [disabled]="!is_group " (click)="customerClick(selectedCustomerLiztuVnKdnr,sparte)">
          <mat-option class="con-option" *ngFor="let customer of customers" class="hide-checkbox" [value]="customer.name" (click)="openConfirmationDialog1 == false && customerChange(customer)" [disabled]="openConfirmationDialog1 == true">
            <span class="listLabel" id="dropdownCheckbox">
                <input style="cursor: pointer;" type="checkbox" class="checkField" disabled
                (change)="CheckAllOptions(sparte)" [checked]="customer.is_selected">
               <span class="mark" *ngIf="is_group"></span>
            </span>
               <span style="font-size:13px">{{customer.name}}</span>
          </mat-option>
      </mat-select>
    </mat-form-field>
    <div style="margin-top: 7px; width: 49%; float: right; position: relative;" class="tooltip1">
      <button  disabled class="static-message-icon">
        <img alt="" src="assets/images/info-sign.svg" class="infoImage" id="info_img" style="float: right;">
      </button>
      <span class="static-message">{{'invite-user.DivisionActivate_text' | translate}}</span>
    </div>
    </mat-dialog-content>
</div>
<div class="accordion-section">
    <table class="table table-striped table-borderless" style="table-layout: fixed;">
      <thead class="stickyTop">
        <tr style="background-color: #dddddd;">
          <th>
            <div class="table-header vertically-center">
              <div class="table-header__title">
                <div *ngIf="lastSelectedCustomer !== 'null' || lastSelectedCustomer"
                  class="custom-ellipsis" title="{{lastSelectedCustomer}}">
                  {{lastSelectedCustomer}}
                </div>
                <div *ngIf="lastSelectedCustomer === 'null' || !lastSelectedCustomer">
                  {{'invite-user.Spartenname_text' | translate}}
                </div>
              </div>

              <div class="vertically-center g-7">
                  <input placeholder="{{'searchComponent.search_text' | translate}}"
                    class="searchBox"
                    [(ngModel)]="searchText"
                    (keyup)="searchChanged($event)">
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="space-between check-continers">
          <mat-checkbox class="custom-checkBox check1" (change)="checkAllContractsForCustomer(sparte)" [checked]="isCheckedAllContractsForThisCustomer">
            {{'update-customer.SelectAllContracts_label' | translate}}
          </mat-checkbox>
          <mat-checkbox class="custom-checkBox check1" (change)="checkAllFutureContractsForCustomer(sparte)" [checked]="isCheckedAllFutureContractsForThisCustomer">
            {{'update-customer.SelectAllFutureContract_label' | translate}}
          </mat-checkbox>
          </tr>
        <tr *ngFor="let sparte of sparte | filter:searchText">
          <mat-accordion>
            <mat-expansion-panel (opened)="sparte.panelOptionState = true" (closed)="sparte.panelOptionState = false" hideToggle [expanded]="sparte.panelOptionState">
              <mat-expansion-panel-header>
                <mat-panel-title class="gap-10">
                  <button class="action-btn">
                    <mat-icon *ngIf="sparte.panelOptionState" class="arrowButtons">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="!sparte.panelOptionState" class="arrowButtons">keyboard_arrow_right</mat-icon>
                  </button>
                   <span>{{sparte.sparte}}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <table class="table">
                <thead>
                  <tr class="inner-table-row">
                    <th>
                      <div class="row">
                        <div class="col-md-4 col-sm-4 col-4">
                          <mat-checkbox (change)="CheckAllOptions(sparte)" [checked]="sparte.isCheckedAll" class="custom-checkBox check1">
                            {{'invite-user.Product_name_text' | translate}}
                          </mat-checkbox>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <span class="common-bold-text font-12">{{'invite-user.Verträge_text' | translate}}</span>
                        </div>
                        <div class="col-md-2 col-sm-2 col-2">
                          <span class="common-bold-text font-12">{{'invite-user.Object_text' | translate}}</span>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3" *ngIf="is_group">
                          <mat-checkbox title="{{'invite-user.Zukunft_umfassen_text'| translate}}"
                            (change)="includeFutureContractsClick(sparte)"
                            [checked]="sparte.isFutureSelected"
                            class="custom-checkBox check1 future-select">
                            <span class="my-custom-label">{{'invite-user.Zukunft_umfassen_text'| translate}}</span>
                          </mat-checkbox>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let contract of sparte.contracts | filter:searchText">
                    <td>
                      <div class="row">
                        <div class="col-md-4 col-sm-4 col-4">
                          <label style="cursor: pointer;" class="listLabel ellipsis" title="{{contract.product_name}}"> {{contract.product_name}}
                            <input style="cursor: pointer;" type="checkbox"  class="checkField"
                            [checked]="contract.is_authorised" (change)="contractClick(contract,sparte)">
                              <span class="mark"></span>
                            </label>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <label style="cursor: pointer;" class="listLabel ellipsis p-0" title="{{contract.contract_number}}">{{contract.contract_number}}
                            <input style="cursor: pointer;" type="checkbox"  class="checkField"
                            [checked]="contract.is_authorised" (change)="contractClick(contract,sparte)">
                            </label>
                        </div>
                        <div class="col-md-5 col-sm-5 col-5">
                          <label style="cursor: pointer;" class="listLabel ellipsis p-0" title="{{contract.object}}">{{contract.object}}
                            <input style="cursor: pointer;" type="checkbox"  class="checkField"
                            [checked]="contract.is_authorised" (change)="contractClick(contract,sparte)">
                            </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </mat-expansion-panel>
          </mat-accordion>
        </tr>
      </tbody>
    </table>
</div>

<mat-dialog-content>
    <div style="display: flex;justify-content: center;">
        <button class="navButton" (click)="onStep1Click(sparte,selectedCustomerLiztuVnKdnr)">1</button>
        <hr style="width: 2%;margin: 10px 0px;border-top: 2px solid #ff9000">
        <button class="navButton" style="border: 1px solid #ff9000;box-shadow: 0 0 0 2px #fff inset;">2</button>
        <hr class="horizontal">
        <button class="navButton" style="background-color: #dddddd;" (click)="onStep3Click(sparte,selectedCustomerLiztuVnKdnr)">3</button>
        <hr style="display: inline-block;width: 2%;margin: 10px 0px;border-top: 2px solid #dddddd;">
        <button class="navButton" style="background-color: #dddddd;">4</button>
    </div>
</mat-dialog-content>

<hr class="hr" style="margin-bottom: 0px;">
<mat-dialog-actions class="save-container mt-0">
  <button class="custom-btn cancel-btn" (click)="onStep1Click(sparte,selectedCustomerLiztuVnKdnr)">{{'invite-user.Zuruck_text' | translate}}</button>
  <button class="custom-btn save-btn" (click)="onStep3Click(sparte,selectedCustomerLiztuVnKdnr)">{{'update-user.Next_text' | translate}}</button>
</mat-dialog-actions>

<div class="modal modelBackground" id="myModal" [style.display]="openConfirmationDialog ? 'table' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content popup-structure">
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
       <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
        <mat-icon>
          <img alt="" src="../../../assets/images/Path 91988 (1).svg" >
        </mat-icon>
      </div>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;">
        <span style="font-size: 18px;color: #272727;">
          {{'save-changes.Select_one_company' | translate}}
        </span>
      </div>
      <div class="save-container mb-2">
        <button class="custom-btn save-btn" (click)="hide()"> {{'user-data.Nein' | translate}} </button>
      </div>
    </div>
  </div>
</div>
<div class="modal modelBackground" id="myModal" [style.display]="openConfirmationDialog1 ? 'table' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content popup-structure" style="width: 544px;">
      <div class="modal-header">
        <span style="font-size: 20px;font-weight: 600;">{{'save-changes.Nicht_gespeicherte' | translate}}</span>
        <button type="button" class="close" style="outline: none;" data-dismiss="modal" (click)="hide1()">&times;</button>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;">
        <div class="row">
          <div class="col-md-2">
            <img alt="" src="../../../assets/images/Path 91988 (1).svg" >
          </div>
          <div class="col-md-10" style="line-height: 1.3; text-align: initial;">
              <p style="font-size: 20px;font-weight: 600;">
                {{'save-changes.Nicht_gespeicherte' | translate}}
                <br>
                <span style="font-weight: 600;font-size: 15px;">
                  {{'save-changes.unsaved_changes_message'| translate}}
                </span>
              </p>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="justify-content: center; border-top: unset;">
          <button class="custom-btn cancel-btn" (click)="hide1()"> {{'user-data.Nein' | translate}} </button>
          <button class="ml-2 custom-btn save-btn" type="button" (click)="saveCustomer()"> {{'update-customer.Speichern_text' | translate}}</button>
      </div>
    </div>
  </div>
</div>
