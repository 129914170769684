import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ERRORS } from '@shared/constants/string.constants';

@Directive({
  selector: '[BillionCheck]',
})
export class BillionCheckDirective {
  constructor(
    private el: ElementRef,
    private ngControl: NgControl
  ) {}

  @HostListener('blur')
  onBlur(): void {
    const value = this.el.nativeElement.value;
    const is_error = this.checkBillion(value);
    if (is_error) {
      this.ngControl.control.setErrors({
        [ERRORS.OVER_BILLION_ERROR]: is_error,
      });
    } else {
      if (this.ngControl.control.errors) {
        delete this.ngControl.control.errors[ERRORS.OVER_BILLION_ERROR];
      }
    }
  }
  checkBillion(value) {
    const tempValue = value?.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue.length > 2 || splitValue[0].length > 9) {
      return true;
    } else {
      return false;
    }
  }
}
