import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'generic-alert-dialog',
  templateUrl: './generic-alert-dialog.component.html',
  styleUrls: ['./generic-alert-dialog.component.css']
})

export class GenericAlertComponent {
  message: string;
  constructor(public dialogRef: MatDialogRef<GenericAlertComponent>,
              @Inject(MAT_DIALOG_DATA) data) { this.message = data.message; }
    closePopup() {
      this.dialogRef.close();
    }
}
