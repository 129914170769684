import { DocumentSource, SourceTargetOptions } from '../../models/document-management.model';

export type UploadDocumentData = Record<
  | 'policyNumber'
  | 'documentNumber'
  | 'documentCreationDate'
  | 'documentIdentifier'
  | 'sourceUser'
  | 'targetUser',
  string
> & { source: DocumentSource; isVisibleForCustomer: boolean } & Record<
    | 'intermediaryId'
    | 'insurerId'
    | 'userId'
    | 'SA_IDNR'
    | 'VS_IDNR'
    | 'VN_KDNR'
    | 'customerId'
    | 'damageId'
    | 'contractId',
    number
  >;

export type DirectionOption = {
  label: string;
  value: Record<'source' | 'target', SourceTargetOptions>
};

export type TypeOption = Record<'label' | 'value', string>;

export const DIRECTION_OPTIONS: DirectionOption[] = [
  {
    label: 'upload-document.ic_to_customer',
    value: {
      source: SourceTargetOptions.IC,
      target: SourceTargetOptions.CUSTOMER,
    },
  },
  {
    label: 'upload-document.ic_to_insurer',
    value: {
      source: SourceTargetOptions.IC,
      target: SourceTargetOptions.INSURER,
    },
  },
  {
    label: 'upload-document.ic_to_intermediary',
    value: {
      source: SourceTargetOptions.IC,
      target: SourceTargetOptions.INTERMEDIARY,
    },
  },
  {
    label: 'upload-document.ic_to_others',
    value: {
      source: SourceTargetOptions.IC,
      target: SourceTargetOptions.OTHERS,
    },
  },
  {
    label: 'upload-document.insurer_to_ic',
    value: {
      source: SourceTargetOptions.INSURER,
      target: SourceTargetOptions.IC,
    },
  },
  {
    label: 'upload-document.insurer_to_customer',
    value: {
      source: SourceTargetOptions.INSURER,
      target: SourceTargetOptions.CUSTOMER,
    },
  },
  {
    label: 'upload-document.insurer_to_intermediary',
    value: {
      source: SourceTargetOptions.INSURER,
      target: SourceTargetOptions.INTERMEDIARY,
    },
  },
  {
    label: 'upload-document.insurer_to_others',
    value: {
      source: SourceTargetOptions.INSURER,
      target: SourceTargetOptions.OTHERS,
    },
  },
  {
    label: 'upload-document.customer_to_ic',
    value: {
      source: SourceTargetOptions.CUSTOMER,
      target: SourceTargetOptions.IC,
    },
  },
  {
    label: 'upload-document.customer_to_insurer',
    value: {
      source: SourceTargetOptions.CUSTOMER,
      target: SourceTargetOptions.INSURER,
    },
  },
  {
    label: 'upload-document.customer_to_intermediary',
    value: {
      source: SourceTargetOptions.CUSTOMER,
      target: SourceTargetOptions.INTERMEDIARY,
    },
  },
  {
    label: 'upload-document.customer_to_others',
    value: {
      source: SourceTargetOptions.CUSTOMER,
      target: SourceTargetOptions.OTHERS,
    },
  },
  {
    label: 'upload-document.others_to_ic',
    value: {
      source: SourceTargetOptions.OTHERS,
      target: SourceTargetOptions.IC,
    },
  },
  {
    label: 'upload-document.others_to_customer',
    value: {
      source: SourceTargetOptions.OTHERS,
      target: SourceTargetOptions.CUSTOMER,
    },
  },
  {
    label: 'upload-document.others_to_insurer',
    value: {
      source: SourceTargetOptions.OTHERS,
      target: SourceTargetOptions.INSURER,
    },
  },
  {
    label: 'upload-document.others_to_intermediary',
    value: {
      source: SourceTargetOptions.OTHERS,
      target: SourceTargetOptions.INTERMEDIARY,
    },
  },
  {
    label: 'upload-document.others_to_others',
    value: {
      source: SourceTargetOptions.OTHERS,
      target: SourceTargetOptions.OTHERS,
    },
  },
];
