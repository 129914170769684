<div class="modal add-product dialog" id="myModal" [style.display]="'block'">
    <div class="modal-dialog addProduct" style="top: 255px; display: initial; ">
        <div class="modal-content modalContent" style="width: 680px;padding-left:0;padding-right:0">
            <!-- Modal Header -->
            <div class="modal-header" style="padding: 7px 10px 1px 18px;">
                <div style="text-align:initial;">
                    <h6 style="font-weight: 600;">Add New Product Module </h6>
                </div>
                <button type="button" style="margin-top: -24px;" class="close" data-dismiss="modal" (click)="hidePopup();">
                    <img alt="" src="../../../../../assets/images/Group 3114.svg" >
                </button>
            </div>
            <div class="modal-body" style="font-size:12px;height: 144px;overflow:scroll;padding: 18px 18px;">
                <div>
                    <form class="row product-module-html" [formGroup]="productForm" id="stepper-form">
                        <div class="col-md-6 col-sm-12 col-12 common-field">
                            <label for="" class="label">Product Name</label>
                            <input type="text" class="input" formControlName="ProductName" placeholder="Product Name" disabled>
                        </div>
                        <div class="col-md-6 col-sm-12 col-12 common-field">
                            <label for="" class="label">Product Module Name</label>
                                <app-custom-autocomplete [control]="productForm.controls['ProductModule']" [placeholder]="'product-management.cover_bundle.product_module' | translate"
                            [required]="false" [lookup]="ProductModuleLookup" (newValueSelected)="ProductModuleNameSave($event)"></app-custom-autocomplete>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer" style="display: block;margin-bottom: -6px;text-align: center;">
                <button (click)="AddProduct()" class="ml-2" type="button" [disabled]="!productForm.controls['ProductModule'].value"
                    style="font-size: 15px;color: #272727;background: #ff9000;;font-weight: 600;padding: 0px 22px 0px 22px;border: solid 1px #ff9000;"
                    mat-button>
                    Save</button>
            </div>
        </div>
    </div>
</div>