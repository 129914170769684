import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { ObjectTypesID } from '@shared/constants/generic.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { BaseComponent } from '@shared/components/base.component';
import { ValidationSharedService } from '@core/services/validation-shared.service';

@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.css'],
})
export class MiscellaneousComponent extends BaseComponent implements OnInit {
  miscellaneousForm: FormGroup;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  @Input('object_data') object_data;
  valueTypes: any = [];
  actualnumber = 0;
  actualnewValue = 0;
  errorNewValue = false;
  errorNewValue1: boolean;
  formError: string;
  currencyTypes: any = [];
  currancySymbol = null;
  currencyType = new FormControl();
  currencyTypetemp: any;
  selectedCurrency: any;
  @ViewChild('currencyTypeDrop', { static: true }) currencyDropdown: MatSelect;
  browser: string;
  constructor(
    private serviceCall: ApiCallsService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private readonly _validationSharedService: ValidationSharedService
  ) {
    super();
    this.browser = this.detectBrowser();
    this.callValueAPI();
    this.serviceCall
      .getCurrencyTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (null !== data.currency_types) {
          this.currencyTypes = data.currency_types;
          this.currencyTypetemp = this.currencyTypes;
          if (
            this.object_data &&
            this.object_data.criteria_response &&
            this.object_data.criteria_response.jsonb
          ) {
            const parsedData = JSON.parse(
              this.object_data.criteria_response.jsonb
            );
            if (parsedData.currency_type_id) {
              this.currancySymbol = this.currencyTypes.find(
                obj => obj.id === parsedData.currency_type_id
              ).cur_code;
              this.selectedCurrency = this.currencyTypes.find(
                obj => obj.id === parsedData.currency_type_id
              ).cur_symbol;
            }
          } else {
            this.currancySymbol = this.currencyTypes.find(
              obj => obj.cur_symbol === 'EUR'
            ).cur_code;
            this.selectedCurrency = this.currencyTypes.find(
              obj => obj.cur_symbol === 'EUR'
            ).cur_symbol;
            const tempId = this.currencyTypes.find(
              obj => obj.cur_symbol === 'EUR'
            ).id;
            this.miscellaneousForm.controls.currency_type_id.setValue(tempId);
          }
        }
      });
    this.currencyType.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterCurrency(value);
      });
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm) {
      this.onFormReset();
    }
    if (this.miscellaneousForm && changes.isSubmitClicked !== undefined) {
      this.miscellaneousForm.reset();
    }
    if (this.miscellaneousForm && changes.objectType) {
      this.miscellaneousForm.patchValue({
        objectType: changes.objectType.currentValue,
      });
      this.initializeForm();
    }
    // changes.prop contains the old and the new value...
  }
  onFormReset() {
    this.initializeForm();
  }
  initializeForm() {
    const formData =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb
        ? JSON.parse(this.object_data.criteria_response.jsonb)
        : '';
    if (formData) {
      const value = formData.value;
      if (value != null || value != undefined) {
        formData.value = formData.value?.toString();
        if (formData.value.indexOf('.')) {
          formData.value = formData.value.replace('.', ',');
        }
        parseInt(formData.value);
      }
    }
    const objectTypeValidation = new FormControl({
      value: this.object_data ? this.object_data.object_type : this.objectType,
      disabled: false,
    });
    const objectNameValidation = new FormControl({
      value: this.object_data ? this.object_data.object_name : this.objectType,
      disabled: false,
    });
    const ValueValidation = new FormControl(
      {
        value: formData
          ? (formData.value
              ? this._currencyCustomPipe
                  .transform(
                    formData.value.replaceAll('.', '')?.replaceAll(',', '.')
                  )
                  ?.replace('€', '')
              : ''
            )
              ?.replace(',00', '')
              ?.trim()
          : '',
        disabled: false,
      },
      []
    );
    const ValueTypeValidation = new FormControl(
      {
        value: formData
          ? formData.value_type_id
            ? formData.value_type_id
            : 1
          : 1,
        disabled: false,
      },
      []
    );
    const descriptionValidation = new FormControl(
      {
        value: formData ? formData.description : '',
        disabled: false,
      },
      []
    );
    const currencyTypeValidation = new FormControl(
      {
        value: formData ? formData.currency_type_id : null,
        disabled: false,
      },
      []
    );
    const objectTypeName = new FormControl('miscellaneous');
    this.miscellaneousForm = new FormGroup({
      objectTypeName,
      objectType: objectTypeValidation,
      object_name: objectNameValidation,
      currency_type_id: currencyTypeValidation,
      value: ValueValidation,
      value_type_id: ValueTypeValidation,
      description: descriptionValidation,
    });

    this.miscellaneousForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(obj => {
        const formValues = this.miscellaneousForm.value;
        formValues.isInValid = this.miscellaneousForm.invalid;
        this.emitFormValue.emit(this.miscellaneousForm);
      });
    if (!this.miscellaneousForm.touched) {
      this.emitFormValue.emit(this.miscellaneousForm);
    }
  }
  callValueAPI() {
    this.serviceCall
      .getValueType(ObjectTypesID.MISCELLANEOUS)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (data.object_value_types !== null) {
          this.valueTypes = data.object_value_types;
        }
      });
    this.serviceCall
      .getCurrencyTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (null !== data.currency_types) {
          this.currencyTypes = data.currency_types;
          this.currencyTypetemp = this.currencyTypes;
        }
      });
  }
  resetValueType() {
    this.miscellaneousForm.controls.value_type_id.setValue(1);
  }
  onTypeNewValue(event, key) {
    if (key === 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length === 0) {
        if (charCode === '45' || charCode === '48') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        this.errorNewValue = false;
        if (
          charCode != 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        this.onTypeDecimalNumber(event);
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualnewValue = event.target.value.length;
      } else {
        this.actualnewValue = this.actualnewValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,.]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualnewValue === 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }
  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length === 2) {
        event.preventDefault();
      }
    }
  }
  changeNumber(event, name) {
    const count = event.split(',').length - 1;
    if (count > 1) {
      this.errorNewValue1 = true;
    } else {
      this.errorNewValue1 = false;
    }
    const tempValue = event.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 9) {
      if (name === 'newValue') {
        this.errorNewValue = true;
      }
    } else {
      this.errorNewValue = false;
    }
    if (this.errorNewValue || this.errorNewValue1) {
      this.formError = 'true';
      this._validationSharedService.saveFormError(this.formError);
    } else {
      this.formError = 'false';
      this._validationSharedService.saveFormError(this.formError);
    }
    this.miscellaneousForm.controls.value.setValue(
      this._currencyCustomPipe
        .transform(event.toString()?.replaceAll('.', '')?.replaceAll(',', '.'))
        ?.replace('€', '')
        .replace(',00', '')
        ?.trim()
    );
  }
  currancyChanged(event) {
    this.currancySymbol = this.currencyTypes.find(
      obj => obj.id === event.value
    ).cur_code;
    this.selectedCurrency = this.currencyTypes.find(
      obj => obj.id === event.value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => {
      this.currencyDropdown.focus();
    }, 200);
  }
  private _filterCurrency(value: any) {
    this.currencyTypes = this.currencyTypetemp;
    if (value !== '') {
      if (
        this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.currencyTypes = this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.currencyTypes = this.currencyTypetemp;
      }
    } else {
      this.currencyTypes = this.currencyTypetemp;
    }
  }
  valueTypeChange(event) {
    if (
      event.value === '' ||
      event.value === null ||
      event.value === undefined
    ) {
      this.miscellaneousForm.controls.value_type_id.setValue('');
    }
  }
  resetList() {
    this.currencyType.setValue('');
  }
  focusCurrencySearch() {
    document.getElementById('currencySearch').focus();
  }
}
