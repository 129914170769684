import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TableStructure } from '@shared/models/column-details.model';
import {
  DeleteConfirmationRequest,
  DeleteConfirmationResponse,
  DeleteServiceRequest,
} from '@shared/interfaces/delete-confirm.interface';
import { TranslateService } from '@ngx-translate/core';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import { ToastService } from '@core/services/toast.service';
import {
  COLUMN_TYPES,
  TOOLTIP_PLACEMENT,
} from '@shared/constants/column-types.constants';
import {
  PAYMENT_ACTION_METHOD_NAMES,
  PM_COLUMNS,
  PM_COLUMN_HEADINGS,
  PM_COLUMN_NAMES,
  PM_MULTILINGUALS,
} from '@shared/constants/claims/payments-management.constant';
import {
  PaymentTableData,
  PaymentTableDetails,
} from '@shared/models/payment-management.model';
import { STATIC_TEXT } from '@shared/constants/string.constants';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { MULTILINGUAL_CONSTS } from '../../models/table.constants';
import { NumberValidationsService } from '@core/services/validations/number-validations.service';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { DateTimeConvertService } from '@core/services/datetime/date-time-convert.service';
import { DeleteService } from '@core/services/shared/delete.service';
import { takeUntil } from 'rxjs/operators';
import { TABLE_MAX_HEIGHT } from '@shared/constants/general/table.constants';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { ActivatedRoute } from '@angular/router';
import { ClaimsShareService } from '../../claims-share.service';
import { ActioButtons, TableConfiguration } from '@shared/models/table.model';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
})
export class PaymentDetailsComponent
  extends BaseComponent
  implements OnInit
{
  @Input() message;
  @Output() isPaymentAddEdit = new EventEmitter<any>();
  @Output() paymentId = new EventEmitter<any>();
  claimId: string;
  IsIvmSynced: boolean;
  tableStructure: TableStructure;
  tableData: PaymentTableDetails[] = [];
  showConfirmDeletePopup = false;
  sendDeleteReq: DeleteConfirmationRequest;
  tableConfig: TableConfiguration;

  constructor(
    private _translateService: TranslateService,
    private _claimsAPI: ClaimsManagementApiService,
    private _toastService: ToastService,
    private _numberValidationService: NumberValidationsService,
    private _dateTimeService: DateTimeConvertService,
    private _deleteService: DeleteService,
    private readonly _userService: UserService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _claimsSharedService: ClaimsShareService
  ) {
    super();
    this.prepareTableConfiguration();
    this.tableStructure = this.prepareTableStructure();
  }

  ngOnInit(): void {
    this._watchOnIvmChanging();
    this.claimId = atob(this._activatedRoute.snapshot.queryParams.claim);
    this.getPaymentOverviewData();
    if (this.message) {
      this._toastService.showToastMessage(document, this.message);
    }
  }

  getPaymentOverviewData(): void {
    this._claimsAPI
      .getPaymentsOverviewData(this.claimId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.tableData = data?.data
          ? this.prepareTableData(this.prepareTableRequest(data?.data))
          : [];
        this._toggleActionButtons(this.IsIvmSynced);
      });
  }
  prepareTableConfiguration() {
    this.tableConfig = {
      isFilterSection: false,
      isPagination: false,
      isHeadingAboveFilter: true,
      headingText: this._translateService.instant(
        PM_MULTILINGUALS.TABLE_HEADING
      ),
    };
  }

  prepareTableStructure(): TableStructure {
    const structure: TableStructure = {
      displayColumns: PM_COLUMNS,
      columnDetails: [
        {
          matColumnDef: PM_COLUMN_NAMES.PAYEE_LIST,
          colHeading: this._translateService.instant(
            PM_COLUMN_HEADINGS.PAYEE_LIST
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: PM_COLUMN_NAMES.AMOUNT,
          colHeading: this._translateService.instant(PM_COLUMN_HEADINGS.AMOUNT),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: PM_COLUMN_NAMES.PAYMENT_DATE,
          colHeading: this._translateService.instant(
            PM_COLUMN_HEADINGS.PAYMENT_DATE
          ),
          columnType: COLUMN_TYPES.STRING,
          isSortable: true,
        },
        {
          matColumnDef: PM_COLUMN_NAMES.REMARK1,
          colHeading: this._translateService.instant(
            PM_COLUMN_HEADINGS.REMARK1
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: PM_COLUMN_NAMES.REMARK2,
          colHeading: this._translateService.instant(
            PM_COLUMN_HEADINGS.REMARK2
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: PM_COLUMN_NAMES.ACTION,
          colHeading: this._translateService.instant(PM_COLUMN_HEADINGS.ACTION),
          columnType: COLUMN_TYPES.ACTION,
        },
      ],
      optionalStyles: {
        maxHeight: TABLE_MAX_HEIGHT,
      },
    };
    return structure;
  }

  prepareTableData(request: PaymentTableData[]): PaymentTableDetails[] {
    const paymentDetailsData: PaymentTableDetails[] = request.map(
      (ele: PaymentTableData) => {
        const paymentDetails: PaymentTableDetails = {
          payment_management_id: ele.payment_management_id,
          payee_name: ele.payee_name,
          amount: ele.amount,
          payment_date: this._dateTimeService.convertToDate(ele.payment_date),
          remark1: ele.remark1,
          remark2: ele.remark2,
          action: [
            {
              iconLink: ACTION_BUTTON.DELETE_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled: PAYMENT_ACTION_METHOD_NAMES.DELETE_PAYMENT,
              isDisabled: this._userService.getUserRole() === USER_ROLES.IC_USER,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.DELETE_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
          ],
        };
        return paymentDetails;
      }
    );
    return paymentDetailsData;
  }

  prepareTableRequest(data): PaymentTableData[] {
    const req: PaymentTableData[] = [];
    data.forEach((payment: any) => {
      const currencySymbol = payment.payment_amount
        ? payment.currency
        : STATIC_TEXT.EMPTY_STRING;
      const paymentDetails: PaymentTableData = {
        payment_management_id: payment.payment_management_id,
        payee_name: payment.payee_name,
        amount:
          `${this._numberValidationService.convertIntToGermanFormatWithDecimal(payment.payment_amount)} ${currencySymbol}`,
        payment_date: payment.payment_date,
        remark1: payment.remark_1,
        remark2: payment.remark_2,
      };
      req.push(paymentDetails);
    });
    return req;
  }

  callMethod(data) {
    if (data && data.methodName) {
      switch (data.methodName) {
        case PAYMENT_ACTION_METHOD_NAMES.DELETE_PAYMENT:
          this.deletePayment(data.element);
          break;
      }
    }
  }

  deletePayment(deleteObject): void {
    if (deleteObject) {
      const config: DeleteServiceRequest = {
        id: deleteObject.payment_management_id,
      };
      this.showConfirmDeletePopup = true;
      this.sendDeleteReq = this._deleteService.deleteConfig(config);
    }
  }

  deletePaymentPostConfirmation(deleteReq: DeleteConfirmationResponse): void {
    this.showConfirmDeletePopup = deleteReq?.showPopup;
    if (deleteReq.isAllowed) {
      this.deletePaymentRecord(deleteReq.id);
    }
  }

  deletePaymentRecord(id: number | string): void {
    this._claimsAPI
      .deletePaymentData(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this._toastService.showToastMessage(document, response.message);
        this.getPaymentOverviewData();
      });
  }
  private _toggleActionButtons(flag: boolean): void {
    if (this._userService.getUserRole() !== USER_ROLES.IC_USER) {
      this.tableData.map((row: PaymentTableDetails) => {
        row.action.map((actionElemen: ActioButtons) => {
          return (actionElemen.isDisabled = flag);
        });
        return row;
      });
    }
  }

  private _watchOnIvmChanging(): void {
    this._claimsSharedService.isIvmSynced$.pipe(takeUntil(this._destroy$)).subscribe(value => {
      this.IsIvmSynced = value;
      if (value) {
        this._toggleActionButtons(this.IsIvmSynced);
      }
    });
  }
}
