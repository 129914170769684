import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { LookupConstants } from '@shared/constants/lookup.constants';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { takeUntil } from 'rxjs/operators';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import { ProductShareService } from '@core/services/product-share.service';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';

export interface GET_PRODUCT_MODULE {
  is_ivm_product: boolean;
  product_id: any;
  division_id: any;
  sparte_id: any;
  insurer_id: any;
  page_number: any;
  no_of_records: any;
}
@Component({
  selector: 'app-product-management',
  templateUrl: './product-management.component.html',
  styleUrls: ['./product-management.component.css'],
})
export class ProductManagementComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(MatSort) sort: MatSort;
  @Output('changeInProduct') changeInProduct = new EventEmitter<any>();
  @Input('addNewPage') addNewPage;
  divisionControl = new FormControl();
  productControl = new FormControl();
  isICUser: boolean;
  user_role: USER_ROLES;
  liztu: string;
  readonly locale$ = this._userService.locale$;
  total_elements: number;
  selectedCountOfRows = 25;
  tablePage = 1;
  numberOfRows = [25, 50, 75, 100, 125];
  row = 25;
  formFilter: any;
  sortedData: unknown[];
  insurer_list: any = [];
  product_list: any = [];
  division_list: any = [];
  customerFilterToggle = true;
  customFilterExpandedHeight = '48px';
  dataSource = new MatTableDataSource();
  showModal: boolean;
  deleteItem: any;
  deleteIndex: any;
  displayedColumns: string[] = [
    'product_name',
    'active_contracts',
    'product_state_id',
    'version',
    'insurer_name',
    'division_name',
    'created_at',
    'last_updated_on',
    'ACTION',
  ];
  tableData: {
    product_name: string;
    version: string;
    product_state_id: string;
    active_contracts: any;
    insurer_name: any;
    division: any;
    created_by: string;
    created_date: string;
    last_updated: string;
    last_updated_by: string;
  }[];
  userDataValue: LoggedInUser;
  sparte_list: any;
  sparteTooltip: any;
  sparte_array = [];
  sparte_selected: any;
  product_selected: any;
  insurer_selected: any;
  division_selected: any;
  hvmProduct_selected: boolean;
  product_module_id = null;
  copyelement: any;
  copyIndex: any;
  showCopyModal: boolean;
  productStates: any[];
  showAttachTemplate: boolean;
  showTemplatePopup = false;
  selected_templates = [];
  available_templates = [];
  product_info_id;
  divisionId: any;
  division_array = [];
  totalObjects: number;
  isCustomerUser = false;
  FORM_ICONS = FORM_ICONS;
  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    public validationService: ValidationService,
    private _liveAnnouncer: LiveAnnouncer,
    private _toastService: ToastService,
    private _userService: UserService,
    private _productShareService: ProductShareService
  ) {
    super();
    this.divisionId = this._userService.getDivisionId();
    this.division_array.push(this.divisionId);
  }

  announceSortChange(sortState: Sort): void {
    this._liveAnnouncer.announce(
      sortState.direction
        ? `Sorted ${sortState.direction}ending`
        : 'Sorting cleared'
    );
  }

  ngOnInit(): void {
    this.userDataValue = this._userService.getLoggedInUser();
    const productMoudleid =
      this.route.snapshot.queryParamMap.get('productMoudleId');
    this._watchOnUserRole();
    this._productShareService.productModuleId = productMoudleid;
    this.loadProductStates();
    this.loadInsurer();
    this.loadProduct('');
  }

  loadProductStates(): void {
    this.serviceCall
      .getProductLookupData(LookupConstants.PRODUCT_STATE)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.productStates = data.data));
  }

  getProductName(productStateId): string {
    const products = this.productStates?.filter(
      product => product.id == productStateId
    );
    return (products && products[0]?.desc) || productStateId;
  }

  loadProductModule(tableData: GET_PRODUCT_MODULE): void {
    this.serviceCall
      .getProductModule(tableData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.totalObjects = data.total_element;
        this.formatProductData(data);
      });
  }

  formatProductData(data: any): void {
    const total = this.totalObjects;
    this.total_elements =
      this.tablePage > 1 && data.total_element == 0
        ? total
        : data.total_element;
    this.dataSource.data = data.product_modules;

    this.dataSource.sort = this.sort;
  }

  loadProduct(sparteId): void {
    this.serviceCall
      .getProducts(sparteId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.product_list = data.data;
      });
  }

  loadSparte(): void {
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.serviceCall
      .getSparte(
        this.userDataValue.user_profile.customer_details.division_id,
        null,
        this.user_role
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.sparte_list = data.sparte.filter(
          object => object.is_sparte_group == false
        );
        const indexOfAlle = this.sparte_list.findIndex(
          data => data.fixer_code == 0
        );
        this.sparte_list.splice(indexOfAlle, 1);
      });
  }

  loadInsurer(): void {
    this.serviceCall
      .getInsurer()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.insurer_list = data.insurers;
      });
  }

  loadMandant(): void {
    this.serviceCall
      .getDivision(this._userService.getUserId())
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.division_list = data.divisions;
      });
  }

  getFormsValue(value): void {
    const tableData: GET_PRODUCT_MODULE = {
      is_ivm_product: value.hvm_product
        ? value.hvm_product
          ? true
          : false
        : null,
      product_id: value.product ? value.product : null,
      division_id: value.division
        ? value.division
        : this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
          ? this.division_array
          : null,
      sparte_id: value.sparte ? value.sparte : null,
      insurer_id: value.insurer ? value.insurer : null,
      page_number: 1,
      no_of_records: 25,
    };
    this.loadProductModule(tableData);
  }

  resetFilter(): void {
    this.tablePage = 1;
    this.formFilter.controls.insurer.reset();
    this.formFilter.controls.product.reset();
    this.formFilter.controls.division.reset();
    this.formFilter.controls.hvm_product.reset();
    this.formFilter.controls.sparte.reset();
    const tableData: GET_PRODUCT_MODULE = {
      is_ivm_product: null,
      product_id: null,
      division_id:
        this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
          ? this.division_array
          : null,
      sparte_id: null,
      insurer_id: null,
      page_number: this.tablePage,
      no_of_records: this.row,
    };

    this.loadProductModule(tableData);
  }

  numberOfRowsChanged(value): void {
    this.row = value.value;
    this.tablePage = 1;
    const tableData: GET_PRODUCT_MODULE = {
      is_ivm_product: this.formFilter.controls.hvm_product.value
        ? this.formFilter.controls.hvm_product.value
        : null,
      product_id: this.formFilter.controls.product.value
        ? this.formFilter.controls.product.value
        : null,
      division_id: this.formFilter.controls.division.value
        ? this.formFilter.controls.division.value
        : this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
          ? this.division_array
          : null,
      sparte_id: this.formFilter.controls.sparte.value
        ? this.formFilter.controls.sparte.value
        : null,
      insurer_id: this.formFilter.controls.insurer.value
        ? this.formFilter.controls.insurer.value
        : null,
      page_number: this.tablePage,
      no_of_records: this.row,
    };
    this.loadProductModule(tableData);
  }

  tablePageChanged(event): void {
    this.tablePage = event;
    const tableData: GET_PRODUCT_MODULE = {
      is_ivm_product: this.formFilter.controls.hvm_product.value
        ? this.formFilter.controls.hvm_product.value
        : null,
      product_id: this.formFilter.controls.product.value
        ? this.formFilter.controls.product.value
        : null,
      division_id: this.formFilter.controls.division.value
        ? this.formFilter.controls.division.value
        : this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
          ? this.division_array
          : null,
      sparte_id: this.formFilter.controls.sparte.value
        ? this.formFilter.controls.sparte.value
        : null,
      insurer_id: this.formFilter.controls.insurer.value
        ? this.formFilter.controls.insurer.value
        : null,
      page_number: this.tablePage,
      no_of_records: this.row,
    };
    this.loadProductModule(tableData);
  }

  addNewProduct(productModuleId): void {
    this.addNewPage = true;
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigateByUrl('/rcs-setup', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/rcs-setup/product-management-edit'], {
          queryParams: { active: 'three', productMoudleId: productModuleId },
        });
      });
  }

  changeInProductManagement(event): void {
    this.changeInProduct.emit(event);
  }

  addNewRow(row, index): void {
    const copyData = {
      product_module_id: row.product_info_id,
      created_by: this.userDataValue.user_profile.user_data.user_id,
      role: this.user_role,
    };
    this.serviceCall
      .copyProduct(copyData)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (!data.HasErrors) {
          const tableData: GET_PRODUCT_MODULE = {
            is_ivm_product: this.hvmProduct_selected ? true : null,
            product_id: this.product_selected,
            division_id: this.division_selected
              ? this.division_selected
              : this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
                ? this.division_array
                : null,
            sparte_id: this.sparte_selected,
            insurer_id: this.insurer_selected,
            page_number: this.tablePage,
            no_of_records: this.row,
          };
          this.loadProductModule(tableData);
        }
        this.hideCopyModal();
        this.showToastMessage(data.message);
      });
  }

  editRow(id): void {
    this._productShareService.productModuleId = id;
    this.addNewProduct(id);
  }

  openModal(item, index): void {
    this.deleteItem = item;
    this.deleteIndex = index;
    this.showModal = true;
  }

  hideModal(): void {
    this.showModal = false;
  }

  deleteRow(item, index): void {
    this.serviceCall
      .deleteProductModule(item)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.HasErrors == false) {
          const tableData: GET_PRODUCT_MODULE = {
            is_ivm_product: this.hvmProduct_selected ? true : null,
            product_id: this.product_selected,
            division_id: this.division_selected
              ? this.division_selected
              : this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
                ? this.division_array
                : null,
            sparte_id: this.sparte_selected,
            insurer_id: this.insurer_selected,
            page_number: this.tablePage,
            no_of_records: this.row,
          };
          this.loadProductModule(tableData);
        }
        this.showToastMessage(data.message);
      });
    this.showModal = false;
  }

  sortData(sort): void {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.sortedData = data.sort((a: any, b: any) => {
      const A = a[sort.active].toUpperCase();
      const B = b[sort.active].toUpperCase();
      return !isAsc ? A.localeCompare(B) : B.localeCompare(A);
    });
    this.dataSource.data = Object.assign([], this.sortedData);
    this.cdr.detectChanges();
  }

  sparteChange(event): void {
    this.sparte_selected = event.value;
    this.sparteTooltip = this.sparte_list.find(
      element => element.fixer_code === event.value
    );
    this.loadProduct(event.value);
  }

  productChange(event): void {
    this.product_selected = event.value;
  }

  insurerChange(event): void {
    this.insurer_selected = event.value;
  }

  divisionChange(event): void {
    this.division_selected = event.value;
  }

  hvmProductSelected(event): void {
    this.hvmProduct_selected = event.checked;
  }

  openCopyModal(item, index): void {
    this.copyelement = item;
    this.copyIndex = index;
    this.showCopyModal = true;
  }

  hideCopyModal(): void {
    this.showCopyModal = false;
  }

  openPop(element): void {
    this.product_info_id = element.product_info_id;
    this.serviceCall
      .getAllTemplates(this.product_info_id, false)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.selected_templates = data.selected_templates;
          this.available_templates = data.available_templates;
          this.showTemplatePopup = true;
        }
      });
  }

  hidePopup(): void {
    this.selected_templates = [];
    this.available_templates = [];
    this.product_info_id = null;
    this.showTemplatePopup = false;
  }

  showToastMessage(message): void {
    this._toastService.showToastMessage(document, message);
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
        this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
        this._initForm();
        this.loadMandant();
        this.loadSparte();

        this.loadProductModule(this._getInitialTableData());
      });
  }

  private _initForm(): void {
    this.formFilter = this.fb.group({
      insurer: [''],
      product: [''],
      division: [
        this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
          ? this.division_array
          : null,
      ],
      hvm_product: [],
      sparte: [''],
    });
  }

  private _getInitialTableData(): GET_PRODUCT_MODULE {
    return {
      is_ivm_product: null,
      product_id: null,
      division_id:
        this.user_role != USER_ROLES.IC_GLOBAL_ADMIN
          ? this.division_array
          : null,
      sparte_id: null,
      insurer_id: null,
      page_number: 1,
      no_of_records: 25,
    };
  }
}
