import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder, FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { MsTemplateManagementApiService } from '@core/services/httpcalls/ms-templates-managment-api.service';
import { BaseComponent } from '@shared/components/base.component';
import { FilterSelectOption } from '@shared/components/table/filter-section/filter-section.component';
import { DocumentSource } from '@shared/models/document-management.model';
import { DocumentTargetDropdownItem, MsTemplate, UploadMsTemplateBody } from '@shared/models/ms-template.model';
import { iif } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-upload-ms-template-popup',
  templateUrl: 'upload-ms-template-popup.component.html',
  styleUrls: ['upload-ms-template-popup.component.scss'],
})
export class UploadMsTemplatePopupComponent extends BaseComponent implements OnInit {

  msTemplateForm: FormGroup;

  templateTypeOptions: FilterSelectOption[];
  documentTargetOptions: FilterSelectOption[];
  documentTypeOptions: FilterSelectOption[];

  isEdit = false;

  constructor(
    private _dialogRef: MatDialogRef<UploadMsTemplatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fb: FormBuilder,
    private _msTemplatesManagementApiService: MsTemplateManagementApiService,
    public _documentsApiService: DocumentManagementApiService,
  ) {
    super();
  }

  ngOnInit() {
    this._loadTemplateTypes();
    this._loadTemplateTargets();

    this._buildForm();
  }

  // Needs for using object as option value,
  // for autocompletion dropdown field with input value
  isDocumentTargetOptionEqual(option: FilterSelectOption, value: FilterSelectOption) {
    return option.value === value.value;
  }

  clickFile(event: Event & { target: HTMLInputElement }): void {
    event.target.value = '';
  }

  selectFile(event: Event & { target: HTMLInputElement }): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.msTemplateForm.get('file').setValue(file);
  }

  removeFile(): void {
    this.msTemplateForm.get('file').reset();
  }

  closeModal(): void {
    this._dialogRef.close();
  }

  confirm(): void {
    if (this.msTemplateForm.invalid) {
      return;
    }

    const body: UploadMsTemplateBody = {
      templateTypeId: this.msTemplateForm.get('templateType').value,
      documentTargetId: this.msTemplateForm.get('documentTarget').value.value,
      documentTypeId: this.msTemplateForm.get('documentType').value,
      isVisible: this.msTemplateForm.get('isVisible').value,
      description: this.msTemplateForm.get('description').value ,
      file: this.msTemplateForm.get('file').value
    };

    iif(
      () => this.isEdit,
      this._msTemplatesManagementApiService.updateMsTemplate(
        this._data?.msTemplate?.id,
        body
      ),
      this._msTemplatesManagementApiService.uploadMsTemplate(body)
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._dialogRef.close(true);
      });
  }

  private _buildForm(): void {
    this.msTemplateForm = this._fb.group({
      templateType: [null, Validators.required],
      documentTarget: [null, Validators.required],
      documentType: [{ value: null, disabled: true }, Validators.required],
      description: null,
      isVisible: false,
      file: [null, !this._data?.msTemplate && Validators.required]
    });

    this._watchDocumentTargetChange();

    this._fillForm(this._data?.msTemplate);
  }

  private _fillForm(msTemplate: MsTemplate = null) {
    if (msTemplate) {
      this.msTemplateForm.patchValue({
        templateType: msTemplate.templateTypeId,
        documentTarget: this._formatDropdownToSelectOption(msTemplate.documentTarget),
        documentType: msTemplate.documentTypeId,
        description: msTemplate.description,
        isVisible: msTemplate.isVisible
      });

      this.isEdit = !!msTemplate;
    }
  }

  private _watchDocumentTargetChange(): void {
    this.msTemplateForm.get('documentTarget').valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: FilterSelectOption) => {
        if (value) {
          this._loadDocumentTypes(value.data?.documentTypeSource);

          this.msTemplateForm.get('documentType').enable();
          this.msTemplateForm.get('documentType').reset();
        }
      });
  }

  private _loadTemplateTypes(): void {
    this._msTemplatesManagementApiService
      .getMsTemplateTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.templateTypeOptions = this._formatDropdownToSelectOptions(data);
      });
  }

  private _loadTemplateTargets(): void {
    this._msTemplatesManagementApiService
      .getMsTemplateTargets()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.documentTargetOptions = this._formatDropdownToSelectOptions(data);
      });
  }

  private _loadDocumentTypes(documentTypeSource: DocumentSource): void {
    this._documentsApiService
      .getDocumentTypes(documentTypeSource)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.documentTypeOptions = this._formatDropdownToSelectOptions(data);
      });
  }

  private _formatDropdownToSelectOptions(data: DocumentTargetDropdownItem[]): FilterSelectOption[] {
    return data.map(el => this._formatDropdownToSelectOption(el));
  }

  private _formatDropdownToSelectOption(item: DocumentTargetDropdownItem): FilterSelectOption {
    return {
      label: item.desc,
      value: item.id,
      data: {
        documentTypeSource: item?.documentTypeSource,
      },
    };
  }

}
