<div class="custom-form-container">
    <div class="custom-form-heading">
        <span *ngIf="!contactPersonId">
            {{contactPersonMultilingual.ADD_CONTACT_PERSON | translate}}
        </span>
        <span *ngIf="contactPersonId">
            {{contactPersonMultilingual.EDIT_CONTACT_PERSON | translate}}
        </span>
    </div>
    <div class="custom-form">
        <form *ngIf="contactPersonForm" [formGroup]="contactPersonForm">
            <div class="row">
                <div class="col-md-3 col-sm-3 name-with-salutation">
                    <div *ngIf="locale$ | async as locale" class="salutation-dropdown custom-dropdown-container">
                        <label for="" class="custom-label">{{ contactPersonMultilingual.SALUTATION  | translate}}</label>
                        <mat-select class="custom-dropdown" placeholder="{{'objectModule.select_lable' | translate}}"
                            [formControlName]="formFieldsName.SALUTATION">
                        <mat-option [value]="null" class="dropdown-select">{{'objectModule.select_lable' | translate}}</mat-option>
                        <mat-option [value]="salutation.salutation_id" *ngFor="let salutation of salutations">
                            <span>{{locale == localsConstant.ENGLISH ? 
                                salutation.salutation_text_en : 
                                salutation.salutation_text_de}}
                            </span>
                        </mat-option>
                        </mat-select>
                    </div>
                    <div class="name-field">
                        <label for="" *ngIf="!contactPersonForm.get(formFieldsName.FIRST_NAME).hasError(errorConstant.NAME_ERROR)" class="custom-label" >
                            {{contactPersonMultilingual.FIRST_NAME | translate}}
                        </label>
                        <label for="" *ngIf="contactPersonForm.get(formFieldsName.FIRST_NAME).hasError(errorConstant.NAME_ERROR)" class="custom-label custom-label-error" 
                            title="{{'update-user.Vorname_validation' | translate}}">
                            {{'update-user.Vorname_validation' | translate}}
                        </label>
                        <input type="text" class="custom-input-field" maxlength="100" 
                            [ngClass]="_showError.showNameInputError(contactPersonForm, formFieldsName.FIRST_NAME)"
                            placeholder="{{contactPersonMultilingual.FIRST_NAME | translate}}"
                            [formControlName]="formFieldsName.FIRST_NAME" title="{{f[formFieldsName.FIRST_NAME].value}}"
                            NameValidation>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" *ngIf="!contactPersonForm.get(formFieldsName.LAST_NAME).hasError(errorConstant.NAME_ERROR)" class="custom-label" >
                        {{contactPersonMultilingual.LAST_NAME | translate}}
                    </label>
                    <label for="" *ngIf="contactPersonForm.get(formFieldsName.LAST_NAME).hasError(errorConstant.NAME_ERROR)" class="custom-label custom-label-error" 
                        title="{{'update-user.Nachname_validation' | translate}}">
                        {{'update-user.Nachname_validation' | translate}}
                    </label>
                    <input type="text" class="custom-input-field" maxlength="100" 
                        [ngClass]="_showError.showNameInputError(contactPersonForm, formFieldsName.LAST_NAME)"
                        placeholder="{{contactPersonMultilingual.LAST_NAME | translate}}"
                        [formControlName]="formFieldsName.LAST_NAME" title="{{f[formFieldsName.LAST_NAME].value}}"
                        NameValidation>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{contactPersonMultilingual.ROLE | translate}}
                    </label>
                    <app-custom-autocomplete class="custom-autocomplete" 
                        [control]="f[formFieldsName.ROLE]"
                        [placeholder]="'objectModule.select_lable' | translate" 
                        [lookup]="roles_data"
                        (newValueSelected)="addContactPersonRole($event)" 
                        [required]="false">
                    </app-custom-autocomplete>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{contactPersonMultilingual.MAIN_CONTACT_PERSON | translate}}
                    </label>
                    <div class="radio-group-align-long">
                        <mat-radio-group aria-label="" [formControlName]="formFieldsName.MAIN_CONTACT_PERSON">
                            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
                            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{contactPersonMultilingual.COMMENT | translate}}
                    </label>
                    <textarea class="textArea" maxlength="5000"
                        [formControlName]="formFieldsName.COMMENT"
                        placeholder="{{contactPersonMultilingual.COMMENT | translate}}"
                        title="{{f[formFieldsName.COMMENT].value}}">
                    </textarea>
                </div>
            </div>
        </form>
    </div>    
</div>
<div class="save-container" *ngIf="contactPersonForm">
    <button class="custom-btn save-btn" (click)="saveContactPersonForm()" [disabled]="!isValueChangedInForm || !contactPersonForm.valid">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>