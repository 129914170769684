import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { ColumnType } from '../../constants/table.constants';
import {
  PaginationConfig,
  TableActionEvent,
  TableConfig,
  TableSortConfig
} from '../../models/table.model';
import { BaseComponent } from '../base.component';
import { Filter } from './filter-section/filter-section.component';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends BaseComponent implements OnInit {
  @Input() set data(value: any) {
    if (!value) {
      return;
    }

    this.dataSource.data = value;
    this.dataSource.sort = this.sort;
  }
  @Input() filters: Filter[];
  @Input() tableConfig: TableConfig;
  @Input() pagination: PaginationConfig;

  @Output() filterChanged: EventEmitter<unknown> = new EventEmitter();
  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  @Output() sortChanged: EventEmitter<TableSortConfig> = new EventEmitter();
  @Output() actionClicked: EventEmitter<TableActionEvent> = new EventEmitter();
  @Output() rowClicked: EventEmitter<unknown> = new EventEmitter();
  @Output() selectChanged: EventEmitter<{element: any, data: any[]}> = new EventEmitter();

  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  sortSuffix = '[sort]';
  columnTypes = ColumnType;
  dataSource = new MatTableDataSource();
  isRowClickable = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._watchOnSort();
    this.isRowClickable = !!this.rowClicked.observers.length;
  }

  changeFilter(filterValues: unknown): void {
    this.filterChanged.emit(filterValues);
  }

  changePageSize(size: number): void {
    this.pageSizeChanged.emit(size);
  }

  clickAction(key: string, element: any): void {
    this.actionClicked.emit({ key, element });
  }

  changePage(page: number): void {
    this.pageChanged.emit(page);
  }

  clickRow(element: any): void {
    this.rowClicked.emit(element);
  }

  onSelectChange(event: PointerEvent,  element: any): void {
    event.preventDefault();
    this.selectChanged.emit({element, data: this.dataSource.data});
  }

  switchChildren(element: any): void {
    const elementIndex = this.dataSource.data.findIndex((el: any) => el.id === element.id);
    if (elementIndex === -1) {
      return;
    }

    if (!element.isOpened) {
      (this.dataSource.data[elementIndex] as any).isOpened = true;
      this.dataSource.data.splice(elementIndex + 1, 0, ...element.children.map(el => ({...el, parentId: element.id})));
    }
    else {
      (this.dataSource.data[elementIndex] as any).isOpened = false;
      this.dataSource.data.splice(elementIndex + 1, element.children.length);
    }
    this.dataSource.data = [...this.dataSource.data];
  }

  private _watchOnSort(): void {
    this.sort.sortChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      const sortProperty = this.sort.active.replace(this.sortSuffix, '');
      this.sortChanged.emit({
        sortDirection: this.sort.direction,
        sortProperty,
      });
    });
  }
}
