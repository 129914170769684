import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ShowErrorService } from '@core/services/show-error.service';
import { MarkReadService } from '@core/services/mark-read.service';
import { ToastService } from '@core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';
import { ActivatedRoute } from '@angular/router';
import { CustomerManagementSharedService } from '../../customer-management-shared-service';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import {
  ERRORS,
  FEATURES,
  STATIC_TEXT,
} from '@shared/constants/string.constants';
import { CLAIM_CP_WARNINIG } from '@modules/new-claims/constants/claim.constants';
import { ADD_INSURER_MAPPING_MULTILINGUAL } from '@components/insurer-management/models/mutlilingual.constants';
import { UploadTypeConstants } from '@shared/constants/product-management.constants';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '@shared/components/base.component';
import { ContactPersonApiService } from '@core/services/httpcalls/contact-person-api.service';
import { ContactPerson } from '@shared/models/contact-person.model';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss'],
})
export class AddBankComponent extends BaseComponent {
  @Input() bankId: string;
  @Input() addCustomer: boolean;

  @Output() isBankAddEdit = new EventEmitter<boolean>();
  @Output() message = new EventEmitter<string>();
  @Output() isBankAdd = new EventEmitter<boolean>();

  bankAccountForm: FormGroup;
  bankFormData: any;
  today = new Date();
  uploadDocumentDetails: UploadObjectDetails;
  selectedSEPADocumentsArray: any = {};
  sepaDocumentIds = [];
  selectedDocumentsArray = {};
  customerWarningMsg: CustomerWarningMsg;
  showWarningPopUp = false;
  showSepaMandateDocumentPopup = false;
  formImages = FORM_ICONS;
  errorConstant = ERRORS;
  editCustomer: boolean;
  filteredContactPersons$: BehaviorSubject<ContactPerson[]> =
    new BehaviorSubject([]);
  contactPersons: ContactPerson[] = [];
  searchContactPersonControl = new FormControl();

  constructor(
    public validation: ValidationService,
    private _apiCallsService: ApiCallsService,
    public _showError: ShowErrorService,
    private _markReadService: MarkReadService,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _activatedRoute: ActivatedRoute,
    private _contactPersonApiService: ContactPersonApiService
  ) {
    super();
    this.customerWarningMsg = {
      cancel: CLAIM_CP_WARNINIG.CANCEL,
      ok: CLAIM_CP_WARNINIG.OK,
      save_and_continue: CLAIM_CP_WARNINIG.SAVE,
      header: CLAIM_CP_WARNINIG.HEADING,
      body: CLAIM_CP_WARNINIG.SUBHEADING,
    };
  }
  warningPopupGuard() {
    this.showWarningPopUp = true;
  }
  ngOnInit(): void {
    this._watchOnEditCustomer();
    setTimeout(() => {
      if (this.bankId) {
        this._apiCallsService
          .getBankDetails(+this.bankId, FEATURES.CUSTOMER)
          .pipe(takeUntil(this._destroy$))
          .subscribe((data: any) => {
            this.bankFormData = data.bankDetails;
            this.initializeForm();
          });
      } else {
        this.initializeForm();
      }
    }, 100);

    this._getContactPersons();
    this._watchOnContactPersonSearch();
  }
  initializeForm() {
    const formData = this.bankFormData || null;
    this.bankAccountForm = new FormGroup({
      description: new FormControl(
        formData ? formData.description : STATIC_TEXT.EMPTY_STRING
      ),
      bankName: new FormControl(
        formData ? formData.bankName : STATIC_TEXT.EMPTY_STRING
      ),
      iban: new FormControl(
        formData ? formData.iban : STATIC_TEXT.EMPTY_STRING,
        Validators.required
      ),
      bic: new FormControl(formData ? formData.bic : STATIC_TEXT.EMPTY_STRING),
      standardBankConnection: new FormControl(
        formData ? formData.standardBankConnection : STATIC_TEXT.EMPTY_STRING
      ),
      differingAccountHolder: new FormControl(
        formData ? formData.differingAccountHolder : STATIC_TEXT.EMPTY_STRING
      ),
      sepaMandate: new FormControl(
        formData ? formData.sepaMandate : STATIC_TEXT.EMPTY_STRING
      ),
      sepaMandateDate: new FormControl({
        value: formData
          ? formData.sepaMandateDate
            ? moment(formData.sepa_mandateDate)?.format(
                STATIC_TEXT.DATE_FORMAT_YY_MM_DD
              )
            : null
          : null,
        disabled: formData ? !formData.sepaMandate : true,
      }),
      sepaMandateReference: new FormControl({
        value: formData
          ? formData.sepaMandateReference
          : STATIC_TEXT.EMPTY_STRING,
        disabled: formData ? !formData.sepaMandate : true,
      }),
      sepaMandateDocument: new FormControl({
        value: formData ? formData.uploadDocumentId : STATIC_TEXT.EMPTY_STRING,
        disabled: formData ? !formData.sepaMandate : true,
      }),
      sepaDocuments: new FormControl({
        value: formData ? formData.uploadDocumentId : STATIC_TEXT.EMPTY_STRING,
        disabled: formData ? !formData.sepaMandate : true,
      }),
      contactPerson: new FormControl(
        formData?.contactPerson
          ? formData.contactPerson.id
          : STATIC_TEXT.EMPTY_STRING
      ),
    });
    if (this.addCustomer) {
      this.bankFormData = null;
      this.bankAccountForm.reset();
    }
    if (this.bankId) {
      this.getDocumentUploadData(this.bankId);
    }
  }
  close() {
    this.isBankAddEdit.emit(false);
    this.isBankAdd.emit(false);
    this.bankFormData = null;
  }
  uploadSepaDocument() {
    this.selectedDocumentsArray = { ...this.selectedSEPADocumentsArray };
    this.uploadDocumentDetails = {
      documentSource: 'bank_account_details',
      documentType: UploadTypeConstants.SEPA_MANDATE,
    };
    this.showSepaMandateDocumentPopup = true;
  }
  showDocumentPopup(): boolean {
    return this.showSepaMandateDocumentPopup;
  }
  hidePopup(selectedDocumentsArray) {
    this.sepaDocumentIds = [];
    this.selectedSEPADocumentsArray = { ...this.selectedDocumentsArray };
    this.showSepaMandateDocumentPopup = false;
    this.bankAccountForm.controls.sepaDocuments.setValue(
      Object.keys(selectedDocumentsArray).join(', ')
    );
    this.bankAccountForm.controls.sepaDocuments.markAsTouched();
    Object.keys(selectedDocumentsArray).forEach(name => {
      if (selectedDocumentsArray[name].upload_document_id) {
        this.sepaDocumentIds.push(
          selectedDocumentsArray[name].upload_document_id
        );
      }
    });
    this.selectedDocumentsArray = {};
  }

  save() {
    if (this.bankAccountForm.invalid) {
      this._markReadService.markAllFieldsAsTouched(this.bankAccountForm);
      this._toastService.showToastMessage(
        document,
        this._translateService.instant(
          ADD_INSURER_MAPPING_MULTILINGUAL.FILL_REQUIRED_FIELDS
        )
      );
      return;
    }
    const customerId =
      this.bankFormData?.customer_id ||
      this._customerSharedService.getSelectedCustomerId();
    const saveData = {
      id: this.bankFormData ? this.bankFormData.id : null,
      liztuVnKdnr: this.bankFormData
        ? this.bankFormData.liztuVnKdnr
        : atob(this._activatedRoute.snapshot.queryParams.liztu),
      description: this.bankAccountForm.controls.description.value,
      bankName: this.bankAccountForm.controls.bankName.value,
      iban: this.bankAccountForm.controls.iban.value,
      bic: this.bankAccountForm.controls.bic.value,
      differingAccountHolder:
        this.bankAccountForm.controls.differingAccountHolder.value,
      sepaMandateDate: this.bankAccountForm.controls.sepaMandateDate.value
        ? moment(this.bankAccountForm.controls.sepaMandateDate.value).format(
            STATIC_TEXT.DATE_FORMAT_YY_MM_DD
          )
        : null,
      customerId: this.bankFormData ? this.bankFormData.customerId : customerId,
      standardBankConnection:
        this.bankAccountForm.controls.standardBankConnection.value,
      sepaMandate: this.bankAccountForm.controls.sepaMandate.value,
      sepaMandateReference:
        this.bankAccountForm.controls.sepaMandateReference.value,
      uploadDocumentId: this.sepaDocumentIds,
      isIvm: this.bankFormData ? this.bankFormData.isIvm : false,
      sourceId: this.bankFormData ? this.bankFormData.customerId : customerId,
      sourceType: FEATURES.CUSTOMER,
      contactPersonId: this.bankAccountForm.controls.contactPerson.value,
    };
    this._apiCallsService
      .addBankDetails(saveData)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.message.emit(data.message);
        this.isBankAddEdit.emit(false);
        this.bankFormData = null;
        if (!saveData.id) {
          this.isBankAdd.emit(false);
        }
      });
  }
  sepaMandateChange(value) {
    if (value) {
      this.bankAccountForm.get('sepaMandateDate').enable();
      this.bankAccountForm.get('sepaMandateReference').enable();
      this.bankAccountForm.get('sepaMandateDocument').enable();
      this.bankAccountForm.get('sepaDocuments').enable();
    } else {
      this.bankAccountForm.get('sepaMandateDate').disable();
      this.bankAccountForm.get('sepaMandateReference').disable();
      this.bankAccountForm.get('sepaMandateDocument').disable();
      this.bankAccountForm.get('sepaDocuments').disable();
      this.bankAccountForm.controls.sepaDocuments.setValue(null);
      this.bankAccountForm.controls.sepaMandateDocument.setValue(null);
      this.bankAccountForm.controls.sepaMandateDate.setValue(null);
      this.bankAccountForm.controls.sepaMandateReference.setValue(null);
    }
  }
  handleWarningPopup(event) {
    this.showWarningPopUp = false;
    !event
      ? this._customerManagementSharedService.IVMSyncToggle$.next(true)
      : this.close();
  }
  saveBankData(event) {
    if (event) {
      this.showWarningPopUp = false;
      this.save();
    }
  }
  getDocumentUploadData(record_id) {
    if (record_id) {
      this._apiCallsService
        .getDocumentPhotovoltik(record_id, '')
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents?.forEach(document => {
              document.is_obj_linked = true;
              this.selectedSEPADocumentsArray[document.document_name] =
                document;
            });
            if (data.documents) {
              this.bankAccountForm.controls.sepaDocuments.setValue(
                Object.keys(this.selectedSEPADocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }

  private _watchOnEditCustomer(): void {
    this._customerManagementSharedService.isEditCustomerOperation$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.editCustomer = value;
        if (!this.editCustomer && this.bankAccountForm?.dirty) {
          this.warningPopupGuard();
        }
        if (!this.editCustomer && !this.bankAccountForm?.dirty) {
          this.close();
        }
      });
  }

  private _getContactPersons(): void {
    this._contactPersonApiService
      .getContactPersonOverview({
        sourceId: this._customerSharedService.getSelectedCustomerId(),
        sourceType: FEATURES.CUSTOMER,
        pagination: false,
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data || data.HasErrors) {
          return;
        }

        this.contactPersons = data.contactPersons;
        this.filteredContactPersons$.next(this.contactPersons);
      });
  }

  private _watchOnContactPersonSearch(): void {
    this.searchContactPersonControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe(searchValue => {
        if (!searchValue) {
          this.filteredContactPersons$.next(this.contactPersons);
          return;
        }

        this.filteredContactPersons$.next(
          this.contactPersons.filter(person => {
            const name = `${person.firstName} ${person.lastName}`;
            return name.toLowerCase().includes(searchValue.toLowerCase());
          })
        );
      });
  }
}
