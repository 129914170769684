import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { SalutationService } from '@core/services/salutation.service';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { StepThreeCustomerInviteComponent } from '../step-three-customer-invite/step-three-customer-invite.component';
import { StepTwoCustomerInviteComponent } from '../step-two-customer-invite/step-two-customer-invite.component';
import { UpdateUserComponent } from '../update-user.component';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { LOCALS } from '@shared/constants/local.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import { SparteShareService } from '@core/services/sparte-share.service';

export interface Sparte {
  sparte: String;
  contracts: Contracts[];
  panelOptionState: boolean;
  isCheckedAll: boolean;
}

export interface Contracts {
  contract_number: String;
  is_authorised: Boolean;
  liztu_vs_idnr: Number;
}
export interface DialogCloseResult {
  message: string;
  hasError: boolean;
}
export interface CustomerWiseSparte {
  customer_name: string;
  sparte: Spartes[];
}
export interface Spartes {
  sparte_name: string;
  contracts: Contract[];
}
export interface Contract {
  contract_name: string;
}

@Component({
  selector: 'app-step-four-customer-invite',
  templateUrl: './step-four-customer-invite.component.html',
  styleUrls: ['./step-four-customer-invite.component.css'],
})
export class StepFourCustomerInviteComponent
  extends BaseComponent
  implements OnInit
{
  full_name: String;
  customerType: string;
  customer: String;
  user;
  sparte;
  multiple_customer_sparte;
  selectedWidgets = [];
  mutterCustomer;
  local: LOCALS;
  updatemessage = '';
  disableSave: Boolean;
  customers = [];
  customerWiseSparte: CustomerWiseSparte[] = [];
  contractInformation: any[] = [];
  user_role: USER_ROLES;
  isICUser: boolean;
  isCustomerUser: boolean;
  userId: any;
  userIdnr: any;
  actorcustomerType: any;
  userDataValue: LoggedInUser;

  constructor(
    public dialogRef: MatDialogRef<StepFourCustomerInviteComponent>,
    private dialog: MatDialog,
    private serviceCall: ApiCallsService,
    private salutationService: SalutationService,
    private readonly _userService: UserService,
    private readonly _sparteShareService: SparteShareService
  ) {
    super();

    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.multiple_customer_sparte = this._userService.selectedCustomers;
    this.userDataValue = this._userService.getLoggedInUser();
    this._watchOnUserRole();
    this.user = this._userService.userFormData;
    this.local = this._userService.getLocale();
    this.mutterCustomer = this._userService.mutterCustomer;
    this.sparte = this._sparteShareService.sparte;
    this.full_name = this.user.firstName + ' ' + this.user.lastName;
    this.customerType = this.user.type;
    this.customer = this.user.customerName;
    this.disableSave = !this._userService.showActiveFlag;
    this.selectedWidgets = this._userService.widgets;
    if (this.sparte !== null) {
      const customer_name = this.user.customerName;
      const spartes: Spartes[] = [];
      for (const element of this.sparte) {
        const sparte_name = element.sparte;
        const contracts: Contract[] = [];
        for (let j = 0; j < element.contracts.length; j++) {
          if (element.contracts[j].is_authorised == true) {
            contracts.push(element.contracts[j].contract_number);
          }
        }
        if (contracts.length > 0) {
          const sparte: Spartes = {
            sparte_name,
            contracts,
          };
          spartes.push(sparte);
        }
      }
      const customerWiseSparte: CustomerWiseSparte = {
        customer_name,
        sparte: spartes,
      };
      this.customerWiseSparte.push(customerWiseSparte);
    } else if (this.multiple_customer_sparte !== null) {
      this.customers = this.multiple_customer_sparte.customers;
      for (const element of this.multiple_customer_sparte
        .allSelectedCustomers) {
        const customer_name = this.customers.find(
          obj => obj.liztu_vn_kdnr == element.liztu_vn_kdnr
        ).name;
        const spartes: Spartes[] = [];
        for (let j = 0; j < element.sparte.length; j++) {
          const sparte_name = element.sparte[j].sparte;
          const contracts: Contract[] = [];
          for (let k = 0; k < element.sparte[j].contracts.length; k++) {
            if (element.sparte[j].contracts[k].is_authorised == true) {
              contracts.push(element.sparte[j].contracts[k].contract_number);
            }
          }
          if (contracts.length > 0) {
            const sparte: Spartes = {
              sparte_name,
              contracts,
            };
            spartes.push(sparte);
          }
        }
        const customerWiseSparte: CustomerWiseSparte = {
          customer_name,
          sparte: spartes,
        };
        this.customerWiseSparte.push(customerWiseSparte);
      }
    }
    this.actorcustomerType =
      this.userDataValue.user_profile.customer_details.type;
    this.userId = this.userDataValue.user_profile.user_data.user_id;
    this.userIdnr = this.userDataValue.user_profile.user_data.user_idnr;
  }
  onNoClick(): void {
    this._resetValues();
    this.dialogRef.close();
  }

  private _resetValues() {
    this._sparteShareService.sparte = null;
    this._userService.mutterCustomer = null;
    this._userService.userToUpdate = null;
    this._userService.userFormData = null;
    this._userService.widgets = null;
    this._userService.customerLiztuVnKdnr = null;
    this._userService.showActiveFlag = null;
    this._userService.selectedCustomers = null;
    this._userService.isFutureSelected = null;
    this._userService.inviteUserContext = null;
  }

  onSaveClick(): void {
    const userDetails = this._userService.userFormData;
    userDetails.salutation_id = this.salutationService.getSalutationId(
      userDetails.salutation,
      this.local
    );

    const widgets = this._userService.widgets as Array<{
      id: number;
      name: string;
    }>;
    const formattedWidgets = widgets.map(widget => ({
      widget_id: widget.id,
      is_authorised: true,
    }));

    if (this.multiple_customer_sparte !== null) {
      if (this.multiple_customer_sparte.allSelectedCustomers.length > 0) {
        for (const element of this.multiple_customer_sparte
          .allSelectedCustomers) {
          const sparteWiseContracts: any[] = [];
          for (let j = 0; j < element.sparte.length; j++) {
            const contracts: any[] = [];
            for (let k = 0; k < element.sparte[j].contracts.length; k++) {
              const contract: any = {
                liztu_vs_idnr: element.sparte[j].contracts[k].liztu_vs_idnr,
                is_authorised: element.sparte[j].contracts[k].is_authorised,
              };
              contracts.push(contract);
            }
            sparteWiseContracts.push({
              sparte_fixer_code: element.sparte[j].sparte_fixer_code,
              include_for_future: element.sparte[j].isFutureSelected,
              contracts,
            });
          }
          const contractInformation: any = {
            liztu_vn_kdnr: element.liztu_vn_kdnr,
            sparteWiseContracts,
            include_for_future: !!this._userService.isFutureSelected,
          };
          this.contractInformation.push(contractInformation);
        }
      } else {
        const sparteWiseContract: any[] = [];
        const contractInformation: any = {
          liztu_vn_kdnr: this.user.liztu_vn_kdnr,
          sparteWiseContracts: sparteWiseContract,
          include_for_future: !!this._userService.isFutureSelected,
        };
        this.contractInformation.push(contractInformation);
      }
    } else if (this.sparte !== null) {
      const sparteWiseContract: any[] = [];
      for (const element of this.sparte) {
        const contracts: any[] = [];
        for (let j = 0; j < element.contracts.length; j++) {
          const contract = {
            liztu_vs_idnr: element.contracts[j].liztu_vs_idnr,
            is_authorised: element.contracts[j].is_authorised,
          };
          contracts.push(contract);
        }
        sparteWiseContract.push({
          sparte_fixer_code: element.sparte_fixer_code,
          include_for_future: element.isFutureSelected,
          contracts,
        });
      }

      const contractInformation: any = {
        liztu_vn_kdnr: this.user.liztu_vn_kdnr,
        sparteWiseContracts: sparteWiseContract,
        include_for_future: !!this._userService.isFutureSelected,
      };
      this.contractInformation.push(contractInformation);
    }
    if (userDetails.salutation !== null) {
      userDetails.salutation = this.salutationService.getSalutationId(
        userDetails.salutation,
        this.local
      );
    }
    let userData;
    if (this.isICUser) {
      userData = {
        address: userDetails.address,
        city: userDetails.city,
        customer_id: userDetails.customerId,
        customer_name: userDetails.customerName,
        country: userDetails.country,
        user_id: userDetails.userId != '' ? userDetails.userId : null,
        user_name: userDetails.userName,
        email: userDetails.email,
        first_name: userDetails.firstName,
        house: userDetails.house,
        invitation_language: userDetails.invitationLanguage,
        is_email_sent: false,
        is_invited_to_rcs: false,
        is_temporary: true,
        is_updated: false,
        is_vip: false,
        last_name: userDetails.lastName,
        lat: userDetails.lat,
        lon: userDetails.lon,
        mfa_mode: userDetails.otpTo == 'Mobile' ? 1 : 2,
        role: userDetails.role,
        salutation_id: userDetails.salutation,
        status: true,
        street: userDetails.street,
        telefon: userDetails.phone,
        type:
          userDetails.customerName == this.mutterCustomer
            ? CUSTOMER_TYPES.MUTTER
            : CUSTOMER_TYPES.TOCHTER,
        username: userDetails.userName,
        widgets_information: formattedWidgets,
        contract_information: this.contractInformation,
        zip_code: userDetails.zipCode,
        inviter_username: userDetails.inviterUsername,
        inviter_email: userDetails.inviterEmail,
        is_source_ivm: false,
        is_invite_reset: false,
        actor_id: this.userId,
        actor_role: this.user_role,
        actor_customer_type: null,
      };
    }
    if (this.isCustomerUser) {
      userData = {
        address: userDetails.address,
        city: userDetails.city,
        customer_id: userDetails.customerId,
        customer_name: userDetails.customerName,
        country: userDetails.country,
        user_id: userDetails.userId != '' ? userDetails.userId : null,
        user_name: userDetails.userName,
        email: userDetails.email,
        first_name: userDetails.firstName,
        house: userDetails.house,
        invitation_language: userDetails.invitationLanguage,
        is_email_sent: false,
        is_invited_to_rcs: false,
        is_temporary: true,
        is_updated: false,
        is_vip: false,
        last_name: userDetails.lastName,
        lat: userDetails.lat,
        lon: userDetails.lon,
        mfa_mode: userDetails.otpTo == 'Mobile' ? 1 : 2,
        role: userDetails.role,
        salutation_id: userDetails.salutation,
        status: true,
        street: userDetails.street,
        telefon: userDetails.phone,
        type:
          userDetails.customerName == this.mutterCustomer
            ? CUSTOMER_TYPES.MUTTER
            : CUSTOMER_TYPES.TOCHTER,
        username: userDetails.userName,
        widgets_information: formattedWidgets,
        contract_information: this.contractInformation,
        zip_code: userDetails.zipCode,
        inviter_username: userDetails.inviterUsername,
        inviter_email: userDetails.inviterEmail,
        is_source_ivm: false,
        is_invite_reset: false,
        actor_id: this.userIdnr,
        actor_role: this.user_role,
        actor_customer_type: this.actorcustomerType,
      };
    }
    this.serviceCall
      .updateInviteUser(userData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.updatemessage = result.message;
        const dialogClose: DialogCloseResult = {
          hasError: result.HasErrors,
          message: this.updatemessage,
        };
        this.dialogRef.close(dialogClose);
        this._userService.inviteUserContext.showInviteResult(dialogClose);
        this._resetValues();
      });
  }

  onBackClick(): void {
    this.dialog.open(StepThreeCustomerInviteComponent, {
      width: '800px',
      height: '700px',
    });
    this.dialogRef.close();
  }

  onStepTwoClick(): void {
    this.dialog.open(StepTwoCustomerInviteComponent, {
      width: '800px',
    });
    this.dialogRef.close();
  }

  onStepOneClick(): void {
    const user = this._userService.userToUpdate;
    this.dialog.open(UpdateUserComponent, {
      width: '800px',
      data: user,
    });
    this.dialogRef.close();
  }

  private _initRoleFields(): void {
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._initRoleFields();
      });
  }
}
