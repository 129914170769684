import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
} from 'rxjs/operators';
import { SalutationService } from '@core/services/salutation.service';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { AZURE } from '@shared/constants/azure-service.constants';
import { StepTwoCustomerInviteComponent } from './step-two-customer-invite/step-two-customer-invite.component';
import { REGEX } from '@shared/constants/general/regex.constants';
import { Country } from '@shared/interfaces/country.interface';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import { ShareDataService } from '@core/services/share-data.service';
import { SparteShareService } from '@core/services/sparte-share.service';

export interface Client {
  value: boolean;
  isClient: string;
}
export interface Status {
  value: boolean;
  status: string;
}
export interface Customer {
  name: string;
  id: number;
  liztu_vn_kdnr: number;
  type: string;
  has_admin: boolean;
}
export interface User {
  address: string;
  city: string;
  customerId: number;
  customerName: string;
  country: string;
  userId: number;
  userName: string;
  email: string;
  firstName: string;
  house: string;
  customer_address_lat_lon_set: boolean;
  invitationLanguage: number;
  lastName: string;
  lat: number;
  lon: number;
  role: number;
  salutation: string;
  street: string;
  phone: number;
  zipCode: number;
  division: string;
  uniqueIdentifier: string;
  newUser: boolean;
  otpTo: string;
  type: string;
  inviterUsername: string;
  liztu_vn_kdnr: number;
  is_group: boolean;
  inviterEmail: string;
  role_id: any;
}
@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent extends BaseComponent implements OnInit {
  @ViewChild('loc') addres: ElementRef<HTMLInputElement>;
  userDataValue: LoggedInUser;
  Alllocations: any;
  filterlocation: any = [];
  selectedaddress: any;
  title = 'Confirm';
  error;
  buttonCancelName = 'Cancel';
  buttonConfirmName = 'Ok';
  countries: Country[] = [];
  country;
  language;
  disable;
  // touched;
  city = false;
  zip = false;
  emailDisable: boolean;
  langDisable: boolean;
  null_user = false;
  User_role_list = [];
  submitButton = true;
  Status: Status[] = [
    { value: true, status: 'Active' },
    { value: false, status: 'Inactive' },
  ];
  errorMessageArray = [];
  logo: string;
  placeholder: any;
  NumberValidator;
  Stadt_validation: string;
  Telefon_validation: string;
  telefon_error = false;
  Postleitzahl_validation: string;
  street = false;
  Straße_validation: string;
  strabe: string;
  Hausnummer_validation: string;
  house = false;
  firstname = false;
  Vorname_validation: string;
  lastname = false;
  Nachname_validation: string;
  email = false;
  email_validation: string;
  salutation = false;
  postalregexppattern: any;
  emailvalidation: any;
  emailunique = false;
  userRoleId: any;
  newUser = true;
  is_source_ivm = false;
  customer = false;
  Unternehmen_validation: string;
  customers: any[] = [];
  selectedLanguage;
  selectedOtpTo;
  selectedLoc = '';
  otpTo = ['Email', 'Mobile'];
  supportedLanguages = ['German(DE)', 'English(EN)'];
  customerId: string;
  showActive = false;
  mutterCustomer = '';
  inviter_username;
  isTelephonefilled = false;
  user_role: USER_ROLES;
  addressChanged = false;
  is_reset_invite = false;
  has_admin = false;
  data_is_source_ivm: boolean;
  user_name: string;
  salutation_id: number;
  inviter_email: string;
  customer_type: string;
  is_group = false;
  old_is_group = false;
  byGroup: boolean;
  firefoxBrowser: any;
  customerRoles = false;
  icRoles = false;
  mutter: any;
  resetUser = false;
  CUSTOMER_TYPES = CUSTOMER_TYPES;
  public keyUp = new Subject<string>();
  showList = false;
  customer_types_list = [
    CUSTOMER_TYPES.TOCHTER,
    CUSTOMER_TYPES.AUSLAND,
    CUSTOMER_TYPES.PRIVATKUNDE,
    CUSTOMER_TYPES.BETEILIGUNG,
  ];
  userEmail: string = null;
  userRoles = USER_ROLES;

  constructor(
    private serviceCall: ApiCallsService,
    public dialogRef: MatDialogRef<UpdateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private salutationService: SalutationService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _shareDataService: ShareDataService,
    private readonly _sparteShareService: SparteShareService
  ) {
    super();
    this.userDataValue = this._userService.getLoggedInUser();
    this.user_role = this._userService.getUserRole();
    this.NumberValidator = new FormControl();
    this.showActive = this._userService.showActiveFlag;
    if (data) {
      this.resetUser = data.status !== 'Nicht eingeladen';
      this.data_is_source_ivm = data.is_source_ivm;
      if (
        data.customer_address_lat_lon_set ||
        (data.lat !== '' &&
          data.lat !== null &&
          data.lat !== undefined &&
          data.lon !== '' &&
          data.lon !== null &&
          data.lon !== undefined)
      ) {
        this.showActive = true;
      }
      if (data.invitation_language) {
        this.langDisable = true;
      } else {
        this.langDisable = false;
      }
      this.userEmail = data.email || null;
      if (data.is_invited_to_rcs) {
        this.emailDisable = true;
        this.langDisable = true;
      } else {
        this.emailDisable = false;
        this.langDisable = false;
      }
      if (data.user_id === null || data.user_id === 'null') {
        this.null_user = true;
      }
      if (data.is_invited_to_rcs) {
        this.disable = true;
      } else {
        this.disable = false;
      }
      if (data.salutation_id !== null) {
        data.salutation = this.salutationService.getSalutation(
          data.salutation_id,
          this._userService.getLocale()
        );
      }
      if (data.newUser) {
        this.newUser = true;
        if (data.Telefon === '') {
          this.data.otpTo = 'Email';
        }
        if (data.is_invite_reset) {
          this.customerId = data.customer_id;
          this.is_reset_invite = true;
        }
        this.old_is_group = data.is_group;
        this.title = data.title ? data.title : this.title;
        this.buttonConfirmName = data.buttonConfirmName
          ? data.buttonConfirmName
          : this.buttonConfirmName;
      } else {
        this.title = data.title ? data.title : this.title;
        this.buttonConfirmName = data.buttonConfirmName
          ? data.buttonConfirmName
          : this.buttonConfirmName;
      }
      if (ROLES.CUSTOMER_ROLES.includes(this.user_role)) {
        this.customerRoles = true;
      } else if (ROLES.IC_ROLES.includes(this.user_role)) {
        this.icRoles = true;
        data.is_group = true;
        if (data.newUser === true) {
          if (this._shareDataService.isCustomerManagementEdit) {
            this.customerId = data.customer_id;
          } else {
            this.customerId =
              this._customerSharedService.getSelectedCustomerId();
          }
        } else {
          this.customerId = data.customer_id;
        }
      }
      this.buttonCancelName = data.buttonCancelName
        ? data.buttonCancelName
        : this.buttonCancelName;
    }
  }
  ngOnInit() {
    this.loadUserRoles();
    this.customer_type = this.userDataValue.user_profile.customer_details.type;
    this.salutation_id =
      this.userDataValue.user_profile.user_data.salutation_id;
    this.user_name = this.userDataValue.user_profile.user_data.user_name;
    this.inviter_email = this.userDataValue.user_profile.user_data.email_id;
    this.byGroup = !(
      this.user_role === USER_ROLES.CUSTOMER_ADMIN &&
      this.customer_types_list.includes(this.customer_type)
    );
    this.getCustomers();
    this.serviceCall
      .getCountries()
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: Country[]) => {
        this.countries = data; // FILL THE ARRAY WITH DATA
        this.country = this.countries.find(this.findCountries);
        if (this.data.country === '') {
          this.data.country = this.country?.countryName;
        } else {
          this.data.country = this.countrySet(this.data.country);
        }
      });
    this.Telefon_validation = 'Telefon_validation';
    if (this.newUser === true) {
      this.selectedLanguage = 'German(DE)';
      this.selectedOtpTo = 'Email';
    }

    this.firefoxBrowser = navigator.userAgent.indexOf('Firefox') !== -1;

    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
  }
  autoComplete(value) {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          this.Alllocations = res.results;
          const allAddresses = this.Alllocations.map(obj => {
            return obj.address;
          });
          this.filterlocation = allAddresses;
          this.showList = true;
        });
    }
  }
  onFocusOut() {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }
  selectedLocation(index) {
    this.selectedaddress = [this.filterlocation[index]];
    this.selectedLoc = this.filterlocation[index].freeformAddress;
    if (this.selectedaddress.length === 1) {
      if (this.selectedaddress[0].streetNumber !== undefined) {
        this.data.house = this.selectedaddress[0].streetNumber;
        this.house = false;
      } else {
        this.data.house = '';
      }
      if (this.selectedLoc !== undefined) {
        this.data.address = this.selectedLoc;
      } else {
        this.data.address = this.data.address ? this.data.address : '';
      }
      if (
        this.selectedaddress[0].streetName !== undefined ||
        this.selectedaddress[0].municipalitySubdivision !== undefined
      ) {
        if (this.selectedaddress[0].streetName !== undefined) {
          this.data.street = this.selectedaddress[0].streetName;
        } else {
          this.data.street = this.selectedaddress[0].municipalitySubdivision;
        }
        this.street = false;
      } else {
        this.data.street = '';
      }
      if (this.selectedaddress[0].postalCode !== undefined) {
        this.data.zip_code = this.selectedaddress[0].postalCode;
        this.zip = false;
      } else {
        this.data.zip_code = '';
      }
      if (
        this.selectedaddress[0].localName !== undefined ||
        this.selectedaddress[0].municipality !== undefined
      ) {
        if (this.selectedaddress[0].localName !== undefined) {
          this.data.city = this.selectedaddress[0].localName;
        } else {
          this.data.city = this.selectedaddress[0].municipality;
        }
        this.city = false;
      } else {
        this.data.city = '';
      }
      if (this.selectedaddress[0].country !== undefined) {
        this.data.country = this.selectedaddress[0].country;
      } else {
        this.data.country = '';
      }
      for (const element of this.Alllocations) {
        if (element.address.freeformAddress === this.selectedLoc) {
          if (element.position.lat !== undefined) {
            this.data.lat = element.position.lat;
          } else {
            this.data.lat = '';
          }
          if (element.position.lon !== undefined) {
            this.data.lon = element.position.lon;
          } else {
            this.data.lon = '';
          }
        }
      }
      if (this.data.lat !== '' && this.data.lon !== '') {
        this.showActive = true;
      } else {
        this.showActive = false;
      }
    }
  }
  findCountries(country) {
    return country.countryName === 'Germany';
  }
  onNoClick() {
    if (this.data.newUser) {
      this._sparteShareService.sparte = null;
      this._userService.mutterCustomer = null;
      this._userService.userToUpdate = null;
      this._userService.userFormData = null;
      this._userService.widgets = null;
      this._userService.customerLiztuVnKdnr = null;
      this._userService.showActiveFlag = null;
      this._userService.selectedCustomers = null;
      this._userService.inviteUserContext = null;
    }
    this.dialogRef.close();
  }
  onCountrySelected(event) {
    const selected = event;
    for (const element of this.countries) {
      if (selected === element.countryName) {
        this.postalregexppattern = element.postalCodeRegex;
        const zip_val = this.data.zip_code;
        this.numbersOnlyzip(zip_val, selected);
      }
    }
  }
  charactersOnlycity(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Stadt_validation') !== -1 ? true : false;
    let serchfind: boolean;

    const regexp = new RegExp(REGEX.TOWN_VALIDATION);
    serchfind = regexp.test(e);
    this.Stadt_validation = 'Stadt_validation';
    if (e.length === 0 || !serchfind) {
      this.city = true;
    } else {
      this.city = false;
    }
    if (!serchfind && !isSalutation) {
      this.city = true;
      this.errorMessageArray.push(this.Stadt_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Stadt_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  charactersOnlycountry(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Land_validation') !== -1 ? true : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.COUNTRY_VALIDATION);
    serchfind = regexp.test(e);
    const Land_validation = 'Land_validation';
    if (!serchfind && !isSalutation) {
      this.errorMessageArray.push(Land_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(Land_validation);
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  numbersOnlytelefon(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Telefon_validation') !== -1
        ? true
        : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.PHONE_NUMBER_VALIDATION);
    serchfind = regexp.test(e);
    this.Telefon_validation = 'Telefon_validation';

    this.telefon_error = !e.target.value.length;
    if (!serchfind && isSalutation) {
      this.errorMessageArray.push(this.Telefon_validation);
    } else {
      const index = this.errorMessageArray.indexOf(this.Telefon_validation);
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  numbersOnlyzip(e, country) {
    const isSalutation =
      this.errorMessageArray.indexOf('Postleitzahl_validation') !== -1
        ? true
        : false;
    let serchfind: boolean;
    for (const element of this.countries) {
      if (country === element.countryName) {
        this.postalregexppattern = element.postalCodeRegex;
      }
    }
    const regexp = new RegExp(this.postalregexppattern);
    serchfind = regexp.test(e);
    this.Postleitzahl_validation = 'Postleitzahl_validation';
    if (e.length === 0 || !serchfind) {
      this.zip = true;
      this.errorMessageArray.push(this.Postleitzahl_validation);
    } else {
      this.zip = false;
    }
    if (!serchfind && !isSalutation) {
      this.zip = true;
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Postleitzahl_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  charactersNumbersOnlystreet(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Straße_validation') !== -1 ? true : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.NON_EMPTY_STRING);
    serchfind = regexp.test(e);
    this.Straße_validation = 'Straße_validation';
    this.strabe = this.Straße_validation;
    if (e.length === 0 || !serchfind) {
      this.street = true;
    } else {
      this.street = false;
    }
    if (!serchfind && !isSalutation) {
      this.street = true;
      this.errorMessageArray.push(this.Straße_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Straße_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  charactersNumbersOnlyhouse(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Hausnummer_validation') !== -1;
    const regexp = new RegExp(REGEX.NON_EMPTY_STRING);
    const serchfind = regexp.test(e);

    this.Hausnummer_validation = 'Hausnummer_validation';
    this.house = !e.length || !serchfind;
    if (!serchfind && !isSalutation) {
      this.house = true;
      this.errorMessageArray.push(this.Hausnummer_validation);
    } else {
      const index = this.errorMessageArray.indexOf(this.Hausnummer_validation);
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }

  vornameOnly(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Vorname_validation') !== -1;
    const regexp = new RegExp(REGEX.NAME_VALIDATION);
    const serchfind = regexp.test(e);

    this.Vorname_validation = 'Vorname_validation';
    this.firstname = !e.length || !serchfind;

    if (!serchfind && !isSalutation) {
      this.firstname = true;
      this.errorMessageArray.push(this.Vorname_validation);
    } else if (!serchfind && isSalutation) {
      const index: number = this.errorMessageArray.indexOf(
        this.Vorname_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
        this.errorMessageArray.push(this.Vorname_validation);
      }
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Vorname_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  nachnameOnly(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Nachname_validation') !== -1;
    const regexp = new RegExp(REGEX.NAME_VALIDATION);
    const serchfind = regexp.test(e);

    this.Nachname_validation = 'Nachname_validation';
    this.lastname = !e.length || !serchfind;
    if (!serchfind && !isSalutation) {
      this.lastname = true;
      this.errorMessageArray.push(this.Nachname_validation);
    } else if (!serchfind && isSalutation) {
      const index: number = this.errorMessageArray.indexOf(
        this.Nachname_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
        this.errorMessageArray.push(this.Nachname_validation);
      }
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Nachname_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  emailOnly(e) {
    let serchfind: boolean;
    const isEmail =
      this.errorMessageArray.indexOf('email_validation') !== -1 ? true : false;
    const regexp = new RegExp(REGEX.EMAIL_VALIDATION);

    serchfind = regexp.test(e);
    this.email_validation = 'email_validation';
    if (e.length === 0 || !serchfind) {
      this.email = true;
    } else {
      this.email = false;
      if (this.userEmail !== e) {
        this.serviceCall
          .check_is_unique('emailId', e)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            if (data.status !== 'unique') {
              this.emailunique = true;
              this.emailvalidation = data.message;
            } else {
              this.emailunique = false;
            }
          });
      }
    }
    if (!serchfind && !isEmail) {
      this.emailunique = false;
      this.email = true;
      this.errorMessageArray.push(this.email_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.email_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  showbutton() {
    this.submitButton = true;
  }
  save(event) {
    event.address = this.selectedLoc;
    if (
      event.salutation !== '' &&
      event.first_name !== '' &&
      event.last_name !== '' &&
      event.email !== '' &&
      event.role !== '' &&
      event.language !== '' &&
      event.house !== '' &&
      event.street !== '' &&
      event.zip_code !== '' &&
      event.city !== '' &&
      event.country !== '' &&
      event.invitation_language !== ''
    ) {
      event.role = event.role_id;
      if (this.data.is_source_ivm && this.data.is_invited_to_rcs) {
        event.invitation_language = event.invitation_language;
      } else if (this.data.is_source_ivm && !this.data.is_invited_to_rcs) {
        event.invitation_language = event.invitation_language;
      } else {
        event.invitation_language = event.invitation_language;
      }
      const index: number = this.errorMessageArray.indexOf(
        'allFieldRequire_validation'
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
      if (this.errorMessageArray.length > 0) {
        if (this.errorMessageArray.indexOf('Vorname_validation') !== -1) {
          this.firstname = true;
          this.Vorname_validation = 'Vorname_validation';
        }
        if (this.errorMessageArray.indexOf('Nachname_validation') !== -1) {
          this.lastname = true;
          this.Nachname_validation = 'Nachname_validation';
        }
        if (this.errorMessageArray.indexOf('Telefon_validation') !== -1) {
          this.telefon_error = true;
          this.Telefon_validation = 'Telefon_validation';
        }
        if (this.errorMessageArray.indexOf('email_validation') !== -1) {
          this.email = true;
          this.email_validation = 'email_validation';
        } else {
          if (!this.emailunique) {
            if (this.userEmail !== event.email) {
              this.serviceCall
                .check_is_unique('emailId', event.email)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  if (data.status !== 'unique') {
                    this.emailunique = true;
                    this.emailvalidation = data.message;
                  }
                });
            }
          }
        }
        if (this.errorMessageArray.indexOf('Hausnummer_validation') !== -1) {
          this.house = true;
          this.Hausnummer_validation = 'Hausnummer_validation';
        }
        if (this.errorMessageArray.indexOf('Straße_validation') !== -1) {
          this.street = true;
          this.Straße_validation = 'Straße_validation';
          this.strabe = this.Straße_validation;
        }
        if (this.errorMessageArray.indexOf('Postleitzahl_validation') !== -1) {
          this.zip = true;
          this.Postleitzahl_validation = 'Postleitzahl_validation';
        }
        if (this.errorMessageArray.indexOf('Stadt_validation') !== -1) {
          this.city = true;
          this.Stadt_validation = 'Stadt_validation';
        }
        this.submitButton = false;
      } else if (this.emailunique || !this.emailunique) {
        if (this.userEmail !== event.email) {
          this.serviceCall
            .check_is_unique('emailId', event.email)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              if (data.status !== 'unique') {
                this.emailunique = true;
                this.emailvalidation = data.message;
              } else {
                this.submitButton = true;
                this.emailunique = false;
                this.dialogRef.close(event);
                this.emailvalidation = '';
              }
            });
        } else {
          this.submitButton = true;
          this.emailunique = false;
          this.dialogRef.close(event);
          this.emailvalidation = '';
        }
      } else {
        this.submitButton = true;
        this.dialogRef.close(event);
      }
    } else {
      if (event.salutation === '') {
        this.salutation = true;
      }
      if (event.first_name === '') {
        this.firstname = true;
        this.Vorname_validation = 'Vorname_validation';
      }
      if (event.last_name === '') {
        this.lastname = true;
        this.Nachname_validation = 'Nachname_validation';
      }
      if (event.email === '') {
        this.email = true;
        this.email_validation = 'email_validation';
      } else {
        if (!this.emailunique) {
          if (this.userEmail !== event.email) {
            this.serviceCall
              .check_is_unique('emailId', event.email)
              .pipe(takeUntil(this._destroy$))
              .subscribe(data => {
                if (data.status !== 'unique') {
                  this.emailunique = true;
                  this.emailvalidation = data.message;
                }
              });
          }
        }
      }
      if (event.house === '') {
        this.house = true;
        this.Hausnummer_validation = 'Hausnummer_validation';
      }
      if (event.street === '') {
        this.street = true;
        this.Straße_validation = 'Straße_validation';
        this.strabe = this.Straße_validation;
      }
      if (event.zip_code === '' || this.zip) {
        this.zip = true;
        this.Postleitzahl_validation = 'Postleitzahl_validation';
      }
      if (event.city === '') {
        this.city = true;
        this.Stadt_validation = 'Stadt_validation';
      }
      this.submitButton = false;
      this.is_source_ivm = event.is_source_ivm;
      const error = 'allFieldRequire_validation';
      const index: number = this.errorMessageArray.indexOf(error);

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
      this.errorMessageArray.push(error);
    }
  }
  loadUserRoles() {
    this.serviceCall
      .getUserRole()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (this.data_is_source_ivm) {
          for (const element of data) {
            if (
              element.user_role_name !== USER_ROLES.CUSTOMER_USER &&
              element.user_role_name !== USER_ROLES.CUSTOMER_USER_GERMAN
            ) {
              this.User_role_list.push(element);
              this.data.role = element.user_role_id;
              this.data.role_id = element.user_role_id;
            }
          }
        } else {
          this.User_role_list = data;
          if (
            this.data.role === USER_ROLES.CUSTOMER_USER ||
            this.data.role === USER_ROLES.CUSTOMER_USER_GERMAN
          ) {
            for (const element of data) {
              if (
                element.user_role_name === USER_ROLES.CUSTOMER_USER ||
                element.user_role_name === USER_ROLES.CUSTOMER_USER_GERMAN
              ) {
                this.data.role = element.user_role_id;
                this.data.role_id = element.user_role_id;
              }
            }
          }
        }
        this.data.role = this.data.role || this.User_role_list[0].user_role_id;
      });
  }
  CountryChangedEvent(input) {
    this.placeholder = input.placeHolder;
  }
  getCustomers() {
    this.serviceCall
      .getUniqueSubsidiaries(this.customerId, this.byGroup, this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.associated_customers) {
          return;
        }

        for (const element of data.associated_customers) {
          const customer: Customer = {
            id: null,
            name: '',
            liztu_vn_kdnr: null,
            type: '',
            has_admin: false,
          };
          customer.id = element.customer_id;
          customer.name = element.customer_name;
          customer.liztu_vn_kdnr = element.liztu_vn_kdnr;
          customer.type = element.type;
          customer.has_admin = element.has_admin;
          if (element.type === 'Private') {
            this.mutter = element.customer_name;
          }
          if (element.type === CUSTOMER_TYPES.MUTTER) {
            this.mutter = element.customer_name;
            this.mutterCustomer = this.mutter;
            this._userService.mutterCustomer = this.mutterCustomer;
          }
          this.customers.push(customer);
          if (this.is_reset_invite) {
            for (const customerItem of this.customers) {
              if (customerItem.id === this.customerId) {
                this.data.customer = customerItem;
                this.data.customer_name = customerItem.name;
                this.data.customer_id = customerItem.id;
                this.data.liztu_vn_kdnr = customerItem.liztu_vn_kdnr;
              }
            }
          }
        }
        const customerSelectedByDefault =
          this.customers.find(ele => ele.id === this.data.customer_id)?.id ||
          this.customerId;
        this.customerChange(customerSelectedByDefault);
      });
  }
  addUser(data) {
    data.customer_name = data.customer.name;
    data.customer_id = data.customer.id;
    data.liztu_vn_kdnr = data.customer.liztu_vn_kdnr;
    data.type = data.customer.type;
    this.salutationLanguageChange();
    if (
      data.salutation !== undefined ||
      data.salutation !== null ||
      data.salutation !== ''
    ) {
      data.salutation_id = this.salutationService.getSalutationId(
        data.salutation,
        this._userService.getLocale()
      );
    }
    if (
      data.customer_name === '' ||
      data.first_name === '' ||
      data.last_name === '' ||
      data.email === '' ||
      data.salutation_id === '' ||
      data.salutation_id === null ||
      data.house === '' ||
      data.house === null ||
      data.street === '' ||
      data.zip_code === '' ||
      data.city === '' ||
      data.customer_name === undefined ||
      this.zip === true
    ) {
      if (data.first_name === '') {
        this.firstname = true;
        this.Vorname_validation = 'Vorname_validation';
      }
      if (data.customer_name === '' || data.customer_name === undefined) {
        this.customer = true;
      }
      if (data.salutation_id === '' || data.salutation_id === null) {
        this.salutation = true;
      }
      if (data.last_name === '') {
        this.lastname = true;
        this.Nachname_validation = 'Nachname_validation';
      }
      if (data.email === '') {
        this.email = true;
        this.email_validation = 'email_validation';
      } else {
        if (this.emailunique === false) {
          this.serviceCall
            .check_is_unique('emailId', data.email)
            .pipe(takeUntil(this._destroy$))
            .subscribe((res: any) => {
              if (res.status !== 'unique') {
                this.emailunique = true;
                this.emailvalidation = res.message;
              }
            });
        }
      }
      if (data.house === '' || data.house === null) {
        this.house = true;
        this.Hausnummer_validation = 'Hausnummer_validation';
      }
      if (data.street === '') {
        this.street = true;
        this.Straße_validation = 'Straße_validation';
        this.strabe = this.Straße_validation;
      }
      if (data.zip_code === '') {
        this.zip = true;
        this.Postleitzahl_validation = 'Postleitzahl_validation';
      }
      if (data.city === '') {
        this.city = true;
        this.Stadt_validation = 'Stadt_validation';
      }
    } else {
      if (!this.emailunique) {
        this.serviceCall
          .check_is_unique('emailId', data.email)
          .pipe(takeUntil(this._destroy$))
          .subscribe(result => {
            if (result.status !== 'unique') {
              this.emailunique = true;
              this.emailvalidation = result.message;
            } else if (!this.email) {
              this.addUserDataFormat(data);
            }
          });
      } else if (this.emailunique) {
        this.serviceCall
          .check_is_unique('emailId', data.email)
          .pipe(takeUntil(this._destroy$))
          .subscribe(result => {
            if (result.status !== 'unique') {
              this.emailunique = true;
              this.emailvalidation = result.message;
            } else if (!this.email) {
              this.emailunique = false;
              this.addUserDataFormat(data);
            }
          });
      }
    }
  }
  addUserDataFormat(data) {
    const user: User = {
      customerId: data.customer.id,
      firstName: data.first_name,
      lastName: data.last_name,
      userName: data.first_name + ' ' + data.last_name,
      uniqueIdentifier: data.email,
      role: data.role,
      salutation: data.salutation,
      house: data.house,
      street: data.street,
      zipCode: data.zip_code,
      city: data.city,
      country: data.country,
      email: data.email,
      phone: data.Telefon,
      invitationLanguage: data.invitation_language,
      customer_address_lat_lon_set: data.customer_address_lat_lon_set,
      address: data.address ? data.address : this.selectedLoc,
      division: data.division,
      userId: data.user_id,
      customerName: data.customer_name,
      lat: data.lat,
      lon: data.lon,
      newUser: data.newUser,
      otpTo: data.otpTo,
      type: data.type,
      inviterUsername: this.inviter_username,
      liztu_vn_kdnr: data.liztu_vn_kdnr,
      is_group: data.is_group,
      inviterEmail: this.inviter_email,
      role_id: data.role_id,
    };
    this._userService.userToUpdate = data;
    this._userService.userFormData = user;
    this._userService.showActiveFlag = this.showActive;
    this.dialog.open(StepTwoCustomerInviteComponent, {
      width: '800px',
    });
    this.dialogRef.close();
  }
  onSaveClick(data) {
    this._userService.userToUpdate = data;

    this.dialog.open(StepTwoCustomerInviteComponent, {
      width: '800px',
    });
    this.dialogRef.close();
  }
  salutationSelection() {
    this.salutation = false;
  }
  customerChange(item) {
    if (item !== null || item !== '') {
      this.customer = false;
    }
    for (const element of this.customers) {
      if (element.id.toString() === item.toString()) {
        this.data.customer = element;
        this.data.customer_name = element.name;
        this.data.customer_id = element.id;
        this.data.liztu_vn_kdnr = element.liztu_vn_kdnr;
        this.has_admin = element.has_admin;
        this.mutter = element.name;
      }
    }
    this._userService.saveInviteCustomerLiztuVnKdnr(this.data.liztu_vn_kdnr);
    if (!this._userService.userFormData) {
      this.serviceCall
        .getCustomerAddress(this._userService.getInviteCustomerLiztuVnKdnr())
        .pipe(takeUntil(this._destroy$))
        .subscribe(result => {
          this.setAddressValues(result);
        });
    } else {
      this.setAddressValues(this._userService.userFormData);
    }
  }
  setAddressValues(result) {
    const {
      house,
      street,
      zip_code,
      zipCode,
      city,
      country,
      lat,
      lon,
      customer_address_lat_lon_set,
      address,
    } = result;

    this.data.house = house;
    this.data.street = street;
    this.data.zip_code = zip_code || zipCode; // Prefer `zipCode` if it's truthy, otherwise use `zipCode`
    this.data.city = city;
    this.data.country = this.countrySet(country);
    this.data.lat = lat;
    this.data.lon = lon;
    this.data.customer_address_lat_lon_set = customer_address_lat_lon_set;
    this.data.address = address;

    if (house && street && (zip_code || zipCode) && city) {
      this.house = false;
      this.street = false;
      this.zip = false;
      this.city = false;
    }
    this.showActive =
      lat !== null && lon !== null && customer_address_lat_lon_set !== null;
  }
  onaddressChange() {
    this.addressChanged = true;
    this.showActive = false;
    this.data.lat = null;
    this.data.lon = null;
    this.data.customer_address_lat_lon_set = null;
  }
  mobileChange(Telefon) {
    if (Telefon === '') {
      this.data.otpTo = 'Email';
    }
  }
  salutationLanguageChange() {
    if (ROLES.IC_ROLES.includes(this.user_role)) {
      let salutation = this.salutationService.getSalutation(
        this.salutation_id,
        this.data.invitation_language
      );
      salutation =
        salutation === null || salutation === undefined ? '' : salutation;
      this.inviter_username = (salutation + ' ' + this.user_name).trim();
    } else {
      const salutation = this.salutationService.getSalutation(
        this.salutation_id,
        this.data.invitation_language
      );
      this.inviter_username = salutation + ' ' + this.user_name;
    }
  }
  isGroupChange(isGroup) {
    if (isGroup !== this.old_is_group) {
      this._userService.selectedCustomers = null;
      this._sparteShareService.sparte = null;
    }
  }
  countrySet(country) {
    if (null === country || country === '') {
      return null;
    }
    const countryReturn = this.countries?.find(
      obj => obj.countryName === country || obj.countryCode === country
    );
    if (undefined === countryReturn || null === countryReturn) {
      return null;
    }
    return countryReturn.countryName;
  }
}
