import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as moment from 'moment';
import { ERRORS, STATIC_TEXT } from '@shared/constants/string.constants';

@Directive({
  selector: '[maxDateToday]',
})
export class MaxDateTodayDirective {
  constructor(
    private el: ElementRef,
    private ngControl: NgControl
  ) {}

  @HostListener('input') onInput(): void {
    let inputValue = this.el.nativeElement.value;
    inputValue = inputValue.replace(/[^0-9\.]/g, '');
    inputValue = inputValue.replace(/\.{2,}/g, '.');
    if (inputValue.length >= 10) {
      inputValue = inputValue.slice(0, 10);
    }
    this.el.nativeElement.value = inputValue;
  }

  @HostListener('blur')
  onBlur(): void {
    const inputValue = this.el.nativeElement.value;
    const dateToCompare = moment(inputValue, STATIC_TEXT.DATE_FORMAT).toDate();
    // here we are comparing input date with today using moment isAfter()
    // moment() will give todays date
    const isAfter = moment(dateToCompare).isAfter(moment());
    if (isAfter) {
      this.el.nativeElement.value = STATIC_TEXT.EMPTY_STRING;
      this.ngControl.control.setValue(this.el.nativeElement.value);
      this.ngControl.control.setErrors({ [ERRORS.INVALID_DATE]: true });
    }
  }
}
