<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic rcs-setup">

  <app-breadcrumbs *ngIf="activeLable" [isCustomerSelected]="isCustomerSelected" [activeLable]="activeLable"
    [pageBreadcrumbText]="pageBreadcrumbText" [pageBreadcrumbSubText]="pageBreadcrumbSubText"
    [customerName]="companyName"></app-breadcrumbs>
  <app-breadcrumbs *ngIf="!activeLable" [isCustomerSelected]="isCustomerSelected" [tempActiveLable]="tempActiveLable"
    [pageBreadcrumbText]="pageBreadcrumbText" [pageBreadcrumbSubText]="pageBreadcrumbSubText"
    [customerName]="companyName"></app-breadcrumbs>

  <mat-sidenav-container class="sidenav-container" (keydown.escape)="$event.stopPropagation()">
    <mat-sidenav #leftSidenav mode="side" opened (keydown.escape)="$event.stopPropagation()">
      <div class="sidenav_container" [@onSideNavChange]="sideNavState ? 'open' : 'close'"
        (keydown.escape)="$event.stopPropagation()">
        <div class="flex-column" (keydown.escape)="$event.stopPropagation()">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
            <li ngbNavItem="one" (click)="fetchName('rcs-setup.Spartenmanagement')"
              (keydown.escape)="$event.stopPropagation()">
              <a ngbNavLink [class.disabled]="diabledSideBar">
                <span class="flex-center">
                  <img alt="" title="{{'rcs-setup.Spartenmanagement' | translate}}" [src]="IC_ICONS.SPARTE_MANAGEMENT">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.Spartenmanagement' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
                <app-sparte-management></app-sparte-management>
              </ng-template>
            </li>

            <li ngbNavItem="two" class="disabled-sideNav" title="{{'rcs-setup.Tabellenmanagement' | translate}}">
              <a ngbNavLink [class.disabled]="true">
                <span class="flex-center">
                  <img alt="" [src]="IC_ICONS.TABLE_MANAGEMENT">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.Tabellenmanagement' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
              </ng-template>
            </li>

            <li ngbNavItem="three" (click)="fetchName('rcs-setup.Produktmanagement')">
              <a ngbNavLink [class.disabled]="diabledSideBar">
                <span class="flex-center">
                  <img alt="" title="{{'rcs-setup.Produktmanagement' | translate}}" [src]="IC_ICONS.PRODUCT_MANAGEMENT">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.Produktmanagement' |translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
                <app-product-management [addNewPage]="addNewPage" (changeInProduct)="changeInProduct($event)">
                </app-product-management>
              </ng-template>
            </li>

            <li ngbNavItem="four" class="disabled-sideNav" title="{{'rcs-setup.Objekt-Management' | translate}}">
              <a ngbNavLink [class.disabled]="true">
                <span class="flex-center">
                  <img alt="" [src]="IC_ICONS.OBJECT_MANAGEMENT">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.Objekt-Management' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
              </ng-template>
            </li>

            <li ngbNavItem="five" class="disabled-sideNav" title="{{'rcs-setup.vorgänge-Manager' | translate}}">
              <a ngbNavLink [class.disabled]="true">
                <span class="flex-center">
                  <img alt="" [src]="IC_ICONS.PROCESS_MANAGEMENT">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.vorgänge-Manager' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
              </ng-template>
            </li>

            <li ngbNavItem="six" (click)="fetchName('rcs-setup.Kontakt-Manager')">
              <a ngbNavLink [class.disabled]="diabledSideBar">
                <span class="flex-center">
                  <img alt="" title="{{'rcs-setup.Kontakt-Manager' | translate}}"
                    [src]="IC_ICONS.CONTACT_MANAGER">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.Kontakt-Manager' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
                <app-contact-manager [userDataValue]="userDataValue"></app-contact-manager>
              </ng-template>
            </li>

            <li ngbNavItem="seven" (click)="fetchName('rcs-setup.TemplateRepository')">
              <a ngbNavLink [class.disabled]="diabledSideBar">
                <span class="flex-center">
                  <img alt="" title="{{'rcs-setup.TemplateRepository' | translate}}" [src]="IC_ICONS.TEMPLATE_REPOSITORY">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.TemplateRepository' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
                <template-repository></template-repository>
              </ng-template>
            </li>

            <li ngbNavItem="eight" (click)="fetchName('rcs-setup.microsoft_templates_management')">
              <a ngbNavLink [class.disabled]="diabledSideBar">
                <span class="flex-center">
                  <img alt="" title="{{'rcs-setup.microsoft_templates_management' | translate}}" src="assets/images/Microsoft_icon.svg">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.microsoft_templates_management' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
                <app-ms-templates-management></app-ms-templates-management>
              </ng-template>
            </li>

            <li ngbNavItem="nine" (click)="fetchName('rcs-setup.form_generator'); formGeneratorTab = true; editFormId = null">
              <a ngbNavLink [class.disabled]="diabledSideBar">
                <span class="flex-center">
                  <img alt="" title="{{'rcs-setup.form_generator' | translate}}" [src]="IC_ICONS.FORM_GENERATOR">
                  <span [@animateText]="linkText ? 'show' : 'hide'">{{'rcs-setup.form_generator' | translate}}</span>
                </span>
              </a>
              <ng-template ngbNavContent>
                <app-form-generator *ngIf="formGeneratorTab" (newFormTab)="newFormClick($event)"
                  (formId)="receiveFormId($event)" [toastMessageForm]="toastMessageForm"
                  (createdBy)="getCreatedBy($event)"></app-form-generator>
                <app-add-new-form *ngIf="!formGeneratorTab" [formId]="editFormId"
                  (snackbar_message)="toastMessageAddForm($event)" [createdBy]="createdByRole"
                  (discardChanges)="closeAddNewForm($event)"></app-add-new-form>
              </ng-template>
            </li>
          </ul>

          <div class="sideNav-btn">
            <button mat-icon-button (click)="onSinenavToggle()" (keydown.escape)="$event.stopPropagation()"
              class="theme-background">
              <mat-icon *ngIf="sideNavState">arrow_left</mat-icon>
              <mat-icon *ngIf="!sideNavState">arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content (keydown.escape)="$event.stopPropagation()"
      [@onMainContentChange]="onSideNavChange ? 'open': 'close'" style="z-index: unset">
      <div [ngbNavOutlet]="nav" style="margin-top: 12px;"></div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
