<div>
  <span class="criteria-heading">{{'objectModule.power_supply_object.power_supply_label' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="powerSupplyForm" id="powerSupplyForm">
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.power_supply_object.type_of_energy_supply' | translate}} </label>
        <mat-select multiple class="dropdownStyle"
          placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="energy_supply_types">
          <mat-option *ngFor="let item of enerySupplyTypesData" [value]="item.id" >
              <span>{{item.desc}}</span>
            </mat-option>
        </mat-select>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.power_supply_object.transformer_stations' | translate}}</label>
        <mat-select multiple class="dropdownStyle"
          placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="transformer_stations">
          <mat-option *ngFor="let item of transformerStationsData" [value]="item.id" >
              <span>{{item.desc}}</span>
            </mat-option>
        </mat-select>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.power_supply_object.transformer_stations' | translate}} : {{'objectModule.image_upload' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.image_upload' | translate}}"
            title="{{powerSupplyForm.controls['transformer_stations_documents'].value}}" formControlName="transformer_stations_documents">
        </div>
        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadTransferStationDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.power_supply_object.owner' | translate}}</label>
      <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="owner" >
          <mat-radio-button value="Own">{{'objectModule.power_supply_object.own' | translate}}</mat-radio-button>
          <mat-radio-button value="Foreign">{{'objectModule.power_supply_object.foreign' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.power_supply_object.multiple_feed' | translate}}</label>
      <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="multiple_feed" >
          <mat-radio-button [value]="true" (change)="powerSupplyChange(true)">
            {{'objectModule.power_supply_object.yes' | translate}}
          </mat-radio-button>
          <mat-radio-button [value]="false" (change)="powerSupplyChange(false)">
            {{'objectModule.power_supply_object.no' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.power_supply_object.multiple_feed_switchable' | translate}}</label>
      <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="multiple_feed_switchable">
          <mat-radio-button [value]="true">
            {{'objectModule.power_supply_object.yes' | translate}}
          </mat-radio-button>
          <mat-radio-button [value]="false">
            {{'objectModule.power_supply_object.no' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.power_supply_object.external_power_supply' | translate}} </label>
      <mat-select class="dropdownStyle"
        placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="external_power_supply">
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let item of externalPowerSupplyData" [value]="item.id" >
            <span>{{item.desc}}</span>
          </mat-option>
      </mat-select>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.power_supply_object.battery_charging_stations' | translate}} </label>
      <mat-select multiple class="dropdownStyle"
        placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="battery_charging_station">
        <mat-option *ngFor="let item of batterChargingStationsData" [value]="item.id"  matTooltip="{{item.desc}}">
            <span>{{item.desc}}</span>
          </mat-option>
      </mat-select>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="long-label">{{'objectModule.power_supply_object.battery_charging_stations' | translate}} : {{'objectModule.image_upload' | translate}}</label>
      <div style="display: flex;">
      <div class="documentUploadInput">
          <input class="input" readonly placeholder="{{'objectModule.image_upload' | translate}}"
            title="{{powerSupplyForm.controls['battery_charging_stations_documents'].value}}" formControlName="battery_charging_stations_documents">
      </div>
      <div class="documentUploadButton">
      <button class="docButton" type="button" (click)="uploadBatteryChargingStationDocumentPopup()">
       <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
      </button>
      </div>
    </div>
    </div>

    <!-- Section one started -->
    <div [ngClass]="powerSupplyForm.value.is_emergency_power_supply ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
      <div class="toggleSectionHeader">
        <mat-slide-toggle formControlName="is_emergency_power_supply">{{'objectModule.power_supply_object.emergency_power_supply' | translate}}</mat-slide-toggle>
      </div>
      <div *ngIf="powerSupplyForm.value.is_emergency_power_supply" class="row toggle-section-content-top-align">
        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.emergency_generator' | translate}}</label>
          <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="emergency_generator" >
              <mat-radio-button [value]="true" (change)="emergencyGeneratorChange(true)">
                {{'objectModule.power_supply_object.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button [value]="false" (change)="emergencyGeneratorChange(false)">
                {{'objectModule.power_supply_object.no' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.for_which_areas' | translate}}</label>
          <input type="text" maxlength="100" class="input" formControlName="emergency_generator_area"
            placeholder="{{'objectModule.power_supply_object.area' | translate}}"
            [disabled]="!powerSupplyForm.controls['emergency_generator'].value"
            title="{{powerSupplyForm.controls['emergency_generator_area'].value}}"
            >
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.duration_in_minutes' | translate}}</label>
          <div class="smallInput" style="width: 100%;">
              <div style="display: flex;">
                <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" formControlName="emergency_generator_minutes"
                type="text" class="input" maxlength="4" type="text" pattern="[0-9]*"
                (keydown)="typeNumber($event, 'keydown',4)" (paste)="typeNumber($event, 'paste',4)"
                (blur)="validation.sqmValidation(powerSupplyForm, $event)" (focus)="validation.sqmValidation(powerSupplyForm, $event)"  numbersOnly
                placeholder="{{'objectModule.power_supply_object.duration_in_minutes' | translate}}" style="border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;">
                <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="4" ng-pattern="[0-9]*" formControlName="emergency_generator_minutes"
                (paste)="typeNumberBrowserSpecific($event, 'paste',4)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',4)"
                (blur)="validation.sqmValidation(powerSupplyForm, $event)" (focus)="validation.sqmValidation(powerSupplyForm, $event)"  numbersOnly
                placeholder="{{'objectModule.power_supply_object.duration_in_minutes' | translate}}" style="border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;">
              <mat-select id="emergency_generator" class="dropdownStyle emergency_generatorStyle"
                placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="emergency_generator_hourMinute">
                <mat-option style="opacity:0.5">
                  {{'objectModule.select_lable' | translate}}
                </mat-option>
                <mat-option *ngFor="let item of externalSupplyHourMinData" [value]="item.id" >
                    <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.backup_power_systems_UPS' | translate}}</label>
          <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="backup_power_system" >
              <mat-radio-button [value]="true" (change)="backupChange(true)">
                {{'objectModule.power_supply_object.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button [value]="false" (change)="backupChange(false)">
                {{'objectModule.power_supply_object.no' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.for_which_areas' | translate}}</label>
          <input type="text" maxlength="100" class="input" formControlName="backup_power_system_area"
            placeholder="{{'objectModule.power_supply_object.area' | translate}}"
            title="{{powerSupplyForm.controls['backup_power_system_area'].value}}">
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.duration_in_minutes' | translate}}</label>
          <div class="smallInput" style="width: 100%;">
              <div style="display: flex;">
                <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" formControlName="backup_power_system_minutes"
                  type="text" class="input" maxlength="4" type="text" pattern="[0-9]*"
                  (keydown)="typeNumber($event, 'keydown',4)" (paste)="typeNumber($event, 'paste',4)"
                  (blur)="validation.sqmValidation(powerSupplyForm, $event)" (focus)="validation.sqmValidation(powerSupplyForm, $event)"  numbersOnly
                  placeholder="{{'objectModule.power_supply_object.duration_in_minutes' | translate}}" style="border-bottom-right-radius: 0px;
                  border-top-right-radius: 0px;">
                <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="4" ng-pattern="[0-9]*" formControlName="backup_power_system_minutes"
                  (paste)="typeNumberBrowserSpecific($event, 'paste',4)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',4)"
                  (blur)="validation.sqmValidation(powerSupplyForm, $event)" (focus)="validation.sqmValidation(powerSupplyForm, $event)"  numbersOnly
                  placeholder="{{'objectModule.power_supply_object.duration_in_minutes' | translate}}" style="border-bottom-right-radius: 0px;
                  border-top-right-radius: 0px;">
                <mat-select class="dropdownStyle backup_power_systemStyle" id="backup_power_system"
                placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="backup_power_system_hourMinute">
                <mat-option style="opacity:0.5">
                  {{'objectModule.select_lable' | translate}}
                </mat-option>
                <mat-option *ngFor="let item of externalSupplyHourMinData" [value]="item.id" >
                    <span>{{item.desc}}</span>
                  </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section two started -->
    <div [ngClass]="powerSupplyForm.value.is_compressors ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
      <div class="toggleSectionHeader">
        <mat-slide-toggle formControlName="is_compressors">{{'objectModule.power_supply_object.compressors' | translate}}</mat-slide-toggle>
      </div>
      <div *ngIf="powerSupplyForm.value.is_compressors" class="row toggle-section-content-top-align">
        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.spatial_separated' | translate}}</label>
          <mat-select class="dropdownStyle"
            placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="spatial_seperated">
            <mat-option style="opacity:0.5">
              {{'objectModule.select_lable' | translate}}
            </mat-option>
            <mat-option *ngFor="let item of spatialSeperatedData" [value]="item.id" >
                <span>{{item.desc}}</span>
              </mat-option>
          </mat-select>
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.switch_to_fire_alarm_system' | translate}}</label>
          <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="switch_to_fire_alarm" >
              <mat-radio-button [value]="true">
                {{'objectModule.power_supply_object.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button [value]="false">
                {{'objectModule.power_supply_object.no' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.type_of_compressors' | translate}} </label>
          <mat-select class="dropdownStyle"
            placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="type_of_compressor">
            <mat-option style="opacity:0.5">
              {{'objectModule.select_lable' | translate}}
            </mat-option>
            <mat-option *ngFor="let item of typeOfCompressors" [value]="item.id" >
                <span>{{item.desc}}</span>
              </mat-option>
          </mat-select>
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.number_of_compressors' | translate}}</label>
            <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" formControlName="number_of_compressors"
            type="text" class="input" maxlength="3" type="number" pattern="[0-9]*"
            (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)"
            placeholder="{{'objectModule.power_supply_object.number_of_compressors' | translate}}">
            <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="3" ng-pattern="[0-9]*" formControlName="number_of_compressors"
            (paste)="typeNumberBrowserSpecific($event, 'paste',3)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)"
            placeholder="{{'objectModule.power_supply_object.number_of_compressors' | translate}}">
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.power_supply_object.regular_maintenance' | translate}}</label>
          <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="regular_maintanance" >
              <mat-radio-button [value]="true" (change)="regularMaintananceChange(true)">
                {{'objectModule.power_supply_object.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button [value]="false" (change)="regularMaintananceChange(false)">
                {{'objectModule.power_supply_object.no' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label"> {{'objectModule.power_supply_object.by_whom' | translate}} </label>
          <mat-select class="dropdownStyle"
            placeholder="{{'objectModule.select_lable' | translate}}"  formControlName="regular_maintanance_by_whom">
            <mat-option style="opacity:0.5">
              {{'objectModule.select_lable' | translate}}
            </mat-option>
            <mat-option *ngFor="let item of regularMaintananceByWhomData" [value]="item.id" >
                <span>{{item.desc}}</span>
              </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
</form>
<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails"
  [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()"
  (hide)="hidePopup($event)">
</app-upload-document>
