<app-header></app-header>

<div class="container-ic">
    <div style="background-color: #ffffff" class="">
        <div style="
            width: 90%;
            height: 100%;
            min-height: 78vh;
            margin: 0 auto;
            white-space: nowrap;">
            <div>
                <h6 style="color: #272727; font-size: 18px; margin-top: 9%">
                    <strong><span>/</span>&nbsp;&nbsp; Register claims</strong>
                </h6>
            </div>
            <div>
                <div style="height: 52px;background-color: rgba(196, 196, 196, 0.17);">
                    <div style="display: flex;justify-content: center;padding-top: 10px;">
                        <button class="navButton" style="border: 2px solid #ff9000;box-shadow: 0 0 0 2px #fff inset;">1
                        </button>
                        <hr style="width: 4%;margin: 16px 0px;border-top: 2px solid #dddddd">
                        <button class="navButton" style="background-color: #dddddd;">2</button>
                        <hr style="display: inline-block;width: 4%;margin: 16px 0px;border-top: 2px solid #dddddd;">
                        <button class="navButton" style="background-color: #dddddd;">3</button>
                    </div>
                </div>
            </div>
            <app-register-claim-step-one></app-register-claim-step-one>
        </div>
    </div>
</div>