import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { VALIDATION_PATTERN } from '@shared/constants/global-constants.constants';
import { AZURE } from '@shared/constants/azure-service.constants';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { ROLES } from '@shared/constants/roles.constants';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { REGEX } from '@shared/constants/general/regex.constants';
import * as moment from 'moment';
import { SendingElement } from './claim-reporting.model';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-claim-reporting',
  templateUrl: './claim-reporting.component.html',
  styleUrls: ['./claim-reporting.component.scss'],
})
export class ClaimReportingComponent extends BaseComponent implements OnInit {
  @ViewChild('phone') mobile: FormControl;
  Schadenhergang: any;
  Alllocations: any;
  customerName: string;
  filterlocation: any = [];
  filterPositionLat;
  filterPositionLang;
  showActive = false;
  openDocUpload: boolean;
  formData: FormGroup;
  submitted = false;
  userDataValue: any;
  liztuVnKdnr: any;
  filesList: File[] = [];
  divisionName: string;
  today = new Date();
  isDateError = false;
  isDamageAmountError = false;
  isSchadenError = false;
  changedValue: string;
  showEmailError = false;
  showMobileError = false;
  customer: string;
  selectedHeaderCustomerId: string;
  isPhoneValid = false;
  isEmailValid = false;
  fileNames = '';
  keyUp = new Subject<string>();
  showList = false;
  pageBreadcrumbText = 'contracts.claimsReportingTool';
  isCustomerUser$: Observable<boolean>;
  customerName$ = this._customerSharedService.selectedCustomerName$.pipe(
    tap(customerName => (this.customerName = customerName))
  );

  private _email = '';
  private _name = '';
  private _telephone = '';

  constructor(
    private _fb: FormBuilder,
    private _apiCallsService: ApiCallsService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private readonly _toastService: ToastService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
    this.today.setDate(this.today.getDate() + 0);

    if (this.Alllocations) {
      if (!this.filterPositionLat && !this.filterPositionLang) {
        this.showActive = true;
      }
    }
  }

  ngOnInit(): void {
    this.liztuVnKdnr = +atob(this._route.snapshot.queryParamMap.get('liztu'));
    this.customer = atob(this._route.snapshot.queryParamMap.get('customer'));
    this.selectedHeaderCustomerId =
      this._customerSharedService.getSelectedCustomerId();
    const patternSchadenhergang =
      VALIDATION_PATTERN.REGISTER_CLAIMS_DAMAGE_HISTORY;
    this.formData = this._fb.group({
      files: [],
      schadenatum: ['', Validators.required],
      Uhrzeit: [
        '',
        [Validators.pattern(VALIDATION_PATTERN.REGISTER_CLAIMS_TIME)],
      ],
      Kennzeichen: [
        '',
        [Validators.pattern(VALIDATION_PATTERN.REGISTER_CLAIMS_INDICATOR)],
      ],
      Schadenort: [''],
      Schadenhergang: [
        '',
        [Validators.required, Validators.pattern(patternSchadenhergang)],
      ],
      AmountOfDamage: ['', [Validators.required]],
      currency: ['EUR'],
      Aktenzeichen: [
        '',
        [Validators.pattern(VALIDATION_PATTERN.REGISTER_CLAIMS_FILE_REFERENCE)],
      ],
      email: ['', [Validators.pattern(REGEX.EMAIL_VALIDATION)]],
      mobile: [''],
      name: ['', [Validators.pattern(REGEX.NAME_VALIDATION)]],
    });

    this.formData
      .get('name')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.formData.get('mobile').updateValueAndValidity();
        this.formData.get('email').updateValueAndValidity();
      });
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
    this.isCustomerUser$ = this._userService.userRole$.pipe(
      map(role => ROLES.CUSTOMER_ROLES.includes(role))
    );
  }

  get f() {
    return this.formData.controls;
  }

  onSubmit(e) {
    let isSubmitted = false;

    if (!e.name) {
      isSubmitted = true;
    } else if (e.name && !e.mobile && e.email) {
      isSubmitted = true;
    } else if (e.name && e.mobile && !e.email) {
      isSubmitted = true;
    } else if (e.name && e.mobile && e.email) {
      isSubmitted = true;
    } else {
      isSubmitted = false;
    }

    if (isSubmitted) {
      this.submitted = true;
      if (this.formData.invalid) {
        if (this.formData.controls.schadenatum.status == 'INVALID') {
          this.isDateError = true;
        }
        if (this.formData.controls.Schadenhergang.status == 'INVALID') {
          this.isSchadenError = true;
        }
        if (this.formData.controls.AmountOfDamage.status == 'INVALID') {
          this.isDamageAmountError = true;
        }
        return;
      } else {
        this.isSchadenError = false;
        this.isDamageAmountError = false;
        this.isDateError = false;
        this.submitted = false;
        this.isPhoneValid = false;
        this.isEmailValid = false;
        this.showEmailError = false;
        this.showMobileError = false;
      }
      if (this.formData.controls.email.status == 'VALID') {
        this.showEmailError = false;
      }
      if (this.formData.controls.mobile.status == 'VALID') {
        this.showMobileError = false;
      }

      const momentDate = new Date(e.schadenatum);
      const damageDate = moment(momentDate).format('DD.MM.YYYY');
      const claimReportFormdata: SendingElement = {
        address: e.Schadenort,
        damage_date: damageDate,
        description: e.Schadenhergang.replaceAll('\n', '<br>'),
        email: e.email,
        est_damage_amt: this.changedValue,
        file_vn: e.Aktenzeichen,
        licence_plate_number: e.Kennzeichen,
        liztu_vn_Kdnr:
          this.liztuVnKdnr ||
          this.userDataValue.user_profile.customer_details.liztu_vn_kdnr,
        mobile: e.mobile,
        role: this._userService.getUserRole(),
        time: e.Uhrzeit,
        user_id: this.userDataValue.user_profile.user_data.user_id,
        username: e.name,
        currency: e.currency,
        customer_name: this.customerName,
        customer_id: this.customer || this.selectedHeaderCustomerId,
      };
      const formdata = new FormData();
      formdata.append('request', JSON.stringify(claimReportFormdata));
      this.filesList.forEach(item => formdata.append('files', item));
      this._apiCallsService
        .updateClaimReportData(formdata)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.filesList = [];
          if (data) {
            this._toastService.showToastMessage(document, data.message);
          }
        });
      this.formData.reset();
      this.mobile.reset();
      this._name = '';
      this._email = '';
      this._telephone = '';
      this.isPhoneValid = false;
      this.isEmailValid = false;
      this.formData.controls.mobile.reset();
      this.formData.get('currency').setValue('EUR');
    } else {
      if (this.formData.invalid) {
        if (this.formData.controls.schadenatum.status == 'INVALID') {
          this.isDateError = true;
        }
        if (this.formData.controls.Schadenhergang.status == 'INVALID') {
          this.isSchadenError = true;
        }
        if (this.formData.controls.AmountOfDamage.status == 'INVALID') {
          this.isDamageAmountError = true;
        }
        return;
      }
    }
  }

  onFocusOut(event) {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
    this.filterPositionLat = Alllocations.map(obj => {
      return obj.position.lat;
    });
    this.filterPositionLang = Alllocations.map(obj => {
      return obj.position.lat;
    });
  }

  selectedLocation(index) {
    this.showList = false;
    this.formData
      .get('Schadenort')
      .patchValue(this.filterlocation[index].freeformAddress);
  }
  uploadDoc() {
    this.openDocUpload = true;
  }
  hide() {
    this.openDocUpload = false;
  }
  save(event: { files: File[]; names: string }) {
    this.filesList = event.files;
    this.fileNames = event.names;
    this.openDocUpload = false;
  }

  userDataReceived(data) {
    this.userDataValue = data;
    this.divisionName = data.user_profile.customer_details.name;
  }

  changeCurrency(item) {
    const regexTemp = new RegExp('^[a-zA-Zs]*$');
    const result = regexTemp.test(item);
    if (!result) {
      this.changedValue = this._currencyCustomPipe.transform(this.changedValue);
      this.changedValue = this.changedValue.replace('€', '');
      this.changedValue = this.changedValue.trim();
    }
    let searchFind: boolean;
    const regexp = new RegExp('^[0-9.,s€]*$');
    searchFind = regexp.test(item);
    if (searchFind) {
      this.isDamageAmountError = false;
    }
  }
  changeClrSchadenher() {
    this.isSchadenError = false;
  }
  changeClrSchaden() {
    this.isDateError = false;
  }
  changeClrGesch(event) {
    this.isDamageAmountError = false;
  }
  numberOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
  nameFill(item) {
    this._name = item;
    if (item && !this._email && !this._telephone) {
      this.isEmailValid = true;
      this.isPhoneValid = true;
    } else if (!item && !this._email && !this._telephone) {
      this.setEmailPhoneValidation();
    } else if (this._email && this._name && !this._telephone) {
      this.setEmailPhoneValidation();
    } else if (this._name && this._telephone && !this._email) {
      this.setEmailPhoneValidation();
    }
  }
  emailKeyUp(item) {
    this._email = item;
    if (item && this._name) {
      this.setEmailPhoneValidation();
    } else if (!this._email && this._name && !this._telephone) {
      this.isEmailValid = true;
      this.isPhoneValid = true;
    } else if (this._email && this._name && !this._telephone) {
      this.isEmailValid = false;
      this.isPhoneValid = true;
    } else if (!this._email && !this._name && !this._telephone) {
      this.setEmailPhoneValidation();
    }
  }
  mobileKeyup(item) {
    this._telephone = item;
    if (item !== '' && this._name !== null) {
      this.setEmailPhoneValidation();
    } else if (!this._email && this._name && !this._telephone) {
      this.isEmailValid = true;
      this.isPhoneValid = true;
    } else if (this._email && this._name && !this._telephone) {
      this.setEmailPhoneValidation();
    } else if (!this._email && this._name && this._telephone) {
      this.isEmailValid = true;
      this.isPhoneValid = false;
    }
  }
  setEmailPhoneValidation() {
    this.isEmailValid = false;
    this.isPhoneValid = false;
  }

  autoComplete(value) {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this._apiCallsService
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          this.Alllocations = res.results;
          this.filterAddressFromResults(this.Alllocations);
          this.showList = true;
        });
    }
  }
}
