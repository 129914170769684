<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic" #profile>
  <div class="user-profile">
    <app-breadcrumbs [isCustomerSelected]="isCustomerSelected" [pageBreadcrumbText]="pageBreadcrumbText"
      [pageBreadcrumbSubText]="pageBreadcrumbSubText" [customerName]="customerName"></app-breadcrumbs>
    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12 ml-auto mr-auto mt-5">

      <div class="row">
        <div class="col-md-3 col-lg-3 col-xl-2 col-sm-6 col-6" style="padding-left: 0px;">
          <label *ngIf="url" class="picture" for="uploader-input">
            <img alt="" *ngIf="url" style='width: 170px;
                      height: 170px;
                      border-radius: 50%;
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center;
                      border: 1px solid #e1e1e1;' [src]="url" />
            <span style="left: 71px; position: absolute; top: 156px;">
              <img alt="" style="cursor: pointer; width:26px" src="../../../assets/images/Profile.svg" />
            </span>
          </label>
          <div *ngIf="!url" class="profile-picture">
            <label *ngIf="editmode" [ngClass]="{'drag-over': hasDragOver}" for="uploader-input"
              (fileOver)="fileOver($event)">
              <div class="infotext"><span *ngIf="!url">{{'user-profile.Upload_label' | translate}}</span></div>
            </label>

          </div>
          <input type="file" name="file" [attr.disabled]="disable == true ? 'disabled' : null"
            accept="image/x-png,image/jpg,image/jpeg,image/png" (change)="onSelectFile($event)" id="uploader-input"
            style="display:none" />
        </div>

        <div class="col-md-9 col-lg-9 col-xl-9 col-sm-6 col-6" style="padding-left: 5rem;">
          <form class="update-profile" *ngIf="user_data" name="profile" autocomplete="off" [formGroup]="formControl"
            (ngSubmit)="updateProfile(formControl.value)">
            <div class="">
              <div class="d-flex">
                <div class="col-md-4 form-group d-flex">

                  <div class="col-lg-4 col-md-6 col-sm-4 col-xs-4 pr-0 pl-0">
                    <label class="required ">{{ 'user-profile.Anrede_label' | translate }}</label>
                    <mat-select *ngIf="selectLang$ | async as selectLang" [(ngModel)]="user_data.salutation"
                      class="form-control salutation-select"
                      [disabled]="disable == true || disableCustomer == true ? 'disabled' : null"
                      formControlName="salutation" style="border: 1px solid #beb8b8c2;
                                border-radius: 1px !important;
                                bottom: -1px;
                                background-color: rgba(0,0,0,.04) !important;
                                height:39px !important">
                      <mat-option value="{{selectLang === 'German(DE)' ? 'Frau' : 'Mrs'}}">{{ 'update-user.Frau_text' | translate }} </mat-option>
                      <mat-option value="{{selectLang === 'German(DE)' ? 'Herr' : 'Mr'}}">{{ 'update-user.Herr_text' | translate }} </mat-option>
                    </mat-select>
                  </div>

                  <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 pl-0">
                    <label class="required  " style="margin-left: 12px !important;">{{
                      'update-user.Vorname_label' | translate }}</label>

                    <input formControlName="first_name" class="form-control firstname-select" style="margin-left: 12px !important;
                        font-size: 14px !important;
                        width: 101%;
                        border: 1px solid rgba(190,184,184,.76);
                        padding: 13px 7px;
                        border-radius: 1px;
                        background-color: rgba(0,0,0,.04);
                        height: 39px !important;"
                      [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null"
                      placeholder="{{'user-profile.Name_text' | translate}} " type="text" name="first_name"
                      [(ngModel)]="user_data.first_name" disabled />
                    <div *ngIf="form1.first_name.touched && form1.first_name.invalid" class="error"
                      style="margin-left: 12px;">
                      <div *ngIf="form1.first_name.errors.required">{{'user-profile.nameRequire_validation' |
                        translate}}</div>
                    </div>
                  </div>

                </div>
                <div class="col-lg-4 col-md-4 col-sm-8 col-xs-8  form-group">
                  <label class="required  ">{{ 'update-user.Nachname_label' | translate }}</label>
                  <input formControlName="last_name" class="form-control"
                    placeholder="{{ 'update-user.Nachname_label' | translate }} "
                    [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null" type="text"
                    name="last_name" [(ngModel)]="user_data.last_name" required />
                  <div *ngIf="form1.last_name.touched && form1.last_name.invalid" class="error">
                    <div *ngIf="form1.last_name.errors.required">{{'user-profile.LastnameRequire_validation' |
                      translate}}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group">
                  <label class="required ">{{ 'update-user.Email_label' | translate }}</label>
                  <input formControlName="email" class="form-control"
                    placeholder="{{'user-profile.E-MAIL_text' | translate}} "
                    [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null" type="email"
                    name="email" email="true" [(ngModel)]="user_data.email" required />
                  <div *ngIf="form1.email.touched && form1.email.invalid" class="error d-flex">
                    <div class="mr-2" *ngIf="form1.email.errors.required">{{'user-profile.emailRequire_validation' |
                      translate}}.
                    </div>
                    <div *ngIf="form1.email.invalid">{{'user-profile.emailInvalid_validation' | translate}}</div>
                  </div>
                </div>
              </div>


              <div class="d-flex">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 form-group" *ngIf="isICUser">
                  <label class="required">{{ 'update-user.Telefonhint_label' | translate }}</label>
                  <div style="display: grid;">
                    <ngx-mat-intl-tel-input style="position: unset; border-radius: 1px;
                          clear: both;
                          float: left;" formControlName="mobile_number" class="form-control"
                      placeholder="{{'user-profile.MOBILE_text' | translate}}"
                      [disabled]="disable == true ? 'disabled' : null" class="input telefon" required
                      [(ngModel)]="user_data.mobile_number" [preferredCountries]="['de']" [enableSearch]="true" #phone>
                    </ngx-mat-intl-tel-input>
                    <div *ngIf="form1.mobile_number.touched && form1.mobile_number.invalid" class="error" style="margin-left: 26% !important;
                      top: 66px;
                      position: absolute;">
                      <div *ngIf="form1.mobile_number.errors.required">
                        {{'user-profile.handynummerRequire_validation'
                        | translate}}</div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 form-group" *ngIf="!isICUser">
                  <label>{{ 'update-user.Telefonhint_label' | translate }}</label>
                  <div style="display: grid;">
                    <ngx-mat-intl-tel-input style="position: unset; border-radius: 1px;
                          clear: both;
                          float: left;" formControlName="mobile_number" class="form-control"
                      placeholder="{{'user-profile.MOBILE_text' | translate}}" disabled class="input telefon"
                      [(ngModel)]="user_data.mobile_number" [preferredCountries]="['de']" [enableSearch]="true" #phone>
                    </ngx-mat-intl-tel-input>
                    <div *ngIf="form1.mobile_number.touched && form1.mobile_number.invalid" class="error" style="margin-left: 26% !important;
                     top: 66px;
                     position: absolute;">
                    </div>
                  </div>
                </div>

                <div class="col-md-4 form-group">
                  <label class="required ">{{ 'update-user.Straße_label' | translate }}</label>
                  <input formControlName="street" class="form-control"
                    placeholder="{{'user-profile.Straße_text' | translate}} " type="text" name="street"
                    [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null"
                    [(ngModel)]="user_data.street" />
                  <div *ngIf="form1.street.touched && form1.street.invalid" class="error">
                    <div *ngIf="form1.street.errors.required">{{'user-profile.straßeRequire_validation' |
                      translate}}
                    </div>
                  </div>
                </div>
                <div class="col-md-4 form-group">
                  <label class="required  ">{{ 'update-user.Hausnummer_label' | translate }}</label>
                  <input formControlName="house" class="form-control" class="form-control"
                    placeholder="{{'user-profile.Haus_text' | translate}} " type="text" name="house"
                    [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null"
                    [(ngModel)]="user_data.house" />
                  <div *ngIf="form1.house.touched && form1.house.invalid" class="error">
                    <div *ngIf="form1.house.errors.required">{{'user-profile.hausnummerRequire_validation' |
                      translate}}</div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-md-4 form-group">
                  <label class=" required">{{'user-profile.Ort_text' | translate}}</label>
                  <input formControlName="city" class="form-control"
                    placeholder="{{'user-profile.Ort_text' | translate}} " type="text" name="city"
                    [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null"
                    [(ngModel)]="user_data.city" />
                  <div *ngIf="form1.city.touched && form1.city.invalid" class="error">
                    <div *ngIf="form1.city.errors.required">{{'user-profile.ortRequire_validation' | translate}}
                    </div>
                  </div>
                </div>

                <div class="col-md-4 form-group">
                  <label class="required">{{ 'update-user.Postleitzahl_label' | translate }}</label>
                  <input formControlName="zip_code" class="form-control"
                    placeholder="{{'user-profile.Postleitzahl_text' | translate}} " type="text" name="zip_code"
                    [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null"
                    [(ngModel)]="user_data.zip_code" />
                  <div *ngIf="form1.zip_code.errors?.pattern" class="error">
                    {{'user-profile.postleitzahlRequire_validation' | translate}}</div>
                  <div *ngIf="form1.zip_code.touched && form1.zip_code.invalid" class="error">
                    <div *ngIf="form1.zip_code.errors.required">{{'user-profile.postleitzahlRequire_validation' |
                      translate}}</div>
                  </div>
                </div>

                <div class="col-md-4 form-group">
                  <label>{{ 'update-user.Land_label' | translate }}</label>
                  <select (change)="onCountrySelected($event)" class="form-control"
                    [attr.disabled]="disable == true || disableCustomer == true ? 'disabled' : null"
                    formControlName="country" name="country" [(ngModel)]="user_data.country">
                    <option value="null" class="country" style="color: #f2f2f296;">{{'user-profile.Land_text' |
                      translate}} </option>
                    <option class="con-option" *ngFor="let country of countries$ | async" [value]="country.countryName">
                      {{country.countryName}}
                    </option>
                  </select>
                  <div *ngIf="form1.country.touched && form1.country.invalid" class="error">
                    <div *ngIf="form1.country.errors.required">{{'user-profile.landRequire_validation' |
                      translate}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="col-md-4 form-group">
                  <label class=" required">{{'user-profile.Sprache_text' | translate}}</label>
                  <mat-select #langSelect (selectionChange)="setTransLanguage($event)"
                    class="form-control languageSelect" formControlName="language" name="language"
                    [(ngModel)]="user_data.language">
                    <mat-option value="null" class="country" style="color: #f2f2f296;">{{'user-profile.Sprache_text' |
                      translate}} </mat-option>
                    <mat-option *ngFor="let language of supportedLanguages" [value]="language">{{language}}</mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="d-flex">
              </div>
              <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12 profile-button">
                <div class="row">
                  <div class="col-md-3 col-lg-3 col-xl-3" style="flex: 0 0 29%;"></div>
                  <button style="margin-top:10px;padding: 0px 22px 0px 22px; outline: none;
                        border-radius: 2px;max-width: 19%;" class="col-md-3 col-lg-3 col-xl-3 col-sm-6 mr-3"
                    type="button" *ngIf="disable == false"
                    (click)="resetPassword(content)">{{'user-profile.Passwort_text' |
                    translate}} </button>
                  <button style="margin-top:10px;padding: 5px 0px; outline: none;
                        border-radius: 2px;max-width: 10.666667%;" class="col-md-2 col-lg-2 col-xl-2 col-sm-6"
                    *ngIf="disable == false" [disabled]="!formControl.valid" type="submit">{{'user-profile.SICHERN_text'
                    | translate}}
                  </button>
                  <button style="margin-top:10px;padding: 5px 0px; outline: none;
                        border-radius: 2px;max-width: 10.666667%;margin-left: 7rem;"
                    class="col-md-6 col-lg-6 col-xl-6 col-sm-6" *ngIf="disable == true"
                    type="submit">{{'user-profile.SICHERN_text' | translate}}
                  </button>
                  <div class="col-md-4 col-lg-4 col-xl-4"></div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class=" col-lg-1 col-xl-1"></div>
      </div>
    </div>
  </div>
  

  <app-present bottom="50px" left="50px" box></app-present>
</div>

<ng-template #content let-c="close" let-d="dismiss" class="user-profile">
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">{{'user-profile.PasswortZurücksetzen_text' | translate}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click'); clearvalidation();">
      <mat-icon>
        <img alt="" style="vertical-align: sub;" src="../../../../assets/images/Group 3298.svg">
      </mat-icon>
    </button>
  </div>
  <div class="modal-body" *ngIf="resetForm">
    <p *ngIf="snack2" id="snackbar2" class="mb-0"><img alt="" src="../../../assets/images/checked.png">
      {{'user-profile.passwortErfolgreich_info' | translate}}</p>
    <p *ngIf="snack3" id="snackbar3" class="mb-0"><img alt="" src="../../../assets/images/Icon material-error.png">
      {{'user-profile.passwortErfolgreich_error' | translate}}</p>
    <form class="update-profile" name="password" [formGroup]="formControl2"
      (ngSubmit)="updatePassword(formControl2.value)">
      <div class="form">
        <div class="form-group">
          <label class=" ">{{'user-profile.AktuellesPasswort_text' | translate}}</label>
          <input formControlName="current" class="form-control"
            placeholder="{{'user-profile.AktuellesPasswort_text' | translate}}"
            [type]="currentTextType ? 'text' : 'password'" name="name" />
          <span (click)="currentPsw()">
            <img alt="" *ngIf="!currentTextType" class="pswIconStyle" src="../../../assets/images/hide_password.svg">
            <img alt="" *ngIf="currentTextType" class="pswIconStyle" src="../../../assets/images/Show-hide.svg">
          </span>
          <div *ngIf="f.current.touched && f.current.invalid" class="error">
            <div style="margin-top: -25px;" *ngIf="f.current.errors.required">
              {{'user-profile.aktuellePasswortRequire_validation' |
              translate}}</div>
          </div>
        </div>
        <div class="form-group">
          <label class=" ">{{'user-profile.NeuesPasswort_text' | translate}}</label>
          <input formControlName="new" class="form-control"
            placeholder="{{'user-profile.NeuesPasswort_text' | translate}}" [type]="newTextType ? 'text' : 'password'"
            name="name" />
          <span (click)="newPsw()">
            <img alt="" *ngIf="!newTextType" class="pswIconStyle" src="../../../assets/images/hide_password.svg">
            <img alt="" *ngIf="newTextType" class="pswIconStyle" src="../../../assets/images/Show-hide.svg">
          </span>
          <div *ngIf="f.new.touched && f.new.invalid" class="error">
            <div style="margin-top: -25px;" *ngIf="f.new.errors.required">
              {{'user-profile.neuesPasswortRequire_validation' | translate}}
            </div>
            <div *ngIf="f.new.errors.pattern"></div>
          </div>
        </div>
        <div class="form-group">
          <label class=" ">{{'user-profile.PasswortBestätigen_text' | translate}}</label>
          <input formControlName="confirm" class="form-control confirmPsw"
            placeholder="{{'user-profile.PasswortBestätigen_text' | translate}}"
            [type]="confirmTextType ? 'text' : 'password'" name="name" />
          <span (click)="confirmPsw()">
            <img alt="" *ngIf="!confirmTextType" class="pswIconStyle" src="../../../assets/images/hide_password.svg">
            <img alt="" *ngIf="confirmTextType" class="pswIconStyle" src="../../../assets/images/Show-hide.svg">
          </span>
          <div *ngIf="f.confirm.touched && f.confirm.invalid" class="error">
            <div style="margin-top: -25px;" *ngIf="f.confirm.errors.required">{{'user-profile.NeuesPasswort_validation'
              | translate}} </div>
            <div style="margin-top: -25px;" *ngIf="f.confirm.errors.confirmedValidator">
              {{'user-profile.dieNeuenRequire_validation' | translate}} </div>
          </div>
        </div>
        <div class="profile-button text-center">
          <button class="ml-auto mr-auto" [disabled]="!formControl2.valid" type="submit" style="padding: 4px 22px 4px 22px;
            border-radius: 2px;">{{'user-profile.KennwortAktualisieren_text' |
            translate}}</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>