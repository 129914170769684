import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { NewSparteGroupComponent } from './new-sparte-group/new-sparte-group.component';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
export interface SparteTableElement {
  id: string;
  groupName: string;
  is_create: any;
  is_delete: any;
  is_read: any;
  is_removable: any;
  division_id: any;
  sparte: any;
  is_update: any;
  owner_role: any;
  div_name: string;
}
export interface sparteRequestElement {
  sparte_group_id: any; // send sparte_group_id null when adding new sparte_group
  sparte_group_name: string;
  sparte: any;
  division_id: any;
  parent_id: any;
  is_removable: any;
  creator_id: any;
  role: any;
  fromWhich: string;
  smData: any;
}
@Component({
  selector: 'app-sparte-management',
  templateUrl: './sparte-management.component.html',
  styleUrls: ['./sparte-management.component.css'],
})
export class SparteManagementComponent extends BaseComponent implements OnInit {
  sampleData: { id: string; group: string }[];
  showModal = false;
  deleteItemId: any;
  division_id;
  user_role: USER_ROLES;
  parent_id: any;
  sparteGroupData: any;
  user_id: any;
  sparteNumber: any;
  formFilter: any;
  customerFilterToggle = false;
  divisions: any;
  spartes: any;
  reset = false;
  groupEditOrDelete = false;
  formIcons = FORM_ICONS;
  displayedColumns: string[] = ['Division', 'GroupName', 'ACTION'];
  SmData = [];
  GroupDataOBJ = {
    sparte_group_id: '', // send sparte_group_id null when adding new sparte_group
    sparte_group_name: '',
    sparte: [],
    division_id: '',
    parent_id: '',
    is_removable: '',
    creator_id: '',
    role: '',
    div_name: '',
  };
  userDataValue: LoggedInUser;
  dataSource = new MatTableDataSource();
  isNewGroupBtn = false;
  FORM_ICONS = FORM_ICONS;
  constructor(
    private serviceCall: ApiCallsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public validationService: ValidationService,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private readonly _userService: UserService
  ) {
    super();
    this.formFilter = this.fb.group({
      division_id: [''],
      sparte_id: [''],
    });
  }

  ngOnInit(): void {
    this._watchOnUser();
    this.formFilter.reset();
  }
  getSparteGrops() {
    this.division_id =
      this.userDataValue.user_profile.customer_details.division_id;
    this.parent_id = this.userDataValue.user_profile.customer_details.parent_id;
    this._userService.saveDivisionId(this.division_id);
    let sparte_id =
      this.spartes !== null || this.spartes !== undefined
        ? this.spartes.find(data => data.description == 'Alle').sparte_id
        : 67;
    let division_id = this.division_id;
    if (this.groupEditOrDelete) {
      sparte_id = this.reset ? sparte_id : this.formFilter.value.sparte_id;
      division_id = this.formFilter.value.division_id;
    }
    this.serviceCall
      .getSparteGroup(division_id, this.parent_id, this.user_role, sparte_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.sparteGroupData = data.sparte_groups;
        if (this.sparteGroupData !== null) {
          this.formatSparteGroupsData();
        } else {
          this.dataSource.data = [];
        }
      });
  }
  formatSparteGroupsData() {
    this.SmData = [];
    for (let i = 0; i < this.sparteGroupData.length; i++) {
      const sparteData: SparteTableElement = {
        id: this.sparteGroupData[i].sparte_group_id,
        groupName: this.sparteGroupData[i].sparte_group_name,
        is_create: this.sparteGroupData[i].create,
        is_removable: this.sparteGroupData[i].is_removable,
        is_delete: this.sparteGroupData[i].delete,
        is_read: this.sparteGroupData[i].read,
        division_id: this.sparteGroupData[i].division_id,
        sparte: this.sparteGroupData[i].sparte,
        is_update: this.sparteGroupData[i].update,
        owner_role: this.sparteGroupData[i].owner_role,
        div_name: this.sparteGroupData[i].div_name,
      };
      this.SmData.push(sparteData);
    }
    this.dataSource.data = this.SmData;
  }
  editProcess(id) {
    for (let i = 0; i < this.SmData.length; i++) {
      if (this.SmData[i].id == id) {
        const sparteData: sparteRequestElement = {
          sparte_group_id: this.SmData[i].id, // send sparte_group_id null when adding new sparte_group
          sparte_group_name: this.SmData[i].groupName,
          sparte: this.SmData[i].sparte,
          division_id: this.SmData[i].division_id,
          parent_id: this.parent_id,
          is_removable: this.SmData[i].is_removable,
          creator_id: this.user_id,
          role: this.SmData[i].owner_role,
          fromWhich: 'edit',
          smData: this.SmData,
        };
        this.dialog
          .open(NewSparteGroupComponent, {
            panelClass: 'sparteDialog',
            width: '800px',
            disableClose: true,
            data: sparteData,
          })
          .afterClosed()
          .subscribe(s => {
            if (s) {
              if (
                s.sparte_group_name == this.SmData[i].groupName &&
                s.sparte.length == 0
              ) {
              } else {
                const formData = this.formatNewData(s);
                this.serviceCall
                  .post_sparte_group(formData)
                  .pipe(takeUntil(this._destroy$))
                  .subscribe(() => {
                    this.groupEditOrDelete = true;
                    this.getSparteGrops();
                  });
              }
            }
          });
      }
    }
  }
  formatNewData(s: any) {
    this.GroupDataOBJ.sparte_group_id = s.sparte_group_id; // send sparte_group_id null when adding new sparte_group
    this.GroupDataOBJ.sparte_group_name = s.sparte_group_name;
    this.GroupDataOBJ.sparte = s.sparte;
    this.GroupDataOBJ.division_id = s.division_id;
    this.GroupDataOBJ.parent_id = s.parent_id;
    this.GroupDataOBJ.is_removable = s.is_removable;
    this.GroupDataOBJ.creator_id = s.creator_id;
    this.GroupDataOBJ.role = s.role;
    return this.GroupDataOBJ;
  }
  deleteProcessButton(id) {
    this.deleteItemId = id;
    for (let i = 0; i < this.SmData.length; i++) {
      if (this.SmData[i].id == this.deleteItemId) {
        this.showModal = true;
        this.sparteNumber = this.SmData[i].sparte.length;
      }
    }
  }
  deleteGroup(id) {
    this.showModal = false;
    this.serviceCall
      .deleteSparteGroup(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.HasErrors == false) {
          this._toastService.showToastMessage(
            document,
            this._translateService.instant('settings.deleteGroupText')
          );
          this.groupEditOrDelete = true;
          this.getSparteGrops();
        }
      });
  }
  addNewSparte() {
    const sparteData: sparteRequestElement = {
      sparte_group_id: '', // send sparte_group_id null when adding new sparte_group
      sparte_group_name: '',
      sparte: [],
      division_id: this._userService.getDivisionId(),
      parent_id: this.parent_id,
      is_removable: '',
      creator_id: this.user_id,
      role: this.user_role,
      fromWhich: 'add',
      smData: this.SmData,
    };
    this.dialog
      .open(NewSparteGroupComponent, {
        panelClass: 'sparteDialog',
        width: '800px',
        disableClose: true,
        data: sparteData,
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(s => {
        if (s) {
          this.serviceCall
            .post_sparte_group(s)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              if (data.HasErrors == false) {
                this.getSparteGrops();
              }
            });
        }
      });
  }
  hideModal() {
    this.showModal = false;
  }
  resetFilters() {
    this.reset = true;
    this.formFilter
      .get('division_id')
      .setValue(this.userDataValue.user_profile.customer_details.division_id);
    this.formFilter
      .get('sparte_id')
      .setValue(
        this.spartes.find(data => data.description == 'Alle').sparte_id
      );
    this.filterSparte();
  }
  filterSparte() {
    let sparte_id = null;
    if (this.reset) {
      sparte_id =
        null !== this.spartes || undefined !== this.spartes
          ? this.spartes.find(data => data.description == 'Alle').sparte_id
          : 67;
    } else {
      sparte_id = this.formFilter.value.sparte_id;
    }
    const division_id = this.formFilter.value.division_id;
    this.serviceCall
      .getSparteGroup(division_id, this.parent_id, this.user_role, sparte_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.sparteGroupData = data.sparte_groups;
        if (this.sparteGroupData !== null) {
          this.formatSparteGroupsData();
        } else {
          this.dataSource.data = [];
        }
        this.reset = false;
      });
  }

  private _getDivision(): void {
    this.serviceCall
      .getDivision(this.user_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.formFilter
            .get('division_id')
            .setValue(
              this.userDataValue.user_profile.customer_details.division_id
            );
          this.divisions = data.divisions;
        }
      });
  }

  private _getSparte(): void {
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.serviceCall
      .getSparte(
        this.userDataValue.user_profile.customer_details.division_id,
        null,
        this.user_role
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.formFilter
          .get('sparte_id')
          .setValue(
            data.sparte.find(data => data.description == 'Alle').sparte_id
          );
        this.spartes = data.sparte.filter(
          object => object.is_sparte_group == false
        );
        this.getSparteGrops();
      });
  }

  private _watchOnUser(): void {
    this._userService.loggedInUser$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(user => {
        this.userDataValue = user;
        this.user_id = user.user_profile.user_data.user_id;
        this.user_role = user.user_profile.user_data.role;
        this.isNewGroupBtn =
          this.user_role !== USER_ROLES.IC_USER &&
          this.user_role !== USER_ROLES.CUSTOMER_USER;
        this._getDivision();
        this._getSparte();
      });
  }
}
