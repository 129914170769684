import {
  CdkDrag,
  CdkDragDrop,
  CdkDragMove,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { v4 as uuid } from 'uuid';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
export interface ribbonData {
  id: any;
  label: any;
  type_id: any;
  sequence: any;
  validations?: [];
  is_mandatory: any;
  is_deleted?: boolean;
  placeholder: any;
  is_single?: boolean;
  is_currency?: boolean;
  radio?: any;
  checkbox?: any;
  is_new?: boolean;
  is_hidden?: boolean;
  is_readonly?: boolean;
  is_default_value?: any;
  default_value?: any;
}
export interface formData {
  created_by?: string;
  description: string;
  form_details_jsonb: any;
  form_details_id: any;
  is_active?: any;
  is_in_use?: any;
  name: string;
  removed_fields: any;
  updated_by?: string;
  created_by_role_id: any;
}
@Component({
  selector: 'app-add-new-form',
  templateUrl: './add-new-form.component.html',
  styleUrls: ['./add-new-form.component.css'],
})
export class AddNewFormComponent extends BaseComponent implements OnInit {
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  @Input('formId') formId;
  @Input('createdBy') createdByRole;
  @Output('discardChanges') discardChanges = new EventEmitter<any>();
  @Output('snackbar_message') snackbar_message = new EventEmitter<any>();
  ribbonData: any;
  formFields = [];
  deletedItem = [];
  draggedData = [];
  public activeContainer;
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  formName = new FormControl();
  formDesc = new FormControl();
  isFormInUse: any;
  removed_fields = [];
  form_dirty = false;
  readonly selectLang$ = this._userService.locale$;
  is_disable = false;
  IC_ROLES = [USER_ROLES.IC_BRANCH_ADMIN, USER_ROLES.IC_GLOBAL_ADMIN];
  user_role: USER_ROLES;
  createdUpdateString = false;
  updatedBy: any;
  updatedAt: any;
  createdAt: any;
  createdBy: any;
  showConfirmPopup = false;
  public dataToBePassesToDialog;
  isICUserRole: boolean;

  constructor(
    private serviceCall: ApiCallsService,
    private viewportRuler: ViewportRuler,
    private readonly _userService: UserService
  ) {
    super();
    this.target = null;
    this.source = null;
  }

  ngOnInit(): void {
    this.apiCalls();
    this.user_role = this._userService.getUserRole();
    this.is_disable =
      this.IC_ROLES.includes(this.createdByRole) &&
      this.user_role == USER_ROLES.IC_USER;
    this.isICUserRole = this.user_role == USER_ROLES.IC_USER;
    if (this.is_disable) {
      this.formDesc.disable();
      this.formName.disable();
    }
    this.formDesc.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (this.formName.value && value !== '' && this.formFields.length > 0) {
          this.form_dirty = true;
        }
        this.checkFormDirty();
      });
    this.formName.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (this.formDesc.value && value !== '' && this.formFields.length > 0) {
          this.form_dirty = true;
        }
        this.checkFormDirty();
      });
  }
  ngAfterViewInit() {
    const phElement = this.placeholder?.element.nativeElement;
    if (phElement) {
      phElement.style.display = 'none';
    }
    phElement?.parentElement.removeChild(phElement);
  }

  checkFormDirty(): void {
    if (
      !this.formName.value &&
      !this.formDesc.value &&
      !this.formFields.length
    ) {
      this.form_dirty = false;
    } else {
      this.form_dirty = true;
    }
  }

  apiCalls() {
    this.serviceCall
      .getFormFieldTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.ribbonData = data.data;
        this.ribbonData.forEach((element, index) => {
          if (element.id == 6) {
            this.ribbonData.splice(index, 1);
          }
        });
      });
    if (this.formId) {
      this.createdUpdateString = true;
      this.serviceCall
        .getFormDataById(this.formId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(formData => {
          this.formName.setValue(formData.form_details.name);
          this.formDesc.setValue(formData.form_details.description);
          this.isFormInUse = formData.form_details.is_in_use;
          if (this.isFormInUse) {
            this.formDesc.disable();
            this.formName.disable();
          }
          this.createdBy = formData.form_details.created_by;
          this.createdAt = formData.form_details.created_at;
          this.updatedBy = formData.form_details.updated_by;
          this.updatedAt = formData.form_details.updated_at;
          const parsedData = JSON.parse(
            formData.form_details.form_detail_jsonb
          );
          if (parsedData) {
            this.formFields.push(...parsedData);
            this.formFields.sort((a, b) => a.sequence - b.sequence);
          }
        });
    }
  }
  ribbonSelected(ribbon) {
    const item: ribbonData = {
      id: uuid(),
      label: ribbon.name,
      type_id: ribbon.id,
      sequence: this.formFields.length + 1,
      is_mandatory: false,
      placeholder: '',
      is_deleted: false,
      is_new: true,
      is_hidden: false,
      is_readonly: false,
      is_default_value: false,
      default_value: '',
    };
    if (ribbon.id == 1) {
      item.is_currency = false;
    }
    if (ribbon.id == 12) {
      item.is_single = true;
    }
    if (ribbon.id == 5) {
      const radio = {
        first: '',
        second: '',
      };
      item.radio = radio;
    }
    if (ribbon.id == 9) {
      const checkbox = {
        label: '',
      };
      item.checkbox = checkbox;
    }
    this.formFields.push(item);
    this.checkFormDirty();
  }
  deleteField(event) {
    this.deletedItem.push(event);
    this.formFields.filter((item, index) => {
      if (item.id == event) {
        this.formFields.splice(index, 1);
      }
    });
    this.checkFormDirty();
  }
  drop(event: CdkDragDrop<string[]>) {
    this.formFields = this.array_move(
      this.formFields,
      event.previousIndex,
      event.currentIndex
    );
  }
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  saveForm() {
    const userId = this._userService.getUserId();
    const userData = this._userService.getLoggedInUser();
    const userRoleId = userData.user_profile.user_data.user_role_id;
    this.formFields.forEach(item => delete item.is_new);
    const requestToSend: formData = {
      created_by: userId,
      description: this.formDesc.value,
      form_details_jsonb: JSON.stringify(this.formFields),
      form_details_id: this.formId ? this.formId : null,
      name: this.formName.value,
      removed_fields:
        this.removed_fields.length > 0 ? this.removed_fields : null,
      updated_by: this.formId ? userId : null,
      created_by_role_id: userRoleId,
    };
    this.serviceCall
      .saveFormDetails(requestToSend)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.snackbar_message.emit(data.message);
        if (!data.HasErrors) {
          this.discardChanges.emit(true);
        }
      });
  }

  close(): void {
    this.snackbar_message.emit(null);
    this.form_dirty ? this.openConfirmPopup() : this.saveConfirm();
  }

  dragMoved(e: CdkDragMove) {
    const point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }
  dropListDropped() {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(this.formFields, this.sourceIndex, this.targetIndex);
    }
    this.formFields.forEach((item, index) => {
      item.sequence = index + 1;
    });
  }
  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder) {
      return true;
    }

    if (drop != this.activeContainer) {
      return false;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(
      dropElement.parentElement.children,
      this.source ? phElement : sourceElement
    );
    const dropIndex = __indexOf(
      dropElement.parentElement.children,
      dropElement
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder._dropListRef.enter(
      drag._dragRef,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop
    );
    return false;
  };
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event)
      ? event.touches[0] || event.changedTouches[0]
      : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top,
    };
  }
  saveField(event) {
    this.formFields.filter((item, index) => {
      if (item.id == event.id) {
        this.formateData(event.json, event.type_id, index);
      }
    });
  }

  formateData(json, type_id, index) {
    this.formFields[index].label = json.label;
    this.formFields[index].placeholder = json.placeholder;
    this.formFields[index].is_mandatory = json.is_mandatory;
    this.formFields[index].is_hidden = json.is_hidden;
    this.formFields[index].is_readonly = json.is_readonly;
    this.formFields[index].is_default_value = json.is_default_value;
    this.formFields[index].default_value = json.default_value;
    if (type_id == 1) {
      this.formFields[index].is_currency = json.is_currency;
    }
    if (type_id == 5) {
      this.formFields[index].radio.first = json.radio_label_first;
      this.formFields[index].radio.second = json.radio_label_second;
    }
    if (type_id == 9) {
      this.formFields[index].checkbox.label = json.checkbox_label;
    }
    if (type_id == 12) {
      this.formFields[index].is_single =
        json.is_single == 'true' || json.is_single == true ? true : false;
    }
  }
  removeFields(event) {
    this.removed_fields.push(event);
  }
  formDirty(event) {
    if (event) {
      if (this.formName.value && this.formDesc.value) {
        this.form_dirty = true;
      }
    }
  }
  openConfirmPopup() {
    this.showConfirmPopup = true;
  }
  closeConfirmPopup() {
    this.showConfirmPopup = false;
  }
  saveConfirm() {
    this.discardChanges.emit(true);
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(
  dropList: CdkDropList,
  x: number,
  y: number
) {
  const { top, bottom, left, right } =
    dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
