import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NewClaimsRoutingModule } from './new-claims-routing.module';
import { NewClaimsComponent } from './new-claims.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '@shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GeneralClaimDataComponent } from './general-claim-data/general-claim-data.component';
import { ContactPersonComponent } from './claim-contact-person-page/contact-person/contact-person.component';
import { PaymentDetailsComponent } from './claim-payment-details-page/payment-details/payment-details.component';
import { AddContactPersonComponent } from './claim-contact-person-page/add-contact-person/add-contact-person.component';
import { DamageDetailsComponent } from './damage-details/damage-details.component';
import { ValidationSharedModule } from '@shared/validation-shared.module';
import { AddPaymentDetailsComponent } from './claim-payment-details-page/add-payment-details/add-payment-details.component';
import { ClaimsShareService } from './claims-share.service';
import { ClaimDocumentsPageComponent } from './claim-documents-page/claim-documents-page.component';
import { ClaimAppointmentsPageComponent } from './claim-appointments-page/claim-appointments-page.component';
import { ClaimPaymentDetailsPageComponent } from './claim-payment-details-page/claim-payment-details-page.component';
import { ClaimContactPersonPageComponent } from './claim-contact-person-page/claim-contact-person-page.component';
@NgModule({
  declarations: [
    NewClaimsComponent,
    GeneralClaimDataComponent,
    ContactPersonComponent,
    PaymentDetailsComponent,
    AddContactPersonComponent,
    DamageDetailsComponent,
    AddPaymentDetailsComponent,
    ClaimDocumentsPageComponent,
    ClaimAppointmentsPageComponent,
    ClaimPaymentDetailsPageComponent,
    ClaimContactPersonPageComponent
  ],
  imports: [
    NgxMatIntlTelInputModule,
    NewClaimsRoutingModule,
    TranslateModule.forChild(),
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatMenuModule,
    MatPaginatorModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    TranslateModule,
    NgbModule,
    MatButtonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    InfiniteScrollModule,
    NgxLoadingModule,
    PdfViewerModule,
    ValidationSharedModule,
  ],
  providers: [ClaimsShareService],
})
export class NewClaimsModule {}
