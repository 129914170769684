export interface Avatar {
  src: string;
  name: string;
}

export const measureUnit = 8;

export enum AvatarSizeEnum {
  xxs = measureUnit * 3,
  xs = measureUnit * 4,
  sm = measureUnit * 5,
  md = measureUnit * 6,
  lg = measureUnit * 8,
  xl = measureUnit * 9
}
