<div class="filter-section__wrapper">
  <mat-accordion>
    <mat-expansion-panel
      class="filter-section" 
      (opened)="openAccordion()" 
      (closed)="closeAccordion()"
      [expanded]="isOpened"
      hideToggle>
      <mat-expansion-panel-header
        [expandedHeight]="expandedHeight"
        class="filter-section-header p-unset">
        <mat-panel-title class="filter-section-title-alignment filter-title">
          <div class="flex-center g-7">
            <button class="action-btn">
              <mat-icon *ngIf="isOpened" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="!isOpened" class="arrowButtons va-bottom">keyboard_arrow_right
              </mat-icon>
            </button>
            <span>
              <strong>{{ 'filter_section.filter' | translate | uppercase }}</strong>
            </span>
          </div>
          <div class="paginator-dropdown-container" (click)="$event.stopPropagation()">
            <mat-select
              class="paginator-dropdown filter-section-dropdown"
              [formControl]="pageSizeControl"
              disableOptionCentering>
              <mat-option *ngFor="let row of numberOfRows" [value]="row">
                {{row}}
              </mat-option>
            </mat-select>
            <span class="results-per-page">
              {{'filter_section.results_per_page' | translate}}
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="filterForm">
        <div class="filter-section__content">
          <div class="filter-section__controls flex-center">
            <ng-container *ngFor="let filter of filters">
              <div *ngIf="filter.type === filterTypes.INPUT && !filter.hidden" class="filter-section__control-wrapper">
                <mat-label *ngIf="filter.label">{{filter.label}}</mat-label>
                <input
                  matInput
                  type="text"
                  class="filter-section__control filter-section__input"
                  [formControlName]="filter.name"
                  [placeholder]="filter.placeholder"/>
              </div>

              <div *ngIf="filter.type === filterTypes.SELECT && !filter.hidden" class="filter-section__control-wrapper">
                <mat-label *ngIf="filter.label">{{filter.label}}</mat-label>
                <mat-select
                  (selectionChange)="resetSearch()"
                  (openedChange)="resetSearch()"
                  [formControlName]="filter.name"
                  class="filter-section__control filter-section__select"
                  [placeholder]="filter.placeholder"
                  [multiple]="filter.isMultiple">
                  <mat-select-trigger *ngIf="filter.valueComponent && filterForm.get(filter.name)?.value">
                    <ng-container dynamicCell [component]="filter.valueComponent" [inputs]="{ option: getSelectedOption(filter.name, filterForm.get(filter.name).value) }"></ng-container>
                  </mat-select-trigger>
                  <input 
                    type="text" 
                    maxlength="20"
                    class="searchSelect"
                    preventSpace
                    [placeholder]="'header.Suche_tooltip' | translate"
                    [formControl]="searchControl">
                  <mat-option *ngFor="let option of filter.options | filterOptions:searchControl.value" [value]="option.value">
                    <ng-container *ngIf="filter.optionComponent; else label">
                      <ng-container dynamicCell [component]="filter.optionComponent" [inputs]="{ option: option }"></ng-container>
                    </ng-container>
                    <ng-template #label>
                      {{option.label}}
                    </ng-template>
                  </mat-option>
                </mat-select>
              </div>

              <div *ngIf="filter.type === filterTypes.DATE_RANGE && !filter.hidden" class="filter-section__control-wrapper">
                <mat-label *ngIf="filter.label">{{filter.label}}</mat-label>
                <div class="filter-section__control filter-section__date-range">
                  <mat-date-range-input
                    [formGroupName]="filter.name"
                    [rangePicker]="picker">
                    <input (focus)="picker.open()" matStartDate formControlName="start" placeholder="Start date">
                    <input (focus)="picker.open()" matEndDate formControlName="end" placeholder="End date">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="filter-section__actions">
            <app-button (click)="filter()" [disabled]="filterDisabled">
              {{'filter_section.filter' | translate}}
            </app-button>
            <app-button
              (click)="reset()"
              icon
              matTooltip="{{'new-document.Filter_löschen' | translate}}">
              <img class="filter-section__reset-img" alt="" src="assets/images/filter-section/refresh.png">
            </app-button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>