<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div (emitUserData)="userDataReceived($event)" class="container-ic users">
    <div class="flex-common mb-2">
      <app-breadcrumbs [isCustomerUser]="isCustomerUser" [isCustomerSelected]="isCustomerSelected"
        [pageBreadcrumbText]="pageBreadcrumbText" [divisionName]="divisionName" [customerName]="customerName$ | async">
      </app-breadcrumbs>
    </div>
    <!-- complete Masterdata section -->
    <mat-accordion *ngIf="isCustomerAdmin">
      <mat-expansion-panel (opened)="masterDataToggle = true" (closed)="masterDataToggle = false"
        [expanded]="masterDataToggle" hideToggle style="margin-bottom: 15px !important;">
        <mat-expansion-panel-header #masterData (click)="masterData._toggle()" [expandedHeight]="customExpandedHeight"
          class="border-bottom">
          <mat-panel-title>
            <button mat-icon-button *ngIf="masterDataToggle == true" class="arrow-size" (click)="masterData._toggle()">
              <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
            </button>
            <button mat-icon-button *ngIf="masterDataToggle == false" class="arrow-size" (click)="masterData._toggle()">
              <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
            </button>
            <span style="margin-top: 10px;font-weight: 600;">
              <div class="d-flex">
                <div>
                  <h6 class="title-heading">&nbsp;
                    {{'master-data.Masterdata_label' | translate}}
                  </h6>
                </div>
              </div>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Main table inside MasterData -->
        <table class="main-table" id="masterTable">
          <thead>
            <tr class="inner-table-row">
              <th style="height: 41px;">
                <div class="row" style="width: 100%;">
                  <div class="col-md-5 col-sm-5 col-5">
                    <label style="cursor: pointer; font-weight: 700;" class="listLabel">
                      {{'user-data.customer_uppercase'| translate}}
                    </label>
                  </div>
                  <div class="col-md-4 col-sm-4 col-4">
                    <label style="cursor: pointer; font-weight: 700;" class="listLabel">
                      {{'user-data.customer_type_uppercase'| translate}}
                    </label>
                  </div>
                  <div class="col-md-3 col-sm-3 col-3">
                    <label style="cursor: pointer; float: right; margin-right:12px; font-weight: 700;"
                      class="listLabel">{{'master-data.ACTION_label' | translate}}</label>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let masterData of masterDataRows;index as ind">
              <td style="padding: 0px;">
                <mat-accordion id="master">
                  <mat-expansion-panel (opened)="masterData.isExpanded = true" (closed)="masterData.isExpanded = false"
                    [expanded]="masterData.isExpanded == true" hideToggle='true'
                    style="border-top: 1px solid #797f9652;">
                    <mat-expansion-panel-header #masterDataRow (click)="masterDataRow._toggle()">
                      <mat-panel-title #arrow style="width: 100%;">
                        <div class="col-md-5 col-sm-5 col-5" style="padding:0px;">
                          <button mat-icon-button *ngIf="masterData.isExpanded == true" class="arrow-size arrowIcon"
                            (click)="masterDataRow._toggle()">
                            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                          </button>
                          <button mat-icon-button *ngIf="masterData.isExpanded == false" class="arrow-size arrowIcon"
                            (click)="masterDataRow._toggle(); loadCustomerInformation(masterData)">
                            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                          </button>
                          <label *ngIf="isICUser" style="cursor: pointer; padding-left: 0px;color: #464646;
                    font-size: 13.2px;" (click)="masterDataRow._toggle(); loadCustomerInformation(masterData)"
                            [style.font-weight]="liztu == masterData.liztu_vn_kdnr ? 700 : 600"
                            class="listLabel ellipsis1 top-5" title="{{masterData.name}}">{{masterData.name}}</label>
                          <label *ngIf="isCustomerUser"
                            (click)="masterDataRow._toggle(); loadCustomerInformation(masterData)" style="cursor: pointer; padding-left: 0px;color: #464646;
                    font-size: 13.2px;" [style.font-weight]="(customerName$ | async) == masterData.name ? 700 : 600"
                            class="listLabel ellipsis1 top-5" title="{{masterData.name}}">{{masterData.name}}</label>
                        </div>
                        <div (click)="masterDataRow._toggle(); loadCustomerInformation(masterData)"
                          class="col-md-4 col-sm-4 col-4" style="padding: 0px;">
                          <label *ngIf="isICUser" style="cursor: pointer;color: #464646;
                      font-size: 13.2px;" [style.font-weight]="liztu == masterData.liztu_vn_kdnr ? 700 : 600"
                            class="listLabel top-5">{{masterData.type}}</label>
                          <label *ngIf="isCustomerUser" style="cursor: pointer;color: #464646;
                      font-size: 13.2px;" [style.font-weight]="(customerName$ | async) == masterData.name ? 700 : 600"
                            class="listLabel top-5">{{masterData.type}}</label>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3 top-5"
                          (click)="masterDataRow._toggle(); loadCustomerInformation(masterData)">
                          <div class="subscribe" style="position: initial;float: right;">
                            <button class="myDIV" id="content"
                              style="position: initial; margin-top: 0px;">&#183;&nbsp;&#183;&nbsp;&#183;</button>
                            <div class="hide democontent" style="position: initial;text-align: center;">
                              <button class="toolEdit" (click)="$event.stopPropagation(); masterDataEdit(masterData)"
                                mat-icon-button
                                style="margin-left:5px; background-color: #f0f0f0;border-radius: 3px;height: 27px;width: 25px; margin-right: 35px;"
                                *ngIf="!masterData.masterDataEdit">
                                <mat-icon style="vertical-align: initial !important;">
                                  <img alt="" style="height: 15px;margin: 3px auto;vertical-align: initial !important;"
                                    class="imgStyle" src='../../../assets/images/new-icons/edit-icon.png'>
                                </mat-icon>
                                <span class="tooltiptextEdit">
                                  <p style="margin-top: -11px;
                                  font-size: 11px;"> {{'user-data.BEARBEITEN_tooltip' | translate}}</p>
                                </span>
                              </button>
                              <button class="tool4" mat-icon-button
                                style="margin-left:5px; background-color: #f0f0f0;border-radius: 3px;height: 27px;width: 25px; margin-right: 35px;"
                                (click)="$event.stopPropagation(); saveMasterDate(masterData)"
                                *ngIf="masterData.masterDataEdit">
                                <mat-icon style="vertical-align: initial !important;">
                                  <img alt="" style="margin: 1px auto;vertical-align: initial !important;"
                                    class="imgStyle safari-style-img" src='../../../assets/images/save-button.svg'>
                                </mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </mat-panel-title>

                    </mat-expansion-panel-header>
                    <div *ngIf="masterData.isExpanded">
                      <div id="customerInformation" style="margin: 0px -24px;">
                        <div class="row customer-info">
                          <div class="col-md-9">
                            <p style="font-size: 16px; font-weight: 600;padding: 10px 19px;margin: 0px;">
                              {{'master-data.CustomerInformation_label' | translate}}</p>
                          </div>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label>
                              <span> {{'master-data.CustomerNumber_label' | translate}}</span>
                            </mat-label>
                            <input matInput placeholder="{{'master-data.CustomerNumber_label' | translate}}"
                              class="input" [(ngModel)]="masterData.customerInformation.customerNumber" disabled>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label style="color: #beb8b8c2;">
                              <span>{{'master-data.DunsNumber_label' | translate}}</span>
                            </mat-label>
                            <input matInput placeholder="{{'master-data.DunsNumber_label' | translate}}" class="input"
                              [(ngModel)]="masterData.customerInformation.dunsNumber" disabled
                              style="color: #beb8b8c2; font-weight: 600;">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label>
                              <span> {{'user-data.customer_name_small' | translate}}</span>
                            </mat-label>
                            <input matInput placeholder="{{'user-data.customer_name_small' | translate}}" class="input"
                              [(ngModel)]="masterData.customerInformation.customerName" disabled>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label>
                              <span>{{'master-data.ActivationFlag_label' | translate}}</span>
                            </mat-label>
                            <mat-select
                              placeholder="{{'master-data.ActivationFlag_label' | translate}}"
                              [(ngModel)]="selectLang == 'English(EN)' ?
                                masterData.customerInformation.activationFlag :
                                masterData.customerInformation.activationFlagGerman" disabled>
                              <mat-select-trigger>
                                <span>
                                  {{selectLang == 'English(EN)' ?
                                    masterData.customerInformation.activationFlag :
                                    masterData.customerInformation.activationFlagGerman}}
                                  </span>
                              </mat-select-trigger>
                              <mat-option *ngFor="let flag of flagByLocale" [value]="flag.name">
                                {{flag.name}} <span style="float: right;">{{flag.symbol}}</span>
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label>
                              <span> {{'master-data.Customergroup_label' | translate}}</span>
                            </mat-label>

                            <input matInput placeholder="{{'master-data.Customergroup_label' | translate}}"
                              class="input" [(ngModel)]="masterData.customerInformation.customerGroup" disabled>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label><span>{{'customer-management.general-data.role_of_customer' | translate}}</span></mat-label>
                            <mat-select placeholder="{{'customer-management.general-data.role_of_customer' | translate}}"
                              [(ngModel)]="masterData.customerInformation.roleOfCustomer" disabled>
                              <mat-option *ngFor="let roles of customerRoles" [value]="roles">{{roles}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label><span> {{'master-data.Classification_label' | translate}}</span></mat-label>
                            <mat-select
                              placeholder="{{'master-data.Classification_label' | translate}}"
                              [(ngModel)]="selectLang === 'English(EN)' ?
                                masterData.customerInformation.classification :
                                masterData.customerInformation.classificationGerman" disabled>
                              <mat-select-trigger>
                                <span>{{selectLang === 'English(EN)' ?
                                  masterData.customerInformation.classification :
                                  masterData.customerInformation.classificationGerman}}
                                </span>
                              </mat-select-trigger>
                              <mat-option *ngFor="let class of classificationFieldByLocale" value="{{class.name}}">
                                {{class.name}} <span style="float: right;"> {{class.symbol}}</span>
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">

                          <mat-form-field class="division-element">
                            <mat-label><span> {{'master-data.Homepage_label' | translate}}</span></mat-label>
                            <input matInput placeholder="{{'master-data.Homepage_label' | translate}}" class="input"
                              style="height: 16px;" [(ngModel)]="masterData.customerInformation.homePage"
                              [disabled]="disable || masterData.customerInformation.classification == 'P'">
                          </mat-form-field>
                        </div>

                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element">
                            <mat-label><span> {{'master-data.E-Mail_label' | translate}}</span></mat-label>
                            <input matInput placeholder="{{'master-data.E-Mail_label' | translate}}" class="input"
                              [(ngModel)]="masterData.customerInformation.email" [disabled]="disable">
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="division-element" appearance="fill" style="border: none;"
                            [ngClass]="{'input-enabled': masterData.masterDataEdit && !isCustomerAdmin}">
                            <mat-label><span>{{'master-data.Telephonenumber_label' | translate}}</span></mat-label>
                            <ngx-mat-intl-tel-input [ngClass]="'ngx-floating'"
                              (countryChanged)="CountryChangedEvent($event)" class="input floating"
                              [preferredCountries]="['de']" [enablePlaceholder]="true" name="tele" [enableSearch]="true"
                              #phone [disabled]="!masterData.masterDataEdit || isCustomerAdmin"
                              [(ngModel)]="masterData.customerInformation.telephone_number" style="height: 39px;">
                            </ngx-mat-intl-tel-input>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="d-flex address-field" id="addressFlag" appearance="fill"
                            class="division-element" [ngClass]="{'input-enabled': masterData.masterDataEdit === true}">
                            <mat-label>{{'master-data.Addresse_label' | translate}}</mat-label>

                            <input type="text" matInput name="location-list" #loc id="location"
                              (focus)="showList = true" (blur)="onFocusOut($event)" (keyup)="keyUp.next($event)"
                              [(ngModel)]="masterData.customerInformation.address || selectedLoc" class="input" required
                              autocomplete="off" [disabled]="!masterData.masterDataEdit">

                            <ul class="statusFilterList" *ngIf="showList && filterlocation.length">
                              <li class="listStyle" *ngFor="let location of filterlocation;index as i"
                                (click)="selectedLocation(i,masterData)">
                                {{location.freeformAddress}}
                              </li>
                            </ul>

                            <span matSuffix class="flagIconContainer"
                              *ngIf="masterData.customerInformation.customer_address_lat_lon_set == false">
                              <img alt="" class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg"></span>
                            <span matSuffix class="flagIconContainer"
                              *ngIf="masterData.customerInformation.customer_address_lat_lon_set == true">
                              <img alt="" class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg"></span>

                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field
                            [ngClass]="{'input-enabled': masterData.masterDataEdit === true, 'red': masterData.customerInformation.streetValidate === true && masterData.masterDataEdit === true}"
                            class="division-element">
                            <mat-label>
                              <span
                                *ngIf="masterData.customerInformation.streetValidate == false || masterData.masterDataEdit === false">
                                {{'master-data.Street_label' | translate}}</span>
                              <span class="touched-warning"
                                *ngIf="masterData.customerInformation.streetValidate == true && masterData.masterDataEdit === true">
                                {{'master-data.Street_label'
                                | translate}}</span>
                            </mat-label>
                            <input matInput required placeholder="{{'master-data.Street_label' | translate}}"
                              class="input" (blur)="charactersNumbersOnlystreet($event.target.value,masterData)"
                              [(ngModel)]="masterData.customerInformation.street" (change)="onaddressChange()"
                              [disabled]="!masterData.masterDataEdit">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field
                            [ngClass]="{'input-enabled': masterData.masterDataEdit === true, 'red': masterData.customerInformation.houseValidate === true && masterData.masterDataEdit === true}"
                            class="division-element">
                            <mat-label>
                              <span
                                *ngIf="masterData.customerInformation.houseValidate == false || masterData.masterDataEdit === false">{{'master-data.Housenumber_label'
                                | translate}}</span>
                              <span class="touched-warning"
                                *ngIf="masterData.customerInformation.houseValidate == true && masterData.masterDataEdit === true">
                                {{'master-data.Housenumber_label'
                                | translate}}</span>
                            </mat-label>
                            <input matInput placeholder="{{'master-data.Housenumber_label' | translate}}" class="input"
                              (blur)="charactersNumbersOnlyhouse($event.target.value,masterData)" required
                              [(ngModel)]="masterData.customerInformation.house"
                              [disabled]="!masterData.masterDataEdit">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field
                            [ngClass]="{'input-enabled': masterData.masterDataEdit === true, 'red': masterData.customerInformation.zipValidate === true && masterData.masterDataEdit === true}"
                            class="division-element">
                            <mat-label>
                              <span
                                *ngIf="masterData.customerInformation.zipValidate == false || masterData.masterDataEdit === false">
                                {{'master-data.Postcode_label'
                                | translate}}</span>
                              <span class="touched-warning"
                                *ngIf="masterData.customerInformation.zipValidate == true && masterData.masterDataEdit === true">
                                {{'master-data.Postcode_label'
                                | translate}}</span>
                            </mat-label>
                            <input matInput placeholder="{{'master-data.Postcode_label' | translate}}" class="input"
                              (blur)="numbersOnlyzip($event.target.value,masterData)" required
                              [(ngModel)]="masterData.customerInformation.zip_code" (change)="onaddressChange()"
                              [disabled]="!masterData.masterDataEdit">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field
                            [ngClass]="{'input-enabled': masterData.masterDataEdit === true, 'red': masterData.customerInformation.cityValidate === true && masterData.masterDataEdit === true}"
                            class="division-element">
                            <mat-label>
                              <span
                                *ngIf="masterData.customerInformation.cityValidate == false || masterData.masterDataEdit === false">
                                {{'master-data.City_label'
                                | translate}}</span>
                              <span class="touched-warning"
                                *ngIf="masterData.customerInformation.cityValidate == true && masterData.masterDataEdit === true">{{'master-data.City_label'
                                | translate}}</span>
                            </mat-label>
                            <input matInput placeholder="{{'master-data.City_label' | translate}}" class="input"
                              (blur)="charactersOnlycity($event.target.value,masterData)" required
                              [(ngModel)]="masterData.customerInformation.city" (change)="onaddressChange()"
                              [disabled]="!masterData.masterDataEdit">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="country" appearance="fill" class="division-element"
                            [ngClass]="{'input-enabled': masterData.masterDataEdit === true}">
                            <mat-label>{{'master-data.Country_label' | translate}}</mat-label>
                            <mat-select (selectionChange)="onCountrySelected($event.value,masterData)"
                              [(ngModel)]="masterData.customerInformation.country"
                              (blur)="charactersOnlycountry($event.target.value)" (selectionChange)="onaddressChange()"
                              [disabled]="!masterData.masterDataEdit">
                              <mat-option class="con-option" *ngFor="let country of countries$ | async" [value]='country.countryName'>
                                {{country.countryName}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <!-- Additional Details Section -->

                    <mat-accordion *ngIf="masterData.isExpanded">
                      <mat-expansion-panel (opened)="masterData.additionalDetailsExpanded = true"
                        (closed)="masterData.additionalDetailsExpanded = false"
                        [expanded]="masterData.additionalDetailsExpanded" hideToggle
                        style="border:1px solid #797f9652; border-bottom: none; margin: 0px -26px;">
                        <mat-expansion-panel-header #additionalDetails (click)="additionalDetails._toggle()"
                          style="background: none;" class="border-bottom">
                          <mat-panel-title (click)="additionalDetails._toggle()" class="expansion-panel-top-margin"
                            style="width: 100%;">
                            <button mat-icon-button *ngIf="masterData.additionalDetailsExpanded"
                              class="arrow-size arrowIcon">
                              <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="!masterData.additionalDetailsExpanded"
                              class="arrow-size arrowIcon">
                              <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                            </button>
                            <div class="row" style="width: 100%;">
                              <div class="col-md-9">
                                <p style="margin: 0px;" class="expansion-panel-heading-text">
                                  {{'master-data.AdditionalDetails_label' | translate}}</p>
                              </div>

                            </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row" style="margin-top: 24px;" *ngIf="isCustomerAdmin">
                          <div class="col-md-3 col-sm-6 col-6">
                            <mat-form-field class="division-element">
                              <mat-label>
                                <span> {{'master-data.BranchName_label' | translate}}</span>
                              </mat-label>
                              <input matInput placeholder="" class="input" disabled
                                [(ngModel)]="masterData.additionalDetails.branch_name">
                            </mat-form-field>
                          </div>
                          <div class="col-md-3 col-sm-6 col-6">
                            <mat-form-field class="division-element">
                              <mat-label>
                                <span> {{'master-data.Legalform_label' | translate}}</span>
                              </mat-label>
                              <input matInput placeholder="" class="input"
                                [(ngModel)]="masterData.additionalDetails.legal_form" disabled>
                            </mat-form-field>
                          </div>
                          <div class="col-md-3 col-sm-6 col-6">
                            <mat-form-field class="division-element">
                              <mat-label>
                                <span> {{'master-data.Vatnumber_label' | translate}}</span>
                              </mat-label>
                              <input matInput placeholder="" class="input"
                                [(ngModel)]="masterData.additionalDetails.vat_number" disabled>
                            </mat-form-field>
                          </div>
                          <div class="col-md-3 col-sm-6 col-6">
                            <mat-form-field class="division-element">
                              <mat-label>
                                <span> {{'master-data.Commercialregisternumber_label' | translate}}</span>
                              </mat-label>
                              <input matInput placeholder="" class="input"
                                [(ngModel)]="masterData.additionalDetails.crn" disabled>
                            </mat-form-field>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <!-- Account Details Section -->

                    <mat-accordion *ngIf="masterData.isExpanded">
                      <mat-expansion-panel (opened)="masterData.accountDetailsExpanded = true"
                        (closed)="masterData.accountDetailsExpanded = false"
                        [expanded]="masterData.accountDetailsExpanded" hideToggle
                        style="border:1px solid #797f9652; border-bottom: none; margin: 0px -26px;">
                        <mat-expansion-panel-header #accountDetails (click)="accountDetails._toggle()"
                          style="background: none;" class="border-bottom">
                          <mat-panel-title (click)="accountDetails._toggle()" class="expansion-panel-top-margin"
                            style="width: 100%;">
                            <button mat-icon-button *ngIf="masterData.accountDetailsExpanded"
                              class="arrow-size arrowIcon">
                              <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="!masterData.accountDetailsExpanded"
                              class="arrow-size arrowIcon">
                              <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                            </button>
                            <span class="expansion-panel-heading-text"> {{'master-data.AccountDetails_label' |
                              translate}}</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="white-space: nowrap;">
                          <table style="margin-top: 5px; border: solid 1px #797f9652 !important;white-space: nowrap;"
                            id="accountDetails">
                            <thead>
                              <tr class="inner-table-row" style="height: 41px;">
                                <th style="width: 7%;">
                                  <label style="cursor: pointer;" class="listLabel"> {{'master-data.ART_label' |
                                    translate}}</label>
                                </th>
                                <th style="width: 16%;">
                                  <label style="cursor: pointer;" class="listLabel"> {{'master-data.BANK_label' |
                                    translate}}</label>
                                </th>
                                <th style="width: 19%;">
                                  <label class="listLabel"> {{'master-data.IBAN_label' | translate}}</label>
                                </th>
                                <th style="width: 16%;">
                                  <label style="cursor: pointer;" class="listLabel"> {{'master-data.BIC_label' |
                                    translate}}</label>
                                </th>
                                <th style="width: 12%;">
                                  <label style="cursor: pointer;" class="listLabel"> {{'master-data.INHABER_label' |
                                    translate}}</label>
                                </th>
                                <th style="width: 10%;">
                                  <label style="cursor: pointer;" class="listLabel"> {{'master-data.GILTLABAB_label' |
                                    translate}}</label>
                                </th>
                                <th style="width: 9%;">
                                  <label style="cursor: pointer;" class="listLabel"> {{'master-data.SPARTE_label' |
                                    translate}}</label>
                                </th>
                                <th style="width: 11%;">
                                  <label style="cursor: pointer;" class="listLabel"> {{'master-data.SEPAMANDANT_label'
                                    | translate}}</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let accountDetails of masterData.accountDetails">
                                <td style="width: 7%;">
                                  <label class="listLabel1">{{accountDetails.bank_type}}</label>
                                </td>
                                <td style="width: 16%;">
                                  <label class="listLabel1 ellipsis top-arrange"
                                    title="{{accountDetails.bank_name}}">{{accountDetails.bank_name}}</label>
                                </td>
                                <td style="width: 19%;">
                                  <label class="listLabel1 ellipsis top-arrange"
                                    title="{{accountDetails.iban}}">{{accountDetails.iban}}</label>
                                </td>
                                <td style="width: 16%;">
                                  <label class="listLabel1 bic-top-arrange">{{accountDetails.bic}}</label>
                                </td>
                                <td style="width: 12%;">
                                  <label class="listLabel1 top-arrange">{{accountDetails.owner}}</label>
                                </td>
                                <td style="width: 10%;" class="bankAccountDatePicker">
                                  <mat-form-field class="date-picker date dateAccountDetail" appearance="fill"
                                    id="datePicker1" style="margin-bottom: 0px; margin-top: 7px;padding: 0;">

                                    <img alt="" src="assets/images/ic-icons/Calendar.svg"
                                      style="margin-right: 5px;vertical-align: unset;margin-bottom: 2px;" class="calendar-icon">
                                    <input matInput [matDatepicker]="picker1" autocomplete="off"
                                      [(ngModel)]="accountDetails.valid_from"
                                      [disabled]="!masterData.masterDataEdit || isCustomerAdmin"
                                      [style.color]="masterData.masterDataEdit === true ? '#303030' : '#989393'"
                                      style="font-weight: 600;margin: 0 !important;"
                                      placeholder="{{'claim-reporting.datePlaceHolder' | translate}}">
                                    <mat-datepicker-toggle matSuffix [for]="picker1">
                                      <mat-icon matDatepickerToggleIcon>{{'claims.keyboard-arrow-down_text' |
                                        translate}}</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>

                                  </mat-form-field>
                                </td>
                                <td style="width: 8%;">
                                  <label class="listLabel1"></label>
                                </td>
                                <td style="width: 10%;">
                                  <label class="listLabel1 tooltipz1"
                                    style="padding-left: 15px;margin-left: 31%;overflow: unset;">
                                    <input style="cursor: pointer;" type="checkbox" [checked]="false" disabled>
                                    <span class="mark"></span>
                                    <img alt="" src="../../../assets/images/new-icons/upload-icon.png"
                                      style="height: 15px;">
                                    <span class="tooltiptextz1">{{'header.In_Entwicklung' | translate}} </span>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </mat-expansion-panel>
                </mat-accordion>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Model for Document Upload -->
    <div class="modal upload-logo dialog" id="myModal" [style.display]="openDocUpload ? 'block' : 'none'">
      <div class="modal-dialog" style="top: 149px; display: initial; ">
        <div class="modal-content modalContent">
          <!-- Modal Header -->
          <div class="modal-header">
            <h6 style="font-weight: 600;">Brokerage mandate </h6>
            <button type="button" class="close" data-dismiss="modal" (click)="hide()">
              <img alt="" src="../../../assets/images/Group 3114.svg">
            </button>
          </div>
          <form>
            <!-- Modal body -->
            <div class="modal-body" style="font-size:12px;height: 254px;">
              <div style="text-align:center">
                <div class="row">
                  <div class="col-md-6 outer-div">
                    <label for="file-upload" style="border: none;background: none;padding-top: 179px;cursor: pointer;">
                      <img alt="" src="../../../assets/images/Group 2790.png"> </label>
                    <input id="file-upload" type="file" (click)="onClick($event)"
                      (change)="onSelectFile($event,masterDataObj)" accept="application/pdf,.msg" multiple>
                    <p class="mt-3 msg-text">{{'master-data.Drop_files' | translate}}
                      <br>
                      <span for="file-upload" style="color: #0E2650;">{{'master-data.browse' | translate}}</span>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <div *ngIf="masterDataObj == undefined">
                    </div>
                    <div *ngIf="masterDataObj != undefined">
                      <div *ngIf="masterDataObj.additionalDetails.documents.length>0">
                        <div style="height: 364px; overflow-y: scroll;margin-bottom: 8px;">
                          <div *ngFor="let rows of masterDataObj.additionalDetails.documents">
                            <p style="padding: 15px 5px;background-color: #f8f8f8;text-align: left;"
                              [style.cursor]="rows.link !== undefined? 'pointer' : 'default' "
                              (click)="rows.link !== undefined ? pdfViewer(rows.link) : null;">
                              <img alt="" src="../../../assets/images/new-icons/Document_icon.svg">
                              <span style="padding-left: 8px; font-size: 12px; font-weight: 600;max-width: 245px;"
                                class="ellipsis3" title="{{rows.name}}">{{rows.name}} </span>
                              <img alt="" src="../../../assets/images/new-icons/Tickmark_Green.svg"
                                style="margin-left: 8px;" *ngIf="rows.name !== undefined && rows.link !== undefined ">
                              <img alt="" src="../../../assets/images/new-icons/Delete icon.svg"
                                style="width:12px;margin:9px" class="img-delete-icon"
                                (click)="$event.stopPropagation();deleteDocument(rows,masterDataObj)">
                            </p>
                          </div>
                        </div>
                        <button class="nextBtn" (click)="documentUpload(masterDataObj)">{{'user-profile.Upload_label'
                          | translate}}</button>
                      </div>
                      <div *ngIf="masterDataObj.additionalDetails.documents.length == 0" class="display-table">
                        <p class="tabel-cell">{{'master-data.No_files_selected' | translate}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <mat-accordion class="userManagementToggle">
      <mat-expansion-panel class="user" (opened)="userManagementToggle = true" (closed)="userManagementToggle = false"
        [expanded]="userManagementToggle" hideToggle>
        <mat-expansion-panel-header #panelH (click)="panelH._toggle()" [expandedHeight]="customExpandedHeight"
          class="border-bottom">
          <mat-panel-title>

            <button (click)="showInviteButton()" mat-icon-button *ngIf="userManagementToggle == true" class="arrow-size"
              (click)="panelH._toggle()">
              <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
            </button>
            <button (click)="showInviteButton()" mat-icon-button *ngIf="userManagementToggle == false"
              class="arrow-size" (click)="panelH._toggle()">
              <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
            </button>
            <span style="margin-top: 10px;font-weight: 600;">
              <div class="d-flex">
                <div>
                  <h6 class="title-heading">&nbsp;
                    {{'user-data.user_management_label' | translate}}
                  </h6>
                </div>
              </div>
            </span>
          </mat-panel-title>
          <div *ngIf="showInviteBtn">
            <div class="filter-icon">
              <div style="float: right; margin-top: 3px; margin-right: 0.9rem;"
                *ngIf="isCustomerAdmin">
                <button (click)="inviteUserClick()" class="InviteButton">
                  <span style="vertical-align: text-top;"><img alt="" style="vertical-align: inherit;"
                      src="../../../assets/images/+.svg"></span> {{'update-user.invite_new_user' |
                  translate}}
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel-header>

        <!-- NEW FILTER SECTION FOR CUSTOME SIDE -->
        <div class="ml-3 mr-3 components-container-gt-xs d-flex flex-column" style="
        margin-top: 2%;margin-right:12px !important; margin-left:12px !important">
          <div class="table-component-holder mat-elevation-z4 more-document-filter" style="cursor: pointer;">
            <mat-accordion>
              <mat-expansion-panel (opened)="customerFilterToggle = true" (closed)="customerFilterToggle = false"
                [expanded]="customerFilterToggle" hideToggle>
                <mat-expansion-panel-header [expandedHeight]="customFilterExpandedHeight" class="border-bottom-filter">
                  <mat-panel-title>
                    <button mat-icon-button *ngIf="customerFilterToggle == true" class="arrow-size">
                      <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="customerFilterToggle == false" class="arrow-size">
                      <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                    </button>
                    <span style="margin-top: 10px;font-weight: 600;">
                      <p class="heading"> &nbsp; <strong>FILTER </strong></p>
                    </span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-container style="margin-top: 15px">
                  <form name="search" [formGroup]="filterCustomerForm" style="font-size: 12px; color: #4e4e4e">
                    <div class="serach-row userdata col-md-12" style="clear: both; padding: 0">
                      <div class="row" style="margin-left: 0px; ">
                        <div class="col-md-8">
                          <div class="row">

                            <mat-form-field style="margin-left: 0.7rem;" appearance="outline"
                              class="col-md-2 dropdownStyle switcherDrpdwn inputy switcher">
                              <mat-select (selectionChange)="getSelectedCustomer($event)" class=""
                                style="color: #272727;font-weight: 600;border-radius: 3px;height: 33px;" id="list"
                                [(ngModel)]="defaultSelectedCustomerValue"
                                placeholder="{{'dashboard.TOCHTERUNTERNEHMEN_text' | translate}}"
                                (openedChange)="searchCustomer.value = ''" formControlName="UnternehmenCustomer"
                                (selectionChange)="searchCustomer.value = ''">
                                <input type="text" maxlength="20" class="searchSelect"
                                  placeholder="{{'header.Suche_tooltip' | translate}}" preventSpace #searchCustomer>
                                <mat-option
                                  *ngFor="let sub of validationService._filter(subsidiaries, 'customer_name', searchCustomer.value)"
                                  matTooltip="{{sub.customer_name}} - {{sub.type}}" [value]="sub">
                                  <span *ngIf="sub.user_name " class="noSafariTooltip">{{sub.customer_name}} -
                                    {{sub.type | uppercase}} -
                                    {{sub.user_name}}</span>
                                  <span *ngIf="!sub.user_name " class="noSafariTooltip">{{sub.customer_name}} -
                                    {{sub.type |
                                    uppercase}}</span>
                                </mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            </mat-form-field>


                            <mat-form-field appearance="outline"
                              class="ml-2 col-md-2 dropdownStyle switcherDrpdwn inputy switcher icCompanyFilter">
                              <mat-select (selectionChange)="getStatus($event)" formControlName="Status"
                                [disabled]="statusDisabled" matTooltip="{{'user-data.statusBlockText' | translate}}"
                                [matTooltipDisabled]="!statusDisabled">
                                <mat-option value="Status" selected>All</mat-option>
                                <mat-option [value]="statusValue.label" *ngFor="let statusValue of statusAvailable">
                                  {{statusValue.label}}</mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            </mat-form-field>

                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="row" style="width: 100%">
                            <button (click)="getCustFormsValue()" class="ml-auto tool1" style="
                    background: #ff9000;
                    outline: none;
                    border-color: #ff9000;
                    box-shadow: none;
                    padding: 0px 22px 0px 22px;
                    height: 30px;
                    border-radius: 2px;
                    border-bottom: none;
                    border-right: none;
                    color: #312f29;
                    font-size: 16px;
                    font-weight: 600;
                    border-style: none;
                  " type="submit" [disabled]="isDisabled">
                              {{'user-data.Filter_text' | translate}}
                            </button>
                            <button (click)="resetCustomerFilter()" style="
                    background: #ff9000;
                    outline: none;
                    border-color: #ff9000;
                    box-shadow: none;
                    padding: 5px 11px 5px 10px;
                    height: 30px;
                    border-radius: 2px;
                    margin-left: 10px;
                    border-bottom: none;
                    border-right: none;
                    border-style: none;
                  " class="tool4">
                              <mat-icon><img alt="" class="resetImgStyle"
                                  src="../../../assets/images/Icon material-refresh.png"></mat-icon>
                              <span class="tooltiptext4"> {{'new-document.Filter_löschen' | translate}}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </ng-container>

              </mat-expansion-panel>
            </mat-accordion>

          </div>
        </div>

        <div style="margin-top: 1%;clear:both"
          class="ml-3 mr-3 components-container-gt-xs contact-table d-flex flex-column">
          <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10" [scrollWindow]="false"
            class="infiniteScrollDiv" style="margin-right:-4px;margin-left:-4px;">
            <div class="table-component-holder">
              <div class="table-container">

                <table style="display: table;" mat-table [dataSource]="dataSource" matSort
                  class="table-responsive table table-striped">

                  <!-- Position Column -->
                  <ng-container matColumnDef="NAME">
                    <th mat-header-cell *matHeaderCellDef><span class="ml-2">{{'user-data.NAME_text' | translate}}
                      </span></th>
                    <td style="font-size: 12px; white-space: nowrap;width: 10%;" mat-cell *matCellDef="let element"
                      title="{{element.user_name}}">
                      <span class="ml-2 ellipsis2">{{element.user_name}}
                      </span>
                    </td>

                  </ng-container>
                  <ng-container matColumnDef=" ">
                    <th mat-header-cell *matHeaderCellDef><span> </span></th>
                    <td style="font-size: 12px;width: 10%; white-space: nowrap;padding: 13px 1px 9px 0px;" mat-cell
                      *matCellDef="let element">
                      <span class="byIvm" *ngIf="element.is_source_ivm == true" style="padding: 0px 10px">ERP</span>
                      <span class="byRcs" *ngIf="element.is_source_ivm == false">ICP</span>
                    </td>

                  </ng-container>

                  <!-- Name Column -->

                  <br />

                  <!-- Weight Column -->
                  <ng-container matColumnDef="CUSTOMER">
                    <th mat-header-cell *matHeaderCellDef> <span>{{'user-data.customers_uppercase'| translate}} </span>
                    </th>
                    <td style="font-size: 12px;padding-bottom: 10px;width: 20%" mat-cell *matCellDef="let element"
                      title="{{element.customer_name}}">
                      <span class="ellipsis2"> {{element.customer_name}} </span>
                    </td>

                  </ng-container>

                  <ng-container matColumnDef="CUSTOMER_TYPE">
                    <th mat-header-cell *matHeaderCellDef><span
                        style="white-space: nowrap;">{{'user-data.customer_type_uppercase'| translate}}
                      </span> </th>
                    <td style="font-size: 12px;width: 20%" mat-cell *matCellDef="let element"> {{element.type}} </td>
                  </ng-container>

                  <ng-container matColumnDef="ROLE">
                    <th mat-header-cell *matHeaderCellDef> <span
                        style="white-space: nowrap;">{{'user-data.BENUTZERROLLE_text' | translate}}
                      </span></th><br />
                    <td style="font-size: 12px; white-space: nowrap;width: 20%" mat-cell *matCellDef="let element">
                      {{element.role}} </td>
                  </ng-container>

                  <ng-container matColumnDef="EMAIL">
                    <th mat-header-cell *matHeaderCellDef><span style="white-space: nowrap;">{{'user-data.EMAIL_text'
                        | translate}} </span></th>
                    <td style="font-size: 12px;white-space: nowrap;width: 20%" mat-cell *matCellDef="let element">
                      {{element.email}} </td>
                  </ng-container>
                  <ng-container matColumnDef="STATUS">
                    <th mat-header-cell *matHeaderCellDef style="position:relative;" class="statusth"><span
                        class="statusSpan">{{'user-data.STATUS_text' | translate}}
                      </span>
                      <ul class="statusFilter" [ngClass]="{'isICUser' : true}" *ngIf="openStatusFilter">
                        <img alt="" height="20px" style="cursor: pointer;" src='../../../assets/images/closeIcon.png'
                          class="statusFilterCloseIcon" (click)="openStatusSelection($event)">
                        <li *ngFor="let statusValue of statusAvailable">
                          <label style="cursor: pointer;" for="{{statusValue.label + '_status'}}"
                            class="listLabel">{{statusValue.label}}
                            <input style="cursor: pointer;" type="checkbox" class="checkField"
                              id="{{statusValue.label + '_status'}}" [(ngModel)]="statusValue.value"
                              (ngModelChange)="checkFieldClicked()">
                            <span class="mark"></span>
                          </label>
                        </li>

                      </ul>
                    </th>
                    <td style="font-size: 12px; white-space: nowrap;width: 20%" mat-cell *matCellDef="let element">
                      {{element.status}} </td>
                  </ng-container>
                  <ng-container matColumnDef="LAST_LOGIN">
                    <th style="white-space: nowrap;" mat-header-cell *matHeaderCellDef>
                      <span>{{'user-data.LETZTEANMELDUNG_text' | translate}}
                      </span>
                    </th>
                    <td style="font-size: 12px;width: 20%" mat-cell *matCellDef="let element"> {{element.last_login}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ACTION">
                    <th mat-header-cell *matHeaderCellDef
                      style="text-align: center;padding: 0px 0px 9px 1px!important;"> <span>{{'user-data.AKTION_text'
                        |
                        translate}} </span></th>
                    <td mat-cell style="text-align: center;" *matCellDef="let element">
                      <div class="control-section" style="position: initial;">
                        <div class="subscribe" style="position: initial;">
                          <button class="myDIV" id="content"
                            style="position: initial;">&#183;&nbsp;&#183;&nbsp;&#183;</button>
                          <div class="hide democontent" style="position: initial;margin-top:-11px;text-align: center;">
                            <button style="width:25%;display: inline-flex !important;" class="tool3"
                              [disabled]="element.user_id == userid" (click)="onEditClick(element)" mat-icon-button>
                              <mat-icon><img alt="" style="padding: 0px 1px 18px 0px; width: 100%;" class="imgStyle"
                                  src='../../../assets/images/edit.svg'></mat-icon><span
                                class="tooltiptext3">{{'user-data.BEARBEITEN_tooltip' | translate}}</span>
                            </button>&nbsp;&nbsp;
                            <button class="tool4"
                              [disabled]="element.invite_user == true || (!isICUser && element.user_id == userid)"
                              (click)="resetInvite(element.user_id, element.role, element.TYPE)" mat-icon-button
                              style="width:25%;display: inline-flex !important;">
                              <mat-icon><img alt="" style="padding: 0px 1px 18px 0px;width: 100%;" class="imgStyle"
                                  src='../../../assets/images/reset.svg'></mat-icon><span style="white-space: break-spaces;
                                    width: 89px;
                                    height: auto;
                                    padding: 0px 2px;
                                    bottom: 80%;
                                    margin-left: -53px;"
                                class="tooltiptext4">{{'user-data.EINLADUNGZURÜCKSETZEN_tooltip' | translate}}</span>
                            </button>
                            &nbsp;&nbsp;
                            <button style="width: 10%;display: inline-flex !important;"
                              *ngIf="element.is_source_ivm == false" class="tool4" mat-icon-button
                              (click)="deleteUser(element.user_id, element.role, element.TYPE)">
                              <mat-icon><img alt="" style="padding: 0px 1px 18px 0px;width: 100%;" class="imgStyle"
                                  src='../../../assets/images/delete.svg'></mat-icon><span style="white-space: break-spaces;
                                    width: 75px;
                                    height: auto;
                                    padding: 0px 2px;
                                    bottom: 80%;
                                    margin-left: -45px;" class="tooltiptext4"> {{'user-data.Löschen_tooltip' |
                                translate}} </span>
                            </button>
                            <button style="width: 10%;display: inline-flex !important;"
                              *ngIf="element.is_source_ivm == true" class="tool4" mat-icon-button
                              (click)="deleteIvmUser()">
                              <mat-icon><img alt="" style="padding: 0px 1px 18px 0px;width: 100%;" class="imgStyle"
                                  src='../../../assets/images/delete.svg'></mat-icon><span style="white-space: break-spaces;
                                    width: 75px;
                                    height: auto;
                                    padding: 0px 2px;
                                    bottom: 80%;
                                    margin-left: -45px;" class="tooltiptext4"> {{'user-data.Löschen_tooltip' |
                                translate}} </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <p style="margin-top: 18px;margin-left: 31px;">{{empty}}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
</div>

<!-- RESET INVITATION POPUP -->
<div class="modal modelBackground" id="myModal" [style.display]="showModal ? 'table' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content" style="border-radius: 8px;
    box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
    border: solid 1px #bbb;
    background-color: #fff;">
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
        <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
          <mat-icon (click)="hideModal()">
            <img alt="" height="65px" src="../../../assets/images/Path 91988 (1).svg">
          </mat-icon>
        </div>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;">
        <span style="margin-top: 1rem;
      font-size: 24px;
      font-weight: 600;
      color: #272727;"> {{'user-data.Sind_Sie' | translate}} </span><br>
        <span style="
     font-size: 18px;
     font-weight: 600;
     color: #272727;"> {{'user-data.dass_Sie' | translate}} </span>
      </div>
      <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">

        <button type="button" style="color: #272727;border-radius: 4px;width: 94.1px;
        height: 36.9px;
        border: solid 1px #272727;font-weight: 600;" mat-button (click)="hideModal()"> {{'user-data.Nein' | translate}}
        </button>
        <button class="ml-2" type="button" style="color: #272727;background: #ff9000;width: 124.1px;
        height: 36.9px;font-weight: 600;" mat-button (click)="invitationRevert(userId)">
          {{'user-data.EINLADUNGZURÜCKSETZEN_tooltip' | translate}} </button>

      </div>
    </div>
  </div>
</div>

<!-- DELETE USER POPUP -->
<div class="modal modelBackground" id="myModal" [style.display]="showDeleteModal ? 'table' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content" style="border-radius: 8px;
    box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
    border: solid 1px #bbb;
    background-color: #fff;">
      <!-- Modal Header -->
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
        <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
          <mat-icon (click)="hideModal()">
            <img alt="" height="65px" src="../../../assets/images/Group 3235.svg">
          </mat-icon>
        </div>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;">
        <br>
        <span style="
     font-size: 18px;
     font-weight: 600;
     color: #272727;"> <span style="font-weight: 600;font-size: 21px;"> {{'user-data.Attention_label' | translate}}
          </span> {{'user-data.Möchten_Sie' | translate}} </span>
      </div>
      <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
        <button type="button" style="color: #272727;border-radius: 4px;width: 94.1px;
        height: 36.9px;
        border: solid 1px #272727;font-weight: 600;" mat-button (click)="hideModal()"> {{'user-data.Nein' | translate}}
        </button>
        <button class="ml-2" type="button" style="color: #272727;background: #ff9000;width: 94.1px;
        height: 36.9px;font-weight: 600;" mat-button (click)="deleteUserProcess(userId)"> {{'user-data.Löschen' |
          translate}} </button>
      </div>
    </div>
  </div>
</div>

<!-- DELETE POPUP FOR IVM USER -->
<div class="modal modelBackground" id="myModal" [style.display]="showDeleteModalIvm ? 'table' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content" style="border-radius: 8px;
    box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
    border: solid 1px #bbb;
    background-color: #fff;">
      <!-- Modal Header -->
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: end;padding: 0;">
        <button style="background: transparent;" (click)="hideModal()" class="closebtn">
          <img alt="" style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
        </button>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;padding: 0 1rem 2rem 1rem;">
        <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
          <mat-icon (click)="hideModal()">
            <img alt="" height="75px" src="../../../assets/images/Icon_for_delete.svg">
          </mat-icon>
        </div>
        <br>
        <div style="
     font-size: 18px;
     font-weight: 600;
     color: #272727;
     margin-top: 2rem;">
          <div style="margin-bottom: 6px;"> {{'user-data.sorry' | translate}} </div> {{'user-data.deleteIvmPopupText' |
          translate}}
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal merchant-modal modelBackground" id="modal" [style.display]="showPDF ? 'block' : 'none'"
  style="z-index: 6; overflow: auto">
  <div class="modal-dialog pdf-dialog" style="width: 1225px; max-width: 2000px">
    <div class="modal-content" style="border: none">
      <!-- Modal Header -->
      <div class="modal-header file-view-header">
        <button class="file-download-btn" type="button" type="back">
          <a style="color: #fff; text-decoration: none" [href]="pdfLink" target="self" download="pdfLink">
            <mat-icon>file_download</mat-icon>
          </a>
        </button>
        <button style="background: transparent;" type="button" class="close" data-dismiss="modal"
          (click)="closePDF(pdfLink)">
          <img alt="" style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body View-body">
        <div class="pages pages-body page-style" id="pages">
          <div *ngFor="let item of thumbnail" id="thumbnail{{ item.page }}">
            <img alt="" (click)="pageRendered(item.page)"
              [ngClass]="[item.page == 1 ? 'pageSideView1' : 'pageSideViewAll']" src="{{ item.url }}" />
            <p style="color: #fff; margin-top: 10px">{{ item.page }}</p>
          </div>
        </div>
        <div class="pdfView" id="pdfView" style="overflow: scroll; height: 640px">
          <pdf-viewer class="pdf-view" [src]="pdfLink" [original-size]="false" [render-text]="false"
            [fit-to-page]="true" (page-rendered)="pageRendered($event)" [show-all]="true" [(page)]="pageVariable"
            (after-load-complete)="callBackFn($event)">
          </pdf-viewer>
        </div>
        <ngx-loading [show]="loading" [config]="{
            animationType: ngxLoadingAnimationTypes.rectangleBounce,
            primaryColour: secondaryColour,
            backdropBorderRadius: '3px',
            fullScreenBackdrop: true
          }">
        </ngx-loading>
      </div>
    </div>
  </div>
</div>
