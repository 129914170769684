export enum Priorities {
  HIGH = 3,
  MEDIUM = 5,
  LOW = 9,
}

export const PRIORITY_ICONS_PATH = {
  [Priorities.LOW]: 'assets/images/priority-low.svg',
  [Priorities.MEDIUM]: 'assets/images/priority-medium.svg',
  [Priorities.HIGH]: 'assets/images/priority-high.svg',
};

export const PRIORITY_LABEL = {
  [Priorities.LOW]: 'priority.low',
  [Priorities.MEDIUM]: 'priority.medium',
  [Priorities.HIGH]: 'priority.high',
};

export const PRIORITY_OPTIONS = [
  {
    label: PRIORITY_LABEL[Priorities.LOW],
    value: Priorities.LOW,
  },
  {
    label: PRIORITY_LABEL[Priorities.MEDIUM],
    value: Priorities.MEDIUM,
  },
  {
    label: PRIORITY_LABEL[Priorities.HIGH],
    value: Priorities.HIGH,
  },
];
