<div>
  <span class="criteria-heading">{{'objectModule.warehouse_object.warehouse' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="warehouseForm" id="warehouseForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.warehouse_object.total_storage_area' |
      translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="6"
      class="input" formControlName="total_storage_area"
      placeholder="{{'objectModule.warehouse_object.total_storage_area' | translate}}"
      (keydown)="validationService.SequenceNumberValidation($event, 'keydown',6)"
      (paste)="validationService.SequenceNumberValidation($event, 'paste',6)"
      (blur)="validationService.sqmValidation(warehouseForm, $event)" (focus)="validationService.sqmValidation(warehouseForm, $event)"
      numbersOnly>
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*"
      (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
      (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)"
      class="input numberText" formControlName="total_storage_area"
      placeholder="{{'objectModule.warehouse_object.total_storage_area' | translate}}"
      (blur)="validationService.sqmValidation(warehouseForm, $event)" (focus)="validationService.sqmValidation(warehouseForm, $event)"
      numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.warehouse_object.stored_goods' | translate}}</label>
    <input type="text" id="stored_goods" class="input" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.warehouse_object.stored_goods' | translate}}" matInput formControlName="stored_goods"
      title="{{warehouseForm.value.stored_goods}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.warehouse_object.number_of_fire_compartments' |
      translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="4"
      class="input" formControlName="number_of_fire_compartments"
      placeholder="{{'objectModule.warehouse_object.number_of_fire_compartments' | translate}}"
      (keydown)="validationService.SequenceNumberValidation($event, 'keydown',4)"
      (paste)="validationService.SequenceNumberValidation($event, 'paste',4)"
      (blur)="validationService.sqmValidation(warehouseForm, $event)" (focus)="validationService.sqmValidation(warehouseForm, $event)"
      numbersOnly>
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="4" ng-pattern="[0-9]*"
      (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',4)"
      (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',4)"
      class="input numberText" formControlName="number_of_fire_compartments"
      placeholder="{{'objectModule.warehouse_object.number_of_fire_compartments' | translate}}"
      (blur)="validationService.sqmValidation(warehouseForm, $event)" (focus)="validationService.sqmValidation(warehouseForm, $event)"
      numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.warehouse_object.largest_storage_area' |
      translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="6"
      class="input" formControlName="largest_storage_area"
      placeholder="{{'objectModule.warehouse_object.largest_storage_area' | translate}}"
      (keydown)="validationService.SequenceNumberValidation($event, 'keydown',6)"
      (paste)="validationService.SequenceNumberValidation($event, 'paste',6)"
      (blur)="validationService.sqmValidation(warehouseForm, $event)" (focus)="validationService.sqmValidation(warehouseForm, $event)"
      numbersOnly>
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*"
      (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
      (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)"
      class="input numberText" formControlName="largest_storage_area"
      placeholder="{{'objectModule.warehouse_object.largest_storage_area' | translate}}"
      (blur)="validationService.sqmValidation(warehouseForm, $event)" (focus)="validationService.sqmValidation(warehouseForm, $event)"
      numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.warehouse_object.type_of_storage' |
      translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="type_of_storage"
      placeholder="{{'objectModule.select_lable' | translate}}" formControlName="type_of_storage"
      (selectionChange)="resetKLTFields()">

      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option [value]="type.id" *ngFor="let type of storageTypes">{{type.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_fire_resistant_separation_of_the_KLT"
      class="long-label">{{'objectModule.warehouse_object.is_fire_resistant_separation_of_the_KLT' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_fire_resistant_separation_of_the_KLT"
        id="is_fire_resistant_separation_of_the_KLT" [disabled]="warehouseForm.value.type_of_storage != kltValue">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_extinguishing_system" class="long-label">{{'objectModule.warehouse_object.is_extinguishing_system' |
      translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_extinguishing_system" id="is_extinguishing_system"
        [disabled]="warehouseForm.value.type_of_storage != kltValue">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.warehouse_object.Type_of_packaging' | translate}}</label>
    <input type="text" id="Type_of_packaging" class="input" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.warehouse_object.Type_of_packaging' | translate}}" matInput formControlName="Type_of_packaging"
      title="{{warehouseForm.value.Type_of_packaging}}">
  </div>


  <div [ngClass]="warehouseForm.value.is_high_bay_warehouse ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_high_bay_warehouse">{{'objectModule.warehouse_object.is_high_bay_warehouse'
        | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align" *ngIf="warehouseForm.value.is_high_bay_warehouse"
      formGroupName="high_bay_warehouse">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="storage_height_top_edge_of_stored_goods_in_m"
          class="long-label">{{'objectModule.warehouse_object.storage_height_top_edge_of_stored_goods_in_m' |
          translate}}</label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="2"
          class="input" formControlName="storage_height_top_edge_of_stored_goods_in_m"
          placeholder="{{'objectModule.warehouse_object.storage_height' | translate}}"
          (keydown)="validationService.SequenceNumberValidation($event, 'keydown',2)"
          (paste)="validationService.SequenceNumberValidation($event, 'paste',2)"
          numbersOnly>
        <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="2" ng-pattern="[0-9]*"
          (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',2)"
          (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',2)"
          class="input numberText" formControlName="storage_height_top_edge_of_stored_goods_in_m"
          placeholder="{{'objectModule.warehouse_object.storage_height' | translate}}"
          numbersOnly>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_extinguishing_system"
          class="long-label">{{'objectModule.warehouse_object.is_extinguishing_system' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_extinguishing_system" id="is_extinguishing_system">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div
    [ngClass]="warehouseForm.value.is_storage_of_packaging_materials ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_storage_of_packaging_materials">
        {{'objectModule.warehouse_object.is_storage_of_packaging_materials' | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align" *ngIf="warehouseForm.value.is_storage_of_packaging_materials"
      formGroupName="storage_of_packaging_materials">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="fire_resistant_partition"
          class="long-label">{{'objectModule.warehouse_object.fire_resistant_partition' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="fire_resistant_partition" id="fire_resistant_partition">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="extinguishing_system" class="long-label">{{'objectModule.warehouse_object.is_extinguishing_system' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="extinguishing_system" id="extinguishing_system">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>


  <div
    [ngClass]="warehouseForm.value.is_silo_tank_farm ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_silo_tank_farm">
        {{'objectModule.warehouse_object.silo_tank_farm' | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align" *ngIf="warehouseForm.value.is_silo_tank_farm"
      formGroupName="silo_tank_farm_object">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="silo_tank_farm"
          class="long-label">{{'objectModule.warehouse_object.silo_tank_farm' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="silo_tank_farm" id="silo_tank_farm">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="bulk_pressed_material" class="long-label">{{'objectModule.warehouse_object.bulk_pressed_material' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="bulk_pressed_material" id="bulk_pressed_material">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="monitoring" class="long-label">{{'objectModule.warehouse_object.monitoring' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="monitoring" id="monitoring">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="extinguishing_system_silo" class="long-label">{{'objectModule.warehouse_object.extinguishing_system_silo' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="extinguishing_system_silo" id="extinguishing_system_silo">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="Outdoor_Camp" class="long-label">{{'objectModule.warehouse_object.Outdoor_Camp' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="Outdoor_Camp" id="Outdoor_Camp">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>


  <div
    [ngClass]="warehouseForm.value.is_warehouse_outdoors ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_warehouse_outdoors">{{'objectModule.warehouse_object.Warehouse_outdoor' | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align" *ngIf="warehouseForm.value.is_warehouse_outdoors"
      formGroupName="Warehouse_outdoor_object">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="type_of_firing" class="long-label">{{'objectModule.warehouse_object.Type_of_packaging' | translate}}</label>
        <input type="text" id="Type_of_packaging_warehouse" class="input" maxlength="100" autocomplete="off"
          placeholder="{{'objectModule.warehouse_object.Type_of_packaging' | translate}}" matInput formControlName="Type_of_packaging_warehouse"
          title="{{warehouseForm.value.Type_of_packaging_warehouse}}">
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="Storage_Height_Top_edge_of_stored_goods"
          class="long-label">{{'objectModule.warehouse_object.Storage_Height_Top_edge_of_stored_goods' |
          translate}}</label>
        <input  type="text" maxlength="5" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="validateNumberAsFloat($event)" formControlName="Storage_Height_Top_edge_of_stored_goods" placeholder="{{'objectModule.warehouse_object.storage_height' | translate}}">
        <input  type="text" maxlength="5" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="validateNumberAsFloat($event)" formControlName="Storage_Height_Top_edge_of_stored_goods" placeholder="{{'objectModule.warehouse_object.storage_height' | translate}}">
      </div>
    </div>
  </div>

  <div
    [ngClass]="warehouseForm.value.Warehouse_Complex_and_Packaging_means ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="Warehouse_Complex_and_Packaging_means">
        {{'objectModule.warehouse_object.Warehouse_Complex_and_Packaging_means' | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align" *ngIf="warehouseForm.value.Warehouse_Complex_and_Packaging_means"
      formGroupName="Warehouse_Complex_and_Packaging_means_object">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="Treatment_processing_in_warehouse_complex"
          class="long-label">{{'objectModule.warehouse_object.Treatment_processing_in_warehouse_complex' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="Treatment_processing_in_warehouse_complex" id="Treatment_processing_in_warehouse_complex">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="Detachment" class="long-label">{{'objectModule.warehouse_object.Detachment' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="Detachment" id="Detachment">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="extinguishing_system_warehouse" class="long-label">{{'objectModule.warehouse_object.extinguishing_system_silo' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="extinguishing_system_warehouse" id="extinguishing_system_warehouse">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="Storage_of_packaging_materials" class="long-label">{{'objectModule.warehouse_object.Storage_of_packaging_materials' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="Storage_of_packaging_materials" id="Storage_of_packaging_materials">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="Fire_resistant_partition" class="long-label">{{'objectModule.warehouse_object.Fire_resistant_partition' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="Fire_resistant_partition" id="Fire_resistant_partition">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.warehouse_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.warehouse_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>


    </div>
  </div>


</form>
