import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductWarningMsg } from '../../../models/product-management.model';

@Component({
  selector: 'close-alert-dialog',
  templateUrl: './close-alert-dialog.component.html',
  styleUrls: ['./close-alert-dialog.component.css'],
})
export class CloseAlertComponent {
  @Output('handlePopup') hideModalValue = new EventEmitter<boolean>();
  @Input('productwarnmsg') productMsg: ProductWarningMsg;
  constructor() {
    if (!this.productMsg) {
      this.productMsg = {
        cancel: 'product-management.stepper_common_msg.cancel_label',
        ok: 'product-management.stepper_common_msg.close',
        header: 'objectModule.Delete_popup_heading',
        body: 'product-management.stepper_common_msg.close_form_warning',
      };
    }
  }
  hideModal() {
    this.hideModalValue.emit(false);
  }
  cancleModal() {
    this.hideModalValue.emit(true);
  }
}
