<div class="save-changes" style="margin:-24px -24px 0px -24px">
    <div class="header">
        <p class="heading" style="color: #272727;font-size: 18px;">
            {{'save-changes.Nicht_gespeicherte' | translate}}
        </p>
        <button style="background: transparent;" (click)="onCloseProductManagement()" class="closebtn">
            <img alt="" style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
        </button>
    </div>
    <div class="container">
        <div class="col-md-2">
            <img alt="" height="65px" src="../../../assets/images/Path 91988 (1).svg">
        </div>
        <div class="col-md-10" style="float: right;top: -53px;line-height: 1.3;">
            <p style="color: #272727;font-size: 20px;">{{'save-changes.Nicht_gespeicherte' |
                translate}}
                <br> <span *ngIf="profileSection"
                    style="color: #272727;font-size: 15px;">{{'save-changes.Sie_sind'| translate}}
                </span>
                <span *ngIf="customerEdit"
                    style="color: #272727;font-size: 15px;">{{'save-changes.unsaved_changes_message'|
                    translate}}</span>
                <span *ngIf="dasboardConfigEdit">{{'save-changes.Sie_sind'| translate}} </span>
                <span *ngIf="objectSection"
                    style="color: #272727;font-size: 15px;">{{'save-changes.object_unsaved_message' |
                    translate}}</span>
                <span *ngIf="productManagementSection"
                    style="color: #272727;font-size: 15px;">{{'product-management.stepper_common_msg.product_management_exit'
                    | translate}}</span>
            </p>
        </div>

    </div>
    <div class="d-flex container" style="float: right;margin-top: -42px;">
        <div class="mr-auto ml-auto">

            <button *ngIf="profileSection" class="mr-3" type="button" (click)="onCloseClick()" style="font-size: 17px;color: #272727; border: 1px solid #272727;
                border-radius: 5px;" mat-button> {{'save-changes.discard' | translate}} </button>

            <button *ngIf="customerEdit" class="mr-3" type="button" (click)="onCloseClick()" style="font-size: 17px;color: #272727; border: 1px solid #272727;
                border-radius: 5px;" mat-button> {{'user-data.Nein' | translate}} </button>

            <button *ngIf="dasboardConfigEdit" class="mr-3" type="button" (click)="onCloseClick()" style="font-size: 17px;color: #272727; border: 1px solid #272727;
                border-radius: 5px;" mat-button> {{'save-changes.Änderungen_verwerfen' | translate}} </button>

            <button *ngIf="objectSection" class="mr-3" type="button" (click)="onCloseObjectClick()" style="background-color: transparent;height: 37px;font-size: 17px;color: #272727; border: 1px solid #272727;
                border-radius: 5px;padding: 0px 16px;outline: none;"> {{'save-changes.discard' | translate}} </button>

            <button *ngIf="productManagementSection" class="mr-3" type="button" (click)="onCloseProductManagement()"
                style="font-size: 16px;color: #272727;background: #ff9000;border: 1px solid transparent;
                border-radius: 5px; min-width: 100px; min-height: 39px"> {{'main_component.modal_button_text' | translate}} </button>

            <button class="mr-3" type="button" (click)="leaveCustomerChangesPage()" style="font-size: 16px;color: #272727;background: #ff9000;border: 1px solid transparent;
                border-radius: 5px" mat-button *ngIf="customerEdit"> {{'update-customer.Speichern_text' | translate}} </button>

            <button class="mr-3" type="button" (click)="leaveprofilePage()" style="font-size: 16px;color: #272727;background: #ff9000;border: 1px solid transparent;
                border-radius: 5px" mat-button *ngIf="profileSection"> {{'update-customer.Speichern_text' | translate}} </button>

            <button class="mr-3" type="button" (click)="leaveDashboardConfigPage()" style="font-size: 16px;color: #272727;background: #ff9000;border: 1px solid transparent;
                border-radius: 5px" mat-button *ngIf="dasboardConfigEdit"> {{'save-changes.Änderungen_speichern' | translate}} </button>

            <button class="mr-3" type="button" (click)="leaveObjectPae()" style="font-size: 16px;color: #272727;background: #ff9000;border: 1px solid transparent;
                border-radius: 5px" mat-button *ngIf="objectSection"> {{'update-customer.Speichern_text' | translate}} </button>

            <button class="mr-3" type="button" (click)="onCloseProductManagementClick()" style="font-size: 16px;color: #272727;background: #ff9000;border: 1px solid transparent;
                border-radius: 5px; min-width: 100px; min-height: 39px" mat-button *ngIf="productManagementSectionDiscard">
                {{'save-changes.discard' | translate}} </button>
        </div>
    </div>
</div>
