import { Directive, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as _moment from 'moment';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { DeleteConfirmationRequest } from '@shared/interfaces/delete-confirm.interface';
import { PaymentData } from './claims.component.model';
import { POPUP_IMAGES } from '@shared/constants/image-paths/popup.constant';
import { DocumentInformation } from '@shared/models/document-management.model';
import { BaseComponent } from '@shared/components/base.component';
import { Observable } from 'rxjs';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Directive()
export class ClaimsComponentBase extends BaseComponent {
  customerFilterToggle = true;
  userManagementToggle = true;
  uncheck: boolean;
  isKosten = true;
  isWholeGroup = false;
  openPaymentModal = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('tooltip')
  // @ViewChild("claimstable")
  table: MatTable<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  @ViewChild(MatSort) sort: MatSort;
  userDataValue: any;
  @Input('recreateDataValue') recreateDataValue;
  // @Input() isClaimsCalledFromContracts = false;
  moment = _moment;
  claim: any;
  counrtySelected: any = 'de';
  damage_date = null;
  SchadenartAvailable: any = [];
  objectAvailable: any = [];
  yearAvailable: any;
  loading = true;
  formFilter: any;
  dataSource = new MatTableDataSource();
  dataSource_filter = new MatTableDataSource();
  paymentDataSource = new MatTableDataSource();
  ClaimData = [];
  ClaimDataCount = 0;
  contract_id_all = null;
  subject = new FormControl();
  msg = new FormControl();
  decoded_date;
  user_role;
  decoded_contract;
  selectLang;
  public secondaryColour = '#d6d1d170';
  public showbutton = false;
  public arrowbutton = false;
  public buttonName: any = '../../../assets/images/filter.png';
  public buttonName1: any = '../../../assets/images/arrow_up.png';
  displayedColumns: string[] = [
    'id',
    'SCHADEN-DATUM',
    'STATUS/SB',
    'SCHADENART/SCHADENORT',
    'VERTRAGSNUMMER',
    'RESERVE/ZAHLUNG',
    'SCHADEN-NR-EXTERN/INTERN',
    'action',
  ];
  groupByColumns: string[] = ['VERTRAGSNUMMER'];
  columnSorting: any = {
    type: true,
    location: true,
    object: true,
    sb: true,
    payment: true,
    reserve: true,
    external_number: true,
    internal_number: true,
  };
  paymentColumns: string[] = [
    'Zahlungsnummer',
    'Zahlungsdatum',
    'Zahlungsbetrag',
    'Bemerkung 1',
    'Bemerkung 2',
  ];
  paymentData: PaymentData[] = [];
  dateSorted = false;
  openStatusFilter = false;
  sparte_five_records: any = [];
  sparte_Non_five_records: any = [];
  showSparteFiveRecords = false;
  statusAvailable: any;
  paymentSorted = false;
  claimsOriginalDataCopy: any = {};
  claimsDataGroupedObject: any = {};
  expandCollapsePolicyDataFlag: any = {};
  daySorted = false;
  claims_id;
  liztu_vn_kdnr: any;
  liztu_sa_idnr: any;
  liztu_vs_idnr: any;
  decoded_damage_id: string;
  damage_id: string;
  showWholeGroupCheck: boolean;
  receivedType;
  liztu_sa_idnr_parameter: string;
  decoded_liztu_sa_idnr: string;
  contract_number: any;
  isCustomerUser: boolean;
  showContract: boolean;
  userData: any;
  // changeTableRow: boolean = false;
  contract_number_grouping: any;
  UniqueContract = [];
  groupedRow = [];
  changeRowContractNumber: any;
  filterbuttonenable = false;
  ClaimData2: any[];
  ClaimDataCount2: number;
  kostenclicked = false;
  kostenControl = new FormControl();
  liztu: string;
  decoded_liztu: string;
  divisionName: string;
  // @ViewChild('myCheckbox') myCheckbox: MatCheckbox;
  unique;
  user_id;
  customer_id;
  whole_group;
  dataLimit;
  logo;
  customer;
  contract: number;
  date;
  is_source_ivm: boolean;
  type;
  contract_id;
  uId: string;
  empty: string;
  cId: string;
  example = '';
  // messageShow;
  routernavigate: any;
  isFilterApplied = false;
  numberOfRows = [25, 50, 75, 100, 125];
  selectedCountOfRows = 25;
  tablePage = 1;
  DefaultPage = 1;
  total_elements: number;
  fromMoredocument = false;
  getUserClaimsData = false;
  loadClaimDataFlag = false;
  isExportClicked = false;
  otherFields: any = {};
  contractID: any;
  cloudDocId: any;
  decoded_source: string;
  source: string;
  pageBreadcrumbText = 'claims.claim_text';
  isCustomerSelected = true;
  customerTypes = [
    CUSTOMER_TYPES.TOCHTER,
    CUSTOMER_TYPES.AUSLAND,
    CUSTOMER_TYPES.PRIVATKUNDE,
    CUSTOMER_TYPES.BETEILIGUNG,
  ];
  showConfirmPopup = false;
  show_confirm_id: any;
  sendDeleteReq: DeleteConfirmationRequest;
  loggedInUserRole = null;
  popupImages = POPUP_IMAGES;
  documentTypeData = [];
  totalClaims: number;
  isICUser = false;
  customerName$ = this._customerSharedService.selectedCustomerName$;

  constructor(public _customerSharedService: CustomerSharedService) {
    super();
  }

  splitDate(date) {
    return date.split('-');
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  hidePaymentModal() {
    this.openPaymentModal = false;
    this.paymentData = [];
  }
  clickedKosten() {
    this.kostenclicked = !this.kostenclicked;
    this.isKosten = !this.isKosten;
  }
}
