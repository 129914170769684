import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  showToastMessage(document, message) {
    const x = document.getElementsByClassName('custom-snackbar');
    x[0].textContent = message;
    x[0].classList.add('show');
    setTimeout(() => {
      x[0].classList.remove('show');
    }, 3000);
  }
  showSlowToastMessage(document, message, time) {
    const x = document.getElementsByClassName('custom-snackbar');
    x[0].textContent = message;
    x[0].classList.add('show-slow');
    setTimeout(() => {
      x[0].classList.remove('show-slow');
    }, time);
  }
}
