import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CanNotDeleteServiceRequest,
  DeleteConfirmationRequest,
  DeleteServiceRequest,
} from '@shared/interfaces/delete-confirm.interface';
import { MULTILINGUAL_CONSTS } from '@modules/new-claims/models/table.constants';
import { COMMON_IMAGES } from '@shared/constants/image-paths/common.constants';

@Injectable({
  providedIn: 'root',
})
export class DeleteService {
  constructor(private _translateService: TranslateService) {}

  canNotDeletePopupConfig(
    canNotDeleteConfig: CanNotDeleteServiceRequest
  ): DeleteConfirmationRequest {
    const noLabel = this._translateService.instant(MULTILINGUAL_CONSTS.CANCEL);
    const successText = this._translateService.instant(
      MULTILINGUAL_CONSTS.SUCCESS
    );

    const request: DeleteConfirmationRequest = {
      heading: canNotDeleteConfig.heading,
      subHeading: canNotDeleteConfig.subHeading,
      isCancelBtn: !!canNotDeleteConfig.is_cancel,
      cancelBtnText: noLabel,
      isSuccessBtn: !!canNotDeleteConfig.is_success,
      successBtnText: successText,
      id: null,
      isShowPopup: true,
      logoLink: canNotDeleteConfig.logoLink || COMMON_IMAGES.DELETE_CROSS,
    };
    return request;
  }

  deleteConfig(deleteConfig: DeleteServiceRequest): DeleteConfirmationRequest {
    const noLabel = this._translateService.instant(MULTILINGUAL_CONSTS.CANCEL);
    const successText = this._translateService.instant(
      MULTILINGUAL_CONSTS.SUCCESS
    );

    const request: DeleteConfirmationRequest = {
      heading:
        deleteConfig.heading ||
        this._translateService.instant(MULTILINGUAL_CONSTS.DELETE_HEADING),
      subHeading:
        deleteConfig.subHeading ||
        this._translateService.instant(MULTILINGUAL_CONSTS.DELETE_SUB_HEADING),
      isCancelBtn: !deleteConfig.isCancelHidden,
      cancelBtnText: noLabel,
      isSuccessBtn: !deleteConfig.isSuccessHidden,
      successBtnText: successText,
      id: deleteConfig.id,
      isShowPopup: true,
      logoLink: deleteConfig.logoLink || COMMON_IMAGES.DELETE_CROSS,
    };
    return request;
  }
}
