import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  powerSupplyFacilityFormConstants,
  UploadTypeConstants,
} from '@shared/constants/building.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { BuildingObject, Criteria } from '@shared/models/request.model';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-power-supply-facility',
  templateUrl: './power-supply-facility.component.html',
  styleUrls: ['./power-supply-facility.component.css'],
})
export class PowerSupplyFacilityComponent
  extends BaseComponent
  implements OnInit
{
  powerSupplyFacilityForm: FormGroup;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('object_data') object_data;
  @Input('objectType') objectType;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  readonly selectLang$ = this._userService.locale$;
  electricalSwitchList = [];
  typeOfHeatingList = [];
  showDocumentOHSPopup = false;
  showDocumentESPopup = false;
  showDocumentSGPPopup = false;
  showDocumentCASPopup = false;
  selectedOHSDocumentsArray = {};
  uploaded_doc_OHS = [];
  selectedESDocumentsArray = {};
  uploaded_doc_ES = [];
  selectedDocumentsArray = {};
  selectedSGPDocumentsArray = {};
  uploaded_doc_SGP = [];
  selectedCASDocumentsArray = {};
  uploaded_doc_CAS = [];
  isFormValueUpdated: boolean;
  request: BuildingObject;
  uploadObjectDetails: UploadObjectDetails;

  constructor(
    private serviceCall: ApiCallsService,
    private PowerSupplyObjectBuilder: FormBuilder,
    public validationService: ValidationService,
    private readonly _userService: UserService
  ) {
    super();
    this.serviceCall
      .getLookupData(13)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.electricalSwitchList = data.data;
      });
    this.serviceCall
      .getLookupData(14)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.typeOfHeatingList = data.data;
      });
  }

  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.POWER_SUPPLY_FACILITIES
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;
    const electricalSwitch = new FormControl({
      value: formData
        ? formData.power_supply_facilities.elec_switchgear_id
        : null,
      disabled: false,
    });
    const AcceptanceProtocolES = new FormControl({
      value: formData
        ? formData.power_supply_facilities.elec_switchgear_img_id
        : null,
      disabled: false,
    });
    const oldHeatingSystem = new FormControl({
      value: formData
        ? formData.power_supply_facilities.old_heating_system_id
        : null,
      disabled: false,
    });
    const AcceptanceProtocolOHS = new FormControl({
      value: formData
        ? formData.power_supply_facilities.old_heating_system_img_id
        : null,
      disabled: false,
    });
    const typeOfHeating = new FormControl({
      value: formData
        ? formData.power_supply_facilities.type_of_heating_id
        : null,
      disabled: false,
    });
    const typeQualityFuel = new FormControl({
      value: formData ? formData.power_supply_facilities.type : null,
      disabled: false,
    });
    const steamGeneratingPlants = new FormControl({
      value: formData
        ? formData.power_supply_facilities.steam_generating_plant_id
        : null,
      disabled: false,
    });
    const AcceptanceProtocolSGP = new FormControl({
      value: formData
        ? formData.power_supply_facilities.steam_generating_plant_img_id
        : null,
      disabled: false,
    });
    const compressedAirSystem = new FormControl({
      value: formData
        ? formData.power_supply_facilities.compressed_air_system_id
        : null,
      disabled: false,
    });
    const AcceptanceProtocolCAS = new FormControl({
      value: formData
        ? formData.power_supply_facilities.compressed_air_system_img_id
        : null,
      disabled: false,
    });
    const annotations = new FormControl({
      value: formData ? formData.power_supply_facilities.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.power_supply_facilities.is_annotation : null,
      disabled: false,
    });

    this.powerSupplyFacilityForm = this.PowerSupplyObjectBuilder.group({
      electricalSwitch,
      AcceptanceProtocolES,
      oldHeatingSystem,
      AcceptanceProtocolOHS,
      typeOfHeating,
      typeQualityFuel,
      steamGeneratingPlants,
      AcceptanceProtocolSGP,
      compressedAirSystem,
      AcceptanceProtocolCAS,
      annotations,
      is_annotation,
    });
    if (formData !== undefined) {
      const record_id =
        this.criteriaRequestDetails &&
        this.criteriaRequestDetails.criteriaList[
          BuildingConstants.POWER_SUPPLY_FACILITIES
        ].record_id;

      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.HasErrors == false) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              this.selectedDocumentsArray[document.document_name] = document;
            });
            this.powerSupplyFacilityForm.controls.AcceptanceProtocolES.setValue(
              Object.keys(this.selectedDocumentsArray).join(', ')
            );
          }
        });
    }
    this.isFormValueUpdated = false;
    this.powerSupplyFacilityForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isFormValueUpdated = true;
        this.buildFormObject();
        const pristine = this.powerSupplyFacilityForm.pristine;
        this.saveCriteriaRequest.emit({
          request: this.request,
          isSave: false,
          pristine,
        });
      });
    this.getDocumentUploadData(record_id);
  }

  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.PSF_OLD_HEATING_SYSTEMS
              ) {
                this.selectedOHSDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.PSF_ELECTRICAL_SWITCHGEAR
              ) {
                this.selectedESDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.PSF_STEAM_GENERATION_PLANT
              ) {
                this.selectedSGPDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.PSF_COMPRESSED_AIR_SYSTEMS
              ) {
                this.selectedCASDocumentsArray[document.document_name] =
                  document;
              }
            });
            if (data.documents) {
              this.powerSupplyFacilityForm.controls.AcceptanceProtocolOHS.setValue(
                Object.keys(this.selectedOHSDocumentsArray).join(', ')
              );
              this.powerSupplyFacilityForm.controls.AcceptanceProtocolES.setValue(
                Object.keys(this.selectedESDocumentsArray).join(', ')
              );
              this.powerSupplyFacilityForm.controls.AcceptanceProtocolSGP.setValue(
                Object.keys(this.selectedSGPDocumentsArray).join(', ')
              );
              this.powerSupplyFacilityForm.controls.AcceptanceProtocolCAS.setValue(
                Object.keys(this.selectedCASDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }
  uploadOHSDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedOHSDocumentsArray };
    this.showDocumentOHSPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.PSF_OLD_HEATING_SYSTEMS,
    };
  }

  uploadESDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedESDocumentsArray };
    this.showDocumentESPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.PSF_ELECTRICAL_SWITCHGEAR,
    };
  }

  uploadSGPDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedSGPDocumentsArray };
    this.showDocumentSGPPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.PSF_STEAM_GENERATION_PLANT,
    };
  }

  uploadCASDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedCASDocumentsArray };
    this.showDocumentCASPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.PSF_COMPRESSED_AIR_SYSTEMS,
    };
  }
  showDocumentPopup() {
    return (
      this.showDocumentESPopup ||
      this.showDocumentOHSPopup ||
      this.showDocumentSGPPopup ||
      this.showDocumentCASPopup
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showDocumentOHSPopup) {
      this.uploaded_doc_OHS = [];
      this.selectedOHSDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentOHSPopup = false;
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolOHS.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolOHS.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploaded_doc_OHS.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentESPopup) {
      this.uploaded_doc_ES = [];
      this.selectedESDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentESPopup = false;
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolES.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolES.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploaded_doc_ES.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentSGPPopup) {
      this.uploaded_doc_SGP = [];
      this.selectedSGPDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentSGPPopup = false;
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolSGP.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolSGP.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploaded_doc_SGP.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentCASPopup) {
      this.uploaded_doc_CAS = [];
      this.selectedCASDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentCASPopup = false;
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolCAS.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.powerSupplyFacilityForm.controls.AcceptanceProtocolCAS.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploaded_doc_CAS.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.powerSupplyFacilityForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }
  buildFormObject() {
    const PowerSupplyFacilityFormAttributeList = {
      elec_switchgear_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.ELECTRICAL_SWITCHGEAR
        ],
      elec_switchgear_img_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.ELECTRICAL_SWITCHGEAR_IMAGE
        ],
      old_heating_system_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.OLD_HEATING_SYSTEM
        ],
      old_heating_system_img_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.OLD_HEATING_SYSTEM_IMAGE
        ],
      type_of_heating_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.TYPE_OF_HEATING
        ],
      type: this.powerSupplyFacilityForm.value[
        powerSupplyFacilityFormConstants.TYPE
      ],
      steam_generating_plant_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.STEAM_GENERATING_PLANT
        ],
      steam_generating_plant_img_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.STEAM_GENERATING_PLANT_IMAGE
        ],
      compressed_air_system_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.COMPRESSED_AIR_SYSTEM
        ],
      compressed_air_system_img_id:
        this.powerSupplyFacilityForm.value[
          powerSupplyFacilityFormConstants.COMPRESSED_AIR_SYSTEM_IMAGE
        ],
      annotations: this.powerSupplyFacilityForm.value.annotations,
      is_annotation: this.powerSupplyFacilityForm.value.is_annotation,
    };
    const formValuesToPersist = {
      power_supply_facilities: PowerSupplyFacilityFormAttributeList,
    };

    this.request = {
      document_ids: [
        ...new Set([
          ...this.uploaded_doc_OHS,
          ...this.uploaded_doc_ES,
          ...this.uploaded_doc_SGP,
          ...this.uploaded_doc_CAS,
        ]),
      ],
      criteria_id: BuildingConstants.POWER_SUPPLY_FACILITIES,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.powerSupplyFacilityForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
  scrollValue(key) {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById(key).focus();
  }
}
