import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from '@shared/models/component-can-deactivate.model';
import { NewCustomerFormRequest } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ShareDataService } from '@core/services/share-data.service';
import { GenericCriteriaConstants } from '@shared/constants/generic.constants';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { ValidationSharedService } from '@core/services/validation-shared.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';

export interface SaveNewObjectRequest {
  liztu_vn_kdnr: any;
  address_id: any;
  object_type_id: number;
  created_by: any;
  record_id: number;
  is_update: boolean;
  object_name: string;
  object_module_id?: any;
  updated_by?: any;
  is_form_save: any;
  criteria: any;
}

@Component({
  selector: 'app-new-object-module',
  templateUrl: './new-object-module.component.html',
  styleUrls: [
    './new-object-module.component.css',
    '../object-module.component.scss',
  ],
})
export class NewObjectModuleComponent extends BaseComponent
  implements OnInit, ComponentCanDeactivate{
  user_role: USER_ROLES;
  userDataValue: any;
  customer_id: any;
  divisionName: any;
  isCustomerUser: boolean;
  customerDataValue: any;
  customerSectionToggle = true;
  detailsSectionToggle = true;
  isICUser: boolean;
  encoded_liztu_vn_kdnr: any;
  liztu_vn_kdnr: string;
  encoded_role: string;
  role: string;
  encoded_org: string;
  org: string;
  decoded_user: string;
  user: string;
  decoded_customer: string;
  encoded_type: string;
  customer: string;
  type: string;
  encoded_is_source_ivm: string;
  is_source_ivm: boolean;
  subsidiaries: any;
  user_idnr: any;
  selectedCustomer: any = null;
  openaddressModal = false;
  showSnackBar = false;
  isCustomerChange = false;
  newObjectForm: FormGroup;
  filterlocation: any = [];
  public keyUp = new Subject<string>();
  showList = false;
  statusDisabled: boolean;
  isObjectFormInValid = true;
  isFormTouched = false;
  showSuccessModel = false;
  showFailureModel = false;
  resetForm = false;
  responseText = '';
  objectForm: any = {};
  object_type_data: any = [];
  object_data: any = null;
  selected_customer_liztu: any = undefined;
  selectedAddress: any = {};
  isSubmitClicked = false;
  failureText = '';
  saveNewObjectData: SaveNewObjectRequest;
  selected_customer_liztu_building: any;
  newCustomerFormReq: NewCustomerFormRequest = {};
  buildingValueChanges: Subject<boolean> = new Subject();
  locationValueChanges: Subject<boolean> = new Subject();
  clickEventsubscription: Subscription;
  showClosePopUp = false;
  isAddressValidation: boolean;
  isCustomerSelected = true;
  pageBreadcrumbText = 'objectModule.ObjectManagement';
  pageBreadcrumbSubText;
  customerName$ = this._customerSharedService.selectedCustomerName$;

  constructor(
    private serviceCall: ApiCallsService,
    private route: ActivatedRoute,
    private router: Router,
    private shareservice: ShareDataService,
    public validationService: ValidationService,
    private readonly _userService: UserService,
    private readonly _validationSharedService: ValidationSharedService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
    this.object_data = this.router.getCurrentNavigation()?.extras.state;

    // if (!this.object_data) {
    //   this.router.navigate([ROUTES_PATH.OBJECT_MODULE], {
    //     queryParams: { liztu: this.route.snapshot.queryParams.liztu },
    //   });
    // }

    this.initializeForm(this.object_data);
    this.clickEventsubscription = this.shareservice
      .getObjectClickEvent()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.saveObject();
      });
  }

  canDeactivate(): boolean {
    if (!this.newObjectForm.pristine) {
      this.newCustomerFormReq.isDiscard = !this.newObjectForm.pristine;
      return this.newObjectForm.pristine;
    }

    if (this.isFormTouched) {
      this.newCustomerFormReq.isDiscard = this.isFormTouched;
      return !this.isFormTouched;
    }
    return true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.showSnackBar = false;
  }

  ngOnInit(): void {
    this._watchOnUserRole();
    this.pageBreadcrumbSubText = this.object_data
      ? 'objectModule.edit_object_text'
      : 'objectModule.new_object_text';
  }

  customerDataReceived(data) {
    this.customerDataValue = data;
  }

  userDataReceived(data) {
    this.getAllObjectTypes();
    this.userDataValue = data;
    this.populatedUserData(data);
  }

  populatedUserData(data) {
    this.customer_id =
      this.userDataValue.user_profile.customer_details.customer_id;
    this.divisionName = data.user_profile.customer_details.name;
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu_vn_kdnr = atob(this.encoded_liztu_vn_kdnr);

    this.encoded_role = this.route.snapshot.queryParamMap.get('role') || null;
    this.role = this.encoded_role && atob(this.encoded_role);
    this.encoded_org = this.route.snapshot.queryParamMap.get('liztu_vn_idnr') || null;
    this.org = this.encoded_org ? atob(this.encoded_org) : null;
    this.decoded_customer = this.route.snapshot.queryParamMap.get('customer') || null;
    this.customer = this.decoded_customer ? atob(this.decoded_customer): null;
    this._customerSharedService.saveCustomerId(this.customer); // ?
    this.decoded_user = this.route.snapshot.queryParamMap.get('user') || null;
    this.user = this.decoded_customer ? atob(this.decoded_customer): null;
    this.encoded_type = this.route.snapshot.queryParamMap.get('type') || null;
    this.type = this.encoded_type ? atob(this.encoded_type) : null;
    this.encoded_is_source_ivm =
      this.route.snapshot.queryParamMap.get('source');
    this.is_source_ivm = atob(this.encoded_is_source_ivm) == 'true';
    this.user_idnr = data.user_profile.user_data.user_idnr;
    this.user_idnr = this.user_idnr == null ? '' : this.user_idnr;

    this.serviceCall
      .getSubsidiaries(
        this.customer,
        this.user_idnr,
        this.user_role,
        this.is_source_ivm
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(obj => {
        this.subsidiaries = obj.associated_customers;

        this.customer_id =
          this.userDataValue.user_profile.customer_details.customer_id;

        if (this.object_data) {
          this.selectedCustomer = this.subsidiaries.filter(
            obj =>
              obj.customer_id == this.object_data.customer_details.customer_id
          )[0];
        } else {
          this.selectedCustomer = this.subsidiaries.filter(
            obj => obj.customer_id == this.customer
          )[0];
        }

        this.selected_customer_liztu_building =
          this.selectedCustomer.liztu_vn_kdnr;
        this.newCustomerFormReq.customerName =
          this.selected_customer_liztu_building || this.liztu_vn_kdnr;

        this.newObjectForm.patchValue({
          customer: this.selectedCustomer.customer_name,
        });
        this.divisionName = data.user_profile.customer_details.name;
        this.autoComplete();
      });
  }

  setZursAddress(event) {
    this.newCustomerFormReq.zursAddress = event.address;
    this.newCustomerFormReq = Object.assign({}, this.newCustomerFormReq);
  }

  getSelectedLocAddress(): boolean {
    return (
      this.newCustomerFormReq &&
      this.newCustomerFormReq.address &&
      this.newCustomerFormReq.address.address_details
    );
  }
  getAllObjectTypes() {
    this.serviceCall
      .getObjectType('')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.object_types !== null) {
          for (let i = 0; i < data.object_types.length; i++) {
            this.object_type_data.push(data.object_types[i].object_name);
          }
          if (this.object_data) {
            this.newObjectForm.patchValue({
              objectType: this.object_data.object_type,
            });
          }
        }
      });
  }

  autoComplete() {
    if (!this.selectedCustomer) {
      return;
    }

    this.filterlocation = [];
    this.serviceCall
      .getAddresses(this.selectedCustomer.liztu_vn_kdnr)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res: any) => {
        if (res) {
          let address: any;
          this.filterlocation = res.address_list.filter(
            obj => obj.address_line
          );

          if (!this.object_data) {
            address = this.filterlocation.find(
              obj =>
                obj.address_line.toLowerCase() ==
                'Kundenadresse'.toLocaleLowerCase()
            );
          } else {
            address = this.filterlocation.find(
              obj => obj.address_id == this.object_data.address_id
            );
            if (!address) {
              address = this.filterlocation.find(
                obj =>
                  obj.address_line.toLowerCase() ==
                  'Kundenadresse'.toLocaleLowerCase()
              );
            }
          }
          this.selectedLocation(address);
        }
      });
  }

  onFocusOut(event) {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  selectedLocation(location) {
    this.newObjectForm.patchValue({
      Address: location ? location.address_line : '',
    });

    this.selectedAddress = location;
    this.newCustomerFormReq.addressId = location
      ? this.getSelectedAddress()
      : '';
    this.newCustomerFormReq.address = location;
    this.newCustomerFormReq = Object.assign({}, this.newCustomerFormReq);
  }

  getSelectedCustomer(event) {
    this.selectedCustomer = this.subsidiaries.filter(
      obj => obj.customer_name == event.value
    )[0];
    this.selectedAddress = {};
    this.selected_customer_liztu = this.selectedCustomer.liztu_vn_kdnr;

    if (this.selected_customer_liztu) {
      this.newCustomerFormReq.customerName = this.selected_customer_liztu;
    }

    if (!this.object_data) {
      this.newObjectForm.reset();
      this.newObjectForm.patchValue({
        customer: this.selectedCustomer.customer_name,
      });
      this.resetForm = !this.resetForm;
    }

    this.autoComplete();
    this.isSubmitClicked = false;
  }

  formValues(formValue) {
    const event = formValue.value;
    if (formValue.pristine !== undefined) {
      this.isFormTouched = !formValue.pristine || formValue.touched;
    } else {
      this.isFormTouched = formValue.touched;
    }
    this.isObjectFormInValid = event && event.isInValid;
    this.objectForm = event;
  }

  getSelectedAddress() {
    return (this.selectedAddress && this.selectedAddress.address_id) || '';
  }

  closeObject() {
    if (this.isFormTouched || !this.newObjectForm.pristine) {
      this.showClosePopUp = true;
    } else {
      this.showClosePopUp = false;
      this.isFormTouched = false;
      this.navigateToObjectModule();
    }
  }

  handlePopup(event) {
    if (event) {
      this.showClosePopUp = false;
    } else {
      this.isFormTouched = false;
      this.showClosePopUp = false;
      this.navigateToObjectModule();
    }
  }

  navigateToObjectModule() {
    if (!this.object_data) {
      this.newObjectForm.reset();
      this.newObjectForm.patchValue({
        customer: this.selectedCustomer.customer_name,
      });
      this.resetForm = !this.resetForm;
    }
    this.resetForm = !this.resetForm;
    this.selected_customer_liztu = undefined;
    this.populatedUserData(this.userDataValue);
    this._routeToObjectOverview();
  }

  saveObject() {
    if (this.newObjectForm.invalid) {
      this.newObjectForm.markAllAsTouched();
      return;
    }
    if (this.isObjectFormInValid) {
      this.isSubmitClicked = true;
      return;
    }

    const userId = this._userService.getUserId();

    if (this.selected_customer_liztu == undefined) {
      if (this.selected_customer_liztu_building !== undefined) {
        this.selected_customer_liztu = this.selected_customer_liztu_building;
      }
    }
    if (
      this._validationSharedService.getFormError() !== 'true' &&
      this._validationSharedService.getFormErrorPower() !== 'true' &&
      this._validationSharedService.getFormErrorCount() !== 'true'
    ) {
      if (this.objectForm.objectTypeName == 'electronic') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 3,
          created_by: userId,
          object_name: this.objectForm.object_name,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[3],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            document_ids: [
              ...new Set([
                ...(Array.isArray(this.objectForm.fire_protection_doc_ids)
                  ? this.objectForm.fire_protection_doc_ids
                  : [this.objectForm.fire_protection_doc_ids]),
                ...(Array.isArray(this.objectForm.acquisition_invoice_doc_ids)
                  ? this.objectForm.acquisition_invoice_doc_ids
                  : [this.objectForm.acquisition_invoice_doc_ids]),
                ...(Array.isArray(this.objectForm.risk_questionnaire_doc_ids)
                  ? this.objectForm.risk_questionnaire_doc_ids
                  : [this.objectForm.risk_questionnaire_doc_ids]),
              ]),
            ].filter(item => item !== null && item !== undefined),
            jsonb: {
              construction_year: this.objectForm.constructionYear,
              count: this.objectForm.count,
              description: this.objectForm.description,
              extinguishing_system_id: this.objectForm.objectExtSystem
                ? this.objectForm.objectExtSystem
                : [],
              manufacturer: this.objectForm.manufacturer,
              model: this.objectForm.model,
              new_value:
                this.objectForm.newValue == null ||
                this.objectForm.newValue == ''
                  ? this.objectForm.newValue
                  : Number(this.removeSymbols(this.objectForm.newValue)),
              new_value_in_year: this.objectForm.newValuePerYear,
              sequence_number: this.objectForm.sequenceNumber,
              serial_number: this.objectForm.serialNumber,
              currency_type_id: this.objectForm.currency_type_id,
              wert_371: this.objectForm.wert_371
                ? Number(this.removeSymbols(this.objectForm.wert_371))
                : null,
              total_value: this.objectForm.Gesamtwert
                ? Number(this.removeSymbols(this.objectForm.Gesamtwert))
                : null,
            },
          },
        };
      }
      if (this.objectForm.objectTypeName == 'operating_equipment') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 4,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[4],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            jsonb: {
              value:
                this.objectForm.value == null || this.objectForm.value == ''
                  ? this.objectForm.value
                  : Number(this.removeSymbols(this.objectForm.value)),
              value_type_id: this.objectForm.value_type_id,
              description: this.objectForm.description,
              currency_type_id: this.objectForm.currency_type_id,
            },
          },
        };
      }
      if (this.objectForm.objectTypeName == 'machine-plants') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 2,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[2],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            document_ids: [
              ...new Set([
                ...(Array.isArray(this.objectForm.fire_protection_doc_ids)
                  ? this.objectForm.fire_protection_doc_ids
                  : [this.objectForm.fire_protection_doc_ids]),
                ...(Array.isArray(this.objectForm.acquisition_invoice_doc_ids)
                  ? this.objectForm.acquisition_invoice_doc_ids
                  : [this.objectForm.acquisition_invoice_doc_ids]),
                ...(Array.isArray(this.objectForm.risk_questionnaire_doc_ids)
                  ? this.objectForm.risk_questionnaire_doc_ids
                  : [this.objectForm.risk_questionnaire_doc_ids]),
              ]),
            ].filter(item => item !== null && item !== undefined),
            jsonb: {
              construction_year: this.objectForm.constructionYear,
              count: this.objectForm.count,
              description: this.objectForm.description,
              extinguishing_system_id: this.objectForm.objectExtSystem
                ? this.objectForm.objectExtSystem
                : [],
              manufacturer: this.objectForm.manufacturer,
              model: this.objectForm.model,
              new_value:
                this.objectForm.newValue == null ||
                this.objectForm.newValue == ''
                  ? this.objectForm.newValue
                  : Number(this.removeSymbols(this.objectForm.newValue)),
              new_value_in_year: this.objectForm.newValuePerYear,
              sequence_number: this.objectForm.sequenceNumber,
              serial_number: this.objectForm.serialNumber,
              hub_height: this.objectForm.hub_height,
              currency_type_id: this.objectForm.currency_type_id,
              wert_371: this.objectForm.wert_371
                ? Number(this.removeSymbols(this.objectForm.wert_371))
                : null,
              total_value: this.objectForm.Gesamtwert
                ? Number(this.removeSymbols(this.objectForm.Gesamtwert))
                : null,
            },
          },
        };
      }

      if (this.objectForm.objectTypeName == 'machine-plants-mobi') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 13,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[13],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            document_ids: [
              ...new Set([
                ...(Array.isArray(this.objectForm.fire_protection_doc_ids)
                  ? this.objectForm.fire_protection_doc_ids
                  : [this.objectForm.fire_protection_doc_ids]),
                ...(Array.isArray(this.objectForm.acquisition_invoice_doc_ids)
                  ? this.objectForm.acquisition_invoice_doc_ids
                  : [this.objectForm.acquisition_invoice_doc_ids]),
                ...(Array.isArray(this.objectForm.risk_questionnaire_doc_ids)
                  ? this.objectForm.risk_questionnaire_doc_ids
                  : [this.objectForm.risk_questionnaire_doc_ids]),
              ]),
            ].filter(item => item !== null && item !== undefined),
            jsonb: {
              construction_year: this.objectForm.constructionYear,
              count: this.objectForm.count,
              description: this.objectForm.description,
              extinguishing_system_id: this.objectForm.objectExtSystem
                ? this.objectForm.objectExtSystem
                : [],
              manufacturer: this.objectForm.manufacturer,
              model: this.objectForm.model,
              new_value:
                this.objectForm.newValue == null ||
                this.objectForm.newValue == ''
                  ? this.objectForm.newValue
                  : Number(this.removeSymbols(this.objectForm.newValue)),
              new_value_in_year: this.objectForm.newValuePerYear,
              sequence_number: this.objectForm.sequenceNumber,
              serial_number: this.objectForm.serialNumber,
              hub_height: this.objectForm.hub_height,
              currency_type_id: this.objectForm.currency_type_id,
              wert_371: this.objectForm.wert_371
                ? Number(this.removeSymbols(this.objectForm.wert_371))
                : null,
              total_value: this.objectForm.Gesamtwert
                ? Number(this.removeSymbols(this.objectForm.Gesamtwert))
                : null,
            },
          },
        };
      }

      if (this.objectForm.objectTypeName == 'building') {
        this.isSubmitClicked = true;
        this.buildingValueChanges.next(true);
        return;
      }
      if (this.objectForm.objectTypeName == 'location') {
        this.isSubmitClicked = true;
        this.locationValueChanges.next(true);
        return;
      }
      if (this.objectForm.objectTypeName == 'individual') {
        if (this.objectForm.date_of_birth == 'Invalid date') {
          this.objectForm.date_of_birth = '';
        }
        if (
          this.objectForm.date_of_birth != '' &&
          this.objectForm.date_of_birth != null
        ) {
          const momentDate = new Date(this.objectForm.date_of_birth);
          this.objectForm.date_of_birth =
            moment(momentDate).format('YYYY-MM-DD');
        }
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 5,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[5],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            document_ids: this.objectForm.doc_id,
            jsonb: {
              sequence_number: this.objectForm.sequence_number
                ? this.objectForm.sequence_number
                : null,
              first_name: this.objectForm.first_name,
              last_name: this.objectForm.last_name,
              date_of_birth: this.objectForm.date_of_birth,
              occupation_id: this.objectForm.occupation_id
                ? this.objectForm.occupation_id
                : null,
              professional_group: this.objectForm.professional_group
                ? this.objectForm.professional_group
                : null,
              gender: this.objectForm.gender,
              doc_id: this.objectForm.doc_id,
            },
          },
        };
      }
      if (this.objectForm.objectTypeName == 'vehicle') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 6,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          is_update: this.object_data ? true : false,
          object_name: this.objectForm.objectType,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[6],
            document_ids: this.objectForm.document_ids,
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            jsonb: this.objectForm,
          },
        };
      }
      if (this.objectForm.objectTypeName == 'goods') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 9,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          is_update: this.object_data ? true : false,
          object_name: this.objectForm.objectType,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[9],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            jsonb: this.objectForm,
          },
        };
      }
      if (this.objectForm.objectTypeName == 'group_of_people') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 7,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[7],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            jsonb: {
              sequence_number: this.objectForm.sequence_number
                ? this.objectForm.sequence_number
                : null,
              group_description: this.objectForm.group_description,
              number_of_people: this.objectForm.number_of_people,
              occupation_id: this.objectForm.occupation_id
                ? this.objectForm.occupation_id
                : null,
              professional_group: this.objectForm.professional_group
                ? this.objectForm.professional_group
                : null,
            },
          },
        };
      }
      if (this.objectForm.objectTypeName == 'model_samples_tools') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 8,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[8],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            document_ids: this.objectForm.doc_id,
            jsonb: {
              value:
                Number(this.removeSymbols(this.objectForm.value)) == 0
                  ? null
                  : Number(this.removeSymbols(this.objectForm.value)),
              value_type_id: this.objectForm.value_type_id,
              currency_type_id: this.objectForm.currency_type_id,
              description: this.objectForm.description,
              owner: this.objectForm.owner,
              doc_id: this.objectForm.doc_id,
            },
          },
        };
      }
      if (this.objectForm.objectTypeName == 'miscellaneous') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 10,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[10],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            jsonb: {
              value:
                Number(this.removeSymbols(this.objectForm.value)) == 0
                  ? null
                  : Number(this.removeSymbols(this.objectForm.value)),
              value_type_id: this.objectForm.value_type_id,
              currency_type_id: this.objectForm.currency_type_id,
              description: this.objectForm.description,
            },
          },
        };
      }
      if (this.objectForm.objectTypeName == 'photovolatic') {
        this.saveNewObjectData = {
          liztu_vn_kdnr:
            this.selected_customer_liztu !== undefined
              ? this.selected_customer_liztu
              : this.liztu_vn_kdnr,
          address_id: this.getSelectedAddress(),
          object_type_id: 11,
          created_by: userId,
          updated_by: this.object_data ? userId : '',
          record_id:
            this.object_data && this.object_data.criteria_response
              ? this.object_data.criteria_response.record_id
              : 0,
          is_update: this.object_data ? true : false,
          object_module_id: this.object_data
            ? this.object_data.object_module_id
            : '',
          object_name: this.objectForm.object_name,
          is_form_save: true,
          criteria: {
            criteria_id: GenericCriteriaConstants[11],
            object_module_id: this.object_data
              ? this.object_data.object_module_id
              : '',
            document_ids: this.objectForm.doc_id,
            jsonb: {
              construction_year: this.objectForm.constructionYear,
              count:
                this.objectForm.count == null || this.objectForm.count == ''
                  ? this.objectForm.count
                  : Number(
                      this.objectForm.count
                        .replaceAll('.', '')
                        .replace(',00', '')
                        .trim()
                    ),
              description: this.objectForm.description,
              manufacturer: this.objectForm.manufacturer,
              model: this.objectForm.model,
              new_value:
                this.objectForm.newValue == null ||
                this.objectForm.newValue == ''
                  ? this.objectForm.newValue
                  : Number(this.removeSymbols(this.objectForm.newValue)),
              new_value_in_year: this.objectForm.newValuePerYear,
              sequence_number: this.objectForm.sequenceNumber,
              model_manufacturer: this.objectForm.modelManufacturer,
              inverter_manufacturer: this.objectForm.inverterManufacturer,
              power: this.objectForm.power
                ? Number(this.removeSymbols(this.objectForm.power))
                : null,
              power_unit_id: this.objectForm.powerUnit,
              is_erevision: this.objectForm.eRivision,
              installation_location_id: this.objectForm.intallationLocation,
              owner: this.objectForm.owner,
              currency_type_id: this.objectForm.currency_type_id,
              doc_id: this.objectForm.doc_id,
              wert_371: this.objectForm.wert_371
                ? Number(this.removeSymbols(this.objectForm.wert_371))
                : null,
            },
          },
        };
      }
      this.saveNewObjectData.criteria.jsonb = JSON.stringify(
        this.saveNewObjectData.criteria.jsonb
      );
      this.serviceCall
        .saveNewObject(this.saveNewObjectData)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          res => {
            this.isFormTouched = false;
            if (!res.HasErrors) {
              this.showSuccessModel = true;

              setTimeout(() => {
                if (this.showSuccessModel) {
                  this.hideModal();
                }
              }, 4000);
            } else {
              this.showFailureModel = true;

              setTimeout(() => {
                if (this.showFailureModel) {
                  this.hideFailureModel();
                }
              }, 4000);

              this.responseText = res.message;
            }
            this.resetForm = !this.resetForm;
            this.newObjectForm.reset();
            this.isSubmitClicked = false;
            this.newObjectForm.patchValue({
              customer: this.selectedCustomer.customer_name,
            });
          },
          () => {
            this.isFormTouched = false;
          }
        );
    }
  }

  emitBuldingFormValue(res) {
    if (!res.HasErrors) {
      this.showSuccessModel = true;
      setTimeout(() => {
        if (this.showSuccessModel) {
          this.hideModal();
        }
      }, 4000);
    } else {
      this.showFailureModel = true;
      setTimeout(() => {
        if (this.showFailureModel) {
          this.hideFailureModel();
        }
      }, 4000);
      this.responseText = res.message;
    }
    this.resetForm = !this.resetForm;
    this.newObjectForm.reset();
    this.isSubmitClicked = false;
    this.newObjectForm.patchValue({
      customer: this.selectedCustomer.customer_name,
    });
  }

  initializeForm(data?) {
    const customerDetails = data ? data.customer_details : '';
    const addressValidation = new FormControl(
      customerDetails ? customerDetails.address : ''
    );
    const objectTypeValidation = new FormControl(data ? data.object_type : '', [
      Validators.required,
    ]);
    const customerValidation = new FormControl(
      customerDetails ? customerDetails.name : '',
      [Validators.required]
    );
    this.newObjectForm = new FormGroup({
      Address: addressValidation,
      objectType: objectTypeValidation,
      customer: customerValidation,
    });
    if (data && data.object_type) {
      this.addressValidation(data.object_type, true);
    }
  }

  public hasFormError = (controlName: string, errorName: string): boolean => {
    return true;
  };

  showAddressPopup() {
    if (this.object_data != null) {
      this.openaddressModal = false;
    } else {
      this.openaddressModal = true;
    }
  }

  hideaddressModal(event?) {
    this.openaddressModal = false;
    this.showSnackBar = true;
    const _that = this;

    setTimeout(() => {
      _that.showSnackBar = false;
    }, 4000);
    this.failureText = event ? event.msg : '';
    if (event) {
      this.autoComplete();
    }
  }

  hideModal() {
    this.showSuccessModel = false;
    this._routeToObjectOverview();
  }

  hideFailureModel() {
    this.showFailureModel = false;
    this.failureText = '';
  }

  removeSymbols(message: string): string {
    if (message) {
      message = message.replaceAll('.', '');
      message = message.replaceAll(',', '.');
      message = message.trim();
    }
    return message;
  }

  scrollValue(key) {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById(key) && document.getElementById(key).focus();
  }
  addressValidation(value, event) {
    if (event) {
      this.isSubmitClicked = false;
      if (
        !(
          value == 'Maschinen/Anlagen (mobil)' ||
          value == 'Person (Einzel)' ||
          value == 'Person (Gruppe)' ||
          value == 'Fahrzeug' ||
          value == 'Standort'
        )
      ) {
        this.isAddressValidation = true;
        this.newObjectForm.controls.Address.setValidators([
          Validators.required,
        ]);
        this.newObjectForm.controls.Address.updateValueAndValidity();
      } else {
        this.isAddressValidation = false;
        this.newObjectForm.controls.Address.clearValidators();
        this.newObjectForm.controls.Address.updateValueAndValidity();
      }
      this.newObjectForm.controls.Address.updateValueAndValidity();
    }
  }

  private _routeToObjectOverview(): void {
    this.router.navigate([ROUTES_PATH.OBJECT_MODULE], {
      queryParams: {
        liztu: btoa(this.liztu_vn_kdnr),
      },
    });
  }

  private _initRoleFields(): void {
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._initRoleFields();
      });
  }
}
