import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Subsidiary } from '@shared/models/subsidiary.model';
import { CustomerGeneralData } from '@shared/interfaces/customer-general-data.interface';

@Injectable({ providedIn: 'root' })
export class CustomerSharedService {
  private readonly _customerId$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly customerId$ = this._customerId$.asObservable();

  private readonly _selectedCustomerDivisionId$: BehaviorSubject<
    string | null
  > = new BehaviorSubject<string | null>(null);
  readonly selectedCustomerDivisionId$ =
    this._selectedCustomerDivisionId$.asObservable();

  private readonly _selectedCustomerId$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly selectedCustomerId$ = this._selectedCustomerId$.asObservable();

  private readonly _selectedCustomerType$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly selectedCustomerType$ = this._selectedCustomerType$.asObservable();

  private readonly _selectedCustomerName$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly selectedCustomerName$ = this._selectedCustomerName$.asObservable();

  private readonly _customerName$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  readonly customerName$ = this._customerName$.asObservable();

  private readonly _customerLiztu$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  readonly customerLiztu$ = this._customerLiztu$.asObservable();

  private readonly _subsidiaries$: BehaviorSubject<Subsidiary[] | null> =
    new BehaviorSubject<Subsidiary[] | null>(null);
  readonly subsidiaries$ = this._subsidiaries$.asObservable();

  private readonly _selectedSource$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(null);
  readonly selectedSource$ = this._selectedSource$.asObservable();

  private readonly _customerGeneralData$: BehaviorSubject<CustomerGeneralData | null> =
    new BehaviorSubject<CustomerGeneralData | null>(null);
  readonly customerGeneralData$ = this._customerGeneralData$.asObservable();

  private _includeForFuture = false;
  get includeForFuture(): boolean {
    return this._includeForFuture;
  }
  set includeForFuture(value: boolean) {
    this._includeForFuture = value;
  }

  saveCustomerId(cId: string): void {
    this._customerId$.next(cId);
  }

  getCustomerId(): string | null {
    return this._customerId$.value;
  }

  selectCustomerId(customerId: string): void {
    this._selectedCustomerId$.next(customerId);
  }

  getSelectedCustomerId(): string | null {
    return this._selectedCustomerId$.value;
  }

  selectCustomerType(customerType: string): void {
    this._selectedCustomerType$.next(customerType);
  }

  getSelectedCustomerName(): string | null {
    return this._selectedCustomerName$.value;
  }

  selectCustomerName(customerName: string): void {
    this._selectedCustomerName$.next(customerName);
  }

  getSelectedCustomerType(): string | null {
    return this._selectedCustomerType$.value;
  }

  selectCustomerDivisionId(customerDivisionId: string | null): void {
    this._selectedCustomerDivisionId$.next(customerDivisionId);
  }

  getSelectedCustomerDivisionId(): string | null {
    return this._selectedCustomerDivisionId$.value;
  }

  saveCustomerName(name: string | null): void {
    this._customerName$.next(name);
  }

  getCustomerName(): string | null {
    return this._customerName$.value;
  }

  saveCustomerLiztu(liztu: number | null): void {
    this._customerLiztu$.next(liztu);
  }

  getCustomerLiztu(): number | null {
    return this._customerLiztu$.value;
  }

  saveSubsidiaries(subsidiaries: Subsidiary[] | null): void {
    this._subsidiaries$.next(subsidiaries);
  }

  selectSource(source: boolean | null): void {
    this._selectedSource$.next(source);
  }

  getSelectedSource(): boolean | null {
    return this._selectedSource$.value;
  }

  getSubsidiaries(): Subsidiary[] | null {
    return this._subsidiaries$.value;
  }

  getCustomerGeneralData(): CustomerGeneralData {
    return this._customerGeneralData$.value;
  }

  saveCustomerGeneralData(customerGeneralData: CustomerGeneralData): void {
    this._customerGeneralData$.next(customerGeneralData);

    this.selectCustomerDivisionId(
      customerGeneralData?.customer_status?.division_id
    );
    this.selectCustomerId(customerGeneralData?.customer_info?.customer_id);
    this.selectCustomerType(customerGeneralData?.customer_status?.type);
    this.selectSource(customerGeneralData?.is_source_ivm);
    this.saveCustomerLiztu(customerGeneralData?.customer_info?.liztu_vn_kdnr);
    this.selectCustomerName(customerGeneralData?.customer_info?.customer_name);
  }
}
