export const CLAIMS_API = {
  CLAIMS_API_URL: '/api/v1/claims',
  CLAIMS_CONTACT_PERSONS: `/api/v1/claims/contact_persons`,
  CLAIMS_ACTION_BAR: '/api/v1/claims/action_bar',
  CLAIMS_IVM_FLAG: '/api/v1/claims/ivm_sync_flag',
  CLAIMS_PAYMENT_OVERVIEW: '/api/v1/claims',
  CLAIMS_PAYMNET_MANAGEMENT: '/api/v1/claims/payment_management',
  CLAIMS_UPDATE_LOCK_STATE: '/api/v1/claims/update_lock_state',
};

export const METHOD_NAMES = {
  FILTERDATA: 'filterData',
};

export const DAMAGE_DETAILS_GUARD = {
  GUARD_HEADING: 'save-changes.Nicht_gespeicherte',
  GUARD_SUB_HEADING: 'form_generator.cancel_form',
  YES: 'objectModule.Yes',
  NO: 'objectModule.No',
};
export const INSURER_API = {
  INSURER_API_URL: '/api/v1/insurer/overview',
};

export enum ContractClaimColumns {
  ID = 'id',
  DATE = 'date',
  OBJECT = 'object',
  STATUS = 'status',
  CONTRACT_NUMBER = 'contract_number',
  TYPE = 'type',
  LOCATION = 'location',
  SP_COSTS = 'sb',
  PAYMENT_RESERVE = 'payment',
  YOUR_IC_DAMANGE_NUMBER = 'external_number',
  ACTION = 'action',
}

export const CONTRACT_CLAIM_COLUMNS = [
  ContractClaimColumns.ID,
  ContractClaimColumns.DATE,
  ContractClaimColumns.OBJECT,
  ContractClaimColumns.STATUS,
  ContractClaimColumns.CONTRACT_NUMBER,
  ContractClaimColumns.TYPE,
  ContractClaimColumns.LOCATION,
  ContractClaimColumns.SP_COSTS,
  ContractClaimColumns.PAYMENT_RESERVE,
  ContractClaimColumns.YOUR_IC_DAMANGE_NUMBER,
  ContractClaimColumns.ACTION,
];

export const CONTRACT_CLAIM_COLUMN_HEADING = {
  ID: '',
  DATE: 'claims.SCHADENDATUM_text',
  OBJECT: 'claims.OBJEKT_text',
  STATUS: 'claims.STATUS_text',
  CONTRACT_NUMBER: 'claims.VERTRAGSNUMMER_text',
  TYPE: 'claims.SCHADENART_text',
  LOCATION: 'claims.SCHADENORT_text',
  SELF_PARTICIPATION: 'claims.SELBSTBETEILIGUNG_text',
  COSTS: 'claims.KOSTEN_text',
  PAYMENT: 'claims.ZAHLUNG_text',
  RESERVE: 'claims.RESERVE_text',
  YOUR_IC_DAMANGE_NUMBER: 'claims.your_ic_damage_number',
  ACTION: 'claims.AKTION_text',
};
