import { ActioButtons } from '@shared/models/table.model';

export const MULTILINGUAL_CONSTS = {
  GENERAL_INSURER_DETAILS: 'insurer_management.general_insurer_details',
  PAGE_BREADCRUMB_TEXT: 'insurer_management.insurer_management_text',
  CONTACT_PERSON: 'insurer_management.contact_person_text',
  COMMUNICATION: 'insurer_management.communication',
  BANK_ACCOUNT_DETAILS: 'insurer_management.bank_account_details',
  AGENCY_NUMBER: 'insurer_management.agency_number',
  BROKER_COMMISSION: 'insurer_management.broker_commission',
  RESET_TEXT: 'contact-manager.Reset_Text',
  EDIT_TOOLTIP: 'product-management.EDIT_text',
  DELETE_TOOLTIP: 'user-data.Löschen_tooltip',
  SELECT_INSURER: 'insurer-details.select_insurer',
  // delete confirmation
  DELETE_HEADING: 'objectModule.Delete_popup_heading',
  DELETE_SUB_HEADING: 'objectModule.Delete_popup_text', // form_generator.delete_form_text
  CANCEL: 'zurs-geo.cancel_label',
  NO: 'contact-manager.NEIN_text',
  SUCCESS: 'contact-manager.JAL‎ÖSCHEN_text',
  SELECT_COMMUNICATION:
    'insurer_management.communication_tab.select_communication',
  COMMUNICATION_DETAILS:
    'insurer_management.communication_tab.communication_details',
  AGENCY_NUMBER_FILTER_PLACEHOLDER:
    'insurer_management.broker_commision_tab.agency_number_filter_placeholder',
  SPARTE_FILTER_PLACEHOLDER:
    'insurer_management.broker_commision_tab.sparte_filter_placeholder',
};

export const GF_FORM_FIELDS_CONST = {
  STATUS: 'status',
  BAFINID: 'bafin_id',
  BAFINNUMBER: 'bafin_number',
  INSURER_NAME: 'insurer_name',
  ADDRESS: 'address',
  STREET: 'street',
  HOUSE_NUMBER: 'house_number',
  POST_CODE: 'post_code',
  CITY: 'city',
  ABBREVIATION: 'abbreviation',
  VALID_FROM: 'valid_from',
  VALID_TO: 'valid_to',
};

export const IMAGE_CONST = {
  GENERAL_INSURER:
    'assets/images/insurer-management/General_Insurer_Details.svg',
  CONTACT_PERSON: 'assets/images/insurer-management/Contact_Person_Insurer.svg',
  COMMUNICATION: 'assets/images/insurer-management/Commuication_Insurer.svg',
  BANK_ACCOUNT_DETAILS:
    'assets/images/insurer-management/Bank_Account_Details.svg',
  AGENCY_NUMBER: 'assets/images/insurer-management/Agency_Number_Insurer.svg',
  BROKER_COMMISION: 'assets/images/insurer-management/Broker_Commission.svg',
};

export const IM_CONTACT_PERSON_FORM_FIELDS = {
  SALUTATION: 'salutation',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ROLE: 'role',
  MAIN_CONTACT_PERSON: 'main_contact_person',
  COMMENT: 'comment',
};

export const API_ENDPOINT_URL = {
  ADD_UPDATE_INSURER: '/api/v1/insurer',
  GET_CONTACT_PERSON: '/api/v1/contact_person/',
  GET_CONTACT_PERSON_ROLES: '/api/v1/contact_persons/roles',
  GET_SALUTATION: '/api/v1/customer/salutation',
  GET_INSURER: '/api/v1/insurer',
  CHECK_DUPLICATE_ABBREVIATION: '/api/v1/insurer/abbreviation',
  CHECK_DUPLICATE_BAFFIN_ID: '/api/v1/insurer/baffin',
  GET_BAFFIN_DATA_LIST: '/api/v1/insurer/baffin_data',
  GET_COMMUNICATION_OVERVIEW_DATA: '/api/v1/communication_details/overview',
  GET_COMMUNICATION_TYPE:
    '/api/v1/product/lookup_data_2?lookup_type_name=communication_type',
  DELETE_COMMUNICATION: '/api/v1/communication_details',
  GET_INSURER_OVERVIEW: '/api/v1/insurer/overview',
  GET_INSURER_FILTER_LIST: '/api/v1/insurer/filter_data',
  GET_INSURER_ACTION_BAR: '/api/v1/insurer/action_bar',
  GET_BROKER_COMMISION: '/api/v1/broker_commission/overview',
  GET_AGENCY_NUMBER: '/api/v1/agency_number',
  DELETE_BROKER_COMMISSION: '/api/v1/broker_commission',
};
export const LOOKUP_CONST = {
  INSURER_STATUS: 'insurer_status',
};
export interface BAFFIN_CONST {
  id: any;
  name: any;
}
export interface ADD_GENERAL_DATA_REQUEST {
  insurer_id: number;
  baffin_id: number;
  baffin_number: number;
  insurer_name: string;
  user_id: any;
  address: string;
  zipcode: any;
  street: string;
  house_number: any;
  city: string;
  status_id: number;
  abbreviation: string;
  valid_from: any;
  valid_to: any;
}
export const COLUMN_NAMES = {
  COMMUNICATION_TYPE: 'communication_type',
  CONTACT: 'contact',
  DESCRIPTION: 'description',
  ACTION: 'action',
};
export const COLUMNS = [
  COLUMN_NAMES.COMMUNICATION_TYPE,
  COLUMN_NAMES.CONTACT,
  COLUMN_NAMES.DESCRIPTION,
  COLUMN_NAMES.ACTION,
];
export const COMMUNICATION_COLUMN_HEADINGS = {
  COMMUNICATION_TYPE:
    'customer-management.communication-details.communication-type',
  CONTACT: 'customer-management.communication-details.contact',
  DESCRIPTION: 'customer-management.communication-details.Description',
  ACTION: 'new-claims.contact_person.action',
};
export interface COMMUNICATION_DATA {
  id?: number | string;
  communication_type: string;
  contact: string;
  description: number;
  communication_type_id: number;
  action: ActioButtons[];
}
export interface Tooltip {
  tooltipText: string;
  tooltipPlacement: string;
}
export const COMMUNICATION_FILTER_FORM_CONTROL = {
  DESCRIPTION: 'Select Communication',
};
export interface GET_COMMUNICATION_DATA_REQUEST {
  source_id: number;
  source_type: string;
}
export interface CommunicationTableData {
  Id: number;
  communication_type: string;
  contact: string;
  description: number;
  communication_type_id: number;
}
export const COMMUNICATION_ACTION_METHOD_NAMES = {
  EDIT_COMMUNICATION: 'editCommunication',
  DELETE_COMMUNICATION: 'deleteCommunication',
};

export interface InsurerOverviewTable {
  id: number;
  insurer_name: string;
  status: null;
  bafin_id: number;
  updated_by: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  action: ActioButtons[];
}

export interface InsurerOverviewTableData {
  InsurerId: number;
  InsurerName: string;
  Status: null;
  BafinId: number;
  UpdatedBy: string;
  CreatedBy: string;
  CreatedAt: string;
  UpdatedAt: string;
  Action: any;
}
export const INSURER_TABS = {
  GENERAL_INSURER_DETAILS: 'General Insurer Details',
  CONTACT_PERSON: 'Contact Person',
  COMMUNICATION: 'Communication',
  BANK_ACCOUNT_DETAILS: 'Bank Account Details',
  AGENCY_NUMBER: 'Agency Number',
  BROKER_COMMISSION: 'Broker Commission',
  DOCUMENTS: 'Documents',
  APPOINTMENTS: 'Appointments',
};
export const INSURER_ACTION_BUTTON = {
  ADD_BROKER_COMMISSION: 'Add Broker Commission',
};
export const BROKER_COMMISSION_ACTION_METHOD_NAMES = {
  EDIT_BROKER_COMMISSION: 'editBrokerCommission',
  DELETE_BROKER_COMMISSION: 'deleteBrokerCommission',
};
export const BROKER_COMMISSION_FILTER_FORM_CONTROL = {
  AGENCY_NUMBER: 'agenecy_number',
  SPARTE: 'sparte',
  COMMISSION_TYPE: 'commission_type',
};
export interface BrokerCommissionTableData {
  Id: number;
  agency_number: any;
  sparte: any;
  product: any;
  commission_in_per: any;
  commission_amount: any;
  commission_type: any;
  valid_from: any;
  valid_to: any;
}
export interface BROKER_COMMISSION_DATA {
  id?: number | string;
  agency_number: string;
  sparte: string;
  product: number;
  commission_in_per: number;
  commission_amount: any;
  commission_type: any;
  valid_from: any;
  valid_to: any;
  action: ActioButtons[];
}
export const BROKER_COLUMN_NAMES = {
  AGENCY_NUMBER: 'agency_number',
  SPARTE: 'sparte',
  PRODUCT: 'product',
  COMMISSION_IN_PERCENTAGE: 'commission_in_per',
  COMMISSION_AMOUNT: 'commission_amount',
  COMMISSION_TYPE: 'commission_type',
  VALID_FROM: 'valid_from',
  VALID_TO: 'valid_to',
  ACTION: 'action',
};
export const BROKER_COLUMNS = [
  BROKER_COLUMN_NAMES.AGENCY_NUMBER,
  BROKER_COLUMN_NAMES.SPARTE,
  BROKER_COLUMN_NAMES.PRODUCT,
  BROKER_COLUMN_NAMES.COMMISSION_IN_PERCENTAGE,
  BROKER_COLUMN_NAMES.COMMISSION_AMOUNT,
  BROKER_COLUMN_NAMES.COMMISSION_TYPE,
  BROKER_COLUMN_NAMES.VALID_FROM,
  BROKER_COLUMN_NAMES.VALID_TO,
  BROKER_COLUMN_NAMES.ACTION,
];
export const BROKER_COMMISSION_COLUMNS = [
  BROKER_COLUMN_NAMES.SPARTE,
  BROKER_COLUMN_NAMES.PRODUCT,
  BROKER_COLUMN_NAMES.COMMISSION_TYPE,
  BROKER_COLUMN_NAMES.COMMISSION_IN_PERCENTAGE,
  BROKER_COLUMN_NAMES.COMMISSION_AMOUNT,
  BROKER_COLUMN_NAMES.VALID_FROM,
  BROKER_COLUMN_NAMES.VALID_TO,
  BROKER_COLUMN_NAMES.ACTION,
];
export const BROKER_COMMISSION_COLUMN_HEADINGS = {
  AGENCY_NUMBER:
    'insurer_management.broker_commision_tab.agency_number_coloumn',
  SPARTE: 'insurer_management.broker_commision_tab.sparte_column',
  PRODUCT: 'insurer_management.broker_commision_tab.product_column',
  COMMISSION_IN_PERCENTAGE:
    'insurer_management.broker_commision_tab.commission_in_per',
  COMMISSION_AMOUNT:
    'insurer_management.broker_commision_tab.commission_amount',
  COMMISSION_TYPE: 'insurer_management.broker_commision_tab.commission_type',
  VALID_FROM: 'insurer_management.broker_commision_tab.valid_from',
  VALID_TO: 'insurer_management.broker_commision_tab.valid_to',
  ACTION: 'new-claims.contact_person.action',
};
export interface GET_BROKER_COMMISSION_DATA_REQUEST {
  agency_number_id: number;
  sparte_id: number;
  page_number: number;
  no_of_records: number;
  source_id: number;
  source_type: string;
  division_id: number;
  commission_type_id: number;
}
