import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocationConstants } from '@shared/constants/location.constants';
import {
  BuildingObject,
  CriteriaRequestDetails,
  GetCriteriaObjectRequest,
  NewCustomerFormRequest,
  NewFormObjectRequest,
  NewFormObjectRequestList,
} from '@shared/models/request.model';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
})
export class LocationComponent extends BaseComponent implements OnInit {
  @Input('object_data') object_data;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @ViewChild('toggleElement') ref: ElementRef;
  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  allLocationPrperties = [];
  @ViewChild('nav') nav;
  active = String(LocationConstants.BASIC_LOCATION_DETAILS);
  @Input('locationParentListener') locationParentListener: Subject<boolean>;
  @Output('emitlocationFormValue') emitlocationFormValue =
    new EventEmitter<any>();
  @Input('newCustomerFormReq') newCustomerFormReq: NewCustomerFormRequest;
  isresetForm = false;
  criteriaRequestDetails: CriteriaRequestDetails = {
    criteriaList: {},
  };
  buildFormRequest: NewFormObjectRequestList;
  isFormSave: boolean;
  locationForm: FormGroup;
  subscription;

  constructor(
    private serviceCall: ApiCallsService,
    private translate: TranslateService,
    private _toastService: ToastService,
    private readonly _userService: UserService
  ) {
    super();
  }
  ngOnInit() {
    this.allLocationPrperties = [
      {
        name: 'basic-location-details',
        id: 'one',
        selector: 'app-basic-location-details',
        requiredCriteria: true,
        number: LocationConstants.BASIC_LOCATION_DETAILS,
      },
      {
        name: 'additional-general-information',
        id: 'two',
        selector: 'app-additional-general-information',
        requiredCriteria: true,
        number: LocationConstants.ADDITIONAL_GENERAL_INFORMATION,
      },
      {
        name: 'organizational-fire-protection',
        id: 'three',
        selector: 'app-organizational-fire-protection',
        requiredCriteria: true,
        number: LocationConstants.ORGANIZATIONAL_FIRE_PROTECTION,
      },
      {
        name: 'protection-factory-premises',
        id: 'four',
        selector: 'app-protection-factory-premises',
        requiredCriteria: true,
        number: LocationConstants.PROTECTION_OF_THE_FACTORY_PREMISES,
      },
      {
        name: 'other-increses-risk-location',
        id: 'five',
        selector: 'app-other-increses-risk-location',
        requiredCriteria: true,
        number: LocationConstants.OTHER_INCREASES_IN_RISK,
      },
    ];
    this.initializeForm();

    if (this.object_data) {
      this.criteriaRequestDetails.object_module_id =
        this.object_data.object_module_id;
      if (
        this.object_data.criteria_response &&
        this.object_data.criteria_response.criteria_list
      ) {
        if (this.object_data.criteria_response.jsonb) {
          this.criteriaRequestDetails.criteriaList[
            LocationConstants.BASIC_LOCATION_DETAILS
          ] = {
            record_id: this.object_data.criteria_response.record_id,
            saved_criteria: this.object_data.criteria_response.jsonb,
          };
        }
        this.enableSavedCriteria();
      }
    }

    this.subscription = this.locationParentListener
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.saveCriteria(true);
        this.emitlocationFormValue.emit({ HasErrors: false, message: '' });
      });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.buildFormRequest && changes.resetForm) {
      this.isresetForm = !this.isresetForm;
      this.getSelectedCriteria(this.buildFormRequest.criteriaId, false);
    }
  }
  initializeForm() {
    const objectTypeName = new FormControl('location');
    this.locationForm = new FormGroup({
      objectTypeName,
    });
    this.emitFormValue.emit(this.locationForm);
  }
  getSelectedCriteria(criteriaId: number, isSwitchTabEnable = false) {
    if (this.criteriaRequestDetails.object_module_id) {
      const value: GetCriteriaObjectRequest = {
        liztu_vn_kdnr: this.object_data
          ? this.object_data.liztu_vn_kdnr
          : this.newCustomerFormReq.customerName,
        object_type: 'location',
        object_type_no: 12,
        object_module_id: this.criteriaRequestDetails.object_module_id,
        criteria_id: criteriaId,
      };
      this.serviceCall
        .getObjectData(value)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          res => {
            if (
              !res.HasErrors &&
              res.criteria_response &&
              res.criteria_response.record_id
            ) {
              this.criteriaRequestDetails.criteriaList[criteriaId] = {
                record_id: res.criteria_response.record_id,
                saved_criteria: res.criteria_response.jsonb,
              };
            }
            this.criteriaRequestDetails = { ...this.criteriaRequestDetails };
            if (isSwitchTabEnable) {
              this.nav.select(String(criteriaId));
            }
          },
          err => {}
        );
    } else {
      if (isSwitchTabEnable) {
        this.nav.select(String(criteriaId));
      }
    }
  }
  buildEmptyCriteriaRequest(): NewFormObjectRequest {
    const userId = +this._userService.getUserId();
    const liztu = this.object_data
      ? this.newCustomerFormReq.customerName || this.object_data.liztu_vn_kdnr
      : this.newCustomerFormReq.customerName;
    const objectName =
      this.criteriaRequestDetails.criteriaList[
        LocationConstants.BASIC_LOCATION_DETAILS
      ] &&
      this.criteriaRequestDetails.criteriaList[
        LocationConstants.BASIC_LOCATION_DETAILS
      ].saved_criteria &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          LocationConstants.BASIC_LOCATION_DETAILS
        ].saved_criteria
      )['basic-location-details'] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          LocationConstants.BASIC_LOCATION_DETAILS
        ].saved_criteria
      )['basic-location-details'].address_description;
    const value: NewFormObjectRequest = {
      liztu_vn_kdnr: liztu,
      address_id: this.newCustomerFormReq.addressId,
      object_type_id: 12,
      created_by: userId,
      record_id: 0,
      is_update: false,
      criteria: null,
    };
    if (objectName) {
      value.object_name = objectName;
    }
    if (this.criteriaRequestDetails.object_module_id) {
      value.object_module_id = this.criteriaRequestDetails.object_module_id;
    }
    if (this.object_data) {
      value.is_update = true;
      value.updated_by = userId;
    }
    return value;
  }
  buildCriteriaRequest(buildingObject: BuildingObject) {
    const value = this.buildEmptyCriteriaRequest();
    value.criteria = buildingObject;

    if (
      buildingObject.criteria_id == LocationConstants.BASIC_LOCATION_DETAILS
    ) {
      const objectName =
        buildingObject.jsonb &&
        JSON.parse(buildingObject.jsonb)['basic-location-details']
          .address_description &&
        JSON.parse(buildingObject.jsonb)['basic-location-details']
          .address_description;
      value.object_name = objectName;
    }

    if (this.criteriaRequestDetails.object_module_id) {
      buildingObject.object_module_id =
        this.criteriaRequestDetails.object_module_id;
      value.object_module_id = this.criteriaRequestDetails.object_module_id;
    }

    if (
      this.criteriaRequestDetails.criteriaList &&
      this.criteriaRequestDetails.criteriaList[buildingObject.criteria_id]
    ) {
      value.record_id =
        this.criteriaRequestDetails.criteriaList[
          buildingObject.criteria_id
        ].record_id;
    }
    this.buildFormRequest = {
      criteriaId: buildingObject.criteria_id,
      request: value,
    };
  }
  onClick(changeEvent: NgbNavChangeEvent) {
    changeEvent.preventDefault();
    this.getSelectedCriteria(changeEvent.nextId, true);
  }
  enableSavedCriteria() {
    const selectedCriterias =
      this.object_data.criteria_response.criteria_list.map(
        criteria => criteria.criteria_id
      );
    this.allLocationPrperties.forEach(criteria => {
      if (selectedCriterias.indexOf(criteria.number) !== -1) {
        criteria.display = 'block';
        criteria.switchCriteria = true;
      }
    });
  }
  saveCriteria(isFormSave = false) {
    this.locationForm.markAsUntouched();
    this.emitFormValue.emit(this.locationForm);
    if (this.buildFormRequest && this.buildFormRequest.criteriaId) {
      let requestToSave: NewFormObjectRequestList = this.buildFormRequest;
      requestToSave = this.updateCriteriaRequest(requestToSave);
      if (isFormSave) {
        requestToSave.request.is_form_save = isFormSave;
        this.isFormSave = isFormSave;
      }
      this.save(this.buildFormRequest);
      this.buildFormRequest = undefined;
    } else {
      const emptyFormToSave = this.buildEmptyCriteriaRequest();
      if (isFormSave) {
        emptyFormToSave.is_form_save = isFormSave;
        this.isFormSave = isFormSave;
      }
      this.saveEmptyCriteria(emptyFormToSave);
    }
  }
  updateCriteriaRequest(updateObject: NewFormObjectRequestList) {
    updateObject.request.liztu_vn_kdnr = this.object_data
      ? this.newCustomerFormReq.customerName || this.object_data.liztu_vn_kdnr
      : this.newCustomerFormReq.customerName;
    updateObject.request.address_id =
      this.newCustomerFormReq.addressId || updateObject.request.address_id;
    return updateObject;
  }
  saveEmptyCriteria(requestToSave: NewFormObjectRequest) {
    this.serviceCall
      .saveNewObject(requestToSave)
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => {
        if (!res.HasErrors) {
          if (!this.criteriaRequestDetails.object_module_id) {
            this.criteriaRequestDetails.object_module_id = res.object_module_id;
          }
        }
      });
  }
  save(requestToSave: NewFormObjectRequestList) {
    this.serviceCall
      .saveNewObject(requestToSave.request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        res => {
          if (!res.HasErrors) {
            this.showToastMessage(
              this.object_data
                ? 'objectModule.criteria_updated'
                : 'objectModule.criteria_added'
            );
            if (!this.criteriaRequestDetails.object_module_id) {
              this.criteriaRequestDetails.object_module_id =
                res.object_module_id;
            }
            if (
              this.criteriaRequestDetails.criteriaList[requestToSave.criteriaId]
            ) {
              this.criteriaRequestDetails.criteriaList[
                requestToSave.criteriaId
              ].saved_criteria = requestToSave.request.criteria.jsonb;
            }
            if (
              !this.criteriaRequestDetails.criteriaList[
                requestToSave.criteriaId
              ] ||
              !this.criteriaRequestDetails.criteriaList[
                requestToSave.criteriaId
              ].record_id
            ) {
              this.getSelectedCriteria(requestToSave.criteriaId);
            }
          } else {
            this.showToastMessage(
              this.object_data
                ? 'objectModule.criteria_update_failed'
                : 'objectModule.criteria_addition_failed'
            );
          }
        },
        err => {
          this.showToastMessage(
            this.object_data
              ? 'objectModule.criteria_update_failed'
              : 'objectModule.criteria_addition_failed'
          );
        }
      );
  }
  saveCriteriaRequest(buildingObject) {
    this.buildCriteriaRequest(buildingObject.request);
    if (buildingObject.pristine === false) {
      this.locationForm.markAsTouched();
      this.emitFormValue.emit(this.locationForm);
    }
    if (
      buildingObject.isSave &&
      !this.isFormSave &&
      this.newCustomerFormReq.isDiscard !== true
    ) {
      this.saveCriteria();
    }
  }
  showToastMessage(message) {
    this._toastService.showToastMessage(
      document,
      this.translate.instant(message)
    );
  }
}
