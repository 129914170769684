<form [formGroup]="formDiv">

<div class=" d-flex" style="flex-wrap: wrap;align-items: center;"> 
    <div id="changeStyle" formControlname="divOne" (click)="selectSparte()" style="justify-content: center;cursor: pointer;" class="iconStyle">
        <div>
        <a id="icon" style="display: none;" class="tool2"><img alt="" class="tool2" style="color:#2175BD;top: -66px;left: 8px;" src="../../../assets/images/new-icons/Group 2832.png"><span class="tooltiptext2">
         <span><b> Sach </b></span> <br>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </span></a>
        </div>
            <div>
      <img alt="" height="23px" src="../../../assets/images/img3.png"><p>sach</p> 
    </div>
    </div>


    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
    <div style="justify-content: center;" class="iconStyle d-flex"><img alt="" height="23px" src="../../../assets/images/img3.png"></div>
</div>
<div style="margin-top: 22vh;" class="text-center">
    <button  [disabled]="isdisabled" class="nextBtn" (click)="nextOne()">Next</button>
</div>
</form>