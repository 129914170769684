import { Component, Inject } from '@angular/core';
import { COMMON_IMAGES } from '@shared/constants/image-paths/common.constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ConfirmPopupData {
  heading: string;
  subHeading: string;
  cancelBtnText: string;
  successBtnText: string;
}

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent {
  crossImage = COMMON_IMAGES.CAUTION;

  constructor(
    private _dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPopupData
  ) {}

  closeConfirmPopup(): void {
    this._dialogRef.close(false);
  }

  saveConfirm(): void {
    this._dialogRef.close(true);
  }
}
