<form class="row object-module-html" [formGroup]="groupOfPeopleForm" id="groupOfPeopleForm">
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label" *ngIf="!(!groupOfPeopleForm.controls['object_name'].valid &&
              (groupOfPeopleForm.controls['object_name']?.touched || isSubmitClicked) && groupOfPeopleForm.invalid )">
          {{'objectModule.newObjecttype' | translate}}</label>
        <label class="touched-warning" *ngIf="!groupOfPeopleForm.controls['object_name'].valid
                     && (groupOfPeopleForm.controls['object_name']?.touched || isSubmitClicked)
                     && groupOfPeopleForm.invalid ">
          {{'objectModule.newObjecttype_error' | translate}}
        </label>
        <input type="text" maxlength="100" class="input" formControlName="object_name"
          placeholder="{{'objectModule.newObjecttype' | translate}}" title="{{groupOfPeopleForm.controls['object_name'].value}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.LaufendeNummer' | translate }}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" numbersOnly
        type="number" maxlength="5" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)"  class="input numberText" formControlName="sequence_number" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
      <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" numbersOnly maxlength="5" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)" class="input numberText" formControlName="sequence_number" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.Group_description_label' | translate }}
        </label>
        <input type="text" maxlength="100" class="input"
          formControlName="group_description" placeholder="{{'objectModule.Group_description_label' | translate }}"
          title="{{groupOfPeopleForm.controls['group_description'].value}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.Count_of_people_label' | translate }}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="5" pattern="[0-9]*" numbersOnly (keydown)="typeNumber($event, 'keydown',5)"
        (paste)="typeNumber($event, 'paste',5)" class="input numberText" formControlName="number_of_people" placeholder="{{'objectModule.Count_of_people_label' | translate }}">
        <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*" numbersOnly (paste)="typeNumberBrowserSpecific($event, 'paste',5)"
        (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)" class="input numberText" formControlName="number_of_people" placeholder="{{'objectModule.Count_of_people_label' | translate }}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.Occupation_label' | translate }}
        </label>
        <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
          (click)="focusOccupation()" (keyup.enter)="focusOccupation()" (openedChange)="occupationControl.setValue('')"
          formControlName="occupation_id" (selectionChange)="occupationChanged($event)" #occupationDD>
        <input type="text" id="occupationSearch" class="searchYear" maxlength="20" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput  [formControl]="occupationControl">
        <mat-option style="opacity:0.5" >{{'objectModule.select_lable' | translate}}</mat-option>
        <mat-option *ngFor="let ele of occupation"  [value]="ele.id" matTooltip="{{ele.desc}}" >{{ele.desc}}</mat-option>
        </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label">
          {{'objectModule.Occupational_group_label' | translate }}
        </label>
        <br>
        <mat-radio-group formControlName="professional_group">
            <mat-radio-button value="A">
                <span [className]="professionGroup == 'A'  ? 'selectedRadioButton' : '' ">
                  {{'objectModule.GroupA' | translate}}
                </span>
            </mat-radio-button>
            <mat-radio-button value="B">
                <span [className]="professionGroup == 'B'  ? 'selectedRadioButton' : '' ">
                  {{'objectModule.GroupB' | translate}}
                </span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</form>


