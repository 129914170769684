<div class="row outer-form-conainer">
  <form name="search" [formGroup]="formFilter" style="font-size: 13px; color: #4e4e4e">
    <div class="row" style="margin: 0px;">
      <div class="col-md-6 col-sm-6" style="display: flex; padding: 0px;">
        <mat-checkbox formControlName="show_all_from_organization" (change)="showData($event)"
          class="check1 top-Checkbox">
          {{'value-module.show_all_from_organization' | translate}}
        </mat-checkbox>
      </div>
      <div class="col-md-6 col-sm-6" style="display: flex;">
        <mat-checkbox formControlName="show_country_wise_data" (change)="showData($event)" class="check1 top-Checkbox">
          {{'value-module.show_country_wise_data' | translate}}
        </mat-checkbox>
      </div>
    </div>
  </form>

  <div style="text-align:center;padding:10px">
    <button class="ml-2 save-button" type="button" mat-button (click)="saveObject()">
      {{'value-module.Save' | translate}}
    </button>
  </div>
</div>

<div style="height: 64vh;margin-left:10px;overflow: scroll;" *ngIf="isShow">

  <table class="table table-border value-table" id="generalTable">
    <thead>
      <tr>
        <th style="background-color: #e7e7e7; vertical-align: top;" class="table-border">
          <label style="font-weight: 600;" class="label font-class">{{"value-module.question" | translate}}:
            {{lastUpdatedDate | dateFormat}}</label>
        </th>
        <th style="font-weight: 600;" class="table-border" *ngFor="let item of customer_data; let i = index" rowspan="2"
          [style.background-color]="item.customer_type== CUSTOMER_TYPES.MUTTER ? '#ff9000' : '#2175BD'">
          <div>
            <label class="label font-class" style="font-weight: 600;"
              [style.color]="item.customer_type == CUSTOMER_TYPES.MUTTER ? '#303030' : 'white'"
              [title]="item.customer_name">{{item.customer_name}} </label>
            <br>
            <label class="label font-class"
              [style.color]="item.customer_type == CUSTOMER_TYPES.MUTTER ? '#303030' : 'white'"
              [title]="item.address">{{item.address}}</label>
            <br>
            <label class="label font-class"
              [style.color]="item.customer_type == CUSTOMER_TYPES.MUTTER ? '#303030' : 'white'">{{item.country}}</label>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of questionData; let i = index;" class="table-border">
        <td class="table-border font-class"> {{"value-module.questions." + item.question_id | translate}}</td>
        <td class="table-border font-class" *ngFor="let customer of customer_data;let j = index;"
          [class.readonly-wrapper]="item.read_only || disableQue(i, j)">
          <div class="row">
            <div *ngIf="item.data_type == 1" class="col-sm-10">
              <div class="smallInput" style="width: 100%; ">
                <div [ngClass]="item.currency_dropdown ? 'bottomMargin':''">
                  <mat-select id="currencyDropdown"
                    (selectionChange)="currancyChanged($event, questions[j].questions_data[i])"
                    *ngIf="item.currency_dropdown" class="dropdownStyle currencyDrop currecnyStyle" [value]=""
                    [disableOptionCentering]="true" title="{{questions[j].questions_data[i].selectedCurrency}}"
                    panelClass="currencyTypeDropdown" #currencyDD [(ngModel)]="questions[j].questions_data[i].currancy"
                    tabIndex="{{(100*j)+i+1}}">
                    <mat-select-trigger>
                      <span>{{questions[j].questions_data[i].currancySymbol || this.currancySymbol}}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let ele of currencyTypes" [value]="ele.id" matTooltip="{{ele.cur_symbol}}">
                      <span style="display: flex;">
                        <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                        <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                      </span>
                    </mat-option>
                  </mat-select>
                  <input tabindex="{{(100*j)+i+2}}" type="text" [class.currecnyInput]="item.currency_dropdown"
                    [maxlength]="employeeQue.includes(item.question_id) ? 6 : 15"
                    *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input newValue"
                    (blur)="intValidation($event, questions[j].questions_data[i]); valueValidation(i, j)"
                    id="{{item.question_id}}" numbersOnly [allowZero]="true"
                    [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only || disableQue(i, j)"
                    (ngModelChange)="auditValue(questions[j].questions_data[i])">
                  <input tabindex="{{(100*j)+i+3}}" type="text" [class.currecnyInput]="item.currency_dropdown"
                    [maxlength]="employeeQue.includes(item.question_id) ? 6 : 15"
                    *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"
                    (blur)="intValidation($event, questions[j].questions_data[i]); valueValidation(i, j)"
                    id="{{item.question_id}}" numbersOnly [allowZero]="true"
                    [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only || disableQue(i, j)"
                    (ngModelChange)="auditValue(questions[j].questions_data[i])">
                </div>
                <span class="error" *ngIf="questions[j].questions_data[i].error">
                  {{questions[j].questions_data[i].error}}</span>
                <span class="error"
                  *ngIf="!questions[j].questions_data[i].error && questions[j].questions_data[i].error1">
                  {{questions[j].questions_data[i].error1}}</span>
                <span class="error"
                  *ngIf="!questions[j].questions_data[i].error && !questions[j].questions_data[i].error1 && questions[j].questions_data[i].error2">
                  {{questions[j].questions_data[i].error2}}</span>
                <span class="error"
                  *ngIf="!questions[j].questions_data[i].error && !questions[j].questions_data[i].error1  && !questions[j].questions_data[i].error2 && questions[j].questions_data[i].error3">
                  {{questions[j].questions_data[i].error3}}</span>
              </div>
            </div>
            <div *ngIf="item.data_type == 3" class="col-sm-10">

              <div class="smallInput" style="width: 100%;">
                <div [ngClass]="item.currency_dropdown ? 'bottomMargin':''">
                  <mat-select id="currencyDropdown"
                    (selectionChange)="currancyChanged($event, questions[j].questions_data[i])"
                    *ngIf="item.currency_dropdown" class="dropdownStyle currencyDrop currecnyStyle" [value]=""
                    [disableOptionCentering]="true" title="{{questions[j].questions_data[i].selectedCurrency}}"
                    panelClass="currencyTypeDropdown" #currencyDD [(ngModel)]="questions[j].questions_data[i].currancy"
                    tabIndex="{{(100*j)+i+1}}">
                    <mat-select-trigger>
                      <span>{{questions[j].questions_data[i].currancySymbol || this.currancySymbol}}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let ele of currencyTypes" [value]="ele.id" matTooltip="{{ele.cur_symbol}}">
                      <span style="display: flex;">
                        <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                        <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                      </span>
                    </mat-option>
                  </mat-select>
                  <input tabindex="{{(100*j)+i+2}}" type="text" [class.currecnyInput]="item.currency_dropdown"
                    maxlength="8" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                    class="input newValue" (blur)="intDecimalValidation($event)" id="{{item.question_id}}" numbersOnly
                    [allowZero]="true" [(ngModel)]="questions[j].questions_data[i].value"
                    [disabled]="item.read_only || disableQue(i, j)">
                  <input tabindex="{{(100*j)+i+3}}" type="text" [class.currecnyInput]="item.currency_dropdown"
                    maxlength="8" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"
                    (blur)="intDecimalValidation($event)" id="{{item.question_id}}" numbersOnly [allowZero]="true"
                    [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only || disableQue(i, j)">
                </div>
              </div>

            </div>
            <div *ngIf="item.data_type == 2" class="col-sm-10">
              <mat-radio-group id="{{item.question_id}}" [(ngModel)]="questions[j].questions_data[i].value"
                (change)="resetValues($event, i, j)" (ngModelChange)="auditValue(questions[j].questions_data[i])">
                <mat-radio-button class="radio-pad" tabIndex="{{(100*j)+i+1}}" [value]=true>
                  {{'objectModule.warehouse_object.Yes' | translate}}
                </mat-radio-button>
                <mat-radio-button class="radio-pad" tabIndex="{{(100*j)+i+2}}" [value]=false>
                  {{'objectModule.warehouse_object.No' | translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <ng-template #popTemplate>{{'value-module.lastUpdate' | translate}} :
              {{questions[j].questions_data[i].updated_by_name}} <br>{{questions[j].questions_data[i].updated_at | date:
              'dd.MM.yyyy, h:mm' :'UTC +' + (cetOffset / 60)}}</ng-template>
            <img alt="" *ngIf="!item.read_only && questions[j].questions_data[i].updated_by_name"
              src="../../../../assets/images/info-sign.svg" class="infoImage" [tooltip]="popTemplate" placement="bottom"
              containerClass="customClass" (mouseover)="getUserDetails(questions[j].questions_data[i])">
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal modelBackground" [class.showModel]="showSuccessModel">
  <div class="modal-dialog" style="margin-top:13%;width:32%">
    <div class="modal-content" style="border-radius: 8px;    box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);    border: solid 1px #bbb;
         background-color: #fff;padding: 40px 0;    width: 400px;">
      <!-- Modal Header -->
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">

        <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
          <mat-icon>
            <img alt="" height="65px" (click)="showSuccessModel = false;is_update = true;"
              src="../../../assets/images/Check_icon.svg">
          </mat-icon>
        </div>
        <div>
          <button style="position: absolute;
          top: 11px;
          right: 11px;
          outline: none;
          border: none;
          height: 32px;
          width: 36px;">
            <mat-icon style="
            font-size: 16px;
            height: 0px;
            line-height: 1.2;
            background-color: initial;
          " (click)="showSuccessModel = false;is_update = true;">close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
        <span style="font-size: 18px;font-weight: 600;color: #272727;" *ngIf="!is_update">
          {{'value-module.new_success_text' | translate}}
        </span>
        <span style="font-size: 18px;font-weight: 600;color: #272727;" *ngIf="is_update">
          {{'value-module.new_success_text_update' | translate}}
        </span>
      </div>
    </div>
  </div>
</div>