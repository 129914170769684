export const COLUMN_NAMES = {
    BANK_NAME  : 'bank_name',
    IBAN       : 'iban',
    BIC        : 'bic',
    VALID_FROM : 'valid_from',
    SEPA_MANDAT: 'sepa_mandate',
    ACTION     : 'action'
};

export const BD_COLUMN_HEADINGS = {
    BANK_NAME  : 'customer-management.bank_details.bank_name',
    IBAN       : 'customer-management.bank_details.IBAN',
    BIC        : 'customer-management.bank_details.BIC',
    VALID_FROM : 'customer-management.bank_details.sepa_mandate_valid_from',
    SEPA_MANDAT: 'customer-management.bank_details.sepa_mandate',
    ACTION     : 'new-claims.contact_person.action'
};

export const BANK_COLUMNS = [
    COLUMN_NAMES.BANK_NAME,
    COLUMN_NAMES.IBAN,
    COLUMN_NAMES.BIC,
    COLUMN_NAMES.VALID_FROM,
    COLUMN_NAMES.SEPA_MANDAT,
    COLUMN_NAMES.ACTION
];

export const BANK_DETAILS_FORM_FIELDS = {
    DESCRIPTION             : 'description',
    BANK_NAME               : 'bank_name',
    IBAN                    : 'iban',
    BIC                     : 'bic',
    STANDARD_BANK_ACCOUNT   : 'standard_bank_account',
    DIFFERING_ACCOUNT_HOLDER: 'differing_account_holder',
    SEPA_MANDATE            : 'sepa_mandate',
    SEPA_MANDATE_DATE       : 'sepa_mandate_date',
    SEPA_MANDATE_REFERENCE  : 'sepa_mandate_ref',
    SEPA_MANDATE_DOCUMENT   : 'sepa_mandate_document',
    SEPA_DOCUMENT           : 'sepa_document'
};

export const BANK_DETAILS_ACTION_METHOD_NAMES = {
    EDIT_BANK_DETAILS  : 'editBankDetails',
    DELETE_BANK_DETAILS: 'deleteBankDetails'
};
