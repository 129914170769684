import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private _httpClient: HttpClient) {}

  login(token: string): Observable<void> {
    const headers = new HttpHeaders().append('Authorization', token);
    return this._httpClient.get<void>(
      Api_URL.IC_API_URL + Api.AUTHENTICATION,
      { headers }
    );
  }

  logout(): Observable<void> {
    return this._httpClient.post<void>(Api_URL.IC_API_URL + Api.LOGOUT, null);
  }
}
