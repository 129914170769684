import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'app-generic-form-field-frame',
  templateUrl: './generic-form-field-frame.component.html',
  styleUrls: ['./generic-form-field-frame.component.css'],
})
export class GenericFormFieldFrameComponent implements OnInit, OnDestroy {
  @Input('formField') formField;
  @Output('deleteField') deleteField = new EventEmitter<any>();
  @Output('outputField') outputField = new EventEmitter<any>();
  @Input('isFormInUse') isFormInUse;
  @Input('is_disabled') is_disabled;
  @Output('removed_fields') removed_fields = new EventEmitter<any>();
  @Output('formDirty') formDirty = new EventEmitter<any>();
  formGenerator: FormGroup;
  placeholder;
  showWarningPopup = false;
  remove_id: any;
  dropdownData: any[] = [];
  is_download_click = false;
  programmaticChange: any = false;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private _apiService: ApiCallsService,
    private _toastSerice: ToastService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initiateForm();
    // for Dropdown id is 12
    if (this.formField.type_id == 12) {
      this.getDropdownData();
    }
  }

  initiateForm() {
    const formData = this.formField;
    if (formData.type_id == 1) {
      this.placeholder = 'Number';
    }
    if (formData.type_id == 3) {
      this.placeholder = 'TextField';
    }
    if (formData.type_id == 8) {
      this.placeholder = 'DD.MM.YYYY';
    }
    // formData.is_new meaning => form filed is new
    // this.isFormInUse ==> whole form is already in use somewhere
    const label = new FormControl(
      {
        value: formData ? formData.label : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const sequence = new FormControl(
      {
        value: formData ? formData.sequence : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const is_mandatory = new FormControl(
      {
        value: formData ? formData.is_mandatory : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const placeholder = new FormControl(
      {
        value: formData ? formData.placeholder : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const is_single = new FormControl(
      {
        // value: formData ? formData.is_single : null,
        value: (formData && formData.is_single ? formData.is_single : false)
          ? true
          : false,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const is_currency = new FormControl(
      {
        value: formData ? formData.is_currency : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const is_hidden = new FormControl(
      {
        value: formData ? formData.is_hidden : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const is_readonly = new FormControl(
      {
        value: formData ? formData.is_readonly : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const is_default_value = new FormControl(
      {
        value: formData ? formData.is_default_value : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const default_value = new FormControl(
      {
        value: formData ? formData.default_value : null,
        disabled: formData.is_default_value ? false : true,
      },
      []
    );
    const radio_label_first = new FormControl(
      {
        value: formData ? (formData.radio ? formData.radio.first : null) : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const radio_label_second = new FormControl(
      {
        value: formData
          ? formData.radio
            ? formData.radio.second
            : null
          : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    const checkbox_label = new FormControl(
      {
        value: formData
          ? formData.checkbox
            ? formData.checkbox.label
            : null
          : null,
        disabled:
          !this.isFormInUse && !this.is_disabled
            ? false
            : formData.is_new && !this.is_disabled
              ? false
              : true,
      },
      []
    );
    this.formGenerator = new FormGroup({
      label,
      sequence,
      is_mandatory,
      placeholder,
      is_single,
      is_currency,
      radio_label_first,
      radio_label_second,
      checkbox_label,
      is_hidden,
      is_readonly,
      is_default_value,
      default_value,
    });
    function parentMethod(value) {
      this.outputField.emit({
        json: value,
        id: formData.id,
        type_id: formData.type_id,
      });
      this.formDirty.emit(true);
    }

    this.formGenerator?.valueChanges.subscribe(value => {
      if (!this.programmaticChange) {
        if (value.is_default_value === false) {
          this.programmaticChange = true;
          this.formGenerator.get('default_value').disable();
          this.formGenerator.get('default_value').reset();
          this.programmaticChange = false;
          value.default_value = '';
        } else {
          this.programmaticChange = true;
          this.formGenerator.get('default_value').enable();
          this.programmaticChange = false;
        }

        this.outputField.emit({
          json: value,
          id: formData.id,
          type_id: formData.type_id,
        });
        this.formDirty.emit(true);
      }
    });
  }

  cancelField(id) {
    if (this.isFormInUse && !this.formField.is_new) {
      this.showWarningPopup = true;
      this.remove_id = id;
    } else {
      this.deleteField.emit(id);
    }
  }
  getDropdownData() {
    this._apiService
      .getDropdownData(this.formField.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        const keys: any[] = data.data;
        if (data?.data?.length > 0) {
          this.dropdownData = [];
          keys?.forEach(value => {
            this.dropdownData.push(value.description);
          });
        }
        if (this.is_download_click) {
          this.downloadFile(this.dropdownData);
          this.is_download_click = false;
        }
      });
  }
  uploadCSV(event) {
    if (event.target.files?.length > 0) {
      const formdata = new FormData();
      formdata.append('field_uuid', this.formField.id);
      formdata.append('file', event.target.files[0]);
      this._apiService
        .uploadCSV(formdata)
        .pipe(takeUntil(this._destroy$))
        .subscribe((data: any) => {
          this._toastSerice.showToastMessage(document, data.message);
          this.getDropdownData();
        });
    }
  }
  downloadCSV() {
    this.is_download_click = true;
    this.getDropdownData();
  }
  convertToCSV(array) {
    return array
      .map(it => {
        return it;
      })
      .join('\n');
  }
  downloadFile(data, filename = 'data') {
    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      downloadLink.setAttribute('target', '_blank');
    }
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename + '.csv');
    downloadLink.style.visibility = 'hidden';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  hideModal() {
    this.showWarningPopup = false;
  }
  deleteFormField(id) {
    this.removed_fields.emit(id);
    this.deleteField.emit(id);
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
