import { BehaviorSubject, Subject } from 'rxjs';

export class CustomerManagementSharedService {
  private readonly _isEditCustomerOperation$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  readonly isEditCustomerOperation$ =
    this._isEditCustomerOperation$.asObservable();

  readonly addCommunication$: Subject<null> = new Subject<null>();
  readonly addBank$: Subject<null> = new Subject<null>();
  readonly inviteNewUser$: Subject<null> = new Subject<null>();
  readonly addContactPerson$: Subject<null> = new Subject<null>();
  readonly addCustomerRelation$: Subject<null> = new Subject<null>();

  readonly isSalutationNotCustomerChosen$: Subject<boolean> =
    new Subject<boolean>();
  readonly IVMSyncToggle$: Subject<boolean> = new Subject<boolean>();
  readonly isSourceIvm$: Subject<boolean> = new Subject<boolean>();

  saveEditCustomerOperation(value: boolean): void {
    this._isEditCustomerOperation$.next(value);
  }

  getEditCustomerOperation(): boolean {
    return this._isEditCustomerOperation$.value;
  }
}
