import { Injectable } from '@angular/core';
import { BROWSERS_LIST } from '@shared/constants/string.constants';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor() {}
  detectBrowser() {
    const userAgent = navigator.userAgent;
    const browsers = {
      Opera: /Opera|OPR/,
      Chrome: /Chrome/,
      Safari: /Safari/,
      Firefox: /Firefox/,
      Edge: /Edge/,
    };
    for (const browserName in browsers) {
      if (browsers[browserName].test(userAgent)) {
        return browserName;
      }
    }
    return BROWSERS_LIST.UNKNOWN;
  }
}
