<div class="template-repo">
  <div class="row template-repo-filter">
    <div class="col-md-6 col-sm-6 template-repo-filter-field">
      <span class="template-label">{{'template-management.show_templates_label' | translate}}</span>
      <mat-select [(ngModel)]="defaultValue" [title]="defaultTemplateSelected && defaultTemplateSelected.desc"
        class="dropdownStyle inputy switcher">
        <mat-option *ngFor="let source of templates_source" (click)="onTemplateSelect(source)" [value]="source.desc"
          [title]="source.desc">{{source.desc}} </mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 template-repo-filter-button">
      <div class="template-form-container">
        <div class="template-buttons">
          <button class="sync-btn" (click)="getTemplatesByTemplateId(defaultTemplateSelected.id, true)">
            <img alt="" src="../../../../assets/images/new-icons/Sync_Icon_18px.svg">
            {{'attach-template.sync-template' | translate}}</button>
          <button *ngIf="!product_template_labels.includes(defaultTemplateSelected && defaultTemplateSelected.desc)"
          class="save-btn" (click)="saveConfigurations()"><img class="sync-btn-img" alt="" src="../../../../assets/images/save-button.svg"> </button>
        </div>
      </div>
    </div>
  </div>
  <div class="template-repo-body">
    <file-viewer *ngIf="!product_template_labels.includes(defaultTemplateSelected && defaultTemplateSelected.desc)" [availableTemplates]="availableValues" 
    (modifiedSelectedValues)="modifiedSelectedValues($event)" [selectedTemplates]="selectedValues"></file-viewer>
    <template-product-viewer *ngIf="product_template_labels.includes(defaultTemplateSelected && defaultTemplateSelected.desc)" 
    [availableTemplates]="availableValues" (deleteTemplate)="handleDeleteTemplatePopup($event)"></template-product-viewer>
  </div>
</div>
<div id="successToast"></div>