<div class="external-container">
    <div class="row-containers">
        <div class="tree-container">
            <div class="container-heading">
                <span class="criteria-heading">{{'product-management.stepper_common_msg.available_cover_bundle' | translate}}</span>
            </div>
            <div class="stepper-inner">
                <tree-list-component *ngIf="loadTreeData" [treeData]="coveringData" [nodeLevel]="2" [isDisabled]="isTreeDisabled"
                [buttonText]="buttonText" (updatedNodeSelection)="updateNodeSelection($event)" (addNewNode)="addNewNode($event)"
                (deleteNode)="deleteNode($event)"></tree-list-component>
            </div>
        </div>
        <div class="form-container">
            <div class="container-heading">
                <span class="criteria-heading">{{'product-management.step.covering' | translate}}</span>
                <button *ngIf="isAddNewStepEnabled" (click)="closeForm()" class="stepper-form-button">
                    <mat-icon><img alt="" class="product-close-button" src="../../../../assets/images/closeIcon.png"></mat-icon>
                </button>
                <button *ngIf="isAddNewStepEnabled && !isTreeDisabled" (click)="saveForm()" class="stepper-form-button">
                    <mat-icon *ngIf="!isFormTouched"><img alt="" src="../../../../assets/images/Edit_icon.svg"></mat-icon>
                    <mat-icon *ngIf="isFormTouched"><img alt="" src="../../../../assets/images/save-button.svg"></mat-icon>
                </button>
            </div>
            <div class="stepper-inner" *ngIf="isAddNewStepEnabled">
                <app-stepper-form [step]="step" (emitFormValue)="formValues($event)" [consolidateData]="coverBundleconsolidateData"></app-stepper-form>
            </div>
            <div class="empty-stepper-inner" *ngIf="!isAddNewStepEnabled">
                {{'product-management.stepper_common_msg.covering_empty_msg' | translate}}
            </div>
        </div>
    </div>
</div>
<span class="tips-class" *ngIf="consolidateData && consolidateData.productStateId==2"><img alt="" class="tips-img" src="../../../../assets/images/tip_icon_summary.svg">{{'product-management.stepper_common_msg.update_product_nonedit_message' | translate}}</span>
<product-close-alert-dialog *ngIf="showWarningPopUp" (handlePopup)="handlePopup($event)"></product-close-alert-dialog>
<div id="successToast"></div>