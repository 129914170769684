import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ProductModuleConstants } from '@shared/constants/global-constants.constants';
import { readonlyDocument } from '@shared/models/generic.model';
import { LookupConstants } from '@shared/constants/lookup.constants';
import { UploadTypeConstants } from '@shared/constants/product-management.constants';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { ClausesExclusionsData } from '../new-product/new-product.component';
import { catchError, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { LOCALS } from '@shared/constants/local.constants';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import { ProductShareService } from '@core/services/product-share.service';
export interface product_data {
  division: any;
  sparte: any;
  product_name: any;
  product_label: any;
  Underwriting_insurer: any;
  versioning: any;
  valid_from: any;
  valid_to: any;
  automatic_contract_renewal: any;
  cancellation_period: any;
  country_code: any;
  side_agreements: any;
  area_of_coverage: any;
  objects_to_be_insured: any;
  split_declaration: any;
  product_description: any;
  product_status: any;
}
@Component({
  selector: 'app-summary-stepper',
  templateUrl: './summary-stepper.component.html',
  styleUrls: ['./summary-stepper.component.css'],
})
export class SummaryStepperComponent extends BaseComponent implements OnInit {
  product_info_id: string;
  product_data: any;
  sparteFixerCode: any;
  clauses: any;
  userDataValue: LoggedInUser;
  user_id: any;
  divisions: any[];
  division_id: any;
  spartes: any[];
  parent_id: any;
  exclusions: any;
  selectedAVBDocumentsArray: any = {};
  selectedSideLettersArray: any = {};
  division;
  sparte = [];
  product_state: any[];
  cancelation_period: any;
  object_types: any;
  insured_object: any;
  product: any;
  tableData: any;
  product_name: any;
  public_label: any;
  Underwriting_insurer: string;
  versioning: any;
  valid_from: string;
  valid_to: string;
  automatic_contract_renewal: any;
  cancelation_period_name: any;
  country_code: any;
  side_agreements: any;
  area_of_coverage: any;
  objects_to_be_insured: string;
  split_declaration: any;
  product_description: any;
  cancelation: any;
  sparteName: string;
  comingFrom;
  @Input('object_data') parentObjectData;
  @Output('versionDetails') versionDetails = new EventEmitter<any>();
  @Input('isFormUpdated') isFromUpdated;

  showAVBDocumentPopup = false;
  showsideLettersPopup = false;
  selectedDocumentsArray = {};
  uploadProductDetails: readonlyDocument;
  is_disable = true;
  addClause = false;
  addExclusion = false;
  clausesOrExclusionData: ClausesExclusionsData;
  clausesIds = [];
  exclusionsIds = [];
  selected_product_state_id: any;
  coverBundleCount: any;
  coveringsCount: any;
  typeOfDamageCount: any;
  selectLang: LOCALS;
  avbDocumentTypes = [
    UploadTypeConstants.A,
    UploadTypeConstants.AVB,
    UploadTypeConstants.RVB,
    UploadTypeConstants.IZD,
  ];
  constructor(
    private serviceCall: ApiCallsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private _userService: UserService,
    private _productShareService: ProductShareService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userDataValue = this._userService.getLoggedInUser();
    this.selectLang = this._userService.getLocale();
    this.product_info_id = this._productShareService.productModuleId;
    this.user_id = this.userDataValue.user_profile.user_data.user_id;
    this.division_id =
      this.userDataValue.user_profile.customer_details.division_id;
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.parent_id = null;
    const requests = [];
    requests.push(
      this.serviceCall
        .getSparte(
          this.division_id,
          this.parent_id,
          this._userService.getUserRole()
        )
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall
        .getProductLookupData(LookupConstants.PRODUCT_STATE)
        .pipe(catchError(error => of(error)))
    );
    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        const sparteDetails =
          !data[0].hasErrors && data[0].sparte ? data[0].sparte : [];
        const productDetails =
          !data[1].hasErrors && data[1].data ? data[1].data : [];
        if (sparteDetails.length > 0) {
          this.spartes = sparteDetails.filter(
            object => object.is_sparte_group == false
          );
        }
        if (productDetails) {
          this.product_state =
            productDetails &&
            productDetails.sort((a, b) => (a.id < b.id ? -1 : 1));
        }
        if (
          this.product_info_id !== 'null' &&
          this.product_info_id !== undefined &&
          this.product_info_id !== null
        ) {
          this.serviceCall
            .getProductData(Number(this.product_info_id))
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              if (!data.HasErrors) {
                this.product_data = data.product;
                if (this.product_data) {
                  this.formatData(this.product_data);
                }
              }
            });
        }
        this.getSteppersSummary();
      });
  }
  getSteppersSummary() {
    this.serviceCall
      .getStepperCount(this.product_info_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          if (data.stepper_count.length > 0) {
            this.coverBundleCount = data.stepper_count[1]
              ? data.stepper_count[1].count
              : 0;
            this.coveringsCount = data.stepper_count[2]
              ? data.stepper_count[2].count
              : 0;
            this.typeOfDamageCount = data.stepper_count[3]
              ? data.stepper_count[3].count
              : 0;
          } else {
            this.coverBundleCount = 0;
            this.coveringsCount = 0;
            this.typeOfDamageCount = 0;
          }
        }
      });
  }
  getDocumentUploadData(record_id: string) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, '')
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (this.avbDocumentTypes.includes(document.document_type)) {
                this.selectedAVBDocumentsArray[document.document_name] =
                  document;
              }
              if (document.document_type === UploadTypeConstants.SIDE_LETTERS) {
                this.selectedSideLettersArray[document.document_name] =
                  document;
              }
            });
          }
        });
    }
  }
  formatData(product_data: any) {
    this.division = String(product_data.division_names);
    this.sparte = this.spartes?.filter(item => {
      if (item.fixer_code == product_data.sparte_id) {
        return item.description;
      }
    });
    if (this.sparte) {
      for (let i = 0; i < this.sparte.length; i++) {
        this.sparteName = this.sparte[i].description;
        this.sparteName = String(this.sparteName);
      }
    }
    this.serviceCall
      .getProductLookupData(47)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.cancelation_period = data.data;
          this.cancelation_period.filter(item => {
            if (item.id === product_data.cancellation_period_id) {
              this.cancelation_period_name = item.desc;
            }
          });
        }
      });
    this.serviceCall
      .getObjectType('')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.object_types = data.object_types;
        this.insured_object = product_data.object_type_id.map(row => {
          return this.object_types.find(data => data.object_type_id == row)
            ?.object_name;
        });
        this.insured_object = String(this.insured_object);
      });
    this.product_name = product_data.name;
    this.public_label = product_data.public_label;
    this.Underwriting_insurer = String(product_data.insurer_names);
    this.valid_from = product_data.valid_from
      ? moment(product_data.valid_from).format('YYYY-MM-DD')
      : null;
    this.valid_to = product_data.valid_to
      ? moment(product_data.valid_to).format('YYYY-MM-DD')
      : null;
    this.automatic_contract_renewal = product_data.auto_contract_renew
      ? this.selectLang === LOCALS.GERMAN
        ? 'Ja'
        : 'Yes'
      : this.selectLang === LOCALS.GERMAN
        ? 'Nein'
        : 'No';
    this.country_code = product_data.country_name;
    this.side_agreements = product_data.is_side_agreement
      ? this.selectLang === LOCALS.GERMAN
        ? 'Ja'
        : 'Yes'
      : this.selectLang === LOCALS.GERMAN
        ? 'Nein'
        : 'No';
    this.area_of_coverage = product_data.coverage_area_name;
    this.split_declaration = product_data.is_split_declaration
      ? this.selectLang === LOCALS.GERMAN
        ? 'Ja'
        : 'Yes'
      : this.selectLang === LOCALS.GERMAN
        ? 'Nein'
        : 'No';
    this.product_description = product_data.product_desc;
    this.selected_product_state_id = product_data.product_state_id;
    this.versioning =
      this.selected_product_state_id == 1 && !!this.isFromUpdated
        ? this.incrementProductVersion(product_data.version_no)
        : product_data.version_no;
    this.versionDetails.emit({
      selectedState: this.selected_product_state_id,
      originalState: this.product_data.product_state_id,
      product_data,
      selected_product_version_no: this.versioning,
    });
    const clauses_names = [];
    const exclusions_names = [];
    this.sparteFixerCode = product_data
      ? this.spartes?.filter(
          data => data.fixer_code == this.product_data.sparte_id
        )[0].fixer_code
      : null;
    this.sparteFixerCode &&
      this.serviceCall
        .getClausesOrExclusions(true, this.sparteFixerCode)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.clauses = data.clauses_exclusions;
          product_data
            ? product_data.clauses.map(row => {
                clauses_names.push(
                  this.clauses.find(data => data.id == row)?.name
                );
              })
            : [];
        });
    this.sparteFixerCode &&
      this.serviceCall
        .getClausesOrExclusions(false, this.sparteFixerCode)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.exclusions = data.clauses_exclusions;
          product_data
            ? product_data.exclusions.map(row => {
                exclusions_names.push(
                  this.exclusions.find(data => data.id == row)?.name
                );
              })
            : [];
        });
    this.getDocumentUploadData(this.product_info_id);
    this.clausesIds = product_data.clauses;
    this.exclusionsIds = product_data.exclusions;
  }

  showDocumentPopup() {
    return this.showAVBDocumentPopup || this.showsideLettersPopup;
  }
  uploadAVBDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedAVBDocumentsArray };
    this.showAVBDocumentPopup = true;
    this.uploadProductDetails = {
      documentSource: 'Product Module',
      documentType: UploadTypeConstants.AVB,
      documentView: 'summary',
    };
  }
  uploadSideLettersPopup() {
    if (this.side_agreements !== 'No') {
      this.selectedDocumentsArray = { ...this.selectedSideLettersArray };
      this.showsideLettersPopup = true;
      this.uploadProductDetails = {
        documentSource: 'Product Module',
        documentType: UploadTypeConstants.SIDE_LETTERS,
        documentView: 'summary',
      };
    }
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showAVBDocumentPopup) {
      this.showAVBDocumentPopup = false;
    }
    if (this.showsideLettersPopup) {
      this.showsideLettersPopup = false;
    }
    this.selectedDocumentsArray = {};
  }
  showClausesExclusionPopup() {
    return this.addClause || this.addExclusion;
  }
  hideClausesExclusionPopup() {
    this.addClause = false;
    this.addExclusion = false;
  }
  addClauses() {
    this.sparteFixerCode &&
      this.serviceCall
        .getClausesOrExclusions(true, this.sparteFixerCode)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.clausesOrExclusionData = {
            data: data.clauses_exclusions,
            selected_clauses: this.clausesIds,
            selected_exclusions: null,
          };
          this.comingFrom = ProductModuleConstants.clause;
          this.addClause = true;
        });
  }
  addExclusions() {
    this.sparteFixerCode &&
      this.serviceCall
        .getClausesOrExclusions(false, this.sparteFixerCode)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.clausesOrExclusionData = {
            data: data.clauses_exclusions,
            selected_clauses: null,
            selected_exclusions: this.exclusionsIds,
          };
          this.comingFrom = ProductModuleConstants.exclusion;
          this.addExclusion = true;
        });
  }
  onVersionChange() {
    this.versioning = this.getVersionId();
    this.versionDetails.emit({
      selectedState: this.selected_product_state_id,
      originalState: this.product_data.product_state_id,
      product_data: this.product_data,
      selected_product_version_no: this.versioning,
    });
  }

  getVersionId() {
    const activeWarning =
      'product-management.stepper_common_msg.update_product_to_active_warning';
    const retiriedWarning =
      'product-management.stepper_common_msg.update_product_to_retired_warning';
    const draftWarning =
      'product-management.stepper_common_msg.update_product_warning';
    let product_version: string = this.product_data.version_no;
    if (this.product_data.product_state_id == 1) {
      if (this.selected_product_state_id == 1) {
        product_version = !!this.isFromUpdated
          ? this.incrementProductVersion(this.product_data.version_no)
          : this.product_data.version_no;
      } else if (this.selected_product_state_id == 2) {
        product_version = this.incrementProductVersion(
          this.product_data.version_no
        );
        this.showProductStateUpdateAlert(activeWarning);
      } else if (this.selected_product_state_id == 3) {
        product_version = this.product_data.version_no;
        this.showProductStateUpdateAlert(retiriedWarning);
      }
    }

    if (this.product_data.product_state_id == 2) {
      if (this.selected_product_state_id == 1) {
        product_version = 'V0';
        this.showProductStateUpdateAlert(draftWarning);
      } else if (this.selected_product_state_id == 2) {
        product_version = this.product_data.version_no;
      } else if (this.selected_product_state_id == 3) {
        product_version = this.product_data.version_no;
        this.showProductStateUpdateAlert(retiriedWarning);
      }
    }
    return product_version;
  }

  incrementProductVersion(version_no: string) {
    const versionId = Number(version_no.split('V')[1]);
    return 'V' + (versionId + 1);
  }

  showProductStateUpdateAlert(message: string) {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(message),
      },
      disableClose: false,
    });
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
