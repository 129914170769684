import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css'],
})
export class WarehouseComponent implements OnInit {
  warehouseForm: FormGroup;
  browser: string;
  storageTypes = [];
  kltValue: any[];
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  actualnewValue = 0;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    public validationService: ValidationService,
    private serviceCall: ApiCallsService
  ) {
    this.browser = this.detectBrowser();
    this.serviceCall
      .getLookupData(28)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.storageTypes = data.data;
        this.kltValue = this.storageTypes.filter(
          _ => _.desc.toLowerCase() == 'klt'
        )[0].id;
      });
  }

  ngOnInit(): void {
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  initForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[BuildingConstants.WAREHOUSE] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.WAREHOUSE]
          .saved_criteria
      );
    this.assignAttributeValues(formData);
    this.isFormValueUpdated = false;
    this.warehouseForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.warehouseForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  assignAttributeValues(formData: any) {
    const total_storage_area = new FormControl(
      {
        value: formData
          ? this.validationService.setSqmValue(
              formData.warehouse.total_storage_area
            )
          : null,
        disabled: false,
      },
      []
    );
    const stored_goods = new FormControl(
      {
        value: formData ? formData.warehouse.stored_goods : null,
        disabled: false,
      },
      []
    );
    const number_of_fire_compartments = new FormControl(
      {
        value: formData
          ? this.validationService.setSqmValue(
              formData.warehouse.number_of_fire_compartments
            )
          : null,
        disabled: false,
      },
      []
    );
    const largest_storage_area = new FormControl(
      {
        value: formData
          ? this.validationService.setSqmValue(
              formData.warehouse.largest_storage_area
            )
          : null,
        disabled: false,
      },
      []
    );
    const type_of_storage = new FormControl(
      {
        value: formData ? formData.warehouse.type_of_storage : null,
        disabled: false,
      },
      []
    );
    const is_fire_resistant_separation_of_the_KLT = new FormControl(
      {
        value: formData
          ? formData.warehouse.is_fire_resistant_separation_of_the_KLT
          : null,
        disabled: false,
      },
      []
    );
    const is_extinguishing_system_one = new FormControl(
      {
        value: formData ? formData.warehouse.is_extinguishing_system : null,
        disabled: false,
      },
      []
    );
    const Type_of_packaging = new FormControl(
      {
        value: formData ? formData.warehouse.Type_of_packaging : null,
        disabled: false,
      },
      []
    );

    const is_high_bay_warehouse = new FormControl(
      {
        value: formData ? formData.warehouse.is_high_bay_warehouse : null,
        disabled: false,
      },
      []
    );
    const is_storage_of_packaging_materials = new FormControl(
      {
        value: formData
          ? formData.warehouse.is_storage_of_packaging_materials
          : null,
        disabled: false,
      },
      []
    );

    const storage_height_top_edge_of_stored_goods_in_m = new FormControl(
      {
        value: formData
          ? formData.warehouse.high_bay_warehouse
              .storage_height_top_edge_of_stored_goods_in_m
          : null,
        disabled: false,
      },
      []
    );
    const is_extinguishing_system_two = new FormControl(
      {
        value: formData
          ? formData.warehouse.high_bay_warehouse.is_extinguishing_system
          : null,
        disabled: false,
      },
      []
    );

    const fire_resistant_partition = new FormControl(
      {
        value: formData
          ? formData.warehouse.storage_of_packaging_materials
              .fire_resistant_partition
          : null,
        disabled: false,
      },
      []
    );
    const extinguishing_system = new FormControl(
      {
        value: formData
          ? formData.warehouse.storage_of_packaging_materials
              .extinguishing_system
          : null,
        disabled: false,
      },
      []
    );

    const is_silo_tank_farm = new FormControl(
      {
        value: formData ? formData.warehouse.is_silo_tank_farm : null,
        disabled: false,
      },
      []
    );

    const silo_tank_farm = new FormControl(
      {
        value: formData
          ? formData.warehouse.silo_tank_farm_object.silo_tank_farm
          : null,
        disabled: false,
      },
      []
    );
    const bulk_pressed_material = new FormControl(
      {
        value: formData
          ? formData.warehouse.silo_tank_farm_object.bulk_pressed_material
          : null,
        disabled: false,
      },
      []
    );
    const monitoring = new FormControl(
      {
        value: formData
          ? formData.warehouse.silo_tank_farm_object.monitoring
          : null,
        disabled: false,
      },
      []
    );
    const extinguishing_system_silo = new FormControl(
      {
        value: formData
          ? formData.warehouse.silo_tank_farm_object.extinguishing_system_silo
          : null,
        disabled: false,
      },
      []
    );
    const Outdoor_Camp = new FormControl(
      {
        value: formData
          ? formData.warehouse.silo_tank_farm_object.Outdoor_Camp
          : null,
        disabled: false,
      },
      []
    );

    const is_warehouse_outdoors = new FormControl(
      {
        value: formData ? formData.warehouse.is_warehouse_outdoors : null,
        disabled: false,
      },
      []
    );
    const Type_of_packaging_warehouse = new FormControl(
      {
        value: formData
          ? formData.warehouse.Warehouse_outdoor_object
              .Type_of_packaging_warehouse
          : null,
        disabled: false,
      },
      []
    );
    const Storage_Height_Top_edge_of_stored_goods = new FormControl(
      {
        value: formData
          ? this.formatToGermanValue(
              formData.warehouse.Warehouse_outdoor_object
                .Storage_Height_Top_edge_of_stored_goods
            )
          : null,
        disabled: false,
      },
      []
    );

    const Warehouse_Complex_and_Packaging_means = new FormControl(
      {
        value: formData
          ? formData.warehouse.Warehouse_Complex_and_Packaging_means
          : null,
        disabled: false,
      },
      []
    );
    const Treatment_processing_in_warehouse_complex = new FormControl(
      {
        value: formData
          ? formData.warehouse.Warehouse_Complex_and_Packaging_means_object
              .Treatment_processing_in_warehouse_complex
          : null,
        disabled: false,
      },
      []
    );
    const Detachment = new FormControl(
      {
        value: formData
          ? formData.warehouse.Warehouse_Complex_and_Packaging_means_object
              .Detachment
          : null,
        disabled: false,
      },
      []
    );
    const extinguishing_system_warehouse = new FormControl(
      {
        value: formData
          ? formData.warehouse.Warehouse_Complex_and_Packaging_means_object
              .extinguishing_system_warehouse
          : null,
        disabled: false,
      },
      []
    );
    const Storage_of_packaging_materials = new FormControl(
      {
        value: formData
          ? formData.warehouse.Warehouse_Complex_and_Packaging_means_object
              .Storage_of_packaging_materials
          : null,
        disabled: false,
      },
      []
    );
    const Fire_resistant_partition = new FormControl(
      {
        value: formData
          ? formData.warehouse.Warehouse_Complex_and_Packaging_means_object
              .Fire_resistant_partition
          : null,
        disabled: false,
      },
      []
    );
    const annotations = new FormControl(
      {
        value: formData ? formData.warehouse.annotations : null,
        disabled: false,
      },
      []
    );
    const is_annotation = new FormControl(
      {
        value: formData ? formData.warehouse.is_annotation : null,
        disabled: false,
      },
      []
    );

    this.warehouseForm = this.fb.group({
      total_storage_area,
      stored_goods,
      number_of_fire_compartments,
      largest_storage_area,
      type_of_storage,
      is_fire_resistant_separation_of_the_KLT,
      is_extinguishing_system: is_extinguishing_system_one,
      Type_of_packaging,
      is_high_bay_warehouse,
      is_storage_of_packaging_materials,
      is_silo_tank_farm,
      is_warehouse_outdoors,
      Warehouse_Complex_and_Packaging_means,

      high_bay_warehouse: this.fb.group({
        storage_height_top_edge_of_stored_goods_in_m,
        is_extinguishing_system: is_extinguishing_system_two,
      }),
      storage_of_packaging_materials: this.fb.group({
        fire_resistant_partition,
        extinguishing_system,
      }),
      silo_tank_farm_object: this.fb.group({
        silo_tank_farm,
        bulk_pressed_material,
        monitoring,
        extinguishing_system_silo,
        Outdoor_Camp,
      }),
      Warehouse_outdoor_object: this.fb.group({
        Type_of_packaging_warehouse,
        Storage_Height_Top_edge_of_stored_goods,
      }),
      Warehouse_Complex_and_Packaging_means_object: this.fb.group({
        Treatment_processing_in_warehouse_complex,
        Detachment,
        extinguishing_system_warehouse,
        Storage_of_packaging_materials,
        Fire_resistant_partition,
      }),
      annotations,
      is_annotation,
    });
  }
  resetKLTFields() {
    if (this.warehouseForm.value.type_of_storage != this.kltValue) {
      this.warehouseForm.patchValue({
        is_fire_resistant_separation_of_the_KLT: null,
        is_extinguishing_system: null,
      });
    }
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  buildFormObject() {
    const warehouseObjectValue = this.warehouseForm.value;
    const formatValue =
      warehouseObjectValue.Warehouse_outdoor_object
        .Storage_Height_Top_edge_of_stored_goods;
    warehouseObjectValue.Warehouse_outdoor_object.Storage_Height_Top_edge_of_stored_goods =
      this.formatToEnglishValue(formatValue);
    warehouseObjectValue.type_of_storage = warehouseObjectValue.type_of_storage
      ? warehouseObjectValue.type_of_storage
      : null;
    warehouseObjectValue.total_storage_area =
      warehouseObjectValue.total_storage_area
        ? Number(
            this.validationService.removeSymbols(
              warehouseObjectValue.total_storage_area
            )
          )
        : null;
    warehouseObjectValue.number_of_fire_compartments =
      warehouseObjectValue.number_of_fire_compartments
        ? Number(
            this.validationService.removeSymbols(
              warehouseObjectValue.number_of_fire_compartments
            )
          )
        : null;
    warehouseObjectValue.largest_storage_area =
      warehouseObjectValue.largest_storage_area
        ? Number(
            this.validationService.removeSymbols(
              warehouseObjectValue.largest_storage_area
            )
          )
        : null;

    const formValuesToPersist = {
      warehouse: warehouseObjectValue,
    };
    this.request = {
      criteria_id: BuildingConstants.WAREHOUSE,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }

  validateNumberAsFloat(event) {
    const numberText = event.target.value.toString();

    if (numberText.includes('.')) {
      this.warehouseForm.patchValue({
        Warehouse_outdoor_object: {
          Storage_Height_Top_edge_of_stored_goods: '',
        },
      });
      return;
    }

    const numberEntered = event.target.value.toString().replace(',', '.');
    const numberEnteredArray = numberEntered.split('.');

    if (numberEntered > 100 || numberEntered == 0) {
      this.warehouseForm.patchValue({
        Warehouse_outdoor_object: {
          Storage_Height_Top_edge_of_stored_goods: '',
        },
      });
    } else if (isNaN(numberEntered)) {
      this.warehouseForm.patchValue({
        Warehouse_outdoor_object: {
          Storage_Height_Top_edge_of_stored_goods: '',
        },
      });
    } else if (numberEnteredArray[1] && numberEnteredArray[1].length > 2) {
      this.warehouseForm.patchValue({
        Warehouse_outdoor_object: {
          Storage_Height_Top_edge_of_stored_goods: '',
        },
      });
    } else if (numberEnteredArray[1] && numberEnteredArray[1] == '') {
      this.warehouseForm.patchValue({
        Warehouse_outdoor_object: {
          Storage_Height_Top_edge_of_stored_goods: '',
        },
      });
    }
  }

  formatToGermanValue(formatValue) {
    if (formatValue) {
      return formatValue.toString().replace('.', ',');
    }
  }

  formatToEnglishValue(formatValue) {
    if (formatValue) {
      return formatValue.toString().replace(',', '.');
    }
  }

  onTypeNewValue(event, key) {
    if (key == 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length == 0) {
        if (charCode == '45' || charCode == '48' || charCode == '46') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        if (
          charCode != 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        this.onTypeDecimalNumber(event);
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualnewValue = event.target.value.length;
      } else {
        this.actualnewValue = this.actualnewValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualnewValue == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }

  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length == 2) {
        event.preventDefault();
      }
    }
  }

  ngOnDestroy() {
    if (this.isFormValueUpdated && this.warehouseForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
