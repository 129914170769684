import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ObjectModuleSharedService {
  private _years: string[] | null = null;
  private _yearsCon: number[] | null = null;

  getYears(): string[] | null {
    return this._years;
  }

  saveYears(years: string[] | null): void {
    this._years = years;
  }

  getYearsCon(): number[] | null {
    return this._yearsCon;
  }

  saveYearsCon(yearsCon: number[] | null): void {
    this._yearsCon = yearsCon;
  }
}
