<div class="ms-templates-management">
  <div class="ms-templates-management__header">
    <app-button (click)="openUploadMsTemplatePopup()">
      <img alt="" src="../../../../assets/images/new-icons/Sync_Icon_18px.svg">
      {{ 'rcs-setup.ms-template-management.upload_new_template' | translate }}
    </app-button>
  </div>

  <app-filter-section
    [filters]="filters"
    (load)="filter($event)"
    (pageSizeChanged)="changePageSize($event)">
  </app-filter-section>

  <div class="ms-templates-management__search">
    <img alt="" src="../../../assets/images/ic-icons/Search.svg" class="ms-templates-management__search-img">
    <input
      class="form-control ms-templates-management__search-input"
      type="text"
      placeholder="{{'rcs-setup.ms-template-management.search_placeholder' | translate}}"
      aria-label="Search"
      [value]="searchValue"
      (keyup.enter)="search($event.target.value)"
      >
  </div>

  <app-table
      (pageSizeChanged)="changePageSize($event)"
      [tableConfig]="tableConfig"
      [pagination]="pagination"
      (pageChanged)="changePage($event)"
      [data]="data"
      (actionClicked)="handleAction($event)"
      (selectChanged)="handleTemplateSelect($event)"
      (sortChanged)="sort($event)">
    </app-table>
</div>
