import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IM_CONTACT_PERSON_FORM_FIELDS } from '../../../../components/insurer-management/models/insurer-management.constants';
import { ERRORS, STATIC_TEXT } from '../../../constants/string.constants';
import {
  AddContactPersonRequest,
  getContactPersonRespone,
} from '../../../../components/insurer-management/models/insurer-management.models';
import { InsurerApiCallsService } from '@core/services/httpcalls/insurer-api-calls.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { LOCALS } from '@shared/constants/local.constants';
import { ShowErrorService } from '@core/services/show-error.service';
import { ContactPersonApiService } from '@core/services/httpcalls/contact-person-api.service';
import { CP_MULTILINGUAL } from '@shared/constants/general/contact-person.constants';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
@Component({
  selector: 'add-contact-person',
  templateUrl: './add-contact-person.component.html',
  styleUrls: ['./add-contact-person.component.css'],
})
export class AddContactPersonComponentNew
  extends BaseComponent
  implements OnInit
{
  @Input() insurerId: number;
  @Input() triggerFrom: string;
  @Input() contactPersonId: number;
  @Output() message = new EventEmitter();
  @Output() iscontactPersonAddEdit = new EventEmitter<any>();
  contactPersonForm: FormGroup;
  contactPersonData: getContactPersonRespone;
  initialFormState: any;
  isValueChangedInForm = false;
  formFieldsName = IM_CONTACT_PERSON_FORM_FIELDS;
  localsConstant = LOCALS;
  readonly locale$ = this._userService.locale$;
  salutations = [];
  roles_data = [];
  customer_roles = [];
  errorConstant = ERRORS;
  contactPersonMultilingual = CP_MULTILINGUAL;
  is_IC_USER = false;
  user_id: number;
  constructor(
    private _insurerAPICallsService: InsurerApiCallsService,
    public _showError: ShowErrorService,
    private _cpAPICallService: ContactPersonApiService,
    private readonly _userService: UserService
  ) {
    super();
    this._userService.loggedInUser$
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.user_id = data.user_profile.user_data.user_id;
      });
  }

  ngOnInit(): void {
    this.is_IC_USER =
      this._userService.getUserRole() === USER_ROLES.IC_USER;
    this.getAPICalls();
  }

  getAPICalls() {
    const requests = [];
    requests.push(
      this._cpAPICallService
        .getContactPersonRoles(this.triggerFrom)
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this._insurerAPICallsService
        .getSalutation()
        .pipe(catchError(error => of(error)))
    );
    if (this.contactPersonId) {
      requests.push(
        this._cpAPICallService
          .getContactPersonById(this.contactPersonId, this.triggerFrom)
          .pipe(catchError(error => of(error)))
      );
    }

    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: any) => {
        if (!response[0].hasErrors && response[0].customer_roles) {
          this.setContactPersonRoles(response[0].customer_roles);
        } else {
          this.customer_roles = [];
        }
        const salutations = (!response[1].hasErrors && response[1].data) || [];
        this.salutations = salutations && this.filterSalutation(salutations);
        if (this.contactPersonId) {
          this.contactPersonData =
            !response[2].hasErrors && response[2].contact_person
              ? response[2].contact_person
              : null;
        }
        this.initializeForm();
      });
  }

  initializeForm() {
    const formData = this.contactPersonData || null;
    this.contactPersonForm = new FormGroup({
      [IM_CONTACT_PERSON_FORM_FIELDS.SALUTATION]: new FormControl(
        formData?.salutationId || null
      ),
      [IM_CONTACT_PERSON_FORM_FIELDS.FIRST_NAME]: new FormControl(
        formData?.firstName || STATIC_TEXT.EMPTY_STRING
      ),
      [IM_CONTACT_PERSON_FORM_FIELDS.LAST_NAME]: new FormControl(
        formData?.lastName || STATIC_TEXT.EMPTY_STRING
      ),
      [IM_CONTACT_PERSON_FORM_FIELDS.ROLE]: new FormControl(
        (formData &&
          this.customer_roles.filter(role => role.id == formData.roleId)[0]
            ?.name) ||
          STATIC_TEXT.EMPTY_STRING
      ),
      [IM_CONTACT_PERSON_FORM_FIELDS.MAIN_CONTACT_PERSON]: new FormControl(
        formData ? formData.mainContactPerson : false
      ),
      [IM_CONTACT_PERSON_FORM_FIELDS.COMMENT]: new FormControl(
        formData?.comments || STATIC_TEXT.EMPTY_STRING
      ),
    });
    if (this.is_IC_USER) {
      this.contactPersonForm.disable();
    }
    this.initialFormState = this.contactPersonForm.value;
    this.contactPersonForm?.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isValueChangedInForm =
          JSON.stringify(this.initialFormState) !=
          JSON.stringify(this.contactPersonForm.value);
        this.removeRoleError();
      });
  }

  prepareSaveData() {
    const saveRequest: AddContactPersonRequest = {
      id: this.contactPersonData?.id || null,
      salutationId: this.f[IM_CONTACT_PERSON_FORM_FIELDS.SALUTATION].value,
      firstName: this.f[IM_CONTACT_PERSON_FORM_FIELDS.FIRST_NAME].value,
      lastName: this.f[IM_CONTACT_PERSON_FORM_FIELDS.LAST_NAME].value,
      roleId: this.f[IM_CONTACT_PERSON_FORM_FIELDS.ROLE].value
        ? this.customer_roles.filter(
            role =>
              role.name == this.f[IM_CONTACT_PERSON_FORM_FIELDS.ROLE].value
          )[0]?.id
        : null,
      mainContactPerson:
        this.f[IM_CONTACT_PERSON_FORM_FIELDS.MAIN_CONTACT_PERSON].value,
      comments: this.f[IM_CONTACT_PERSON_FORM_FIELDS.COMMENT].value,
      sourceId: this.insurerId,
      updatedBy: this.user_id,
      sourceType: this.triggerFrom,
    };
    return saveRequest;
  }

  saveContactPersonForm() {
    const request: AddContactPersonRequest = this.prepareSaveData();
    this._cpAPICallService
      .addContactPerson(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this.iscontactPersonAddEdit.emit(false);
        this.message.emit(response.message);
        this.contactPersonData = null;
      });
  }

  get f() {
    return this.contactPersonForm.controls;
  }

  filterSalutation(salutations: any[]) {
    return salutations.filter(ele => ele.salutation_id != 3);
  }

  addContactPersonRole(event) {
    if (event) {
      const data = { data: event };
      if (!this.roles_data.includes(event)) {
        this._cpAPICallService
          .addContactPersonRole(this.triggerFrom, data)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            if (!data.HasErrors) {
              this._cpAPICallService
                .getContactPersonRoles(this.triggerFrom)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  this.setContactPersonRoles(data?.customer_roles);
                });
            }
          });
      }
    }
    this.removeRoleError();
  }

  removeRoleError() {
    this.f[IM_CONTACT_PERSON_FORM_FIELDS.ROLE].setErrors(null);
  }

  setContactPersonRoles(data) {
    this.customer_roles = data;
    this.roles_data = [];
    data?.map(element => {
      this.roles_data.push(element.name);
    });
  }
}
