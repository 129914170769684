import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { LOCALS } from '@shared/constants/local.constants';
import { UserService } from '../user.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(private readonly _userService: UserService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const locale =
      this._userService.getLocale() === LOCALS.ENGLISH ? 'en-US' : 'de-DE';
    return request.url === '/assets/i18n/null.json'
      ? EMPTY
      : next.handle(
          request.clone({
            setHeaders: {
              'Accept-Language': locale,
            },
          })
        );
  }
}
