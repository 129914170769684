<div class="confirm-popup">
    <div class="d-flex header-content p-unset">
        <div class="pointer">
            <img alt="" (click)="closeConfirmPopup()" [src]="crossImage" >
        </div>
    </div>
    <div class="confirm-popup__body">
        <span class="confirm-popup__body--heading">{{data?.heading || ('confirm_popup.are_you_sure' | translate)}}</span>
        <br /><br />
        <span class="confirm-popup__body--desc">{{data?.subHeading || ('confirm_popup.do_you_want' | translate)}}</span>
    </div>
    <div class="confirm-popup__footer ml-auto mr-auto">
        <app-button (click)="closeConfirmPopup()" secondary>
            {{data?.cancelBtnText || ('confirm_popup.cancel' | translate)}}
        </app-button>
        <app-button class="ml-2" (click)="saveConfirm()" cdkFocusInitial>
            {{data?.successBtnText || ('confirm_popup.yes' | translate)}}
        </app-button>
    </div>
</div>
