import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Priorities } from '../../../constants/priority.constants';

@Component({
  selector: 'app-priority-column',
  template: '<app-priority [priority]="element.priority"></app-priority>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityColumnComponent {
  @Input() element: { priority: Priorities };
}
