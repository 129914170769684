<div *ngIf="!contactPersonAddEdit">
  <app-contact-person
    (isContactPersonAddEdit)="isContactPersonAddEdit($event)"
    (contactPersonId)="getContactPersonId($event)"
  >
  </app-contact-person>
</div>
<div *ngIf="contactPersonAddEdit">
  <app-add-contact-person
    [contactPersonId]="contactPersonId"
    [addContactPerson]="add_contact_person"
    (message)="showMessage($event)"
    (isContactPersonAddEdit)="isContactPersonAddEdit($event)"
    (isContactPersonAdd)="isContactPersonAdd($event)"
  >
  </app-add-contact-person>
</div>
