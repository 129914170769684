<div>
  <span class="criteria-heading">{{'objectModule.pellet_plant_object.pellet_plant' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="pelletPlantForm" id="pelletPlantForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="fire_compartment_area" class="long-label">{{'objectModule.pellet_plant_object.fire_compartment_area' |
      translate}}</label>
      <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="fire_compartment_area"
      placeholder="{{'objectModule.select_lable' | translate}}" formControlName="fire_compartment_area">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option [value]="area.id" *ngFor="let area of compartmentArea">{{area.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_sprinkler_system"
      class="long-label">{{'objectModule.pellet_plant_object.sprinkler_system' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_sprinkler_system"
        id="is_sprinkler_system">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_grates_in_filling_openings"
      class="long-label">{{'objectModule.pellet_plant_object.grates_in_filling_openings' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_grates_in_filling_openings"
        id="is_grates_in_filling_openings">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_separation_of_foreign_matter_upon_delivery"
      class="long-label">{{'objectModule.pellet_plant_object.sepration_of_foriegn_matter_upon_delivery' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_separation_of_foreign_matter_upon_delivery"
        id="is_separation_of_foreign_matter_upon_delivery">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_wood_storage"
      class="long-label">{{'objectModule.pellet_plant_object.wood_storage' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_wood_storage"
        id="is_wood_storage">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_magnets_and_sieves_before_further_internal_transport"
      class="long-label">{{'objectModule.pellet_plant_object.magnets_&_sieves_before_further_internal_transport' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_magnets_and_sieves_before_further_internal_transport"
        id="is_magnets_and_sieves_before_further_internal_transport">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_fire_alarm_system"
      class="long-label">{{'objectModule.pellet_plant_object.fire_alarm_system' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_fire_alarm_system"
        id="is_fire_alarm_system">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_structural_or_spatial_fire_compartment_separation"
      class="long-label">{{'objectModule.pellet_plant_object.structural_or_spatial_fire_compartment_separation' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_structural_or_spatial_fire_compartment_separation"
        id="is_structural_or_spatial_fire_compartment_separation">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_chip_heating_with_burn_back_protection"
      class="long-label">{{'objectModule.pellet_plant_object.chip_heating_with_burn-back_protection' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_chip_heating_with_burn_back_protection"
        id="is_chip_heating_with_burn_back_protection">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div [ngClass]="pelletPlantForm.value.is_production_shredder_hammer_mill ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_production_shredder_hammer_mill">{{'objectModule.pellet_plant_object.production_shredder_hammer_mill'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="pelletPlantForm.value.is_production_shredder_hammer_mill"
      formGroupName="production_shredder_hammer_mill">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_metal_separator_before_entering_processing"
          class="long-label">{{'objectModule.pellet_plant_object.metal_separator_before_entering_processing' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_metal_separator_before_entering_processing" id="is_metal_separator_before_entering_processing">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_gravity_separator_before_entering_processing"
          class="long-label">{{'objectModule.pellet_plant_object.gravity_separator_before_entering_processing' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_gravity_separator_before_entering_processing"
          id="is_gravity_separator_before_entering_processing">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_water_spray_protection"
          class="long-label">{{'objectModule.pellet_plant_object.water_spray_protection_in_hopper_and_after_the_outlet' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_water_spray_protection"
          id="is_water_spray_protection">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_grinding_chamber"
          class="long-label">{{'objectModule.pellet_plant_object.grinding_chamber' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_grinding_chamber"
          id="is_grinding_chamber">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_spark_extinguishing_system_at_the_discharge"
          class="long-label">{{'objectModule.pellet_plant_object.spark_extinguishing_system_at_the_discharge' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_spark_extinguishing_system_at_the_discharge"
          id="is_spark_extinguishing_system_at_the_discharge">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div> 
  </div>

  <div [ngClass]="pelletPlantForm.value.is_drying ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_drying">{{'objectModule.pellet_plant_object.drying'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="pelletPlantForm.value.is_drying"
      formGroupName="drying">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_park_extinguishing_system_at_the_entry_and_exit_of_the_drying"
          class="long-label">{{'objectModule.pellet_plant_object.spark_extinguishing_system_at_drying_inlet_and_outlet' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_park_extinguishing_system_at_the_entry_and_exit_of_the_drying"
          id="is_park_extinguishing_system_at_the_entry_and_exit_of_the_drying">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_park_extinguishing_systems_in_the_drying_exhaust_air_ducts"
          class="long-label">{{'objectModule.pellet_plant_object.spark_extinguishing_systems_in_the_drying_exhaust_air_ducts' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_park_extinguishing_systems_in_the_drying_exhaust_air_ducts"
          id="is_park_extinguishing_systems_in_the_drying_exhaust_air_ducts">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_pray_water_extinguishing_system_in_the_dryer"
          class="long-label">{{'objectModule.pellet_plant_object.spray_water_extinguishing_system_in_the_dryer' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_pray_water_extinguishing_system_in_the_dryer"
          id="is_pray_water_extinguishing_system_in_the_dryer">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_rase_above_and_below_the_bands"
          class="long-label">{{'objectModule.pellet_plant_object.erase_above_and_below_the_bands' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_rase_above_and_below_the_bands"
          id="is_rase_above_and_below_the_bands">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_temperature_monitoring_with_automatic_shutdown"
          class="long-label">{{'objectModule.pellet_plant_object.temperature_monitoring_with_automatic_shutdown' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_temperature_monitoring_with_automatic_shutdown"
          id="is_temperature_monitoring_with_automatic_shutdown">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="pelletPlantForm.value.is_pellet_mill ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_pellet_mill">{{'objectModule.pellet_plant_object.pellet_mill_cooler'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="pelletPlantForm.value.is_pellet_mill"
      formGroupName="pellet_mill">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_temperature_monitoring"
          class="long-label">{{'objectModule.pellet_plant_object.temperature_monitoring' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_temperature_monitoring"
          id="is_temperature_monitoring">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_moisture_measurement"
          class="long-label">{{'objectModule.pellet_plant_object.moisture_measurement' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_moisture_measurement"
          id="is_moisture_measurement">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_spark_extinguishing_system_at_the_discharge"
          class="long-label">{{'objectModule.pellet_plant_object.spark_extinguishing_system_at_the_discharge' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_spark_extinguishing_system_at_the_discharge"
          id="is_spark_extinguishing_system_at_the_discharge">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_water_spray_extinguishing_system_in_the_pellet_cooler"
          class="long-label">{{'objectModule.pellet_plant_object.water_spray_extinguishing_system_in_the_pellet_cooler' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_water_spray_extinguishing_system_in_the_pellet_cooler"
          id="is_water_spray_extinguishing_system_in_the_pellet_cooler">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_regular_die_change"
          class="long-label">{{'objectModule.pellet_plant_object.regular_die_change' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_regular_die_change"
          id="is_regular_die_change">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="pelletPlantForm.value.is_silos ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_silos">{{'objectModule.pellet_plant_object.silo_bunker'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="pelletPlantForm.value.is_silos"
      formGroupName="silos">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_water_spray_protection"
          class="long-label">{{'objectModule.pellet_plant_object.water_spray_protection' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_water_spray_protection"
          id="is_water_spray_protection">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_manual_water_feed"
          class="long-label">{{'objectModule.pellet_plant_object.manual_water_feed' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_manual_water_feed"
          id="is_manual_water_feed">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_spark_detection"
          class="long-label">{{'objectModule.pellet_plant_object.spark_detection' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_spark_detection"
          id="is_spark_detection">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_structural_separation"
          class="long-label">{{'objectModule.pellet_plant_object.structural_spatial_separation' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_structural_separation"
          id="is_structural_separation">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_inerting_option"
          class="long-label">{{'objectModule.pellet_plant_object.inerting_option' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_inerting_option"
          id="is_inerting_option">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_dry_line"
          class="long-label">{{'objectModule.pellet_plant_object.dry_line_riser_line' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_dry_line"
          id="is_dry_line">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_printingload"
          class="long-label">{{'objectModule.pellet_plant_object.pressure_relief' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_printingload"
          id="is_printingload">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_separated_by_firewall"
          class="long-label">{{'objectModule.pellet_plant_object.separated_by_firewall' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_separated_by_firewall"
          id="is_separated_by_firewall">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_spatially_separated"
          class="long-label">{{'objectModule.pellet_plant_object.spatially_separated' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_spatially_separated"
          id="is_spatially_separated">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="pelletPlantForm.value.is_filter_system ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_filter_system">{{'objectModule.pellet_plant_object.filter_system_dust_chamber'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="pelletPlantForm.value.is_filter_system"
      formGroupName="filter_system">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_water_spray_protection_f"
          class="long-label">{{'objectModule.pellet_plant_object.water_spray_protection' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_water_spray_protection"
          id="is_water_spray_protection_f">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_manual_water_feed_f"
          class="long-label">{{'objectModule.pellet_plant_object.manual_water_feed' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_manual_water_feed"
          id="is_manual_water_feed_f">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_separated_by_firewall_f"
          class="long-label">{{'objectModule.pellet_plant_object.spray_water_extinguishing_system_in_the_dryer' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_separated_by_firewall"
          id="is_separated_by_firewall_f">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_spatially_separated_f"
          class="long-label">{{'objectModule.pellet_plant_object.erase_above_and_below_the_bands' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_spatially_separated"
          id="is_spatially_separated_f">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>


  <div [ngClass]="pelletPlantForm.value.is_conveyors ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_conveyors">{{'objectModule.pellet_plant_object.conveyors_elevators'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="pelletPlantForm.value.is_conveyors"
      formGroupName="conveyors">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_regular_belt_tension_check"
          class="long-label">{{'objectModule.pellet_plant_object.regular_belt_tension_check' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_regular_belt_tension_check"
          id="is_regular_belt_tension_check">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_regular_lubrication_of_rotating_parts"
          class="long-label">{{'objectModule.pellet_plant_object.regular_lubrication_of_rotating_parts' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_regular_lubrication_of_rotating_parts"
          id="is_regular_lubrication_of_rotating_parts">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_speed_monitor_on_drive_and_idler_pulleys"
          class="long-label">{{'objectModule.pellet_plant_object.speed_monitors_on_drive_and_idler_pulleys' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_speed_monitor_on_drive_and_idler_pulleys"
          id="is_speed_monitor_on_drive_and_idler_pulleys">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_belt_misalignment_monitoring_with_automatic_transport_shutdown"
          class="long-label">{{'objectModule.pellet_plant_object.belt_misalignment_monitoring_with_automatic_transport_shutdown' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_belt_misalignment_monitoring_with_automatic_transport_shutdown"
          id="is_belt_misalignment_monitoring_with_automatic_transport_shutdown">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_devices_for_separating_out_foreign_objects_and_magnetic_separators"
          class="long-label">{{'objectModule.pellet_plant_object.devices_for_separating_pout_foreign_objects_and_magnetic_separators' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_devices_for_separating_out_foreign_objects_and_magnetic_separators"
          id="is_devices_for_separating_out_foreign_objects_and_magnetic_separators">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_grounding_of_all_conductive_parts"
          class="long-label">{{'objectModule.pellet_plant_object.grounding_of_all_conductive_parts' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_grounding_of_all_conductive_parts"
          id="is_grounding_of_all_conductive_parts">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_dust_extraction_in_the_head_part_of_the_system"
          class="long-label">{{'objectModule.pellet_plant_object.dust_extraction_in_the_head_part_of_the_system' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_dust_extraction_in_the_head_part_of_the_system"
          id="is_dust_extraction_in_the_head_part_of_the_system">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_protection_by_spark_extinguishing_system"
          class="long-label">{{'objectModule.pellet_plant_object.protection_by_spark_extinguishing_system' |
          translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_protection_by_spark_extinguishing_system"
          id="is_protection_by_spark_extinguishing_system">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.pellet_plant_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.pellet_plant_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</form>
