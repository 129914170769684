import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UploadObjectDetails } from '@shared/models/generic.model';
import {
  LocationConstants,
  UploadTypeConstants,
} from '@shared/constants/location.constants';
import {
  AdditionalGeneralInformation,
  BuildingObject,
  Criteria,
} from '@shared/models/request.model';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
@Component({
  selector: 'app-additional-general-information',
  templateUrl: './additional-general-information.component.html',
  styleUrls: ['./additional-general-information.component.css'],
})
export class AdditionalGeneralInformationComponent
  extends BaseComponent
  implements OnInit
{
  additionalGeneralInformationForm: FormGroup;
  browser: string;
  unit = [];
  FireWaterSupply = true;
  property_location = [];
  fire_department = [];
  fire_water_supply = [];
  @Input('object_data') object_data;
  @Input('isresetForm') isresetForm;
  @Input('objectType') objectType;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  isFormValueUpdated: boolean;
  uploadObjectDetails: UploadObjectDetails;
  request: BuildingObject;
  selectedSitePlanDocumentsArray = {};
  selectedFireDepartmentArray = {};
  selectedDocumentsArray = {};
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  readonly selectLang$ = this._userService.locale$;
  showSitePlanPopup = false;
  showFireDepartmentPopup = false;
  fdDocsIds = [];
  spDocsIds = [];
  from: any;
  size_of_water: string;
  buttonDisable = true;

  constructor(
    public validation: ValidationService,
    private serviceCall: ApiCallsService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private readonly _userService: UserService
  ) {
    super();
    this.browser = this.detectBrowser();
    this.serviceCall
      .getLookupData(37)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.property_location = data.data;
      });
    this.serviceCall
      .getLookupData(38)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.fire_department = data.data;
      });
    this.serviceCall
      .getLookupData(39)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.fire_water_supply = data.data;
      });
    this.serviceCall
      .getLookupData(44)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.unit = data.data;
      });
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        LocationConstants.ADDITIONAL_GENERAL_INFORMATION
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    let toDataHH, toDataMM, fromDataHH, fromDataMM;
    if (formData) {
      if (formData.additional_general_info.operating_hours.from) {
        fromDataHH = formData.additional_general_info.operating_hours.from
          .replace(/(^:)|(:$)/g, '')
          .split(':')[0];
        fromDataMM = formData.additional_general_info.operating_hours.from
          .replace(/(^:)|(:$)/g, '')
          .split(':')[1];
      }
      if (formData.additional_general_info.operating_hours.to) {
        toDataHH = formData.additional_general_info.operating_hours.to
          .replace(/(^:)|(:$)/g, '')
          .split(':')[0];
        toDataMM = formData.additional_general_info.operating_hours.to
          .replace(/(^:)|(:$)/g, '')
          .split(':')[1];
      }
      if (formData.additional_general_info.size_of_fire_water) {
        this.size_of_water = this._currencyCustomPipe
          .transform(
            formData.additional_general_info.size_of_fire_water
              .toString()
              ?.replaceAll('.', '')
          )
          ?.replace('€', '')
          ?.replace(',00', '')
          ?.trim();
      } else {
        this.size_of_water = '';
      }
      if (formData.additional_general_info.fire_department) {
        this.buttonDisable = false;
      }
    }
    const record_id = criteria && criteria.record_id;
    const type_of_use = new FormControl({
      value: formData ? formData.additional_general_info.type_of_use : null,
      disabled: false,
    });
    const owner_of_insured_property = new FormControl({
      value: formData
        ? formData.additional_general_info.owner_of_insured_property
        : null,
      disabled: false,
    });
    const owner_of_building = new FormControl({
      value: formData
        ? formData.additional_general_info.owner_of_building
        : null,
      disabled: false,
    });
    const site_plan = new FormControl({
      value: formData ? formData.additional_general_info.site_plan : null,
      disabled: false,
    });
    const property_location = new FormControl({
      value: formData
        ? formData.additional_general_info.property_location
        : null,
      disabled: false,
    });
    const fire_department = new FormControl({
      value: formData ? formData.additional_general_info.fire_department : null,
      disabled: false,
    });
    const fire_department_plans = new FormControl({
      value: formData
        ? formData.additional_general_info.fire_department_plans
        : null,
      disabled: formData
        ? formData.additional_general_info.fire_department
          ? false
          : true
        : true,
    });
    const distance_in_km = new FormControl({
      value: formData ? formData.additional_general_info.distance_in_km : null,
      disabled: formData
        ? formData.additional_general_info.fire_department == 1 ||
          formData.additional_general_info.fire_department == 2
          ? false
          : true
        : true,
    });
    const arrival_time = new FormControl({
      value: formData ? formData.additional_general_info.arrival_time : null,
      disabled: formData
        ? formData.additional_general_info.fire_department == 1 ||
          formData.additional_general_info.fire_department == 2
          ? false
          : true
        : true,
    });
    const fire_water_supply = new FormControl({
      value: formData
        ? formData.additional_general_info.fire_water_supply
        : null,
      disabled: false,
    });
    const nominal_width_water_line = new FormControl({
      value: formData
        ? formData.additional_general_info.nominal_width_water_line
        : null,
      disabled: formData
        ? formData.additional_general_info.fire_water_supply?.includes(1)
          ? false
          : true
        : true,
    });
    const size_of_fire_water = new FormControl({
      value: formData ? this.size_of_water : null,
      disabled: formData
        ? formData.additional_general_info.fire_water_supply?.includes(6)
          ? false
          : true
        : true,
    });
    const unit = new FormControl({
      value: formData ? formData.additional_general_info.unit : null,
      disabled: formData
        ? formData.additional_general_info.fire_water_supply?.includes(6)
          ? false
          : true
        : true,
    });
    const is_operating_hours = new FormControl({
      value: formData
        ? formData.additional_general_info.is_operating_hours
        : null,
      disabled: false,
    });
    const day_per_week = new FormControl({
      value: formData
        ? formData.additional_general_info.operating_hours.day_per_week
        : null,
      disabled: false,
    });
    const from = new FormControl({
      value: formData
        ? formData.additional_general_info.operating_hours.from
        : null,
      disabled: false,
    });
    const to = new FormControl({
      value: formData
        ? formData.additional_general_info.operating_hours.to
        : null,
      disabled: false,
    });
    const work_shifts = new FormControl({
      value: formData
        ? formData.additional_general_info.operating_hours.work_shifts
        : null,
      disabled: false,
    });
    const is_regulatory_req = new FormControl({
      value: formData
        ? formData.additional_general_info.is_regulatory_req
        : null,
      disabled: false,
    });
    const regulatory_restriction_desc = new FormControl({
      value: formData
        ? formData.additional_general_info.regulatory_req
            .regulatory_restriction_desc
        : null,
      disabled: false,
    });
    const is_outdoor_hydrant = new FormControl({
      value: formData
        ? formData.additional_general_info.is_outdoor_hydrant
        : null,
      disabled: false,
    });
    const count = new FormControl({
      value: formData
        ? formData.additional_general_info.outdoor_hydrant.count
        : null,
      disabled: false,
    });
    const nominal_diameter = new FormControl({
      value: formData
        ? formData.additional_general_info.outdoor_hydrant.nominal_diameter
        : null,
      disabled: false,
    });
    const fromHH = new FormControl({
      value: formData ? fromDataHH : null,
      disabled: false,
    });
    const fromMM = new FormControl({
      value: formData ? fromDataMM : null,
      disabled: false,
    });
    const toHH = new FormControl({
      value: formData ? toDataHH : null,
      disabled: false,
    });
    const toMM = new FormControl({
      value: formData ? toDataMM : null,
      disabled: false,
    });

    this.additionalGeneralInformationForm = new FormGroup({
      type_of_use,
      owner_of_insured_property,
      owner_of_building,
      site_plan,
      property_location,
      fire_department,
      fire_department_plans,
      distance_in_km,
      arrival_time,
      fire_water_supply,
      nominal_width_water_line,
      size_of_fire_water,
      unit,
      is_operating_hours,
      day_per_week,
      from,
      fromHH,
      fromMM,
      to,
      toHH,
      toMM,
      work_shifts,
      is_regulatory_req,
      regulatory_restriction_desc,
      is_outdoor_hydrant,
      count,
      nominal_diameter,
    });
    this.isFormValueUpdated = false;
    this.additionalGeneralInformationForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isFormValueUpdated = true;
        this.buildFormObject();
        const pristine = this.additionalGeneralInformationForm.pristine;
        this.saveCriteriaRequest.emit({
          request: this.request,
          isSave: false,
          pristine,
        });
      });
    this.getDocumentUploadData(record_id);
  }
  uploadSitePlanDoc() {
    this.selectedDocumentsArray = { ...this.selectedSitePlanDocumentsArray };
    this.showSitePlanPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.AGI_SITE_PLAN,
    };
  }
  uploadFireDepartmentDoc() {
    this.selectedDocumentsArray = { ...this.selectedFireDepartmentArray };
    this.showFireDepartmentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.AGI_FIRE_DEPARTMENT_PLAN,
    };
  }
  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 12)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.AGI_FIRE_DEPARTMENT_PLAN
              ) {
                this.selectedFireDepartmentArray[document.document_name] =
                  document;
              }
              if (
                document.document_type === UploadTypeConstants.AGI_SITE_PLAN
              ) {
                this.selectedSitePlanDocumentsArray[document.document_name] =
                  document;
              }
            });
            if (data.documents) {
              this.additionalGeneralInformationForm.controls.fire_department_plans.setValue(
                Object.keys(this.selectedFireDepartmentArray).join(', ')
              );
              this.additionalGeneralInformationForm.controls.site_plan.setValue(
                Object.keys(this.selectedSitePlanDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }

  buildFormObject() {
    const operating_hours = {
      day_per_week: this.additionalGeneralInformationForm.value.day_per_week,
      from:
        this.additionalGeneralInformationForm.value.fromHH +
        ':' +
        this.additionalGeneralInformationForm.value.fromMM,
      to:
        this.additionalGeneralInformationForm.value.toHH +
        ':' +
        this.additionalGeneralInformationForm.value.toMM,
      work_shifts: this.additionalGeneralInformationForm.value.work_shifts,
    };
    const regulatory_req = {
      regulatory_restriction_desc:
        this.additionalGeneralInformationForm.value.regulatory_restriction_desc,
    };
    const outdoor_hydrant = {
      count: this.additionalGeneralInformationForm.value.count,
      nominal_diameter:
        this.additionalGeneralInformationForm.value.nominal_diameter,
    };
    const additional_general_info: AdditionalGeneralInformation = {
      type_of_use: this.additionalGeneralInformationForm.value.type_of_use,
      owner_of_insured_property:
        this.additionalGeneralInformationForm.value.owner_of_insured_property,
      owner_of_building:
        this.additionalGeneralInformationForm.value.owner_of_building,
      site_plan: this.additionalGeneralInformationForm.value.site_plan,
      property_location: this.additionalGeneralInformationForm.value
        .property_location
        ? this.additionalGeneralInformationForm.value.property_location
        : null,
      fire_department: this.additionalGeneralInformationForm.value
        .fire_department
        ? this.additionalGeneralInformationForm.value.fire_department
        : null,
      fire_department_plans:
        this.additionalGeneralInformationForm.value.fire_department_plans,
      distance_in_km:
        this.additionalGeneralInformationForm.value.distance_in_km,
      arrival_time: this.additionalGeneralInformationForm.value.arrival_time,
      fire_water_supply:
        this.additionalGeneralInformationForm.value.fire_water_supply,
      nominal_width_water_line:
        this.additionalGeneralInformationForm.value.nominal_width_water_line,
      size_of_fire_water: this.additionalGeneralInformationForm.value
        .size_of_fire_water
        ? Number(
            this.validation.removeSymbols(
              this.additionalGeneralInformationForm.value.size_of_fire_water
            )
          )
        : null,
      unit: this.additionalGeneralInformationForm.value.unit
        ? this.additionalGeneralInformationForm.value.unit
        : null,
      is_operating_hours:
        this.additionalGeneralInformationForm.value.is_operating_hours,
      operating_hours,
      is_regulatory_req:
        this.additionalGeneralInformationForm.value.is_regulatory_req,
      regulatory_req,
      is_outdoor_hydrant:
        this.additionalGeneralInformationForm.value.is_outdoor_hydrant,
      outdoor_hydrant,
    };
    const formValuesToPersist = {
      additional_general_info,
    };
    this.request = {
      document_ids: [...new Set([...this.spDocsIds, ...this.fdDocsIds])],
      criteria_id: LocationConstants.ADDITIONAL_GENERAL_INFORMATION,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (
      this.isFormValueUpdated &&
      this.additionalGeneralInformationForm.touched
    ) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
  typeNumber(event, key, length) {
    this.validation?.MinutesValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showFireDepartmentPopup) {
      this.fdDocsIds = [];
      this.selectedFireDepartmentArray = { ...selectedDocumentsArray };
      this.showFireDepartmentPopup = false;
      this.additionalGeneralInformationForm.controls.fire_department_plans.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.additionalGeneralInformationForm.controls.fire_department_plans.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.fdDocsIds.push(selectedDocumentsArray[name].upload_document_id);
        }
      });
    }
    if (this.showSitePlanPopup) {
      this.spDocsIds = [];
      this.selectedSitePlanDocumentsArray = { ...selectedDocumentsArray };
      this.showSitePlanPopup = false;
      this.additionalGeneralInformationForm.controls.site_plan.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.additionalGeneralInformationForm.controls.site_plan.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.spDocsIds.push(selectedDocumentsArray[name].upload_document_id);
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.additionalGeneralInformationForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }
  showDocumentPopup() {
    return this.showFireDepartmentPopup || this.showSitePlanPopup;
  }
  typeHH(event, name, key) {
    if (name == 'from') {
      this.from = event.target.value;
    }
  }
  typeMM(event) {
    if (
      event.target.value > 60 &&
      event.keyCode !== 46 &&
      event.keyCode !== 8
    ) {
      event.preventDefault();
    }
  }
  valueFireWaterSupply(event) {
    if (event.value.includes(6)) {
      this.additionalGeneralInformationForm.controls.size_of_fire_water.enable();
      this.additionalGeneralInformationForm.controls.unit.enable();
    } else {
      this.additionalGeneralInformationForm.controls.size_of_fire_water.disable();
      this.additionalGeneralInformationForm.controls.unit.disable();
      this.additionalGeneralInformationForm.controls.size_of_fire_water.setValue(
        null
      );
      this.additionalGeneralInformationForm.controls.unit.setValue(null);
    }
    if (event.value.includes(1)) {
      this.additionalGeneralInformationForm.controls.nominal_width_water_line.enable();
    } else {
      this.additionalGeneralInformationForm.controls.nominal_width_water_line.disable();
      this.additionalGeneralInformationForm.controls.nominal_width_water_line.setValue(
        null
      );
    }
  }
  FireDepartment(event) {
    if (event.value == 1 || event.value == 2 || event.value == 3) {
      this.buttonDisable = false;
      this.additionalGeneralInformationForm.controls.fire_department_plans.enable();
      if (event.value == 1 || event.value == 2) {
        this.additionalGeneralInformationForm.controls.distance_in_km.enable();
        this.additionalGeneralInformationForm.controls.arrival_time.enable();
      }
      if (event.value == 3) {
        this.additionalGeneralInformationForm.controls.distance_in_km.disable();
        this.additionalGeneralInformationForm.controls.arrival_time.disable();
        this.additionalGeneralInformationForm.controls.distance_in_km.setValue(
          null
        );
        this.additionalGeneralInformationForm.controls.arrival_time.setValue(
          null
        );
      }
    } else {
      this.buttonDisable = true;
      this.additionalGeneralInformationForm.controls.distance_in_km.setValue(
        null
      );
      this.additionalGeneralInformationForm.controls.arrival_time.setValue(
        null
      );
      this.additionalGeneralInformationForm.controls.distance_in_km.disable();
      this.additionalGeneralInformationForm.controls.arrival_time.disable();
    }
  }
  get unitName() {
    if (this.additionalGeneralInformationForm.value.unit && this.unit.length) {
      return this.unit.filter(
        _ => _.id == this.additionalGeneralInformationForm.value.unit
      )[0].desc;
    }
    return '';
  }
}
