import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, of } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { BaseComponent } from '@shared/components/base.component';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { AddCustomerRelationComponent } from '../add-customer-relation/add-customer-relation.component';
import { CustomerManagementSharedService } from '../customer-management-shared-service';
import { ActivatedRoute } from '@angular/router';
import { filterNil } from '@shared/utils/filter-nil.pipe';

@Component({
  selector: 'app-customer-relations',
  templateUrl: './customer-relations.component.html',
  styleUrls: ['./customer-relations.component.scss'],
})
export class CustomerRelationsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  readonly editCustomer$ =
    this._customerManagementSharedService.isEditCustomerOperation$;
  @ViewChild('RelationControl', { static: true })
  relationControlDropdown: MatSelect;
  customerRelationFilterToggle = true;
  customFilterExpandedHeight = '55px';
  numberOfRows = [25, 50, 75, 100, 125];
  selectedCountOfRows = 25;
  tablePage = 1;
  total_elements: number;
  selected_filter_value = null;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'customer_name',
    'branch_name',
    'relationship_of_company',
    'customer_number',
    'city',
    'country',
    'action',
  ];
  row = 25;
  customer_relations = [];
  showModal: boolean;
  relative_customer_id: any;
  delete_relation_id: number;
  customer_relations_response: any;
  readonly locale$ = this._userService.locale$;
  selectField = 'name1';
  actionButton = ACTION_BUTTON;
  customer_id;
  constructor(
    private _http: ApiCallsService,
    private toastService: ToastService,
    public validationService: ValidationService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _dialog: MatDialog,
    private readonly _customerManagementSharedService: CustomerManagementSharedService,
    private readonly _activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchOnAddingCustomerRelation();

    this._customerSharedService.selectedCustomerId$
      .pipe(filterNil(), take(1))
      .subscribe(customerId => {
        this.customer_id = customerId;

        this.apiCalls();
      });
  }
  apiCalls() {
    const requests = [];
    const relationship_id = this.selected_filter_value
      ? this.selected_filter_value
      : '';
    requests.push(
      this._http.getCustomerRelations().pipe(catchError(error => of(error)))
    );
    requests.push(
      this._http
        .getRelationships(
          this.customer_id,
          relationship_id,
          this.tablePage,
          this.row
        )
        .pipe(catchError(error => of(error)))
    );

    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.customer_relations =
          !data[0].hasErrors && data[0].data ? data[0].data : [];
        this.customer_relations_response =
          !data[1].hasErrors && data[1] ? data[1] : [];
        this.dataSource.data = this.customer_relations_response.data;
        this.total_elements = this.customer_relations_response.total_element;
      });
  }
  numberOfRowsChanged(value) {
    this.row = value.value;
    this.tablePage = 1;
    this.apiCalls();
  }
  tablePageChanged(event) {
    this.tablePage = event;
    this.apiCalls();
  }
  resetFilters() {
    this.selected_filter_value = null;
    this.apiCalls();
  }
  filterCustomerRelations() {
    this.apiCalls();
  }
  delete(id: number, relative_customer_id) {
    this._http
      .deleteCustomerRelation(id, this.customer_id, relative_customer_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.toastService.showToastMessage(document, data.message);
        this.hideModal();
        this.apiCalls();
      });
  }
  openModal(delete_relation_id: number, relative_customer_id) {
    this.relative_customer_id = relative_customer_id;
    this.delete_relation_id = delete_relation_id;
    this.showModal = true;
  }
  hideModal() {
    this.relative_customer_id = null;
    this.showModal = false;
  }
  save(data) {
    const request = data;
    this._http
      .postCustomersRelationship(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.apiCalls();
        this.toastService.showToastMessage(document, data.message);
      });
  }
  relationValueChanged() {
    setTimeout(() => this.relationControlDropdown.focus(), 150);
  }
  focusSearch(id) {
    document.getElementById(id).focus();
  }
  editCustomerRelation({ customer_id, invert_relationship_id, id }) {
    const data = {
      division_id: this._customerSharedService.getSelectedCustomerDivisionId(),
      liztu_vn_kdnr: atob(this._activatedRoute.snapshot.queryParams.liztu),
      customer_id: this.customer_id,
      customer2_id: customer_id,
      relationship_id: invert_relationship_id,
      customer_relationship_id: id,
    };
    this._openCustomerRelationDialog(data);
  }

  private _watchOnAddingCustomerRelation(): void {
    this._customerManagementSharedService.addCustomerRelation$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._addCustomerRelationModal();
      });
  }

  private _openCustomerRelationDialog(data: object): void {
    this._dialog
      .open(AddCustomerRelationComponent, {
        panelClass: 'add-customer-relation',
        width: '700px',
        data,
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (data) {
          this.save(data);
        }
      });
  }

  private _addCustomerRelationModal(): void {
    const data = {
      division_id: this._customerSharedService.getSelectedCustomerDivisionId(),
      liztu_vn_kdnr: atob(this._activatedRoute.snapshot.queryParams.liztu),
      customer_id: this.customer_id,
    };
    this._openCustomerRelationDialog(data);
  }
}
