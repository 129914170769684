<div class="modal upload-logo dialog" id="myModal" [style.display]="showDocumentPopup ? 'block' : 'none'">
  <div class="modal-dialog uploadDocs" style="top: 155px; display: initial; ">
    <div class="modal-content modalContent" style="width: 800px;padding-left:0;padding-right:0">
      <!-- Modal Header -->
      <div class="modal-header" style="padding: 7px 10px 1px 25px;">
        <div style="text-align:initial;">
          <h6 style="font-weight: 600;">{{'product-management.Attach_Template' | translate}} </h6>
          <span style="font-size: 11px;
        color: #a0a0a0;" [innerHTML]="'product-management.template_subheading' | translate"></span>
        </div>
        <button type="button" class="close" data-dismiss="modal" (click)="hidePopup();">
          <img alt="" src="../../../../../assets/images/Group 3114.svg" >
        </button>
      </div>
      <div class="modal-body" style="font-size:12px;height: 254px;padding-left:0;padding-right:0;overflow:scroll">
        <div style="text-align:center">

          <table *ngIf="areFilesAvailable()" style="table-layout: fixed;width: 100%;margin-top: -11px;">
            <tr class="document-row">
              <th style="width: 0px;"></th>
              <th class="document-name-th">{{'product-management.template_name' | translate}}</th>
              <th>{{'product-management.document_type' | translate}}</th>
              <th>{{'product-management.description' | translate}}</th>
              <th>{{'product-management.uploadedby' | translate}}</th>
              <th style="text-align:center">{{'product-management.action' | translate}}</th>
            </tr>
            <tr class="document-row document-data" *ngFor="let item of selectedDocumentsArray | keyvalue: originalOrder; let i = index">
              <td style="padding-left: 10px;"><span
                  *ngIf="item.value.is_obj_linked == false || item.value.is_obj_linked == true"><img alt="" height="12px"
                    src="../../../../../assets/images/Tickmark_Green.svg"></span>
                <span *ngIf="item.value.is_obj_linked == null"><img alt="" height="12px"
                    src="../../../../../assets/images/Upload_pending.svg"></span>
              </td>
              <td class="document-name-td"><span [tooltip]="popTemplate" containerClass="customClass" placement="bottom"
                  class="nameShort">{{item.value.document_name}}</span>
                  <ng-template #popTemplate> <ng-container *ngFor="let title of getTeplate(item.value.title ? item.value.title : item.value.document_name)"> {{title}} <br></ng-container></ng-template>
                </td>
              <td [title]="getDocType(item.value.document_type)">
            <mat-select id="docType{{item.value.id||item.value.upload_document_id}}" [typeaheadDebounceInterval]="750" class="dropdownStyle switcherDrpdwn inputy switcher"
                style="color: #272727;font-weight: 600;border-radius: 3px;height: 33px;background: #fff;"
                placeholder="{{'objectModule.select_lable' | translate}}" [disabled]="enableIndex !== i"
                [(ngModel)]="item.value.document_type" (openedChange)="searchdocType.value = '';">
                <input type="text" class="searchSelect"
                    preventSpace
                    placeholder="{{'header.Suche_tooltip' | translate}}" maxlength="20"
                    #searchdocType>
                <mat-option *ngFor="let doc of  validationService._filter(docType, 'desc', searchdocType.value)" title="{{doc.description}}" [value]="doc.id">
                    {{doc.desc}}
                </mat-option>
            </mat-select>
              </td>
              <td>
                <input class="nameShort" id="docDescription{{item.value.id||item.value.upload_document_id}}"
                  placeholder="{{'product-management.description' | translate}}"
                  title="{{item.value.document_description}}"
                  [(ngModel)]="item.value.document_description"
                  style="border:none;background-color: initial;outline: transparent;" disabled type="text" maxlength="100">
              </td>
              <td title="{{item.value.created_by}}  {{item.value.upload_date | dateFormat}}">
                <span class="custom-ellipsis">{{item.value.created_by}}<br/>
                  <span class="below-text">{{item.value.upload_date | dateFormat}}</span>
                </span>
              </td>

              <td style="text-align: center;"> <span>
                  <button style="background: none; border: none; color: #2175BD" [disabled]="is_disable ? true : false">
                    <img alt="" matTooltip="{{'product-management.EDIT_text' | translate}}"
                      src="../../../../../assets/images/Edit_Description_Icon.svg" style="height: 16px;"
                      (click)="is_disable ? '' : editDescription(item.value.id||item.value.upload_document_id,i)" id="edit{{i}}" >
                    <span style="display: none;background: #ff9000;
                border: none;
                color: #2175BD;
                padding: 0px 3px 2px 3px;
                border-radius: 3px;
                margin-right: -3px;" id="save{{i}}"><img alt="" matTooltip="{{'product-management.save' | translate}}"
                        style="height: 16px;" src="../../../../../assets/images/Save_description_without_bg.svg"
                        (click)="is_disable ? '' : saveDescription(item.value.id||item.value.upload_document_id,item.value.document_name,i,item.value.upload_document_id)"></span>
                  </button>&nbsp;
                  <button style="background: none; border: none; color: #2175BD" [disabled]="true">
                    <img alt="" matTooltip="{{'product-management.download' | translate}}"
                      src="../../../assets/images/Download_Template_Icon.svg" style="height: 16px;">
                  </button>&nbsp;
                  <button style="background: none; border: none; color: #2175BD" [disabled]="true">
                    <img alt="" matTooltip="{{'product-management.replace' | translate}}"
                      src="../../../assets/images/Replace_Template_Icon.svg" style="height: 16px;">
                  </button>&nbsp;
                  <button
                    (click)="processDelete(item.value.document_name, item.value.upload_document_id)"
                    style="background: none; border: none; color: #2175BD" [disabled]="is_disable ? true : false">
                    <img alt="" matTooltip="{{'contracts.Löschen_tooltip' | translate}}"
                      src="../../../assets/images/new-icons/Delete icon.svg" style="height: 16px;">
                  </button>
                </span></td>
            </tr>
          </table>
          <span *ngIf="!areFilesAvailable()" class="noFileText">{{'product-management.no_template_selected' |
            translate}}</span>
        </div>
      </div>
      <div class="modal-footer" style="display: block;margin-bottom: -6px;">
        <label for="uploader-input" ng2FileDrop [uploader]="uploader">
          <div style="font-size: 15px;color: #272727;padding: 7px 0px 8px 0px;
        border: solid 1px #272727;font-weight: 600;border-radius: 3px; width: 140px;" [style.cursor]="is_disable ? 'inherit' : 'pointer'">
            {{'objectModule.browse_text' | translate}}</div>
        </label>&nbsp;&nbsp;&nbsp;
        <button [disabled]="attachTemplateEnabled() || is_disable" class="ml-2" type="button"
          style="font-size: 15px;color: #272727;background: #ff9000;;font-weight: 600;padding: 0px 22px 0px 22px;border: solid 1px #ff9000; width: 140px;"
          mat-button>
          {{'product-management.upload' | translate}}</button>
        <input type="file" #fileUpload id="file"
          accept=".jrxml,.zip" ng2FileSelect [uploader]="uploader"
          (change)="handleFileInput($event.target.files)" id="uploader-input" style="display:none" [disabled]="is_disable ? true : false" />
      </div>
    </div>
  </div>
</div>
<custom-warning-popup *ngIf="showModal" [cloudDocId]="cloudDocId" [documentName]="docNameToDelete"
  (onHideModal)="onHideModal($event)" (onDelete)="deleteDocument($event)"></custom-warning-popup>
