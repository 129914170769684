import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { CustomerManagementSharedService } from '../customer-management-shared-service';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'app-contact-person-page',
  templateUrl: './contact-person-page.component.html',
  styleUrls: ['./contact-person-page.component.scss'],
})
export class ContactPersonPageComponent
  extends BaseComponent
  implements OnInit
{
  contactPersonAddEdit = false;
  contactPersonId: string;
  add_contact_person = false;

  constructor(
    private readonly _customerManagementSharedService: CustomerManagementSharedService,
    private readonly _toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchOnAddingContactPerson();
  }

  isContactPersonAdd(event): void {
    this.add_contact_person = event;
  }

  isContactPersonAddEdit(event): void {
    this.contactPersonAddEdit = event;
  }

  getContactPersonId(event): void {
    this.contactPersonId = event;
  }

  showMessage(message: string): void {
    this._toastService.showToastMessage(document, message);
  }

  private _watchOnAddingContactPerson(): void {
    this._customerManagementSharedService.addContactPerson$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.contactPersonId = null;
        this.contactPersonAddEdit = true;
        this.add_contact_person = true;
      });
  }
}
