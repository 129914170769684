export enum ReminderValues {
  START = 0,
  FIVE_MINUTES = 5,
  FIFTEEN_MINUTES = 15,
  THIRTY_MINUTES = 30,
  ONE_HOUR = 60,
  TWO_HOURS = 120,
  FOUR_HOURS = 240,
  ONE_DAY = 1440,
  ONE_WEEK = 10080
}

export const REMINDER_LABELS = {
  [ReminderValues.START]: 'appointments.reminder.start',
  [ReminderValues.FIVE_MINUTES]: 'appointments.reminder.five_minutes',
  [ReminderValues.FIFTEEN_MINUTES]: 'appointments.reminder.fifteen_minutes',
  [ReminderValues.THIRTY_MINUTES]: 'appointments.reminder.thirty_minutes',
  [ReminderValues.ONE_HOUR]: 'appointments.reminder.one_hour',
  [ReminderValues.TWO_HOURS]: 'appointments.reminder.two_hours',
  [ReminderValues.FOUR_HOURS]: 'appointments.reminder.four_hours',
  [ReminderValues.ONE_DAY]: 'appointments.reminder.one_day',
  [ReminderValues.ONE_WEEK]: 'appointments.reminder.one_week',
};

export const REMINDER_OPTIONS = [
  {
    label: REMINDER_LABELS[ReminderValues.START],
    value: ReminderValues.START,
  },
  {
    label: REMINDER_LABELS[ReminderValues.FIVE_MINUTES],
    value: ReminderValues.FIVE_MINUTES,
  },
  {
    label: REMINDER_LABELS[ReminderValues.FIFTEEN_MINUTES],
    value: ReminderValues.FIFTEEN_MINUTES,
  },
  {
    label: REMINDER_LABELS[ReminderValues.THIRTY_MINUTES],
    value: ReminderValues.THIRTY_MINUTES,
  },
  {
    label: REMINDER_LABELS[ReminderValues.ONE_HOUR],
    value: ReminderValues.ONE_HOUR,
  },
  {
    label: REMINDER_LABELS[ReminderValues.TWO_HOURS],
    value: ReminderValues.TWO_HOURS,
  },
  {
    label: REMINDER_LABELS[ReminderValues.FOUR_HOURS],
    value: ReminderValues.FOUR_HOURS,
  },
  {
    label: REMINDER_LABELS[ReminderValues.ONE_DAY],
    value: ReminderValues.ONE_DAY,
  },
  {
    label: REMINDER_LABELS[ReminderValues.ONE_WEEK],
    value: ReminderValues.ONE_WEEK,
  },
];
