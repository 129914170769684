<ul class="avatar-group">
    <li *ngFor="let avatar of avatars | slice:0:maxAvatarsCount; let i=index"
        class="avatar-group__item"
        [style.right.px]="i * (avatarSizes[size] / 3)">
        <app-avatar [src]="avatar.src"
                   [matTooltip]="avatar.name"
                   [initials]="avatar.name | initials"
                   [alt]="avatar.name"
                   [size]="size">
        </app-avatar>
    </li>
    <li *ngIf="avatars.length > maxAvatarsCount"
        class="avatar-group__item avatar-group__item--others"
        [class.avatar-group__item--big]="avatarSizes[size] > avatarSizes['xs']"
        (click)="clickToAction()"
        [ngStyle]="{
            width: avatarSizes[size] + gap + 'px',
            height: avatarSizes[size] + gap + 'px',
            right: (avatars.length > maxAvatarsCount ? maxAvatarsCount : avatars.length) * (avatarSizes[size] / 3) + 'px',
            fontSize: fontSize + 'px'
        }">
        <span>
            +{{ avatars.length - maxAvatarsCount }}
        </span>
    </li>
</ul>
