import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { ClaimsShareService } from '../claims-share.service';

@Component({
  templateUrl: './claim-contact-person-page.component.html',
})
export class ClaimContactPersonPageComponent
  extends BaseComponent
  implements OnInit
{
  addContactPersonEvent = false;
  contactPersonAddEdit = false;
  contactPersonId: string;
  sendMessage: string;

  constructor(private readonly _claimsSharedService: ClaimsShareService) {
    super();
  }
  ngOnInit(): void {
    this.watchOnAddingContactPerson();
  }
  isContactPersonAdd(event: boolean): void {
    this.addContactPersonEvent = event;
  }
  isContactPersonAddEdit(event: boolean): void {
    this.contactPersonAddEdit = event;
  }
  getContactPersonId(event: string): void {
    this.contactPersonId = event;
  }

  getMessage(event: string): void {
    this.sendMessage = event;
  }

  watchOnAddingContactPerson(): void {
    this._claimsSharedService.addContactPerson$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.contactPersonId = null;
        this.contactPersonAddEdit = true;
        this.addContactPersonEvent = true;
      });
  }
}
