import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { iif, of, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { SalutationService } from '@core/services/salutation.service';
import { ToastService } from '@core/services/toast.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { CommunicationService } from '@core/services/communication.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { AZURE } from '@shared/constants/azure-service.constants';
import { UpdateUserComponent } from '../update-user/update-user.component';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { DocumentSource } from '@shared/models/document-management.model';
import { REGEX } from '@shared/constants/general/regex.constants';
import { Country } from '@shared/interfaces/country.interface';
import { BaseComponent } from '@shared/components/base.component';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { LOCALS } from '@shared/constants/local.constants';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { ShareDataService } from '@core/services/share-data.service';
import { SparteShareService } from '@core/services/sparte-share.service';

export interface PeriodicElement {
  user_id: string;
  user_name: string;
  customer_name: string;
  customer_id: string;
  TYPE: string;
  EMAIL: string;
  city: string;
  country: string;
  house: string;
  status: string;
  division: string;
  is_email_sent: boolean;
  is_invited_to_rcs: boolean;
  invitation_language: string;
  supported_languages: any;
  role: string;
  salutation_id: number;
  street: string;
  address: string;
  first_name: any;
  last_name: any;
  is_vip: boolean;
  last_login: string;
  is_logged_in: boolean;
  time_invited_to_rcs: string;
  telefon: string;
  zip_code: string;
  invite_user: boolean;
  customer_address_lat_lon_set: boolean;
  lat: any;
  lon: any;
  is_source_ivm: boolean;
  liztu_vn_kdnr: number;
  user_idnr: number;
  mfa_mode: number;
  is_invite_reset: boolean;
  role_id: any;
}

export interface SendingDeleteResetUserData {
  user_id: string;
  user_role: string;
  user_customer_type: string;
  actor_id: number;
  actor_role: string;
  actor_customer_type: string;
  actor_email: string;
}
export interface InviteUserDialogData {
  customer_id: string;
  user_id: string;
  user_name: string;
  first_name: any;
  last_name: any;
  Telefon: string;
  email: string;
  customer: string;
  salutation_id: number;
  status: string;
  role: string;
  division: string;
  childcustomer: string;
  otp: string;
  house: string;
  street: string;
  zip_code: string;
  city: string;
  country: string;
  supported_languages: any;
  viaemail: string;
  vialetter: string;
  is_vip: boolean;
  is_invite_to_be_sent: boolean;
  is_invited_to_rcs: boolean;
  is_invite_reset: boolean;
  title: string;
  invitation_language: string;
  buttonCancelName: string;
  buttonConfirmName: string;
  newUser: boolean;
  otpTo: string;
  customer_address_lat_lon_set: boolean;
  lat: any;
  lon: any;
  address: String;
  customer_name: String;
  is_source_ivm: boolean;
  liztu_vn_kdnr: number;
  user_idnr: number;
  type: string;
  mfa_mode: number;
  is_group: boolean;
  role_id: any;
}
// MasterDataRow, CustomerInformation, AccountDetails are added for master Data model
export interface MasterDataRow {
  name: string;
  type: string;
  liztu_vn_kdnr: number;
  isExpanded: boolean;
  additionalDetailsExpanded: boolean;
  accountDetailsExpanded: boolean;
  artusContactPersonExpanded: boolean;
  customerInformation: CustomerInformation;
  accountDetails: AccountDetails[];
  additionalDetails: AdditionalDetails;
  masterDataEdit: boolean;
  selectedDocuments: string;
}
export interface CustomerInformation {
  customerNumber: string;
  dunsNumber: string;
  customerName: string;
  customerId: number;
  activationFlag: string;
  customerGroup: string;
  roleOfCustomer: string;
  classification: string;
  language: string;
  email: string;
  homePage: string;
  house: string;
  street: string;
  telephone_number: number;
  zip_code: number;
  city: string;
  country: string;
  address: string;
  lat: number;
  lon: number;
  customer_address_lat_lon_set: boolean;
  cityValidate: boolean;
  houseValidate: boolean;
  streetValidate: boolean;
  zipValidate: boolean;
}
export interface AdditionalDetails {
  broker_mandate_date: string;
  cmp_desc: string;
  cmp_desc_short: string;
  crn: string;
  css_email: string;
  broker_mandate: string;
  last_annual_meeting: string;
  legal_form: string;
  turnover: number;
  vat_number: number;
  turnover_validate: boolean;
  short_description_validate: boolean;
  long_description_validate: boolean;
  branch_name: string;
  est_of_cmp: string;
  fiscal_year: string;
  is_seasonal_op: boolean;
  actual_fiscal;
  dummy_fiscal;
  is_est_of_cmp_error: boolean;
  is_fiscal_year_error: boolean;
}
export interface AccountDetails {
  account_number: string;
  bank_name: string;
  bank_type: string;
  bic: string;
  iban: string;
  owner: string;
  valid_from: string;
}
// For Update MasterData
export interface CustomerInformationUpdate {
  activation_flag: string;
  address: string;
  city: string;
  classification: string;
  customer_address_lat_lon_set: boolean;
  customer_id: string;
  country: string;
  house: string;
  lat: number;
  liztu_vn_kdnr: number;
  lon: number;
  name: string;
  street: string;
  telephohne: string;
  type: string;
  zip_code: string;
  email: string;
  language_id: number;
}
export interface AdditionalDetailsUpdate {
  cmp_desc: string;
  cmp_desc_short: string;
  crn: string;
  documents: any[];
  duns_number: string;
  broker_mandate: string;
  last_annual_meeting: string;
  liztu_vn_kdnr: number;
  turnover: number;
  vat_number: string;
  broker_mandate_date: string;
  legal_form: string;
  css_email: string;
  branch_name: string;
  est_of_cmp: string;
  fiscal_year: string;
  is_seasonal_op: boolean;
}
export interface AccountDetailsUpdate {
  account_number: number;
  bank_type: string;
  valid_from: string;
}
export interface ContactPersonUpdate {
  address: string;
  email: string;
  link: string;
  mobileNumber: string;
  name: string;
  phoneNumber: string;
  profile_image_url: string;
  responsibility: string;
}
export interface MasterDataUpdate {
  user_id: number;
  role: string;
  customer_info: CustomerInformationUpdate;
  additional_details: AdditionalDetailsUpdate;
  account_details: AccountDetailsUpdate[];
  contact_persons: ContactPersonUpdate[];
}
export interface documentElement {
  name: string;
  link: string;
  type: string;
  desc: string;
  id: string;
  date: string;
  upload_via: string;
}
@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.css'],
})
export class UserDataComponent extends BaseComponent implements OnInit {
  @Output('emitUserData') emitUserData = new EventEmitter<any>();
  defaultSelectedCustomerValue: any;
  is_source_ivm: boolean;
  user_idnr;
  decoded_org;
  finaltype: any;
  subsidiaries = [];
  user;
  data_delivery_date;
  data_delivery_cycle;
  logo;
  contact_manager;
  showInviteBtn = true;
  customExpandedHeight = '45px';
  customFilterExpandedHeight = '55px';
  artusCustomFilterExpandedHeight = '55px';
  customerFilterToggle = true;
  userManagementToggle = true;
  masterDataToggle = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  sidebarState: string;
  dataSource = new MatTableDataSource();
  formControl;
  filterCustomerForm;
  UserDataParentObject: any;
  statusAvailable: any;
  UserData: any;
  openStatusFilter = false;
  divisionList = [];
  org;
  showModal = false;
  showDeleteModal = false;
  showDeleteModalIvm = false;
  updatemessage = '';
  searchValue;
  inviteUser = false;
  organizationList = [];
  data = [];
  user_role: USER_ROLES;
  division_id;
  division_name;
  specific_roles;
  userData: any = null;
  userid;
  childcustomer;
  cstmr;
  page = 1;
  empty: string;
  message;
  newUserData: InviteUserDialogData;
  division;
  finalCustomerName: any;
  customer;
  customer_user = USER_ROLES.CUSTOMER_USER;
  type;
  userOBJ = {
    liztu_vn_kdnr: '',
    user_role: '',
    user_idnr: '',
  };
  updateUserOBJ = {
    actor_customer_type: '',
    user_id: '',
    customer_id: '',
    customer_name: '',
    salutation_id: '',
    first_name: '',
    last_name: '',
    telefon: '',
    user_name: '',
    email: '',
    house: '',
    street: '',
    zip_code: '',
    city: '',
    country: '',
    division: '',
    type: '',
    role: '',
    address: '',
    invitation_language: '',
    is_vip: false,
    is_invited_to_rcs: true,
    is_invite_to_be_sent: true,
    is_temporary: true,
    lat: null,
    lon: null,
    is_source_ivm: false,
    inviter_username: '',
    mfa_mode: null,
    actor_id: '',
    actor_role: '',
    inviter_email: '',
  };
  isICUser: boolean;
  isCustomerUser = false;
  single_user_data = [];
  public show = false;
  updateUser;
  inviter_user;
  public buttonName = '../../../assets/images/filter.png';
  displayedColumns: string[] = [
    'NAME',
    ' ',
    'CUSTOMER',
    'CUSTOMER_TYPE',
    'ROLE',
    'EMAIL',
    'STATUS',
    'LAST_LOGIN',
    'ACTION',
  ];
  revertInvitation = false;
  updateInviteMessage: string;
  timer: NodeJS.Timeout;
  counter: number;
  masterDataRows: MasterDataRow[] = [];
  customerGroups = ['AKO', 'Star', 'ABP GMBH', 'Anton', 'Artus'];
  customerRoles = ['Mutter', 'Tochter', 'Overseas', 'Private', 'Private VIP'];
  classification = [
    { name: 'Private customer', symbol: 'P' },
    { name: 'Commercial customer', symbol: 'G' },
    { name: 'Industrial customer', symbol: 'I' },
  ];
  classificationGerman = [
    { name: 'Privat Kunde', symbol: 'P' },
    { name: 'Kommerzieller Kunde', symbol: 'G' },
    { name: 'Industrieller Kunde', symbol: 'I' },
  ];
  languages = [
    { name: 'German', flag: '1' },
    { name: 'English', flag: '2' },
  ];
  activationFlag = [
    { name: 'Prospect', symbol: 'I' },
    { name: 'Active Customer', symbol: 'A' },
    { name: 'Cancelled Customer', symbol: 'S' },
  ];
  activationFlagGerman = [
    { name: 'Interessent', symbol: 'I' },
    { name: 'Aktiver Kunde', symbol: 'A' },
    { name: 'Stornierter Kunde', symbol: 'S' },
  ];
  selectedClassification = '';
  selectedActivationFlag = '';
  disable = true;
  customerInformation: CustomerInformation;
  masterDataRowsData: MasterDataRow[] = [];
  filterlocation: any = [];
  additionalDataExpanded = true;
  contactPersonExpanded = true;
  selectedaddress: any;
  city = false;
  house = false;
  street = false;
  zip = false;
  Alllocations: any;
  errorMessageArray = [];
  Hausnummer_validation: string;
  Stadt_validation: string;
  strabe: string;
  Postleitzahl_validation: string;
  Straße_validation: string;
  country = '';
  postalregexppattern: any;
  addressChanged = false;
  saveDisabled = false;
  placeholder: any;
  orgid: any;
  selectedOrgId: any;
  userIdnr: any;
  deleteUserIdnr: any;
  selectedCustomer: any;
  selectedCustomerLiztu: any;
  customerType: any;
  userRole: any;
  userCustomerTyp: any;
  userId: any;
  actorEmail: any;
  selectedStatus = 'Status';
  decoded_liztu: any;
  liztu: any;
  openDocUpload = false;
  customerData;
  allowedWidgets = [];
  contractInformation: any[] = [];
  inviteresponse: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  sortDir = 1;
  salutation_id: string;
  customer_name: string;
  inviter_email: string;
  selectLang: LOCALS;
  masterDataObj: any;
  messageShow: any;
  pageNumber: any;
  loading = true;
  thumbnail = [];
  element: any;
  pdfPageNum: any;
  pdfPageArray = [];
  pageVariable = 0;
  pdfLink;
  showPDF: boolean;
  zoom = 1.0;
  selectedLoc = '';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public secondaryColour = '#d6d1d170';
  userDataValue: any;
  divisionName: any;
  firefoxBrowser: boolean;
  isDisabled = true;
  birthdayError = false;
  public keyUp = new Subject<string>();
  showList = false;
  statusDisabled: boolean;
  original_liztu = true;
  today = new Date();
  maxDate = new Date(2000, 11, 31);
  minDate = new Date(2000, 0, 1);
  todaysDay = new Date().getDate();
  todaysMonth = new Date().getMonth();
  selectedDate = new Date(2000, this.todaysMonth, this.todaysDay);
  actualFiscal = null;
  dummyFiscal = '';
  invalidFiscal: boolean;
  pageBreadcrumbText = 'user-data.heading_label';
  isCustomerSelected = true;
  decustomer: string;
  masterdata = null;
  isCustomerAdmin = false;
  customerName$ = this._customerSharedService.selectedCustomerName$;
  readonly countries$ = this.serviceCall
    .getCountries()
    .pipe(tap((data: Country[]) => (this._countries = data)));
  private _countries: Country[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serviceCall: ApiCallsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private translate: TranslateService,
    private salutationService: SalutationService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private _toastSerice: ToastService,
    public validationService: ValidationService,
    private readonly _documentsApiService: DocumentManagementApiService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _shareDataService: ShareDataService,
    private readonly _sparteShareService: SparteShareService
  ) {
    super();

    this.filterCustomerForm = this.formBuilder.group({
      UnternehmenCustomer: ['UnternehmenCustomer'],
      Status: ['Status'],
    });

    this.filterCustomerForm
      .get('UnternehmenCustomer')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (value !== null || value !== '') {
          this.filterCustomerForm.get('Status').setValue('Status');
        }
      });

    this.formControl = this.formBuilder.group({
      DIVISION: '',
      CUSTOMER: ['CUSTOMER'],
      StatusArtus: ['StatusArtus'],
      UnternehmenArtus: ['UnternehmenArtus'],
    });
  }

  get UnternehmenCustomer() {
    return this.filterCustomerForm.get('UnternehmenCustomer');
  }
  get Status() {
    return this.filterCustomerForm.get('Status');
  }
  get CUSTOMER() {
    return this.formControl.get('CUSTOMER');
  }
  get StatusArtus() {
    return this.formControl.get('StatusArtus');
  }

  get flagByLocale(): Record<'name' | 'symbol', string>[] {
    return this.selectLang === LOCALS.ENGLISH
      ? this.activationFlag
      : this.activationFlagGerman;
  }

  get classificationFieldByLocale(): Record<'name' | 'symbol', string>[] {
    return this.selectLang === LOCALS.ENGLISH
      ? this.classification
      : this.classificationGerman;
  }

  ngOnInit(): void {
    this._watchOnLocale();
    this.firefoxBrowser = navigator.userAgent.indexOf('Firefox') != -1;
    this.user_role = this._userService.getUserRole();
    this.isCustomerAdmin = this.user_role === USER_ROLES.CUSTOMER_ADMIN;

    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  onSortClick(event) {
    const target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-arrow-up')) {
      classList.remove('fa-arrow-up');
      classList.add('fa-arrow-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-arrow-up');
      classList.remove('fa-arrow-down');
      this.sortDir = 1;
    }
  }
  resetCustomerFilter() {
    this.statusDisabled = false;
    this.isDisabled = true;
    this.original_liztu = true;
    if (ROLES.CUSTOMER_ROLES.includes(this.userRole)) {
      this.loadUserforCustomerReset();
    }
    this.loadCustomerListForReset();
    this.filterCustomerForm.controls.Status.setValue('Status');
  }
  getStatus(item) {
    this.isDisabled = false;
    this.selectedStatus = item.value;
  }
  getSelectedCustomer(item) {
    if (item.value.liztu_vn_kdnr === this.selectedOrgId) {
      this.original_liztu = true;
    } else {
      this.original_liztu = false;
    }
    this.isDisabled = false;
    this.statusDisabled = true;
    this.selectedCustomer = item.value;
    this.selectedCustomerLiztu = this.selectedCustomer.liztu_vn_kdnr;
    this.selectedStatus = this.filterCustomerForm.get('Status').value;
  }
  getCustFormsValue() {
    this.statusDisabled = false;
    this.userData = [];
    if (this.isCustomerUser) {
      this.org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
      this.userOBJ.liztu_vn_kdnr = this.org;
      if (
        this.selectedCustomerLiztu != undefined ||
        this.selectedCustomerLiztu != null
      ) {
        this.userOBJ.liztu_vn_kdnr = this.selectedCustomerLiztu;
      } else if (
        this.selectedCustomerLiztu === null ||
        this.selectedCustomerLiztu === undefined ||
        this.decoded_liztu === null
      ) {
        this.userOBJ.liztu_vn_kdnr = this.org;
      }

      if (
        this.decoded_liztu != null &&
        this.selectedCustomerLiztu === undefined
      ) {
        this.userOBJ.liztu_vn_kdnr = this.liztu;
      } else if (
        this.decoded_liztu != null &&
        this.selectedCustomerLiztu != undefined
      ) {
        this.userOBJ.liztu_vn_kdnr = this.selectedCustomerLiztu;
      }
      if (
        this.decoded_liztu != null &&
        this.selectedOrgId === this.selectedCustomerLiztu
      ) {
        this.userOBJ.liztu_vn_kdnr = this.liztu;
      }

      this.userOBJ.user_role = this.user_role;
      this.userOBJ.user_idnr = this.userIdnr;
      const filterFormdata = this.userOBJ;
      this.serviceCall
        .getUserList(filterFormdata)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.loadUserData(data);
        });
    }
    if (this.isICUser) {
      this.org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
      this.userOBJ.liztu_vn_kdnr = this.org;
      if (this.original_liztu === true) {
        this.userOBJ.liztu_vn_kdnr = this.selectedOrgId;
      } else {
        this.userOBJ.liztu_vn_kdnr = this.selectedCustomerLiztu;
      }
      this.userOBJ.user_role = this.user_role;
      this.userOBJ.user_idnr = null;
      const filterFormdata = this.userOBJ;
      this.serviceCall
        .getUserList(filterFormdata)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.loadUserData(data);
        });
    }
  }
  loadCustomerList() {
    if (ROLES.IC_ROLES.includes(this.userRole)) {
      this.formatUserDataNew(this.data);
    }
    this.is_source_ivm =
      this.userDataValue.user_profile.user_data.is_source_ivm;
    if (
      this.decoded_liztu !== null &&
      this.customer &&
      this.decoded_liztu !== 'null'
    ) {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
    } else if (this.customer && this.decoded_liztu === 'null') {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
    } else {
      this.org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
      this.userOBJ.liztu_vn_kdnr = this.org;
    }
    this.userOBJ.user_role = this.user_role;
    if (this.isCustomerUser) {
      this.userOBJ.user_idnr = this.userIdnr;
    } else {
      this.userOBJ.user_idnr = null;
    }
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.loadUserData(data);
      });
    if (!this.userDataValue.error) {
      if (this.userDataValue.user_profile != null) {
        this.formatUserDataNew(this.userDataValue);
      } else {
        alert(
          this.translate.instant(
            'customer-management.user_managment.no_profile_found'
          )
        );
      }
    }
  }
  loadCustomerListForReset() {
    if (ROLES.IC_ROLES.includes(this.userRole)) {
      this.formatUserDataNew(this.data);
    }
    this.is_source_ivm =
      this.userDataValue.user_profile.user_data.is_source_ivm;
    this.emitUserData.emit(this.userDataValue);
    this.org = this.selectedOrgId;
    this.userOBJ.liztu_vn_kdnr = this.org;
    this.userOBJ.user_role = this.user_role;
    if (this.isCustomerUser) {
      this.userOBJ.user_idnr = this.userIdnr;
    } else {
      this.userOBJ.user_idnr = null;
    }
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.loadUserDataReset(data);
      });
    if (!this.userDataValue.error) {
      if (this.userDataValue.user_profile != null) {
        this.formatUserDataNew(this.userDataValue);
      } else {
        alert(
          this.translate.instant(
            'customer-management.user_managment.no_profile_found'
          )
        );
      }
    }
  }
  formatUserDataNew(UserItem?) {
    const newUserItem = Array.isArray(UserItem) ? UserItem : [UserItem];
    if (newUserItem.length) {
      const userRole = this._userService.getUserRole();
      const isICUser = ROLES.IC_ROLES.includes(userRole);
      this.user_idnr = this.user_idnr === null ? '' : this.user_idnr;
      this.user_idnr = this.user_idnr === null ? '' : this.user_idnr;
      this.is_source_ivm =
        this.is_source_ivm === null ? false : this.is_source_ivm;
      if (isICUser) {
        if (this.customer) {
          this.serviceCall
            .getSubsidiariesCustomerManagement(
              this.customer,
              this.user_idnr,
              this.user_role,
              this.is_source_ivm,
              this.route.snapshot.queryParamMap.get('is_customer_source_ivm')
                ? atob(
                    this.route.snapshot.queryParamMap.get(
                      'is_customer_source_ivm'
                    )
                  )
                : true
            )
            .pipe(takeUntil(this._destroy$))
            .subscribe(obj => {
              this.subsidiaries = obj.associated_customers;
              if (this.subsidiaries.length > 0) {
                for (let i = 0; i < this.subsidiaries.length; i++) {
                  if (this.subsidiaries[i].customer_id === this.customer) {
                    this.defaultSelectedCustomerValue = this.subsidiaries[i];
                    this._shareDataService.messageType = null;
                    this.finaltype = this.defaultSelectedCustomerValue.type;
                    this.finalCustomerName =
                      this.defaultSelectedCustomerValue.customer_name;
                    this.orgid =
                      this.defaultSelectedCustomerValue.liztu_vn_kdnr;
                  }
                }
                this.selectedOrgId = this.orgid;
                if (this.masterDataRows.length === 0) {
                  this.loadMasterDataCustomers();
                }
              }
            });
        } else {
          const subsidiries =
            this.communicationService.getAssociatedCustomerValues();
          this.subsidiaries = subsidiries.map(obj => {
            const newSub: any = {};
            newSub.customer_id = obj.customer_id;
            newSub.user_id = obj.user_id;
            newSub.customer_name = obj.customer_name;
            newSub.type = obj.TYPE;
            return newSub;
          });
          this.defaultSelectedCustomerValue = this.subsidiaries.find(
            x => x.customer_id === this.customer
          );
        }
      } else {
        this.subsidiaries = UserItem.user_profile.associated_customers;
        this.defaultSelectedCustomerValue = this.subsidiaries.find(
          x =>
            x.customer_id === UserItem.user_profile.customer_details.customer_id
        );
        if (this.subsidiaries.length > 0) {
          for (let i = 0; i < this.subsidiaries.length; i++) {
            if (this.subsidiaries[i].customer_id === this.customer) {
              this.defaultSelectedCustomerValue = this.subsidiaries[i];
            }
          }
        }
      }
      if (this.masterDataRows.length === 0) {
        this.loadMasterDataCustomers();
      }
    }
  }
  showInviteButton() {
    if (this.userManagementToggle === true) {
      this.showInviteBtn = false;
    }
    if (this.userManagementToggle === false) {
      this.showInviteBtn = true;
    }
  }
  resetArtusFilter() {
    this.formControl.reset();
    this.filterCustomerForm
      .get('UnternehmenArtus')
      .setValue('UnternehmenArtus');
    this.formControl.get('StatusArtus').setValue('StatusArtus');
    this.dataSource.filter = '';
  }
  userDataReceived(data) {
    this.userDataValue = data;
    this.divisionName = data.user_profile.customer_details.name;
    this.dataSource.paginator = this.paginator;
    this.masterdata = null;
    this.loadDivision();
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
    this.specific_roles = [
      USER_ROLES.IC_USER,
      USER_ROLES.IC_BRANCH_ADMIN,
    ].includes(this.user_role);

    this.customerType = data.user_profile.customer_details.type;
    this.userid = data.user_profile.user_data.user_id;
    this.userIdnr = data.user_profile.user_data.user_idnr;
    this.actorEmail = data.user_profile.user_data.email_id;
    this.org = data.user_profile.customer_details.liztu_vn_kdnr;
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);

    this.decoded_liztu = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu = atob(this.decoded_liztu);

    this._customerSharedService.saveCustomerId(this.customer);

    this._customerSharedService.customerGeneralData$
      .pipe(filterNil(), take(1))
      .subscribe(customerGeneralData => {
        this.customer = customerGeneralData.customer_info.customer_id;
        this.user = customerGeneralData.customer_info.customer_id;
        this.type = customerGeneralData.customer_status.type;
        this.is_source_ivm = customerGeneralData.is_source_ivm;

        if (this.decoded_liztu === 'null' || this.decoded_liztu === null) {
          if (this.customer) {
            const sub = this._customerSharedService.getSubsidiaries();
            for (let i = 0; i < sub.length; i++) {
              if (sub[i].customer_id === this.customer) {
                this.liztu = sub[i].liztu_vn_kdnr;
                this._userService.saveDeliztu(this.liztu);
              }
            }
          }
        }
        if (
          this.customer &&
          this.is_source_ivm !== null &&
          this.decoded_liztu &&
          this.type
        ) {
          this.decustomer = this.customer;
          this._userService.saveDeliztu(this.liztu);
          this._shareDataService.deType = this.type;
        }
        this.loadCustomerList();
        if (ROLES.CUSTOMER_ROLES.includes(this.userRole)) {
          this.loadUserforCustomer();
          this.getDivisionCustomer();
        }
      });

    this.setAvailableStatusValues();
    if (this._shareDataService.getReloadValue() && this.decustomer !== null) {
      this._shareDataService.saveReloadValue(null);
      this.router.navigate(['/users'], {
        queryParams: {
          user: btoa(this.user),
          customer: btoa(this.decustomer),
          source: String(this.is_source_ivm),
          liztu: btoa(this._userService.getDeliztu()),
          type: btoa(this._shareDataService.deType),
        },
      });
      this.decustomer = null;
      this._userService.saveDeliztu(null);
      this._shareDataService.deType = null;
    }
  }
  loadDivision() {
    this.userid = this.userDataValue.user_profile.user_data.user_id;
    this.inviter_user = this.userDataValue.user_profile.user_data.email_id;
    this.org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
    this.salutation_id =
      this.userDataValue.user_profile.user_data.salutation_id;
    this.customer_name =
      this.userDataValue.user_profile.user_data.customer_name;
    this.inviter_email = this.userDataValue.user_profile.user_data.email_id;
    this.is_source_ivm =
      this.userDataValue.user_profile.user_data.is_source_ivm;
    this.user_idnr = this.userDataValue.user_profile.user_data.user_idnr;
    this.serviceCall
      .getDivision(this.userid)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (
          this.user_role === USER_ROLES.IC_USER ||
          this.user_role === USER_ROLES.IC_BRANCH_ADMIN
        ) {
          for (let i = 0; i < data.divisions.length; i++) {
            this.division_id = data.divisions[i].division_id;
            this.division_name = data.divisions[i].division_name;
          }
          this.onSelectedDivisionAutomatically(this.division_id);
        }
        this.divisionList = data.divisions;
      });
  }
  loadUserforCustomer() {
    this.userIdnr = this.userDataValue.user_profile.user_data.user_idnr;
    if (this.decoded_liztu !== null) {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
    } else {
      this.org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
      this.userOBJ.liztu_vn_kdnr = this.org;
    }
    this.userOBJ.user_role = this.user_role;
    if (this.isCustomerUser) {
      this.userOBJ.user_idnr = this.userIdnr;
    } else {
      this.userOBJ.user_idnr = null;
    }
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.loadUserData(data);
      });

    if (!this.userDataValue.error) {
      if (this.userDataValue.user_profile != null) {
        this.formatUserDataNew(this.userDataValue);
      } else {
        alert(
          this.translate.instant(
            'customer-management.user_managment.no_profile_found'
          )
        );
      }
    }
  }
  loadUserforCustomerInvite() {
    this.org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
    if (this.decoded_liztu != null) {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
    } else {
      this.userOBJ.liztu_vn_kdnr = this.org;
    }
    this.userOBJ.user_role = this.user_role;
    if (this.isCustomerUser) {
      this.userOBJ.user_idnr = this.userIdnr;
    } else {
      this.userOBJ.user_idnr = null;
    }
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.loadUserDataForInvite(data);
      });
  }
  loadUserforCustomerReset() {
    this.org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
    if (this.decoded_liztu != null) {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
      this.selectedCustomerLiztu = this.liztu;
    } else {
      this.userOBJ.liztu_vn_kdnr = this.org;
      this.selectedCustomerLiztu = this.org;
    }
    this.userOBJ.user_role = this.user_role;
    if (this.isCustomerUser) {
      this.userOBJ.user_idnr = this.userIdnr;
    } else {
      this.userOBJ.user_idnr = null;
    }
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.loadUserDataReset(data);
      });
  }
  loadUserData(data) {
    if (!data) {
      return;
    }
    this.formatUserData(data);
  }
  loadUserDataForInvite(data) {
    if (!data) {
      return;
    }
    this.formatUserDataForInvite(data);
  }
  loadUserDataReset(data) {
    if (!data) {
      return;
    }
    this.formatUserDataForReset(data);
  }
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  formatUserData(userData) {
    this.UserData = [];
    let statuss;
    if (userData.users.length > 0) {
      for (let i = 0; i < userData.users.length; i++) {
        this.childcustomer = btoa(userData.users[i].customer_id);
        this.cstmr = this.childcustomer;
        if (userData.users[i].is_invited_to_rcs === false) {
          statuss = 'Nicht eingeladen';
        } else if (userData.users[i].is_invited_to_rcs === true) {
          if (userData.users[i].is_logged_in === false) {
            statuss = 'Eingeladen';
          } else {
            if (this.selectLang === 'German(DE)') {
              statuss = 'Aktiv';
            } else {
              statuss = 'Active';
            }
          }
        }
        if (userData.users[i].is_invited_to_rcs === false) {
          this.revertInvitation = true;
        } else if (
          userData.users[i].is_invited_to_rcs === true ||
          userData.users[i].is_logged_in === true
        ) {
          this.revertInvitation = false;
        }
        const tableData = this.setTableData(
          userData.users[i],
          statuss,
          userData.supported_languages
        );
        if (
          tableData.status === this.selectedStatus ||
          this.selectedStatus === 'Status'
        ) {
          this.UserData.push(tableData);
        }
      }
      this.UserDataParentObject = JSON.parse(JSON.stringify(this.UserData));
      this.displayUserData();
      this.setAvailableStatusValues();
      this.communicationService.setAssociatedCustomerValues(this.UserData);
    } else {
      this.empty = '';
    }
    if (
      this.user_role === USER_ROLES.CUSTOMER_USER &&
      userData.users.length < 1
    ) {
      this.dataSource.data = this.UserData;
    }
  }
  formatUserDataForInvite(userData) {
    this.UserData = [];
    let statuss;
    if (userData.users.length > 0) {
      for (let i = 0; i < userData.users.length; i++) {
        this.childcustomer = btoa(userData.users[i].customer_id);
        this.cstmr = this.childcustomer;
        if (userData.users[i].is_invited_to_rcs === false) {
          statuss = 'Nicht eingeladen';
        } else if (userData.users[i].is_invited_to_rcs === true) {
          if (userData.users[i].is_logged_in === false) {
            statuss = 'Eingeladen';
          } else {
            if (this.selectLang === 'German(DE)') {
              statuss = 'Aktiv';
            } else {
              statuss = 'Active';
            }
          }
        }

        if (userData.users[i].is_invited_to_rcs === false) {
          this.revertInvitation = true;
        } else if (
          userData.users[i].is_invited_to_rcs === true ||
          userData.users[i].is_logged_in === true
        ) {
          this.revertInvitation = false;
        }
        const tableData = this.setTableData(
          userData.users[i],
          statuss,
          userData.supported_languages
        );
        this.UserData.push(tableData);
      }
      this.UserDataParentObject = JSON.parse(JSON.stringify(this.UserData));
      this.displayUserData();
      this.setAvailableStatusValues();
      this.communicationService.setAssociatedCustomerValues(this.UserData);
    } else {
      this.empty = '';
    }
  }
  displayUserData() {
    this.dataSource.data = [...this.UserData];
  }
  formatUserDataForReset(userData) {
    this.UserData = [];
    let statuss;
    if (userData.users.length > 0) {
      for (let i = 0; i < userData.users.length; i++) {
        this.childcustomer = btoa(userData.users[i].customer_id);
        this.cstmr = btoa(userData.users[i].customer_id);
        if (userData.users[i].is_invited_to_rcs === false) {
          statuss = 'Nicht eingeladen';
        } else if (userData.users[i].is_invited_to_rcs === true) {
          if (userData.users[i].is_logged_in === false) {
            statuss = 'Eingeladen';
          } else {
            if (this.selectLang === 'German(DE)') {
              statuss = 'Aktiv';
            } else {
              statuss = 'Active';
            }
          }
        }

        if (userData.users[i].is_invited_to_rcs === false) {
          this.revertInvitation = true;
        } else if (
          userData.users[i].is_invited_to_rcs === true ||
          userData.users[i].is_logged_in === true
        ) {
          this.revertInvitation = false;
        }
        const tableData = this.setTableData(
          userData.users[i],
          statuss,
          userData.supported_languages
        );
        this.UserData.push(tableData);
      }
      this.UserDataParentObject = JSON.parse(JSON.stringify(this.UserData));
      this.setAvailableStatusValues();
      this.dataSource.data = this.UserData;
      this.communicationService.setAssociatedCustomerValues(this.UserData);
    } else {
      this.empty = '';
    }
  }
  deleteIvmUser() {
    this.showDeleteModalIvm = true;
  }
  deleteUser(customer_id, customer_role, customer_company_type) {
    this.showDeleteModal = true;
    this.userId = customer_id;
    this.userRole = customer_role;
    this.userCustomerTyp = customer_company_type;
  }
  deleteUserProcess(user) {
    if (this.isCustomerUser) {
      const deleteuserSendingdata: SendingDeleteResetUserData = {
        user_id: this.userId,
        user_role: this.userRole,
        user_customer_type: this.userCustomerTyp,
        actor_id: this.userIdnr,
        actor_role: this.user_role,
        actor_customer_type: this.customerType,
        actor_email: this.actorEmail,
      };

      this.serviceCall
        .deleteUser(deleteuserSendingdata)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data) {
            this.showDeleteModal = false;
            this._toastSerice.showToastMessage(document, data.message);
            this.loadUserforCustomer();
          }
        });
    }
  }
  resetInvite(customer_id, customer_role, customer_company_type) {
    this.showModal = true;
    this.userId = customer_id;
    this.userRole = customer_role;
    this.userCustomerTyp = customer_company_type;
  }
  hideModal() {
    this.showModal = false;
    this.showDeleteModal = false;
    this.showDeleteModalIvm = false;
  }
  invitationRevert(user) {
    const resetUserInvitationSendingdata: SendingDeleteResetUserData = {
      user_id: this.userId,
      user_role: this.userRole,
      user_customer_type: this.userCustomerTyp,
      actor_id: true === this.isICUser ? this.userid : this.userIdnr,
      actor_role: this.user_role,
      actor_customer_type: true === this.isICUser ? null : this.customerType,
      actor_email: this.actorEmail,
    };
    this.serviceCall
      .resetInvite(resetUserInvitationSendingdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data) {
          this.showModal = false;
          this._toastSerice.showToastMessage(document, data.message);
          if (ROLES.CUSTOMER_ROLES.includes(this.userRole)) {
            this.loadUserforCustomer();
          } else if (this.isICUser === true) {
            this.loadCustomerList();
          }
        }
      });
  }
  onSelectedDivisionAutomatically(divisionId) {
    this.serviceCall
      .getOrganization(divisionId, this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.organisations.length > 0) {
          this.organizationList = data.organisations;
          this.message = '';
        } else {
          this.message = 'Keine Kunden gefunden für diese Niederlassung.';
          this.organizationList = [];
        }
      });
  }
  onSelectDivision(divisionid) {
    this.serviceCall
      .getOrganization(divisionid.value, this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.organisations.length > 0) {
          this.organizationList = data.organisations;
          this.message = '';
        } else {
          this.message = 'Keine Kunden gefunden für diese Niederlassung.';
          this.organizationList = [];
        }
      });
  }
  onActionProcess(user) {
    const user_data = user;
    user_data.salutation = this.salutationService.getSalutation(
      user_data.salutation_id,
      this.selectLang
    );
    const updateUserData = this.createUpdateUserRequest(user_data);
    updateUserData.customer_name = '';
    updateUserData.mfa_mode = user_data.is_source_ivm === true ? 1 : null;
    updateUserData.is_group = false;

    this.dialog
      .open(UpdateUserComponent, {
        panelClass: 'updateUser',
        width: '800px',
        data: updateUserData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        mergeMap(s => {
          const formData = this.formatFormData(s);

          return iif(
            () => !formData,
            of(null),
            this.serviceCall.updateInviteUser(formData)
          );
        }),
        takeUntil(this._destroy$)
      )
      .subscribe(data => {
        if (!data) {
          return;
        }

        this.updatemessage = data.message;
        if (data.message === 'Successfully Updated') {
          this.updatemessage = this.translate.instant(
            'update-user.erfolgreichAktualisiert_info'
          );
        } else if (
          data.message === 'User Already Exist with Same Email or Username'
        ) {
          this.updatemessage = this.translate.instant(
            'update-user.Nutzer_info'
          );
        } else if (data.message === 'Invitation Email Not Sent') {
          this.updatemessage = this.translate.instant(
            'update-user.Einladungsemail_info'
          );
        }
        this._toastSerice.showToastMessage(document, this.updatemessage);
        if (ROLES.CUSTOMER_ROLES.includes(this.userRole)) {
          this.loadCustomerList();
          this.ngOnInit();
        } else if (this.isICUser === true) {
          this.loadCustomerList();
        }
      });
  }
  formatFormData(formData) {
    if (this.isCustomerUser) {
      if (!formData) {
        return;
      }
      formData.salutation_id = this.salutationService.getSalutationId(
        formData.salutation,
        formData.invitation_language
      );
      let salutation = this.salutationService.getSalutation(
        this.salutation_id,
        formData.invitation_language
      );
      salutation =
        salutation === null || salutation === undefined ? '' : salutation;
      this.inviter_user = (salutation + ' ' + this.customer_name).trim();
      this.updateUserOBJ.user_id = formData.user_id;
      this.updateUserOBJ.customer_id = formData.customer_id;
      this.updateUserOBJ.customer_name = formData.customer;
      this.updateUserOBJ.first_name = formData.first_name;
      this.updateUserOBJ.last_name = formData.last_name;
      this.updateUserOBJ.telefon = formData.Telefon;
      this.updateUserOBJ.customer_name =
        formData.first_name + ' ' + formData.last_name;
      this.updateUserOBJ.email = formData.email;
      this.updateUserOBJ.house = formData.house;
      this.updateUserOBJ.street = formData.street;
      this.updateUserOBJ.zip_code = formData.zip_code;
      this.updateUserOBJ.city = formData.city;
      this.updateUserOBJ.country = formData.country;
      this.updateUserOBJ.type = formData.type;
      this.updateUserOBJ.role = formData.role;
      this.updateUserOBJ.salutation_id = formData.salutation_id;
      this.updateUserOBJ.address = formData.address;
      this.updateUserOBJ.invitation_language =
        formData.is_invited_to_rcs === false
          ? formData.invitation_language
          : null;
      this.updateUserOBJ.is_invite_to_be_sent = formData.is_invite_to_be_sent;
      this.updateUserOBJ.is_temporary = true;
      this.updateUserOBJ.is_invited_to_rcs = formData.is_invited_to_rcs;
      this.updateUserOBJ.is_vip = formData.is_vip === true ? true : false;
      this.updateUserOBJ.division = formData.division;
      this.updateUserOBJ.is_source_ivm = formData.is_source_ivm;
      this.updateUserOBJ.lat = formData.lat;
      this.updateUserOBJ.lon = formData.lon;
      this.updateUserOBJ.inviter_username = this.inviter_user;
      this.updateUserOBJ.inviter_email = this.inviter_email;
      this.updateUserOBJ.actor_id = formData.actor_id;
      this.updateUserOBJ.actor_role = formData.actor_role;
      this.updateUserOBJ.actor_customer_type = this.customerType;
      this.updateUserOBJ.mfa_mode = formData.otpTo === 'Mobile' ? 1 : 2;
      this.updateUserOBJ.actor_id = this.userIdnr;
      this.updateUserOBJ.actor_role = this.user_role;
      if (formData.lat !== '') {
        this.updateUserOBJ.lat = formData.lat;
      } else {
        this.updateUserOBJ.lat = null;
      }
      if (formData.lon !== '') {
        this.updateUserOBJ.lon = formData.lon;
      } else {
        this.updateUserOBJ.lon = null;
      }
      return this.updateUserOBJ;
    }
  }
  openStatusSelection(event) {
    this.openStatusFilter = !this.openStatusFilter;
  }
  setAvailableStatusValues() {
    if (this.UserDataParentObject !== undefined) {
      const unique = [
        ...new Set(this.UserDataParentObject.map(item => item.status)),
      ];
      this.statusAvailable = [];
      for (const value of unique) {
        const newObject = {
          value: true,
          label: value,
        };
        this.statusAvailable.push(newObject);
      }
    }
  }
  checkFieldClicked() {
    let statusVisible = this.statusAvailable.map(obj => {
      if (obj.value) {
        return obj.label;
      }
    });
    statusVisible = statusVisible.filter((e: any) => e);
    this.UserData = this.UserDataParentObject.filter(
      obj => statusVisible.indexOf(obj.status) != -1
    );
    this.dataSource.data = [...this.UserData];
  }
  getDivisionCustomer() {
    this.serviceCall
      .getDivision(this._userService.getUserId())
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.division = data.divisions[0].division_name;
      });
  }
  inviteUserClick() {
    this.getDivisionCustomer();
    const newUserData: InviteUserDialogData = {
      customer_id: '',
      user_id: '',
      user_name: '',
      Telefon: '',
      first_name: '',
      last_name: '',
      email: '',
      customer: '',
      salutation_id: null,
      status: '',
      supported_languages: '',
      role: USER_ROLES.CUSTOMER_USER,
      division: this.division,
      childcustomer: '',
      otp: '',
      house: '',
      invitation_language: 'German(DE)',
      street: '',
      zip_code: '',
      city: '',
      country: '',
      viaemail: '',
      vialetter: '',
      is_invite_to_be_sent: false,
      is_invited_to_rcs: false,
      is_vip: false,
      newUser: true,
      type: '',
      otpTo: 'Email',
      title: 'Neuen_Benutzer_hinzufügen',
      buttonCancelName: 'Abbrechen_text',
      buttonConfirmName: 'Senden',
      customer_address_lat_lon_set: false,
      lat: '',
      lon: '',
      address: '',
      customer_name: '',
      is_source_ivm: false,
      is_group: false,
      liztu_vn_kdnr: null,
      user_idnr: null,
      is_invite_reset: false,
      mfa_mode: null,
      role_id: null,
    };
    this.dialog.open(UpdateUserComponent, {
      panelClass: 'updateUser',
      width: '800px',
      data: newUserData,
      disableClose: true,
    });
  }
  editUserClick(user) {
    const user_data = user;
    const updateUserData = this.createUpdateUserRequest(user_data);
    this.updateUser = updateUserData;
    this.router.navigate(['customer-edit'], {
      state: this.updateUser,
      queryParams: {
        liztu: this.decoded_liztu,
      },
    });
  }
  onNoClick() {
    this._sparteShareService.sparte = null;
    this._userService.mutterCustomer = null;
    this._userService.userFormData = null;
    this._userService.widgets = null;
    this._userService.customerLiztuVnKdnr = null;
    this._userService.showActiveFlag = null;
    this._userService.selectedCustomers = null;
  }
  async showMessage(data) {
    this._shareDataService.saveReloadValue(true);
    if (data.hasError === true) {
      this._toastSerice.showToastMessage(
        document,
        this.translate.instant('user-data.Update_Error_Message')
      );
    } else {
      this._toastSerice.showToastMessage(
        document,
        this.translate.instant('user-data.Update_Success_Message')
      );
    }
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(1500);
    this.loadPage();
  }
  loadPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl('users');
    });
    this.loadUserforCustomerInvite();
  }
  onEditClick(user) {
    const user_data = user;
    if (
      user_data.is_source_ivm === false &&
      user_data.is_invited_to_rcs === true &&
      user_data.is_invite_reset === false
    ) {
      this.editUserClick(user);
    } else if (
      user_data.is_source_ivm === true &&
      user_data.is_invited_to_rcs === false &&
      user_data.is_invite_reset === false
    ) {
      this.onActionProcess(user);
    } else if (
      user_data.is_source_ivm === true &&
      user_data.is_invited_to_rcs === true &&
      user_data.is_invite_reset === false
    ) {
      this.onActionProcess(user);
    } else if (
      user_data.is_source_ivm === true &&
      user_data.is_invited_to_rcs === false &&
      user_data.is_invite_reset === true
    ) {
      this.onActionProcess(user);
    } else if (
      user_data.is_source_ivm === false &&
      user_data.is_invited_to_rcs === false &&
      user_data.is_invite_reset === false
    ) {
      this.editUserClick(user);
    } else if (
      user_data.is_source_ivm === false &&
      (user_data.is_invited_to_rcs === false ||
        user_data.is_invited_to_rcs === true) &&
      user_data.is_invite_reset === true
    ) {
      this.reinviteUser(user);
    } else if (
      user_data.is_source_ivm === false &&
      user_data.is_invited_to_rcs === true &&
      user_data.is_invite_reset === true
    ) {
      this.editUserClick(user);
    }
  }
  reinviteUser(user) {
    const user_data = user;
    user_data.salutation = this.salutationService.getSalutation(
      user_data.salutation_id,
      this.selectLang
    );
    const updateUserData = this.createUpdateUserRequest(user_data);
    updateUserData.country = user_data.country;
    updateUserData.otpTo = user_data.telefon != null ? 'Mobile' : 'Email';
    (updateUserData.address = ''),
      (updateUserData.customer_name = ''),
      (updateUserData.newUser = true);
    updateUserData.type = user_data.type;
    this.dialog.open(UpdateUserComponent, {
      panelClass: 'updateUser',
      width: '800px',
      data: updateUserData,
      disableClose: true,
    });
  }
  CountryChangedEvent(input) {
    this.placeholder = input.placeHolder;
  }
  editCustomerInformation(masterData) {
    masterData.is_customer_info_edit = !masterData.is_customer_info_edit;
    this.disable = masterData.is_customer_info_edit === true ? false : true;
  }
  masterDataEdit(masterData) {
    masterData.masterDataEdit = !masterData.masterDataEdit;
    masterData.isExpanded = true;
    this.loadCustomerInformation(masterData);
  }
  // Customer section toggle
  customerInformationToggle(masterData) {
    masterData.isExpanded = masterData.isExpanded === 'true' ? 'false' : 'true';
  }
  // Location autofill
  selectedLocation(index?, masterData?) {
    // code added by umesh
    this.selectedaddress = [this.filterlocation[index]];
    this.selectedLoc = this.filterlocation[index].freeformAddress;
    // code ends here

    if (this.selectedaddress.length === 1) {
      if (this.selectedaddress[0].streetNumber !== undefined) {
        masterData.customerInformation.house =
          this.selectedaddress[0].streetNumber;
        masterData.customerInformation.houseValidate = false;
      } else {
        masterData.customerInformation.house = '';
      }
      if (this.selectedaddress[0].streetName !== undefined) {
        masterData.customerInformation.street =
          this.selectedaddress[0].streetName;
        masterData.customerInformation.streetValidate = false;
      } else {
        masterData.customerInformation.street = '';
      }
      if (this.selectedaddress[0].postalCode !== undefined) {
        masterData.customerInformation.zip_code =
          this.selectedaddress[0].postalCode;
        masterData.customerInformation.zipValidate = false;
      } else {
        masterData.customerInformation.zip_code = null;
      }
      if (this.selectedaddress[0].localName !== undefined) {
        masterData.customerInformation.city = this.selectedaddress[0].localName;
        masterData.customerInformation.cityValidate = false;
      } else {
        masterData.customerInformation.city = '';
      }
      if (this.selectedaddress[0].country !== undefined) {
        masterData.customerInformation.country =
          this.selectedaddress[0].country;
      } else {
        masterData.customerInformation.country = '';
      }
      for (let i = 0; i < this.Alllocations.length; i++) {
        if (this.Alllocations[i].position.lat !== undefined) {
          masterData.customerInformation.lat =
            this.Alllocations[i].position.lat;
        } else {
          masterData.customerInformation.lat = null;
        }
        if (this.Alllocations[i].position.lon !== undefined) {
          masterData.customerInformation.lon =
            this.Alllocations[i].position.lon;
        } else {
          masterData.customerInformation.lon = null;
        }
      }
      masterData.customerInformation.address =
        this.selectedaddress[0].freeformAddress;
      if (
        masterData.customerInformation.lat !== null &&
        masterData.customerInformation.lon !== null
      ) {
        masterData.customerInformation.customer_address_lat_lon_set = true;
      } else {
        masterData.customer_address_lat_lon_set = false;
      }
    }
  }
  autoComplete(value) {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          res => {
            this.Alllocations = res.results;
            this.filterAddressFromResults(this.Alllocations);
            // code added by umesh
            this.showList = true;
            // code ends here
          },
          err => {}
        );
    }
  }
  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
  }
  onFocusOut(event) {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }
  // City Validation
  charactersOnlycity(e, masterData) {
    const isSalutation =
      this.errorMessageArray.indexOf('Stadt_validation') != -1 ? true : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.TOWN_VALIDATION);
    serchfind = regexp.test(e);
    this.Stadt_validation = 'Stadt_validation';
    if (e.length === 0 || serchfind === false) {
      masterData.customerInformation.cityValidate = true;
    } else {
      masterData.customerInformation.cityValidate = false;
    }
    if (serchfind === false && isSalutation === false) {
      masterData.customerInformation.cityValidate = false;
      this.errorMessageArray.push(this.Stadt_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Stadt_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  // Country Validation
  charactersOnlycountry(e) {
    const isSalutation =
      this.errorMessageArray.indexOf('Land_validation') != -1 ? true : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.COUNTRY_VALIDATION);
    serchfind = regexp.test(e);
    const Land_validation = 'Land_validation';
    if (serchfind === false && isSalutation === false) {
      this.errorMessageArray.push(Land_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(Land_validation);
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  // House Number Validation
  charactersNumbersOnlyhouse(e, masterData) {
    const isSalutation =
      this.errorMessageArray.indexOf('Hausnummer_validation') != -1
        ? true
        : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.NON_EMPTY_STRING);
    serchfind = regexp.test(e);
    this.Hausnummer_validation = 'Hausnummer_validation';
    if (e.length === 0 || serchfind === false) {
      masterData.customerInformation.houseValidate = true;
    } else {
      masterData.customerInformation.houseValidate = false;
    }
    if (serchfind === false && isSalutation === false) {
      masterData.customerInformation.houseValidate = false;
      this.errorMessageArray.push(this.Hausnummer_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Hausnummer_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  // Street Validation
  charactersNumbersOnlystreet(e, masterData) {
    const isSalutation =
      this.errorMessageArray.indexOf('Straße_validation') != -1 ? true : false;
    let serchfind: boolean;
    const regexp = new RegExp(REGEX.NON_EMPTY_STRING);
    serchfind = regexp.test(e);
    this.Straße_validation = 'Straße_validation';
    this.strabe = this.Straße_validation;
    if (e.length === 0 || serchfind === false) {
      masterData.customerInformation.streetValidate = true;
    } else {
      masterData.customerInformation.streetValidate = false;
    }
    if (serchfind === false && isSalutation === false) {
      masterData.customerInformation.streetValidate = true;
      this.errorMessageArray.push(this.Straße_validation);
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Straße_validation
      );

      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  onCountrySelected(event, masterData) {
    const selected = event;
    for (let j = 0; j < this._countries.length; j++) {
      if (selected === this._countries[j].countryName) {
        this.postalregexppattern = this._countries[j].postalCodeRegex;
        const zip_val = masterData.customerInformation.zip_code;
        this.numbersOnlyzip(zip_val, masterData);
      }
    }
  }
  // Zip code Validation
  numbersOnlyzip(e, masterData) {
    const isSalutation =
      this.errorMessageArray.indexOf('Postleitzahl_validation') != -1
        ? true
        : false;
    let serchfind: boolean;
    for (let j = 0; j < this._countries.length; j++) {
      if (
        masterData.customerInformation.country ===
        this._countries[j].countryName
      ) {
        this.postalregexppattern = this._countries[j].postalCodeRegex;
      }
    }
    const regexp = new RegExp(this.postalregexppattern);
    serchfind = regexp.test(e);
    this.Postleitzahl_validation = 'Postleitzahl_validation';
    if (e.length === 0 || serchfind === false) {
      masterData.customerInformation.zipValidate = true;
      this.errorMessageArray.push(this.Postleitzahl_validation);
    } else {
      masterData.customerInformation.zipValidate = false;
    }
    if (serchfind === false && isSalutation === false) {
      masterData.customerInformation.zipValidate = true;
    } else {
      const index: number = this.errorMessageArray.indexOf(
        this.Postleitzahl_validation
      );
      if (index !== -1) {
        this.errorMessageArray.splice(index, 1);
      }
    }
  }
  findCountries(country) {
    return country.countryName === 'Germany';
  }
  onaddressChange() {
    this.addressChanged = true;
    this.customerInformation.lat = null;
    this.customerInformation.lon = null;
    this.customerInformation.customer_address_lat_lon_set = null;
  }
  uploadDoc(masterData) {
    this.masterDataObj = masterData;
    this.openDocUpload = true;
  }
  hide() {
    this.openDocUpload = false;
  }
  contactPersonToggle() {
    this.contactPersonExpanded = !this.contactPersonExpanded;
  }
  // To load the companies to show in masterdata table
  loadMasterDataCustomers() {
    if (this.subsidiaries.length > 0) {
      for (let i = 0; i < this.subsidiaries.length; i++) {
        const customerInfo: CustomerInformation = {
          customerNumber: '',
          dunsNumber: '',
          customerName: '',
          customerId: null,
          activationFlag: '',
          customerGroup: '',
          roleOfCustomer: '',
          classification: '',
          language: '',
          email: '',
          homePage: '',
          house: '',
          street: '',
          zip_code: null,
          city: '',
          country: '',
          address: '',
          lat: null,
          lon: null,
          telephone_number: null,
          customer_address_lat_lon_set: false,
          cityValidate: false,
          houseValidate: false,
          streetValidate: false,
          zipValidate: false,
        };
        const additionalDetails: AdditionalDetails = {
          broker_mandate_date: '',
          cmp_desc: '',
          cmp_desc_short: '',
          crn: '',
          css_email: '',
          broker_mandate: '',
          last_annual_meeting: '',
          legal_form: '',
          turnover: null,
          vat_number: null,
          turnover_validate: false,
          short_description_validate: false,
          long_description_validate: false,
          branch_name: '',
          est_of_cmp: '',
          fiscal_year: '',
          is_seasonal_op: null,
          is_fiscal_year_error: false,
          is_est_of_cmp_error: false,
          dummy_fiscal: null,
          actual_fiscal: null,
        };
        const customer: MasterDataRow = {
          name: this.subsidiaries[i].customer_name,
          type: this.subsidiaries[i].type,
          liztu_vn_kdnr: this.subsidiaries[i].liztu_vn_kdnr,
          isExpanded: false,
          additionalDetailsExpanded: false,
          accountDetailsExpanded: false,
          artusContactPersonExpanded: false,
          masterDataEdit: false,
          customerInformation: customerInfo,
          accountDetails: [],
          additionalDetails,
          selectedDocuments: '',
        };
        this.masterDataRows.push(customer);
      }
    }
  }
  loadCustomerInformation(masterData) {
    const masterdata = this.masterdata;
    let index = -1;
    if (masterdata != null || masterdata != undefined) {
      index = masterdata.findIndex(
        obj => obj.liztu_vn_kdnr === masterData.liztu_vn_kdnr
      );
    }
    if (index === -1) {
      this.serviceCall
        .getMasterData(masterData.liztu_vn_kdnr)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          // Customer information set
          masterData.customerInformation.customerNumber =
            data.customer_info.customer_number;
          masterData.customerInformation.customerName =
            data.customer_info.customer_name;
          if (this.selectLang === 'German(DE)') {
            masterData.customerInformation.activationFlagGerman =
              this.getActivationFlagGerman(data.customer_info.activation_flag);
          } else {
            masterData.customerInformation.activationFlag =
              this.getActivationFlag(data.customer_info.activation_flag);
          }
          masterData.customerInformation.roleOfCustomer =
            data.customer_info.type;
          if (this.selectLang === 'German(DE)') {
            masterData.customerInformation.classificationGerman =
              this.getClassificationGerman(data.customer_info.classification);
          } else {
            masterData.customerInformation.classification =
              this.getClassification(data.customer_info.classification);
          }
          masterData.customerInformation.email = data.customer_info.email;
          masterData.customerInformation.address = data.customer_info.address;
          masterData.customerInformation.house = data.customer_info.house;
          masterData.customerInformation.street = data.customer_info.street;
          masterData.customerInformation.zip_code = data.customer_info.zip_code;
          masterData.customerInformation.city = data.customer_info.city;
          masterData.customerInformation.country = this.countrySet(
            data.customer_info.country
          );
          masterData.customerInformation.lat = data.customer_info.lat;
          masterData.customerInformation.lon = data.customer_info.lon;
          masterData.customerInformation.customer_address_lat_lon_set =
            data.customer_info.customer_address_lat_lon_set === null
              ? false
              : data.customer_info.customer_address_lat_lon_set;
          masterData.customerInformation.telephone_number =
            data.customer_info.telephohne;
          masterData.customerInformation.language = this.languages.find(
            obj => obj.flag === data.customer_info.language_id
          ).name;
          masterData.customerInformation.homePage = data.customer_info.homepage;
          if (data.additional_details) {
            masterData.customerInformation.dunsNumber =
              data.additional_details.duns_number;
            masterData.customerInformation.customerGroup =
              data.additional_details.customer_group;
            // Additional Details Set
            masterData.additionalDetails.broker_mandate_date =
              data.additional_details.broker_mandate_date;
            masterData.additionalDetails.cmp_desc =
              data.additional_details.cmp_desc;
            masterData.additionalDetails.cmp_desc_short =
              data.additional_details.cmp_desc_short;
            masterData.additionalDetails.crn = data.additional_details.crn;
            masterData.additionalDetails.css_email =
              data.additional_details.css_email;
            (masterData.additionalDetails.broker_mandate =
              data.additional_details.broker_mandate?.toLocaleLowerCase() ==
                'full broker' ||
              data.additional_details.broker_mandate?.toLocaleLowerCase() ==
                'half broker'
                ? data.additional_details.broker_mandate?.toLocaleLowerCase()
                : 'none'),
              (masterData.additionalDetails.last_annual_meeting =
                data.additional_details.last_annual_meeting);
            masterData.additionalDetails.legal_form =
              data.additional_details.legal_form;
            masterData.additionalDetails.turnover =
              data.additional_details.turnover;
            masterData.additionalDetails.turnover =
              this._currencyCustomPipe.transform(
                masterData.additionalDetails.turnover
              );
            masterData.additionalDetails.vat_number =
              data.additional_details.vat_number;
            masterData.additionalDetails.documents =
              data.additional_details.documents;
            masterData.additionalDetails.branch_name =
              data.additional_details.branch_name;
            masterData.additionalDetails.est_of_cmp =
              data.additional_details.est_of_cmp !== null
                ? moment(data.additional_details.est_of_cmp)?.format(
                    'YYYY-MM-DD'
                  )
                : null;
            masterData.additionalDetails.is_seasonal_op =
              data.additional_details.is_seasonal_op;
            masterData.additionalDetails.dummy_fiscal =
              data.additional_details.fiscal_year != null
                ? moment(data.additional_details.fiscal_year)?.format('DD.MM.')
                : null;
            masterData.additionalDetails.actual_fiscal =
              data.additional_details.fiscal_year != null
                ? moment(data.additional_details.fiscal_year)?.format(
                    'YYYY-MM-DD'
                  )
                : null;
            masterData.selectedDocuments = '';
            for (
              let i = 0;
              i < masterData.additionalDetails.documents.length;
              i++
            ) {
              if (i === masterData.additionalDetails.documents.length - 1) {
                masterData.selectedDocuments =
                  masterData.selectedDocuments +
                  ' ' +
                  '"' +
                  data.additional_details.documents[i].name +
                  '"';
              } else {
                masterData.selectedDocuments =
                  masterData.selectedDocuments +
                  ' ' +
                  '"' +
                  data.additional_details.documents[i].name +
                  '" ,';
              }
            }
          }
          // Account Details Set
          for (let i = 0; i < data.account_details?.length; i++) {
            const accountDetails: AccountDetails = {
              account_number: data.account_details[i].account_number,
              bank_name: data.account_details[i].bank_name,
              bank_type: data.account_details[i].bank_type,
              bic: data.account_details[i].bic,
              iban: data.account_details[i].iban,
              owner: data.account_details[i].owner,
              valid_from: data.account_details[i].valid_from,
            };
            masterData.accountDetails.push(accountDetails);
          }

          if (this.masterDataRowsData.length > 0) {
            const i = this.masterDataRowsData.findIndex(
              obj => obj.liztu_vn_kdnr === masterData.liztu_vn_kdnr
            );
            if (i === -1) {
              this.masterDataRowsData.push(masterData);
            } else {
              this.masterDataRowsData[i] = masterData;
            }
          } else {
            this.masterDataRowsData.push(masterData);
          }
          this.masterdata = this.masterDataRowsData;
        });
    } else if (index >= 0) {
      masterData = this.masterDataRowsData[index];
    }
  }
  // To resolve classification in Customer details section
  getClassification(classificationSymbol) {
    const index = this.classification.findIndex(
      obj => obj.symbol === classificationSymbol
    );
    if (index != -1) {
      return this.classification[index].name;
    } else {
      return '';
    }
  }
  getClassificationGerman(classificationSymbol) {
    const index = this.classificationGerman.findIndex(
      obj => obj.symbol === classificationSymbol
    );
    if (index != -1) {
      return this.classificationGerman[index].name;
    } else {
      return '';
    }
  }
  // To resolve classification in Activation flag section
  getActivationFlag(activationFlag) {
    const index = this.activationFlag.findIndex(
      obj => obj.symbol === activationFlag
    );
    if (index != -1) {
      return this.activationFlag[index].name;
    } else {
      return '';
    }
  }
  getActivationFlagSymbol(activationFlag) {
    const index = this.activationFlag.findIndex(
      obj => obj.name === activationFlag
    );
    if (index != -1) {
      return this.classification[index].symbol;
    } else {
      return '';
    }
  }
  getClassificationSymbol(classificationSymbol) {
    const index = this.classification.findIndex(
      obj => obj.name === classificationSymbol
    );
    if (index != -1) {
      return this.classification[index].symbol;
    } else {
      return '';
    }
  }
  getLanguageId(language) {
    const index = this.languages.findIndex(obj => obj.name === language);
    if (index != -1) {
      return Number(this.languages[index].flag);
    } else {
      return null;
    }
  }
  saveMasterDate(masterData) {
    masterData.additionalDetails.is_fiscal_year_error = false;
    masterData.additionalDetails.is_est_of_cmp_error = false;
    if (
      masterData.customerInformation.city === '' ||
      masterData.customerInformation.city === undefined ||
      masterData.customerInformation.house === '' ||
      masterData.customerInformation.house === undefined ||
      masterData.customerInformation.street === '' ||
      masterData.customerInformation.street === undefined ||
      masterData.customerInformation.zip_code === '' ||
      masterData.customerInformation.zip_code === undefined ||
      masterData.additionalDetails.turnover_validate === true ||
      masterData.additionalDetails.short_description_validate === true ||
      masterData.additionalDetails.long_description_validate === true ||
      masterData.customerInformation.zipValidate === true
    ) {
      if (
        masterData.customerInformation.city === '' ||
        masterData.customerInformation.city === undefined
      ) {
        masterData.customerInformation.cityValidate = true;
      } else if (
        masterData.customerInformation.house === '' ||
        masterData.customerInformation.house === undefined
      ) {
        masterData.customerInformation.houseValidate = true;
      } else if (
        masterData.customerInformation.street === '' ||
        masterData.customerInformation.street === undefined
      ) {
        masterData.customerInformation.streetValidate = true;
      } else if (
        masterData.customerInformation.zip_code === '' ||
        masterData.customerInformation.zip_code === undefined
      ) {
        masterData.customerInformation.zipValidate = true;
      }
    } else {
      let last_annual_meeting;
      let broker_mandate_date;
      if (
        masterData.additionalDetails.last_annual_meeting != null ||
        masterData.additionalDetails.last_annual_meeting != ''
      ) {
        const momentDate = new Date(
          masterData.additionalDetails.last_annual_meeting
        );
        last_annual_meeting = moment(momentDate).format('YYYY-MM-DD');
      } else {
        last_annual_meeting = null;
      }
      if (
        masterData.additionalDetails.broker_mandate_date != null ||
        masterData.additionalDetails.broker_mandate_date != ''
      ) {
        const momentBrokerMandateDate = new Date(
          masterData.additionalDetails.broker_mandate_date
        );
        broker_mandate_date = moment(momentBrokerMandateDate).format(
          'YYYY-MM-DD'
        );
      } else {
        broker_mandate_date = null;
      }
      const customer_info: CustomerInformationUpdate = {
        activation_flag: this.getActivationFlagSymbol(
          masterData.customerInformation.activationFlag
        ),
        address: masterData.customerInformation.address,
        city: masterData.customerInformation.city,
        classification: this.getClassificationSymbol(
          masterData.customerInformation.classification
        ),
        customer_address_lat_lon_set:
          masterData.customerInformation.customer_address_lat_lon_set,
        customer_id: masterData.customerInformation.customerId,
        country: masterData.customerInformation.country,
        house: masterData.customerInformation.house,
        lat: masterData.customerInformation.lat,
        liztu_vn_kdnr: masterData.liztu_vn_kdnr,
        lon: masterData.customerInformation.lon,
        name: masterData.customerInformation.customerName,
        street: masterData.customerInformation.street,
        telephohne: masterData.customerInformation.telephone_number,
        type: masterData.customerInformation.roleOfCustomer,
        zip_code: masterData.customerInformation.zip_code,
        email: masterData.customerInformation.email,
        language_id: this.getLanguageId(
          masterData.customerInformation.language
        ),
      };
      const additional_details: AdditionalDetailsUpdate = {
        cmp_desc: masterData.additionalDetails.cmp_desc,
        cmp_desc_short: masterData.additionalDetails.cmp_desc_short,
        crn: masterData.additionalDetails.crn,
        documents: null,
        duns_number: masterData.customerInformation.dunsNumber,
        broker_mandate:
          masterData.additionalDetails.broker_mandate.toLocaleLowerCase() ==
            'full broker' ||
          masterData.additionalDetails.broker_mandate.toLocaleLowerCase() ==
            'half broker'
            ? masterData.additionalDetails.broker_mandate.toLocaleLowerCase()
            : 'none',
        last_annual_meeting:
          last_annual_meeting === '1970-01-01' ? null : last_annual_meeting,
        liztu_vn_kdnr: masterData.liztu_vn_kdnr,
        turnover:
          masterData.additionalDetails.turnover === null
            ? null
            : Number(this.removeSymbols(masterData.additionalDetails.turnover)),
        vat_number: masterData.additionalDetails.vat_number,
        broker_mandate_date:
          broker_mandate_date === '1970-01-01' ? null : broker_mandate_date,
        legal_form: masterData.additionalDetails.legal_form,
        css_email: masterData.additionalDetails.css_email,
        branch_name: masterData.additionalDetails.branch_name,
        est_of_cmp:
          masterData.additionalDetails.est_of_cmp != null
            ? moment(masterData.additionalDetails.est_of_cmp).format(
                'yyyy-MM-DD'
              )
            : null,
        fiscal_year:
          masterData.additionalDetails.actual_fiscal != null
            ? moment(masterData.additionalDetails.actual_fiscal).format(
                'yyyy-MM-DD'
              )
            : null,
        is_seasonal_op: masterData.additionalDetails.is_seasonal_op,
      };
      const account_details: AccountDetailsUpdate[] = [];
      for (let i = 0; i < masterData.accountDetails.length; i++) {
        const modifiedDate = new Date(masterData.accountDetails[i].valid_from);
        const validForm = moment(modifiedDate).format('YYYY-MM-DD');
        const account_detail: AccountDetailsUpdate = {
          account_number: masterData.accountDetails[i].account_number,
          bank_type: masterData.accountDetails[i].bank_type,
          valid_from: validForm,
        };
        account_details.push(account_detail);
      }
      const contact_person = null;

      const masterDataUpdate: MasterDataUpdate = {
        user_id: masterData.liztu_vn_kdnr,
        role: this.user_role,
        customer_info,
        additional_details,
        account_details,
        contact_persons: contact_person,
      };
      this.serviceCall
        .updateMasterData(masterDataUpdate)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.HasErrors) {
            this._toastSerice.showToastMessage(document, data.message);
          } else {
            this.updatemessage =
              this.selectLang === LOCALS.ENGLISH
                ? 'Masterdata is successfully updated.'
                : 'Masterdaten wurden erfolgreich aktualisiert.';
            this._toastSerice.showToastMessage(document, this.updatemessage);
            masterData.masterDataEdit = !masterData.masterDataEdit;
          }
        });
    }
  }
  onClick(event) {
    event.target.value = '';
  }
  // function is for selecting the file and validating it
  onSelectFile(event, masterData) {
    const selectedFile = event.target.files[0];
    if (selectedFile.size <= 35840000) {
      masterData.additionalDetails.documents.push(selectedFile);
      masterData.selectedDocuments =
        masterData.selectedDocuments + ', ' + '"' + selectedFile.name + '"';
    } else {
      alert(
        this.selectLang === LOCALS.ENGLISH
          ? 'The file cannot be larger than 35 MB.'
          : 'Datei darf nicht größer als 35 MB sein.'
      );
    }
  }
  // function is for uploading the selected files
  documentUpload(masterData) {
    const index = this.subsidiaries.findIndex(
      obj => masterData.liztu_vn_kdnr === obj.liztu_vn_kdnr
    );
    let customer_id = null;
    if (index != -1) {
      customer_id = this.subsidiaries[index].customer_id;
    }
    this._documentsApiService
      .uploadDocument(
        masterData.additionalDetails.documents,
        null,
        'mandate',
        null,
        null,
        null,
        customer_id,
        masterData.liztu_vn_kdnr,
        null,
        null,
        null,
        DocumentSource.MASTER_DATA,
        null
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.openDocUpload = false;
        }
        this._toastSerice.showToastMessage(document, data.message);
        this.loadDocumentData(masterData);
      });
  }
  pageRendered(e) {
    if (typeof e !== 'object') {
      const elpage = document.getElementById('page' + e);
      const elthumbnail = document.getElementById('thumbnail' + e);
      this.pageNumber = e;
      elpage.scrollIntoView();
      elthumbnail.scrollIntoView();
    } else {
      this.loading = false;
      if (e.pageNumber === 1) {
        this.thumbnail = [];
        this.thumbnail.push({
          page: e.pageNumber,
          url: e.source.canvas.toDataURL(),
        });
      } else {
        this.thumbnail.push({
          page: e.pageNumber,
          url: e.source.canvas.toDataURL(),
        });
      }
      const length = document.getElementsByClassName('page');
      for (let i = 0; i <= length.length; i++) {
        const id = i + 1;
        length[i].setAttribute('id', 'page' + id);
      }
    }
  }
  getActivationFlagGerman(activationFlagGerman) {
    const index = this.activationFlagGerman.findIndex(
      obj => obj.symbol === activationFlagGerman
    );
    if (index != -1) {
      return this.activationFlagGerman[index].name;
    } else {
      return '';
    }
  }
  callBackFn(pdf) {
    this.element = document.getElementsByClassName('page');
    this.pdfPageNum = pdf._pdfInfo.numPages;
    for (let i = 1; i <= this.pdfPageNum; i++) {
      this.pdfPageArray.push(i);
    }
  }
  nextPage(pageNum) {
    this.pageVariable = 0;
    this.pageVariable = this.pageVariable + pageNum;
  }
  backPage() {
    this.pageNumber = this.pageNumber - 1;
    const elpage = document.getElementById('page' + this.pageNumber);
    const elthumbnail = document.getElementById('thumbnail' + this.pageNumber);
    elpage.scrollIntoView();
    elthumbnail.scrollIntoView();
  }
  pdfViewer(item) {
    let fileType = item.split('.');
    fileType = fileType[fileType.length - 1];

    if (fileType === 'pdf') {
      if (item !== this.pdfLink) {
        this.pdfPageArray = [];
      }
      this.pdfLink = item;
      this.showPDF = true;
    } else if (fileType === 'msg') {
      window.open(item, '_blank').focus();
    }
  }
  closePDF(item) {
    this.zoom = 1.0;
    this.pageNumber = 1;
    const elpage = document.getElementById('pages');
    const elthumbnail = document.getElementById('pdfView');
    elpage.scroll(0, 0);
    elthumbnail.scroll(0, 0);
    window.scroll(0, 0);
    this.showPDF = false;
  }
  // function deletes the document
  deleteDocument(row, masterData) {
    // this condition deletes the uploaded and saved document
    if (row.id !== undefined && row.link !== undefined) {
      this._documentsApiService
        .deleteDocument(row.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.HasErrors === false) {
            const index = masterData.additionalDetails.documents.findIndex(
              obj => obj.id === row.id
            );
            if (index != -1) {
              masterData.additionalDetails.documents.splice(index, 1);
              // code for seting the label for showing all the selected files
              masterData.selectedDocuments = '';
              for (
                let i = 0;
                i < masterData.additionalDetails.documents.length;
                i++
              ) {
                if (i === masterData.additionalDetails.documents.length - 1) {
                  masterData.selectedDocuments =
                    masterData.selectedDocuments +
                    ' ' +
                    '"' +
                    masterData.additionalDetails.documents[i].name +
                    '"';
                } else {
                  masterData.selectedDocuments =
                    masterData.selectedDocuments +
                    ' ' +
                    '"' +
                    masterData.additionalDetails.documents[i].name +
                    '" ,';
                }
              }
            }
            this.updatemessage =
              this.selectLang === LOCALS.ENGLISH
                ? 'Document deleted successfully.'
                : 'Dokument erfolgreich gelöscht.';
            this._toastSerice.showToastMessage(document, this.updatemessage);
          } else {
            this._toastSerice.showToastMessage(document, data.message);
          }
        });
    }
    // this condition deletes the unsaved document
    else {
      const index = masterData.additionalDetails.documents.findIndex(
        obj => obj.id === undefined && obj.name === row.name
      );
      masterData.additionalDetails.documents.splice(index, 1);
    }
  }
  // function load the documents
  loadDocumentData(masterData) {
    const index = this.subsidiaries.findIndex(
      obj => masterData.liztu_vn_kdnr === obj.liztu_vn_kdnr
    );
    let customer_id = null;
    if (index != -1) {
      customer_id = this.subsidiaries[index].customer_id;
    }
    this._documentsApiService
      .getMasterDataDocuments(
        masterData.liztu_vn_kdnr,
        null,
        null,
        customer_id,
        null,
        null,
        null,
        null
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.formatDocumentData(data, masterData);
        }
      });
  }
  formatDocumentData(document, masterData) {
    const documentData = [];
    if (document.documents.length > 0) {
      for (let i = 0; i < document.documents.length; i++) {
        const Data: documentElement = {
          link: document.documents[i].document_link,
          name: document.documents[i].document_name,
          type: document.documents[i].document_type,
          desc: document.documents[i].document_description,
          id: document.documents[i].upload_document_id,
          date: document.documents[i].date,
          upload_via: document.documents[i].upload_via,
        };
        documentData.push(Data);
      }
    }
    masterData.additionalDetails.documents = [];
    masterData.additionalDetails.documents = documentData;
    masterData.selectedDocuments = '';
    for (let i = 0; i < masterData.additionalDetails.documents.length; i++) {
      if (i === masterData.additionalDetails.documents.length - 1) {
        masterData.selectedDocuments =
          masterData.selectedDocuments +
          ' ' +
          '"' +
          masterData.additionalDetails.documents[i].name +
          '"';
      } else {
        masterData.selectedDocuments =
          masterData.selectedDocuments +
          ' ' +
          '"' +
          masterData.additionalDetails.documents[i].name +
          '" ,';
      }
    }
  }
  turnoverValidation(turnover, masterData) {
    const regexTemp = new RegExp('^[a-zA-Zs]*$');
    const result = regexTemp.test(turnover);
    if (result === false) {
      masterData.additionalDetails.turnover =
        this._currencyCustomPipe.transform(
          masterData.additionalDetails.turnover
        );
    }
    let searchFind: boolean;
    const regexp = new RegExp('^[0-9.,s€]*$');
    searchFind = regexp.test(turnover);
    if (searchFind === false) {
      masterData.additionalDetails.turnover_validate = true;
    } else {
      masterData.additionalDetails.turnover_validate = false;
    }
  }
  removeSymbols(message: string): string {
    message = message.replace(',', '');
    message = message.replace('.', '');
    message = message.replace('€', '');
    message = message.trim();
    return message;
  }
  countrySet(country) {
    if (null === country || country === '') {
      return null;
    }
    const countryReturn = this._countries.find(
      obj => obj.countryName === country || obj.countryCode === country
    );
    if (undefined === countryReturn || null === countryReturn) {
      return null;
    }
    return countryReturn.countryName;
  }
  dateChange(e, ind) {
    this.masterDataRows[ind].additionalDetails.is_est_of_cmp_error = false;
    const regexp = new RegExp('^[0-9./]*$');
    let searchfind: boolean;
    if (e.target.value?.length >= 10) {
      e.preventDefault();
    }
    if (e?.type === 'paste') {
      searchfind = regexp.test(e.clipboardData.getData('text/plain'));
      if (!searchfind) {
        e.preventDefault();
      }
    } else {
      searchfind = regexp.test(e.key);
      if (!searchfind) {
        e.preventDefault();
      }
    }
  }
  onDateBlur(e, ind) {
    const today = moment(this.today, 'DD-MM-YYYY').format('MM/DD/YYYY');
    const enteredDate = moment(e.target.value, 'DD-MM-YYYY').format(
      'MM/DD/YYYY'
    );
    const difference = moment(enteredDate).diff(moment(today), 'days');
    if (difference > 0) {
      e.target.value = '';
      this.masterDataRows[ind].additionalDetails.est_of_cmp = null;
      this.masterDataRows[ind].additionalDetails.is_est_of_cmp_error = true;
    }
  }
  dateClicked(ind) {
    this.masterDataRows[ind].additionalDetails.is_est_of_cmp_error = false;
  }
  fiscalYearValidation(e) {
    const regexp = new RegExp('^[0-9.]*$');
    let searchfind: boolean;
    if (e?.type === 'paste') {
      searchfind = regexp.test(e.clipboardData.getData('text/plain'));
      if (!searchfind) {
        e.preventDefault();
      }
    } else {
      searchfind = regexp.test(e.key);
      if (!searchfind) {
        e.preventDefault();
      } else {
        const ann = e.target.value.toString();
        const keee = ann.concat(e.key);
        const temp = keee.replace('.', '');
        if (temp.length > 4) {
          e.preventDefault();
        }
        const reg = new RegExp('^(([0-9]{0,2}){1}(.?)([0-9]{0,2}){1})(.?)$');
        const stringCheck = reg.test(keee);
        if (!stringCheck) {
          e.preventDefault();
        }
      }
    }
  }
  onFiscalYearBlur(e, ind) {
    this.masterDataRows[ind].additionalDetails.is_fiscal_year_error = false;
    const length = e.target.value.length;
    if (length === 3 || length === 4 || length === 5) {
      if (
        e.target.value === '292' ||
        e.target.value === '29.02' ||
        e.target.value === '29.2' ||
        e.target.value === '2902'
      ) {
        this.masterDataRows[ind].additionalDetails.dummy_fiscal = moment(
          '29-2-2000',
          'DD-MM-YYYY'
        ).format('DD.MM.');
      } else {
        this.masterDataRows[ind].additionalDetails.dummy_fiscal = moment(
          e.target.value,
          'DD-MM-YYYY'
        ).format('DD.MM.');
      }
      if (
        this.masterDataRows[ind].additionalDetails.dummy_fiscal !==
        'Invalid date'
      ) {
        const dividedDate =
          this.masterDataRows[ind].additionalDetails.dummy_fiscal.split('.');
        const day = Number(dividedDate[0]);
        const month = Number(dividedDate[1]);
        this.masterDataRows[ind].additionalDetails.actual_fiscal = new Date(
          2000,
          month - 1,
          day
        );
      } else {
        this.masterDataRows[ind].additionalDetails.is_fiscal_year_error = true;
        this.masterDataRows[ind].additionalDetails.dummy_fiscal = '';
        this.masterDataRows[ind].additionalDetails.actual_fiscal = null;
      }
    } else {
      this.masterDataRows[ind].additionalDetails.actual_fiscal = null;
    }
  }
  fiscalYearChange(picker5, ind) {
    this.masterDataRows[ind].additionalDetails.dummy_fiscal = moment(
      picker5._validSelected
    ).format('DD.MM.');
  }
  setTableData(userData, status, supported_languages) {
    return {
      user_id: userData.user_id,
      user_name: userData.user_name,
      customer_name: userData.customer_name,
      customer_id: userData.customer_id,
      type: userData.type,
      email: userData.email,
      division: userData.division_name,
      city: userData.city,
      status,
      country: userData.country,
      house: userData.house,
      is_email_sent: userData.is_email_sent,
      is_invited_to_rcs: userData.is_invited_to_rcs,
      invitation_language: userData.language_code,
      supported_languages,
      role: userData.role,
      is_vip: userData.is_vip,
      salutation_id: userData.salutation_id,
      street: userData.street,
      address: userData.address,
      first_name: userData.first_name,
      last_name: userData.last_name,
      time_invited_to_rcs: userData.time_invited_to_rcs,
      telefon: userData.telefon,
      zip_code: userData.zip_code,
      last_login: userData.last_login,
      is_logged_in: userData.is_logged_in,
      invite_user: this.revertInvitation,
      customer_address_lat_lon_set: userData.customer_address_lat_lon_set,
      lat: userData.lat,
      lon: userData.lon,
      is_source_ivm: userData.is_source_ivm,
      liztu_vn_kdnr: userData.liztu_vn_kdnr,
      user_idnr: userData.user_idnr,
      mfa_mode: userData.mfa_mode,
      is_invite_reset: userData.is_invite_reset,
      role_id: userData.role_id,
    };
  }
  createUpdateUserRequest(user_data) {
    return {
      customer_id: user_data.customer_id,
      user_id: user_data.user_id,
      user_name: user_data.user_name,
      Telefon: user_data.telefon,
      first_name: user_data.first_name,
      last_name: user_data.last_name,
      email: user_data.email,
      customer: user_data.customer_name,
      salutation_id: user_data.salutation_id,
      status: user_data.status,
      supported_languages: user_data.supported_languages,
      role: user_data.role_id,
      division: user_data.division,
      childcustomer: user_data.childcustomer,
      otp: user_data.otp,
      house: user_data.house,
      invitation_language: user_data.invitation_language,
      street: user_data.street,
      zip_code: user_data.zip_code,
      city: user_data.city,
      country: this.countrySet(user_data.country),
      viaemail: user_data.is_email_sent,
      vialetter: user_data.is_email_sent,
      is_invite_to_be_sent: user_data.is_invited_to_rcs === true ? false : true,
      is_invited_to_rcs: user_data.is_invited_to_rcs,
      is_invite_reset: user_data.is_invite_reset,
      is_vip: user_data.is_vip,
      otpTo: user_data.mfa_mode === 1 ? 'Mobile' : 'Email',
      customer_address_lat_lon_set: user_data.customer_address_lat_lon_set,
      lat: user_data.lat,
      lon: user_data.lon,
      address: user_data.address,
      customer_name: user_data.customer_name,
      title: 'Benutzerinformationen_text',
      buttonCancelName: 'Abbrechen_text',
      newUser: false,
      mfa_mode: user_data.mfa_mode,
      buttonConfirmName:
        user_data.is_invited_to_rcs != true
          ? 'AktualisierenUnd_text'
          : 'Aktualisieren_text',
      is_source_ivm: user_data.is_source_ivm,
      liztu_vn_kdnr: user_data.liztu_vn_kdnr,
      user_idnr: user_data.user_idnr,
      is_group: user_data.is_group,
      type: user_data.type,
      role_id: user_data.role_id,
    };
  }

  private _watchOnLocale(): void {
    this._userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(locale => (this.selectLang = locale));
  }
}
