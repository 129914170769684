<app-modal width="600" (close)="closeModal()">
  <ng-container title>{{ 'appointments.create_new_appointment' | translate }}</ng-container>

  <div content class="create-appointment">
    <form [formGroup]="appointmentForm">
      <div class="create-appointment__row">
        <mat-label>{{ 'appointments.appointment_name' | translate }}</mat-label>
        <input
          matInput 
          type="text" 
          class="create-appointment__control create-appointment__input" 
          formControlName="subject"
          [placeholder]="'appointments.appointment_name' | translate" />
      </div>
      <div class="create-appointment__row">
        <mat-label>{{ 'appointments.description' | translate }}</mat-label>
        <textarea
          rows="5"
          class="create-appointment__control create-appointment__textarea"
          formControlName="description"
          [placeholder]="'appointments.description' | translate"></textarea>
      </div>
      <div class="create-appointment__row">
        <mat-label>{{ 'appointments.location' | translate }}</mat-label>
        <input
          matInput
          type="text" 
          class="create-appointment__control create-appointment__input" 
          formControlName="location"
          [placeholder]="'appointments.location' | translate" />
      </div>
      <div class="create-appointment__row">
        <mat-label>{{ 'appointments.date_and_time' | translate }}</mat-label>
        <div class="create-appointment__date create-appointment__control">
          <mat-form-field appearance="outline" class="create-appointment__field">
            <input 
              matInput
              [matDatepicker]="datepicker"
              class="date-input"
              formControlName="date"
              [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="datepicker">
              <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                keyboard_arrow_down
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" class="create-appointment__field">
            <input 
              matTimepicker 
              #t1="matTimepicker" 
              [okLabel]="'confirm_popup.ok' | translate"
              [cancelLabel]="'confirm_popup.cancel' | translate"
              mode="24h"
              [placeholder]="'appointments.from' | translate"
              formControlName="timeFrom"
              [maxDate]="appointmentForm.get('timeTo').value">
            <mat-icon matSuffix (click)="t1.showDialog()">access_time</mat-icon>
          </mat-form-field>
          <mat-form-field appearance="outline" class="create-appointment__field">
            <input 
              matTimepicker 
              #t2="matTimepicker" 
              [okLabel]="'confirm_popup.ok' | translate"
              [cancelLabel]="'confirm_popup.cancel' | translate"
              mode="24h"
              [placeholder]="'appointments.to' | translate"
              formControlName="timeTo"
              [minDate]="appointmentForm.get('timeFrom').value">
            <mat-icon matSuffix (click)="t2.showDialog()">access_time</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="create-appointment__row">
        <mat-label>{{ 'appointments.employees' | translate }}</mat-label>

        <mat-form-field appearance="outline" class="create-appointment__control create-appointment__field">
          <mat-select
            (selectionChange)="resetSearch()"
            (openedChange)="resetSearch()"
            formControlName="employees"
            [placeholder]="'appointments.select_employees' | translate"
            multiple>
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip *ngFor="let guest of appointmentForm.get('employees')?.value" [value]="guest">
                  <div class="create-appointment__guest-chip">
                    <app-guest-option
                      *ngIf="getSelectedGuest(guest)"
                      [option]="getSelectedGuest(guest)"></app-guest-option>
                  </div>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <input 
              type="text" 
              maxlength="20"
              class="searchSelect"
              preventSpace
              [placeholder]="'header.Suche_tooltip' | translate"
              [formControl]="searchControl">
              <ng-container *ngFor="let option of guestOptions">
                <mat-option 
                  [style.display]="getOptionDisplay(option)" 
                  [value]="option.value">
                  <app-guest-option [option]="option"></app-guest-option>
                </mat-option>
              </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="create-appointment__row">
        <mat-label>{{ 'appointments.external' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="create-appointment__control create-appointment__field">
          <mat-chip-list #chipGrid formControlName="external">
            <mat-chip
              *ngFor="let guest of appointmentForm.get('external').value"
              removable
              (removed)="removeExternalGuest(guest)">
              {{guest}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input 
              [placeholder]="'appointments.add_external' | translate"
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              matChipInputAddOnBlur
              (matChipInputTokenEnd)="addExternalGuest($event)"/>
          </mat-chip-list>
        </mat-form-field>
      </div>
      <div class="create-appointment__row">
        <mat-label>{{ 'appointments.reminder.set_reminder' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="create-appointment__control create-appointment__field">
          <mat-select
            formControlName="reminder"
            [placeholder]="'appointments.reminder.label' | translate">
            <mat-option *ngFor="let option of reminderOptions" [value]="option.value">
              {{ option.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>

  <ng-container actions>
    <app-button secondary (click)="closeModal()">{{ 'confirm_popup.cancel' | translate }}</app-button>
    <app-button (click)="confirm()" [disabled]="appointmentForm.invalid">{{ 'confirm_popup.confirm' | translate }}</app-button>
  </ng-container>
</app-modal>