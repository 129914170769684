import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  UploadTypeConstants,
} from '@shared/constants/building.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { BuildingObject, Criteria } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-electroplating',
  templateUrl: './electroplating.component.html',
  styleUrls: ['./electroplating.component.css'],
})
export class ElectroplatingComponent implements OnInit, OnDestroy {
  electroplatingForm: FormGroup;
  @Input('object_data') object_data;
  @Input('objectType') objectType;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  selectedDocumentsArray = {};
  selectedThermographicDocumentsArray = {};
  showDocumentThermographicPopup = false;
  browser: string;
  isFormValueUpdated: boolean;
  uploadObjectDetails: UploadObjectDetails;
  request: BuildingObject;
  uploadedDocsIds = [];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    public validation: ValidationService,
    private serviceCall: ApiCallsService
  ) {
    this.browser = this.detectBrowser();
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.ELECTROPLATING
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;

    const electroplatingIntegralValidation = new FormControl({
      value: formData ? formData.electroplating.electroplating_integral : null,
      disabled: false,
    });
    const isSecondPlantValidation = new FormControl({
      value: formData ? formData.electroplating.is_second_plant : null,
      disabled: false,
    });
    const numberOfBathsValidation = new FormControl({
      value: formData ? formData.electroplating.number_of_baths : null,
      disabled: false,
    });
    const ageOfEqupimentValidation = new FormControl({
      value: formData ? formData.electroplating.age_of_equpiment : null,
      disabled: false,
    });
    const floorAreaValidation = new FormControl({
      value: formData
        ? this.validation.setSqmValue(formData.electroplating.floor_area)
        : null,
      disabled: false,
    });
    const fireproofPartitionValidation = new FormControl({
      value: formData ? formData.electroplating.fireproof_partition : null,
      disabled: false,
    });

    const fireAlarmSystemValidation = new FormControl({
      value: formData ? formData.electroplating.fire_alarm_system : null,
      disabled: false,
    });
    const extinguishingSystemValidation = new FormControl({
      value: formData ? formData.electroplating.extinguishing_system : null,
      disabled: false,
    });
    const isSafeToShutdownValidation = new FormControl({
      value: formData ? formData.electroplating.is_safe_to_shutdown : null,
      disabled: false,
    });
    const areBathsMadeOfPlasticValidation = new FormControl({
      value: formData
        ? formData.electroplating.are_baths_made_of_plastic
        : null,
      disabled: false,
    });
    const areSteamWaterHeatedValidation = new FormControl({
      value: formData ? formData.electroplating.are_steam_water_heated : null,
      disabled: false,
    });
    const isAnOverflowProtectionValidation = new FormControl({
      value: formData
        ? formData.electroplating.is_an_overflow_protection
        : null,
      disabled: false,
    });
    const areVentilationDuctsValidation = new FormControl({
      value: formData ? formData.electroplating.are_ventilation_ducts : null,
      disabled: false,
    });
    const isThereUnsupervisedValidation = new FormControl({
      value: formData ? formData.electroplating.is_there_unsupervised : null,
      disabled: false,
    });
    const areLevelSwitchesValidation = new FormControl({
      value: formData ? formData.electroplating.are_level_switches : null,
      disabled: false,
    });
    const areHazardPreventionPlansValidation = new FormControl({
      value: formData
        ? formData.electroplating.are_hazard_prevention_plans
        : null,
      disabled: false,
    });
    const areChemicalsStoredAtUnsafePlaceValidation = new FormControl({
      value: formData
        ? formData.electroplating.are_chemicals_stored_at_unsafe_place
        : null,
      disabled: false,
    });

    const thermographicTestValidation = new FormControl({
      value: formData ? formData.electroplating.is_thermographic_test : null,
      disabled: false,
    });
    const uploadThermographicTestValidation = new FormControl({
      value: formData
        ? formData.electroplating.thermographic_test.upload_thermographic_test
        : null,
      disabled: false,
    });

    const areElectricRodValidation = new FormControl({
      value: formData ? formData.electroplating.are_electric_rod : null,
      disabled: false,
    });
    const areSafetyClearancesValidation = new FormControl({
      value: formData
        ? formData.electroplating.electric_rod.are_safety_clearances
        : null,
      disabled: false,
    });
    const areSelfSufficientValidation = new FormControl({
      value: formData
        ? formData.electroplating.electric_rod.are_self_sufficient
        : null,
      disabled: false,
    });

    const hasRiskAnalysisAarriedValidation = new FormControl({
      value: formData
        ? formData.electroplating.has_risk_analysis_carried
        : null,
      disabled: false,
    });
    const resultingMeasuresValidation = new FormControl({
      value: formData
        ? formData.electroplating.risk_analysis_carried.resulting_measures
        : null,
      disabled: false,
    });
    const annotations = new FormControl({
      value: formData ? formData.electroplating.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.electroplating.is_annotation : null,
      disabled: false,
    });

    this.electroplatingForm = new FormGroup({
      electroplating_integral: electroplatingIntegralValidation,
      is_second_plant: isSecondPlantValidation,
      number_of_baths: numberOfBathsValidation,
      age_of_equpiment: ageOfEqupimentValidation,
      floor_area: floorAreaValidation,
      fireproof_partition: fireproofPartitionValidation,
      fire_alarm_system: fireAlarmSystemValidation,
      extinguishing_system: extinguishingSystemValidation,
      is_safe_to_shutdown: isSafeToShutdownValidation,
      are_baths_made_of_plastic: areBathsMadeOfPlasticValidation,
      are_steam_water_heated: areSteamWaterHeatedValidation,
      is_an_overflow_protection: isAnOverflowProtectionValidation,
      are_ventilation_ducts: areVentilationDuctsValidation,
      is_there_unsupervised: isThereUnsupervisedValidation,
      are_level_switches: areLevelSwitchesValidation,
      are_hazard_prevention_plans: areHazardPreventionPlansValidation,
      are_chemicals_stored_at_unsafe_place:
        areChemicalsStoredAtUnsafePlaceValidation,

      is_thermographic_test: thermographicTestValidation,
      upload_thermographic_test: uploadThermographicTestValidation,

      are_electric_rod: areElectricRodValidation,
      are_safety_clearances: areSafetyClearancesValidation,
      are_self_sufficient: areSelfSufficientValidation,

      has_risk_analysis_carried: hasRiskAnalysisAarriedValidation,
      resulting_measures: resultingMeasuresValidation,

      annotations,
      is_annotation,
    });
    this.isFormValueUpdated = false;
    this.electroplatingForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.electroplatingForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
    this.getDocumentUploadData(record_id);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.ELECTROPLATING_THERMOGRAPHIC_TEST
              ) {
                this.selectedThermographicDocumentsArray[
                  document.document_name
                ] = document;
              }
            });
            if (data.documents) {
              this.electroplatingForm.controls.upload_thermographic_test.setValue(
                Object.keys(this.selectedThermographicDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  uploadThermographicDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedThermographicDocumentsArray,
    };
    this.showDocumentThermographicPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.ELECTROPLATING_THERMOGRAPHIC_TEST,
    };
  }
  showDocumentPopup() {
    return this.showDocumentThermographicPopup;
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showDocumentThermographicPopup) {
      this.uploadedDocsIds = [];
      this.selectedThermographicDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentThermographicPopup = false;
      this.electroplatingForm.controls.upload_thermographic_test.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.electroplatingForm.controls.upload_thermographic_test.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploadedDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.electroplatingForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }
  buildFormObject() {
    const ThermographicTest = {
      upload_thermographic_test:
        this.electroplatingForm.value.upload_thermographic_test,
    };
    const ElectricRod = {
      are_safety_clearances:
        this.electroplatingForm.value.are_safety_clearances,
      are_self_sufficient: this.electroplatingForm.value.are_self_sufficient,
    };

    const RiskAnalysisCarried = {
      resulting_measures: this.electroplatingForm.value.resulting_measures,
    };
    const electroplating = {
      electroplating_integral:
        this.electroplatingForm.value.electroplating_integral,
      is_second_plant: this.electroplatingForm.value.is_second_plant,
      number_of_baths: this.electroplatingForm.value.number_of_baths,
      age_of_equpiment: this.electroplatingForm.value.age_of_equpiment,
      floor_area: this.electroplatingForm.value.floor_area
        ? Number(
            this.validation.removeSymbols(
              this.electroplatingForm.value.floor_area
            )
          )
        : null,
      fireproof_partition: this.electroplatingForm.value.fireproof_partition,
      fire_alarm_system: this.electroplatingForm.value.fire_alarm_system,
      extinguishing_system: this.electroplatingForm.value.extinguishing_system,
      is_safe_to_shutdown: this.electroplatingForm.value.is_safe_to_shutdown,
      are_baths_made_of_plastic:
        this.electroplatingForm.value.are_baths_made_of_plastic,
      are_steam_water_heated:
        this.electroplatingForm.value.are_steam_water_heated,
      is_an_overflow_protection:
        this.electroplatingForm.value.is_an_overflow_protection,
      are_ventilation_ducts:
        this.electroplatingForm.value.are_ventilation_ducts,
      is_there_unsupervised:
        this.electroplatingForm.value.is_there_unsupervised,
      are_level_switches: this.electroplatingForm.value.are_level_switches,
      are_hazard_prevention_plans:
        this.electroplatingForm.value.are_hazard_prevention_plans,
      are_chemicals_stored_at_unsafe_place:
        this.electroplatingForm.value.are_chemicals_stored_at_unsafe_place,

      is_thermographic_test:
        this.electroplatingForm.value.is_thermographic_test,
      thermographic_test: ThermographicTest,

      are_electric_rod: this.electroplatingForm.value.are_electric_rod,
      electric_rod: ElectricRod,

      has_risk_analysis_carried:
        this.electroplatingForm.value.has_risk_analysis_carried,
      risk_analysis_carried: RiskAnalysisCarried,

      annotations: this.electroplatingForm.value.annotations,
      is_annotation: this.electroplatingForm.value.is_annotation,
    };

    const formValuesToPersist = {
      electroplating,
    };
    this.request = {
      document_ids: [...new Set(this.uploadedDocsIds)],
      criteria_id: BuildingConstants.ELECTROPLATING,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.electroplatingForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }

    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
