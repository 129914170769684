import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SparteShareService {
    private _sparte: any = null;
    get sparte(): any {
        return this._sparte;
    }
    set sparte(value: any) {
        this._sparte = value;
    }
}
