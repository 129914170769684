import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableStructure } from '@shared/models/column-details.model';
import {
  TableConfiguration,
} from '@shared/models/table.model';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import {
  DOC_POPUP_ACTION_TOOLTIPS,
  DOC_POPUP_COLUMNS,
  DOC_POPUP_COLUMN_NAMES,
  METHOD_CALL,
  DOC_POPUP_HEADINGS,
} from './documents-popup.constants';
import { TranslateService } from '@ngx-translate/core';
import {
  COLUMN_TYPES,
  TOOLTIP_PLACEMENT,
} from '@shared/constants/column-types.constants';
import { TABLE_MAX_HEIGHT } from '@shared/constants/general/table.constants';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import {
  DocumentInformation,
  GetSecuredDocumentRequest,
} from '../../models/document-management.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ActioButtons } from '@shared/models/table.model';

type DocPopupTable = DocumentInformation & {
  action: ActioButtons[];
};

@Component({
  selector: 'app-documents-popup',
  templateUrl: 'documents-popup.component.html',
})
export class DocumentsPopupComponent extends BaseComponent implements OnInit {
  tableStructure: TableStructure;
  tableConfig: TableConfiguration = {
    isFilterSection: false,
    isPagination: false,
    isHeadingAboveFilter: false,
  };
  tableData: DocPopupTable[] = [];
  isPdfDisplayed = false;
  pdfLink: string;
  blob: string;

  constructor(
    public dialogRef: MatDialogRef<DocumentsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translateService: TranslateService,
    private _documentsApiService: DocumentManagementApiService,
    private _dateFormatPipe: DateFormatPipe,
    private _apiCallsService: ApiCallsService
  ) {
    super();
  }

  ngOnInit() {
    this.tableStructure = this._getTableStructure();
    this._getDocuments();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  callMethod(data: { element: DocumentInformation }): void {
    this._viewPdf(data.element);
  }

  closePDF() {
    this.isPdfDisplayed = false;
  }

  private _getDocuments(): void {
    this._documentsApiService
      .getDocuments(
        this.data.source,
        this.data.liztuVnKdnr,
        this.data.liztuVsIdnr,
        this.data.liztuSaIdnr || null,
        this.data.customerId,
        this.data.policy,
        this.data.contractId,
        null,
        null,
        null,
        null
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.tableData = this._getTableData(data.documents);
        }
      });
  }

  private _getTableData(data: DocumentInformation[]): DocPopupTable[] {
    return data.map(item => ({
      ...item,
      sourceTarget: `${item.sourceUser}/${item.targetUser}`,
      uploadDate: this._dateFormatPipe.transform(item.uploadDate),
      action: [
        {
          iconLink: ACTION_BUTTON.OPEN_DOC,
          isSingleTooltip: true,
          functionToBeCalled: METHOD_CALL.PDF,
          isDisabled: false,
          width: 19,
          singleTooltip: {
            tooltipText: this._translateService.instant(
              DOC_POPUP_ACTION_TOOLTIPS.PDF
            ),
            tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
          },
        },
      ],
    }));
  }

  private _getHeadingTranslation(heading: string): string {
    return this._translateService.instant(heading).toUpperCase();
  }

  private _getTableStructure(): TableStructure {
    return {
      displayColumns: DOC_POPUP_COLUMNS,
      columnDetails: [
        {
          matColumnDef: DOC_POPUP_COLUMN_NAMES.TYPE,
          colHeading: this._getHeadingTranslation(DOC_POPUP_HEADINGS.TYPE),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: DOC_POPUP_COLUMN_NAMES.DESCRIPTION,
          colHeading: this._getHeadingTranslation(
            DOC_POPUP_HEADINGS.DESCRIPTION
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: DOC_POPUP_COLUMN_NAMES.CREATED_BY,
          colHeading: this._getHeadingTranslation(
            DOC_POPUP_HEADINGS.CREATED_BY
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: DOC_POPUP_COLUMN_NAMES.SOURCE_TARGET,
          colHeading: this._getHeadingTranslation(
            DOC_POPUP_HEADINGS.SOURCE_TARGET
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: DOC_POPUP_COLUMN_NAMES.UPLOAD_DATE,
          colHeading: this._getHeadingTranslation(
            DOC_POPUP_HEADINGS.UPLOAD_DATE
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: DOC_POPUP_COLUMN_NAMES.ACTION,
          colHeading: this._getHeadingTranslation(DOC_POPUP_HEADINGS.ACTION),
          columnType: COLUMN_TYPES.ACTION,
        },
      ],
      optionalStyles: {
        maxHeight: TABLE_MAX_HEIGHT,
      },
    };
  }

  private _viewPdf(document: DocumentInformation) {
    const request: GetSecuredDocumentRequest = {
      uuid: document.uuid,
    };
    this._apiCallsService
      .getSecureDocument(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (document.content_type == 'pdf') {
          const fileURL = window.URL.createObjectURL(data);
          this.isPdfDisplayed = true;
          this.pdfLink = fileURL;
          this.blob = data;
        } else {
          const objectURL = window.URL.createObjectURL(data);
          window.open(objectURL);
        }
      });
  }
}
