import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ValidationSharedService {
  private _formError: string | null;
  private _formErrorPower: string | null;
  private _formErrorCount: string | null;

  saveFormError(error: string | null): void {
    this._formError = error;
  }

  getFormError(): string | null {
    return this._formError;
  }

  saveFormErrorPower(error: string | null): void {
    this._formErrorPower = error;
  }

  getFormErrorPower(): string | null {
    return this._formErrorPower;
  }

  saveFormErrorCount(error: string | null): void {
    this._formErrorCount = error;
  }

  getFormErrorCount(): string | null {
    return this._formErrorCount;
  }
}
