import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface TableAction {
  disabled?: boolean;
  icon: string;
  label: string;
  key: string;
}

@Component({
  selector: 'app-table-actions',
  templateUrl: 'table-actions.component.html',
  styleUrls: ['table-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionsComponent {
  @Input() actions: TableAction[] = [];
  @Output() actionClicked: EventEmitter<string> = new EventEmitter();

  clickAction(key: string): void {
    this.actionClicked.emit(key);
  }
}
