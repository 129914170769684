<div style="border-bottom: 1px solid #f1f0f0;margin-top: -17px;float:left;width: 98%;"
    [ngClass]="groupClass == true ? 'singleClass' : 'groupClass'">
    <mat-tab-group style="padding: 0px 50px 0px 30px;" (selectedTabChange)="onTabChanged($event);">
        <mat-tab style="width: 100%;" *ngIf="data.is_flood == true" label="Flood">
            <ng-template mat-tab-label><span [ngClass]="activeFlood == true ? 'oneTab' : ''">{{ 'zurs-geo.Flood_label' |
                    translate }}</span></ng-template>
        </mat-tab>

        <mat-tab style="width: 100%;" *ngIf="data.is_earthquake == true" label="Earth">
            <ng-template mat-tab-label><span [ngClass]="activeEarth == true ? 'oneTab' : ''">{{
                    'zurs-geo.Earthquake_label' | translate }}</span>
            </ng-template>
        </mat-tab>
        <mat-tab style="width: 100%;" *ngIf="data.is_snow == true" label="Snow">
            <ng-template mat-tab-label><span [ngClass]="activeSnow == true ? 'oneTab' : ''">{{ 'zurs-geo.Snow_label' |
                    translate }}</span>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<div style="    float: right;
margin-top: -46px;
margin-right: 16px;"><button style="border: none;
    background: #fff;color: #272727;" (click)="onNoClick()">
    <mat-icon>
        <img alt="" style="vertical-align: sub;" src="../../../../../assets/images/Group 3298.svg">
    </mat-icon>
</button></div>
<mat-dialog-content style="padding: 19px 30px;margin:0px;height: 456px;">
    <div style="width: 100%;" *ngIf="showFlood == true">
         <div *ngIf="showFloodScreen == true">
        <span class="text-color-bold" style="font-size:18px">{{ 'zurs-geo.Analysis_label' | translate }}</span>
        <form>

            <div style="width: 100%;">
                <div style="padding: 13px 5px 0px 0px;
                width: 33%;
                float: left;">
                    <label><span>{{ 'zurs-geo.Adresse_label' | translate }}</span></label>
                    <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                        [(ngModel)]="data.address" name="address">
                </div>
                <div style="    padding: 13px 5px 0px 6px;
                width: 33%;
                float: left;">
                    <label><span>{{ 'zurs-geo.Street_label' | translate }}</span></label>
                    <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                        [(ngModel)]="data.street" name="street">
                </div>
                <div style="    padding: 13px 5px 0px 6px;
                width: 33%;
                float: left;">
                    <label><span>{{ 'zurs-geo.HouseNumber_label' | translate }}</span></label>
                    <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                        [(ngModel)]="data.house_number" name="house">
                </div>
            </div>
            <div style="width: 100%;">
                <div style="    padding: 13px 3px 0px 0px;
                width: 24.5%;float: left;">
                    <label><span>{{ 'zurs-geo.City_label' | translate }}</span></label>
                    <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                        [(ngModel)]="data.city" name="city">
                </div>
                <div style="padding: 13px 3px 0px 7px;
                width: 24.5%;float: left;">
                    <label><span>{{ 'zurs-geo.PostCode_label' | translate }}</span></label>
                    <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                        [(ngModel)]="data.zip_code" name="zip">
                </div>
                <div style="padding: 13px 3px 0px 7px;
                width: 24.5%;float: left;">
                    <label><span>{{ 'zurs-geo.Latitude' | translate }}</span></label>
                    <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                        [(ngModel)]="data.lat" name="lat">
                </div>
                <div style="    padding: 13px 0px 0px 6px;
                width: 24.5%;float: left;">
                    <label><span>{{ 'zurs-geo.Longitude' | translate }}</span></label>
                    <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                        [(ngModel)]="data.lon" name="long">
                </div>
            </div>
            <div style="width: 100%;">
                <div style="padding: 13px 5px 0px 0px;
                width: 45%;float: left;">
                    <label><span>{{ 'zurs-geo.Zone_found_lable' | translate }}</span></label>
                    <input readonly readonly class="input" [(ngModel)]="zone" required style="border-radius: 1px;border: 1px solid;"
                        name="zone">
                </div>
                <div style="padding: 13px 5px 0px 6px;
                width: 26%;float: left;">
                    <label><span>{{ 'zurs-geo.Rain_risk_lable' | translate }}</span></label>
                    <input readonly readonly class="input" [(ngModel)]="intenseRainRisk" required style="border-radius: 1px;border: 1px solid;"
                        name="rainrisk">
                </div>
                <div style="    padding: 13px 0px 0px 18px;
                width: 27%;
                float: left;">
                    <label><span>{{ 'zurs-geo.Risk_hazard_lable' | translate }}</span></label>
                    <div style="width:100%">
                        <button disabled style="margin-left: 0px;" type="button" class= "tooltip1" [ngStyle]="{'background-color' : riskHazard === 1 ? '#46bd2a' : '#fff' , 'color' : riskHazard === 1 ? '#fff' : '#9c9898' , 'border' : riskHazard === 1 ? '1px solid #46bd2a' : '1px solid #bbbbbb'}"
                        >1
                        <span class="tooltipbutton1" [ngClass]="{'Tool1' : selectLang=='English(EN)'}"><div style="background-color: #e5e5e5;
                            padding: 6px 5px 13px 5px;
                            border-radius: 2px;
                            line-height: 1.3;
                            word-spacing: 0.1px;">{{ 'zurs-geo.GK1_text' | translate }}</div></span>
                    </button>
                        <button disabled class= "tooltip2" style="margin-left: 6px;" type="button"
                        [ngStyle]="{'background-color' : riskHazard === 2 ? '#46bd2a' : '#fff' , 'color' : riskHazard === 2 ? '#fff' : '#9c9898' , 'border' : riskHazard === 2 ? '1px solid #46bd2a' : '1px solid #bbbbbb'}">2
                            <span class="tooltipbutton2" [ngClass]="{'Tool2' : selectLang=='English(EN)'}"><div style="    background-color: #e5e5e5;
                                padding: 6px 5px 4px 5px;
                                border-radius: 2px;
                                line-height: 1.2;
                                word-spacing: 0.1px;">{{ 'zurs-geo.GK2_text' | translate }}</div></span>
                        </button>
                        <button  disabled type="button" style="margin-left: 6px;" class= "tooltip3"
                        [ngStyle]="{'background-color' : riskHazard === 3 ? '#46bd2a' : '#fff' , 'color' : riskHazard === 3 ? '#fff' : '#9c9898' , 'border' : riskHazard === 3 ? '1px solid #46bd2a' : '1px solid #bbbbbb'}">3
                            <span class="tooltipbutton3" [ngClass]="{'Tool3' : selectLang=='English(EN)'}"><div style="background-color:#e5e5e5;padding: 6px 5px 13px 5px;
                                border-radius: 2px;
                                line-height: 1.3;
                                word-spacing: 0.1px;">{{ 'zurs-geo.GK3_text' | translate }}</div></span>
                        </button>
                        <button disabled type="button" style="margin-left: 6px;" class= "tooltip4"
                        [ngStyle]="{'background-color' : riskHazard === 4 ? '#46bd2a' : '#fff' , 'color' : riskHazard === 4 ? '#fff' : '#9c9898' , 'border' : riskHazard === 4 ? '1px solid #46bd2a' : '1px solid #bbbbbb'}">4
                            <span class="tooltipbutton4" [ngClass]="{'Tool4' : selectLang=='English(EN)'}"><div style="background-color:#e5e5e5;padding: 2px 5px 3px 5px;
                                border-radius: 2px;
                                line-height: 1.3;
                                word-spacing: 0.1px;">{{ 'zurs-geo.GK4_text' | translate }}</div></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <div style="width:100%;margin-top: 20px;
        display: inline-block;">
            <span class="text-color-bold" style="font-size:13px;float:left">{{ 'zurs-geo.hazard_level_lable' | translate
                }} {{data.city}}</span>
            <div style="float: right;
            color: #9c9898;
            font-size: 9px;
            font-weight: 600;
            margin-top: 6px;"><span style="padding-right: 12px;">{{'zurs-geo.read_zurs_text' | translate}}</span><span>{{'zurs-geo.retrieved_On_text' | translate}}
                   {{currentDate | dateFormat}}</span></div>
        </div>
       </div>
       <div *ngIf="showFloodScreen == false && showFloodProcessing == true" style="text-align: center;margin-top:4%;margin-bottom: 20%;">
        <br/>
        <br/>
        <h1 style="color: #272727;font-weight: 600;">{{ 'zurs-geo.process_code_text' | translate}}</h1>
           <br/><br/>
           <h1 style="color: #272727;font-weight: 600;">{{processing_id_flood}}</h1>
           <br/><br/>
           <h3 style="color: #272727;font-weight: 600;">{{processing_desc_flood}}</h3>
       </div>
       <div *ngIf="showFloodScreen == false && showNullFloodScreen == true" style="text-align: center;margin-top: 35%;margin-bottom: 35%;">

        <h5 style="color: #272727;font-weight: 600;">{{ 'zurs-geo.no_response_text' | translate}}</h5>

    </div>

    </div>
    <div style="width: 100%;" *ngIf="showEarth == true">
         <div *ngIf="showEarthScreen == true">
            <span class="text-color-bold" style="font-size:18px">{{ 'zurs-geo.Analysis_label' | translate }}</span>
            <form>

                <div style="width: 100%;">
                    <div style="padding: 13px 5px 0px 0px;
                    width: 33%;
                    float: left;">
                        <label><span>{{ 'zurs-geo.Adresse_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.address" name="address">
                    </div>
                    <div style="    padding: 13px 5px 0px 6px;
                    width: 33%;
                    float: left;">
                        <label><span>{{ 'zurs-geo.Street_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.street" name="street">
                    </div>
                    <div style="    padding: 13px 5px 0px 6px;
                    width: 33%;
                    float: left;">
                        <label><span>{{ 'zurs-geo.HouseNumber_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.house_number" name="house">
                    </div>
                </div>
                <div style="width: 100%;">
                    <div style="    padding: 13px 3px 0px 0px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.City_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.city" name="city">
                    </div>
                    <div style="padding: 13px 3px 0px 7px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.PostCode_label' | translate }}</span></label>
                        <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.zip_code" name="zip">
                    </div>
                    <div style="padding: 13px 3px 0px 7px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.Latitude' | translate }}</span></label>
                        <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.lat" name="lat">
                    </div>
                    <div style="    padding: 13px 0px 0px 6px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.Longitude' | translate }}</span></label>
                        <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.lon" name="long">
                    </div>
                </div>
                <div style="width: 100%;">
                    <div style="padding: 13px 5px 0px 0px;
                    width: 27%;float: left;">
                        <label><span>{{ 'zurs-geo.EMS_Class_Label' | translate }}</span></label>
                        <input readonly readonly class="input" [(ngModel)]="ems_class" required style="border-radius: 1px;border: 1px solid;"
                            name="ems_class">
                    </div>
                    <div style="padding: 13px 5px 0px 6px;
                    width: 36%;float: left;">
                        <label><span>{{ 'zurs-geo.EMS_Intensity_Label' | translate }}</span></label>
                        <input readonly readonly class="input" [(ngModel)]="ems_intensity" required style="border-radius: 1px;border: 1px solid;"
                            name="ems_intensity">
                    </div>
                    <div style="padding: 13px 0px 0px 6px;
                    width: 36%;float: left;">
                        <label><span>{{ 'zurs-geo.Earthquake_Zone_Label' | translate }}</span></label>
                        <input readonly readonly class="input" [(ngModel)]="earthquake_zone" required style="border-radius: 1px;border: 1px solid;"
                            name="earthquake_zone">
                    </div>
                </div>
            </form>
            <div style="width:100%;margin-top: 20px;
            display: inline-block;">
                <span class="text-color-bold" style="font-size:13px;float:left">{{ 'zurs-geo.hazard_level_lable' | translate
                    }} {{data.city}}</span>
                <div style="float: right;
                color: #9c9898;
                font-size: 9px;
                font-weight: 600;
                margin-top: 6px;"><span style="padding-right: 12px;">{{'zurs-geo.read_zurs_text' | translate}}</span><span>{{'zurs-geo.retrieved_On_text' | translate}}
                       {{currentDate | dateFormat}}</span></div>
            </div>
           </div>
           <div *ngIf="showEarthScreen == false && showEarthProcessing == true" style="text-align: center;margin-top:4%;margin-bottom: 20%;">
            <br/>
            <br/>
            <h1 style="color: #272727;font-weight: 600;">{{ 'zurs-geo.process_code_text' | translate}}</h1>
               <br/><br/>
               <h1 style="color: #272727;font-weight: 600;">{{processing_id_earth}}</h1>
               <br/><br/>
               <h3 style="color: #272727;font-weight: 600;">{{processing_desc_earth}}</h3>
           </div>
           <div *ngIf="showEarthScreen == false && showNullEarthScreen == true" style="text-align: center;margin-top:35%;margin-bottom: 35%;">

            <h5 style="color: #272727;font-weight: 600;">{{ 'zurs-geo.no_response_text' | translate}}</h5>

        </div>

    </div>
    <div style="width: 100%;" *ngIf="showSnow == true">
        <div *ngIf="showSnowScreen == true">
            <span class="text-color-bold" style="font-size:18px">{{ 'zurs-geo.Analysis_label' | translate }}</span>
            <form>

                <div style="width: 100%;">
                    <div style="padding: 13px 5px 0px 0px;
                    width: 33%;
                    float: left;">
                        <label><span>{{ 'zurs-geo.Adresse_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.address" name="address">
                    </div>
                    <div style="    padding: 13px 5px 0px 6px;
                    width: 33%;
                    float: left;">
                        <label><span>{{ 'zurs-geo.Street_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.street" name="street">
                    </div>
                    <div style="    padding: 13px 5px 0px 6px;
                    width: 33%;
                    float: left;">
                        <label><span>{{ 'zurs-geo.HouseNumber_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.house_number" name="house">
                    </div>
                </div>
                <div style="width: 100%;">
                    <div style="    padding: 13px 3px 0px 0px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.City_label' | translate }}</span></label>
                        <input readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.city" name="city">
                    </div>
                    <div style="padding: 13px 3px 0px 7px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.PostCode_label' | translate }}</span></label>
                        <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.zip_code" name="zip">
                    </div>
                    <div style="padding: 13px 3px 0px 7px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.Latitude' | translate }}</span></label>
                        <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.lat" name="lat">
                    </div>
                    <div style="    padding: 13px 0px 0px 6px;
                    width: 24.5%;float: left;">
                        <label><span>{{ 'zurs-geo.Longitude' | translate }}</span></label>
                        <input readonly readonly class="input" required style="border-radius: 1px;border: 1px solid;"
                            [(ngModel)]="data.lon" name="long">
                    </div>
                </div>
                <div style="width: 100%;">
                    <div style="padding: 13px 5px 0px 0px;
                    width: 45%;float: left;">
                        <label><span>{{ 'zurs-geo.Corrected_snowload_zone' | translate }}</span></label>
                        <input readonly readonly class="input" [(ngModel)]="corrected_zone" required style="border-radius: 1px;border: 1px solid;"
                            name="corrected_snowload_zone">
                    </div>
                    <div style="padding: 13px 5px 0px 6px;
                    width: 30%;float: left;">
                        <label><span>{{ 'zurs-geo.Snowload_zone' | translate }}</span></label>
                        <input readonly readonly class="input" [(ngModel)]="snowload_zone" required style="border-radius: 1px;border: 1px solid;"
                            name="snowload_zone">
                    </div>
                </div>
            </form>
            <div style="width:100%;margin-top: 20px;
            display: inline-block;">
                <span class="text-color-bold" style="font-size:13px;float:left">{{ 'zurs-geo.hazard_level_lable' | translate
                    }} {{data.city}}</span>
                <div style="float: right;
                color: #9c9898;
                font-size: 9px;
                font-weight: 600;
                margin-top: 6px;"><span style="padding-right: 12px;">{{'zurs-geo.read_zurs_text' | translate}}</span><span>{{'zurs-geo.retrieved_On_text' | translate}}
                       {{currentDate | dateFormat}}</span></div>
            </div>
           </div>
           <div *ngIf="showSnowScreen == false && showSnowProcessing == true" style="text-align: center;margin-top:4%;margin-bottom: 20%;">
            <br/>
            <br/>
            <h1 style="color: #272727;font-weight: 600;">{{ 'zurs-geo.process_code_text' | translate}}</h1>
               <br/><br/>
               <h1 style="color: #272727;font-weight: 600;">{{processing_id_snow}}</h1>
               <br/><br/>
               <h3 style="color: #272727;font-weight: 600;">{{processing_desc_snow}}</h3>
           </div>
           <div *ngIf="showSnowScreen == false && showNullSnowScreen == true" style="text-align: center;margin-top:35%;margin-bottom: 35%;">

            <h5 style="color: #272727;font-weight: 600;">{{ 'zurs-geo.no_response_text' | translate}}</h5>

        </div>

    </div>
    <div id="showMap"></div>
</mat-dialog-content>
<mat-dialog-actions align="center" style="border-top:1px solid #f1f0f0;">
    <div  *ngIf="showFlood == true && showFloodScreen == true"><a (click)="downloadPdf(pdfLink, 'flood')"><button style="color: #272727;background: #ff9000;padding: 5px 16px 5px 16px;
    border-radius: 2px;font-size: 15px;font-weight:600;margin-left: 7px;border: 1px solid #ff9000;margin-bottom: 13px;
    margin-top: 13px;" mat-button type="submit" color="warn">
        <mat-icon style="color:#272727;vertical-align: middle;"> <img alt="" style="margin-top: -25px;
            margin-left: -14px;" src="../../../../../assets/images/Download_analysis_report_icon.svg"></mat-icon>{{ 'zurs-geo.analysis_report_button' | translate}}
    </button></a></div>
    <div  *ngIf="showEarth == true && showEarthScreen == true"><a (click)="downloadPdf(pdfLink2, 'earth')"><button style="color: #272727;background: #ff9000;padding: 5px 16px 5px 16px;
        border-radius: 2px;font-size: 15px;font-weight:600;margin-left: 7px;border: 1px solid #ff9000;margin-bottom: 13px;
        margin-top: 13px;" mat-button type="submit" color="warn">
            <mat-icon style="color:#272727;vertical-align: middle;"> <img alt="" style="margin-top: -25px;
                margin-left: -14px;" src="../../../../../assets/images/Download_analysis_report_icon.svg"></mat-icon>{{ 'zurs-geo.analysis_report_button' | translate}}
        </button></a></div>
        <div  *ngIf="showSnow == true && showSnowScreen == true"><a (click)="downloadPdf(pdfLink3, 'snow')"><button style="color: #272727;background: #ff9000;padding: 5px 16px 5px 16px;
            border-radius: 2px;font-size: 15px;font-weight:600;margin-left: 7px;border: 1px solid #ff9000;margin-bottom: 13px;
            margin-top: 13px;" mat-button type="submit" color="warn">
                <mat-icon style="color:#272727;vertical-align: middle;"> <img alt="" style="margin-top: -25px;
                    margin-left: -14px;" src="../../../../../assets/images/Download_analysis_report_icon.svg"></mat-icon>{{ 'zurs-geo.analysis_report_button' | translate}}
            </button></a></div>
</mat-dialog-actions>
