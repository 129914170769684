import { TODO_MANAGEMENT_ROUTES } from './shared/constants/router.constants';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimReportingComponent } from './components/claim-reporting/claim-reporting.component';
import { ClaimsComponent } from './components/claims/claims.component';
import { ROUTES_PATH } from './shared/constants/router.constants';
import { ContactManagerComponent } from './modules/rcs-setup/contact-manager/contact-manager.component';
import { ContractsMainComponent } from './components/contracts/contracts.component';
import { CustomerDashboardConfigurationComponent } from './modules/dashboard/cd-dashboard-configuration/cd-dashboard-configuration.component';
import { DashboardConfigurationComponent } from './components/dashboard-configuration/dashboard-configuration.component';
import { InviteUserComponent } from './components/invite-user/invite-user.component';
import { NewDocumentComponent } from './components/new-document/new-document.component';
import { NewObjectModuleComponent } from './components/object-module/new-object-module/new-object-module.component';
import { ObjectModuleComponent } from './components/object-module/object-module.component';
import { OTPComponent } from './components/otp/otp.component';
import { RcsSetupComponent } from './modules/rcs-setup/rcs-setup.component';
import { RegisterClaimStepThreeComponent } from './components/register-claim/register-claim-step-three/register-claim-step-three.component';
import { RegisterClaimStepTwoComponent } from './components/register-claim/register-claim-step-two/register-claim-step-two.component';
import { RegisterClaimComponent } from './components/register-claim/register-claim.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SparteManagementComponent } from './modules/rcs-setup/sparte-management/sparte-management.component';
import { UpdateCustomerComponent } from './components/update-customer/update-customer.component';
import { UserDataComponent } from './components/user-data/user-data.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ValueModuleComponent } from './components/value-module/value-module.component';
import { DirtycheckGuard } from './core/guards/dirtycheck.guard';
import { LeaveCustomerEditGuard } from './core/guards/leave-customer-edit.guard';
import { LeaveObjectGuard } from './core/guards/leave-object.guard';
import { LeaveprofileGuard } from './core/guards/leaveprofile.guard';

const Approutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTES_PATH.DASHBOARD,
  },
  {
    path: ROUTES_PATH.DASHBOARD,
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
  },
  { path: ROUTES_PATH.OTP, component: OTPComponent },
  { path: ROUTES_PATH.CONTACT_MANAGER, component: ContactManagerComponent },
  { path: ROUTES_PATH.USERS, component: UserDataComponent },
  {
    path: ROUTES_PATH.CUSTOMER_MANAGEMENT,
    loadChildren: () =>
      import('./modules/customer-management/customer-management.module').then(
        m => m.CustomerManagementModule
      ),
  },
  { path: ROUTES_PATH.INVITE_USER, component: InviteUserComponent },
  { path: ROUTES_PATH.SETTINGS, component: SettingsComponent },
  {
    path: ROUTES_PATH.USER_PROFILE,
    component: UserProfileComponent,
    canDeactivate: [LeaveprofileGuard],
  },
  { path: ROUTES_PATH.CLAIMS, component: ClaimsComponent },
  {
    path: ROUTES_PATH.DASHBOARD_CONFIGURATION,
    component: DashboardConfigurationComponent,
    canDeactivate: [DirtycheckGuard],
  },
  {
    path: ROUTES_PATH.CUSTOMER_DASHBOARD_CONFIGURATION,
    component: CustomerDashboardConfigurationComponent,
    canDeactivate: [DirtycheckGuard],
  },
  { path: ROUTES_PATH.SPARTE_MANAGEMENT, component: SparteManagementComponent },
  {
    path: ROUTES_PATH.CUSTOMER_EDIT,
    component: UpdateCustomerComponent,
    canDeactivate: [LeaveCustomerEditGuard],
  },
  { path: ROUTES_PATH.MORE_DOCUMENT, component: NewDocumentComponent },
  { path: ROUTES_PATH.REGISTER_CLAIM, component: RegisterClaimComponent },
  {
    path: ROUTES_PATH.REGISTER_CLAIM_STEP_TWO,
    component: RegisterClaimStepTwoComponent,
  },
  {
    path: ROUTES_PATH.REGISTER_CLAIM_STEP_THREE,
    component: RegisterClaimStepThreeComponent,
  },
  {
    path: ROUTES_PATH.CONTRACT,
    component: ContractsMainComponent,
    pathMatch: 'full',
  },
  { path: ROUTES_PATH.CLAIM_REPORTING, component: ClaimReportingComponent },
  { path: ROUTES_PATH.OBJECT_MODULE, component: ObjectModuleComponent },
  {
    path: ROUTES_PATH.ADD_NEW_OBJECT,
    component: NewObjectModuleComponent,
    canDeactivate: [LeaveObjectGuard],
  },
  { path: ROUTES_PATH.VALUE_MODULE, component: ValueModuleComponent },
  {
    path: ROUTES_PATH.ADD_NEW_CONTRACT,
    loadChildren: () =>
      import('./modules/new-contract/new-contract.module').then(
        m => m.NewContractModule
      ),
  },
  {
    path: ROUTES_PATH.RCS_SETUP,
    component: RcsSetupComponent,
    loadChildren: () =>
      import('./modules/rcs-setup/rcs-setup-routing.module').then(
        m => m.RCSSetupRoutingModule
      ),
  },
  {
    path: ROUTES_PATH.CREATE_CLAIMS,
    loadChildren: () =>
      import('./modules/new-claims/new-claims.module').then(
        m => m.NewClaimsModule
      ),
  },
  {
    path: ROUTES_PATH.INSURER_MANAGEMENT,
    loadChildren: () =>
      import('./components/insurer-management/insurer-management.module').then(
        m => m.InusrerManagementModule
      ),
  },
  {
    path: ROUTES_PATH.INTERMEDIARY_MANAGEMENT,
    loadChildren: () =>
      import(
        './components/intermediary-management/intermediary-management.module'
      ).then(m => m.IntermediaryManagementModule),
  },
  {
    path: ROUTES_PATH.RECENT_LOGINS,
    loadChildren: () =>
      import('./components/login-details/login-details.module').then(
        m => m.LoginDetailsModule
      ),
  },
  {
    path: TODO_MANAGEMENT_ROUTES.GENERAL,
    loadChildren: () =>
      import('./modules/todo/todo.module').then(m => m.TodoModule),
  },
  {
    path: ROUTES_PATH.DEEPLINK,
    loadChildren: () =>
      import('./modules/deeplink/deeplink.module').then(m => m.DeeplinkModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(Approutes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
