<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic new-claims">
  <div style="min-height: 78vh; white-space: nowrap; height: 100%">
    <app-breadcrumbs
      [isCustomerUser]="isCustomerUser"
      [activeLable]="activeLable"
      [isCustomerSelected]="isCustomerSelected"
      [contractInfo]="contractInfo"
      [pageBreadcrumbText]="pageBreadcrumbText"
      [divisionName]="divisionName"
      [customerName]="customerName$ | async">
    </app-breadcrumbs>
    <div class="flex-common margin-top-10">
      <div class="d-flex action-bar-container flex-grow-1 gap-10">
        <app-action-bar
          (actionClick)="actionBarClick($event)"
          [leftActions]="defaultActions"
          [rightActions]="extraActions"></app-action-bar>
      </div>
      <!-- <mat-slide-toggle
        [formControl]="ivm_sync"
        [disabled]="disable_ivm || lock_state > 0"
        (change)="postIVMSync(claim_id, !!$event.checked)"
        labelPosition="before"
        class="toggle-button"
        style="float: right">
        {{ 'new-contract.general_information.enableForm' | translate }}
      </mat-slide-toggle> -->
      <mat-slide-toggle
        [formControl]="ivm_sync"
        [disabled]="true"
        (change)="postIVMSync(claim_id, !!$event.checked)"
        labelPosition="before"
        class="toggle-button"
        style="float: right">
        {{ 'new-contract.general_information.enableForm' | translate }}
      </mat-slide-toggle>
    </div>
    <div style="margin-top: 10px">
      <label class="label, text-danger" [hidden]="state_warning_ivm_hidden">{{
        'new-contract.general_information.lockStateIVM' | translate
      }}</label>
      <label
        class="label, text-warning"
        [hidden]="state_warning_protect_hidden"
        >{{
          'new-contract.general_information.lockStateProtect' | translate
        }}</label
      >
    </div>
    <mat-sidenav-container
      class="sidenav-container"
      (keydown.escape)="$event.stopPropagation()">
      <mat-sidenav
        #leftSidenav
        mode="side"
        [ngClass]="{ 'mat-sidenav--opened': sideNavState }"
        opened
        (keydown.escape)="$event.stopPropagation()">
        <div
          class="sidenav_container"
          [@onSideNavChange]="sideNavState ? 'open' : 'close'"
          (keydown.escape)="$event.stopPropagation()">
          <div class="d-flex flex-column" style="height: 100%">
            <ul
              ngbNav
              #nav="ngbNav"
              [activeId]="active"
              class="nav-pills"
              orientation="vertical"
              (navChange)="tabChange($event)"
              (keydown.escape)="$event.stopPropagation()">
              <li
                [ngClass]="{ 'disabled-sideNav': tab.disabled }"
                (click)="navigateToTab(tab.name)"
                [ngbNavItem]="tab.tab"
                *ngFor="let tab of leftTabs">
                <a ngbNavLink [class.disabled]="tab.disabled">
                  <span class="left-element flex-center">
                    <img alt="" title="{{ tab.sourceTitle }}" [src]="tab.img" />
                    <span [@animateText]="linkText ? 'show' : 'hide'">{{
                      tab.sourceTitle
                    }}</span>
                  </span>
                </a>
              </li>
            </ul>
            <div class="sideNav-btn">
              <button
                mat-icon-button
                (click)="onSinenavToggle()"
                (keydown.escape)="$event.stopPropagation()"
                style="background: #ff9000">
                <mat-icon>{{
                  sideNavState ? 'arrow_left' : 'arrow_right'
                }}</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-sidenav>
      <div class="sidenav-container__content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-container>
  </div>
</div>
<app-upload-document-popup></app-upload-document-popup>
<app-create-doument-policy
  *ngIf="showDocTemplateModal"
  [avaliable_templates]="avaliable_templates"
  ui_mask_id="30"
  [user_id]="user_id"
  [customer_id]="customer_id"
  [liztu_vn_kdnr]="liztu_vn_kdnr"
  (closeDocModal)="closeDocModal($event)"
  (save_document)="savePolicyTemplate($event)">
</app-create-doument-policy>
<custom-pdf-viewer
  *ngIf="showPDFViewer"
  [pdfLink]="pdfLink"
  [pdfName]="pdfName"
  via="policyTemplate"
  [blob]="blob"
  (onClosePdf)="onClosePdf($event)"></custom-pdf-viewer>
