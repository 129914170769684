import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { ProductModuleConstants } from '@shared/constants/global-constants.constants';
import { MultilingualPipe } from '../../pipes/multilingual.pipe';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface ClausesExclusionsData {
  name: string;
  id: number;
  description: string;
  is_selected: boolean;
}

@Component({
  selector: 'app-clauses-exclusions',
  templateUrl: './clauses-exclusions.component.html',
  styleUrls: ['./clauses-exclusions.component.css'],
})
export class ClausesExclusionsComponent
  implements OnInit, OnDestroy, OnChanges
{
  copytext;
  showAddForm = false;
  showClause = false;
  fieldDescription = '';
  fieldName = '';
  addEntryForm: FormGroup;
  comingFromPlural = '';
  showRequiredError = false;
  @Input('comingFrom') comingFrom;
  @Input('route') route;
  @Input('showPopup') showPopup;
  @Input('data') data;
  @Input('sparteFixerCode') sparteFixerCode;
  @Output('hidePopup') hide = new EventEmitter<any>();
  @Output('clauses_exclusions_data') clauses_exclusions_emit =
    new EventEmitter<any>();
  @Input('contract_id') contract_id;
  @Input('is_disable') is_disable;
  showModal = false;
  deleteId;
  contractId: any;
  showDiscardPopup: boolean;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    public _clipboard: Clipboard,
    private fb: FormBuilder,
    public multilingual: MultilingualPipe,
    private _apiService: ApiCallsService,
    private translate: TranslateService,
    private _toastService: ToastService
  ) {}
  clauses_exclusions: ClausesExclusionsData[] = [];
  selected_clauses_exclusions_ids = [];
  selected_clauses_exclusions_names = [];
  clause_name: '';
  clause_description: '';
  clause_description_display = '';
  copyData;
  readonly ProductModuleConstants = ProductModuleConstants;
  isGuardShow = false;
  ngOnInit(): void {
    this.addEntryForm = this.fb.group({
      id: [null],
      name: [null, Validators.required],
      description: [null],
    });
  }
  ngOnChanges() {
    if (this.data) {
      this.copyData = this.data ? JSON.parse(JSON.stringify(this.data)) : null;
    }
    if (this.copyData) {
      this.clauses_exclusions = [];
      if (this.route !== 'contract') {
        this.selected_clauses_exclusions_ids = this.copyData
          ? this.copyData.selected_clauses
            ? this.copyData.selected_clauses
            : this.copyData.selected_exclusions
              ? this.copyData.selected_exclusions
              : []
          : [];
        this.copyData?.data?.map(row => {
          const clause_exclusion: ClausesExclusionsData = {
            name: row.name,
            description: row.description,
            id: row.id,
            is_selected: this.selected_clauses_exclusions_ids.find(
              entry => entry == row.id
            )
              ? true
              : false,
          };
          this.clauses_exclusions.push(clause_exclusion);
        });
      } else {
        if (this.comingFrom == ProductModuleConstants.additionAgreement) {
          this.contractId = this.copyData.contract_id;
          this.selected_clauses_exclusions_ids = this.copyData
            ? this.copyData.selected_additional
              ? this.copyData.selected_additional
              : []
            : [];
          this.copyData?.data?.map(row => {
            const clause_exclusion: ClausesExclusionsData = {
              name: row.name,
              description: row.description,
              id: row.id,
              is_selected: row.is_selected,
            };
            this.clauses_exclusions.push(clause_exclusion);
            this.clauses_exclusions.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
          });
        } else {
          this.selected_clauses_exclusions_ids = this.copyData
            ? this.copyData.selected_clauses
              ? this.copyData.selected_clauses
              : []
            : [];
          this.copyData?.data?.map(row => {
            const clause_exclusion: ClausesExclusionsData = {
              name: row.name,
              description: row.description,
              id: row.id,
              is_selected:
                this.selected_clauses_exclusions_ids !== undefined
                  ? this.selected_clauses_exclusions_ids.find(
                      entry => entry == row.id
                    )
                    ? true
                    : false
                  : false,
            };
            this.clauses_exclusions.push(clause_exclusion);
          });
        }
      }
    }
    this.comingFromPlural = this.comingFrom ? this.comingFrom + 's' : null;
  }
  hidePopup() {
    this.isGuardShow = false;
    this.showPopup = false;
    this.showClause = false;
    this.showAddForm = false;
    this.selected_clauses_exclusions_ids = [];
    this.selected_clauses_exclusions_names = [];
    this.addEntryForm.reset();
    this.hide.emit();
  }
  copy(value) {
    this._clipboard.copy(value);
  }
  setClause(clause) {
    this.clause_name = clause.name;
    this.clause_description = clause.description;
    this.clause_description_display = clause.description;
    this.clause_description_display = this.clause_description_display.replace(
      new RegExp('\r?\n', 'g'),
      '<br>'
    );
  }
  openAddForm() {
    this.addEntryForm.reset();
    this.showClause = false;
    this.showAddForm = true;
  }
  showDetails() {
    this.addEntryForm.reset();
    this.showAddForm = false;
    this.showClause = true;
  }
  save() {
    this.isGuardShow = false;
    if (this.comingFrom !== ProductModuleConstants.additionAgreement) {
      const clause = {
        id: this.addEntryForm.controls.id.value,
        name: this.addEntryForm.controls.name.value,
        description: this.addEntryForm.controls.description.value,
        is_clauses: this.comingFrom == ProductModuleConstants.clause,
        sparte_fixer_code: this.sparteFixerCode,
        is_update: this.addEntryForm.controls.id.value ? true : false,
      };
      if (this.addEntryForm.status == 'INVALID') {
        this.showRequiredError = true;
        this.addEntryForm.markAllAsTouched();
      }
      if (this.addEntryForm.status == 'VALID') {
        this._apiService
          .addUpdateClauseOrExclusion(clause)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            this.showToastMessage(data.message);
            this.getClausesOrExclusionData(
              this.comingFrom == ProductModuleConstants.clause,
              this.sparteFixerCode
            );
            setTimeout(() => {
              if (!this.addEntryForm.controls.id.value) {
                this.addEntryForm.reset();
              } else {
                this.showAddForm = false;
                this.showClause = true;
                this.setClause(clause);
                this.setActiveElement(clause.id);
              }
            }, 500);
          });
      }
    } else {
      const additionalSideAgreement = {
        data: {
          id: this.addEntryForm.controls.id.value,
          name: this.addEntryForm.controls.name.value,
          description: this.addEntryForm.controls.description.value,
          contract_id: Number(this.contractId),
          is_selected: this.addEntryForm.controls.id.value ? true : false,
        },
      };
      if (this.addEntryForm.status == 'INVALID') {
        this.showRequiredError = true;
        this.addEntryForm.markAllAsTouched();
      }
      if (this.addEntryForm.status == 'VALID') {
        this._apiService
          .addUpdateAdditionalSideAgreement(additionalSideAgreement)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            this.showToastMessage(data.message);
            this.getAdditionalSideAgreement();
            setTimeout(() => {
              if (!this.addEntryForm.controls.id.value) {
                this.addEntryForm.reset();
              } else {
                this.showAddForm = false;
                this.showClause = true;
                this.setClause(additionalSideAgreement.data);
                this.setActiveElement(additionalSideAgreement.data.id);
              }
            }, 500);
          });
      }
    }
  }
  getAdditionalSideAgreement() {
    this._apiService
      .getAdditionalSideAgreement(this.contractId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.selected_clauses_exclusions_ids = [];
        this.copyData = data;
        this.clauses_exclusions = [];
        this.copyData?.data?.map(row => {
          if (row.is_selected) {
            this.selected_clauses_exclusions_ids.push(row.id);
          }
          const clause_exclusion: ClausesExclusionsData = {
            name: row.name,
            description: row.description,
            id: row.id,
            is_selected: row.is_selected,
          };
          this.clauses_exclusions.push(clause_exclusion);
          this.clauses_exclusions.sort();
          this.clauses_exclusions.sort((a, b) => a.name.localeCompare(b.name));
        });
      });
  }
  checkInvalidInput() {
    this.showRequiredError = !this.addEntryForm.controls.name.valid
      ? true
      : false;
    this.isGuardShow = true;
  }
  showToastMessage(message) {
    this._toastService.showToastMessage(document, message);
  }
  copyToClipbboard() {
    this._clipboard.copy(this.clause_description.replace(/\n/g, '\r\n'));
    this.showToastMessage(
      this.translate.instant(
        'product-management.general_information.copied_to_clipboard'
      )
    );
  }
  deleteClauseOrExclusion(id) {
    const index = this.selected_clauses_exclusions_ids.findIndex(
      data => data == id
    );
    if (index !== -1) {
      this.selected_clauses_exclusions_ids.splice(index, 1);
    }
    if (this.comingFrom !== ProductModuleConstants.additionAgreement) {
      this._apiService
        .deleteClauseOrExclusion(id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.addEntryForm.reset();
            this.showAddForm = false;
            this.showClause = false;
            this.clause_name = '';
            this.clause_description = '';
            this.clause_description_display = '';
            this.showToastMessage(data.message);
            this.getClausesOrExclusionData(
              this.comingFrom == ProductModuleConstants.clause,
              this.sparteFixerCode
            );
          } else {
            this.selected_clauses_exclusions_ids.push(id);
            this.showToastMessage(data.message);
          }
          this.showModal = false;
        });
    } else {
      this._apiService
        .deleteAdditionalSideAgreement(this.contractId, id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.addEntryForm.reset();
            this.showAddForm = false;
            this.showClause = false;
            this.clause_name = '';
            this.clause_description = '';
            this.clause_description_display = '';
            this.showToastMessage(data.message);
            this.getAdditionalSideAgreement();
          } else {
            this.selected_clauses_exclusions_ids.push(id);
            this.showToastMessage(data.message);
          }
          this.showModal = false;
        });
    }
  }
  getClausesOrExclusionData(is_clauses, sparte_fixer_code) {
    this._apiService
      .getClausesOrExclusions(is_clauses, sparte_fixer_code)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.clauses_exclusions = [];
        data.clauses_exclusions.map(row => {
          const clause_exclusion: ClausesExclusionsData = {
            name: row.name,
            description: row.description,
            id: row.id,
            is_selected: this.selected_clauses_exclusions_ids.find(
              entry => entry == row.id
            )
              ? true
              : false,
          };
          this.clauses_exclusions.push(clause_exclusion);
        });
      });
  }
  editClauseOrExclusion(clause) {
    this.showRequiredError = false;
    this.openAddForm();
    this.addEntryForm.controls.id.setValue(clause.id);
    this.addEntryForm.controls.name.setValue(clause.name);
    this.addEntryForm.controls.description.setValue(clause.description);
  }
  clauseOrExclusionSelect(id, event) {
    const index = this.selected_clauses_exclusions_ids.findIndex(
      data => data == id
    );
    if (index >= 0) {
      this.selected_clauses_exclusions_ids.splice(index, 1);
      this.clauses_exclusions.find(row => row.id == id).is_selected = false;
    } else {
      this.selected_clauses_exclusions_ids.push(id);
      this.clauses_exclusions.find(row => row.id == id).is_selected = true;
    }
    if (this.comingFrom == ProductModuleConstants.additionAgreement) {
      this._apiService
        .editAdditionalSideAgreemnet(id, event.currentTarget.checked)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.getAdditionalSideAgreement();
          }
        });
    }
  }
  continueClick() {
    if (this.comingFrom == ProductModuleConstants.additionAgreement) {
      if (this.addEntryForm.controls.name.value !== '' && this.isGuardShow) {
        this.showDiscardPopup = true;
      } else {
        this.continueSaveProcess();
        this.hidePopup();
      }
    } else {
      this.continueSaveProcess();
    }
  }
  continueSaveProcess() {
    this.selected_clauses_exclusions_names = [];
    let clauses_exclusions_data;
    this.selected_clauses_exclusions_ids?.map(id => {
      const name = this.clauses_exclusions.find(data => data.id == id)?.name;
      if (name) {
        this.selected_clauses_exclusions_names.push(name);
      }
    });
    clauses_exclusions_data = {
      names: this.selected_clauses_exclusions_names,
      ids: this.selected_clauses_exclusions_ids,
      comingFrom: this.comingFrom,
    };
    this.clauses_exclusions_emit.emit(clauses_exclusions_data);
  }
  setActiveElement(id) {
    document.querySelectorAll('.set-background').forEach(el => {
      el.classList.remove('set-background');
    });
    const Id = 'div' + id;
    document.getElementById(Id).classList.add('set-background');
  }
  hideModal() {
    this.showModal = false;
  }
  showDeleteModal(id) {
    this.deleteId = id;
    this.showModal = true;
  }
  hideDiscardPopup(e) {
    if (e == 'true') {
      this.save();
      this.showDiscardPopup = false;
      setTimeout(() => {
        this.continueSaveProcess();
      }, 1000);

      this.showDiscardPopup = false;
      setTimeout(() => {
        this.hidePopup();
      }, 500);
    } else {
      this.showDiscardPopup = false;
      this.hidePopup();
    }
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
