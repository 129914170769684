import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  photovoltaicFormConstants,
} from '@shared/constants/building.constants';
import { Dictionary } from '@shared/models/dictionary.model';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { BuildingObject } from '@shared/models/request.model';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
import { SelectedDocument } from '@shared/models/upload-data.model';
@Component({
  selector: 'app-photovoltaic-inside',
  templateUrl: './photovoltaic.component.html',
  styleUrls: ['./photovoltaic.component.css'],
})
export class PhotovoltaicInsideComponent
  extends BaseComponent
  implements OnInit
{
  photovoltaicForm: FormGroup;
  browser: string;
  readonly selectLang$ = this._userService.locale$;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('object_data') object_data;
  @Input('objectType') objectType;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  uploadedDocsIds = [];
  showDocumentPopup = false;
  selectedDocumentsArray: Dictionary<SelectedDocument> = {};
  uploadObjectDetails: UploadObjectDetails;
  performance_of_the_plant;
  isFormValueUpdated: boolean;
  request: BuildingObject;

  constructor(
    public validationService: ValidationService,
    private serviceCall: ApiCallsService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private readonly _userService: UserService
  ) {
    super();
    this.browser = this.detectBrowser();
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }

  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.PHOTOVOLTAIC
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.PHOTOVOLTAIC]
          .saved_criteria
      );

    if (formData) {
      if (formData.photovoltaic.performance_of_the_plant) {
        this.performance_of_the_plant = this._currencyCustomPipe
          .transform(
            formData.photovoltaic.performance_of_the_plant
              .toString()
              ?.replaceAll('.', '')
          )
          .replace('€', '')
          ?.replace(',00', '')
          ?.replace(',00', '')
          ?.trim();
      } else {
        this.performance_of_the_plant = '';
      }
    }
    const performace_of_the_plant = new FormControl({
      value: formData ? this.performance_of_the_plant : null,
      disabled: false,
    });
    const owner = new FormControl({
      value: formData ? formData.photovoltaic.owner : null,
      disabled: false,
    });
    const invertor_free_from_combustible_materials = new FormControl({
      value: formData
        ? formData.photovoltaic.invertor_free_from_combustible_materials
        : null,
      disabled: false,
    });
    const PV_system_included_in_electrorivision = new FormControl({
      value: formData
        ? formData.photovoltaic.PV_system_included_in_electrorivision
        : null,
      disabled: false,
    });
    const fire_compartment_seperation_lifted = new FormControl({
      value: formData
        ? formData.photovoltaic.is_fire_compartment_separation_lifted
        : null,
      disabled: false,
    });
    const remark = new FormControl({
      value: formData
        ? formData.photovoltaic.fire_compartment_separation_lifted.remark
        : null,
      disabled: false,
    });
    const photovoltaic_system_included_in_erevision = new FormControl({
      value: formData
        ? formData.photovoltaic.is_photovoltaic_system_included_in_e_revision
        : null,
      disabled: false,
    });
    const AcceptanceProtocolPhotovoltaic = new FormControl({
      value: formData
        ? formData.photovoltaic.photovoltaic_system_included_in_e_revision
            .acceptance_report
        : null,
      disabled: false,
    });
    const annotations = new FormControl({
      value: formData ? formData.photovoltaic.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.photovoltaic.is_annotation : null,
      disabled: false,
    });

    this.photovoltaicForm = new FormGroup({
      owner,
      fire_compartment_seperation_lifted,
      remark,
      photovoltaic_system_included_in_erevision,
      AcceptanceProtocolPhotovoltaic,
      performace_of_the_plant,
      invertor_free_from_combustible_materials,
      PV_system_included_in_electrorivision,
      annotations,
      is_annotation,
    });

    if (formData !== undefined) {
      const record_id =
        this.criteriaRequestDetails &&
        this.criteriaRequestDetails.criteriaList[BuildingConstants.PHOTOVOLTAIC]
          .record_id;

      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.HasErrors == false) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              this.selectedDocumentsArray[document.document_name] = document;
            });
            this.photovoltaicForm.controls.AcceptanceProtocolPhotovoltaic.setValue(
              Object.keys(this.selectedDocumentsArray).join(', ')
            );
          }
        });
    }
    this.isFormValueUpdated = false;
    this.photovoltaicForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isFormValueUpdated = true;
        this.buildFormObject();
        const pristine = this.photovoltaicForm.pristine;
        this.saveCriteriaRequest.emit({
          request: this.request,
          isSave: false,
          pristine,
        });
      });
  }
  uploadDoc() {
    this.showDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: 'photovoltaic_acceptance_report',
    };
  }
  hidePopup(selectedDocumentsArray) {
    this.showDocumentPopup = false;
    this.uploadedDocsIds = [];
    Object.keys(selectedDocumentsArray).forEach(name => {
      if (selectedDocumentsArray[name].upload_document_id) {
        this.uploadedDocsIds.push(
          selectedDocumentsArray[name].upload_document_id
        );
      }
    });
    this.photovoltaicForm.controls.AcceptanceProtocolPhotovoltaic.setValue(
      Object.keys(selectedDocumentsArray).join(', ')
    );
    this.buildFormObject();
    const pristine = !this.photovoltaicForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
  }
  buildFormObject() {
    const fire_compartment_separation_lifted_attribute = {
      remark: this.photovoltaicForm.value[photovoltaicFormConstants.REMARK],
    };
    const photovoltaic_system_included_in_e_revision_attribute = {
      acceptance_report:
        this.photovoltaicForm.value[
          photovoltaicFormConstants.ACCPTANCE_PROTOCOL
        ],
    };
    const photovoltaicFromAttributeList = {
      performance_of_the_plant: this.photovoltaicForm.value[
        photovoltaicFormConstants.PERFORMACE_OF_THE_PLANT
      ]
        ? Number(
            this.validationService.removeSymbols(
              this.photovoltaicForm.value[
                photovoltaicFormConstants.PERFORMACE_OF_THE_PLANT
              ]
            )
          )
        : null,
      owner: this.photovoltaicForm.value[photovoltaicFormConstants.OWNER],
      is_fire_compartment_separation_lifted:
        this.photovoltaicForm.value[
          photovoltaicFormConstants.FIRE_COMPARTMENT_SEPERATION_LIFTED
        ],
      fire_compartment_separation_lifted:
        fire_compartment_separation_lifted_attribute,
      is_photovoltaic_system_included_in_e_revision:
        this.photovoltaicForm.value[
          photovoltaicFormConstants.PHOTOVOLTAIC_SYSTEM_INCLUDED_IN_EREVISION
        ],
      photovoltaic_system_included_in_e_revision:
        photovoltaic_system_included_in_e_revision_attribute,
      invertor_free_from_combustible_materials:
        this.photovoltaicForm.value[
          photovoltaicFormConstants.INVERTOR_FREE_FROM_COMBUSTIBLE_MATERIAL
        ],
      PV_system_included_in_electrorivision:
        this.photovoltaicForm.value[
          photovoltaicFormConstants.PV_SYSTEM_INCLUDED_IN_ELECTROREVISION
        ],
      annotations: this.photovoltaicForm.value.annotations,
      is_annotation: this.photovoltaicForm.value.is_annotation,
    };
    const formValuesToPersist = {
      photovoltaic: photovoltaicFromAttributeList,
    };
    this.request = {
      document_ids: [...new Set(this.uploadedDocsIds)],
      criteria_id: BuildingConstants.PHOTOVOLTAIC,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.photovoltaicForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
