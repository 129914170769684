<div *ngIf="!addPaymentDetails">
  <app-payment-details
    (paymentId)="getPaymentId($event)"
    [message]="sendMessage">
  </app-payment-details>
</div>
<div *ngIf="addPaymentDetails">
  <add-payment-details
    [payment_id]="paymentId"
    (message)="getMessage($event)"
    (isPaymentAdd)="isPaymentAdd($event)">
  </add-payment-details>
  <app-present bottom="10vh" right="10px" box></app-present>
</div>
