import { IC_ICONS } from "./ic-icons.constant";

const PATH = {
    ACTION_BAR: 'assets/images/action-bar/'
};

export const ACTION_BAR = {
    CREATE_DOCUMENT_FROM_TEMPLATE: `${PATH.ACTION_BAR}create_document_from_template.png`,
    WRITE_LETTER: `${PATH.ACTION_BAR}write_letter_or_email.png`,
    ADD_CONTACT_PERSON: `${PATH.ACTION_BAR}add_contact_person.svg`,
    ADD_PAYMENT_DETAILS: `${PATH.ACTION_BAR}add_payment_details.svg`,
    ADD_BANK_DETAILS: `${PATH.ACTION_BAR}add_bank_account.svg`,
    ADD_COMMUNICATION: `${PATH.ACTION_BAR}add_communication.svg`,
    CREATE_CLAIM: `${PATH.ACTION_BAR}create_claim.png`,
    SHOW_CURRENT_POLICY_DOCUMENT: `${PATH.ACTION_BAR}show_current_policy_documents.png`,
    REPLACE_PRODUCT: `${PATH.ACTION_BAR}replace_product.png`,
    ADD_INSURER: `${PATH.ACTION_BAR}add_insurer.png`,
    CREATE_CONTRACT: `${PATH.ACTION_BAR}create_contract.svg`,
    INVITE_NEW_USER: `${PATH.ACTION_BAR}invite_new_user.svg`,
    ADD_CUSTOMER_RELATION: `${PATH.ACTION_BAR}add_customer_relation.svg`,
    ADD_BROKER_COMMISSION: `${PATH.ACTION_BAR}add_broker_commission.svg`,
    PROVIDE_DATA_TO_IVM: `${PATH.ACTION_BAR}send_data_to_ivm.png`,
    UPLOAD_DOCUMENT: IC_ICONS.UPLOAD,
    GENERAL_INTERMEDIARY_DETAILS : `${PATH.ACTION_BAR}General_Intermediary_Details.svg`,
    TOGGLE_PROTECT_LOCK: `${PATH.ACTION_BAR}toggle_protect_lock.png`,
    ADD_SUBORDINATE_CONTRACT: `${PATH.ACTION_BAR}icon-add-Subordinate-Contract.svg`,
    WRITE_EMAIL: `${PATH.ACTION_BAR}write-email.svg`,
};

export const ACTION_BAR_KEYS = {
    CREATE_DOCUMENT_FROM_TEMPLATE: 'Create Document From Template',
    WRITE_LETTER: 'Write A Letter',
    WRITE_EMAIL: 'Write An Email',
    CREATE_CONTRACT: 'Create Contract',
    ADD_COMMUNICATION: 'Add Communication',
    INVITE_NEW_USER: 'Invite New User',
    ADD_BANK_DETAILS: 'Add Bank Account',
    ADD_CONTACT_PERSON: 'Add Contact Person',
    ADD_CUSTOMER_RELATION: 'Add Customer Relation',
    CREATE_CLAIM: 'Create Claim',
    SHOW_CURRENT_POLICY_DOCUMENT: 'Show Current Policy Documents',
    REPLACE_PRODUCT: 'Replace Product',
    ADD_INSURER: 'Add Insurer',
    ADD_PAYMENT: 'Add Payment Details',
    ADD_AGENCY_NUMBER: 'Add Agency Number',
    ADD_COMMISION: 'Add Commission',
    PROVIDE_DATA_TO_IVM: 'Provide data to ERP now',
    UPLOAD_DOCUMENT: 'Upload document',
    TOGGLE_PROTECT_LOCK: 'Toggle lock from ICP',
    GENERAL_INTERMEDIARY_DETAILS: 'General Intermediary Details',
    ADD_SUBORDINATE_CONTRACT: 'Add Subordinate Contracts',
};

export const ACTION_BAR_MULTILINGUAL = {
    ADD_COMMUNICATION: 'action_bar.add_communication',
    ADD_CONTACT_PERSON: 'action_bar.add_contact_person',
    ADD_CUSTOMER_RELATION: 'action_bar.add_customet_relation',
    ADD_BANK_DETAILS: 'action_bar.add_bank_details',
    ADD_INSURER: 'action_bar.add_insurer',
    ADD_PAYMENT: 'action_bar.add_payment',
    ADD_AGENCY_NUMBER: 'action_bar.add_agency_number',
    CREATE_DOCUMENT_FROM_TEMPLATE: 'action_bar.create_document_from_template',
    CREATE_CONTRACT: 'action_bar.create_contract',
    CREATE_CLAIM: 'action_bar.create_claim',
    INVITE_NEW_USER: 'action_bar.invite_new_user',
    REPLACE_PRODUCT: 'action_bar.replace_product',
    SHOW_CURRENT_POLICY_DOCUMENT: 'action_bar.show_current_policy_document',
    WRITE_LETTER: 'action_bar.write_letter',
    WRITE_EMAIL: 'action_bar.write_email',
    ADD_BROKER_COMMSSION: 'insurer_management.broker_commision_tab.add_broker_commission',
    PROVIDE_DATA_TO_IVM: 'action_bar.provide_data_to_ivm',
    UPLOAD_DOCUMENT: 'action_bar.upload_document',
    COMMISSION: 'action_bar.add_commission',
    GENERAL_INTERMEDIARY_DETAILS: 'intermediary_management.general_intermediary_details',
    TOGGLE_PROTECT_LOCK: 'action_bar.toggle_protect_lock',
    ADD_SUBORDINATE_CONTRACT: 'new-contract.subordinate_contracts.add_subordinate_contract',
};
