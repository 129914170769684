import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  UploadTypeConstants,
} from '@shared/constants/building.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { BuildingObject, Criteria } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';

@Component({
  selector: 'app-power-supply',
  templateUrl: './power-supply.component.html',
  styleUrls: ['./power-supply.component.css'],
})
export class PowerSupplyComponent implements OnInit {
  powerSupplyForm: FormGroup;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('object_data') object_data;
  @Input('objectType') objectType;
  @Input('isresetForm') isresetForm;
  enerySupplyTypesData: any = [];
  transformerStationsData: any = [];
  externalPowerSupplyData: any = [];
  batterChargingStationsData: any = [];
  regularMaintananceByWhomData: any = [];
  typeOfCompressors: any = [];
  spatialSeperatedData: any = [];
  emergencyPowerSupply = false;
  showDocumentTransferSupplyPopup = false;
  showDocumentBatteryChargingStationsPopup = false;
  selectedTransferSupplyDocumentsArray = {};
  selectedBatteryChargingStationsDocumentsArray = {};
  sdDocsIds = [];
  bcsdDocsIds = [];
  selectedDocumentsArray = {};
  browser: string;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  uploadObjectDetails: UploadObjectDetails;
  externalSupplyHourMinData: any = [];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private serviceCall: ApiCallsService,
    public validation: ValidationService,
    private _currencyCustomPipe: CurrencyCustomPipe
  ) {
    this.browser = this.detectBrowser();
    this.serviceCall
      .getLookupData(15)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.enerySupplyTypesData = data.data;
      });
    this.serviceCall
      .getLookupData(16)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.transformerStationsData = data.data;
      });
    this.serviceCall
      .getLookupData(18)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.externalPowerSupplyData = data.data;
      });
    this.serviceCall
      .getLookupData(19)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.batterChargingStationsData = data.data;
      });
    this.serviceCall
      .getLookupData(20)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.spatialSeperatedData = data.data;
      });
    this.serviceCall
      .getLookupData(21)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.typeOfCompressors = data.data;
      });
    this.serviceCall
      .getLookupData(36)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.regularMaintananceByWhomData = data.data;
      });
    this.serviceCall
      .getLookupData(43)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.externalSupplyHourMinData = data.data;
      });
  }
  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[BuildingConstants.POWER_SUPPLY];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;
    const energySupplyTypeValidation = new FormControl({
      value: formData ? formData.powerSupply.energy_supply_types : null,
      disabled: false,
    });
    const transformerStationsValidation = new FormControl({
      value: formData ? formData.powerSupply.transformer_stations : null,
      disabled: false,
    });
    const transformerStationsDocumentsValidation = new FormControl({
      value: formData
        ? formData.powerSupply.transformer_stations_documents
        : null,
      disabled: false,
    });
    const ownerValidation = new FormControl({
      value: formData ? formData.powerSupply.owner : null,
      disabled: false,
    });
    const multipleFeedSwitchableValidation = new FormControl({
      value: formData ? formData.powerSupply.multiple_feed_switchable : null,
      disabled: formData
        ? formData.powerSupply.multiple_feed == false ||
          formData.powerSupply.multiple_feed == ''
          ? true
          : false
        : true,
    });
    const externalPowerSupplyValidation = new FormControl({
      value: formData ? formData.powerSupply.external_power_supply : null,
      disabled: false,
    });
    const multipleFeedValidation = new FormControl({
      value: formData ? formData.powerSupply.multiple_feed : null,
      disabled: false,
    });
    const batteryChargingStationValidation = new FormControl({
      value: formData ? formData.powerSupply.battery_charging_station : null,
      disabled: false,
    });
    const batteryChargingStationsDocumentsValidation = new FormControl({
      value: formData
        ? formData.powerSupply.battery_charging_stations_documents
        : null,
      disabled: false,
    });

    const isEmergencyPowerSupplyValidation = new FormControl({
      value: formData ? formData.powerSupply.is_emergency_power_supply : false,
      disabled: false,
    });
    const emergencyGeneratorValidation = new FormControl({
      value: formData
        ? formData.powerSupply.emergency_power_supply.emergency_generator
        : null,
      disabled: false,
    });
    const emergencyGeneratorAreaValidation = new FormControl({
      value: formData
        ? formData.powerSupply.emergency_power_supply.emergency_generator_area
        : null,
      disabled: formData
        ? formData.powerSupply.emergency_power_supply.emergency_generator ==
            false ||
          formData.powerSupply.emergency_power_supply.emergency_generator == ''
          ? true
          : false
        : true,
    });
    const emergencyGeneratorMinutesValidation = new FormControl({
      value: formData
        ? this._currencyCustomPipe
            .transform(
              formData.powerSupply.emergency_power_supply
                .emergency_generator_minutes
            )
            ?.replace('€', '')
            ?.split(',')[0]
            ?.trim()
        : null,
      disabled: formData
        ? formData.powerSupply.emergency_power_supply.emergency_generator ==
            false ||
          formData.powerSupply.emergency_power_supply.emergency_generator == ''
          ? true
          : false
        : true,
    });
    const emergencyGeneratorMinutesDropdownValidation = new FormControl({
      value: formData
        ? formData.powerSupply.emergency_power_supply
            .emergency_generator_hourMinute
        : null,
      disabled: formData
        ? formData.powerSupply.emergency_power_supply.emergency_generator ==
            false ||
          formData.powerSupply.emergency_power_supply.emergency_generator == ''
          ? true
          : false
        : true,
    });
    const backupPowerSystemValidation = new FormControl({
      value: formData
        ? formData.powerSupply.emergency_power_supply.backup_power_system
        : null,
      disabled: false,
    });
    const backupPowerSystemAreaValidation = new FormControl({
      value: formData
        ? formData.powerSupply.emergency_power_supply.backup_power_system_area
        : null,
      disabled: formData
        ? formData.powerSupply.emergency_power_supply.backup_power_system ==
            false ||
          formData.powerSupply.emergency_power_supply.backup_power_system == ''
          ? true
          : false
        : true,
    });
    const backupPowerSystemMinutesValidation = new FormControl({
      value: formData
        ? this._currencyCustomPipe
            .transform(
              formData.powerSupply.emergency_power_supply
                .backup_power_system_minutes
            )
            ?.replace('€', '')
            ?.split(',')[0]
            ?.trim()
        : null,
      disabled: formData
        ? formData.powerSupply.emergency_power_supply.backup_power_system ==
            false ||
          formData.powerSupply.emergency_power_supply.backup_power_system == ''
          ? true
          : false
        : true,
    });
    const backupPowerSystemMinutesDropdownValidation = new FormControl({
      value: formData
        ? formData.powerSupply.emergency_power_supply
            .backup_power_system_hourMinute
        : null,
      disabled: formData
        ? formData.powerSupply.emergency_power_supply.backup_power_system ==
            false ||
          formData.powerSupply.emergency_power_supply.backup_power_system == ''
          ? true
          : false
        : true,
    });

    const isCompressorsValidation = new FormControl({
      value: formData ? formData.powerSupply.is_compressors : false,
      disabled: false,
    });
    const spatialSeperatedValidation = new FormControl({
      value: formData
        ? formData.powerSupply.compressor.spatial_seperated
        : null,
      disabled: false,
    });
    const switchToFireAlarmValidation = new FormControl({
      value: formData
        ? formData.powerSupply.compressor.switch_to_fire_alarm
        : null,
      disabled: false,
    });
    const typeOfCompressorValidation = new FormControl({
      value: formData
        ? formData.powerSupply.compressor.type_of_compressor
        : null,
      disabled: false,
    });
    const numberOfCompressorsValidation = new FormControl({
      value: formData
        ? formData.powerSupply.compressor.number_of_compressors
        : null,
      disabled: false,
    });
    const regularMaintananceValidation = new FormControl({
      value: formData
        ? formData.powerSupply.compressor.regular_maintanance
        : null,
      disabled: false,
    });
    const regularMaintananceByWhomValidation = new FormControl({
      value: formData
        ? formData.powerSupply.compressor.regular_maintanance_by_whom
        : null,
      disabled: formData
        ? formData.powerSupply.compressor.regular_maintanance == false ||
          formData.powerSupply.compressor.regular_maintanance == ''
          ? true
          : false
        : true,
    });
    const annotations = new FormControl({
      value: formData ? formData.powerSupply.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.powerSupply.is_annotation : null,
      disabled: false,
    });
    this.powerSupplyForm = new FormGroup({
      energy_supply_types: energySupplyTypeValidation,
      transformer_stations: transformerStationsValidation,
      transformer_stations_documents: transformerStationsDocumentsValidation,
      owner: ownerValidation,
      multiple_feed_switchable: multipleFeedSwitchableValidation,
      external_power_supply: externalPowerSupplyValidation,
      multiple_feed: multipleFeedValidation,
      battery_charging_station: batteryChargingStationValidation,
      battery_charging_stations_documents:
        batteryChargingStationsDocumentsValidation,

      is_emergency_power_supply: isEmergencyPowerSupplyValidation,
      emergency_generator: emergencyGeneratorValidation,
      emergency_generator_area: emergencyGeneratorAreaValidation,
      emergency_generator_minutes: emergencyGeneratorMinutesValidation,
      emergency_generator_hourMinute:
        emergencyGeneratorMinutesDropdownValidation,
      backup_power_system: backupPowerSystemValidation,
      backup_power_system_area: backupPowerSystemAreaValidation,
      backup_power_system_minutes: backupPowerSystemMinutesValidation,
      backup_power_system_hourMinute:
        backupPowerSystemMinutesDropdownValidation,

      is_compressors: isCompressorsValidation,
      spatial_seperated: spatialSeperatedValidation,
      switch_to_fire_alarm: switchToFireAlarmValidation,
      type_of_compressor: typeOfCompressorValidation,
      number_of_compressors: numberOfCompressorsValidation,
      regular_maintanance: regularMaintananceValidation,
      regular_maintanance_by_whom: regularMaintananceByWhomValidation,
      annotations,
      is_annotation,
    });
    this.isFormValueUpdated = false;
    this.powerSupplyForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.powerSupplyForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
    this.getDocumentUploadData(record_id);
  }

  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.PS_BATTERY_CHARGING_STATIONS
              ) {
                this.selectedBatteryChargingStationsDocumentsArray[
                  document.document_name
                ] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.PS_TRANSFORMER_STATION
              ) {
                this.selectedTransferSupplyDocumentsArray[
                  document.document_name
                ] = document;
              }
            });
            if (data.documents) {
              this.powerSupplyForm.controls.transformer_stations_documents.setValue(
                Object.keys(this.selectedTransferSupplyDocumentsArray).join(
                  ', '
                )
              );
              this.powerSupplyForm.controls.battery_charging_stations_documents.setValue(
                Object.keys(
                  this.selectedBatteryChargingStationsDocumentsArray
                ).join(', ')
              );
            }
          }
        });
    }
  }

  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  emergencyGeneratorChange(value: boolean) {
    if (value) {
      this.powerSupplyForm.get('emergency_generator_area').enable();
      this.powerSupplyForm.get('emergency_generator_minutes').enable();
      this.powerSupplyForm.get('emergency_generator_hourMinute').enable();
    } else {
      this.powerSupplyForm.get('emergency_generator_area').disable();
      this.powerSupplyForm.get('emergency_generator_minutes').disable();
      this.powerSupplyForm.get('emergency_generator_hourMinute').disable();
      this.powerSupplyForm.controls.emergency_generator_area.setValue(null);
      this.powerSupplyForm.controls.emergency_generator_minutes.setValue(null);
      this.powerSupplyForm.controls.emergency_generator_hourMinute.setValue(
        null
      );
    }
  }
  backupChange(value: boolean) {
    if (value) {
      this.powerSupplyForm.get('backup_power_system_area').enable();
      this.powerSupplyForm.get('backup_power_system_minutes').enable();
      this.powerSupplyForm.get('backup_power_system_hourMinute').enable();
    } else {
      this.powerSupplyForm.get('backup_power_system_area').disable();
      this.powerSupplyForm.get('backup_power_system_minutes').disable();
      this.powerSupplyForm.get('backup_power_system_hourMinute').disable();
      this.powerSupplyForm.controls.backup_power_system_area.setValue(null);
      this.powerSupplyForm.controls.backup_power_system_minutes.setValue(null);
      this.powerSupplyForm.controls.backup_power_system_hourMinute.setValue(
        null
      );
    }
  }
  regularMaintananceChange(value: boolean) {
    if (value) {
      this.powerSupplyForm.get('regular_maintanance_by_whom').enable();
    } else {
      this.powerSupplyForm.get('regular_maintanance_by_whom').disable();
      this.powerSupplyForm.controls.regular_maintanance_by_whom.setValue(null);
    }
  }
  powerSupplyChange(value) {
    if (value) {
      this.powerSupplyForm.get('multiple_feed_switchable').enable();
    } else {
      this.powerSupplyForm.get('multiple_feed_switchable').disable();
      this.powerSupplyForm.controls.multiple_feed_switchable.setValue(null);
    }
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  uploadTransferStationDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedTransferSupplyDocumentsArray,
    };
    this.showDocumentTransferSupplyPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.PS_TRANSFORMER_STATION,
    };
  }
  uploadBatteryChargingStationDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedBatteryChargingStationsDocumentsArray,
    };
    this.showDocumentBatteryChargingStationsPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.PS_BATTERY_CHARGING_STATIONS,
    };
  }
  showDocumentPopup() {
    return (
      this.showDocumentBatteryChargingStationsPopup ||
      this.showDocumentTransferSupplyPopup
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showDocumentTransferSupplyPopup) {
      this.sdDocsIds = [];
      this.selectedTransferSupplyDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentTransferSupplyPopup = false;
      this.powerSupplyForm.controls.transformer_stations_documents.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.powerSupplyForm.controls.transformer_stations_documents.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.sdDocsIds.push(selectedDocumentsArray[name].upload_document_id);
        }
      });
    }
    if (this.showDocumentBatteryChargingStationsPopup) {
      this.bcsdDocsIds = [];
      this.selectedBatteryChargingStationsDocumentsArray = {
        ...selectedDocumentsArray,
      };
      this.showDocumentBatteryChargingStationsPopup = false;
      this.powerSupplyForm.controls.battery_charging_stations_documents.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.powerSupplyForm.controls.battery_charging_stations_documents.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.bcsdDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.powerSupplyForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }

  buildFormObject() {
    const emergencyPowerSupply = {
      emergency_generator: this.powerSupplyForm.value.emergency_generator,
      emergency_generator_area:
        this.powerSupplyForm.value.emergency_generator_area,
      emergency_generator_minutes: this.powerSupplyForm.value
        .emergency_generator_minutes
        ? Number(
            this.validation.removeSymbols(
              this.powerSupplyForm.value.emergency_generator_minutes
            )
          )
        : null,
      // this.powerSupplyForm.value["emergency_generator_minutes"],
      emergency_generator_hourMinute:
        this.powerSupplyForm.value.emergency_generator_hourMinute,
      backup_power_system: this.powerSupplyForm.value.backup_power_system,
      backup_power_system_area:
        this.powerSupplyForm.value.backup_power_system_area,
      backup_power_system_minutes: this.powerSupplyForm.value
        .backup_power_system_minutes
        ? Number(
            this.validation.removeSymbols(
              this.powerSupplyForm.value.backup_power_system_minutes
            )
          )
        : null,
      // this.powerSupplyForm.value["backup_power_system_minutes"],
      backup_power_system_hourMinute:
        this.powerSupplyForm.value.backup_power_system_hourMinute,
    };

    const compressor = {
      spatial_seperated: this.powerSupplyForm.value.spatial_seperated
        ? this.powerSupplyForm.value.spatial_seperated
        : null,
      switch_to_fire_alarm: this.powerSupplyForm.value.switch_to_fire_alarm,
      type_of_compressor: this.powerSupplyForm.value.type_of_compressor
        ? this.powerSupplyForm.value.type_of_compressor
        : null,
      number_of_compressors: this.powerSupplyForm.value.number_of_compressors,
      regular_maintanance: this.powerSupplyForm.value.regular_maintanance,
      regular_maintanance_by_whom: this.powerSupplyForm.value
        .regular_maintanance_by_whom
        ? this.powerSupplyForm.value.regular_maintanance_by_whom
        : null,
    };

    const powerSupply = {
      energy_supply_types: this.powerSupplyForm.value.energy_supply_types,
      transformer_stations: this.powerSupplyForm.value.transformer_stations,
      transformer_stations_documents:
        this.powerSupplyForm.value.transformer_stations_documents,
      owner: this.powerSupplyForm.value.owner,
      multiple_feed_switchable:
        this.powerSupplyForm.value.multiple_feed_switchable,
      external_power_supply: this.powerSupplyForm.value.external_power_supply
        ? this.powerSupplyForm.value.external_power_supply
        : null,
      multiple_feed: this.powerSupplyForm.value.multiple_feed,
      battery_charging_station:
        this.powerSupplyForm.value.battery_charging_station,
      battery_charging_stations_documents: this.powerSupplyForm.value
        .battery_charging_stations_documents
        ? this.powerSupplyForm.value.battery_charging_stations_documents
        : null,
      is_emergency_power_supply:
        this.powerSupplyForm.value.is_emergency_power_supply,
      emergency_power_supply: emergencyPowerSupply,
      is_compressors: this.powerSupplyForm.value.is_compressors,
      compressor,
      annotations: this.powerSupplyForm.value.annotations,
      is_annotation: this.powerSupplyForm.value.is_annotation,
    };

    const formValuesToPersist = {
      powerSupply,
    };

    this.request = {
      document_ids: [...new Set([...this.bcsdDocsIds, ...this.sdDocsIds])],
      criteria_id: BuildingConstants.POWER_SUPPLY,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.powerSupplyForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
