import { Component, OnInit } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { CustomerManagementSharedService } from '../customer-management-shared-service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'app-bank-account-details-page',
  templateUrl: './bank-account-details-page.component.html',
  styleUrls: ['./bank-account-details-page.component.scss'],
})
export class BankAccountDetailsPageComponent
  extends BaseComponent
  implements OnInit
{
  editBankId: string;
  bankAddEdit: boolean;
  add_customer: boolean;
  constructor(
    private readonly _toastService: ToastService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchOnAddBankOperation();
  }

  showMessage(message: string): void {
    this._toastService.showToastMessage(document, message);
  }

  isBankAddEdit(event: boolean): void {
    this.bankAddEdit = event;
  }

  getBankId(event: string): void {
    this.editBankId = event;
  }

  isBankAdd(event: boolean): void {
    this.add_customer = event;
  }

  private _watchOnAddBankOperation(): void {
    this._customerManagementSharedService.addBank$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.editBankId = null;
        this.bankAddEdit = true;
        this.add_customer = true;
      });
  }
}
