<div class="update-heading">
    <h2 matDialogTitle class="mat-headline center-align heading">
       {{'update-user.invite_new_user' | translate}}
    </h2>
    <button class="close-button" style="background: transparent;" (click)="onNoClick()">
        <img alt=""   style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
    </button>
</div>
<mat-dialog-content>
    <div>
        <input value="{{full_name}}" class="staticInput" disabled>
        <input value="{{customer}}-{{customerType | uppercase}}" class="staticInput" disabled style="margin-left: 2%;">
    </div>
    <div style="margin-top: 10px;">
        <img alt="" src="../../../../assets/images/info-sign.svg" class="infoImage">
        <span class="infoMessage"> {{'invite-user.SelectWidget_text' | translate}}</span>
    </div>
</mat-dialog-content>

<p class="static-heading">{{'invite-user.WidgetVerwaltung_text' | translate}}</p>
<mat-dialog-content>
   <div class="widget-section" >
        <div class="col-md-6 col-sm-6 col-6" style="padding:0px;padding-right:5px;">
            <div class="widget-section-heading">
                {{'invite-user.DeaktivierteWidgets_text' | translate}}
            </div>
            <div *ngFor="let widget of widgets$ | async" class="widget-section-card">
                <div class="col-md-8 col-sm-10 col-10" style="padding:0px;">
                    <input [value]="widget.name" disabled class="widget-section-input">
                </div>
                <div class="col-md-4 col-sm-2 col-2" style="padding-left: 0px;">
                    <button class="addRemove" (click)="changeWidgetSelection(widget.id, true)">+</button>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-6" style="padding:0px;padding-left:5px">
            <div class="widget-section-heading" >
                 {{'invite-user.AusgewählteWidget_text' | translate}}
            </div>
            <div *ngFor="let widget of selectedWidgets$ | async" class="widget-section-card">
                <div class="col-md-8 col-sm-10 col-10" style="padding:0px;">
                    <input [value]="widget.name" disabled class="widget-section-input">
                </div>
                <div class="col-md-4 col-sm-2 col-2" style="padding-left: 0px;">
                    <button class="addRemove" (click)="changeWidgetSelection(widget.id, false)">-</button>
                </div>
            </div>
        </div>
   </div>
</mat-dialog-content>
<mat-dialog-content>
    <div style="display: flex;justify-content: center;">
        <button class="navButton" (click)="onOneClick()">1</button>
        <hr class="horizontal">
        <button class="navButton" (click)="backClick()">2</button>
        <hr class="horizontal">
        <button class="navButton" style="border: 1px solid #ff9000;box-shadow: 0 0 0 2px #fff inset;">3</button>
        <hr class="horizontal-half">
        <button class="navButton" style="background-color: #dddddd;" (click)="onSaveClick()">4</button>
    </div>
</mat-dialog-content>
<hr class="hr" style="margin-bottom: 0px;">

<mat-dialog-actions class="save-container mt-0">
    <button class="custom-btn cancel-btn" (click)="backClick()">{{'invite-user.Zuruck_text' | translate}}</button>
    <button class="custom-btn save-btn" (click)="onSaveClick()">{{'update-user.Next_text' | translate}}</button>
</mat-dialog-actions>
