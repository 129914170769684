import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { ClaimsShareService } from '../claims-share.service';

@Component({
  templateUrl: './claim-payment-details-page.component.html',
})
export class ClaimPaymentDetailsPageComponent
  extends BaseComponent
  implements OnInit
{
  paymentId: string;
  sendMessage: string;
  addPaymentDetails = false;

  constructor(private readonly _claimsSharedService: ClaimsShareService) {
    super();
  }

  ngOnInit(): void {
    this._watchOnAddingPaymentEvent();
  }

  getPaymentId(event: string): void {
    this.paymentId = event;
  }

  getMessage(event: string): void {
    this.sendMessage = event;
  }

  isPaymentAdd(value: boolean): void {
    this.addPaymentDetails = value;
  }

  private _watchOnAddingPaymentEvent(): void {
    this._claimsSharedService.addPayment$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => (this.addPaymentDetails = true));
  }
}
