export const COLUMN_NAMES = {
    NAME               : 'name',
    ROLE               : 'role',
    MAIN_CONTACT_PERSON: 'mainContactPerson',
    COMMENT            : 'comment',
    ACTION             : 'action'
};

export const CP_COLUMN_HEADINGS = {
    NAME               : 'new-claims.contact_person.name',
    ROLE               : 'customer-management.contact_person.role',
    MAIN_CONTACT_PERSON: 'customer-management.contact_person.main_contact_person',
    COMMENT            : 'customer-management.contact_person.comments',
    ACTION             : 'new-claims.contact_person.action'
};

export const MULTILINGUAL_CONSTS = {
    CP                : 'customer-management.contact_person.contact_person',
    SELECT_ROLE       : 'new-claims.contact_person.select_role',
    RESET_TEXT        : 'contact-manager.Reset_Text',
    EDIT_TOOLTIP      : 'product-management.EDIT_text',
    DELETE_TOOLTIP    : 'user-data.Löschen_tooltip',
    // delete confirmation
    DELETE_HEADING    : 'objectModule.Delete_popup_heading',
    DELETE_SUB_HEADING: 'objectModule.Delete_popup_text', // form_generator.delete_form_text
    CANCEL            : 'zurs-geo.cancel_label',
    NO                : 'contact-manager.NEIN_text',
    SUCCESS           : 'contact-manager.JAL‎ÖSCHEN_text'
};

export const COLUMNS = [
    COLUMN_NAMES.NAME,
    COLUMN_NAMES.ROLE,
    COLUMN_NAMES.MAIN_CONTACT_PERSON,
    COLUMN_NAMES.COMMENT,
    COLUMN_NAMES.ACTION
];

export const CONTACT_PERSON_ACTION_METHOD_NAMES = {
    EDIT_CONTACT_PERSON  : 'editContactPerson',
    DELETE_CONTACT_PERSON: 'deleteContactPerson'
};
