<app-filter-section
    *ngIf="filters"
    [filters]="filters"
    (load)="changeFilter($event)"
    (pageSizeChanged)="changePageSize($event)">
</app-filter-section>

<div class="ic-table" [style.max-height]="tableConfig.styles?.maxHeight">
  <mat-table class="ic-table__table" matSort [dataSource]="dataSource">
    <ng-container matColumnDef="expand">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.parentId; else actual">
          <mat-icon>subdirectory_arrow_right</mat-icon>
        </ng-container>
        <ng-template #actual>
          <button
            class="action-btn arrow-size"
            *ngIf="element.children?.length && !element.parentId"
            (click)="switchChildren(element)"
          >
            <mat-icon class="arrowButtons">
              {{ element.isOpened ? "keyboard_arrow_down" : "keyboard_arrow_right" }}
            </mat-icon>
          </button>
        </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox
          class="custom-checkBox"
          [checked]="element.selected"
          (click)="onSelectChange($event, element)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container *ngFor="let column of tableConfig.columnDetails" [matColumnDef]="column.name">
      <ng-container *ngIf="column.sortable; else noSort">
        <mat-header-cell *matHeaderCellDef [mat-sort-header]="column.sortProperty || (column.name + sortSuffix)" [style.max-width.px]="column.maxWidth">
          <span [matTooltip]="column.title" class="ellipsis">
            {{ column.title | uppercase }}
          </span>
        </mat-header-cell>
      </ng-container>
      <ng-template #noSort>
        <mat-header-cell *matHeaderCellDef [style.max-width.px]="column.maxWidth">
          <span [matTooltip]="column.title" class="ellipsis">
            {{ column.title | uppercase }}
          </span>
        </mat-header-cell>
      </ng-template>
      <mat-cell *matCellDef="let element" [style.max-width.px]="column.maxWidth">
        <ng-container [ngSwitch]="column.type">
          <span *ngSwitchCase="columnTypes.STRING" [matTooltip]="element[column.name]" class="ellipsis">
            {{ element[column.name] }}
          </span>
          <ng-container *ngSwitchCase="columnTypes.COMPONENT">
            <ng-container dynamicCell [component]="column.component" [inputs]="{ element: element }"></ng-container>
          </ng-container>
          <span *ngSwitchCase="columnTypes.ACTION">
            <app-table-actions *ngIf="element[column.name]?.length" [actions]="element[column.name]" (actionClicked)="clickAction($event, element)"></app-table-actions>
          </span>
          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableConfig.displayColumns; sticky: true"></mat-header-row>
    <mat-row
      (click)="clickRow(row)"
      [class.ic-table__row--clickable]="isRowClickable"
      *matRowDef="let row; columns: this.tableConfig.displayColumns"></mat-row>
  </mat-table>
</div>

<app-pagination [pagination]="pagination" (pageChanged)="changePage($event)"></app-pagination>
