export const UploadTypeConstants = {
  SIDE_LETTERS: 'Auswahl_der_Seitenbriefe',
  AVB: 'Hinterlegter_Satz_von_Konditionen',
  RVB: 'Reihe_von_Bedingungen',
  A: 'Annahmerichtlinien',
  IZD: 'Informationen_zum_Datenschutz',
  Mandate: 'customer_mandate',
  SEPA_MANDATE: 'sepa_mandate_document',
};

export const StepperLookupConstants = {
  GENERAL_INFORMATION: 1,
  PRODUCT_MODULE: 2,
  COVER_BUNDLE: 3,
  COVERINGS: 4,
  TYPES_OF_DAMAGE: 5,
  MASK_GENERATOR: 6,
  SUMMARY: 7,
};
export const LOOKUP = {
  COVERING_TYPE: 'Deckungs-Typ',
};
