import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AddBankDetailsRequest,
  getBankDetailsResponse,
} from '../../../../components/insurer-management/models/bank-details.models';
import { BANK_DETAILS_FORM_FIELDS } from '../../../../components/insurer-management/models/bank-details.constants';
import {
  ARROW,
  ERRORS,
  STATIC_TEXT,
} from '../../../constants/string.constants';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';
import { UploadObjectDetails } from '../../../models/generic.model';
import { BankDetailsApiService } from '@core/services/httpcalls/bank-details-api.service';
import { ShowErrorService } from '@core/services/show-error.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { USER_ROLES } from '@shared/constants/roles.constants';
import * as moment from 'moment';
import { UploadTypeConstants } from '../../../constants/product-management.constants';
import { MarkReadService } from '@core/services/mark-read.service';
import { ToastService } from '@core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ADD_INSURER_MAPPING_MULTILINGUAL } from '../../../../components/insurer-management/models/mutlilingual.constants';
import { UserService } from '@core/user.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'genric-add-bank-details',
  templateUrl: './genric-add-bank-details.component.html',
  styleUrls: ['./genric-add-bank-details.component.css'],
})
export class GenricAddBankDetailsComponent
  extends BaseComponent
  implements OnInit
{
  @Input() sourceId: number;
  @Input() bankDetailsId: number;
  @Output() isBankDetailsAddEdit = new EventEmitter<any>();
  @Output() message = new EventEmitter<any>();
  @Input() sourceType;
  bankDetailsForm: FormGroup;
  bankDetailsData: getBankDetailsResponse;
  initialFormState: any;
  isValueChangedInForm = false;
  formFieldsName = BANK_DETAILS_FORM_FIELDS;
  errorConstant = ERRORS;
  formImages = FORM_ICONS;
  arrows = ARROW;

  showSepaMandateDocumentPopup = false;
  uploadDocumentDetails: UploadObjectDetails;
  selectedSEPADocumentsArray: any = {};
  selectedDocumentsArray = {};
  sepaDocumentIds = [];
  is_disable = false;

  today = new Date();

  user_role: string;
  is_IC_USER = false;
  user_id: number;

  documentsTempNames = '';

  constructor(
    private _bankAPIService: BankDetailsApiService,
    public _showError: ShowErrorService,
    private _apiCallsService: ApiCallsService,
    public validation: ValidationService,
    private _markReadService: MarkReadService,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _userService: UserService
  ) {
    super();

    this._userService.loggedInUser$
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.user_role = data.user_profile.user_data.role;
        this.user_id = data.user_profile.user_data.user_id;
        this.is_IC_USER = this.user_role === USER_ROLES.IC_USER;
      });
  }

  ngOnInit(): void {
    this.getAPICalls();
  }

  getAPICalls() {
    if (this.bankDetailsId) {
      this._bankAPIService
        .getBankDetailsById(this.bankDetailsId, this.sourceType)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: bankDetailsResult => {
            this.bankDetailsData = bankDetailsResult.bankDetails;
            this.initializeForm();
          },
        });
    } else {
      this.initializeForm();
    }
  }

  initializeForm() {
    const formData = this.bankDetailsData || null;
    this.bankDetailsForm = new FormGroup({
      [BANK_DETAILS_FORM_FIELDS.DESCRIPTION]: new FormControl(
        (formData && formData.description) || STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.BANK_NAME]: new FormControl(
        (formData && formData.bankName) || STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.IBAN]: new FormControl(
        (formData && formData.iban) || STATIC_TEXT.EMPTY_STRING,
        [Validators.required]
      ),
      [BANK_DETAILS_FORM_FIELDS.BIC]: new FormControl(
        (formData && formData.bic) || STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.STANDARD_BANK_ACCOUNT]: new FormControl(
        formData ? formData.standardBankConnection : STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.DIFFERING_ACCOUNT_HOLDER]: new FormControl(
        (formData && formData.differingAccountHolder) ||
          STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE]: new FormControl(
        formData ? formData.sepaMandate : STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DATE]: new FormControl(
        (formData &&
          formData.sepaMandateDate &&
          moment(formData.sepaMandateDate)?.format(
            STATIC_TEXT.DATE_FORMAT_YY_MM_DD
          )) ||
          STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_REFERENCE]: new FormControl(
        (formData && formData.sepaMandateReference) || STATIC_TEXT.EMPTY_STRING
      ),
      [BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DOCUMENT]: new FormControl(
        (formData && formData.uploadDocumentId) || null
      ),
      [BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT]: new FormControl(
        (formData && formData.uploadDocumentId) || null
      ),

      sepaMandateDocument: new FormControl({
        value: formData ? formData.uploadDocumentId : STATIC_TEXT.EMPTY_STRING,
        disabled: formData ? !formData.sepaMandate : true,
      }),
    });
    this.sepaMandateChange(
      this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE].value
    );
    if (this.bankDetailsId) {
      this.getDocumentUploadData(this.bankDetailsId);
    }
    this.initialFormState = this.bankDetailsForm.value;
    this.bankDetailsForm?.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (
          this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE].value
        ) {
          this.initialFormState[BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT] =
            this.documentsTempNames;
        }
        this.isValueChangedInForm =
          JSON.stringify(this.initialFormState) !=
          JSON.stringify(this.bankDetailsForm.value);
      });

    if (this.is_IC_USER) {
      this.bankDetailsForm.disable();
      this.isValueChangedInForm = false;
    }
  }

  prepareSaveData(): AddBankDetailsRequest {
    const addBankDetailsRequest: AddBankDetailsRequest = {
      id: (this.bankDetailsData && this.bankDetailsData.id) || null,
      liztuVnKdnr: null,
      bankType: null,
      bankName:
        this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.BANK_NAME].value,
      iban: this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.IBAN].value,
      bic: this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.BIC].value,
      customerId: null,
      standardBankConnection:
        this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.STANDARD_BANK_ACCOUNT]
          .value,
      differingAccountHolder:
        this.bankDetailControls[
          BANK_DETAILS_FORM_FIELDS.DIFFERING_ACCOUNT_HOLDER
        ].value,
      sepaMandate:
        this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE].value,
      sepaMandateDate:
        (this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DATE]
          .value &&
          moment(
            this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DATE]
              .value
          )?.format(STATIC_TEXT.DATE_FORMAT_YY_MM_DD)) ||
        STATIC_TEXT.EMPTY_STRING,
      validFrom: null,
      sepaMandateReference:
        this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_REFERENCE]
          .value,
      uploadDocumentId: this.sepaDocumentIds,
      isIvm: false,
      description:
        this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.DESCRIPTION].value,
      sourceId: this.sourceId || null,
      sourceType: this.sourceType,
      updatedBy: this.user_id,
    };
    return addBankDetailsRequest;
  }

  checkFormValid() {
    return this.bankDetailsForm.invalid;
  }

  saveBankDetails() {
    if (this.checkFormValid()) {
      this._markReadService.markAllFieldsAsTouched(this.bankDetailsForm);
      this._toastService.showToastMessage(
        document,
        this._translateService.instant(
          ADD_INSURER_MAPPING_MULTILINGUAL.FILL_REQUIRED_FIELDS
        )
      );
      return;
    }
    const request = this.prepareSaveData();
    this._bankAPIService
      .addBankDetails(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: addBankResponse => {
          this.message.emit(addBankResponse.message);
          this.isBankDetailsAddEdit.emit(false);
          this.bankDetailsData = null;
        },
      });
  }

  get bankDetailControls() {
    return this.bankDetailsForm.controls;
  }

  sepaMandateChange(value) {
    if (value) {
      this.bankDetailsForm
        .get(BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DATE)
        .enable();
      this.bankDetailsForm
        .get(BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_REFERENCE)
        .enable();
      this.bankDetailsForm
        .get(BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DOCUMENT)
        .enable();
      this.bankDetailsForm.get(BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT).enable();
    } else {
      this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT].reset();
      this.bankDetailControls[
        BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DOCUMENT
      ].reset();
      this.bankDetailControls[
        BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DATE
      ].reset();
      this.bankDetailControls[
        BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_REFERENCE
      ].reset();
      this.bankDetailsForm
        .get(BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DATE)
        .disable();
      this.bankDetailsForm
        .get(BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_REFERENCE)
        .disable();
      this.bankDetailsForm
        .get(BANK_DETAILS_FORM_FIELDS.SEPA_MANDATE_DOCUMENT)
        .disable();
      this.bankDetailsForm
        .get(BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT)
        .disable();
    }
  }

  uploadSepaDocument() {
    this.selectedDocumentsArray = { ...this.selectedSEPADocumentsArray };
    this.uploadDocumentDetails = {
      documentSource: 'bank_account_details',
      documentType: UploadTypeConstants.SEPA_MANDATE,
    };
    this.showSepaMandateDocumentPopup = true;
  }

  getDocumentUploadData(record_id) {
    if (record_id) {
      this._apiCallsService
        .getDocumentPhotovoltik(record_id, STATIC_TEXT.EMPTY_STRING)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents?.forEach(document => {
              document.is_obj_linked = true;
              this.selectedSEPADocumentsArray[document.document_name] =
                document;
            });
            if (data.documents) {
              this.documentsTempNames = Object.keys(
                this.selectedSEPADocumentsArray
              ).join(', ');
              this.bankDetailControls[
                BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT
              ].setValue(
                Object.keys(this.selectedSEPADocumentsArray).join(', ')
              );
            }
          }
          if (this.is_IC_USER) {
            this.bankDetailsForm.disable();
            this.isValueChangedInForm = false;
          }
        });
    }
  }

  showDocumentPopup(): boolean {
    return this.showSepaMandateDocumentPopup;
  }

  hideDocumentPopup(selectedDocumentsArray) {
    this.sepaDocumentIds = [];
    this.selectedSEPADocumentsArray = { ...this.selectedDocumentsArray };
    this.showSepaMandateDocumentPopup = false;
    this.bankDetailControls[BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT].setValue(
      Object.keys(selectedDocumentsArray).join(', ')
    );
    this.bankDetailControls[
      BANK_DETAILS_FORM_FIELDS.SEPA_DOCUMENT
    ].markAsTouched();
    Object.keys(selectedDocumentsArray).forEach(name => {
      if (selectedDocumentsArray[name].upload_document_id) {
        this.sepaDocumentIds.push(
          selectedDocumentsArray[name].upload_document_id
        );
      }
    });
    this.selectedDocumentsArray = {};
  }
}
