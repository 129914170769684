<app-header (emitUserData)="userDataReceived($event)"></app-header>
<div class="container-ic customer-management">
    <app-breadcrumbs 
        [isCustomerUser]="isCustomerUser" 
        [activeLable]="activeLabel"
        isCustomerSelected="true" 
        [pageBreadcrumbText]="pageBreadcrumbText"
        [divisionName]="divisionName" 
        [customerName]="customerName$ | async">
    </app-breadcrumbs>
    <div class="flex-center margin-top-10">
        <div class="d-flex action-bar-container flex-grow-1 gap-10">
            <app-action-bar
                (actionClick)="actionBarClick($event)"
                [leftActions]="defaultActions"
                [rightActions]="extraActions"></app-action-bar>
        </div>
        <mat-slide-toggle 
            [formControl]="ivm_sync"
            labelPosition="before" 
            (change)="changeState($event.checked)"
            class="toggle-button" style="float:right">
            {{'new-contract.general_information.enableForm' | translate}}
        </mat-slide-toggle>
    </div>
    <div style="margin-top: 10px">
        <label class="label, text-danger"
            [hidden]="state_warning_ivm_hidden">{{'new-contract.general_information.lockStateIVM' | translate}}</label>
        <label class="label, text-warning"
            [hidden]="state_warning_protect_hidden">{{'new-contract.general_information.lockStateProtect' |
            translate}}</label>
    </div>
    <mat-sidenav-container class="sidenav-container" (keydown.escape)="$event.stopPropagation()" style="height: auto;">
        <mat-sidenav [ngClass]="{'mat-sidenav--opened': sideNavState}" #leftSidenav mode="side" opened
            (keydown.escape)="$event.stopPropagation()">
            <div class="sidenav_container" [@onSideNavChange]="sideNavState ? 'open' : 'close'"
                (keydown.escape)="$event.stopPropagation()">
                <div class="d-flex flex-column" style="height: 100%;"
                    (keydown.escape)="$event.stopPropagation()">
                    <ul ngbNav #nav="ngbNav" (keydown.escape)="$event.stopPropagation()" [activeId]="active"
                        class="nav-pills" orientation="vertical" (navChange)="tabChange($event)">
                        <li [ngClass]="{'disabled-sideNav' : tab.disabled}" 
                            (click)="navigateToTab(tab.name)" 
                            [ngbNavItem]="tab.tab" 
                            *ngFor="let tab of leftTabs">
                            <a ngbNavLink [class.disabled]="tab.disabled">
                                <span class="left-element flex-center">
                                    <img alt="" title="{{tab.sourceTitle}}" [src]="tab.img">
                                    <span [@animateText]="linkText ? 'show' : 'hide'">{{tab.sourceTitle}}</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div class="sideNav-btn">
                        <button mat-icon-button (click)="onSinenavToggle()" (keydown.escape)="$event.stopPropagation()"
                            style="background: #ff9000;">
                            <mat-icon>{{sideNavState ? 'arrow_left' : 'arrow_right'}}</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-sidenav>
        <div class="sidenav-container__content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-container>
</div>
<app-upload-document-popup></app-upload-document-popup>
<div class="modal" [style.display]="showDocTemplateModal ? 'table' : 'none'">
    <div class="modal-dialog modal-dialog-new">
        <div class="modal-content">
            <div class="modal-header modal-header-new">
                <span>{{'new-contract.general_information.create_doc' | translate}}</span>
                <button class="close-icon-btn" (click)="closeDocModal()">
                    <mat-icon class="close-icon">close</mat-icon>
                </button>
            </div>
            <div class="modal-body modal-body-new">
                <span class="span-label"> {{'new-contract.general_information.available_template' | translate}}</span>
                <mat-select (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')"
                    placeholder="{{'objectModule.select_lable' | translate}}" id="doc_temp"
                    [(ngModel)]="selected_template" (openedChange)="search.value = ''"
                    (selectionChange)="selectedTemplate($event)">
                    <input type="text" maxlength="20" id="searchSelect" class="searchSelect"
                        placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                    <mat-option *ngFor="let item of avaliable_templates" [value]="item.resourceUri"
                        title="{{item.resourceName}}">
                        {{item.resourceName}}
                    </mat-option>
                </mat-select><br />
                <br />
                <span class="span-label"> {{'new-contract.general_information.docDescrition' | translate}}</span><br />
                <input id="docDesc" matInput
                    placeholder="{{'new-contract.general_information.docDescrition' | translate}}"
                    [formControl]="docDescription">
            </div>
            <div class="modal-footer ml-auto mr-auto modal-footer-new">
                <button type="button" class="save-button-popup" mat-button [disabled]="!selected_template"
                    (click)="saveDocTemplate(false)">
                    {{'new-contract.general_information.create_doc_button' | translate}}
                </button>
                <button type="button" class="ml-2 save-button-popup" mat-button [disabled]="!selected_template"
                    (click)="saveDocTemplate(true)">
                    {{'new-contract.general_information.create_save_doc' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
<custom-pdf-viewer *ngIf="showPDFViewer" [pdfLink]="pdfLink" [pdfName]="pdfName" via="policyTemplate" [blob]="blob"
    (onClosePdf)="onClosePdf($event)"></custom-pdf-viewer>
