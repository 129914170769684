<div style="padding: 0px 10px;">
    <div class="flex-end-align">
        <!-- button-process new-group-button-form -->
        <button class="custom-btn save-btn add-new-feature-btn" (click)="addNewForm()" [disabled]="IC_USER">
            <img alt="" [src]="FORM_ICONS.ADD">
            <span>{{'form_generator.configure_new_form' | translate}}</span>
        </button>
    </div>

    <div class="paginator-dropdown-container" (click)="$event.stopPropagation()">
        <mat-select (selectionChange)="numberOfRowsChanged($event)" panelClass="rowChange"
            class="paginator-dropdown filter-section-dropdown" [(ngModel)]="selectedCountOfRows" disableOptionCentering>
            <mat-option *ngFor="let row of numberOfRows" [value]="row">
                {{row}}
            </mat-option>
        </mat-select>
        <span class="results-per-page">
            {{'claims.results_per_page' | translate}}
        </span>
    </div>

    <div class="table-outer-container insured-object-section">
        <div class="infiniteScrollDiv">
            <mat-table class="custom-table" style="table-layout: fixed" mat-table [dataSource]="dataSource"
                cellpadding="5" cellspacing="0" matSort #formGenerator="matSort" #formTable>
                <ng-container matColumnDef="form_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'form_generator.form_name' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell class="custom-checkbox-container" *matCellDef="let element">
                        <div style="display: flex; align-items: baseline;">
                            <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.name}}" matTooltipClass="my-tooltip"
                                style="margin-top: 4px;">
                                {{element.name}}
                            </span>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="form_description" style="width:30%">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'form_generator.form_description' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="padding: 8px 5px;">
                        <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.description}}" matTooltipClass="my-tooltip">
                            {{element.description}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="usage_status">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'form_generator.usage_status' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="padding: 8px 5px;">
                        <span class="custom-ellipsis noSafariTooltip"
                            matTooltip="{{(element.is_in_use ? 'form_generator.in_use' : 'form_generator.not_in_use') | translate}}">
                            {{(element.is_in_use ? "form_generator.in_use" : "form_generator.not_in_use") | translate}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="created_at" style="padding: 8px 5px;">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span>{{'product-management.Created_by' | translate}}
                            <img class="arrow-image" src="../../../../assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip">
                            {{element.created_by}}<br />
                            <span class="table-below-text">{{element.created_at | dateFormat:'dd.MM.yyyy HH:mm'}}</span>
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="updated_at" style="padding: 8px 5px;">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span>{{'product-management.Last_updated_by' | translate}}
                            <img class="arrow-image" src="../../../../assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip">
                            {{element.updated_by}}<br />
                            <span class="table-below-text">{{element.updated_at | dateFormat:'dd.MM.yyyy HH:mm'}}</span>
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef style="text-align: center; width: 130px;">
                        <span>{{'user-data.AKTION_text' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="form-generator"
                        style="text-align: center;word-break: initial">
                        <div class="control-section" style="position: initial;">
                            <div class="subscribe" style="position: initial">
                                <div class="hidecontent democontent"
                                    style="position: initial;margin-top: -11px;text-align: center;">
                                    <button class="tooltipEdit" mat-icon-button
                                        (click)="editForm(element.form_details_id,element.created_by_role)">
                                        <img alt="" style="width: 100%;height: 100%;" class="imgStyle"
                                            src='../../../assets/images/edit_contract_icon.svg'>
                                        <span class="tooltipEditText">{{'product-management.EDIT_text' |
                                            translate}}</span>
                                    </button>
                                    <span style="padding-top: 2px;"
                                        ngbTooltip="{{(element.is_in_use ? 'form_generator.form_in_use' : '') | translate}}"
                                        placement="bottom" tooltipClass="my-tooltip">
                                        <button class="tooltipDelete" mat-icon-button
                                            (click)="openModal(element.form_details_id)"
                                            [disabled]="element.is_in_use || IC_USER">
                                            <img alt="" height="19px"
                                                src="../../../../assets/images/new-icons/Delete icon.svg" />
                                            <span *ngIf="!element.is_in_use"
                                                class="tooltipDeleteText">{{'user-data.Löschen_tooltip' |
                                                translate}}</span>
                                        </button>
                                    </span>
                                    <div ngbTooltip="{{(element.is_active ? '' : 'form_generator.activate_for_future_use') | translate }}"
                                        placement="bottom" class="toggleAction" tooltipClass="my-tooltip">
                                        <mat-slide-toggle [checked]="element.is_active" [disabled]="IC_USER"
                                            (change)="toggleForm($event, element.form_details_id,element)">
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
        <div class="table-footer" id="contractsFooter">
            <div class="paginator-footer">
                <ngb-pagination [collectionSize]="totalElements" (pageChange)="tablePageChanged($event)" [maxSize]=5
                    [(page)]="tablePage" [pageSize]="selectedCountOfRows" [boundaryLinks]="false">
                    <ng-template ngbPaginationPrevious>
                        <i class="fa fa-angle-left"></i>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <i class="fa fa-angle-right"></i>
                    </ng-template>
                </ngb-pagination>
                <div class="footer-text">
                    <span>
                        {{ "form_generator.number_of_forms" | translate }} : {{ totalElements }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal modelBackground" [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog spacing">
        <div class="modal-content p-3"> 
            <div class="d-flex header-content p-unset">
                <div class="pointer">
                    <img (click)="hideModal()" alt="" height="65px" src="../../../assets/images/Group 3235.svg">
                </div>
            </div>
            <div class="modal-body body-text">
                <span class="delete-heading">{{'objectModule.Delete_popup_heading' | translate}}</span>
                <br /><br />
                <span class="delete-text">{{'form_generator.delete_form_text' | translate}}</span>
            </div>
            <div class="modal-footer ml-auto mr-auto">
                <button (click)="hideModal()" class="custom-btn cancel-btn">
                    {{'zurs-geo.cancel_label' | translate}}</button>
                <button class="ml-2 custom-btn save-btn" (click)="deleteForm(delete_item_id)">
                    {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal modelBackground" [style.display]="showConfirmPopup ? 'table' : 'none'">
    <div class="modal-dialog spacing">
        <div class="modal-content p-3">
            <div class="d-flex header-content p-unset">
                <div class="pointer">
                    <img alt="" (click)="closeConfirmPopup()" height="65px" src="../../../assets/images/Group 3235.svg">
                </div>
            </div>
            <div class="modal-body body-text">
                <span class="delete-heading">{{'objectModule.Delete_popup_heading' | translate}}</span>
                <br /><br />
                <span class="delete-text">{{'form_generator.confirm_message' | translate}}</span>
            </div>
            <div class="modal-footer ml-auto mr-auto">
                <button (click)="closeConfirmPopup()" class="custom-btn cancel-btn">
                    {{'zurs-geo.cancel_label' | translate}}</button>
                <button class="ml-2 custom-btn save-btn" (click)="saveConfirm()">
                    {{'form_generator.deactivate' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>