export const LocationConstants = {
    BASIC_LOCATION_DETAILS : 21,
    ADDITIONAL_GENERAL_INFORMATION : 22,
    ORGANIZATIONAL_FIRE_PROTECTION : 23,
    PROTECTION_OF_THE_FACTORY_PREMISES : 24,
    OTHER_INCREASES_IN_RISK : 25
};

export const UploadTypeConstants = {
    OFP_ORDER_CLEANLINESS: 'ofp_order_cleanliness',
    OFP_USE_OF_HOT_PERMIT: 'ofp_use_of_hot_permit',
    AGI_SITE_PLAN : 'agi_site_plan',
    AGI_FIRE_DEPARTMENT_PLAN : 'agi_fire_department_plan',
    POTFP_CONSTANT_GAS_SURVEILLANCE : 'potfp_constant_gas_surveillance',
    POTFP_TERRAIN_CLEAR: 'potfp_terrain_clear',
    POTFP_OUTER_WALLS_BUILDING: 'potfp_outer_walls_building',
    POTFP_ILLUMINATION_OF_PERMISES: 'potfp_illumination_of_permises',
    POTFP_CLIMB_OVER_PROTECTION: 'potfp_climb_over_protection',
    POTFP_ACCESS_CONTROL: 'potfp_access_control'
};
