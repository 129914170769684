import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { REMINDER_LABELS, ReminderValues } from '../../../constants/reminder.constants';

@Component({
    selector: 'app-reminder',
    template: `<span class="table-below-text">{{labels[element.reminderInMinutesBeforeStart] | translate}}</span>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReminderComponent {
    @Input() element: { reminderInMinutesBeforeStart: ReminderValues };
    labels = REMINDER_LABELS;
}
