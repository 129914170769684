<form class="row object-module-html" [formGroup]="photovolaticForm" id="photovolaticForm">
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label">{{'objectModule.newObjecttype' | translate}}</label>
      <input type="text" maxlength="100" class="input" formControlName="object_name" title="{{photovolaticForm.controls['object_name'].value}}" placeholder="{{'objectModule.newObjecttype' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label"> {{'objectModule.LaufendeNummer' | translate}}</label>
      <input  *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" numbersOnly maxlength="5" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)"  class="input numberText" formControlName="sequenceNumber" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
      <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*" numbersOnly (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)"   class="input numberText" formControlName="sequenceNumber" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label">
        {{'objectModule.Bezeichnung' | translate}}</label>
      <input type="text" maxlength="100" class="input" title="{{photovolaticForm.controls['description'].value}}" formControlName="description" placeholder="{{'objectModule.Bezeichnung' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label">
        {{'objectModule.Hersteller' | translate}}</label>
      <input  type="text" class="input" maxlength="100" title="{{photovolaticForm.controls['manufacturer'].value}}" formControlName="manufacturer" placeholder="{{'objectModule.Hersteller' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label">
          {{'objectModule.Module_Manufacturer' | translate}}</label>
      <input  type="text" class="input" maxlength="100" formControlName="modelManufacturer" title="{{photovolaticForm.controls['modelManufacturer'].value}}" placeholder="{{'objectModule.Module_Manufacturer' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label">
          {{'objectModule.Inverter_Manufacturer' | translate}}</label>
      <input  type="text" class="input" maxlength="100" title="{{photovolaticForm.controls['inverterManufacturer'].value}}" formControlName="inverterManufacturer" placeholder="{{'objectModule.Inverter_Manufacturer' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label">
        {{'objectModule.Modell/Typ' | translate}}</label>
      <input type="text" class="input" maxlength="100" title="{{photovolaticForm.controls['model'].value}}" formControlName="model" placeholder="{{'objectModule.Modell/Typ' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <div class="smallInput" style="width: 100%;">                <label for=""  *ngIf="!errorPowerNewValue && !errorPowerNewValue1" class="label"> {{'objectModule.Power' | translate}}</label>
      <label title="{{'objectModule.limiterrorPower' | translate}}" class="touched-warning ellipsis"
      *ngIf="errorPowerNewValue || errorPowerNewValue1"><span [ngStyle]="{'text-transform' : selectLang === 'English(EN)' ? 'capitalize' : 'inherit'}">
        {{'objectModule.Power' | translate}}</span> : {{'objectModule.limiterrorPower' | translate}}"</label>
            <div style="display: flex;">
              <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="100"
              (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
              class="input numberText" formControlName="power" placeholder="{{'objectModule.Power' | translate}}"
              (blur)="changePowerNumber($event.target.value, 'power');"
               style="border-bottom-right-radius: 0px;
              border-top-right-radius: 0px;">
              <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="100"
              class="input numberText" formControlName="power" placeholder="{{'objectModule.Power' | translate}}" (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
              (blur)="changePowerNumber($event.target.value, 'power');"
               style="border-bottom-right-radius: 0px;
              border-top-right-radius: 0px;">
              <mat-select id="powerUnitStyle" class="dropdownStyle switcherDrpdwn inputy switcher powerUnitStyle ellipsis"
              placeholder="{{'objectModule.select_lable' | translate}}" formControlName="powerUnit">
              <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
              <mat-option *ngFor="let ele of powerUnit" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
            </mat-select>
          </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label"> {{'objectModule.Installation_Location' | translate}}</label>
          <mat-select class="dropdownStyle switcherDrpdwn inputy switcher"
            placeholder="{{'objectModule.select_lable' | translate}}" formControlName="intallationLocation">
            <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
            <mat-option *ngFor="let ele of installtionLocation" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
          </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label"> {{'objectModule.Baujahr' | translate}}</label>
        <mat-select (click)="scroll()" class="dropdownStyle switcherDrpdwn inputy switcher"
         (selectionChange)="yearSelectedCon()" (click)="focusManufacturerSearch()" (keyup.enter)="focusManufacturerSearch()"
          placeholder="{{'objectModule.select_lable' | translate}}" formControlName="constructionYear" #manufacturerDD>
          <input type="text" id="manufactureSearch" maxlength="4" class="searchYear" placeholder="{{'header.Suche_tooltip' | translate}}" matInput [formControl]="yearControl1">
          <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
          <mat-option *ngFor="let year of yearsCon" [ngClass]="{'active-option': year === currentYear && !photovolaticForm.value.constructionYear}" [value]="year" >{{year}}</mat-option>
        </mat-select>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" *ngIf="!errorWert" class="label">{{'objectModule.wert_371' | translate}}</label>
    <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
      *ngIf="errorWert"><span [ngStyle]="{'text-transform' : selectLang === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'objectModule.wert_371' | translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
      <input  *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text"
      (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
      (blur)="calculateNewValue(photovolaticForm ,$event);changeNumber($event.target.value, 'wert371')"
      class="input numberText" formControlName="wert_371" placeholder="{{'objectModule.wert_371' | translate}}">
      <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" (paste)="onTypeNewValue($event, 'paste')"
       (keypress)="onTypeNewValue($event, 'keypress')"  class="input numberText" formControlName="wert_371"
      (blur)="calculateNewValue(photovolaticForm ,$event);changeNumber($event.target.value, 'wert371')"
      placeholder="{{'objectModule.wert_371' | translate}}">
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <div class="smallInput" style="width: 100%;">
        <label for=""  *ngIf="!errorNewValue && !errorNewValue1" class="label"> {{'objectModule.Neuwert' | translate}}</label>
        <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
        *ngIf="errorNewValue || errorNewValue1"><span [ngStyle]="{'text-transform' : selectLang === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'objectModule.Neuwert' | translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
        <div style="display: flex;">
        <mat-select id="currencyDropdown" (selectionChange)="currancyChanged($event)"
          (click)="focusCurrencySearch()" (keyup.enter)="focusCurrencySearch()"
          class="dropdownStyle currencyDrop currecnyStyle" formControlName="currency_type_id"
          [disableOptionCentering]="true" title="{{selectedCurrency}}"
          panelClass="currencyTypeDropdown"
          #currencyDD
          >
          <mat-select-trigger>
              <span>{{currancySymbol}}</span>
            </mat-select-trigger>
          <input type="text" id="currencySearch" class="searchYear" maxlength="5" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
              [formControl]="currencyType">
          <mat-option *ngFor="let ele of currencyTypes" (click)="resetList()" [value]="ele.id" matTooltip="{{ele.cur_symbol}}" >
              <span style="display: flex;">
                  <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                  <span style="justify-content: end;">{{ele.cur_symbol}}</span>
              </span>
          </mat-option>
      </mat-select>
        <input  type="text" style="border-radius: 0px;height: 38px;" maxlength="100"
        *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input newValue"
        (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
        (blur)="changeNumber($event.target.value, 'newValue'); calculateValue($event)"
        formControlName="newValue" placeholder="{{'objectModule.Neuwert' | translate}}">
        <input  type="text" style="border-radius: 0px;
        height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"
        (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
        (blur)="changeNumber($event.target.value, 'newValue'); calculateValue($event)" formControlName="newValue" placeholder="{{'objectModule.Neuwert' | translate}}">
        <mat-select id="valueDropdown" (click)="scrollNewValue()" (keyup.enter)="scrollNewValue()"
          class="dropdownStyle switcherDrpdwn inputy switcher valueTypeStyle ellipsis" (selectionChange)="yearSelected(); yearChange($event.value)"
          placeholder="{{'objectModule.select_lable' | translate}}" formControlName="newValuePerYear" #newValueDD>
          <input type="tex t" maxlength="4" id="newValueSearch" class="searchYear" placeholder="{{'header.Suche_tooltip' | translate}}" matInput [formControl]="yearControl">
          <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
          <mat-option *ngFor="let year of years" [ngClass]="{'active-option': year === currentYear && !photovolaticForm.value.newValuePerYear}" [value]="year" >{{year}}</mat-option>
        </mat-select>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label"> {{'objectModule.Anzahl' | translate}}</label>
      <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*"
          (paste)="validation.SequenceNumberValidationBrowserSpecific($event, 'paste',5)"
          (keypress)="validation.SequenceNumberValidationBrowserSpecific($event, 'keydown',5)"
          class="input numberText" formControlName="count" placeholder="{{'objectModule.Anzahl' | translate}}" (blur)="validation.ValidationPowerAndCount(photovolaticForm, $event)"
          (focus)="validation.ValidationPowerAndCount(photovolaticForm, $event)"
          numbersOnly>
          <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="5"
          class="input numberText" formControlName="count" placeholder="{{'objectModule.Anzahl' | translate}}" (keydown)="validation.SequenceNumberValidation($event, 'keydown',5)"
          (paste)="validation.SequenceNumberValidation($event, 'paste',5)"
          (blur)="validation.ValidationPowerAndCount(photovolaticForm, $event)" (focus)="validation.ValidationPowerAndCount(photovolaticForm, $event)"
          numbersOnly>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label"> {{'objectModule.Owner' | translate}}</label>
        <input type="text" class="input numberText" maxlength="100"  title="{{photovolaticForm.controls['owner'].value}}" formControlName="owner" placeholder="{{'objectModule.Owner' | translate}}" readonly>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <label [ngStyle]="{'text-transform' : selectLang === 'English(EN)' ? 'capitalize' : 'inherit'}" for="" class="label" title="{{'objectModule.Photovoltaic_system' | translate}}">
          {{'objectModule.Photovoltaic_system' | translate}}
      </label>
      <br>
      <mat-radio-group formControlName="eRivision">
          <mat-radio-button value="true">
              <span>
                  {{'objectModule.yes' | translate}}
              </span>
          </mat-radio-button>
          <mat-radio-button (click)="onNoclick()" value="false">
              <span>
                  {{'objectModule.no' | translate}}
              </span>
          </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <div class="smallInput" style="width: 85%;margin-right: 2%;">
          <label for="" class="label">
              {{'objectModule.Acceptance_protocol' | translate}}<br/>

            </label>
          <input class="input" title="{{photovolaticForm.controls['acceptanceProtocol'].value}}" formControlName="acceptanceProtocol" readonly placeholder="{{'objectModule.Acceptance_protocol' | translate}}">
      </div>
      <div class="smallInput addGroup" style="width:13%">
      <button class="docButton" type="button" (click)="uploadDoc()">
        <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
                            </button>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <div class="smallInput" style="width: 85%;margin-right: 2%;">
        <label for="acquisitionInvoice" class="label">
            {{'objectModule.Acquisition_invoice' | translate}}<br/>
        </label>
        <input class="input" id="acquisitionInvoice" title="{{photovolaticForm.controls['acquisitionInvoice'].value}}" formControlName="acquisitionInvoice" readonly placeholder="{{'objectModule.Acquisition_invoice' | translate}}">
      </div>
      <div class="smallInput addGroup" style="width:13%">
      <button class="docButton" type="button" (click)="uploadDoc_Acquisition()">
       <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
      </button>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
      <div class="smallInput" style="width: 85%;margin-right: 2%;">
        <label for="riskQuestionnaire" class="label">
            {{'objectModule.Risk_questionnaire' | translate}}<br/>
        </label>
        <input class="input" id="riskQuestionnaire" title="{{photovolaticForm.controls['riskQuestionnaire'].value}}" formControlName="riskQuestionnaire" readonly placeholder="{{'objectModule.Risk_questionnaire' | translate}}">
      </div>
      <div class="smallInput addGroup" style="width:13%">
      <button class="docButton" type="button" (click)="uploadDoc_Risk()">
      <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
      </button>
      </div>
    </div>
</form>
<div class="modal upload-logo dialog" [style.display]="NoclickText ? 'block' : 'none'">
  <div class="modal-dialog" style="top: 245px; display: initial; ">
    <div class="modal-content modalContent" style="width: 510px;">
      <!-- Modal Header -->
        <button type="button" class="close" style="text-align: right;" data-dismiss="modal" (click)="HideNoclick()">
          <img alt="" src="../../../../../assets/images/Group 3114.svg" >
        </button>
        <!-- Modal body -->
        <div class="modal-body" style="font-size: 15px;
        height: 94px;
        font-weight: 600;
        margin-top: -10px;">
          <div style="text-align:justify">
              {{'objectModule.eRivision_popup_text' | translate}}
          </div>
        </div>
    </div>
  </div>
</div>

<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="allselectedDocumentsArray"
  [showDocumentPopup]="showDocumentPopup || showDocumentAcquisitionPopup || showDocumentRiskPopup" (hide)="hide($event)">
</app-upload-document>
