<div class="object-module-html flex-common">
  <div class="f-14 dropdown-width">
    <label for="" class="long-label required">{{'product-management.stepper_common_msg.assign_form_to' | translate}}</label>
    <mat-select class="dropdownStyle" required [(ngModel)]="assignFormTo"
      placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="assignFormChange($event)">
        <mat-option *ngFor="let ele of dropdownData" [value]="ele.id">{{ele.desc}}</mat-option>
    </mat-select>
  </div>
  <div>
    <button [disabled]="!isFormTouched" (click)="saveForm()" class="save-btn">
      <img alt="" src="../../../../assets/images/save-button.svg">
   </button>
  </div>
</div>
<div class="external-container">
    <div class="row-containers">
        <div class="tree-container">
          <div class="container-heading">
            <span class="criteria-heading">{{'product-management.stepper_common_msg.available_form' | translate}}</span>
          </div>
          <div class="template-criteria-body">
            <div class="template-criteria-inner-body" *ngIf="dataValuesAvailable.data.length > 0">
              <div class="col col-xs-3" class="searchBox">
                <img alt="" src="../../../assets/images/ic-icons/Search.svg" class="form-control-feedback custom-search">
                <input type="text" [value]="searchTextLeft" #input class="form-control custom_search"
                  placeholder="{{'product-management.stepper_common_msg.search_from' | translate}}" aria-label="Search"
                  (keyup)="applyAvailableDataFilter($event, dataValuesAvailable)">
              </div>
              <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10" [scrollWindow]="false"
                class="infiniteScrollDiv">
                <div class="table-component-holder">
                  <mat-table class="table-responsive table table-striped documents template-table" mat-table
                    [dataSource]="dataValuesAvailable" cellpadding="5" cellspacing="0">
                    <ng-container matColumnDef="template_name">
                      <td mat-cell [attr.colspan]="12" *matCellDef="let element" class="custom-data">
                        <span class="ellipsis1 noSafariTooltip main-text" title="{{element.name}}">
                          {{element.name}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="plus_icon">
                      <td mat-cell [attr.colspan]="1" *matCellDef="let element" [ngClass]="!isDisabled ? 'custom-sign': 'custom-disabled'" (click)="addForm(element)">
                          <img alt="" height="15px" src='../../../assets/images/Icon ionic-md-close.svg'>
                      </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedAvailableColumns" class="custom-row"></tr>
                  </mat-table>
                </div>
              </div>
            </div>
          </div>
          <div class="empty-stepper-inner" *ngIf="dataValuesAvailable.data.length == 0">
            {{'product-management.stepper_common_msg.no_form_available' | translate}}
        </div>
        </div>
        <div class="form-container">
            <div class="container-heading">
                <span class="criteria-heading">{{'product-management.stepper_common_msg.selected_form' | translate}}</span>
            </div>
            <div class="template-criteria-body" *ngIf="dataValuesSelected.data.length > 0">
              <div class="template-criteria-inner-body">
                <div class="col col-xs-3" class="searchBox">
                  <img alt="" src="../../../assets/images/ic-icons/Search.svg" class="form-control-feedback custom-search">
                  <input type="text" [value]="searchTextRight" #input class="form-control custom_search"
                    placeholder="{{'product-management.stepper_common_msg.search_from' | translate}}" aria-label="Search"
                    (keyup)="applyAvailableDataFilter($event, dataValuesSelected)">
                </div>

                <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10" [scrollWindow]="false"
                  class="infiniteScrollDiv">
                  <div class="table-component-holder">
                    <mat-table class="table-responsive table table-striped documents template-table selected-template-table" mat-table #table
                      [dataSource]="dataValuesSelected" cellpadding="5" cellspacing="0" [cdkDropListDisabled]="isDisabled"
                      cdkDropList [cdkDropListData]="dataValuesSelected" (cdkDropListDropped)="drop($event)">
                      <ng-container matColumnDef="template_name">
                        <td mat-cell [attr.colspan]="12" *matCellDef="let element" class="selected-custom-data">
                          <img alt="" class="drag-btn" src="../../../assets/images/dragAndDrop.png" />
                          <span class="ellipsis1 noSafariTooltip main-text selected-text" title="{{element.name}}">
                            {{element.name}}
                          </span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="plus_icon">
                        <td  mat-cell [attr.colspan]="1" *matCellDef="let element" [ngClass]="!isDisabled ? 'custom-sign': 'custom-disabled'"   (click)="removeForm(element)">
                            <img class="custom-image" alt="" height="15px" src='../../../assets/images/minus_icon_product_module.svg'>
                        </td>
                      </ng-container>
                      <tr mat-row *matRowDef="let row; columns: displayedAvailableColumns" class="custom-row"  cdkDrag [cdkDragData]="row"></tr>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty-stepper-inner" *ngIf="dataValuesSelected.data.length == 0">
                {{'product-management.stepper_common_msg.no_form_selected' | translate}}
            </div>
        </div>
    </div>
</div>
<div id="successToast"></div>
<product-close-alert-dialog *ngIf="showWarningPopUp" [productwarnmsg]="productWarningMsg" (savedata)="saveModalValue($event)" (handlePopup)="handlePopup($event)"></product-close-alert-dialog>
