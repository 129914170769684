import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ROUTES_PATH } from '@shared/constants/router.constants';

@Component({
  selector: 'app-register-claim-step-one',
  templateUrl: './register-claim-step-one.component.html',
  styleUrls: ['./register-claim-step-one.component.css'],
})
export class RegisterClaimStepOneComponent implements OnInit {
  isdisabled = true;
  formDiv: FormGroup;
  constructor(
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formDiv = this.fb.group({
      divOne: new FormControl(''),
    });
  }

  nextOne(): void {
    this.router.navigateByUrl(ROUTES_PATH.REGISTER_CLAIM_STEP_TWO, {
      skipLocationChange: true,
    });
  }

  selectSparte(): void {
    this.isdisabled = false;
    document.getElementById('changeStyle').style.border = 'solid 1px #febc34';
    document.getElementById('changeStyle').style.boxShadow =
      '0 0 6px 0 #febc34';
    document.getElementById('icon').style.display = 'inline-block';
  }
}
