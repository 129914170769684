// import { TASK_STATUS_LABELS } from './../table/table-column/status.component';
import { Component, Input, OnInit } from '@angular/core';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { MatDialog } from '@angular/material/dialog';
import { SourceType } from '../../models/source-type.model';
// import { GuestOptionComponent } from '../select-options/guest-option/guest-option.component';
// import { PriorityOptionComponent } from '../select-options/priority-option.component';
// import { PRIORITY_OPTIONS } from '../../constants/priority.constants';
import { CreateTaskPopupComponent } from './create-task-popup/create-task-popup.component';
import {
  ColumnType,
  TABLE_ACTIONS_KEYS,
} from '../../constants/table.constants';
import {
  PaginationConfig,
  TableActionEvent,
  TableConfig,
} from '../../models/table.model';
import { TranslateService } from '@ngx-translate/core';
import {
  TASKS_COLUMNS,
  TASKS_COLUMN_HEADINGS,
  TasksColumns,
} from '../../constants/tasks.constants';
import { GuestsComponent } from '../table/table-column/guests.component';
import { CreatedByComponent } from '../table/table-column/created-by.component';
// import { UpdatedByComponent } from '../table/table-column/updated-by.component';
import { PriorityColumnComponent } from '../table/table-column/priority-column.component';
import { DueDateComponent } from '../table/table-column/due-date.component';
import {
  StatusComponent,
  // TaskStatuses,
} from '../table/table-column/status.component';
import { TaskApiService } from '@core/services/httpcalls/task-api.service';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
// import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
// import { AttendeeApiService } from '@core/services/httpcalls/attendee-api.service';
// import { iif, of } from 'rxjs';
// import { Attendee } from '../../models/attendee.model';
// import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { TASK_OFFICE_URL } from '@shared/constants/url.constants';
import { GetTasksParams, Task } from '@shared/models/task.model';

const TASKS_TABLE_HEIGHT = {
  GLOBAL: 'calc(100vh - 410px)',
  FEATURE: 'calc(100vh - 560px)',
};

// interface TaskFilterConfig {
//   dueDate?: { start: Date; end: Date };
//   division?: number;
//   assignees?: string[];
//   status?: string;
//   priority?: string;
// }

@Component({
  selector: 'app-tasks',
  templateUrl: 'tasks.component.html',
  styleUrls: ['tasks.component.scss'],
})
export class TasksComponent extends BaseComponent implements OnInit {
  @Input() source: SourceType;
  @Input() sourceId: number;
  @Input() customerId: number;
  @Input() divisionId: number;

  // filters: Filter[] = [];
  tableConfig: TableConfig;
  pagination: PaginationConfig = {
    page: 1,
    size: 25,
    total: 0,
    label: this._translateService.instant('tasks.number_of_tasks'),
  };
  data: Task[];
  // filterConfig: TaskFilterConfig;

  constructor(
    private _dialog: MatDialog,
    private _translateService: TranslateService,
    private _taskApiService: TaskApiService
    // private _apiCallsService: ApiCallsService,
    // private _attendeeApiService: AttendeeApiService
  ) {
    super();
  }

  ngOnInit() {
    this.tableConfig = this._getTableConfig();

    // this._loadFiltersData();

    this._loadTasks();
  }

  changePage(page: number): void {
    this.pagination.page = page;

    this._loadTasks();
  }

  changePageSize(size: number): void {
    this.pagination.size = size;

    this._loadTasks();
  }

  // filter(filterConfig: TaskFilterConfig): void {
  //   this.filterConfig = filterConfig;

  //   this._loadTasks();
  // }

  handleAction({ key, element }: TableActionEvent) {
    // TODO: implement actions
    switch (key) {
      case TABLE_ACTIONS_KEYS.EDIT:
        this._openTask(element.id);
        break;
      case TABLE_ACTIONS_KEYS.DELETE:
        // this._deleteTask(element.id);
        break;
      case TABLE_ACTIONS_KEYS.COMPLETE:
        // this._completeTask(element.id);
        break;
      default:
        break;
    }
  }

  openCreateTaskPopup(task: Task = null): void {
    this._dialog
      .open(CreateTaskPopupComponent, {
        data: {
          customerId: this.customerId,
          sourceId: this.sourceId,
          source: this.source,
          divisionId: this.divisionId,
          task,
        },
      })
      .afterClosed()
      .pipe(filter(Boolean), takeUntil(this._destroy$))
      .subscribe(() => {
        this._loadTasks();
      });
  }

  clickRow(element: Task): void {
    this._openTask(element.id);
  }

  private _openTask(id: string): void {
    window.open(`${TASK_OFFICE_URL}${id}`, '_blank');
  }

  // private _deleteTask(id: string): void {
  //   this._dialog
  //     .open(ConfirmPopupComponent, {
  //       data: {
  //         subHeading: this._translateService.instant(
  //           'appointments.delete_confirm'
  //         ),
  //         successBtnText: this._translateService.instant(
  //           'confirm_popup.yes_delete'
  //         ),
  //       },
  //     })
  //     .afterClosed()
  //     .pipe(
  //       filter(Boolean),
  //       mergeMap(() => this._taskApiService.deleteTask(id)),
  //       takeUntil(this._destroy$)
  //     )
  //     .subscribe(() => {
  //       this._loadTasks();
  //     });
  // }

  // private _completeTask(id: string): void {
  //   this._taskApiService
  //     .completeTask(id)
  //     .pipe(takeUntil(this._destroy$))
  //     .subscribe(() => {
  //       this._loadTasks();
  //     });
  // }

  private _loadTasks(): void {
    const params: GetTasksParams = {
      page: this.pagination.page,
      size: this.pagination.size,
    };

    this._taskApiService
      .getTasks(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.data = this._formatTableData(data.content);
        this.pagination = {
          ...this.pagination,
          total: data.totalRecords,
        };
      });
  }

  // TODO: will be needed after API is ready
  // private _getSourceParam(): { [key: string]: number } {
  //   switch (this.source) {
  //     case SourceType.CUSTOMER:
  //       return { customerId: this.sourceId };
  //     case SourceType.CLAIM:
  //       return { claimId: this.sourceId };
  //     case SourceType.CONTRACT:
  //       return { contractId: this.sourceId };
  //     case SourceType.INSURER:
  //       return { insurerId: this.sourceId };
  //     case SourceType.INTERMEDIARY:
  //       return { intermediaryId: this.sourceId };
  //     default:
  //       return {};
  //   }
  // }

  private _formatTableData(data: Task[]): Task[] {
    return data.map(item => ({
      ...item,
      guests: item.assignees.map(assignee => ({
        name: assignee.name,
        src: assignee.profilePictureUrl,
      })),
      action: [
        // {
        //   label: 'Complete',
        //   icon: ACTION_BUTTON.COMPLETE,
        //   key: TABLE_ACTIONS_KEYS.COMPLETE,
        //   disabled: item.status === TaskStatuses.COMPLETED,
        // },
        {
          label: this._translateService.instant('tasks.edit'),
          icon: ACTION_BUTTON.EDIT_WO_BACKGROUND,
          key: TABLE_ACTIONS_KEYS.EDIT,
          rowAction: true,
        },
        // {
        //   label: this._translateService.instant('table_action.delete'),
        //   icon: ACTION_BUTTON.DELETE_WO_BACKGROUND,
        //   key: TABLE_ACTIONS_KEYS.DELETE,
        // },
      ],
    }));
  }

  // private _loadFiltersData(): void {
  //   let attendees = [];

  //   this._attendeeApiService
  //     .getAttendees()
  //     .pipe(
  //       mergeMap(data => {
  //         attendees = data;

  //         return iif(
  //           () => !!this.source,
  //           of(null),
  //           this._apiCallsService.getDivisionList()
  //         );
  //       }),
  //       takeUntil(this._destroy$)
  //     )
  //     .subscribe(data => {
  //       this.filters = this._getFilters(attendees, data?.data);
  //     });
  // }

  // private _getFilters(attendees: Attendee[], divisions = []): Filter[] {
  //   return [
  //     {
  //       name: 'division',
  //       type: FilterType.SELECT,
  //       label: this._translateService.instant('appointments.division'),
  //       placeholder: this._translateService.instant('appointments.division'),
  //       options: divisions.map(division => ({
  //         label: division.desc,
  //         value: division.id,
  //       })),
  //       hidden: !divisions.length,
  //     },
  //     {
  //       name: 'priority',
  //       placeholder: 'Priority',
  //       type: FilterType.SELECT,
  //       label: 'Priority',
  //       optionComponent: PriorityOptionComponent,
  //       valueComponent: PriorityOptionComponent,
  //       options: PRIORITY_OPTIONS,
  //     },
  //     {
  //       name: 'dueDate',
  //       type: FilterType.DATE_RANGE,
  //       label: 'Due Date',
  //     },
  //     {
  //       name: 'status',
  //       placeholder: 'Status',
  //       type: FilterType.SELECT,
  //       label: 'Status',
  //       options: [
  //         {
  //           label: TASK_STATUS_LABELS[TaskStatuses.COMPLETED],
  //           value: TaskStatuses.COMPLETED,
  //         },
  //         {
  //           label: TASK_STATUS_LABELS[TaskStatuses.TODO],
  //           value: TaskStatuses.TODO,
  //         },
  //       ],
  //     },
  //     // TODO: uncomment after implemented
  //     {
  //       name: 'assignees',
  //       placeholder: 'Assigned To',
  //       type: FilterType.SELECT,
  //       isMultiple: true,
  //       label: 'Assigned To',
  //       optionComponent: GuestOptionComponent,
  //       options: attendees.map(attendee => ({
  //         label: attendee.name,
  //         value: attendee.email,
  //         data: {
  //           imgSrc: attendee.profilePictureUrl,
  //         },
  //       })),
  //     },
  //   ];
  // }

  private _getTableConfig(): TableConfig {
    const config: TableConfig = {
      displayColumns: [...TASKS_COLUMNS],
      columnDetails: [
        {
          title: this._translateService.instant(TASKS_COLUMN_HEADINGS.TITLE),
          name: TasksColumns.TITLE,
          type: ColumnType.STRING,
        },
        {
          title: this._translateService.instant(
            TASKS_COLUMN_HEADINGS.DESCRIPTION
          ),
          name: TasksColumns.DESCRIPTION,
          type: ColumnType.STRING,
        },
        {
          title: this._translateService.instant(TASKS_COLUMN_HEADINGS.PRIORITY),
          name: TasksColumns.PRIORITY,
          type: ColumnType.COMPONENT,
          component: PriorityColumnComponent,
          maxWidth: 90,
        },
        {
          title: this._translateService.instant(TASKS_COLUMN_HEADINGS.DUE_DATE),
          name: TasksColumns.DUE_DATE,
          type: ColumnType.COMPONENT,
          component: DueDateComponent,
        },
        {
          title: this._translateService.instant(
            TASKS_COLUMN_HEADINGS.ASSIGNED_TO
          ),
          name: TasksColumns.ASSIGNED_TO,
          type: ColumnType.COMPONENT,
          component: GuestsComponent,
        },
        {
          title: this._translateService.instant(TASKS_COLUMN_HEADINGS.STATUS),
          name: TasksColumns.STATUS,
          type: ColumnType.COMPONENT,
          component: StatusComponent,
        },
        {
          title: this._translateService.instant(
            TASKS_COLUMN_HEADINGS.CREATED_BY
          ),
          name: TasksColumns.CREATED_BY,
          type: ColumnType.COMPONENT,
          component: CreatedByComponent,
        },
        // {
        //   title: this._translateService.instant(
        //     TASKS_COLUMN_HEADINGS.UPDATED_BY
        //   ),
        //   name: TasksColumns.UPDATED_BY,
        //   type: ColumnType.COMPONENT,
        //   component: UpdatedByComponent,
        // },
        {
          title: this._translateService.instant(TASKS_COLUMN_HEADINGS.ACTION),
          name: TasksColumns.ACTION,
          type: ColumnType.ACTION,
        },
      ],
      styles: {
        maxHeight: this.source
          ? TASKS_TABLE_HEIGHT.FEATURE
          : TASKS_TABLE_HEIGHT.GLOBAL,
      },
    };

    // if (!this.source) {
    //   config.displayColumns.splice(4, 0, TasksColumns.DIVISION);
    //   config.columnDetails.splice(4, 0, {
    //     title: this._translateService.instant(TASKS_COLUMN_HEADINGS.DIVISION),
    //     name: TasksColumns.DIVISION,
    //     type: ColumnType.STRING,
    //   });
    // }

    return config;
  }
}
