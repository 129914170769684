import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject, SolderingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';

@Component({
  selector: 'app-soldering',
  templateUrl: './soldering.component.html',
  styleUrls: ['./soldering.component.css'],
})
export class SolderingComponent implements OnInit {
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  solderingForm: FormGroup;
  solderingTypeData = [];
  request: BuildingObject;
  constructor(private serviceCall: ApiCallsService) {
    this.serviceCall.getLookupData(26).subscribe(data => {
      this.solderingTypeData = data.data;
    });
  }
  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[BuildingConstants.SOLDERING] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.SOLDERING]
          .saved_criteria
      );
    const solderingValidation = new FormControl({
      value: formData ? formData.soldering.soldering : null,
      disabled: false,
    });
    const safetyTemperatureValidation = new FormControl({
      value: formData ? formData.soldering.safety_temperature : null,
      disabled: false,
    });
    const suctionValidation = new FormControl({
      value: formData ? formData.soldering.suction : null,
      disabled: false,
    });
    const Automatic_Soldering_Line = new FormControl({
      value: formData ? formData.soldering.Automatic_Soldering_Line : null,
      disabled: false,
    });
    const annotations = new FormControl({
      value: formData ? formData.soldering.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.soldering.is_annotation : null,
      disabled: false,
    });
    this.solderingForm = new FormGroup({
      soldering: solderingValidation,
      safety_temperature: safetyTemperatureValidation,
      suction: suctionValidation,
      Automatic_Soldering_Line,
      annotations,
      is_annotation,
    });
    this.isFormValueUpdated = false;
    this.solderingForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.solderingForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }

  buildFormObject() {
    const soldering: SolderingObject = {
      soldering: this.solderingForm.value.soldering
        ? this.solderingForm.value.soldering
        : null,
      safety_temperature: this.solderingForm.value.safety_temperature,
      suction: this.solderingForm.value.suction,
      Automatic_Soldering_Line:
        this.solderingForm.value.Automatic_Soldering_Line,
      annotations: this.solderingForm.value.annotations,
      is_annotation: this.solderingForm.value.is_annotation,
    };
    const formValuesToPersist = {
      soldering,
    };
    this.request = {
      criteria_id: BuildingConstants.SOLDERING,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.solderingForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
