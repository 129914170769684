import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyCustom',
})
export class CurrencyCustomPipe implements PipeTransform {
  constructor(private _currencyPipe: CurrencyPipe) {}

  transform(
    value: number | string | null | undefined,
    currencyCode = 'EUR',
    display = '',
    digitsInfo = '1.2-2',
    locale?: string
  ): string | null {
    return this._currencyPipe.transform(
      value?.toString().replace(/\,/g, '.'),
      currencyCode,
      display,
      digitsInfo,
      locale
    );
  }
}
