export const getInitials = (name: string): string => {
    if (!name) {
        return '';
    }

    let names = name.split(' ');

    if (names.length <= 1) {
        names = name.split('.');
    }

    return names.length === 1
        ? names[0][0].toUpperCase()
        : `${names[0][0].toUpperCase()}${names[1][0].toUpperCase()}`;
};
