import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilterSelectOption } from '../table/filter-section/filter-section.component';

@Component({
    selector: 'app-priority-option',
    template: '<app-priority [priority]="option.value"></app-priority>',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PriorityOptionComponent {
    @Input() option: FilterSelectOption;
}