import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[decimalNumber]'
})
export class DecimalDirective {
  @Input('decimals') decimals = 0;
  @Input('negative') negative = 0;
  @Input('separator') separator = '.';

  private checkAllowNegative(value: string): RegExpMatchArray {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^-?\d+$/));
    } else {
      const regExpString =
        '^-?\\s*((\\d+(\\' + this.separator + '\\d{0,' +
        this.decimals +
        '})?)|((\\d*(\\' + this.separator + '\\d{1,' +
        this.decimals +
        '}))))\\s*$';
      return String(value).match(new RegExp(regExpString));
    }
  }

  private check(value: string): RegExpMatchArray {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      const regExpString =
        '^\\s*((\\d+(\\' + this.separator + '\\d{0,' +
        this.decimals +
        '})?)|((\\d*(\\' + this.separator + '\\d{1,' +
        this.decimals +
        '}))))\\s*$';
      return String(value).match(new RegExp(regExpString));
    }
  }

  private run(oldValue) {
      const currentValue: string = this.el.nativeElement.value;
      const allowNegative = this.negative > 0;

      if (allowNegative) {
        if (
          !['', '-'].includes(currentValue) &&
          !this.checkAllowNegative(currentValue)
        ) {
          this.el.nativeElement.value = oldValue;
        }
      } else {
        if (currentValue !== '' && !this.check(currentValue)) {
          this.el.nativeElement.value = oldValue;
        }
      }
  }

  constructor(private el: ElementRef) {}

  @HostListener('keydown')
  onKeyDown() {
    this.run(this.el.nativeElement.value);
  }

  @HostListener('paste')
  onPaste() {
    this.run(this.el.nativeElement.value);
  }
}
