const FOLDER_CONST = {
    HEADER: 'assets/images/header/',
};

export const HEADER_ICONS = {
    // home.svg`,
    HOME: `${FOLDER_CONST.HEADER}dashboard.svg`,
    HOME_ACTIVE: `${FOLDER_CONST.HEADER}home_active.svg`,
    CUSTOMER_MANAGEMENT: `${FOLDER_CONST.HEADER}master.svg`,
    CUSTOMER_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}master_active.svg`,
    DOCUMENT_MANAGEMENT: `${FOLDER_CONST.HEADER}document.png`,
    DOCUMENT_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}document_active.svg`,
    CONTRACT_MANAGEMENT: `${FOLDER_CONST.HEADER}contract.svg`,
    CONTRACT_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}contract_active.svg`,
    OBJECT_MANAGEMENT: `${FOLDER_CONST.HEADER}object.svg`,
    OBJECT_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}object_active.svg`,
    CLAIMS_MANAGEMENT: `${FOLDER_CONST.HEADER}claim.svg`,
    CLAIMS_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}claim_active.svg`,
    INUSRER_MANAGEMENT: `${FOLDER_CONST.HEADER}insurer_management.svg`,
    INUSRER_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}insurer_management_active.svg`,
    SEARCH: `${FOLDER_CONST.HEADER}search.svg`,
    INFO_DOCUMENT: `${FOLDER_CONST.HEADER}supporting_document.svg`,
    SETTINGS: `${FOLDER_CONST.HEADER}setting.svg`,
    SETTINGS_ACTIVE: `${FOLDER_CONST.HEADER}setting_active.svg`,
    RCS_SETUP: `${FOLDER_CONST.HEADER}rcs_setup.svg`,
    PROFILE: `${FOLDER_CONST.HEADER}profile.svg`,
    LOGOUT: `${FOLDER_CONST.HEADER}logout.svg`,
    TREE: `${FOLDER_CONST.HEADER}tree.svg`,
    IC_LOGO: `${FOLDER_CONST.HEADER}insure_core_logo.png`,
    DOCUMENT_MANAGEMENT_DISABLED: `${FOLDER_CONST.HEADER}documents_disabled.svg`,
    CONTRACT_MANAGEMENT_DISABLED: `${FOLDER_CONST.HEADER}contracts_disabled.svg`,
    CLAIMS_MANAGEMENT_DISABLED: `${FOLDER_CONST.HEADER}claims_disabled.svg`,
    OBJECT_MANAGEMENT_DISABLED: `${FOLDER_CONST.HEADER}object_disabled.svg`,
    INTERMEDIARY_MANAGEMENT: `${FOLDER_CONST.HEADER}intermediary_management.svg`,
    INTERMEDIARY_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}intermediary_management_active.svg`,
    TODO_MANAGEMENT: `${FOLDER_CONST.HEADER}todo-default.svg`,
    TODO_MANAGEMENT_ACTIVE: `${FOLDER_CONST.HEADER}todo-active.svg`,
};
