import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';
import {
  CreateTaskBody,
  GetTasksParams,
  GetTasksResponse,
} from '@shared/models/task.model';

@Injectable({ providedIn: 'root' })
export class TaskApiService {
  constructor(private _http: HttpClient) {}

  getTasks(requestParams: GetTasksParams): Observable<GetTasksResponse> {
    let params = new HttpParams();

    Object.keys(requestParams).forEach(key => {
      params = params.append(key, requestParams[key]);
    });

    return this._http.get<GetTasksResponse>(
      `${Api_URL.IC_API_URL}${Api.TASKS}`,
      { params }
    );
  }

  createTask(body: CreateTaskBody): Observable<void> {
    return this._http.post<void>(`${Api_URL.IC_API_URL}${Api.TASKS}`, body);
  }
}
