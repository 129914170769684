<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic">
  <div class="flex-common">
    <app-breadcrumbs [isCustomerUser]="isCustomerUser" [isCustomerSelected]="isCustomerSelected"
      [pageBreadcrumbText]="pageBreadcrumbText" [divisionName]="divisionName" [customerName]="customerName$ | async">
    </app-breadcrumbs>
    <button *ngIf="!isCustomerUser" (click)="onNewContract()"
      class="custom-btn save-btn add-new-feature-btn">
      <img [src]="formIcons.ADD" alt="">
      <span>{{'new-contract.add_new_button' | translate}}</span>
    </button>
  </div>

  <div class="filter-distance">
      <mat-accordion>
        <mat-expansion-panel class="filter-section" (opened)="customerFilterToggle = true"
          (closed)="customerFilterToggle = false" [expanded]="customerFilterToggle" hideToggle>
          <mat-expansion-panel-header class="filter-section-header p-unset">
            <mat-panel-title class="filter-section-title-alignment filter-title">
              <div class="flex-center g-7">
                <button class="action-btn">
                  <mat-icon *ngIf="customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="!customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_right</mat-icon>
                </button>
                <span><strong>FILTER</strong></span>
              </div>
              <div class="paginator-dropdown-container"
                (click)="$event.stopPropagation()">
                <mat-select (selectionChange)="numberOfRowsChanged()" panelClass="rowChange"
                  class="paginator-dropdown filter-section-dropdown" [(ngModel)]="selectedCountOfRows"
                  disableOptionCentering>
                  <mat-option *ngFor="let row of numberOfRows" [value]="row">
                    {{row}}
                  </mat-option>
                </mat-select>
                <span class="results-per-page">
                  {{'claims.results_per_page' | translate}}
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="formFilter" class="flex-common f-13" >
            <div class="d-flex gap-3 my-2">
              <div class="vertically-center g-7">
                <mat-checkbox formControlName="KfzVerträge" class="custom-checkBox check1">
                  {{ "contracts.Kfz-Verträge_text" | translate }}
                </mat-checkbox>
              </div>
              <div class="vertically-center g-7" *ngIf="isICUser && showWholeGroupCheck">
                <mat-checkbox (click)="showWholeGroup()" class="custom-checkBox check1" [checked]="uncheck"
                  formControlName="wholeGroup">
                  {{ "contracts.Gruppe_anzeigen" | translate }}
                </mat-checkbox>
              </div>
              <div class="vertically-center g-7" *ngIf="isCustomerUser && showWholeGroupCheck">
                <mat-select [(value)]="statusValues"
                  placeholder="{{'contracts.filter_by_status' | translate}}"
                  disableOptionCentering panelClass="statusMultiSelectPane"
                  multiple>
                  <mat-option *ngFor="let element of statusAvailable" class="status-class hide-checkbox"
                    (click)="addStatusFilter(element)">
                    <span class="flex-center g-7">
                      <mat-checkbox class="custom-checkBox"
                        [(checked)]="element.isSelected" [value]="element.isSelected" disabled>
                      </mat-checkbox>
                      <span>{{element.label}}</span>
                    </span>
                  </mat-option>
                </mat-select>
              </div>
              <div class="vertically-center g-7" *ngIf="!isCustomerUser || (!showWholeGroupCheck && isCustomerUser)">
                <mat-select
                  placeholder="{{'contracts.filter_by_status' | translate}}" class="dropdown-style"
                  [(value)]="statusValues"
                  multiple panelClass="statusMultiSelectPane"
                  disableOptionCentering (openedChange)="search.value = ''">
                  <input type="text" maxlength="20" class="searchSelect" preventSpace
                    placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                  <mat-option [hidden]="element.hide"
                    class="hide-checkbox"
                    *ngFor="let element of validationService._filterMultiSelect(statusAvailable, 'label', search.value)"
                    (click)="addStatusFilter(element)">
                    <span class="flex-center g-7">
                      <mat-checkbox class="custom-checkBox" [(checked)]="element.isSelected" [value]="element.isSelected" disabled>
                      </mat-checkbox>
                      <span>{{element.label}}</span>
                    </span>
                  </mat-option>
                </mat-select>
              </div>
              <div class="vertically-center g-7" *ngIf="encoded_contract !== null && isCustomerUser">
                <div class="contract-number-field" *ngIf="showContract">
                  <span class="bold-text f-13">
                    {{ "contracts.By_Contracts" | translate }} : {{ contract_number }}
                  </span>
                  <button class="action-btn" (click)="resetContract()">
                    <mat-icon style="font-size: 16px;height:0px;line-height: 0.6;">
                      close
                    </mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-center filter-btn-gap">
              <button class="filterBtn" type="submit" (click)="getFormsValue(formFilter.value)">
                {{'user-data.Filter_text' | translate}}
              </button>
              <button *ngIf="isICUser"
                placement="top" tooltipClass="generic-tooltip"
                ngbTooltip="{{'contracts.export_excel' | translate}}"
                class="filterBtn"
                (click)="exportExcelData(formFilter.value)"
                [class.disabled]="(contractexport && contractexport.length == 0) || dataSource.data.length == 0 || (isFilterApplied && dataSource.filteredData.length == 0)">
                <img alt="" src="assets/images/ic-icons/Export to Excel.svg">
            </button>
            <button class="filterBtn" (click)="resetFilters()"
              placement="top" tooltipClass="generic-tooltip"
              ngbTooltip="{{'new-document.Filter_löschen' | translate}}">
              <img alt="" height="16px" src="assets/images/Icon material-refresh.png" />
            </button>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
  </div>

  <div class="table-outer-container">
    <div class="infiniteScrollTable" style="max-height: calc(100vh - 395px);">
      <mat-table class="table custom-table contracts__table" matSort [dataSource]="dataSource">
        <ng-container matColumnDef="expand">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.parentId; else actual">
              <mat-icon>subdirectory_arrow_right</mat-icon>
            </ng-container>
            <ng-template #actual>
              <button
                (click)="switchChildContracts(element)"
                *ngIf="element.childContracts?.length && !element.parentId"
                class="action-btn arrow-size"
              >
                <mat-icon class="arrowButtons">{{
                  element.isOpened
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_right"
                }}</mat-icon>
              </button>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef style="width: 55px"
            [ngStyle]="{'display' : isCustomerUser ? 'none' : ''}"></mat-header-cell>
          <mat-cell
            [ngStyle]="{'display' : isCustomerUser ? 'none' : ''}" *matCellDef="let element">
            <span class="pill byIvm w-unset" style="padding: unset;" *ngIf="element.is_source_ivm">ERP</span>
            <span class="pill byRcs" *ngIf="!element.is_source_ivm">ICP</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="productName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span [ngStyle]="{'padding-left' : isCustomerUser ? '13px' : 'inherit'}">{{ "contracts.PRODUKT_text" | translate }}
            </span>
          </mat-header-cell>
          <mat-cell
            [ngStyle]="{'padding-left' : isCustomerUser ? '13px' : '4px'}" *matCellDef="let element"
            title="{{ element.product_name }}">
            <span class="custom-ellipsis noSafariTooltip"> {{ element.product_name }}</span>
          </mat-cell>
          <br />
        </ng-container>
        <ng-container matColumnDef="policyNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-150">
            {{ "contracts.VERTRAGSNUMMER_text" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-150"  title="{{ element.contract_number }}">
            <span class="custom-ellipsis noSafariTooltip"> {{ element.contract_number }} </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="insurer">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "contracts.VERSICHERER_text" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" title="{{ element.insurer }}">
            <span class="custom-ellipsis noSafariTooltip"> {{ element.insurer }} </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "contracts.STATUS_text" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span [ngClass]="element.status == 'Angebot' ? 'offerBtnStyle' : ''">
              {{ element.status}}
            </span>
            <span *ngIf="element.status == 'Storno'">
              | {{ element.cancellation_date | dateFormat }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="object">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "contracts.OBJEKT_text" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" title="{{ element.object }}">
            <span class="custom-ellipsis noSafariTooltip"> {{ element.object }} </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="insuredAmount">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-140">
            {{ "contracts.VERTRAGSSUMMEN_text" | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-140">
            <span *ngIf="element.insurance_sum">
              {{isNotANumber(element.insurance_sum) ? "" : (element.insurance_sum | currencyCustom)}}
              {{isNotANumber(element.insurance_sum) ? "" : element.currency_symbol}}
            </span>
            <span *ngIf="!element.insurance_sum">
              {{ element.insurance_sum }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="annualPremium">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-140">
            {{ "contracts.JAHRESPRÄMIE_text" | translate }}
          </mat-header-cell>
          <mat-cell matTooltipPosition="right"
            matTooltip="{{ 'contracts.Inklusive_tooltip' | translate }}" class="w-140" *matCellDef="let element">
            <span *ngIf="element.premium">
              {{element.premium | currencyCustom}}
              {{element.premium ? element.currency_symbol : ''}}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-130">
            {{'product-management.Created_by' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-130">
            <div>
              <div class="custom-ellipsis noSafariTooltip">
                {{element.created_by}}
              </div>
              <div class="table-below-text">{{element.created_at}}</div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="updatedBy">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-130">
            {{'product-management.Last_updated_by' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-130">
            <div>
              <div class="custom-ellipsis noSafariTooltip">
                {{element.updated_by}}
              </div>
              <div class="table-below-text">{{element.updated_at}}</div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell class="pr-0 w-130" *matHeaderCellDef>
            <span>{{ "contracts.AKTION_text" | translate }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-130">
            <div class="control-section">
              <div class="subscribe">
                <button class="hide-action-button myDIV"> &#183; &#183; &#183; </button>
                <div class="hide">
                  <div class="action-btn-align">
                    <button *ngIf="!isCustomerUser"
                      class="action-btn"
                      placement="top"
                      tooltipClass="generic-tooltip"
                      ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                      (click)="editRow(element)"
                    >
                      <img alt="" class="imgStyle" src='assets/images/edit_contract_icon.svg'>
                    </button>
                    <button
                      class="action-btn"
                      *ngIf="isCustomerUser"
                      (click)="loadDocument(element)"
                      placement="top"
                      tooltipClass="generic-tooltip"
                      ngbTooltip="{{'claims.Dokumente_tooltip' | translate}}"
                    >
                      <img alt="" height="18px" src="assets/images/Document copy.svg" />
                    </button>
                    <button class="action-btn"
                      placement="top"
                      tooltipClass="generic-tooltip"
                      ngbTooltip="{{'contracts.Schäden_tooltip' | translate}}"
                      (click)="navigatToClaims(element)"
                    >
                      <img alt="" height="22px" src="assets/images/Group 2867 copy.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          [ngClass]="{'row--opened' : row.isOpened, 'row--clickable': !isCustomerUser }"
          (click)="editRow(row)" 
          *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>

    <div class="table-footer">
      <div class="paginator-footer">
        <ngb-pagination [collectionSize]="total_elements" (pageChange)="tablePageChanged($event)" [maxSize]=5
          [(page)]="tablePage" [pageSize]="selectedCountOfRows" [boundaryLinks]="false">
          <ng-template ngbPaginationPrevious>
            <i class="fa fa-angle-left"></i>
          </ng-template>
          <ng-template ngbPaginationNext>
            <i class="fa fa-angle-right"></i>
          </ng-template>
        </ngb-pagination>
        <div class="footer-text">
          <span>{{ "contracts.AnzahlVerträge_text" | translate }}{{ total_elements }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
