import {
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  Directive,
  ComponentRef,
  OnInit,
  OnChanges,
} from '@angular/core';

@Directive({
  selector: '[dynamicCell]',
})
export class DynamicCellDirective implements OnInit, OnChanges {
  @Input() component: any;
  @Input() inputs: any;

  private _cmp: ComponentRef<any>;

  constructor(
    private cfr: ComponentFactoryResolver,
    private vc: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this._renderComponent();
  }

  ngOnChanges(): void {
    if (this.inputs && this._cmp) {
      this.vc.remove();

      this._renderComponent();
    }
  }

  private _renderComponent(): void {
    const componentFactory = this.cfr.resolveComponentFactory(this.component);
    this._cmp = this.vc.createComponent(componentFactory);
    Object.keys(this.inputs).forEach(input => {
      this._cmp.instance[input] = { ...this.inputs[input] };
    });
  }
}
