import { DateAdapter } from '@angular/material/core';
import { Input, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ItemNodeList } from './tree-list.model';

@Directive()
export class TreeRootStructure {

  @Input('nodeLevel') endNodeLevel;
  @Input('isDisabled') isDisabled;

  dataChange: BehaviorSubject<ItemNodeList[]>;
  constructor() {
    this.dataChange = new BehaviorSubject<ItemNodeList[]>([]);
  }

  ngOnDestroy(): void {
    this.dataChange.unsubscribe();
  }
  get data(): ItemNodeList[] { return this.dataChange.value; }

  intializeTree(obj: object) {
    this.dataChange = new BehaviorSubject<ItemNodeList[]>([]);

    let data = this.buildFileTree(obj, 0, null);
    if (data) {
      data = data.sort((a, b) => (a.sequence_number < b.sequence_number) ? -1 : 1);
      for (let i = 0; i < data.length; i++) {
        data[i].isLastElement = (i == data.length - 1) ? true : false;
      }
    }
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: object, level: number, parentNode: ItemNodeList): ItemNodeList[] {
    return Object.keys(obj).reduce<ItemNodeList[]>((accumulator, key) => {
      const value = obj[key];
      const node = new ItemNodeList();
      if (value != null) {
        node.item = value.name;
        node.itemId = value.id;
        node.isSelected = value.is_selected;
        node.sequence_number = value.sequence_number;
        node.parentNode = parentNode && Object.assign(parentNode);
        if (value.inner_step_details != null && value.inner_step_details.length > 0) {
          const childConstNode = this.buildFileTree(value.inner_step_details, level + 1, node);
          node.children = childConstNode && Object.assign(childConstNode);
          node.children = node.children && node.children.sort((a, b) => (a.sequence_number < b.sequence_number) ? -1 : 1);
          let isSelect = true;
          for (let i = 0; i < node.children.length ; i++) {
              node.children[i].isLastElement = false;
              if (!node.children[i].isSelected && isSelect) {
                isSelect = false;
              }
          }
          if (node.children && level == this.endNodeLevel - 1 && !this.isDisabled) {
            node.children.push({
              children: undefined,
              item: 'dummy',
              itemId: 0,
              isLastElement: true,
              isSelected: isSelect,
              parentNode: node.children[node.children.length - 1].parentNode,
              sequence_number: node.children.length,
            });
          }
        }
      }
      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: ItemNodeList, name: string) {
    if (parent.children) {
      parent.children.push({item: name} as ItemNodeList);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: ItemNodeList, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}
