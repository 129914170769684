<div *ngIf="selectLang$ | async as locale">
    <form 
        *ngIf="generalClaimsDetailsForm" 
        class="general-form product-module-html"
        [formGroup]="generalClaimsDetailsForm" 
        id="general-form">
        <div class="general-first-block block">
            <div class="contracts-tabs-heading heading-one">
                <h6 style="font-weight: 600;">{{'new-claims.general_details.general_claims_details' | translate}}</h6>
            </div>
            <div class="contracts-tabs-content general-block-contents row">
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label required">{{'new-contract.general_information.status' |
                        translate}}</label><br>
                    <mat-select formControlName="status" class="dropdownStyle switcherDrpdwn inputy switcher"
                        style="color: #272727;border-radius: 3px;height: 33px;background: #fff;"
                        placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="search.value = ''"
                        (openedChange)="search.value = ''"
                        [ngClass]="{'red' : (generalClaimsDetailsForm.controls['status'].touched || submitted) && generalClaimsDetailsForm.controls['status'].errors?.required }">
                        <input type="text" maxlength="20" preventSpace class="searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #search>
                        <mat-option style="opacity:0.5">
                            {{'objectModule.select_lable' | translate}}
                        </mat-option>
                        <mat-option *ngFor="let item of _validationService._filter(claim_status, 'desc', search.value)"
                            [value]="item.id">{{item.desc}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label">{{'new-claims.general_details.comment_on_status' |
                        translate}}</label>
                    <input formControlName="comment_on_status" type="text" class="input" maxlength="100"
                        placeholder="{{'new-claims.general_details.comment_on_status' | translate}}">
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label required">{{'new-claims.general_details.clerk_vm' |
                        translate}}</label><br>
                    <mat-select formControlName="clerk_vm" class="dropdownStyle switcherDrpdwn inputy switcher"
                        style="color: #272727;border-radius: 3px;height: 33px;background: #fff;"
                        placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="search.value = ''"
                        (openedChange)="search.value = ''"
                        [ngClass]="{'red' : (generalClaimsDetailsForm.controls['clerk_vm'].touched || submitted) && generalClaimsDetailsForm.controls['clerk_vm'].errors?.required }">
                        <input type="text" maxlength="20" preventSpace class="searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #search2>
                        <mat-option style="opacity:0.5">
                            {{'objectModule.select_lable' | translate}}
                        </mat-option>
                        <mat-option *ngFor="let item of _validationService._filter(clerk_vm, 'desc', search2.value)"
                            [value]="item.id">{{item.desc}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-4 col-6 dateContainer" id="datepicker1">
                    <label *ngIf="!NotificationDateError" for="" class="long-label">
                        {{'new-claims.general_details.notification_date'
                        | translate}}
                    </label>
                    <label *ngIf="NotificationDateError" for="" class="long-label touched-warning ellipsis"
                        style="display: inline-block;">
                        {{'new-claims.general_details.notification_date'
                        | translate}} : {{'objectModule.Invalid_date' | translate}}
                    </label><br />
                    <mat-form-field class="date-picker" appearance="fill" id="datePicker1">
                        <span>
                            <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                        </span>
                        <input formControlName="notification_date" matInput [matDatepicker]="datepicker1" id="myInput1"
                            (keypress)="dateChangeNotification($event)" (paste)="dateChangeNotification($event)"
                            (dateChange)="dateClickedNotification()" class="date-input" autocomplete="off"
                            placeholder="{{'claim-reporting.datePlaceHolder' | translate}}">
                        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker1">
                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                {{'claims.keyboard-arrow-down_text' | translate}}
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-4 col-6 dateContainer" id="datepicker2">
                    <label *ngIf="!dateOfClaimDateError" for="" class="long-label required">
                        {{'new-claims.general_details.date_of_claim'
                        | translate}}
                    </label>
                    <label *ngIf="dateOfClaimDateError" for="" class="long-label required touched-warning ellipsis"
                        style="display: inline-block;">
                        {{'new-claims.general_details.date_of_claim'
                        | translate}} : {{'objectModule.Invalid_date' | translate}}
                    </label><br />
                    <mat-form-field
                        [ngClass]="{'red' : (generalClaimsDetailsForm.controls['date_of_claim'].touched || submitted) && generalClaimsDetailsForm.controls['date_of_claim'].errors?.required }"
                        class="date-picker" appearance="fill" id="datePicker2">
                        <span>
                            <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                        </span>
                        <input formControlName="date_of_claim" matInput [matDatepicker]="datepicker2" id="myInput"
                            (keypress)="dateChangeclaim($event)" (paste)="dateChangeclaim($event)"
                            (dateChange)="dateClickedclaim()" class="date-input" autocomplete="off"
                            placeholder="{{'claim-reporting.datePlaceHolder' | translate}}">
                        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker2">
                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                {{'claims.keyboard-arrow-down_text' | translate}}
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label">{{'new-claims.general_details.type_of_claim' |
                        translate}} (ICP)</label><br>
                    <mat-select formControlName="type_of_claim_rcs" class="dropdownStyle switcherDrpdwn inputy switcher"
                        style="color: #272727;border-radius: 3px;height: 33px;background: #fff;"
                        placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="search.value = ''"
                        (openedChange)="search.value = ''">
                        <input type="text" maxlength="20" preventSpace class="searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #search3>
                        <mat-option style="opacity:0.5">
                            {{'objectModule.select_lable' | translate}}
                        </mat-option>
                        <mat-option
                            *ngFor="let item of _validationService._filter(type_of_claims, 'desc', search3.value)"
                            [value]="item.id">{{item.desc}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label">{{'new-claims.general_details.type_of_claim' |
                        translate}} (ERP)</label><br>
                    <mat-select formControlName="type_of_claim_ivm" class="dropdownStyle switcherDrpdwn inputy switcher"
                        style="color: #272727;border-radius: 3px;height: 33px;background: #fff;"
                        placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="search.value = ''"
                        (openedChange)="search.value = ''">
                        <input type="text" maxlength="20" preventSpace class="searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #search4>
                        <mat-option style="opacity:0.5">
                            {{'objectModule.select_lable' | translate}}
                        </mat-option>
                        <mat-option
                            *ngFor="let item of _validationService._filter(type_of_claims, 'desc', search4.value)"
                            [value]="item.id">{{item.desc}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label">{{'new-claims.general_details.claim_location' |
                        translate}}</label><br>
                    <input type="text" class="input" formControlName="claim_location"
                        placeholder="{{'new-claims.general_details.claim_location' | translate}}"
                        [ngClass]="showActive == false ? 'greyLocationPinIcon' : 'greenLocationPinIcon'" name="location-list" #loc
                        id="location" [matAutocomplete]="auto" (focus)="showList = true" (focusout)="onFocusOut()"
                        (keyup)="keyUp.next($event)">
                    <mat-autocomplete #auto="matAutocomplete">
                        <ng-container *ngIf="menuContent">
                            <mat-option *ngFor="let item of filterlocation;index as i" (click)="item"
                                (click)="selectedLocation(i,item.freeformAddress)">

                                {{ item.freeformAddress }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
        <div class="general-second-block">
            <div class="general-second-left-block block">
                <div class="contracts-tabs-heading heading-two">
                    <h6 style="font-weight: 600;">{{'new-claims.general_details.claim_number_details' | translate}}
                    </h6>
                </div>
                <div class="contracts-tabs-content general-block-contents row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <label style="text-transform: initial;" for=""
                            class="long-label">{{'new-claims.general_details.damage_number_vm' |
                            translate}}</label>
                        <input formControlName="damage_number_vm" type="text" class="input" maxlength="100"
                            placeholder="{{'new-claims.general_details.damage_number_vm' | translate}}">
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <label style="text-transform: initial;" for=""
                            class="long-label">{{'new-claims.general_details.claim_number_vn' |
                            translate}}</label>
                        <input formControlName="claim_number_vn" type="text" class="input" maxlength="100"
                            placeholder="{{'new-claims.general_details.claim_number_vn' | translate}}">
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <label style="text-transform: initial;" for=""
                            class="long-label">{{'new-claims.general_details.reference_vn' |
                            translate}}</label>
                        <input formControlName="reference_vn" type="text" class="input" maxlength="100"
                            placeholder="{{'new-claims.general_details.reference_vn' | translate}}">
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <label style="text-transform: initial;" for=""
                            class="long-label">{{'new-claims.general_details.claim_number_vr' |
                            translate}}</label>
                        <input formControlName="claim_number_vr" type="text" class="input" maxlength="100"
                            placeholder="{{'new-claims.general_details.claim_number_vr' | translate}}">
                    </div>
                </div>
                <div class="contracts-tabs-heading heading-two">
                    <h6 style="font-weight: 600;">{{'new-claims.general_details.amount_payment_details' | translate}}
                    </h6>
                </div>
                <div class="contracts-tabs-content general-block-contents row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <label style="text-transform: initial;" for="" *ngIf="!payment1error2 && !payment1error1"
                            class="long-label">{{'new-claims.general_details.amount_of_loss' |
                            translate}}</label>
                        <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
                            *ngIf="payment1error2 || payment1error1">
                            <span
                                [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                                {{'new-claims.general_details.amount_of_loss' |
                                translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
                        <div style="display: flex;">
                            <mat-select id="currencyDropdown" style="height: 38px;"
                                (selectionChange)="currancyChanged($event.value)"
                                class="dropdownStyle currencyDrop currecnyStyle"
                                (click)="currencyTypeSearch('currencyTypeSearchOne')"
                                (keyup.enter)="currencyTypeSearch('currencyTypeSearchOne')"
                                formControlName="currency_type" [disableOptionCentering]="true"
                                panelClass="currencyTypeDropdown" title="{{selectedCurrency}}"
                                #currencyTypeAmountOfLoss>
                                <mat-select-trigger>
                                    <span>{{currancySymbol}}</span>
                                </mat-select-trigger>
                                <input type="text" class="searchYear" id="currencyTypeSearchOne" maxlength="5"
                                    (click)="resetList()" autocomplete="off"
                                    placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                    [formControl]="currencyTypeSearchText">
                                <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()"
                                    [value]="ele.cur_code" matTooltip="{{ele.cur_symbol}}">
                                    <span style="display: flex;">
                                        <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                                        <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                                    </span>
                                </mat-option>
                            </mat-select>
                            <input type="text" style="border-bottom-left-radius: 0px;
                            border-top-left-radius: 0px;
                            height: 38px;" maxlength="100"
                                *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                                class="input newValue"
                                (paste)="numberValidation.restrictCharactersInNumericFields($event, staticText.PASTE,'generalClaims')"
                                (keypress)="numberValidation.restrictCharactersInNumericFields($event, staticText.KEYPRESS,'generalClaims')"
                                (blur)="changeNumber($event.target.value, 'amount_of_loss')"
                                formControlName="amount_of_loss"
                                placeholder="{{'new-claims.general_details.amount_of_loss' | translate}}">
                            <input type="text" style="border-bottom-left-radius: 0px;
                            border-top-left-radius: 0px;
                            height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                                class="input newValue" (paste)="onTypeNewValue($event, 'paste')"
                                (keyup)="onTypeNewValue($event, 'keypress')"
                                (blur)="changeNumber($event.target.value, 'amount_of_loss')"
                                formControlName="amount_of_loss"
                                placeholder="{{'new-claims.general_details.amount_of_loss' | translate}}">
                        </div>

                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <label style="text-transform: initial;" for="" *ngIf="!payment2error2 && !payment2error1"
                            class="long-label">{{'new-claims.general_details.deductable_amount' |
                            translate}}</label>
                        <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
                            *ngIf="payment2error2 || payment2error1">
                            <span
                                [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                                {{'new-claims.general_details.deductable_amount' |
                                translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
                        <div style="display: flex;">
                            <mat-select id="currencyDropdown" style="height: 38px;"
                                (selectionChange)="currancyChanged($event.value)"
                                class="dropdownStyle currencyDrop currecnyStyle"
                                (click)="currencyTypeSearch('currencyTypeSearchTwo')"
                                (keyup.enter)="currencyTypeSearch('currencyTypeSearchTwo')"
                                formControlName="currency_type" [disableOptionCentering]="true"
                                panelClass="currencyTypeDropdown" title="{{selectedCurrency}}"
                                #currencyTypeDeductableAmount>
                                <mat-select-trigger>
                                    <span>{{currancySymbol}}</span>
                                </mat-select-trigger>
                                <input type="text" class="searchYear" id="currencyTypeSearchTwo" maxlength="5"
                                    (click)="resetList()" autocomplete="off"
                                    placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                    [formControl]="currencyTypeSearchText">
                                <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()"
                                    [value]="ele.cur_code" matTooltip="{{ele.cur_symbol}}">
                                    <span style="display: flex;">
                                        <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                                        <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                                    </span>
                                </mat-option>
                            </mat-select>
                            <input type="text" style="border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;
                                height: 38px;" maxlength="100"
                                *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                                class="input newValue"
                                (paste)="numberValidation.restrictCharactersInNumericFields($event, staticText.PASTE,'generalClaims')"
                                (keypress)="numberValidation.restrictCharactersInNumericFields($event, staticText.KEYPRESS,'generalClaims')"
                                (blur)="changeNumber($event.target.value, 'deductable_amount')"
                                formControlName="deductable_amount"
                                placeholder="{{'new-claims.general_details.deductable_amount' | translate}}">
                            <input type="text" style="border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;
                                height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                                class="input newValue" (paste)="onTypeNewValue($event, 'paste')"
                                (keyup)="onTypeNewValue($event, 'keypress')"
                                (blur)="changeNumber($event.target.value, 'deductable_amount')"
                                formControlName="deductable_amount"
                                placeholder="{{'new-claims.general_details.deductable_amount' | translate}}">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <label *ngIf="!payment3error2 && !payment3error1" style="text-transform: initial;" for=""
                            class="long-label">{{'new-claims.general_details.ammount_not_eligible_for_settlement'
                            |
                            translate}}</label>
                        <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
                            *ngIf="payment3error2 || payment3error1">
                            <span
                                [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                                {{'new-claims.general_details.ammount_not_eligible_for_settlement'
                                |
                                translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
                        <div style="display: flex;">
                            <mat-select id="currencyDropdown" style="height: 38px;"
                                (selectionChange)="currancyChanged($event.value)"
                                class="dropdownStyle currencyDrop currecnyStyle"
                                (click)="currencyTypeSearch('currencyTypeSearchThree')"
                                (keyup.enter)="currencyTypeSearch('currencyTypeSearchThree')"
                                formControlName="currency_type" [disableOptionCentering]="true"
                                panelClass="currencyTypeDropdown" title="{{selectedCurrency}}"
                                #currencyTypeEligibleForSettlement>
                                <mat-select-trigger>
                                    <span>{{currancySymbol}}</span>
                                </mat-select-trigger>
                                <input type="text" class="searchYear" id="currencyTypeSearchThree" maxlength="5"
                                    (click)="resetList()" autocomplete="off"
                                    placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                    [formControl]="currencyTypeSearchText">
                                <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()"
                                    [value]="ele.cur_code" matTooltip="{{ele.cur_symbol}}">
                                    <span style="display: flex;">
                                        <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                                        <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                                    </span>
                                </mat-option>
                            </mat-select>
                            <input type="text" style="border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;
                                height: 38px;" maxlength="100"
                                *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                                class="input newValue"
                                (paste)="numberValidation.restrictCharactersInNumericFields($event, staticText.PASTE,'generalClaims')"
                                (keypress)="numberValidation.restrictCharactersInNumericFields($event, staticText.KEYPRESS,'generalClaims')"
                                (blur)="changeNumber($event.target.value, 'ammount_not_eligible_for_settlement')"
                                formControlName="ammount_not_eligible_for_settlement"
                                placeholder="{{'new-claims.general_details.ammount_not_eligible_for_settlement' | translate}}">
                            <input type="text" style="border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;
                                height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                                class="input newValue"
                                (paste)="onTypeNewValue($event, 'paste')"
                                (keyup)="onTypeNewValue($event, 'keypress')"
                                (blur)="changeNumber($event.target.value, 'ammount_not_eligible_for_settlement')"
                                formControlName="ammount_not_eligible_for_settlement"
                                placeholder="{{'new-claims.general_details.ammount_not_eligible_for_settlement' | translate}}">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <label *ngIf="!payment4error2 && !payment4error1" style="text-transform: initial;" for=""
                            class="long-label">{{'new-claims.general_details.reserve' |
                            translate}}</label>
                        <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
                            *ngIf="payment4error2 || payment4error1">
                            <span
                                [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                                {{'new-claims.general_details.reserve' |
                                translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
                        <div style="display: flex;">
                            <mat-select id="currencyDropdown" style="height: 38px;"
                                (selectionChange)="currancyChanged($event.value)"
                                class="dropdownStyle currencyDrop currecnyStyle"
                                (click)="currencyTypeSearch('currencyTypeSearchFour')"
                                (keyup.enter)="currencyTypeSearch('currencyTypeSearchFour')"
                                formControlName="currency_type" [disableOptionCentering]="true"
                                panelClass="currencyTypeDropdown" title="{{selectedCurrency}}" #currencyTypeReserve>
                                <mat-select-trigger>
                                    <span>{{currancySymbol}}</span>
                                </mat-select-trigger>
                                <input type="text" class="searchYear" id="currencyTypeSearchFour" maxlength="5"
                                    (click)="resetList()" autocomplete="off"
                                    placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                    [formControl]="currencyTypeSearchText">
                                <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()"
                                    [value]="ele.cur_code" matTooltip="{{ele.cur_symbol}}">
                                    <span style="display: flex;">
                                        <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                                        <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                                    </span>
                                </mat-option>
                            </mat-select>
                            <input type="text" style="border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;
                                height: 38px;" maxlength="100"
                                *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                                class="input newValue"
                                (paste)="numberValidation.restrictCharactersInNumericFields($event, staticText.PASTE,'generalClaims')"
                                (keypress)="numberValidation.restrictCharactersInNumericFields($event, staticText.KEYPRESS,'generalClaims')"
                                (blur)="changeNumber($event.target.value, 'reserve')" formControlName="reserve"
                                placeholder="{{'new-claims.general_details.reserve' | translate}}">
                            <input type="text" style="border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;
                                height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                                class="input newValue" (paste)="onTypeNewValue($event, 'paste')"
                                (keyup)="onTypeNewValue($event, 'keypress')"
                                (blur)="changeNumber($event.target.value, 'reserve')" formControlName="reserve"
                                placeholder="{{'new-claims.general_details.reserve' | translate}}">
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                        <label style="text-transform: initial;" for=""
                            class="long-label">{{'new-claims.general_details.sum_of_payments' |
                            translate}}</label>
                        <div style="display: flex;">
                            <mat-select id="currencyDropdown" style="height: 38px;"
                                (selectionChange)="currancyChanged($event.value)"
                                class="dropdownStyle currencyDrop currecnyStyle"
                                (click)="currencyTypeSearch('currencyTypeSearchFive')"
                                (keyup.enter)="currencyTypeSearch('currencyTypeSearchFive')"
                                formControlName="currency_type" [disableOptionCentering]="true"
                                panelClass="currencyTypeDropdown" title="{{selectedCurrency}}" #currencyTypeSumOfPayment
                                disabled>
                                <mat-select-trigger>
                                    <span>{{currancySymbol}}</span>
                                </mat-select-trigger>
                                <input type="text" class="searchYear" id="currencyTypeSearchFive" maxlength="5"
                                    (click)="resetList()" autocomplete="off"
                                    placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                    [formControl]="currencyTypeSearchText">
                                <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()"
                                    [value]="ele.cur_code" matTooltip="{{ele.cur_symbol}}">
                                    <span style="display: flex;">
                                        <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                                        <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                                    </span>
                                </mat-option>
                            </mat-select>
                            <input type="text" style="border-bottom-left-radius: 0px;
                            border-top-left-radius: 0px;
                            height: 38px;" maxlength="100" formControlName="sum_of_payments"
                                placeholder="{{'new-claims.general_details.sum_of_payments' | translate}}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="general-second-right-block block" style="position: relative;">
                <div class="contracts-tabs-heading heading-two">
                    <h6 style="font-weight: 600;">Graph</h6>
                </div>
                <div style="position: absolute;
                top: 45%;
                left: 43%;">
                    <span style="color: #808080a1;
                    font-size: 40px;
                    font-weight: 600;">Graph</span>
                </div>
            </div>
        </div>
        <div class="general-third-block block">
            <div class="contracts-tabs-heading heading-one">
                <h6 style="font-weight: 600;">{{'new-claims.general_details.other_deatils' | translate}}
                </h6>
            </div>
            <div class="contracts-tabs-content general-block-contents row">
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label">{{'new-claims.general_details.cause_of_claim' |
                        translate}}</label>
                    <app-custom-autocomplete class="autocomplete" *ngIf="cause_of_claim_lookup"
                        [placeholder]="'objectModule.select_lable' | translate" [required]="false"
                        [lookup]="cause_of_claim_lookup" [control]="generalClaimsDetailsForm.controls['cause_of_claim']"
                        (newValueSelected)="targetValue($event)"></app-custom-autocomplete>
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label for="" class="long-label"> {{'new-claims.general_details.police_report' |
                        translate}}</label>
                    <div class="radio-group-align-long" style="margin-top:0px">
                        <mat-radio-group aria-label="" formControlName="police_report">
                            <mat-radio-button [value]="1" class="checkBox">{{'objectModule.Yes' | translate}}
                            </mat-radio-button>
                            <mat-radio-button [value]="2" style="margin-left:20%" class="checkBox">
                                {{'objectModule.No' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label">{{'new-claims.general_details.police_fire_number' |
                        translate}}</label>
                    <input type="text" formControlName="police_fire_number" class="input" maxlength="100"
                        placeholder="{{'new-claims.general_details.police_fire_number' | translate}}">
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label style="text-transform: initial;" for=""
                        class="long-label">{{'new-claims.general_details.assigned_object' |
                        translate}}</label><br>
                    <mat-select formControlName="assigned_object" class="dropdownStyle switcherDrpdwn inputy switcher"
                        style="color: #272727;border-radius: 3px;height: 33px;background: #fff;"
                        placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="search.value = ''"
                        (openedChange)="search.value = ''" multiple>
                        <mat-option *ngFor="let item of assigned_claims_object" [value]="item.id">{{item.desc}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-4 col-6">
                    <label for="" class="long-label">
                        {{'new-claims.general_details.input_tax_deduction_authorization' |
                        translate}}</label>
                    <div class="radio-group-align-long">
                        <mat-radio-group aria-label="" formControlName="input_tax_deduction_authorization">
                            <mat-radio-button [value]="1" class="checkBox">{{'objectModule.Yes' | translate}}
                            </mat-radio-button>
                            <mat-radio-button style="margin-left:20%" [value]="2" class="checkBox">
                                {{'objectModule.No' | translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-6 col-sm-8 col-6">
                    <label style="text-transform: initial;" for=""
                        class="label">{{'new-claims.general_details.claim_history' | translate}}</label><br />
                    <textarea class="textArea" formControlName="claim_history" maxlength="2000"
                        style="width: 100%;padding: 5px 7px;"
                        placeholder="{{'new-claims.general_details.claim_history' | translate}}"></textarea>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="save-container">
    <button class="custom-btn save-btn" *ngIf="generalClaimsDetailsForm"
        [disabled]="!isValueChangedInForm || formError || isIvmSynced" (click)="saveClaims()">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>
<app-warning-popup *ngIf="showWarningPopUp" [customerWarningMsg]="customerWarningMsg"
    (savedata)="saveClaimGeneralDetails()" (handlePopup)="handleWarningPopup($event)">
</app-warning-popup>