import { IC_ICONS } from "./ic-icons.constant";

const FOLDER_CONST = {
  TABLE: 'assets/images/table/',
  ACTION: 'assets/images/action/',
  CLAIMS: 'assets/images/claims/',
};

export const ACTION_BUTTON = {
  EDIT_WITH_BACKGROUND: `${FOLDER_CONST.ACTION}edit-with-background.svg`,
  DELETE_WITH_BACKGROUND: `${FOLDER_CONST.ACTION}delete-with-background.svg`,
  EDIT_WO_BACKGROUND: `${FOLDER_CONST.ACTION}edit.svg`,
  DELETE_WO_BACKGROUND: `${FOLDER_CONST.ACTION}delete.svg`,
  EXPORT_EXCEL: IC_ICONS.EXPORT_TO_EXCEL,
  PAYMENT_DETAILS: `${FOLDER_CONST.CLAIMS}payment-details.svg`,
  DOCUMENT: `${FOLDER_CONST.CLAIMS}document-copy.svg`,
  RESET: `${FOLDER_CONST.ACTION}reset.svg`,
  DAMAGE: `${FOLDER_CONST.ACTION}damage.svg`,
  DETACH: `${FOLDER_CONST.ACTION}detach.png`,
  OPEN_DOC: `${FOLDER_CONST.ACTION}open-doc.svg`,
  COMPLETE: `${FOLDER_CONST.ACTION}mark-as-completed.svg`,
  DOWNLOAD: `${FOLDER_CONST.ACTION}download.svg`
};

export const TABLE = {
  SORT: IC_ICONS.SORT //`${FOLDER_CONST.TABLE}sort.svg`,
};
