import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES_PATH } from '@shared/constants/router.constants';

@Component({
  selector: 'app-register-claim-step-two',
  templateUrl: './register-claim-step-two.component.html',
  styleUrls: ['./register-claim-step-two.component.css'],
})
export class RegisterClaimStepTwoComponent {
  isdisabled = true;
  selectedBitte: any;
  Bitte: any;
  selectedSchadenart: any;
  Schadenart: any;
  test: void;
  testt: string;
  constructor(private router: Router) {}
  nextTwo() {
    this.router.navigateByUrl(ROUTES_PATH.REGISTER_CLAIM_STEP_THREE, {
      skipLocationChange: true,
    });
  }
  navigateStepOne() {
    this.router.navigateByUrl(ROUTES_PATH.REGISTER_CLAIM, {
      skipLocationChange: true,
    });
  }
  selectBitte(value) {
    this.isdisabled = false;
    this.selectedBitte = value.value;
  }
  selectSchadenart(value) {
    this.selectedSchadenart = value.value;
    document.getElementById('Weiter').style.background = '#ff9000';
    document.getElementById('placeholder').style.display = 'contents';
  }
}
