<input type="text" class="input" placeholder="{{placeholder}}" [formControl]="control" title="{{control.value}}"
    [ngClass]="showActive == false ? 'greyLocationPinIcon' : 'greenLocationPinIcon'" name="location-list" #loc id="location"
    [matAutocomplete]="auto" (focus)="showList = true" (focusout)="onFocusOut($event)" (keyup)="keyUp.next($event)">
<mat-autocomplete #auto="matAutocomplete">
    <ng-container *ngIf="menuContent">
        <mat-option *ngFor="let item of filterlocation;index as i" (click)="item"
            (click)="selectedLocation(i,item.freeformAddress)">

            {{ item.freeformAddress }}
        </mat-option>
    </ng-container>
</mat-autocomplete>