import { Injectable } from '@angular/core';
import { KEYCODES, LENGTH } from '@shared/constants/numeric.constants';
import { ERRORS, STATIC_TEXT } from '@shared/constants/string.constants';
import { REGEX } from '@shared/constants/general/regex.constants';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';

@Injectable({
  providedIn: 'root',
})
export class NumberValidationsService {
  constructor(private _currencyCustomPipe: CurrencyCustomPipe) {}

  public removeSymbols(value) {
    return value
      ? value?.replaceAll('.', '').replaceAll(',', '.').trim()
      : value;
  }
  public setNumberValidation(str) {
    if (!str) {
      return null;
    }

    str = this.removeSymbols(str);
    if (str.length >= LENGTH.FOUR) {
      str = this._currencyCustomPipe
        .transform(str)
        .replace(STATIC_TEXT.EUR_SYMBOL, STATIC_TEXT.EMPTY_STRING)
        .trim();
    }
    return str;
  }

  /**
   * @param event value to be converted to german format
   * @returns german formatted value
   */
  public convertToGermanFormat(event: any) {
    const count = (event.target.value.match(/,/g) || []).length;
    if (count > 1) {
      event.preventDefault();
      return event.target.value;
    } else {
      return this.setNumberValidation(event.target.value);
    }
  }
  /**
   * @param value German formatted value to be converted to number
   * @returns number formatted value else null
   */
  public convertGermanToInt(value: string) {
    return value ? Number(this.removeSymbols(value)) : null;
  }
  public validateBillionCheck(value: any) {
    const tempValue = value?.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue.length > 2 || splitValue[0].length > 9) {
      return { isError: true, errorName: ERRORS.OVER_BILLION_ERROR };
    } else {
      return { isError: false, errorName: ERRORS.OVER_BILLION_ERROR };
    }
  }
  public setRemoveError(validate, formGroup, formControl: any) {
    validate?.isError
      ? formGroup
          .get(formControl)
          .setErrors({ [validate?.errorName]: validate?.isError })
      : formGroup.get(formControl).errors
        ? delete formGroup.get(formControl).errors[validate?.errorName]
        : '';
  }

  public restrictCharactersInNumericFields(event, key, fromWhere?) {
    let newValue;
    if (key === STATIC_TEXT.KEYPRESS) {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length === 0) {
        if (charCode === KEYCODES.INSERT || charCode === KEYCODES.ZERO) {
          event.preventDefault();
        }
      }
      if (!STATIC_TEXT.UMLOUTS.includes(event.target.value)) {
        if (fromWhere !== 'generalClaims') {
          if (
            charCode !== 46 &&
            charCode > 31 &&
            (charCode < 48 || charCode > 57)
          ) {
            event.preventDefault();
          }
        } else {
          if (
            charCode !== 46 &&
            charCode !== 44 &&
            charCode > 31 &&
            (charCode < 48 || charCode > 57)
          ) {
            event.preventDefault();
          }
        }
        return;
      } else {
        event.preventDefault();
      }
      if (event.code !== STATIC_TEXT.BACKSPACE) {
        newValue = event.target.value.length;
      } else {
        newValue = newValue - 1;
      }
    } else {
      const reg = new RegExp(REGEX.NUMBERS_ONLY);
      if (!reg.test(event.clipboardData.getData(STATIC_TEXT.TEXT_PLAIN))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData(STATIC_TEXT.TEXT_PLAIN);
      if (this.isValuePresent(value, STATIC_TEXT.UMLOUTS)) {
        event.preventDefault();
      }
      if (
        newValue === 0 &&
        event.clipboardData.getData(STATIC_TEXT.TEXT_PLAIN) < 1
      ) {
        event.preventDefault();
      }
    }
  }
  private isValuePresent(searchValue, array) {
    return array.some(item => searchValue.includes(item));
  }

  restrictCharactersInNumericFieldBrowserSpecific(event, key, length) {
    const keybord = event.keyCode || event.charCode || 0;
    let actualnumber;
    if (key === 'keydown' && !event.metaKey) {
      const reg = new RegExp('^[0-9]*$');
      event = event || window.event;
      const charCode =
        typeof event.which === 'undefined' ? event.keyCode : event.which;
      const charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9]+$/)) {
        event.preventDefault();
      }
      if (
        charCode === '69' ||
        charCode === '189' ||
        charCode === '187' ||
        !reg.test(event.target.value)
      ) {
        event.preventDefault();
      }
      if (event.target.value.length === 0) {
        if (charCode === '48' || charCode === '45') {
          event.preventDefault();
        }
      }
      const checkSelectedLength =
        document.getSelection().toString().length === length;
      if (
        !checkSelectedLength &&
        event.target.value.length === length &&
        event.code !== 'Backspace' &&
        event.code !== 'Tab'
      ) {
        event.preventDefault();
      }
      if (event.code !== 'Backspace' && event.code !== 'Tab') {
        actualnumber = event.target.value.length;
      } else {
        actualnumber = actualnumber - 1;
      }
    } else {
      const reg = new RegExp('^[0-9]*$');
      if (reg.test(event.clipboardData.getData('text/plain')) === false) {
        event.preventDefault();
      }
    }
  }

  /**
   * This Method will accept value in number format and convert it into two decimal german format
   * @param num
   * @returns german formatted with two decimal value
   */
  convertIntToGermanFormatWithDecimal(num) {
    if (!num) {
      return '';
    }
    num = this._currencyCustomPipe
      .transform(num)
      .replace(STATIC_TEXT.EUR_SYMBOL, STATIC_TEXT.EMPTY_STRING)
      .trim();
    return num;
  }
}
