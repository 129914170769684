import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DATE_FORMAT, TIMEZONE } from '@shared/constants/string.constants';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private _datePipe: DatePipe) {}

  transform(value: string | Date, format?: string, timezone?: string): string {
    if (!value) {
      return '';
    }

    return this._datePipe.transform(
      value,
      format || DATE_FORMAT,
      timezone || TIMEZONE
    );
  }
}
