<div>
  <span class="criteria-heading">{{'objectModule.location.protection_of_factory_obj.protection_of_the_factory_premises' | translate}}</span>
</div>

<form class="row object-module-html form-align" [formGroup]="protectionFactoryPremisesForm" id="protectionFactoryPremisesForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_surveillance_of_the_premises"
      class="long-label">{{'objectModule.location.protection_of_factory_obj.surveillance_of_the_premises' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_surveillance_of_the_premises" id="is_surveillance_of_the_premises">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.location.protection_of_factory_obj.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.location.protection_of_factory_obj.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_factory_security"
      class="long-label">{{'objectModule.location.protection_of_factory_obj.factory_security' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_factory_security" id="is_factory_security">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.location.protection_of_factory_obj.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.location.protection_of_factory_obj.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>



  <div [ngClass]="protectionFactoryPremisesForm.value.is_constant_gate_surveillance ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_constant_gate_surveillance">{{'objectModule.location.protection_of_factory_obj.constant_gate_surveillance'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="protectionFactoryPremisesForm.value.is_constant_gate_surveillance"
      formGroupName="constant_gate_surveillance">

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.protection_of_factory_obj.constant_gate_surveillance' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"  formControlName="constant_gas_surveillance" [title]="protectionFactoryPremisesForm.get('constant_gate_surveillance.constant_gas_surveillance').value">
        </div>

        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadConstantGateSurveillanceDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
      </div>

    </div>
  </div>

  <div [ngClass]="protectionFactoryPremisesForm.value.is_access_control ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_access_control">{{'objectModule.location.protection_of_factory_obj.access_control'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="protectionFactoryPremisesForm.value.is_access_control"
      formGroupName="access_control_doc">

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.protection_of_factory_obj.access_control' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"  formControlName="doc" [title]="protectionFactoryPremisesForm.get('access_control_doc.doc').value">
        </div>

        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadAccessControlDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
      </div>

    </div>
  </div>

  <div [ngClass]="protectionFactoryPremisesForm.value.is_enclosure_2m_high ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_enclosure_2m_high">{{'objectModule.location.protection_of_factory_obj.enclosure'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="protectionFactoryPremisesForm.value.is_enclosure_2m_high"
      formGroupName="enclosure_2m_high_doc">

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.protection_of_factory_obj.enclosure' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"  formControlName="doc" [title]="protectionFactoryPremisesForm.get('enclosure_2m_high_doc.doc').value">
        </div>

        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadEnclosureDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
      </div>

    </div>
  </div>

  <div [ngClass]="protectionFactoryPremisesForm.value.is_illumination_premises ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_illumination_premises">{{'objectModule.location.protection_of_factory_obj.illumination_of_the_premises'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="protectionFactoryPremisesForm.value.is_illumination_premises"
      formGroupName="illumination_premises_doc">

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.protection_of_factory_obj.illumination_of_the_premises' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"  formControlName="doc" [title]="protectionFactoryPremisesForm.get('illumination_premises_doc.doc').value">
        </div>

        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadIlluminationPremisesDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
      </div>

    </div>
  </div>

  <div [ngClass]="protectionFactoryPremisesForm.value.is_illumination_outer_walls_of_the_building_doc ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_illumination_outer_walls_of_the_building_doc">{{'objectModule.location.protection_of_factory_obj.illumination_of_the_enclosure'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="protectionFactoryPremisesForm.value.is_illumination_outer_walls_of_the_building_doc"
      formGroupName="illumination_outer_walls_of_the_building_doc">

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.protection_of_factory_obj.illumination_of_the_enclosure' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"  formControlName="doc" [title]="protectionFactoryPremisesForm.get('illumination_outer_walls_of_the_building_doc.doc').value">
        </div>

        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadIlluminationenclosureDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
      </div>

    </div>
  </div>

  <div [ngClass]="protectionFactoryPremisesForm.value.is_terrain_is_clear ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_terrain_is_clear">{{'objectModule.location.protection_of_factory_obj.terrain_is_clear'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content form-align" *ngIf="protectionFactoryPremisesForm.value.is_terrain_is_clear"
      formGroupName="terrain_is_clear">

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.protection_of_factory_obj.terrain_is_clear' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"  formControlName="doc" [title]="protectionFactoryPremisesForm.get('terrain_is_clear.doc').value">
        </div>

        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadTerrainClearDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
      </div>

    </div>
  </div>


</form>


<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails"
  [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()"
  (hide)="hidePopup($event)">
</app-upload-document>
