export const MS_TEMPLATES_COLUMN_HEADINGS = {
  NAME: 'rcs-setup.ms-template-management.name',
  CREATED_ON: 'rcs-setup.ms-template-management.created_on',
  LAST_UPDATED: 'rcs-setup.ms-template-management.last_updated',
  ACTION: 'rcs-setup.ms-template-management.action'
};

export enum MsTemplatesColumns {
  EXPAND = 'expand',
  SELECT = 'select',
  NAME = 'title',
  CREATED_ON = 'createdAt',
  LAST_UPDATED = 'updatedAt',
  ACTION = 'action'
}

export const MS_TEMPLATES_COLUMNS = [
  MsTemplatesColumns.EXPAND,
  MsTemplatesColumns.SELECT,
  MsTemplatesColumns.NAME,
  MsTemplatesColumns.CREATED_ON,
  MsTemplatesColumns.LAST_UPDATED,
  MsTemplatesColumns.ACTION
];
