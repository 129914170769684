import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
@Component({
  selector: 'app-foil-packaging',
  templateUrl: './foil-packaging.component.html',
  styleUrls: ['./foil-packaging.component.css'],
})
export class FoilPackagingComponent implements OnInit {
  foilPackagingForm: FormGroup;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.FOIL_PACKAGING
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          BuildingConstants.FOIL_PACKAGING
        ].saved_criteria
      );
    this.assignAttributeValues(formData);
    this.isFormValueUpdated = false;
    this.foilPackagingForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.foilPackagingForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  assignAttributeValues(formData: any) {
    const is_winding = new FormControl(
      {
        value: formData ? formData['foil-packaging'].is_winding : null,
        disabled: false,
      },
      []
    );
    const is_welding_with_hot_wire = new FormControl(
      {
        value: formData
          ? formData['foil-packaging'].is_welding_with_hot_wire
          : null,
        disabled: false,
      },
      []
    );
    const is_dist_combustible_materials_5m = new FormControl(
      {
        value: formData
          ? formData['foil-packaging'].is_dist_combustible_materials_5m
          : null,
        disabled: false,
      },
      []
    );
    const is_shrinking_with_open_flame = new FormControl(
      {
        value: formData
          ? formData['foil-packaging'].is_shrinking_with_open_flame
          : null,
        disabled: false,
      },
      []
    );
    const shrinking_with_open_flame = new FormControl(
      {
        value: formData
          ? formData['foil-packaging'].shrinking_with_open_flame
          : null,
        disabled: false,
      },
      []
    );
    const is_object_protection_available = new FormControl(
      {
        value: formData
          ? formData['foil-packaging'].is_object_protection_available
          : null,
        disabled: false,
      },
      []
    );
    const remarks = new FormControl(
      {
        value: formData ? formData['foil-packaging'].remarks : null,
        disabled: false,
      },
      []
    );
    const annotations = new FormControl({
      value: formData ? formData['foil-packaging'].annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData['foil-packaging'].is_annotation : null,
      disabled: false,
    });
    this.foilPackagingForm = this.fb.group({
      is_winding,
      is_welding_with_hot_wire,
      is_dist_combustible_materials_5m,
      is_shrinking_with_open_flame,
      shrinking_with_open_flame,
      is_object_protection_available,
      remarks,
      annotations,
      is_annotation,
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  buildFormObject() {
    const formValuesToPersist = {
      'foil-packaging': this.foilPackagingForm.value,
    };
    this.request = {
      criteria_id: BuildingConstants.FOIL_PACKAGING,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.foilPackagingForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
