<div>
    <span class="criteria-heading">{{'objectModule.location.organizational_fire_protection.organizational_fire_protection' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="organizationalFireProtectionForm" id="organizationalFireProtection">
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label"> {{'objectModule.location.organizational_fire_protection.order_cleanliness' | translate}} </label>
        <mat-select class="dropdownStyle"
          placeholder="{{'objectModule.select_lable' | translate}}" formControlName="order_cleanliness">
          <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
          <mat-option *ngFor="let item of orderCleanlinessData" [value]="item.id" >
              <span>{{item.desc}}</span>
            </mat-option>
        </mat-select>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.order_cleanliness' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}" 
            title="{{organizationalFireProtectionForm.controls['order_cleanliness_documents'].value}}" formControlName="order_cleanliness_documents">
        </div>
       
        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadOrderCleanlinessDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.safety_concept' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="safety_concept" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.binding_layer_thickness' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="binding_layer_thickness" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.certification_according_to_DIN' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="certification_according_to_DIN" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.planning_of_measures' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="planning_of_measures" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.stand_By_Service' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="stand_By_Service" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.servicing_maintenance' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="servicing_maintenance" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.operation_of_private_electrical_device' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="operation_of_private_electrical_device" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.key_control_and_locking' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="key_control_and_locking" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.fire_protection_officer' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="fire_protection_officer" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.fire_protection_regulations' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="fire_protection_regulations" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.fire_safety_exercises' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="fire_safety_exercises" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.instruction_monitoring_external_companies' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="instruction_monitoring_external_companies" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.rectification_defects' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="rectification_defects" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.fire_protection_recommendations' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="fire_protection_recommendations" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.fire_protection_advice' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="fire_protection_advice" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.smoking_is_prohibited' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="smoking_is_prohibited" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.use_of_hot_permit' | translate}}</label>
        <div style="display: flex;">
        <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}" 
            title="{{organizationalFireProtectionForm.controls['use_of_hot_permit'].value}}" formControlName="use_of_hot_permit">
        </div>
       
        <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadSmokingDocumentPopup()">
         <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.process_control_systems' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="process_control_systems" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div [ngClass]="organizationalFireProtectionForm.value.is_registration_accidents_at_work ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_registration_accidents_at_work">{{'objectModule.location.organizational_fire_protection.registration_accidents_at_work' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="organizationalFireProtectionForm.value.is_registration_accidents_at_work" class="row toggle-section-content">
            <div class="col-md-3 col-sm-6 col-6">
                <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.accidents_below_average' | translate}}</label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="accidents_below_average" >
                    <mat-radio-button [value]="true">
                        {{'objectModule.yes' | translate}}
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                        {{'objectModule.no' | translate}}
                    </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

        </div>
    </div>
</form>

<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" 
  [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()"
  (hide)="hidePopup($event)">
</app-upload-document>