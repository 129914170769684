import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CLAIMS_API } from '@shared/constants/claims/claims.constants';
import { GetLocksResponse } from '@shared/models/locks.model';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';

@Injectable({ providedIn: 'root' })
export class LocksApiService {
  constructor(private readonly _httpClient: HttpClient) {}

  updateCustomerLockState(data): Observable<any> {
    return this._httpClient.post(
      Api_URL.IC_API_URL + Api.UPDATE_CUSTOMER_LOCK_STATE,
      data
    );
  }

  updateClaimesLockState(data): Observable<any> {
    return this._httpClient.post(
      Api_URL.IC_API_URL + CLAIMS_API.CLAIMS_UPDATE_LOCK_STATE,
      data
    );
  }

  updateContractLockState(data): Observable<any> {
    return this._httpClient.post(
      Api_URL.IC_API_URL + Api.UPDATE_CONTRACT_LOCK_STATE,
      data
    );
  }

  getLocks(): Observable<GetLocksResponse[]> {
    return this._httpClient.get<GetLocksResponse[]>(
      Api_URL.IC_API_URL + Api.GET_LOCKS
    );
  }
}
