import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.css'],
})
export class WarningPopupComponent implements OnInit {
  @Output('handlePopup') hideModalValue = new EventEmitter<boolean>();
  @Output('savedata') savedata = new EventEmitter<boolean>();
  @Input('customerWarningMsg') warningMsg: CustomerWarningMsg;
  @Input('disableSave') disableSave;
  constructor() {}

  ngOnInit(): void {}
  hideModal() {
    this.hideModalValue.emit(false);
  }
  continueModal() {
    this.hideModalValue.emit(true);
  }
  saveAndContinue() {
    this.savedata.emit(true);
  }
}
