import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { GenericCriteriaConstants } from '@shared/constants/generic.constants';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { ShareDataService } from '@core/services/share-data.service';
export interface TabelElememntsInterface {
  customer: string;
  customer_type: string;
  last_updated: string;
  liztu_vn_kdnr: any;
  object_name: string;
  object_type: string;
  registration_date: any;
  object_module_id: any;
  record_id: any;
  object_type_id: any;
  note: any;
}
export interface ObjectModuleRequestInterface {
  address_ids: any;
  division_id: any;
  is_source_ivm: boolean;
  liztu_vn_kdnr: any;
  no_of_records: any;
  object_type_id: any;
  page_number: any;
  whole_group: any;
}
export interface CopyObjectModuleInterface {
  object_module_id: any;
  object_type_no: any;
  created_by: any;
  record_id: any;
  role: any;
}
@Component({
  selector: 'app-object-module',
  templateUrl: './object-module.component.html',
  styleUrls: ['./object-module.component.scss'],
})
export class ObjectModuleComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  user_role: USER_ROLES;
  tableData = [];
  customerFilterToggle = true;
  customFilterExpandedHeight = '48px';
  customerDataValue: any;
  userDataValue: any;
  customer_id: any;
  divisionName: any;
  total_elements: number;
  selectedCountOfRows = 25;
  tablePage = 1;
  numberOfRows = [25, 50, 75, 100, 125];

  formFilter: any;
  isICUser: boolean;
  decoded_liztu: any;
  liztu: string;
  subsidiaries: any;
  defaultSelectedCustomerValue: any;
  finaltype: any;
  finalCustomerName: any;
  orgid: any;
  user_idnr: any;
  selectedOrgId: any;
  selectedCustomer: any;
  selectedObject: any;
  objectData: any;
  object_type_data: any;
  row = 25;
  sortDir = 1;
  showWholeGroup = false;
  objecttype: any[];
  persistObject = false;
  showModal: boolean;
  deleteItem: any;
  deleteIndex: any;
  sortedData: unknown[];
  dir: string;
  deleteAPIResponse: string;
  copy_message: any;
  copy_message_error: any;
  decoded_loc: string;
  location: any = [];
  decoded_objectId: string;
  objectId: string = null;
  copyelement: any;
  copyIndex: any;
  showCopyModal: boolean;
  isCustomerSelected = true;
  pageBreadcrumbText = 'objectModule.ObjectManagement';
  pageBreadcrumbSubText = 'objectModule.overview';
  formImages = FORM_ICONS;
  totalObjects: number;
  customerId: string;
  customerName$ = this._customerSharedService.selectedCustomerName$;

  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    public validationService: ValidationService,
    private _toastService: ToastService,
    private translate: TranslateService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _shareDataService: ShareDataService
  ) {
    super();
    this.formFilter = this.fb.group({
      customer: [''],
      object: [''],
      showalldata: [''],
    });
  }

  isCustomerUser: boolean;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'object_name',
    'object_type',
    'note',
    'customer',
    'registration_date',
    'last_updated',
    'ACTION',
  ];
  ngOnInit(): void {
    this._watchOnUserRole();
  }
  customerDataReceived(data) {
    this.customerDataValue = data;
  }
  userDataReceived(data) {
    this.userDataValue = data;
    this.customer_id =
      this.userDataValue.user_profile.customer_details.customer_id;
    this.divisionName = data.user_profile.customer_details.name;

    this.decoded_liztu = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu = atob(this.decoded_liztu);
    this.decoded_loc = this.route.snapshot.queryParamMap.get('location');
    if (this.decoded_loc) {
      this.location = atob(this.decoded_loc);
      this.location = this.location.split`,`.map(x => +x);
    }
    this.decoded_objectId = this.route.snapshot.queryParamMap.get('object');
    if (this.decoded_objectId) {
      this.objectId = atob(this.decoded_objectId);
      this.formFilter.controls.object.setValue(this.objectId);
    }
    this.user_idnr = data.user_profile.user_data.user_idnr;
    this.user_idnr = this.user_idnr == null ? '' : this.user_idnr;

    this._customerSharedService.customerGeneralData$
      .pipe(
        filterNil(),
        take(1),
        mergeMap(customerGeneralData => {
          this.customerId = customerGeneralData.customer_info.customer_id;

          return this.serviceCall.getSubsidiaries(
            this.customerId,
            this.user_idnr,
            this.user_role,
            customerGeneralData.is_source_ivm
          );
        }),
        takeUntil(this._destroy$)
      )
      .subscribe(obj => {
        this.subsidiaries = obj.associated_customers;
        if (this.subsidiaries.length > 0) {
          for (const element of this.subsidiaries) {
            if (element.customer_id == this.customerId) {
              this.defaultSelectedCustomerValue = element;
              this._shareDataService.messageType = null;
              this.finaltype = this.defaultSelectedCustomerValue.type;
              this.finalCustomerName =
                this.defaultSelectedCustomerValue.customer_name;
              this.orgid = this.defaultSelectedCustomerValue.liztu_vn_kdnr;
            }
          }
          this.selectedCustomer = this.defaultSelectedCustomerValue;
        }
      });

    this.getObjectModuleData(
      this.liztu,
      this.row,
      1,
      this.showWholeGroup,
      this.objectId,
      this.location
    );
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  getObjectModuleData(
    liztuVnKdnr: string,
    totalRow: number,
    pagenumber: number,
    wholeGroup: boolean,
    objectTypeId: string,
    addressIds: number[]
  ) {
    this._customerSharedService.selectedSource$
      .pipe(
        filterNil(),
        take(1),
        mergeMap(isSourceIvm => {
          const formdata: ObjectModuleRequestInterface = {
            address_ids: addressIds,
            division_id: null,
            is_source_ivm: isSourceIvm,
            liztu_vn_kdnr: parseInt(liztuVnKdnr),
            no_of_records: totalRow,
            object_type_id: objectTypeId,
            page_number: pagenumber,
            whole_group: wholeGroup,
          };
          return this.serviceCall.getObjectModuleData(formdata);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe(data => {
        this.totalObjects = data.total_element;

        if (data.object_types) {
          this.object_type_data = data.object_types;
        }

        this.formatObjectData(data);
      });
  }
  formatObjectData(data: Object) {
    this.tableData = [];
    this.objectData = data;
    const total = this.totalObjects;
    this.total_elements =
      this.tablePage > 1 && this.objectData?.total_element == 0
        ? total
        : this.objectData.total_element || 0;
    if (this.objectData) {
      if (this.objectData.objects) {
        for (const element of this.objectData.objects) {
          const documentData: TabelElememntsInterface = {
            customer: element.customer_name,
            customer_type: element.customer_type,
            last_updated: element.last_updated_on,
            liztu_vn_kdnr: element.liztu_vn_kdnr,
            object_name: element.object_name,
            object_type: element.object_type,
            registration_date: element.reg_date,
            object_module_id: element.object_module_id,
            record_id: element.record_id,
            object_type_id: element.object_type_id,
            note: element.note,
          };
          this.tableData.push(documentData);
        }
      }
      this.dataSource.data = [...this.tableData];
      this.dataSource.sort = this.sort;
      this.dataSource
        .connect()
        .pipe(takeUntil(this._destroy$))
        .subscribe(d => (this.tableData = d));
    }
  }
  numberOfRowsChanged(value) {
    this.row = value.value;
    this.tablePage = 1;
    this.getObjectModuleData(
      this.liztu,
      value.value,
      this.tablePage,
      this.showWholeGroup,
      this.selectedObject,
      this.location
    );
  }
  tablePageChanged(event) {
    this.tablePage = event;
    this.getObjectModuleData(
      this.liztu,
      this.row,
      event,
      this.showWholeGroup,
      this.selectedObject,
      this.location
    );
  }
  getSelectedCustomer(item) {
    this.objecttype = [];
    this.serviceCall
      .getObjectType(item.value.liztu_vn_kdnr)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.object_types) {
          this.object_type_data = data.object_types;
        }
      });
    this.formFilter.controls.object.reset();
  }
  addNewRow(row, index) {
    const copyData: CopyObjectModuleInterface = {
      object_module_id: row.object_module_id,
      object_type_no: row.object_type_id,
      created_by: this.userDataValue.user_profile.user_data.user_id,
      record_id: row.record_id,
      role: this.user_role,
    };
    this.serviceCall
      .copyObjectModule(copyData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.copy_message = data.message;
          this.getObjectModuleData(
            this.liztu,
            this.row,
            this.tablePage,
            this.showWholeGroup,
            this.selectedObject,
            this.location
          );
        }
        this.showToastMessage(data.message);
        this.hideCopyModal();
      });
  }
  editRow(item) {
    const objectData: any = {
      liztu_vn_kdnr: item.liztu_vn_kdnr,
      object_type_no: item.object_type_id,
      object_type: item.object_type,
      object_module_id: item.object_module_id,
      object_type_id: item.object_type_id,
    };
    objectData.criteria_id = GenericCriteriaConstants[item.object_type_id];
    objectData.is_address_id_needed = true;

    this.serviceCall
      .getObjectData(objectData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          data.record_id = item.record_id;
          this.router.navigate([ROUTES_PATH.ADD_NEW_OBJECT], {
            state: data,
            queryParams: {
              customer: btoa(
                this._customerSharedService.getSelectedCustomerId()
              ),
              source: btoa(
                this._customerSharedService.getSelectedSource().toString()
              ),
              type: btoa(this._customerSharedService.getSelectedCustomerType()),
              liztu: this.decoded_liztu,
            },
          });
        }
      });
  }
  openModal(item, index) {
    this.deleteItem = item;
    this.deleteIndex = index;
    this.showModal = true;
  }
  openCopyModal(item, index) {
    this.copyelement = item;
    this.copyIndex = index;
    this.showCopyModal = true;
  }
  deleteRow(item, index) {
    if (this.user_role == USER_ROLES.IC_GLOBAL_ADMIN) {
      this.serviceCall
        .deleteObjectModule(item.object_module_id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this._toastService.showSlowToastMessage(document, data.message, 5000);
          if (!data.HasErrors) {
            this.getObjectModuleData(
              this.liztu,
              this.row,
              this.tablePage,
              this.showWholeGroup,
              this.selectedObject,
              this.location
            );
          }
        });
      this.showModal = false;
    } else {
      this._toastService.showSlowToastMessage(
        document,
        this.translate.instant('objectModule.permission_text'),
        4000
      );
      this.showModal = false;
    }
  }
  hideModal() {
    this.showModal = false;
  }
  hideCopyModal() {
    this.showCopyModal = false;
  }
  addNewObject() {
    this.router.navigate([ROUTES_PATH.ADD_NEW_OBJECT], {
      queryParams: {
        liztu: this.decoded_liztu,
        customer: btoa(this.customerId)
      },
    });
  }
  getFormsValue(value) {
    this.selectedCustomer = value.customer;
    this.liztu = value.customer.liztu_vn_kdnr;
    this.selectedObject = value.object;
    this.showWholeGroup = value.showalldata;
    this.persistObject = true;
    if (value.showalldata == '' || value.showalldata == null) {
      value.showalldata = false;
    }
    this.tablePage = 1;
    this.getObjectModuleData(
      value.customer.liztu_vn_kdnr,
      this.row,
      this.tablePage,
      value.showalldata,
      value.object,
      this.location
    );
  }
  resetFilter(value) {
    this.tablePage = 1;
    this.persistObject = false;
    this.showWholeGroup = false;
    this.selectedObject = null;
    this.formFilter.controls.object.reset();
    this.formFilter.controls.showalldata.reset();
    if (this.subsidiaries.length > 0) {
      for (const element of this.subsidiaries) {
        if (element.customer_id == this.customerId) {
          this.defaultSelectedCustomerValue = element;
          this.liztu = this.defaultSelectedCustomerValue.liztu_vn_kdnr;
          this.formFilter.controls.customer.setValue(
            this.defaultSelectedCustomerValue
          );
          this.getObjectModuleData(
            this.liztu,
            this.row,
            this.tablePage,
            this.showWholeGroup,
            this.objectId,
            this.location
          );
        }
      }
    }
  }
  onSortClick(event) {
    const target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-arrow-up')) {
      classList.remove('fa-arrow-up');
      classList.add('fa-arrow-down');
      this.sortDir = -1;
      this.dir = 'asc';
    } else {
      classList.add('fa-arrow-up');
      classList.remove('fa-arrow-down');
      this.sortDir = 1;
      this.dir = 'desc';
    }
  }
  sortData(sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.sortedData = data.sort((a: any, b: any) => {
      const A = a[sort.active].toUpperCase();
      const B = b[sort.active].toUpperCase();
      return !isAsc ? A.localeCompare(B) : B.localeCompare(A);
    });
    this.dataSource.data = Object.assign([], this.sortedData);
    this.cdr.detectChanges();
  }
  showToastMessage(message) {
    this._toastService.showToastMessage(document, message);
  }

  private _initRoleFields(): void {
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this._initRoleFields();
      });
  }
}
