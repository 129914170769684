import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
} from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { AZURE } from '../../../constants/azure-service.constants';
@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.css'],
})
export class AddressAutocompleteComponent implements OnInit {
  @Input('control') control = new FormControl('');
  @Output('address') address = new EventEmitter<any>();
  @Input('placeholder') placeholder = '';
  public keyUp = new Subject<string>();
  Alllocations: any[];
  filterlocation: any[];
  menuContent = false;
  selectedaddress: any;
  selectedLoc = '';
  lat: any;
  lon: any;
  showActive = false;
  showList = false;
  constructor(private serviceCall: ApiCallsService) {}

  ngOnInit(): void {
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100)))
      )
      .subscribe(this.autoComplete.bind(this));
  }
  onFocusOut(event) {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }
  autoComplete(value) {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .subscribe(res => {
          this.menuContent = true;
          this.Alllocations = res.results;
          this.filterAddressFromResults(this.Alllocations);
        });
    }
  }
  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
  }
  selectedLocation(e, data) {
    this.menuContent = false;
    this.selectedaddress = [this.filterlocation[e]];
    this.address.emit(this.selectedaddress);
    this.selectedLoc = this.filterlocation[e].freeformAddress;
    for (const element of this.Alllocations) {
      if (element.address.freeformAddress == this.selectedLoc) {
        if (element.position.lat !== undefined) {
          this.lat = element.position.lat;
        } else {
          this.lat = '';
        }
        if (element.position.lon !== undefined) {
          this.lon = element.position.lon;
        } else {
          this.lon = '';
        }
      }
    }
    if (this.lat !== '' && this.lon !== '') {
      this.showActive = true;
    } else {
      this.showActive = false;
    }
  }
}
