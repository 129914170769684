import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, take, takeUntil } from 'rxjs/operators';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { ContactPersonApiService } from '@core/services/httpcalls/contact-person-api.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { FEATURES } from '@shared/constants/string.constants';
import { BaseComponent } from '@shared/components/base.component';
import { CustomerManagementSharedService } from '../../customer-management-shared-service';
import { ActivatedRoute } from '@angular/router';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';

@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.scss'],
})
export class ContactPersonComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output() isContactPersonAddEdit = new EventEmitter<boolean>();
  @Output() contactPersonId = new EventEmitter<string>();

  @ViewChild('ContactPerson', { static: true })
  ContactPersonDropdown: MatSelect;
  customerRelationFilterToggle = true;
  customFilterExpandedHeight = '55px';
  numberOfRows = [25, 50, 75, 100, 125];
  selectedCountOfRows = 25;
  tablePage = 1;
  total_elements: number;
  selected_filter_value = null;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'name',
    'role',
    'main_contact_person',
    'comments',
    'action',
  ];
  row = 25;
  customer_roles = [];
  contact_persons_response: any;
  showModal: boolean;
  delete_item_id: any;
  actionButton = ACTION_BUTTON;
  readonly editCustomer$ =
    this._customerManagementSharedService.isEditCustomerOperation$;

  constructor(
    public validationService: ValidationService,
    private toastService: ToastService,
    private translate: TranslateService,
    private _cpAPICallsService: ContactPersonApiService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _activatedRoute: ActivatedRoute
  ) {
    super();
  }
  ngOnInit(): void {
    this.apiCalls();
  }
  apiCalls(): void {
    this._customerSharedService.selectedCustomerId$
      .pipe(
        filterNil(),
        take(1),
        mergeMap(customerId => {
          const requests = [];
          const getContactPersonList = {
            sourceId: customerId,
            roleId: this.selected_filter_value,
            page: this.tablePage,
            pageRecordSize: this.row,
            pagination: true,
            sourceType: FEATURES.CUSTOMER,
          };
          requests.push(
            this._cpAPICallsService
              .getContactPersonRoles(FEATURES.CUSTOMER)
              .pipe(catchError(error => of(error)))
          );
          requests.push(
            this._cpAPICallsService
              .getContactPersonOverview(getContactPersonList)
              .pipe(catchError(error => of(error)))
          );

          return forkJoin(requests);
        })
      )
      .subscribe((data: any) => {
        this.customer_roles =
          !data[0].hasErrors && data[0].customer_roles
            ? data[0].customer_roles
            : [];
        this.contact_persons_response =
          !data[1].hasErrors && data[1] ? data[1] : [];
        this.dataSource.data = this.contact_persons_response.contactPersons;
        this.dataSource.data?.forEach((element: any) => {
          if (element.is_main_contact_person) {
            element.is_main_contact_person =
              this.translate.instant('objectModule.Yes');
          } else if (element.is_main_contact_person == false) {
            element.is_main_contact_person =
              this.translate.instant('objectModule.No');
          }
        });
        this.total_elements = this.contact_persons_response.totalElement;
      });
  }
  numberOfRowsChanged(value): void {
    this.row = value.value;
    this.tablePage = 1;
    this.apiCalls();
  }
  tablePageChanged(event): void {
    this.tablePage = event;
    this.apiCalls();
  }
  resetFilters(): void {
    this.selected_filter_value = null;
    this.apiCalls();
  }
  filterContactPerson(): void {
    this.apiCalls();
  }
  edit(id): void {
    this.contactPersonId.emit(id);
    this.isContactPersonAddEdit.emit(true);
  }
  delete(id): void {
    this._cpAPICallsService
      .deleteContactPersonById(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.toastService.showToastMessage(document, data.message);
        this.hideModal();
        this.apiCalls();
      });
  }
  openModal(id): void {
    this.delete_item_id = id;
    this.showModal = true;
  }
  hideModal(): void {
    this.delete_item_id = null;
    this.showModal = false;
  }
  relationValueChanged(): void {
    setTimeout(() => this.ContactPersonDropdown.focus(), 150);
  }
  focusSearch(id): void {
    document.getElementById(id).focus();
  }
}
