import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { Directive } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { NumberValidationsService } from '@core/services/validations/number-validations.service';
import { STATIC_TEXT } from '@shared/constants/string.constants';
import { AZURE } from '@shared/constants/azure-service.constants';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';

@Directive()
export class GeneralClaimDataService extends BaseComponent {
  isIvmProduct: boolean;
  allCurrencyValues = [];
  cause_of_claim = [];
  cause_of_claim_lookup = [];
  currencyTypetemp = [];
  generalClaimsDetailsForm: FormGroup;
  initialFormState = null;
  isValueChangedInForm = false;
  payment1error1 = false;
  payment2error1 = false;
  payment3error1 = false;
  payment4error1 = false;
  payment1error2 = false;
  payment2error2 = false;
  payment3error2 = false;
  payment4error2 = false;
  showWarningPopUp = false;
  Alllocations: any;
  filterlocation = [];
  selectedaddress: any;
  selectedLoc = '';
  lat: any;
  lon: any;
  dateOfClaimDateError = false;
  NotificationDateError = false;
  showList = false;
  currancySymbol = null;
  selectedCurrency: any;
  hasChange = false;
  currencyTypeSearchText = new FormControl();
  showActive = false;
  menuContent = false;
  currencyArray: any[] = [];

  formError = false;

  constructor(
    public serviceCall: ApiCallsService,
    public _validationService: ValidationService,
    public _currencyCustomPipe: CurrencyCustomPipe,
    public numberValidation: NumberValidationsService
  ) {
    super();
  }

  _filterCurrency(value: any) {
    this.allCurrencyValues = this.currencyArray;

    if (value !== '') {
      this.allCurrencyValues =
        this.allCurrencyValues.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        ) || this.currencyArray;
    }
  }
  getCauseOfClaim() {
    this.serviceCall
      .getCauseOfClaim()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.cause_of_claim = data.data;
        this.cause_of_claim.map(element => {
          this.cause_of_claim_lookup.push(element.desc);
        });
      });
  }
  getCurrency() {
    this.serviceCall
      .getCurrencies()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.allCurrencyValues = data.currency_types;
        this.currencyTypetemp = data.currency_types;
        this.currencyArray = this.allCurrencyValues;
      });
  }
  changeDetection() {
    const currentFormState = this.setControls();
    return (
      JSON.stringify(this.initialFormState) !== JSON.stringify(currentFormState)
    );
  }
  setControls() {
    const controlValues = {};
    Object.keys(this.generalClaimsDetailsForm.controls).forEach(control => {
      controlValues[control] = this.generalClaimsDetailsForm.get(control).value;
    });
    return controlValues;
  }
  onTypeNewValue(event, key, name?) {
    this.numberValidation.restrictCharactersInNumericFieldBrowserSpecific(
      event,
      key,
      11
    );
  }
  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length === 2) {
        event.preventDefault();
      }
    }
  }
  changeNumber(event, name) {
    const count = event.split(',').length - 1;
    if (name === 'amount_of_loss') {
      if (count > 1) {
        this.payment1error2 = true;
      } else {
        this.payment1error2 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name === 'amount_of_loss') {
        this.payment1error1 = true;
      } else {
        this.payment1error1 = false;
      }

      if (
        this.payment1error1 ||
        this.payment1error2 ||
        this.payment2error1 ||
        this.payment2error2 ||
        this.payment3error1 ||
        this.payment3error2 ||
        this.payment4error1 ||
        this.payment4error2
      ) {
        this.formError = true;
      } else {
        this.formError = false;
      }

      if (event) {
        this.generalClaimsDetailsForm.controls.amount_of_loss.setValue(
          this._currencyCustomPipe
            .transform(
              Number(event.toString().replaceAll('.', '').replaceAll(',', '.'))
            )
            ?.replace('€', '')
            ?.trim()
        );
      }
    }

    if (name === 'deductable_amount') {
      if (count > 1) {
        this.payment2error2 = true;
      } else {
        this.payment2error2 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name === 'deductable_amount') {
        this.payment2error1 = true;
      } else {
        this.payment2error1 = false;
      }

      if (
        this.payment1error1 ||
        this.payment1error2 ||
        this.payment2error1 ||
        this.payment2error2 ||
        this.payment3error1 ||
        this.payment3error2 ||
        this.payment4error1 ||
        this.payment4error2
      ) {
        this.formError = true;
      } else {
        this.formError = false;
      }

      if (event) {
        this.generalClaimsDetailsForm.controls.deductable_amount.setValue(
          this._currencyCustomPipe
            .transform(
              Number(event.toString().replaceAll('.', '').replaceAll(',', '.'))
            )
            ?.replace('€', '')
            ?.trim()
        );
      }
    }
    if (name === 'ammount_not_eligible_for_settlement') {
      if (count > 1) {
        this.payment3error2 = true;
      } else {
        this.payment3error2 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (
        splitValue[0].length > 9 &&
        name === 'ammount_not_eligible_for_settlement'
      ) {
        this.payment3error1 = true;
      } else {
        this.payment3error1 = false;
      }

      if (
        this.payment1error1 ||
        this.payment1error2 ||
        this.payment2error1 ||
        this.payment2error2 ||
        this.payment3error1 ||
        this.payment3error2 ||
        this.payment4error1 ||
        this.payment4error2
      ) {
        this.formError = true;
      } else {
        this.formError = false;
      }
      if (event) {
        this.generalClaimsDetailsForm.controls.ammount_not_eligible_for_settlement.setValue(
          this._currencyCustomPipe
            .transform(
              Number(event.toString().replaceAll('.', '').replaceAll(',', '.'))
            )
            ?.replace('€', '')
            ?.trim()
        );
      }
    }
    if (name === 'reserve') {
      if (count > 1) {
        this.payment4error2 = true;
      } else {
        this.payment4error2 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name === 'reserve') {
        this.payment4error1 = true;
      } else {
        this.payment4error1 = false;
      }

      if (
        this.payment1error1 ||
        this.payment1error2 ||
        this.payment2error1 ||
        this.payment2error2 ||
        this.payment3error1 ||
        this.payment3error2 ||
        this.payment4error1 ||
        this.payment4error2
      ) {
        this.formError = true;
      } else {
        this.formError = false;
      }

      if (event) {
        this.generalClaimsDetailsForm.controls.reserve.setValue(
          this._currencyCustomPipe
            .transform(
              Number(event.toString().replaceAll('.', '').replaceAll(',', '.'))
            )
            ?.replace('€', '')
            ?.trim()
        );
      }
    }
  }
  targetValue(event) {
    if (event) {
      const data = { label: event };
      this.serviceCall
        .addCauseOfClaim(data)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() =>
          this.getCauseOfClaim()
        );
    }
  }
  showWarningPopup() {
    this.showWarningPopUp = true;
  }
  currancyChangedTwo(event) {}
  currencyTypeSearch(id) {
    setTimeout(() => document.getElementById(id).focus(), 200);
  }
  resetList() {
    this.currencyTypeSearchText.setValue('');
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') !== -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }

  dateClickedclaim() {
    this.dateOfClaimDateError = false;
  }
  dateChangeclaim(event) {
    this.dateOfClaimDateError = this._validationService?.dateValidation(event);
    if (this.dateOfClaimDateError) {
      this.generalClaimsDetailsForm.controls.date_of_claim.setErrors({
        invalid: true,
      });
    } else {
      this.generalClaimsDetailsForm.controls.date_of_claim.setErrors({
        invalid: false,
      });
    }
  }
  dateClickedNotification() {
    this.NotificationDateError = false;
  }
  dateChangeNotification(event) {
    this.NotificationDateError = this._validationService?.dateValidation(event);
    if (this.NotificationDateError) {
      this.generalClaimsDetailsForm.controls.notification_date.setErrors({
        invalid: true,
      });
    } else {
      this.generalClaimsDetailsForm.controls.notification_date.setErrors({
        invalid: false,
      });
    }
  }
  onFocusOut() {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  toggleFormState(methodName: string) {
    if (
      this.generalClaimsDetailsForm &&
      typeof this.generalClaimsDetailsForm[methodName] === STATIC_TEXT.FUNCTION
    ) {
      this.generalClaimsDetailsForm[methodName]();
    }
  }
  disableFormControls(): void {
    this.generalClaimsDetailsForm.controls[
      this.isIvmProduct ? 'type_of_claim_rcs' : 'type_of_claim_ivm'].disable();
    this.generalClaimsDetailsForm.controls.sum_of_payments.disable();
    if (!this.generalClaimsDetailsForm.controls.police_report.value) {
      this.generalClaimsDetailsForm.controls.police_fire_number.disable();
    }
  }
  onCreateGroupFormValueChange(initialValue) {
    this.hasChange = Object.keys(initialValue).some(
      key => this.generalClaimsDetailsForm.value[key] !== initialValue[key]
    );
  }
  currancyChanged(event?, key?) {
    this.getCurrency();
    const currency = this.currencyArray;
    const value = event
      ? currency.find(obj => obj.cur_code === event).id
      : currency.find(obj => obj.cur_symbol === 'EUR').id;
    this.currancySymbol = currency.find(obj => obj.id === value).cur_code;
    this.selectedCurrency = currency.find(obj => obj.id === value).cur_symbol;
    this.generalClaimsDetailsForm.controls.currency_type.setValue(
      this.currancySymbol
    );
    this.currencyTypeSearchText.setValue('');
  }

  autoComplete(value) {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          res => {
            this.Alllocations = res.results;
            this.filterAddressFromResults(this.Alllocations);
            this.menuContent = true;
          },
        );
    } else {
      this.menuContent = false;
    }
  }
  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
  }
  selectedLocation(e, data) {
    this.menuContent = false;
    this.selectedaddress = [this.filterlocation[e]];
    this.selectedLoc = this.filterlocation[e].freeformAddress;
    this.generalClaimsDetailsForm.controls.claim_location.setValue(data);
    for (const element of this.Alllocations) {
      if (element.address.freeformAddress === this.selectedLoc) {
        if (element.position.lat !== undefined) {
          this.lat = element.position.lat;
        } else {
          this.lat = '';
        }
        if (element.position.lon !== undefined) {
          this.lon = element.position.lon;
        } else {
          this.lon = '';
        }
      }
    }
    if (this.lat !== '' && this.lon !== '') {
      this.showActive = true;
    } else {
      this.showActive = false;
    }
  }
}
