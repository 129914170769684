import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api_URL } from '@shared/constants/url.constants';
import { API_ENDPOINT_URL } from './constants';

@Injectable({
  providedIn: 'root',
})
export class CommunicationApiCallsService {
  constructor(private http: HttpClient) {}

  addEditCommunicationData(request): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.EDIT_COMMUNICATION_DATA,
      request
    );
  }
  getCommunicationData(id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_COMMUNICATION_DATA + '/' + id
    );
  }
}
