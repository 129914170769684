import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DashboardItem,
  DashboardItemId,
  ReorderWidgetsRequest,
  Widget,
  WidgetAuthorization,
  WidgetAuthorizationId,
  WidgetId,
} from '@shared/models/widget.model';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';

@Injectable({ providedIn: 'root' })
export class WidgetsApiService {
  constructor(private readonly _httpClient: HttpClient) {}

  getDashboardItems(userId: string): Observable<DashboardItem[]> {
    const params: HttpParams = new HttpParams().append('userId', userId);
    return this._httpClient.get<DashboardItem[]>(
      Api_URL.IC_API_URL + Api.DASHBOARD_ITEMS,
      {
        params,
      }
    );
  }

  getWidgets(
    isCustomerUserAccessibleOnly?: boolean,
    userId?: number
  ): Observable<Widget[]> {
    let params = new HttpParams();
    if (isCustomerUserAccessibleOnly) {
      params = params.append('customerUserAccessibleOnly', 'true');
    }

    if (userId) {
      params = params.append('userId', userId.toString(10));
    }
    return this._httpClient.get<Widget[]>(
      Api_URL.IC_API_URL + Api.GET_WIDGETS,
      { params }
    );
  }

  updateOrder(widgets: ReorderWidgetsRequest[]): Observable<DashboardItem[]> {
    return this._httpClient.post<DashboardItem[]>(
      `${Api_URL.IC_API_URL}${Api.DASHBOARD_ITEMS}/reordered`,
      widgets
    );
  }

  subscribe(
    id: DashboardItemId,
    position: number,
    userId: number,
    widgetId: number
  ): Observable<DashboardItem> {
    const body = { id, position, userId, widgetId };
    return this._httpClient.post<DashboardItem>(
      Api_URL.IC_API_URL + Api.DASHBOARD_ITEMS,
      body
    );
  }

  unsubscribe(id: WidgetId): Observable<null> {
    return this._httpClient.delete<null>(
      `${Api_URL.IC_API_URL}${Api.DASHBOARD_ITEMS}/${id}`
    );
  }

  getWidgetAuthorizations(userId: number): Observable<WidgetAuthorization[]> {
    const params = new HttpParams().append('userId', userId.toString(10));
    return this._httpClient.get<WidgetAuthorization[]>(
      `${Api_URL.IC_API_URL}${Api.WIDGET_AUTHORIZATIONS}`,
      { params }
    );
  }

  addWidgetAuthorization(
    userId: number,
    widgetId: WidgetId
  ): Observable<WidgetAuthorization> {
    const body = { userId, widgetId };
    return this._httpClient.post<WidgetAuthorization>(
      Api_URL.IC_API_URL + Api.WIDGET_AUTHORIZATIONS,
      body
    );
  }

  removeWidgetAuthorization(
    widgetAuthorizationId: WidgetAuthorizationId
  ): Observable<null> {
    return this._httpClient.delete<null>(
      `${Api_URL.IC_API_URL}${Api.WIDGET_AUTHORIZATIONS}/${widgetAuthorizationId}`
    );
  }
}
