<app-header (emitUserData)="userDataReceived($event)" (emitCustomerData)="customerDataReceived($event)"></app-header>

<div class="container-ic objectModule">
  <div class="object-container">
    <div class="flex-common">
      <app-breadcrumbs [isCustomerUser]="isCustomerUser" [isCustomerSelected]="isCustomerSelected"
        [pageBreadcrumbText]="pageBreadcrumbText" [pageBreadcrumbSubText]="pageBreadcrumbSubText"
        [divisionName]="divisionName" [customerName]="customerName$ | async">
      </app-breadcrumbs>
      <button class="custom-btn save-btn add-new-feature-btn" (click)="addNewObject()">
          <img [src]="formImages.ADD" alt="">
          <span>{{'objectModule.add_new_button' | translate}}</span>
      </button>
    </div>

    <div class="filter-distance">
      <mat-accordion>
        <mat-expansion-panel class="filter-section" (opened)="customerFilterToggle = true" (closed)="customerFilterToggle = false" 
          [expanded]="customerFilterToggle" hideToggle>
          <mat-expansion-panel-header [expandedHeight]="customFilterExpandedHeight" class="filter-section-header p-unset">
            <mat-panel-title class="filter-section-title-alignment filter-title">
              <div class="flex-center g-7">
                <button  class="action-btn">
                  <mat-icon *ngIf="customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="!customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_right</mat-icon>
                </button>
                <span>
                  <strong>FILTER</strong>
                </span>
              </div>
              <div (click)="$event.stopPropagation()" class="paginator-dropdown-container">
                <mat-select 
                  class="paginator-dropdown filter-section-dropdown"
                  (selectionChange)="numberOfRowsChanged($event)" 
                  [(ngModel)]="selectedCountOfRows"
                  panelClass="rowChange" 
                  disableOptionCentering>
                  <mat-option *ngFor="let row of numberOfRows" [value]="row">
                    {{row}}
                  </mat-option>
                </mat-select>
                <span class="results-per-page">
                  {{'claims.results_per_page' | translate}}
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="formFilter" class="flex-common f-13">
              <div class="d-flex gap-3 my-2">
                <div class="vertically-center g-7">
                  <span class="span-label">{{'invite-user.Kunde_label' | translate}}</span>
                  <mat-select (selectionChange)="getSelectedCustomer($event)"
                    [(ngModel)]="defaultSelectedCustomerValue"
                    placeholder="{{'objectModule.Subsidiary_company_placeholder' | translate}}"
                    formControlName="customer" (selectionChange)="searchCustomer.value = ''"
                    (openedChange)="searchCustomer.value = ''">
                    <input type="text" maxlength="20" preventSpace class="searchSelect"
                      placeholder="{{'header.Suche_tooltip' | translate}}" #searchCustomer>
                    <mat-option *ngFor="let sub of validationService._filter(subsidiaries, 'customer_name', searchCustomer.value)"
                      matTooltip="{{sub.customer_name}} - {{sub.type}}" [value]="sub">
                      <span *ngIf="sub.customer_name " class="noSafariTooltip">{{sub.customer_name}} -
                        {{sub.type | uppercase}}
                      </span>
                      <span *ngIf="!sub.customer_name " class="noSafariTooltip">{{sub.customer_name}} -
                        {{sub.type |
                        uppercase}}
                      </span>
                    </mat-option>
                  </mat-select>
                </div>
                <div class="vertically-center g-7">
                  <span class="span-label">{{'objectModule.Object' | translate}}</span>
                  <mat-select placeholder="{{'objectModule.object_placeholder' | translate}}" formControlName="object"
                    (selectionChange)="search.value = ''" (openedChange)="search.value = ''">
                    <input type="text" maxlength="20" preventSpace class="searchSelect"
                      placeholder="{{'header.Suche_tooltip' | translate}}" #search>
                    <mat-option *ngFor="let ele of validationService._filter(object_type_data, 'object_name', search.value)"
                      [value]="ele.object_type_id">{{ele.object_name}}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="vertically-center g-7">
                  <mat-checkbox formControlName="showalldata"  class="custom-checkBox check1">
                    {{'objectModule.show_all_data_text' | translate}}
                  </mat-checkbox>
                </div>
              </div>
              <div class="flex-center filter-btn-gap">
                <button class="filterBtn" (click)="getFormsValue(formFilter.value)" type="submit">
                  {{'user-data.Filter_text' | translate}}
                </button>
                <button class="filterBtn"
                  placement="top" tooltipClass="generic-tooltip"
                  ngbTooltip="{{'new-document.Filter_löschen' | translate}}"
                  (click)="resetFilter(formFilter.value)">
                  <img alt="" height="16px" src="assets/images/Icon material-refresh.png" />
                </button>
              </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    
    <div class="table-outer-container">
      <div class="infiniteScrollTable object__table">
          <mat-table class="table custom-table" matSort [dataSource]="dataSource"
            (matSortChange)="sortData($event)" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="object_name">
              <mat-header-cell *matHeaderCellDef>
                <span> {{'objectModule.object_name' | translate}} 
                  <img alt="" mat-sort-header="object_name" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.object_name}}">
                  {{element.object_name}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="object_type">
              <mat-header-cell *matHeaderCellDef>
                <span> {{'objectModule.object_type' | translate}} 
                  <img alt="" mat-sort-header="object_type" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.object_type}}">
                  {{element.object_type}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="note">
              <mat-header-cell *matHeaderCellDef>
                <span>{{'objectModule.note' | translate}} 
                  <img alt="" mat-sort-header="note" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <span class="custom-ellipsis noSafariTooltip object__note" matTooltip="{{element.note}}">
                  {{element.note}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="customer">
              <mat-header-cell *matHeaderCellDef>
                <span>{{'objectModule.company' | translate}} 
                  <img alt="" mat-sort-header="customer" class="arrow-image"
                    src="assets/images/ic-icons/Sort.svg">
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.customer}}">
                  {{element.customer}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="registration_date">
              <mat-header-cell *matHeaderCellDef>
                <span>{{'objectModule.registration_date' | translate}} 
                  <img alt="" mat-sort-header="registration_date" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <span class="custom-ellipsis noSafariTooltip"
                  matTooltip="{{element.registration_date | dateFormat:'dd.MM.yyyy HH:mm'}}">
                  {{element.registration_date | dateFormat:'dd.MM.yyyy HH:mm'}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_updated">
              <mat-header-cell *matHeaderCellDef>
                <span>{{'objectModule.last_updated' | translate }} 
                  <img alt="" mat-sort-header="last_updated" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <span class="custom-ellipsis noSafariTooltip"
                  matTooltip="{{element.last_updated | dateFormat:'dd.MM.yyyy HH:mm'}}">
                  {{element.last_updated | dateFormat:'dd.MM.yyyy HH:mm'}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ACTION">
              <mat-header-cell *matHeaderCellDef class="pr-0">
                <span>{{'objectModule.Aktion' | translate}}</span>
              </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;">
                <div class="control-section" (click)="$event.stopPropagation()">
                  <div class="subscribe">
                    <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                    <div class="hide">
                      <div class="action-btn-align">
                        <button class="action-btn"
                          placement="top"
                          ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                          tooltipClass="generic-tooltip"
                          (click)="editRow(element)">
                          <img alt="" class="image-icon" src='assets/images/Group 2659.svg'>
                        </button>
                        <button class="action-btn"
                          placement="top"
                          ngbTooltip="{{'objectModule.COPY_tooltip'| translate}}"
                          tooltipClass="generic-tooltip"
                          (click)="openCopyModal(element,i)">
                          <img alt="" class="image-icon" src='assets/images/Duplicate_icon.svg'>
                        </button>
                        <button class="action-btn"
                          placement="top"
                          ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                          tooltipClass="generic-tooltip"
                          (click)="openModal(element,i)">
                            <img alt="" class="image-icon" src='assets/images/Group 2658.svg'>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row (click)="editRow(row)" *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
      </div>
      <div class="table-footer">
        <div class="paginator-footer">
          <ngb-pagination [collectionSize]="total_elements" (pageChange)="tablePageChanged($event)" [maxSize]=5
            [(page)]="tablePage" [pageSize]="selectedCountOfRows" [boundaryLinks]="false">
            <ng-template ngbPaginationPrevious>
              <i class="fa fa-angle-left"></i>
            </ng-template>
            <ng-template ngbPaginationNext>
              <i class="fa fa-angle-right"></i>
            </ng-template>
          </ngb-pagination>
          <div class="footer-text">
            <span>{{ "objectModule.totalobject_text" | translate }} : {{ total_elements }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="doc-modal modal modelBackground" id="deleteDocumentModel"
  [style.display]="showModal ? 'table' : 'none'">
  <div class="modal-dialog doc-modal__dialog" >
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-content doc-modal__content">
        <div class="modal-header doc-modal__header">
          <div class="text-center doc-modal__header-text">
            <mat-icon (click)="hideModal()">
              <img alt="" height="65px" src="../../../assets/images/Group 3235.svg">
            </mat-icon>
          </div>
        </div>
        <div class="modal-body doc-modal__body">
          <span class="doc-modal__title">{{'objectModule.Delete_popup_heading' | translate}}</span>
          <br /><br />
          <span class="doc-modal__subtitle">{{'objectModule.Delete_popup_text' |
            translate}}</span>
        </div>
        <div class="modal-footer ml-auto mr-auto doc-modal__footer">
          <button class="doc-modal__button" type="button" mat-button (click)="hideModal()">
            {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp;
          <button class="ml-2 doc-modal__button doc-modal__button--primary" type="button"
            mat-button (click)="deleteRow(deleteItem,deleteIndex)">
            {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modelBackground doc-modal" id="copyDocumentModel"
  [style.display]="showCopyModal ? 'table' : 'none'">
  <div class="modal-dialog doc-modal__dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-content doc-modal__content">
        <div class="modal-header doc-modal__header">
          <div class="text-center doc-modal__header-text">
            <mat-icon (click)="hideCopyModal()">
              <img alt="" height="65px" src="../../../assets/images/copy_confirmation_warning_icon.svg">
            </mat-icon>
          </div>
        </div>
        <div class="modal-body doc-modal__body">
          <span class="doc-modal__title">{{'objectModule.Delete_popup_heading' | translate}}</span>
          <br /><br />
          <span class="doc-modal__subtitle">{{'objectModule.Copy_popup_text' |
            translate}}</span>
        </div>
        <div class="modal-footer ml-auto mr-auto doc-modal__footer">
          <button class="doc-modal__button" type="button" mat-button (click)="hideCopyModal()">
            {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp;
          <button class="ml-2 doc-modal__button doc-modal__button--primary" type="button"
            mat-button (click)="addNewRow(copyelement,copyIndex)">
            {{'objectModule.COPY_tooltip' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>