import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from '@core/services/share-data.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { URLS } from '@shared/constants/url.constants';
import { CommunicationService } from '@core/services/communication.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { UserService } from '@core/user.service';
import { AddNewContractDialogComponent } from '../../modules/new-contract/add-new-contract-dialog/add-new-contract-dialog.component';
import { SearchComponent } from '../search/search.component';
import {
  CONTRACTS_ROUTES,
  CUSTOMER_MANAGEMENT_ROUTES,
  ROUTES_PATH,
  TODO_MANAGEMENT_ROUTES,
} from '@shared/constants/router.constants';
import { HEADER_ICONS } from '@shared/constants/image-paths/header.constants';
import { STATIC_TEXT } from '@shared/constants/string.constants';
import { BaseComponent } from '@shared/components/base.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@core/services/httpcalls/auth.service';
import { filter, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import * as moment from 'moment';
import { iif } from 'rxjs';
import { ToastService } from '@core/services/toast.service';
import { IC_ICONS } from '@shared/constants/image-paths/ic-icons.constant';
import { faCoffee, faCalendar, faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
export interface UpdateElement {
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Output() emitUserData = new EventEmitter<any>();
  @Output() emitsubData = new EventEmitter<any>();
  @Output() emitCustomerData = new EventEmitter<any>();
  @Output() emitClaimsDataChange = new EventEmitter<any>();

  user_data;
  subsidiaries = [];
  user_role;
  customer_id;
  userLogo = false;
  logo;
  is_source_ivm: boolean;
  defaultSelectedCustomerValue: any;
  isMenuCollapsed = true;
  disbaleMenuIcon = false;
  user_idnr_local;
  encoded_liztu_vn_kdnr: string;
  liztu: string;
  liz: string;
  claimsActive = false;
  contractsActive = false;
  DocumentActive = false;
  CRactive = false;
  CLactive = false;
  CActactive = false;
  CActCreateactive = false;
  urlClaims: boolean;
  encoded_contract_number: string;
  contractNumber;
  isCustomerUser: boolean;
  masterActive = false;
  objectActive = false;
  OBactive = false;
  OBnewactive = false;
  isICRoles = false;
  isCustomerRoles = false;
  headerIcons = HEADER_ICONS;
  routesPath = ROUTES_PATH;
  todoRoutes = TODO_MANAGEMENT_ROUTES;
  userRoles = USER_ROLES;
  greeting = this._getGreeting();

  IC_ICONS = IC_ICONS;
  faCoffee = faCommentDots

  constructor(
    private serviceCall: ApiCallsService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    public translate: TranslateService,
    private readonly _shareService: ShareDataService,
    private domSanitizer: DomSanitizer,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _userService: UserService,
    private readonly _securityApiService: AuthService,
    private readonly _toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.urlClaims =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CLAIMS) !== -1;
    this.encoded_contract_number =
      this.route.snapshot.queryParamMap.get('contract');
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParamMap.get('liztu');
    const contractNumber = this.encoded_contract_number
      ? atob(this.encoded_contract_number)
      : null;
    this.liztu = this.encoded_liztu_vn_kdnr
      ? atob(this.encoded_liztu_vn_kdnr)
      : contractNumber;
    this.liz = this.liztu ? btoa(this.liztu) : null;

    this.loadUserData();
    const currentUrlContracts =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CONTRACT) !== -1;
    const currentUrlNewContract =
      this.router.url
        .toLocaleLowerCase()
        .indexOf(ROUTES_PATH.ADD_NEW_CONTRACT) !== -1;
    const newContract =
      !this.router.url.toLocaleLowerCase().includes('contract=') &&
      currentUrlNewContract;
    if (currentUrlContracts || currentUrlNewContract || newContract) {
      if (currentUrlContracts) {
        this.CActactive = true;
      }

      if (newContract) {
        this.CActCreateactive = true;
        this.CActactive = false;
      }
      this.contractsActive = true;
    } else {
      this.contractsActive = false;
    }
    const currentUrlClaimsReporting: boolean =
      this.router.url
        .toLocaleLowerCase()
        .indexOf(ROUTES_PATH.CLAIM_REPORTING) !== -1;
    const currentUrlClaims: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CLAIMS) !== -1;
    if (currentUrlClaimsReporting || currentUrlClaims) {
      if (currentUrlClaimsReporting) {
        this.CRactive = true;
      }
      if (currentUrlClaims) {
        this.CLactive = true;
      }
      this.claimsActive = true;
      this.contractsActive = false;
      this.CActactive = false;
      this.CActCreateactive = false;
    }
    const currentUrlUser: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.USERS) !== -1;
    const currentUrlCustomerActive: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CUSTOMER_EDIT) !=
      -1;
    if (currentUrlUser || currentUrlCustomerActive) {
      this.masterActive = true;
    } else {
      this.masterActive = false;
    }
    const currentUrlObejct: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.OBJECT_MODULE) !=
      -1;
    const currentUrlValueModule: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.VALUE_MODULE) !=
      -1;
    const currentUrlObjectNewActive: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.ADD_NEW_OBJECT) !=
      -1;
    if (
      currentUrlObejct ||
      currentUrlObjectNewActive ||
      currentUrlValueModule
    ) {
      if (currentUrlObejct) {
        this.objectActive = true;
        this.OBactive = true;
      }
      if (currentUrlObjectNewActive) {
        this.objectActive = true;
        this.OBactive = true;
      }
      if (currentUrlValueModule) {
        this.objectActive = true;
        this.OBnewactive = true;
      }
    } else {
      this.objectActive = false;
    }
    const currentUrlDocument: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.MORE_DOCUMENT) !=
      -1;
    if (currentUrlDocument) {
      this.DocumentActive = true;
    } else {
      this.DocumentActive = false;
    }
  }
  ngOnChanges(): void {
    const currentUrlClaimsReporting: boolean =
      this.router.url
        .toLocaleLowerCase()
        .indexOf(ROUTES_PATH.CLAIM_REPORTING) !== -1
        ? true
        : false;
    const currentUrlClaims: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CLAIMS) !== -1
        ? true
        : false;
    if (currentUrlClaimsReporting || currentUrlClaims) {
      if (currentUrlClaimsReporting) {
        this.CRactive = true;
      }
      if (currentUrlClaims) {
        this.CLactive = true;
      }
      this.claimsActive = true;
      this.contractsActive = false;
      this.CActactive = false;
      this.CActCreateactive = false;
    } else {
      this.claimsActive = false;
    }
    const currentUrlContracts: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CONTRACT) !== -1
        ? true
        : false;
    if (currentUrlContracts) {
      this.contractsActive = true;
      this.CActactive = true;
    } else {
      this.contractsActive = false;
    }
    const currentUrlDocument: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.MORE_DOCUMENT) !=
      -1
        ? true
        : false;
    if (currentUrlDocument) {
      this.DocumentActive = true;
    } else {
      this.DocumentActive = false;
    }
    const currentUrlUser: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.USERS) !== -1
        ? true
        : false;
    const currentUrlCustomerActive: boolean =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CUSTOMER_EDIT) !=
      -1
        ? true
        : false;
    if (currentUrlUser || currentUrlCustomerActive) {
      this.masterActive = true;
    } else {
      this.masterActive = false;
    }
    if (this.customer_id) {
      this.userLogo = true;
      this.emitClaimsDataChange.emit('changeData');
      this.serviceCall
        .getLogo(this.customer_id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data) {
            const objectURL = window.URL.createObjectURL(data);
            this.logo = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
          }
        });
    }
  }
  homeNavigate() {
    this.router.navigateByUrl(ROUTES_PATH.DASHBOARD);
    this.subsidiaries = [];
    this._customerSharedService.saveSubsidiaries(null);
  }
  navigateToClaims() {
    if (!this.customer_id) {
      this.router.navigateByUrl(ROUTES_PATH.CLAIMS);
    } else {
      if (
        this.urlClaims &&
        this.isCustomerUser &&
        this.encoded_contract_number
      ) {
        this.router.navigate([ROUTES_PATH.CLAIMS], {
          queryParams: {
            liztu: this.encoded_contract_number,
          },
        });
      } else {
        this.router.navigate([ROUTES_PATH.CLAIMS], {
          queryParams: { liztu: this.liz },
        });
      }
    }
  }
  navigateToContracts() {
    if (!this.customer_id) {
      this.router.navigateByUrl(ROUTES_PATH.CONTRACT);
    } else {
      if (
        this.urlClaims &&
        this.isCustomerUser &&
        this.encoded_contract_number &&
        this.encoded_liztu_vn_kdnr == null
      ) {
        this.router.navigate([ROUTES_PATH.CONTRACT], {
          queryParams: {
            liztu: this.encoded_contract_number,
          },
        });
      } else {
        this.router.navigate([ROUTES_PATH.CONTRACT], {
          queryParams: { liztu: this.liz },
        });
      }
    }
  }
  navigateToUsers() {
    if (!this.customer_id) {
      this.router.navigate([ROUTES_PATH.USERS], {
        queryParams: {
          liztu: this.liz,
        },
      });
    } else {
      if (
        this.urlClaims &&
        this.isCustomerUser &&
        this.encoded_contract_number
      ) {
        this.router.navigate([ROUTES_PATH.USERS], {
          queryParams: {
            liztu: this.encoded_contract_number,
          },
        });
      } else {
        this.router.navigate([ROUTES_PATH.USERS], {
          queryParams: {
            liztu: this.liz,
          },
        });
      }
    }
  }
  navigateToClaimsReporting() {
    if (!this.customer_id) {
      this.router.navigateByUrl(ROUTES_PATH.CLAIM_REPORTING);
    } else {
      if (
        this.urlClaims &&
        this.isCustomerUser &&
        this.encoded_contract_number !== null
      ) {
        this.router.navigate([ROUTES_PATH.CLAIM_REPORTING], {
          queryParams: {
            liztu: this.encoded_contract_number,
          },
        });
      } else {
        this.router.navigate([ROUTES_PATH.CLAIM_REPORTING], {
          queryParams: {
            liztu: this.liz,
          },
        });
      }
    }
  }
  navigateToDocument() {
    const encoded_via = atob(this.route.snapshot.queryParamMap.get('via'));
    if (!this.customer_id) {
      this.router.navigateByUrl(ROUTES_PATH.MORE_DOCUMENT);
    }
    {
      if (
        this.urlClaims &&
        this.isCustomerUser &&
        this.encoded_contract_number !== null &&
        encoded_via !== 'contracts' &&
        encoded_via !== 'search'
      ) {
        this.router.navigate([ROUTES_PATH.MORE_DOCUMENT], {
          queryParams: {
            liztu: this.encoded_contract_number,
          },
        });
      } else {
        this.router.navigate([ROUTES_PATH.MORE_DOCUMENT], {
          queryParams: {
            liztu: this.liz,
          },
        });
      }
    }
  }

  navigateToCustomerManagement(): void {
    this.isMenuCollapsed = true;
    this.router.navigate(
      [
        `${ROUTES_PATH.CUSTOMER_MANAGEMENT}/${CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA}`,
      ],
      {
        queryParams: {
          liztu: this.liz,
        },
      }
    );
  }

  isLinkActive(url): boolean {
    let queryParamsIndex = this.router.url.indexOf('?');
    if (queryParamsIndex == -1) {
      queryParamsIndex = this.router.url.indexOf(';');
    }
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);

    return baseUrl === url;
  }

  navigateToCreatingNewCustomer(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([
      `${ROUTES_PATH.CUSTOMER_MANAGEMENT}/${CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA}`,
    ]);
  }

  navigateTo(value) {
    this.emitCustomerData.emit(value.value);
    this._customerSharedService.saveCustomerName(value.value.customer_name);
    this._shareService.type = null;

    const liztu = value.value.liztu_vn_kdnr;
    const enc_liztu = liztu ? btoa(liztu) : null;

    if (this.isCustomerRoles) {
      const currentUrl =
        this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.DASHBOARD) !=
        -1;
      const currentUrlSettings =
        this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.SETTINGS) != -1;
      const currentUrlProfile =
        this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.USER_PROFILE) !=
        -1;
      const currentUrlCustomerEdit =
        this.router.url
          .toLocaleLowerCase()
          .indexOf(ROUTES_PATH.CUSTOMER_EDIT) != -1;

      if (currentUrl) {
        this.router.navigate([ROUTES_PATH.USERS], {
          queryParams: {
            liztu: enc_liztu,
          },
        });
      } else {
        if (currentUrlSettings || currentUrlProfile || currentUrlCustomerEdit) {
          this.router.navigate([ROUTES_PATH.USERS], {
            queryParams: {
              liztu: enc_liztu,
            },
          });
        } else {
          let currentUrl = this.router.url.split('?')[0];
          // fix for specific case as is ueser navigate param gets appended with semicolon
          if (currentUrl.includes('isUserWantToNevigate')) {
            currentUrl = this.router.url.split(';')[0];
          }
          const prev = this.router.routeReuseStrategy.shouldReuseRoute;
          const prevOSN = this.router.onSameUrlNavigation;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router
                .navigate([currentUrl], {
                  queryParams: {
                    liztu: enc_liztu,
                  },
                })
                .then(() => {
                  this.router.routeReuseStrategy.shouldReuseRoute = prev;
                  this.router.onSameUrlNavigation = prevOSN;
                });
            });
        }
      }
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(
          [
            `${ROUTES_PATH.CUSTOMER_MANAGEMENT}/${CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA}`,
          ],
          {
            queryParams: {
              liztu: enc_liztu,
            },
          }
        )
      );
    }
    this.userLogo = true;
    this.emitClaimsDataChange.emit('changeData');
  }
  loadUserData() {
    let userData: LoggedInUser;
    this._userService.loggedInUser$
      .pipe(
        filter<LoggedInUser>(Boolean),
        mergeMap(loggedInUser => {
          userData = loggedInUser;
          const customerLiztu = this._customerSharedService.getCustomerLiztu();
          this._initFieldsRegardingRole(
            loggedInUser.user_profile.user_data.role
          );

          if (this.isCustomerRoles && !this.liztu) {
            this.liztu = userData.user_profile.customer_details.liztu_vn_kdnr;
            this.liz = btoa(this.liztu);
          }

          if (!this.liztu) {
            this._customerSharedService.saveCustomerGeneralData(null);
          }

          return iif(
            () => this.liztu && +this.liztu !== customerLiztu,
            this.serviceCall.getCustomerGeneralData(this.liztu).pipe(
              tap(customerData => {
                if (!customerData?.customer_info) {
                  this._toastService.showToastMessage(
                    document,
                    this.translate.instant('header.customer_not_exist')
                  );

                  this.router.navigate([ROUTES_PATH.DASHBOARD]);
                }
              })
            ),
            this._customerSharedService.customerGeneralData$
          );
        }),
        take(1)
      )
      .subscribe(customerData => {
        this.customer_id = customerData?.customer_info?.customer_id || null;

        if (this.customer_id) {
          this._customerSharedService.saveCustomerGeneralData(customerData);
        }

        const user = userData.user_profile.user_data;
        if (user.is_deactivated) {
          this.router.navigate([ROUTES_PATH.DASHBOARD], {
            queryParams: { is_deactivated: 'true' },
          });
        }
        const user_data: UpdateElement = {
          first_name: user.first_name,
          last_name: user.last_name,
        };

        this.user_data = user_data;
        this.is_source_ivm = user.is_source_ivm;
        this.user_idnr_local = user.user_idnr;
        this.userService.saveUserIdnr(user.user_idnr);
        if (!this._shareService.type) {
          this._shareService.type = userData.user_profile.customer_details.type;
        }
        // emit uesr data from header
        this.emitUserData.emit(userData);
        if (!userData.error) {
          if (userData.user_profile) {
            this.formatUserData(userData);
          } else {
            alert(this.translate.instant('header.upsBeiDer_alert'));
          }
        }
      });
  }

  formatUserData(userData: LoggedInUser): void {
    const user_profile = userData.user_profile;
    const customer_details = user_profile?.customer_details;
    const user_data = user_profile?.user_data;
    const customerId = customer_details.customer_id;

    this.userService.saveUserId(user_data.user_id.toString());

    this.user_idnr_local =
      this.user_idnr_local == null ? '' : this.user_idnr_local;
    this.is_source_ivm =
      this.is_source_ivm == null ? false : this.is_source_ivm;
    if (this.isICRoles) {
      if (this.customer_id) {
        const customerSource = this._customerSharedService.getSelectedSource();
        const isCustomerSourceIVM =
          customerSource !== null ? customerSource : true;

        this.serviceCall
          .getSubsidiariesCustomerManagement(
            this.customer_id,
            this.user_idnr_local,
            this.user_role,
            this.is_source_ivm,
            isCustomerSourceIVM
          )
          .pipe(takeUntil(this._destroy$))
          .subscribe(obj => {
            this.subsidiaries = obj.associated_customers;
            this._customerSharedService.saveSubsidiaries(this.subsidiaries);
            if (this.liztu && this.customer_id) {
              const subs = this._customerSharedService.getSubsidiaries();

              for (let i = 0; i < subs.length; i++) {
                if (subs[i].customer_id == this.customer_id) {
                  this.liz = subs[i].liztu_vn_kdnr
                    ? btoa(subs[i].liztu_vn_kdnr.toString(10))
                    : null;
                }
              }
            }
            this.emitsubData.emit(this.subsidiaries);
            if (this.subsidiaries && this.subsidiaries.length) {
              for (const element of this.subsidiaries) {
                if (element.customer_id == this.customer_id) {
                  this.defaultSelectedCustomerValue = element;
                  this._customerSharedService.selectCustomerDivisionId(
                    this.defaultSelectedCustomerValue.division_id
                  );
                  this._shareService.messageType =
                    this.defaultSelectedCustomerValue.type;
                  this.disbaleMenuIcon = false;
                }
              }
            }
          });
      } else {
        const subsidiries =
          this.communicationService.getAssociatedCustomerValues();
        this.subsidiaries = subsidiries.map(obj => {
          const newSub: any = {};
          newSub.customer_id = obj.CHILD_COMPANY;
          newSub.user_id = obj.ID;
          newSub.customer_name = obj.customer;
          newSub.type = obj.TYPE;
          return newSub;
        });
        this.defaultSelectedCustomerValue = this.subsidiaries.find(x => {
          x.customer_id == this.customer_id;
        });
      }
    } else {
      this.subsidiaries = userData.user_profile.associated_customers;
      this._customerSharedService.saveSubsidiaries(this.subsidiaries);
      this.emitsubData.emit(this.subsidiaries);

      if (!this.customer_id) {
        const endcodedCustomer = this.route.snapshot.queryParams.customer;
        const queryCustomer = endcodedCustomer ? atob(endcodedCustomer) : null;

        this.customer_id = queryCustomer || customerId;
      }

      this.defaultSelectedCustomerValue = this.subsidiaries.find(
        x => x.customer_id === this.customer_id.toString()
      );

      if (this.defaultSelectedCustomerValue) {
        this._customerSharedService.selectCustomerId(
          this.defaultSelectedCustomerValue.customer_id
        );
        this._customerSharedService.selectCustomerType(
          this.defaultSelectedCustomerValue.type
        );
      }
    }

    if (this.isCustomerRoles || this.defaultSelectedCustomerValue) {
      this.disbaleMenuIcon = false;
    } else {
      this.disbaleMenuIcon = true;
    }

    if (!this.logo) {
      this.userLogo = !!this.customer_id;
      this.emitClaimsDataChange.emit('changeData');

      this.serviceCall
        .getLogo(this.customer_id || customerId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data) {
            const objectURL = window.URL.createObjectURL(data);
            this.logo = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
          }
        });
    }
  }
  onSearch() {
    this.dialog.open(SearchComponent, {
      panelClass: 'search',
      width: '700px',
      disableClose: false,
    });
  }
  onNewContract() {
    const contractMatDialog = this.dialog.open(AddNewContractDialogComponent);
    contractMatDialog
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data) {
          const prev = this.router.routeReuseStrategy.shouldReuseRoute;
          const prevOSN = this.router.onSameUrlNavigation;
          this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
          };
          this.router.onSameUrlNavigation = 'reload';
          this.router
            .navigate(
              [
                `${ROUTES_PATH.ADD_NEW_CONTRACT}/${CONTRACTS_ROUTES.GENERAL_DATA}`,
              ],
              {
                queryParams: {
                  liztu: this.liz,
                },
              }
            )
            .then(() => {
              this.router.routeReuseStrategy.shouldReuseRoute = prev;
              this.router.onSameUrlNavigation = prevOSN;
            });
        }
      });
  }
  logout() {
    this._securityApiService
      .logout()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => (document.location.href = URLS.ic_logout_url));
  }

  isCustomerManagementActive(): boolean {
    return this.router.url.includes(this.routesPath.CUSTOMER_MANAGEMENT);
  }

  isContractsActive(): boolean {
    const url = this.router.url;
    return (
      url.includes(`/${this.routesPath.ADD_NEW_CONTRACT}`) ||
      url.includes(`/${this.routesPath.CONTRACT}`)
    );
  }

  isInsurerOverviewActive(): boolean {
    const url = this.router.url;

    return (
      url.includes(this.routesPath.INSURER_OVERVIEW) ||
      url.includes(this.routesPath.INSURER_MAPPING) ||
      url.includes(this.routesPath.CREATE_INSURER)
    );
  }
  isClaimActive(): boolean {
    const url = this.router.url;

    return (
      url.includes(`/${this.routesPath.CLAIMS}`) ||
      url.includes(`/${this.routesPath.CLAIM_REPORTING}`) ||
      url.includes(`/${this.routesPath.CREATE_CLAIMS}`)
    );
  }

  isCreateClaimActive(): boolean {
    const url = this.router.url;
    return url.includes(this.routesPath.CLAIMS) ||
      url.includes(this.routesPath.CREATE_CLAIMS);
  }

  getURL(): string {
    return (
      this.router.url.split('?')[0]?.replace(/^\//, '') ||
      STATIC_TEXT.EMPTY_STRING
    );
  }
  getHelpDocument(userInfo) {
    this.serviceCall
      .getHelpDocument(userInfo)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        const pdfBlob = new Blob([data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      });
  }

  private _getGreeting(): string {
    const hours = moment().hour();

    if (hours >= 17) {
      return 'header.good_evening';
    }

    if (hours >= 10) {
      return 'header.good_afternoon';
    }

    return 'header.good_morning';
  }

  private _initFieldsRegardingRole(role: USER_ROLES): void {
    this.user_role = role;
    if (ROLES.CUSTOMER_ROLES.includes(role)) {
      this.isCustomerUser = true;
      this.isCustomerRoles = true;
    } else if (ROLES.IC_ROLES.includes(this.user_role)) {
      this.isICRoles = true;
    }
  }
}
