<app-header></app-header>

<div class="container-ic">
  <div style="background-color: #ffffff" class="">
    <div style="
            width: 90%;
            height: 100%;
            min-height: 78vh;
            margin: 0 auto;
            white-space: nowrap;">
      <div>
        <h6 style="color: #272727; font-size: 18px; margin-top: 9%">
          <strong><span>/</span>&nbsp;&nbsp; Register claims</strong>
        </h6>
      </div>
      <div>
        <div style="height: 52px;background-color: rgba(196, 196, 196, 0.17);margin-bottom: 1.1rem;">
          <div style="display: flex;justify-content: center;padding-top: 10px;">
            <button class="navButton">1</button>
            <hr style="width: 4%;margin: 16px 0px;border-top: 2px solid #ff9000">
            <button class="navButton" style="border: 2px solid #ff9000;box-shadow: 0 0 0 2px #fff inset;">2</button>
            <hr style="width: 4%;margin: 16px 0px;border-top: 2px solid #dddddd">
            <button class="navButton" style="background-color: #dddddd;">3</button>
          </div>
        </div>
      </div>
      <div>
        <label for="" class="lableStyle"> Bitte wählen sie die </label>
        <mat-select placeholder="Bitte wählen Sie Schäden " class="col-md-2 ml-4 selectOption"
          (selectionChange)="selectBitte($event)">
          <mat-option class="abcd" value="abcd">abcd</mat-option>
          <mat-option value="pqrs">pqrs</mat-option>
        </mat-select>
        <label class="lableStyle" style="margin-left: 4rem;" for=""> Schadenart </label>
        <mat-select placeholder="Bitte Schadensart auswählen " class="col-md-2 ml-4 selectOption"
          [disabled]="isdisabled" (selectionChange)="selectSchadenart($event)">
          <mat-option value="pqr">pqr</mat-option>
          <mat-option value="xyz">xyz</mat-option>
        </mat-select>
      </div>
      <div class="d-flex mt-5" style="min-height: 50vh;">
        <div id="placeholder" style="display: none;">
          <div style=" font-size: 15px;font-weight: 600;color: #797f96;">Claims info</div>
          <div class="col-md-8">
            <p class="placeholderStyle"> Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
              unchanged
            </p>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button (click)="navigateStepOne()" class="nextBtn mr-2">zurück</button>
        <button id="Weiter" [disabled]="isdisabled" style="background-color: #c7c7c7;" class="nextBtn ml-2"
          (click)="nextTwo()">Weiter</button>
      </div>
    </div>
  </div>
</div>