<router-outlet *ngIf="isLangSet$ | async"></router-outlet>
<ng-http-loader [opacity]=".1" [backgroundColor]="'#000'"></ng-http-loader>
<div class="modal modelBackground" id="myModal" [style.display]="showUserDeactivatedModal ? 'block' : 'none'"
    style="z-index: 4;overflow: scroll;">
    <div class="modal-dialog" style="top: 200px;">
        <div class="modal-content" style="height:250px;border: none;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;">
            <div class="modal-header">
                <h6>{{'main_component.modal_heading' | translate}} </h6>
                <button type="button" class="close" data-dismiss="modal" (click)="hide()">&times;</button>
            </div>
 
            <div class="modal-body" style="font-size:12px;height: 254px;text-align: center;">
                <p>
                    {{'main_component.modal_text' | translate}}
                </p>
                <button (click)="logout()" class="logout">
                    {{'main_component.modal_button_text' | translate}}
                </button>
            </div>
            <div class="modal-footer" style="clear:both">
            </div>
        </div>
    </div>
</div>
<div class="modal modelBackground" id="myModal" [style.display]="showUserNotFoundModal ? 'block' : 'none'"
    style="z-index: 4;overflow: scroll;">
    <div class="modal-dialog" style="top: 200px;">
        <div class="modal-content" style="border: none;">
            <div class="modal-body" style="font-size:12px;text-align: center;">
                <p>
                    {{statusText}}
                </p>
                <button (click)="logout()" class="logout">
                    OK
                </button>
            </div>
        </div>
    </div>
</div>

<div class="custom-snackbar"></div>