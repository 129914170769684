import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MaskGeneratorPayLoad,
  ProductSaveRequest,
} from '@shared/models/product-management.model';

interface VisibilityOption {
  value: string;
  displayValue: string;
}

@Component({
  selector: 'app-mask-generator',
  templateUrl: './mask-generator.component.html',
  styleUrls: ['./mask-generator.component.css'],
})
export class MaskGeneratorComponent implements OnInit {
  maskStepperForm: FormGroup;
  @Input('maskGenFormData') maskGenFormData;
  @Input('isAutoPopulatedSelected') isAutoPopulatedSelected;
  @Input('isProductDeactivated') isProductDeactivated;
  @Input('configData') configData;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  defaultVisibility = 'notVisible';
  visibilityOptions: VisibilityOption[] = [
    { value: 'notVisible', displayValue: 'Nicht sichtbar' },
    { value: 'visibleEnabled', displayValue: 'Sichtbar, Eingabe möglich' },
    {
      value: 'visibleDisabled',
      displayValue: 'Sichtbar, Eingabe nicht möglich',
    },
  ];
  form: FormGroup = new FormGroup({});

  constructor(private maskStepperBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maskGenFormData) {
      this.initializeForm();
    }
  }
  initializeForm() {
    this.assignAttributeValues(this.maskGenFormData);
  }
  assignAttributeValues(formData) {
    const optionalField = new FormControl(
      {
        value:
          formData &&
          formData.optional_field &&
          formData.optional_field == 'true'
            ? 'optional'
            : 'required',
        disabled: false,
      },
      []
    );
    const typeOfCoverage = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.type_of_coverage : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const maximization = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.maximization_id : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const maximumCompensation = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.maximumCompensation : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const fullValueInsuredAmount = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.fullValueInsuredAmount : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const premiumRate = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.premiumRate : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const premium = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.premium : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const deductibleType = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.deductible_type : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const deductibleAmount = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.deductible_amt : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const deductibleIn = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.deductible_in : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const deductibleMin = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.deductible_min : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const deductibleMax = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.deductible_max : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const daysForDeductible = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.days_for_Deductible : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );
    const indemnityPeriod = new FormControl(
      {
        value: this.getValue(
          formData,
          formData ? formData.max_pr : null,
          this.defaultVisibility
        ),
        disabled: false,
      },
      []
    );

    this.maskStepperForm = this.maskStepperBuilder.group({
      optionalField,
      typeOfCoverage,
      maximization,
      maximumCompensation,
      fullValueInsuredAmount,
      premiumRate,
      premium,
      deductibleType,
      deductibleAmount,
      deductibleIn,
      deductibleMin,
      deductibleMax,
      daysForDeductible,
      indemnityPeriod,
    });
    this.maskStepperForm.valueChanges.subscribe(value => {
      this.emitFormData();
    });
  }

  getValue(formData, value: string, defaultValue: string) {
    return !formData || !value ? defaultValue : value;
  }

  emitFormData() {
    const formValues: MaskGeneratorPayLoad = {
      optional_field: this.maskStepperForm.controls.optionalField.value,
      type_of_coverage: this.maskStepperForm.controls.typeOfCoverage.value,
      deductible_type: this.maskStepperForm.controls.deductibleType.value,
      deductible_in: this.maskStepperForm.controls.deductibleIn.value,
      deductible_min: this.maskStepperForm.controls.deductibleMin.value,
      deductible_max: this.maskStepperForm.controls.deductibleMax.value,
      days_for_Deductible:
        this.maskStepperForm.controls.daysForDeductible.value,
      maximization_id: this.maskStepperForm.controls.maximization.value,
      deductible_amt: this.maskStepperForm.controls.deductibleAmount.value,
      premiumRate: this.maskStepperForm.controls.premiumRate.value,
      premium: this.maskStepperForm.controls.premium.value,
      indemnityPeriod: this.maskStepperForm.controls.indemnityPeriod.value,
      maximumCompensation:
        this.maskStepperForm.controls.maximumCompensation.value,
    };

    const request: ProductSaveRequest = {
      fromValues: formValues,
      valid: true,
      isFormTouched: this.maskStepperForm && !this.maskStepperForm.pristine,
    };
    this.emitFormValue.emit(request);
  }
}
