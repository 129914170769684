export enum WidgetIds {
    NEW_CLAIMS = 18,
    PAYMENT = 19,
    NEW_DOCUMENTS = 20,
    CONTACT_PARTNERS = 21,
    TO_DO_LIST = 3,
    WEATHER = 13,
    DSGVO = 26,
    LOCKS = 27,
    QUICK_LINKS = 22,
    IC_ACADEMY = 29
  }

export type WidgetImages = { [key: number]: Record<'imgDE' | 'imgEN', string> };

export const widgetImages: WidgetImages = {
    [WidgetIds.NEW_CLAIMS]: {
        imgEN: '../../../../assets/images/Aktuelle Schadenmeldungen-En.svg',
        imgDE: '../../../../assets/images/Aktuelle Schadenmeldungen.svg'
    },
    [WidgetIds.TO_DO_LIST]: {
        imgEN: '../../../../assets/images/todo_en.png',
        imgDE: '../../../../assets/images/todo_de.png'
    },
    [WidgetIds.WEATHER]: {
        imgEN: '../../../../assets/images/weather_en.png',
        imgDE: '../../../../assets/images/weather_de.png'
    },
    [WidgetIds.LOCKS]: {
        imgEN: '../../../../assets/images/locks-widget.png',
        imgDE: null
    },
    [WidgetIds.CONTACT_PARTNERS]: {
        imgEN: '../../../../assets/images/Ansprechpartner-En.svg',
        imgDE: '../../../../assets/images/Ansprechpartner.svg'
    },
    [WidgetIds.NEW_DOCUMENTS]: {
        imgEN: '../../../../assets/images/Neue Doumente-En.svg',
        imgDE: '../../../../assets/images/Neue Dokumente.svg'
    },
    [WidgetIds.PAYMENT]: {
        imgEN: '../../../../assets/images/Zahlung-En.svg',
        imgDE: '../../../../assets/images/Zahlung_german.svg'
    },
    [WidgetIds.DSGVO]: {
        imgEN: '../../../../assets/images/IC_Customer_ENG.svg',
        imgDE: '../../../../assets/images/IC_Customer_DE.svg'
    },
    [WidgetIds.IC_ACADEMY]: {
        imgEN: '../../../../assets/images/ic-academy.png',
        imgDE: null
    }

};
