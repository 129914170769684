import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClaimsObject } from './models/general-claim-data.model';

@Injectable()
export class ClaimsShareService {
  private readonly _isIvmSynced$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(null);
  readonly isIvmSynced$ = this._isIvmSynced$.asObservable();

  private readonly _getClaimData$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  readonly getClaimData$ = this._getClaimData$.asObservable();

  private readonly _productInfoId$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  readonly productInfoId$ = this._productInfoId$.asObservable();

  private readonly _ivmEventChanging$: BehaviorSubject<boolean | null> =
    new BehaviorSubject<boolean | null>(null);
  readonly ivmEventChanging$ = this._ivmEventChanging$.asObservable();

  private readonly _liztuSa$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null);
  readonly liztuSa$ = this._liztuSa$.asObservable();

  private readonly _generalData$: BehaviorSubject<ClaimsObject | null | object> =
    new BehaviorSubject<ClaimsObject | null | object>(null);
  readonly generalData$ = this._generalData$.asObservable();

  readonly addContactPerson$: Subject<null> = new Subject();
  readonly addPayment$: Subject<null> = new Subject();

  setClaimData(data: ClaimsObject | object): void {
    this._generalData$.next(data);
  }

  getLiztuSa(): number | null {
    return this._liztuSa$.value;
  }

  setLiztuSa(value: number): void {
    this._liztuSa$.next(value);
  }

  setEventImvChanging(value: boolean): void {
    this._ivmEventChanging$.next(value);
  }

  setProductInfoId(value: number): void {
    return this._productInfoId$.next(value);
  }

  getProductInfoId(): number | null {
    return this._productInfoId$.value;
  }

  setIvmSyncedValue(value: boolean): void {
    this._isIvmSynced$.next(value);
  }

  getIsIvmSynced(): boolean | null {
    return this._isIvmSynced$.value;
  }
}
