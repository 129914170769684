import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerManagementComponent } from './customer-management.component';
import { GeneralCustomerDataComponent } from './general-customer-data/general-customer-data.component';
import { CUSTOMER_MANAGEMENT_ROUTES } from '@shared/constants/router.constants';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CustomerRelationsComponent } from './customer-relations/customer-relations.component';
import { CommunicationDetailsComponent } from './communication-details/communication-details.component';
import { CustomerDocumentsComponent } from './customer-documents/customer-documents.component';
import { BankAccountDetailsPageComponent } from './bank-account-details-page/bank-account-details-page.component';
import { UserManagementPageComponent } from './user-management-page/user-management-page.component';
import { ContactPersonPageComponent } from './contact-person-page/contact-person-page.component';
import { CustomerAppointmentsComponent } from './customer-appointments/customer-appointments.component';

const routes: Routes = [
  {
    path: '',
    component: CustomerManagementComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/',
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA,
        component: GeneralCustomerDataComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.CUSTOMER_DETAILS,
        component: CompanyDetailsComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.CUSTOMER_RELATIONS,
        component: CustomerRelationsComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.COMMUNICATION,
        component: CommunicationDetailsComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.USER_MANAGEMENT,
        component: UserManagementPageComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.BANK_ACCOUNT_DETAILS,
        component: BankAccountDetailsPageComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.CONTACT_PERSON,
        component: ContactPersonPageComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.DOCUMENT_MANAGEMENT,
        component: CustomerDocumentsComponent,
      },
      {
        path: CUSTOMER_MANAGEMENT_ROUTES.APPOINTMENTS,
        component: CustomerAppointmentsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerManagementRoutingModule {}
