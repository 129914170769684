import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ProductShareService {
    private _productModuleId: string = null;
    get productModuleId(): string {
        return this._productModuleId;
    }
    set productModuleId(value: string) {
        this._productModuleId = value;
    }
}
