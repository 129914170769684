<div>
  <span class="criteria-heading">{{'objectModule.lithium_batteries_object.lithium-batteries' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="lithiumBatteriesForm" id="lithiumBatteriesForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing"
      class="long-label">{{'objectModule.lithium_batteries_object.storage_of_lithium_batteries' |
      translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="storage_of_lithium_batteries"
      placeholder="{{'objectModule.select_lable' | translate}}" formControlName="storage_of_lithium_batteries" multiple>
      <mat-option [value]="battery.id" *ngFor="let battery of lithiumBatteriesStorage">{{battery.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_safety_rules_according_to_manufacturer_complied_with"
      class="long-label">{{'objectModule.lithium_batteries_object.is_safety_rules_according_to_manufacturer_complied_with'
      | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_safety_rules_according_to_manufacturer_complied_with"
        id="is_safety_rules_according_to_manufacturer_complied_with">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.lithium_batteries_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.lithium_batteries_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_warehouse_fire_resistant_or_spatially_separated"
      class="long-label">{{'objectModule.lithium_batteries_object.is_warehouse_fire_resistant_or_spatially_separated' |
      translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_warehouse_fire_resistant_or_spatially_separated"
        id="is_warehouse_fire_resistant_or_spatially_separated">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.lithium_batteries_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.lithium_batteries_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_fire_alarm_system" class="long-label">{{'objectModule.lithium_batteries_object.is_fire_alarm_system'
      | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_fire_alarm_system" id="is_fire_alarm_system">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.lithium_batteries_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.lithium_batteries_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_extinguishing_system"
      class="long-label">{{'objectModule.lithium_batteries_object.is_extinguishing_system' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_extinguishing_system" id="is_extinguishing_system">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.lithium_batteries_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.lithium_batteries_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_extinguishing_system_with_extinguishing_agent_according_to_safety_data_sheet"
      class="long-label">{{'objectModule.lithium_batteries_object.is_extinguishing_system_with_extinguishing_agent_according_to_safety_data_sheet'
      | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_extinguishing_system_with_extinguishing_agent_according_to_safety_data_sheet"
        id="is_extinguishing_system_with_extinguishing_agent_according_to_safety_data_sheet">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.lithium_batteries_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.lithium_batteries_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div
    [ngClass]="lithiumBatteriesForm.value.is_storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets">
        {{'objectModule.lithium_batteries_object.is_storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets'
        | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align"
      *ngIf="lithiumBatteriesForm.value.is_storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets"
      formGroupName="storage_quantity_larger_than_7_square_meters_or_more_than_6_EURO_pallets">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="type_of_firing" class="long-label">{{'objectModule.lithium_batteries_object.storage_area' |
          translate}}</label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="6"
          class="input" formControlName="storage_area" numbersOnly
          placeholder="{{'objectModule.lithium_batteries_object.storage_area' | translate}}"
          (keydown)="validationService.SequenceNumberValidation($event, 'keydown',6)"
          (paste)="validationService.SequenceNumberValidation($event, 'paste',6)"
          (blur)="sqmValidation(lithiumBatteriesForm ,$event)" (focus)="sqmValidation(lithiumBatteriesForm ,$event)">
        <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*"
          (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
          (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)"
          class="input numberText" formControlName="storage_area"
          placeholder="{{'objectModule.lithium_batteries_object.storage_area' | translate}}"
          (blur)="sqmValidation(lithiumBatteriesForm ,$event)" (focus)="sqmValidation(lithiumBatteriesForm ,$event)"
          numbersOnly>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="storage_height_top_edge_of_stored_goods_in_m"
          class="long-label">{{'objectModule.lithium_batteries_object.storage_height_top_edge_of_stored_goods_in_m' |
          translate}}</label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="2"
          class="input" formControlName="storage_height_top_edge_of_stored_goods_in_m"
          placeholder="{{'objectModule.lithium_batteries_object.storage_height' | translate}}"
          (keydown)="validationService.SequenceNumberValidation($event, 'keydown',2)"
          (paste)="validationService.SequenceNumberValidation($event, 'paste',2)"
          numbersOnly>
        <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="2" ng-pattern="[0-9]*"
          (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',2)"
          (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',2)"
          class="input numberText" formControlName="storage_height_top_edge_of_stored_goods_in_m"
          placeholder="{{'objectModule.lithium_batteries_object.storage_height' | translate}}"
          numbersOnly>
      </div>
    </div>
  </div>
  <div
    [ngClass]="lithiumBatteriesForm.value.is_mixed_storage_with_other_products ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_mixed_storage_with_other_products">
        {{'objectModule.lithium_batteries_object.is_mixed_storage_with_other_products'
        | translate}}
      </mat-slide-toggle>
    </div>
    <div class="row toggle-section-content form-align"
      *ngIf="lithiumBatteriesForm.value.is_mixed_storage_with_other_products"
      formGroupName="mixed_storage_with_other_products">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="type_of_firing"
          class="long-label">{{'objectModule.lithium_batteries_object.remark_of_mixed_storage_with_other_products' |
          translate}}</label>
        <input type="text" id="remark_of_mixed_storage_with_other_products" class="input" maxlength="100"
          autocomplete="off"
          placeholder="{{'objectModule.lithium_batteries_object.remark_of_mixed_storage_with_other_products' | translate}}"
          matInput formControlName="remark_of_mixed_storage_with_other_products"
          [title]="lithiumBatteriesForm.value.mixed_storage_with_other_products.remark_of_mixed_storage_with_other_products">
      </div>


      <div class="col-md-3 col-sm-6 col-6">
        <label for="storage_area_BMA"
          class="long-label">{{'objectModule.lithium_batteries_object.storage_area_BMA'
          | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="storage_area_BMA"
            id="storage_area_BMA">
            <mat-radio-button class="radio-pad" value=true>{{'objectModule.lithium_batteries_object.Yes' | translate}}
            </mat-radio-button>
            <mat-radio-button class="radio-pad" value=false>{{'objectModule.lithium_batteries_object.No' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="approx_storage_height"
          class="long-label">{{'objectModule.lithium_batteries_object.approx_storage_height' |
          translate}}</label>

          <input  type="text" maxlength="5" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="validateNumberAsFloat($event)" formControlName="approx_storage_height" placeholder="{{'objectModule.lithium_batteries_object.approx_storage_height' | translate}}">
          <input  type="text" maxlength="5" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input"  (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="validateNumberAsFloat($event)" formControlName="approx_storage_height" placeholder="{{'objectModule.lithium_batteries_object.approx_storage_height' | translate}}">

      </div>


    </div>
  </div>
</form>
