<div class="row outer-form-conainer">
  <form name="search" [formGroup]="formFilter" style="font-size: 13px; color: #4e4e4e">
    <div class="row">
      <div class="col-md-6 col-sm-6" style="display: flex;">
        <mat-checkbox formControlName="show_all_from_organization" (change)="showData($event)"
          class="check1 top-Checkbox">
          {{'value-module.show_all_from_organization' | translate}}
        </mat-checkbox>
      </div>
      <div class="col-md-6 col-sm-6" style="display: flex;">
        <mat-checkbox formControlName="show_country_wise_data" (change)="showData($event)" class="check1 top-Checkbox">
          {{'value-module.show_country_wise_data' | translate}}
        </mat-checkbox>
      </div>
    </div>
  </form>

  <div style="text-align:center;padding:10px">
    <button class="ml-2 save-button" type="button" mat-button (click)="saveObject()">
      {{'value-module.Save' | translate}}
    </button>
  </div>

  <app-present top="20px" left="20px"></app-present>
</div>

<div style="height: 64vh;margin-left:10px;overflow: scroll;">
  <table class="table table-border value-table" *ngIf="showTable" id="propertyTable">
    <thead>
      <tr style="position: sticky;">
        <th style="background-color: #e7e7e7; vertical-align: top;" class="table-border"> <label
            class="label font-class">{{"value-module.question" | translate}}:
            {{lastUpdatedDate | dateFormat}}</label></th>
        <th class="table-border" *ngFor="let item of customer_data; let i = index" rowspan="2"
          [style.background-color]="item.customer_type == CUSTOMER_TYPES.MUTTER ? '#ff9000' : '#2175BD'">
          <div>
            <label class="label font-class"
              [style.color]="item.customer_type == CUSTOMER_TYPES.MUTTER ? '#303030' : 'white'"
              [title]="item.customer_name">{{item.customer_name}} </label>
            <br>
            <label class="label font-class"
              [style.color]="item.customer_type == CUSTOMER_TYPES.MUTTER ? '#303030' : 'white'"
              [title]="item.address">{{item.address}}</label>
            <br>
            <label class="label font-class"
              [style.color]="item.customer_type == CUSTOMER_TYPES.MUTTER ? '#303030' : 'white'">{{item.country}}</label>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of questionData; let i = index;" class="table-border">
        <td class="table-border font-class"> {{"value-module.questions." + item.question_id | translate}}</td>
        <td class="table-border font-class" *ngFor="let customer of customer_data;let j = index;"
          (click)="item.read_only ? redirectToObject(i, j): ''">
          <div class="row">
            <div *ngIf="item.data_type == 1" class="col-sm-10">
              <input type="text" maxlength="12" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                class="input newValue" id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [ngModel]="questions[j].questions_data[i].value | currencyCustom"
                (ngModelChange)="questions[j].questions_data[i].value=$event; auditValue(questions[j].questions_data[i])"
                [disabled]="item.read_only" tabindex="{{(100*j)+i+1}}">
              <input type="text" maxlength="12" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                class="input newValue" id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [ngModel]="questions[j].questions_data[i].value | currencyCustom"
                (ngModelChange)="questions[j].questions_data[i].value=$event; auditValue(questions[j].questions_data[i])"
                [disabled]="item.read_only" tabindex="{{(100*j)+i+2 }}">
            </div>
            <div *ngIf="item.data_type == 3" class="col-sm-10">
              <input type="text" maxlength="3" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                class="input newValue" (blur)="MaxHundredValidation($event, questions[j].questions_data[i])"
                id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [ngModel]="questions[j].questions_data[i].value | currencyCustom"
                (ngModelChange)="questions[j].questions_data[i].value=$event; auditValue(questions[j].questions_data[i])"
                [disabled]="item.read_only" tabindex="{{(100*j)+i+1}}">
              <input type="text" maxlength="3" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                class="input newValue" (blur)="MaxHundredValidation($event, questions[j].questions_data[i])"
                id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [ngModel]="questions[j].questions_data[i].value | currencyCustom"
                (ngModelChange)="questions[j].questions_data[i].value=$event; auditValue(questions[j].questions_data[i])"
                [disabled]="item.read_only" tabindex="{{(100*j)+i+2}}">
              <span class="error" *ngIf="questions[j].questions_data[i].error">
                {{questions[j].questions_data[i].error}}</span>
            </div>
            <div *ngIf="item.data_type == 2" class="col-sm-10">
              <mat-radio-group id="{{item.question_id}}" [(ngModel)]="questions[j].questions_data[i].value"
                (ngModelChange)="auditValue(questions[j].questions_data[i])">
                <mat-radio-button class="radio-pad" tabIndex="{{(100*j)+i+1}}" [value]=true>
                  {{'objectModule.warehouse_object.Yes' | translate}}
                </mat-radio-button>
                <mat-radio-button class="radio-pad" tabIndex="{{(100*j)+i+2}}" [value]=false>
                  {{'objectModule.warehouse_object.No' | translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>


            <div *ngIf="item.data_type == 4" class="col-sm-10">
              <input type="text" maxlength="2" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                class="input newValue" id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only"
                (ngModelChange)="auditValue(questions[j].questions_data[i])" tabindex="{{(100*j)+i+1}}">
              <input type="text" maxlength="2" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                class="input newValue" id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only"
                (ngModelChange)="auditValue(questions[j].questions_data[i])" tabindex="{{(100*j)+i+2}}">
            </div>

            <div *ngIf="item.data_type == 5" class="col-sm-10">
              <input type="text" maxlength="2" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                class="input newValue" id="{{item.question_id}}" numbersOnly
                [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only"
                (ngModelChange)="auditValue(questions[j].questions_data[i])" tabindex="{{(100*j)+i+1}}">
              <input type="text" maxlength="2" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                class="input newValue" id="{{item.question_id}}" numbersOnly
                [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only"
                (ngModelChange)="auditValue(questions[j].questions_data[i])" tabindex="{{(100*j)+i+2}}">
            </div>

            <div *ngIf="item.data_type == 6" class="col-sm-10">
              <input type="text" maxlength="12" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                class="input newValue" id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only"
                (ngModelChange)="auditValue(questions[j].questions_data[i])" tabindex="{{(100*j)+i+1}}">
              <input type="text" maxlength="12" *ngIf="browser == 'Safari' || browser == 'Firefox'"
                class="input newValue" id="{{item.question_id}}" numbersOnly [allowZero]="true"
                [(ngModel)]="questions[j].questions_data[i].value" [disabled]="item.read_only"
                (ngModelChange)="auditValue(questions[j].questions_data[i])" tabindex="{{(100*j)+i+2}}">
            </div>

            <div *ngIf="item.data_type == 7" class="col-sm-10">
              <input type="text" maxlength="100" class="input newValue" id="{{item.question_id}}"
                [ngModel]="questions[j].questions_data[i].value" [disabled]="item.read_only"
                (ngModelChange)="auditValue(questions[j].questions_data[i])" tabindex="{{(100*j)+i+1}}">
            </div>
            <ng-template #popTemplate>{{'value-module.lastUpdate' | translate}} :
              {{questions[j].questions_data[i].updated_by_name}} <br>{{questions[j].questions_data[i].updated_at | date:
              'dd.MM.yyyy, h:mm' :'UTC +' + (cetOffset / 60)}}</ng-template>
            <img alt="" *ngIf="!item.read_only && questions[j].questions_data[i].updated_by_name"
              src="../../../../assets/images/info-sign.svg" class="infoImage" [tooltip]="popTemplate" placement="bottom"
              containerClass="customClass" (mouseover)="getUserDetails(questions[j].questions_data[i])">
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal modelBackground" [class.showModel]="showSuccessModel">
  <div class="modal-dialog" style="margin-top:13%;width:32%">
    <div class="modal-content" style="border-radius: 8px;    box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);    border: solid 1px #bbb;
         background-color: #fff;padding: 40px 0;    width: 400px;">
      <!-- Modal Header -->
      <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">

        <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
          <mat-icon>
            <img alt="" height="65px" (click)="showSuccessModel = false;is_update = true;"
              src="../../../assets/images/Check_icon.svg">
          </mat-icon>
        </div>
        <div>
          <button style="position: absolute;
          top: 11px;
          right: 11px;
          outline: none;
          border: none;
          height: 32px;
          width: 36px;">
            <mat-icon style="
            font-size: 16px;
            height: 0px;
            line-height: 1.2;
            background-color: initial;
          " (click)="showSuccessModel = false;is_update = true;">close</mat-icon>
          </button>
        </div>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
        <span style="font-size: 18px;color: #272727;" *ngIf="!is_update">
          {{'value-module.new_success_text' | translate}}
        </span>
        <span style="font-size: 18px;color: #272727;" *ngIf="is_update">
          {{'value-module.new_success_text_update' | translate}}
        </span>
      </div>
    </div>
  </div>
</div>