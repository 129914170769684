import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { URLS } from '@shared/constants/url.constants';

const UNAUTHORIZED_REQUEST_STATUS = 401;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: Error) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === UNAUTHORIZED_REQUEST_STATUS
        ) {
          document.location.href = URLS.ic_logout_url;
        }
        return throwError(err);
      })
    );
  }
}
