<div>
  <span class="criteria-heading"> {{'objectModule.clean-rooms' | translate}}</span>
</div>

<form class="row object-module-html form-align" [formGroup]="CleanRoomForm" id="CleanRoomForm" *ngIf="CleanRoomForm">

  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.smoke_seperation' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_smoke_tight_separation">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.fire_alarm' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_fire_alarm_system">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.co_protection' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_co2_protection">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

</form>
