<div>
  <span class="criteria-heading">{{'objectModule.location.other_increases_at_risk.other_increses_risk' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="otherIncreasesInRiskForm">
  <div [ngClass]="otherIncreasesInRiskForm.value.is_charging_station ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_charging_station">{{'objectModule.location.other_increases_at_risk.charging_station_e_Vehicles' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="otherIncreasesInRiskForm.value.is_charging_station" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.other_increases_at_risk.upload_document' | translate}}</label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}" title="{{otherIncreasesInRiskForm.controls['charging_station_documents'].value}}" formControlName="charging_station_documents">
          </div>

          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadChargingStationDocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div [ngClass]="otherIncreasesInRiskForm.value.is_increased_in_danger ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_increased_in_danger">{{'objectModule.location.other_increases_at_risk.is_there_increased_danger' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="otherIncreasesInRiskForm.value.is_increased_in_danger" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.location.other_increases_at_risk.description' | translate}}
        </label>
        <input type="text" maxlength="100" class="input" formControlName="increase_in_risk_description" placeholder="{{'objectModule.location.other_increases_at_risk.description' | translate}}"
          title="{{otherIncreasesInRiskForm.controls['increase_in_risk_description'].value}}">
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.other_increases_at_risk.upload_document' | translate}}</label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}" title="{{otherIncreasesInRiskForm.controls['increase_in_risk_documents'].value}}" formControlName="increase_in_risk_documents">
          </div>

          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadIncreasedDangerDocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label" style="line-break: anywhere;">
          {{'objectModule.location.other_increases_at_risk.distance_of_hazardours_neighbourhood_in_km' | translate}}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" pattern="[0-9]*" (keydown)="validation.SequenceNumberValidation($event, 'keydown',6)"
          (paste)="validation.SequenceNumberValidation($event, 'paste',6)" (blur)="validation.sqmValidation(otherIncreasesInRiskForm, $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm, $event)"
          formControlName="distance_of_hazardours_neighbourhood_in_km" placeholder="{{'objectModule.location.other_increases_at_risk.distance_of_hazardours_neighbourhood_in_km' | translate}}" numbersOnly>
        <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="validation.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
          (keypress)="validation.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)" (blur)="validation.sqmValidation(otherIncreasesInRiskForm, $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm, $event)"
          formControlName="distance_of_hazardours_neighbourhood_in_km" placeholder="{{'objectModule.location.other_increases_at_risk.distance_of_hazardours_neighbourhood_in_km' | translate}}" numbersOnly>
      </div>

      <div class="col-md-3 col-sm-6 col-6">


        <label for="operating_mode_of_neighbourhood" class="long-label">{{'objectModule.location.other_increases_at_risk.operating_mode_of_neighbourhood' | translate}}</label>
        <input type="text" id="operating_mode_of_neighbourhood" class="input" maxlength="100" autocomplete="off" placeholder="{{'objectModule.location.other_increases_at_risk.operating_mode_of_neighbourhood' | translate}}" matInput
          formControlName="operating_mode_of_neighbourhood" title="{{otherIncreasesInRiskForm.value.operating_mode_of_neighbourhood}}">
      </div>


    </div>
  </div>

  <div [ngClass]="otherIncreasesInRiskForm.value.are_flammable_substances ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="are_flammable_substances">{{'objectModule.location.other_increases_at_risk.are_there_flammable' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="otherIncreasesInRiskForm.value.are_flammable_substances" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.location.other_increases_at_risk.description' | translate}}
        </label>
        <input type="text" maxlength="100" class="input" formControlName="are_flammable_substances_description" placeholder="{{'objectModule.location.other_increases_at_risk.description' | translate}}"
          title="{{otherIncreasesInRiskForm.controls['are_flammable_substances_description'].value}}">
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.other_increases_at_risk.upload_document' | translate}}</label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}" title="{{otherIncreasesInRiskForm.controls['are_flammable_substances_documents'].value}}" formControlName="are_flammable_substances_documents">
          </div>

          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadFlammablDocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="type_of_flammable_or_explosive_substance" class="long-label">{{'objectModule.location.other_increases_at_risk.type_of_flammable_or_explosive_substance' | translate}}</label>
        <input type="text" id="type_of_flammable_or_explosive_substance" class="input pad-right" maxlength="100" autocomplete="off"
          placeholder="{{'objectModule.location.other_increases_at_risk.type_of_flammable_or_explosive_substance' | translate}}" matInput formControlName="type_of_flammable_or_explosive_substance"
          title="{{otherIncreasesInRiskForm.value.type_of_flammable_or_explosive_substance}}">
      </div>

      <div class="col-md-3 col-sm-6 col-6" style="display:flex;align-items: baseline;">
        <div style="width: 60%;margin-right: 2%;">
          <label for="" class="long-label" style="line-break: anywhere;">
            {{'objectModule.location.other_increases_at_risk.quantity_of_flammable_or_explosive_substance' | translate}}
          </label>
          <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" pattern="[0-9]*" (keydown)="validation.SequenceNumberValidation($event, 'keydown',6)"
            (paste)="validation.SequenceNumberValidation($event, 'paste',6)" (blur)="validation.sqmValidation(otherIncreasesInRiskForm, $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm, $event)"
            formControlName="quantity_of_flammable_or_explosive_substance" placeholder="{{'objectModule.location.other_increases_at_risk.type_of_flammable_or_explosive_substance_placeholder' | translate}}" numbersOnly>
          <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="validation.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
            (keypress)="validation.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)" (blur)="validation.sqmValidation(otherIncreasesInRiskForm, $event)" (focus)="validation.sqmValidation(otherIncreasesInRiskForm, $event)"
            formControlName="quantity_of_flammable_or_explosive_substance" placeholder="{{'objectModule.location.other_increases_at_risk.type_of_flammable_or_explosive_substance_placeholder' | translate}}" numbersOnly>
        </div>
        <div style="width:38%;">
          <label for="" class="long-label">
            {{'objectModule.location.other_increases_at_risk.unit' | translate}}
          </label>
          <mat-select class="dropdownStyle" [title]="unitName" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="unit">
            <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
            <mat-option *ngFor="let item of unit" [value]="item.id" matTooltip="{{item.desc}}">
              <span>{{item.desc}}</span>
            </mat-option>
          </mat-select>

        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
          <label for="" class="long-label">{{'objectModule.location.organizational_fire_protection.ls_labeled_safety_cabinets' | translate}}</label>
          <div class="radio-group-align-long">
              <mat-radio-group aria-label="" formControlName="ls_labeled_safety_cabinets" >
              <mat-radio-button [value]="true">
                  {{'objectModule.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button [value]="false">
                  {{'objectModule.no' | translate}}
              </mat-radio-button>
              </mat-radio-group>
          </div>
      </div>

    </div>
  </div>

  <div [ngClass]="otherIncreasesInRiskForm.value.is_company_gas_station ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_company_gas_station">{{'objectModule.location.other_increases_at_risk.company_gas_station' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="otherIncreasesInRiskForm.value.is_company_gas_station" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.location.other_increases_at_risk.upload_document' | translate}}</label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}" title="{{otherIncreasesInRiskForm.controls['company_gas_station_document'].value}}" formControlName="company_gas_station_document">
          </div>

          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadCompanyGasDocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.location.other_increases_at_risk.total_capacity_tanks' | translate}}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" class="input" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)"
          formControlName="company_gas_station_capacity" placeholder="{{'objectModule.location.other_increases_at_risk.total_capacity_placeholder' | translate}}" (blur)="validation.sqmValidation(otherIncreasesInRiskForm, $event)"
          (focus)="validation.sqmValidation(otherIncreasesInRiskForm, $event)" numbersOnly>
        <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)"
          formControlName="company_gas_station_capacity" placeholder="{{'objectModule.location.other_increases_at_risk.total_capacity_placeholder' | translate}}" (blur)="validation.sqmValidation(otherIncreasesInRiskForm, $event)"
          (focus)="validation.sqmValidation(otherIncreasesInRiskForm, $event)" numbersOnly>
      </div>
    </div>
  </div>

  <div [ngClass]="otherIncreasesInRiskForm.value.third_party_use ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="third_party_use">{{'objectModule.location.additional_general_information.third_party_use' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="otherIncreasesInRiskForm.value.third_party_use" class="row toggle-section-content">
      <div class="col-md-3 col-sm-6 col-6">

        <label for="type_of_Third_party_use" class="long-label">{{'objectModule.location.additional_general_information.type_of_Third_party_use' | translate}}</label>
        <input type="text" id="type_of_Third_party_use" class="input pad-right" maxlength="100" autocomplete="off" placeholder="{{'objectModule.location.additional_general_information.type_of_Third_party_use' | translate}}" matInput
          formControlName="type_of_Third_party_use" title="{{otherIncreasesInRiskForm.value.type_of_Third_party_use}}">
      </div>
    </div>
  </div>

</form>

<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)">
</app-upload-document>
