<div class="template-row-containers">
  <div class="template-left-container">
    <span class="template-criteria-heading">{{'template-management.available_template' | translate}}</span>
    <div class="template-criteria-body">
      <div class="template-criteria-inner-body">
        <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10" [scrollWindow]="false"
          class="infiniteScrollDiv">
          <div class="table-component-holder">
            <div class="col col-xs-3" class="searchBox">
              <img alt="" src="../../../assets/images/ic-icons/Search.svg" class="form-control-feedback custom-search">
              <input type="text" [value]="searchTextLeft" #input class="form-control custom_search"
                placeholder="{{'header.Suche_tooltip' | translate}}" aria-label="Search"
                (keyup)="applyAvailableDataFilter($event, dataValuesAvailable)">
            </div>
            
            <mat-table class="table-responsive table table-striped documents template-table" mat-table [dataSource]="dataValuesAvailable"
              cellpadding="5" cellspacing="0">
              <ng-container matColumnDef="select">
                <th class="custom-template-checkbox" mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                  <mat-checkbox (change)="$event ? masterToggle(availableValues, availableTempSelection) : null"
                                [checked]="availableTempSelection.hasValue() && isAllSelected(availableValues, availableTempSelection)"
                                [indeterminate]="availableTempSelection.hasValue() && !isAllSelected(availableValues, availableTempSelection)">
                  </mat-checkbox>
                </th>
                <td class="custom-template-checkbox" mat-cell *matCellDef="let row" [attr.colspan]="1">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? availableTempSelection.toggle(row) : null"
                                [checked]="availableTempSelection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="template_name">
                <th class="template-table-header" mat-header-cell [attr.colspan]="4" *matHeaderCellDef>
                  <span>
                    {{'template-management.template_name' | translate}}
                  </span>
                </th>
                <td mat-cell [attr.colspan]="4" *matCellDef="let element">
                  <span class="custom-ellipsis noSafariTooltip main-text" title="{{element.template_name}}">
                    {{element.template_name}}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="created_on">
                <th class="template-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                  <span>
                    {{'template-management.created_on' | translate}}
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [attr.colspan]="2">
                  <span class="custom-ellipsis noSafariTooltip main-text">
                    {{element.created_at | dateFormat}}<br />
                    <span class="table-below-text">{{element.created_at | dateFormat:'HH:mm'}}</span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="last_updated_on">
                <th class="template-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                  <span>
                    {{'template-management.last_updated_on' | translate}}
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [attr.colspan]="2">
                  <span class="custom-ellipsis noSafariTooltip main-text">
                    {{element.last_updated_on | dateFormat}}<br />
                    <span class="table-below-text">{{element.last_updated_on | dateFormat:'HH:mm'}}</span>
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedAvailableColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedAvailableColumns"></tr>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="template-center-container">
    <button class="arrow-btn" (click)="moveLeftToRight()" mat-button><mat-icon>keyboard_arrow_right</mat-icon></button>
    <button class="arrow-btn" (click)="moveRightToLeft()" mat-button><mat-icon>keyboard_arrow_left</mat-icon></button>
  </div>
  <div class="template-right-container">
    <span class="template-criteria-heading">{{'template-management.selected_template' | translate}}</span>
    <div class="template-criteria-body" *ngIf="selectedValues.length > 0">
      <div class="template-criteria-inner-body">
        <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10" [scrollWindow]="false"
          class="infiniteScrollDiv">
          <div class="table-component-holder">
            <div class="col col-xs-3" class="searchBox">
              <img alt="" src="../../../assets/images/ic-icons/Search.svg" class="form-control-feedback custom-search">
              <input type="text" [value]="searchTextRight" #input class="form-control custom_search"
                placeholder="{{'header.Suche_tooltip' | translate}}" aria-label="Search"
                (keyup)="applyAvailableDataFilter($event, dataValuesSelected)">
            </div>

            <mat-table class="table-responsive table table-striped documents template-table" mat-table [dataSource]="dataValuesSelected"
              cellpadding="5" cellspacing="0">
              <ng-container matColumnDef="select">
                <th class="custom-template-checkbox" mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                  <mat-checkbox (change)="$event ? masterToggle(selectedValues, selectedTempSelection) : null"
                                [checked]="selectedTempSelection.hasValue() && isAllSelected(selectedValues, selectedTempSelection)"
                                [indeterminate]="selectedTempSelection.hasValue() && !isAllSelected(selectedValues, selectedTempSelection)">
                  </mat-checkbox>
                </th>
                <td class="custom-template-checkbox" mat-cell *matCellDef="let row" [attr.colspan]="1">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selectedTempSelection.toggle(row) : null"
                                [checked]="selectedTempSelection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="template_name">
                <th class="template-table-header" mat-header-cell [attr.colspan]="4" *matHeaderCellDef>
                  <span>
                    {{'template-management.template_name' | translate}}
                  </span>
                </th>
                <td mat-cell [attr.colspan]="4" *matCellDef="let element">
                  <span class="custom-ellipsis noSafariTooltip main-text" title="{{element.template_name}}">
                    {{element.template_name}}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="created_on">
                <th class="template-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                  <span>
                    {{'template-management.created_on' | translate}}
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [attr.colspan]="2">
                  <span class="custom-ellipsis noSafariTooltip main-text">
                    {{element.created_at | dateFormat}}<br />
                    <span class="table-below-text">{{element.created_at | dateFormat:'HH:mm'}}</span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="last_updated_on">
                <th class="template-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="3">
                  <span>
                    {{'template-management.last_updated_on' | translate}}
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [attr.colspan]="3">
                  <span class="custom-ellipsis noSafariTooltip main-text">
                    {{element.last_updated_on | dateFormat}}<br />
                    <span class="table-below-text">{{element.last_updated_on | dateFormat:'HH:mm'}}</span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="created_by">
                <th class="template-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                  <span>
                    {{'template-management.user_assigned' | translate}}
                  </span>
                </th>
                <td mat-cell *matCellDef="let element" [attr.colspan]="2">
                  <span class="custom-ellipsis noSafariTooltip main-text" title="{{element.created_by}}">
                    {{element.created_by}}<br />
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedSelectedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedSelectedColumns"></tr>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedValues.length == 0" class="template-right-container-inner">
      <div class="empty-stepper-inner">
        {{'template-management.select_template_empty_msg' | translate}}
      </div>
    </div>
  </div>
</div>