import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import { takeUntil } from 'rxjs/operators';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { BaseComponent } from '@shared/components/base.component';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { CustomerManagementSharedService } from '../customer-management-shared-service';
export interface SaveCustomerDetails {
  branch_name: string;
  business_description: string;
  business_type_id: any;
  cmp_desc: string;
  cmp_desc_short: string;
  customer_additional_id: any;
  customer_id: any;
  crn: string;
  duns_number: string;
  est_of_cmp: any;
  fiscal_year: any;
  homePage: string;
  is_seasonal_op: boolean;
  last_annual_meeting: any;
  legal_form: string;
  liztu_vn_kdnr: any;
  turnover: any;
  vat_number: string;
}
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('operatingModeControl', { static: true })
  operatingModeDropdown: MatSelect;
  @ViewChild('legalFormControl', { static: true }) legalFormDrodown: MatSelect;

  operatingModeListFiltered: any = [];
  turnoverError = false;
  fiscalYearError = false;
  customer_additional_id: any;
  customerWarningMsg: CustomerWarningMsg;
  showWarningPopUp = false;
  reload = false;
  operatingModeListFilteredTemp: any;
  operatingModeFormControl = new FormControl();
  LegalFormControl = new FormControl();
  fiscalYearDummy = new FormControl();
  customerDetailsForm: FormGroup;
  today = new Date();
  maxDate = new Date(2000, 11, 31);
  minDate = new Date(2000, 0, 1);
  legalFormData = [];
  legalFormDataTemp: any[];
  editCustomer: boolean;

  constructor(
    private serviceCall: ApiCallsService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService
  ) {
    super();
    this.customerWarningMsg = {
      cancel: 'product-management.stepper_common_msg.discard',
      ok: 'product-management.stepper_common_msg.cancel_label',
      save_and_continue:
        'product-management.stepper_common_msg.save_and_continue',
      header: 'save-changes.Nicht_gespeicherte',
      body: 'save-changes.object_unsaved_message',
    };
    this.operatingModeFormControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterTypeOfBusiness(value);
      });
    this.LegalFormControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.filterTypeLegalForm(value);
      });
  }

  ngOnInit(): void {
    this._watchOnEditCustomerValue();
  }

  filterTypeLegalForm(value: any) {
    this.legalFormData = this.legalFormDataTemp;
    if (value !== '') {
      if (
        this.legalFormData.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.legalFormData = this.legalFormData.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.legalFormData = this.legalFormDataTemp;
      }
    } else {
      this.legalFormData = this.legalFormDataTemp;
    }
  }
  filterTypeOfBusiness(value: any) {
    this.operatingModeListFiltered = this.operatingModeListFilteredTemp;
    if (value !== '') {
      if (
        this.operatingModeListFiltered.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.operatingModeListFiltered = this.operatingModeListFiltered.filter(
          option => option.desc.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.operatingModeListFiltered = this.operatingModeListFilteredTemp;
      }
    } else {
      this.operatingModeListFiltered = this.operatingModeListFilteredTemp;
    }
  }
  focusoperatingModeSearch() {
    document.getElementById('operatingModeSearch').focus();
  }
  focuslegalFormSearch() {
    document.getElementById('legalFormSearch').focus();
  }
  valueChanged() {
    this.operatingModeFormControl.reset();
    setTimeout(() => this.operatingModeDropdown.focus(), 150);
  }
  valueChangedLegalForm() {
    this.LegalFormControl.reset();
    setTimeout(() => this.legalFormDrodown.focus(), 150);
  }

  warningPopupGuard() {
    this.showWarningPopUp = true;
  }
  getFormData() {
    this.serviceCall
      .getCustomerDetails(atob(this.route.snapshot.queryParams.liztu))
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.loadFormData(data.data);
      });
  }
  getDropDownData() {
    this.serviceCall
      .getBuildingOperatingMode()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.operatingModeListFiltered = data.data;
        this.operatingModeListFilteredTemp = this.operatingModeListFiltered;
      });
    this.serviceCall
      .getLegalForm('legal_form')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.legalFormData = data.data;
        this.legalFormDataTemp = this.legalFormData;
      });
  }
  loadFormData(data: any) {
    this.customer_additional_id = data.customer_additional_id;
    this.customerDetailsForm.controls.homePage.setValue(data.homePage);
    this.customerDetailsForm.controls.businessDesc.setValue(
      data.business_description
    );
    this.customerDetailsForm.controls.customerDescShort.setValue(
      data.cmp_desc_short
    );
    this.customerDetailsForm.controls.customerDescLong.setValue(data.cmp_desc);
    this.customerDetailsForm.controls.businessType.setValue(
      data.business_type_id
    );
    this.customerDetailsForm.controls.duns.setValue(data.duns_number);
    this.customerDetailsForm.controls.brnachName.setValue(data.branch_name);
    this.customerDetailsForm.controls.legalForm.setValue(data.legal_form);
    this.customerDetailsForm.controls.vatNumber.setValue(data.vat_number);
    this.customerDetailsForm.controls.Turnover.setValue(
      data.turnover
        ? this._currencyCustomPipe
            .transform(data.turnover)
            .replace('€', '')
            .trim()
        : data.turnover
    );
    this.customerDetailsForm.controls.comanyEstablishment.setValue(
      data.est_of_cmp ? moment(data.est_of_cmp).format('YYYY-MM-DD') : null
    );
    this.customerDetailsForm.controls.commercialNumber.setValue(data.crn);
    this.customerDetailsForm.controls.lastAnnualMeeting.setValue(
      data.last_annual_meeting
        ? moment(data.last_annual_meeting).format('YYYY-MM-DD')
        : null
    );
    this.customerDetailsForm.controls.fiscaYear.setValue(
      data.fiscal_year ? moment(data.fiscal_year).format('YYYY-MM-DD') : null
    );
    this.customerDetailsForm.controls.seasonalBusiness.setValue(
      data.is_seasonal_op !== null
        ? data.is_seasonal_op.toString()
        : data.is_seasonal_op
    );
    this.fiscalYearDummy.setValue(
      data.fiscal_year ? moment(data.fiscal_year).format('DD.MM.') : null
    );
    if (!this.editCustomer) {
      this.customerDetailsForm.disable();
      this.fiscalYearDummy.disable();
    } else {
      this.customerDetailsForm.enable();
      this.customerDetailsForm.controls.duns.disable();
      this.fiscalYearDummy.enable();
    }
    this.customerDetailsForm.markAsPristine();
  }
  initiateForm() {
    this.customerDetailsForm = new FormGroup({
      homePage: new FormControl(''),
      businessDesc: new FormControl(''),
      customerDescShort: new FormControl(''),
      customerDescLong: new FormControl(''),
      businessType: new FormControl(''),
      duns: new FormControl({ value: '', disabled: true }),
      brnachName: new FormControl(''),
      legalForm: new FormControl(''),
      vatNumber: new FormControl(''),
      Turnover: new FormControl(''),
      comanyEstablishment: new FormControl(''),
      commercialNumber: new FormControl(''),
      lastAnnualMeeting: new FormControl(''),
      fiscaYear: new FormControl(''),
      seasonalBusiness: new FormControl(''),
    });
  }
  setValidFromDate(event) {}
  saveCustomer() {
    if (!this.turnoverError) {
      const turnover = this.customerDetailsForm.controls.Turnover.value
        ?.replaceAll('.', '')
        .replaceAll(',', '.')
        .replace('€', '')
        .split(',')[0]
        .trim();
      const formData: SaveCustomerDetails = {
        branch_name: this.customerDetailsForm.controls.brnachName.value,
        business_description:
          this.customerDetailsForm.controls.businessDesc.value,
        business_type_id: this.customerDetailsForm.controls.businessType.value,
        cmp_desc: this.customerDetailsForm.controls.customerDescLong.value,
        cmp_desc_short:
          this.customerDetailsForm.controls.customerDescShort.value,
        customer_additional_id: this.customer_additional_id,
        customer_id: this._customerSharedService.getSelectedCustomerId(),
        crn: this.customerDetailsForm.controls.commercialNumber.value,
        duns_number: this.customerDetailsForm.controls.duns.value,
        est_of_cmp: this.customerDetailsForm.controls.comanyEstablishment.value,
        fiscal_year: this.customerDetailsForm.controls.fiscaYear.value,
        homePage: this.customerDetailsForm.controls.homePage.value,
        is_seasonal_op:
          this.customerDetailsForm.controls.seasonalBusiness.value,
        last_annual_meeting: this.customerDetailsForm.controls.lastAnnualMeeting
          .value
          ? moment(
              this.customerDetailsForm.controls.lastAnnualMeeting.value
            ).format('YYYY-MM-DD')
          : null,
        legal_form: this.customerDetailsForm.controls.legalForm.value,
        liztu_vn_kdnr: +atob(this.route.snapshot.queryParams.liztu),
        turnover: Number(turnover),
        vat_number: this.customerDetailsForm.controls.vatNumber.value,
      };
      this.serviceCall
        .saveCustomerDetails(formData)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.toastService.showToastMessage(document, data.message);
            this.getFormData();
          }
        });
    }
  }
  turnoverValidation(value) {
    const regexTemp = new RegExp('^[0-9.,]*$');
    const result = regexTemp.test(value.target.value);
    if (result) {
      this.turnoverError = false;
      this.customerDetailsForm.controls.Turnover.setValue(
        this._currencyCustomPipe
          .transform(value.target.value?.replaceAll('.', ''))
          ?.replace('€', '')
          ?.trim()
      );
    } else {
      this.turnoverError = true;
    }
  }
  onDateBlur(e) {
    const today = moment(this.today, 'DD-MM-YYYY').format('MM/DD/YYYY');
    const enteredDate = moment(e.target.value, 'DD-MM-YYYY').format(
      'MM/DD/YYYY'
    );
    const difference = moment(enteredDate).diff(moment(today), 'days');
    if (difference > 0) {
      e.target.value = '';
      this.customerDetailsForm.controls.comanyEstablishment.setValue(null);
    }
  }
  dateChange(e) {
    const regexp = new RegExp('^[0-9./]*$');
    let searchfind: boolean;
    if (e.target.value?.length >= 10) {
      e.preventDefault();
    }
    if (e?.type === 'paste') {
      searchfind = regexp.test(e.clipboardData.getData('text/plain'));
      if (!searchfind) {
        e.preventDefault();
      }
    } else {
      searchfind = regexp.test(e.key);
      if (!searchfind) {
        e.preventDefault();
      }
    }
  }
  fiscalYearValidation(e) {
    const regexp = new RegExp('^[0-9.]*$');
    let searchfind: boolean;
    if (e?.type === 'paste') {
      searchfind = regexp.test(e.clipboardData.getData('text/plain'));
      if (!searchfind) {
        e.preventDefault();
      }
    } else {
      searchfind = regexp.test(e.key);
      if (!searchfind) {
        e.preventDefault();
      } else {
        const ann = e.target.value.toString();
        const keee = ann.concat(e.key);
        const temp = keee.replace('.', '');
        if (temp.length > 4) {
          e.preventDefault();
        }
        const reg = new RegExp('^(([0-9]{0,2}){1}(.?)([0-9]{0,2}){1})(.?)$');
        const stringCheck = reg.test(keee);
        if (!stringCheck) {
          e.preventDefault();
        }
      }
    }
  }
  fiscalYearChange(event) {
    this.fiscalYearDummy.setValue(
      moment(event._validSelected).format('DD.MM.')
    );
  }
  onFiscalYearBlur(e) {
    this.fiscalYearError = false;
    const length = e.target.value.length;
    if (length === 3 || length === 4 || length === 5) {
      if (
        e.target.value === '292' ||
        e.target.value === '29.02' ||
        e.target.value === '29.2' ||
        e.target.value === '2902'
      ) {
        this.fiscalYearDummy.setValue(
          moment('29-2-2000', 'DD-MM-YYYY').format('DD.MM.')
        );
      } else {
        this.fiscalYearDummy.setValue(
          moment(e.target.value, 'DD-MM-YYYY').format('DD.MM.')
        );
      }
      if (this.fiscalYearDummy.value !== 'Invalid date') {
        const dividedDate = this.fiscalYearDummy.value.split('.');
        const day = Number(dividedDate[0]);
        const month = Number(dividedDate[1]);
        this.customerDetailsForm.controls.fiscaYear.setValue(
          new Date(2000, month - 1, day)
        );
      } else {
        this.fiscalYearError = true;
        this.fiscalYearDummy.setValue('');
        this.customerDetailsForm.controls.fiscaYear.setValue(null);
      }
    } else {
      this.customerDetailsForm.controls.fiscaYear.setValue(null);
    }
  }
  handleWarningPopup(event) {
    this.showWarningPopUp = false;
    if (!event) {
      this._customerManagementSharedService.IVMSyncToggle$.next(true);
      this.reload = true;
    } else {
      this.reload = false;
      this.initiateForm();
      this.getDropDownData();
      this.getFormData();
    }
  }
  saveCustomerData(event) {
    if (event) {
      this.showWarningPopUp = false;
      this.saveCustomer();
    }
  }

  private _watchOnEditCustomerValue(): void {
    this._customerManagementSharedService.isEditCustomerOperation$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.editCustomer = value;
        if (this.editCustomer && this.customerDetailsForm?.dirty) {
          this.warningPopupGuard();
        } else {
          if (!this.reload) {
            this.initiateForm();
            this.getDropDownData();
            this.getFormData();
          }
        }
      });
  }
}
