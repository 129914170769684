import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  BuildingConstants,
  PlasticFormConstants,
} from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';

@Component({
  selector: 'app-plastic',
  templateUrl: './plastic.component.html',
  styleUrls: [
    './plastic.component.css',
    '../../../object-module.component.scss',
  ],
})
export class PlasticComponent implements OnInit {
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  plasticFormGroup: FormGroup;
  isFormValueUpdated: boolean;
  isManufacturersOfPlastics: boolean;
  isSprayingMachines: boolean;
  request: BuildingObject;

  constructor(private plasticObjectBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[BuildingConstants.PLASTIC] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.PLASTIC]
          .saved_criteria
      );
    const plastic_pm_auto_shutdown = new FormControl(
      {
        value: formData
          ? formData.plastic.is_pressure_monitoring_with_automatic_shutdown
          : null,
        disabled: false,
      },
      []
    );
    const plastic_tubularfilms = new FormControl(
      {
        value: formData
          ? formData.plastic.is_production_of_tubular_films
          : null,
        disabled: false,
      },
      []
    );
    const plastic_foamed_plastics = new FormControl(
      {
        value: formData
          ? formData.plastic.is_production_of_foamed_plastics
          : null,
        disabled: false,
      },
      []
    );
    const plastic_self_ignition = new FormControl(
      {
        value: formData
          ? formData.plastic.manufacture_of_plastics
              .is_use_of_substances_prone_to_self_ignition
          : null,
        disabled: false,
      },
      []
    );
    const plastic_organic_peroxides = new FormControl(
      {
        value: formData
          ? formData.plastic.manufacture_of_plastics
              .is_production_of_organic_peroxides
          : null,
        disabled: false,
      },
      []
    );
    const plastic_use_of_explosives = new FormControl(
      {
        value: formData
          ? formData.plastic.manufacture_of_plastics
              .is_production_use_of_explosives
          : null,
        disabled: false,
      },
      []
    );
    const plastic_use_of_highly_flammable = new FormControl(
      {
        value: formData
          ? formData.plastic.manufacture_of_plastics
              .is_production_use_of_highly_flammable_or_explosive_substances
          : null,
        disabled: false,
      },
      []
    );
    const plastic_automatic_switch_off = new FormControl(
      {
        value: formData
          ? formData.plastic.spraying_machines
              .plasis_safety_temperature_limiter_with_automatic_switch_off
          : null,
        disabled: false,
      },
      []
    );
    const isManufacturersOfPlastics = new FormControl(
      {
        value: formData ? formData.plastic.is_manufacture_of_plastics : null,
        disabled: false,
      },
      []
    );
    const isSprayingMachines = new FormControl(
      {
        value: formData ? formData.plastic.is_spraying_machines : null,
        disabled: false,
      },
      []
    );
    const annotations = new FormControl(
      {
        value: formData ? formData.plastic.annotations : null,
        disabled: false,
      },
      []
    );
    const is_annotation = new FormControl(
      {
        value: formData ? formData.plastic.is_annotation : null,
        disabled: false,
      },
      []
    );

    this.plasticFormGroup = this.plasticObjectBuilder.group({
      plastic_pm_auto_shutdown,
      plastic_tubularfilms,
      plastic_foamed_plastics,
      plastic_self_ignition,
      plastic_organic_peroxides,
      plastic_use_of_explosives,
      plastic_use_of_highly_flammable,
      plastic_automatic_switch_off,
      isManufacturersOfPlastics,
      isSprayingMachines,
      annotations,
      is_annotation,
    });

    this.isFormValueUpdated = false;
    this.plasticFormGroup.valueChanges.subscribe(value => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.plasticFormGroup.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }

  buildFormObject() {
    const manufactureOfPlastic = {
      is_use_of_substances_prone_to_self_ignition:
        this.plasticFormGroup.value[PlasticFormConstants.SELF_IGNITION],
      is_production_of_organic_peroxides:
        this.plasticFormGroup.value[PlasticFormConstants.ORGANIC_PEROXIDES],
      is_production_use_of_explosives:
        this.plasticFormGroup.value[PlasticFormConstants.USE_OF_EXPLOSIVES],
      is_production_use_of_highly_flammable_or_explosive_substances:
        this.plasticFormGroup.value[
          PlasticFormConstants.USE_OF_HIGHLY_FLAMMABLE
        ],
    };

    const sprayingMachine = {
      plasis_safety_temperature_limiter_with_automatic_switch_off:
        this.plasticFormGroup.value[PlasticFormConstants.AUTOMATIC_SWITCH_OFF],
    };

    const plasticFromAttributeList = {
      is_pressure_monitoring_with_automatic_shutdown:
        this.plasticFormGroup.value[PlasticFormConstants.AUTOMATIC_SHUTDOWN],
      is_production_of_tubular_films:
        this.plasticFormGroup.value[PlasticFormConstants.TUBULAR_FILMS],
      is_production_of_foamed_plastics:
        this.plasticFormGroup.value[PlasticFormConstants.FOAMED_PLASTICS],
      is_manufacture_of_plastics:
        this.plasticFormGroup.value[
          PlasticFormConstants.IS_MANUFACTURE_OF_PLASTICS
        ],
      manufacture_of_plastics: manufactureOfPlastic,
      is_spraying_machines:
        this.plasticFormGroup.value[PlasticFormConstants.IS_SPRAYING_MACHINES],
      spraying_machines: sprayingMachine,

      annotations: this.plasticFormGroup.value.annotations,
      is_annotation: this.plasticFormGroup.value.is_annotation,
    };
    const formValuesToPersist = {
      plastic: plasticFromAttributeList,
    };
    this.request = {
      criteria_id: BuildingConstants.PLASTIC,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }

  ngOnDestroy() {
    if (this.isFormValueUpdated && this.plasticFormGroup.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
