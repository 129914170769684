import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableStructure } from '../../../models/column-details.model';
import {
  DeleteConfirmationRequest,
  DeleteConfirmationResponse,
  DeleteServiceRequest,
} from '@shared/interfaces/delete-confirm.interface';
import { LookupResponse } from '../../../models/response.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@core/services/toast.service';
import { PATHS, STATIC_TEXT } from '../../../constants/string.constants';
import { IMG_URL } from '../../../constants/image-urls.constants';
import {
  COLUMN_TYPES,
  TOOLTIP_PLACEMENT,
} from '../../../constants/column-types.constants';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { ContactPersonApiService } from '@core/services/httpcalls/contact-person-api.service';
import {
  COLUMNS,
  COLUMN_NAMES,
  CONTACT_PERSON_ACTION_METHOD_NAMES,
  CP_COLUMN_HEADINGS,
  MULTILINGUAL_CONSTS,
} from '../../../../components/insurer-management/models/contact-person.constants';
import { CP_FORM_CONTROL } from '@modules/new-claims/models/table.constants';
import {
  ContactPersonData,
  ContactPersonTableData,
} from '@components/insurer-management/models/contact-person.models';
import { map, takeUntil } from 'rxjs/operators';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { DeleteService } from '@core/services/shared/delete.service';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { TABLE_MAX_HEIGHT } from '@shared/constants/general/table.constants';
import { FilterSectionConfig, TableConfiguration } from '@shared/models/table.model';

@Component({
  selector: 'contact-person-overview',
  templateUrl: './contact-person-overview.component.html',
  styleUrls: ['./contact-person-overview.component.css'],
})
export class ContactPersonOverviewComponent
  extends BaseComponent
  implements OnInit
{
  @Input() message;
  @Input() insurerId;
  @Input() triggerFrom;
  @Output('iscontactPersonAddEdit') iscontactPersonAddEdit =
    new EventEmitter<any>();
  @Output('contactPersonId') contactPersonId = new EventEmitter<any>();
  tableStructure: TableStructure;
  tableData: ContactPersonData[] = [];
  showConfirmDeletePopup = false;
  sendDeleteReq: DeleteConfirmationRequest;
  tableConfig: TableConfiguration;
  filterSectionConfig: FilterSectionConfig;
  contactPersonRoles: LookupResponse[] = [];
  tableDataCopy: ContactPersonData[] = [];
  is_IC_USER = false;

  constructor(
    private _translateService: TranslateService,
    private _toastService: ToastService,
    private _cpAPICallsService: ContactPersonApiService,
    private _deleteService: DeleteService,
    private readonly _userService: UserService
  ) {
    super();
    // this.getRolesData();
    this.prepareTableConfiguration();
    this.tableStructure = this.prepareTableStructure();
  }

  ngOnInit(): void {
    this.is_IC_USER =
      this._userService.getUserRole() === USER_ROLES.IC_USER;
    this.getRolesData();
    this.getContactPersonOverview();
    if (this.message) {
      this._toastService.showToastMessage(document, this.message);
    }
  }

  getContactPersonOverview() {
    const request = {
      sourceId: this.insurerId || null,
      roleId: null,
      page: 1,
      pageRecordSize: 25,
      pagination: false,
      sourceType: this.triggerFrom,
    };
    this._cpAPICallsService
      .getContactPersonOverview(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.tableData =
          (data?.contactPersons &&
            this.prepareTableData(
              this.prepareTableRequest(data?.contactPersons)
            )) ||
          [];
        this.tableDataCopy = [...this.tableData];
      });
  }

  getRolesData() {
    this._cpAPICallsService
      .getContactPersonRoles(this.triggerFrom)
      .pipe(
        map(data => data?.customer_roles || []),
        map(customerRoles =>
          customerRoles.map(role => ({ id: role.id, desc: role.name }))
        )
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(contactPersonRoles => {
        this.contactPersonRoles = contactPersonRoles;
        this.prepareFilterSectionConfig();
      });
  }

  prepareTableConfiguration() {
    this.tableConfig = {
      isFilterSection: true,
      isPagination: false,
      isHeadingAboveFilter: true,
      headingText: this._translateService.instant(MULTILINGUAL_CONSTS.CP),
    };
  }

  prepareFilterSectionConfig() {
    this.filterSectionConfig = {
      heading: STATIC_TEXT.FILTER,
      defaultOpen: true,
      isPagination: false,
      elements: [
        {
          elementType: 1, // 1 for dropdown,
          isLabel: false,
          placeholder: this._translateService.instant(
            MULTILINGUAL_CONSTS.SELECT_ROLE
          ),
          data: this.contactPersonRoles,
          isSearchable: true,
          isSelectShow: true,
          controlName: CP_FORM_CONTROL.ROLE,
          value: null,
        },
      ],
      isFilterBtn: true,
      isResetBtn: true,
      resetBtnIconLink: `${PATHS.IMG}${IMG_URL.REFRESH}`,
      resetTooltip: {
        tooltipText: this._translateService.instant(
          MULTILINGUAL_CONSTS.RESET_TEXT
        ),
        tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
      },
      isFirstTimeLoad: true,
    };
  }

  toUpperCase(string) {
    return string.toUpperCase();
  }

  prepareTableStructure(): TableStructure {
    const structure: TableStructure = {
      displayColumns: COLUMNS,
      columnDetails: [
        {
          matColumnDef: COLUMN_NAMES.NAME,
          colHeading: this.toUpperCase(
            this._translateService.instant(CP_COLUMN_HEADINGS.NAME)
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.ROLE,
          colHeading: this.toUpperCase(
            this._translateService.instant(CP_COLUMN_HEADINGS.ROLE)
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.MAIN_CONTACT_PERSON,
          colHeading: this.toUpperCase(
            this._translateService.instant(
              CP_COLUMN_HEADINGS.MAIN_CONTACT_PERSON
            )
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.COMMENT,
          colHeading: this.toUpperCase(
            this._translateService.instant(CP_COLUMN_HEADINGS.COMMENT)
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.ACTION,
          colHeading: this.toUpperCase(
            this._translateService.instant(CP_COLUMN_HEADINGS.ACTION)
          ),
          columnType: COLUMN_TYPES.ACTION,
        },
      ],
      optionalStyles: {
        maxHeight: TABLE_MAX_HEIGHT,
      },
    };
    return structure;
  }

  prepareTableData(requst: ContactPersonTableData[]): ContactPersonData[] {
    const contactPersonData: ContactPersonData[] = requst.map(
      (ele: ContactPersonTableData) => {
        const contactPerson: ContactPersonData = {
          id: ele.Id,
          name: ele.Name,
          role: ele.Role,
          mainContactPerson: this.returnIsMainContactPerson(
            ele.MainContactPerson
          ),
          comment: ele.Comment,
          action: [
            {
              iconLink: ACTION_BUTTON.EDIT_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                CONTACT_PERSON_ACTION_METHOD_NAMES.EDIT_CONTACT_PERSON,
              isDisabled: false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.EDIT_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
            {
              iconLink: ACTION_BUTTON.DELETE_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                CONTACT_PERSON_ACTION_METHOD_NAMES.DELETE_CONTACT_PERSON,
              isDisabled: this.is_IC_USER || false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.DELETE_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
          ],
        };
        return contactPerson;
      }
    );
    return contactPersonData;
  }

  returnIsMainContactPerson(isMainContactPerson: any) {
    if (isMainContactPerson == false) {
      return 'No';
    } else if (isMainContactPerson == true) {
      return 'Yes';
    }
  }

  prepareTableRequest(data): ContactPersonTableData[] {
    const req: ContactPersonTableData[] = [];
    data.forEach((cp: any) => {
      const contactPerson: ContactPersonTableData = {
        Id: cp.id,
        Name: `${cp.lastName} ${cp.firstName}`,
        Role: cp.role,
        MainContactPerson: cp.isMainContactPerson,
        Comment: cp.comments,
      };
      req.push(contactPerson);
    });
    return req;
  }

  callMethod(data: any): void {
    if (data && data.methodName) {
      switch (data.methodName) {
        case CONTACT_PERSON_ACTION_METHOD_NAMES.EDIT_CONTACT_PERSON:
          this.editContactPerson(data.element);
          break;
        case CONTACT_PERSON_ACTION_METHOD_NAMES.DELETE_CONTACT_PERSON:
          this.deleteContactPerson(data.element);
          break;
      }
    }
  }

  editContactPerson(cp): void {
    this.contactPersonId.emit(cp.id);
    this.iscontactPersonAddEdit.emit(true);
  }

  deleteContactPerson(deleteObject): void {
    if (deleteObject) {
      const config: DeleteServiceRequest = {
        id: deleteObject.id,
      };
      this.showConfirmDeletePopup = true;
      this.sendDeleteReq = this._deleteService.deleteConfig(config);
    }
  }

  deleteCPPostConfirmation(deleteReq: DeleteConfirmationResponse): void {
    this.showConfirmDeletePopup = deleteReq?.showPopup;
    if (deleteReq.isAllowed) {
      this.deleteCP(deleteReq.id);
    }
  }

  deleteCP(id): void {
    this._cpAPICallsService
      .deleteContactPersonById(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: any) => {
        this._toastService.showToastMessage(document, response.message);
        if (!response.HasErrors) {
          this.getContactPersonOverview();
        }
      });
  }

  filterTable(event) {
    const roleData = event?.data[CP_FORM_CONTROL.ROLE];
    const roleSelected = this.contactPersonRoles.find(
      ele => ele.id == roleData
    )?.desc;
    if (event?.isReset || !roleData) {
      this.tableData = this.tableDataCopy;
    } else {
      this.tableData = this.tableDataCopy.filter(
        ele => ele.role === roleSelected
      );
    }
  }
}
