import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterSelectOption } from '../../table/filter-section/filter-section.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { REGEX } from '@shared/constants/general/regex.constants';
import { REMINDER_OPTIONS } from '@shared/constants/reminder.constants';
import { AppointmentApiService } from '@core/services/httpcalls/appointment-api.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { AttendeeApiService } from '@core/services/httpcalls/attendee-api.service';
import { Attendee } from '@shared/models/attendee.model';
import { CreateAppointmentBody } from '@shared/models/appointment.model';
import * as moment from 'moment';
import { iif } from 'rxjs';
import { SourceType } from '@shared/models/source-type.model';
import { DATE_ISO_FORMAT } from '@shared/constants/common.constants';

@Component({
  selector: 'app-create-appointment-popup',
  templateUrl: 'create-appointment-popup.component.html',
  styleUrls: ['create-appointment-popup.component.scss'],
})
export class CreateAppointmentPopupComponent
  extends BaseComponent
  implements OnInit
{
  appointmentForm: FormGroup;
  guestOptions: FilterSelectOption[] = [];
  reminderOptions = REMINDER_OPTIONS;
  searchControl = new FormControl();
  externalGuests = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  minDate = new Date();

  constructor(
    private _dialogRef: MatDialogRef<CreateAppointmentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fb: FormBuilder,
    private _appointmentApiService: AppointmentApiService,
    private _attendeeApiService: AttendeeApiService
  ) {
    super();
  }

  ngOnInit() {
    this._loadAttendees();
    this._buildForm();
  }

  closeModal(): void {
    this._dialogRef.close();
  }

  resetSearch(): void {
    this.searchControl.reset();
  }

  getOptionDisplay(option: FilterSelectOption): 'flex' | 'none' {
    const searchValue = this.searchControl.value;
    return !searchValue ||
      option.label?.toLowerCase().includes(searchValue.toLowerCase()) ? 'flex' : 'none';
  }

  confirm(): void {
    if (this.appointmentForm.invalid) {
      return;
    }

    const from = moment(this.appointmentForm.get('timeFrom').value);
    const to = moment(this.appointmentForm.get('timeTo').value);

    const body: CreateAppointmentBody = {
      subject: this.appointmentForm.get('subject').value,
      description: this.appointmentForm.get('description').value,
      location: this.appointmentForm.get('location').value,
      startDateTime: moment(this.appointmentForm.get('date').value)
        .set('hours', from.get('hours'))
        .set('minutes', from.get('minutes'))
        .format(DATE_ISO_FORMAT),
      endDateTime: moment(this.appointmentForm.get('date').value)
        .set('hours', to.get('hours'))
        .set('minutes', to.get('minutes'))
        .format(DATE_ISO_FORMAT),
      attendees: [
        ...this.appointmentForm.get('employees').value,
        ...this.appointmentForm.get('external').value,
      ],
      reminderInMinutesBeforeStart: this.appointmentForm.get('reminder').value,
      customerId: this._data?.appointment
        ? +this._data?.appointment.customerId
        : +this._data.customerId,
      divisionId: this._data?.appointment
        ? this._data?.appointment.divisionId
        : +this._data.divisionId,
      ...this._getSourceParam(),
    };

    iif(
      () => !!this._data?.appointment,
      this._appointmentApiService.updateAppointment(
        this._data?.appointment?.id,
        body
      ),
      this._appointmentApiService.createAppointment(body)
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._dialogRef.close(true);
      });
  }

  removeExternalGuest(guest: string): void {
    const externalControl = this.appointmentForm.controls.external;

    const value = externalControl.value.filter(external => external !== guest);

    externalControl.patchValue(value);
  }

  addExternalGuest(event: any): void {
    const value = event.value.trim();

    if (!value) {
      return;
    }

    const externalControl = this.appointmentForm.controls.external;

    const regexp = new RegExp(REGEX.EMAIL_VALIDATION);

    if (!regexp.test(value) || externalControl.value?.includes(value)) {
      return;
    }

    externalControl.patchValue([...externalControl.value, value]);

    event.input.value = '';
  }

  getSelectedGuest(email: string): FilterSelectOption {
    return this.guestOptions?.find(guest => guest.value === email);
  }

  private _getSourceParam(): { [key: string]: number } {
    switch (this._data?.source) {
      case SourceType.CLAIM:
        return {
          claimId: this._data?.appointment?.claimId || +this._data?.sourceId,
        };
      case SourceType.CONTRACT:
        return {
          contractId:
            this._data?.appointment?.contractId || +this._data?.sourceId,
        };
      case SourceType.INSURER:
        return {
          insurerId:
            this._data?.appointment?.insurerId || +this._data?.sourceId,
        };
      case SourceType.INTERMEDIARY:
        return {
          intermediaryId:
            this._data?.appointment?.intermediaryId || +this._data?.sourceId,
        };
      default:
        return {};
    }
  }

  private _buildForm(): void {
    // TODO: add validation
    this.appointmentForm = this._fb.group({
      subject: [this._data?.appointment?.subject || null, Validators.required],
      description: [
        this._data?.appointment?.description || null,
        Validators.required,
      ],
      location: [this._data?.appointment?.location || null],
      date: [this._data?.appointment?.date || new Date(), Validators.required],
      timeFrom: [
        this._data?.appointment?.startDateTime
          ? new Date(this._data?.appointment.startDateTime)
          : null,
        Validators.required,
      ],
      timeTo: [
        this._data?.appointment?.endDateTime
          ? new Date(this._data?.appointment.endDateTime)
          : null,
        Validators.required,
      ],
      employees: [
        this._data?.appointment
          ? this._getEmails(this._data?.appointment.attendees)
          : [],
      ],
      external: [
        this._data?.appointment
          ? this._getEmails(this._data?.appointment.attendees, true)
          : [],
      ],
      reminder: [
        this._data?.appointment?.reminderInMinutesBeforeStart || null,
        Validators.required,
      ],
    });
  }

  private _getEmails(attendees: Attendee[], isExternal = false): string[] {
    return attendees
      .filter(attendee =>
        isExternal ? attendee.isExternal : !attendee.isExternal
      )
      .map(attendee => attendee.email);
  }

  private _loadAttendees(): void {
    this._attendeeApiService
      .getAttendees()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.guestOptions = this._formatToGuestOptions(data);
      });
  }

  private _formatToGuestOptions(attendees: Attendee[]): FilterSelectOption[] {
    return attendees.map(attendee => ({
      label: attendee.name,
      value: attendee.email,
      data: {
        imgSrc: attendee.profilePictureUrl,
      },
    }));
  }
}
