import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from '@shared/models/component-can-deactivate.model';
import { SaveProfileChangesDialogComponent } from '../../components/save-profile-changes-dialog/save-profile-changes-dialog.component';
import { SearchComponent } from '../../components/search/search.component';
import { ShareDataService } from '../services/share-data.service';

@Injectable({
  providedIn: 'root',
})
export class LeaveObjectGuard
  implements CanActivate, CanDeactivate<ComponentCanDeactivate>
{
  discardObject: Subscription;
  isUserWantToNevigate = false;
  constructor(
    private dialog: MatDialog,
    private shareData: ShareDataService
  ) {
    this.discardObject = this.shareData.getIsObjectDiscarded().subscribe(() => {
      this.shareData.setIsDiscard();
      this.isUserWantToNevigate = true;
    });
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let data = { nextUrl: nextState.url, currentUrl: currentState.url };
    this.setSearchItemPath(nextState, currentState);
    if (this.isUserWantToNevigate) {
      this.isUserWantToNevigate = false;
      return true;
    }

    if (component.canDeactivate()) {
      this.isUserWantToNevigate = false;
      return true;
    } else {
      const searchItemPath = sessionStorage.getItem('searchItemPath');
      if (searchItemPath) {
        data = this.getSearchProductPath(searchItemPath, currentState);
      }
      if (this.dialog.openDialogs.length == 0) {
        this.dialog.open(SaveProfileChangesDialogComponent, {
          width: '600px',
          data,
        });
      } else if (
        this.dialog.openDialogs.length == 1 &&
        this.dialog.openDialogs[0].componentInstance instanceof SearchComponent
      ) {
        return true;
      }
    }
    return component.canDeactivate();
  }

  /**
   * revisit this code to solve multi discard popup issue
   * now resolving only for search and object modal issue
   */
  setSearchItemPath(nextState, currentState): void {
    if (
      (nextState.url.indexOf('/customer-management') >= 0 ||
        nextState.url.indexOf('/contract') >= 0 ||
        nextState.url.indexOf('/claims') >= 0) &&
      currentState.url.indexOf('/add-new-object') >= 0
    ) {
      sessionStorage.setItem('searchItemPath', nextState.url);
      this.dialog.closeAll();
      this.dialog.openDialogs.length = 0;
    }
  }

  getSearchProductPath(searchItemPath: string, currentState) {
    if (
      searchItemPath.indexOf('/customer-management') >= 0 ||
      searchItemPath.indexOf('/contract') >= 0 ||
      searchItemPath.indexOf('/claims') >= 0
    ) {
      return {
        nextUrl: searchItemPath,
        currentUrl: currentState.url,
      };
    }
  }
}
