export interface OutboundEntryRequest {
    entity_type_id: number;
    entity_type: string;
    entity_protect_id?: number;
    entity_ivm_id?: number;
    division_id?: number;
    liztu?: number;
  }

export const IVMTYPE = {
    CUSTOMER: 'Customer',
    CONTRACT: 'Contract',
    CLAIM: 'Claim'
};

export const ENTITYTYPEID = {
    CUSTOMER : 27,
    CONTRACT: 29,
    CLAIM: 30
};

