import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ValidationSharedModule } from '@shared/validation-shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { UserDataComponent } from '../../components/user-data/user-data.component';
import { AddCustomerRelationComponent } from './add-customer-relation/add-customer-relation.component';
import { AddBankComponent } from './bank-account-details-page/add-bank/add-bank.component';
import { BankAccountDetailsPageComponent } from './bank-account-details-page/bank-account-details-page.component';
import { BankDetailsComponent } from './bank-account-details-page/bank-details/bank-details.component';
import { CommunicationDetailsComponent } from './communication-details/communication-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { ContactPersonPageComponent } from './contact-person-page/contact-person-page.component';
import { ContactPersonComponent } from './contact-person-page/contact-person/contact-person.component';
import { CustomerAppointmentsComponent } from './customer-appointments/customer-appointments.component';
import { CustomerDocumentsComponent } from './customer-documents/customer-documents.component';
import { CustomerManagementRoutingModule } from './customer-management-routing.module';
import { CustomerManagementSharedService } from './customer-management-shared-service';
import { CustomerManagementComponent } from './customer-management.component';
import { CustomerRelationsComponent } from './customer-relations/customer-relations.component';
import { GeneralCustomerDataComponent } from './general-customer-data/general-customer-data.component';
import { EditCustomerComponent } from './user-management-page/edit-customer/edit-customer.component';
import { UserManagementPageComponent } from './user-management-page/user-management-page.component';
import { UserManagementComponent } from './user-management-page/user-management/user-management.component';
import { AddContactPersonComponent } from './contact-person-page/add-contact-person/add-contact-person.component';
@NgModule({
  declarations: [
    CustomerManagementComponent,
    GeneralCustomerDataComponent,
    UserManagementComponent,
    BankDetailsComponent,
    CommunicationDetailsComponent,
    CompanyDetailsComponent,
    AddBankComponent,
    CustomerRelationsComponent,
    ContactPersonComponent,
    UserDataComponent,
    EditCustomerComponent,
    AddCustomerRelationComponent,
    CustomerDocumentsComponent,
    BankAccountDetailsPageComponent,
    UserManagementPageComponent,
    ContactPersonPageComponent,
    CustomerAppointmentsComponent,
    AddContactPersonComponent,
  ],
  imports: [
    NgxMatIntlTelInputModule,
    CustomerManagementRoutingModule,
    TranslateModule.forChild(),
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatMenuModule,
    MatPaginatorModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    TranslateModule,
    NgbModule,
    MatButtonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    InfiniteScrollModule,
    NgxLoadingModule,
    PdfViewerModule,
    ValidationSharedModule,
  ],
  providers: [CustomerManagementSharedService],
  entryComponents: [AddCustomerRelationComponent],
  exports: [EditCustomerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomerManagementModule {}
