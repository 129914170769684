import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableStructure } from '@shared/models/column-details.model';
import {
  MULTILINGUAL_CONSTS,
  COLUMNS,
  COLUMN_NAMES,
  COMMUNICATION_COLUMN_HEADINGS,
  COMMUNICATION_DATA,
  COMMUNICATION_FILTER_FORM_CONTROL,
  GET_COMMUNICATION_DATA_REQUEST,
  CommunicationTableData,
  COMMUNICATION_ACTION_METHOD_NAMES,
} from '@components/insurer-management/models/insurer-management.constants';
import {
  COLUMN_TYPES,
  TOOLTIP_PLACEMENT,
} from '@shared/constants/column-types.constants';
import { STATIC_TEXT, PATHS } from '@shared/constants/string.constants';
import { IMG_URL } from '@shared/constants/image-urls.constants';
import { InsurerApiCallsService } from '@core/services/httpcalls/insurer-api-calls.service';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import {
  DeleteConfirmationRequest,
  DeleteConfirmationResponse,
  DeleteServiceRequest,
} from '@shared/interfaces/delete-confirm.interface';
import { ToastService } from '@core/services/toast.service';
import { ROLES } from '@shared/constants/roles.constants';
import { DeleteService } from '@core/services/shared/delete.service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { TABLE_MAX_HEIGHT } from '@shared/constants/general/table.constants';
import { ActivatedRoute } from '@angular/router';
import { FilterSectionConfig, TableConfiguration } from '@shared/models/table.model';
@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css'],
})
export class CommunicationComponent extends BaseComponent implements OnInit {
  tableStructure: TableStructure;
  tableConfig: TableConfiguration;
  tableData: COMMUNICATION_DATA[] = [];
  tableDataCopy: COMMUNICATION_DATA[] = [];
  filterSectionConfig: FilterSectionConfig;
  communication_type;
  showConfirmDeletePopup = false;
  sendDeleteReq: DeleteConfirmationRequest;
  @Output('iscommunicationAddEdit') iscommunicationAddEdit =
    new EventEmitter<any>();
  @Output('communicationId') communicationId = new EventEmitter<any>();
  @Output('addCommunication') addCommunication = new EventEmitter<any>();
  @Output('widthOptionemit') widthOptionemit = new EventEmitter<any>();
  @Input('message') message;
  @Input() triggerFrom;
  @Input() sourceId;
  communication_description: any;
  insurer_id: any;
  is_IC_USER = false;
  constructor(
    private _translateService: TranslateService,
    private serviceCall: InsurerApiCallsService,
    private _toastService: ToastService,
    private _deleteService: DeleteService,
    private readonly _userService: UserService,
    private readonly _route: ActivatedRoute
  ) {
    super();
    this.prepareTableConfiguration();
    this.tableStructure = this.prepareTableStructure();
  }
  ngOnInit(): void {
    this.insurer_id = atob(this._route.snapshot.queryParams.insurerId || '');

    this.is_IC_USER = ROLES.IC_ROLES.includes(
      this._userService.getUserRole()
    );
    this.getCommunicationOverview();
    if (this.message) {
      this._toastService.showToastMessage(document, this.message);
    }
  }
  getCommunicationDescription(tableData) {
    const communication_description = tableData
      .map(item => item.description)
      .filter(str => str !== '');
    this.communication_description = communication_description.map(
      (value, index) => {
        return { id: index + 1, desc: value };
      }
    );
    this.prepareFilterSectionConfig();
  }
  getCommunicationOverview() {
    const request: GET_COMMUNICATION_DATA_REQUEST = {
      source_id: this.sourceId,
      source_type: this.triggerFrom,
    };
    this.serviceCall
      .getCommunicationData(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.tableData = data?.communication_details
          ? this.prepareTableData(
              this.prepareTableRequest(data?.communication_details)
            )
          : [];
        this.tableDataCopy = [...this.tableData];
        this.getCommunicationDescription(this.tableData);
      });
  }
  prepareTableRequest(data): CommunicationTableData[] {
    const req: CommunicationTableData[] = [];
    data.forEach((com: any) => {
      const communiction: CommunicationTableData = {
        Id: com.id,
        description: com.description,
        contact: com.contact,
        communication_type: com.communication_type,
        communication_type_id: com.communication_type_id,
      };
      req.push(communiction);
    });
    return req;
  }
  prepareTableData(requst: CommunicationTableData[]): COMMUNICATION_DATA[] {
    const communicationData: COMMUNICATION_DATA[] = requst.map(
      (ele: CommunicationTableData) => {
        const communication: COMMUNICATION_DATA = {
          id: ele.Id,
          communication_type: ele.communication_type,
          contact: ele.contact,
          description: ele.description,
          communication_type_id: ele.communication_type_id,
          action: [
            {
              iconLink: ACTION_BUTTON.EDIT_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                COMMUNICATION_ACTION_METHOD_NAMES.EDIT_COMMUNICATION,
              isDisabled: false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.EDIT_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
            {
              iconLink: ACTION_BUTTON.DELETE_WITH_BACKGROUND,
              isSingleTooltip: true,
              functionToBeCalled:
                COMMUNICATION_ACTION_METHOD_NAMES.DELETE_COMMUNICATION,
              isDisabled: this.is_IC_USER || false,
              singleTooltip: {
                tooltipText: this._translateService.instant(
                  MULTILINGUAL_CONSTS.DELETE_TOOLTIP
                ),
                tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
              },
            },
          ],
        };
        return communication;
      }
    );
    return communicationData;
  }
  prepareTableConfiguration() {
    this.tableConfig = {
      isFilterSection: true,
      isPagination: false,
      isHeadingAboveFilter: true,
      headingText: this._translateService.instant(
        MULTILINGUAL_CONSTS.COMMUNICATION_DETAILS
      ),
    };
  }

  toUpperCase(string) {
    return string.toUpperCase();
  }

  prepareTableStructure(): TableStructure {
    const structure: TableStructure = {
      displayColumns: COLUMNS,
      columnDetails: [
        {
          matColumnDef: COLUMN_NAMES.COMMUNICATION_TYPE,
          colHeading: this.toUpperCase(
            this._translateService.instant(
              COMMUNICATION_COLUMN_HEADINGS.COMMUNICATION_TYPE
            )
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.CONTACT,
          colHeading: this.toUpperCase(
            this._translateService.instant(
              COMMUNICATION_COLUMN_HEADINGS.CONTACT
            )
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.DESCRIPTION,
          colHeading: this.toUpperCase(
            this._translateService.instant(
              COMMUNICATION_COLUMN_HEADINGS.DESCRIPTION
            )
          ),
          columnType: COLUMN_TYPES.STRING,
        },
        {
          matColumnDef: COLUMN_NAMES.ACTION,
          colHeading: this.toUpperCase(
            this._translateService.instant(COMMUNICATION_COLUMN_HEADINGS.ACTION)
          ),
          columnType: COLUMN_TYPES.ACTION,
        },
      ],
      optionalStyles: {
        maxHeight: TABLE_MAX_HEIGHT,
      },
    };
    return structure;
  }
  prepareFilterSectionConfig() {
    this.filterSectionConfig = {
      heading: STATIC_TEXT.FILTER,
      defaultOpen: true,
      isPagination: false,
      elements: [
        {
          elementType: 1,
          isLabel: false,
          placeholder: this._translateService.instant(
            MULTILINGUAL_CONSTS.SELECT_COMMUNICATION
          ),
          data: this.communication_description,
          isSearchable: true,
          isSelectShow: true,
          controlName: COMMUNICATION_FILTER_FORM_CONTROL.DESCRIPTION,
          value: null,
        },
      ],
      isFilterBtn: true,
      isResetBtn: true,
      resetBtnIconLink: `${PATHS.IMG}${IMG_URL.REFRESH}`,
      resetTooltip: {
        tooltipText: this._translateService.instant(
          MULTILINGUAL_CONSTS.RESET_TEXT
        ),
        tooltipPlacement: TOOLTIP_PLACEMENT.TOP,
      },
      isFirstTimeLoad: true,
    };
  }
  callMethod(data: any): void {
    if (data && data.methodName) {
      switch (data.methodName) {
        case COMMUNICATION_ACTION_METHOD_NAMES.EDIT_COMMUNICATION:
          this.editCommunication(data.element);
          break;
        case COMMUNICATION_ACTION_METHOD_NAMES.DELETE_COMMUNICATION:
          this.deleteCommunication(data.element);
          break;
      }
    }
  }
  filterTable(event) {
    const com_desc = event?.data[COMMUNICATION_FILTER_FORM_CONTROL.DESCRIPTION];
    const descSelected = this.communication_description.find(
      ele => ele.id == com_desc
    )?.desc;
    if (event?.isReset || !com_desc) {
      this.tableData = this.tableDataCopy;
    } else {
      this.tableData = this.tableDataCopy.filter(
        ele => ele.description === descSelected
      );
    }
  }
  editCommunication(com): void {
    this.communicationId.emit(com.id);
    this.iscommunicationAddEdit.emit(true);
    this.addCommunication.emit(false);
    this.widthOptionemit.emit(true);
  }
  deleteCommunication(deleteObject): void {
    if (deleteObject) {
      const config: DeleteServiceRequest = {
        id: deleteObject.id,
      };
      this.showConfirmDeletePopup = true;
      this.sendDeleteReq = this._deleteService.deleteConfig(config);
    }
  }

  deleteCPPostConfirmation(deleteReq: DeleteConfirmationResponse): void {
    this.showConfirmDeletePopup = deleteReq?.showPopup;
    if (deleteReq.isAllowed) {
      this.deleteCP(deleteReq.id);
    }
  }

  deleteCP(id): void {
    this.serviceCall
      .deleteCommunication(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: any) => {
        this._toastService.showToastMessage(document, response.message);
        this.getCommunicationOverview();
      });
  }
}
