<div class="modal" [style.display]="'table'">
    <div class="modal-dialog modal-dialog-new">
        <div class="modal-content">
            <div class="modal-header modal-header-new">
                <span>{{'new-contract.general_information.create_doc' | translate}}</span>
                <button class="close-icon-btn" (click)="closeModal()">
                    <mat-icon class="close-icon">close</mat-icon>
                </button>
            </div>
            <div class="modal-body modal-body-new">
                <span class="span-label"> {{'new-contract.general_information.available_template' | translate}}</span>
                <mat-select (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')"
                    placeholder="{{'objectModule.select_lable' | translate}}" id="doc_temp"
                    [(ngModel)]="selected_template" (openedChange)="search.value = ''"
                    (selectionChange)="selectedTemplate($event)">
                    <input type="text" maxlength="20" id="searchSelect" class="searchSelect"
                        placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                    <mat-option *ngFor="let item of avaliable_templates" [value]="item.resourceUri"
                        title="{{item.resourceName}}">
                        {{item.resourceName}}
                    </mat-option>
                </mat-select><br />
                <br />
                <span class="span-label"> {{'new-contract.general_information.docDescrition' | translate}}</span><br />
                <input id="docDesc" matInput
                    placeholder="{{'new-contract.general_information.docDescrition' | translate}}"
                    [formControl]="docDescription">
            </div>
            <div class="modal-footer ml-auto mr-auto modal-footer-new">
                <button type="button" class="save-button-popup" mat-button [disabled]="!selected_template"
                    (click)="saveDocTemplate(false)">
                    {{'new-contract.general_information.create_doc_button' | translate}}
                </button>
                <button type="button" class="ml-2 save-button-popup" mat-button [disabled]="!selected_template"
                    (click)="saveDocTemplate(true)">
                    {{'new-contract.general_information.create_save_doc' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>