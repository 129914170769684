import { Injectable } from '@angular/core';
import { URLS } from '@shared/constants/url.constants';
import * as Keycloak from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  keycloakAuth: Keycloak.KeycloakInstance;

  init(): Promise<string | void> {
    return new Promise((resolve, reject) => {
      const config = {
        url: URLS.ic_kc_url,
        realm: URLS.ic_kc_realm,
        clientId: URLS.ic_kc_client,
      };
      this.keycloakAuth = Keycloak(config);
      this.keycloakAuth
        .init({
          onLoad: 'login-required',
          checkLoginIframe: false,
          responseMode: 'query',
        })
        .then(() => resolve(this.keycloakAuth.token))
        .catch(() => reject());
    });
  }
}
