import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'product-linked-template',
  templateUrl: './product-linked-template.component.html',
  styleUrls: ['./product-linked-template.compnent.css']
})
export class ProductLinkedTemplateComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @Input('availableProducts') availableValues;
  @Output('onclose') onclose = new EventEmitter();
  @Output('detachProd') detachProd = new EventEmitter();

  productToDetach;
  enableDeletePopup = false;
  productMsg;
  dataValuesAvailable = new MatTableDataSource<any>();
  displayedAvailableColumns: string[] = [
    'productName',
    'ACTION'
  ];

  constructor() {
  }

  ngOnInit(): void {
    this.enableDeletePopup = false;
    this.productMsg = {
      cancel: 'product-management.stepper_common_msg.cancel_label',
      ok: 'template-management.yes_detach',
      header: 'objectModule.Delete_popup_heading',
      body: 'template-management.detach_product_msg',
    };
    this.dataValuesAvailable.data = this.availableValues;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.availableValues) {
      this.ngOnInit();
    }
  }

  closeButton() {
    this.onclose.emit();
  }

  detachProduct(resourceUri) {
    this.productToDetach = resourceUri;
    this.enableDeletePopup = true;
  }
  handleDetachProductPopup(event) {
    this.enableDeletePopup = false;
    if (!event) {
     this.detachProd.emit(this.productToDetach);
    }
  }

  isWindows() {
    return navigator.userAgent.indexOf('Windows') != -1;
  }
}
