import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
const moment = require('moment-timezone');
@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css'],
})
export class GeneralComponent implements OnInit {
  liztu: any;
  customer_data: any;
  questions: any;
  decoded_liztu: any;
  formFilter: FormGroup;
  browser: string;
  currencyTypes: any;
  currancySymbol: any;
  selectedCurrency: any;
  is_update = false;
  currencyId: any;
  showSuccessModel = false;
  employeeQue = [25, 26, 27, 28];
  toggleQueId = [
    { que: 4, parent: 3 },
    { que: 5, parent: 3 },
    { que: 7, parent: 6 },
    { que: 8, parent: 6 },
    { que: 10, parent: 9 },
    { que: 12, parent: 11 },
    { que: 14, parent: 13 },
  ];
  lastUpdatedDate: any;
  questionData: any;
  isShow: boolean;
  userData: any;
  users: any = [];
  cetOffset = moment().tz('Europe/Berlin')._offset;
  CUSTOMER_TYPES = CUSTOMER_TYPES;

  constructor(
    private fb: FormBuilder,
    private apiCallService: ApiCallsService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private _userService: UserService,
    private _customerSharedService: CustomerSharedService
  ) {
    this.formFilter = this.fb.group({
      show_all_from_organization: [true],
      show_country_wise_data: [false],
    });
  }

  ngOnInit(): void {
    this.userData = this._userService.getLoggedInUser();
    this.browser = this.detectBrowser();
    this.decoded_liztu = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu = atob(this.decoded_liztu);
    const login_customer_liztu =
      this.userData.user_profile.customer_details.liztu_vn_kdnr;

    this._customerSharedService.selectedCustomerType$
      .pipe(filterNil(), take(1))
      .subscribe(type => {
        const payload = {
          login_customer: login_customer_liztu,
          liztu_vn_kdnr: this.liztu,
          customer_type: type,
          value_module_tab_id: 1,
        };
        this.getData(payload);
        this.lastUpdatedDate = '';
      });
  }
  saveObject() {
    if (this.hasError()) {
      this.formValidMessage('value-module.validation_message');
      return;
    }
    const utcDate = moment().tz('Europe/Berlin').format();
    const payload = {
      data: [],
      user_id: this.getUser.user_id,
      value_module_type: 1,
      is_update: this.is_update,
      is_country_wise_aggregated_data:
        this.formFilter.controls.show_country_wise_data.value,
    };
    this.questions.forEach(customer => {
      const queData = [];
      customer.questions_data.forEach(que => {
        if (que.question_id != 30) {
          que.updated_by =
            que.created_by && que.isChanged
              ? this.getUser.user_id
              : que.updated_by || '';
          que.updated_at =
            que.created_by && que.isChanged ? utcDate : que.updated_at || '';
          que.updated_by_name =
            que.created_by && que.isChanged
              ? this.getUser.first_name + ' ' + this.getUser.last_name
              : que.updated_by_name || '';
          que.created_by = que.created_by
            ? que.created_by
            : que.isChanged
              ? this.getUser.user_id
              : '';
          que.created_at = que.created_at
            ? que.created_at
            : que.isChanged
              ? utcDate
              : '';
          que.created_by_name = que.created_by_name
            ? que.created_by_name
            : que.isChanged
              ? this.getUser.first_name + ' ' + this.getUser.last_name
              : '';
          que.isChanged = false;
          if (que.currency_dropdown) {
            queData.push({
              questionId: que.question_id,
              value: que.value,
              currancy: que.currancy,
              updated_by: que.updated_by,
              updated_at: que.updated_at,
              updated_by_name: que.updated_by_name,
              created_by: que.created_by,
              created_at: que.created_at,
              created_by_name: que.created_by_name,
            });
          } else {
            queData.push({
              questionId: que.question_id,
              value: que.value,
              updated_by: que.updated_by,
              updated_at: que.updated_at,
              updated_by_name: que.updated_by_name,
              created_by: que.created_by,
              created_at: que.created_at,
              created_by_name: que.created_by_name,
            });
          }
        } else {
          queData.push({
            questionId: que.question_id,
            value: que.value,
            updated_by: que.updated_by,
            updated_at: que.updated_at,
            updated_by_name: que.updated_by_name,
            created_by: que.created_by,
            created_at: que.created_at,
            created_by_name: que.created_by_name,
          });
        }
      });
      payload.data.push({
        litzu_vn_kdnr: customer.liztu_vn_kdnr,
        value_module_id: this.is_update ? customer.value_module_id : 0,
        location_ids: customer.loction_ids,
        questions_data: JSON.stringify(queData),
      });
    });
    this.apiCallService.saveQuestionsData(payload).subscribe(data => {
      this.questions.forEach(
        (customer, index) => {
          customer.value_module_id = data.response_data[index].value_module_id;
        },
        err => {
          this.formValidMessage('value-module.error_text');
        }
      );
      this.showSuccessModel = true;
    });
  }
  showData(event) {
    const login_customer_liztu =
      this.userData.user_profile.customer_details.liztu_vn_kdnr;
    const payload = {
      login_customer: login_customer_liztu,
      liztu_vn_kdnr: this.liztu,
      customer_type: this._customerSharedService.getSelectedCustomerType(),
      show_all_from_organization:
        this.formFilter.controls.show_all_from_organization.value,
      show_country_wise_data:
        this.formFilter.controls.show_country_wise_data.value,
      value_module_tab_id: 1,
    };
    this.getData(payload);
  }
  getData(payload) {
    this.isShow = false;
    const requests = [];
    this.customer_data = [];
    this.questions = [];
    // let login_customer_liztu = this.userData.user_profile.customer_details.liztu_vn_kdnr
    // let payload = { "login_customer": login_customer_liztu, "liztu_vn_kdnr": this.liztu, "customer_type": this.type, "show_all_from_organization": this.formFilter.controls['show_all_from_organization'].value, "show_country_wise_data": this.formFilter.controls['show_country_wise_data'].value, value_module_tab_id: 1 };
    requests.push(
      this.apiCallService
        .getValueCustomer(payload)
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this.apiCallService
        .getValueQuestions(1)
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this.apiCallService.getCurrencies().pipe(catchError(error => of(error)))
    );
    forkJoin(requests).subscribe((data: any) => {
      this.customer_data =
        !data[0].hasErrors && data[0].customer_data
          ? data[0].customer_data
          : [];
      const questions =
        !data[1].hasErrors && data[1].questions ? data[1].questions : [];
      this.questionData = JSON.parse(JSON.stringify(questions));
      const customer_data = JSON.parse(JSON.stringify(this.customer_data));
      this.currencyTypes =
        !data[2].hasErrors && data[2].currency_types
          ? data[2].currency_types
          : [];
      this.currancySymbol = this.currencyTypes.find(
        obj => obj.cur_symbol == 'EUR'
      ).cur_code;
      this.selectedCurrency = this.currencyTypes.find(
        obj => obj.cur_symbol == 'EUR'
      ).cur_symbol;
      this.currencyId = this.currencyTypes.find(
        obj => obj.cur_symbol == 'EUR'
      ).id;
      const customerIds = [
        ...new Set(this.customer_data.map(obj => obj.liztu_vn_kdnr)),
      ];
      const getQes = {
        liztu_vn_kdnr: customerIds,
        show_all_from_organization:
          this.formFilter.controls.show_all_from_organization.value,
        show_country_wise_data:
          this.formFilter.controls.show_country_wise_data.value,
        value_module_tab_id: 1,
      };
      this.apiCallService
        .getQuestionsData(getQes)
        .pipe(catchError(error => of(error)))
        .subscribe(resp => {
          const queData = !resp.hasErrors && resp.data ? resp.data : [];
          this.lastUpdatedDate =
            !resp.hasErrors && resp.updated_at ? resp.updated_at : '';
          this.is_update = this.lastUpdatedDate ? true : false;
          questions.forEach(element => {
            element.currancySymbol = this.currancySymbol;
            element.selectedCurrency = this.selectedCurrency;
            element.currancy = this.currencyId;
          });
          queData.forEach(element => {
            element.questions_data = JSON.parse(element.questions_data);
          });
          customer_data.forEach((customer, index) => {
            customer.questions_data = JSON.parse(JSON.stringify(questions));
            customer.questions_data.forEach(element => {
              if (queData && queData.length) {
                const selectedCustomer = queData.filter(
                  _ => _.location_ids.join('') == customer.loction_ids.join('')
                );
                if (selectedCustomer.length) {
                  const values = selectedCustomer[0].questions_data.filter(
                    _ => _.questionId == element.question_id
                  );
                  element.value = values.length ? values[0].value : '';
                  if (!element.read_only) {
                    element.orignalValue = values.length ? values[0].value : '';
                    element.updated_by =
                      values.length && values[0].updated_by
                        ? values[0].updated_by
                        : '';
                    element.updated_by_name =
                      values.length && values[0].updated_by_name
                        ? values[0].updated_by_name
                        : '';
                    element.updated_at =
                      values.length && values[0].updated_at
                        ? values[0].updated_at
                        : '';
                    element.created_by =
                      values.length && values[0].created_by
                        ? values[0].created_by
                        : '';
                    element.created_by_name =
                      values.length && values[0].created_by_name
                        ? values[0].created_by_name
                        : '';
                    element.created_at =
                      values.length && values[0].created_at
                        ? values[0].created_at
                        : '';
                  }
                  if (element.question_id == 30) {
                    element.value = customer.vehicle_count;
                  }
                  if (element.currency_dropdown) {
                    element.currancy = values.length
                      ? values[0].currancy
                        ? values[0].currancy
                        : this.currencyId
                      : this.currencyId;
                    if (element.currancy) {
                      element.currancySymbol = this.currencyTypes.find(
                        obj => obj.id == element.currancy
                      ).cur_code;
                      element.selectedCurrency = this.currencyTypes.find(
                        obj => obj.id == element.currancy
                      ).cur_symbol;
                    } else {
                      element.currancySymbol = this.currencyTypes.find(
                        obj => obj.cur_symbol == 'EUR'
                      ).cur_code;
                      element.selectedCurrency = this.currencyTypes.find(
                        obj => obj.id == 'EUR'
                      ).cur_symbol;
                    }
                  }
                  customer.value_module_id =
                    selectedCustomer[0].value_module_id;
                }
              }
              if (element.question_id == 30) {
                element.value = customer.vehicle_count;
              } else if (queData.length == 0) {
                element.value = '';
              }
            });
          });
          this.isShow = true;
          this.questions = customer_data;
        });
    });
  }
  public intValidation(event, data) {
    const count = event.target.value.length;
    if (count > 1) {
      event.target.value = this._currencyCustomPipe
        .transform(
          event.target.value
            .toString()
            ?.replaceAll('.', '')
            ?.replaceAll(',', '.')
        )
        ?.replace('€', '')
        .replace(',00', '')
        ?.trim();
      data.value = event.target.value;
    }
    const tempValue = event.target.value.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 12) {
      data.error = this.translate.instant('value-module.limitError');
    } else {
      data.error = '';
    }
  }
  public intDecimalValidation(event) {
    const count = event.target.value.length;
    if (count > 1) {
      const valueelem = this._currencyCustomPipe
        .transform(
          event.target.value
            .toString()
            ?.replaceAll('.', '')
            ?.replaceAll(',', '.')
        )
        ?.replace('€', '')
        .replace(',00', '')
        ?.trim();
      event.target.value = valueelem;
    }
    const tempValue = event.target.value?.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 6) {
      alert('number more than 999.999');
    }
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  currancyChanged(event, data) {
    data.currancySymbol = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_code;
    data.selectedCurrency = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_symbol;
  }
  hasError() {
    let hasError = false;
    this.questions.forEach(customer => {
      const errordata = customer.questions_data.filter(
        _ => _.error || _.error1 || _.error2 || _.error3
      );
      if (errordata.length) {
        hasError = true;
      }
    });
    return hasError;
  }
  disableQue(i, j) {
    const ques = this.toggleQueId.filter(
      _ => _.que == this.questions[j].questions_data[i].question_id
    );
    if (ques.length) {
      const parentQue = this.questions[j].questions_data.filter(
        _ => _.question_id == ques[0].parent
      );
      if (parentQue.length) {
        if (parentQue && !parentQue[0].value) {
          return true;
        }
      }
    }
    return false;
  }
  valueValidation(i, j) {
    if (this.questions[j].questions_data[i].question_id == 1) {
      const child = this.questions[j].questions_data.filter(
        _ => _.question_id == 2
      );
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue1'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          child[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 2) {
      const parentQue = this.questions[j].questions_data.filter(
        _ => _.question_id == 1
      );
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue2'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 4) {
      const child = this.questions[j].questions_data.filter(
        _ => _.question_id == 5
      );
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue4'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          child[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 5) {
      const parentQue = this.questions[j].questions_data.filter(
        _ => _.question_id == 4
      );
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue5'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 15) {
      let child = this.questions[j].questions_data.filter(
        _ => _.question_id == 16
      );
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue15-1'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          child[0].error1 = '';
        }
      }
      if (this.questions[j].questions_data[i].error1) {
        return;
      }
      child = this.questions[j].questions_data.filter(_ => _.question_id == 17);
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error2 = this.translate.instant(
            'value-module.errorQue15-2'
          );
        } else {
          this.questions[j].questions_data[i].error2 = '';
          child[0].error1 = '';
        }
      }
      if (this.questions[j].questions_data[i].error1) {
        return;
      }
      child = this.questions.filter(_ => _.question_id == 18);
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error3 = this.translate.instant(
            'value-module.errorQue15-3'
          );
        } else {
          this.questions[j].questions_data[i].error3 = '';
          child[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 16) {
      const parentQue = this.questions.filter(_ => _.question_id == 15);
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue16'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 17) {
      const parentQue = this.questions.filter(_ => _.question_id == 15);
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue17'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error2 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 18) {
      const parentQue = this.questions.filter(_ => _.question_id == 15);
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue18'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error3 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 19) {
      let child = this.questions.filter(_ => _.question_id == 20);
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue19-1'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          child[0].error1 = '';
        }
      }
      if (this.questions[j].questions_data[i].error1) {
        return;
      }
      child = this.questions.filter(_ => _.question_id == 21);
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error2 = this.translate.instant(
            'value-module.errorQue19-2'
          );
        } else {
          this.questions[j].questions_data[i].error2 = '';
          child[0].error1 = '';
        }
      }
      if (this.questions[j].questions_data[i].error1) {
        return;
      }
      child = this.questions.filter(_ => _.question_id == 22);
      if (child.length) {
        if (
          child &&
          +child[0].value.replace('.', '') >
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error3 = this.translate.instant(
            'value-module.errorQue19-3'
          );
        } else {
          this.questions[j].questions_data[i].error3 = '';
          child[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 20) {
      const parentQue = this.questions.filter(_ => _.question_id == 19);
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue20'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error1 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 21) {
      const parentQue = this.questions.filter(_ => _.question_id == 19);
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue21'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error2 = '';
        }
      }
    } else if (this.questions[j].questions_data[i].question_id == 22) {
      const parentQue = this.questions.filter(_ => _.question_id == 19);
      if (parentQue.length) {
        if (
          parentQue &&
          +parentQue[0].value.replace('.', '') <
            +this.questions[j].questions_data[i].value.replace('.', '')
        ) {
          this.questions[j].questions_data[i].error1 = this.translate.instant(
            'value-module.errorQue22'
          );
        } else {
          this.questions[j].questions_data[i].error1 = '';
          parentQue[0].error3 = '';
        }
      }
    } else if (
      this.questions[j].questions_data[i].question_id == 25 ||
      this.questions[j].questions_data[i].question_id == 26 ||
      this.questions[j].questions_data[i].question_id == 27 ||
      this.questions[j].questions_data[i].question_id == 28
    ) {
      const employee = this.questions[j].questions_data.filter(
        _ => _.question_id == 24
      );
      const fullTime = this.questions[j].questions_data.filter(
        _ => _.question_id == 25
      );
      const partTime = this.questions[j].questions_data.filter(
        _ => _.question_id == 26
      );
      const apprentices = this.questions[j].questions_data.filter(
        _ => _.question_id == 27
      );
      const partTimeEmp = this.questions[j].questions_data.filter(
        _ => _.question_id == 28
      );
      const headCountEmp = this.questions[j].questions_data.filter(
        _ => _.question_id == 52
      );
      employee[0].value = this.calculateEmp(
        fullTime[0],
        partTime[0],
        apprentices[0],
        partTimeEmp[0]
      );
      headCountEmp[0].value = this.calculateEmpCount(
        fullTime[0],
        partTime[0],
        apprentices[0],
        partTimeEmp[0]
      );
    }
  }
  calculateEmp(full, part, apprentices, partEmp) {
    const fullValue = full.value ? +full.value.replace('.', '') : 0;
    const partValue = part.value ? +part.value.replace('.', '') * 0.5 : 0;
    const apprenticesValue = apprentices.value
      ? +apprentices.value.replace('.', '') * 0.5
      : 0;
    const partEmpValue = partEmp.value
      ? +partEmp.value.replace('.', '') * 0.25
      : 0;
    let value: any =
      fullValue + partValue + apprenticesValue + partEmpValue
        ? fullValue + partValue + apprenticesValue + partEmpValue
        : '';
    value = value ? value.toFixed(2).replace('.', ',') : '';
    return value
      ? this._currencyCustomPipe
          .transform(
            value.toString()?.replaceAll('.', '')?.replaceAll(',', '.')
          )
          ?.replace('€', '')
          ?.trim()
      : '';
  }
  calculateEmpCount(full, part, apprentices, partEmp) {
    const fullValue = full.value ? +full.value.replace('.', '') : 0;
    const partValue = part.value ? +part.value.replace('.', '') : 0;
    const apprenticesValue = apprentices.value
      ? +apprentices.value.replace('.', '')
      : 0;
    const partEmpValue = partEmp.value ? +partEmp.value.replace('.', '') : 0;
    const value: any =
      fullValue + partValue + apprenticesValue + partEmpValue
        ? fullValue + partValue + apprenticesValue + partEmpValue
        : '';
    return value
      ? this._currencyCustomPipe
          .transform(
            value.toString()?.replaceAll('.', '')?.replaceAll(',', '.')
          )
          ?.replace('€', '')
          .replace(',00', '')
          ?.trim()
      : '';
  }
  formValidMessage(key) {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(key),
      },
      disableClose: false,
    });
  }
  resetValues(event, i, j) {
    if (!event.value) {
      const ques = this.toggleQueId.filter(
        _ => _.parent == this.questions[j].questions_data[i].question_id
      );
      if (ques.length) {
        ques.forEach(ele => {
          const childQue = this.questions[j].questions_data.filter(
            _ => _.question_id == ele.que
          );
          if (childQue.length) {
            childQue[0].value = '';
          }
        });
      }
    }
  }
  auditValue(que) {
    if (que.value != que.orignalValue) {
      que.isChanged = true;
    } else {
      que.isChanged = false;
    }
  }
  get getUser() {
    return this.userData.user_profile.user_data;
  }
  getUserDetails(que) {
    if (this.users.filter(_ => _.id == que.updated_by).length) {
      que.updated_by_name = this.users.filter(
        _ => _.id == que.updated_by
      )[0].name;
      return;
    }
    this.apiCallService.getUserName(que.updated_by).subscribe(res => {
      if (!res.HasErrors) {
        que.updated_by_name = res.first_name + ' ' + res.last_name;
        this.users.push({
          id: que.updated_by,
          name: res.first_name + ' ' + res.last_name,
        });
      }
    });
  }
}
