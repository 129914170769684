<div class="d-flex location">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical" (navChange)="onClick($event)">
      <li *ngFor="let item of allLocationPrperties" ngbNavItem="{{item.number}}">
        <a ngbNavLink> {{item.name | multilingual | translate}} </a>
        <ng-template ngbNavContent>
          <app-basic-location-details *ngIf="item.name == 'basic-location-details'" [criteriaRequestDetails]="criteriaRequestDetails" [isresetForm]="isresetForm" (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-basic-location-details>
          <app-additional-general-information *ngIf="item.name == 'additional-general-information'" [criteriaRequestDetails]="criteriaRequestDetails" [isresetForm]="isresetForm" (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-additional-general-information>
          <app-organizational-fire-protection *ngIf="item.name == 'organizational-fire-protection'" [criteriaRequestDetails]="criteriaRequestDetails" [isresetForm]="isresetForm" (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-organizational-fire-protection>
          <app-protection-factory-premises *ngIf="item.name == 'protection-factory-premises'" [criteriaRequestDetails]="criteriaRequestDetails" [isresetForm]="isresetForm" (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-protection-factory-premises>
          <app-other-increses-risk-location *ngIf="item.name == 'other-increses-risk-location'" [criteriaRequestDetails]="criteriaRequestDetails" [isresetForm]="isresetForm" (saveCriteriaRequest)="saveCriteriaRequest($event)"></app-other-increses-risk-location>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="ml-4 right-panel"></div>
  </div>
