import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { ButtonSize } from '../button/button.model';

@Component({
  selector: 'app-ic-academy-widget',
  templateUrl: './ic-academy-widget.component.html',
  styleUrls: ['./ic-academy-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ICAcademyWidgetComponent extends BaseComponent {
  readonly videos$ = this._apiCallsService.getVideos();
  readonly ButtonSize = ButtonSize;
  constructor(private readonly _apiCallsService: ApiCallsService) {
    super();
  }

  openVideo(name: string): void {
    this._apiCallsService
      .getVideo(name)
      .pipe(takeUntil(this._destroy$))
      .subscribe(url => window.open(url, '_blank'));
  }
}
