import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { SalutationService } from '@core/services/salutation.service';
import { ToastService } from '@core/services/toast.service';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { CommunicationService } from '@core/services/communication.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { UpdateUserComponent } from '../../../../components/update-user/update-user.component';
import { FILTER } from '@shared/constants/numeric.constants';
import { LOCALS } from '@shared/constants/local.constants';
import { take, takeUntil } from 'rxjs/operators';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { ShareDataService } from '@core/services/share-data.service';
import { CustomerManagementSharedService } from '../../customer-management-shared-service';
import { Observable } from 'rxjs';

export interface SendingDeleteResetUserData {
  user_id: string;
  user_role: USER_ROLES;
  user_customer_type: string;
  actor_id: number;
  actor_role: USER_ROLES;
  actor_customer_type: string;
  actor_email: string;
}
export interface InviteUserDialogData {
  customer_id: string;
  user_id: string;
  user_name: string;
  first_name: any;
  last_name: any;
  Telefon: string;
  email: string;
  customer: string;
  salutation_id: number;
  status: string;
  role: number;
  division: string;
  childcustomer: string;
  otp: string;
  house: string;
  street: string;
  zip_code: string;
  city: string;
  country: string;
  supported_languages: any;
  viaemail: string;
  vialetter: string;
  is_vip: boolean;
  is_invite_to_be_sent: boolean;
  is_invited_to_rcs: boolean;
  is_invite_reset: boolean;
  title: string;
  invitation_language: string;
  buttonCancelName: string;
  buttonConfirmName: string;
  newUser: boolean;
  otpTo: string;
  customer_address_lat_lon_set: boolean;
  lat: any;
  lon: any;
  address: string;
  customer_name: string;
  is_source_ivm: boolean;
  liztu_vn_kdnr: number;
  user_idnr: number;
  type: string;
  mfa_mode: number;
  is_group: boolean;
  role_id: any;
}
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent
  extends BaseComponent
  implements OnChanges
{
  @Input() inviteUserFlag;

  @Output() emitUserData = new EventEmitter<any>();
  @Output() emitinviteUser = new EventEmitter<any>();
  @Output() editCustomerPage = new EventEmitter<any>();
  @Output() updateUserInfo = new EventEmitter<any>();

  defaultSelectedCustomerValue: any;
  is_source_ivm: boolean = null;
  user_idnr;
  subsidiaries = [];
  customer_id;
  customFilterExpandedHeight: string = FILTER.HEIGHT;
  customerFilterToggle = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource();
  formControl;
  filterCustomerForm;
  UserDataParentObject: any;
  statusAvailable: any;
  UserData: any;
  showDeleteModal = false;
  updatemessage = '';
  user_role: USER_ROLES;
  division_id;
  userid;
  division;
  user;
  type;
  userOBJ: any = {};
  updateUserOBJ: any = {};
  isICUser: boolean;
  inviter_user;
  displayedColumns: string[] = [
    'NAME',
    ' ',
    'COMPANY',
    'COMPANY_TYPE',
    'ROLE',
    'EMAIL',
    'STATUS',
    'LAST_LOGIN',
    'ACTION',
  ];
  revertInvitation = false;
  timer: NodeJS.Timeout;
  selectedOrgId: any;
  userIdnr: any;
  selectedCustomerLiztu: any;
  customerType: any;
  userRole: any;
  userCustomerTyp: any;
  userId: any;
  actorEmail: any;
  selectedStatus = 'Status';
  encoded_liztu_vn_kdnr: any;
  liztu: any;
  salutation_id: string;
  customer_name: string;
  inviter_email: string;
  locale: LOCALS;
  userDataValue: any;
  isDisabled = true;
  statusDisabled: boolean;
  original_liztu = true;
  selectedCountOfRows = 25;
  tablePage = 1;
  numberOfRows = [25, 50, 75, 100, 125];
  total_elements: number;
  isIvm = false;
  isResetInvite = false;
  userbranchAdmin: ROLES[] = [
    USER_ROLES.IC_USER,
    USER_ROLES.IC_BRANCH_ADMIN,
  ];
  totalUsers: number;
  isEditCustomer$ =
    this._customerManagementSharedService.isEditCustomerOperation$;
  customerName$: Observable<string> = this._customerSharedService.customerName$;

  constructor(
    private route: ActivatedRoute,
    private serviceCall: ApiCallsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private translate: TranslateService,
    private salutationService: SalutationService,
    private _toastSerice: ToastService,
    public validationService: ValidationService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _shareDataService: ShareDataService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService
  ) {
    super();
    this.filterCustomerForm = this.formBuilder.group({
      UnternehmenCustomer: ['UnternehmenCustomer'],
      Status: ['Status'],
    });

    this.filterCustomerForm
      .get('UnternehmenCustomer')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (value !== null || value !== '') {
          this.filterCustomerForm.get('Status').setValue('Status');
        }
      });

    this.formControl = this.formBuilder.group({
      DIVISION: '',
      COMPANY: ['COMPANY'],
      StatusArtus: ['StatusArtus'],
      UnternehmenArtus: ['UnternehmenArtus'],
    });
  }
  ngOnChanges() {
    this.editCustomerPage.emit(false);
    if (this.inviteUserFlag) {
      this.inviteUserClick();
    }
  }
  get UnternehmenCustomer() {
    return this.filterCustomerForm.get('UnternehmenCustomer');
  }
  get Status() {
    return this.filterCustomerForm.get('Status');
  }
  get COMPANY() {
    return this.formControl.get('COMPANY');
  }
  get StatusArtus() {
    return this.formControl.get('StatusArtus');
  }

  ngOnInit(): void {
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParams.liztu;
    this.liztu = this.encoded_liztu_vn_kdnr
      ? atob(this.encoded_liztu_vn_kdnr)
      : null;

    this._getUserData();
    this._watchOnRole();
    this._watchOnLocale();
    this._customerSharedService.includeForFuture = false;
    this.resetCustomerFilter();
  }

  resetCustomerFilter() {
    this.statusDisabled = false;
    this.isDisabled = true;
    this.original_liztu = true;
    if (ROLES.CUSTOMER_ROLES.includes(this.user_role)) {
      this.loadUserforCustomerReset();
    }
    this.loadUserListForReset();
    this.filterCustomerForm.controls.Status.setValue('Status');
  }
  getStatus(item) {
    this.isDisabled = false;
    this.selectedStatus = item.value;
  }
  getSelectedCustomer(item) {
    if (item.value.liztu_vn_kdnr === this.selectedOrgId) {
      this.original_liztu = true;
    } else {
      this.original_liztu = false;
    }
    this.isDisabled = false;
    this.statusDisabled = true;
    this.selectedCustomerLiztu = item.value.liztu_vn_kdnr;
    this.selectedStatus = this.filterCustomerForm.get('Status').value;
  }
  getCustFormsValue() {
    this.statusDisabled = false;
    if (this.isICUser) {
      this.userOBJ.liztu_vn_kdnr =
        this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
      if (this.original_liztu === true) {
        this.userOBJ.liztu_vn_kdnr = this.selectedOrgId;
      } else {
        this.userOBJ.liztu_vn_kdnr = this.selectedCustomerLiztu;
      }
      this.userOBJ.user_role = this.user_role;
      this.userOBJ.user_idnr = null;
      this.userOBJ.page_number = this.tablePage;
      this.userOBJ.no_of_records = this.selectedCountOfRows;
      const filterFormdata = this.userOBJ;
      this.serviceCall
        .getUserList(filterFormdata)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.handleLoadData(data);
        });
    }
  }
  loadCustomerList() {
    if (ROLES.IC_ROLES.includes(this.user_role)) {
      this.formatUserDataNew([]);
    }
    this.is_source_ivm =
      this.userDataValue.user_profile.user_data.is_source_ivm;
    if (
      this.encoded_liztu_vn_kdnr &&
      this.customer_id &&
      this.encoded_liztu_vn_kdnr !== 'null'
    ) {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
    } else if (this.customer_id && this.encoded_liztu_vn_kdnr === 'null') {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
    } else {
      this.userOBJ.liztu_vn_kdnr =
        this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
    }
    this.userOBJ.user_role = this.user_role;
    this.userOBJ.user_idnr = null;
    this.userOBJ.page_number = this.tablePage;
    this.userOBJ.no_of_records = this.selectedCountOfRows;
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.handleLoadData(data);
      });
    if (!this.userDataValue.error) {
      if (this.userDataValue.user_profile != null) {
        this.formatUserDataNew(this.userDataValue);
      } else {
        alert(
          this.translate.instant(
            'customer-management.user_managment.no_profile_found'
          )
        );
      }
    }
  }
  loadUserListForReset() {
    if (ROLES.IC_ROLES.includes(this.user_role)) {
      this.formatUserDataNew([]);
    }
    this.is_source_ivm =
      this.userDataValue.user_profile.user_data.is_source_ivm;
    this.emitUserData.emit(this.userDataValue);
    this.userOBJ.liztu_vn_kdnr = this.selectedOrgId;
    this.userOBJ.user_role = this.user_role;
    this.userOBJ.user_idnr = null;
    this.userOBJ.page_number = this.tablePage;
    this.userOBJ.no_of_records = this.selectedCountOfRows;
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data?.users?.length > 0) {
          this.loadUserDataReset(data);
        } else {
          this.handleEmptyTable();
        }
      });
    if (!this.userDataValue.error) {
      if (this.userDataValue.user_profile != null) {
        this.formatUserDataNew(this.userDataValue);
      } else {
        alert(
          this.translate.instant(
            'customer-management.user_managment.no_profile_found'
          )
        );
      }
    }
  }
  formatUserDataNew(UserItem?) {
    const newUserItem = Array.isArray(UserItem) ? UserItem : [UserItem];
    if (newUserItem.length) {
      this.user_idnr = this.user_idnr === null ? '' : this.user_idnr;
      this.is_source_ivm =
        this.is_source_ivm === null ? false : this.is_source_ivm;
      if (this.isICUser) {
        if (this.customer_id) {
          this.serviceCall
            .getSubsidiariesCustomerManagement(
              this.customer_id,
              this.user_idnr,
              this.user_role,
              this.is_source_ivm,
              this.is_source_ivm
            )
            .pipe(takeUntil(this._destroy$))
            .subscribe(obj => {
              this.subsidiaries = obj.associated_customers;
              let orgId;
              if (this.subsidiaries.length > 0) {
                for (let i = 0; i < this.subsidiaries.length; i++) {
                  if (this.subsidiaries[i].customer_id === this.customer_id) {
                    this.defaultSelectedCustomerValue = this.subsidiaries[i];
                    orgId = this.defaultSelectedCustomerValue.liztu_vn_kdnr;
                  }
                }
                this.selectedOrgId = orgId;
              }
            });
        } else {
          const subsidiries =
            this.communicationService.getAssociatedCustomerValues();
          this.subsidiaries = subsidiries.map(obj => {
            const newSub: any = {};
            newSub.customer_id = obj.customer_id;
            newSub.user_id = obj.user_id;
            newSub.customer_name = obj.customer_name;
            newSub.type = obj.type;
            return newSub;
          });
          this.defaultSelectedCustomerValue = this.subsidiaries.find(
            x => x.customer_id === this.customer_id
          );
        }
      } else {
        this.subsidiaries = UserItem.user_profile.associated_customers;
        this.defaultSelectedCustomerValue = this.subsidiaries.find(
          x =>
            x.customer_id === UserItem.user_profile.customer_details.customer_id
        );
        if (this.subsidiaries.length > 0) {
          for (let i = 0; i < this.subsidiaries.length; i++) {
            if (this.subsidiaries[i].customer_id === this.customer_id) {
              this.defaultSelectedCustomerValue = this.subsidiaries[i];
            }
          }
        }
      }
    }
  }

  private _getUserData(): void {
    this._userService.loggedInUser$
      .pipe(filterNil(), take(1))
      .subscribe(data => {
        this.userDataValue = data;
        this.dataSource.paginator = this.paginator;
        this.loadDivision();

        this.customerType = data.user_profile.customer_details.type;
        this.userid = data.user_profile.user_data.user_id;
        this.userIdnr = data.user_profile.user_data.user_idnr;
        this.actorEmail = data.user_profile.user_data.email_id;
      });

    this._customerSharedService.customerGeneralData$
      .pipe(filterNil(), take(1))
      .subscribe(customerGeneralData => {
        this.customer_id = customerGeneralData.customer_info.customer_id;
        this.user = this._userService.getUserIdnr();
        this.type = customerGeneralData.customer_status.type;
        this.is_source_ivm = customerGeneralData.is_source_ivm;

        if (this.liztu && this.customer_id) {
          const sub = this._customerSharedService.getSubsidiaries() || [];

          for (let i = 0; i < sub.length; i++) {
            if (sub[i].customer_id === this.customer_id) {
              this.liztu = sub[i].liztu_vn_kdnr;
              this._userService.saveDeliztu(this.liztu);
            }
          }
        }
        if (
          this.customer_id &&
          this.is_source_ivm !== null &&
          this.liztu &&
          this.type
        ) {
          this._userService.saveDeliztu(this.liztu);
          this._shareDataService.deType = this.type;
        }
        this.loadCustomerList();
        if (ROLES.CUSTOMER_ROLES.includes(this.user_role)) {
          this.loadUserforCustomer();
          this.getDivisionCustomer();
        }
        this.setAvailableStatusValues();
        if (this._shareDataService.getReloadValue()) {
          this._shareDataService.saveReloadValue(null);
          this._userService.saveDeliztu(null);
          this._shareDataService.deType = null;
        }
      });
  }
  loadDivision() {
    this.userid = this.userDataValue.user_profile.user_data.user_id;
    this.inviter_user = this.userDataValue.user_profile.user_data.email_id;
    this.salutation_id =
      this.userDataValue.user_profile.user_data.salutation_id;
    this.customer_name =
      this.userDataValue.user_profile.user_data.customer_name;
    this.inviter_email = this.userDataValue.user_profile.user_data.email_id;
    this.is_source_ivm =
      this.userDataValue.user_profile.user_data.is_source_ivm;
    this.user_idnr = this.userDataValue.user_profile.user_data.user_idnr;
    this.serviceCall
      .getDivision(this.userid)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (this.userbranchAdmin.includes(this.user_role)) {
          for (let i = 0; i < data.divisions.length; i++) {
            this.division_id = data.divisions[i].division_id;
          }
          this.onSelectedDivisionAutomatically(this.division_id);
        }
      });
  }
  loadUserforCustomer() {
    this.userIdnr = this.userDataValue.user_profile.user_data.user_idnr;
    if (this.encoded_liztu_vn_kdnr !== null) {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
    } else {
      this.userOBJ.liztu_vn_kdnr =
        this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
    }
    this.userOBJ.user_role = this.user_role;
    this.userOBJ.user_idnr = null;
    this.userOBJ.page_number = this.tablePage;
    this.userOBJ.no_of_records = this.selectedCountOfRows;
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.handleLoadData(data);
      });

    if (!this.userDataValue.error) {
      !this.userDataValue.user_profile
        ? this.formatUserDataNew(this.userDataValue)
        : alert(
            this.translate.instant(
              'customer-management.user_managment.no_profile_found'
            )
          );
    }
  }

  loadUserforCustomerReset() {
    const org = this.userDataValue.user_profile.customer_details.liztu_vn_kdnr;
    if (this.encoded_liztu_vn_kdnr != null) {
      this.userOBJ.liztu_vn_kdnr = this.liztu;
      this.selectedCustomerLiztu = this.liztu;
    } else {
      this.userOBJ.liztu_vn_kdnr = org;
      this.selectedCustomerLiztu = org;
    }
    this.userOBJ.user_role = this.user_role;
    this.userOBJ.user_idnr = null;
    this.userOBJ.page_number = this.tablePage;
    this.userOBJ.no_of_records = this.selectedCountOfRows;
    const frmdata = this.userOBJ;
    this.serviceCall
      .getUserList(frmdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data?.users?.length > 0) {
          this.loadUserDataReset(data);
        } else {
          this.handleEmptyTable();
        }
      });
  }

  loadUserData(data) {
    if (!data) {
      return;
    }
    this.formatUserData(data);
  }

  loadUserDataReset(data) {
    if (!data) {
      return;
    }
    this.formatUserDataForReset(data);
  }
  formatUserData(userData) {
    this.UserData = [];
    let statuss;
    this.total_elements =
      this.tablePage > 1 && userData.total_element === 0
        ? this.totalUsers
        : userData.total_element;
    if (userData?.users?.length > 0) {
      for (let i = 0; i < userData.users.length; i++) {
        if (userData.users[i].is_invited_to_rcs === false) {
          statuss = 'Nicht eingeladen';
        } else if (userData.users[i].is_invited_to_rcs === true) {
          if (userData.users[i].is_logged_in === false) {
            statuss = 'Eingeladen';
          } else {
            statuss = this.translate.instant('invite-user.active');
          }
        }
        if (userData.users[i].is_invited_to_rcs === false) {
          this.revertInvitation = true;
        } else if (
          userData.users[i].is_invited_to_rcs === true ||
          userData.users[i].is_logged_in === true
        ) {
          this.revertInvitation = false;
        }
        const tableData = this.setTableData(
          userData.users[i],
          statuss,
          userData.supported_languages
        );

        if (
          tableData.status === this.selectedStatus ||
          this.selectedStatus === 'Status'
        ) {
          this.UserData.push(tableData);
        }
      }
      this.UserDataParentObject = JSON.parse(JSON.stringify(this.UserData));
      this.displayUserData();
      this.setAvailableStatusValues();
      this.communicationService.setAssociatedCustomerValues(this.UserData);
    }
    if (
      this.user_role === USER_ROLES.CUSTOMER_USER &&
      userData.users.length < 1
    ) {
      this.dataSource.data = this.UserData;
    }
  }

  displayUserData() {
    this.dataSource.data = [...this.UserData];
  }
  formatUserDataForReset(userData) {
    this.UserData = [];
    let statuss;
    if (userData?.users?.length > 0) {
      for (let i = 0; i < userData.users.length; i++) {
        if (userData.users[i].is_invited_to_rcs === false) {
          statuss = 'Nicht eingeladen';
        } else if (userData.users[i].is_invited_to_rcs === true) {
          if (userData.users[i].is_logged_in === false) {
            statuss = 'Eingeladen';
          } else {
            statuss = this.translate.instant('invite-user.active');
          }
        }

        if (userData.users[i].is_invited_to_rcs === false) {
          this.revertInvitation = true;
        } else if (
          userData.users[i].is_invited_to_rcs === true ||
          userData.users[i].is_logged_in === true
        ) {
          this.revertInvitation = false;
        }
        const tableData = this.setTableData(
          userData.users[i],
          statuss,
          userData.supported_languages
        );
        this.UserData.push(tableData);
      }
      this.UserDataParentObject = JSON.parse(JSON.stringify(this.UserData));
      this.setAvailableStatusValues();
      this.dataSource.data = this.UserData;
      this.communicationService.setAssociatedCustomerValues(this.UserData);
    }
  }
  deleteIvmUser() {
    this.showDeleteModal = true;
    this.isIvm = true;
  }
  deleteUser(user_id, user_role, user_customer_type) {
    this.showDeleteModal = true;
    this.isIvm = false;
    this.userId = user_id;
    this.userRole = user_role;
    this.userCustomerTyp = user_customer_type;
  }
  deleteUserProcess(user) {
    if (this.isICUser) {
      const deleteuserSendingdata: SendingDeleteResetUserData = {
        user_id: this.userId,
        user_role: this.userRole,
        user_customer_type: this.userCustomerTyp,
        actor_id: this.userid,
        actor_role: this.user_role,
        actor_customer_type: null,
        actor_email: this.actorEmail,
      };
      this.serviceCall
        .deleteUser(deleteuserSendingdata)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data) {
            this.showDeleteModal = false;
            this._toastSerice.showToastMessage(document, data.message);
            this.loadCustomerList();
          }
        });
    }
  }
  resetInvite(user_id, user_role, user_customer_type) {
    this.showDeleteModal = true;
    this.isIvm = false;
    this.isResetInvite = true;
    this.userId = user_id;
    this.userRole = user_role;
    this.userCustomerTyp = user_customer_type;
  }

  onHideModal(event) {
    this.showDeleteModal = false;
    this.isIvm = false;
    this.isResetInvite = false;
  }
  invitationRevert(user) {
    const resetUserInvitationSendingdata: SendingDeleteResetUserData = {
      user_id: this.userId,
      user_role: this.userRole,
      user_customer_type: this.userCustomerTyp,
      actor_id: true === this.isICUser ? this.userid : this.userIdnr,
      actor_role: this.user_role,
      actor_customer_type: true === this.isICUser ? null : this.customerType,
      actor_email: this.actorEmail,
    };
    this.serviceCall
      .resetInvite(resetUserInvitationSendingdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data) {
          this._toastSerice.showToastMessage(document, data.message);
          if (ROLES.CUSTOMER_ROLES.includes(this.user_role)) {
            this.loadUserforCustomer();
          } else if (this.isICUser) {
            this.loadCustomerList();
          }
        }
      });
  }
  onSelectedDivisionAutomatically(divisionId) {
    this.serviceCall
      .getOrganization(divisionId, this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe();
  }

  onActionProcess(user) {
    const user_data = user;
    user_data.salutation = this.salutationService.getSalutation(
      user_data.salutation_id,
      this.locale
    );
    const updateUserData = this.createUpdateUserRequest(user_data);
    updateUserData.customer_name = '';
    updateUserData.mfa_mode = user_data.is_source_ivm === true ? 1 : null;
    updateUserData.is_group = false;

    this.dialog
      .open(UpdateUserComponent, {
        panelClass: 'updateUser',
        width: '800px',
        data: updateUserData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(s => {
        this.emitinviteUser.emit(false);
        if (s) {
          const formData = this.formatFormData(s);
          this.serviceCall
            .updateInviteUser(formData)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              this.updatemessage = data.message;
              if (data.message === 'Successfully Updated') {
                this.updatemessage = this.translate.instant(
                  'update-user.erfolgreichAktualisiert_info'
                );
              } else if (
                data.message ===
                'User Already Exist with Same Email or Username'
              ) {
                this.updatemessage = this.translate.instant(
                  'update-user.Nutzer_info'
                );
              } else if (data.message === 'Invitation Email Not Sent') {
                this.updatemessage = this.translate.instant(
                  'update-user.Einladungsemail_info'
                );
              }
              this._toastSerice.showToastMessage(document, this.updatemessage);
              if (ROLES.CUSTOMER_ROLES.includes(this.user_role)) {
                this.loadCustomerList();
                this.ngOnInit();
              } else if (this.isICUser) {
                this.loadCustomerList();
              }
            });
        }
      });
  }
  formatFormData(formData) {
    if (this.isICUser) {
      if (!formData) {
        return;
      }
      formData.salutation_id = this.salutationService.getSalutationId(
        formData.salutation,
        this.locale
      );
      let salutation = this.salutationService.getSalutation(
        this.salutation_id,
        formData.invitation_language
      );
      salutation =
        salutation === null || salutation === undefined ? '' : salutation;
      this.inviter_user = (salutation + ' ' + this.customer_name).trim();
      this.updateUserOBJ.user_id = formData.user_id;
      this.updateUserOBJ.customer_id = formData.customer_id;
      this.updateUserOBJ.customer_name = formData.customer_name;
      this.updateUserOBJ.first_name = formData.first_name;
      this.updateUserOBJ.last_name = formData.last_name;
      this.updateUserOBJ.telefon = formData.Telefon;
      this.updateUserOBJ.user_name =
        formData.first_name + ' ' + formData.last_name;
      this.updateUserOBJ.email = formData.email;
      this.updateUserOBJ.house = formData.house;
      this.updateUserOBJ.street = formData.street;
      this.updateUserOBJ.zip_code = formData.zip_code;
      this.updateUserOBJ.city = formData.city;
      this.updateUserOBJ.country = formData.country;
      this.updateUserOBJ.type = formData.type;
      this.updateUserOBJ.role = formData.role;
      this.updateUserOBJ.salutation_id = formData.salutation_id;
      this.updateUserOBJ.address = formData.address;
      this.updateUserOBJ.invitation_language =
        formData.is_invited_to_rcs === false
          ? formData.invitation_language
          : null;
      this.updateUserOBJ.is_invite_to_be_sent = formData.is_invite_to_be_sent;
      this.updateUserOBJ.is_temporary = true;
      this.updateUserOBJ.is_invited_to_rcs = formData.is_invited_to_rcs;
      (this.updateUserOBJ.is_vip = formData.is_vip === true ? true : false),
        (this.updateUserOBJ.division = formData.division);
      this.updateUserOBJ.is_source_ivm = formData.is_source_ivm;
      this.updateUserOBJ.lat = formData.lat;
      this.updateUserOBJ.lon = formData.lon;
      this.updateUserOBJ.inviter_username = this.inviter_user;
      (this.updateUserOBJ.inviter_email = this.inviter_email),
        (this.updateUserOBJ.actor_id = formData.actor_id),
        (this.updateUserOBJ.actor_role = formData.actor_role),
        (this.updateUserOBJ.actor_customer_type = null),
        (this.updateUserOBJ.mfa_mode = formData.otpTo === 'Mobile' ? 1 : 2);
      (this.updateUserOBJ.actor_id = this.userid),
        (this.updateUserOBJ.actor_role = this.user_role);
      if (formData.lat !== '') {
        this.updateUserOBJ.lat = formData.lat;
      } else {
        this.updateUserOBJ.lat = null;
      }
      if (formData.lon !== '') {
        this.updateUserOBJ.lon = formData.lon;
      } else {
        this.updateUserOBJ.lon = null;
      }
      return this.updateUserOBJ;
    }
  }

  setAvailableStatusValues() {
    if (this.UserDataParentObject !== undefined) {
      const unique = [
        ...new Set(this.UserDataParentObject.map(item => item.status)),
      ];
      this.statusAvailable = [];
      for (const value of unique) {
        const newObject = {
          value: true,
          label: value,
        };
        this.statusAvailable.push(newObject);
      }
    }
  }

  getDivisionCustomer() {
    this.serviceCall
      .getDivision(this.userid)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.division = data.divisions[0].division_name;
      });
  }

  inviteUserClick() {
    this._userService.inviteUserContext = this;
    this.getDivisionCustomer();
    const newUserData: InviteUserDialogData = {
      customer_id: this.customer_id,
      user_id: '',
      user_name: '',
      Telefon: '',
      first_name: '',
      last_name: '',
      email: '',
      customer: '',
      salutation_id: null,
      status: '',
      supported_languages: '',
      role: 3,
      division: this.division,
      childcustomer: '',
      otp: '',
      house: '',
      invitation_language: 'German(DE)',
      street: '',
      zip_code: '',
      city: '',
      country: '',
      viaemail: '',
      vialetter: '',
      is_invite_to_be_sent: false,
      is_invited_to_rcs: false,
      is_vip: false,
      newUser: true,
      type: '',
      otpTo: 'Email',
      title: 'Neuen_Benutzer_hinzufügen',
      buttonCancelName: 'Abbrechen_text',
      buttonConfirmName: 'Senden',
      customer_address_lat_lon_set: false,
      lat: '',
      lon: '',
      address: '',
      customer_name: '',
      is_source_ivm: false,
      is_group: false,
      liztu_vn_kdnr: null,
      user_idnr: null,
      is_invite_reset: false,
      mfa_mode: null,
      role_id: null,
    };
    this.dialog
      .open(UpdateUserComponent, {
        panelClass: 'updateUser',
        width: '800px',
        data: newUserData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.emitinviteUser.emit(false);
      });
  }
  editUserClick(user) {
    this.editCustomerPage.emit(true);
    this.updateUserInfo.emit(this.createUpdateUserRequest(user));
  }

  showInviteResult(data) {
    if (data.hasError) {
      this._toastSerice.showToastMessage(
        document,
        this.translate.instant('user-data.Update_Error_Message')
      );
    } else {
      this._toastSerice.showToastMessage(
        document,
        this.translate.instant('user-data.Update_Success_Message')
      );
      this.loadCustomerList();
    }
  }

  onEditClick(user) {
    this._shareDataService.isCustomerManagementEdit = true;
    if (user.is_source_ivm && !user.is_invited_to_rcs) {
      this.onActionProcess(user);
      return;
    }

    if (user.is_invite_reset) {
      this.reinviteUser(user);
      return;
    }

    this.editUserClick(user);
  }

  reinviteUser(user) {
    const user_data = user;
    user_data.salutation = this.salutationService.getSalutation(
      user_data.salutation_id,
      this.locale
    );
    const updateUserData = this.createUpdateUserRequest(user_data);
    updateUserData.country = user_data.country;
    updateUserData.otpTo = user_data.telefon != null ? 'Mobile' : 'Email';
    (updateUserData.address = ''),
      (updateUserData.customer_name = ''),
      (updateUserData.newUser = true);
    updateUserData.type = user_data.type;

    this.dialog
      .open(UpdateUserComponent, {
        panelClass: 'updateUser',
        width: '800px',
        data: updateUserData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.emitinviteUser.emit(false);
      });
  }

  tablePageChanged(event) {
    this.tablePage = event;
    this.loadCustomerList();
  }

  numberOfRowsChanged(value) {
    this.selectedCountOfRows = value.value;
    this.tablePage = 1;
    this.loadCustomerList();
  }

  setTableData(userData, status, supported_languages) {
    return {
      user_id: userData.user_id,
      user_name: userData.user_name,
      customer_name: userData.customer_name,
      customer_id: userData.customer_id,
      type: userData.type,
      email: userData.email,
      division: userData.division_name,
      city: userData.city,
      status,
      country: userData.country,
      house: userData.house,
      is_email_sent: userData.is_email_sent,
      is_invited_to_rcs: userData.is_invited_to_rcs,
      invitation_language: userData.language_code,
      supported_languages,
      role: userData.role,
      is_vip: userData.is_vip,
      salutation_id: userData.salutation_id,
      street: userData.street,
      address: userData.address,
      first_name: userData.first_name,
      last_name: userData.last_name,
      time_invited_to_rcs: userData.time_invited_to_rcs,
      telefon: userData.telefon,
      zip_code: userData.zip_code,
      last_login: userData.last_login,
      is_logged_in: userData.is_logged_in,
      invite_user: this.revertInvitation,
      customer_address_lat_lon_set: userData.customer_address_lat_lon_set,
      lat: userData.lat,
      lon: userData.lon,
      is_source_ivm: userData.is_source_ivm,
      lock_state: userData.lock_state,
      liztu_vn_kdnr: userData.liztu_vn_kdnr,
      user_idnr: userData.user_idnr,
      mfa_mode: userData.mfa_mode,
      is_invite_reset: userData.is_invite_reset,
      role_id: userData.role_id,
    };
  }
  handleEmptyTable() {
    this.total_elements = 0;
    this.dataSource.data = [];
    this.dataSource = new MatTableDataSource(null);
  }
  handleLoadData(data) {
    if (data?.users?.length > 0) {
      this.totalUsers = data.total_element;
      this.loadUserData(data);
    } else {
      this.handleEmptyTable();
    }
  }
  createUpdateUserRequest(user_data) {
    return {
      customer_id: user_data.customer_id,
      user_id: user_data.user_id,
      user_name: user_data.user_name,
      Telefon: user_data.telefon,
      first_name: user_data.first_name,
      last_name: user_data.last_name,
      email: user_data.email,
      customer: user_data.customer_name,
      salutation_id: user_data.salutation_id,
      status: user_data.status,
      supported_languages: user_data.supported_languages,
      role: user_data.role_id,
      division: user_data.division,
      childcustomer: user_data.childcustomer,
      otp: user_data.otp,
      house: user_data.house,
      invitation_language: user_data.invitation_language,
      street: user_data.street,
      zip_code: user_data.zip_code,
      city: user_data.city,
      country: user_data.country || null,
      viaemail: user_data.is_email_sent,
      vialetter: user_data.is_email_sent,
      is_invite_to_be_sent: !user_data.is_invited_to_rcs,
      is_invited_to_rcs: user_data.is_invited_to_rcs,
      is_invite_reset: user_data.is_invite_reset,
      is_vip: user_data.is_vip,
      otpTo: user_data.mfa_mode === 1 ? 'Mobile' : 'Email',
      customer_address_lat_lon_set: user_data.customer_address_lat_lon_set,
      lat: user_data.lat,
      lon: user_data.lon,
      address: user_data.address,
      customer_name: user_data.customer_name,
      title: 'Benutzerinformationen_text',
      buttonCancelName: 'Abbrechen_text',
      newUser: false,
      mfa_mode: user_data.mfa_mode,
      buttonConfirmName: !user_data.is_invited_to_rcs
        ? 'AktualisierenUnd_text'
        : 'Aktualisieren_text',
      is_source_ivm: user_data.is_source_ivm,
      liztu_vn_kdnr: user_data.liztu_vn_kdnr,
      user_idnr: user_data.user_idnr,
      is_group: user_data.is_group,
      type: user_data.type,
      role_id: user_data.role_id,
    };
  }

  private _watchOnLocale(): void {
    this._userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(locale => (this.locale = locale));
  }

  private _watchOnRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
      });
  }
}
