import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableAppointmentAttendee } from '../../../models/appointment.model';

@Component({
    selector: 'app-guests',
    template: `<app-avatar-group [avatars]="element.guests" size="xs"></app-avatar-group>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GuestsComponent {
    @Input() element: { guests: TableAppointmentAttendee[] };
}
