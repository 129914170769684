<div class="stepper-main-form">
    <form *ngIf="selectLang$ | async as locale" class="row product-module-html" [formGroup]="stepperForm" id="stepper-form">
        <div class="col-md-12 col-sm-12 col-12 common-covering-damage">
            <mat-checkbox formControlName="autofillData" (change)="copyDataFromRoot($event, stepIndex)"
                [disabled]="disableField"
                [(ngModel)]="is_auto_fill_data_enabled" class="checkbox" *ngIf="stepIndex == 3">
                {{'product-management.stepper_common_msg.auto_fill_cover_bundle_Data' | translate}} </mat-checkbox>
            <mat-checkbox formControlName="autofillData" (change)="copyDataFromRoot($event, stepIndex)"
                [disabled]="disableField"
                [(ngModel)]="is_auto_fill_data_enabled" class="checkbox" *ngIf="stepIndex == 4">
                {{'product-management.stepper_common_msg.auto_fill_covering_Data' | translate}}</mat-checkbox>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" class="label">{{'product-management.product_module_stepper_lables.product_name'  | translate}}</label>
            <input type="text" class="input" formControlName="ProductName" placeholder="{{'product-management.product_module_stepper_lables.product_name'  | translate}}" disabled>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">

            <label for="" [ngClass]="stepIndex != 1 ? 'label' : 'label required'">{{'product-management.product_module_stepper_lables.Product_Module_name'  | translate}}</label>
            <input type="text" class="input" formControlName="ProductModule" *ngIf="stepIndex != 1 || disableField"
                placeholder="{{'product-management.product_module_stepper_lables.Product_Module_name'  | translate}}" disabled>
            <app-custom-autocomplete *ngIf="stepIndex == 1 && !disableField" [control]="stepperForm.controls['ProductModule']"
                [placeholder]="'product-management.cover_bundle.product_module' | translate" [required]="false"
                [lookup]="ProductModuleLookup" (newValueSelected)="ProductModuleNameSave($event)"></app-custom-autocomplete>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field"  *ngIf="stepIndex != 1">
            <label *ngIf="stepIndex == 3 || stepIndex == 4 || disableField" for="" class="label">{{'product-management.cover_bundle.cover_bundle_name' | translate}}</label>
            <input *ngIf="stepIndex == 3 || stepIndex == 4 || disableField" type="text" class="input" formControlName="CoverBundle"
                placeholder="{{'product-management.cover_bundle.cover_bundle_name' | translate}}" disabled>
            <label *ngIf="stepIndex == 2 && !disableField" for="" class="label required">{{'product-management.cover_bundle.cover_bundle_name' | translate}}</label>
            <app-custom-autocomplete *ngIf="stepIndex == 2 && !disableField" [control]="stepperForm.controls['CoverBundle']"
                [placeholder]="'product-management.cover_bundle.cover_bundle_name' | translate" [required]="false"
                [lookup]="CoverBundleLookup" (newValueSelected)="CoverBundleNameSave($event)"></app-custom-autocomplete>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field" *ngIf="stepIndex == 1">
            <label for="" class="label">{{'product-management.product_module_stepper_lables.Product_Module_Type'  | translate}}</label>
            <mat-select class="dropdownStyle" formControlName="ProductModuleType" 
                [disabled]="disableField == true" placeholder="{{'objectModule.select_lable' | translate}}">
                <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                <mat-option *ngFor="let ele of coverBundleTypes" [value]="ele.id">{{ele.desc}}</mat-option>
            </mat-select>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field" *ngIf="stepIndex == 2">
            <label for="" class="label">{{'product-management.product_module_stepper_lables.Cover_bundle_type'  | translate}}</label>
            <mat-select class="dropdownStyle" formControlName="CoverBundleType" 
                [disabled]="disableField == true" placeholder="{{'objectModule.select_lable' | translate}}">
                <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                <mat-option *ngFor="let ele of coverBundleTypes" [value]="ele.id">{{ele.desc}}</mat-option>
            </mat-select>
        </div>
        <div class="col-md-6 col-sm-12 col-12 damage-field" *ngIf="stepIndex == 3 || stepIndex == 1">
            <label for="" class="label">{{'product-management.product_module_stepper_lables.condition_text'  | translate}}</label>
            <input type="text" maxlength="100" class="input" [attr.disabled]="disableField ? 'disabled' : null" 
                formControlName="ConditionText"
                placeholder="{{'product-management.product_module_stepper_lables.condition_text'  | translate}}">
        </div>
        <div class="col-md-6 col-sm-12 col-12 covering-field" *ngIf="stepIndex == 3 || stepIndex == 4">
            
            <label for="" class="label" *ngIf="stepIndex == 4 || disableField">{{'product-management.cover_bundle.covering_name' | translate}}</label>
            <input type="text" maxlength="100" *ngIf="stepIndex == 4 || disableField" class="input" formControlName="CoveringName"
                placeholder="{{'product-management.cover_bundle.covering_name' | translate}}" disabled>
            <label for="" class="label required" *ngIf="stepIndex == 3 && !disableField">{{'product-management.cover_bundle.covering_name' | translate}}</label>
            <app-custom-autocomplete [control]="stepperForm.controls['CoveringName']"
                [placeholder]="'product-management.cover_bundle.covering_name' | translate" [required]="false"
                *ngIf="stepIndex == 3 && !disableField" [lookup]="CoveringLookup" (newValueSelected)="CoveringNameSave($event)">
            </app-custom-autocomplete>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field" *ngIf="stepIndex == 3 || stepIndex == 4">
            <label for="" class="label">{{'product-management.stepper_common_msg.type_of_Covering'  | translate}}</label>
            <mat-select class="dropdownStyle" formControlName="CoveringType" 
                [disabled]="disableField == true" placeholder="{{'objectModule.select_lable' | translate}}">
                <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                <mat-option *ngFor="let ele of coverBundleTypes" [value]="ele.id">{{ele.desc}}</mat-option>
            </mat-select>
        </div>
        <div class="col-md-6 col-sm-12 col-12 covering-field" *ngIf="stepIndex == 4">
            <label for="" class="label required">{{'product-management.cover_bundle.type_of_damage' | translate}}</label>
            <input type="text" maxlength="100" *ngIf="disableField" class="input"
                formControlName="TypeOfDamage" disabled>
            <app-custom-autocomplete *ngIf="!disableField" [control]="stepperForm.controls['TypeOfDamage']"
                [placeholder]="'product-management.cover_bundle.type_of_damage' | translate" [required]="false"
                [lookup]="typeOfDamage" (newValueSelected)="typeOfDamageSave($event)"></app-custom-autocomplete>
        </div>
        <div class="col-md-6 col-sm-12 col-12 damage-field" *ngIf="stepIndex == 4">
            <label for="" class="label">{{'product-management.product_module_stepper_lables.condition_text'  | translate}}</label>
            <input type="text" maxlength="100" class="input" 
                [attr.disabled]="disableField ? 'disabled' : null" formControlName="ConditionText"
                placeholder="{{'product-management.product_module_stepper_lables.condition_text'  | translate}}">
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" class="label">{{'product-management.product_module_stepper_lables.Maximisation' | translate}}</label>
                <mat-select class="dropdownStyle" formControlName="Maximisation" 
                [disabled]="disableField == true" placeholder="{{'objectModule.select_lable' | translate}}">
                <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                <mat-option *ngFor="let ele of maximizationtype" [value]="ele.id">{{ele.desc}}</mat-option>
            </mat-select>

        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" class="label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'product-management.product_module_stepper_lables.Period_detention_in_months'  | translate}}</label>
            <input type="number" class="input" formControlName="DetentionPeriod"
                placeholder="{{'product-management.product_module_stepper_lables.Period_detention_in_months'  | translate}}" 
                [attr.disabled]="(is_auto_fill_data_enabled == true || disableField) ? 'disabled' : null"
                oninput="if(this.value > 99 || !this.value.match('^[0-9]*$') || this.value.match('^[+-,eE]*$') || this.value < 1)this.value='';">
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" class="label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'product-management.product_module_stepper_lables.Type_of_deductible'  | translate}}</label>
            <mat-select class="dropdownStyle" formControlName="DeductibleType"
                placeholder="{{'objectModule.select_lable' | translate}}"
                [disabled]="(is_auto_fill_data_enabled || disableField)" >
                <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                <mat-option *ngFor="let ele of typeOfDeductible" [value]="ele.id">{{ele.desc}}</mat-option>
            </mat-select>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" class="label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'product-management.product_module_stepper_lables.sb_Type'  | translate}}</label>
            <mat-select class="dropdownStyle" formControlName="SBType" (selectionChange)="selectSBType($event)"
                placeholder="{{'objectModule.select_lable' | translate}}"
                [disabled]="(is_auto_fill_data_enabled || disableField)" >
                <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                <mat-option *ngFor="let ele of SBType" [value]="ele.id">{{ele.desc}}</mat-option>
            </mat-select>
        </div>

       

<div class="col-md-6 col-sm-12 col-12 common-field">
    <label for="" *ngIf="!deductibleAmountError1 && !deductibleAmountError2" class="label" style="text-transform: none;"> {{'product-management.product_module_stepper_lables.deductable_amount'  | translate}}</label>
    <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
        *ngIf="deductibleAmountError1 || deductibleAmountError2">
        <span [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
            {{'product-management.product_module_stepper_lables.deductable_amount'  | translate}}</span> : <span style="text-transform: initial">{{'objectModule.limitError' | translate}}"</span></label>
    <div style="display: flex;">
        <mat-select id="currencyDropdown" (selectionChange)="currancyChanged($event)"
            [disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 35) ? 'disabled' : null"
            (click)="currencyTypeOneSearch()" (keyup.enter)="currencyTypeOneSearch()"
            class="dropdownStyle currencyDrop currecnyStyle" formControlName="currency_type_id"
            [disableOptionCentering]="true" panelClass="currencyTypeDropdown" title="{{selectedCurrency}}"
            #currencyTypeMinDeductibleAmount>
            <mat-select-trigger>
                <span>{{currancySymbol}}</span>
            </mat-select-trigger>
            <input type="text" class="searchYear" id="currencyTypeOneSearch" maxlength="5" (click)="resetList()"
                autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                [formControl]="currencyType">
            <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()" [value]="ele.id"
                matTooltip="{{ele.cur_symbol}}">
                <span style="display: flex;">
                    <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                    <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                </span>
            </mat-option>
        </mat-select>
        <input type="text" style="border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
            class="input newValue" (paste)="onTypeNewValue($event, 'paste','DeductibleAmount')"
            (keypress)="onTypeNewValue($event, 'keypress','DeductibleAmount')"
            (blur)="changeNumber($event.target.value, 'DeductibleAmount')"
            [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 35) ? 'disabled' : null"
            formControlName="DeductibleAmount" placeholder="{{'product-management.product_module_stepper_lables.deductable_amount'  | translate}}">
        <input type="text" style="border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"
            (paste)="onTypeNewValue($event, 'paste','DeductibleAmount')"
            (keypress)="onTypeNewValue($event, 'keypress','DeductibleAmount')"
            (blur)="changeNumber($event.target.value, 'DeductibleAmount')"
            [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 35) ? 'disabled' : null"
            formControlName="DeductibleAmount" placeholder="{{'product-management.product_module_stepper_lables.deductable_amount'  | translate}}">
    </div>
</div>





        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" class="label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'product-management.product_module_stepper_lables.Deductible'  | translate}}</label>
            <input type="number" class="input" formControlName="DeductiblePercentage"
                [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 36) ? 'disabled' : null"
                placeholder="{{'product-management.product_module_stepper_lables.Deductible'  | translate}}" 
                oninput="if(this.value > 100 || !this.value.match('^[0-9]*$') || this.value.match('^[+-,eE]*$') || this.value < 1)this.value='';">
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" *ngIf="!minDeductibleAmountError1 && !minDeductibleAmountError2" class="label" style="text-transform: none;"> {{'product-management.product_module_stepper_lables.Min_deductible_amount'  | translate}}</label>
            <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
                *ngIf="minDeductibleAmountError1 || minDeductibleAmountError2">
                <span [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                    {{'product-management.product_module_stepper_lables.Min_deductible_amount'  | translate}}</span> : <span style="text-transform: initial">{{'objectModule.limitError' | translate}}"</span></label>
            <div style="display: flex;">
                <mat-select id="currencyDropdown" (selectionChange)="currancyChanged($event)"
                    [disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 36) ? 'disabled' : null"
                    (click)="currencyTypeOneSearch()" (keyup.enter)="currencyTypeOneSearch()"
                    class="dropdownStyle currencyDrop currecnyStyle" formControlName="currency_type_id"
                    [disableOptionCentering]="true" panelClass="currencyTypeDropdown" title="{{selectedCurrency}}"
                    #currencyTypeMinDeductibleAmount>
                    <mat-select-trigger>
                        <span>{{currancySymbol}}</span>
                    </mat-select-trigger>
                    <input type="text" class="searchYear" id="currencyTypeOneSearch" maxlength="5" (click)="resetList()"
                        autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                        [formControl]="currencyType">
                    <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()" [value]="ele.id"
                        matTooltip="{{ele.cur_symbol}}">
                        <span style="display: flex;">
                            <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                            <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                        </span>
                    </mat-option>
                </mat-select>
                <input type="text" style="border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                    class="input newValue" (paste)="onTypeNewValue($event, 'paste','minDeductibleAmount')"
                    (keypress)="onTypeNewValue($event, 'keypress','minDeductibleAmount')"
                    (blur)="changeNumber($event.target.value, 'minDeductibleAmount')"
                    [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 36) ? 'disabled' : null"
                    formControlName="minDeductibleAmount" placeholder="{{'product-management.product_module_stepper_lables.Min_deductible_amount'  | translate}}">
                <input type="text" style="border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"
                    (paste)="onTypeNewValue($event, 'paste','minDeductibleAmount')"
                    (keypress)="onTypeNewValue($event, 'keypress','minDeductibleAmount')"
                    (blur)="changeNumber($event.target.value, 'minDeductibleAmount')"
                    [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 36) ? 'disabled' : null"
                    formControlName="minDeductibleAmount" placeholder="{{'product-management.product_module_stepper_lables.Min_deductible_amount'  | translate}}">
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" *ngIf="!maxDeductibleAmountError1 && !maxDeductibleAmountError2" class="label"
                style="text-transform: none;"> {{'product-management.product_module_stepper_lables.Max_deductible_amount'  | translate}}</label>
            <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
                *ngIf="maxDeductibleAmountError1 || maxDeductibleAmountError2">
                <span [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                    {{'product-management.product_module_stepper_lables.Max_deductible_amount'  | translate}}</span> : <span style="text-transform: initial">{{'objectModule.limitError' | translate}}"</span></label>


            <div style="display: flex;">
                <mat-select id="currencyDropdown" (selectionChange)="currancyChangedTwo($event)"
                [disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 36) ? 'disabled' : null"
                    class="dropdownStyle currencyDrop currecnyStyle" (click)="currencyTypeTwoSearch()"
                    (keyup.enter)="currencyTypeTwoSearch()" formControlName="currency_type_id"
                    [disableOptionCentering]="true" panelClass="currencyTypeDropdown" title="{{selectedCurrency}}"
                    #currencyTypeAverageStock>
                    <mat-select-trigger>
                        <span>{{currancySymbol}}</span>
                    </mat-select-trigger>
                    <input type="text" class="searchYear" id="currencyTypeTwoSearch" maxlength="5" (click)="resetList()"
                        autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                        [formControl]="currencyType">
                    <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()" [value]="ele.id"
                        matTooltip="{{ele.cur_symbol}}">
                        <span style="display: flex;">
                            <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                            <span style="justify-content: end;">{{ele.cur_symbol}}</span>
                        </span>
                    </mat-option>
                </mat-select>
                <input type="text" style="border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'"
                    class="input newValue" (paste)="onTypeNewValue($event, 'paste','maxDeductibleAmount')"
                    (keypress)="onTypeNewValue($event, 'keypress','maxDeductibleAmount')"
                    [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 36) ? 'disabled' : null"
                    (blur)="changeNumber($event.target.value, 'maxDeductibleAmount')"
                    formControlName="maxDeductibleAmount" placeholder="{{'product-management.product_module_stepper_lables.Max_deductible_amount'  | translate}}">
                <input type="text" style="border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"
                    (paste)="onTypeNewValue($event, 'paste','maxDeductibleAmount')"
                    (keyup)="onTypeNewValue($event, 'keypress','maxDeductibleAmount')"
                    [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 36) ? 'disabled' : null"
                    (blur)="changeNumber($event.target.value, 'maxDeductibleAmount')"
                    formControlName="maxDeductibleAmount" placeholder="{{'product-management.product_module_stepper_lables.Max_deductible_amount'  | translate}}">
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-12 common-field">
            <label for="" class="label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'product-management.product_module_stepper_lables.Days_for_deductible'  | translate}}</label>
            <input type="number" [attr.disabled]="((is_auto_fill_data_enabled == true || disableField) || stepperForm.controls['SBType'].value !== 37) ? 'disabled' : null"
                oninput="if(this.value > 100 || !this.value.match('^[0-9]*$') || this.value.match('^[+-,eE]*$'))this.value='';" 
                class="input" formControlName="DeductibleDays"
                placeholder="{{'product-management.product_module_stepper_lables.Days_for_deductible'  | translate}}">
        </div>
    </form>
</div>
