export const GenericCriteriaConstants = {
  1: 1, // Criteria 1 to 20
  2: 26,
  3: 27,
  4: 28,
  5: 29,
  6: 30,
  7: 31,
  8: 32,
  9: 33,
  10: 34,
  11: 35,
  12: 21,
  13: 36 // criteria 21 to 25
};
export const ObjectTypesID = {
  BUILDING: 1,
  OPERATING_EQUIPMENT: 4,
  MODEL_SAMPLES_TOOLS: 8,
  GOODS_INVENTORY: 9,
  MISCELLANEOUS: 10
};
