import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import {
  DataSourceTypes,
  RowSides,
} from '../../../../constants/formula.constants';
import { ValidationService } from '@core/services/object-module/validation-service.service';
@Component({
  selector: 'app-lhs-rhs',
  templateUrl: './lhs-rhs.component.html',
  styleUrls: ['./lhs-rhs.component.css'],
})
export class LHSRHSComponent implements OnInit {
  @Input('operators') operators;
  @Input('is_disabled') is_disabled;
  @Input('stepper_id') stepper_id;
  @Input('formula_id') formula_id;
  @Input('row') row;
  @Input('data_source_types') data_source_types;
  @Input('data_source_data') data_source_data;
  @Input('i') i;
  @Input('product_info_id') product_info_id;
  @Input('isformUpdated') isformUpdated;
  @Input('from') from;
  @Output('changeMenuItemData') changeMenuItemData = new EventEmitter<any>();
  @Output('changeOperatorData') changeOperatorData = new EventEmitter<any>();
  @Output('isformUpdatedData') isformUpdatedData = new EventEmitter<any>();
  @Output('data_sourceData') data_sourceData = new EventEmitter<any>();
  readonly ROWSIDES = RowSides;
  constructor(
    private _apiCallService: ApiCallsService,
    public validationService: ValidationService
  ) {}

  ngOnInit(): void {}
  changeMenuItem(id, fromWhere, index, is_heading, event, is_data) {
    this.changeMenuItemData.emit({
      id,
      fromWhere,
      index,
      is_heading,
      event,
      is_data,
      level: this.i?.i,
      inputDataOriginal: this.i,
    });
  }
  changeOperator(id, index, is_heading, event, from) {
    this.changeOperatorData.emit({
      id,
      index,
      is_heading,
      event,
      from,
      level: this.i?.i,
      inputDataOriginal: this.i,
    });
  }
  getSurceData(id, product_info_id) {
    this.data_source_data = [];

    if (id == DataSourceTypes.CUSTOM_CREATED_ATTRIBUTE) {
      if (this.stepper_id) {
        this._apiCallService
          .getFormulaListByStepperId(this.stepper_id)
          .subscribe(data => {
            this.data_source_data = data.data;
            this.data_source_data = this.data_source_data.filter(
              dataValue => dataValue.id != this.formula_id
            );
            this.data_sourceData.emit(this.data_source_data);
          });
      }
    } else {
      this._apiCallService
        .getDataSourceDetails(id, product_info_id)
        .subscribe(data => {
          this.data_source_data = data.data;
          this.data_sourceData.emit(this.data_source_data);
        });
    }
  }
  inputChanged(event) {
    this.isformUpdated = true;
    this.isformUpdatedData.emit(event);
  }
  focusSearch(key) {
    document.getElementById(key) && document.getElementById(key).focus();
  }
}
