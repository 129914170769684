import { Directive, ElementRef, HostListener } from '@angular/core';
import { CurrencyCustomPipe } from '../pipes/currency-custom.pipe';

@Directive({
  selector: '[GermanFormat]',
})
export class GermanFormatingDirective {
  constructor(
    private el: ElementRef,
    private _currencyCustomPipe: CurrencyCustomPipe
  ) {}

  @HostListener('input') onInput(): void {
    let inputValue = this.el.nativeElement.value;
    // allow only numbers, comma, and dot
    inputValue = inputValue.replace(/[^0-9,\.]/g, '');

    // Replace multiple commas with a single comma
    inputValue = inputValue.replace(/,+/g, ',');

    // Replace multiple dots with a single dot
    inputValue = inputValue.replace(/\.{2,}/g, '.');

    // Limit the input to a maximum of 15 characters
    if (inputValue.length > 15) {
      inputValue = inputValue.slice(0, 15);
    }

    // Ensure only one comma exists and restrict to two decimal places
    const parts = inputValue.split(',');
    if (parts.length > 2) {
      inputValue = parts[0] + ',' + parts[1];
    } else if (parts.length === 2) {
      parts[1] = parts[1].slice(0, 2); // Restrict to two decimal places
      inputValue = parts.join(',');
    }

    this.el.nativeElement.value = inputValue;
  }

  @HostListener('blur')
  onBlur(): void {
    let value = this.el.nativeElement.value;

    // Remove all periods and replace commas with periods
    value = value.replace(/\./g, '').replace(/,/g, '.');

    // Trim whitespace
    value = value.trim();

    // Check if the value has at least 4 characters
    if (value.length >= 4) {
      // Use the Angular currency pipe to format the value
      this.el.nativeElement.value = this._currencyCustomPipe
        .transform(value)
        ?.replace('€', '')
        .trim();
    }
  }
}
