import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  associated_customers_for_artus: any = [];

  setAssociatedCustomerValues(values: any){
    this.associated_customers_for_artus = values;
  }
  getAssociatedCustomerValues(){
    return this.associated_customers_for_artus;
  }
}
