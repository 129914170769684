<app-header></app-header>

<div class="container-ic">
    <div style="margin-left:45px;background-color: #ffffff;">
        <div style="width:100%; height:100%;min-height: 100vh; margin:0">
             <h6 style="color:#2175BD;font-size: 18px;margin-top:1%" class="user-name"> <strong><span>/</span>&nbsp;&nbsp;{{'invite-user.INVITEbENUTZER_text' | translate}}  </strong> </h6>
            <div class="d-flex flex-column components-container-gt-xs" style="width: 98%;
            background: #d6d1d170;margin-top:1%;border-radius:4px">
                <div class="table-component-holder mat-elevation-z4" style="padding: 19px 37px 0px;">
                   <p class="heading" style="float:left;width:98%" ><strong>{{'invite-user.FILTER_text' | translate}}</strong></p>
                    <img alt="" src="../../../assets/images/filter.png" style="cursor: pointer;float:left"(click)="toggle()" height="20px" id="bt">
                    <ng-container *ngIf="show" style="margin-top: 15px;">
                  <form name="search" [formGroup]="searchForm" style="font-size: 13px;
                  color: #4e4e4e;">
                    <div class="serach-row2 col-md-12" style="clear:both;padding:0">
                      <div class="row" style="margin-left: -3px;
                      margin-right: -3px;">
                        <div class="form-field col-md-3">
                          <div class="row">
                        <label class="col-md-5 col-lg-4 col-xl-4 col-sm-12" style="padding-left:3px" style="padding-left:3px">{{'invite-user.Gesellschaft_label' | translate}}</label>
                        <mat-select class="col-md-7 col-lg-6 col-xl-6 col-sm-12" placeholder="{{'invite-user.Versicherer_text' | translate}}" style="padding:0;height: fit-content;border:1px solid #00000057;background-color: #ffffff;border-radius: 3px;">
                          <mat-option>
                            {{'invite-user.WÄHLEN_text' | translate}}  
                          </mat-option>
                        </mat-select>
                        </div>
                        </div>
                        <div class="form-field col-md-3">  
                          <div class="row">    
                        <label class="col-md-5 col-lg-4 col-xl-4 col-sm-12" style="padding-left:3px">{{'invite-user.Kunde_label' | translate}}</label>
                          <mat-select class="col-md-7 col-lg-6 col-xl-6 col-sm-12" placeholder="{{'invite-user.RCS_text' | translate}}" style="padding:0;border:1px solid #00000057;height:fit-content;background-color: #ffffff;border-radius: 3px;">
                            <mat-option>
                              {{'invite-user.WÄHLEN_text' | translate}}  
                            </mat-option>
                          </mat-select>
                          </div>
                        </div>
                        <div class="form-field col-md-3">  
                          <div class="row">
                        <label class="col-md-5 col-lg-4 col-xl-4 col-sm-12" style="padding-left:3px">{{'invite-user.Tochterunternehmen_label' | translate}}</label>
                        <mat-select class="col-md-7 col-lg-6 col-xl-6 col-sm-12" placeholder="{'invite-user.FGanz_text' | translate}}" style="padding:0;border:1px solid #00000057;height:fit-content;background-color: #ffffff;border-radius: 3px;">
                          <mat-option>
                            {{'invite-user.WÄHLEN_text' | translate}}  
                          </mat-option>
                        </mat-select>
                        </div>
                        </div>
                        <div class="form-field col-md-3">
                          <div class="row">
                        <label class="col-md-5 col-lg-4 col-xl-4 col-sm-12" style="padding-left:3px">{{'invite-user.status_label' | translate}}</label>
                        <mat-select class="col-md-7 col-lg-6 col-xl-6 col-sm-12" placeholder="{'invite-user.InaMuller_text' | translate}}" style="padding:0;height: fit-content;border:1px solid #00000057;background-color: #ffffff;border-radius: 3px;">
                          <mat-option>
                            {{'invite-user.WÄHLEN_text' | translate}}  
                          </mat-option>
                        </mat-select>
                          </div>
                          </div>
                      </div>
                    </div>
                    <div class="serach-row3 col-md-12" style="clear:both;padding:0">
                      <div class="row" style="    margin-left: -3px;
                      margin-right: -3px;">
                      </div>
                    </div>
                  </form>
                </ng-container>
                </div>
            </div>
            <div  style="width: 92%;
            margin: 0 2%;
            margin-top: 2%;clear:both" class="d-flex flex-column components-container-gt-xs">
              <div class="table-component-holder">
                <div class="table-container">
                        <table mat-table [dataSource]="dataSource" class="table-responsive">
                        <!-- Position Column -->
                        <ng-container matColumnDef="NAME">
                          <th mat-header-cell *matHeaderCellDef><span>{{'invite-user.NAME_text' | translate}}    </span></th>
                          <td style="border-bottom: 1px solid  #00000057 !important;
                          border-left: 1px solid  #00000057 !important;
                          font-size: 12px;" mat-cell *matCellDef="let element">{{element.NAME}}  </td>
                        </ng-container>
                        
                        <!-- Name Column -->
                        <ng-container matColumnDef="DIVISION">
                          <th mat-header-cell *matHeaderCellDef> <span>{{'invite-user.DIVISION_text' | translate}} </span></th><br/>
                          <td style="border-bottom: 1px solid  #00000057 !important;
                          border-left: 1px solid  #00000057 !important;
                          font-size: 12px;" mat-cell *matCellDef="let element"> {{element.DIVISION}}</td><br/>
                        </ng-container>
                        <br/>
                        
                        <!-- Weight Column -->
                        <ng-container matColumnDef="CUSTOMER">
                          <th mat-header-cell *matHeaderCellDef>  <span>{{'invite-user.KUNDIN_text' | translate}} </span></th>
                          <td style="border-bottom: 1px solid  #00000057 !important;
                          border-left: 1px solid  #00000057 !important;
                          font-size: 12px;" mat-cell *matCellDef="let element"> {{element.CHILDCOMPANY}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="CHILDCOMPANY">
                          <th mat-header-cell *matHeaderCellDef><span>{{'invite-user.CHILDuNTERNEHMEN_text' | translate}} </span></th>
                          <td style="border-bottom: 1px solid  #00000057 !important;
                          border-left: 1px solid  #00000057 !important;
                          font-size: 12px;" mat-cell *matCellDef="let element"> {{element.CHILDCOMPANY}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="TELEFON">
                          <th mat-header-cell *matHeaderCellDef><span>{{'invite-user.TELEFON_text' | translate}} </span> </th>
                          <td style="border-bottom: 1px solid  #00000057 !important;
                          border-left: 1px solid  #00000057 !important;
                          font-size: 12px;" mat-cell *matCellDef="let element"> {{element.TELEFON}} </td>
                        </ng-container>

                        <ng-container matColumnDef="EMAIL">
                          <th mat-header-cell *matHeaderCellDef><span>{{'invite-user.EMAIL_text' | translate}} </span> </th>
                          <td style="border-bottom: 1px solid  #00000057 !important;
                          border-left: 1px solid  #00000057 !important;
                          font-size: 12px;" mat-cell *matCellDef="let element"> {{element.EMAIL}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef style="background-color: #2175BD;">{{'invite-user.Aktion_text' | translate}}  </th>
      
                          <td mat-cell *matCellDef="let element" 
                            class="edit-text">
                          <a class="edit-text-link" (click)="Onactionprocess()"><span>
                             {{'invite-user.EDIT_text' | translate}} </span></a></td>
                      </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                                                
                </div>
              </div>
            </div> 
        </div>
      </div>
    </div>
