<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic newDocument">
  <div style="white-space: nowrap;">
    <app-breadcrumbs [isCustomerUser]="isCustomerUser" [pageBreadcrumbText]="pageBreadcrumbText"
      [isCustomerSelected]="isCustomerSelected" [divisionName]="divisionName" [customerName]="customerName$ | async">
    </app-breadcrumbs>

    <div class="filter-distance">
      <mat-accordion>
        <mat-expansion-panel class="filter-section" (opened)="customerFilterToggle = true"
          (closed)="customerFilterToggle = false" [expanded]="customerFilterToggle" hideToggle>
          <mat-expansion-panel-header class="filter-section-header p-unset">
            <mat-panel-title class="filter-section-title-alignment filter-title">
              <div class="flex-center g-7">
                <button class="action-btn">
                  <mat-icon *ngIf="customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="!customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_right</mat-icon>
                </button>
                <span>
                  <strong>FILTER</strong>
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <form [formGroup]="filterForm" class="f-13">
            <div class="d-flex">
              <div class="d-flex gap-3 flex-grow-1">
                <select formControlName="contractSelect" (change)="documentSelected($event)" class="select-dropdown">
                  <option value="customerall" selected>{{'customer-dashboard.all_documents' | translate}}</option>
                  <option value="contract" selected>{{'customer-dashboard.contracts' | translate}}</option>
                  <option value="claims">{{'customer-dashboard.claims' | translate}}</option>
                  <option value="customer">{{'customer-dashboard.customer' | translate}}</option>
                </select>

                <select *ngIf="(selectLang$ | async) === 'German(DE)'" formControlName="docType" (change)="doctypeSelected()"
                  class="select-dropdown">
                  <option value="1" hidden disabled selected>
                    {{'customer-dashboard.Nach_Dokumenten' | translate}}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Angebot">{{
                    'contracts.Offer_text' | translate }}
                  </option>
                  <option *ngIf="document == 'claims' || document == 'all'" value="Auszahlung">
                    {{ "claims.Auszahlungsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'claims' || document == 'all'" value="Gutachten">
                    {{ "claims.Gutachten_text" | translate }}
                  </option>
                  <option *ngIf="document == 'claims'" value="Korrespondenz">
                    {{ "claims.Korrespondenzdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'claims' || document == 'all'" value="Rechnung">
                    {{ "claims.Rechnungsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Bedingungen">
                    {{ "contracts.Bedingungen_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Beratungsprotokoll">
                    {{ "contracts.Beratungsprotokoll_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'"
                    value="Elektronische Versicherungsbestätigung">
                    {{
                    "contracts.Elektronische_Versicherungsbestätigung_text"
                    | translate
                    }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Formular">
                    {{'contracts.Form_text' | translate}}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Korrespondenzdokument">
                    {{
                    "contracts.Korrespondenzdokument_text" | translate
                    }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Nachtrag">
                    {{ "contracts.Nachtragsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Police">
                    {{ "contracts.Policendokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Rechnung">
                    {{ "contracts.Rechnungsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Sicherungsschein">
                    {{ "contracts.Sicherungsschein_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Versicherungsbestätigung">
                    {{
                    "contracts.Versicherungsbestätigung_text"
                    | translate
                    }}
                  </option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Wichtiges Dokument">
                    {{'contracts.Important_document_text' | translate}}</option>
                  <option *ngIf="document == 'contract' || document == 'all'" value="Zulassungsbescheinigung">
                    {{
                    "contracts.Zulassungsbescheinigung_text" | translate
                    }}
                  </option>
                </select>

                <select *ngIf="(selectLang$ | async) === 'English(EN)'" formControlName="docType" (change)="doctypeSelected()"
                  class="select-dropdown">
                  <option value="1" hidden disabled selected>
                    {{'customer-dashboard.Nach_Dokumenten' | translate}}
                  </option>
                  <option *ngIf="document == 'claims'" value="Korrespondenz">
                    {{ "claims.Korrespondenzdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'claims'" value="Gutachten">
                    {{ "claims.Gutachten_text" | translate }}
                  </option>
                  <option *ngIf="document == 'claims'" value="Rechnung">
                    {{ "claims.Rechnungsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'claims'" value="Auszahlung">
                    {{ "claims.Auszahlungsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Nachtrag">
                    {{ "contracts.Nachtragsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Beratungsprotokoll">
                    {{ "contracts.Beratungsprotokoll_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Bedingungen">
                    {{ "contracts.Bedingungen_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Korrespondenzdokument">
                    {{
                    "contracts.Korrespondenzdokument_text" | translate
                    }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Elektronische Versicherungsbestätigung">
                    {{
                    "contracts.Elektronische_Versicherungsbestätigung_text"
                    | translate
                    }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Formular">{{'contracts.Form_text' | translate}}
                  </option>
                  <option *ngIf="document == 'contract'" value="Wichtiges Dokument">
                    {{'contracts.Important_document_text' | translate}}</option>
                  <option *ngIf="document == 'contract'" value="Versicherungsbestätigung">
                    {{
                    "contracts.Versicherungsbestätigung_text"
                    | translate
                    }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Rechnung">
                    {{ "contracts.Rechnungsdokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Angebot">{{ 'contracts.Offer_text' | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Police">
                    {{ "contracts.Policendokument_text" | translate }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Zulassungsbescheinigung">
                    {{
                    "contracts.Zulassungsbescheinigung_text" | translate
                    }}
                  </option>
                  <option *ngIf="document == 'contract'" value="Sicherungsschein">
                    {{ "contracts.Sicherungsschein_text" | translate }}
                  </option>
                </select>

                <mat-form-field class="filter-date-picker" appearance="fill">
                  <input matInput [matDatepicker]="picker1" class="date-input"
                    placeholder="{{'claims.von_text' | translate}}" (dateChange)="vonSelected()" formControlName="von"
                    [max]="filterForm.get('bis').value" style="color: #989393;">
                  <mat-datepicker-toggle matSuffix [for]="picker1">
                    <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                      {{'claims.keyboard-arrow-down_text' | translate}}
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="filter-date-picker" appearance="fill">
                  <input matInput [matDatepicker]="picker2" class="date-input"
                    placeholder="{{'claims.bis_text' | translate}}" (dateChange)="bisSelected()" formControlName="bis"
                    [min]="filterForm.get('von').value" style="color: #989393;">
                  <mat-datepicker-toggle matSuffix [for]="picker2">
                    <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                      {{'claims.keyboard-arrow-down_text' | translate}}
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="flex-center filter-btn-gap">
                <button class="filterBtn" type="submit" [disabled]="isDisabled"
                  (click)="getFormsValue(filterForm.value)">
                  {{'user-data.Filter_text' | translate}}
                </button>
                <button class="filterBtn" placement="top" tooltipClass="generic-tooltip"
                  ngbTooltip="{{'new-document.Filter_löschen' | translate}}" (click)="resetFilter()">
                  <img alt="" height="16px" src="assets/images/Icon material-refresh.png">
                </button>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="table-outer-container">
      <div class="infiniteScrollTable" style="max-height: calc(100vh - 325px);">
        <mat-table class="table custom-table" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="contract_number">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'customer-dashboard.Vertragsnummer' | translate}}
                <img alt="" mat-sort-header class="arrow-image" src="assets/images/ic-icons/Sort.svg">
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.document_source == 'Contract'" style="cursor: pointer;"
                (click)="navigateToContract(element.liztu_vn_kdnr,element.liztu_vs_idnr)">
                {{element.contract_number}} </span>
              <span *ngIf="element.document_source == 'Claims'" style="cursor: pointer;"
                (click)="navigateToClaims(element.customer_id,element.liztu_sa_idnr,element.liztu_vn_kdnr,is_source_ivm,element.upload_date)">
                {{element.contract_number}} </span>
              <span *ngIf="element.document_source == 'Customer'">
                {{element.contract_number}} </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="product_name">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'customer-dashboard.product' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" title="{{element.product_name}}">
              <span class="custom-ellipsis"> {{element.product_name}} </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="document_type">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'customer-dashboard.Dokument_Typ' | translate}}
                <img alt="" mat-sort-header class="arrow-image" src="assets/images/ic-icons/Sort.svg">
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" title="{{element.document_type}}">
              <span class="custom-ellipsis" style="max-width: 150px;">{{element.document_type}}</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Date">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'customer-dashboard.Datum_der' | translate}}
                <img alt="" mat-sort-header class="arrow-image" (click)="sortByDate();"
                  src="assets/images/ic-icons/Sort.svg">
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.upload_date | dateFormat}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Documentbeschreibung">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'customer-dashboard.Beschreibung' | translate }} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" title="{{element.document_description | removeString}}">
              <span class="custom-ellipsis">
                {{element.document_description | removeString }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ACTION">
            <mat-header-cell *matHeaderCellDef style="width: 115px; text-align: center;">
              <span>{{'customer-dashboard.Aktion' | translate}} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="control-section">
                <div class="subscribe">
                  <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                  <div class="hide">
                    <div class="action-btn-align" style="gap: 1.5em;">
                      <button class="action-btn" (click)="pdfViewer(element)" placement="top"
                        tooltipClass="generic-tooltip" ngbTooltip="{{'user-data.EINSEHEN_tooltip' | translate}}">
                        <img alt="" src="assets/images/Group 2493.svg">
                      </button>
                      <button placement="top" tooltipClass="generic-tooltip"
                        ngbTooltip="{{'new-document.Herunterladen_tooltip' | translate}}" class="action-btn"
                        (click)="downloadPdf(element)">
                        <img alt="" src="assets/images/Group 3560.svg">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <app-present top="50vh" left="5px"></app-present>
</div>

<custom-pdf-viewer *ngIf="showPDF" [pdfLink]="pdfLink" (onClosePdf)="closePDF($event)" [blob]="blob"
  [via]="'policyTemplate'"></custom-pdf-viewer>