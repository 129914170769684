<label class="zursLableExt">
  <div class="zursLabel">{{'objectModule.object_details_group.zürszone' | translate}}</div>
  <a class="zursAnchor" *ngIf="!isLinkdisabled" (click)="zursPopUp()" >{{'objectModule.zurs_analysis' | translate}}</a>
  <span class="zursAnchor" *ngIf="isLinkdisabled && isLatLongMissing"  matTooltip="{{'objectModule.object_details_group.lat_lon_not_available' | translate}} ">
  {{'objectModule.zurs_analysis' | translate}}
</span>
<span class="zursAnchor" *ngIf="isLinkdisabled && !isLatLongMissing" matTooltip="{{'objectModule.object_details_group.save_Address_Msg' | translate}} ">
{{'objectModule.zurs_analysis' | translate}}
</span>

</label>
<div class="zursInput">
  <input readonly type="text" class="input zursInputStyle" *ngIf="!isLocationObject" title="{{getZursId() || 'objectModule.object_details_group.zürszone' | translate}}" value="{{getZursId()}}"
    placeholder="{{'objectModule.object_details_group.zürszone' | translate}}">

  <input readonly type="text" class="input zursInputStyle" *ngIf="isLocationObject" title="{{getZursId() || 'objectModule.object_details_group.Zone' | translate}}" value="{{getZursId()}}"
    placeholder="{{'objectModule.object_details_group.Zone' | translate}}">

  <input readonly type="text" class="input zursInputDateStyle" title="{{(getZursDate() | dateFormat:'dd.MM.yyyy HH:mm') || 'objectModule.object_details_group.zürszone' | translate}}" value="{{getZursDate() | dateFormat:'dd.MM.yyyy HH:mm'}}"
    placeholder="{{'objectModule.object_details_group.zürszone' | translate}}">
</div>
<div id="successToast"></div>
