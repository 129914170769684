import { DocumentSource } from '../../models/document-management.model';

export type WriteLetterEmailRequiredFields = {
    type: PopupType,
    source: DocumentSource
};

export enum PopupType {
    LETTER = 'LETTER',
    EMAIL = 'EMAIL'
}
