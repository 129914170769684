<div>
  <span class="criteria-heading">{{'objectModule.foil_object.foil_pacaking' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="foilPackagingForm" id="foilPackagingForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label class="long-label" for="is_winding">{{'objectModule.foil_object.winding_brushing' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_winding" id="is_winding">
        <mat-radio-button value=true>{{'objectModule.foil_object.Yes' | translate}}</mat-radio-button>
        <mat-radio-button value=false>{{'objectModule.foil_object.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_welding_with_hot_wire" class="long-label">{{'objectModule.foil_object.cutting_welding_with_hot_wire'
      | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_welding_with_hot_wire" id="is_welding_with_hot_wire">
        <mat-radio-button value=true>{{'objectModule.foil_object.Yes' | translate}}</mat-radio-button>
        <mat-radio-button value=false>{{'objectModule.foil_object.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_dist_combustible_materials_5m"
      class="long-label">{{'objectModule.foil_object.minimum_distance_to_combustible_material' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_dist_combustible_materials_5m" id="is_dist_combustible_materials_5m">
        <mat-radio-button value=true>{{'objectModule.foil_object.Yes' | translate}}</mat-radio-button>
        <mat-radio-button value=false>{{'objectModule.foil_object.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div
    [ngClass]="foilPackagingForm.value.is_shrinking_with_open_flame ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_shrinking_with_open_flame">
        {{'objectModule.foil_object.shrinking_with_an_open_flame'
        | translate}}
      </mat-slide-toggle>
    </div>

    <div class="row toggle-section-content" *ngIf="foilPackagingForm.value.is_shrinking_with_open_flame">
      <div class="col-md-3 col-sm-6 col-6">
        <label for="is_object_protection_available"
          class="long-label">{{'objectModule.foil_object.object_protection_available' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group formControlName="is_object_protection_available" id="is_object_protection_available">
            <mat-radio-button value=true>{{'objectModule.foil_object.Yes' | translate}}</mat-radio-button>
            <mat-radio-button value=false>{{'objectModule.foil_object.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-6">
        <label for="remarks" class="long-label">{{'objectModule.foil_object.remark' | translate}}</label>
        <input formControlName="remarks" id="remarks" class="input" maxlength="100" autocomplete="off"
          placeholder="{{'objectModule.foil_object.remark' | translate}}" matInput
          [title]="foilPackagingForm.value.remarks">
      </div>
    </div>
  </div>
</form>
