<div class="modal upload-logo dialog" id="myModal" [style.display]="showDocumentPopup ? 'block' : 'none'">
  <div class="modal-dialog" style="top: 155px; display: initial; ">
    <div class="modal-content modalContent" style="width: 800px;padding-left:0;padding-right:0">
      <!-- Modal Header -->
      <div class="modal-header" style="padding: 7px 10px 1px 25px;">
        <div style="text-align:initial;">
          <h6 style="font-weight: 600;">{{'objectModule.document_heading' | translate}} </h6>
          <span style="font-size: 11px;
        color: #a0a0a0;">{{'objectModule.document_subheading' | translate}}</span>
        </div>
        <button type="button" class="close" data-dismiss="modal" (click)="hidePopup();">
          <img alt="" src="../../../../../assets/images/Group 3114.svg">
        </button>
      </div>
      <div class="modal-body" style="font-size:12px;height: 254px;padding-left:0;padding-right:0;overflow:scroll">
        <div style="text-align:center">

          <table *ngIf="areFilesAvailable()" style="table-layout: fixed;width: 100%;margin-top: -11px;">
            <tr class="document-row">
              <th style="width: 0px;"></th>
              <th class="document-name-th">{{'objectModule.Document_Name' | translate}}</th>
              <th>{{'objectModule.description_header' | translate}}</th>
              <th>{{'objectModule.Uploaded_By' | translate}}</th>
              <th>{{'objectModule.last_Updated_by' | translate}}</th>
              <th [style.display]="insurer_list ? '' : 'none'">{{'product-management.insurer' | translate}}</th>
              <th [style.display]="document_list ? '' : 'none'">{{'product-management.document_type' | translate}}</th>
              <th style="text-align:center">{{'invite-user.Aktion_text' | translate}}</th>
            </tr>
            <tr class="document-row document-data"
              *ngFor="let item of selectedDocumentsArray | keyvalue: originalOrder; let i = index">
              <td style="padding-left: 10px;"><span
                  *ngIf="item.value.is_obj_linked == false || item.value.is_obj_linked == true"><img alt=""
                    height="12px" src="../../../../../assets/images/Tickmark_Green.svg"></span>
                <span *ngIf="item.value.is_obj_linked == null"><img alt="" height="12px"
                    src="../../../../../assets/images/Upload_pending.svg"></span>
              </td>
              <td class="document-name-td" title="{{item.value.document_name}}">
                <span class="custom-ellipsis">{{item.value.document_name}}</span>
              </td>
              <td>
                <input class="nameShort" id="docDescription{{item.value.id||item.value.upload_document_id}}"
                  placeholder="{{'objectModule.description_header' | translate}}" (blur)="desValue($event);"
                  title="{{item.value.document_description}}" value="{{item.value.document_description}}" style="border:none;background-color: initial;outline: transparent;
                " disabled type="text" maxlength="100">
              </td>
              <td title="{{item.value.created_by}}  {{item.value.upload_date | dateFormat:'dd.MM.yyyy HH:mm'}}">
                <span class="custom-ellipsis">{{item.value.created_by}}<br />
                  <span class="below-text">{{item.value.upload_date | dateFormat:'dd.MM.yyyy HH:mm'}}</span>
                </span>
              </td>
              <td title="{{item.value.updated_by}}  {{item.value.updated_at | dateFormat:'dd.MM.yyyy HH:mm'}}">
                <span class="custom-ellipsis">{{item.value.updated_by}}<br />
                  <span class="below-text">{{item.value.updated_at | dateFormat:'dd.MM.yyyy HH:mm'}}</span>
                </span>
              </td>
              <td [style.display]="insurer_list ? 'block' : 'none'" title="{{item.value.insurer_value}}">
                <mat-select class="dropdownStyle" [(ngModel)]="item.value.insurer_id"
                  (selectionChange)="getSelectedValue($event,item)" id="list"
                  placeholder="{{'product-management.insurer' | translate}}" [disabled]="enableInsurer(i)">
                  <mat-option *ngFor="let insurer of insurer_list" matTooltip="{{insurer.desc}}"
                    (onSelectionChange)="onChange(insurer, item)" [value]="insurer.id">
                    {{insurer.desc}}
                  </mat-option>
                </mat-select>
              </td>

              <td [style.display]="document_list ? 'block' : 'none'">
                <mat-select class="dropdownStyle" [(ngModel)]="item.value.document_type"
                  (selectionChange)="getSelectedDocumentValue($event,item)" id="list"
                  placeholder="{{'product-management.document_type' | translate}}">
                  <mat-option *ngFor="let document of document_list" matTooltip="{{document.desc}}"
                    (onSelectionChange)="onDocumentChange(document, item)" [value]="document.desc">
                    {{document.desc}}
                  </mat-option>
                </mat-select>
              </td>
              <td style="text-align: center;"> <span>
                  <button style="background: none; border: none; color: #2175BD" [disabled]="is_disable ? true : false">
                    <img alt="" matTooltip="{{'objectModule.edit_tooltip' | translate}}"
                      src="../../../../../assets/images/Edit_Description_Icon.svg" style="height: 16px;"
                      (click)="is_disable ? '' : editDescription(item.value.id||item.value.upload_document_id,i)"
                      id="edit{{i}}">
                    <span style="display: none;background: #ff9000;
                border: none;
                color: #2175BD;
                padding: 0px 3px 2px 3px;
                border-radius: 3px;
                margin-right: -3px;" id="save{{i}}"><img alt=""
                        matTooltip="{{'objectModule.save_tooltip' | translate}}" style="height: 16px;"
                        src="../../../../../assets/images/Save_description_without_bg.svg"
                        (click)="is_disable ? '' : saveDescription(item.value.id||item.value.upload_document_id,item.value.document_name,i,item.value.upload_document_id)"></span>
                  </button>&nbsp;
                  <button (click)="viewPDF(item)" [disabled]="!item"
                    style="background: none; border: none; color: #2175BD">
                    <img alt="" matTooltip="{{getTooltip(item)}}" src="../../../assets/images/new-icons/Open Doc.svg"
                      style="height: 16px;">
                  </button>&nbsp;
                  <button (click)="processDelete(item.value.document_name, item.value.upload_document_id)"
                    style="background: none; border: none; color: #2175BD" [disabled]="is_disable ? true : false">
                    <img alt="" matTooltip="{{'contracts.Löschen_tooltip' | translate}}"
                      src="../../../assets/images/new-icons/Delete icon.svg" style="height: 16px;">
                  </button>
                </span></td>
            </tr>
          </table>
          <span *ngIf="!areFilesAvailable()" class="noFileText">{{'objectModule.no_file_selected' |
            translate}}</span>
        </div>
      </div>
      <div class="modal-footer" style="display: block;margin-bottom: -6px;">
        <label for="uploader-input" ng2FileDrop [uploader]="uploader">
          <div class="custom-btn cancel-btn" [style.cursor]="is_disable ? 'inherit' : 'pointer'">
            {{'objectModule.browse_text' | translate}}</div>
        </label>
        <button (click)="uploadDocument()" [disabled]="uploadDocumentEnabled() || is_disable"
          class="custom-btn save-btn ml-2" type="button">
          {{'objectModule.document_heading' | translate}}</button>
        <input type="file" #fileUpload id="file" accept="image/png,image/jpg,image/jpeg,application/pdf,.png,.msg"
          ng2FileSelect [uploader]="uploader" (change)="handleFileInput($event.target.files)" id="uploader-input"
          style="display:none" [disabled]="is_disable ? true : false" />
      </div>
    </div>
  </div>
</div>
<custom-pdf-viewer *ngIf="showPDF" [pdfLink]="pdfLink" (onClosePdf)="onClosePdf($event)" [blob]="blob"
  [via]="'policyTemplate'"></custom-pdf-viewer>
<custom-warning-popup *ngIf="showModal" [cloudDocId]="cloudDocId" [documentName]="docNameToDelete"
  (onHideModal)="onHideModal($event)" (onDelete)="deleteDocument($event)"></custom-warning-popup>