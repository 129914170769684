<div class="contact-edit">
    <div class="contracts-tabs-heading">
        <h6 class="title">{{'intermediary_management.general_intermediary_details' | translate}}</h6>
    </div>
    <div class="form right-tab-common-style">
        <form class="general-form product-module-html" id="form" [formGroup]="generalIntermediaryForm"
            *ngIf="generalIntermediaryForm">
            <div class="row">
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label required"
                        [ngClass]="{'label-error' : isSubmitted && generalIntermediaryForm.controls['status'].errors?.required }"
                        >{{'intermediary_management.general_intermediary_details_tab.status'
                        | translate}}</label>
                    <br />
                    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                        [formControlName]="formFields.STATUS"
                        [ngClass]="{'red' : isSubmitted && generalIntermediaryForm.controls['status'].errors?.required }"
                        title="{{intermediaryDataObj?.tooltTips?.status}}" (selectionChange)="getTooltipValue($event.value,'status','statusList')">
                        <input type="text" maxlength="20" class="input searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #search>
                        <mat-option [value]="null" style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}
                        </mat-option>
                        <mat-option [value]="item.id" *ngFor="let item of intermediaryDataObj?.statusList">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label required"
                    [ngClass]="{'label-error' : isSubmitted && generalIntermediaryForm.controls['intermediary_id'].errors?.required }">
                    {{'intermediary_management.general_intermediary_details_tab.intermediary_id'| translate}}
                </label><br />
                <input type="text" class="custom-input-field" maxlength="20"
                    placeholder="{{'intermediary_management.general_intermediary_details_tab.intermediary_id' | translate}}"
                    [formControlName]="formFields.INTERMEDIARY_ID"
                    title="{{generalIntermediaryForm.controls['intermediary_id'].value}}"
                    [ngClass]="{'red' : isSubmitted && generalIntermediaryForm.controls['intermediary_id'].errors?.required }"
                    numbersOnly oninput="if(!this.value.match('^[0-9]*$') || this.value.match('^[+-,eE]*$'))">
                </div>

                <div class="col-md-3 col-sm-3">
                    <label for="" class="label required"
                        [ngClass]="{'label-error' : isSubmitted && generalIntermediaryForm.controls['intermediary_type'].errors?.required }"
                        >{{'intermediary_management.general_intermediary_details_tab.intermediary_type'
                        | translate}}</label>
                    <br />

                    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                        [formControlName]="formFields.INTERMEDIARY_TYPE"
                        [ngClass]="{'red' : isSubmitted && generalIntermediaryForm.controls['intermediary_type'].errors?.required }"
                        title="{{intermediaryDataObj?.tooltTips?.intermediaryType}}" (selectionChange)="getTooltipValue($event.value,'intermediaryType','intermediaryTypes')">
                        <input type="text" maxlength="20" class="input searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #searchIntermediaryType title="{{generalIntermediaryForm.controls['intermediary_type'].value}}">
                        <mat-option [value]="null" style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}
                        </mat-option>
                        <mat-option [value]="item.id" *ngFor="let item of _validationService._filter(intermediaryDataObj?.intermediaryTypes, 'name', searchIntermediaryType.value)">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="col-md-3 col-sm-3">
                    <label for="" class="label required"
                        [ngClass]="{'label-error' : isSubmitted && generalIntermediaryForm.controls['intermediary_name'].errors?.required }"
                        >{{'intermediary_management.general_intermediary_details_tab.intermediary_name'
                        | translate}}</label><br />
                    <input type="text" class="custom-input-field" maxlength="100" placeholder="{{'intermediary_management.general_intermediary_details_tab.intermediary_name'
                    | translate}}" [formControlName]="formFields.INTERMEDIARY_NAME"
                        title="{{generalIntermediaryForm.controls['intermediary_name'].value}}"
                        [ngClass]="{'red' : isSubmitted && generalIntermediaryForm.controls['intermediary_name'].errors?.required }">
                </div>

                <div class="col-md-3 col-sm-3">
                    <label  for="" class="long-label">{{'user-profile.Address_text' |
                        translate}}</label><br>
                    <app-address-autocomplete (address)="getAddress($event)"
                        [control]="generalIntermediaryForm.controls['address']"
                        [placeholder]="'update-user.Adresse_label' | translate">
                    </app-address-autocomplete>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" >{{'user-profile.Straße_text' |
                        translate}}</label><br />
                    <input type="text" class="custom-input-field" maxlength="100"
                        placeholder="{{'user-profile.Straße_text' | translate}}" [formControlName]="formFields.STREET" 
                        title="{{generalIntermediaryForm.controls['street'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" >{{'user-profile.Haus_text' |
                        translate}}</label><br />
                    <input type="text" class="custom-input-field" maxlength="100"
                        placeholder="{{'user-profile.Haus_text' | translate}}"
                        [formControlName]="formFields.HOUSE_NUMBER"
                        title="{{generalIntermediaryForm.controls['house_number'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" >{{'user-profile.Postleitzahl_text' |
                        translate}}</label><br />
                    <input type="text" class="custom-input-field" maxlength="100"
                        placeholder="{{'user-profile.Postleitzahl_text' | translate}}"
                        [formControlName]="formFields.POST_CODE"
                        title="{{generalIntermediaryForm.controls['zipcode'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" >{{'user-profile.Ort_text' |
                        translate}}</label><br />
                    <input type="text" class="custom-input-field" maxlength="100"
                        placeholder="{{'user-profile.Ort_text' | translate}}" [formControlName]="formFields.CITY"
                        title="{{generalIntermediaryForm.controls['city'].value}}">
                </div>

                <div class="col-md-3 col-sm-3">
                    <label for="" class="label required"
                        [ngClass]="{'label-error' : isSubmitted && generalIntermediaryForm.controls['division_id'].errors?.required }"
                        >{{'intermediary_management.general_intermediary_details_tab.division'
                        | translate}}</label>
                    <br />

                    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                        [formControlName]="formFields.DIVISION_ID" panelClass="statusMultiSelectPane"
                        id="status-multiselect" multiple  (selectionChange)="selectDivision($event.value);
                        getTooltipValue($event.value,'division','divisionList')"
                        (closed)="callMainIntermediary()" [ngClass]="{'red' : isSubmitted && generalIntermediaryForm.controls['division_id'].errors?.required }"
                        title="{{intermediaryDataObj?.tooltTips?.division}}" [disabled]="(user_role == userRoles?.IC_BRANCH_ADMIN || user_role == userRoles?.IC_USER)">
                        <input type="text" maxlength="20" class="input searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #searchDivision>
                        <mat-option [value]="item.id" *ngFor="let item of _validationService._filter(intermediaryDataObj?.divisionList, 'name', searchDivision.value)">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="col-md-3 col-sm-3">
                    <label for="" class="label">{{'intermediary_management.general_intermediary_details_tab.main_intermediary' | translate}}</label>
                <br />

                <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                    [formControlName]="formFields.MAIN_INTERMEDIARY"
                    title="{{intermediaryDataObj?.tooltTips?.mainParent}}" (selectionChange)="getTooltipValue($event.value,'mainParent','mainIntermendiaryList')"
                    [ngClass]="{'red' : isSubmitted && generalIntermediaryForm.controls['main_parent_id'].invalid }">
                    <input type="text" maxlength="20" class="input searchSelect"
                        placeholder="{{'header.Suche_tooltip' | translate}}" #searchMainIntermendiary>
                    <mat-option [value]="null" style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}
                    </mat-option>
                    <mat-option [value]="item.id" *ngFor="let item of _validationService._filter(intermediaryDataObj?.mainIntermendiaryList, 'name', searchMainIntermendiary.value)">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                </div>

                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" >
                        {{'intermediary_management.general_intermediary_details_tab.registration_id' |
                        translate}}</label><br />
                    <input type="text" class="custom-input-field" maxlength="100"
                        placeholder="{{'intermediary_management.general_intermediary_details_tab.registration_id' | translate}}"
                        [formControlName]="formFields.REGISTRATION_ID"
                        title="{{generalIntermediaryForm.controls['registration_id'].value}}">
                </div>

                <div class="col-md-3 col-sm-3 dateContainer" id="datepicker2">
                    <label for="" class="label required"
                        [class.label-error]="isSubmitted && generalIntermediaryForm.controls['valid_from'].errors?.required"
                        [ngClass]="_showError.checkInvalidLabel(generalIntermediaryForm, 'valid_from')"
                        >{{'insurer_management.broker_commision_tab.valid_from' |
                        translate}}</label><br />
                    <mat-form-field [ngClass]="_showError.checkInvalid(generalIntermediaryForm, 'valid_from')"
                        [class.red]="isSubmitted && generalIntermediaryForm.controls['valid_from'].errors?.required"
                        class="date-picker" appearance="fill" id="datePicker2">
                        <span>
                            <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                        </span>
                        <input [formControlName]="formFields.VALID_FROM" matInput [matDatepicker]="datepicker2"
                            id="myInput" (keypress)="dateChange($event,'from')" (paste)="dateChange($event,'from')"
                            (dateChange)="dateClicked('form')" class="date-input"
                            autocomplete="off" placeholder="{{'claim-reporting.datePlaceHolder' | translate}}"
                            [max]="generalIntermediaryForm.controls['valid_to'].value"
                            title="{{generalIntermediaryForm.controls['valid_from']?.value}}">
                        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker2">
                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                {{'claims.keyboard-arrow-down_text' | translate}}
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker2></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-md-3 col-sm-3 dateContainer" id="datepicker1">
                    <label for="" class="label" 
                        [ngClass]="_showError.checkInvalidLabel(generalIntermediaryForm, 'valid_to')">{{'insurer_management.broker_commision_tab.valid_to'
                        | translate}}</label><br />
                    <mat-form-field class="date-picker" appearance="fill" id="datePicker1"
                        [ngClass]="_showError.checkInvalid(generalIntermediaryForm, 'valid_to')">
                        <span>
                            <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                        </span>
                        <input [formControlName]="formFields.VALID_TO" matInput [matDatepicker]="datepicker1"
                            id="myInput1" (keypress)="dateChange($event,'to')" (paste)="dateChange($event,'to')"
                            [min]="generalIntermediaryForm.controls['valid_from'].value"
                            (dateChange)="dateClicked('to')" class="date-input" autocomplete="off"
                            placeholder="{{'claim-reporting.datePlaceHolder' | translate}}"
                            title="{{generalIntermediaryForm.controls['valid_to']?.value}}">
                        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker1">
                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                {{'claims.keyboard-arrow-down_text' | translate}}
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker1></mat-datepicker>
                    </mat-form-field>
                </div> 


                <div class="col-md-3 col-sm-3">
                    <label for="" class="label"
                        >{{'master-data.ic_main_contact_lable' | translate}}</label>
                    <br />

                    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                        [formControlName]="formFields.IC_MAIN_CONTACT"
                        title="{{intermediaryDataObj?.tooltTips?.mainContact}}" (selectionChange)="getTooltipValue($event.value,'mainContact','mainContactList')">
                        <input type="text" maxlength="20" class="input searchSelect"
                            placeholder="{{'header.Suche_tooltip' | translate}}" #searchMainContact>
                        <mat-option [value]="null" style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}
                        </mat-option>
                        <mat-option [value]="item.id" *ngFor="let item of _validationService._filter(intermediaryDataObj?.mainContactList, 'name', searchMainContact.value)" [title]="item.name">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" >{{'objectModule.Description_label' |
                        translate}}</label><br />
                    <input type="text" class="custom-input-field" maxlength="100"
                        placeholder="{{'objectModule.Description_label' | translate}}"
                        [formControlName]="formFields.DESCRIPTION"
                        title="{{generalIntermediaryForm.controls['description'].value}}">
                </div>

            </div>
        </form>
    </div>
</div>
<div class="save-container">
     <button class="custom-btn save-btn" *ngIf="generalIntermediaryForm" (click)="saveGeneralData()"
        [disabled]="!isValueChangedInForm">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>