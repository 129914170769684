
import { Pipe, PipeTransform } from '@angular/core';
import { getInitials } from '../utils/get-initials';

@Pipe({
    name: 'initials'
})
export class InitialsPipe implements PipeTransform {
    transform(value: string): string {
        return getInitials(value);
    }
}
