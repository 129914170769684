<app-header></app-header>

<div class="container-ic">
  <div *ngIf="language$ | async as language">
    <div class="external-container">
      <div class="header-container">
        <div class="flex-center">
          <img
            alt=""
            class="head-image"
            src="../../../assets/images/config-icon.svg"
          />&nbsp;&nbsp;
          <span class="head-style">{{
            "customer-dashboard.Dashboard_Konfiguration" | translate
          }}</span>
        </div>
        <div>
          <button
            class="save-button"
            [disabled]="!(isDirty$ | async)"
            (click)="saveConfiguration()"
          >
            <img
              class="save-button__image"
              alt=""
              src="../../../assets/images/save-button.svg"
            />
          </button>
        </div>
      </div>
      <div class="main-container">
        <div
          cdkDropList
          class="widget-list col-md-4 col-lg-4 col-sm-6 left-panel"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
        >
          <div
            class="widget-box"
            *ngFor="let widget of widgets$ | async"
            cdkDrag
          >
            <div class="widget-box__button-wrapper">
              <button
                (click)="setChosenWidget(widget)"
                [ngClass]="{
                  'single-widget--chosen': isWidgetChosen(widget.id)
                }"
                class="single-widget"
              >
                <img
                  alt=""
                  class="drag-btn"
                  src="../../../assets/images/dragAndDrop.png"
                />
                <span class="single-widget-span">
                  {{
                    language === "German(DE)"
                      ? widget.widgetNameDE
                      : widget.widgetName
                  }}
                </span>
                <mat-slide-toggle
                  [checked]="widget.isSubscribed"
                  (click)="$event.stopPropagation()"
                  (change)="changeState(widget)"
                  class="toggle-button"
                >
                </mat-slide-toggle>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-8 col-sm-6 right-panel">
          <div *ngIf="chosenWidget$ | async as chosenWidget">
            <div class="right-panel-header">
              <span class="fontStyle">{{
                language === "German(DE)"
                  ? chosenWidget.widgetNameDE
                  : chosenWidget.widgetName
              }}</span>
              <button class="desc-button" (click)="changeState(chosenWidget)">
                {{
                  (chosenWidget.isSubscribed
                    ? "dashboard-configuration.unsubscribe"
                    : "dashboard-configuration.subscribe"
                  ) | translate
                }}
              </button>
            </div>
            <div class="right-panel-content">
              <div class="w-40">
                <img
                  class="right-panel-content__image"
                  alt=""
                  [src]="
                    language === 'German(DE)'
                      ? chosenWidget.imgDE || chosenWidget.imgEN
                      : chosenWidget.imgEN
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
