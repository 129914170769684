<div class="contact-edit">
    <div class="contracts-tabs-heading">
        <h6 style="font-weight: 600;">{{'customer-management.contact_person.contact_person' | translate}}</h6>
    </div>
    <div class="form">
        <form class="general-form object-module-html" id="form" [formGroup]="contactPersonForm" *ngIf="contactPersonForm">
            <div class="row">
                <div class="col-md-3 col-sm-3 flex-center" >                    
                    <div *ngIf="locale$ | async as locale" class="smallInput" style="width: 35%;">
                        <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.salutation' | translate}}</label><br/>
                        <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                         [formControlName]="formFieldsName.SALUTATION">
                        <mat-option [value]="null"  style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                        <mat-option [value]="salutation.salutation_id" *ngFor="let salutation of salutations">
                            <span>{{locale == localsConstant.ENGLISH ? 
                                salutation.salutation_text_en : 
                                salutation.salutation_text_de}}
                            </span>
                        </mat-option>
                        </mat-select>
                    </div>
                    <div class="smallInput" style="width:65%;margin-left: 5px;">
                        <label for="" *ngIf="!contactPersonForm.get(formFieldsName.FIRST_NAME).hasError(errorConstant.NAME_ERROR)" class="label" >
                            {{'customer-management.contact_person.first_name' | translate}}
                        </label>
                        <label for="" *ngIf="contactPersonForm.get(formFieldsName.FIRST_NAME).hasError(errorConstant.NAME_ERROR)" class="label label-error" 
                            title="{{'update-user.Vorname_validation' | translate}}">
                            {{'update-user.Vorname_validation' | translate}}
                        </label>
                        <input type="text" class="custom-input-field" maxlength="100" 
                            placeholder="{{'customer-management.contact_person.first_name' | translate}}"
                            [formControlName]="formFieldsName.FIRST_NAME" title="{{contactPersonForm.controls[formFieldsName.FIRST_NAME].value}}"
                            (blur)="validateName($event.target.value, formFieldsName.FIRST_NAME)">
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" *ngIf="!contactPersonForm.get(formFieldsName.LAST_NAME).hasError(errorConstant.NAME_ERROR)" class="label">
                        {{'customer-management.contact_person.last_name' | translate}}
                    </label>
                    <label for="" *ngIf="contactPersonForm.get(formFieldsName.LAST_NAME).hasError(errorConstant.NAME_ERROR)" class="label label-error" 
                        title="{{'update-user.Nachname_validation' | translate}}">
                        {{'update-user.Nachname_validation' | translate}}
                    </label>
                    <input type="text" class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.last_name' | translate}}"
                        [formControlName]="formFieldsName.LAST_NAME" title="{{contactPersonForm.controls[formFieldsName.LAST_NAME].value}}"
                        (blur)="validateName($event.target.value, formFieldsName.LAST_NAME)">
                </div> 
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.role' | translate}}</label><br/>
                        <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" 
                            [formControlName]="formFieldsName.ROLE"
                            (selectionChange)="roleChanged($event.value, true)">
                            <input type="text" maxlength="20" class="input searchSelect" preventSpace
                            placeholder="{{'header.Suche_tooltip' | translate}}" #search>
                            <mat-option [value]="null" style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                            <mat-option [value]="role.id" *ngFor="let role of _validationService._filter(customer_roles, 'desc', search.value) ">
                                {{role.desc}}
                            </mat-option>
                        </mat-select>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" *ngIf="!contactPersonForm.get(formFieldsName.RECEIVABLE_AMOUNT).hasError(errorConstant.OVER_BILLION_ERROR)" class="label">{{'new-claims.contact_person.receivable_amount' | translate}}</label>
                    <label for="" *ngIf="contactPersonForm.get(formFieldsName.RECEIVABLE_AMOUNT).hasError(errorConstant.OVER_BILLION_ERROR)" 
                        title="{{'new-claims.contact_person.receivable_amount' | translate}} : {{'objectModule.limitError' | translate}}" class="label label-error">
                        {{'new-claims.contact_person.receivable_amount' | translate}} : {{'objectModule.limitError' | translate}}
                    </label>
                    <br/>
                    <div class="smallInput" style="width: 100%;">
                        <div class="d-flex">
                          <mat-select class="custom-currency-dropdown" 
                            (selectionChange)="currancyChanged($event.value)" 
                            [value]="currencyId" title="{{selectedCurrency}}">
                            <mat-select-trigger>
                              <span>{{ currancySymbol }}</span>
                            </mat-select-trigger>
                            <input type="text" class="input searchYear" preventSpace maxlength="5" autocomplete="off"
                              placeholder="{{'header.Suche_tooltip' | translate}}" #currency>
                            <mat-option *ngFor="let ele of _validationService._filter(currencyTypes, 'cur_symbol', currency.value)" [value]="ele.id" matTooltip="{{ele.cur_symbol}}">
                              <span class="d-flex">
                                <span class="currency-symbol">{{ele.cur_code}}</span>
                                <span>{{ele.cur_symbol}}</span>
                              </span>
                            </mat-option>
                          </mat-select>
                            <input class="custom-input-field currencyValueInput"
                            [formControlName]="formFieldsName.RECEIVABLE_AMOUNT"
                            placeholder="{{'new-claims.contact_person.receivable_amount' | translate}}"
                            GermanFormat BillionCheck>
                        </div>
                      </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <label for="" class="label" style="text-transform: initial;">{{'new-claims.contact_person.claim_description' | translate}}</label><br/>
                    <textarea class="textArea" maxlength="5000" [formControlName]="formFieldsName.CLAIM_DESCRIPTION"
                        placeholder="{{'new-claims.contact_person.claim_description' | translate}}">
                    </textarea>
                </div>
                <div class="col-md-6 col-sm-6">
                    <label for="" class="label" style="text-transform: initial;">{{'new-claims.contact_person.remark' | translate}}</label><br/>
                    <textarea class="textArea" maxlength="5000" [formControlName]="formFieldsName.REMARK"
                        placeholder="{{'new-claims.contact_person.remark' | translate}}">
                    </textarea> 
                </div>
            </div>
        </form>
    </div>    
</div>
<div class="save-container" *ngIf="contactPersonForm">
    <button class="custom-btn save-btn" 
        (click)="save()" [disabled]="isIvmSynced || !contactPersonForm.valid || !isValueChangedInForm">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>
<app-warning-popup *ngIf="showWarningPopUp" 
    [customerWarningMsg]="customerWarningMsg" 
    (savedata)="saveContactPerson($event)" 
    (handlePopup)="handleWarningPopup($event)" 
    [disableSave]="!contactPersonForm.valid || !isValueChangedInForm">
</app-warning-popup>
