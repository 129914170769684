<!-- button-process new-group-button -->
<div style="padding: 0px 10px">
    <div class="flex-end-align">
        <button class="custom-btn save-btn add-new-feature-btn" *ngIf="isNewGroupBtn" (click)="addNewSparte()">
            <img alt="" [src]="FORM_ICONS.ADD">
            <span>{{'settings.newGroupButtonText' | translate}}</span>
        </button>
    </div>
    <div class="filter-distance" id="spateManagementFilter">
        <mat-accordion>
            <mat-expansion-panel (opened)="customerFilterToggle = true" (closed)="customerFilterToggle = false" [expanded]="customerFilterToggle" hideToggle>
                <mat-expansion-panel-header #panelH class="filter-section-header">
                    <mat-panel-title style="align-items: center;">
                        <button mat-icon-button *ngIf="customerFilterToggle == true">
                            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="customerFilterToggle == false">
                            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                        </button>
                        <span style="margin-top: 3px;">
                            <span> &nbsp; <strong>FILTER </strong></span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
    
                <ng-container style="margin-top: 15px;">
                    <form name="search" [formGroup]="formFilter" style="font-size: 13px;
                  color: #4e4e4e;">
                        <div style="clear:both;padding:0">
                            <div class="row">
                                <div class="col-md-3" style="display: flex;">
                                    <span class="span-label">{{'rcs-setup.division-text' | translate}}</span>
                                    <mat-select formControlName="division_id" placeholder="{{'contact-manager.Mandant_placeholder' | translate}}" (selectionChange)="search.value = ''" (openedChange)="search.value = ''">
                                      <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" preventSpace #search>
                                      <mat-option *ngFor="let division of validationService._filter(divisions, 'division_name', search.value)" [value]="division.division_id">
                                            {{division.division_name}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-3" style="display: flex;">
                                    <span class="span-label">{{'rcs-setup.sparte-text' | translate}}</span>
                                    <mat-select formControlName="sparte_id" placeholder="{{'contact-manager.Section_placeholder' | translate}}" (selectionChange)="searchSparte.value = ''" (openedChange)="searchSparte.value = ''">
                                      <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" preventSpace #searchSparte>
                                      <mat-option *ngFor="let sparte of validationService._filter(spartes, 'description', searchSparte.value)" [value]="sparte.sparte_id">
                                            {{sparte.description}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-md-3" style="display: flex;">
                                </div>
                                <div class="col-md-3" style="display: flex;">
                                    <button class="filterBtn" (click)="filterSparte()" style="margin-left: auto;margin-right: 8px;">
                                        {{'user-data.Filter_text' | translate}}
                                    </button>
                                    <button class="filterBtn tooltipResetFilter" (click)="resetFilters()"><img alt="" src="../../../assets/images/Icon material-refresh.png" style="height: 16px;"><span class="tooltiptextResetFilter"> {{'new-document.Filter_löschen' | translate}}</span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="contact-manager">
        <div>
            <div class="table-container sparte-table" style="clear:both">
                <table mat-table [dataSource]="dataSource" class="table-responsive table-contact-manager">
                    <ng-container matColumnDef="Division">
                        <th style="width: 50%;" mat-header-cell *matHeaderCellDef><span> {{'rcs-setup.DIVISON_text' | translate}}</span>
                        </th>
                        <td style="
                        font-size: 12px;font-weight:600;padding-left: 30px" mat-cell *matCellDef="let element">
                            <span>{{element.div_name}}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="GroupName">
                        <th style="width: 50%;" mat-header-cell *matHeaderCellDef><span> {{'settings.group_name' | translate}}</span>
                        </th>
                        <td style="
                            font-size: 12px;font-weight:600;" mat-cell *matCellDef="let element">
                            <span>{{element.groupName}}</span> </td>
                    </ng-container>
                    <ng-container matColumnDef="ACTION">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> <span>{{'user-data.AKTION_text' |
                        translate}} </span></th>
                        <td mat-cell style="text-align: center;" *matCellDef="let element">
                            <div class="control-section" style="position: initial;">
                                <div class="subscribe" style="position: initial;">
                                    <button class="myDIV" id="content" style="position: initial;">&#183;&nbsp;&#183;&nbsp;&#183;</button>
                                    <div class="hide democontent" style="position: initial;
                            margin-top: -18px;
                            text-align: center;    padding: 0 21px;">
                                        <button style="width:24px" [disabled]="element.is_update == false" type="button" class="edit tool3" (click)="editProcess(element.id)" mat-icon-button>
                                        <mat-icon><img alt="" style="width: 100%;height: 100%;" class="imgStyle" src='../../../assets/images/Group 2659.svg'></mat-icon><span *ngIf="element.is_update == true" class="tooltiptext3">{{'user-data.BEARBEITEN_tooltip' | translate}}</span>
                                        <span class="tooltiptextupdate" *ngIf="element.is_update == false">{{'settings.unauthorised' | translate}}</span>
                                        </button>&nbsp;&nbsp;
                                        <button [disabled]="element.is_delete == false || element.is_removable == false" type="button" class="delete tool2" (click)="deleteProcessButton(element.id)" mat-icon-button style="width:24px">
                              <mat-icon><img alt="" style="height:100%;width: 100%;" class="imgStyle"
                                src="../../../assets/images/Group 2658.svg"></mat-icon><span
                                class="tooltiptext2" *ngIf="element.is_delete == true && element.is_removable == true ">{{'user-data.Löschen_tooltip' | translate}}</span>
                                <span
                                class="tooltiptextdelete" *ngIf="element.is_delete == false && element.is_removable == true">{{'settings.unauthorised' | translate}}</span><span
                                class="tooltiptextremovable" *ngIf="element.is_removable == false">{{'settings.inUsed' | translate}}</span>
                            </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal modelBackground" [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content" style="border-radius: 8px;
  box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
  border: solid 1px #bbb;
  background-color: #fff;">
            <!-- Modal Header -->
            <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                    <mat-icon (click)="hideModal()">
                        <img alt="" height="65px" src="../../../assets/images/Group 3235.svg" >
                    </mat-icon>
                </div>
            </div>
            <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
                <span style="
 font-size: 18px;
 font-weight: 600;
 color: #272727;"> {{'settings.DeleteRecordText' | translate}} {{sparteNumber}} {{'settings.Spartetext' | translate}}!! <br/>
   {{'contact-manager.Löschen_text' | translate}}?</span>
            </div>
            <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                <button type="button" style="font-size: 15px;color: #272727;border-radius: 2px;padding: 0px 22px 0px 22px;
      border: solid 1px #272727;font-weight: 600;" mat-button (click)="hideModal()">
        {{'contact-manager.NEIN_text' | translate}}</button>
                <button class="ml-2" type="button" style="font-size: 15px;color: #272727;background: #ff9000;border-radius: 2px;padding: 0px 22px 0px 22px;font-weight: 600;border: solid 1px #ff9000;" (click)="deleteGroup(deleteItemId)" mat-button> {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
            </div>
        </div>
    </div>
</div>
