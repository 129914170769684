<h6 class="customer-management-page-heading">{{'customer-management.left-panel.bank-account' | translate}}</h6>
<div class="table-outer-container insured-object-section">
    <div class="infiniteScrollDiv">
        <mat-table class="custom-table" [dataSource]="dataSource" cellpadding="5" cellspacing="0" matSort>
            <ng-container matColumnDef="bank">
                <mat-header-cell *matHeaderCellDef>
                    <span>BANK</span>
                </mat-header-cell>
                <mat-cell class="custom-checkbox-container" *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.bank_name}}">
                        {{element.bank_name}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="iban">
                <mat-header-cell *matHeaderCellDef>
                    <span>IBAN</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.iban}}">
                        {{element.iban}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="bic">
                <mat-header-cell *matHeaderCellDef>
                    <span>BIC</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.bic}}">
                        {{element.bic}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="valid_from">
                <mat-header-cell *matHeaderCellDef>
                    <span>{{'customer-management.bank_details.sepa_mandate_valid_from' | translate | uppercase}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span class="custom-ellipsis noSafariTooltip" matTooltip="{{element.valid_from | dateFormat}}">
                        {{element.valid_from | dateFormat}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sepa_mandant">
                <mat-header-cell *matHeaderCellDef>
                    <span>SEPA MANDANT</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="flex-center">
                        <mat-checkbox class="custom-checkBox check1 mr-3" [checked]="element.sepa_mandate" disabled>
                        </mat-checkbox>
                        <button
                          [disabled]="!(isEditCustomer$ | async) || !element.sepa_mandate"
                          class="upload-download-btn"
                          (click)="showDocument(element.bank_account_detail_id)"
                        >
                            <img alt="" class="rotate-180" src="assets/images/common/upload_download.svg">
                        </button>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="action-header">
                    <span>{{'customer-management.bank_details.action' | translate}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="control-section" style="text-align: center;">
                        <div class="subscribe">
                            <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                            <div class="hide">
                                <div class="action-btn-align">
                                    <button
                                      class="action-btn"
                                      placement="top"
                                      ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                                      tooltipClass="generic-tooltip"
                                      (click)="edit(element.bank_account_detail_id)"
                                      [disabled]="!(isEditCustomer$ | async)"
                                    >
                                        <img alt="" [src]="actionButton.EDIT_WO_BACKGROUND">
                                    </button>
                                    <button
                                      class="action-btn"
                                      placement="top"
                                      ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                                      tooltipClass="generic-tooltip"
                                      (click)="openModal(element.bank_account_detail_id)"
                                      [disabled]="!(isEditCustomer$ | async)"
                                    >
                                        <img alt="" [src]="actionButton.DELETE_WO_BACKGROUND">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>

<div class="modal modelBackground" [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog spacing" >
      <div class="modal-content p-3">
        <div class="d-flex header-content p-unset">
            <div class="pointer">
                <img alt="" (click)="hideModal()" height="65px" src="assets/images/Group 3235.svg" >
            </div>
        </div>
        <div class="modal-body body-text">
            <span class="delete-heading">{{'objectModule.Delete_popup_heading' | translate}}</span>
            <br/><br/>
            <span class="delete-text">{{'objectModule.Delete_popup_text' | translate}}</span>
        </div>
        <div class="modal-footer ml-auto mr-auto">
            <button (click)="hideModal()" class="custom-btn cancel-btn">
                {{'contact-manager.NEIN_text' | translate}}</button>
            <button class="ml-2 custom-btn save-btn" (click)="deleteBank(delete_item_id)">
                {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
        </div>
      </div>
    </div>
</div>
<app-upload-document [object_type]="uploadDocumentDetails"
[selectedDocumentsArray]="selectedDocumentsArray" [is_disable]="true"
[showDocumentPopup]="showDocumentPopup" (hide)="hidePopup()">
</app-upload-document>
