<mat-dialog-content style="margin: -24px -24px 0px -24px;" id="update" *ngIf="null_user == false">

    <div class="update-heading" *ngIf="newUser == false" style="border-bottom: 1px solid #d5d5d5;">
        <h2 matDialogTitle style="float: left;
      width: 50%;
      font-size: 17px;color: #272727;;margin-bottom:2px" class="mat-headline center-align">{{title | multilingual |
            translate}}
        </h2>
        <button style="float: right;
      color: #272727;
      margin-top: -2px;border: none;background: transparent;" (click)="onNoClick()">
            <mat-icon>
                <img alt="" style="vertical-align: sub;" src="../../../../assets/images/Group 3298.svg">
            </mat-icon>
        </button>
    </div>

    <div class="update-heading" *ngIf="newUser==true"
        style="background: none;padding: 7px 10px 20px 23px; border-bottom: 2px solid rgba(0, 0, 0, 0.1);">
        <h2 matDialogTitle style="float: left;width: 50%;font-size: 17px;" class="mat-headline center-align">
            {{'update-user.invite_new_user' | translate}}
        </h2>
        <button style="float: right; color:black;margin-top: -2px; border: none; outline: none;background: transparent;"
            (click)="onNoClick()" [autofocus]="false">
            <img alt="" style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
        </button>
        <br>
    </div>

    <div class="row-height d-flex flex-row">
        <div class="flex-fill">
            <div style="display: flex; float: left; width: 49%;">
                <mat-form-field [ngClass]="{'red': salutation === true}" appearance="fill"
                    style="width: 20%; line-height: 0.7;margin-right: 9px;    padding: 4px 0px 0px 0px;">
                    <mat-label>
                        <span *ngIf="salutation == false && salutationModel.untouched">{{ 'update-user.title_label' |
                            translate
                            }}</span>
                        <span style="color:#FF1010;letter-spacing: -0.3px;"
                            *ngIf="salutation == true && salutationModel.untouched">{{
                            'update-user.title_label' | translate }}</span>
                        <span *ngIf="salutationModel.touched">
                            <span *ngIf="salutationModel.valid">{{ 'update-user.title_label' | translate }}</span>
                            <span style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="salutationModel.invalid">{{
                                'update-user.title_label' | translate }}</span></span>
                    </mat-label>
                    <mat-select [(ngModel)]="data.salutation" #salutationModel="ngModel"
                        placeholder="{{ 'update-user.Frau_text' | translate }}" required
                        (selectionChange)="salutationSelection()">
                        <mat-option value="{{'update-user.Frau_text' | translate }}">{{ 'update-user.Frau_text' |
                            translate }}
                        </mat-option>
                        <mat-option value="{{'update-user.Herr_text' | translate}}">{{ 'update-user.Herr_text' |
                            translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field [ngClass]="{'red': firstname === true}" appearance="fill"
                    style="width: 80%;    padding: 4px 0px 0px 0px;">
                    <mat-label><span *ngIf="firstname == false">{{ 'update-user.Vorname_label' | translate }}</span>
                        <span style="color:#FF1010;letter-spacing: -0.3px;"
                            *ngIf="firstname == true">{{Vorname_validation | multilingual |
                            translate}}</span>
                    </mat-label>
                    <input (blur)="vornameOnly($event.target.value)" class="input" required matInput
                        [(ngModel)]="data.first_name">
                </mat-form-field>
            </div>
            <mat-form-field [ngClass]="{'red': lastname === true}" appearance="fill"
                style="width: 49%;float:right;    padding: 4px 0px 0px 0px;">
                <mat-label><span *ngIf="lastname == false">{{ 'update-user.Nachname_label' | translate }}</span><span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="lastname == true">{{Nachname_validation |
                        multilingual |
                        translate}}</span></mat-label>
                <input matInput (blur)="nachnameOnly($event.target.value)" class="input" required
                    [(ngModel)]="data.last_name">
            </mat-form-field>
        </div>
    </div>

    <div class="row-height d-flex flex-row">
        <div class="flex-fill">
            <mat-form-field appearance="fill" class="tel" style="width: 49%;float:left;border:none;">
                <mat-label><span>{{'update-user.Mobiltelefonnummer_label' | translate }}</span>
                </mat-label>
                <ngx-mat-intl-tel-input [ngClass]="'ngx-floating'" (countryChanged)="CountryChangedEvent($event)"
                    class="input floating" [preferredCountries]="['de']" (keyup)="numbersOnlytelefon($event)"
                    [enablePlaceholder]="true" [(ngModel)]="data.Telefon" #telefon="ngModel" name="tele"
                    [enableSearch]="true" #phone (change)="mobileChange(data.Telefon)">
                </ngx-mat-intl-tel-input>
            </mat-form-field>
            <mat-form-field [ngClass]="{'red': email === true , 'redcolor' : emailunique === true}" appearance="fill"
                style="width: 49%;float:right;    padding: 4px 0px 0px 0px;">
                <mat-label><span *ngIf="email == false && emailunique == false">{{ 'update-user.Email_label' | translate
                        }}</span><span style="color:#FF1010;letter-spacing: -0.3px;"
                        *ngIf="email == true && emailunique == false">{{email_validation | multilingual |
                        translate}}</span>
                    <span style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="emailunique == true && email == false">
                        {{'user-profile.emailExists_validation' | translate}}
                    </span>
                </mat-label>
                <input matInput type="email" [disabled]="emailDisable == true" placeholder=""
                    (blur)="emailOnly($event.target.value)" class="input" required [(ngModel)]="data.email">
            </mat-form-field>
        </div>
    </div>

    <div class="row-height  d-flex flex-row">
        <div class="flex-fill">
            <mat-form-field appearance="fill" *ngIf="data.newUser==true"
                style="width: 49%;float:left;padding: 4px 0px 0px 0px;">
                <mat-label>{{'update-user.customers_label' | translate }}</mat-label>
                <input matInput placeholder="" class="input" required matInput [(ngModel)]="mutter"
                    [value]="mutter + 'Hello'" [disabled]="data.newUser==true">
            </mat-form-field>

            <mat-form-field [ngClass]="{'red': customer === true}" class="role" appearance="fill"
                *ngIf="data.newUser==true"
                style="width: 49%;float:right;line-height: 0.8;height:42px;    padding: 4px 0px 0px 0px;">
                <mat-label>
                    <span *ngIf="customer == false && customerModel.untouched">{{'update-user.subsidiary_customer_label'
                        |
                        translate}}</span>
                    <span style="color:#FF1010;letter-spacing: -0.3px;"
                        *ngIf="customer == true && customerModel.untouched">{{'update-user.subsidiary_customer_label' |
                        translate}}</span>
                    <span *ngIf="customerModel.touched">
                        <span *ngIf="customerModel.valid">{{'update-user.subsidiary_customer_label' | translate}}</span>
                        <span style="color:#FF1010;letter-spacing: -0.3px;"
                            *ngIf="customerModel.invalid">{{'update-user.subsidiary_customer_label' | translate}}</span>
                    </span>
                </mat-label>
                <mat-select [(ngModel)]="data.customer_id" #customerModel="ngModel" required
                    (selectionChange)="customerChange($event.value)">
                    <mat-option *ngFor="let customer of customers" [value]="customer.id" style="font-size: 14px;">
                        {{customer.name}} - {{customer.type | uppercase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="data.newUser==false"
                style="width: 49%;float:left;padding: 4px 0px 0px 0px;">
                <mat-label>{{ 'update-user.customers_label' | translate }}</mat-label>
                <input matInput placeholder="" class="input" required matInput [(ngModel)]="data.customer_name"
                    [disabled]="newUser==false">
            </mat-form-field>

            <mat-form-field appearance="fill" style="width: 49%;float:right;padding: 4px 0px 0px 0px;"
                *ngIf="data.newUser==false">
                <mat-label>{{ 'update-user.Division_label' | translate }}</mat-label>
                <input matInput placeholder="" class="input" required matInput [(ngModel)]="data.division" disabled>
            </mat-form-field>
        </div>
    </div>

    <div class="row-height  d-flex flex-row">
        <div class="flex-fill">
            <mat-form-field class="role" appearance="fill" *ngIf="data.newUser == true"
                style="width: 49%;float:left;line-height: 0.8;height:42px;    padding: 4px 0px 0px 0px;">
                <mat-label>{{ 'update-user.Benutzerrolle_label' | translate }}</mat-label>
                <mat-select [(ngModel)]="data.role">
                    <mat-option *ngFor="let role of User_role_list" [value]="role.user_role_id">
                        {{role.user_role_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="newUser ==true" class="language" appearance="fill"
                style="width: 49%;float:right;line-height: 0.8;height:42px;padding: 4px 0px 0px 0px;">
                <mat-label>{{ 'update-user.Sprache_label' | translate }}</mat-label>
                <mat-select [(ngModel)]="data.invitation_language"
                    [ngClass]="data.invitation_language == 'German(DE)' ? 'german' : 'english'">
                    <mat-option *ngFor="let language of supportedLanguages" [value]="language">
                        <span *ngIf="language == 'German(DE)'" class="german"><span
                                style="margin-left:36px;font-size: 15px;">{{language}}</span></span>
                        <span *ngIf="language == 'English(EN)'" class="english"><span
                                style="margin-left:36px;font-size: 15px;">{{language}}</span></span>

                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="role" appearance="fill" *ngIf="data.newUser == false"
                style="width: 49%;float:left;line-height: 0.8;height:42px;    padding: 4px 0px 0px 0px;">
                <mat-label>{{ 'update-user.Benutzerrolle_label' | translate }}</mat-label>
                <mat-select [(ngModel)]="data.role">
                    <mat-option *ngFor="let role of User_role_list" [value]="role.user_role_id">
                        {{role.user_role_name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="newUser ==false" class="language" appearance="fill"
                style="width: 49%;float:right;line-height: 0.8;height:42px;padding: 4px 0px 0px 0px;">
                <mat-label>{{ 'update-user.Sprache_label' | translate }}</mat-label>
                <mat-select [(ngModel)]="data.invitation_language"
                    [ngClass]="data.invitation_language == 'German(DE)' ? 'german' : 'english'"
                    [disabled]="langDisable == true">
                    <mat-option *ngFor="let language of data.supported_languages" [value]="language">
                        <span *ngIf="language == 'German(DE)'" class="german"><span
                                style="margin-left:35px;font-size: 15px;">{{language}}</span></span>
                        <span *ngIf="language == 'English(EN)'" class="english"><span
                                style="margin-left:35px;font-size: 15px;">{{language}}</span></span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row-height  d-flex flex-row">
        <div class="flex-fill">
            <mat-form-field [ngClass]="{'red': street === true}" appearance="fill" *ngIf="data.newUser == true"
                style="width: 49%;float:left;padding: 4px 0px 0px 0px;">
                <mat-label><span *ngIf="street == false">{{ 'update-user.Straße_label' | translate }}</span><span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="street == true">{{strabe | multilingual |
                        translate}}</span></mat-label>
                <input matInput (blur)="charactersNumbersOnlystreet($event.target.value)" class="input" required
                    [(ngModel)]="data.street" (change)="onaddressChange()" [disabled]="customerRoles">
            </mat-form-field>
            <mat-form-field [ngClass]="{'red': house === true}" appearance="fill" *ngIf="data.newUser == true"
                          style="width: 49%;float:right;padding: 4px 0px 0px 0px;">
            <mat-label><span *ngIf="house == false">{{ 'update-user.Hausnummer_label' | translate }}</span><span
              style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="house == true">{{Hausnummer_validation |
              multilingual |
              translate}}</span></mat-label>
            <input (blur)="charactersNumbersOnlyhouse($event.target.value)" class="input" required matInput
                   [(ngModel)]="data.house" [disabled]="customerRoles">
          </mat-form-field>
            <mat-form-field [ngClass]="{'red': street === true}" appearance="fill" *ngIf="data.newUser == false"
                style="width: 49%;float:left;    padding: 4px 0px 0px 0px;">
                <mat-label><span *ngIf="street == false">{{ 'update-user.Straße_label' | translate }}</span><span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="street == true">{{strabe | multilingual |
                        translate}}</span></mat-label>
                <input matInput (blur)="charactersNumbersOnlystreet($event.target.value)" class="input" required
                    [(ngModel)]="data.street" (change)="onaddressChange()" [disabled]="customerRoles">
            </mat-form-field>
          <mat-form-field [ngClass]="{'red': house === true}" appearance="fill" *ngIf="data.newUser == false"
                          style="width: 49%;float:right;    padding: 4px 0px 0px 0px;">
            <mat-label><span *ngIf="house == false">{{ 'update-user.Hausnummer_label' | translate }}</span><span
              style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="house == true">{{Hausnummer_validation |
              multilingual |
              translate}}</span></mat-label>
            <input (blur)="charactersNumbersOnlyhouse($event.target.value)" class="input" required matInput
                   [(ngModel)]="data.house" [disabled]="customerRoles">
          </mat-form-field>
        </div>
    </div>

    <div class="row-height  d-flex flex-row">
        <div class="flex-fill">
            <mat-form-field [ngClass]="{'red': zip === true}" appearance="fill" *ngIf="data.newUser == true"
                style="width: 49%;float:left;padding: 4px 0px 0px 0px;">
                <mat-label><span *ngIf="zip == false">{{ 'update-user.Postleitzahl_label' | translate }}</span><span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="zip == true">{{Postleitzahl_validation |
                        multilingual |
                        translate}}</span></mat-label>
                <input (blur)="numbersOnlyzip($event.target.value,data.country)" class="input" required matInput
                    [(ngModel)]="data.zip_code" (change)="onaddressChange()" [disabled]="customerRoles">
            </mat-form-field>
          <mat-form-field [ngClass]="{'red':city === true}" appearance="fill" *ngIf="data.newUser == true"
                          style="width: 49%;float:right;    padding: 4px 0px 0px 0px;">
            <mat-label><span *ngIf="city == false">{{ 'update-user.Stadt_label' | translate }}</span> <span
              style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="city == true">{{Stadt_validation |
              multilingual |
              translate}}</span></mat-label>
            <input (blur)="charactersOnlycity($event.target.value)" class="input" required matInput
                   [(ngModel)]="data.city" (change)="onaddressChange()" [disabled]="customerRoles">
          </mat-form-field>
            <mat-form-field [ngClass]="{'red': zip === true}" appearance="fill" *ngIf="data.newUser == false"
                style="width: 49%;float:left; padding: 4px 0px 0px 0px;">
                <mat-label><span *ngIf="zip == false">{{ 'update-user.Postleitzahl_label' | translate }}</span><span
                        style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="zip == true">{{Postleitzahl_validation |
                        multilingual |
                        translate}}</span></mat-label>
                <input (blur)="numbersOnlyzip($event.target.value,data.country)" class="input" required matInput
                    [(ngModel)]="data.zip_code" (change)="onaddressChange()" [disabled]="customerRoles">
            </mat-form-field>
          <mat-form-field [ngClass]="{'red':city === true}" appearance="fill" *ngIf="data.newUser == false"
                          style="width: 49%;float:right;    padding: 4px 0px 0px 0px;">
            <mat-label><span *ngIf="city == false">{{ 'update-user.Stadt_label' | translate }}</span> <span
              style="color:#FF1010;letter-spacing: -0.3px;" *ngIf="city == true">{{Stadt_validation |
              multilingual |
              translate}}</span></mat-label>
            <input (blur)="charactersOnlycity($event.target.value)" class="input" required matInput
                   [(ngModel)]="data.city" (change)="onaddressChange()" [disabled]="customerRoles">
          </mat-form-field>
        </div>
    </div>

    <div class="row-height  d-flex flex-row">
        <div class="flex-fill">
            <mat-form-field class="country" appearance="fill" style="width: 49%;float:left;padding: 4px 0px 0px 0px;"
                *ngIf="data.newUser == false">
                <mat-label>{{ 'update-user.Land_label' | translate }}</mat-label>
                <mat-select (selectionChange)="onCountrySelected($event.value)" [(ngModel)]="data.country"
                    (blur)="charactersOnlycountry($event.target.value)" (selectionChange)="onaddressChange()"
                    [disabled]="customerRoles">
                    <mat-option class="con-option" *ngFor="let country of countries" [value]='country.countryName'>
                        {{country.countryName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="country" appearance="fill" style="width: 49%;float:left;padding: 4px 0px 0px 0px;"
                *ngIf="data.newUser == true">
                <mat-label>{{ 'update-user.Land_label' | translate }}</mat-label>
                <mat-select (selectionChange)="onCountrySelected($event.value)" [(ngModel)]="data.country"
                    (blur)="charactersOnlycountry($event.target.value)" (selectionChange)="onaddressChange()"
                    [disabled]="customerRoles">
                    <mat-option class="con-option" *ngFor="let country of countries" [value]='country.countryName'>
                        {{country.countryName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row-height  d-flex flex-row">
        <div class="flex-fill">
            <mat-form-field *ngIf="data.is_source_ivm == true" class="country" appearance="fill"
                style="width: 49%;float:left; padding: 4px 0px 0px 0px;">
                <mat-label>{{'update-user.OTP_label' | translate}}</mat-label>
                <mat-select [(ngModel)]="data.otpTo" [disabled]="resetUser || data.Telefon == '' ? true : false">
                    <mat-option class="con-option" *ngFor="let otpOption of otpTo" [value]='otpOption'>
                        {{otpOption}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <section style="width: 49%;float:right;font-size: 12px;"
                *ngIf=" data.is_source_ivm == true && !customerRoles || (data.newUser === false && data.is_source_ivm == false)">
                <mat-checkbox [disabled]="disable == true ? 'disabled' : null"
                    style="float:left;width:32%;padding-left: 2px;color:rgb(116 106 106);margin-top: 7px;font-size: 13px;"
                    [(ngModel)]="data.is_vip">
                    {{'update-user.vipKunde_info' | translate}}
                </mat-checkbox>
            </section>

            <mat-form-field *ngIf="data.newUser === true" class="country" appearance="fill"
                style="width: 49%;float:left; padding: 4px 0px 0px 0px;">
                <mat-label>{{'update-user.OTP_label' | translate}}</mat-label>
                <mat-select [(ngModel)]="data.otpTo" [disabled]="data.Telefon == '' ? true : false">
                    <mat-option class="con-option" *ngFor="let otpOption of otpTo" [value]='otpOption'>
                        {{otpOption}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row-height  d-flex flex-row"
        *ngIf="(data.newUser && user_role === userRoles.CUSTOMER_ADMIN && customer_type === CUSTOMER_TYPES.MUTTER)">
        <div class="flex-fill">
            <section style="width: 100%;float:right;font-size: 12px; margin-top: 0px;" id="checkbox">
                <mat-checkbox [disabled]="disable == true ? 'disabled' : null" class="groupCheckbox"
                    [(ngModel)]="data.is_group" (change)="isGroupChange(data.is_group)">
                    {{'invite-user.Gruppenauswahl_text' | translate}}
                </mat-checkbox>
            </section>
        </div>
    </div>
    <div class="row-height  d-flex flex-row"
        *ngIf="data.newUser === true && icRoles == true ">
        <div class="flex-fill">
            <section style="width: 100%;float:right;font-size: 12px; margin-top: 0px;" id="checkbox">
                <mat-checkbox class="groupCheckbox" disabled [checked]="icRoles"> {{'invite-user.Gruppenauswahl_text'
                    | translate}}
                </mat-checkbox>
            </section>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-content *ngIf="newUser==true">
    <div style="display: flex;justify-content: center;">
        <button class="navButton" style="border: 1px solid #ff9000;box-shadow: 0 0 0 2px #fff inset;">1</button>
        <hr class="horizontal">
        <button class="navButton" style="background-color: #dddddd;" (click)="addUser(data)">2</button>
        <hr style="display: inline-block;width: 2%;margin: 10px 0px;border-top: 2px solid #dddddd;">
        <button class="navButton" style="background-color: #dddddd;">3</button>
        <hr style="display: inline-block;width: 2%;margin: 10px 0px;border-top: 2px solid #dddddd;">
        <button class="navButton" style="background-color: #dddddd;">4</button>
    </div>
    <hr class="hr" style="margin-bottom: 0px;">
</mat-dialog-content>
<mat-dialog-content id="update" *ngIf="null_user == true && newUser==false">

    <p>{{'update-user.keineBenutzerzuordnung_info' | translate}} </p>

</mat-dialog-content>

<mat-dialog-actions align="center" style="padding-bottom:15px;" *ngIf="newUser !== true">
    <button style="color: #272727;border: solid 1px #272727;padding: 0px 33px;" mat-button
        matDialogClose="cancel" (click)="onNoClick()" color="primary">{{buttonCancelName | multilingual |
        translate}}</button>
    <button
        style="background: #ff9000;padding: 0px 33px; border: 1px solid #ff9000;color: #272727 !important;"
        class="text-color-bold" [disabled]="data.Telefon == null" *ngIf="null_user == false" mat-button color="warn"
        (click)=" save(data) " firstname>
        {{buttonConfirmName | multilingual | translate }}
    </button>
</mat-dialog-actions>

<mat-dialog-actions align="center" *ngIf="newUser == true">
    <button class="custom-btn save-btn" [disabled]="data.Telefon == null" *ngIf="null_user == false"
        (click)="addUser(data)">
        {{'update-user.Next_text' | translate}}
    </button>
</mat-dialog-actions>
