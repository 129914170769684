import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'custom-pdf-viewer',
  templateUrl: './custom-pdf-viewer.component.html',
  styleUrls: ['./custom-pdf-viewer.component.css'],
})
export class CustomPdfViewerComponent implements OnInit {
  page = 1;
  @Input('pdfLink') pdfLink: string;
  @Input('blob') data;
  @Input('via') via: string;
  @Output('onClosePdf') pdfCloseEvent = new EventEmitter<boolean>();
  @Input('pdfName') pdfName;
  pageNumber: number;

  loading: boolean;
  thumbnail = [];
  secondaryColour = '#d6d1d170';
  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  ngOnInit(): void {
    this.loading = true;
  }

  closePDF() {
    this.pageNumber = 1;
    const elpage = document.getElementById('pages');
    const elthumbnail = document.getElementById('pdfView');
    elpage.scroll(0, 0);
    elthumbnail.scroll(0, 0);
    window.scroll(0, 0);
    this.pdfCloseEvent.emit(false);
  }

  pageRendered(e) {
    if (typeof e !== 'object') {
      const elpage = document.getElementById('page' + e);
      const elthumbnail = document.getElementById('thumbnail' + e);
      this.pageNumber = e;
      elpage.scrollIntoView();
      elthumbnail.scrollIntoView();
    } else {
      this.loading = false;
      if (e.pageNumber == 1) {
        this.thumbnail = [];
        this.thumbnail.push({
          page: e.pageNumber,
          url: e.source.canvas.toDataURL(),
        });
      } else {
        this.thumbnail.push({
          page: e.pageNumber,
          url: e.source.canvas.toDataURL(),
        });
      }

      const length = document.getElementsByClassName('page');
      for (let id = 1; id <= length.length; id++) {
        length[id - 1].setAttribute('id', 'page' + id);
      }
    }
  }
  backPage() {
    this.pageNumber = this.pageNumber - 1;
    const elpage = document.getElementById('page' + this.pageNumber);
    const elthumbnail = document.getElementById('thumbnail' + this.pageNumber);
    elpage.scrollIntoView();
    elthumbnail.scrollIntoView();
  }
  download(data, name) {
    const newBlob = new Blob([data], { type: 'application/pdf' });
    const downloadLink = document.createElement('a');
    downloadLink.target = '_self';
    const fileName = name + '.pdf';
    const data1 = window.URL.createObjectURL(newBlob);
    downloadLink.href = data1;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
