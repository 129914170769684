<ng-container *ngIf="generalDataForm">
  <div  class="general-tab">
    <h6 class="customer-management-page-heading">{{'customer-management.left-panel.general-data' | translate}}</h6>
    <form [formGroup]="generalDataForm" (keydown.enter)="$event.preventDefault()" class="product-module-html">
      <div class="box-row-containers">
        <div class="box-container-left">
          <div class="box-container-heading">
            <span class="box-criteria-heading">{{'customer-management.general-data.customer_info' | translate}}</span>
          </div>
          <div class="inner-box">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12 displayFlex">

                <div class="smallInput" style="width: 35%;">
                  <label for="" class="label"> {{'update-user.title_label' | translate }} <span
                      class="text-danger">*</span></label>
                  <mat-select
                    *ngIf="locale$ | async as locale"
                    class="dropdownStyle"
                    (selectionChange)="salutationSelected($event.value)"
                    placeholder="{{'objectModule.select_lable' | translate}}" formControlName="salutation"
                    [ngClass]="{'red' : (generalDataForm.controls['salutation'].touched || submitted) && generalDataForm.controls['salutation'].errors?.required }">
                    <mat-option [value]="item.salutation_id" *ngFor="let item of salutations">
                      <span>{{ locale === "English(EN)" ? item.salutation_text_en : item.salutation_text_de}}</span>
                    </mat-option>
                  </mat-select>
                </div>
                <div class="smallInput" style="width:65%;margin-left: 5px;">
                  <label for="" class="label " *ngIf="FirmaSelected"> Name 1 <span class="text-danger">*</span></label>
                  <label for="" class="label " *ngIf="!FirmaSelected"> {{'customer-management.contact_person.first_name' |
                    translate}} <span class="text-danger">*</span></label>
                  <input type="text" class="input"
                    [ngClass]="{'red' : (generalDataForm.controls['name1'].touched || submitted) && generalDataForm.controls['name1'].errors?.required || validFirstTextError}"
                    title="{{generalDataForm.controls['name1'].value}}" formControlName="name1" placeholder="Name 1">
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label " *ngIf="FirmaSelected"> Name 2</label>
                <label for="" class="label " *ngIf="!FirmaSelected"> {{'customer-management.contact_person.last_name' |
                  translate}} <span class="text-danger">*</span></label>
                <input type="text"
                  [ngClass]="{'red' : !generalDataForm.controls['name2'].value && submitted && !FirmaSelected || validLastTextError}"
                  class="input" title="{{generalDataForm.controls['name2'].value}}" formControlName="name2"
                  placeholder="Name 2">
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label "> Name 3</label>
                <input type="text" class="input" title="{{generalDataForm.controls['name3'].value}}"
                  formControlName="name3" placeholder="Name 3">
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label "> Name 4</label>
                <input type="text" class="input" title="{{generalDataForm.controls['name4'].value}}"
                  formControlName="name4" placeholder="Name 4">
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label "> {{'master-data.Addresse_label' | translate}}</label>
                <input type="text" class="input" formControlName="address"
                  placeholder="{{'master-data.Addresse_label' | translate}}"
                  [ngClass]="showActive == false ? 'greyLocationPinIcon' : 'greenLocationPinIcon'" name="location-list" #loc id="location"
                  autocomplete="off" (focus)="showList = true" (focusout)="onFocusOut()"
                  (keyup)="keyUp.next($event)">
                <ul class="statusFilter" id="locationList" *ngIf="showList && filterlocation.length">
                  <li class="listStyle" *ngFor="let location of filterlocation;index as i" (click)="selectedLocation(i)">
                    {{location.freeformAddress}}
                  </li>
                </ul>
              </div>
              <div class="col-md-6 col-sm-12 col-12 displayFlex">

                <div class="smallInput" style="width: 65%;">
                  <label for="" class="label "> {{'master-data.Street_label' | translate}}</label>
                  <input type="text" class="input" formControlName="street"
                    placeholder="{{'master-data.Street_label' | translate}}">
                </div>
                <div class="smallInput" style="width:35%;margin-left: 5px;">
                  <label for="" class="label "> {{'master-data.Housenumber_label' | translate}}</label>
                  <input type="text" class="input" formControlName="houseNumber"
                    placeholder="{{'master-data.Housenumber_label' | translate}}">
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-12 displayFlex">

                <div class="smallInput" style="width: 50%;">
                  <label for="" class="label "> {{'master-data.Postcode_label' | translate}}</label>
                  <input type="text" class="input" formControlName="postalCode"
                    placeholder="{{'master-data.Postcode_label' | translate}}">
                </div>
                <div class="smallInput" style="width:50%;margin-left: 5px;">
                  <label for="" class="label "> {{'master-data.City_label' | translate}}</label>
                  <input type="text" class="input" formControlName="city"
                    placeholder="{{'master-data.City_label' | translate}}">
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label>{{'master-data.Country_label' | translate}}</label>
                <mat-select formControlName="country" class="dropdownStyle"
                  placeholder="{{'objectModule.select_lable' | translate}}">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option class="con-option" *ngFor="let country of countries$ | async" [value]="country.countryName">
                    {{country.countryName}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label"> {{'master-data.CustomerNumber_label' | translate}}</label>
                <input type="text" class="input" formControlName="customerNumber"
                  placeholder="{{'master-data.CustomerNumber_label' | translate}}">
              </div>
            </div>
          </div>
        </div>
        <div class="box-container-right customer_status">
          <div class="box-container-heading">
            <span class="box-criteria-heading">{{'customer-management.general-data.customer_status' | translate}}</span>
          </div>
          <div class="inner-box">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'rcs-setup.division-text' | translate}} <span
                    class="text-danger">*</span></label>
                <mat-select formControlName="division" class="dropdownStyle" (selectionChange)="getAgents($event.value)"
                  [disabled]="!generalDataForm.controls['salutation'].value || source_ivm || !editCustomer"
                  [ngClass]="{'red' : (generalDataForm.controls['division'].touched || submitted) && generalDataForm.controls['division'].errors?.required }"
                  placeholder="{{'objectModule.select_lable' | translate}}" (selectionChange)="divisionChanged()">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option class="con-option" *ngFor="let division of divisions" [value]='division.division_id'>
                    {{division.division_name}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'master-data.ic_main_contact_lable' | translate}} <span
                    class="text-danger">*</span></label>
                <mat-select class="dropdownStyle"
                  [ngClass]="{'red' : (generalDataForm.controls['IC_USER'].touched || submitted) && generalDataForm.controls['IC_USER'].errors?.required }"
                  [disabled]="!generalDataForm.controls['division'].value || !editCustomer"
                  placeholder="{{'objectModule.select_lable' | translate}}" formControlName="IC_USER"
                  (openedChange)="search.value = ''" (click)="focusMainContactSearch()"
                  (keyup.enter)="focusMainContactSearch()">
                  <input type="text" maxlength="20" class="searchSelect" autocomplete="off"
                    placeholder="{{'header.Suche_tooltip' | translate}}" preventSpace #search id="search">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option class="con-option"
                    *ngFor="let berbeiter of validationService._filter(IC_USERs, 'editor_name', search.value) "
                    [value]='berbeiter.editor_id'>
                    {{berbeiter.editor_name}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'master-data.Classification_label' | translate}} <span
                    class="text-danger">*</span></label>
                <mat-select
                  *ngIf="locale$ | async as locale"
                  class="dropdownStyle"
                  id="classificationDropdown"
                  [disabled]="!editCustomer"
                  [ngClass]="
                    {'red' : (generalDataForm.controls['classification'].touched || submitted) &&
                    generalDataForm.controls['classification'].errors?.required }"
                  placeholder="{{'objectModule.select_lable' | translate}}"
                  formControlName="classification"
                  #classificationDropdown>
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option *ngFor="let class of classification" [value]="class.shortName">
                    <span>{{locale == "English(EN)" ? class.nameEn : class.name}}</span>
                  </mat-option>

                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'master-data.ActivationFlag_label' | translate}}</label>
                <mat-select
                  *ngIf="locale$ | async as locale"
                  class="dropdownStyle"
                  placeholder="{{'objectModule.select_lable' | translate}}"
                  formControlName="activationFlag">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option *ngFor="let flag of getActivationFlagByLocale(locale)" [value]="flag.symbol">
                    {{flag.name}} <span style="float: right;">{{flag.symbol}}</span>
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'customer-management.general-data.role_of_customer' | translate}} <span
                    class="text-danger">*</span></label>
                <mat-select class="dropdownStyle" [disabled]="!editCustomer"
                  placeholder="{{'objectModule.select_lable' | translate}}"
                  [ngClass]="{'red' : (generalDataForm.controls['roleOfCustomer'].touched || submitted) && generalDataForm.controls['roleOfCustomer'].errors?.required }"
                  formControlName="roleOfCustomer">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option class="con-option" *ngFor="let role of customerRoles" [value]='role'>
                    {{role}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'customer-management.general-data.status' | translate}}</label>
                <mat-select formControlName="status" class="dropdownStyle" (selectionChange)="statusSelect($event)"
                  placeholder="{{'objectModule.select_lable' | translate}}">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option class="con-option" *ngFor="let status of statusList" [value]='status.id'>
                    {{status.desc}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'customer-management.general-data.status_reason' | translate}}</label>
                <app-custom-autocomplete class="autocomplete" [placeholder]="'objectModule.select_lable' | translate"
                  [required]="false" [lookup]="status_reason_lookup" [control]="generalDataForm.controls['statusReason']"
                  [is_disabled]="!editCustomer" (newValueSelected)="targetValue($event)"></app-custom-autocomplete>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'customer-management.general-data.sales_status'| translate}}</label>
                <mat-select formControlName="salesStatus" class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option class="con-option" *ngFor="let status of sales_status" [value]="status.id">{{status.name}}</mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label "> {{'master-data.Customergroup_label' | translate}}</label>
                <input type="text" class="input" formControlName="customerGroup"
                  placeholder="{{'master-data.Customergroup_label' | translate}}">
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label "> {{'customer-management.general-data.customer_origin' | translate}}</label>
                <input type="text" class="input" maxlength="100"
                  title="{{generalDataForm.controls['customerOrigin'].value}}" formControlName="customerOrigin"
                  placeholder="{{'customer-management.general-data.customer_origin' | translate}}">
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label "> {{'master-data.Customersatisfactionsurvey_label' | translate}}</label>
                <input type="text" class="input" formControlName="customerSatisfactionSurvey"
                  placeholder="{{'header.In_Entwicklung' | translate}}">
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label ">{{'master-data.BrokerageMandate_label' | translate}}</label>
                <mat-select formControlName="brokerageMandate" class="dropdownStyle"
                  placeholder="{{'objectModule.select_lable' | translate}}">
                  <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                  <mat-option class="con-option" *ngFor="let status of brokerageMandate" [value]='status.id'>
                    {{status.desc}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-6 col-sm-12 col-12 datePicker">
                <label for="" class="label"
                  style="text-transform: initial;">{{'customer-management.general-data.brokerage_mandate_date' |
                  translate}}</label><br />
                <mat-form-field class="date-picker" appearance="fill" id="datePicker-field">
                  <span>
                    <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                  </span>
                  <input matInput [matDatepicker]="datepicker3" id="myInput"
                    class="date-input" autocomplete="off" placeholder="{{'claim-reporting.datePlaceHolder' | translate}}"
                    formControlName="brokerageMandateDate" [max]="today">
                  <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker3">
                    <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                      {{'claims.keyboard-arrow-down_text' | translate}}
                    </mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #datepicker3></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-sm-12 col-12">
                <label for="" class="label">{{'customer-management.general-data.brokerage_mandate_document' |
                  translate}}</label><br />
                <div style="display: flex;cursor: pointer;"
                  title="{{generalDataForm.controls['brokerageMandateDocument'].value}}">
                  <div style="width: 87%;">
                    <input type="text" class="input" readonly
                      style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                      formControlName="brokerageMandateDocument"
                      placeholder="{{'customer-management.general-data.brokerage_mandate_document' | translate}}">
                  </div>
                  <button class="upload-button" (click)="showPopup()" [disabled]="!editCustomer">
                    <img alt="" src="../../../../assets/images/ic-icons/Upload.svg" style="opacity: 0.6;">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-row-containers">
        <div class="box-container-left-noborder">
          <div class="box-container-top">
            <div class="box-container-heading">
              <span class="box-criteria-heading">{{'customer-management.general-data.main_contact' |
                translate}}</span>
            </div>
            <div class="inner-box">
              <div class="row">
                <div class="col-md-6 col-sm-12 col-12" id="update">
                  <label for="" class="label "> {{'master-data.Telephonenumber_label' | translate}}</label>
                  <mat-form-field appearance="fill" class="tel" style="width: 95%;float:left;border:none;">
                    <ngx-mat-intl-tel-input [ngClass]="'ngx-floating'"
                      class="input floating" [preferredCountries]="['de']"
                      [enablePlaceholder]="true" formControlName="tel" name="tele" [enableSearch]="true" #phone>
                    </ngx-mat-intl-tel-input>
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12 col-12">
                  <label [ngStyle]="{'color' : generalDataForm.get('email').invalid ? 'red' : 'inherit'}" for=""
                    class="label "> {{'master-data.E-Mail_label' | translate}}</label>
                  <input [class.is-invalid]="generalDataForm.get('email').invalid" type="email" class="input"
                    formControlName="email" placeholder="{{'master-data.E-Mail_label' | translate}}">
                </div>
              </div>
            </div>
          </div>
          <div class="box-container-bottom">
            <div class="box-container-heading">
              <span class="box-criteria-heading">{{'customer-management.general-data.special_remark' |
                translate}}</span>
            </div>
            <div class="inner-box">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-12">
                  <label for="" class="label "> {{'objectModule.fire_protection_labels.Remark' | translate}} </label>
                  <textarea class="textArea" maxlength="5000" formControlName="remark"
                    placeholder="{{'objectModule.fire_protection_labels.Remark' | translate}}"
                    style="width: 100%;"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-container-right">
          <div class="box-container-heading">
            <span class="box-criteria-heading">{{'customer-management.general-data.graph' |
              translate}}</span>
          </div>
        </div>
      </div>
      <div class="box-row-containers">
        <div class="box-container-left">
          <div>
            <ul>
              <li class="headerRow">
                <div class="headerSection" style="width:30%">{{'customer-management.general-data.agent_name' |
                  translate}}</div>
                <div class="headerSection" style="width:60%">{{'customer-management.general-data.agent_desc' |
                  translate}}</div>
                <div class="headerSection" style="width:10%">{{'product-management.action' | translate}}</div>
              </li>
              <div class="rowContainer">
                <li *ngFor="let claminProcessing of allAgents; index as ind">
                  <div class="lineSetion" style="width:30%">
                    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" [disabled]="!editCustomer"
                      (selectionChange)="agentSelected($event,ind);search.value = ''" (openedChange)="search.value = ''"
                      placeholder="{{'objectModule.select_lable' | translate}}"
                      [(value)]="claminProcessing.rcs_division_user_id">
                      <input type="text" maxlength="20" class="searchSelect"
                        preventSpace
                        placeholder="{{'header.Suche_tooltip' | translate}}" #search>
                      <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                      <mat-option *ngFor="let agent of validationService._filter(agents, 'user_name', search.value)"
                        [value]="agent.id">{{agent.user_name}}</mat-option>
                    </mat-select>
                  </div>
                  <div class="lineSetion" style="width:60%">
                    <input type="text" maxlength="100" [value]="claminProcessing.agent_desc"
                      title="{{claminProcessing.agent_desc}}" placeholder="Agent Description"
                      (blur)="descAdded($event,ind)" class="noBorderInput" style="margin: 0; width:70%;"
                      [disabled]="!editCustomer" />
                      <button mat-icon-button [disabled]="!editCustomer">
                        <img
                          alt=""
                          matTooltip="{{'edit' | translate}}"
                          src="../../../assets/images/edit_contract_icon.svg"
                        />
                      </button>
                  </div>
                  <div class="lineSetion" style="width:10%">
                    <button mat-icon-button (click)="openModal(ind,claminProcessing.id)" [disabled]="!editCustomer">
                      <img
                        alt=""
                        matTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                        src="../../../assets/images/Group 2658.svg"
                      />
                    </button>
                  </div>
                </li>
              </div>
              <li class="add-row" [class.add-row--disabled]="!editCustomer" (click)="addNewRow()">
                <span style="font-weight: 600;">
                  <img alt="" style="margin-top: -3px;margin-right: 4px;" class="imgStyle"
                    src="../../../assets/images/Icon ionic-md-close.svg">{{'customer-management.general-data.add_row' |
                  translate}}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="box-container-right">
          <div>
            <ul>
              <li class="headerRow">
                <div class="headerSection" style="width:30%">{{'customer-management.general-data.intermediary_name' |
                  translate}}</div>
                <div class="headerSection" style="width:60%">{{'customer-management.general-data.intermediary_desc' |
                  translate}}</div>
                <div class="headerSection" style="width:10%">{{'product-management.action' | translate}}</div>
              </li>
              <li *ngFor="let index of [1,2]">
                <div class="lineSetion" style="width:30%">
                  <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" [value]="'Mediator'" disabled>
                    <mat-option [value]="'Mediator'">Mediator</mat-option>
                  </mat-select>
                </div>
                <div class="lineSetion" style="width:60%;font-size: 13px;">10 % AP</div>
                <div class="lineSetion" style="width:10%">
                  <button mat-icon-button [disabled]="!editCustomer">
                    <img
                      alt=""
                      matTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                      src="../../../assets/images/Group 2658.svg"
                    />
                  </button>
                </div>
              </li>
              <li class="add-row" [class.add-row--disabled]="!editCustomer" >
                <span style="font-weight: 600;">
                  <img alt="" style="margin-top: -3px;margin-right: 4px;" class="imgStyle"
                    src="../../../assets/images/Icon ionic-md-close.svg">
                  {{'customer-management.general-data.add_row' | translate}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="save-container">
    <button class="custom-btn save-btn" [disabled]="!editCustomer || generalDataForm.get('email').invalid"
      (click)="saveCustomer()">{{'update-customer.Speichern_text' |
      translate}}</button>
  </div>
  <div class="modal" id="deleteDocumentModel" [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog spacing">
      <div class="modal-content">
        <div class="modal-content">
          <div class="modal-header header-content">
            <div class="text-center">
              <mat-icon (click)="hideModal()">
                <img alt="" height="65px" src="../../../assets/images/Group 3235.svg">
              </mat-icon>
            </div>
          </div>
          <div class="modal-body body-text">
            <span class="delete-heading">{{'objectModule.Delete_popup_heading' | translate}}</span>
            <br /><br />
            <span class="delete-text">{{'objectModule.Delete_popup_text' | translate}}</span>
          </div>
          <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
            <button (click)="hideModal()" class="custom-btn cancel-btn">
              {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp;
            <button class="ml-2 custom-btn save-btn" (click)="deleteEnrty(deleteIndex,delete_item_id)">
              {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-upload-document *ngIf="showDocumentPopup" [object_type]="uploadDocumentDetails"
    [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup"
    (hide)="hidePopup($event)">
  </app-upload-document>
  <app-warning-popup *ngIf="showWarningPopUp" [customerWarningMsg]="customerWarningMsg"
    (savedata)="saveGeneralData($event)" (handlePopup)="handleWarningPopup($event)"></app-warning-popup>
  
</ng-container>

