
<div class="external-container">
    <div class="container-heading">
        <span class="criteria-heading">{{'product-management.general_information.general_product_information' | translate}}</span>
    </div>
    <form class="row object-module-html form-align" [formGroup]="generalInformationForm">
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.division' | translate}}</label>
            <mat-select multiple class="dropdownStyle" required formControlName="division" 
            (click)="focusDropdown('searchDivision')" (keyup.enter)="focusDropdown('searchDivision')"  (selectionChange)="searchDivision.value = ''; selectionChanged('divisionControl');"
            [disabled]="user_role !== userRoles.IC_GLOBAL_ADMIN || generalInformationForm.controls['division'].disabled"
            placeholder="{{'objectModule.select_lable' | translate}}" #divisionControl>
                <input type="text" class="searchSelect" 
                preventSpace
                placeholder="{{'header.Suche_tooltip' | translate}}" matInput #searchDivision>
                <mat-option [hidden]="item.hide" *ngFor="let item of validationService._filterMultiSelect(divisions, 'division_name', searchDivision.value)" title="{{item.division_name}}" [value]="item.division_id">
                    <span>{{item.division_name}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.sparte' | translate}}</label>
            <mat-select class="dropdownStyle" formControlName="sparte" (click)="focusDropdown('searchSparte')" (keyup.enter)="focusDropdown('searchSparte')"  (selectionChange)="searchSparte.value = ''; selectionChanged('sparteControl');sparteChange($event.value);"
                [disabled]="generalInformationForm.controls['sparte'].disabled"
                placeholder="{{'objectModule.select_lable' | translate}}" #sparteControl>
                <input type="text" preventSpace class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #searchSparte>
                <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                <mat-option *ngFor="let item of validationService._filter(spartes, 'description', searchSparte.value)" title="{{item.description}}" [value]="item.fixer_code">
                    <span>{{item.description}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label class="long-label required">{{'product-management.general_information.product_name' | translate}}</label>
            <input type="text" maxlength="100" class="input" formControlName="product_name" placeholder="{{'product-management.general_information.product_name' | translate}}"
                [attr.disabled]="generalInformationForm.controls['product_name'].disabled ? 'disabled' : null"
                title="{{generalInformationForm.controls['product_name'].value}}">
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label class="long-label required">{{'product-management.general_information.public_label' | translate}}</label>
            <input type="text" maxlength="100" class="input" formControlName="public_label" placeholder="{{'product-management.general_information.public_label' | translate}}"
                [attr.disabled]="generalInformationForm.controls['public_label'].disabled ? 'disabled' : null"
                title="{{generalInformationForm.controls['public_label'].value}}">
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.Underwriting_insurer' | translate}}</label>
            <mat-select multiple class="dropdownStyle" formControlName="underwriting_insurers" (click)="searchInsurer.value = '';focusDropdown('searchInsurer')" (keyup.enter)="searchInsurer.value = '';focusDropdown('searchInsurer')"  (selectionChange)="searchInsurer.value = ''; selectionChanged('insurerControl')"
                [disabled]="generalInformationForm.controls['underwriting_insurers'].disabled"
                placeholder="{{'objectModule.select_lable' | translate}}" #insurerControl>
                <input type="text" class="searchSelect" preventSpace placeholder="{{'header.Suche_tooltip' | translate}}" #searchInsurer>
                <mat-option [hidden]="item.hide" *ngFor="let item of validationService._filterMultiSelect(insurers, 'desc', searchInsurer.value)" title="{{item.desc}}" [value]="item.id">
                    <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.versioning' | translate}}</label>
            <div style="display: flex;">
                <mat-select class="dropdownStyle versioning-dropdown" formControlName="versioning" placeholder="{{'objectModule.select_lable' | translate}}"
                    [disabled]="true">
                    <mat-option *ngFor="let item of product_state" title="{{item.desc}}" [value]="item.id">
                        <span>{{item.desc}}</span>
                    </mat-option>
                </mat-select>
                <input type="text" maxlength="5" class="input zursInputDateStyle" formControlName="versioning_number"
                    placeholder="{{'product-management.general_information.versioning' | translate}}" readonly>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6 date-picker-container" id="datePicker1">
            <label for="" class="label required">
                {{'product-management.general_information.validity_indicator' | translate}}
            </label>
            <div style="display: flex;">
            <mat-form-field class="date-picker" [ngClass]="generalInformationForm.controls['validity_indicator_from'].disabled ? 'product-data-pickerdisabled' : 'product-data-picker'" appearance="fill" id="datePicker1"
                [style.backgroundColor]="generalInformationForm.controls['validity_indicator_from'].disabled ? '#e4e4e4 !important' : '#FFFFFF !important'">
            <span>
              <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg" >
            </span>
            <input matInput [matDatepicker]="datePicker1" class="date-input" formControlName="validity_indicator_from"  [max]="generalInformationForm.get('validity_indicator_to').value"
                [disabled]="generalInformationForm.controls['validity_indicator_from'].disabled"
                autocomplete="off" placeholder="{{'product-management.general_information.from_text' | translate}}" style="margin-top: 1px; margin-left: 3px;" (blur)="compareTwoDates()" (dateChange)="compareTwoDates()">
            <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datePicker1">
              <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                {{'claims.keyboard-arrow-down_text' | translate}}
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field  class="date-picker" [ngClass]="generalInformationForm.controls['validity_indicator_from'].disabled ? 'product-data-pickerdisabled' : 'product-data-picker'" appearance="fill" id="datePicker2"
            [class.input-error]="error">
                <span>
                  <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                </span>
                <input matInput [matDatepicker]="datePicker2" class="date-input" formControlName="validity_indicator_to" [min]="generalInformationForm.get('validity_indicator_from').value"
                    [disabled]="generalInformationForm.controls['validity_indicator_to'].disabled"
                    autocomplete="off" placeholder="{{'product-management.general_information.to_text' | translate}}" style="margin-top: 1px; margin-left: 3px;" (blur)="compareTwoDates()" (dateChange)="compareTwoDates()">
                <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datePicker2">
                  <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                    {{'claims.keyboard-arrow-down_text' | translate}}
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #datePicker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.automatic_contract_renewal' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="automatic_contract_renewal"
                    [disabled]="generalInformationForm.controls['automatic_contract_renewal'].disabled"
                    [ngClass]="{'invalid': generalInformationForm.get('automatic_contract_renewal').invalid && generalInformationForm.get('automatic_contract_renewal').touched }">
                <mat-radio-button [value]="true" (click)="booleanChange(true,'cancellation_period_in_months')">
                    {{'objectModule.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false" (click)="booleanChange(false,'cancellation_period_in_months')">
                    {{'objectModule.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label" [ngClass]="{'required': generalInformationForm.value.automatic_contract_renewal}">
                {{'product-management.general_information.cancellation_period' | translate}}</label>
            <mat-select class="dropdownStyle" formControlName="cancellation_period_in_months"
                placeholder="{{'objectModule.select_lable' | translate}}" 
                [disabled]="!generalInformationForm.controls['automatic_contract_renewal'].value || generalInformationForm.controls['cancellation_period_in_months'].disabled">
                <mat-option *ngFor="let item of cancellation_period" title="{{item.desc}}" [value]="item.id">
                    <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.country_code' | translate}}</label>
            <mat-select multiple class="dropdownStyle" formControlName="country_code" (click)="searchCountryCode.value = '';focusDropdown('searchCountryCode')" (keyup.enter)="searchCountryCode.value = '';focusDropdown('searchCountryCode')"  (selectionChange)="searchCountryCode.value = ''; selectionChanged('countryCodeControl')"
                [disabled]="generalInformationForm.controls['country_code'].disabled"
                placeholder="{{'objectModule.select_lable' | translate}}" #countryCodeControl>
                <input type="text" id="searchCountryCode" class="searchSelect" preventSpace placeholder="{{'header.Suche_tooltip' | translate}}" matInput #searchCountryCode>
                <mat-option [hidden]="item.hide" *ngFor="let item of validationService._filterMultiSelect(countries, 'desc', searchCountryCode.value)" title="{{item.desc}}" [value]="item.id">
                    <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.avb' | translate}}</label>
            <div style="display: flex;">
            <div class="documentUploadInput">
                <input class="input" readonly formControlName="avb_docs"
                    [attr.disabled]="generalInformationForm.controls['avb_docs'].disabled ? 'disabled' : null"
                    placeholder="{{'product-management.general_information.upload' | translate}}">
            </div>
            <div class="documentUploadButton">
                <button class="docButton" type="button" (click)="uploadAVBDocumentPopup()"
                [disabled]="generalInformationForm.controls['avb_docs'].disabled"
                [class.input-error]="generalInformationForm.get('avb_docs').invalid &&
                (generalInformationForm.get('avb_docs').dirty || generalInformationForm.get('avb_docs').touched)">
                    <img alt="" src="../../../assets/images/ic-icons/Upload.svg">
                </button>
            </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.side_agreements' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="side_agreements"
                [disabled]="generalInformationForm.controls['side_agreements'].disabled"
                [ngClass]="{'invalid': generalInformationForm.get('side_agreements').invalid && generalInformationForm.get('side_agreements').touched }">
                    <mat-radio-button [value]="true" (click)="booleanChange(true,'selection_of_side_letters_docs')">
                    {{'objectModule.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false" (click)="booleanChange(false,'selection_of_side_letters_docs')">
                    {{'objectModule.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required" [ngClass]="{'required': generalInformationForm.value.side_agreements}">
                {{'product-management.general_information.selection_side_agreements' | translate}}
            </label>
            <div style="display: flex;">
            <div class="documentUploadInput">
                <input class="input" readonly formControlName="selection_of_side_letters_docs"
                    placeholder="{{'product-management.general_information.upload' | translate}}"
                    [attr.disabled]="!generalInformationForm.value.side_agreements || generalInformationForm.controls['selection_of_side_letters_docs'].disabled ? 'disabled' : null">
            </div>
            <div class="documentUploadButton">
                <button class="docButton" type="button" (click)="uploadSideLettersPopup()" [disabled]="!generalInformationForm.value.side_agreements || generalInformationForm.controls['selection_of_side_letters_docs'].disabled"
                [class.input-error]="generalInformationForm.get('selection_of_side_letters_docs').invalid &&
                (generalInformationForm.get('selection_of_side_letters_docs').dirty || generalInformationForm.get('selection_of_side_letters_docs').touched)">
                    <img alt="" src="../../../assets/images/ic-icons/Upload.svg">
                </button>
            </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.area_of_coverage' | translate}}</label>
            <mat-select multiple class="dropdownStyle" formControlName="area_of_coverage" (click)="areaOfCoverage.value = '';focusDropdown('areaOfCoverage')" (keyup.enter)="areaOfCoverage.value = '';focusDropdown('areaOfCoverage')"  (selectionChange)="areaOfCoverage.value = ''; selectionChanged('areaOfCoverageControl')"
            [disabled]="generalInformationForm.controls['area_of_coverage'].disabled"
            placeholder="{{'objectModule.select_lable' | translate}}" #areaOfCoverageControl>
                <input type="text" id="areaOfCoverage" class="searchSelect" preventSpace placeholder="{{'header.Suche_tooltip' | translate}}" matInput #areaOfCoverage>
                <mat-option [hidden]="item.hide" *ngFor="let item of validationService._filterMultiSelect(coverage_area, 'desc', areaOfCoverage.value)" title="{{item.desc}}" [value]="item.id">
                    <span>{{item.desc}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.objects_to_be_insured' | translate}}</label>
            <mat-select multiple class="dropdownStyle" formControlName="objects_to_be_insured" (click)="objectsToBeInsured.value = '';focusDropdown('objectsToBeInsured')" (keyup.enter)="objectsToBeInsured.value = '';focusDropdown('objectsToBeInsured')"  (selectionChange)="objectsToBeInsured.value = ''; selectionChanged('objectsToBeInsuredControl')"
                [disabled]="generalInformationForm.controls['objects_to_be_insured'].disabled"
                placeholder="{{'objectModule.select_lable' | translate}}" #objectsToBeInsuredControl>
                <input type="text" id="objectsToBeInsured" class="searchSelect" preventSpace placeholder="{{'header.Suche_tooltip' | translate}}" matInput #objectsToBeInsured>
                <mat-option [hidden]="item.hide" *ngFor="let item of validationService._filterMultiSelect(object_types, 'object_name', objectsToBeInsured.value)" title="{{item.object_name}}" [value]="item.object_type_id">
                    <span>{{item.object_name}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label required">{{'product-management.general_information.split_declaration' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="split_declaration"
                [disabled]="generalInformationForm.controls['split_declaration'].disabled"
                [ngClass]="{'invalid': generalInformationForm.get('split_declaration').invalid && generalInformationForm.get('split_declaration').touched }">
                <mat-radio-button [value]="true">
                    {{'objectModule.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="description-row">
        <div class="col-md-3 col-sm-6 col-6">
            <div>
                <label for="" class="long-label">
                    {{'product-management.general_information.clauses_set_for_product' | translate}}
                </label>
                <div style="display: flex;">
                    <div class="documentUploadInput">
                        <input disabled style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;height: 39px;border: 1.5px solid #beb8b8c2;"
                        type="text" class="input zursInputDateStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                        [disabled]="generalInformationForm.controls['clauses'].disabled"
                        formControlName="clauses" title="{{generalInformationForm.controls['clauses'].value}}">
                    </div>
                    <div class="documentUploadButton" [ngClass]="{'tooltipz1': !generalInformationForm.value.sparte}">
                        <button class="docButton" type="button" (click)="addClauses()"
                        [class.input-error]="generalInformationForm.get('clauses').invalid &&
                        (generalInformationForm.get('clauses').dirty || generalInformationForm.get('clauses').touched)"
                        [disabled]="!generalInformationForm.value.sparte || generalInformationForm.controls['clauses'].disabled">
                            <img alt="" src="../../../../assets/images/Icon ionic-md-close.svg">
                        </button>
                        <span class="tooltiptextz1">{{'product-management.general_information.please_select_sparte' | translate}}</span>
                    </div>
                </div>
            </div>
            <div>
                <label for="" class="long-label required">{{ 'product-management.general_information.covering_type' | translate }}</label>
                <mat-select class="dropdownStyle" formControlName="coverage_type"
                    placeholder="{{'objectModule.select_lable' | translate}}">
                    <mat-option *ngFor="let item of coverage_type" title="{{item.desc}}" [value]="item.id">
                        <span>{{item.desc}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">
                {{'product-management.general_information.exclusion_for_product' | translate}}
            </label>
        <div style="display: flex;">
            <div class="documentUploadInput">
                <input disabled style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;height: 39px;border: 1.5px solid #beb8b8c2;"
                [disabled]="generalInformationForm.controls['exclusions'].disabled"
                type="text" class="input zursInputDateStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                formControlName="exclusions" title="{{generalInformationForm.controls['exclusions'].value}}">
            </div>
            <div class="documentUploadButton" [ngClass]="{'tooltipz1': !generalInformationForm.value.sparte}">
                <button class="docButton" type="button" (click)="addExclusions()"
                [class.input-error]="generalInformationForm.get('exclusions').invalid &&
                (generalInformationForm.get('exclusions').dirty || generalInformationForm.get('exclusions').touched)"
                 [disabled]="!generalInformationForm.value.sparte || generalInformationForm.controls['exclusions'].disabled">
                    <img alt="" src="../../../../assets/images/Icon ionic-md-close.svg">
                </button>
                <span class="tooltiptextz1">{{'product-management.general_information.please_select_sparte' | translate}}</span>
            </div>
        </div>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
            <label for="" class="long-label">{{'product-management.general_information.product_description' | translate}}</label>
            <mat-form-field class="text-area" appearance="fill" style="width: 100%;">
                <textarea  matInput class="" placeholder="{{'product-management.general_information.product_description' | translate}}"
                    formControlName="product_description"
                    [disabled]="generalInformationForm.controls['product_description'].disabled"
                    rows="8" cols="50"maxlength="5000">
                </textarea>
                <span class="pull-right">{{ 5000 - generalInformationForm.controls['product_description']?.value?.length }} {{'product-management.general_information.characters_left' | translate}}</span>
            </mat-form-field>
        </div>
        </div>
    </form>
</div>
<app-upload-document [is_disable]="(product_data && product_data.product_state_id == 2)" [object_data]="parentObjectData" [object_type]="uploadObjectDetails" [insurer_list]="selected_insurer_list" [document_list]="selected_document_list"
    [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)">
</app-upload-document>

<app-clauses-exclusions [showPopup]="showClausesExclusionPopup()" [comingFrom]="comingFrom" [data]="clausesOrExclusionData"
 (hidePopup)="hideClausesExclusionPopup()" [sparteFixerCode]="sparteFixerCode" (clauses_exclusions_data)="getClausesExclusionsData($event)">
</app-clauses-exclusions>

<div class="modal modelBackground" id="myModal" [style.display]="sparteChangedDialog ? 'block' : 'none'" style="overflow: scroll;">
    <div class="modal-dialog" style="top: 166px;max-width: 450px;">
        <div class="modal-content">
            <div class="modal-body" style="font-size:12px">
                <div style="display: flex;justify-content: center;">
                    <img alt="" height="65px" src="../../../../assets/images/Path 91988 (1).svg" >
                </div>
                <div>
                    <p class="popup-heading">{{'user-data.Sind_Sie' | translate}}</p>
                </div>
                <div style="display: grid;justify-content: center;text-align: center;">
                    <p class="popup-message">
                        {{'product-management.general_information.selected_clauses_discarded' | translate}}
                    </p>
                    <p class="popup-message" style="margin-top: 0px;"> 
                        {{'product-management.general_information.sure_you_want_to_change' | translate}}
                    </p>
                </div>
                <br />
            </div>
            <div style="display: flex;justify-content: center;padding: 1rem;">
                <button type="button" (click)="closePopup()" class="btn btn-warning ml-2 noButton">
                    {{'objectModule.No' | translate }}
                </button>
                <button type="button" (click)="removeClausesExclusions()" class="btn btn-warning ml-2 yesButton">
                    {{'objectModule.Yes' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
