import { TranslateService } from '@ngx-translate/core';
import {
  RowSides,
  RowTypes,
  IfElseTypes,
} from '../../../constants/formula.constants';
import { row_object } from './formula.model';
import { BaseComponent } from '@shared/components/base.component';
import { Directive } from '@angular/core';

@Directive()
export class FormulaMakerBaseComponent extends BaseComponent {
  readonly ROWTYPES = RowTypes;
  readonly ROWSIDES = RowSides;
  readonly IfElseTypes = IfElseTypes;
  fromIFBrackets = 'fromIFBrackets';
  formula_object: row_object[] = [
    {
      is_plus_button: true,
      row_type_id: null,
      is_add_row_button: false,
      is_operator_row: false,
      is_number_row: false,
      is_text_row: false,
      index: 0,
    },
    {
      is_plus_button: false,
      row_type_id: null,
      is_add_row_button: true,
      is_operator_row: false,
      is_text_row: false,
      is_number_row: false,
      index: 1,
    },
  ];
  row_types = [
    {
      id: 3,
      label: this._translate.instant('value-calculator.Pattern'),
      is_heading: true,
      is_if_else: true,
    },
    {
      id: RowTypes.NUMBER,
      label: this._translate.instant('value-calculator.number'),
      is_heading: false,
      is_if_else: false,
    },
    {
      id: RowTypes.LHS_RHS,
      label: 'LHS-RHS',
      is_heading: false,
      is_if_else: true,
    },
    {
      id: RowTypes.If_Else,
      label: 'If...Else',
      is_heading: false,
      is_if_else: false,
    },
    {
      id: RowTypes.TEXT,
      label: 'Text',
      is_heading: false,
      is_if_else: false,
    },
  ];
  data_source_types = [
    {
      id: 1,
      label: this._translate.instant('value-calculator.Input-Field'),
      is_heading: true,
    },
    {
      id: 2,
      label: this._translate.instant('value-calculator.number'),
      is_heading: false,
    },
    {
      id: 3,
      label: this._translate.instant('value-calculator.Data-Source'),
      is_heading: true,
    },
    {
      id: 12,
      label: this._translate.instant('value-calculator.Product-Module'),
      is_heading: false,
    },
    {
      id: 11,
      label: this._translate.instant('value-calculator.Object-Module'),
      is_heading: false,
    },
    {
      id: 14,
      label: this._translate.instant('value-calculator.Contract'),
      is_heading: false,
    },
  ];
  operators = [
    {
      id: 1,
      label: this._translate.instant('value-calculator.Mathematical-Operator'),
      is_heading: true,
    },
    { id: 2, label: '+', is_heading: false },
    { id: 3, label: '-', is_heading: false },
    { id: 4, label: '*', is_heading: false },
    { id: 5, label: '/', is_heading: false },
    { id: 6, label: '==', is_heading: false },
    { id: 7, label: '!=', is_heading: false },
  ];
  operatorsIfElse = [
    {
      id: 1,
      label: this._translate.instant('value-calculator.logical-Operator'),
      is_heading: true,
    },
    { id: 8, label: '>', is_heading: false },
    { id: 9, label: '<', is_heading: false },
    { id: 10, label: '>=', is_heading: false },
    { id: 11, label: '<=', is_heading: false },
    { id: 12, label: '==', is_heading: false },
    { id: 13, label: '!=', is_heading: false },
  ];
  constructor(public _translate: TranslateService) {
    super();
  }
}
