import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddContactPersonRequest } from '@components/insurer-management/models/insurer-management.models';
import { CP_API_URLS } from '@shared/constants/general/contact-person.constants';
import { Api_URL } from '@shared/constants/url.constants';

@Injectable({
  providedIn: 'root',
})
export class ContactPersonApiService {
  constructor(private http: HttpClient) {}

  getContactPersonOverview(request: any): Observable<any> {
    return this.http.post(
      `${Api_URL.IC_API_URL}${CP_API_URLS.CONTACT_PERSON_OVERVIEW}`,
      request
    );
  }

  getContactPersonById(id: number, source: string): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${CP_API_URLS.CONTACT_PERSON}/${id}`,
      { params: this._getHttpParamsWithSourceType(source) }
    );
  }

  getContactPersonRoles(source: string): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${CP_API_URLS.CONTACT_PERSON_ROLE}`,
      { params: this._getHttpParamsWithSourceType(source) }
    );
  }

  addContactPersonRole(source: string, requestData: any): Observable<any> {
    return this.http.post(
      `${Api_URL.IC_API_URL}${CP_API_URLS.CONTACT_PERSON_ROLE}`,
      requestData,
      { params: this._getHttpParamsWithSourceType(source) }
    );
  }

  deleteContactPersonById(id: number): Observable<any> {
    return this.http.delete(
      `${Api_URL.IC_API_URL}${CP_API_URLS.CONTACT_PERSON}/${id}`
    );
  }

  addContactPerson(cpRequest: AddContactPersonRequest): Observable<any> {
    return this.http.post(
      `${Api_URL.IC_API_URL}${CP_API_URLS.CONTACT_PERSON}`,
      cpRequest
    );
  }

  private _getHttpParamsWithSourceType(source: string): HttpParams {
    return new HttpParams().append('source_type', source);
  }
}
