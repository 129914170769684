import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PRIORITY_OPTIONS } from '../../../constants/priority.constants';
import { FilterSelectOption } from '../../table/filter-section/filter-section.component';
import * as moment from 'moment';
// import { AttendeeApiService } from '@core/services/httpcalls/attendee-api.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base.component';
import { DATE_ISO_FORMAT } from '@shared/constants/common.constants';
import { TaskApiService } from '@core/services/httpcalls/task-api.service';

@Component({
  selector: 'app-create-task-popup',
  templateUrl: './create-task-popup.component.html',
  styleUrls: ['./create-task-popup.component.scss'],
})
export class CreateTaskPopupComponent extends BaseComponent implements OnInit {
  taskForm: FormGroup;
  assignedToOptions: FilterSelectOption[] = [];
  priorityOptions = PRIORITY_OPTIONS;
  searchControl = new FormControl();
  minDate = new Date();

  constructor(
    private _dialogRef: MatDialogRef<CreateTaskPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fb: FormBuilder,
    private _taskApiService: TaskApiService
    // private _attendeeApiService: AttendeeApiService
  ) {
    super();
  }

  ngOnInit() {
    // this._loadAttendees();
    this._buildForm();
  }

  closeModal(): void {
    this._dialogRef.close();
  }

  resetSearch(): void {
    this.searchControl.reset();
  }

  confirm(): void {
    if (this.taskForm.invalid) {
      return;
    }

    const body = {
      title: this.taskForm.get('title').value,
      description: this.taskForm.get('description').value,
      priority: this.taskForm.get('priority').value,
      dueDate: moment(this.taskForm.get('dueDate').value).format(
        DATE_ISO_FORMAT
      ),
    };

    this._taskApiService
      .createTask(body)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._dialogRef.close(true);
      });
  }

  private _buildForm(): void {
    // TODO: add validation
    this.taskForm = this._fb.group({
      title: [this._data?.task?.title || null, Validators.required],
      description: [this._data?.task?.description || null, Validators.required],
      priority: [this._data?.task?.priority || null],
      dueDate: [
        this._data?.task?.dueDate || moment().add(2, 'weeks').toDate(),
        Validators.required,
      ],
      // assignees: [
      //   this._data?.task
      //     ? this._data?.task.assignees?.map(person => person.email)
      //     : [],
      // ],
      isNotify: [this._data?.task?.isNotify || false, Validators.required],
    });
  }

  // private _loadAttendees(): void {
  //   this._attendeeApiService
  //     .getAttendees()
  //     .pipe(takeUntil(this._destroy$))
  //     .subscribe(data => {
  //       this.assignedToOptions = data.map(attendee => ({
  //         label: attendee.name,
  //         value: attendee.email,
  //         data: {
  //           imgSrc: attendee.profilePictureUrl,
  //         },
  //       }));
  //     });
  // }
}
