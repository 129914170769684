<div>
  <span class="criteria-heading">{{'objectModule.printing_companies' | translate}}</span>
</div>

<form  class="row object-module-html form-align"  [formGroup]="PrintingForm" id="PrintingForm" *ngIf="PrintingForm">

  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.fire_resistance' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_fire_resistant_separated_paper_store">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.offset_printing' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_offset_printing">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.rotary_printing' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_rotary_printing">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.flammable_solvents' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_use_of_flammable_solvents">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.roller_laundry' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_roller_laundry">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.color_kitchen' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_mixing_and_stirring_system">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.solvant_recovery' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_solvent_recovery_plant">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label for="" class="long-label"> {{'objectModule.extraction' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_extraction_of_flammable_vapour">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

</form>
