<div style="padding: 0px 10px" *ngIf="!addNewPage">
    <div class="flex-end-align">
        <button class="custom-btn save-btn add-new-feature-btn"
            *ngIf="!isCustomerUser" (click)="addNewProduct(null)">
            <img alt="" [src]="FORM_ICONS.ADD">
            <span> {{'product-management.Configure_a_new_product' | translate}}</span>
        </button>
    </div>

    <div class="filter-distance" id="productManagement">
        <mat-accordion>
            <mat-expansion-panel
                class="filter-section"  
                (opened)="customerFilterToggle = true"
                (closed)="customerFilterToggle = false" 
                [expanded]="customerFilterToggle" hideToggle>
                <mat-expansion-panel-header #panelH 
                    class="filter-section-header p-unset"
                    [expandedHeight]="customFilterExpandedHeight">
                    <mat-panel-title class="filter-section-title-alignment filter-title">
                        <div class="flex-center g-7">
                            <button class="default-unset">
                                <mat-icon *ngIf="customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="!customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_right</mat-icon>
                            </button>
                            <span>
                                <strong>FILTER </strong>
                            </span>
                        </div>
                        <div class="paginator-dropdown-container" (click)="$event.stopPropagation()">
                            <mat-select (selectionChange)="numberOfRowsChanged($event)"
                                [(ngModel)]="selectedCountOfRows" panelClass="rowChange"
                                class="paginator-dropdown filter-section-dropdown" disableOptionCentering>
                                <mat-option *ngFor="let row of numberOfRows" [value]="row">
                                    {{row}}
                                </mat-option>
                            </mat-select>
                            <span class="results-per-page">{{'claims.results_per_page' | translate}}</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <form class="filter-form" [formGroup]="formFilter">
                    <div class="f-13 flex-column gap-3">
                        <div class="row">
                            <div class="col-4 flex-gap-2">
                                <span>{{'product-management.insurer' | translate}}</span>
                                <mat-select multiple
                                    placeholder="{{'objectModule.select_lable' | translate}}"
                                    formControlName="insurer"
                                    (selectionChange)="insurerChange($event);searchInsurer.value =''"
                                    (openedChange)="searchInsurer.value = '';">
                                    <input type="text" class="searchSelect" maxlength="20"
                                        placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                        preventSpace
                                        #searchInsurer>
                                    <mat-option [hidden]="insurer.hide"
                                        *ngFor="let insurer of  validationService._filterMultiSelect(insurer_list, 'desc', searchInsurer.value)"
                                        title="{{insurer.desc}}" [value]="insurer.id">
                                        {{insurer.desc}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="col-4 flex-gap-2">
                                <span>{{'product-management.sparte' | translate}}</span>
                                <mat-select
                                    (selectionChange)="sparteChange($event);searchSparte.value =''"
                                    [attr.title]="sparteTooltip ? sparteTooltip.description : ''"
                                    placeholder="{{'objectModule.select_lable' | translate}}"
                                    formControlName="sparte"
                                    (openedChange)="searchSparte.value = '';">
                                    <input type="text" class="searchSelect" maxlength="20"
                                        placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                                        preventSpace
                                        #searchSparte>
                                    <mat-option
                                        *ngFor="let sparte of  validationService._filter(sparte_list, 'description', searchSparte.value)"
                                        title="{{sparte.description}}" [value]="sparte.fixer_code">
                                        {{sparte.description}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="col-4 flex-gap-2">
                                <span>{{'product-management.product' | translate}}</span>
                                <mat-select
                                    (selectionChange)="productChange($event);searchProduct.value = ''" multiple
                                    placeholder="{{'objectModule.select_lable' | translate}}"
                                    formControlName="product" (selectionChange)="searchProduct.value =''"
                                    (openedChange)="searchProduct.value = '';">
                                    <input type="text" class="searchSelect" maxlength="20" [formControl]="productControl"
                                        placeholder="{{'header.Suche_tooltip' | translate}}"
                                        matInput #searchProduct preventSpace>
                                    <mat-option [hidden]="product.hide"
                                        *ngFor="let product of validationService._filterMultiSelect(product_list, 'desc', searchProduct.value)"
                                        title="{{product.desc}}" [value]="product.id">
                                        {{product.desc}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 flex-gap-2">
                                <span>{{'product-management.division' | translate}}</span>
                                <mat-select multiple
                                    placeholder="{{'objectModule.select_lable' | translate}}"
                                    formControlName="division"
                                    (selectionChange)="divisionChange($event);searchDivision.value =''"
                                    (openedChange)="searchDivision.value = '';">
                                    <input type="text" [formControl]="divisionControl" preventSpace
                                        class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
                                        matInput maxlength="20" #searchDivision>
                                    <mat-option [hidden]="division.hide"
                                        *ngFor="let division of validationService._filterMultiSelect(division_list, 'division_name', searchDivision.value)"
                                        title="{{division.division_name}}" [value]="division.division_id">
                                        {{division.division_name}}
                                    </mat-option>
                                </mat-select>
                            </div>

                            <div class="col-3 vertically-center">
                                <mat-checkbox (change)="hvmProductSelected($event)" formControlName="hvm_product">
                                    {{'product-management.Hide_IC_Products' | translate}}
                                </mat-checkbox>
                            </div>

                            <div class="col-5">
                                <div class="flex-end-align filter-btn-gap">
                                    <button class="filterBtn" 
                                        (click)="getFormsValue(formFilter.value)"
                                        type="submit">
                                        {{'user-data.Filter_text' | translate}}
                                    </button>
                                    <button class="filterBtn" color="warn"
                                        placement="top"
                                        tooltipClass="generic-tooltip"
                                        ngbTooltip=" {{'new-document.Filter_löschen' | translate}}"
                                        (click)="resetFilter()">
                                        <img alt="" height="16px" src="assets/images/Icon material-refresh.png" /> 
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    
    <div class="table-outer-container">
        <div class="infiniteScrollTable" style="height: calc(96vh - 368px);">
            <mat-table 
                class="custom-table" mat-table
                [dataSource]="dataSource" 
                cellpadding="5" cellspacing="0" 
                matSort
                (matSortChange)="announceSortChange($event)">
                <ng-container matColumnDef="product_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span class="header-with-sorting">
                            {{'product-management.product_name' | translate}}
                            <img alt="" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip" title="{{element.product_name}}">
                            <span class="custom-pill byIvm" *ngIf="element.is_ivm_product == true">ERP</span>
                            <span class="custom-pill byRcs" *ngIf="element.is_ivm_product == false">IC</span>
                            {{element.product_name}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="active_contracts">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span class="header-with-sorting">
                            {{'product-management.active_contracts' | translate}}
                            <img alt="" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip">
                            {{element.active_contracts}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="product_state_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span class="header-with-sorting">
                            {{'product-management.status_label' | translate}}
                            <img alt="" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip">
                            {{getProductName(element.product_state_id)}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="version">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span class="header-with-sorting">
                            {{'product-management.version' | translate}}
                            <img alt="" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip ">
                            {{element.version}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="insurer_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'product-management.insurer_name' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span 
                            *ngFor="let item of element.insurer_name;index as i"
                            title="{{element.insurer_name}}" class="noSafariTooltip "
                            style="word-break: break-word;">
                            <span *ngIf="element.insurer_name.length > 1">
                                <span *ngIf="i == 0"><span>{{item}}</span> + {{element.insurer_name.length -
                                    1}}</span>
                            </span>
                            <span *ngIf="element.insurer_name.length == 1" class="ellipsis">
                                {{element.insurer_name}}
                            </span>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="division_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>{{'product-management.division_name' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span *ngFor="let item of element.division_name;index as i"
                            title="{{element.division_name}}" class="noSafariTooltip "
                            style="word-break: break-word;">
                            <span *ngIf="element.division_name.length > 1">
                                <span *ngIf="i == 0"><span>{{item}}</span> + 
                                {{element.division_name.length - 1}}</span>
                            </span>
                            <span *ngIf="element.division_name.length == 1" class="ellipsis">
                                {{element.division_name}}
                            </span>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="created_at">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span class="header-with-sorting">
                            {{'product-management.Created_by' | translate}}
                            <img alt="" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip">
                            {{element.created_by}}<br />
                            <span class="table-below-text">{{element.created_at | dateFormat:'dd.MM.yyyy HH:mm'}}</span>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="last_updated_on">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span class="header-with-sorting">
                            {{'product-management.Last_updated_by' | translate}}
                            <img alt="" class="arrow-image" src="assets/images/ic-icons/Sort.svg">
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="custom-ellipsis noSafariTooltip">
                            {{element.last_updated_by}}<br />
                            <span class="table-below-text">{{element.last_updated_on | dateFormat:'dd.MM.yyyy HH:mm'}}</span>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ACTION">
                    <mat-header-cell *matHeaderCellDef style="justify-content: center;">
                        <span>{{'objectModule.Aktion' | translate}}</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
                        <div class="control-section" style="text-align: center;">
                            <div class="subscribe">
                                <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                                <div class="hide">
                                    <div class="action-btn-align" (click)="$event.stopPropagation()">
                                        <button
                                            placement="top"
                                            class="action-btn"
                                            tooltipClass="generic-tooltip"
                                            ngbTooltip="{{'product-management.Attach_Template'| translate}}"
                                            [disabled]="element.isIvmProduct" (click)="openPop(element)">
                                            <img class="action-btn-img" src='assets/images/Attach_Template_Icon.svg'>
                                        </button>
                                        <button
                                            placement="top"
                                            class="action-btn"
                                            tooltipClass="generic-tooltip"
                                            ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                                            [disabled]="element.isIvmProduct"
                                            (click)="editRow(element.product_info_id)">
                                            <img class="action-btn-img" src='assets/images/Group 2659.svg'>
                                        </button>
                                        <button
                                            placement="top"
                                            class="action-btn"
                                            tooltipClass="generic-tooltip"
                                            ngbTooltip="{{'objectModule.COPY_tooltip' | translate}}"
                                            [disabled]="element.isIvmProduct"
                                            (click)="openCopyModal(element,i)">
                                            <img class="action-btn-img" src='assets/images/Duplicate_icon.svg'>
                                        </button>
                                        <button 
                                            placement="top"
                                            class="action-btn"
                                            tooltipClass="generic-tooltip"
                                            ngbTooltip="{{'user-data.Löschen_tooltip'| translate}}"
                                            [disabled]="element.isIvmProduct"
                                            (click)="openModal(element.product_info_id,i)"
                                            >
                                            <img class="action-btn-img" src='assets/images/Group 2658.svg'>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
        <div class="table-footer">
            <div class="paginator-footer">
                <ngb-pagination [collectionSize]="total_elements" (pageChange)="tablePageChanged($event)"
                    [maxSize]=5 [(page)]="tablePage" [pageSize]="selectedCountOfRows"
                    [boundaryLinks]="false">
                    <ng-template ngbPaginationPrevious>
                        <i class="fa fa-angle-left"></i>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <i class="fa fa-angle-right"></i>
                    </ng-template>
                </ngb-pagination>
                <div class="footer-text">
                    <span>
                        {{'product-management.Number_of_products' | translate}} : {{ total_elements }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal modelBackground" id="deleteDocumentModel"
    [style.display]="showModal ? 'table' : 'none'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-content" style="
        box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
        border: solid 1px #bbb;
        background-color: #fff;">
                <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                    <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                        <mat-icon (click)="hideModal()">
                            <img alt="" height="65px" src="../../../assets/images/Group 3235.svg">
                        </mat-icon>
                    </div>
                </div>
                <div class="modal-body" style="font-size:12px; overflow: auto;text-align: center;">
                    <span style="font-weight: 600;font-size: 21px;">{{'objectModule.Delete_popup_heading' |
                        translate}}</span>
                    <br /><br />
                    <span style="font-weight: 600;font-size: 16px;margin-top:5px">{{'objectModule.Delete_popup_text' |
                        translate}}</span>
                </div>
                <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                    <button type="button" style="font-size: 15px;color: #272727;border-radius: 2px;padding: 0px 22px 0px 22px;
          border: solid 1px #272727;font-weight: 600;" mat-button (click)="hideModal()">
                        {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp;
                    <button class="ml-2" type="button"
                        style="font-size: 15px;color: #272727;background: #ff9000;;font-weight: 600;padding: 0px 22px 0px 22px;border: solid 1px #ff9000;"
                        mat-button (click)="deleteRow(deleteItem,deleteIndex)">
                        {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="copyDocumentModel modelBackground"
    [style.display]="showCopyModal ? 'table' : 'none'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-content" style="
        box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
        border: solid 1px #bbb;
        background-color: #fff;">
                <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                    <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                        <mat-icon (click)="hideCopyModal()">
                            <img alt="" height="65px" src="../../../assets/images/copy_confirmation_warning_icon.svg">
                        </mat-icon>
                    </div>
                </div>
                <div class="modal-body" style="font-size:12px; overflow: auto;text-align: center;">
                    <span style="font-weight: 600;font-size: 21px;">{{'objectModule.Delete_popup_heading' |
                        translate}}</span>
                    <br /><br />
                    <span style="font-weight: 600;font-size: 16px;margin-top:5px">{{'product-management.Copy_popup_text'
                        | translate}}</span>
                </div>
                <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                    <button type="button" style="font-size: 15px;color: #272727;border-radius: 2px;padding: 0px 22px 0px 22px;
          border: solid 1px #272727;font-weight: 600;" mat-button (click)="hideCopyModal()">
                        {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp;
                    <button class="ml-2" type="button"
                        style="font-size: 15px;color: #272727;background: #ff9000;;font-weight: 600;padding: 0px 22px 0px 22px;border: solid 1px #ff9000;"
                        mat-button (click)="addNewRow(copyelement,copyIndex)">
                        {{'objectModule.COPY_tooltip' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="addNewPage">
    <product-base-stepper (changeInProductManagement)="changeInProductManagement($event)"></product-base-stepper>
</div>

<app-attach-template 
    *ngIf="showAttachTemplate" 
    [is_disable]="false" 
    [selectedDocumentsArray]="[]"
    [showDocumentPopup]="showAttachTemplate" 
    (hide)="showAttachTemplate = false">
</app-attach-template>

<app-view-template-list 
    *ngIf="available_templates" 
    [showTemplatePopup]="showTemplatePopup"
    [available_templates]="available_templates" 
    [selected_templates]="selected_templates"
    [product_info_id]="product_info_id" 
    (hide)="hidePopup()">
</app-view-template-list>