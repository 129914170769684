<app-header></app-header>

<div class="container-ic registerClaimThree">
    <div style="background-color: #ffffff" class="">
        <div style="
            width: 90%;
            height: 100%;
            min-height: 78vh;
            margin: 0 auto;
            white-space: nowrap;">
            <div>
                <h6 style="color: #272727; font-size: 18px; margin-top: 9%">
                    <strong><span>/</span>&nbsp;&nbsp; Register claims</strong>
                </h6>
            </div>
            <div>
                <div style="height: 52px;background-color: rgba(196, 196, 196, 0.17);">
                    <div style="display: flex;justify-content: center;padding-top: 10px;">
                        <button class="navButton">1</button>
                        <hr style="width: 4%;margin: 16px 0px;border-top: 2px solid #ff9000">
                        <button class="navButton">2</button>
                        <hr style="width: 4%;margin: 16px 0px;border-top: 2px solid #ff9000">
                        <button class="navButton"
                            style="border: 1px solid #ff9000;box-shadow: 0 0 0 2px #fff inset;">3</button>
                    </div>
                </div>
            </div>

            <ng-container>
                <form name="search" [formGroup]="formData" (ngSubmit)="onSubmit()"
                    style="margin-top: 10px;font-size: 13px; color: #4e4e4e">
                    <div class="serach-row userdata col-md-12" style="clear: both; padding: 0">
                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 pl-0 labelStyle required">Schadenatum</label>
                                <mat-form-field [ngClass]="{ 'is-invalid': submitted && f.schadenatum.errors }"
                                    class="date-picker col-md-7" appearance="fill">
                                    <span class="placeholderStyle">
                                        <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">&nbsp;
                                    </span>
                                    <input class="placeholderStyle" matInput [matDatepicker]="picker1"
                                        placeholder="dd/mm/yyyy" formControlName="schadenatum">
                                    <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="picker1">
                                        <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>


                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Uhrzeit</label>
                                <mat-form-field [ngClass]="{ 'is-invalid': submitted && f.Uhrzeit.errors }"
                                    class="col-md-7" appearance="fill">
                                    <span class="placeholderStyle"><img
                                            src="../../../assets/images/Icon ionic-md-time.png" >&nbsp;
                                    </span>
                                    <input class="placeholderStyle" matInput placeholder="00:00:00"
                                        formControlName="Uhrzeit">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle required">Kennzeichen</label>
                                <mat-form-field class="col-md-7" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="Kennzeichen"
                                        formControlName="Kennzeichen">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle required">Schadenort</label>
                                <mat-form-field class="col-md-7 address-field" appearance="fill">
                                    <input class="placeholderStyle" matInput name="location-list"
                                        placeholder="Kein Standort ausgewählt" autocomplete="off" #loc id="location"
                                        list="locationList" (keyup)="autoComplete($event)"
                                        (change)="selectedLocation($event)" formControlName="Schadenort">
                                    <datalist id="locationList">
                                        <option *ngFor="let location of filterlocation"
                                            [attr.value]="location.freeformAddress"></option>
                                    </datalist>
                                    <mat-icon style="position: absolute;" matSuffix *ngIf="showActive == false"><img
                                            class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg"
                                            width="15px" height="20px"></mat-icon>
                                    <mat-icon style="position: absolute;" matSuffix *ngIf="showActive == true"><img
                                            class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg"
                                            width="15px" height="20px"></mat-icon>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Schadenhergang</label>
                                <mat-form-field class="text-area col-md-7" appearance="fill">
                                    <textarea matInput class="" placeholder="Text" formControlName="Schadenhergang"
                                        rows="6" cols="50" [(ngModel)]="Schadenhergang" maxlength="255"></textarea>
                                    <span style="margin-top: 6.4rem;color: #797f96;
                                        font-size: 11px;
                                        position: absolute;
                                        margin-left: -5rem;" class="pull-right">{{ 255 - Schadenhergang?.length }}
                                        Characters left</span>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Beschädigte Sache / Teile </label>
                                <mat-form-field class="text-area col-md-7" appearance="fill">
                                    <textarea matInput placeholder="Text" rows="6" cols="50" formControlName="Teile"
                                        [(ngModel)]="Teile" maxlength="255"></textarea>
                                    <span style="margin-top: 6.4rem;color: #797f96;
                                        font-size: 11px;
                                        position: absolute;
                                        margin-left: -5rem;" class="pull-right">{{ 255 - Teile?.length }} Characters
                                        left</span>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Angaben Geschädigter</label>
                                <mat-form-field class="col-md-7" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="Angaben Geschädigter"
                                        formControlName="Angaben">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Wo können beschädigte Teile
                                    besichtigt werden </label>
                                <mat-form-field class="col-md-7 address-field" appearance="fill">
                                    <input class="placeholderStyle" matInput name="location-list"
                                        placeholder="Kein Standort ausgewählt" autocomplete="off" #loc id="location"
                                        list="locationList" (keyup)="autoComplete($event)"
                                        (change)="selectedLocation($event)" formControlName="können">
                                    <datalist id="locationList">
                                        <option *ngFor="let location of filterlocation"
                                            [attr.value]="location.freeformAddress"></option>
                                    </datalist>
                                    <mat-icon style="position: absolute;" matSuffix *ngIf="showActive == false"><img
                                            class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg"
                                            width="15px" height="20px"></mat-icon>
                                    <mat-icon style="position: absolute;" matSuffix *ngIf="showActive == true"><img
                                            class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg"
                                            width="15px" height="20px"></mat-icon>

                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Geschätze Schadenhöhe</label>
                                <select class="placeholderStyle col-md-2 selectDropdown" placeholder="Währung"
                                    formControlName="Währung">
                                    <option value="" disabled selected>Währung</option>
                                    <option value="eur">eur</option>
                                    <option value="euro">euro</option>
                                </select>

                                <mat-form-field style="margin-right: 2.5rem;" class="col-md-4 inputStyle ml-auto"
                                    appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="Geschätze Schadenhöhe"
                                        formControlName="Geschätze">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Vorsteuerabzugsbe-rechtigung
                                    / Abzugssatz </label>
                                <mat-form-field class="col-md-7" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="Vorsteuerabzugsbe-rechtigung "
                                        formControlName="Vorsteuerabzugsbe">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Aktenzeichen VN</label>
                                <mat-form-field class="col-md-7" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="Aktenzeichen VN"
                                        formControlName="Aktenzeichen">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Glasbruch </label>
                                <mat-radio-group class="col-md-7 radio-button" aria-label="Select an option">
                                    <mat-radio-button value="Ja">Ja</mat-radio-button>
                                    <mat-radio-button value="Nein">Nein</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Auszahlungskonto</label>
                                <mat-form-field class="col-md-7" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="Auszahlungskonto"
                                        formControlName="Auszahlungskonto">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Kontakt für Rückfragen </label>
                                <mat-checkbox class="example-margin">Email</mat-checkbox>
                                <mat-form-field class="col-md-4" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="" formControlName="email">
                                </mat-form-field>

                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Polizeiliche Aufnahme j/n
                                    wenn j dann Aktenzeichen </label>
                                <mat-radio-group class="col-md-3 radio-button" aria-label="Select an option">
                                    <mat-radio-button (click)="enablePolizeiliche()" value="Ja">Ja</mat-radio-button>
                                    <mat-radio-button value="Nein">Nein</mat-radio-button>
                                </mat-radio-group>

                                <mat-form-field class="col-md-4" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder=""
                                        formControlName="Polizeiliche">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle"> </label>
                                <mat-checkbox class="example-margin">Mobile</mat-checkbox>
                                <mat-form-field class="col-md-4" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="" formControlName="mobile">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Bilder</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Bilder">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn" (click)="uploadDoc()"><img
                                        src="../../../assets/images/ic-icons/Upload.svg" ></button>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Wertnachweis / urspüngl </label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Wertnachweis">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">Kostenvoranschlag/Rechnung</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Rechnung">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">AGB / Einkaufsbedingungen</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="AGB">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle">8D Report / Mängelanzeige</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Mängelanzeige">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Skizzen/ Zeichnungen</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Skizzen">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle"> Auftragsunterlagen</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Auftragsunterlagen">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle">Allgemeiner Schriftwechsel </label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Allgemeiner">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>
                        </div>
                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle"> Lieferschein</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Lieferschein">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle"> Haftbarhaltung </label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Haftbarhaltung">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                                <label for="" class="col-md-5 labelStyle"> Frachtunterlagen</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Frachtunterlagen">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle"> Polizeiliche
                                    Meldebescheinigung</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Meldebescheinigung">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>
                        </div>

                        <div class="row" style="margin-left: 0px; ">
                            <div class="col-md-6 pl-0 ml-0 d-flex">
                            </div>

                            <div class="col-md-6 d-flex">
                                <label for="" class="col-md-5 labelStyle"> Sonstiges</label>
                                <mat-form-field class="col-md-6" appearance="fill">
                                    <input class="placeholderStyle" matInput placeholder="keine Datei ausgewählt"
                                        formControlName="Sonstiges">
                                </mat-form-field>
                                <button class="col-md-1 uploadBtn"><img alt="" src="../../../assets/images/ic-icons/Upload.svg"
                                        ></button>
                            </div>
                        </div>

                        <div class="text-center">
                            <button (click)="navigateStepTwo()" class="nextBtn mr-2">zurück</button>
                            <button id="Weiter" style="background-color: #c7c7c7;"
                                class="nextBtn ml-2">speichern</button>
                        </div>
                    </div>
                </form>
            </ng-container>

            <div class="modal upload-logo modelBackground" id="myModal" [style.display]="openDocUpload ? 'block' : 'none'"
                style="z-index: 4;overflow: scroll;">
                <div class="modal-dialog" style="top: 149px;
            right: 98px;">
                    <div class="modal-content" style="width: 762px;
                height: 556px;
              
                object-fit: contain;
                border-radius: 10px;
                box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
                background-color: #fff;
                border: none;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;">

                        <!-- Modal Header -->
                        <div class="modal-header">
                            <h6>Datei-Upload </h6>
                            <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                                <img alt="" src="../../../assets/images/Group 3114.svg" >
                            </button>

                        </div>
                        <form>
                            <!-- Modal body -->
                            <div class="modal-body" style="font-size:12px;height: 254px;">
                                <div style="text-align:center">
                                    <div class="row">
                                        <div class="col-md-6" style="height: 400px;border: dotted 3px #797f96;
                               margin: 2px 4px 0 19px;flex: 0 0 46%; object-fit: contain; background-color: #f8f8f8;">
                                            <button style="border: none;background: none;padding-top: 179px;"> <img
                                                    src="../../../assets/images/Group 2790.png" > </button>
                                            <p class="mt-3" style="
                                    font-size: 18px;
                                    font-weight: 600;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: 1.27;
                                    letter-spacing: normal;
                                    text-align: center;
                                    color: #797f96;
                                    white-space: break-spaces;">Dateien zum Hochladen ablegen oder
                                                <span> stöbern</span>
                                            </p>
                                        </div>
                                        <div class="col-md-6" style="padding-top: 350px;">
                                            <button class="nextBtn">upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
