<div>
    <span class="criteria-heading">{{'objectModule.electroplating_object.electroplating' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="electroplatingForm" id="electroplatingForm">
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.electroplating_integral' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="electroplating_integral" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.is_second_plant' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="is_second_plant" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.electroplating_object.number_of_baths' | translate}}
        </label>
        <input type="text" maxlength="100" class="input" formControlName="number_of_baths"
            placeholder="{{'objectModule.electroplating_object.number_of_baths_placeholder' | translate}}"
            title="{{electroplatingForm.controls['number_of_baths'].value}}"
        >
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.electroplating_object.age_of_equpiment' | translate}}
        </label>
        <input type="text" class="input" maxlength="100" formControlName="age_of_equpiment"
            placeholder="{{'objectModule.electroplating_object.age_of_equpiment' | translate}}"
            title="{{electroplatingForm.controls['age_of_equpiment'].value}}"
            >
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">
          {{'objectModule.electroplating_object.floor_area' | translate}}
        </label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input" type="text" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',6)" (paste)="typeNumber($event, 'paste',6)"
        formControlName="floor_area" placeholder="{{'objectModule.electroplating_object.floor_area_placeholder' | translate}}"
        (blur)="validation.sqmValidation(electroplatingForm, $event)" (focus)="validation.sqmValidation(electroplatingForm, $event)"  numbersOnly>
        <input class="input" *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',6)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',6)"
        formControlName="floor_area" placeholder="{{'objectModule.electroplating_object.floor_area_placeholder' | translate}}"
        (blur)="validation.sqmValidation(electroplatingForm, $event)" (focus)="validation.sqmValidation(electroplatingForm, $event)"  numbersOnly>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.fireproof_partition' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="fireproof_partition" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.fire_alarm_system' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="fire_alarm_system" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.extinguishing_system' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="extinguishing_system" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.is_safe_to_shutdown' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="is_safe_to_shutdown" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.are_baths_made_of_plastic' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="are_baths_made_of_plastic" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.are_steam_water_heated' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="are_steam_water_heated" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.is_an_overflow_protection' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="is_an_overflow_protection" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.are_ventilation_ducts' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="are_ventilation_ducts" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.is_there_unsupervised' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="is_there_unsupervised" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.are_level_switches' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="are_level_switches" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.are_hazard_prevention_plans' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="are_hazard_prevention_plans" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.electroplating_object.are_chemicals_stored_at_unsafe_place' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="are_chemicals_stored_at_unsafe_place" >
            <mat-radio-button [value]="true">
                {{'objectModule.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>


    <div [ngClass]="electroplatingForm.value.is_thermographic_test ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="is_thermographic_test">{{'objectModule.electroplating_object.thermographic_test' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="electroplatingForm.value.is_thermographic_test" class="row toggle-section-content">
            <div class="col-md-3 col-sm-6 col-6">
                <label for="" class="long-label">{{'objectModule.electroplating_object.upload_thermographic_test' | translate}}</label>
                <div style="display: flex;">
                  <div class="documentUploadInput">
                      <input class="input" readonly placeholder="{{'objectModule.document_heading' | translate}}"
                      title="{{electroplatingForm.controls['upload_thermographic_test'].value}}" formControlName="upload_thermographic_test">
                  </div>

                  <div class="documentUploadButton">
                  <button class="docButton" type="button" (click)="uploadThermographicDocumentPopup()">
                   <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
                  </button>
                  </div>
                </div>
              </div>
        </div>
    </div>

    <div [ngClass]="electroplatingForm.value.are_electric_rod ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="are_electric_rod">{{'objectModule.electroplating_object.are_electric_rod' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="electroplatingForm.value.are_electric_rod" class="row toggle-section-content">
            <div class="col-md-3 col-sm-6 col-6">
                <label for="" class="long-label">{{'objectModule.electroplating_object.are_safety_clearances' | translate}}</label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="are_safety_clearances" >
                    <mat-radio-button [value]="true">
                        {{'objectModule.yes' | translate}}
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                        {{'objectModule.no' | translate}}
                    </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="col-md-3 col-sm-6 col-6">
                <label for="" class="long-label">{{'objectModule.electroplating_object.are_self_sufficient' | translate}}</label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="are_self_sufficient" >
                    <mat-radio-button [value]="true">
                        {{'objectModule.yes' | translate}}
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                        {{'objectModule.no' | translate}}
                    </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="electroplatingForm.value.has_risk_analysis_carried ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
          <mat-slide-toggle formControlName="has_risk_analysis_carried">{{'objectModule.electroplating_object.has_risk_analysis_carried' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="electroplatingForm.value.has_risk_analysis_carried" class="row toggle-section-content">
        <div class="col-md-3 col-sm-6 col-6">
            <label for="" class="long-label">{{'objectModule.electroplating_object.resulting_measures' | translate}}</label>
            <div class="radio-group-align-long">
                <mat-radio-group aria-label="" formControlName="resulting_measures" >
                <mat-radio-button [value]="true">
                    {{'objectModule.yes' | translate}}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    {{'objectModule.no' | translate}}
                </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        </div>
    </div>
</form>
<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails"
  [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()"
  (hide)="hidePopup($event)">
</app-upload-document>