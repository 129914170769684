import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@core/services/toast.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { LOCALS } from '@shared/constants/local.constants';
import { takeUntil } from 'rxjs/operators';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent extends BaseComponent implements OnInit {
  showModal: boolean;
  user_role: USER_ROLES;
  isICUser;
  url: string;
  customer_id;
  form: FormGroup;
  file: File;
  selectedFile;
  formControl: FormGroup;
  message = '';
  logo: any;
  message_NoLogo = false;
  sparteManagement = false;
  userDataValue: any;
  customerName: any;
  isCustomerSelected = false;
  pageBreadcrumbText = 'settings.EINSTELLUNGEN_text';
  isCustomerAdmin = false;

  constructor(
    private serviceCall: ApiCallsService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private _toastService: ToastService,
    private readonly _userService: UserService,
    private domSanitizer: DomSanitizer,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
    this.formControl = this.fb.group({
      file: [''],
      customerId: [''],
    });
  }

  ngOnInit(): void {
    this._watchOnUserRole();
    this._customerSharedService.saveCustomerName(null);
  }

  userDataReceived(data): void {
    this.userDataValue = data;
    this.customerName = data.user_profile.customer_details.name;
  }

  Onactionprocess(): void {
    this.showModal = true;
  }

  onSelectFile(event): void {
    this.message_NoLogo = false;
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.size < 1048576) {
      const pattern = /image-*/;
      const reader = new FileReader();
      if (!this.selectedFile.type.match(pattern)) {
        return;
      }
      this.url = this.selectedFile.name.split('.').pop();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(this.selectedFile);
    } else {
      alert(
        this._userService.getLocale() === LOCALS.ENGLISH
          ? 'The file cannot be larger than 1 MB.'
          : 'Datei darf nicht größer als 1 MB sein.'
      );
      this.selectedFile = '';
      this.url = '';
      this.formControl.reset();
    }
  }

  _handleReaderLoaded(e): void {
    const reader = e.target;
    this.url = reader.result;
  }

  hide(): void {
    this.formControl.reset();
    this.showModal = false;
    this.message_NoLogo = false;
    this.url = '';
  }

  save(): void {
    this.message_NoLogo = false;
    this.customer_id =
      this.userDataValue.user_profile.customer_details.customer_id;
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('customer_id', this.customer_id);
    formData.append('user_role', this.user_role.toString());
    this.serviceCall
      .uploadCustomerLogo(formData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(async data => {
        if (data.HasErrors == false) {
          this._toastService.showToastMessage(
            document,
            this.translate.instant('settings.LogoHochgeladen_info')
          );
          const delay = ms => new Promise(res => setTimeout(res, ms));
          await delay(1000);
          this.refreshPage();
        }
      });
  }

  refreshPage(): void {
    const currentUrl = this.router.url;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([currentUrl]));
  }

  Onuserprocess(): void {
    this.router.navigateByUrl(ROUTES_PATH.USER_PROFILE);
  }

  deleteLogo(): void {
    this.customer_id =
      this.userDataValue.user_profile.customer_details.customer_id;
    this.serviceCall
      .getLogo(this.customer_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        const objectURL = window.URL.createObjectURL(data);
        this.logo = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
        if (this.logo !== null && this.logo !== '') {
          this.serviceCall
            .deleteLogo(this.customer_id, this.user_role)
            .pipe(takeUntil(this._destroy$))
            .subscribe(async data => {
              if (data.HasErrors == false) {
                this.showModal = false;
                this._toastService.showToastMessage(
                  document,
                  this.translate.instant(
                    'settings.LogoGelöscht_info this.message'
                  )
                );
                const delay = ms => new Promise(res => setTimeout(res, ms));
                await delay(1000);
                this.refreshPage();
              }
            });
        } else {
          this.message_NoLogo = true;
        }
      });
  }

  sparteComponent(): void {
    this.sparteManagement = true;
  }

  sparteManagementOpen(): void {
    this.router.navigateByUrl('sparte-management');
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
        this.isCustomerAdmin = this.user_role === USER_ROLES.CUSTOMER_ADMIN;
      });
  }
}
