import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ZursGeoPaymentComponent } from './zurs-geo-payment/zurs-geo-payment.component';
import { ZursGeoComponent } from './zurs-geo.component';
import { UserService } from '@core/user.service';

@Component({
  selector: 'app-mat-zurs-geo',
  templateUrl: './zurs-geo.component.html',
  styleUrls: ['./zurs-geo.component.css'],
})
export class ZursMathGeoComponent extends ZursGeoComponent {
  constructor(
    serviceCall: ApiCallsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ZursGeoPaymentComponent>,
    public zursGeodialogRef: MatDialogRef<ZursGeoComponent>,
    dialog: MatDialog,
    userService: UserService
  ) {
    super(serviceCall, dialog, userService);
    if (this.data && this.data.address) {
      this.fillAddress();
    }
  }

  fillAddress(): void {
    const address = JSON.parse(this.data.address);
    this.city_name = address.city;
    this.city = false;
    this.house_num = address.house_no;
    this.house = false;
    this.lat = address.lat;
    this.lon = address.lon;
    this.showActive = this.lat !== '' && this.lon !== '';
    this.zip_code = address.postal_code.toString();
    this.zip = false;
    this.street_num = address.street;
    this.street = false;
    this.selectedLoc = address.address;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
