import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateError',
})
export class TranslateErrorPipe implements PipeTransform {
  transform(value: any): string {
    let rvalue = '';
    if (value !== null) {
      if (value === 'allFieldRequire_validation') {
        rvalue = 'update-user.allFieldRequire_validation';
      }
      if (value === 'email_validation') {
        rvalue = 'update-user.email_validation';
      }
      if (value === 'Nachname_validation') {
        rvalue = 'update-user.Nachname_validation';
      }
      if (value === 'Vorname_validation') {
        rvalue = 'update-user.Vorname_validation';
      }
      if (value === 'Anrede_validation') {
        rvalue = 'update-user.Anrede_validation';
      }
      if (value === 'Hausnummer_validation') {
        rvalue = 'update-user.Hausnummer_validation';
      }
      if (value === 'Straße_validation') {
        rvalue = 'update-user.Straße_validation';
      }
      if (value === 'Postleitzahl_validation') {
        rvalue = 'update-user.Postleitzahl_validation';
      }
      if (value === 'Telefon_validation') {
        rvalue = 'update-user.Telefon_validation';
      }
      if (value === 'Land_validation') {
        rvalue = 'update-user.Land_validation';
      }
      if (value === 'Stadt_validation') {
        rvalue = 'update-user.Stadt_validation';
      }
      // BELOW VALIDATION KEYS ARE FROM VALUES COMPONENT
      if (value === 'Eingegebene_validation') {
        rvalue = 'valuesComponent.Eingegebene_validation';
      }
      if (value === 'dieAnzhal_validation') {
        rvalue = 'valuesComponent.dieAnzhal_validation';
      }
      if (value === 'derMaximale_validation') {
        rvalue = 'valuesComponent.derMaximale_validation';
      }
      if (value === 'bitteGültigen_validation') {
        rvalue = 'valuesComponent.bitteGültigen_validation';
      }
      if (value === 'negativeEingaben_validation') {
        rvalue = 'valuesComponent.negativeEingaben_validation';
      }

      // BELOW ARE THE KEYS FROM SETTINGS COMPONENT
      if (value === 'dateiDarfNicht_alert') {
        rvalue = 'settings.dateiDarfNicht_alert';
      }

      // BELOW ARE THE KEYS FROM SEARCH COMPONENT
      if (value === 'keinSuchergebnis_info') {
        rvalue = 'searchComponent.keinSuchergebnis_info';
      }
      // BELOW ARE THE KEYS FROM USER-DATA COMPONENT

      if (value === 'upsBeiDer_alert') {
        rvalue = 'header.upsBeiDer_alert';
      }

      // Below Are the keys from zurs Component
      if (value === 'b_brook_zone_text') {
        rvalue = 'zurs-geo.b_brook_zone_text';
      }
      if (value === 'B_brook_zone_text') {
        rvalue = 'zurs-geo.B_brook_zone_text';
      }
      if (value === 'k_costal_zone_text') {
        rvalue = 'zurs-geo.k_costal_zone_text';
      }
      if (value === 'K_costal_zone_text') {
        rvalue = 'zurs-geo.K_costal_zone_text';
      }
      if (value === 'i_island_zone_text') {
        rvalue = 'zurs-geo.i_island_zone_text';
      }
      if (value === 'I_island_zone_text') {
        rvalue = 'zurs-geo.I_island_zone_text';
      }
    }
    return rvalue;
  }
}
