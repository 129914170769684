/**
 * Node for to-do item
 */
 export class ItemNodeList {
    children: ItemNodeList[];
    item: string;
    itemId: number;
    isLastElement: boolean;
    isSelected: boolean;
    parentNode?: ItemNodeList;
    sequence_number: number;
  }

  /** Flat to-do item node with expandable and level information */
 export class ItemFlatNode {
    item: string;
    flatNodeIds?: number;
    itemId: number;
    level: number;
    expandable: boolean;
    isLastElement: boolean;
    isSelected: boolean;
    parentNode?: ItemNodeList;
    sequence_number: number;
  }
