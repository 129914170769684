import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
@Component({
  selector: 'app-pellete-plant',
  templateUrl: './pellete-plant.component.html',
  styleUrls: ['./pellete-plant.component.css'],
})
export class PelletePlantComponent implements OnInit {
  pelletPlantForm: FormGroup;
  compartmentArea = [];
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService
  ) {
    this.serviceCall
      .getLookupData(24)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.data) {
          this.compartmentArea = data.data.sort((a, b) => a.id - b.id);
        }
      });
  }

  ngOnInit(): void {
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  initForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.PELLET_PLANT
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[BuildingConstants.PELLET_PLANT]
          .saved_criteria
      );
    this.assignAttributeValues(formData);
    this.isFormValueUpdated = false;
    this.pelletPlantForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.pelletPlantForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  assignAttributeValues(formData: any) {
    const fire_compartment_area = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].fire_compartment_area
          : null,
        disabled: false,
      },
      []
    );
    const is_sprinkler_system = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_sprinkler_system : null,
        disabled: false,
      },
      []
    );
    const is_grates_in_filling_openings = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].is_grates_in_filling_openings
          : null,
        disabled: false,
      },
      []
    );
    const is_separation_of_foreign_matter_upon_delivery = new FormControl(
      {
        value: formData
          ? formData['pellete-plant']
              .is_separation_of_foreign_matter_upon_delivery
          : null,
        disabled: false,
      },
      []
    );
    const is_wood_storage = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_wood_storage : null,
        disabled: false,
      },
      []
    );
    const is_magnets_and_sieves_before_further_internal_transport =
      new FormControl(
        {
          value: formData
            ? formData['pellete-plant']
                .is_magnets_and_sieves_before_further_internal_transport
            : null,
          disabled: false,
        },
        []
      );
    const is_fire_alarm_system = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_fire_alarm_system : null,
        disabled: false,
      },
      []
    );
    const is_structural_or_spatial_fire_compartment_separation =
      new FormControl(
        {
          value: formData
            ? formData['pellete-plant']
                .is_structural_or_spatial_fire_compartment_separation
            : null,
          disabled: false,
        },
        []
      );
    const is_chip_heating_with_burn_back_protection = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].is_chip_heating_with_burn_back_protection
          : null,
        disabled: false,
      },
      []
    );

    const is_production_shredder_hammer_mill = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].is_production_shredder_hammer_mill
          : null,
        disabled: false,
      },
      []
    );

    const is_metal_separator_before_entering_processing = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].production_shredder_hammer_mill
              .is_metal_separator_before_entering_processing
          : null,
        disabled: false,
      },
      []
    );
    const is_gravity_separator_before_entering_processing = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].production_shredder_hammer_mill
              .is_gravity_separator_before_entering_processing
          : null,
        disabled: false,
      },
      []
    );
    const is_water_spray_protection_one = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].production_shredder_hammer_mill
              .is_water_spray_protection
          : null,
        disabled: false,
      },
      []
    );
    const is_grinding_chamber = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].production_shredder_hammer_mill
              .is_grinding_chamber
          : null,
        disabled: false,
      },
      []
    );
    const is_spark_extinguishing_system_at_the_discharge_one = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].production_shredder_hammer_mill
              .is_spark_extinguishing_system_at_the_discharge
          : null,
        disabled: false,
      },
      []
    );

    const is_drying = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_drying : null,
        disabled: false,
      },
      []
    );

    const is_park_extinguishing_system_at_the_entry_and_exit_of_the_drying =
      new FormControl(
        {
          value: formData
            ? formData['pellete-plant'].drying
                .is_park_extinguishing_system_at_the_entry_and_exit_of_the_drying
            : null,
          disabled: false,
        },
        []
      );
    const is_park_extinguishing_systems_in_the_drying_exhaust_air_ducts =
      new FormControl(
        {
          value: formData
            ? formData['pellete-plant'].drying
                .is_park_extinguishing_systems_in_the_drying_exhaust_air_ducts
            : null,
          disabled: false,
        },
        []
      );
    const is_pray_water_extinguishing_system_in_the_dryer = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].drying
              .is_pray_water_extinguishing_system_in_the_dryer
          : null,
        disabled: false,
      },
      []
    );
    const is_rase_above_and_below_the_bands = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].drying.is_rase_above_and_below_the_bands
          : null,
        disabled: false,
      },
      []
    );
    const is_temperature_monitoring_with_automatic_shutdown = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].drying
              .is_temperature_monitoring_with_automatic_shutdown
          : null,
        disabled: false,
      },
      []
    );

    const is_pellet_mill = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_pellet_mill : null,
        disabled: false,
      },
      []
    );

    const is_temperature_monitoring = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].pellet_mill.is_temperature_monitoring
          : null,
        disabled: false,
      },
      []
    );
    const is_moisture_measurement = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].pellet_mill.is_moisture_measurement
          : null,
        disabled: false,
      },
      []
    );
    const is_spark_extinguishing_system_at_the_discharge_two = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].pellet_mill
              .is_spark_extinguishing_system_at_the_discharge
          : null,
        disabled: false,
      },
      []
    );
    const is_water_spray_extinguishing_system_in_the_pellet_cooler =
      new FormControl(
        {
          value: formData
            ? formData['pellete-plant'].pellet_mill
                .is_water_spray_extinguishing_system_in_the_pellet_cooler
            : null,
          disabled: false,
        },
        []
      );
    const is_regular_die_change = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].pellet_mill.is_regular_die_change
          : null,
        disabled: false,
      },
      []
    );

    const is_silos = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_silos : null,
        disabled: false,
      },
      []
    );

    const is_water_spray_protection_two = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_water_spray_protection
          : null,
        disabled: false,
      },
      []
    );
    const is_manual_water_feed_one = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_manual_water_feed
          : null,
        disabled: false,
      },
      []
    );
    const is_spark_detection = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_spark_detection
          : null,
        disabled: false,
      },
      []
    );
    const is_structural_separation = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_structural_separation
          : null,
        disabled: false,
      },
      []
    );
    const is_inerting_option = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_inerting_option
          : null,
        disabled: false,
      },
      []
    );
    const is_dry_line = new FormControl(
      {
        value: formData ? formData['pellete-plant'].silos.is_dry_line : null,
        disabled: false,
      },
      []
    );
    const is_printingload = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_printingload
          : null,
        disabled: false,
      },
      []
    );
    const is_separated_by_firewall_one = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_separated_by_firewall
          : null,
        disabled: false,
      },
      []
    );
    const is_spatially_separated_one = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].silos.is_spatially_separated
          : null,
        disabled: false,
      },
      []
    );

    const is_filter_system = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_filter_system : null,
        disabled: false,
      },
      []
    );

    const is_water_spray_protection_three = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].filter_system.is_water_spray_protection
          : null,
        disabled: false,
      },
      []
    );
    const is_manual_water_feed_two = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].filter_system.is_manual_water_feed
          : null,
        disabled: false,
      },
      []
    );
    const is_separated_by_firewall_two = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].filter_system.is_separated_by_firewall
          : null,
        disabled: false,
      },
      []
    );
    const is_spatially_separated_two = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].filter_system.is_spatially_separated
          : null,
        disabled: false,
      },
      []
    );

    const is_conveyors = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_conveyors : null,
        disabled: false,
      },
      []
    );

    const is_regular_belt_tension_check = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].conveyors.is_regular_belt_tension_check
          : null,
        disabled: false,
      },
      []
    );
    const is_regular_lubrication_of_rotating_parts = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].conveyors
              .is_regular_lubrication_of_rotating_parts
          : null,
        disabled: false,
      },
      []
    );
    const is_speed_monitor_on_drive_and_idler_pulleys = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].conveyors
              .is_speed_monitor_on_drive_and_idler_pulleys
          : null,
        disabled: false,
      },
      []
    );
    const is_belt_misalignment_monitoring_with_automatic_transport_shutdown =
      new FormControl(
        {
          value: formData
            ? formData['pellete-plant'].conveyors
                .is_belt_misalignment_monitoring_with_automatic_transport_shutdown
            : null,
          disabled: false,
        },
        []
      );
    const is_devices_for_separating_out_foreign_objects_and_magnetic_separators =
      new FormControl(
        {
          value: formData
            ? formData['pellete-plant'].conveyors
                .is_devices_for_separating_out_foreign_objects_and_magnetic_separators
            : null,
          disabled: false,
        },
        []
      );
    const is_grounding_of_all_conductive_parts = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].conveyors
              .is_grounding_of_all_conductive_parts
          : null,
        disabled: false,
      },
      []
    );
    const is_dust_extraction_in_the_head_part_of_the_system = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].conveyors
              .is_dust_extraction_in_the_head_part_of_the_system
          : null,
        disabled: false,
      },
      []
    );
    const is_protection_by_spark_extinguishing_system = new FormControl(
      {
        value: formData
          ? formData['pellete-plant'].conveyors
              .is_protection_by_spark_extinguishing_system
          : null,
        disabled: false,
      },
      []
    );

    const annotations = new FormControl(
      {
        value: formData ? formData['pellete-plant'].annotations : null,
        disabled: false,
      },
      []
    );
    const is_annotation = new FormControl(
      {
        value: formData ? formData['pellete-plant'].is_annotation : null,
        disabled: false,
      },
      []
    );

    this.pelletPlantForm = this.fb.group({
      fire_compartment_area,
      is_sprinkler_system,
      is_grates_in_filling_openings,
      is_separation_of_foreign_matter_upon_delivery,
      is_wood_storage,
      is_magnets_and_sieves_before_further_internal_transport,
      is_fire_alarm_system,
      is_structural_or_spatial_fire_compartment_separation,
      is_chip_heating_with_burn_back_protection,
      is_production_shredder_hammer_mill,
      production_shredder_hammer_mill: this.fb.group({
        is_metal_separator_before_entering_processing,
        is_gravity_separator_before_entering_processing,
        is_water_spray_protection: is_water_spray_protection_one,
        is_grinding_chamber,
        is_spark_extinguishing_system_at_the_discharge:
          is_spark_extinguishing_system_at_the_discharge_one,
      }),
      is_drying,
      drying: this.fb.group({
        is_park_extinguishing_system_at_the_entry_and_exit_of_the_drying,
        is_park_extinguishing_systems_in_the_drying_exhaust_air_ducts,
        is_pray_water_extinguishing_system_in_the_dryer,
        is_rase_above_and_below_the_bands,
        is_temperature_monitoring_with_automatic_shutdown,
      }),
      is_pellet_mill,
      pellet_mill: this.fb.group({
        is_temperature_monitoring,
        is_moisture_measurement,
        is_spark_extinguishing_system_at_the_discharge:
          is_spark_extinguishing_system_at_the_discharge_two,
        is_water_spray_extinguishing_system_in_the_pellet_cooler,
        is_regular_die_change,
      }),
      is_silos,
      silos: this.fb.group({
        is_water_spray_protection: is_water_spray_protection_two,
        is_manual_water_feed: is_manual_water_feed_one,
        is_spark_detection,
        is_structural_separation,
        is_inerting_option,
        is_dry_line,
        is_printingload,
        is_separated_by_firewall: is_separated_by_firewall_one,
        is_spatially_separated: is_spatially_separated_one,
      }),
      is_filter_system,
      filter_system: this.fb.group({
        is_water_spray_protection: is_water_spray_protection_three,
        is_manual_water_feed: is_manual_water_feed_two,
        is_separated_by_firewall: is_separated_by_firewall_two,
        is_spatially_separated: is_spatially_separated_two,
      }),
      is_conveyors,
      conveyors: this.fb.group({
        is_regular_belt_tension_check,
        is_regular_lubrication_of_rotating_parts,
        is_speed_monitor_on_drive_and_idler_pulleys,
        is_belt_misalignment_monitoring_with_automatic_transport_shutdown,
        is_devices_for_separating_out_foreign_objects_and_magnetic_separators,
        is_grounding_of_all_conductive_parts,
        is_dust_extraction_in_the_head_part_of_the_system,
        is_protection_by_spark_extinguishing_system,
      }),
      annotations,
      is_annotation,
    });
  }
  buildFormObject() {
    const formValuesToPersist = {
      'pellete-plant': this.pelletPlantForm.value,
    };
    formValuesToPersist['pellete-plant'].fire_compartment_area =
      formValuesToPersist['pellete-plant'].fire_compartment_area
        ? formValuesToPersist['pellete-plant'].fire_compartment_area
        : null;
    this.request = {
      criteria_id: BuildingConstants.PELLET_PLANT,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.pelletPlantForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
