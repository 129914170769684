export const PM_COLUMN_NAMES = {
  PAYEE_LIST: 'payee_name',
  AMOUNT: 'amount',
  PAYMENT_DATE: 'payment_date',
  REMARK1: 'remark1',
  REMARK2: 'remark2',
  ACTION: 'action',
};

export const PM_COLUMN_HEADINGS = {
  PAYEE_LIST: 'new-claims.payment_management.payee_list',
  AMOUNT: 'new-claims.payment_management.amount',
  PAYMENT_DATE: 'new-claims.payment_management.payment_date',
  REMARK1: 'new-claims.payment_management.remark1',
  REMARK2: 'new-claims.payment_management.remark2',
  ACTION: 'new-claims.contact_person.action',
};

export const PM_MULTILINGUALS = {
  TABLE_HEADING: 'new-claims.payment_management.payments',
  ADD_PAYMENTS: 'new-claims.action_bar.add_payment_details',
};

export const PM_COLUMNS = [
  PM_COLUMN_NAMES.PAYEE_LIST,
  PM_COLUMN_NAMES.AMOUNT,
  PM_COLUMN_NAMES.PAYMENT_DATE,
  PM_COLUMN_NAMES.REMARK1,
  PM_COLUMN_NAMES.REMARK2,
  PM_COLUMN_NAMES.ACTION,
];

export const CLAIM_PAYMENT_FIELDS = {
  PAYEE_LIST: 'payee_list',
  AMOUNT: 'amount',
  PAYMENT_DATE: 'payment_date',
  REMARK1: 'remark1',
  REMARK2: 'remark2',
};

export const PAYMENT_ACTION_METHOD_NAMES = {
  EDIT_PAYMENT: 'editPayment',
  DELETE_PAYMENT: 'deletePayment',
};

export enum PaymentDetailsColumns {
  PAYMENT_NUMBER = 'pmt_number',
  PAYMENT_DATE = 'pmt_date',
  PAYMENT_AMOUNT = 'pmt_value',
  REMART_1 = 'remark_1',
  REMARK_2 = 'remark_2',
}

export const PAYMENT_DETAILS_COLUMNS = [
  PaymentDetailsColumns.PAYMENT_NUMBER,
  PaymentDetailsColumns.PAYMENT_DATE,
  PaymentDetailsColumns.PAYMENT_AMOUNT,
  PaymentDetailsColumns.REMART_1,
  PaymentDetailsColumns.REMARK_2,
];

export const PAYMENT_DETAILS_COLUMN_HEADING = {
  PAYMENT_NUMBER: 'claims.Payment_number_text',
  PAYMENT_DATE: 'claims.Payment_date_text',
  PAYMENT_AMOUNT: 'claims.Payment_amount_text',
  REMART_1: 'claims.Remark_1',
  REMARK_2: 'claims.Remark_2',
};
