import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { SelectedDocument } from '@shared/models/upload-data.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { GenericAlertComponent } from '../generic-alert-dialog/generic-alert-dialog.component';
@Component({
  selector: 'app-attach-template',
  templateUrl: './attach-template.component.html',
  styleUrls: ['./attach-template.component.css'],
})
export class AttachTemplateComponent implements OnInit {
  constructor(
    private _apiCallsService: ApiCallsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public validationService: ValidationService,
    private dialog: MatDialog,
    private _toastService: ToastService
  ) {
    this.uploader = new FileUploader({
      url: '',
      disableMultipart: true,
      autoUpload: true,
    });
  }
  @Input('selectedDocumentsArray') selectedDocumentsArray;
  @Input('showDocumentPopup') showDocumentPopup;
  @Input('is_disable') is_disable;
  @Output('hide') hide = new EventEmitter<any>();
  @ViewChild('fileUpload') fileUpload: any;
  documents = [];
  uploadButtonDisable: boolean;
  documentMessage: any;
  docNameToDelete: string;
  cloudDocId: any;
  uploader: FileUploader;
  showModal: boolean;
  enableIndex: any = '';
  docType = [];
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  }
  ngOnInit(): void {
    this.uploadButtonDisable = true;
    this.documentType();
  }
  documentType() {
    this._apiCallsService.getContractDocType().subscribe(data => {
      this.docType = data.data;
    });
  }
  areFilesAvailable(): boolean {
    this.uploadButtonDisable = !this.areDocsAvailableForUpload();
    return Object.keys(this.selectedDocumentsArray).length > 0;
  }
  handleFileInput(inputFile) {
    if (
      !inputFile ||
      (inputFile && this.selectedDocumentsArray[inputFile[0].name])
    ) {
      return;
    }

    if (inputFile[0].size >= 36700160) {
      alert(this.translate.instant('objectModule.uploadFileLimit'));
    } else {
      this.uploadButtonDisable = false;
      const newDoc: SelectedDocument = {
        id: Object.keys(this.selectedDocumentsArray).length + 1,
        document_description: '',
        document_name: inputFile[0].name,
        document_type: '',
        is_obj_linked: null,
        document_link: '',
        file: inputFile[0],
      };
      if (inputFile[0].type == 'application/x-zip-compressed') {
        const jsZip = require('jszip');
        jsZip.loadAsync(inputFile[0]).then(zip => {
          let error = false;
          Object.keys(zip.files).forEach(_ => {
            const name = _.split('.');
            if (name.length != 1) {
              if (name[name.length - 1].toLowerCase() != 'jrxml') {
                error = true;
              }
            } else {
              delete zip.files[_];
            }
          });
          if (!error) {
            newDoc.title = Object.keys(zip.files).join(',');
            this.selectedDocumentsArray[inputFile[0].name] = newDoc;
          } else {
            this.genericAlert('product-management.jrxml_error');
          }
        });
      } else {
        this.selectedDocumentsArray[inputFile[0].name] = newDoc;
      }
    }
  }
  editDescription(id, index) {
    this.enableIndex = index;
    document.getElementById('edit' + index).style.display = 'none';
    document.getElementById('save' + index).style.display = 'initial';
    document.getElementById('docDescription' + id).removeAttribute('disabled');
    document.getElementById('docDescription' + id).focus();
    document.getElementById('docDescription' + id).style.borderBottom =
      '1.5px solid #8f8f8f';
  }
  getDocDesc(item) {
    return 'docDescription' + item.value.id || item.value.upload_document_id;
  }
  saveDescription(id, name, index, docId) {
    this.enableIndex = '';
    document.getElementById('save' + index).style.display = 'none';
    document.getElementById('edit' + index).style.display = 'initial';
    document.getElementById('docDescription' + id).blur();
    document.getElementById('docDescription' + id).style.outline = 'none';
    document
      .getElementById('docDescription' + id)
      .setAttribute('disabled', 'true');
    document.getElementById('docDescription' + id).style.borderBottom = 'none';
  }
  deleteDocument(event) {
    if (!event.cloudDocId) {
      this.cleanUpDocument(event);
    }
  }
  cleanUpDocument(event) {
    delete this.selectedDocumentsArray[event.name];
    this.uploadButtonDisable = !this.areDocsAvailableForUpload();
    this.onHideModal(false);
    this.showToastMessage(
      this.translate.instant('objectModule.documentDeleteMessage')
    );
    this.fileUpload.nativeElement.value = '';
  }
  attachTemplateEnabled(): boolean {
    return this.uploadButtonDisable;
  }
  processDelete(docNameToDelete, cloudDocId = null) {
    this.docNameToDelete = docNameToDelete;
    this.cloudDocId = cloudDocId;
    this.showModal = true;
  }
  onHideModal(event) {
    this.showModal = event;
  }
  areDocsAvailableForUpload(): boolean {
    return (
      Object.keys(this.selectedDocumentsArray).filter(
        fileName => this.selectedDocumentsArray[fileName].is_obj_linked == null
      ).length > 0
    );
  }
  showToastMessage(message) {
    this._toastService.showToastMessage(document, message);
  }
  getTooltip(link) {
    return link
      ? this.translate.instant('objectModule.uploadFileHint')
      : this.translate.instant('objectModule.emptyFileUploadHint');
  }
  hidePopup() {
    this.fileUpload.nativeElement.value = '';
    Object.keys(this.selectedDocumentsArray).forEach(name => {
      if (!this.selectedDocumentsArray[name].upload_document_id) {
        delete this.selectedDocumentsArray[name];
      }
    });
    this.hide.emit(this.selectedDocumentsArray);
  }
  getDocType(id) {
    if (id && this.docType.length) {
      return this.docType.find(_ => _.id == id).desc;
    }
    return '';
  }
  getTeplate(text: string) {
    return text.split(',');
  }
  genericAlert(key) {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(key),
      },
      disableClose: false,
    });
  }
}
