export const LookupConstants = {
    PRODUCT_STATE: 46,
    CANCELLATION_PERIOD: 47,
    COUNTRY: 48,
    COVERAGE_AREA: 49,
    CLAIM: 106,
    CONTRACT: 105,
};
export const LookupStrings = {
    DEDUCTIBLE_TYPE: 'deductible_type',
    TYPE_0F_COVERING: 'type_0f_covering',
    TYPE_OF_PAYMENT: 'type_of_payment',
    KIND_OF_INSURANCE: 'kind_of_insurance',
    DATA_SOURCE: 'data_source',
    MAXIMIZATION: 'maximization',
    ACTION_BAR: 'action_bar',
    TEMPLATE_REPOSITORY: 'template_repository',
    DECKUNGS_TYP: 'Deckungs-Typ',
    SB_TYP: 'SB-Typ',
    ACTION_BAR_CUSTOMER: 'action_bar_customer',
    CUSTOMER_GENERAL_DATA: 'customer_general_data',
    LEGAL_FORM: 'legal_form',
    COMMUNICATION_TYPE: 'communication_type',
    BROKERAGE_MANDATE: 'brokerage_mandate',
    COLLECTION_TYPE: 'collection_type',
    PAYMENT_MODE: 'payment_mode'
 };

