import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { enableProdMode, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';
import { environment } from '../../../../environments/environment';
import { Country } from '@shared/interfaces/country.interface';
import { InsurerParticipationResponse } from '@shared/models/new-contract.model';
import { InsurerRatio } from '@shared/models/insurer.model';
import { GetContractDetailsResponse } from '@shared/models/contract-request.model';
import { ProductInfoResponse } from '@shared/models/product-management.model';
import { CustomerGeneralData } from '@shared/interfaces/customer-general-data.interface';
import { ClaimsObject } from '@modules/new-claims/models/general-claim-data.model';

if (environment.production) {
  enableProdMode();
}

@Injectable({
  providedIn: 'root',
})
export class ApiCallsService {
  constructor(private http: HttpClient) {}
  getContract(contractData): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_CONTRACT, contractData);
  }
  getClaims(claimData): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_CLAIM, claimData);
  }
  getUser(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_USER);
  }
  getDivision(userid): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${Api.GET_DIVISIONS}/${userid}`
    );
  }

  getDivisionList(): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_DIVISIONS + '?all_required=false'
    );
  }
  getOrganization(divisionid, role): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_ORGANIZATION + '/' + divisionid + '/' + role
    );
  }
  getSubsidiaries(customerId, user_idnr, role, is_source_ivm): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SUBSIDIARIES +
        '?customer_id=' +
        customerId +
        '&user_idnr=' +
        user_idnr +
        '&role=' +
        role +
        '&is_source_ivm=' +
        is_source_ivm
    );
  }
  getSubsidiariesCustomerManagement(
    customerId,
    user_idnr,
    role,
    is_source_ivm,
    is_customer_source_ivm?: any
  ): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SUBSIDIARIES +
        '?customer_id=' +
        customerId +
        '&user_idnr=' +
        user_idnr +
        '&role=' +
        role +
        '&is_source_ivm=' +
        is_source_ivm +
        '&is_customer_source_ivm=' +
        is_customer_source_ivm
    );
  }
  getUserList(formdata): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_USERS_LIST, formdata);
  }
  uploadCustomerLogo(formdata): Observable<any> {
    const headers = new HttpHeaders({
      charset: 'UTF-8',
    });
    return this.http.post(Api_URL.IC_API_URL + Api.IMAGE_UPLOAD, formdata, {
      headers,
    });
  }
  resetPassword(formdata): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.RESET_PASSWORD, formdata);
  }
  updateProfile(formdata): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.UPDATE_PROFILE, formdata);
  }
  userImage(formdata): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.UPLOAD_LOGO, formdata);
  }

  getSearchResult(searchResult): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.SEARCH_RESULT,
      searchResult
    );
  }
  getLogo(customerId): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_SECURE_LOGO + '?customer_id=' + customerId,
      {
        headers: { policy: 'true' },
        responseType: 'blob',
      }
    );
  }
  deleteLogo(customerId, user_role): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.DELETE_LOGO +
        '?customer_id=' +
        customerId +
        '&user_role=' +
        user_role
    );
  }
  getUserRole(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_USER_ROLE);
  }
  updateInviteUser(formdata): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.INVITE_USER, formdata);
  }
  getOTP(mfa_mode, preferedLanguage): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_OPT +
        '?mfa_mode=' +
        mfa_mode +
        '&language=' +
        preferedLanguage
    );
  }
  sendOTP(OTP): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.SEND_OPT, OTP);
  }
  resetInvite(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.RESET_INVITE, data);
  }
  getLang(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_LANG);
  }
  getActorTyp(role): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_ACTOR_TYPES + '?role=' + role
    );
  }
  getActor(actorTyp, division_id, role, userId): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_ACTOR +
        '?actor_type=' +
        actorTyp +
        '&division_id=' +
        division_id +
        '&role=' +
        role +
        '&user_id=' +
        userId
    );
  }
  getSparte(division, parentId, role): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SPARTE +
        '?divisionId=' +
        division +
        '&customer_id=' +
        parentId +
        '&role=' +
        role
    );
  }
  getProcess(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_PROCESSE);
  }
  getSubsidiary(formData): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SUBSIDIARY +
        '?divisionId=' +
        formData.divisionId +
        '&actor_type=' +
        formData.actor_type +
        '&liztu_vn_kdnr=' +
        formData.liztu_vn_kdnr +
        '&role=' +
        formData.role
    );
  }
  getCMdata(formdata): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_CM_DATA, formdata);
  }
  getEditor(divisionId, actor_id, actor_type, role): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_EDITOR +
        '?divisionId=' +
        divisionId +
        '&actor_type=' +
        actor_type +
        '&actor_id=' +
        actor_id +
        '&role=' +
        role
    );
  }
  getProduct(SparteId, is_sparte_group): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_PRODUCT +
        '?sparteId=' +
        SparteId +
        '&is_sparte_group=' +
        is_sparte_group
    );
  }
  getExtern(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_EXTERN);
  }
  getBereich(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_BEREICH);
  }
  postCMData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.POST_CM_DATA, data);
  }
  deleteCMData(CMid): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.DELETE_CM_DATA + '?contact_manager_id=' + CMid
    );
  }

  get_usage_behaviour(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_USAGE_BEHAVIOUR, data);
  }
  get_inventory_controlling(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_INVENTROY_CONTROLLING,
      data
    );
  }
  new_bigger_claims(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.NEW_BIGGER_CLAIMS, data);
  }
  getTopCustomers(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_TOP_CUSTOMERS, data);
  }
  getInventoryPenetrationByICProducts(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_INVENTORY_PENETRATION,
      data
    );
  }
  getCustomerStructureByPremium(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_CUSTOMER_BY_PREMIUM,
      data
    );
  }
  getInsureRatios(divisionId: number): Observable<InsurerRatio[]> {
    let params = new HttpParams();

    if (divisionId) {
      params = params.append('divisionId', divisionId);
    }

    return this.http.get<InsurerRatio[]>(
      Api_URL.IC_API_URL + Api.GET_INSURER_RATIOS,
      { params }
    );
  }
  getInventoryDistribution(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_INVENTORY_DISTRIBUTION,
      data
    );
  }
  getSparteGroup(
    division_id,
    parent_id,
    user_role,
    sparte_id
  ): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SPARTE_GROUP +
        '?divisionId=' +
        division_id +
        '&customer_id=' +
        parent_id +
        '&role=' +
        user_role +
        '&sparte_id=' +
        sparte_id
    );
  }
  post_sparte_group(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.POST_SPARTE_GROUP, data);
  }
  deleteSparteGroup(id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.DELETE_SPARTE_GROUP + '?sparte_group_id=' + id
    );
  }
  getCustomerByIndustry(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_CUSTOMER_STRUCTURE_BY_INDUSTRY,
      data
    );
  }
  check_is_unique(paramName, paramValue): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.IS_UNIQUE +
        '?paramName=' +
        paramName +
        '&paramValue=' +
        paramValue
    );
  }
  addNewCustomer(jwtToken, data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + jwtToken,
    });
    return this.http.post(Api_URL.IC_API_URL + Api.ADD_NEW_CUSTOMER, data, {
      headers,
    });
  }
  getUserRoleId(jwtToken, userRoleName): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + jwtToken,
    });
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_USER_ROLE_ID +
        '?user_role_name=' +
        userRoleName,
      { headers }
    );
  }
  getLanguageId(jwtToken, languageDisplayName): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + jwtToken,
    });
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_LANGUAGE_ID +
        '?language_display_name=' +
        languageDisplayName,
      { headers }
    );
  }
  getAutocomplete(subscription, query, language, countrySet): Observable<any> {
    return this.http.get(
      Api.GET_AUTO_COMPLETE +
        '?subscription-key=' +
        subscription +
        '&api-version=1&query={' +
        query +
        '}&language={' +
        language +
        '}&countrySet={' +
        countrySet +
        '}&view=Auto&limit=20 ',
      { headers: { skip: 'true' } }
    );
  }
  getPayment(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_PAYMENT, data);
  }
  getNewDocument(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_NEW_DOCUMENT, data);
  }
  getNewClaims(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_NEW_CLAIMS, data);
  }
  getContractsForCustomer(liztu_vn_kdnr): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_CONTRACT_FOR_COMPANY +
        '?liztu_vn_kdnr=' +
        liztu_vn_kdnr
    );
  }

  getContractsForuser(user_idnr, liztu_vn_kdnr): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_CONTRACTS_FOR_CUSTOMER +
        '?user_idnr=' +
        user_idnr +
        '&liztu_vn_kdnr=' +
        liztu_vn_kdnr
    );
  }

  getCustomerAddress(liztu_vn_kdnr): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SUB_CUSTOMER_DETAILS +
        '?liztu_vn_kdnr=' +
        liztu_vn_kdnr
    );
  }
  getUniqueSubsidiaries(customer_id, groupBy, role): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_UNIQUE_SUBSIDIARIES +
        '?customer_id=' +
        customer_id +
        '&byGroup=' +
        groupBy +
        '&role=' +
        role
    );
  }
  deleteUser(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.DELETE_USER, data);
  }
  getMasterData(liztu_vn_kdnr): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.MASTER_DATA + '?liztu_vn_kdnr=' + liztu_vn_kdnr
    );
  }
  updateMasterData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.MASTER_DATA, data);
  }
  getContactPerson(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_CONTACT_PERSON, data);
  }
  updateClaimReportData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_CLAIM_REPORT, data);
  }
  getMonitoringData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_MONITORING, data);
  }
  reverseLoggingWidget(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_REVERSELOGGING, data);
  }
  getZursPayment(flood, earth, snow) {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_ZURS_PRICE +
        '?is_flood=' +
        flood +
        '&is_snow=' +
        snow +
        '&is_earthquake=' +
        earth
    );
  }
  getZursAnalysis(data) {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_ZURS_ANALYSIS, data);
  }
  getZursData(data) {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_ZURS_ZONE_VALUE, data);
  }
  getCliamPaymentBreakup(liztu_sa_idnr) {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_CLAIM_PAYMENT_BREAKUP +
        '?liztu_sa_idnr=' +
        liztu_sa_idnr
    );
  }
  postWidgetVisitedCount(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.UPDATE_SIMPLEYGDPR_VISITS_COUNT,
      data
    );
  }
  getWidgetVisitedCount() {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_SIMPLYGDPR_VISITED_COUNT
    );
  }
  getObjectModuleData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_OBJECT_MODULE, data);
  }
  getAddresses(liztuVnKdnr) {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.NEW_OBJECT_ADDRESS_LIST +
        '?liztuVnKdnr=' +
        liztuVnKdnr
    );
  }
  saveAddress(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.SAVE_NEW_ADDRESS, data);
  }
  saveNewObject(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.SAVE_NEW_OBJECT, data);
  }
  deleteObjectModule(object_module_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.GET_OBJECT_MODULE_V2 + '/' + object_module_id
    );
  }
  getObjectType(liztu): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_OBJECT_TYPE + '?liztuVnKdnr=' + liztu
    );
  }
  getConstTypes() {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_CONST_TYPE);
  }
  getValueType(object_type_id) {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_VALUE_TYPE +
        '?object_type_id=' +
        object_type_id
    );
  }
  copyObjectModule(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.COPY_OBJECT_MODULE, data);
  }
  getObjectData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_OBJECT_DATA, data);
  }
  getBuildingTypes(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_TYPE_OF_USE);
  }
  getSequenceNumber(liztu, object_type_id, criteria_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SEQUENCE_NUMBER +
        '?liztu_vn_kdnr=' +
        liztu +
        '&object_type_id=' +
        object_type_id +
        '&criteria_id=' +
        criteria_id
    );
  }
  getExtinguishingSystems(object_type_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_EXTINGUISHING_SYSTEMS + '/' + object_type_id
    );
  }
  getLookupData(id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.OBJ_LOOKUP_DATA + '?lookup_id=' + id
    );
  }
  getCurrencies(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_CURRENCY_VALUES);
  }
  getCurrencyTypes(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_CURRENCY_TYPE);
  }
  uploadPhotovoltaikDocument(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.UPLOAD_OBJ_DOC, data);
  }
  deleteDocumentPhotoVoltaik(id, user_id, actor_type_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.OBJ_DOC_DELETE +
        '?upload_document_id=' +
        id +
        '&user_id=' +
        user_id +
        '&actor_type_id=' +
        actor_type_id
    );
  }
  updateDocumentDesPhotoVoltaik(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.UPDATE_DOC, data);
  }
  getDocumentPhotovoltik(recordId, objecTypeId): Observable<any> {
    let params = new HttpParams().append('record_id', recordId);
    if (objecTypeId) {
      params = params.append('object_type_id', objecTypeId);
    }
    return this.http.get(Api_URL.IC_API_URL + Api.OBJ_DOC, { params });
  }
  getPast100YearList(): number[] {
    return Array.from(Array(100).keys()).map(
      x => new Date().getFullYear() + 1 - x
    );
  }
  getBuildingOperatingMode(): Observable<any> {
    return this.getLookupData(10); // Lookup_type:operating_mode
  }
  getBuildingOperatingModeGGP(): Observable<any> {
    return this.getLookupData(52); // Lookup_type:operating_mode_GGP
  }
  getBuildingObjectTypes(): Observable<any> {
    return this.getLookupData(11); // Lookup_type:building_object_type
  }
  getBuildingDefects(): Observable<any> {
    return this.getLookupData(12); // Lookup_type:defects
  }
  addNewCriterias(data): Observable<any> {
    return this.http.put(Api_URL.IC_API_URL + Api.ADD_CRITERIA, data);
  }
  getFactorBasedOnYear(year): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.FACTORS + '?year=' + year);
  }
  getInsurer(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_INSURER);
  }
  getProducts(sparteId): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_PRODUCTS + '?sparte_id=' + sparteId
    );
  }
  getProductLookupData(id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.PRODUCT_LOOKUP_DATA + '?lookup_id=' + id
    );
  }
  getYearsData(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GETYEARS);
  }
  getInsurerList(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_INSURER_LIST);
  }
  getProductModule(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_PRODUCT_MODULE, data);
  }
  saveNewProduct(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.ADD_UPDATE_PRODUCT, data);
  }
  deleteProductModule(product_module_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.DELETE_PRODUCT_MODULE + '/' + product_module_id
    );
  }
  getProductData(product_module_id): Observable<ProductInfoResponse> {
    return this.http.get<ProductInfoResponse>(
      Api_URL.IC_API_URL +
        Api.DELETE_PRODUCT_MODULE +
        '?product_info_id=' +
        product_module_id
    );
  }
  addUpdateClauseOrExclusion(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.ADD_UPDATE_CLAUSE_EXCLUSION,
      data
    );
  }
  getClausesOrExclusions(is_clauses, sparte_fixer_code): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_CLAUSES_EXCLUSIONS +
        '?is_clauses=' +
        is_clauses +
        '&sparte_fixer_code=' +
        sparte_fixer_code
    );
  }
  deleteClauseOrExclusion(clause_exclusion_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.DELETE_CLAUSE_EXCLUSION +
        '/' +
        clause_exclusion_id
    );
  }
  getCurrenciesValue(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_CURRENCY_VALUES);
  }
  getProductLookups(lookupName): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.PRODUCT_LOOKUP_DATA_2 +
        '?lookup_type_name=' +
        lookupName
    );
  }
  getCustomerBankDetails(liztu_vn_kdnr): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_CUSTOMER_BANK_DETAILS +
        '?liztu_vn_kdnr=' +
        liztu_vn_kdnr
    );
  }
  stepperDataLookup(stepperId): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_STEPPER_LOOKUP_DATA +
        '?stepper_id=' +
        stepperId
    );
  }
  getValueCustomer(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_VALUE_CUSTOMER, data);
  }
  getValueQuestions(tabId): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_VALUE_QUESTIONS + tabId);
  }
  getProductSpecificStepperData(
    product: number,
    stepperId: number
  ): Observable<any> {
    const relativeUrl =
      Api.GET_STEPPER_DATA.replace('productId', product.toString()) +
      stepperId +
      '?deleted=false';
    return this.http.get(Api_URL.IC_API_URL + relativeUrl);
  }
  getMaskGeneratorTreeData(
    product: number,
    stepperId: number
  ): Observable<any> {
    const relativeUrl =
      Api.GET_STEPPER_DATA.replace('productId', product.toString()) +
      stepperId +
      '?selected=true&deleted=false';
    return this.http.get(Api_URL.IC_API_URL + relativeUrl);
  }
  getMaskGeneratorTreeData1(nodeId: number): Observable<any> {
    const relativeUrl = Api.SAVE_STEPPER_DATA + '?stepper_id=' + nodeId;
    return this.http.get(Api_URL.IC_API_URL + relativeUrl);
  }
  saveStepperData(data: any): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.SAVE_STEPPER_DATA, data);
  }
  saveMaskGeneratorData(data: any): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.SAVE_MASK_GENERATOR_STEPPER_DATA,
      data
    );
  }
  getStepperNodeData(nodeId: number): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.SAVE_STEPPER_DATA + '?stepper_id=' + nodeId
    );
  }
  deleteStepperNodeData(nodeId: number): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.SAVE_STEPPER_DATA + '?stepper_id=' + nodeId
    );
  }
  getQuestionsData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_QUESTIONS_DATA, data);
  }
  saveQuestionsData(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.SAVE_QUESTIONS_DATA, data);
  }
  saveTreeSelection(data): Observable<any> {
    return this.http.put(Api_URL.IC_API_URL + Api.SAVE_TREE_SELECTION, data);
  }
  getUserName(id): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_USER_NAME + id);
  }
  getInsurerDetails(id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_INSURER_DETAILS + '/' + id + '/insurer'
    );
  }

  getInsurerParticipations(
    contractId: string,
    divisionId: string
  ): Observable<InsurerParticipationResponse> {
    const formData = new FormData();
    formData.append('contractId', contractId);
    formData.append('divisionId', divisionId);
    return this.http.post<InsurerParticipationResponse>(
      Api_URL.IC_API_URL + Api.GET_INSURER_PARTICIPATIONS,
      formData
    );
  }

  updateInsurerDetails(id, data): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL + Api.GET_INSURER_DETAILS + '/' + id + '/insurer',
      data
    );
  }

  getProductMapping(contractId): Observable<any> {
    const formData = new FormData();
    formData.append('contractId', contractId);
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_PRODUCT_MAPPING,
      formData
    );
  }
  getGeneralContractData(contract_id): Observable<GetContractDetailsResponse> {
    return this.http.get<GetContractDetailsResponse>(
      Api_URL.IC_API_URL + Api.GET_INSURER_DETAILS + '/' + contract_id
    );
  }
  getSelectableObjectTypes(product_info_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SELECTABLE_OBJECT_TYPES +
        '/' +
        product_info_id +
        '/get_object_types'
    );
  }
  getInsuredObjects(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_INSURED_OBJECTS, data);
  }
  getContractGraphData(contract_id): Observable<any> {
    const api = Api.GET_CONTRACT_GRAPH.replace('{contract_id}', contract_id);
    return this.http.get(Api_URL.IC_API_URL + api);
  }
  copyProduct(data) {
    return this.http.post(Api_URL.IC_API_URL + Api.COPY_PRODUCT, data);
  }
  addUpdateAdditionalSideAgreement(data): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL + Api.ADD_ADDITIONAL_AGREEMENT,
      data
    );
  }
  getAdditionalSideAgreement(contract_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_INSURER_DETAILS + '/' + contract_id + '/asa'
    );
  }

  getContractStatus(user_role): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_CONTRACT_STATUS + '?user_role=' + user_role
    );
  }

  deleteAdditionalSideAgreement(contract_id, id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.GET_INSURER_DETAILS +
        '/' +
        contract_id +
        '/asa/' +
        id
    );
  }
  replaceProduct(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.REPLACE_PRODUCT, data);
  }
  updateProductMapping(data, id): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL +
        Api.GET_INSURER_DETAILS +
        '/' +
        id +
        '/product/stepper',
      data
    );
  }
  editContractData(data, contract_id): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL + Api.GET_INSURER_DETAILS + '/' + contract_id,
      data
    );
  }

  createContractData(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_INSURER_DETAILS + '/create',
      data
    );
  }
  editAdditionalSideAgreemnet(id, select): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_INSURER_DETAILS +
        '/asa/' +
        id +
        '?selection=' +
        select
    );
  }
  getAvailableProducts(sparte_id, division_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_PRODUCTS +
        '/' +
        sparte_id +
        '/' +
        division_id
    );
  }
  updateInsuredObjects(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.UPDATE_INSURED_OBJECTS,
      data
    );
  }
  updateProductVersion(data): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL + Api.UPDATE_PRODUCT_VERSION,
      data
    );
  }
  getStepperCount(product_info_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SELECTABLE_OBJECT_TYPES +
        '/' +
        product_info_id +
        '/stepper/count'
    );
  }
  getTargetAttribute(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.VALUE_TARGET_ATTRIBUTE);
  }
  addTargetAttribute(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.VALUE_TARGET_ATTRIBUTE,
      data
    );
  }
  getDataSourceDetails(datasource_id, product_info_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_DATA_SOURCE_DETAILS +
        '?datasource_id=' +
        datasource_id +
        '&product_info_id=' +
        product_info_id
    );
  }

  getFormulaListByStepperId(stepper_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_FORUMULA_LIST + '?stepper_id=' + stepper_id
    );
  }

  addUpdateFormula(data): Observable<any> {
    return this.http.put(Api_URL.IC_API_URL + Api.ADD_UPDATE_FORMULA, data);
  }
  getFormulaList(id, is_key_value_required): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_FORMULA_LIST +
        '?stepper_form_detail_id=' +
        id +
        '&is_key_value_required=' +
        is_key_value_required
    );
  }

  getValueCalcMenu(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_VALUE_CALC_MENU);
  }

  evaluateFormulat(data: any): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.EVALUATE_FORMULA, data);
  }

  saveFormula(data: any): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.SAVE_FORMULA, data);
  }

  deleteFormula(id, user_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.GET_FORMULA_LIST +
        '?formula_id=' +
        id +
        '&deleted_by=' +
        user_id
    );
  }

  getSideLetterDoc(id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_INSURER_DETAILS + '/' + id + '/side_letter'
    );
  }
  getContractDocType(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_CONTRACT_DOCUMENT_TYPE);
  }
  getCalculationData(product_info_id, contract_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_CALCULATIONS +
        '?product_info_id=' +
        product_info_id +
        '&contract_id=' +
        contract_id
    );
  }
  getTemplateReports(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_TEMPLATE_REPORTS);
  }
  createPolicy(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.CREATE_POLICY, data, {
      headers: { policy: 'true' },
      responseType: 'blob',
    });
  }
  getFormulaListContract(
    id,
    is_key_value_required,
    contractId,
    contractInsurerObjectId
  ): Observable<any> {
    const url = !!contractInsurerObjectId
      ? '&contract_insurer_object_id=' + contractInsurerObjectId
      : '';
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_FORMULA_LIST +
        '?stepper_form_detail_id=' +
        id +
        '&is_key_value_required=' +
        is_key_value_required +
        '&contract_id=' +
        contractId +
        url
    );
  }
  getAllTemplates(product_info_id, sync_jasper_server): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_ALL_TEMPLATES +
        '?product_info_id=' +
        product_info_id +
        '&sync_jasper_server=' +
        sync_jasper_server
    );
  }

  getTemplatesByTemplateType(
    templateTypeId,
    sync_jasper_server
  ): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_ALL_TEMPLATES +
        '?template_type_id=' +
        templateTypeId +
        '&sync_jasper_server=' +
        sync_jasper_server
    );
  }

  addTemplateConfig(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.ADD_TEMPLATE_CONFIG, data);
  }
  getProductSpecificTemplateContract(
    product_info_id,
    ui_mask_id
  ): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_PRODUCT_SPECIFIC_TEMPLATE +
        '?product_info_id=' +
        product_info_id +
        '&ui_mask_id=' +
        ui_mask_id
    );
  }

  getTemplateSpecificProducts(template_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_TEMPLATE_SPECIFIC_PRODUCTS +
        '?template_id=' +
        template_id
    );
  }

  deleteTemplateConfig(templateURI: string): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.GET_PRODUCT_SPECIFIC_TEMPLATE +
        '?templateUri=' +
        templateURI
    );
  }

  deleteProductConfig(
    templateURI: string,
    product_info_id: string,
    template_id: string
  ): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.GET_PRODUCT_SPECIFIC_TEMPLATE +
        '?templateUri=' +
        templateURI +
        '&product_info_id=' +
        product_info_id +
        '&template_id=' +
        template_id
    );
  }

  getLegalForm(name): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.PRODUCT_LOOKUP_DATA_2 +
        '?lookup_type_name=' +
        name
    );
  }
  getCustomerDetails(liztu): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_CUSTOMER_DETAILS + '?liztu_vn_kdnr=' + liztu
    );
  }
  // + "?customer_id=" + customer_id
  getCommunicationList(request): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_COMMUNICATION_OVERVIEW,
      request
    );
  }
  deleteCommunicationDetails(communication_details_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        Api.COMMUNICATION_DETAILS +
        '/' +
        communication_details_id
    );
  }
  postCommunicationDetails(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.COMMUNICATION_DETAILS,
      data
    );
  }
  saveCustomerDetails(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_CUSTOMER_DETAILS, data);
  }
  getCustomerGeneralData(liztu): Observable<CustomerGeneralData> {
    return this.http.get<CustomerGeneralData>(
      Api_URL.IC_API_URL +
        Api.GET_CUSTOMER_GENERAL_DATA +
        '?liztu_vn_kdnr=' +
        liztu
    );
  }
  SaveCustomerGeneralData(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_CUSTOMER_GENERAL_DATA,
      data
    );
  }
  getSalutation(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_SALUTATION);
  }
  getBanks(request: { sourceId: number; sourceType: string }): Observable<any> {
    const params = new HttpParams()
      .append('sourceId', request.sourceId)
      .append('sourceType', request.sourceType);

    return this.http.get(`${Api_URL.IC_API_URL}${Api.GET_BANKS}`, { params });
  }
  getBankDetails(bankDetailsId: number, sourceType: string): Observable<any> {
    const params = new HttpParams().append('sourceType', sourceType);

    return this.http.get(
      `${Api_URL.IC_API_URL}${Api.GET_BANKS}/${bankDetailsId}`,
      { params }
    );
  }
  addBankDetails(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_BANKS, data);
  }
  getClassification(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_CLASSIFICATION);
  }
  getStatusReason(status_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_STATUS_REASON + '?status_id=' + status_id
    );
  }
  getSalesStatus(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_SALES_STATUS);
  }
  saveStatusReason(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_STATUS_REASON, data);
  }
  saveSalesReason(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_SALES_STATUS, data);
  }
  getAgentList(division_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_AGENTS + '?division_id=' + division_id
    );
  }
  deleteAgent(id): Observable<any> {
    return this.http.delete(Api_URL.IC_API_URL + Api.GET_AGENTS + '?id=' + id);
  }
  IVMsyn(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.IVMsync, data);
  }
  GetIVMsync(liztu): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.IVMsync + '?liztu_vn_kdnr=' + liztu
    );
  }
  deleteBank(bank_id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.GET_BANKS + '/' + bank_id
    );
  }
  getDifferingAccountholder(): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_DIFFERING_ACCOUNT_HOLDER
    );
  }
  saveDifferingAccountholder(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_DIFFERING_ACCOUNT_HOLDER,
      data
    );
  }

  getInsurerDetailsForCalc(partner_id, code_name): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_INSURENCE_DETAILS +
        '?contract_to_insurer_partner_id=' +
        partner_id +
        '&insurer_code_name=' +
        code_name
    );
  }

  updateInsurerCalcDetails(contract_id, data): Observable<any> {
    const api = Api.INSURER_BASE.replace('{contract_id}', contract_id);
    return this.http.put(Api_URL.IC_API_URL + api, data);
  }
  getCustomerRoles(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_ROLES);
  }
  getContactPersonsList(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_CONTACT_PERSON_LIST,
      data
    );
  }
  getContactPersonData(id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_CONTACT_PERSON_DATA + '?id=' + id
    );
  }
  addEditContactPerson(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_CONTACT_PERSON_DATA,
      data
    );
  }
  addCustomerRole(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.ADD_ROLE, data);
  }
  deleteContactPerson(id): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.GET_CONTACT_PERSON_DATA + '/' + id
    );
  }
  deleteInsurerDetails(
    contract_id,
    contract_insurer_participation_id
  ): Observable<any> {
    const api =
      Api.INSURER_BASE.replace('{contract_id}', contract_id) +
      '/' +
      contract_insurer_participation_id;
    return this.http.delete(Api_URL.IC_API_URL + api);
  }
  getCustomerRelations(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_CUSTOMER_RELATIONSHIP);
  }
  getRelationships(
    customer_id,
    relationship,
    page_number,
    no_of_records
  ): Observable<any> {
    let params = new HttpParams();
    if (customer_id) {
      params = params.append('customer_id', customer_id);
    }
    if (relationship) {
      params = params.append('relationship_id', relationship);
    }
    if (page_number) {
      params = params.append('page_number', page_number);
    }
    if (no_of_records) {
      params = params.append('no_of_records', no_of_records);
    }
    return this.http.get(Api_URL.IC_API_URL + Api.GET_RELATIONSHIP, {
      params,
    });
  }
  getCustomersBasedOnDivision(division_id, role): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_CUSTOMERS_BASED_ON_DIVISION +
        '/' +
        division_id +
        '?role=' +
        role
    );
  }
  postCustomersRelationship(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_RELATIONSHIP, data);
  }
  deleteCustomerRelation(
    id,
    customer_id,
    related_customer_id
  ): Observable<any> {
    const params = new HttpParams()
      .append('customer_id', customer_id)
      .append('related_customer_id', related_customer_id);
    // + "/" + id
    return this.http.delete(
      Api_URL.IC_API_URL + Api.GET_RELATIONSHIP + '/' + id,
      { params }
    );
  }

  getFormFieldTypes(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_FORM_FIELD_TYPES);
  }
  getFormsData(page, records_per_page): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_FORM_LIST +
        '?page=' +
        page +
        '&pageRecordSize=' +
        records_per_page
    );
  }
  deleteFormData(form_details_id: any): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL + Api.GET_FORM_LIST + '/' + form_details_id
    );
  }
  toggleFormActivation(form_details_id, form_status): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL +
        Api.GET_FORM_LIST +
        '/' +
        form_details_id +
        '/form_status/' +
        form_status,
      {}
    );
  }
  getAvailableForms(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_AVAILABLE_FORMS);
  }
  getSelectedForms(product_infoid, assign_to): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_SELECTED_FORMS_FOR_PRODUCT +
        '?product_info_id=' +
        product_infoid +
        '&assign_to=' +
        assign_to
    );
  }
  updateSelectedForms(product_infoid, assign_to, data): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL +
        Api.GET_SELECTED_FORMS_FOR_PRODUCT +
        '?product_info_id=' +
        product_infoid +
        '&assign_to=' +
        assign_to,
      data
    );
  }
  getFormDataById(form_details_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_FORM_LIST + '/' + form_details_id
    );
  }
  uploadCSV(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.UPLOAD_CSV, data);
  }
  getDropdownData(uuid): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_DROPDOWN_DATA + '/' + uuid
    );
  }
  saveFormDetails(form): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.POST_FORM_DETAILS, form);
  }
  getFormDetailsByProductAndContractId(
    contract_id,
    product_info_id,
    assigned_To
  ): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_FORM_DETAILS_BY_PRODUCT_ID +
        '/' +
        contract_id +
        '/fg_sparte_details/' +
        product_info_id +
        '/' +
        assigned_To
    );
  }
  saveFormDetailsData(contract_id, data): Observable<any> {
    return this.http.put(
      Api_URL.IC_API_URL +
        Api.GET_INSURER_DETAILS +
        '/' +
        contract_id +
        '/fg_sparte_details',
      data
    );
  }
  getProductSpecificTemplateCustomer(ui_mask_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_PRODUCT_SPECIFIC_TEMPLATE +
        '?ui_mask_id=' +
        ui_mask_id
    );
  }
  getClaimConntactPersonById(id: number): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.CLAIM_CONTACT_PERSON + '/' + id
    );
  }
  getGeneralClaimsData(claims_id): Observable<{data: ClaimsObject}> {
    return this.http.get<{data: ClaimsObject}>(
      Api_URL.IC_API_URL + Api.GET_GENERAL_CLAIMS_DATA + '/' + claims_id
    );
  }
  getTypeOfClaims(division_id, contract_id, is_ivm): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_TYPE_OF_CLAIMS +
        '?division_id=' +
        division_id +
        '&contract_id=' +
        contract_id +
        '&is_ivm=' +
        is_ivm
    );
  }
  getClaimsClerkVm(division_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_GENERAL_CLAIMS_DATA +
        '/clerk_vm?division_id=' +
        division_id
    );
  }
  getClaimsAssignedObject(contract_id): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        Api.GET_GENERAL_CLAIMS_DATA +
        '/assigned_objects?contract_id=' +
        contract_id
    );
  }
  saveClaimData(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_GENERAL_CLAIMS_DATA,
      data
    );
  }
  getCauseOfClaim(): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_GENERAL_CLAIMS_DATA + '/causes'
    );
  }
  addCauseOfClaim(label): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + Api.GET_GENERAL_CLAIMS_DATA + '/causes',
      label
    );
  }

  saveOutboundEntry(body) {
    return this.http.post(Api_URL.IC_API_URL + Api.SAVE_OUTBOUND_ENTRY, body);
  }

  getCountries(countryName?: string): Observable<Country[]> {
    const options = {
      params: new HttpParams(),
    };

    if (countryName) {
      options.params.append('countryName', countryName);
    }

    return this.http.get<Country[]>(
      Api_URL.IC_API_URL + Api.GET_COUNTRIES,
      options
    );
  }

  getSecureDocument(data): Observable<any> {
    return this.http.post(Api_URL.IC_API_URL + Api.GET_SECURE_DOCUMENT, data, {
      headers: { policy: 'true' },
      responseType: 'blob',
    });
  }

  getUserProfilePhoto(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + Api.GET_USER_PROFILE_PHOTO, {
      headers: { policy: 'true' },
      responseType: 'blob',
    });
  }

  getHelpDocument(userInfo): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + Api.GET_HELP_DOCUMENT + userInfo,
      {
        headers: { policy: 'true' },
        responseType: 'blob',
      }
    );
  }

  getVideos(): Observable<Record<'name' | 'fullName', string>> {
    return this.http.get<Record<'name' | 'fullName', string>>(
      `${Api_URL.IC_API_URL}${Api.VIDEOS}`
    );
  }

  getVideo(videoName: string): Observable<string> {
    return this.http.get(`${Api_URL.IC_API_URL}${Api.VIDEOS}/${videoName}`, {
      responseType: 'text',
    });
  }
}
