<div class="modal dialog" [style.display]="showTemplatePopup ? 'block' : 'none'">
    <div class="modal-dialog uploadDocs" style="top: 155px; display: initial; ">
      <div class="modal-content">
        <div class="modal-header" style="padding: 10px 25px;align-items: center;">
          <div style="text-align:initial;">
            <h6 style="font-weight: 600;margin: 0px;">{{'attach-template.attach-template' | translate}} </h6>
          </div>
          <div style="display: flex;">
            <button (click)="refreshData()" class="sync-btn"><img alt="" src="../../../../assets/images/new-icons/Sync_Icon_18px.svg" style="margin-right: 4px;"> {{'attach-template.sync-template' | translate}}</button>
            <button type="button" class="close" data-dismiss="modal" (click)="hidePopup();">
              <img alt="" src="../../../../../assets/images/Group 3114.svg" >
            </button>
          </div>
        </div>
        <div class="modal-body modalBody">
          <div style="text-align:center">
            <div class="widget-section" >
              <div class="col-md-6 col-sm-6 col-6 header-sticky">
                <div class="widget-section-heading">
                {{'attach-template.available-templates' | translate}}                       
                </div>
            </div>
              <div class="col-md-6 col-sm-6 col-6 header-sticky">
                <div class="widget-section-heading" >
                  {{'attach-template.selected-templates' | translate}}
                </div>
              </div>
                <div class="col-md-6 col-sm-6 col-6" style="padding:0px;padding-right:5px;">
                    <div *ngFor="let widget of availableTemplateArray" class="widget-section-card">
                        <div class="col-md-8 col-sm-10 col-10" style="padding:0px;">
                            <div class="widget-section-input">{{widget.template_name}} 
                              <span class="doc-type-text">({{widget.document_type}})</span>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-2 col-2 add-btn">
                            <button class="addRemove" (click)="addWidget(widget)"><img alt="" src="../../../../assets/images/new-icons/Add_Template_Icon.svg" style="margin-top: -2px;"></button>
                        </div>                
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-6" style="padding:0px;padding-left:5px">
                    <div *ngIf="selectedTemplateArray.length == 0" class="no-template">
                      {{'attach-template.no-template-selected' | translate}}
                    </div>
                    <div *ngFor="let widget of selectedTemplateArray" class="widget-section-card">
                        <div class="col-md-8 col-sm-10 col-10" style="padding:0px;">
                          <div class="widget-section-input">{{widget.template_name}}
                            <span class="doc-type-text">({{widget.document_type}})</span>
                          </div>
                        </div>
                        <div class="col-md-4 col-sm-2 col-2 add-btn">
                            <button class="addRemove" (click)="removeWidget(widget)">-</button>
                        </div> 
                    </div>
                </div>
           </div>
          </div>
        </div>
        <div class="modal-footer" style="justify-content: center;">
            <button class="save-btn" (click)="saveConfigurations()">{{'value-calculator.Save' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="snackbar-text"></div>