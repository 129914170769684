
import { Pipe, PipeTransform } from '@angular/core';

const AMOUNT_OF_SYMBOLS = 22;
const MAX_STRING_LENGTH = 50;

@Pipe({
    name: 'cutString'
})
export class CutStringPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return value;
        }

        if (value.length < MAX_STRING_LENGTH) {
            return value;
        }

        const leftSubString = value.substring(0, AMOUNT_OF_SYMBOLS);
        const rightSubStr = value.substring(value.length - AMOUNT_OF_SYMBOLS);

        return `${leftSubString}...${rightSubStr}`;
    }
}
