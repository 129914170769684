import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ4d0ZrNU1MRVBxUENLc0RNaU5YWllrVjd1cEVSMkNLME02X1BhSE5MM01RIn0.eyJleHAiOjE3NTM4MDc0MTgsImlhdCI6MTcyMjM0OTMyMywiYXV0aF90aW1lIjoxNzIyMjcxNDE4LCJqdGkiOiI3MjQyZWU0NS0zYTM5LTRiM2UtOWNiMS1mY2RlZjFlYjY5MmEiLCJpc3MiOiJodHRwczovL2luc3VyZTIuZXZhbHVlOC5pdC9yZWFsbXMvYXJ0dXMiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiMWIzYzA1NDYtZmRjYS00YTBmLThlZmEtODY3ZjcwOTZhOWJmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiYW5ndWxhci1hcHAiLCJub25jZSI6ImRhZGEzMzlhLTg0NmItNGI3Yi04ZmE5LTJhODdmYTVmMDcyMiIsInNlc3Npb25fc3RhdGUiOiIzNzE0NmEwMy00NWQ4LTQwYjktYjM5NS1mYjI1MGRlMWJlZmMiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHBzOi8vaW5zdXJlMi5ldmFsdWU4Lml0IiwiKiIsImh0dHBzOi8vd3d3Lmluc3VyZTIuZXZhbHVlOC5pdCJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJJQy1HTE9CQUwtQURNSU4iLCJ1bWFfYXV0aG9yaXphdGlvbiIsImRlZmF1bHQtcm9sZXMtYXJ0dXMiXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwic2lkIjoiMzcxNDZhMDMtNDVkOC00MGI5LWIzOTUtZmIyNTBkZTFiZWZjIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoiTmlraGlsIEd1bmRhbGUiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJuaWtoaWwuZ3VuZGFsZUBpcWRpZ2kuY29tIiwiZ2l2ZW5fbmFtZSI6Ik5pa2hpbCIsImZhbWlseV9uYW1lIjoiR3VuZGFsZSIsImVtYWlsIjoibmlraGlsLmd1bmRhbGVAaXFkaWdpLmNvbSJ9.W7VBprJeu2x6dXLKhHAr98MUjjse1VUwuqVa3bGhcMHq5rFKXWNi5FYBpHSKf9zp09DUFh_V70ny4Q10vLlLaq9qUL8A6li4iNJMNboSMFOIizx-btRJVeEGp2ZjyDm8QugLVtlj8wxW8ChMOofd-ZacMqhK0GOLUpTHh-0v6qwdlMcgi8vpAdfM6SNEX3fS0MGg5nR_JcMs_5t1Jl_-HopxnVKf44KqDp99coDI1BmcDJ9iv6RUXrvoQM5_usSLa8Fqgg7pWza078qXkkjsZFZNefobWQP4c5zj39jTltb3_bXy7hNqceoUd-tcdV1tjQjPj0Zvhz1dMJFg5Z1iBA';
    // this.cookieService.set('user-token', token);

    // Clone the request and add the hardcoded cookie header
    const updatedRequest = request.clone({
      withCredentials: true,
      // headers: new HttpHeaders({
      //   'Cookie': `user-token=${token}`
      // })
    });

    return next.handle(updatedRequest);
  }
}
