import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DeleteConfirmationRequest,
  DeleteConfirmationResponse,
} from '@shared/interfaces/delete-confirm.interface';
import { COMMON_IMAGES } from '@shared/constants/image-paths/common.constants';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css'],
})
export class DeleteConfirmationComponent {
  @Input() inputData: DeleteConfirmationRequest;
  @Output() emitData = new EventEmitter<DeleteConfirmationResponse>();
  crossImage = COMMON_IMAGES.DELETE_CROSS;

  closeConfirmPopup(): void {
    this.inputData.isShowPopup = false;
    const response: DeleteConfirmationResponse = {
      isAllowed: false,
      id: this.inputData?.id,
      showPopup: false,
    };
    this.emitData.emit(response);
  }
  saveConfirm(): void {
    this.inputData.isShowPopup = false;
    const response: DeleteConfirmationResponse = {
      isAllowed: true,
      id: this.inputData?.id,
      showPopup: false,
    };
    this.emitData.emit(response);
  }
}
