<mat-tree class="mat-tree-custom" [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="drop($event)" [treeControl]="treeControl">
  <mat-tree-node [ngClass]="shouldEnableBackground(node) ? 'mat-custom-node-hover' : 'mat-custom-node'" cdkDrag [cdkDragData]="node" [cdkDragDisabled]="disabledMove(node)" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()" *matTreeNodeDef="let node;" matTreeNodePadding>
    <button class="custom-mat-tree-button" [disableRipple]=true *ngIf="node.level==endNodeLevel || !checkNodeButton(node)" mat-icon-button></button>
    <button class="custom-mat-tree-button" *ngIf="node.level!=endNodeLevel && checkNodeButton(node)" mat-icon-button matTreeNodeToggle
      [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon *ngIf="endNodeLevel != 4" class="mat-icon-custm-arrow">
        {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
      </mat-icon>
    </button>
    <button *ngIf="(endNodeLevel != 4 && !isDisabled) && ((node.level == endNodeLevel) || (endNodeLevel == 1 && (node.level == endNodeLevel-1)))" [ngClass]="isWindows() ? 'hide' : 'mac-hide'" class="product-drag-button" [disableRipple]=true mat-icon-button>
      <mat-icon class="product-delete-button">
        <img alt="" src='../../../../assets/images/Drag_Drop_Icon.svg'>
      </mat-icon>
    </button>
    <mat-checkbox 
      [disabled]="(endNodeLevel == 4 || !!isDisabled) ? 'disabled' : null"
      class="custom-checkBox checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
      (change)="checklistSelection.toggle(node);updateNodeSelection([node]);"></mat-checkbox>
    <button class="custom-invisible-button text-left" [disableRipple]=true (click)="updateNodeItem(node);selectedSecondaryTree(node);" mat-icon-button
      title="{{node.item}}">
      {{node.item}}
    </button>
    <button *ngIf="(endNodeLevel != 4 && !isDisabled) && ((node.level == endNodeLevel) || (endNodeLevel == 1 && (node.level == endNodeLevel-1)))" [ngClass]="isWindows() ? 'hide' : 'mac-hide'" class="product-save-button" [disableRipple]=true (click)="onDelete(node)" mat-icon-button>
      <mat-icon class="product-delete-button">
        <img alt="" src='../../../../assets/images/new-icons/Delete_icon.svg'>
      </mat-icon>
    </button>
    <div *ngIf="(node.level==(endNodeLevel-1)) && !node.expandable && treeControl.isExpanded(node) && endNodeLevel != 4 && !isDisabled"
      class="new-child-product-container">
      <button mat-button class="button-process new-child-product-group-button" (click)="addNewItem(node, true)">
        <img alt="" src="../../../../assets/images/Icon ionic-md-close.svg">&nbsp;&nbsp;
        <span style="vertical-align: middle;">
          {{buttonText}}
        </span>
      </button>
    </div>
  </mat-tree-node>

  <mat-tree-node class="mat-custom-node" *matTreeNodeDef="let node; when: showShowButton" matTreeNodePadding>
    <div *ngIf="node.level==endNodeLevel && node.isLastElement && endNodeLevel != 4  && !isDisabled" class="new-child-product-container">
      <button mat-button class="button-process new-child-product-group-button" (click)="addNewItem(node, false)">
        <img alt="" src="../../../../assets/images/Icon ionic-md-close.svg">&nbsp;&nbsp;
        <span style="vertical-align: middle;">
          {{buttonText}}
        </span>
      </button>
    </div>
  </mat-tree-node>

  <mat-tree-node [ngClass]="shouldEnableBackground(node) ? 'mat-custom-node-hover' : 'mat-custom-node'" cdkDrag [cdkDragData]="node" [cdkDragDisabled]="disabledMove(node)" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-custm-arrow">
        {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
      </mat-icon>
    </button>
    <button *ngIf="(endNodeLevel != 4 && !isDisabled) && ((node.level == endNodeLevel) || (endNodeLevel == 1 && (node.level == endNodeLevel-1)))" [ngClass]="isWindows() ? 'hide' : 'mac-hide'" class="product-drag-button" [disableRipple]=true mat-icon-button>
      <mat-icon class="product-delete-button">
        <img alt="" src='../../../../assets/images/Drag_Drop_Icon.svg'>
      </mat-icon>
    </button>
    <mat-checkbox class="custom-checkBox" [disabled]="(endNodeLevel == 4 || !!isDisabled) ? 'disabled' : null"
      [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)"></mat-checkbox>
      <button class="custom-invisible-button text-left" [disableRipple]=true (click)="updateNodeItemRoot(node);selectedPrimaryTree(node);" mat-icon-button title="{{node.item}}">
        {{node.item}}
      </button>
      <button *ngIf="(endNodeLevel != 4 && !isDisabled) && ((node.level == endNodeLevel) || (endNodeLevel == 1 && (node.level == endNodeLevel-1)))" [ngClass]="isWindows() ? 'hide' : 'mac-hide'" class="product-save-button" [disableRipple]=true (click)="onDelete(node)" mat-icon-button>
        <mat-icon class="product-delete-button">
          <img alt="" src='../../../../assets/images/new-icons/Delete_icon.svg'>
        </mat-icon>
      </button>
  </mat-tree-node>
</mat-tree>
<product-close-alert-dialog *ngIf="showWarningPopUp" [productwarnmsg]="productWarningMsg" (handlePopup)="handlePopup($event)"></product-close-alert-dialog>