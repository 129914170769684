import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionBarItem } from '../../interfaces/action-bar.interface';

@Component({
    selector: 'app-action-bar',
    templateUrl: 'action-bar.component.html',
    styleUrls: ['action-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActionBarComponent {
    @Input() rightActions: ActionBarItem[] = [];
    @Input() leftActions: ActionBarItem[] = [];

    @Output() actionClick: EventEmitter<string> = new EventEmitter();

    constructor() { }

    clickAction(key: string): void {
        this.actionClick.emit(key);
    }
}
