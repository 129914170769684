import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';

@Component({
  selector: 'app-form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FormGeneratorComponent extends BaseComponent implements OnInit {
  @Output('newFormTab') newFormTab = new EventEmitter<any>();
  @Output('formId') formId = new EventEmitter<any>();
  @Output('createdBy') createdBy = new EventEmitter<any>();
  @ViewChild('formGenerator') formGenerator = new MatSort();
  @ViewChild('formTable', { static: true }) formTable: MatTable<any>;
  @Input('toastMessageForm') toastMessageForm;
  row = 25;
  numberOfRows = [25, 50, 75, 100, 125];
  selectedCountOfRows = 25;
  tablePage = 1;
  totalElements: number;
  showModal = false;
  delete_item_id: any;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = [
    'form_name',
    'form_description',
    'usage_status',
    'created_at',
    'updated_at',
    'action',
  ];
  showConfirmPopup = false;
  show_confirm_id: any;
  IC_USER_ROLES = USER_ROLES.IC_USER;
  IC_USER = false;
  FORM_ICONS = FORM_ICONS;
  constructor(
    private apiService: ApiCallsService,
    private _toastSerice: ToastService,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.IC_USER = this.IC_USER_ROLES === this._userService.getUserRole();
    this.getFormData();
    if (this.toastMessageForm) {
      this._toastSerice.showToastMessage(document, this.toastMessageForm);
    }
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.formGenerator;
  }
  getFormData() {
    this.apiService
      .getFormsData(this.tablePage, this.row)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.dataSource.data = data.form_details;
        this.totalElements = data.total_element;
      });
  }
  addNewForm() {
    this.newFormTab.emit(true);
  }
  numberOfRowsChanged(value) {
    this.row = value.value;
    this.tablePage = 1;
    this.getFormData();
  }
  tablePageChanged(event) {
    this.tablePage = event;
    this.getFormData();
  }
  openModal(id) {
    this.delete_item_id = id;
    this.showModal = true;
  }
  hideModal() {
    this.delete_item_id = null;
    this.showModal = false;
  }
  deleteForm(id) {
    this.apiService
      .deleteFormData(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (!data.HasErrors) {
          this.getFormData();
        }
        this.hideModal();
        this._toastSerice.showToastMessage(document, data.message);
      });
  }
  toggleForm(event, id, element) {
    if (element.is_active) {
      event.source.checked = true;
      this.openConfirmPopup(id);
    } else {
      this.toggleFormActivation(id, true);
    }
  }
  openConfirmPopup(id) {
    this.show_confirm_id = id;
    this.showConfirmPopup = true;
    this.formTable.renderRows();
  }
  closeConfirmPopup() {
    this.showConfirmPopup = false;
    this.show_confirm_id = null;
    this.formTable.renderRows();
  }
  saveConfirm() {
    this.toggleFormActivation(this.show_confirm_id, false);
  }
  toggleFormActivation(form_details_id, form_status) {
    this.apiService
      .toggleFormActivation(form_details_id, form_status)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          const index: number = this.dataSource.data.findIndex(
            (element: any) => element.form_details_id == form_details_id
          );
          this.dataSource.data[index].is_active =
            !this.dataSource.data[index].is_active;
          this.formTable.renderRows();
        }
        this._toastSerice.showToastMessage(document, data.message);
        this.closeConfirmPopup();
      });
  }
  editForm(id, createdBy) {
    this.formId.emit(id);
    this.createdBy.emit(createdBy);
    this.newFormTab.emit(true);
  }
}
