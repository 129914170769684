import { Component, OnInit } from '@angular/core';
import { CustomerManagementSharedService } from '../customer-management-shared-service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: ['./user-management-page.component.scss'],
})
export class UserManagementPageComponent
  extends BaseComponent
  implements OnInit
{
  editCustomer = false;
  inviteUser = false;
  userDataEdit: any;

  constructor(
    private readonly _customerManagementSharedService: CustomerManagementSharedService
  ) {
    super();
  }
  ngOnInit(): void {
    this._watchOnInvitingUser();
  }

  editCustomerUserManagement(event: boolean): void {
    this.editCustomer = event;
  }

  emitInviteUser(event: boolean): void {
    this.inviteUser = event;
  }

  updateUserData(event: any): void {
    this.userDataEdit = event;
  }

  private _watchOnInvitingUser(): void {
    this._customerManagementSharedService.inviteNewUser$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (!this.editCustomer) {
          this.inviteUser = true;
        }
      });
  }
}
