import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactManagerSharedService {
  private _processId: string;
  get processId(): string {
    return this._processId;
  }
  set processId(value: string) {
    this._processId = value;
  }

  private _processName: string;
  get processName(): string {
    return this._processName;
  }
  set processName(value: string) {
    this._processName = value;
  }

  private _sparteId: string;
  get sparteId(): string {
    return this._sparteId;
  }
  set sparteId(value: string) {
    this._sparteId = value;
  }

  private _sparteName: string;
  get sparteName(): string {
    return this._sparteName;
  }
  set sparteName(value: string) {
    this._sparteName = value;
  }

  private _productId: string;
  get productId(): string {
    return this._productId;
  }
  set productId(value: string) {
    this._productId = value;
  }

  private _productName: string;
  get productName(): string {
    return this._productName;
  }
  set productName(value: string) {
    this._productName = value;
  }

  private _externId: string;
  get externId(): string {
    return this._externId;
  }
  set externId(value: string) {
    this._externId = value;
  }

  private _externName: string;
  get externName(): string {
    return this._externName;
  }
  set externName(value: string) {
    this._externName = value;
  }

  private _editorId: string;
  get editorId(): string {
    return this._editorId;
  }
  set editorId(value: string) {
    this._editorId = value;
  }

  private _editorName: string;
  get editorName(): string {
    return this._editorName;
  }
  set editorName(value: string) {
    this._editorName = value;
  }

  private _telephone: string;
  get telephone(): string {
    return this._telephone;
  }
  set telephone(value: string) {
    this._telephone = value;
  }

  private _email: string;
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }

  private _from: string;
  get from(): string {
    return this._from;
  }
  set from(value: string) {
    this._from = value;
  }

  private _to: string;
  get to(): string {
    return this._to;
  }
  set to(value: string) {
    this._to = value;
  }

  private _cmAreaId: string;
  get cmAreaId(): string {
    return this._cmAreaId;
  }
  set cmAreaId(value: string) {
    this._cmAreaId = value;
  }

  private _cmAreaName: string;
  get cmAreaName(): string {
    return this._cmAreaName;
  }
  set cmAreaName(value: string) {
    this._cmAreaName = value;
  }

  discardValuesExceptCmArea(): void {
    this.processId = null;
    this.processName = null;
    this.sparteId = null;
    this.sparteName = null;
    this.productId = null;
    this.productName = null;
    this.externId = null;
    this.externName = null;
    this.editorId = null;
    this.editorName = null;
    this.telephone = null;
    this.email = null;
    this.from = null;
    this.to = null;
  }

  discardAllValues(): void {
    this.discardValuesExceptCmArea();
    this.cmAreaId = null;
    this.cmAreaName = null;
  }
}
