import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { UpdateUserModule } from '@components/update-user/update-user.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IconsModule, MDBBootstrapModule } from 'angular-bootstrap-md';
import { HighchartsChartModule } from 'highcharts-angular';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { FileUploadModule } from 'ng2-file-upload';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FilePickerModule } from 'ngx-awesome-uploader';
import { TooltipModule as BTTooltipModule } from 'ngx-bootstrap/tooltip';
import { CookieService } from 'ngx-cookie-service';
import { CountdownModule } from 'ngx-countdown';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClaimReportingUploadPopupComponent } from './components/claim-reporting/claim-reporting-upload-popup/claim-reporting-upload-popup.component';
import { ClaimReportingComponent } from './components/claim-reporting/claim-reporting.component';
import { ContractsMainComponent } from './components/contracts/contracts.component';
import { CustomerDashboardConfigurationComponent } from './modules/dashboard/cd-dashboard-configuration/cd-dashboard-configuration.component';
import { DashboardConfigurationComponent } from './components/dashboard-configuration/dashboard-configuration.component';
import { InviteUserModule } from './components/invite-user/invite-user.module';
import { MainSpinnerComponent } from './components/main-spinner/main-spinner.component';
import { NewClaimsModule } from './modules/new-claims/new-claims.module';
import { AddNewContractDialogComponent } from './modules/new-contract/add-new-contract-dialog/add-new-contract-dialog.component';
import { NewDocumentComponent } from './components/new-document/new-document.component';
import { AddressPopupComponent } from './components/object-module/new-object-module/address-popup/address-popup.component';
import { BuildingFormComponent } from './components/object-module/new-object-module/building-form/building-form.component';
import { CleanRoomsComponent } from './components/object-module/new-object-module/building-form/clean-rooms/clean-rooms.component';
import { ElectroplatingComponent } from './components/object-module/new-object-module/building-form/electroplating/electroplating.component';
import { FireProtectionComponent } from './components/object-module/new-object-module/building-form/fire-protection/fire-protection.component';
import { FoilPackagingComponent } from './components/object-module/new-object-module/building-form/foil-packaging/foil-packaging.component';
import { FoundaryComponent } from './components/object-module/new-object-module/building-form/foundary/foundary.component';
import { GrindingPolishingComponent } from './components/object-module/new-object-module/building-form/grinding-polishing/grinding-polishing.component';
import { LithiumBatteriesComponent } from './components/object-module/new-object-module/building-form/lithium-batteries/lithium-batteries.component';
import { MillInstallationComponent } from './components/object-module/new-object-module/building-form/mill-installation/mill-installation.component';
import { ObjectDetailsComponent } from './components/object-module/new-object-module/building-form/object-details/object-details.component';
import { OtherIncreaseRiskComponent } from './components/object-module/new-object-module/building-form/other-increase-risk/other-increase-risk.component';
import { PelletePlantComponent } from './components/object-module/new-object-module/building-form/pellete-plant/pellete-plant.component';
import { PhotovoltaicInsideComponent } from './components/object-module/new-object-module/building-form/photovoltaic/photovoltaic.component';
import { PlasticComponent } from './components/object-module/new-object-module/building-form/plastic/plastic.component';
import { PowerSupplyFacilityComponent } from './components/object-module/new-object-module/building-form/power-supply-facility/power-supply-facility.component';
import { PowerSupplyComponent } from './components/object-module/new-object-module/building-form/power-supply/power-supply.component';
import { PrintingCompaniesComponent } from './components/object-module/new-object-module/building-form/printing-companies/printing-companies.component';
import { RecyclingComponent } from './components/object-module/new-object-module/building-form/recycling/recycling.component';
import { SolderingComponent } from './components/object-module/new-object-module/building-form/soldering/soldering.component';
import { WarehouseComponent } from './components/object-module/new-object-module/building-form/warehouse/warehouse.component';
import { WoodworkingProcessingComponent } from './components/object-module/new-object-module/building-form/woodworking-processing/woodworking-processing.component';
import { ElectronicsFormComponent } from './components/object-module/new-object-module/electronics-form/electronics-form.component';
import { GoodsInventoriesComponent } from './components/object-module/new-object-module/goods-inventories/goods-inventories.component';
import { GroupOfPeopleComponent } from './components/object-module/new-object-module/group-of-people/group-of-people.component';
import { IndividualComponent } from './components/object-module/new-object-module/individual/individual.component';
import { AdditionalGeneralInformationComponent } from './components/object-module/new-object-module/location/additional-general-information/additional-general-information.component';
import { BasicLocationDetailsComponent } from './components/object-module/new-object-module/location/basic-location-details/basic-location-details.component';
import { LocationComponent } from './components/object-module/new-object-module/location/location.component';
import { OrganizationalFireProtectionComponent } from './components/object-module/new-object-module/location/organizational-fire-protection/organizational-fire-protection.component';
import { OtherIncresesRiskLocationComponent } from './components/object-module/new-object-module/location/other-increses-risk-location/other-increses-risk-location.component';
import { ProtectionFactoryPremisesComponent } from './components/object-module/new-object-module/location/protection-factory-premises/protection-factory-premises.component';
import { MachinePlantsFormMobiComponent } from './components/object-module/new-object-module/machine-plants-form-mobi/machine-plants-form-mobi.component';
import { MachinePlantsFormComponent } from './components/object-module/new-object-module/machine-plants-form/machine-plants-form.component';
import { MiscellaneousComponent } from './components/object-module/new-object-module/miscellaneous/miscellaneous.component';
import { ModelSampleToolsComponent } from './components/object-module/new-object-module/model-sample-tools/model-sample-tools.component';
import { NewObjectModuleComponent } from './components/object-module/new-object-module/new-object-module.component';
import { OperatingEquipmentComponent } from './components/object-module/new-object-module/operating-equipment/operating-equipment.component';
import { PhotovoltaicComponent } from './components/object-module/new-object-module/photovoltaic/photovoltaic.component';
import { VehicleComponent } from './components/object-module/new-object-module/vehicle/vehicle.component';
import { ObjectModuleComponent } from './components/object-module/object-module.component';
import { OTPComponent } from './components/otp/otp.component';
import { RegisterClaimModule } from './components/register-claim/register-claim.module';
import { SaveProfileChangesDialogComponent } from './components/save-profile-changes-dialog/save-profile-changes-dialog.component';
import { SearchComponent } from './components/search/search.component';
import { SearchModule } from './components/search/search.module';
import { SettingsComponent } from './components/settings/settings.component';
import { UpdateCustomerComponent } from './components/update-customer/update-customer.component';
import { UpdateUserComponent } from './components/update-user/update-user.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { GeneralComponent } from './components/value-module/general/general.component';
import { PropertyComponent } from './components/value-module/property/property.component';
import { ValueModuleComponent } from './components/value-module/value-module.component';
import { ZursCommonComponent } from './modules/dashboard/zurs-geo/zurs-common-component/zurs-common.component';
import { CustomDateAdapter } from './core/custom.date.adapter';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { CookieInterceptor } from './core/interceptors/cookie.interceptor';
import { LocaleInterceptor } from './core/interceptors/locale.interceptor';
import { KeycloakService } from './core/services/keycloak/keycloak.service';
import { SidebarService } from './core/services/sidebar.service';
import { CustomerManagementModule } from './modules/customer-management/customer-management.module';
import { RCSSetupRoutingModule } from './modules/rcs-setup/rcs-setup-routing.module';
import { RCSSETUPModule } from './modules/rcs-setup/rcs-setup.module';
import { SharedModule } from './shared/shared.module';
registerLocaleData(localeDE);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    AddNewContractDialogComponent,
    OTPComponent,
    SettingsComponent,
    UserProfileComponent,
    DashboardConfigurationComponent,
    CustomerDashboardConfigurationComponent,
    UpdateCustomerComponent,
    NewDocumentComponent,
    ContractsMainComponent,
    SaveProfileChangesDialogComponent,
    ClaimReportingComponent,
    ObjectModuleComponent,
    NewObjectModuleComponent,
    BuildingFormComponent,
    AddressPopupComponent,
    OperatingEquipmentComponent,
    ElectronicsFormComponent,
    MachinePlantsFormComponent,
    IndividualComponent,
    VehicleComponent,
    GroupOfPeopleComponent,
    GoodsInventoriesComponent,
    ModelSampleToolsComponent,
    MiscellaneousComponent,
    PhotovoltaicComponent,
    ObjectDetailsComponent,
    PowerSupplyFacilityComponent,
    PowerSupplyComponent,
    FireProtectionComponent,
    OtherIncreaseRiskComponent,
    PrintingCompaniesComponent,
    FoilPackagingComponent,
    ElectroplatingComponent,
    FoundaryComponent,
    WoodworkingProcessingComponent,
    PlasticComponent,
    WarehouseComponent,
    LithiumBatteriesComponent,
    SolderingComponent,
    MillInstallationComponent,
    PelletePlantComponent,
    RecyclingComponent,
    CleanRoomsComponent,
    ZursCommonComponent,
    GrindingPolishingComponent,
    PhotovoltaicInsideComponent,
    LocationComponent,
    BasicLocationDetailsComponent,
    AdditionalGeneralInformationComponent,
    OrganizationalFireProtectionComponent,
    ProtectionFactoryPremisesComponent,
    OtherIncresesRiskLocationComponent,
    MachinePlantsFormMobiComponent,
    ValueModuleComponent,
    GeneralComponent,
    PropertyComponent,
    ClaimReportingUploadPopupComponent,
    MainSpinnerComponent,
  ],
  imports: [
    BrowserModule,
    NgxMatIntlTelInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    OverlayModule,
    InviteUserModule,
    MDBBootstrapModule.forRoot(),
    MatFormFieldModule,
    SearchModule,
    InfiniteScrollModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    NgHttpLoaderModule.forRoot(),
    IconsModule,
    NgxLoadingModule,
    MatSlideToggleModule,
    CountdownModule,
    AppRoutingModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatBadgeModule,
    ClickOutsideModule,
    MatSidenavModule,
    HttpClientModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatStepperModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatToolbarModule,
    MatInputModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatChipsModule,
    FontAwesomeModule,
    FileUploadModule,
    MatSelectCountryModule,
    SharedModule,
    DragDropModule,
    RegisterClaimModule,
    FilePickerModule,
    MatStepperModule,
    MatRadioModule,
    ClipboardModule,
    MatTreeModule,
    MatDividerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BTTooltipModule.forRoot(),
    HighchartsChartModule,
    CustomerManagementModule,
    RCSSetupRoutingModule,
    NewClaimsModule,
    MatProgressSpinnerModule,
    RCSSETUPModule,
    UpdateUserModule,
  ],
  exports: [MatSortModule, BrowserAnimationsModule],
  providers: [
    SidebarService,
    KeycloakService,
    CookieService,
    { provide: LOCALE_ID, useValue: 'de' },
    [DatePipe],
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    [
      { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
      { provide: DateAdapter, useClass: CustomDateAdapter },
    ],
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddNewContractDialogComponent,
    UpdateUserComponent,
    SearchComponent,
    CustomerDashboardConfigurationComponent,
    SaveProfileChangesDialogComponent,
  ],
})
export class AppModule {}
