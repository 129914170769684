import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';

@Component({
  selector: 'app-woodworking-processing',
  templateUrl: './woodworking-processing.component.html',
  styleUrls: [
    './woodworking-processing.component.css',
    '../../new-object-module.component.css',
  ],
})
export class WoodworkingProcessingComponent implements OnInit {
  WoodWorkingForm: FormGroup;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;

  constructor(private serviceCall: ApiCallsService) {}

  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }

  initializeForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.WOODWORKING_AND_PROCESSING
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          BuildingConstants.WOODWORKING_AND_PROCESSING
        ].saved_criteria
      );

    this.WoodWorkingForm = new FormGroup({
      is_appropriate_cleaning: new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing.is_appropriate_cleaning
            : null,
          disabled: false,
        },
        []
      ),
      'is_stationary_cleaning_/_extraction_system': new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing[
                'is_stationary_cleaning_/_extraction_system'
              ]
            : null,
          disabled: false,
        },
        []
      ),
      is_coatings: new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing.is_coatings
            : null,
          disabled: false,
        },
        []
      ),
      'is_solvent-based_adhesives': new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing['is_solvent-based_adhesives']
            : null,
          disabled: false,
        },
        []
      ),
      'is_iomass_chip_heating_with_burn-back_protection': new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing[
                'is_iomass_chip_heating_with_burn-back_protection'
              ]
            : null,
          disabled: false,
        },
        []
      ),
      'is_metal_detector_available_for_log_processing?': new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing[
                'is_metal_detector_available_for_log_processing?'
              ]
            : null,
          disabled: false,
        },
        []
      ),
      is_chip_silo_bunker: new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing.is_chip_silo_bunker
            : null,
          disabled: false,
        },
        []
      ),
      is_spark_detection_and_extinguishing: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.chip_silo_bunker
              ? formData.woodworking_and_processing.chip_silo_bunker
                  .is_spark_detection_and_extinguishing
              : null,
          disabled: false,
        },
        []
      ),
      is_structural_spatial_separation: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.chip_silo_bunker
              ? formData.woodworking_and_processing.chip_silo_bunker
                  .is_structural_spatial_separation
              : null,
          disabled: false,
        },
        []
      ),
      is_inerting_option: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.chip_silo_bunker
              ? formData.woodworking_and_processing.chip_silo_bunker
                  .is_inerting_option
              : null,
          disabled: false,
        },
        []
      ),
      is_dry_line: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.chip_silo_bunker
              ? formData.woodworking_and_processing.chip_silo_bunker.is_dry_line
              : null,
          disabled: false,
        },
        []
      ),
      is_printingload: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.chip_silo_bunker
              ? formData.woodworking_and_processing.chip_silo_bunker
                  .is_printingload
              : null,
          disabled: false,
        },
        []
      ),
      is_workshop_grinding_shop: new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing.is_workshop_grinding_shop
            : null,
          disabled: false,
        },
        []
      ),
      is_drying: new FormControl(
        {
          value: formData
            ? formData.woodworking_and_processing.is_drying
            : null,
          disabled: false,
        },
        []
      ),
      is_structural_spatial_separation1: new FormControl(
        {
          value:
            formData &&
            formData.woodworking_and_processing.workshop_grinding_shop
              ? formData.woodworking_and_processing.workshop_grinding_shop[
                  'structural_/_spatial_separation'
                ]
              : null,
          disabled: false,
        },
        []
      ),
      is_structural_spatial_separation2: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.drying
              ? formData.woodworking_and_processing.drying
                  .is_structural_spatial_separation
              : null,
          disabled: false,
        },
        []
      ),
      annotations: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.annotations
              ? formData.woodworking_and_processing.annotations
              : null,
          disabled: false,
        },
        []
      ),
      is_annotation: new FormControl(
        {
          value:
            formData && formData.woodworking_and_processing.is_annotation
              ? formData.woodworking_and_processing.is_annotation
              : null,
          disabled: false,
        },
        []
      ),
    });

    this.isFormValueUpdated = false;

    this.WoodWorkingForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.WoodWorkingForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }

  buildFormObject() {
    const formValues = this.WoodWorkingForm.value;

    const newFormValues = {
      is_appropriate_cleaning: formValues.is_appropriate_cleaning,
      'is_stationary_cleaning_/_extraction_system':
        formValues['is_stationary_cleaning_/_extraction_system'],
      is_coatings: formValues.is_coatings,
      'is_solvent-based_adhesives': formValues['is_solvent-based_adhesives'],
      'is_iomass_chip_heating_with_burn-back_protection':
        formValues['is_iomass_chip_heating_with_burn-back_protection'],
      'is_metal_detector_available_for_log_processing?':
        formValues['is_metal_detector_available_for_log_processing?'],
      is_chip_silo_bunker: formValues.is_chip_silo_bunker,
      chip_silo_bunker: {
        is_spark_detection_and_extinguishing:
          formValues.is_spark_detection_and_extinguishing,
        is_structural_spatial_separation:
          formValues.is_structural_spatial_separation,
        is_inerting_option: formValues.is_inerting_option,
        is_dry_line: formValues.is_dry_line,
        is_printingload: formValues.is_printingload,
      },
      is_workshop_grinding_shop: formValues.is_workshop_grinding_shop,
      workshop_grinding_shop: {
        'structural_/_spatial_separation':
          formValues.is_structural_spatial_separation1,
      },
      is_drying: formValues.is_drying,
      drying: {
        is_structural_spatial_separation:
          formValues.is_structural_spatial_separation2,
      },
      annotations: formValues.annotations,
      is_annotation: formValues.is_annotation,
    };

    const formValuesToPersist = {
      woodworking_and_processing: newFormValues,
    };
    this.request = {
      criteria_id: BuildingConstants.WOODWORKING_AND_PROCESSING,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.WoodWorkingForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
