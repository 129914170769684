<ng-container *ngIf="divisionId$ | async as divisionId">
  <ng-container *ngIf="customerId$ | async as customerId">
    <ng-container *ngIf="claimId$ | async as claimId">
      <app-appointments
        [source]="sourceTypes.CLAIM"
        [sourceId]="claimId"
        [customerId]="customerId"
        [divisionId]="divisionId"></app-appointments>
    </ng-container>
  </ng-container>
</ng-container>
