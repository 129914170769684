import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import { SidebarService } from '@core/services/sidebar.service';
import { ToastService } from '@core/services/toast.service';
import {
  animateText,
  onMainContentChange,
  onSideNavChange,
} from '../../animations';
import { STATIC_TEXT } from '@shared/constants/string.constants';
import {
  ENTITYTYPEID,
  IVMTYPE,
  OutboundEntryRequest,
} from '@shared/constants/ivm-data.constants';
import { DocumentSource } from '@shared/models/document-management.model';
import { UploadDocumentData } from '@shared/components/upload-document-popup/upload-document-popup.model';
import { DocumentManagementService } from '@core/services/document-management.service';
import { takeUntil } from 'rxjs/operators';
import { LocksApiService } from '@core/services/httpcalls/locks-api.service';
import { ROLES } from '@shared/constants/roles.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { UserService } from '@core/user.service';
import { NewClaimsComponentBase } from './new-claims.component-base';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { ClaimsShareService } from './claims-share.service';
import { PopupType } from '@shared/components/write-letter-email-popup/write-letter-email-popup.model';
import { MatDialog } from '@angular/material/dialog';
import { WriteLetterEmailComponent } from '@shared/components/write-letter-email-popup/write-letter-email-popup.component';
import { ACTION_BAR_KEYS } from '@shared/constants/image-paths/action-bar.constant';
import { CLAIM_TABS } from './constants/claim.constants';
import {
  CLAIM_ROUTES,
  CLAIM_ROUTES_ASSOCIATION,
} from '@shared/constants/router.constants';
import { Tab } from '@shared/interfaces/tab.interface';
import { LOCK_STATUS } from '@shared/constants/general/lock-status.constants';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';

@Component({
  selector: 'app-new-claims',
  templateUrl: './new-claims.component.html',
  styleUrls: ['./new-claims.component.scss'],
  animations: [onSideNavChange, animateText, onMainContentChange],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewClaimsComponent
  extends NewClaimsComponentBase
  implements OnInit
{
  constructor(
    private _sidenavService: SidebarService,
    private route: ActivatedRoute,
    public serviceCall: ApiCallsService,
    public translate: TranslateService,
    public _toastService: ToastService,
    public _claimsAPICall: ClaimsManagementApiService,
    public readonly _documentManagementService: DocumentManagementService,
    public readonly _claimsShareService: ClaimsShareService,
    private readonly _locksService: LocksApiService,
    private readonly _userService: UserService,
    public readonly _customerSharedService: CustomerSharedService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router
  ) {
    super(
      serviceCall,
      translate,
      _toastService,
      _claimsAPICall,
      _documentManagementService,
      _claimsShareService,
      _customerSharedService
    );
  }

  ngOnInit(): void {
    this._initClaimDetails();
    this._watchOnIvmValueChanging();
    this._watchOnProductInfoIdChanging();
    this._watchOnIvmEventChanging();
    this._watchOnUserRole();
    this._watchOnUrlChanging();
    this._initBreadcrumbInfo();
    if (this.claim_id) {
      this.getIvmSyncStatus(this.claim_id);
    }
    if (window.location.origin === STATIC_TEXT.PROD_URL) {
      this.isProdMode = true;
    }
    this._sidenavService.sideNavState$
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => (this.onSideNavChange = res));
    this._setActiveTab();
    this.activeLable = this._setActiveLable();
    this._setActions();
  }

  userDataReceived(data: LoggedInUser): void {
    this.customer_id =
      data.user_profile.customer_details.customer_id;
    this.divisionName = data.user_profile.customer_details.name;

    this.user_id = data.user_profile.user_data?.user_id;
    this.liztu_vn_kdnr = atob(this.route.snapshot.queryParamMap.get('liztu'));
  }

  navigateToTab(tab: CLAIM_ROUTES): void {
    this._router.routeReuseStrategy.shouldReuseRoute = (future, curr) =>
      future.routeConfig === curr.routeConfig;
    this._router.navigate([tab], {
      relativeTo: this.route,
      queryParams: this.route.snapshot.queryParams,
    });
  }

  tabChange(event: { activeId: CLAIM_TABS; nextId: CLAIM_TABS }): void {
    this.active = event.nextId;
    this.activeLable = this._setActiveLable();
    this._setActions();
  }

  onSinenavToggle() {
    this.sideNavState = !this.sideNavState;
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200);
    this._sidenavService.sideNavState$.next(this.sideNavState);
  }

  actionBarClick(key: keyof typeof ACTION_BAR_KEYS): void {
    switch (key) {
      case ACTION_BAR_KEYS.CREATE_DOCUMENT_FROM_TEMPLATE: {
        this.showDocTemplateModal = true;
        this.serviceCall
          .getProductSpecificTemplateCustomer(30)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => (this.avaliable_templates = data.data));
        break;
      }
      case ACTION_BAR_KEYS.WRITE_LETTER: {
        this._dialog.open(WriteLetterEmailComponent, {
          data: {
            type: PopupType.LETTER,
            ...this._getRequiredFieldsToUpload(),
          },
        });
        break;
      }

      case ACTION_BAR_KEYS.WRITE_EMAIL: {
        this._dialog.open(WriteLetterEmailComponent, {
          data: { type: PopupType.EMAIL, ...this._getRequiredFieldsToUpload() },
        });
        break;
      }

      case ACTION_BAR_KEYS.ADD_CONTACT_PERSON: {
        this._claimsShareService.addContactPerson$.next(null);
        break;
      }
      case ACTION_BAR_KEYS.ADD_PAYMENT: {
        this._claimsShareService.addPayment$.next(null);
        break;
      }

      case ACTION_BAR_KEYS.PROVIDE_DATA_TO_IVM: {
        this._provideDataToIVM();
        break;
      }

      case ACTION_BAR_KEYS.TOGGLE_PROTECT_LOCK: {
        this._changeLockState(
          this.lock_state === LOCK_STATUS.UNLOCKED
            ? LOCK_STATUS.LOCKED_BY_IC
            : LOCK_STATUS.UNLOCKED
        );
        break;
      }

      case ACTION_BAR_KEYS.UPLOAD_DOCUMENT: {
        this._upload();
        break;
      }
      default:
        return;
    }
  }

  private _initClaimDetails(): void {
    this.claim_id = atob(this.route.snapshot.queryParams.claim || '');
    if (this.claim_id) {
      this.serviceCall
        .getGeneralClaimsData(this.claim_id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this._claimsShareService.setClaimData(data.data);
          this._claimsShareService.setLiztuSa(data.data.liztu_sa_idnr);
          this.is_ivm_product = data.data?.is_product_ivm;
          this._claimsShareService.setProductInfoId(data.data?.product_info_id);
          this.getFormsData(this.claim_id, data.data?.product_info_id);
          this.getProductData(data.data?.product_info_id);
          this._setTabs();
        });
    } else {
      this._claimsShareService.setClaimData({});
      this.disable_ivm = true;
      this.is_ivm_product = true;
      this._setTabs();
    }
  }

  private _setTabs(): void {
    this.leftTabs = this._getTabs();
  }

  private _setActiveLable(): string {
    return {
      [CLAIM_TABS.GENERAL_DATA]:
        'new-claims.general_details.general_claims_details',
      [CLAIM_TABS.DAMAGE_DETAILS]: 'new-claims.general_details.damage_details',
      [CLAIM_TABS.DOCUMENT_MANAGEMENT]:
        'new-claims.general_details.document_management',
      [CLAIM_TABS.CONTACT_PERSON]: 'new-claims.general_details.contact_person',
      [CLAIM_TABS.PAYMENT_MANAGEMENT]:
        'new-claims.general_details.payment_management',
      [CLAIM_TABS.APPOINTMENTS]: 'appointments.title',
    }[this.active];
  }

  private _provideDataToIVM(): void {
    const body: OutboundEntryRequest = {
      entity_type_id: ENTITYTYPEID.CLAIM,
      entity_type: IVMTYPE.CLAIM,
      entity_protect_id: Number(this.claim_id),
      entity_ivm_id: this._claimsShareService.getLiztuSa(),
      division_id: +this._customerSharedService.getSelectedCustomerDivisionId(),
      liztu: null,
    };
    this.serviceCall
      .saveOutboundEntry(body)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._toastService.showToastMessage(
          document,
          this.translate.instant('product-management.added_success_msg')
        );
      });
  }

  private _upload(): void {
    this._documentManagementService.setUploadingDocsFields(
      this._getRequiredFieldsToUpload()
    );
    this._documentManagementService.openDialog();
  }

  private _changeLockState(state: number): void {
    const lockData = {
      id: Number(this.claim_id),
      lock_state: Number(state),
      updatedByUserId: this.user_id,
    };
    this._locksService
      .updateClaimesLockState(lockData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.status == 'success') {
          this.lock_state = state;
          this._handleLockState();
        } else {
          this._toastService.showToastMessage(
            document,
            this.translate.instant('generic_message.change_lock_state_error')
          );
        }
      });
  }

  private _getRequiredFieldsToUpload(): UploadDocumentData {
    return {
      userId: +this.user_id,
      VN_KDNR: +this.liztu_vn_kdnr,
      VS_IDNR: null,
      SA_IDNR: this._claimsShareService.getLiztuSa(),
      contractId: +atob(this.route.snapshot.queryParams.contract),
      source: DocumentSource.CLAIMS,
      customerId: +(
        this._customerSharedService.getSelectedCustomerId() ||
        this.customer_id
      ),
      policyNumber: null,
      documentNumber: null,
      insurerId: null,
      damageId: null,
      intermediaryId: null,
      isVisibleForCustomer: false,
      sourceUser: null,
      targetUser: null,
      documentCreationDate: null,
      documentIdentifier: null,
    };
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(
        role => (this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(role))
      );
  }

  private _watchOnIvmValueChanging(): void {
    this.ivm_sync.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => this._claimsShareService.setIvmSyncedValue(value));
  }

  private _watchOnProductInfoIdChanging(): void {
    this._claimsShareService.productInfoId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(value => {
        if (!this.claim_id) {
          this.claim_id = atob(this.route.snapshot.queryParams.claim || '');
          this._setTabs();
          this._setActions();
        }
        this.getFormsData(this.claim_id, value);
        this.getProductData(value);
      });
  }

  private _watchOnIvmEventChanging(): void {
    this._claimsShareService.ivmEventChanging$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(value => {
        this.ivm_sync.setValue(value);
        this.postIVMSync(this.claim_id, value);
      });
  }

  private _initBreadcrumbInfo(): void {
    const contractId = atob(this.route.snapshot.queryParams.contract);
    this.serviceCall.getGeneralContractData(contractId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(value =>  {
        this.contractInfo = {
          policyNumber: value.general_info.policy_number,
          contractId,
          assignedProduct: value.general_info?.product_name,
          contractLabel: this.translate.instant('contracts.contract_text'),
        };
      });
  }

  private _watchOnUrlChanging(): void {
    this._router.events.pipe(takeUntil(this._destroy$)).subscribe(value => {
      if (value instanceof NavigationEnd) {
        this._setActiveTab();
        this.activeLable = this._setActiveLable();
        this._setActions();
      }
    });
  }

  private _setActiveTab(): void {
    const url = this._router.url;
    for (const key in CLAIM_ROUTES_ASSOCIATION) {
      if (url.includes(CLAIM_ROUTES_ASSOCIATION[key])) {
        this.active = CLAIM_TABS[key];
        return;
      }
    }
  }

  private _getTabs(): Tab<CLAIM_ROUTES, CLAIM_TABS>[] {
    return [
      {
        name: this.links.GENERAL_DATA,
        disabled: false,
        sourceTitle: this.translate.instant(
          'new-claims.general_details.general_claims_details'
        ),
        img: '../../../assets/images/General_claim_details.svg',
        tab: this.tabs.GENERAL_DATA,
      },
      {
        name: this.links.DAMAGE_DETAILS,
        disabled:
          !this.claim_id ||
          this.is_ivm_product ||
          !this.is_active_product ||
          !this.formsPresent,
        sourceTitle: this.translate.instant(
          'new-claims.general_details.damage_details'
        ),
        img: '../../../assets/images/damage_details.svg',
        tab: this.tabs.DAMAGE_DETAILS,
      },
      {
        name: this.links.DOCUMENT_MANAGEMENT,
        disabled: !this.claim_id,
        sourceTitle: this.translate.instant(
          'new-claims.general_details.document_management'
        ),
        img: '../../../assets/images/documents_overview.svg',
        tab: this.tabs.DOCUMENT_MANAGEMENT,
      },
      {
        name: this.links.CONTACT_PERSON,
        disabled: !this.claim_id,
        sourceTitle: this.translate.instant(
          'new-claims.general_details.contact_person'
        ),
        img: '../../../assets/images/Contact_Person.svg',
        tab: this.tabs.CONTACT_PERSON,
      },
      {
        name: this.links.PAYMENT_MANAGEMENT,
        disabled: !this.claim_id,
        sourceTitle: this.translate.instant(
          'new-claims.general_details.payment_management'
        ),
        img: '../../../assets/images/payment_management_icon.svg',
        tab: this.tabs.PAYMENT_MANAGEMENT,
      },
      {
        name: this.links.APPOINTMENTS,
        disabled: !this.claim_id,
        sourceTitle: this.translate.instant('appointments.title'),
        img: '../../../assets/images/appointments-tab.svg',
        tab: this.tabs.APPOINTMENTS,
      },
    ];
  }
}
