<div id="searchScroll">
  <div class="form-group has-search" style="margin: -10px -7px 15px;">
    <img alt="" src="../../../assets/images/ic-icons/Search.svg" class="form-control-feedback">
    <input type="text" [value]="searchText" #search class="form-control"
      placeholder="{{'searchComponent.search_text' | translate}}" aria-label="Search"
      (keyup.enter)="defaultSearch(search.value,$event,0)">
  </div>

  <div style="clear:both">
    <ul class="game-list" style="padding:1px;">
      <li *ngFor="let g of games | filter : searchText; let i = index;" class="game-item" id="list{{i}}">
        <input style="display:none" (change)="getSelected(search.value,$event,i)" type="checkbox" name="games"
          value="{{g.id}}" [(ngModel)]="g.selected" id="check{{i}}" />
        <label class="game-text" for="check{{i}}" style="margin-top: 0.2rem">{{g.name | multilingual |
          translate}}</label>
      </li>
    </ul>
  </div>
  <div class="hide" id="showMessage">
    <img alt="" height="18px" [src]="messageimg">
    {{message}}
  </div>
  <div>
    <div class="searchResult" *ngIf="showResult">
      <div class="one" *ngIf="this.one">
        <h6 (click)="showOne=!showOne" class="divHeading" #arrow>
          <button *ngIf="showOne" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
          </button>
          <button *ngIf="!showOne" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
          </button>
          {{ 'searchComponent.NameAdresse_text' | translate }}
        </h6>
        <ul class="list-items" *ngIf="showOne">
          <ng-container *ngIf="user_role === userRoles.IC_GLOBAL_ADMIN">
            <li class="sresult" *ngFor="let data of datasourceOne">
              <a
                (click)="navigatToCustomerResult(data.customer_id,data.user_idnr,data.is_source_ivm, data.liztu_vn_kdnr,data.customer_type,data.search_result_label,data.division_id,data.is_customer_source_ivm)">
                <div class="row-containers">
                  <div class="divCol">
                    <img alt="" *ngIf="data.customer_type == CUSTOMER_TYPES.MUTTER"
                      [src]="IC_ICONS.MOTHER_COMPANY" class="h-20">
                    <img alt="" *ngIf="non_mutter_customers.includes(data.customer_type)"
                      [src]="IC_ICONS.CHILD_COMPANY" class="h-20">
                    <img alt="" *ngIf="data.customer_type == 'Private' || data.customer_type == 'Private VIP'"
                      src="../../../assets/images/User_Icon_for_search.svg" class="h-20">
                  </div>
                  <div>
                    <span>{{data.search_result_label}}</span>
                  </div>
                </div>
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="user_role !== userRoles.IC_GLOBAL_ADMIN">
            <li class="sresult" *ngFor="let data of datasourceOne"
              [class.disable]="division_id !== data['division_id']">
              <a
                (click)="navigatToCustomerResult(data.customer_id,data.user_idnr,data.is_source_ivm, data.liztu_vn_kdnr,data.customer_type,data.search_result_label,data.division_id,data.is_customer_source_ivm)">
                <div class="row-containers">
                  <div class="divCol">
                    <img alt=""
                      *ngIf="data.customer_type == CUSTOMER_TYPES.MUTTER && (division_id == data['division_id'])"
                      [src]="IC_ICONS.MOTHER_COMPANY" class="h-20">
                    <img alt=""
                      *ngIf="non_mutter_customers.includes(data.customer_type) && (division_id == data['division_id'])"
                      [src]="IC_ICONS.CHILD_COMPANY" class="h-20">
                    <img alt="" *ngIf="division_id !== data['division_id']"
                      src="../../../assets/images/Icon awesome-building_Gray.svg" class="h-20">
                    <img alt="" *ngIf="data.customer_type == 'Private' || data.customer_type == 'Private VIP'"
                      src="../../../assets/images/User_Icon_for_search.svg" class="h-20">
                  </div>
                  <div>
                    <span>{{data.search_result_label}}</span>
                  </div>
                </div>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="two" *ngIf="this.two">
        <h6 (click)="showTwo=!showTwo" class="divHeading" #arrow>
          <button *ngIf="showTwo" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
          </button>
          <button *ngIf="!showTwo" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
          </button>
          {{ 'searchComponent.Versicherer_text' | translate }}
        </h6>
        <ul class="list-items" *ngIf="showTwo">
          <li class="sresult" *ngFor="let data of datasourceTwo">
            <a
              (click)="navigatTo(data.customer_id,data.user_idnr,data.liztu_vs_idnr,data.is_source_ivm,data.liztu_vn_kdnr,data.search_result_label,data.division_id)">
              {{data.search_result_label}}
            </a>
          </li>
        </ul>
      </div>
      <div class="three" *ngIf="this.three">
        <h6 (click)="showThree=!showThree" class="divHeading" #arrow>
          <button *ngIf="showThree" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
          </button>
          <button *ngIf="!showThree" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
          </button>
          {{ 'searchComponent.KFZ_text' | translate }}
        </h6>
        <ul class="list-items" *ngIf="showThree">
          <li class="sresult" *ngFor="let data of datasourceThree">
            <a
              (click)="navigatTo(data.customer_id,data.user_idnr,data.liztu_vs_idnr,data.is_source_ivm,data.liztu_vn_kdnr,data.search_result_label,data.division_id)">
              {{data.search_result_label}}
            </a>
          </li>
        </ul>
      </div>
      <div class="four" *ngIf="this.four">
        <h6 (click)="showFour=!showFour" class="divHeading" #arrow>
          <button *ngIf="showFour" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
          </button>
          <button *ngIf="!showFour" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
          </button>
          {{ 'searchComponent.Schadendatum_text' | translate }}
        </h6>
        <ul class="list-items" *ngIf="showFour">
          <li class="sresult" *ngFor="let data of datasourceFour">
            <a
              (click)="navigatToClaims(data.customer_id,data.user_idnr,data.liztu_vs_idnr,data.damage_date,data.is_source_ivm,data.liztu_vn_kdnr,data.customer_type,data.search_result_label,data.division_id)">
              {{data.search_result_label}}
            </a>
          </li>
        </ul>
      </div>
      <div class="six" *ngIf="this.six">
        <h6 (click)="showSix=!showSix" class="divHeading" #arrow>
          <button *ngIf="showSix" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
          </button>
          <button *ngIf="!showSix" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
          </button>
          {{ 'searchComponent.Kundennummer_text' | translate }}
        </h6>
        <ul class="list-items" *ngIf="showSix">
          <li class="sresult" *ngFor="let data of datasourceSix">
            <a
              (click)="navigatToCustomerResult(data.customer_id,data.user_idnr,data.is_source_ivm,data.liztu_vn_kdnr,data.customer_type,data.search_result_label,data.division_id,data.is_customer_source_ivm)">
              <div class="row-containers">
                <div class="divCol">
                  <img alt="" *ngIf="data.customer_type == CUSTOMER_TYPES.MUTTER"
                    [src]="IC_ICONS.MOTHER_COMPANY" class="h-20">
                  <img alt="" *ngIf="non_mutter_customers.includes(data.customer_type)"
                    [src]="IC_ICONS.CHILD_COMPANY" class="h-20">
                  <img alt="" *ngIf="data.customer_type == 'Private' || data.customer_type == 'Private VIP'"
                    src="../../../assets/images/User_Icon_for_search.svg" class="h-20">
                </div>
                <div>
                  <span>{{data.search_result_label}}</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="seven" *ngIf="this.seven">
        <h6 (click)="showSeven=!showSeven" class="divHeading" #arrow>
          <button *ngIf="showSeven" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
          </button>
          <button *ngIf="!showSeven" class="arrow-size">
            <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
          </button>
          {{ 'searchComponent.Vertragsnummer_text' | translate }}
        </h6>
        <ul class="list-items" *ngIf="showSeven">
          <li class="sresult" *ngFor="let data of datasourceSeven">
            <a
              (click)="navigatTo(data.customer_id,data.user_idnr,data.liztu_vs_idnr,data.is_source_ivm,data.liztu_vn_kdnr,data.search_result_label,data.division_id)">
              {{data.search_result_label}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>