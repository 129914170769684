import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadObjectDetails } from '@shared/models/generic.model';
import {
  LocationConstants,
  UploadTypeConstants,
} from '@shared/constants/location.constants';
import {
  BuildingObject,
  Criteria,
  OrganizationalFireProtection,
} from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-organizational-fire-protection',
  templateUrl: './organizational-fire-protection.component.html',
  styleUrls: ['./organizational-fire-protection.component.css'],
})
export class OrganizationalFireProtectionComponent implements OnInit {
  organizationalFireProtectionForm: FormGroup;
  @Input('object_data') object_data;
  @Input('objectType') objectType;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;

  isFormValueUpdated: boolean;
  orderCleanlinessData: any = [];
  showDocumentOrderCleanlinessPopup = false;
  showDocumentSmokingPopup = false;
  selectedOrderCleanlinessDocumentsArray = {};
  selectedSmokingDocumentsArray = {};
  selectedDocumentsArray = {};
  uploadObjectDetails: UploadObjectDetails;
  request: BuildingObject;
  ocdDocsIds = [];
  uohpDocsIds = [];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fireProtectionObjectBuilder: FormBuilder,
    public validation: ValidationService,
    private serviceCall: ApiCallsService
  ) {
    this.serviceCall
      .getLookupData(40)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.data) {
          this.orderCleanlinessData = data.data.sort((a, b) => a.id - b.id);
        }
      });
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }

  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        LocationConstants.ORGANIZATIONAL_FIRE_PROTECTION
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;

    const orderCleanlinessValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.order_cleanliness
        : null,
      disabled: false,
    });
    const orderCleanlinessDocumentsValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.order_cleanliness_documents
        : null,
      disabled: false,
    });

    const safetyConceptValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.safety_concept
        : null,
      disabled: false,
    });
    const bindingLayerThicknessValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.binding_layer_thickness
        : null,
      disabled: false,
    });
    const certificationAccordingToDINValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.certification_according_to_DIN
        : null,
      disabled: false,
    });

    const planningOfMeasuresValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.planning_of_measures
        : null,
      disabled: false,
    });

    const standByServiceValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.stand_By_Service
        : null,
      disabled: false,
    });
    const servicingMaintenanceValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.servicing_maintenance
        : null,
      disabled: false,
    });

    const operationOfPrivateElectricalDDeviceValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection
            .operation_of_private_electrical_device
        : null,
      disabled: false,
    });
    const keyControlAndLockingValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.key_control_and_locking
        : null,
      disabled: false,
    });
    const fireProtectionOfficerValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.fire_protection_officer
        : null,
      disabled: false,
    });
    const fireProtectionRegulationsValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.fire_protection_regulations
        : null,
      disabled: false,
    });
    const fireSafetyExercisesValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.fire_safety_exercises
        : null,
      disabled: false,
    });

    const instructionMonitoringExternalCompaniesValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection
            .instruction_monitoring_external_companies
        : null,
      disabled: false,
    });
    const rectificationDefectsValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.rectification_defects
        : null,
      disabled: false,
    });
    const fireProtectionRecommendationsValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.fire_protection_recommendations
        : null,
      disabled: false,
    });
    const fireProtectionAdviceValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.fire_protection_advice
        : null,
      disabled: false,
    });
    const smokingIsProhibitedValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.smoking_is_prohibited
        : null,
      disabled: false,
    });
    const useOfHotPermitValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.use_of_hot_permit
        : null,
      disabled: false,
    });
    const processControlSystemsValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.process_control_systems
        : null,
      disabled: false,
    });
    const isRegistrationAccidentsAtWorkValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection
            .is_registration_accidents_at_work
        : null,
      disabled: false,
    });
    const accidentsBelowAverageValidation = new FormControl({
      value: formData
        ? formData.organizationalFireProtection.registration_accidents_at_work
            .accidents_below_average
        : null,
      disabled: false,
    });

    this.organizationalFireProtectionForm =
      this.fireProtectionObjectBuilder.group({
        order_cleanliness: orderCleanlinessValidation,
        order_cleanliness_documents: orderCleanlinessDocumentsValidation,
        safety_concept: safetyConceptValidation,
        binding_layer_thickness: bindingLayerThicknessValidation,
        certification_according_to_DIN: certificationAccordingToDINValidation,
        planning_of_measures: planningOfMeasuresValidation,
        stand_By_Service: standByServiceValidation,
        servicing_maintenance: servicingMaintenanceValidation,
        operation_of_private_electrical_device:
          operationOfPrivateElectricalDDeviceValidation,
        key_control_and_locking: keyControlAndLockingValidation,
        fire_protection_officer: fireProtectionOfficerValidation,
        fire_protection_regulations: fireProtectionRegulationsValidation,
        fire_safety_exercises: fireSafetyExercisesValidation,
        instruction_monitoring_external_companies:
          instructionMonitoringExternalCompaniesValidation,
        rectification_defects: rectificationDefectsValidation,
        fire_protection_recommendations:
          fireProtectionRecommendationsValidation,
        fire_protection_advice: fireProtectionAdviceValidation,
        smoking_is_prohibited: smokingIsProhibitedValidation,
        use_of_hot_permit: useOfHotPermitValidation,
        process_control_systems: processControlSystemsValidation,

        is_registration_accidents_at_work:
          isRegistrationAccidentsAtWorkValidation,
        accidents_below_average: accidentsBelowAverageValidation,
      });
    this.isFormValueUpdated = false;
    this.organizationalFireProtectionForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.organizationalFireProtectionForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
    this.getDocumentUploadData(record_id);
  }

  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 12)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.OFP_ORDER_CLEANLINESS
              ) {
                this.selectedOrderCleanlinessDocumentsArray[
                  document.document_name
                ] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.OFP_USE_OF_HOT_PERMIT
              ) {
                this.selectedSmokingDocumentsArray[document.document_name] =
                  document;
              }
            });
            if (data.documents) {
              this.organizationalFireProtectionForm.controls.order_cleanliness_documents.setValue(
                Object.keys(this.selectedOrderCleanlinessDocumentsArray).join(
                  ', '
                )
              );
              this.organizationalFireProtectionForm.controls.use_of_hot_permit.setValue(
                Object.keys(this.selectedSmokingDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }

  buildFormObject() {
    const registerAccidentAtWork = {
      accidents_below_average:
        this.organizationalFireProtectionForm.value.accidents_below_average,
    };

    const organizationalFireProtection: OrganizationalFireProtection = {
      order_cleanliness: this.organizationalFireProtectionForm.value
        .order_cleanliness
        ? this.organizationalFireProtectionForm.value.order_cleanliness
        : null,
      order_cleanliness_documents:
        this.organizationalFireProtectionForm.value.order_cleanliness_documents,
      safety_concept:
        this.organizationalFireProtectionForm.value.safety_concept,
      binding_layer_thickness:
        this.organizationalFireProtectionForm.value.binding_layer_thickness,
      certification_according_to_DIN:
        this.organizationalFireProtectionForm.value
          .certification_according_to_DIN,
      planning_of_measures:
        this.organizationalFireProtectionForm.value.planning_of_measures,
      stand_By_Service:
        this.organizationalFireProtectionForm.value.stand_By_Service,
      servicing_maintenance:
        this.organizationalFireProtectionForm.value.servicing_maintenance,
      operation_of_private_electrical_device:
        this.organizationalFireProtectionForm.value
          .operation_of_private_electrical_device,
      key_control_and_locking:
        this.organizationalFireProtectionForm.value.key_control_and_locking,
      fire_protection_officer:
        this.organizationalFireProtectionForm.value.fire_protection_officer,
      fire_protection_regulations:
        this.organizationalFireProtectionForm.value.fire_protection_regulations,
      fire_safety_exercises:
        this.organizationalFireProtectionForm.value.fire_safety_exercises,
      instruction_monitoring_external_companies:
        this.organizationalFireProtectionForm.value
          .instruction_monitoring_external_companies,
      rectification_defects:
        this.organizationalFireProtectionForm.value.rectification_defects,
      fire_protection_recommendations:
        this.organizationalFireProtectionForm.value
          .fire_protection_recommendations,
      fire_protection_advice:
        this.organizationalFireProtectionForm.value.fire_protection_advice,
      smoking_is_prohibited:
        this.organizationalFireProtectionForm.value.smoking_is_prohibited,
      use_of_hot_permit:
        this.organizationalFireProtectionForm.value.use_of_hot_permit,
      process_control_systems:
        this.organizationalFireProtectionForm.value.process_control_systems,
      is_registration_accidents_at_work:
        this.organizationalFireProtectionForm.value
          .is_registration_accidents_at_work,
      registration_accidents_at_work: registerAccidentAtWork,
    };
    const formValuesToPersist = {
      organizationalFireProtection,
    };
    this.request = {
      document_ids: [...new Set([...this.ocdDocsIds, ...this.uohpDocsIds])],
      criteria_id: LocationConstants.ORGANIZATIONAL_FIRE_PROTECTION,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (
      this.isFormValueUpdated &&
      this.organizationalFireProtectionForm.touched
    ) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
  uploadOrderCleanlinessDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedOrderCleanlinessDocumentsArray,
    };
    this.showDocumentOrderCleanlinessPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.OFP_ORDER_CLEANLINESS,
    };
  }
  uploadSmokingDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedSmokingDocumentsArray };
    this.showDocumentSmokingPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.OFP_USE_OF_HOT_PERMIT,
    };
  }
  showDocumentPopup() {
    return (
      this.showDocumentOrderCleanlinessPopup || this.showDocumentSmokingPopup
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showDocumentOrderCleanlinessPopup) {
      this.ocdDocsIds = [];
      this.selectedOrderCleanlinessDocumentsArray = {
        ...selectedDocumentsArray,
      };
      this.showDocumentOrderCleanlinessPopup = false;
      this.organizationalFireProtectionForm.controls.order_cleanliness_documents.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.organizationalFireProtectionForm.controls.order_cleanliness_documents.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.ocdDocsIds.push(selectedDocumentsArray[name].upload_document_id);
        }
      });
    }
    if (this.showDocumentSmokingPopup) {
      this.uohpDocsIds = [];
      this.selectedSmokingDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentSmokingPopup = false;
      this.organizationalFireProtectionForm.controls.use_of_hot_permit.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.organizationalFireProtectionForm.controls.use_of_hot_permit.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uohpDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.organizationalFireProtectionForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }
}
