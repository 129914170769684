import { Component, Input, OnInit } from '@angular/core';
import {
  Filter,
  FilterType,
} from '@shared/components/table/filter-section/filter-section.component';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { MatDialog } from '@angular/material/dialog';
import { CreateAppointmentPopupComponent } from './create-appointment-popup/create-appointment-popup.component';
import { SourceType } from '../../models/source-type.model';
import { AppointmentApiService } from '@core/services/httpcalls/appointment-api.service';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import {
  Appointment,
  GetAppointmentsParams,
  TableAppointment,
} from '../../models/appointment.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { iif, of } from 'rxjs';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { DateAndTimeComponent } from '../table/table-column/date-and-time.component';
import { CreatedByComponent } from '../table/table-column/created-by.component';
import { UpdatedByComponent } from '../table/table-column/updated-by.component';
import { ReminderComponent } from '../table/table-column/reminder.component';
import { GuestsComponent } from '../table/table-column/guests.component';
import {
  APPOINTMENTS_COLUMNS,
  APPOINTMENT_COLUMN_HEADINGS,
  AppointmentsColumns,
} from '../../constants/appointments.constants';
import {
  PaginationConfig,
  TableActionEvent,
  TableConfig,
  TableSortConfig,
} from '../../models/table.model';
import { DATE_ISO_FORMAT } from '../../constants/common.constants';
import * as moment from 'moment';
import { Attendee } from '../../models/attendee.model';
import { AttendeeApiService } from '@core/services/httpcalls/attendee-api.service';
import { GuestOptionComponent } from '../select-options/guest-option/guest-option.component';
import {
  ColumnType,
  TABLE_ACTIONS_KEYS,
  TABLE_ACTION_LABEL,
} from '@shared/constants/table.constants';

const APPOINTMENTS_TABLE_HEIGHT = {
  GLOBAL: 'calc(100vh - 410px)',
  FEATURE: 'calc(100vh - 560px)',
};

interface AppointmentFilterConfig {
  date?: { start: Date; end: Date };
  attendees?: string[];
  division?: number;
}

@Component({
  selector: 'app-appointments',
  templateUrl: 'appointments.component.html',
  styleUrls: ['appointments.component.scss'],
})
export class AppointmentsComponent extends BaseComponent implements OnInit {
  @Input() source: SourceType;
  @Input() sourceId: number;
  @Input() customerId: number;
  @Input() divisionId: number;

  filters: Filter[];
  filterConfig: AppointmentFilterConfig = {
    date: {
      start: new Date(),
      end: null,
    },
  };
  tableConfig: TableConfig;
  sortConfig: TableSortConfig;
  pagination: PaginationConfig = {
    page: 1,
    size: 25,
    total: 0,
    label: this._translateService.instant(
      'appointments.number_of_appointments'
    ),
  };
  data: TableAppointment[];

  constructor(
    private _dialog: MatDialog,
    private _appointmentApiService: AppointmentApiService,
    private _attendeeApiService: AttendeeApiService,
    private _translateService: TranslateService,
    private _apiCallsService: ApiCallsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.tableConfig = this._getTableConfig();

    this._loadAttendees();
    this._loadAppointments();
  }

  filter(filterConfig: AppointmentFilterConfig): void {
    this.filterConfig = filterConfig;

    this._loadAppointments();
  }

  changePage(page: number): void {
    this.pagination.page = page;

    this._loadAppointments();
  }

  handleAction({ key, element }: TableActionEvent): void {
    switch (key) {
      case TABLE_ACTIONS_KEYS.EDIT:
        this.openCreateAppointmentPopup(element);
        break;
      case TABLE_ACTIONS_KEYS.DELETE:
        this._deleteAppointment(element.id);
        break;
      default:
        break;
    }
  }

  openCreateAppointmentPopup(appointment: TableAppointment = null): void {
    this._dialog
      .open(CreateAppointmentPopupComponent, {
        data: {
          customerId: this.customerId,
          sourceId: this.sourceId,
          source: this.source,
          divisionId: this.divisionId,
          appointment,
        },
      })
      .afterClosed()
      .pipe(filter(Boolean), takeUntil(this._destroy$))
      .subscribe(() => {
        this._loadAppointments();
      });
  }

  changePageSize(size: number): void {
    this.pagination.size = size;

    this._loadAppointments();
  }

  sort(sortConfig: TableSortConfig): void {
    this.sortConfig = sortConfig;

    this._loadAppointments();
  }

  private _formatTableData(appointments: Appointment[]): TableAppointment[] {
    return appointments.map(appointment => ({
      ...appointment,
      date: new Date(appointment.startDateTime),
      guests: appointment.attendees.map(attendee => ({
        name: attendee.name,
        src: attendee.profilePictureUrl,
      })),
      action: [
        {
          label: TABLE_ACTION_LABEL.EDIT,
          icon: ACTION_BUTTON.EDIT_WO_BACKGROUND,
          key: TABLE_ACTIONS_KEYS.EDIT,
        },
        {
          label: TABLE_ACTION_LABEL.DELETE,
          icon: ACTION_BUTTON.DELETE_WO_BACKGROUND,
          key: TABLE_ACTIONS_KEYS.DELETE,
        },
      ],
    }));
  }

  private _loadAppointments(): void {
    const params: GetAppointmentsParams = {
      page: this.pagination.page,
      size: this.pagination.size,
      ...this._getSourceParam(),
    };

    if (this.sortConfig?.sortDirection) {
      (params.sortProperty = this.sortConfig.sortProperty),
        (params.sortDirection = this.sortConfig.sortDirection);
    }

    if (this.source && this.customerId) {
      params.customerId = this.customerId;
    }

    if (this.filterConfig?.division || this.divisionId) {
      params.divisionId = this.filterConfig?.division || this.divisionId;
    }

    if (this.filterConfig?.date.start) {
      params.fromDate = moment(this.filterConfig.date.start)
        .startOf('day')
        .format(DATE_ISO_FORMAT);
    }

    if (this.filterConfig?.date.end) {
      params.toDate = moment(this.filterConfig.date.end)
        .endOf('day')
        .format(DATE_ISO_FORMAT);
    }

    if (this.filterConfig?.attendees) {
      params.attendees = this.filterConfig.attendees;
    }

    this._appointmentApiService
      .getAppointments(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.data = this._formatTableData(data.content);
        this.pagination = {
          ...this.pagination,
          total: data.totalRecords,
        };
      });
  }

  private _getSourceParam(): { [key: string]: number } {
    switch (this.source) {
      case SourceType.CUSTOMER:
        return { customerId: this.sourceId };
      case SourceType.CLAIM:
        return { claimId: this.sourceId };
      case SourceType.CONTRACT:
        return { contractId: this.sourceId };
      case SourceType.INSURER:
        return { insurerId: this.sourceId };
      case SourceType.INTERMEDIARY:
        return { intermediaryId: this.sourceId };
      default:
        return {};
    }
  }

  private _loadAttendees(): void {
    let attendees = [];

    this._attendeeApiService
      .getAttendees()
      .pipe(
        mergeMap(data => {
          attendees = data;

          return iif(
            () => !!this.source,
            of(null),
            this._apiCallsService.getDivisionList()
          );
        }),
        takeUntil(this._destroy$)
      )
      .subscribe(data => {
        this.filters = this._getFilters(attendees, data?.data);
      });
  }

  private _deleteAppointment(id: string): void {
    this._dialog
      .open(ConfirmPopupComponent, {
        data: {
          subHeading: this._translateService.instant(
            'appointments.delete_confirm'
          ),
          successBtnText: this._translateService.instant(
            'confirm_popup.yes_delete'
          ),
        },
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        mergeMap(() => this._appointmentApiService.deleteAppointment(id)),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this._loadAppointments();
      });
  }

  private _getFilters(attendees: Attendee[], divisions = []): Filter[] {
    let filters: Filter[] = [
      {
        name: 'date',
        type: FilterType.DATE_RANGE,
        startValue: new Date(),
        label: this._translateService.instant(
          'appointments.date_of_appointment'
        ),
      },
      // TODO: uncomment after implemented
      // {
      //     name: 'attendees',
      //     placeholder: this._translateService.instant('appointments.choose_guests'),
      //     type: FilterType.SELECT,
      //     isMultiple: true,
      //     label: this._translateService.instant('appointments.guests'),
      //     optionComponent: GuestOptionComponent,
      //     options: attendees.map(attendee => ({
      //         label: attendee.name,
      //         value: attendee.email,
      //         data: {
      //             imgSrc: attendee.profilePictureUrl
      //         }
      //     }))
      // }
    ];

    if (divisions?.length) {
      filters = [
        {
          name: 'division',
          type: FilterType.SELECT,
          label: this._translateService.instant('appointments.division'),
          placeholder: this._translateService.instant('appointments.division'),
          options: divisions.map(division => ({
            label: division.desc,
            value: division.id,
          })),
        },
        ...filters,
      ];
    }
    return filters;
  }

  private _getTableConfig(): TableConfig {
    const config: TableConfig = {
      displayColumns: [...APPOINTMENTS_COLUMNS],
      columnDetails: [
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.NAME
          ),
          name: AppointmentsColumns.SUBJECT,
          type: ColumnType.STRING,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.DESCRIPTION
          ),
          name: AppointmentsColumns.DESCRIPTION,
          type: ColumnType.STRING,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.LOCATION
          ),
          name: AppointmentsColumns.LOCATION,
          type: ColumnType.STRING,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.DATE
          ),
          name: AppointmentsColumns.DATE,
          type: ColumnType.COMPONENT,
          component: DateAndTimeComponent,
          maxWidth: 100,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.GUESTS
          ),
          name: AppointmentsColumns.GUESTS,
          type: ColumnType.COMPONENT,
          component: GuestsComponent,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.REMINDER
          ),
          name: AppointmentsColumns.REMINDER,
          type: ColumnType.COMPONENT,
          component: ReminderComponent,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.CREATED_BY
          ),
          name: AppointmentsColumns.CREATED_BY,
          type: ColumnType.COMPONENT,
          component: CreatedByComponent,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.UPDATED_BY
          ),
          name: AppointmentsColumns.UPDATED_BY,
          type: ColumnType.COMPONENT,
          component: UpdatedByComponent,
        },
        {
          title: this._translateService.instant(
            APPOINTMENT_COLUMN_HEADINGS.ACTION
          ),
          name: AppointmentsColumns.ACTION,
          type: ColumnType.ACTION,
        },
      ],
      styles: {
        maxHeight: this.source
          ? APPOINTMENTS_TABLE_HEIGHT.FEATURE
          : APPOINTMENTS_TABLE_HEIGHT.GLOBAL,
      },
    };

    if (!this.source) {
      config.displayColumns.splice(4, 0, AppointmentsColumns.DIVISION);
      config.columnDetails.splice(4, 0, {
        title: this._translateService.instant(
          APPOINTMENT_COLUMN_HEADINGS.DIVISION
        ),
        name: AppointmentsColumns.DIVISION,
        type: ColumnType.STRING,
      });
    }

    return config;
  }
}
