<div class="external-container">
    <div class="container-heading">
        <span class="criteria-heading">{{'product-management.step.mask_generator' | translate}}</span>
    </div>
    <div class="row-containers">
        <div class="tree-container">
            <div class="stepper-inner">
                <tree-list-component *ngIf="loadTreeData" [treeData]="coveringData" [nodeLevel]="4"
                    [isDisabled]="isTreeDisabled" [buttonText]="buttonText"
                    (updatedNodeSelection)="updatedNodeSelection($event)" (addNewNode)="addNewNode($event)">
                </tree-list-component>
            </div>
        </div>
        <div class="form-container" *ngIf="selectedNode && [0,1,2].includes(selectedNode.level)">
            <div *ngIf="selectedNode && [0,1,2].includes(selectedNode.level)">
                <div class="masking-container-heading" style="display: flex;align-items: center;">
                    <div style="width: 85%;">
                        <span class="criteria-heading"
                            *ngIf="selectedNode.level==0">{{'product-management.mask_generator.Product_Module' |
                            translate}} -
                            {{selectedNode.item}}</span>
                        <span class="criteria-heading"
                            *ngIf="selectedNode.level==1">{{'product-management.mask_generator.Cover_Bundle' |
                            translate}} -
                            {{selectedNode.item}}</span>
                        <span class="criteria-heading"
                            *ngIf="selectedNode.level==2">{{'product-management.mask_generator.Covering'
                            | translate}} - {{selectedNode.item}}</span>
                        <br>
                        <span class="criteria-suffix">{{'product-management.mask_generator.stepper_msg' |
                            translate}}</span>
                    </div>
                    <div style="justify-content: flex-end;width: 15%;">
                        <button (click)="closeForm()" class="mask-stepper-form-button" style="padding-left: 10px;">
                            <img alt="" class="product-close-button" src="../../../../assets/images/closeIcon.png">
                        </button>
                        <button *ngIf="!isTreeDisabled && !isAutoPopulatedSelected" (click)="saveForm()"
                            class="mask-stepper-form-button" style="padding-right: 10px;">
                            <img alt="" *ngIf="!isFormTouched" src="../../../../assets/images/Edit_icon.svg">
                            <img alt="" *ngIf="isFormTouched" src="../../../../assets/images/save-button.svg">
                        </button>
                    </div>
                </div>
                <div class="stepper-inner-mask-p1-inner">
                    <app-mask-generator [maskGenFormData]="maskGenFormData" [configData]="configData"
                        [isAutoPopulatedSelected]="isAutoPopulatedSelected" [isProductDeactivated]="isTreeDisabled"
                        (emitFormValue)="formValues($event)">
                    </app-mask-generator>
                </div>
            </div>

            <div class="stepper-inner-mask-p2" *ngIf="selectedNode && [0,1,2].includes(selectedNode.level)">
                <div class="masking-container-heading">
                    <span class="criteria-heading">{{'product-management.mask_generator.Value_Calculator' | translate}}
                    </span>
                    <br>
                    <span class="criteria-suffix">{{'product-management.mask_generator.value_stepper_msg' |
                        translate}}</span>
                    <button (click)="addValueCalculator()" class="mask-stepper-two-button" [disabled]="isTreeDisabled">
                        <img alt="" style="height: 14px;
                        width: 17px;margin-top:-3px" src="../../../../assets/images/Icon ionic-md-close.svg">

                    </button>
                </div>
                <div class="stepper-inner-mask-p2-inner" *ngIf="formulaList.length > 0">
                    <table class="mask-p2-table">
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr *ngFor="let item of formulaList" style="height: 36px">
                            <td style="width:90%">{{item.target_variable_attribute}} - {{item.formula_name}}</td>

                            <td style="display: flex;">

                                <button (click)="editFormula(item)" [disabled]="isTreeDisabled"><img alt=""
                                        style="height: 13px" src="../../../../assets/images/Eye_show 2.svg"></button>
                                <button (click)="deleteFormula(item.id)" style="margin-left:5px"
                                    [disabled]="isTreeDisabled"><img alt="" style="height: 20px;width: 20px;"
                                        src="../../../../assets/images/Group 2658.svg"></button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="formulaList.length < 1" class="mask-empty-stepper-inner" style="height:230px">
                    {{'value-calculator.blank_list_message' | translate}}
                </div>
            </div>
        </div>
        <div class="form-container mask-empty-stepper-inner"
            *ngIf="!selectedNode || ![0,1,2].includes(selectedNode.level)">
            {{'product-management.stepper_common_msg.mask_node_step_empty_msg' | translate}}
        </div>
    </div>
</div>
<span class="tips-class" *ngIf="consolidateData && consolidateData.productStateId==2"><img alt="" class="tips-img"
        src="../../../../assets/images/tip_icon_summary.svg">{{'product-management.stepper_common_msg.update_product_nonedit_message'
    | translate}}</span>
<product-close-alert-dialog *ngIf="showWarningPopUp" (handlePopup)="handlePopup($event)"></product-close-alert-dialog>

<app-formula-maker *ngIf="showFormulaPopup" [show_formula_popup]="showFormulaPopup"
    [targetVariableParentName]="targetVariableParentName" [data]="formula" [is_calculation]="is_calculation"
    (hide)="hideFormulaPopup($event)" (is_saved)="addNewFormula($event)" (message)="getMessage($event)">
</app-formula-maker>