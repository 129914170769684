import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class BaseComponent implements OnDestroy {
    readonly _destroy$: Subject<void> = new Subject();

    constructor() {}

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
