<div class="ic-modal" [class.ic-modal--no-footer]="noActions" [style.width.px]="width">
    <h2 mat-dialog-title>
        <ng-content select="[title]"></ng-content>
        <mat-icon (click)="closeModal()">close</mat-icon>
    </h2>
    
    <mat-dialog-content>
        <ng-content select="[content]"></ng-content>
    </mat-dialog-content>
    
    <mat-dialog-actions #actions *ngIf="!noActions">
        <ng-content select="[actions]"></ng-content>
    </mat-dialog-actions>
</div>