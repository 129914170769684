<!-- Heading -->
<h6 class="customer-management-page-heading" *ngIf="tableConfig.isHeadingAboveFilter">
    {{tableConfig.headingText}}
</h6>
<!-- Heading End -->

<!-- Filter Section Stars -->
<div class="filter-distance"  *ngIf="tableConfig.isFilterSection">
    <mat-accordion>
        <mat-expansion-panel class="filter-section" (opened)="filterToggle = true" (closed)="filterToggle = false"
            [expanded]="filterToggle" hideToggle>
            <mat-expansion-panel-header [expandedHeight]="customFilterExpandedHeight"
                class="filter-section-header p-unset">
                <mat-panel-title class="filter-section-title-alignment filter-title">
                    <div class="flex-center g-7">
                        <button class="default-unset">
                            <mat-icon *ngIf="filterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="!filterToggle" class="arrowButtons va-bottom">keyboard_arrow_right
                            </mat-icon>
                        </button>
                        <span>
                            <strong>{{filterSection?.heading}}</strong>
                        </span>
                    </div>
                    <div *ngIf="filterSection?.isPagination" class="paginator-dropdown-container"
                        (click)="$event.stopPropagation()">
                        <mat-select panelClass="rowChange" (selectionChange)="numberOfRowsChanged($event)"
                            class="paginator-dropdown filter-section-dropdown" [(ngModel)]="paginationObject.pageSize"
                            disableOptionCentering>
                            <mat-option *ngFor="let row of filterSection?.numberOfRows" [value]="row">{{row}}
                            </mat-option>
                        </mat-select>
                        <span class="results-per-page">
                            {{'claims.results_per_page' | translate}}
                        </span>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="filterSection">
                <form [formGroup]="filterForm">
                    <div class="flex-common f-13">
                        <div class="flex-center dropdowns-gap">
                            <div *ngFor="let ele of filterSection?.elements">
                                <mat-select *ngIf="ele.elementType == 1" [formControlName]="ele.controlName"
                                    (selectionChange)="resetSearchString()"
                                    (openedChange)="resetSearchString()"
                                    [placeholder]="ele.placeholder" style="width: 230px;" [title]="_dropdownTitle.getDropdownTitle(ele.controlName, filterForm, ele?.data, 'id', 'desc')">
                                    <ng-container *ngIf="ele.isSearchable">
                                        <input type="text" maxlength="20" class="searchSelect" preventSpace
                                            placeholder="{{'header.Suche_tooltip' | translate}}" #SearchRow>
                                        <mat-option *ngIf="ele.isSelectShow" style="opacity: 0.5;">
                                            {{'objectModule.select_lable' | translate}}</mat-option>
                                        <mat-option [title]="row.desc"
                                            *ngFor="let row of validationService._filter(ele?.data, 'desc', SearchRow.value)"
                                            [value]="row.id">
                                            <span>{{row.desc}}</span>
                                        </mat-option>
                                    </ng-container>
                                    <ng-container *ngIf="!ele.isSearchable">
                                        <mat-option *ngIf="ele.isSelectShow" style="opacity: 0.5;">
                                            {{'objectModule.select_lable' | translate}}</mat-option>
                                        <mat-option *ngFor="let row of ele?.data" [title]="row.desc" [value]="row.id">
                                            {{row.desc}}</mat-option>
                                    </ng-container>
                                </mat-select>
                                <input
                                    matInput
                                    type="text" 
                                    class="input"
                                    *ngIf="ele.elementType === 2" 
                                    [formControlName]="ele.controlName"
                                    [placeholder]="ele.placeholder"
                                    style="width: 230px;" />
                            </div>
                        </div>
                        <div class="flex-center filter-btn-gap">
                            <button class="filterBtn" *ngIf="filterSection?.isFilterBtn"
                                (click)="filterBtnClicked(methodNames.FILTERDATA)">
                                {{'user-data.Filter_text' | translate}}
                            </button>
                            <button class="filterBtn"
                                *ngIf="filterSection?.isExportExcelButton"
                                [placement]="filterSection?.resetTooltip.tooltipPlacement"
                                ngbTooltip="{{'insurer_management.overview.export_excel' | translate}}"
                                tooltipClass="generic-tooltip" (click)="exportExcelClicked()">
                                <img alt="" [src]="actionIcons.EXPORT_EXCEL">
                            </button>
                            <button class="filterBtn" *ngIf="filterSection?.isResetBtn"
                                [placement]="filterSection?.resetTooltip.tooltipPlacement"
                                [ngbTooltip]="filterSection?.resetTooltip.tooltipText" tooltipClass="generic-tooltip"
                                (click)="clearFilterSection()">
                                <img alt="" src="{{filterSection?.resetBtnIconLink}}" style="height: 16px;">
                            </button>
                        </div>
                    </div>
                </form>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<!-- Filter Section Ends -->

<!-- Table starts here -->
<div class="table-outer-container">
    <div class="infiniteScrollTable" [style.max-height]="tableObj?.optionalStyles?.maxHeight || ''">
        <mat-table class="custom-table" [dataSource]="dataSource" cellpadding="5" cellspacing="0" matSort>
            <ng-container [matColumnDef]="col.matColumnDef" *ngFor="let col of tableObj.columnDetails">
                <ng-container *ngIf="col.isSortable; else notSortable">
                    <mat-header-cell *matHeaderCellDef mat-sort-header
                        [style.max-width.px]="col.width"
                        [ngClass]="{'action-header' : col.columnType == 2}">
                        <span [ngClass]="{'toUpper' : (locale$ | async) == localeConstants.ENGLISH}"
                            class="header-with-sorting">{{col.colHeading | uppercase}} <img alt="" [src]="tableIcons.SORT"></span>
                    </mat-header-cell>
                </ng-container>
                <ng-template #notSortable>
                    <mat-header-cell *matHeaderCellDef [style.max-width.px]="col.width" [ngClass]="{'action-header' : col.columnType == 2}">
                        <span [ngClass]="{'toUpper' : (locale$ | async) == localeConstants.ENGLISH}">{{col.colHeading | uppercase}}</span>
                    </mat-header-cell>
                </ng-template>
                <mat-cell *matCellDef="let element" [style.max-width.px]="col.width">
                    <ng-container *ngIf="col.columnType == 1">
                        <span class="custom-ellipsis noSafariTooltip"
                            title="{{element[col.matColumnDef]}}">{{element[col.matColumnDef]}}</span>
                    </ng-container>
                    <ng-container *ngIf="col.columnType == 2">
                        <div class="control-section" style="text-align: center;">
                            <div class="subscribe">
                                <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                                <div class="hide">
                                    <div class="action-btn-align" (click)="$event.stopPropagation()">
                                        <ng-container *ngFor="let actionBtn of element[col.matColumnDef]">
                                            <button *ngIf="!actionBtn.isHidden" class="action-btn"
                                                [placement]="actionBtn.singleTooltip.tooltipPlacement"
                                                [ngbTooltip]="actionBtn.singleTooltip.tooltipText"
                                                tooltipClass="generic-tooltip"
                                                (click)="methodToBeCalledInParentComponent(actionBtn.functionToBeCalled, element)"
                                                [disabled]="actionBtn.isDisabled">
                                                <img class="action-btn-img" [style.width.px]="actionBtn.width" alt="" [src]="actionBtn.iconLink">
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="col.columnType == 3">
                        <div class="custom-ellipsis noSafariTooltip" [matTooltip]="element[col.secondRowColumnDef]">
                            {{element[col.secondRowColumnDef]}}
                        </div>
                        <div class="custom-table-below-text">
                            {{element[col.matColumnDef]}}
                        </div>
                    </div>
                    <ng-container *ngIf="col.columnType == 4">
                        <div class="flex-center">
                            <mat-checkbox class="custom-checkBox custom-checkbox-form mr-3"
                                [checked]="element[col.matColumnDef]" disabled>
                            </mat-checkbox>
                            <button class="upload-download-btn" [disabled]="!element[col.matColumnDef]"
                                (click)="showDocument(element.id)">
                                <img alt="" class="rotate-180" src="assets/images/common/upload_download.svg">
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="col.columnType === 5">
                        <ng-container dynamicCell [component]="col.component" [inputs]="{ element: element }"></ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="tableObj.displayColumns"></mat-header-row>
            <mat-row
                (click)="methodToBeCalledInParentComponent(tableConfig.rowClick, row)"
                [class.row--clickable]="tableConfig.rowClick"
                *matRowDef="let row; columns: tableObj.displayColumns"></mat-row>
        </mat-table>
    </div>
    <div class="table-footer" *ngIf="tableConfig.isPagination">
        <div class="paginator-footer">
            <ngb-pagination [collectionSize]="paginationObject.totalElements" (pageChange)="tablePageChanged($event)"
                [maxSize]=5 [(page)]="paginationObject.currentPage" [pageSize]="paginationObject.pageSize"
                [boundaryLinks]="false">
                <ng-template ngbPaginationPrevious>
                    <i class="fa fa-angle-left"></i>
                </ng-template>
                <ng-template ngbPaginationNext>
                    <i class="fa fa-angle-right"></i>
                </ng-template>
            </ngb-pagination>
            <div class="footer-text">
                <span>
                    {{ paginationObject.paginationText }} : {{ paginationObject.totalElements }}
                </span>
            </div>
        </div>
    </div>
</div>
<!-- Table ends here -->
<app-upload-document [selectedDocumentsArray]="selectedDocumentsArray" [is_disable]="true"
    [showDocumentPopup]="showDocumentPopup" (hide)="hidePopup($event)">
</app-upload-document>