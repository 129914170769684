<div class="container save-changes">
    <div class="row">
        <div class="col-md-2">
            <img alt="" src="../../../assets/images/Path 91988 (1).svg" >
        </div>
        <div class="col-md-10 generic-message">{{message}}</div>
    </div>
    <div class="row">
        <div class="col-md-12 text-right">
            <button type="button" (click)="closePopup()" class="btn btn-warning ml-2" mat-button>{{'main_component.modal_button_text' | translate}}</button>
        </div>
    </div>
</div>