import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[safariNumberInput]'
})
export class SafariNumberOnlyDirective {
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const sanitizedValue = value.replace(/[^0-9.,]/g, '');

    if (value !== sanitizedValue) {
      input.value = sanitizedValue;
      input.dispatchEvent(new Event('input'));
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData ;
    const pastedText = clipboardData.getData('text');
    const sanitizedText = pastedText.replace(/[^0-9.,]/g, '');

    document.execCommand('insertText', false, sanitizedText);
    event.preventDefault();
  }
}
