import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsTemplateManagementApiService } from '@core/services/httpcalls/ms-templates-managment-api.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base.component';
import { ConfirmPopupComponent } from '@shared/components/confirm-popup/confirm-popup.component';
import { Filter, FilterType } from '@shared/components/table/filter-section/filter-section.component';
import { CreatedByComponent } from '@shared/components/table/table-column/created-by.component';
import { UpdatedByComponent } from '@shared/components/table/table-column/updated-by.component';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import {
  MsTemplatesColumns,
  MS_TEMPLATES_COLUMNS,
  MS_TEMPLATES_COLUMN_HEADINGS
} from '@shared/constants/ms-templates-management.constants';
import { ColumnType, TABLE_ACTIONS_KEYS, TABLE_ACTION_LABEL } from '@shared/constants/table.constants';
import {
  DocumentTargetDropdownItem,
  DropdownItem,
  GetMsTemplatesParams, MsTemplate, MsTemplateCategory,
  TableMsTemplateCategory,
  TableMsTemplateChildren
} from '@shared/models/ms-template.model';
import { PaginationConfig, TableActionEvent, TableConfig, TableSortConfig } from '@shared/models/table.model';
import { Utils } from '@shared/utils/utils';
import { forkJoin } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { UploadMsTemplatePopupComponent } from './upload-ms-template-popup/upload-ms-template-popup.component';

interface MsTemplatesManagementFilterConfig {
  type?: number;
  target?: number;
}

interface MsTemplateSelectEvent {
  element: TableMsTemplateChildren & TableMsTemplateCategory;
  data: TableMsTemplateCategory[];
}
@Component({
  selector: 'app-ms-templates-management',
  templateUrl: './ms-templates-management.component.html',
  styleUrls: ['./ms-templates-management.component.scss']
})
export class MsTemplatesManagementComponent extends BaseComponent implements OnInit {

  filters: Filter[] = [];
  filterConfig: MsTemplatesManagementFilterConfig = {
  };
  searchValue = '';
  tableConfig: TableConfig;
  sortConfig: TableSortConfig;
  pagination: PaginationConfig = {
    page: 1,
    size: 25,
    total: 0,
    label: this._translateService.instant('rcs-setup.ms-template-management.number_of_templates'),
  };
  data: TableMsTemplateCategory[];

  constructor(
    private _translateService: TranslateService,
    private _dialog: MatDialog,
    private _msTemplatesManagementApiService: MsTemplateManagementApiService,
  ) {
      super();
  }

  ngOnInit(): void {
    this.tableConfig = this._getTableConfig();

    this._loadMsTemplates();
    this._loadFilters();
  }

  filter(filterConfig: MsTemplatesManagementFilterConfig): void {
    this.filterConfig = filterConfig;

    this._loadMsTemplates();
  }

  search(value: string): void {
    this.searchValue = value;

    this._loadMsTemplates();
  }

  changePage(page: number): void {
    this.pagination.page = page;

    this._loadMsTemplates();
  }

  changePageSize(size: number): void {
    this.pagination.size = size;

    this._loadMsTemplates();
  }

  sort(sortConfig: TableSortConfig): void {
    this.sortConfig = sortConfig;

    this._loadMsTemplates();
  }

  handleAction({ key, element }: TableActionEvent): void {
    switch (key) {
      case TABLE_ACTIONS_KEYS.EDIT:
        this.openEditMsTemplatePopup(element.id);
        break;
      case TABLE_ACTIONS_KEYS.DELETE:
        this._deleteMsTemplate(element.id);
        break;
      case TABLE_ACTIONS_KEYS.DOWNLOAD:
        this._downloadTemplate(element);
        break;
      default:
        break;
    }
  }

  openUploadMsTemplatePopup(msTemplate: MsTemplate = null): void {
    this._dialog
      .open(UploadMsTemplatePopupComponent, { data: { msTemplate }})
      .afterClosed()
      .pipe(filter(Boolean), takeUntil(this._destroy$))
      .subscribe(() => this._loadMsTemplates());
  }

  openEditMsTemplatePopup(id: number): void {
    this._msTemplatesManagementApiService
      .getMsTemplateById(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(msTemplate => this.openUploadMsTemplatePopup(msTemplate));
  }

  handleTemplateSelect({ element, data }: MsTemplateSelectEvent) {
    this._msTemplatesManagementApiService
      .selectTemplate({
        ids: element.children ? element.children.map(el => el.id) : [element.id],
        isVisible: !element.selected,
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        const elementIndex = data.findIndex(el => el.id === element.id);
        const parentIndex = data.findIndex(el => el.id === element.parentId);

        if (element.parentId) {
          const childrenInnerIndex = data[parentIndex].children.findIndex(el => el.id === element.id);
          data[parentIndex].children[childrenInnerIndex].selected = !element.selected;
          data[parentIndex].selected = data[parentIndex].children.some(el => el.selected);
        }
        if (element.children) {
          data[elementIndex].children = data[elementIndex].children.map(el => ({...el, selected: !element.selected}));
          data[elementIndex].children.forEach((child) =>
            data.forEach((el, i) =>
              el.id === child.id ? data[i].selected = !element.selected : null
            ));
        }
        data[elementIndex].selected = !element.selected;
        this.data = [...data];
      });
  }

  private _loadFilters(): void {
    forkJoin([
      this._msTemplatesManagementApiService.getMsTemplateTypes(),
      this._msTemplatesManagementApiService.getMsTemplateTargets()
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([types, targets]) => this.filters = this._getFilters(types, targets));
  }

  private _loadMsTemplates(): void {
    const params: GetMsTemplatesParams = {
      page: this.pagination.page,
      size: this.pagination.size,
      title: this.searchValue
    };

    if (this.sortConfig?.sortDirection) {
      (params.sortProperty = this.sortConfig.sortProperty),
        (params.sortDirection = this.sortConfig.sortDirection);
    }

    if (this.filterConfig?.type) {
      params.templateTypeId = this.filterConfig?.type;
    }

    if (this.filterConfig?.target) {
      params.documentTargetId = this.filterConfig?.target;
    }

    this._msTemplatesManagementApiService
      .getMsTemplates(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.data = this._formatTableData(data.content);
        this.pagination = {
          ...this.pagination,
          total: data.totalRecords,
        };
      });
  }

  private _deleteMsTemplate(id: string): void {
    this._dialog
      .open(ConfirmPopupComponent, {
        data: {
          subHeading: this._translateService.instant(
            'rcs-setup.ms-template-management.delete_confirm'
          ),
          successBtnText: this._translateService.instant(
            'confirm_popup.yes_delete'
          ),
        },
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        mergeMap(() => this._msTemplatesManagementApiService.deleteMsTemplate(id)),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this._loadMsTemplates();
      });
  }

  private _downloadTemplate(msTemplate: TableMsTemplateChildren) {
    this._msTemplatesManagementApiService
      .downloadMsTemplate(msTemplate.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((blobStream: Blob) => {
        Utils.downloadFile(blobStream, msTemplate.title);
      });
  }

  private _formatTableData(msTemplateCategories: MsTemplateCategory[]): TableMsTemplateCategory[] {
    return msTemplateCategories.map(msTemplateCategory => ({
      id: msTemplateCategory.documentTypeId,
      title: msTemplateCategory.documentType,
      isOpened: false,
      selected: msTemplateCategory.children.some(el => el.isVisible),
      children: msTemplateCategory.children.map(el => ({
            ...el,
            parentId: msTemplateCategory.documentTypeId,
            title: el.title,
            selected: el.isVisible,
            action: [
              {
                label: TABLE_ACTION_LABEL.EDIT,
                icon: ACTION_BUTTON.EDIT_WO_BACKGROUND,
                key: TABLE_ACTIONS_KEYS.EDIT,
              },
              {
                label: TABLE_ACTION_LABEL.DELETE,
                icon: ACTION_BUTTON.DELETE_WO_BACKGROUND,
                key: TABLE_ACTIONS_KEYS.DELETE,
              },
              {
                label: TABLE_ACTION_LABEL.DOWNLOAD,
                icon: ACTION_BUTTON.DOWNLOAD,
                key: TABLE_ACTIONS_KEYS.DOWNLOAD,
              },
            ]
          })),
    }));
  }

  private _getFilters(types: DropdownItem[], targets: DocumentTargetDropdownItem[]): Filter[] {
    const filters: Filter[] = [
      {
        name: 'type',
        type: FilterType.SELECT,
        label: this._translateService.instant('rcs-setup.ms-template-management.types_filter_label'),
        placeholder: this._translateService.instant('rcs-setup.ms-template-management.types_filter_placeholder'),
        options: types.map(type => ({
          label: type.desc,
          value: type.id,
        })),
      },
      {
        name: 'target',
        type: FilterType.SELECT,
        label: this._translateService.instant('rcs-setup.ms-template-management.targets_filter_label'),
        placeholder: this._translateService.instant('rcs-setup.ms-template-management.targets_filter_placeholder'),
        options: targets.map(target => ({
          label: target.desc,
          value: target.id,
        })),
      },
    ];

    return filters;
  }

  private _getTableConfig(): TableConfig {
    const config: TableConfig = {
      displayColumns: [...MS_TEMPLATES_COLUMNS],
      columnDetails: [
        {
          title: this._translateService.instant(
            MS_TEMPLATES_COLUMN_HEADINGS.NAME
          ),
          name: MsTemplatesColumns.NAME,
          type: ColumnType.STRING,
        },
        {
          title: this._translateService.instant(
            MS_TEMPLATES_COLUMN_HEADINGS.CREATED_ON
          ),
          name: MsTemplatesColumns.CREATED_ON,
          type: ColumnType.COMPONENT,
          component: CreatedByComponent,
          maxWidth: 120,
        },
        {
          title: this._translateService.instant(
            MS_TEMPLATES_COLUMN_HEADINGS.LAST_UPDATED
          ),
          name: MsTemplatesColumns.LAST_UPDATED,
          type: ColumnType.COMPONENT,
          component: UpdatedByComponent,
          maxWidth: 120,
        },
        {
          title: this._translateService.instant(
            MS_TEMPLATES_COLUMN_HEADINGS.ACTION
          ),
          name: MsTemplatesColumns.ACTION,
          type: ColumnType.ACTION,
        },
      ],
      styles: {
        maxHeight: 'calc(100vh - 560px)'
      },
    };

    return config;
  }
}
