<div>
  <span class="criteria-heading"> {{'objectModule.working_wood.woodworking' | translate}}</span>
</div>

<form class="row object-module-html form-align" [formGroup]="WoodWorkingForm" id="WoodWorkingForm" *ngIf="WoodWorkingForm">
  <div class="col-md-3 col-sm-6 col-6" >
    <label for="" class="long-label"> {{'objectModule.working_wood.app_cleaning' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_appropriate_cleaning">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >
    <label for="" class="long-label"> {{'objectModule.working_wood.stat_cleaning' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_stationary_cleaning_/_extraction_system">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >
    <label for="" class="long-label"> {{'objectModule.working_wood.coating' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_coatings">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >
    <label for="" class="long-label"> {{'objectModule.working_wood.solvent_adhesives' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_solvent-based_adhesives">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >
    <label for="" class="long-label"> {{'objectModule.working_wood.biomass' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_iomass_chip_heating_with_burn-back_protection">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >
    <label for="" class="long-label"> {{'objectModule.working_wood.metal_detector' | translate}}</label>
    <div  class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_metal_detector_available_for_log_processing?">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>



  <div [ngClass]="WoodWorkingForm.value.is_chip_silo_bunker ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_chip_silo_bunker">{{'objectModule.working_wood.ciip_silo' | translate}}</mat-slide-toggle>
    </div>

    <div *ngIf="WoodWorkingForm.value.is_chip_silo_bunker"  class="row toggle-section-content" >
      <div class="col-md-3 col-sm-6 col-6" >
        <label for="" class="long-label"> {{'objectModule.working_wood.spart_detection' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_spark_detection_and_extinguishing">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6" >
        <label for="" class="long-label"> {{'objectModule.working_wood.structural_seperation' | translate}}</label>
        <div  class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_structural_spatial_separation">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6" >
        <label for="" class="long-label"> {{'objectModule.working_wood.inerting' | translate}}</label>
        <div  class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_inerting_option">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6" >
        <label for="" class="long-label"> {{'objectModule.working_wood.dey_line' | translate}}</label>
        <div  class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_dry_line">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6" >
        <label for="" class="long-label"> {{'objectModule.working_wood.pressure_relif' | translate}}</label>
        <div  class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_printingload">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="WoodWorkingForm.value.is_workshop_grinding_shop ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_workshop_grinding_shop">{{'objectModule.working_wood.workshop' | translate}}</mat-slide-toggle>
    </div>

    <div *ngIf="WoodWorkingForm.value.is_workshop_grinding_shop"  class="row toggle-section-content" >
      <div class="col-md-3 col-sm-6 col-6" >
        <label for="" class="long-label"> {{'objectModule.working_wood.structural_seperation' | translate}}</label>
        <div  class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_structural_spatial_separation1">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="WoodWorkingForm.value.is_drying ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_drying">{{'objectModule.working_wood.drying' | translate}}</mat-slide-toggle>
    </div>

  <div *ngIf="WoodWorkingForm.value.is_drying"  class="row toggle-section-content" >
    <div class="col-md-3 col-sm-6 col-6" >
      <label for="" class="long-label"> {{'objectModule.working_wood.structural_seperation' | translate}}</label>
      <div  class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="is_structural_spatial_separation2">
          <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>


</form>
