<div class="modal modelBackground" style="display: table;" 
    [style.display]="inputData.isShowPopup ? 'table' : 'none'">
    <div class="modal-dialog spacing">
        <div class="modal-content">
                <div class="d-flex header-content p-unset">
                    <div class="pointer">
                        <img alt="" (click)="closeConfirmPopup()" [src]="inputData.logoLink || crossImage" >
                    </div>
                </div>
                <div class="modal-body body-text">
                    <span class="delete-heading">{{inputData.heading}}</span>
                    <br /><br />
                    <span class="delete-text">{{inputData.subHeading}}</span>
                </div>
                <div class="modal-footer ml-auto mr-auto">
                    <button (click)="closeConfirmPopup()" 
                        class="custom-btn cancel-btn" *ngIf="inputData.isCancelBtn">
                        {{inputData.cancelBtnText}}
                    </button>
                    <button class="custom-btn ml-2 save-btn" 
                        (click)="saveConfirm()" *ngIf="inputData.isSuccessBtn">
                        {{inputData.successBtnText}}
                    </button>
                </div>
        </div>
    </div>
</div>
