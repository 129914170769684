import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { UploadTypeConstants } from '@shared/constants/product-management.constants';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import * as moment from 'moment';
import { ToastService } from '@core/services/toast.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import {
  CUSTOMER_MANAGEMENT_ROUTES,
  ROUTES_PATH,
} from '@shared/constants/router.constants';
import { TranslateService } from '@ngx-translate/core';
import { AZURE } from '@shared/constants/azure-service.constants';
import { Country } from '@shared/interfaces/country.interface';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { LOCALS } from '@shared/constants/local.constants';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { REGEX } from '@shared/constants/general/regex.constants';
import { CustomerManagementSharedService } from '../customer-management-shared-service';

export interface SendDetails {
  custmer_agents: any;
  customer_info: CustomerInfo;
  customer_status: CustomerStatus;
  is_ivm_sync: boolean;
  main_contact: MainContact;
  customer_id: number;
  is_source_ivm: boolean;
}
export interface CustomerInfo {
  address: string;
  city: string;
  customer_address_lat_lon_set: boolean;
  customer_id: number;
  customer_name: string;
  country: string;
  customer_number: string;
  house: string;
  lat: any;
  liztu_vn_kdnr: number;
  lon: any;
  name2: string;
  name3: string;
  name4: string;
  salutation_id: any;
  street: string;
  zip_code: string;
}
export interface CustomerStatus {
  activation_flag: string;
  broker_mandate_date: string;
  brokerage_mandate_id: number;
  classification_short_name: string;
  customer_additional_id: number;
  customer_origin: string;
  customer_id: number;
  sales_status_id: any;
  status_id: number;
  status_reason_id: any;
  type: string;
  customer_group: string;
  document_ids: any;
  division_id: any;
  ivm_mandant_id: any;
  rcs_user_id: any;
}
export interface MainContact {
  agents: string;
  email: string;
  remark: string;
  telephone: string;
}
@Component({
  selector: 'app-general-customer-data',
  templateUrl: './general-customer-data.component.html',
  styleUrls: ['./general-customer-data.component.scss'],
})
export class GeneralCustomerDataComponent
  extends BaseComponent
  implements OnInit
{
  editCustomer: boolean;
  generalDataForm: FormGroup;
  showActive = false;
  showList = false;
  Alllocations: any;
  filterlocation = [];
  selectedaddress: any;
  selectedLoc = '';
  lat: any;
  lon: any;
  keyUp = new Subject<string>();
  customerRoles = [
    'Mutter',
    'Tochter',
    'Overseas',
    'Beteiligung',
    'Private',
    'Private VIP',
  ];
  activationFlag = [
    { name: 'Active Customer', symbol: 'A' },
    { name: 'Inactive Customer', symbol: 'S' },
    { name: 'Inactive Customer', symbol: 'I' },
    { name: 'Inactive Customer', symbol: 'SI' },
  ];
  activationFlagGerman = [
    { name: 'Aktiver Kunde', symbol: 'A' },
    { name: 'Inaktiver Kunde', symbol: 'S' },
    { name: 'Inaktiver Kunde', symbol: 'I' },
    { name: 'Inaktiver Kunde', symbol: 'SI' },
  ];
  classification = [];
  statusList = [];
  status_reason = [];
  sales_status = [];
  brokerageMandate = [];
  allAgents = [];
  agentObj = { agent_desc: '', id: null, rcs_division_user_id: null };
  liztu: any;
  agents = [];
  customer_id: any;
  customer_additional_id: any;
  salutations: any;
  readonly locale$ = this._userService.locale$;
  classificatinControl = new FormControl();
  salutation_id: any;
  classificationTemp: any[];
  today = new Date();
  status_reason_lookup = [];
  sales_status_lookup = [];
  status_reason_id: any;
  sales_status_id: any;
  customer_address_lat_lon_set: boolean;
  selectedDocumentsArray = {};
  selectedDocumentsArrayBrokerage = {};
  showDocumentPopup = false;
  uploadDocumentDetails: UploadObjectDetails;
  customerWarningMsg: CustomerWarningMsg;
  showWarningPopUp = false;
  reload = false;
  documentids: any[];
  agentLength = 0;
  newAgentlength = 0;
  deleteIndex: any;
  delete_item_id: any;
  showModal = false;
  customer_created_from_ui: any;
  user_role: USER_ROLES;
  divisions: any;
  source_ivm: any;
  IC_USERs: any[] = [];
  submitted = false;
  FirmaSelected = false;
  validFirstTextError = false;
  validLastTextError = false;
  readonly countries$: Observable<Country[]> = this.serviceCall.getCountries();

  constructor(
    private serviceCall: ApiCallsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    public validationService: ValidationService,
    private translate: TranslateService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService
  ) {
    super();
    this.customerWarningMsg = {
      cancel: 'product-management.stepper_common_msg.discard',
      ok: 'product-management.stepper_common_msg.cancel_label',
      save_and_continue:
        'product-management.stepper_common_msg.save_and_continue',
      header: 'save-changes.Nicht_gespeicherte',
      body: 'save-changes.object_unsaved_message',
    };
    this.classificatinControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => this.filterClassification(value));
  }

  getActivationFlagByLocale(
    locale: LOCALS
  ): Record<'name' | 'symbol', string>[] {
    return locale === LOCALS.GERMAN
      ? this.activationFlagGerman
      : this.activationFlag;
  }

  filterClassification(value: any): void {
    this.classification = this.classificationTemp;
    if (value !== '') {
      if (
        this.classification.filter(option =>
          option.name.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.classification = this.classification.filter(option =>
          option.name.toLowerCase().includes(value.toLowerCase())
        );
      } else if (
        this.classification.filter(option =>
          option.nameEn.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.classification = this.classification.filter(option =>
          option.nameEn.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.classification = this.classificationTemp;
      }
    } else {
      this.classification = this.classificationTemp;
    }
  }

  focusMainContactSearch(): void {
    setTimeout(() => document.getElementById('search')?.focus(), 100);
  }

  warningPopupGuard(): void {
    this.showWarningPopUp = true;
  }

  formEditCall(): void {
    this.generalDataForm.controls.salutation.enable();
    this.generalDataForm.controls.name1.enable();
    this.generalDataForm.controls.name2.enable();
    this.generalDataForm.controls.address.enable();
    this.generalDataForm.controls.houseNumber.enable();
    this.generalDataForm.controls.postalCode.enable();
    this.generalDataForm.controls.city.enable();
    this.generalDataForm.controls.country.enable();
    this.generalDataForm.controls.street.enable();
    this.generalDataForm.controls.roleOfCustomer.enable();
    this.generalDataForm.controls.status.enable();

    if (this.generalDataForm.controls.status.value) {
      this.generalDataForm.controls.statusReason.enable();
    }
    this.generalDataForm.controls.salesStatus.enable();
    this.generalDataForm.controls.classification.enable();
    this.generalDataForm.controls.customerGroup.disable();
    this.generalDataForm.controls.customerOrigin.disable();
    this.generalDataForm.controls.brokerageMandate.enable();
    this.generalDataForm.controls.brokerageMandateDate.enable();
    this.generalDataForm.controls.brokerageMandateDocument.enable();
    this.generalDataForm.controls.tel.enable();
    this.generalDataForm.controls.email.enable();
    this.generalDataForm.controls.remark.enable();
    this.generalDataForm.controls.division.enable();
    this.generalDataForm.controls.IC_USER.enable();
  }

  ngOnInit(): void {
    const liztu = this.route.snapshot.queryParams.liztu;
    this.liztu = liztu ? atob(liztu) : null;
    this._watchOnUserRole();
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
    this._watchOnEditCustomerValue();
  }

  getFormData(): void {
    if (this.liztu) {
      this._customerSharedService.customerGeneralData$
        .pipe(filterNil(), takeUntil(this._destroy$))
        .subscribe(data => {
          this.formatFormData(data);
        });
    }
  }

  formatFormData(data): void {
    this.customer_created_from_ui = data.is_source_ivm;
    this.source_ivm = data.is_source_ivm;
    this._customerManagementSharedService.isSourceIvm$.next(this.source_ivm);
    this.liztu = data?.customer_info?.liztu_vn_kdnr;
    this.allAgents = data?.customer_agents ? data?.customer_agents : [];
    this.agentLength = this.allAgents?.length;
    this.newAgentlength = this.allAgents?.length;
    this.customer_id = data?.customer_info?.customer_id;
    this.customer_additional_id = data?.customer_status?.customer_additional_id;
    this.salutation_id = data?.customer_info?.salutation_id;
    this.salutationSelected(this.salutation_id);
    this.lat = data?.customer_info?.lat;
    this.lon = data?.customer_info?.lon;
    if (this.lat && this.lon) {
      this.showActive = true;
    }
    this.customer_address_lat_lon_set =
      data?.customer_info?.customer_address_lat_lon_set;
    this.generalDataForm.controls.salutation.setValue(
      data?.customer_info?.salutation_id
    );
    this.generalDataForm.controls.name1.setValue(
      data?.customer_info?.customer_name
    );
    this.generalDataForm.controls.name2.setValue(data?.customer_info?.name2);
    this.generalDataForm.controls.name3.setValue(data?.customer_info?.name3);
    this.generalDataForm.controls.name4.setValue(data?.customer_info?.name4);
    this.generalDataForm.controls.address.setValue(
      data?.customer_info?.address
    );
    this.generalDataForm.controls.houseNumber.setValue(
      data?.customer_info?.house
    );
    this.generalDataForm.controls.postalCode.setValue(
      data?.customer_info?.zip_code
    );
    this.generalDataForm.controls.city.setValue(data?.customer_info?.city);
    this.generalDataForm.controls.country.setValue(
      data?.customer_info?.country
    );
    this.generalDataForm.controls.street.setValue(data?.customer_info?.street);
    this.generalDataForm.controls.customerNumber.setValue(
      data?.customer_info?.customer_number
    );

    this.generalDataForm.controls.roleOfCustomer.setValue(
      data?.customer_status?.type
    );
    this.generalDataForm.controls.activationFlag.setValue(
      data?.customer_status?.activation_flag
        ? data?.customer_status?.activation_flag
        : 'S'
    );
    this.generalDataForm.controls.status.setValue(
      data?.customer_status?.status_id
    );
    if (!this.editCustomer && data?.customer_status?.status_id) {
      this.generalDataForm.controls.statusReason.enable();
    }
    this.status_reason_id = data?.customer_status?.status_reason_id;
    if (data?.customer_status?.status_id) {
      this.getStatusReason(data?.customer_status?.status_id);
      this.status_reason.map(element => {
        this.status_reason_lookup.push(element.name);
      });
    }
    this.sales_status_id = data?.customer_status?.sales_status_id;
    if (this.sales_status_id) {
      this.generalDataForm.controls.salesStatus.setValue(
        this.sales_status.filter(
          element => element.id === this.sales_status_id
        )[0]?.name
      );
    }
    this.generalDataForm.controls.classification.setValue(
      data?.customer_status?.classification_short_name
    );
    this.generalDataForm.controls.customerGroup.setValue(
      data?.customer_status?.customer_group
    );
    this.generalDataForm.controls.customerOrigin.setValue(
      data?.customer_status?.customer_origin
    );
    this.generalDataForm.controls.brokerageMandate.setValue(
      data?.customer_status?.brokerage_mandate_id
    );
    this.generalDataForm.controls.brokerageMandateDate.setValue(
      data?.customer_status?.broker_mandate_date
    );
    this.generalDataForm.controls.division.setValue(
      data?.customer_status?.division_id
    );
    if (data.customer_status.division_id) {
      this.divisionChanged();
    }
    this.generalDataForm.controls.IC_USER.setValue(
      data?.customer_status?.rcs_user_id
    );
    this.getAgents(data?.customer_status?.division_id);
    this.getDocumentUploadData(this.liztu);

    this.generalDataForm.controls.tel.setValue(data?.main_contact?.telephone);
    this.generalDataForm.controls.email.setValue(data?.main_contact?.email);
    this.generalDataForm.controls.remark.setValue(data?.main_contact?.remark);
    this._checkIsEditCustomer();
  }

  getDocumentUploadData(recordId: number): void {
    if (recordId) {
      this.serviceCall
        .getDocumentPhotovoltik(recordId, '')
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents?.forEach(document => {
              document.is_obj_linked = true;
              this.selectedDocumentsArrayBrokerage[document.document_name] =
                document;
            });
            if (data.documents) {
              this.generalDataForm.controls.brokerageMandateDocument.setValue(
                Object.keys(this.selectedDocumentsArrayBrokerage).join(', ')
              );
            }
          }
        });
    }
  }

  getDropdownData(): void {
    this.serviceCall
      .getLegalForm('customer_general_data')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.statusList = data.data;
      });
    this.serviceCall
      .getLegalForm('brokerage_mandate')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.brokerageMandate = data.data;
      });
    this.serviceCall
      .getSalutation()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.salutations = data.data;
      });
    this.getSalesStatus();
    this.getDivision();
    this.getClassification();
  }

  getContactPersons(): void {
    const divisionId = this.generalDataForm.controls.division.value;

    this._userService.loggedInUser$
      .pipe(
        filterNil(),
        mergeMap(userData =>
          this.serviceCall.getEditor(
            divisionId,
            this._customerSharedService.getSelectedCustomerId() ||
              userData.user_profile.customer_details.customer_id,
            'IC',
            userData.user_profile.user_data.role
          )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(data => (this.IC_USERs = data.berbeiters));
  }

  divisionChanged(): void {
    this.generalDataForm.controls.IC_USER.reset();
    this.getContactPersons();
  }

  getDivision(): void {
    this._userService.userId$
      .pipe(
        filterNil(),
        mergeMap(id => this.serviceCall.getDivision(id)),
        takeUntil(this._destroy$)
      )
      .subscribe(data => {
        this.divisions = data.divisions;
        this.divisions.forEach((element, index) => {
          if (element.division_id === 12) {
            this.divisions.splice(index, 1);
          }
        });
      });
  }

  getClassification(): void {
    this.serviceCall
      .getClassification()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.classification = data.customer_classification_data;
        this.classificationTemp = this.classification;
      });
  }

  getAgents(divisionId: number): void {
    this.serviceCall
      .getAgentList(divisionId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.agents = data.agents));
  }

  getSalesStatus(): void {
    this.serviceCall
      .getSalesStatus()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.sales_status = data.data;
        this.sales_status_lookup = [];
        data.data.map(element => this.sales_status_lookup.push(element.name));
      });
  }

  getStatusReason(statusId: number): void {
    if (statusId) {
      this.serviceCall
        .getStatusReason(statusId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => this.formateStatusReason(data.data));
    }
  }

  formateStatusReason(data: any): void {
    this.status_reason = data;
    this.status_reason_lookup = [];
    data.map(element => this.status_reason_lookup.push(element.name));
    if (this.status_reason_id) {
      this.generalDataForm.controls.statusReason.setValue(
        this.status_reason.filter(
          element => element.id === this.status_reason_id
        )[0]?.name
      );
    }
  }

  initForm(): void {
    this.generalDataForm = new FormGroup({
      salutation: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      firstName: new FormControl({ value: '', disabled: true }),
      lastName: new FormControl({ value: '', disabled: true }),
      name1: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      name2: new FormControl({ value: '', disabled: true }),
      name3: new FormControl({ value: '', disabled: true }),
      name4: new FormControl({ value: '', disabled: true }),
      address: new FormControl({ value: '', disabled: true }),
      street: new FormControl({ value: '', disabled: true }),
      houseNumber: new FormControl({ value: '', disabled: true }),
      postalCode: new FormControl({ value: '', disabled: true }),
      city: new FormControl({ value: '', disabled: true }),
      country: new FormControl({ value: '', disabled: true }),
      customerNumber: new FormControl({ value: '', disabled: true }),
      roleOfCustomer: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      activationFlag: new FormControl(
        { value: 'A', disabled: true },
        Validators.required
      ),
      status: new FormControl({ value: '', disabled: true }),
      statusReason: new FormControl({ value: '', disabled: true }),
      salesStatus: new FormControl({ value: '', disabled: true }),
      classification: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      customerGroup: new FormControl({ value: '', disabled: true }),
      customerOrigin: new FormControl({ value: '', disabled: true }),
      customerSatisfactionSurvey: new FormControl({
        value: '',
        disabled: true,
      }),
      brokerageMandate: new FormControl({ value: '', disabled: true }),
      brokerageMandateDate: new FormControl({ value: '', disabled: true }),
      brokerageMandateDocument: new FormControl({ value: '', disabled: true }),
      tel: new FormControl({ value: '', disabled: true }),
      email: new FormControl(
        { value: '', disabled: true },
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ),
      remark: new FormControl({ value: '', disabled: true }),
      division: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      IC_USER: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
    });

    this._checkIsEditCustomer();
  }

  formReverseEdit(): void {
    this.generalDataForm.controls.salutation.disable();
    this.generalDataForm.controls.name1.disable();
    this.generalDataForm.controls.name2.disable();
    this.generalDataForm.controls.name3.disable();
    this.generalDataForm.controls.name4.disable();
    this.generalDataForm.controls.address.disable();
    this.generalDataForm.controls.houseNumber.disable();
    this.generalDataForm.controls.postalCode.disable();
    this.generalDataForm.controls.city.disable();
    this.generalDataForm.controls.country.disable();
    this.generalDataForm.controls.street.disable();
    this.generalDataForm.controls.customerNumber.disable();
    this.generalDataForm.controls.roleOfCustomer.disable();
    this.generalDataForm.controls.activationFlag.disable();
    this.generalDataForm.controls.status.disable();
    this.generalDataForm.controls.statusReason.disable();
    this.generalDataForm.controls.salesStatus.disable();
    this.generalDataForm.controls.classification.disable();
    this.generalDataForm.controls.customerGroup.disable();
    this.generalDataForm.controls.customerOrigin.disable();
    this.generalDataForm.controls.customerSatisfactionSurvey.disable();
    this.generalDataForm.controls.brokerageMandate.disable();
    this.generalDataForm.controls.brokerageMandateDate.disable();
    this.generalDataForm.controls.brokerageMandateDocument.disable();
    this.generalDataForm.controls.tel.disable();
    this.generalDataForm.controls.email.disable();
    this.generalDataForm.controls.remark.disable();
    this.generalDataForm.controls.division.disable();
    this.generalDataForm.controls.IC_USER.disable();
  }

  onFocusOut(): void {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  autoComplete(value): void {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          this.Alllocations = res.results;
          this.filterAddressFromResults(this.Alllocations);
          this.showList = true;
        });
    }
  }

  filterAddressFromResults(Alllocations: any): void {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
  }

  selectedLocation(e): void {
    this.showList = false;
    this.selectedaddress = [this.filterlocation[e]];
    this.selectedLoc = this.filterlocation[e].freeformAddress;
    this.generalDataForm.controls.address.setValue(
      this.filterlocation[e].freeformAddress
    );
    if (this.selectedaddress.length === 1) {
      if (this.selectedaddress[0].streetNumber) {
        this.generalDataForm.controls.houseNumber.setValue(
          this.selectedaddress[0].streetNumber
        );
      }
      if (
        this.selectedaddress[0].streetName ||
        this.selectedaddress[0].municipalitySubdivision
      ) {
        if (this.selectedaddress[0].streetName) {
          this.generalDataForm.controls.street.setValue(
            this.selectedaddress[0].streetName
          );
        } else {
          this.generalDataForm.controls.street.setValue(
            this.selectedaddress[0].municipalitySubdivision
          );
        }
      }
      if (this.selectedaddress[0].postalCode) {
        this.generalDataForm.controls.postalCode.setValue(
          this.selectedaddress[0].postalCode
        );
      }
      if (
        this.selectedaddress[0].localName ||
        this.selectedaddress[0].municipality
      ) {
        if (this.selectedaddress[0].localName) {
          this.generalDataForm.controls.city.setValue(
            this.selectedaddress[0].localName
          );
        } else {
          this.generalDataForm.controls.city.setValue(
            this.selectedaddress[0].municipality
          );
        }
      }
      if (this.selectedaddress[0].country) {
        this.generalDataForm.controls.country.setValue(
          this.selectedaddress[0].country
        );
      }

      for (const element of this.Alllocations) {
        if (element.address.freeformAddress === this.selectedLoc) {
          this.lat = element.position.lat ?? '';
          this.lon = element.position.lon ?? '';
        }
      }
      this.showActive = this.lat !== '' && this.lon !== '';
    }
  }

  salutationSelected(event): void {
    if (event !== 3) {
      this.FirmaSelected = false;
      this.generalDataForm.controls.name2.setValidators([
        Validators.required,
        Validators.pattern(REGEX.NAME_VALIDATION),
      ]);
      this.generalDataForm.controls.name1.setValidators([
        Validators.required,
        Validators.pattern(REGEX.NAME_VALIDATION),
      ]);
      this._customerManagementSharedService.isSalutationNotCustomerChosen$.next(
        true
      );
      this.generalDataForm.controls.name3.reset();
      this.generalDataForm.controls.name4.reset();
      this.generalDataForm.controls.name1.enable();
      this.generalDataForm.controls.name2.enable();
      this.generalDataForm.controls.name3.disable();
      this.generalDataForm.controls.name4.disable();
    } else {
      this.FirmaSelected = true;
      this.validFirstTextError = false;
      this.validLastTextError = false;
      this.generalDataForm.controls.name2.clearValidators();
      this.generalDataForm.controls.name1.clearValidators();
      this.generalDataForm.controls.name1.setValidators(Validators.required);
      this._customerManagementSharedService.isSalutationNotCustomerChosen$.next(
        false
      );
      this.generalDataForm.controls.name1.enable();
      this.generalDataForm.controls.name2.enable();
      this.generalDataForm.controls.name3.enable();
      this.generalDataForm.controls.name4.enable();
    }
  }

  addNewRow(): void {
    if (this.editCustomer) {
      this.agentObj = { agent_desc: '', id: null, rcs_division_user_id: null };
      this.allAgents.push(this.agentObj);
    }
    this.newAgentlength = this.allAgents.length;
  }

  deleteEnrty(index, id): void {
    this.hideModal();
    if (this.editCustomer) {
      if (id) {
        this.serviceCall
          .deleteAgent(id)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            if (!data.HasErrors) {
              this.toastService.showToastMessage(document, data.message);
              this.allAgents.splice(index, 1);
              this.newAgentlength = this.allAgents.length;
            }
          });
      } else {
        this.allAgents.splice(index, 1);
        this.newAgentlength = this.allAgents.length;
      }
    }
  }

  saveCustomer(): void {
    this.submitted = true;
    const invalid = [];
    const controls = this.generalDataForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    this.validFirstTextError =
      this.generalDataForm.controls.name1.value && invalid.includes('name1');
    this.validLastTextError =
      this.generalDataForm.controls.name2.value && invalid.includes('name2');
    this.generalDataForm.controls.statusReason.clearValidators();
    this.generalDataForm.controls.salesStatus.clearValidators();
    this.generalDataForm.controls.statusReason.setErrors(null);
    this.generalDataForm.controls.salesStatus.setErrors(null);
    if (!invalid.length) {
      this.generalDataForm.setErrors(null);
    }
    this.agentLength = this.allAgents?.length;
    this.newAgentlength = this.allAgents?.length;
    const customerInfo: CustomerInfo = {
      address: this.generalDataForm.controls.address.value,
      city: this.generalDataForm.controls.city.value,
      customer_address_lat_lon_set: this.lat && this.lon ? true : false,
      customer_id: this.customer_id,
      customer_name: this.generalDataForm.controls.name1.value,
      country: this.generalDataForm.controls.country.value,
      customer_number: this.generalDataForm.controls.customerNumber.value,
      house: this.generalDataForm.controls.houseNumber.value,
      lat: this.lat,
      liztu_vn_kdnr: this.liztu || null,
      lon: this.lon,
      name2: this.generalDataForm.controls.name2.value,
      name3: this.generalDataForm.controls.name3.value,
      name4: this.generalDataForm.controls.name4.value,
      salutation_id: this.generalDataForm.controls.salutation.value,
      street: this.generalDataForm.controls.street.value,
      zip_code: this.generalDataForm.controls.postalCode.value,
    };
    const customerStatus: CustomerStatus = {
      activation_flag: 'A',
      broker_mandate_date: this.generalDataForm.controls.brokerageMandateDate
        .value
        ? moment(
            this.generalDataForm.controls.brokerageMandateDate.value
          ).format('YYYY-MM-DD')
        : null,
      brokerage_mandate_id:
        this.generalDataForm.controls.brokerageMandate.value,
      classification_short_name:
        this.generalDataForm.controls.classification.value,
      customer_additional_id: this.customer_additional_id
        ? this.customer_additional_id
        : null,
      customer_origin: this.generalDataForm.controls.customerOrigin.value,
      customer_group: this.generalDataForm.controls.customerGroup.value,
      customer_id: this.customer_id, // vishal asked to send this
      sales_status_id: this.generalDataForm.controls.salesStatus.value,
      status_id: this.generalDataForm.controls.status.value,
      status_reason_id: this.generalDataForm.controls.statusReason.value
        ? this.status_reason.filter(
            element =>
              element.name === this.generalDataForm.controls.statusReason.value
          )[0].id
        : null,
      type: this.generalDataForm.controls.roleOfCustomer.value,
      document_ids: this.documentids,
      division_id: this.generalDataForm.controls.division.value,
      rcs_user_id: this.generalDataForm.controls.IC_USER.value,
      ivm_mandant_id: this.divisions.find(
        item =>
          item.division_id === this.generalDataForm.controls.division.value
      )?.ivm_mandant_id,
    };
    const mainContact: MainContact = {
      agents: '',
      email: this.generalDataForm.controls.email.value,
      remark: this.generalDataForm.controls.remark.value,
      telephone: this.generalDataForm.controls.tel.value,
    };
    const sendDetails: SendDetails = {
      custmer_agents: this.allAgents,
      customer_info: customerInfo,
      customer_status: customerStatus,
      is_ivm_sync: this.editCustomer,
      main_contact: mainContact,
      customer_id: this.customer_id,
      is_source_ivm: this.liztu ? this.customer_created_from_ui : true,
    };
    if (!invalid.length) {
      this.serviceCall
        .SaveCustomerGeneralData(sendDetails)
        .pipe(
          tap(data => {
            this.toastService.showToastMessage(document, data.message);
          }),
          filter(data => !data.hasErrors),
          mergeMap(data => {
            this.validFirstTextError = false;
            this.validLastTextError = false;
            this.liztu = data.liztu_vn_kdnr;
            return this.serviceCall.getCustomerGeneralData(this.liztu);
          }),
          filter(data => !data.HasErrors),
          takeUntil(this._destroy$)
        )
        .subscribe(data => {
          this.formatFormData(data);
          this._customerSharedService.selectCustomerDivisionId(
            data.customer_status.division_id
          );
          this._customerSharedService.saveCustomerName(null);
          const prev = this.router.routeReuseStrategy.shouldReuseRoute;
          const prevOSN = this.router.onSameUrlNavigation;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router
            .navigate(
              [
                `${ROUTES_PATH.CUSTOMER_MANAGEMENT}/${CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA}`,
              ],
              {
                queryParams: {
                  liztu: btoa(this.liztu),
                },
              }
            )
            .then(() => {
              this.router.routeReuseStrategy.shouldReuseRoute = prev;
              this.router.onSameUrlNavigation = prevOSN;
            });
        });
    } else {
      this.generalDataForm.controls.statusReason.setErrors(null);
      this.generalDataForm.controls.salesStatus.setErrors(null);
      this._customerManagementSharedService.IVMSyncToggle$.next(true);
      this.reload = true;
      this.toastService.showToastMessage(
        document,
        this.translate.instant(
          this.validFirstTextError || this.validLastTextError
            ? 'customer-management.general-data.validation_text_error'
            : 'customer-management.general-data.mandatory_text'
        )
      );
    }
  }

  agentSelected(event, index): void {
    this.allAgents[index].rcs_division_user_id = event.value;
  }

  descAdded(event, index): void {
    this.allAgents[index].agent_desc = event.target.value;
  }

  targetValue(event): void {
    this.status_reason_id = null;
    const data = {
      data: event,
      status_id: this.generalDataForm.controls.status.value,
    };
    if (!this.status_reason_lookup.includes(event)) {
      this.serviceCall
        .saveStatusReason(data)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          if (!res.hasErrors) {
            this.getStatusReason(this.generalDataForm.controls.status.value);
          }
        });
    }
  }

  targetValueSales(data): void {
    const params = { data };
    if (!this.sales_status_lookup.includes(data)) {
      this.serviceCall
        .saveSalesReason(params)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          if (!res.hasErrors) {
            this.getSalesStatus();
          }
        });
    }
  }

  statusSelect(event): void {
    this.status_reason_lookup = [];
    this.generalDataForm.controls.statusReason.reset();
    if (event.value) {
      this.getStatusReason(event.value);
      this.generalDataForm.controls.statusReason.enable();
    } else {
      this.generalDataForm.controls.statusReason.disable();
    }
  }

  showPopup(): void {
    this.selectedDocumentsArray = { ...this.selectedDocumentsArrayBrokerage };
    this.uploadDocumentDetails = {
      documentSource: 'general_data',
      documentType: UploadTypeConstants.Mandate,
    };
    this.showDocumentPopup = true;
  }

  hidePopup(selectedDocumentsArray): void {
    this.documentids = [];
    this.selectedDocumentsArrayBrokerage = { ...this.selectedDocumentsArray };
    this.showDocumentPopup = false;
    this.generalDataForm.controls.brokerageMandateDocument.setValue(
      Object.keys(selectedDocumentsArray).join(', ')
    );
    this.generalDataForm.controls.brokerageMandateDocument.markAsTouched();
    Object.keys(selectedDocumentsArray).forEach(name => {
      if (selectedDocumentsArray[name].upload_document_id) {
        this.documentids.push(selectedDocumentsArray[name].upload_document_id);
      }
    });
    this.selectedDocumentsArray = {};
  }

  handleWarningPopup(event): void {
    this.showWarningPopUp = false;
    if (!event) {
      this._customerManagementSharedService.IVMSyncToggle$.next(true);
      this.reload = true;
    } else {
      this.reload = false;
      this.initForm();
      this.getDropdownData();
      this.getFormData();
    }
  }

  saveGeneralData(event): void {
    this.generalDataForm.controls.statusReason.setErrors(null);
    this.generalDataForm.controls.salesStatus.setErrors(null);
    if (event) {
      this.showWarningPopUp = false;
      this.saveCustomer();
    }
  }

  openModal(ind, id): void {
    if (this.editCustomer) {
      this.deleteIndex = ind;
      this.delete_item_id = id;
      this.showModal = true;
    }
  }

  hideModal(): void {
    this.delete_item_id = null;
    this.deleteIndex = null;
    this.showModal = false;
  }

  private _checkIsEditCustomer(): void {
    this.editCustomer ? this.formEditCall() : this.formReverseEdit();
    this.generalDataForm.markAsPristine();
  }

  private _watchOnUserRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => (this.user_role = role));
  }

  private _watchOnEditCustomerValue(): void {
    this._customerManagementSharedService.isEditCustomerOperation$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.editCustomer = value;
        if (
          (this.editCustomer && this.generalDataForm?.dirty) ||
          this.agentLength !== this.newAgentlength
        ) {
          this.warningPopupGuard();
        } else {
          if (!this.reload) {
            this.initForm();
            this.getDropdownData();
            this.getFormData();
          }
          this._checkIsEditCustomer();
        }
      });
  }
}
