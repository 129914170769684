import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import {
  documentDescription,
  SelectedDocument,
  uploadDocuments,
  uploadDocumentsName,
} from '@shared/models/upload-data.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { Dictionary } from '../../../models/dictionary.model';
import { UploadObjectDetails } from '../../../models/generic.model';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { takeUntil } from 'rxjs/operators';
import { GetSecuredDocumentRequest } from '../../../models/upload-document.model';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css'],
})
export class UploadDocumentComponent extends BaseComponent implements OnInit {
  constructor(
    private _apiCallsService: ApiCallsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private _toastService: ToastService,
    private readonly _userService: UserService
  ) {
    super();
    this.selectedInsurerToolTip = undefined;
    this.uploader = new FileUploader({
      url: '',
      disableMultipart: true,
      autoUpload: true,
    });
  }
  @Input('object_data') object_data;
  @Input('selectedDocumentsArray') selectedDocumentsArray;
  @Input('showDocumentPopup') showDocumentPopup;
  @Input('object_type') uploadObjectDetails: UploadObjectDetails;
  @Input('insurer_list') insurer_list: any;
  @Input('document_list') document_list: any;
  @Input('is_disable') is_disable;
  @Output('pdfViewer') pdfViewer = new EventEmitter<any>();
  @Output('hide') hide = new EventEmitter<any>();
  @ViewChild('fileUpload') fileUpload: any;
  @Input('via') via;
  // document_list =[];
  desTitle: any;
  documents = [];
  liztu: string;
  uploadButtonDisable: boolean;
  documentMessage: any;
  docNameToDelete: string;
  cloudDocId: any;
  uploader: FileUploader;
  decoded_liztu: any;
  showModal: boolean;
  pdfLink: string;
  showPDF = false;
  selectedInsurer;
  selectedDocument;
  selectedInsurerToolTip;
  selectedInsurerListLocal: Dictionary<boolean> = {};
  blob: string;
  pdfName: string;

  private _userId: string;
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  }

  ngOnInit(): void {
    this._watchOnUserId();
    this.selectedInsurerToolTip = undefined;
    this.uploadButtonDisable = true;
    this.decoded_liztu = this.route.snapshot.queryParamMap.get('liztu');
    if (this.decoded_liztu) {
      this.liztu = atob(this.decoded_liztu);
    }
    this.updateInsurerDetails();
  }

  onChange(insurer, itemValue): void {
    this.selectedInsurer = insurer;
    if (this.selectedInsurer) {
      this.selectedDocumentsArray[itemValue.key].insurer_id =
        this.selectedInsurer.id;
      this.selectedDocumentsArray[itemValue.key].insurer_value =
        this.selectedInsurer.desc;
    }
  }

  onDocumentChange(document, itemValue): void {
    this.selectedDocument = document;
    if (this.selectedDocument) {
      this.selectedDocumentsArray[itemValue.key].document_type_id =
        this.selectedDocument.id;
      this.selectedDocumentsArray[itemValue.key].document_value =
        this.selectedDocument.desc;
    }
  }

  getSelectedValue(item, itemValue): void {
    this.selectedDocumentsArray[itemValue.key].insurer_value =
      this.insurer_list.filter(
        selecteItem => selecteItem.id == item.value
      )[0].desc;
  }

  getSelectedDocumentValue(item, itemValue) {
    const selectDoc = this.document_list.filter(
      selecteItem => selecteItem.desc == item.value.document_type
    );
    if (!selectDoc) {
      return;
    }
    this.selectedDocumentsArray[itemValue.key].document_value =
      selectDoc[0]?.desc;
  }

  areFilesAvailable(): boolean {
    this.uploadButtonDisable = !this.areDocsAvailableForUpload();
    return Object.keys(this.selectedDocumentsArray).length > 0;
  }

  handleFileInput(inputFile): void {
    if (
      !inputFile ||
      (inputFile && this.selectedDocumentsArray[inputFile[0].name])
    ) {
      return;
    }

    if (inputFile[0].size >= 20971520) {
      alert(this.translate.instant('objectModule.uploadFileLimit'));
    } else {
      this.uploadButtonDisable = false;
      const newDoc: SelectedDocument = {
        id: Object.keys(this.selectedDocumentsArray).length + 1,
        document_description: '',
        document_name: inputFile[0].name,
        // document_type: this.uploadObjectDetails.documentType,
        is_obj_linked: null,
        document_link: '',
        file: inputFile[0],
      };
      this.selectedDocumentsArray[inputFile[0].name] = newDoc;
    }
  }

  editDescription(id, index): void {
    document.getElementById('edit' + index).style.display = 'none';
    document.getElementById('save' + index).style.display = 'initial';
    this.selectedInsurerListLocal[index] = false;
    document.getElementById('docDescription' + id).removeAttribute('disabled');
    document.getElementById('docDescription' + id).focus();
    document.getElementById('docDescription' + id).style.borderBottom =
      '1.5px solid #8f8f8f';
  }

  enableInsurer(index): boolean {
    return !!this.selectedInsurerListLocal[index];
  }

  updateInsurerDetails(): void {
    let indexValue = 0;
    Object.keys(this.selectedDocumentsArray).forEach(() => {
      this.selectedInsurerListLocal[indexValue] = true;
      indexValue++;
    });
  }

  getDocDesc(item): string {
    return 'docDescription' + item.value.id || item.value.upload_document_id;
  }

  saveDescription(id, name, index, docId) {
    document.getElementById('save' + index).style.display = 'none';
    document.getElementById('edit' + index).style.display = 'initial';
    this.selectedInsurerListLocal[index] = true;
    document.getElementById('docDescription' + id).blur();
    document.getElementById('docDescription' + id).style.outline = 'none';
    document
      .getElementById('docDescription' + id)
      .setAttribute('disabled', 'true');
    document.getElementById('docDescription' + id).style.borderBottom = 'none';
    this.selectedDocumentsArray[name].document_description = this.desTitle;
    if (docId) {
      const tableData: documentDescription = {
        upload_document_id: docId,
        user_id: this._userId,
        desc: this.desTitle,
      };
      if (this.selectedDocumentsArray[name].insurer_id) {
        tableData.insurer_id = this.selectedDocumentsArray[name].insurer_id;
      }
      if (this.uploadObjectDetails?.documentType) {
        tableData.doc_type = this.uploadObjectDetails.documentType;
      }
      if (this.selectedDocumentsArray[name].document_type) {
        tableData.doc_type = this.selectedDocumentsArray[name].document_type;
      }

      this._apiCallsService
        .updateDocumentDesPhotoVoltaik(tableData)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.selectedDocumentsArray[name].updated_at =
              data.desc_data.updated_at;
            this.selectedDocumentsArray[name].updated_by =
              data.desc_data.updated_by;
          }
        });
    }
    this.showToastMessage(
      this.translate.instant('objectModule.documentUpdateMessage')
    );
  }

  desValue(e): void {
    this.desTitle = e.target.value;
  }

  uploadDocument(): void {
    const documentsDesc = [];
    Object.keys(this.selectedDocumentsArray).forEach(fileName => {
      if (this.selectedDocumentsArray[fileName].is_obj_linked === null) {
        const row: uploadDocumentsName = {
          name: this.selectedDocumentsArray[fileName].document_name,
          desc: this.selectedDocumentsArray[fileName].document_description,
        };
        if (this.selectedDocumentsArray[fileName].insurer_id) {
          row.insurer_id = this.selectedDocumentsArray[fileName].insurer_id;
        }
        // if (this.selectedDocumentsArray[fileName].document_type_id) {
        //   row.document_type_id = this.selectedDocumentsArray[fileName].document_type_id;
        // }
        if (this.uploadObjectDetails.documentType) {
          row.doc_type = this.uploadObjectDetails.documentType;
        }
        if (this.selectedDocumentsArray[fileName].document_type) {
          row.doc_type = this.selectedDocumentsArray[fileName].document_type;
        }

        documentsDesc.push(row);
      }
    });
    const uploadDocDetails: uploadDocuments = {
      upload_user_id: this._userId,
      // doc_type: this.uploadObjectDetails.documentType,
      doc_source: this.uploadObjectDetails.documentSource,
      desc: documentsDesc,
      object_type_id: this.uploadObjectDetails.objectTypeId,
      actor_type_id: 9,
      upload_via: 'rcs',
    };
    if (this.liztu) {
      uploadDocDetails.liztu_vn_kdnr = this.liztu;
    }
    const formdata = new FormData();
    formdata.append('request', JSON.stringify(uploadDocDetails));
    Object.keys(this.selectedDocumentsArray)
      .filter(
        fileName => this.selectedDocumentsArray[fileName].is_obj_linked == null
      )
      .forEach(fileName =>
        formdata.append('file', this.selectedDocumentsArray[fileName].file)
      );
    this._apiCallsService
      .uploadPhotovoltaikDocument(formdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.uploadButtonDisable = true;
          data.docs.forEach(document => {
            Object.assign(
              this.selectedDocumentsArray[document.document_name],
              document
            );
            this.selectedDocumentsArray[document.document_name].document_name =
              document.document_name;
          });
          this.updateInsurerDetails();
          this.showToastMessage(data.message);
        }
      });
  }

  deleteDocument(event): void {
    if (event.cloudDocId) {
      this._apiCallsService
        .deleteDocumentPhotoVoltaik(event.cloudDocId, this._userId, 9)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.cleanUpDocument(event);
          }
        });
    } else {
      this.cleanUpDocument(event);
    }
  }

  cleanUpDocument(event): void {
    delete this.selectedDocumentsArray[event.name];
    this.uploadButtonDisable = !this.areDocsAvailableForUpload();
    this.onHideModal(false);
    this.showToastMessage(
      this.translate.instant('objectModule.documentDeleteMessage')
    );
    this.fileUpload.nativeElement.value = '';
  }

  viewPDF(doc_array): void {
    const data: GetSecuredDocumentRequest = {
      uuid: doc_array.value.uuid,
    };
    this._apiCallsService
      .getSecureDocument(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (doc_array.value.content_type == 'pdf') {
          const fileURL = window.URL.createObjectURL(data);
          this.showPDF = true;
          this.pdfLink = fileURL;
          this.pdfName = doc_array.value.document_name.split('.pdf')[0];
          this.blob = data;
        } else {
          const objectURL = window.URL.createObjectURL(data);
          window.open(objectURL);
        }
      });
  }

  uploadDocumentEnabled(): boolean {
    return this.uploadButtonDisable;
  }

  onClosePdf(eventValue): void {
    this.showPDF = eventValue;
  }

  processDelete(docNameToDelete, cloudDocId = null): void {
    this.docNameToDelete = docNameToDelete;
    this.cloudDocId = cloudDocId;
    this.showModal = true;
  }

  onHideModal(event): void {
    this.showModal = event;
  }

  areDocsAvailableForUpload(): boolean {
    return (
      Object.keys(this.selectedDocumentsArray).filter(
        fileName => this.selectedDocumentsArray[fileName].is_obj_linked == null
      ).length > 0
    );
  }

  showToastMessage(message): void {
    this._toastService.showToastMessage(document, message);
  }

  getTooltip(link): string {
    return link
      ? this.translate.instant('objectModule.uploadFileHint')
      : this.translate.instant('objectModule.emptyFileUploadHint');
  }

  hidePopup(): void {
    this.fileUpload.nativeElement.value = '';
    Object.keys(this.selectedDocumentsArray).forEach(name => {
      if (!this.selectedDocumentsArray[name].upload_document_id) {
        delete this.selectedDocumentsArray[name];
      }
    });
    this.hide.emit(this.selectedDocumentsArray);
  }

  private _watchOnUserId(): void {
    this._userService.userId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((userId: string) => (this._userId = userId));
  }
}
