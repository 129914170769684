import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { LookupConstants } from '@shared/constants/lookup.constants';
import * as moment from 'moment';
import {
  DeleteConfirmationRequest,
  DeleteConfirmationResponse,
} from '@shared/interfaces/delete-confirm.interface';
import { TranslateService } from '@ngx-translate/core';
import { DAMAGE_DETAILS_GUARD } from '@shared/constants/claims/claims.constants';
import { STATIC_TEXT } from '@shared/constants/string.constants';
import { NumberValidationsService } from '@core/services/validations/number-validations.service';
import { GENERIC_FORM_TYPES } from '@shared/constants/numeric.constants';
import { COMMON_IMAGES } from '@shared/constants/image-paths/common.constants';
import { ShowErrorService } from '@core/services/show-error.service';
import { ADD_INSURER_MAPPING_MULTILINGUAL } from '../../../components/insurer-management/models/mutlilingual.constants';
import { takeUntil } from 'rxjs/operators';
import { ShareDataService } from '@core/services/share-data.service';
import { BaseComponent } from '@shared/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { ClaimsShareService } from '../claims-share.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';

@Component({
  templateUrl: './damage-details.component.html',
  styleUrls: ['./damage-details.component.scss'],
})
export class DamageDetailsComponent extends BaseComponent implements OnInit {
  claimId: string;
  formsConfiguration = [];
  currencyTypes: any = [];
  currency_selected = {};
  myFormGrp!: FormGroup;
  dropdownsData = {};
  formSpecificData = [];
  FORM_DATA = [];
  isSubmitted = false;
  showGuardPopup = false;
  sendGuardReq: DeleteConfirmationRequest;
  savedFormData: any;
  formFieldType = GENERIC_FORM_TYPES;

  constructor(
    private serviceCall: ApiCallsService,
    public validationService: ValidationService,
    public formBuilder: FormBuilder,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    public _showError: ShowErrorService,
    public _numberValidationService: NumberValidationsService,
    public _shareDataService: ShareDataService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _claimsSharedService: ClaimsShareService
  ) {
    super();
  }

  ngOnInit(): void {
    this.claimId = atob(this._activatedRoute.snapshot.queryParams.claim);
    this._watchOnProductInfoId();
    this.serviceCall
      .getCurrencyTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (null !== data.currency_types) {
          this.currencyTypes = data.currency_types;
        }
      });
  }
  getFormDetails(): void {
    // here we are getting data for claims
    this.serviceCall
      .getFormDetailsByProductAndContractId(
        this.claimId,
        this._claimsSharedService.getProductInfoId(),
        LookupConstants.CLAIM
      )
      .subscribe((data: any) => {
        this.formSpecificData = data.forms_data;
        this.generateAndArrangeForm(data?.forms_configurations);
      });
  }
  generateFormComponents() {
    const group: FormGroup = new FormGroup({});
    this.FORM_DATA.forEach(fg => {
      const getData = this.formSpecificData?.find(
        ele => ele.form_details_id == fg.formGrpName
      )?.form_detail_jsonb;
      const data = getData ? JSON.parse(getData) : null;
      this.currency_selected[fg.formGrpName] = data
        ? data.find(ele => ele.key == 'currency_data' && ele.UUID == null)
            ?.input
        : {} || {};
      this._shareDataService.currencySelectedFormGenerator =
        this.currency_selected;
      const formGroup: FormGroup = new FormGroup({});
      fg.formControls.forEach(fc => {
        let val = data?.find(ele => ele.UUID == fc.name)?.input || null;
        if (fc.type == 1 && val) {
          val =
            this._numberValidationService.convertIntToGermanFormatWithDecimal(
              val
            );
        }
        if (fc.type == 1 && fc.is_currency) {
          if (!(fc.id in this.currency_selected[fg.formGrpName])) {
            const obj = {
              cur_id: 43,
              cur_code: '€',
              cur_symbol: 'EUR',
            };
            this.currency_selected[fg.formGrpName][fc.id] = obj;
          }
        }
        const nCtrol = {
          name: fc.name,
          control: new FormControl(val),
          required: fc.required,
        };
        formGroup.addControl(fc.name, nCtrol.control);
        if (nCtrol.required) {
          formGroup.controls[fc.name].setValidators(Validators.required);
        }
      });
      group.addControl(fg.formGrpName, formGroup);
    });
    this.myFormGrp = group;
    this.savedFormData = this.getValues();
  }
  generateAndArrangeForm(forms_configurations: any[]) {
    const forms = [];
    this.formsConfiguration = [];
    forms_configurations.sort((a, b) => a.name - b.name);
    forms_configurations.forEach(form => {
      const formData = this.formSpecificData.find(
        ele => ele.form_details_id == form.form_id
      );
      const result = {
        name: form.name,
        id: form.form_id,
        form_config: this.sortFormFields(form.form_configuration),
        mapping_product_to_form_id: form.mapping_product_to_form_id,
        mapping_contract_to_form_data_id: formData
          ? formData.mapping_contract_to_form_data_id
          : null,
      };
      forms.push(result);
    });
    this.formsConfiguration = [...forms];
    this.generateFormFields();
  }
  generateFormFields() {
    this.FORM_DATA = [];
    for (const element of this.formsConfiguration) {
      const obj: any = {};
      obj.formGrpLabel = element.name;
      obj.formGrpName = element.id;
      obj.mapping_product_to_form_id = element.mapping_product_to_form_id;
      const fieldsArray = [];
      for (const form_config of element.form_config) {
        const fieldObj = form_config;
        const field = {
          id: fieldObj.id,
          label: fieldObj.label,
          name: fieldObj.id,
          value: STATIC_TEXT.EMPTY_STRING,
          required: fieldObj.is_mandatory,
          type: fieldObj.type_id,
          placeholder: fieldObj.placeholder,
          radio: fieldObj.radio,
          checkbox: fieldObj.checkbox,
          is_currency: fieldObj.is_currency,
          is_single: fieldObj.is_single,
        };
        fieldsArray.push(field);
        if (field.type == 12) {
          this.setDropdownData(field.id);
        }
      }
      obj.formControls = fieldsArray;
      this.FORM_DATA.push(obj);
    }
    this.generateFormComponents();
  }
  sortFormFields(form_configuration) {
    const parsedData: any[] = JSON.parse(form_configuration);
    parsedData.sort((a, b) => a.sequence - b.sequence);
    return parsedData;
  }
  currancyChanged(event, formControl, formGroupName): void {
    const currancySymbol = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_code;
    const selectedCurrency = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_symbol;
    let temp = {};
    temp = {
      cur_id: event.value,
      cur_code: currancySymbol,
      cur_symbol: selectedCurrency,
    };
    if (this.currency_selected[formGroupName]) {
      this.currency_selected[formGroupName][formControl] = temp;
    } else {
      const obj = {};
      obj[formControl] = temp;
      this.currency_selected = {
        ...this.currency_selected,
        [formGroupName]: obj,
      };
    }
  }
  dateClicked(event, fg, fc): void {
    this.myFormGrp.controls[fg]
      .get(fc)
      .setValue(moment(event.target.value).format('YYYY-MM-DD'));
  }
  onDateBlur(fg, fc, required): void {
    if (required) {
      if (
        this.myFormGrp.controls[fg].get(fc).value == 'Invalid date' ||
        this.myFormGrp.controls[fg].get(fc).value == ''
      ) {
        this.myFormGrp.controls[fg].get(fc).setErrors({ required: true });
      } else {
        this.myFormGrp.controls[fg].get(fc).setErrors(null);
      }
    }
  }
  onChechboxChecked(event, fg, fc, required): void {
    if (required) {
      if (!event.checked) {
        this.myFormGrp.controls[fg].setErrors({ invalid: true });
        this.myFormGrp.controls[fg].get(fc).setErrors({ required: true });
      }
    }
  }
  convertToNumeric(value): number | null {
    return (
      (value &&
        Number(
          value.toString().replaceAll('.', '').replaceAll(',', '.').trim()
        )) ||
      null
    );
  }
  getValues() {
    const completeValue: { [x: string]: any } = {};
    this.FORM_DATA.forEach(fg => {
      const formDataResponse = [];
      const formData = this.myFormGrp.controls[fg.formGrpName]?.value;
      for (const control of Object.keys(formData)) {
        let input = formData[control];
        const type = fg.formControls?.find(ele => ele.id == control)?.type;
        if (type == 1) {
          input = this.convertToNumeric(input);
        }
        const obj = {
          key: fg.formControls?.find(ele => ele.id == control)?.label,
          UUID: control,
          type: fg.formControls?.find(ele => ele.id == control)?.type,
          input,
          is_deleted: false,
        };
        formDataResponse.push(obj);
      }
      completeValue[fg.formGrpName] = formDataResponse;
    });
    return completeValue;
  }
  setDropdownData(uuid): void {
    this.serviceCall
      .getDropdownData(uuid)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        const dropdownData = data.data;
        const setdropdownData = [];
        if (dropdownData.length > 0) {
          dropdownData.forEach(ele => {
            const obj: any = {};
            (obj.dropdownDetailsId = ele.dropdownDetailsId),
              (obj.description = ele.description);
            setdropdownData.push(obj);
          });
        }
        this.dropdownsData[uuid] = setdropdownData;
      });
  }
  saveFormDetails(): void {
    this.isSubmitted = true;
    const invalid = [];
    const controls = this.myFormGrp.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        this._toastService.showToastMessage(
          document,
          this._translateService.instant(
            ADD_INSURER_MAPPING_MULTILINGUAL.FILL_REQUIRED_FIELDS
          )
        );
        return;
      }
    }
    if (invalid.length < 1) {
      const generatedData = this.getValues();
      const requestDataList = [];
      for (const key of Object.keys(generatedData)) {
        const collectedData: any[] = generatedData[key];
        const obj = {
          key: 'currency_data',
          input: this.currency_selected[key],
          UUID: null,
          is_deleted: false,
        };
        collectedData?.push(obj);
        const jsonData = JSON.stringify(collectedData);
        const sendFormData = this.FORM_DATA.find(
          element => element.formGrpName == key
        );
        const getContractData = this.formsConfiguration.find(
          ele => ele.id == key
        );
        const data = {
          mapping_contract_to_form_data_id:
            getContractData?.mapping_contract_to_form_data_id
              ? getContractData?.mapping_contract_to_form_data_id
              : null,
          mapping_product_to_form_id: sendFormData.mapping_product_to_form_id,
          form_details_id: sendFormData.formGrpName,
          contract_id: this.claimId,
          form_detail_jsonb: jsonData,
        };
        requestDataList.push(data);
      }
      const requestData = {
        data: requestDataList,
      };
      this.serviceCall
        .saveFormDetailsData(this.claimId, requestData)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this._toastService.showToastMessage(document, data.message);
          if (!data.hasErrors) {
            this.getFormDetails();
          }
        });
    }
  }
  cancelBtnClick(): void {
    const generatedData = this.getValues();
    if (
      JSON.stringify(generatedData) !== JSON.stringify(this.savedFormData) ||
      JSON.stringify(this.currency_selected) !==
        JSON.stringify(this._shareDataService.currencySelectedFormGenerator)
    ) {
      this.changesGuard();
    }
  }
  hideModal(data: DeleteConfirmationResponse): void {
    if (data.isAllowed) {
      this.generateFormComponents();
    }
    this.showGuardPopup = false;
  }
  changesGuard(): void {
    const guardHeading = this._translateService.instant(
      DAMAGE_DETAILS_GUARD.GUARD_HEADING
    );
    const guardSubHeading = this._translateService.instant(
      DAMAGE_DETAILS_GUARD.GUARD_SUB_HEADING
    );
    const noLabel = this._translateService.instant(DAMAGE_DETAILS_GUARD.NO);
    const successText = this._translateService.instant(
      DAMAGE_DETAILS_GUARD.YES
    );
    this.showGuardPopup = true;
    const request: DeleteConfirmationRequest = {
      heading: guardHeading,
      subHeading: guardSubHeading,
      isCancelBtn: true,
      cancelBtnText: noLabel,
      isSuccessBtn: true,
      successBtnText: successText,
      id: null,
      isShowPopup: true,
      logoLink: COMMON_IMAGES.DELETE_CROSS,
    };
    this.sendGuardReq = request;
  }

  private _watchOnProductInfoId(): void {
    this._claimsSharedService.productInfoId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(() => this.getFormDetails());
  }
}
