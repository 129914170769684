<div>
    <span class="criteria-heading">{{'objectModule.grinding-polishing' | translate}}</span>
</div>

<form 
    *ngIf="selectLang$ | async as locale" 
    class="row object-module-html form-align" 
    [formGroup]="grindingForm" 
    id="grindingForm">

  <div class="col-md-3 col-sm-6 col-6">

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
            {{'objectModule.grinding.use_of_iron_lightMetal' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="use_of_iron_lightMetal" >
        <mat-radio-button value="true">
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
            {{'objectModule.grinding.extraction_with_adequate_cleaning' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="extraction_with_adequate_cleaning" >
        <mat-radio-button value="true" >
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
        {{'objectModule.grinding.seperate_extraction_ferrous_nonFerrous' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="seperate_extraction_ferrous_nonFerrous" >
        <mat-radio-button value="true" >
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
        {{'objectModule.grinding.dry_seperator' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="dry_seperator" >
        <mat-radio-button value="true" >
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
        {{'objectModule.grinding.fireproof_partition' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="fireproof_partition" >
        <mat-radio-button value="true" >
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
        {{'objectModule.grinding.pressure_reliefe' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="pressure_reliefe" >
        <mat-radio-button value="true" >
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6" >

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
        {{'objectModule.grinding.wet_seperator' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="wet_seperator" >
        <mat-radio-button value="true" >
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6" >

    <label 
        for="" 
        class="long-label" 
        [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
        {{'objectModule.grinding.non_ferrus_metal_seperator' | translate}}
    </label>
    <div class="radio-group-align-long">
        <mat-radio-group aria-label="" formControlName="non_ferrus_metal_seperator" >
        <mat-radio-button value="true" >
            {{'objectModule.power_supply_object.yes' | translate}}
        </mat-radio-button>
        <mat-radio-button value="false">
            {{'objectModule.power_supply_object.no' | translate}}
        </mat-radio-button>
        </mat-radio-group>
    </div>
  </div>
</form>
