import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilterSelectOption } from '@shared/components/table/filter-section/filter-section.component';

@Component({
  selector: 'app-guest-option',
  templateUrl: 'guest-option.component.html',
  styleUrls: ['guest-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuestOptionComponent {
  @Input() option: FilterSelectOption;
}
