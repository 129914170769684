<div *ngIf="isLoggedUser$ | async" class="row loginContainer" >
    <div class="bg">
        <div class="login">
            <form [formGroup]="loginForm" (ngSubmit)="onsubmit(loginForm.value)" class="login-form" id="form_login">
                <div class="login-text">
                        <div style="background-color: #fff;"><img alt="" class="logo" src="../../../assets/images/artus-logo-hd.png"></div>
                        <span *ngIf="is_source_ivm == true" style="font-weight: 600;">{{'otp.WirHabenIhnen_ic_text' | translate}} </span>
                        <span *ngIf="is_source_ivm == false" style="font-weight: 600;">{{'otp.WirHabenIhnen_text' | translate}} </span>
                        <p style="font-size: 14px;
                        font-weight: 600;color: #272727;">{{'otp.BitteGeben_text' | translate}} </p>
                </div>
                <div class="otp-field">
                    <div class="form-group">
                        <input style="text-align: center;color:#272727" type="text" #otp1 maxlength="1" (keyup)="changeinput($event, otp2, '')" (paste)="onPaste($event,otp6)" class="form-control" formControlName="otp1">
                    </div>
                    <div class="form-group">
                        <input style="text-align: center;color:#272727" id="otps" type="text" #otp2 maxlength="1" (keyup)="changeinput($event, otp3, otp1)" class="form-control" formControlName="otp2">
                    </div>
                    <div class="form-group">
                        <input style="text-align: center;color:#272727" type="text" #otp3 maxlength="1" (keyup)="changeinput($event, otp4, otp2)" class="form-control" formControlName="otp3">
                    </div>
                    <div class="form-group">
                        <input style="text-align: center;color:#272727" type="text" #otp4 maxlength="1" (keyup)="changeinput($event, otp5, otp3)" class="form-control" formControlName="otp4">
                    </div>
                    <div class="form-group">
                        <input style="text-align: center;color:#272727" type="text" #otp5 maxlength="1" (keyup)="changeinput($event, otp6, otp4)"  class="form-control" formControlName="otp5" >
                    </div>
                    <div class="form-group">
                        <input style="text-align: center;color:#272727" type="text" #otp6 maxlength="1" (keyup)="changeinput($event, '' , otp5)" class="form-control" formControlName="otp6">
                    </div>
                </div>
                    <p style="font-size: 13px;margin-left: 9px;clear:both;color: #272727;font-weight: 600;">{{'otp.Verbleibende_text' | translate}} <span style="font-size: 13px;" id="demo"></span></p>
                    <div class="flex-container" style="clear: both;
                    float: left;
                    width: 100%;">
                        <div class="form-check" style="flex-grow: 1;padding-left:0px">
                            <button (click)="resend()" type="button" style="color: #272727;
                            background-color: #fff;
                            border: none;
                            width: 100%;
                            font-size: 13px;
                            font-weight: 600;text-align: left;padding-left: 9px;">{{'otp.Sicherheitscode_text' | translate}}</button>
                        </div>
                    </div>
                    <br>
                    <button click='onsubmit()' type="submit" [disabled]="!loginForm.valid" class="btn waves-light m-0 mb-2" mdbWavesEffect>
                        {{'otp.Bestätigen_text' | translate}}</button>
                    <br>

            </form>
        </div>
        <div class="content" style="color:#fff"><p> {{'otp.MIT_text' | translate}} <br />
           <span style="color:#ff9000">/</span><br/>
           <span style="letter-spacing: 1px;"><strong>{{'otp.IC_text' | translate}}  </strong></span></p></div>
    </div>
</div>
<div id="snackbar">{{messageShow}}</div>
<script>
    $(document).ready(function(){
    $('input').keyup(function(){
        if($(this).val().length==$(this).attr("maxlength")){
            $(this).next().focus();
        }
    });
});
</script>
