<div>
  <span class="criteria-heading">{{'objectModule.fire_protection' | translate}}</span>
</div>


<form class="row object-module-html form-align" [formGroup]="FireProtectionForm" id="FireProtectionForm" *ngIf="FireProtectionForm">
  <div class="col-md-3 col-sm-5 col-5">

    <label for="" class="long-label">
      {{'objectModule.fire_protection_labels.Fire_Protection' | translate}}
    </label>
    <div style="display: flex;">
      <div class="documentUploadInput">
        <input class="input" readonly title="{{FireProtectionForm.value.fire_protection_id}}" formControlName="fire_protection_id" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
      </div>

      <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadFPDocumentPopup()">
          <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
      </div>
    </div>


  </div>
  <div class="col-md-3 col-sm-5 col-5">
    <label for="" class="long-label">
      {{'objectModule.fire_protection_labels.Floor_Plans' | translate}}
    </label>
    <div style="display: flex;">
      <div class="documentUploadInput">
        <input class="input" readonly  title="{{FireProtectionForm.value.floor_plan_id}}"  formControlName="floor_plan_id" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
      </div>

      <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadFLPDocumentPopup()">
          <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-3 col-sm-5 col-5">
    <label for="" class="long-label">
      {{'objectModule.fire_protection_labels.External_Doors_Image_Upload' | translate}}
    </label>
    <div style="display: flex;">
      <div class="documentUploadInput">
        <input class="input" readonly   title="{{FireProtectionForm.value.external_door_img_id}}" formControlName="external_door_img_id" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
      </div>

      <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="uploadFCDocumentPopup()">
          <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-3 col-sm-5 col-5">
    <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Production_Engineering' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group aria-label="" formControlName="is_switched_off">
        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>


  <div [ngClass]="FireProtectionForm.value.is_fire_compartment ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_fire_compartment">{{'objectModule.fire_protection_labels.Fire_Compartments' | translate}}</mat-slide-toggle>
    </div>


    <div *ngIf="FireProtectionForm.value.is_fire_compartment" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Total_Area' | translate}}</label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="6" class="input" formControlName="area_in_sqm" placeholder="{{'objectModule.fire_protection_labels.Total_Area' |
            translate}}" (keydown)="validation.SequenceNumberValidation($event, 'keydown',5)" (paste)="validation.SequenceNumberValidation($event, 'paste',5)"
            (blur)="validation.sqmValidation(FireProtectionForm, $event)" (focus)="validation.sqmValidation(FireProtectionForm, $event)"
          numbersOnly>
        <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" (paste)="validation.SequenceNumberValidationBrowserSpecific($event, 'paste',5)"
          (keypress)="validation.SequenceNumberValidationBrowserSpecific($event, 'keydown',5)" class="input numberText" formControlName="area_in_sqm" placeholder="{{'objectModule.fire_protection_labels.Total_Area' |
            translate}}" (blur)="validation.sqmValidation(FireProtectionForm, $event)" (focus)="validation.sqmValidation(FireProtectionForm, $event)" numbersOnly>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Number_Of_Fire_Compartments' | translate}}</label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="6" class="input" formControlName="No_of_fire_compartments" placeholder="{{'objectModule.fire_protection_labels.Number_Of_Fire_Compartments' |
              translate}}" (keydown)="validation.SequenceNumberValidation($event, 'keydown',5)" (paste)="validation.SequenceNumberValidation($event, 'paste',5)" (blur)="validation.sqmValidation(FireProtectionForm, $event)" (focus)="validation.sqmValidation(FireProtectionForm, $event)"
          numbersOnly>
        <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" (paste)="validation.SequenceNumberValidationBrowserSpecific($event, 'paste',5)"
          (keypress)="validation.SequenceNumberValidationBrowserSpecific($event, 'keydown',5)" class="input numberText" formControlName="No_of_fire_compartments" placeholder="{{'objectModule.fire_protection_labels.Number_Of_Fire_Compartments' |
              translate}}" (blur)="validation.sqmValidation(FireProtectionForm, $event)" (focus)="validation.sqmValidation(FireProtectionForm, $event)" numbersOnly>
      </div>

      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Largest_Fire_Section' | translate}}</label>
        <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="text" maxlength="5" class="input" formControlName="largest_fire_compartment_in_sqm" placeholder="{{'objectModule.fire_protection_labels.Largest_Fire_Section' |
              translate}}" (keydown)="validation.SequenceNumberValidation($event, 'keydown',4)" (paste)="validation.SequenceNumberValidation($event, 'paste',5)" (blur)="validation.sqmValidation(FireProtectionForm, $event)" (focus)="validation.sqmValidation(FireProtectionForm, $event)"
          numbersOnly>
        <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*" (paste)="validation.SequenceNumberValidationBrowserSpecific($event, 'paste',4)"
          (keypress)="validation.SequenceNumberValidationBrowserSpecific($event, 'keydown',4)" class="input numberText" formControlName="largest_fire_compartment_in_sqm" placeholder="{{'objectModule.fire_protection_labels.Largest_Fire_Section' |
              translate}}" (blur)="validation.sqmValidation(FireProtectionForm, $event)" (focus)="validation.sqmValidation(FireProtectionForm, $event)" numbersOnly>
      </div>
    </div>
  </div>

  <div [ngClass]="FireProtectionForm.value.is_fire_extinguisher_available ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_fire_extinguisher_available">{{'objectModule.fire_protection_labels.Fire_Extinguishers_Available' | translate}}</mat-slide-toggle>
    </div>

    <div *ngIf="FireProtectionForm.value.is_fire_extinguisher_available" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Type_Of_Fire_Extinguishers' | translate}}</label>
        <mat-select multiple class="dropdownStyle" formControlName="type_of_fire_extinguisher_id" placeholder="{{'objectModule.select_lable' | translate}}">
          <mat-option *ngFor="let ele of Typeoffireextinguishers" [value]="ele.id">{{ele.desc}}</mat-option>
        </mat-select>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Remark_Fire_Extinguishers' | translate}}</label>
        <input type="text" maxlength="100" title="{{FireProtectionForm.value.sfpsd_remark}}" class="input" formControlName="sfpsd_remark" placeholder="{{'objectModule.fire_protection_labels.Remark' | translate}}">
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Checked_Every_Two_Years' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_checked_every_two_years">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Locations_Of_Fire_Extinguishers' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_fire_exting_loc_marked">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Extinguishers_Freely_Accessible' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_fire_exting_accessible">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>


  <div [ngClass]="FireProtectionForm.value.is_intruder_alarm ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_intruder_alarm">{{'objectModule.fire_protection_labels.Intruder_Alarm_System' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="FireProtectionForm.value.is_intruder_alarm" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.VdS_Approved' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_vds_approved">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label">
          {{'objectModule.fire_protection_labels.Document_Upload' | translate}}
        </label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly   title="{{FireProtectionForm.value.vds_doc_id}}"  formControlName="vds_doc_id" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}" [class.disabledinput]="!FireProtectionForm.value.is_vds_approved">
          </div>
          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadDUPDocumentPopup()" [disabled]="(!FireProtectionForm.value.is_vds_approved || FireProtectionForm.value.is_vds_approved == '')">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.External_Entity' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_intrude_alarm_overridden">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox" (click)="resetDependentValue('override_to_id')">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Connected_To' | translate}}</label>
        <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="override_to_id" [disabled]="(!FireProtectionForm.value.is_intrude_alarm_overridden || FireProtectionForm.value.is_intrude_alarm_overridden == '')">
          <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
          <mat-option *ngFor="let ele of TypeofIntrusion" [value]="ele.id">{{ele.desc}}</mat-option>
        </mat-select>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Perimeter_Surveillance' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_perimeter_surveillance">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Interior_Surveillance' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_interior_surveillance">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="FireProtectionForm.value.is_safety_locks ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_safety_locks">{{'objectModule.fire_protection_labels.Locking_Of_Outer_Doors' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="FireProtectionForm.value.is_safety_locks" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label">
          {{'objectModule.fire_protection_labels.Locking_Of_Outer_Doors' | translate}}
        </label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly   title="{{FireProtectionForm.value.is_safety_locks_id}}"  formControlName="is_safety_locks_id" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
          </div>
          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadLODDocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="FireProtectionForm.value.is_fire_alarm_system ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_fire_alarm_system">{{'objectModule.fire_protection_labels.Fire_Alarm_System' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="FireProtectionForm.value.is_fire_alarm_system" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <div>
          <label for="" class="long-label " *ngIf="!(FireProtectionForm.controls['degree_of_protection']?.touched
                       && (hasFormError('degree_of_protection','max') ||  hasFormError('degree_of_protection','min'))) ">
            {{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}</label>
          <label for="" class="long-label touched-warning" *ngIf="(FireProtectionForm.controls['degree_of_protection']?.touched
                       && (hasFormError('degree_of_protection','max') ||  hasFormError('degree_of_protection','min'))) ">
            {{'objectModule.fire_protection_labels.maxValue100' | translate}}</label>
          <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" pattern="[0-9]*" type="number" maxlength="3" (blur)="resetValue('degree_of_protection')" class="input" formControlName="degree_of_protection"
            (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)" placeholder="{{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}">
          <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="3" (blur)="resetValue('degree_of_protection')" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',3)"
            (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)" class="input numberText" formControlName="degree_of_protection" placeholder="{{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}">
        </div>
      </div>

      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.VdS_Approved' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="fa_is_vds_approved">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label">
          {{'objectModule.fire_protection_labels.Document_Upload_VDS' | translate}}
        </label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly   title="{{FireProtectionForm.value.fa_vds_doc_id}}"   formControlName="fa_vds_doc_id" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}" [class.disabledinput]="!FireProtectionForm.value.fa_is_vds_approved">
          </div>
          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadLO2DDocumentPopup()" [disabled]="!FireProtectionForm.value.fa_is_vds_approved">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Call_Up' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_intrusion1">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox" (click)="resetDependentValue('fa_override_to')">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Connected_To' | translate}}</label>
        <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="fa_override_to" [disabled]="!FireProtectionForm.value.is_intrusion1">
          <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
          <mat-option *ngFor="let ele of TypeofIntrusion" [value]="ele.id">{{ele.desc}}</mat-option>
        </mat-select>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <div>
          <label for="" class="long-label">
            {{'objectModule.fire_protection_labels.Fire_Alarm_System_remark' | translate}}<br />
          </label>
          <input class="input" maxlength="100" title="{{FireProtectionForm.value.fa_remark}}" placeholder="{{'objectModule.fire_protection_labels.Fire_Alarm_System_remark' | translate}}" formControlName="fa_remark">
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="FireProtectionForm.value.is_extinguishing_system ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_extinguishing_system">{{'objectModule.fire_protection_labels.Extinguishing_Systems' | translate}}</mat-slide-toggle>
    </div>

    <div *ngIf="FireProtectionForm.value.is_extinguishing_system" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Type_Extinguishing_System' | translate}}</label>
        <mat-select multiple class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="type_of_exting_system_id">
          <mat-option *ngFor="let ele of TypeOfFireExtingSystems" [value]="ele.id">{{ele.desc}}</mat-option>
        </mat-select>
      </div>
      <div class="col-md-3 col-sm-5 col-5">
        <div>
          <label for="" class="long-label " *ngIf="!(FireProtectionForm.controls['es_degree_of_protection']?.touched
                       && (hasFormError('es_degree_of_protection','max') ||  hasFormError('es_degree_of_protection','min'))) ">
            {{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}</label>
          <label for="" class="long-label touched-warning" *ngIf="(FireProtectionForm.controls['es_degree_of_protection']?.touched
                       && (hasFormError('es_degree_of_protection','max') ||  hasFormError('es_degree_of_protection','min'))) ">
            {{'objectModule.fire_protection_labels.maxValue100' | translate}}</label>
          <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" pattern="[0-9]*" type="number" maxlength="3" (blur)="resetValue('es_degree_of_protection')" class="input" formControlName="es_degree_of_protection"
            (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)" placeholder="{{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}">
          <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="3" (blur)="resetValue('es_degree_of_protection')" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',3)"
            (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)" class="input numberText" formControlName="es_degree_of_protection" placeholder="{{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}">
        </div>
      </div>
      <div class="col-md-3 col-sm-5 col-5 date-picker-container" id="datePicker3">
        <label *ngIf="!birthdayError" for="" class="long-label">
          {{'objectModule.fire_protection_labels.Date_Of_Audit' | translate}}
        </label>
        <label *ngIf="birthdayError" for="" class="label touched-warning ellipsis">
          {{'objectModule.fire_protection_labels.Date_Of_Audit' | translate}} : {{'objectModule.Invalid_date' |
              translate}}
        </label>
        <mat-form-field class="date-picker" appearance="fill" id="datePicker">
          <span style="margin-right: 5px;vertical-align: text-top;">
            <img alt="" class="calendar-icon" style="vertical-align: unset;" src="assets/images/ic-icons/Calendar.svg">
          </span>
          <input matInput (dateChange)="dateClicked()" [matDatepicker]="datepicker3" class="date-input" autocomplete="off" (keypress)="dateChange($event)" (paste)="dateChange($event)" (blur)="onDateBlur($event)"
            placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" formControlName="es_date_of_audit" [max]="today">
          <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker3">
            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
              {{'claims.keyboard-arrow-down_text' | translate}}
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datepicker3></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label">
          {{'objectModule.fire_protection_labels.Upload_Audit_Document' | translate}}
        </label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly  title="{{FireProtectionForm.value.Upload_Audit_Document}}" formControlName="Upload_Audit_Document" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
          </div>
          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadLAODDocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-5 col-5">
        <div>
          <label for="" class="long-label">
            {{'objectModule.fire_protection_labels.Extinguishing_Systems_remark' | translate}}<br />
          </label>
          <input class="input" maxlength="100" title="{{FireProtectionForm.value.es_remark}}" placeholder="{{'objectModule.fire_protection_labels.Remark' | translate}}" formControlName="es_remark">
        </div>
      </div>
    </div>
  </div>


  <div [ngClass]="FireProtectionForm.value.is_smoke_heat_extraction ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_smoke_heat_extraction">{{'objectModule.fire_protection_labels.Smoke_Extraction_Systems' | translate}}</mat-slide-toggle>
    </div>

    <div *ngIf="FireProtectionForm.value.is_smoke_heat_extraction" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <div>

          <label for="" class="long-label " *ngIf="!(FireProtectionForm.controls['shes_degree_of_protection']?.touched
                       && (hasFormError('shes_degree_of_protection','max') ||  hasFormError('shes_degree_of_protection','min'))) ">
            {{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}</label>
          <label for="" class="long-label touched-warning" *ngIf="(FireProtectionForm.controls['shes_degree_of_protection']?.touched
                       && (hasFormError('shes_degree_of_protection','max') ||  hasFormError('shes_degree_of_protection','min'))) ">
            {{'objectModule.fire_protection_labels.maxValue100' | translate}}</label>

          <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" pattern="[0-9]*" type="number" maxlength="3" (blur)="resetValue('shes_degree_of_protection')" class="input" formControlName="shes_degree_of_protection"
            (keydown)="typeNumber($event, 'keydown',3)" (paste)="typeNumber($event, 'paste',3)" placeholder="{{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}">

          <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="3" (blur)="resetValue('shes_degree_of_protection')" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',3)"
            (keypress)="typeNumberBrowserSpecific($event, 'keydown',3)" class="input numberText" formControlName="shes_degree_of_protection" placeholder="{{'objectModule.fire_protection_labels.Degree_Of_Protection' | translate}}">

        </div>
      </div>

      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label">
          {{'objectModule.fire_protection_labels.upload_doc_inst' | translate}}
        </label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly title="{{FireProtectionForm.value.upload_doc_inst}}"  formControlName="upload_doc_inst" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
          </div>

          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadUDIDocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div [ngClass]="FireProtectionForm.value.is_explosion_protection_area ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_explosion_protection_area">{{'objectModule.fire_protection_labels.Explosion_Protection_Area' | translate}}</mat-slide-toggle>
    </div>
    <div *ngIf="FireProtectionForm.value.is_explosion_protection_area" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label">
          {{'objectModule.fire_protection_labels.upload_doc_prot' | translate}}
        </label>
        <div style="display: flex;">
          <div class="documentUploadInput">
            <input class="input" readonly  title="{{FireProtectionForm.value.exp_pro_doc_id}}" formControlName="exp_pro_doc_id" placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
          </div>

          <div class="documentUploadButton">
            <button class="docButton" type="button" (click)="uploadUDI2DocumentPopup()">
              <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="FireProtectionForm.value.is_relevant_quantities ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_relevant_quantities">{{'objectModule.fire_protection_labels.Combustible_Waste' | translate}}</mat-slide-toggle>
    </div>

    <div *ngIf="FireProtectionForm.value.is_relevant_quantities" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Daily_Disposal' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_daily_disposal">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="FireProtectionForm.value.is_oily_cleaning_rags_available ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
    <div class="toggleSectionHeader">
      <mat-slide-toggle formControlName="is_oily_cleaning_rags_available">{{'objectModule.fire_protection_labels.Oily_Cleaning' | translate}}</mat-slide-toggle>
    </div>

    <div *ngIf="FireProtectionForm.value.is_oily_cleaning_rags_available" class="row toggle-section-content">
      <div class="col-md-3 col-sm-5 col-5">
        <label for="" class="long-label"> {{'objectModule.fire_protection_labels.Storage_In_Non_Flammable' | translate}}</label>
        <div class="radio-group-align-long">
          <mat-radio-group aria-label="" formControlName="is_store_in_non-flammable_containers">
            <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
            <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</form>

<app-upload-document [object_data]="parentObjectData" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)">
</app-upload-document>
