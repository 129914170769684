import {
  DocumentInformation,
  DocumentSource,
} from '../../models/document-management.model';

export type GetDocumentsParams = {
  source: DocumentSource;
  VN_KDNR: string | null;
  VS_IDNR: string | null;
  SA_IDNR: string | null;
  customerId: string | null;
  policy: string | null;
  contractId: string | null;
  claimId: string | null;
  insurerId: string | null;
  intermediaryId: string | null;
};

export enum DocumentColumns {
  EXPAND = 'expand',
  VISIBLE_FOR_CUSTOMER = 'visibleForCustomer',
  DOCUMENT_TYPE = 'documentType',
  DESCRIPTION = 'description',
  CREATED_BY = 'createdBy',
  SOURCE_TARGET = 'sourceTarget',
  UPLOAD_DATE = 'uploadDate',
  ACTION = 'action',
}

export type VersionedDocument = DocumentInformation & {
  isOpened: boolean;
  parentId: number | null;
};

export enum DocumentExtension {
  PDF = 'pdf',
  EMAIL = 'eml',
  WORD = 'docx'
}
