import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: ['./custom-autocomplete.component.css'],
})
export class CustomAutocompleteComponent implements OnInit {
  @Input('control') control = new FormControl('');
  @Input('placeholder') placeholder = '';
  @Input('required') required = true;
  @Input('lookup') lookup = [];
  @Output('newValueSelected') newValueSelected = new EventEmitter<any>();
  @Input('validate') validate;
  @Input('selected') selectedString;
  @Input('is_disabled') is_disabled;
  @Input('extended_dropdown_data') extended_dropdown_data = [];
  is_input_disabled = false;
  optionSelected = false;
  filteredStreets: Observable<string[]>;
  newValue: string;
  @Input('via') via: string;
  maskgeneratorAttribute: string;

  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.is_input_disabled = this.is_disabled;
    this.initialize();
  }

  initialize() {
    if (this.control) {
      this.filteredStreets = this.control.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || ''))
      );
      this.control.valueChanges.subscribe((res: any) => {
        if (res && !this.lookup.includes(res) && res != this.newValue) {
          this.optionSelected = false;
        }
      });
      this.control.setValidators(
        this.required
          ? [Validators.required, this.autocompleteStringValidator()]
          : [this.autocompleteStringValidator()]
      );
      if (this.via == 'maskGenerator') {
        this.control.setValue(this.selectedString);
      }
      this.control.updateValueAndValidity();
    } else {
      this.control = new FormControl('');
    }
  }
  private _filter(value: string): string[] {
    if (value) {
      const filterValue = this._normalizeValue(value);
      const lookup = this.lookup.filter((val) =>
        this._normalizeValue(val).includes(filterValue)
      );
      if (
        lookup.length < 3 &&
        !this.lookup.filter(
          (val) => val && val.toLowerCase() == value.toLowerCase()
        ).length
      ) {
        this.newValue = value;
      } else {
        this.newValue = '';
      }
      this.maskgeneratorAttribute = value;
      return lookup;
    }
  }

  private _normalizeValue(value: string): string {
    return (value && value.toLowerCase().replace(/\s/g, '')) || '';
  }
  checkIsSelected() {
    setTimeout(() => {
      if (!this.optionSelected) {
        if (this.control.value && this.lookup.includes(this.control.value)) {
          this.optionSelected = true;
          this.control.updateValueAndValidity();
        } else {
          this.control.setValue('');
        }
      } else {
        this.control.updateValueAndValidity();
      }
    }, 500);
  }
  newValeSelected() {
    if (this.via == 'maskGenerator') {
      this.newValueSelected.emit(this.maskgeneratorAttribute);
    } else {
      this.newValueSelected.emit(this.newValue);
    }
  }

  autocompleteStringValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.optionSelected || !control.value) {
        return null; /* valid option selected */
      }
      return { invalidAutocompleteString: { value: control.value } };
    };
  }
  isNumber(n) {
    return typeof (n) != 'boolean' && !isNaN(n);
  }
}
