import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaveProductManagementGuard } from '@core/guards/leave-product_management.guard';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { ProductBaseStepper } from './product-management/product-base-stepper/product-base-stepper.component';

const routes: Routes = [
  {
    path: ROUTES_PATH.PRODUCT_MANAGEMENT_EDIT,
    component: ProductBaseStepper,
    canDeactivate: [LeaveProductManagementGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RCSSetupRoutingModule {}
