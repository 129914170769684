import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DeleteDocumentResponse,
  DocumentSource,
  DocumentsResponse,
  GetAllDocumentsResponse,
  UploadDocumentResponse,
  DocumentTypeResponse,
  SourceTargetOptions,
  DocumentInformation,
  UploadOfficeDocument,
} from '@shared/models/document-management.model';
import { Api } from '@shared/constants/api.constants';
import { Api_URL } from '@shared/constants/url.constants';

@Injectable({ providedIn: 'root' })
export class DocumentManagementApiService {
  constructor(private readonly _httpClient: HttpClient) {}

  getMasterDataDocuments(
    VN_KDNR: string,
    VS_IDNR: string,
    SA_IDNR: string,
    customerId: string,
    policy: string,
    contractId: string,
    damageId: string | null,
    insurerId: string | null
  ): Observable<GetAllDocumentsResponse> {
    const documentFormData = new FormData();
    documentFormData.append('document_source', DocumentSource.MASTER_DATA);
    documentFormData.append('liztu_vn_kdnr', VN_KDNR);
    documentFormData.append('liztu_vs_idnr', VS_IDNR);
    documentFormData.append('liztu_sa_idnr', SA_IDNR);
    documentFormData.append('target_customer_id', customerId);
    documentFormData.append('policy_number ', policy);
    documentFormData.append('contract_id', contractId);
    documentFormData.append('damage_id', damageId);
    documentFormData.append('insurerId', insurerId);
    return this._httpClient.post<GetAllDocumentsResponse>(
      Api_URL.IC_API_URL + Api.GET_DOCUMENT,
      documentFormData
    );
  }

  getDocuments(
    documentSource: DocumentSource,
    liztuVnKdnr: string,
    liztuVsIdnr: string,
    liztuSaIdnr: string,
    customerId: string,
    policyNumber: string,
    contractId: string,
    claimId: string | null,
    isGewerbe: boolean | false,
    insurerId: string | null,
    intermediaryId: string | null
  ): Observable<DocumentsResponse> {
    const data = {
      documentSource,
      liztuVnKdnr,
      liztuVsIdnr,
      liztuSaIdnr,
      customerId,
      policyNumber,
      contractId,
      claimId,
      isGewerbe,
      insurerId,
      intermediaryId,
    };
    const documentFormData = new FormData();
    documentFormData.append('documentSource', documentSource);
    documentFormData.append('liztuVnKdnr', liztuVnKdnr);
    documentFormData.append('liztuVsIdnr', liztuVsIdnr);
    documentFormData.append('liztuSaIdnr', liztuSaIdnr);
    documentFormData.append('customerId', customerId);
    documentFormData.append('policyNumber ', policyNumber);
    documentFormData.append('contractId', contractId);
    documentFormData.append('claimId', claimId);
    documentFormData.append('isGewerbe', String(isGewerbe));
    documentFormData.append('insurerId', insurerId);
    documentFormData.append('intermediaryId', intermediaryId);
    return this._httpClient.post<DocumentsResponse>(
      Api_URL.IC_API_URL + Api.GET_DOCUMENTS,
      data
    );
  }

  deleteDocument(id: number): Observable<DeleteDocumentResponse> {
    const params = new HttpParams().append(
      'upload_document_ids',
      id.toString(10)
    );
    return this._httpClient.delete<DeleteDocumentResponse>(
      Api_URL.IC_API_URL + Api.DELETE_DOCUMENT,
      { params }
    );
  }

  uploadDocument(
    files: (Blob | string)[],
    userId: string,
    documentType: string,
    documentNumber: string,
    policyNumber: string,
    description: string,
    customerId: string,
    VN_KDNR: string,
    contractId: string,
    VS_IDNR: string,
    SA_IDNR: string,
    source: DocumentSource,
    insurerId: string | null
  ): Observable<UploadDocumentResponse> {
    const formData = new FormData();
    files.forEach(file => formData.append('file', file));
    formData.append('upload_user_id', userId);
    formData.append('document_type', documentType);
    formData.append('document_no', documentNumber);
    formData.append('policy_no', policyNumber);
    formData.append('document_source', source);
    formData.append('document_desc', description);
    formData.append('target_customer_id', customerId);
    formData.append('liztu_vn_kdnr', VN_KDNR);
    formData.append('contract_id', contractId);
    formData.append('liztu_vs_idnr', VS_IDNR);
    formData.append('liztu_sa_idnr', SA_IDNR);
    formData.append('insurerId', insurerId);
    formData.append('damage_id', null);
    formData.append('action', 'new');
    formData.append('upload_via', 'rcs');
    formData.append('document_creation_date', null);
    formData.append('document_identifier', null);

    const headers = new HttpHeaders({
      charset: 'UTF-8',
    });
    return this._httpClient.post<UploadDocumentResponse>(
      Api_URL.IC_API_URL + Api.DOCUMENT_UPLOAD,
      formData,
      {
        headers,
      }
    );
  }

  documentUpload(
    files: (Blob | string)[],
    userId: number,
    documentType: string,
    documentNumber: string,
    policyNumber: string,
    description: string,
    customerId: number,
    liztuVnKdnr: number,
    liztuSaIdnr: number,
    liztuVsIdnr: number,
    contractId: number,
    documentCreationDate: string,
    documentIdentifier: string,
    damageId: number,
    insurerId: number,
    intermediaryId: number,
    sourceUser: SourceTargetOptions,
    targetUser: SourceTargetOptions,
    isVisibleForCustomer: boolean,
    documentSource: DocumentSource
  ): Observable<UploadDocumentResponse> {
    const formData = new FormData();
    files.forEach(file => formData.append('file', file));
    const documentInfo = {
      userId,
      documentType,
      documentNumber,
      policyNumber,
      documentSource,
      description,
      customerId,
      liztuVnKdnr,
      liztuSaIdnr,
      liztuVsIdnr,
      contractId,
      documentCreationDate,
      documentIdentifier,
      damageId,
      insurerId,
      intermediaryId,
      sourceUser,
      targetUser,
      isVisibleForCustomer,
    };
    formData.append('documentInfo', JSON.stringify(documentInfo));
    const headers = new HttpHeaders({
      charset: 'UTF-8',
    });
    return this._httpClient.post<UploadDocumentResponse>(
      Api_URL.IC_API_URL + Api.DOCUMENT_UPLOAD_2,
      formData,
      { headers }
    );
  }

  customerDocuments(
    userId: number,
    customerId: number,
    documentSource: DocumentSource,
    fetchAll: boolean
  ): Observable<DocumentsResponse> {
    const data = { userId, customerId, documentSource, fetchAll };
    return this._httpClient.post<DocumentsResponse>(
      Api_URL.IC_API_URL + Api.GET_CUSTOMER_DOCUMENTS,
      data
    );
  }

  getDocumentTypes(source: DocumentSource): Observable<DocumentTypeResponse[]> {
    const params = new HttpParams().append('documentSource', source);
    return this._httpClient.get<DocumentTypeResponse[]>(
      `${Api_URL.IC_API_URL}${Api.GET_DOCUMENT_TYPES}`,
      { params }
    );
  }

  updateMetaData(
    documentDescription: string,
    sourceUser: SourceTargetOptions,
    targetUser: SourceTargetOptions,
    documentId: number,
    isVisibleForCustomer: boolean,
    documentType: string
  ): Observable<DocumentInformation> {
    const body = {
      documentDescription,
      sourceUser,
      targetUser,
      documentId,
      isVisibleForCustomer,
      documentType,
    };
    return this._httpClient.post<DocumentInformation>(
      Api_URL.IC_API_URL + Api.UPDATE_DOCUMENT_METADATA,
      body
    );
  }

  getVersions(documentId: number): Observable<DocumentsResponse> {
    return this._httpClient.get<DocumentsResponse>(
      `${Api_URL.IC_API_URL}${Api.GET_DOCUMENT_VERSIONS}/${documentId}`
    );
  }

  addVersions(
    file: Blob,
    documentId: number,
    description: string,
    documentType: string
  ): Observable<DocumentsResponse> {
    const formData = new FormData();
    formData.append('file', file);
    const params = new HttpParams()
      .append('documentId', documentId.toString(10))
      .append('description', description)
      .append('documentType', documentType);
    return this._httpClient.post<DocumentsResponse>(
      `${Api_URL.IC_API_URL}${Api.ADD_DOCUMENT_VERSION}`,
      formData,
      { params }
    );
  }

  createWordDraft(doc: UploadOfficeDocument): Observable<string> {
    return this._httpClient.post(
      `${Api_URL.IC_API_URL}${Api.CREATE_DRAFT_WORD}`,
      doc,
      { responseType: 'text' }
    );
  }

  sendWordDraftToAzure(documentId: number): Observable<void> {
    return this._httpClient.put<void>(
      `${Api_URL.IC_API_URL}${Api.SAVE_DRAFT_WORD}/${documentId}`,
      null
    );
  }

  createOutlookDraft(doc: UploadOfficeDocument): Observable<string> {
    return this._httpClient.post(
      `${Api_URL.IC_API_URL}${Api.CREATE_DRAFT_OUTLOOK}`,
      doc,
      { responseType: 'text' }
    );
  }

  sendOutlookDraftToAzure(documentId: number): Observable<void> {
    return this._httpClient.put<void>(
      `${Api_URL.IC_API_URL}${Api.SAVE_DRAFT_OUTLOOK}/${documentId}`,
      null
    );
  }
}
