import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { NewEditorComponent } from './new-editor/new-editor.component';
import { StepOneProcessComponent } from './step-one-process/step-one-process.component';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { ToastService } from '@core/services/toast.service';
import { FILTER } from '@shared/constants/numeric.constants';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';

// interface for sending data to first step of add process
export interface FirstStepData {
  mandant: string;
  actortype: string;
  actor: string;
  subsidiary: string;
  actortypename: string;
  actorname: string;
  mandantname: string;
  subsidiaryname: string;
  sparte_id: string;
  sparte: string;
  processes_id: string;
  process: string;
  is_sparte_group: any;
}
// handle get cm data interface
export interface Element {
  contact_manager_id: string;
  division_id: string;
  processes_id: string;
  cm_area_id: string;
  sparte_id: string;
  editor_id: string;
  actor_id: string;
  actor_type_id: string;
  product_id: string;
  subsidiary_id: string;
  subsidiary_name: string;
  extern_id: string;
  creator_id: string;
  user_role_id: string;
  process: string;
  sparte: string;
  product: string;
  actor_type: string;
  cm_area: string;
  from: string;
  to: string;
  extern: string;
  actor: string;
  editor: string;
  email: string;
  telephone: string;
  is_sparte_group: string;
}
// interface for handle edit cm data
export interface ProcessElement {
  contact_manager_id: string;
  mandantname: string;
  processes_id: string;
  subsidiaryname: string;
  cm_area_id: string;
  sparte_id: string;
  editor_id: string;
  actor: string;
  actortype: string;
  product_id: string;
  subsidiary: string;
  extern_id: string;
  creator_id: string;
  user_role_id: string;
  process: string;
  sparte: string;
  product: string;
  actortypename: string;
  cm_area: string;
  from: string;
  to: string;
  extern: string;
  editor: string;
  email: string;
  telephone: string;
  actorname: string;
  mandant: string;
  fromWhich: string;
  title: string;
  fixer_code: string;
  buttonConfirmName: string;
  buttonCancelName: string;
  is_sparte_group;
}
// get cm data api request interface
export interface SendingElement {
  division_id: string;
  actor_type_id: string;
  actor_type: string;
  actor_id: string;
  processes_id: string;
  sparte_id: string;
  product_id: string;
  role: USER_ROLES;
  subsidiary_id: any;
  is_sparte_group: boolean;
}
export interface SubSendingElement {
  divisionId: string;
  actor_type: string;
  liztu_vn_kdnr: string;
  role: USER_ROLES;
}

@Component({
  selector: 'app-contact-manager',
  templateUrl: './contact-manager.component.html',
  styleUrls: ['./contact-manager.component.css'],
})
export class ContactManagerComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  firstname: string;
  lastname: string;
  searchForm: FormGroup;
  actor_type_disable = true;
  actor_disable = true;
  subsidiary_disable = true;
  dataSource = new MatTableDataSource();
  data = [];
  division_list = [];
  showModal = false;
  public buttonName = '../../../assets/images/filter.png';
  public show = false;
  @Input('userDataValue') userDataValue;
  displayedColumns: string[] = [
    'VORGANG',
    'SPARTE',
    'PRODUKT',
    'BEREICH',
    'VON',
    'BIS',
    'EXTERN',
    'BEARBEITER',
    'EMAIL',
    'TELEFON',
    'ACTION',
  ];
  deleteItemId: any;
  userid: any;
  user_role: USER_ROLES;
  actorTypes = [];
  customers = [];
  actorTypeID: any;
  actorTypeName: any;
  division_id: any;
  divisionId: any;
  actors = [];
  sparte_array = [];
  processes = [];
  subsidiary = [];
  CMData: any[];
  message: any;
  sparteId: any;
  vorgange_name: any;
  sparte_name: any;
  processDataOBJ;
  vorgange_Id: any;
  actor_id: string;
  liztu: string;
  actor_type: any;
  actor_name: string;
  telephone: any;
  email: any;
  fixer_code: any;
  filter_data: any;
  showMessage = false;
  oneMandant = false;
  divisionName: any;
  actorTypName: any;
  actorTypeId: any;
  oneActorType = false;
  selectedParameter: any;
  selectedParameterActorType: any;
  errorMessage: string;
  addData: any;
  selectedDivision: any;
  selectedDivisionId: any;
  selectedActorType: any;
  selectedActorTypeId: any;
  selectedActor: any;
  selectedActorId: any;
  divisionPreSelected = false;
  Actor_PreSelected = false;
  Actor_type_PreSelected = false;
  subsidiary_PreSelected = false;
  divisionID: string;
  actor_type_name: any;
  liztu_vn_kdnr: any;
  subsidiary_id: any;
  subsidiaryTypeId: any;
  selectedLiztuVnKdnr: any;
  mandantname: any;
  subsidiaryId: any;
  subsidiary_name: any;
  is_sparte_group: any;
  is_sparte_group_search: any;
  actor_type_selected_name: any;
  filterToggle = true;
  customFilterExpandedHeight: string = FILTER.HEIGHT;
  isICOrCustomerUserRole = false;
  FORM_ICONS = FORM_ICONS;
  constructor(
    private serviceCall: ApiCallsService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public validationService: ValidationService,
    private readonly _userService: UserService,
    private toastService: ToastService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.user_role = this._userService.getUserRole();
    this.isICOrCustomerUserRole =
      this.user_role === USER_ROLES.CUSTOMER_USER ||
      this.user_role === USER_ROLES.IC_USER;
    if (this.userDataValue) {
      // calling prior needed api
      this.loadMandant();
      this.loadActorTyp();
      this.loadSparte();
      this.loadProcesses();
      if (this.user_role == USER_ROLES.IC_GLOBAL_ADMIN) {
        this.getCMData(null, null, null, null);
      }
      this.searchForm = this.fb.group({
        mypost: new FormControl(''),
        email: new FormControl(''),
        mandant: new FormControl('', [Validators.required]),
        actor: new FormControl('', [Validators.required]),
        subsidiary: new FormControl('', [Validators.required]),
        actorType: new FormControl('', [Validators.required]),
        sparte: new FormControl('', [Validators.required]),
        vorgange: new FormControl('', [Validators.required]),
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userDataValue) {
      this.ngOnInit();
    }
  }

  // function for fetch cm data
  getCMData(divisionid, actortypeid, actortype, actorid) {
    const cmSendingData: SendingElement = {
      division_id: divisionid,
      actor_type_id: actortypeid,
      actor_type: actortype,
      actor_id: actorid,
      subsidiary_id: null,
      processes_id: null,
      sparte_id: null,
      product_id: null,
      role: this.user_role,
      is_sparte_group: null,
    };
    this.serviceCall
      .getCMdata(cmSendingData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.formatCMdata(data);
      });
  }
  // load all the process
  loadProcesses() {
    this.serviceCall
      .getProcess()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.processes = data.processes;
      });
  }
  // load all the divisions
  loadMandant() {
    this.userid = this._userService.getUserId();
    this.telephone = this.userDataValue.user_profile.user_data.mobile_number;
    this.email = this.userDataValue.user_profile.user_data.email;
    this.serviceCall
      .getDivision(this.userid)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.division_list = data.divisions;
        // code if division list has only single value then it will be pre selected
        if (data.divisions.length == 1) {
          this.divisionPreSelected = true;
          this.actor_type_disable = false;
          this.selectedDivision = data.divisions[0].division_name;
          this.selectedDivisionId = data.divisions[0].division_id;
          this.getCMData(this.selectedDivisionId, null, null, null);
          // saving preselected data id and name
          this.savedivisionIds(this.selectedDivisionId, this.selectedDivision);
        }
      });
  }

  // actor type api call
  loadActorTyp(data?: string) {
    this.divisionID = data ? data : '';
    this.serviceCall
      .getActorTyp(this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.actorTypes = data.actor_types;

        this.subsidiary_disable = false;
        // code if list have single value inside it
        if (data.actor_types.length == 1) {
          this.Actor_type_PreSelected = true;
          this.actor_disable = false;
          this.subsidiary_disable = false;
          this.selectedActorType = data.actor_types[0].actor_type_name;
          this.selectedActorTypeId = data.actor_types[0].actorTypeId;
          this.actor_type_selected_name = data.actor_types[0].actor_type_name;
          this.getCMData(
            this.divisionID,
            this.selectedActorTypeId,
            this.selectedActorType,
            null
          );
          // saving preselected data for further use
          this.saveActorTypeId(
            this.selectedActorTypeId,
            this.selectedActorType,
            this.divisionID
          );
        }
      });
  }
  // subsidiary api call
  loadSubsidiaryTyp(data) {
    const subsidiarySendingData: SubSendingElement = {
      divisionId: this.division_id,
      actor_type: this.actor_type,
      liztu_vn_kdnr: data,
      role: this.user_role,
    };
    this.serviceCall
      .getSubsidiary(subsidiarySendingData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.customers = data.customers;
      });
  }

  // this function will call when user will select any mandant
  getMandantId(item) {
    this.searchForm.controls.actorType.reset();
    this.division_id = item.value;
    this.mandantname = item.source.triggerValue;
    this.selectedDivisionId = item.value;
    this.actor_type_disable = false;
    this.searchForm.controls.subsidiary.reset();
    this.customers = [];
    if (this.selectedActor == undefined) {
      this.searchForm.controls.actor.reset();
      this.actors = [];
    }
    if (this.selectedActorType == undefined) {
      this.searchForm.controls.actorType.reset();
    }
    // code : if any pre selected manadant is available then get actor api will call automatically
    else {
      this.actor_disable = false;
      this.subsidiary_disable = false;
      this.serviceCall
        .getActor(
          this.selectedActorType,
          this.division_id,
          this.user_role,
          this.userid
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.actors.length > 0) {
            this.message = '';
            this.actors = data.actors;
          } else {
            this.actors = [];
            this.message = 'KeinAkteur_info';
          }
        });
    }
  }
  // call when actor type will select
  actor(item) {
    this.searchForm.controls.subsidiary.reset();
    this.actor_disable = false;
    if (item.source.triggerValue == 'Kunde') {
      this.actor_type_selected_name = item.source.triggerValue;
      this.subsidiary_disable = false;
    } else {
      this.actor_type_selected_name = item.source.triggerValue;
      this.subsidiary_disable = true;
    }
    this.actor_type_disable = false;
    this.actorTypeID = item.value;
    this.actor_type = item.source.triggerValue;
    this.selectedActorTypeId = item.value;
    this.selectedActorType = item.source.triggerValue;
    this.actor_type_selected_name = item.source.triggerValue;
    // get actor api will automatically call if any preselected actor type is available
    for (let i = 0; i < this.actorTypes.length; i++) {
      if (this.actorTypes[i].actorTypeId == item.value) {
        this.actorTypeName = this.actorTypes[i].actor_type_name;

        this.serviceCall
          .getActor(
            this.actorTypeName,
            this.division_id,
            this.user_role,
            this.userid
          )
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            if (data.actors.length > 0) {
              this.message = '';
              this.actors = data.actors;
            } else {
              this.actors = [];
              this.message = 'KeinAkteur_info';
            }
          });
      }
    }
  }
  // function for saving selected division data
  savedivisionIds(id, name) {
    this.divisionId = id;
    this.division_id = id;
    this.loadActorTyp(this.divisionId);
  }
  // function for saving selected actortype data
  saveActorTypeId(id, name, division) {
    this.actorTypeID = id;
    this.actor_type = name;
    this.serviceCall
      .getActor(this.actor_type, division, this.user_role, this.userid)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.actors.length > 0) {
          this.message = '';
          this.actors = data.actors;
          if (data.actors.length == 1) {
            this.Actor_PreSelected = true;
            this.selectedActor = data.actors[0].actor_name;
            this.selectedActorId = data.actors[0].actor_id;
            this.getCMData(
              this.divisionID,
              this.actorTypeID,
              this.actor_type,
              this.selectedActorId
            );
            this.saveActorId(this.selectedActorId, this.selectedActor);
            const cmSendingData: SendingElement = {
              division_id:
                this.selectedDivisionId !== undefined
                  ? this.selectedDivisionId
                  : null,
              actor_type_id:
                this.selectedActorTypeId !== undefined
                  ? this.selectedActorTypeId
                  : null,
              actor_type:
                this.selectedActorType !== undefined
                  ? this.selectedActorType
                  : null,
              actor_id:
                this.selectedActorId !== undefined
                  ? this.selectedActorId
                  : null,
              subsidiary_id:
                this.selectedLiztuVnKdnr !== undefined
                  ? this.selectedLiztuVnKdnr
                  : null,
              processes_id: this.vorgange_Id,
              sparte_id: this.sparteId,
              product_id: null,
              role: this.user_role,
              is_sparte_group: this.is_sparte_group,
            };
            this.serviceCall
              .getCMdata(cmSendingData)
              .pipe(takeUntil(this._destroy$))
              .subscribe(data => {
                this.formatCMdata(data);
              });
          }
        } else {
          this.actors = [];
          this.message = 'KeinAkteur_info';
        }
      });

    const cmSendingData: SendingElement = {
      division_id:
        this.selectedDivisionId !== undefined ? this.selectedDivisionId : null,
      actor_type_id:
        this.selectedActorTypeId !== undefined
          ? this.selectedActorTypeId
          : null,
      actor_type:
        this.selectedActorType !== undefined ? this.selectedActorType : null,
      actor_id:
        this.selectedActorId !== undefined ? this.selectedActorId : null,
      subsidiary_id:
        this.selectedLiztuVnKdnr !== undefined
          ? this.selectedLiztuVnKdnr
          : null,
      processes_id: this.vorgange_Id,
      sparte_id: this.sparteId,
      product_id: null,
      role: this.user_role,
      is_sparte_group: this.is_sparte_group,
    };
    this.serviceCall
      .getCMdata(cmSendingData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.formatCMdata(data);
      });
  }
  // function for saving selected actor data
  saveActorId(id, name) {
    this.actor_id = id;
    this.actor_name = name;
  }
  // function for call sparte api
  loadSparte() {
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.serviceCall
      .getSparte(
        this.userDataValue.user_profile.customer_details.division_id,
        null,
        this.user_role
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.sparte_array = data.sparte;
      });
  }
  // call when user clicked on reset button
  resetFilter() {
    this.is_sparte_group_search = null;
    this.message = '';
    this.searchForm.controls.subsidiary.reset();
    this.subsidiaryId = '';
    this.subsidiary_name = '';
    this.subsidiary_id = '';
    this.subsidiary_disable = false;
    this.customers = [];
    this.searchForm.controls.sparte.reset();
    this.sparte_name = '';
    this.sparteId = '';
    this.fixer_code = '';
    this.selectedLiztuVnKdnr = '';
    this.searchForm.controls.vorgange.reset();
    this.vorgange_name = '';
    this.vorgange_Id = '';
    // condition is checking if any preselected value is available then it will remain same even after reseting the form
    if (!this.Actor_type_PreSelected) {
      this.searchForm.controls.actorType.reset();
      this.actor_type_disable = true;
      this.actor_type = '';
      this.actorTypName = '';
      this.actorTypeID = '';
      this.actorTypeId = '';
      this.actor_type_name = '';
    }
    if (!this.Actor_PreSelected) {
      this.searchForm.controls.actor.reset();
      this.actor_disable = true;
      this.actors = [];
      this.actor_id = '';
      this.actor_name = '';
    }
    if (!this.divisionPreSelected) {
      this.searchForm.controls.mandant.reset();
    } else {
      this.actor_type_disable = false;
      this.loadActorTyp(this.selectedDivisionId);
    }
  }
  // call when user clicked on filter button
  Search(search, selectedLiztuVnKdnr) {
    if (search.sparte !== null && search.sparte !== '') {
      for (let i = 0; i < this.sparte_array.length; i++) {
        if (this.sparte_array[i].fixer_code == search.sparte) {
          this.is_sparte_group_search = this.sparte_array[i].is_sparte_group;
        }
      }
    }
    this.filter_data = search;
    // data is importing into interface
    const cmSendingData: SendingElement = {
      division_id: search.mandant,
      actor_type_id: search.actorType,
      actor_type: this.actor_type,
      actor_id: search.actor,
      processes_id: search.vorgange,
      sparte_id: search.sparte,
      product_id: null,
      role: this.user_role,
      subsidiary_id: this.selectedLiztuVnKdnr,
      is_sparte_group: this.is_sparte_group_search,
    };
    this.serviceCall
      .getCMdata(cmSendingData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.formatCMdata(data);
      });
  }
  // arranging the cm data into datasorce
  formatCMdata(data: any) {
    this.CMData = [];
    if (data.contact_manager_data !== null) {
      if (data.contact_manager_data.length > 0) {
        for (let i = 0; i < data.contact_manager_data.length; i++) {
          const tableData: Element = {
            contact_manager_id: data.contact_manager_data[i].contact_manager_id,
            division_id: data.contact_manager_data[i].division_id,
            processes_id: data.contact_manager_data[i].processes_id,
            cm_area_id: data.contact_manager_data[i].cm_area_id,
            sparte_id: data.contact_manager_data[i].sparte_id,
            editor_id: data.contact_manager_data[i].editor_id,
            actor_id: data.contact_manager_data[i].actor_id,
            actor: data.contact_manager_data[i].actor,
            actor_type_id: data.contact_manager_data[i].actor_type_id,
            product_id: data.contact_manager_data[i].product_id,
            subsidiary_id: data.contact_manager_data[i].subsidiary_id,
            subsidiary_name: data.contact_manager_data[i].subsidiary_name,
            extern_id: data.contact_manager_data[i].extern_id,
            creator_id: data.contact_manager_data[i].creator_id,
            user_role_id: data.contact_manager_data[i].user_role_id,
            process: data.contact_manager_data[i].process,
            sparte: data.contact_manager_data[i].sparte,
            product: data.contact_manager_data[i].product,
            actor_type: data.contact_manager_data[i].actor_type,
            cm_area: data.contact_manager_data[i].cm_area,
            from: data.contact_manager_data[i].from,
            to: data.contact_manager_data[i].to,
            extern: data.contact_manager_data[i].extern,
            editor: data.contact_manager_data[i].editor,
            email: data.contact_manager_data[i].email,
            telephone: data.contact_manager_data[i].telephone,
            is_sparte_group: data.contact_manager_data[i].is_sparte_group,
          };

          this.CMData.push(tableData);
        }
        this.dataSource.data = this.CMData;
      }
    } else {
      this.dataSource.data = [];
    }
  }
  toggle() {
    this.show = !this.show;
    this.buttonName = '../../../assets/images/filter.png';
  }

  toggleDisp() {
    this.show = true;
    if (this.show) {
      this.buttonName = '../../../assets/images/filter.png';
    }
  }
  // call when user select actor
  saveActor(item) {
    this.actor_id = item.value;
    this.actor_name = item.source.triggerValue;
    this.selectedActor = item.source.triggerValue;
    this.selectedActorId = item.value;
    if (this.actor_type_selected_name !== 'IC') {
      this.loadSubsidiaryTyp(item.value);
    }
  }
  // call when user select process
  saveVorgange(item) {
    this.vorgange_name = item.source.triggerValue;
    this.vorgange_Id = item.value;
  }
  // call when user select sparte
  saveSparte(item) {
    this.sparte_name = item.source.triggerValue;
    this.sparteId = item.value;
    for (let i = 0; i < this.sparte_array.length; i++) {
      if (this.sparte_array[i].sparte_id == this.sparteId) {
        this.fixer_code = this.sparte_array[i].fixer_code;
        this.is_sparte_group = this.sparte_array[i].is_sparte_group;
      }
    }
  }
  // call when user edit any process
  editProcess(item, id) {
    if (
      this.user_role === USER_ROLES.IC_USER ||
      this.user_role === USER_ROLES.CUSTOMER_USER
    ) {
      this.showMessage = true;
      this.errorMessage = 'Siekönnen_info';
    } else {
      for (let i = 0; i < item.length; i++) {
        if (item[i].contact_manager_id == id) {
          for (let j = 0; j < this.division_list.length; j++) {
            if (this.division_list[j].division_id == item[i].division_id) {
              this.mandantname = this.division_list[j].division_name;
            }
          }

          const editProcess: ProcessElement = {
            contact_manager_id: item[i].contact_manager_id,
            mandantname: this.mandantname,
            subsidiaryname: item[i].subsidiary_name,
            processes_id: item[i].processes_id,
            cm_area_id: item[i].cm_area_id,
            sparte_id: item[i].sparte_id,
            editor_id: item[i].editor_id,
            actor: item[i].actor_id,
            actortype: item[i].actor_type_id,
            product_id: item[i].product_id,
            subsidiary: item[i].subsidiary_id,
            extern_id: item[i].extern_id,
            creator_id: item[i].creator_id,
            user_role_id: item[i].user_role_id,
            process: item[i].process,
            sparte: item[i].sparte,
            product: item[i].product,
            actortypename: item[i].actor_type,
            cm_area: item[i].cm_area,
            from: item[i].from,
            to: item[i].to,
            actorname: item[i].actor,
            extern: item[i].extern,
            editor: item[i].editor,
            email: item[i].email,
            telephone: item[i].telephone,
            mandant: item[i].division_id,
            fromWhich: 'edit',
            fixer_code: this.fixer_code,
            title: 'InformationenAktualisieren_title',
            buttonCancelName: 'Abbrechen_text',
            buttonConfirmName: 'Aktualisieren_text',
            is_sparte_group: item[i].is_sparte_group,
          };

          this.dialog
            .open(NewEditorComponent, {
              panelClass: 'processDialog',
              width: '800px',
              data: editProcess,
              disableClose: true,
            })
            .afterClosed()
            .subscribe(s => {
              if (!s) {
                return;
              }
              const formData = this.formatProcessData(s);
              this.serviceCall
                .postCMData(formData)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  if (!data.HasErrors) {
                    if (this.filter_data !== undefined) {
                      this.Search(this.filter_data, this.selectedLiztuVnKdnr);
                      this.toastService.showToastMessage(
                        document,
                        this.translate.instant(
                          'contact-manager.DatensatzErfolgreich_info'
                        )
                      );
                    } else {
                      const cmSendingData: SendingElement = {
                        division_id:
                          this.selectedDivisionId !== undefined
                            ? this.selectedDivisionId
                            : s.division_id,
                        actor_type_id:
                          this.selectedActorTypeId !== undefined
                            ? this.selectedActorTypeId
                            : s.actor_type_id,
                        actor_type:
                          this.selectedActorType !== undefined
                            ? this.selectedActorType
                            : s.actor_type,
                        actor_id:
                          this.selectedActorId !== undefined
                            ? this.selectedActorId
                            : s.actor_id,
                        subsidiary_id:
                          this.selectedLiztuVnKdnr !== undefined
                            ? this.selectedLiztuVnKdnr
                            : s.subsidiary_id,
                        processes_id:
                          this.vorgange_Id !== undefined
                            ? this.vorgange_Id
                            : s.processes_id,
                        sparte_id:
                          this.sparteId !== undefined
                            ? this.sparteId
                            : s.sparte_id,
                        product_id: null,
                        role: this.user_role,
                        is_sparte_group:
                          this.sparteId !== undefined
                            ? this.is_sparte_group
                            : s.is_sparte_group,
                      };
                      this.serviceCall
                        .getCMdata(cmSendingData)
                        .pipe(takeUntil(this._destroy$))
                        .subscribe(data => {
                          this.formatCMdata(data);
                        });
                    }
                  } else {
                    this.toastService.showToastMessage(
                      document,
                      this.translate.instant(
                        'contact-manager.DatensatznichtAktualisiert_info'
                      )
                    );
                  }
                });
            });
        }
      }
    }
  }
  // call when user select subsidiary
  saveSubsidiary(item) {
    this.subsidiaryId = item.value;
    this.subsidiary_name = item.source.triggerValue;
    this.selectedLiztuVnKdnr = item.value;
  }
  // call when user click on add new process button
  addNewProcess(data) {
    const firstProcess: FirstStepData = {
      mandant: data.mandant,
      actortype: data.actorType,
      actor: data.actor,
      subsidiary: data.subsidiary,
      mandantname: this.mandantname,
      actorname: this.actor_name,
      actortypename: this.actor_type,
      subsidiaryname: this.subsidiary_name,
      sparte_id: this.sparteId,
      sparte: this.sparte_name,
      processes_id: this.vorgange_Id,
      process: this.vorgange_name,
      is_sparte_group: this.is_sparte_group,
    };
    this.dialog
      .open(StepOneProcessComponent, {
        panelClass: 'processDialog',
        width: '800px',
        height: '500px',
        data: firstProcess,
      })
      .afterClosed()
      .subscribe(s => {
        if (s) {
          const formData = this.formatProcessData(s);
          this.serviceCall
            .postCMData(formData)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              if (!data.HasErrors) {
                if (this.filter_data !== undefined) {
                  this.Search(this.filter_data, this.selectedLiztuVnKdnr);
                  this.toastService.showToastMessage(
                    document,
                    this.translate.instant(
                      'contact-manager.Datensatzhinzugefügt_info'
                    )
                  );
                } else {
                  const cmSendingData: SendingElement = {
                    division_id:
                      this.selectedDivisionId !== undefined
                        ? this.selectedDivisionId
                        : null,
                    actor_type_id:
                      this.selectedActorTypeId !== undefined
                        ? this.selectedActorTypeId
                        : null,
                    actor_type:
                      this.selectedActorType !== undefined
                        ? this.selectedActorType
                        : null,
                    actor_id:
                      this.selectedActorId !== undefined
                        ? this.selectedActorId
                        : null,
                    subsidiary_id:
                      this.selectedLiztuVnKdnr !== undefined
                        ? this.selectedLiztuVnKdnr
                        : null,
                    processes_id:
                      this.vorgange_Id !== undefined ? this.vorgange_Id : null,
                    sparte_id:
                      this.sparteId !== undefined ? this.sparteId : null,
                    product_id: null,
                    role: this.user_role,
                    is_sparte_group:
                      this.sparteId !== undefined ? this.is_sparte_group : null,
                  };
                  this.serviceCall
                    .getCMdata(cmSendingData)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(data => {
                      this.formatCMdata(data);
                    });
                  this.toastService.showToastMessage(
                    document,
                    this.translate.instant(
                      'contact-manager.Datensatzhinzugefügt_info'
                    )
                  );
                }
              } else {
                this.Search(this.filter_data, this.selectedLiztuVnKdnr);
                this.toastService.showToastMessage(
                  document,
                  this.translate.instant('contact-manager.wendenSiesich_info')
                );
              }
            });
        }
      });
  }
  // formating process data after dialog close
  formatProcessData(formData: any) {
    if (!formData) {
      return;
    }
    this.processDataOBJ.subsidiary_name = formData.subsidiary;
    this.processDataOBJ.actor_id = formData.actor_id;
    this.processDataOBJ.division_id = formData.division_id;
    this.processDataOBJ.contact_manager_id = formData.contact_manager_id;
    this.processDataOBJ.processes_id = formData.processes_id;
    this.processDataOBJ.cm_area_id = formData.cm_area_id;
    this.processDataOBJ.sparte_id = formData.sparte_id;
    this.processDataOBJ.editor_id = formData.editor_id;
    this.processDataOBJ.subsidiary_id = formData.subsidiary_id;
    this.processDataOBJ.actor_type_id = formData.actor_type_id;
    this.processDataOBJ.product_id = formData.product_id;
    this.processDataOBJ.extern_id = formData.extern_id;
    this.processDataOBJ.creator_id = formData.creator_id;
    this.processDataOBJ.user_role_id = formData.user_role_id;
    this.processDataOBJ.process = formData.process;
    this.processDataOBJ.sparte = formData.sparte;
    this.processDataOBJ.product = formData.product;
    this.processDataOBJ.actor_type = formData.actor_type;
    this.processDataOBJ.cm_area = formData.cm_area;
    this.processDataOBJ.from = formData.from;
    this.processDataOBJ.to = formData.to;
    this.processDataOBJ.extern = formData.extern;
    this.processDataOBJ.editor = formData.editor;
    this.processDataOBJ.email = formData.email;
    this.processDataOBJ.telephone = formData.telephone;
    this.processDataOBJ.role = this.user_role;
    this.processDataOBJ.actor = formData.actor;
    this.processDataOBJ.is_sparte_group = formData.is_sparte_group;

    return this.processDataOBJ;
  }
  // delete function
  deleteProcessButton(item) {
    if (
      this.user_role === USER_ROLES.IC_USER ||
      this.user_role === USER_ROLES.CUSTOMER_USER
    ) {
      this.showMessage = true;
      this.errorMessage = 'Siekönnen_info';
    } else {
      this.showModal = true;
      this.deleteItemId = item;
    }
  }
  deleteProcess(item) {
    this.showModal = false;
    this.deleteItemId = item;
    this.serviceCall
      .deleteCMData(this.deleteItemId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          if (this.filter_data !== undefined) {
            this.Search(this.filter_data, this.selectedLiztuVnKdnr);
            this.toastService.showToastMessage(
              document,
              this.translate.instant(
                'contact-manager.DatensatzErfolgreichGelöscht_info'
              )
            );
          } else {
            const cmSendingData: SendingElement = {
              division_id:
                this.selectedDivisionId !== undefined
                  ? this.selectedDivisionId
                  : null,
              actor_type_id:
                this.selectedActorTypeId !== undefined
                  ? this.selectedActorTypeId
                  : null,
              actor_type:
                this.selectedActorType !== undefined
                  ? this.selectedActorType
                  : null,
              actor_id:
                this.selectedActorId !== undefined
                  ? this.selectedActorId
                  : null,
              subsidiary_id:
                this.selectedLiztuVnKdnr !== undefined
                  ? this.selectedLiztuVnKdnr
                  : null,
              processes_id: this.vorgange_Id,
              sparte_id: this.sparteId,
              product_id: null,
              role: this.user_role,
              is_sparte_group: this.is_sparte_group,
            };
            this.serviceCall
              .getCMdata(cmSendingData)
              .pipe(takeUntil(this._destroy$))
              .subscribe(data => {
                this.formatCMdata(data);
              });
            this.toastService.showToastMessage(
              document,
              this.translate.instant(
                'contact-manager.DatensatzErfolgreichGelöscht_info'
              )
            );
          }
        }
      });
  }
  hideModal() {
    this.showModal = false;
  }
  getSparteNamesList(data): string {
    return data.join('\n');
  }
}
