import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum TaskStatuses {
  COMPLETED = 100,
  IN_PROGRESS = 50,
  TODO = 0,
}

export const TASK_STATUS_LABELS = {
  [TaskStatuses.COMPLETED]: 'tasks.completed',
  [TaskStatuses.IN_PROGRESS]: 'tasks.in_progress',
  [TaskStatuses.TODO]: 'tasks.todo',
};

@Component({
  selector: 'app-status-column',
  template: `<div
    class="status"
    [class.status--completed]="element.status === statuses.COMPLETED"
    [class.status--progress]="element.status === statuses.IN_PROGRESS">
    {{ labels[element.status] | translate }}
  </div>`,
  styles: [
    `
      .status {
        padding: 3px 10px;
        color: var(--main-yellow);
        background: var(--light-yellow);
        width: 100px;
        text-align: center;
      }

      .status--completed {
        color: var(--main-green);
        background: var(--light-green);
      }

      .status--progress {
        color: var(--main-blue);
        background: var(--light-blue);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  @Input() element: { status: TaskStatuses };
  statuses = TaskStatuses;
  labels = TASK_STATUS_LABELS;
}
