<app-header (emitUserData)="userDataReceived($event)"></app-header>
<div class="container-ic">
  <div class="main-section">
    <div>
      <h6 style="    color: #272727;    white-space: nowrap;    width: 100%;    display: inline-block;        "
        class="user-name top-heading">
        <strong style="width: 80%;    white-space: break-spaces;    display: inline-block;">
          <span
            *ngIf="isICUser">{{
            customerName }}&nbsp;&nbsp; <span>/</span>&nbsp;&nbsp; </span>
          {{ data.customer_name }}&nbsp;&nbsp; <span>/</span>&nbsp;&nbsp; {{data.last_name}}&nbsp;{{data.first_name}}
        </strong>
        <div style="display: inline; float: right; margin-top: -6px;">
          <button class="save"
            (click)="saveCustomer(data, sparte, selectedCustomerLiztuVnKdnr)"
            *ngIf="resetUser == false">
            {{'update-customer.Speichern_text' | translate}}
          </button>
          <button class="save"
            (click)="saveCustomer(data, sparte, selectedCustomerLiztuVnKdnr)"
            *ngIf="resetUser == true">
            {{'invite-user.SpeichernUndEinladen_text' | translate}}
          </button>
        </div>
      </h6>
      <div style="width: 100%; margin-top: 1%;clear: both;">
        <div class="content-panel">
          <table class="table table-stripped" style="margin-bottom: 0px;" #main>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr class="border-bottom">
                <mat-accordion>
                  <mat-expansion-panel (opened)="userInfoToggle = true" (closed)="userInfoToggle = false"
                    [expanded]="userInfoToggle" hideToggle>
                    <mat-expansion-panel-header class="border-bottom">
                      <mat-panel-title>
                        <button mat-icon-button *ngIf="userInfoToggle == true" class="arrow-size">
                          <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="userInfoToggle == false" class="arrow-size">
                          <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                        </button>
                        <span style="margin-left: 10px;font-weight: 600;">
                          {{'update-customer.Nutzerdetails_text' | translate}}
                        </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div id="update">
                      <div class="row" style="padding-top: 30px;">
                        <div class="col-md-3 col-sm-6 col-6">
                          <div style="display: flex; float: left; width: 100%;">
                            <mat-form-field [ngClass]="{'red': salutation === true}" appearance="fill"
                              style="width: 20%;line-height: 0.6;margin-right: 9px;padding: 4px 0px 0px 0px;">
                              <mat-label>
                                <span
                                  *ngIf="salutation == false && salutationModel.untouched">{{'update-user.title_label' |
                                  translate}}</span>
                                <span class="touched-warning"
                                  *ngIf="salutation == true && salutationModel.untouched">{{'update-user.title_label' |
                                  translate }}</span>
                                <span *ngIf="salutationModel.touched">
                                  <span *ngIf="salutationModel.valid">{{ 'update-user.title_label' | translate}}</span>
                                  <span class="touched-warning"
                                    *ngIf="salutationModel.invalid">{{'update-user.title_label' | translate }}</span>
                                </span>
                              </mat-label>
                              <mat-select [(ngModel)]="data.salutation" #salutationModel="ngModel"
                                placeholder="{{ 'update-user.Frau_text' | translate }}" required>
                                <mat-option value="{{'update-user.Frau_text' | translate }}">{{'update-user.Frau_text' |
                                  translate }}
                                </mat-option>
                                <mat-option value="{{'update-user.Herr_text' | translate}}">{{'update-user.Herr_text' |
                                  translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field [ngClass]="{'red': firstname === true}" appearance="fill"
                              style="width: 80%;padding: 4px 0px 0px 0px;">
                              <mat-label>
                                <span *ngIf="firstname == false">{{ 'update-user.Vorname_label' | translate}}</span>
                                <span class="touched-warning" *ngIf="firstname == true">{{Vorname_validation |
                                  multilingual | translate}}</span>
                              </mat-label>
                              <input (blur)="validateFirstname($event.target.value)" class="input" required matInput
                                     [(ngModel)]="data.first_name">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field [ngClass]="{'red': lastname === true}" appearance="fill"
                            class="division-element">
                            <mat-label>
                              <span *ngIf="lastname == false">{{ 'update-user.Nachname_label' | translate}}</span>
                              <span class="touched-warning" *ngIf="lastname == true">{{Nachname_validation |
                                multilingual | translate}}</span>
                            </mat-label>
                            <input matInput (blur)="validateLastname($event.target.value)" class="input" required
                                   [(ngModel)]="data.last_name">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field appearance="fill" class="tel"
                            style="width: 100%;float:left;border:none; margin-bottom: 53px;">
                            <mat-label>
                              <span>
                                {{'update-user.Mobiltelefonnummer_label' | translate }}
                              </span>
                            </mat-label>
                            <ngx-mat-intl-tel-input [ngClass]="'ngx-floating'"
                              (countryChanged)="CountryChangedEvent($event)" class="input floating"
                              [preferredCountries]="['de']" (keyup)="validatePhoneNumber($event)"
                              [enablePlaceholder]="true" [(ngModel)]="data.Telefon" #telefon="ngModel" name="tele"
                              [enableSearch]="true" #phone (change)="mobileChange(data.Telefon)">
                            </ngx-mat-intl-tel-input>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field [ngClass]="{'red': email === true , 'redcolor' : emailunique === true}"
                            appearance="fill" class="division-element">
                            <mat-label>
                              <span *ngIf="email == false && emailunique == false">{{'update-user.Email_label' |
                                translate}}</span>
                              <span class="touched-warning"
                                *ngIf="email == true && emailunique == false">{{email_validation | multilingual |
                                translate}}</span>
                              <span class="touched-warning" *ngIf="emailunique == true && email == false">E-Mail
                                :{{emailvalidation}}</span>
                            </mat-label>
                            <input matInput type="email" placeholder="" (blur)="validateEmail($event.target.value)"
                              class="input" required [(ngModel)]="data.email" [disabled]="!resetUser">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field [ngClass]="{'red': customer === true}" class="role" appearance="fill"
                            class="division-element">
                            <mat-label>
                              <span *ngIf="customer == false && customerModel.untouched">{{'update-user.UNTERNEHMEN_label'
                                | translate}}</span>
                              <span class="touched-warning"
                                *ngIf="customer == true && customerModel.untouched">{{'update-user.UNTERNEHMEN_label' |
                                translate }}</span>
                              <span *ngIf="customerModel.touched">
                                <span *ngIf="customerModel.valid">{{ 'update-user.UNTERNEHMEN_label' | translate}}</span>
                                <span class="touched-warning"
                                  *ngIf="customerModel.invalid">{{'update-user.UNTERNEHMEN_label' | translate }}</span>
                              </span>
                            </mat-label>
                            <mat-select [(ngModel)]="data.customer_name" #customerModel="ngModel" required
                              [disabled]="!resetUser">
                              <mat-option *ngFor="let customer of customers" [value]="customer.name">
                                {{customer.name}} - {{customer.type | uppercase}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field appearance="fill" class="division-element">
                            <mat-label>{{ 'update-user.Division_label' | translate }}</mat-label>
                            <input matInput placeholder="" class="input" required matInput [(ngModel)]="data.division"
                              disabled>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="role" appearance="fill" class="division-element">
                            <mat-label>{{ 'update-user.Benutzerrolle_label' | translate }}</mat-label>
                            <mat-select [(ngModel)]="data.role_id"
                              [disabled]="(mutterCustomer == true || resetUser == false) ? true : false">
                              <mat-option *ngFor="let role of User_role_list" [value]="role.user_role_id">
                                {{role.user_role_name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field appearance="fill" class="division-element language">
                            <mat-label>{{ 'update-user.Sprache_label' | translate }}</mat-label>
                            <mat-select [(ngModel)]="data.invitation_language"
                              [ngClass]="data.invitation_language == 'German(DE)' ? 'german' : 'english'"
                              [disabled]="!resetUser">
                              <mat-option *ngFor="let language of data.supported_languages" [value]="language">
                                <span *ngIf="language == 'German(DE)'" class="german">
                                  <span style="margin-left:36px;font-size: 15px;">{{language}}</span>
                                </span>
                                <span *ngIf="language == 'English(EN)'" class="english">
                                  <span style="margin-left:36px;font-size: 15px;">{{language}}</span>
                                </span>
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="language d-flex address-field" style="position: relative;"
                            appearance="fill" class="division-element">
                            <mat-label>{{'update-customer.Adresse_label' | translate}}</mat-label>
                            <input type="text" matInput name="location-list" #loc id="location"
                              (focus)="showList = true" (focusout)="onFocusOut()" (keyup)="keyUp.next($event)"
                              [(ngModel)]="data.address || selectedLoc" class="input addressInput" required
                              autocomplete="off" [disabled]="customerRoles">
                            <ul class="statusFilter" *ngIf="showList && filterlocation.length">
                              <li class="listStyle" *ngFor="let location of filterlocation;index as i"
                                (click)="selectedLocation(i)">
                                {{location.freeformAddress}}
                              </li>
                            </ul>
                            <span class="flagIconContainer" *ngIf="showActive == false">
                              <img alt="" class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg" height="25px">
                            </span>
                            <span class="flagIconContainer" *ngIf="showActive == true">
                              <img alt="" class="imgStyle" src="../../../assets/images/ic-icons/Geolocation.svg" height="25px">
                            </span>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field [ngClass]="{'red': street === true}" appearance="fill"
                            class="division-element">
                            <mat-label>
                              <span *ngIf="street == false">{{ 'update-user.Straße_label' | translate }}</span>
                              <span class="touched-warning" *ngIf="street == true"> {{strabe | multilingual |
                                translate}}</span>
                            </mat-label>
                            <input matInput (blur)="validateStreet($event.target.value)" class="input"
                                   required [(ngModel)]="data.street" (change)="onaddressChange()"
                                   [disabled]="customerRoles">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field [ngClass]="{'red': house === true}" appearance="fill"
                            class="division-element">
                            <mat-label>
                              <span *ngIf="house == false">{{ 'update-user.Hausnummer_label' | translate }}</span>
                              <span class="touched-warning" *ngIf="house == true">{{Hausnummer_validation | multilingual
                                | translate}}</span>
                            </mat-label>
                            <input (blur)="validateHouseNumber($event.target.value)" class="input" required
                                   matInput [(ngModel)]="data.house" [disabled]="customerRoles">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field [ngClass]="{'red': zip === true}" appearance="fill" class="division-element">
                            <mat-label>
                              <span *ngIf="zip == false">{{ 'update-user.Postleitzahl_label' | translate }}</span>
                              <span class="touched-warning" *ngIf="zip == true">{{postalCodeValidation | multilingual
                                | translate}}</span>
                            </mat-label>
                            <input (blur)="validatePostalCode($event.target.value,data.country)" class="input" required
                                   matInput [(ngModel)]="data.zip_code" (change)="onaddressChange()"
                                   [disabled]="customerRoles">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field [ngClass]="{'red':city === true}" appearance="fill" class="division-element">
                            <mat-label>
                              <span *ngIf="city == false">{{ 'update-user.Stadt_label' | translate }}</span>
                              <span class="touched-warning" *ngIf="city == true">{{Stadt_validation | multilingual |
                                translate}}</span>
                            </mat-label>
                            <input (blur)="validateTown($event.target.value)" class="input" required matInput
                                   [(ngModel)]="data.city" (change)="onaddressChange()" [disabled]="customerRoles">
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="country" appearance="fill" class="division-element">
                            <mat-label>{{ 'update-user.Land_label' | translate }}</mat-label>
                            <mat-select (selectionChange)="onCountrySelected($event.value)" [(ngModel)]="data.country"
                                        (blur)="validateCountry($event.target.value)" (selectionChange)="onaddressChange()"
                                        [disabled]="customerRoles">
                              <mat-option class="con-option" *ngFor="let country of countries$ | async" [value]='country.countryName'>
                                {{country.countryName}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6">
                          <mat-form-field class="country" appearance="fill" class="division-element">
                            <mat-label>
                              {{'update-user.OTP_label' | translate}}
                            </mat-label>
                            <mat-select [(ngModel)]="data.otpTo"
                              [disabled]="!resetUser || data.Telefon == '' ? true : false">
                              <mat-option class="con-option" *ngFor="let otpOption of otpTo" [value]='otpOption'>
                                {{otpOption}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="col-md-3 col-sm-6 col-6"></div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-12 col-sm-12">
                          <section style="width: 100%;float:right;font-size: 12px; margin-top: 0px;" id="checkbox">
                            <mat-checkbox style="float:left;width:32%;margin-top: -20px;padding-left: 2px;color:rgb(116 106 106);
                font-weight: 600;
                font-size: 13px;" [(ngModel)]="data.is_group" (change)="isGroupChange()">
                              {{'invite-user.Gruppenauswahl_text' | translate}}
                            </mat-checkbox>
                          </section>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </tr>
              <tr class="border-bottom">
                <mat-accordion>
                  <mat-expansion-panel (opened)="contractDetailsToggle = true" (closed)="contractDetailsToggle = false"
                    [expanded]="contractDetailsToggle" hideToggle>
                    <mat-expansion-panel-header class="border-bottom">
                      <mat-panel-title>
                        <button mat-icon-button *ngIf="contractDetailsToggle == true" class="arrow-size">
                          <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="contractDetailsToggle == false" class="arrow-size">
                          <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                        </button>
                        <span style="margin-left: 10px;font-weight: 600;">
                          {{'update-customer.ZugriffsrechteaufVerträge_text' | translate}}
                        </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p style="padding-top: 15px; font-weight: 600;">
                      {{'update-customer.Hauptkommunikationsadresse_text' | translate}}
                    </p>
                    <mat-dialog-content id="edit-dropdown">
                      <mat-form-field appearance="fill" class="companyDropdown">
                        <mat-select style="font-size: 13px; color: gray;" placeholder="Tochterunternehmen"
                          [value]="lastSelectedCustomer" (click)="customerClick(sparte,selectedCustomerLiztuVnKdnr)"
                          id="companySection">
                          <mat-option class="con-option" *ngFor="let cust of customers" class="hide-checkbox"
                            [value]="cust.name" (click)="openUnsavedDialog == false && customerChange(cust)"
                            [disabled]="openUnsavedDialog == true"
                            [style.display]="customerDropdownVisible == true ? 'flex' : 'none'">
                            <span class="listLabel" id="dropdownCheckbox"
                              [style.display]="onlyOneCustomer ? 'none' : 'initial' ">
                              <input style="cursor: pointer;" type="checkbox" class="checkField" disabled
                                (change)="CheckAllOptions(sparte)" [checked]="cust.is_selected">
                              <span class="mark" *ngIf="!onlyOneCustomer"></span>
                            </span>
                            {{cust.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </mat-dialog-content>
                    <div class="accordion-section" style="width: 100%;min-height: inherit;max-height: 435px;">
                      <table class="table table-striped table-borderless">
                        <thead class="stickyTop">
                          <tr style="background-color: #dddddd;">
                            <th scope="col" style="padding-left: 60px;">
                              <span
                                *ngIf="lastSelectedCustomer !== 'null' || lastSelectedCustomer !== null || lastSelectedCustomer !== undefined">
                                {{lastSelectedCustomer}}</span>
                              <span
                                *ngIf="lastSelectedCustomer === 'null' || lastSelectedCustomer === null || lastSelectedCustomer === undefined">
                                {{'invite-user.Spartenname_text' | translate}}</span>
                              <button (click)="SaveCustomer(selectedCustomerLiztuVnKdnr)"
                                style="border: none; float: right; background-color: #ff9000;padding: 4px 7px;margin: -4px 0px 0px 7px;color: #272727;font-weight: 600;">
                                {{'update-customer.Speichern_text' | translate}}
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="height: 48px;">
                            <label style="cursor: pointer;"
                              class="listLabel selectAll">{{'update-customer.SelectAllContracts_label' | translate}}
                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                (change)="checkAllContractsForCustomer(sparte)"
                                [checked]="isCheckedAllContractsForThisCustomer">
                              <span class="mark"></span>
                            </label>
                            <label style="cursor: pointer; float: right;" class="listLabel selectAll">
                              <span>{{'update-customer.SelectAllFutureContract_label' | translate}}</span>
                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                (change)="checkAllFutureContractsForCustomer(sparte)"
                                [checked]="isCheckedAllFutureContractsForThisCustomer">
                              <span class="mark"></span>
                            </label>
                          </tr>
                          <tr *ngFor="let sparte of sparte">
                            <mat-accordion>
                              <mat-expansion-panel (opened)="sparte.panelOpenState = true"
                                (closed)="sparte.panelOpenState = false" hideToggle>
                                <mat-expansion-panel-header>
                                  <mat-panel-title #arrow>
                                    <button mat-icon-button *ngIf="sparte.panelOpenState" class="arrow-size">
                                      <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                                    </button>
                                    <button mat-icon-button *ngIf="!sparte.panelOpenState" class="arrow-size">
                                      <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                                    </button>
                                    <span
                                      style="margin-left: 10px;font-weight: 600;color: #464646;">{{sparte.sparte}}</span>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <table class="table" style="margin-top: 5px;">
                                  <thead>
                                    <tr class="inner-table-row">
                                      <th>
                                        <div class="row">
                                          <div class="col-md-4 col-sm-4 col-4">
                                            <label style="cursor: pointer;"
                                              class="listLabel">{{'invite-user.Product_name_text' | translate}}
                                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                                (change)="CheckAllOptions(sparte)" [checked]="sparte.isCheckedAll">
                                              <span class="mark"></span>
                                            </label>
                                          </div>
                                          <div class="col-md-3 col-sm-3 col-3">
                                            <label style="cursor: pointer;"
                                              class="listLabel">{{'invite-user.Verträge_text' | translate}}
                                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                                (change)="CheckAllOptions(sparte)" [checked]="sparte.isCheckedAll">
                                            </label>
                                          </div>
                                          <div class="col-md-2 col-sm-2 col-2">
                                            <label style="cursor: pointer;"
                                              class="listLabel">{{'invite-user.Object_text' | translate}}
                                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                                (change)="CheckAllOptions(sparte)" [checked]="sparte.isCheckedAll">
                                            </label>
                                          </div>
                                          <div class="col-md-3 col-sm-3 col-3">
                                            <span>
                                              <label style="cursor: pointer;" class="listLabel custom-ellipsis"
                                                title="{{'invite-user.Zukunft_umfassen_text'| translate}}">
                                                {{'invite-user.Zukunft_umfassen_text'| translate}}
                                                <input style="cursor: pointer;" type="checkbox" class="checkField"
                                                  (click)="includeFutureContractsClick(sparte)"
                                                  [checked]="sparte.isFutureSelected">
                                                <span class="mark"></span>
                                              </label>
                                            </span>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let contract of sparte.contracts">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-4 col-sm-4 col-4">
                                            <label style="cursor: pointer;font-weight: 600;color: #464646;"
                                              class="listLabel custom-ellipsis"
                                              title="{{contract.product_name}}">{{contract.product_name}}
                                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                                [checked]="contract.is_authorised"
                                                (change)="contractClick(contract,sparte)">
                                              <span class="mark"></span>
                                            </label>
                                          </div>
                                          <div class="col-md-3 col-sm-3 col-3">
                                            <label style="cursor: pointer;font-weight: 600;color: #464646;"
                                              class="listLabel custom-ellipsis"
                                              title="{{contract.contract_number}}">{{contract.contract_number}}
                                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                                [checked]="contract.is_authorised"
                                                (change)="contractClick(contract,sparte)">
                                            </label>
                                          </div>
                                          <div class="col-md-5 col-sm-5 col-5">
                                            <label style="cursor: pointer;font-weight: 600;color: #464646;"
                                              class="listLabel custom-ellipsis" title="{{contract.object}}">{{contract.object}}
                                              <input style="cursor: pointer;" type="checkbox" class="checkField"
                                                [checked]="contract.is_authorised"
                                                (change)="contractClick(contract,sparte)">
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </tr>
              <tr>
                <mat-accordion>
                  <mat-expansion-panel (opened)="widgetConfogurationToggle = true"
                    (closed)="widgetConfogurationToggle = false" [expanded]="widgetConfogurationToggle" hideToggle>
                    <mat-expansion-panel-header class="border-bottom">
                      <mat-panel-title>
                        <button mat-icon-button *ngIf="widgetConfogurationToggle == true" class="arrow-size">
                          <mat-icon class="arrowButtons">keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="widgetConfogurationToggle == false" class="arrow-size">
                          <mat-icon class="arrowButtons">keyboard_arrow_right</mat-icon>
                        </button>
                        <span style="margin-left: 10px; font-weight: 600;">
                          {{'update-customer.WidgetVerwaltung_text' | translate}}
                        </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-dialog-content>
                      <div class="widget-section">
                        <div class="col-md-6 col-sm-6 col-6" style="padding:0px;padding-right:5px;">
                          <div class="widget-section-heading">
                            {{'invite-user.DeaktivierteWidgets_text' | translate}}
                          </div>
                          <div *ngFor="let widget of widgets$ | async" class="widget-section-card">
                            <div class="col-md-8 col-sm-10 col-10" style="padding:0px;">
                              <input [value]="widget.name" disabled class="widget-section-input">
                            </div>
                            <div class="col-md-4 col-sm-2 col-2" style="padding-left: 0px;">
                              <span>
                                <button class="addRemove" (click)="addWidget(widget.id)">+</button>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6" style="padding:0px;padding-left:5px">
                          <div class="widget-section-heading">
                            {{'invite-user.AusgewählteWidget_text' | translate}}
                          </div>
                          <div *ngFor="let widget of selectedWidgets$ | async" class="widget-section-card">
                            <div class="col-md-8 col-sm-10 col-10" style="padding:0px;">
                              <input [value]="widget.name" disabled class="widget-section-input">
                            </div>
                            <div class="col-md-4 col-sm-2 col-2" style="padding-left: 0px;">
                              <span>
                                <button 
                                  class="addRemove" 
                                  (click)="removeWidget(widget.id, widget.widgetAuthorizationId)">-</button>
                              </span>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </mat-dialog-content>
                  </mat-expansion-panel>
                </mat-accordion>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="saveDivision">
          <button class="save" (click)="saveCustomer(data, sparte, selectedCustomerLiztuVnKdnr)"
            *ngIf="resetUser == false">
            {{'update-customer.Speichern_text' | translate}}
          </button>
          <button class="save" (click)="saveCustomer(data, sparte, selectedCustomerLiztuVnKdnr)"
            *ngIf="resetUser == true">
            {{'invite-user.SpeichernUndEinladen_text' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-present bottom="10vh" right="10px" *ngIf="customerRoles"></app-present>
</div>
<div class="modal modelBackground" id="myModal" [style.display]="openUnsavedDialog ? 'table' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content popup-structure" style="width: 544px;">
      <div class="modal-header">
        <span style="font-size: 20px;font-weight: 600;">{{'save-changes.Nicht_gespeicherte' | translate}}</span>
        <button type="button" class="close" style="outline: none;" data-dismiss="modal"
          (click)="hideUnsavedDialog()">&times;</button>
      </div>
      <div class="modal-body" style="font-size:12px; overflow: auto;">
        <div class="row">
          <div class="col-md-2">
            <img alt="" src="../../../assets/images/Path 91988 (1).svg" >
          </div>
          <div class="col-md-10" style="line-height: 1.3; text-align: initial;">
            <p style="font-size: 20px;font-weight: 600;"> {{'save-changes.Nicht_gespeicherte' | translate}}
              <br>
              <span style="font-weight: 600;font-size: 15px;">{{'save-changes.unsaved_changes_message'| translate}}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="justify-content: center; border-top: none;">
        <button type="button" class="cancel-button" mat-button (click)="hideUnsavedDialog()"> {{'user-data.Nein' |
          translate}} </button>
        <button class="ml-2 save-button" type="button" (click)="SaveCustomer(selectedCustomerLiztuVnKdnr)" mat-button>
          {{'update-customer.Speichern_text' | translate}}</button>
      </div>
    </div>
  </div>
</div>
