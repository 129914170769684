export const TASKS_COLUMN_HEADINGS = {
  TITLE: 'tasks.task_name',
  DESCRIPTION: 'tasks.description',
  PRIORITY: 'tasks.priority',
  DIVISION: 'tasks.division',
  DUE_DATE: 'tasks.due_date',
  ASSIGNED_TO: 'tasks.assigned_to',
  STATUS: 'tasks.status',
  CREATED_BY: 'tasks.created_by',
  UPDATED_BY: 'tasks.updated_by',
  ACTION: 'tasks.action',
};

export enum TasksColumns {
  TITLE = 'title',
  DESCRIPTION = 'description',
  PRIORITY = 'priority',
  DIVISION = 'divisionName',
  DUE_DATE = 'dueDate',
  ASSIGNED_TO = 'assignedTo',
  STATUS = 'status',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  ACTION = 'action',
}

export const TASKS_COLUMNS = [
  TasksColumns.TITLE,
  TasksColumns.DESCRIPTION,
  TasksColumns.PRIORITY,
  TasksColumns.DUE_DATE,
  TasksColumns.ASSIGNED_TO,
  TasksColumns.STATUS,
  TasksColumns.CREATED_BY,
  //   TasksColumns.UPDATED_BY,
  TasksColumns.ACTION,
];
