import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SalutationService {
  getSalutation(id, language) {
    if (id === 1) {
      if (language === 'German(DE)') {
        return 'Frau';
      } else if (language === 'English(EN)') {
        return 'Mrs';
      }
    } else if (id === 2) {
      if (language === 'German(DE)') {
        return 'Herr';
      } else if (language === 'English(EN)') {
        return 'Mr';
      }
    } else {
      return null;
    }
  }

  getSalutationId(salutation, language) {
    if (salutation === 'Frau' || salutation === 'Mrs') {
      return 1;
    } else if (salutation === 'Herr' || salutation === 'Mr') {
      return 2;
    } else {
      return null;
    }
  }
}
