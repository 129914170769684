export interface ADD_COMMUNICATION_REQUEST {
    id: number;
    contact: any;
    description: string;
    communication_type: string;
    communication_type_id: number;
    source_id: number;
    source_type: string;
    updated_by?: number;
}
export const API_ENDPOINT_URL = {
    EDIT_COMMUNICATION_DATA: '/api/v1/communication_details',
    GET_COMMUNICATION_DATA: '/api/v1/communication_details'
};
