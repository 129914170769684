import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { UploadTypeConstants } from '@shared/constants/building.constants';
import { GenericCriteriaConstants } from '@shared/constants/generic.constants';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { BaseComponent } from '@shared/components/base.component';
import { ValidationSharedService } from '@core/services/validation-shared.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { LOCALS } from '@shared/constants/local.constants';
import { ObjectModuleSharedService } from '../services/object-module-shared.service';

@Component({
  selector: 'app-machine-plants-form-mobi',
  templateUrl: './machine-plants-form-mobi.component.html',
  styleUrls: ['./machine-plants-form-mobi.component.css'],
})
export class MachinePlantsFormMobiComponent
  extends BaseComponent
  implements OnInit
{
  yearControl = new FormControl();
  yearControl1 = new FormControl();
  machinePlantsForm: FormGroup;
  years = [];
  valueTypes: any = [];
  constructionTypes: any = [];
  objectSystem = [];
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();

  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  @Input('object_data') object_data;
  @Input('selectedLiztu') selectedLiztu;
  currentYear: number;
  errorNewValue = false;
  errorWert = false;
  formError = 'false';
  filteredOptions;
  tempVal: any;
  actualnumber = 0;
  actualneuValue = 0;
  errorNewValue1 = false;
  yearsCon: any[];
  selectLang: LOCALS;
  encoded_liztu_vn_kdnr: any;
  liztu_vn_kdnr: string;
  ObjectTypes: any;
  object_type_id: any;
  sequence_number: any;
  browser: string;
  tooltipTest: any;
  currencyTypes: any = [];
  currancySymbol = null;
  currencyType = new FormControl();
  currencyTypetemp: any;
  selectedCurrency: any;
  validations;
  @ViewChild('tempControl', { static: true }) tempControl: MatSelect;
  @ViewChild('yearOfManufacture', { static: true }) manufacturerYear: MatSelect;
  @ViewChild('currencyDropdown', { static: true }) currencyDropdown: MatSelect;
  yearlyFactor = 25100;
  thisYear = new Date().getFullYear();
  lastYear;
  uploadObjectDetails: UploadObjectDetails;
  selectedDocumentsArray = {};
  selectedFireDocumentsArray = {};
  selectedAcquisitionDocumentsArray = {};
  selectedRiskDocumentsArray = {};
  showFireDocumentPopup = false;
  showAcquisitionDocumentPopup = false;
  showRiskDocumentPopup = false;
  fireDocsIds = [];
  acquisitionDocsIds = [];
  riskDocsIds = [];

  constructor(
    private serviceCall: ApiCallsService,
    private route: ActivatedRoute,
    private _currencyCustomPipe: CurrencyCustomPipe,
    public validation: ValidationService,
    private _toastService: ToastService,
    private readonly _userService: UserService,
    private readonly _validationSharedService: ValidationSharedService,
    private readonly _objectModuleSharedService: ObjectModuleSharedService
  ) {
    super();
    this.lastYear = this.thisYear - 1;
    this.browser = this.detectBrowser();
    const year = new Date().getFullYear();
    this.currentYear = year;
    this.yearsCon = [];
    this.years = [];
    this.serviceCall
      .getYearsData()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.years = data.factor_years;
          this._objectModuleSharedService.saveYears(this.years);
          if (!this.object_data) {
            this.machinePlantsForm.patchValue({
              newValuePerYear: this.years[this.years.length - 1],
            });
          }
        }
      });

    this.serviceCall
      .getCurrencyTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (null !== data.currency_types) {
          this.currencyTypes = data.currency_types;
          this.currencyTypetemp = this.currencyTypes;
          if (
            this.object_data &&
            this.object_data.criteria_response &&
            this.object_data.criteria_response.jsonb
          ) {
            const parsedResp = JSON.parse(
              this.object_data.criteria_response.jsonb
            );
            if (parsedResp && parsedResp.currency_type_id) {
              this.currancySymbol = this.currencyTypes.find(
                obj => obj.id == parsedResp.currency_type_id
              ).cur_code;
              this.selectedCurrency = this.currencyTypes.find(
                obj => obj.id == parsedResp.currency_type_id
              ).cur_symbol;
            }
          } else {
            this.currancySymbol = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).cur_code;
            this.selectedCurrency = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).cur_symbol;
            const tempId = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).id;
            this.machinePlantsForm.controls.currency_type_id.setValue(tempId);
          }
        }
      });
    this.currencyType.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterCurrency(value);
      });
    this.initializeForm();
    this.yearControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filter(value);
      });
    this.yearControl1.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filtercon(value);
      });
  }
  currancyChanged(event) {
    this.currancySymbol = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_code;
    this.selectedCurrency = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => this.currencyDropdown.focus(), 150);
  }
  private _filterCurrency(value: any) {
    this.currencyTypes = this.currencyTypetemp;
    if (value !== '') {
      if (
        this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.currencyTypes = this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.currencyTypes = this.currencyTypetemp;
      }
    } else {
      this.currencyTypes = this.currencyTypetemp;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm) {
      this.onFormReset();
    }
    if (this.machinePlantsForm && changes.isSubmitClicked !== undefined) {
      this.machinePlantsForm.reset();
    }
    if (this.machinePlantsForm && changes.objectType) {
      this.machinePlantsForm.patchValue({
        objectType: changes.objectType.currentValue,
      });
      this.browser = this.detectBrowser();
      this.initializeForm();
    }
  }

  onFormReset() {
    this.selectedFireDocumentsArray = {};
    this.selectedAcquisitionDocumentsArray = {};
    this.selectedRiskDocumentsArray = {};
    this.selectedDocumentsArray = {};
    this.initializeForm();
  }

  ngOnInit(): void {
    this._watchOnLocale();
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu_vn_kdnr = atob(this.encoded_liztu_vn_kdnr);
    const year = new Date().getFullYear();
    for (let i = 100; i >= 1; i--) {
      this.yearsCon.push(year - i);
    }
    this.yearsCon.push(year);
    this.yearsCon.push(year + 1);
    this._objectModuleSharedService.saveYearsCon(this.yearsCon);

    if (this.selectedLiztu !== undefined) {
      this.CompulsoryAPICalls(this.selectedLiztu);
    } else {
      this.CompulsoryAPICalls(this.liztu_vn_kdnr);
    }

    this.initializeForm();
    this.browser = this.detectBrowser();
    this.machinePlantsForm
      .get('constructionYear')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filtercon(value);
      });
  }

  CompulsoryAPICalls(liztu) {
    this.serviceCall
      .getObjectType('')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.object_types !== null) {
          this.ObjectTypes = data.object_types;
          if (!this.object_data) {
            for (let i = 0; i < this.ObjectTypes.length; i++) {
              if (this.ObjectTypes[i].object_name == this.objectType) {
                this.object_type_id = this.ObjectTypes[i].object_type_id;
                this.serviceCall
                  .getSequenceNumber(
                    liztu,
                    this.object_type_id,
                    GenericCriteriaConstants[13]
                  )
                  .pipe(takeUntil(this._destroy$))
                  .subscribe(data => {
                    if (data.HasErrors == false) {
                      this.sequence_number = data.sequence_number;
                      this.machinePlantsForm.controls.sequenceNumber.setValue(
                        this.sequence_number
                      );
                    }
                  });
              }
            }
          }
          for (let i = 0; i < this.ObjectTypes.length; i++) {
            if (this.ObjectTypes[i].object_name == this.objectType) {
              this.object_type_id = this.ObjectTypes[i].object_type_id;
              this.serviceCall
                .getExtinguishingSystems(this.object_type_id)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  if (!data.HasErrors) {
                    this.objectSystem = data.extinguishing_systems;
                  }
                });
            }
          }
        }
      });
  }
  _filter(value: string) {
    this.years = this._objectModuleSharedService.getYears();
    if (value !== '') {
      this.years =
        this.years.filter(option => option.toString().includes(value) === 0) !==
        null
          ? (this.years = this.years.filter(option =>
              option.toString().includes(value)
            ))
          : this._objectModuleSharedService.getYears();
    }
  }

  private _filtercon(value: any) {
    this.yearsCon = this._objectModuleSharedService.getYearsCon();
    if (value !== '') {
      this.yearsCon =
        this.yearsCon.filter(
          option => option.toString().includes(value) === 0
        ) !== null
          ? this.yearsCon.filter(option => option.toString().includes(value))
          : this._objectModuleSharedService.getYearsCon();
    }
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  initializeForm() {
    const formData =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb
        ? JSON.parse(this.object_data.criteria_response.jsonb)
        : '';
    this.tooltipTest = formData.extinguishing_system_id;
    let new_value;
    if (formData) {
      if (formData.new_value !== null && formData.count !== null) {
        new_value = formData.new_value;
        new_value = new_value.toString().replaceAll(',', '.');
        new_value = new_value * formData.count;
        new_value = this._currencyCustomPipe
          .transform(new_value)
          .replace('€', '');
      } else {
        new_value = null;
      }
    }
    const objectTypeValidation = new FormControl({
      value: this.object_data ? this.object_data.object_name : this.objectType,
      disabled: false,
    });
    const sequenceNumberValidation = new FormControl(
      {
        value: formData ? formData.sequence_number : '',
        disabled: false,
      },
      []
    );
    const designation = new FormControl(
      { value: formData ? formData.description : '', disabled: false },
      []
    );
    const manufacturer = new FormControl(
      { value: formData ? formData.manufacturer : '', disabled: false },
      []
    );
    const model = new FormControl(
      { value: formData ? formData.model : '', disabled: false },
      []
    );
    const objectNameValidation = new FormControl({
      value: this.object_data ? this.object_data.object_name : this.objectType,
      disabled: false,
    });
    const serialNumber = new FormControl(
      { value: formData ? formData.serial_number : '', disabled: false },
      []
    );
    const constructionYear = new FormControl(
      { value: formData ? formData.construction_year : '', disabled: false },
      []
    );
    const newValue = new FormControl(
      {
        value: formData.new_value
          ? this._currencyCustomPipe
              .transform(formData.new_value)
              .replace('€', '')
              ?.split(',')[0]
              ?.trim()
          : '',
        disabled: false,
      },
      []
    );
    const newValuePerYear = new FormControl(
      { value: formData ? formData.new_value_in_year : '', disabled: false },
      []
    );
    const number = new FormControl(
      { value: formData ? formData.count : '', disabled: false },
      []
    );
    const objectSystem = new FormControl(
      {
        value: formData ? formData.extinguishing_system_id : '',
        disabled: false,
      },
      []
    );
    const hubHeight = new FormControl(
      { value: formData ? formData.hub_height : '', disabled: false },
      []
    );

    const wert371Validation = new FormControl(
      {
        value: formData
          ? this._currencyCustomPipe
              .transform(formData.wert_371)
              .replace('€', '')
              ?.split(',')[0]
              ?.trim()
          : null,
        disabled: false,
      },
      []
    );
    const fireProtectionDocValidation = new FormControl(
      { value: formData ? formData.fire_protection_doc : '', disabled: false },
      []
    );
    const acquisitionInvoiceDocValidation = new FormControl(
      {
        value: formData ? formData.acquisition_invoice_doc : '',
        disabled: false,
      },
      []
    );
    const riskQuestionnaireDocValidation = new FormControl(
      {
        value: formData ? formData.risk_questionnaire_doc : '',
        disabled: false,
      },
      []
    );
    const fireProtectionIdsValidation = new FormControl(
      {
        value: formData ? formData.fire_protection_doc_ids : null,
        disabled: false,
      },
      []
    );
    const acquisitionInvoiceIdsValidation = new FormControl(
      {
        value: formData ? formData.acquisition_invoice_doc_ids : null,
        disabled: false,
      },
      []
    );
    const riskQuestionnaireIdsValidation = new FormControl(
      {
        value: formData ? formData.risk_questionnaire_doc_ids : null,
        disabled: false,
      },
      []
    );
    const objectTypeName = new FormControl('machine-plants-mobi');
    const Gesamtwert = new FormControl(
      { value: formData ? new_value : '', disabled: false },
      []
    );
    const currencyTypeValidation = new FormControl(
      {
        value: formData ? formData.currency_type_id : null,
        disabled: false,
      },
      []
    );
    this.machinePlantsForm = new FormGroup({
      objectTypeName,
      objectType: objectTypeValidation,
      object_name: objectNameValidation,
      sequenceNumber: sequenceNumberValidation,
      description: designation,
      manufacturer,
      model,
      serialNumber,
      constructionYear,
      newValue,
      objectExtSystem: objectSystem,
      newValuePerYear,
      count: number,
      hub_height: hubHeight,
      Gesamtwert,
      currency_type_id: currencyTypeValidation,
      wert_371: wert371Validation,
      fire_protection_doc: fireProtectionDocValidation,
      acquisition_invoice_doc: acquisitionInvoiceDocValidation,
      risk_questionnaire_doc: riskQuestionnaireDocValidation,
      fire_protection_doc_ids: fireProtectionIdsValidation,
      acquisition_invoice_doc_ids: acquisitionInvoiceIdsValidation,
      risk_questionnaire_doc_ids: riskQuestionnaireIdsValidation,
    });

    if (this.object_data) {
      this.serviceCall
        .getDocumentPhotovoltik(
          this.object_data.criteria_response.record_id,
          13
        )
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.HasErrors == false) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              this.selectedDocumentsArray[document.document_name] = document;
              if (
                document.document_type ===
                UploadTypeConstants.FIRE_PROTECTION_EQUIPMENT_STAT
              ) {
                this.selectedFireDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.ACQUISITION_INVOICE_STAT
              ) {
                this.selectedAcquisitionDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.RISK_QUESTIONNAIRE_STAT
              ) {
                this.selectedRiskDocumentsArray[document.document_name] =
                  document;
              }
            });
            this.machinePlantsForm.controls.fire_protection_doc.setValue(
              Object.keys(this.selectedFireDocumentsArray).join(', ')
            );
            this.machinePlantsForm.controls.acquisition_invoice_doc.setValue(
              Object.keys(this.selectedAcquisitionDocumentsArray).join(', ')
            );
            this.machinePlantsForm.controls.risk_questionnaire_doc.setValue(
              Object.keys(this.selectedRiskDocumentsArray).join(', ')
            );
          }
        });
    }
    this.machinePlantsForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        const formValues = this.machinePlantsForm.value;
        formValues.isInValid = this.machinePlantsForm.invalid;
        this.emitFormValue.emit(this.machinePlantsForm);
      });
    if (!this.machinePlantsForm.touched) {
      const formValues = this.machinePlantsForm.value;
      formValues.isInValid = this.machinePlantsForm.invalid;
      this.emitFormValue.emit(this.machinePlantsForm);
    }
  }
  itemtooltiptext() {
    if (this.tooltipTest) {
      const object_system = [];
      for (let i = 0; i < this.objectSystem.length; i++) {
        for (
          let j = 0;
          j <
          JSON.parse(this.object_data.criteria_response.jsonb)
            .extinguishing_system_id.length;
          j++
        ) {
          if (
            this.objectSystem[i].id ==
            JSON.parse(this.object_data.criteria_response.jsonb)
              .extinguishing_system_id[j]
          ) {
            object_system.push(this.objectSystem[i].name);
          }
        }
      }
      return object_system.join('\n');
    } else {
      return '';
    }
  }
  public hasFormError = (controlName: string, errorName: string): boolean => {
    return true;
  };
  scroll() {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById('searchYear1').focus();
  }
  onTypeNewValue(event, key) {
    if (key == 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length == 0) {
        if (charCode == '45' || charCode == '48') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        this.errorNewValue = false;
        if (
          charCode != 46 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualneuValue = event.target.value.length;
      } else {
        this.actualneuValue = this.actualneuValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,.]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualneuValue == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  typeNumberBrowserSpecificHubHeight(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }

  changeNumber(event, name) {
    const count = event.split(',').length - 1;
    if (count > 1) {
      this.errorNewValue1 = true;
    } else {
      this.errorNewValue1 = false;
    }
    const tempValue = event.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 9) {
      if (name == 'newValue') {
        this.errorNewValue = true;
      } else if (name == 'wert371') {
        this.errorWert = true;
      }
    } else {
      this.errorNewValue = false;
      this.errorWert = false;
    }
    if (this.errorNewValue || this.errorNewValue1 || this.errorWert) {
      this.formError = 'true';
      this._validationSharedService.saveFormError(this.formError);
    } else {
      this.formError = 'false';
      this._validationSharedService.saveFormError(this.formError);
    }
    if (
      this.machinePlantsForm.controls.count.value !== '' &&
      this.machinePlantsForm.controls.count.value !== null &&
      this.formError == 'false' &&
      name == 'newValue'
    ) {
      if (event !== '') {
        const a = event.replaceAll('.', '');
        const b = a.replace(',', '.');
        const c = b * this.machinePlantsForm.controls.count.value;
        const d = c.toFixed(2);
        const e = this._currencyCustomPipe
          .transform(d.toString())
          .replace('€', '')
          .trim();
        this.machinePlantsForm.controls.Gesamtwert.setValue(e);
      } else {
        this.machinePlantsForm.controls.Gesamtwert.setValue(null);
      }
    }
    if (event) {
      if (name == 'newValue') {
        this.machinePlantsForm.controls.newValue.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString().replaceAll('.', '').replaceAll(',', '.')
            )
            .replace('€', '')
            ?.split(',')[0]
            ?.trim()
        );
      } else if (name == 'wert371') {
        this.machinePlantsForm.controls.wert_371.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString().replaceAll('.', '').replaceAll(',', '.')
            )
            .replace('€', '')
            ?.split(',')[0]
            .trim()
        );
      }
    }
  }
  calculateGesamtwert(event) {
    if (
      this.machinePlantsForm.controls.newValue.value !== '' &&
      this.machinePlantsForm.controls.newValue.value !== null
    ) {
      if (event.target.value !== '' && this.formError == 'false') {
        const a = this.machinePlantsForm.controls.newValue.value.replaceAll(
          '.',
          ''
        );
        const b = a.replace(',', '.');
        const c = b * event.target.value;
        const d = c.toFixed(2);
        const e = this._currencyCustomPipe
          .transform(d.toString())
          .replace('€', '');
        this.machinePlantsForm.controls.Gesamtwert.setValue(e);
      } else {
        this.machinePlantsForm.controls.Gesamtwert.setValue(null);
      }
    }
  }
  focusCurrency() {
    document.getElementById('currencyDD').focus();
  }
  calculateNewValue(form, event, year) {
    if (event.target.value) {
      let yearlyFactor;
      const yearF = this.machinePlantsForm.controls.newValuePerYear.value;
      this.serviceCall
        .getFactorBasedOnYear(yearF == null ? '' : yearF)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.sum_of_factor !== null) {
            yearlyFactor = data.sum_of_factor;
            if (yearlyFactor != null && yearlyFactor != undefined) {
              this.machinePlantsForm.controls.newValue.setValue(
                this.validation.calculateNewValue(form, event, yearlyFactor)
              );
              this.changeNumber(
                this.machinePlantsForm.value.newValue,
                'newValue'
              );
            }
          } else {
            this.resetWert();
            this.showToastMessage();
          }
        });
    } else {
      this.resetWert();
    }
  }
  calculateValue(event, year) {
    if (event.target.value) {
      let yearlyFactor;
      const yearF = this.machinePlantsForm.controls.newValuePerYear.value;
      this.serviceCall
        .getFactorBasedOnYear(yearF == null ? '' : yearF)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.sum_of_factor !== null) {
            yearlyFactor = data.sum_of_factor;
            if (yearlyFactor != null && yearlyFactor != undefined) {
              const result: any = this._currencyCustomPipe
                .transform(
                  Math.round(
                    this.validation.calculateWert371(event, yearlyFactor)
                  )
                )
                ?.replace('€', '')
                .trim()
                .split(',')[0];
              this.changeNumber(result, 'wert371');
              this.machinePlantsForm.controls.wert_371.setValue(result);
            }
          } else {
            this.resetWert();
            if (event.target.value) {
              this.showToastMessage();
            }
          }
        });
    } else {
      this.resetWert();
    }
  }

  yearChange(event) {
    let yearlyFactor;

    if (this.machinePlantsForm.controls.wert_371.value !== '') {
      this.serviceCall
        .getFactorBasedOnYear(event == null ? '' : event)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          yearlyFactor = data.sum_of_factor;
          if (data.sum_of_factor !== null) {
            const calculatedNewValue =
              yearlyFactor *
              Number(
                this.machinePlantsForm.controls.wert_371.value
                  .toString()
                  ?.replaceAll('.', '')
                  ?.replaceAll(',', '.')
                  .trim()
              );
            const result = this._currencyCustomPipe
              .transform(Math.round(calculatedNewValue))
              .replace('€', '')
              ?.split(',')[0]
              ?.trim();
            this.machinePlantsForm.controls.newValue.setValue(result);
            this.changeNumber(
              this.machinePlantsForm.value.newValue,
              'newValue'
            );
          } else {
            this.machinePlantsForm.controls.newValue.setValue('');
            this.machinePlantsForm.controls.wert_371.setValue('');
            this.showToastMessage();
          }
        });
    }
  }

  uploadFireDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedFireDocumentsArray };
    this.showFireDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 13,
      documentType: UploadTypeConstants.FIRE_PROTECTION_EQUIPMENT_STAT,
    };
  }
  uploadAcquisitionDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedAcquisitionDocumentsArray };
    this.showAcquisitionDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 13,
      documentType: UploadTypeConstants.ACQUISITION_INVOICE_STAT,
    };
  }
  uploadRiskDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedRiskDocumentsArray };
    this.showRiskDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 13,
      documentType: UploadTypeConstants.RISK_QUESTIONNAIRE_STAT,
    };
  }
  showDocumentPopup(): boolean {
    return (
      this.showRiskDocumentPopup ||
      this.showFireDocumentPopup ||
      this.showAcquisitionDocumentPopup
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showFireDocumentPopup) {
      this.fireDocsIds = [];
      this.selectedFireDocumentsArray = { ...this.selectedDocumentsArray };
      this.showFireDocumentPopup = false;
      this.machinePlantsForm.controls.fire_protection_doc.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.machinePlantsForm.controls.fire_protection_doc.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.fireDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
          this.machinePlantsForm.controls.fire_protection_doc_ids.setValue(
            this.fireDocsIds
          );
        }
      });
    }
    if (this.showAcquisitionDocumentPopup) {
      this.acquisitionDocsIds = [];
      this.selectedAcquisitionDocumentsArray = {
        ...this.selectedDocumentsArray,
      };
      this.showAcquisitionDocumentPopup = false;
      this.machinePlantsForm.controls.acquisition_invoice_doc.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.machinePlantsForm.controls.acquisition_invoice_doc.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.acquisitionDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
          this.machinePlantsForm.controls.acquisition_invoice_doc_ids.setValue(
            this.acquisitionDocsIds
          );
        }
      });
    }
    if (this.showRiskDocumentPopup) {
      this.riskDocsIds = [];
      this.selectedRiskDocumentsArray = { ...this.selectedDocumentsArray };
      this.showRiskDocumentPopup = false;
      this.machinePlantsForm.controls.risk_questionnaire_doc.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.machinePlantsForm.controls.risk_questionnaire_doc.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.riskDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
          this.machinePlantsForm.controls.risk_questionnaire_doc_ids.setValue(
            this.riskDocsIds
          );
        }
      });
    }
  }

  showToastMessage(): void {
    const message =
      this.selectLang === LOCALS.GERMAN
        ? 'Für das gewählte Jahr ist keine Faktorsumme verfügbar, daher werden die Werte zurückgesetzt'
        : 'There is no sum of factor available for the selected year, resetting the values';
    this._toastService.showToastMessage(document, message);
  }

  resetWert() {
    this.machinePlantsForm.controls.newValue.setValue('');
    this.machinePlantsForm.controls.wert_371.setValue('');
    this.errorNewValue = false;
    this.errorNewValue1 = false;
    this.errorWert = false;
    this.formError = 'false';
    this._validationSharedService.saveFormError(this.formError);
  }
  yearSelectedCon() {
    this.machinePlantsForm.controls.constructionYear.setValue('');
  }
  typeHubHeight(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  resetList() {
    this.currencyType.setValue('');
  }
  resetSearch() {
    this.yearControl1.reset();
    this._objectModuleSharedService.getYearsCon();
    document.getElementById('searchYear1').blur();
    setTimeout(() => this.manufacturerYear.focus(), 150);
  }
  scrollNewValue() {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById('searchYear2').focus();
  }
  yearSelected() {
    this.yearControl.reset();
    this.years = this._objectModuleSharedService.getYears();
    document.getElementById('searchYear2').blur();
    setTimeout(() => this.tempControl.focus(), 150);
  }
  dropdownChanges() {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById('searchYear1').focus();
  }

  private _watchOnLocale(): void {
    this._userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(locale => (this.selectLang = locale));
  }
}
