<mat-dialog-content id="add-relation" class="dialog-container">
    <div class="modal-header modal-popup-header">
        <span class="modal-popup-heading m-0"> {{ "customer-management.customer_relations.add_customer_relation" |
            translate }}</span>
        <button (click)="close()" class="action-btn">
            <img alt="" src="../../../../assets/images/Group 3298.svg">
        </button>
    </div>
    <div class="center-section p-3">
        <form class="general-form product-module-html" [formGroup]="customerRelationForm" id="form"
            *ngIf="customerRelationForm">
            <div class="row">
                <div class="col-md-4 col-sm-4">
                    <label for="" class="label"
                        style="text-transform: initial;">{{'customer-management.customer_relations.company_1' |
                        translate}}</label><br />
                    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}"
                        formControlName="entity_one" disabled>
                        <mat-option [value]="customer.customer_id" *ngFor="let customer of customers"
                            title="{{customer.name}}">
                            {{customer.name}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-md-4 col-sm-4">
                    <label for="" class="label required"
                        style="text-transform: initial;">{{'customer-management.customer_relations.choose_relationship'
                        | translate}}</label><br />
                    <mat-select class="dropdownStyle" #RelationControl (openedChange)="searchRelation.value = ''"
                        (click)="focusSearch('searchRelation')" (keyup.enter)="focusSearch('searchRelation')"
                        placeholder="{{'objectModule.select_lable' | translate}}" formControlName="relationship">
                        <input type="text" maxlength="20" class="searchSelect" preventSpace
                            placeholder="{{'header.Suche_tooltip' | translate}}" matInput id="searchRelation"
                            #searchRelation>
                        <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                        <mat-option [value]="relation.relationshipId"
                            *ngFor="let relation of validationService._filter(relationships, selectField , searchRelation.value)"
                            [title]="relation[selectField]">
                            <span >{{relation[selectField]}}</span>
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-md-4 col-sm-4">
                    <label for="" class="label required"
                        style="text-transform: initial;">{{'customer-management.customer_relations.with_company_2' |
                        translate}}</label><br />
                    <mat-select class="dropdownStyle" #CompanyControl (openedChange)="searchCompany.value = ''"
                        (click)="focusSearch('searchCompany')" (keyup.enter)="focusSearch('searchCompany')"
                        (selectionChange)="selectCustomer2($event)"
                        placeholder="{{'objectModule.select_lable' | translate}}" formControlName="entity_two">
                        <input type="text" maxlength="20" class="searchSelect" preventSpace
                            placeholder="{{'header.Suche_tooltip' | translate}}" #searchCompany>
                        <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                        <mat-option [value]="customer.customer_id"
                            *ngFor="let customer of validationService._filter(customers2, 'name' , searchCompany.value)"
                            title="{{customer.name}}">
                            {{customer.name}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </form>
    </div>
    <div class="save-container pb-3" *ngIf="customerRelationForm">
        <button (click)="save()"
            [disabled]="!customerRelationForm.controls['entity_two'].value || !customerRelationForm.controls['relationship'].value"
            class="custom-btn save-btn">{{ "settings.addGroupButtonText" | translate }}</button>
    </div>
</mat-dialog-content>