export const COLUMN_NAMES = {
    NAME: 'name',
    ROLE: 'role',
    RECEIVABLE_AMOUNT: 'receivable_amount',
    CLAIM_DESCRIPTION: 'claim_description',
    REMARK: 'remark',
    ACTION: 'action'
};

export const CP_COLUMN_HEADINGS = {
    NAME: 'new-claims.contact_person.name',
    ROLE: 'customer-management.contact_person.role',
    RECEIVABLE_AMOUNT: 'new-claims.contact_person.receivable_amount',
    CLAIM_DESCRIPTION: 'new-claims.contact_person.claim_description',
    REMARK: 'new-claims.contact_person.remark',
    ACTION: 'new-claims.contact_person.action'
};

export const MULTILINGUAL_CONSTS = {
    CP: 'customer-management.contact_person.contact_person',
    SELECT_ROLE: 'new-claims.contact_person.select_role',
    RESET_TEXT: 'contact-manager.Reset_Text',
    EDIT_TOOLTIP: 'product-management.EDIT_text',
    DELETE_TOOLTIP: 'user-data.Löschen_tooltip',
    SELECT_INSURER: 'insurer-details.select_insurer',
    // delete confirmation
    DELETE_HEADING: 'objectModule.Delete_popup_heading',
    DELETE_SUB_HEADING: 'objectModule.Delete_popup_text', // form_generator.delete_form_text
    CANCEL: 'zurs-geo.cancel_label',
    NO: 'contact-manager.NEIN_text',
    SUCCESS: 'contact-manager.JAL‎ÖSCHEN_text',
    CANNOT_DELETE_HEADING: 'new-claims.contact_person.cannot_delete_heading',
    CANNOT_DELETE_SUBHEADING: 'new-claims.contact_person.cannot_delete_subheading'
};

export const COLUMNS = [
    COLUMN_NAMES.NAME,
    COLUMN_NAMES.ROLE,
    COLUMN_NAMES.RECEIVABLE_AMOUNT,
    COLUMN_NAMES.CLAIM_DESCRIPTION,
    COLUMN_NAMES.REMARK,
    COLUMN_NAMES.ACTION
];

export const CONTACT_PERSON_ACTION_METHOD_NAMES = {
    EDIT_CONTACT_PERSON: 'editContactPerson',
    DELETE_CONTACT_PERSON: 'deleteContactPerson'
};

export const CP_FORM_CONTROL = {
    ROLE: 'role'
};

export const INS_FORM_CONTROL = {
    INSURER: 'insurer'
};
