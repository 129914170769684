import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { getZursDataRequest } from '@shared/models/generic.model';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ZursMathGeoComponent } from '../zurs-math-geo.component';

@Component({
  selector: 'zurs-common-component',
  templateUrl: './zurs-common.component.html',
  styleUrls: ['./zurs-common.component.css'],
})
export class ZursCommonComponent implements OnInit {
  @Input('address') address?;
  @Input('isLinkdisabled') isLinkdisabled?;
  @Input('isLocationObject') isLocationObject?;

  @Output('zursAddress') zursAddress = new EventEmitter<any>();
  zursParams: string;
  zursResponse;

  isLatLongMissing = false;

  constructor(
    private translate: TranslateService,
    private serviceCall: ApiCallsService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.checkForLatLonPresenceInAddress();
  }

  // Check of address if edited any field in the address to show tooltip for differnet message

  ngOnChanges(changes: SimpleChanges) {
    if (changes.address && changes.address.currentValue) {
      this.getZursData();
    }
    this.checkForLatLonPresenceInAddress();
  }

  zursPopUp() {
    if (!this.address) {
      this.dialog.open(GenericAlertComponent, {
        width: '40%',
        panelClass: 'genericAlert',
        data: {
          message: this.translate.instant('objectModule.new_selectAddress'),
        },
        disableClose: false,
      });
    } else {
      const data = {
        isCloseButtonEnabled: true,
        address: this.address,
      };
      const zursMathDialog = this.dialog.open(ZursMathGeoComponent, {
        width: '45%',
        panelClass: 'zursPayment',
        data,
        disableClose: true,
      });
      zursMathDialog.afterClosed().subscribe(data => {
        this.getZursData();
      });
    }
  }
  getZursData() {
    if (!this.address) {
      this.handleZursResponse(undefined);
      return;
    }
    const zursDataReq: getZursDataRequest = {
      lat: JSON.parse(this.address).lat,
      lon: JSON.parse(this.address).lon,
    };
    this.serviceCall.getZursData(zursDataReq).subscribe(data => {
      this.handleZursResponse(data);
    });
  }

  checkForLatLonPresenceInAddress() {
    const addressObject = this.address
      ? JSON.parse(this.address)
      : this.address;
    if (addressObject && addressObject.lat == '' && addressObject.lon == '') {
      this.isLatLongMissing = true;
    } else {
      this.isLatLongMissing = false;
    }
  }

  handleZursResponse(data) {
    if (!data) {
      this.zursResponse = undefined;
      return;
    }
    this.zursResponse = data;
    this.zursAddress.emit({
      address: this.zursResponse.gk + '|' + this.zursResponse.analysis_date,
    });
    if (!this.zursResponse.gk) {
      this.showToastMessage(this.translate.instant('zurs-geo.zurs_failed_msg'));
    }
  }

  getZursId() {
    return (this.zursResponse && this.zursResponse.gk) || '';
  }

  getZursDate() {
    return (this.zursResponse && this.zursResponse.analysis_date) || '';
  }

  showToastMessage(message) {
    const x = document.getElementById('successToast');
    if (x) {
      x.textContent = this.translate.instant(message);
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }
}
