<div class="external-container">
    <div class="row-containers">
        <div class="tree-container">
            <div class="container-heading">
                <span class="criteria-heading">{{'product-management.stepper_common_msg.available_product_module' | translate}}</span>
            </div>
            <div *ngIf="!isTreeDisabled" class="new-product-container">
                <button mat-button class="button-process new-product-group-button" (click)="addNewProductModule()">
                    <img alt="" src="../../../../assets/images/Icon ionic-md-close.svg">&nbsp;&nbsp;
                    <span style="vertical-align: middle;">
                        {{'product-management.product_module_stepper_lables.Add_new_Product_Module' | translate}}
                    </span>
                </button>
            </div> 
            <div class="stepper-inner">
                <tree-list-component *ngIf="loadTreeData" [treeData]="coverBunderData" [nodeLevel]="1"
                [isDisabled] = "isTreeDisabled"
                [buttonText]="buttonText" (updatedNodeSelection)="updatedNodeSelection($event)" (addNewNode)="addNewNode($event)"
                (deleteNode)="deleteNode($event)">
                </tree-list-component>
            </div>
        </div>
        <div class="form-container">
            <div class="container-heading">
                <span *ngIf="isAddNewStepEnabled" class="criteria-heading">{{'product-management.product_module_stepper_lables.Cover_Bundles' | translate}}</span>
                <span *ngIf="isNewProductModuleEnabled"  class="criteria-heading">{{'product-management.product_module_stepper_lables.Product_Module' | translate}}</span>
                <button *ngIf="isNewProductModuleEnabled || isAddNewStepEnabled" (click)="closeForm()" class="stepper-form-button">
                    <mat-icon><img alt="" class="product-close-button" src="../../../../assets/images/closeIcon.png"></mat-icon>
                </button>
                <button *ngIf="(isNewProductModuleEnabled || isAddNewStepEnabled) && !isTreeDisabled" (click)="saveForm()" class="stepper-form-button">
                    <mat-icon *ngIf="!isFormTouched"><img alt="" src="../../../../assets/images/Edit_icon.svg"></mat-icon>
                    <mat-icon *ngIf="isFormTouched"><img alt="" src="../../../../assets/images/save-button.svg"></mat-icon>
                </button>
            </div>
            <div class="stepper-inner" *ngIf="isAddNewStepEnabled">
                <app-stepper-form [step]="stepIndex" (emitFormValue)="formValues($event)" [consolidateData]="coverBundleconsolidateData"></app-stepper-form>
            </div>
            <div class="stepper-inner" *ngIf="isNewProductModuleEnabled">
                <app-stepper-form [step]="stepIndex-1" (emitFormValue)="formValues($event)" [consolidateData]="productModuleconsolidateData"></app-stepper-form>
            </div>
            <div class="empty-stepper-inner" *ngIf="!isAddNewStepEnabled && !isNewProductModuleEnabled">
                {{'product-management.stepper_common_msg.coverbundle_empty_msg' | translate}}
            </div>
        </div>
    </div>
</div>
<span class="tips-class" *ngIf="consolidateData && consolidateData.productStateId==2"><img alt="" class="tips-img" src="../../../../assets/images/tip_icon_summary.svg">{{'product-management.stepper_common_msg.update_product_nonedit_message' | translate}}</span>
<product-close-alert-dialog *ngIf="showWarningPopUp" (handlePopup)="handlePopup($event)"></product-close-alert-dialog>
<app-add-new-product-dialog *ngIf="addProductPopUp" [consolidateData]="consolidateData" (handleNewProduct)="handleNewProduct($event)" (hideProductPopup)="hideProductPopup($event)"></app-add-new-product-dialog>
<div id="successToast"></div>