import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Dictionary } from '@shared/models/dictionary.model';
import { GenericCriteriaConstants } from '@shared/constants/generic.constants';
import { SelectedDocument } from '@shared/models/upload-data.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.css'],
})
export class IndividualComponent implements OnInit {
  individualForm: FormGroup;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  @Input('object_data') object_data;
  @Input('selectedLiztu') selectedliztu;
  birthdayError = false;
  today = new Date();
  encoded_liztu_vn_kdnr: any;
  liztu_vn_kdnr: string;
  ObjectTypes: any;
  object_type_id: any;
  sequence_number: any;
  occupation: any = [];
  valueTypestemp: any;
  occupationControl = new FormControl();
  professionGroup = '';
  actualnumber = 0;
  browser: string;
  occuptaionDD;
  @ViewChild('occuptaionDD', { static: true }) occupationDropdown: MatSelect;
  showDocumentPopup: boolean;
  uploadObjectDetails: {
    documentSource: any;
    objectTypeId: number;
    documentType: string;
  };
  uploadedDocsIds: any[];
  selectedDocumentsArray: Dictionary<SelectedDocument> = {};
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private serviceCall: ApiCallsService,
    private route: ActivatedRoute,
    private validation: ValidationService
  ) {
    this.today.setDate(this.today.getDate() + 0);
    this.serviceCall
      .getLookupData(6)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.occupation = data.data;
        this.valueTypestemp = this.occupation;
      });
    this.occupationControl.valueChanges.subscribe(value => {
      this._filterOccupation(value);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm) {
      this.onFormReset();
    }
    if (this.individualForm && changes.isSubmitClicked !== undefined) {
      this.individualForm.reset();
    }
    if (this.individualForm && changes.objectType) {
      this.individualForm.patchValue({
        objectType: changes.objectType.currentValue,
      });
      this.initializeForm();
    }
  }
  onFormReset() {
    this.selectedDocumentsArray = {};
    this.initializeForm();
  }
  ngOnInit(): void {
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu_vn_kdnr = atob(this.encoded_liztu_vn_kdnr);
    this.initializeForm();
    this.loadDataFromAPI();
    this.browser = this.detectBrowser();
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  initializeForm() {
    const formData =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb
        ? JSON.parse(this.object_data.criteria_response.jsonb)
        : '';
    const objectTypeValidation = new FormControl({
      value: this.object_data ? this.object_data.object_type : this.objectType,
      disabled: false,
    });
    const objectNameValidation = new FormControl({
      value: this.object_data ? this.object_data.object_name : this.objectType,
      disabled: false,
    });
    const SequenceNumberValidation = new FormControl(
      {
        value: formData ? formData.sequence_number : '',
        disabled: false,
      },
      []
    );
    const FirstNameValidation = new FormControl(
      {
        value: formData ? formData.first_name : '',
        disabled: false,
      },
      []
    );
    const LastNameValidation = new FormControl(
      {
        value: formData ? formData.last_name : '',
        disabled: false,
      },
      []
    );
    const DOBValidation = new FormControl(
      {
        value: formData ? formData.date_of_birth : '',
        disabled: false,
      },
      []
    );
    const JobDescriptionValidation = new FormControl(
      {
        value: formData ? formData.job_description : '',
        disabled: false,
      },
      []
    );
    const GenderValidation = new FormControl(
      {
        value: formData ? formData.gender : '',
        disabled: false,
      },
      []
    );
    const occupationValidation = new FormControl(
      {
        value: formData ? formData.occupation_id : '',
        disabled: false,
      },
      []
    );
    const occupationGroupValidation = new FormControl(
      {
        value: formData ? formData.professional_group : '',
        disabled: false,
      },
      []
    );
    const health_questionnaireValidation = new FormControl(
      {
        value: formData ? formData.health_questionnaire : '',
        disabled: false,
      },
      []
    );
    const doc_id = new FormControl(
      {
        value: formData ? formData.doc_id : null,
        disabled: false,
      },
      []
    );
    const objectTypeName = new FormControl('individual');
    this.individualForm = new FormGroup({
      objectTypeName,
      objectType: objectTypeValidation,
      object_name: objectNameValidation,
      sequence_number: SequenceNumberValidation,
      first_name: FirstNameValidation,
      last_name: LastNameValidation,
      date_of_birth: DOBValidation,
      job_description: JobDescriptionValidation,
      occupation_id: occupationValidation,
      professional_group: occupationGroupValidation,
      gender: GenderValidation,
      health_questionnaire: health_questionnaireValidation,
      doc_id,
    });
    if (
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response
    ) {
      const record_id = this.object_data.criteria_response.record_id;
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 5)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data.HasErrors == false) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              this.selectedDocumentsArray[document.document_name] = document;
            });
            this.individualForm.controls.health_questionnaire.setValue(
              Object.keys(this.selectedDocumentsArray).join(', ')
            );
          }
        });
    }

    this.individualForm.valueChanges.subscribe(obj => {
      this.individualForm.controls.date_of_birth.setErrors(null);
      const formValues = this.individualForm.value;
      formValues.isInValid = this.individualForm.invalid;
      this.emitFormValue.emit(this.individualForm);
    });
    if (!this.individualForm.touched) {
      this.emitFormValue.emit(this.individualForm);
    }
  }
  nameValidation(e, length) {
    this.validation?.nameValidation(e, length);
  }
  sequenceNumberValidation(e) {
    const regexp = new RegExp('^[0-9]*$');
    let searchfind: boolean;
    searchfind = regexp.test(e.key);
    if (!searchfind) {
      e.preventDefault();
    }
    if (e?.type == 'paste') {
      let searchfind: boolean;
      const regexp = new RegExp('^([1-9]{1})[0-9]{1,4}$');
      if (e.clipboardData.getData('text/plain').length > 5) {
        e.preventDefault();
      } else {
        searchfind = regexp.test(e.clipboardData.getData('text/plain'));
        if (!searchfind) {
          e.preventDefault();
        }
      }
    }
  }
  loadDataFromAPI() {
    this.serviceCall
      .getObjectType('')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.object_types !== null) {
          this.ObjectTypes = data.object_types;
          for (let i = 0; i < this.ObjectTypes.length; i++) {
            if (this.ObjectTypes[i].object_name == this.objectType) {
              this.object_type_id = this.ObjectTypes[i].object_type_id;
              const liztu_vn_kdnr =
                this.selectedliztu != undefined
                  ? this.selectedliztu
                  : this.liztu_vn_kdnr;
              if (!this.object_data) {
                this.serviceCall
                  .getSequenceNumber(
                    liztu_vn_kdnr,
                    this.object_type_id,
                    GenericCriteriaConstants[5]
                  )
                  .pipe(takeUntil(this._destroy$))
                  .subscribe(data => {
                    if (data.HasErrors == false) {
                      this.sequence_number = data.sequence_number;
                      this.individualForm.controls.sequence_number.setValue(
                        this.sequence_number
                      );
                    }
                  });
              }
            }
          }
        }
      });
  }
  dateChange(e) {
    this.birthdayError = this.validation?.dateValidation(e);
  }
  onDateBlur(e) {
    const today = moment(this.today, 'DD-MM-YYYY').format('MM/DD/YYYY');
    const enteredDate = moment(e.target.value, 'DD-MM-YYYY').format(
      'MM/DD/YYYY'
    );
    const difference = moment(enteredDate).diff(moment(today), 'days');
    if (difference > 0) {
      e.target.value = '';
      this.birthdayError = true;
      this.individualForm.controls.date_of_birth.setValue(null);
      this.individualForm.controls.date_of_birth.setErrors(null);
    }
  }
  private _filterOccupation(value: any) {
    this.occupation = this.valueTypestemp;
    if (value !== '') {
      if (
        this.occupation.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.occupation = this.occupation.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.occupation = this.valueTypestemp;
      }
    } else {
      this.occupation = this.valueTypestemp;
    }
  }
  occupationChanged(event) {
    if (event.value == '' || event.value == null || event.value == undefined) {
      this.individualForm.controls.professional_group.setValue('');
      this.individualForm.controls.occupation_id.setValue('');
      this.occupationControl.setValue('');
      this.professionGroup = '';
    } else {
      const data = this.valueTypestemp.find(value => value.id == event.value);
      this.individualForm.controls.professional_group.setValue(data.info);
      this.occupationControl.setValue('');
      this.professionGroup = data.info;
    }
    setTimeout(() => this.occupationDropdown.focus(), 150);
  }
  dateClicked() {
    this.birthdayError = false;
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  focusDropdown() {
    document.getElementById('occupationSearch').focus();
  }
  uploadDoc() {
    this.showDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 5,
      documentType: 'Gesundheitsfragebogen',
    };
  }
  hidePopup(selectedDocumentsArray) {
    this.showDocumentPopup = false;
    this.uploadedDocsIds = [];
    Object.keys(selectedDocumentsArray).forEach(name => {
      if (selectedDocumentsArray[name].upload_document_id) {
        this.uploadedDocsIds.push(
          selectedDocumentsArray[name].upload_document_id
        );
      }
    });
    this.individualForm.controls.health_questionnaire.setValue(
      Object.keys(selectedDocumentsArray).join(', ')
    );
    this.individualForm.controls.doc_id.setValue(this.uploadedDocsIds);
  }
  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
