import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AddNewFormComponent } from './add-new-form/add-new-form.component';
import { GenericFormFieldFrameComponent } from './add-new-form/generic-form-field-frame/generic-form-field-frame.component';
import { ContactManagerComponent } from './contact-manager/contact-manager.component';
import { NewEditorComponent } from './contact-manager/new-editor/new-editor.component';
import { StepOneProcessComponent } from './contact-manager/step-one-process/step-one-process.component';
import { FormGeneratorComponent } from './form-generator/form-generator.component';
import { MsTemplatesManagementComponent } from './ms-templates-management/ms-templates-management.component';
import { UploadMsTemplatePopupComponent } from './ms-templates-management/upload-ms-template-popup/upload-ms-template-popup.component';
import { AddNewProductDialogComponent } from './product-management/add-new-product-dialog/add-new-product-dialog.component';
import { CoverBundlesStepperComponent } from './product-management/cover-bundles-stepper/cover-bundles-stepper.component';
import { CoveringStepperComponent } from './product-management/covering-stepper/covering-stepper.component';
import { DamageTypeStepperComponent } from './product-management/damage-type-stepper/damage-type-stepper.component';
import { MaskGeneratorStepperComponent } from './product-management/mask-generator-stepper/mask-generator-stepper.component';
import { MaskGeneratorComponent } from './product-management/mask-generator-stepper/mask-generator/mask-generator.component';
import { ValueCalculatorComponent } from './product-management/mask-generator-stepper/value-calculator/value-calculator.component';
import { NewProductComponent } from './product-management/new-product/new-product.component';
import { ProductBaseStepper } from './product-management/product-base-stepper/product-base-stepper.component';
import { ProductManagementComponent } from './product-management/product-management.component';
import { SparteDetailsStepperComponent } from './product-management/sparte-details-stepper/sparte-details-stepper.component';
import { StepperFormComponent } from './product-management/stepper-form/stepper-form.component';
import { SummaryStepperComponent } from './product-management/summary-stepper/summary-stepper.component';
import { PageUnderConstructionComponent } from './product-management/under-construction-page/page-under-construction';
import { RCSSetupRoutingModule } from './rcs-setup-routing.module';
import { RcsSetupComponent } from './rcs-setup.component';
import { NewSparteGroupComponent } from './sparte-management/new-sparte-group/new-sparte-group.component';
import { SparteManagementComponent } from './sparte-management/sparte-management.component';
import { FileViewerComponent } from './template-repository/file-list/file-viewer.component';
import { ProductLinkedTemplateComponent } from './template-repository/product-linked-template/product-linked-template.component';
import { TemplateProductListComponent } from './template-repository/product-list/template-product-list.component';
import { TemplateRepositoryComponent } from './template-repository/template-repository.component';

@NgModule({
  declarations: [
    RcsSetupComponent,
    // Sparte Management
    SparteManagementComponent,
    NewSparteGroupComponent,
    // Product Management
    ProductManagementComponent,
    PageUnderConstructionComponent,
    SummaryStepperComponent,
    StepperFormComponent,
    SparteDetailsStepperComponent,
    ProductBaseStepper,
    NewProductComponent,
    MaskGeneratorStepperComponent,
    ValueCalculatorComponent,
    MaskGeneratorComponent,
    DamageTypeStepperComponent,
    CoveringStepperComponent,
    CoverBundlesStepperComponent,
    AddNewProductDialogComponent,
    // Add New Form
    AddNewFormComponent,
    GenericFormFieldFrameComponent,
    // Form Generator
    FormGeneratorComponent,
    // Contract Manager
    ContactManagerComponent,
    StepOneProcessComponent,
    NewEditorComponent,
    // Template Repository
    TemplateRepositoryComponent,
    TemplateProductListComponent,
    ProductLinkedTemplateComponent,
    FileViewerComponent,
    // MS TeplatesManagement
    MsTemplatesManagementComponent,
    UploadMsTemplatePopupComponent
  ],
  imports: [
    NgxMatIntlTelInputModule,
    RCSSetupRoutingModule,
    TranslateModule.forChild(),
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatMenuModule,
    MatPaginatorModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    TranslateModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatOptionModule,
    InfiniteScrollModule,
    DragDropModule,
    NgbModule,
    SharedModule,
  ],
})
export class RCSSETUPModule { }
