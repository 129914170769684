// compulsory constants
export class Api {
  static MESSAGE_SEND = '/message/send';
  static GET_CONTRACT = '/api/v1/get_contract_summary';
  static GET_CONTRACT_LIST = '/api/v1/contract';
  static ATTACH_CONTRACT = '/api/v1/contract/attach';
  static DETACH_CONTRACT = '/api/v1/contract/detach';
  static GET_UNBUNDLED_CONTRACTS = '/api/v1/contract/unbundled';
  static GET_SUBORDINATE_CONTRACTS = '/api/v1/contract/subordinated';
  static GET_CLAIM = '/api/v1/get_claim_summary';
  static GET_USER = '/api/v1/get_user_profile';
  static GET_DIVISIONS = '/api/v1/get_divisions';
  static GET_ORGANIZATION = '/api/v1/get_organisations';
  static GET_USERS_LIST = '/api/v1/get_user_list';
  static IMAGE_UPLOAD = '/api/v1/upload_customer_logo';
  static UPDATE_PROFILE = '/api/v1/update_user_profile';
  static UPLOAD_LOGO = '/api/v1/upload_profile_image';
  static DOCUMENT_UPLOAD = '/api/v1/upload_document';
  static DOCUMENT_UPLOAD_2 = '/api/v1/document/uploadDocument';
  static GET_DOCUMENT = '/api/v1/get_document';
  static GET_DOCUMENTS = '/api/v1/document/documents';
  static GET_DOCUMENT_TYPES = '/api/v1/document/types';
  static UPDATE_DOCUMENT_METADATA = '/api/v1/document/update';
  static GET_DOCUMENT_VERSIONS = '/api/v1/document/versions';
  static ADD_DOCUMENT_VERSION = '/api/v1/document/addVersion';
  static SAVE_DRAFT_WORD = '/api/v1/document/letters';
  static CREATE_DRAFT_WORD = '/api/v1/document/draft/letters';
  static SAVE_DRAFT_OUTLOOK = '/api/v1/document/emails';
  static CREATE_DRAFT_OUTLOOK = '/api/v1/document/draft/emails';
  static GET_CUSTOMER_DOCUMENTS = '/api/v1/document/customerDocuments';
  static DELETE_DOCUMENT = '/api/v1/delete_documents';
  static SEARCH_RESULT = '/api/v1/get_search_results';
  static GET_COMPANIES = '/api/v1/get_values_data';
  static ADD_UPDATE_COMPANIES = '/api/v1/update_values_data';
  static GET_LOGO_LINK = '/api/v1/get_logo_link';
  static DELETE_LOGO = '/api/v1/delete_customer_logo';
  static GET_CUSTOMER_INFO = '/api/v1/customer_header_info';
  static GET_USER_ROLE = '/api/v1/get_user_roles';
  static INVITE_USER = '/api/v1/invite_user';
  static GET_SUBSIDIARIES = '/api/v1/get_associated_customers';
  static GET_OPT = '/api/v1/get_otp';
  static SEND_OPT = '/api/v1/submit_otp';
  static GET_LANG = '/api/v1/get_locales';
  static RESET_PASSWORD = '/api/v1/resetPassword';
  static RESET_INVITE = '/api/v1/invite_reset';
  static GET_ACTOR_TYPES = '/api/v1/get_actor_types';
  static GET_ACTOR = '/api/v1/get_actors';
  static GET_SPARTE = '/api/v1/get_sparte';
  static GET_PROCESSE = '/api/v1/get_processes';
  static GET_SUBSIDIARY = '/api/v1/get_subsidiaries';
  static GET_CM_DATA = '/api/v1/get_cm_data';
  static GET_EXTERN = '/api/v1/get_extern';
  static GET_PRODUCT = '/api/v1/get_products';
  static GET_EDITOR = '/api/v1/get_berbeiters';
  static GET_BEREICH = '/api/v1/get_bereich';
  static POST_CM_DATA = '/api/v1/post_cm_data';
  static DELETE_CM_DATA = '/api/v1/delete_cm_data';
  static WIDGET_AUTHORIZATIONS = '/api/v1/widgetauthorizations';
  static DASHBOARD_ITEMS = '/api/v1/dashboarditems';
  static GET_WIDGETS = '/api/v1/widgets';
  static GET_USAGE_BEHAVIOUR = '/api/v1/dashboard/get_usage_behaviour';
  static GET_INVENTROY_CONTROLLING =
    '/api/v1/dashboard/get_inventory_controlling';
  static NEW_BIGGER_CLAIMS = '/api/v1/dashboard/get_new_bigger_claims';
  static GET_TOP_CUSTOMERS = '/api/v1/dashboard/get_top_customers';
  static GET_INVENTORY_PENETRATION =
    '/api/v1/dashboard/get_inventory_penetration_by_ic_products';
  static GET_CUSTOMER_BY_PREMIUM =
    '/api/v1/dashboard/get_customer_structure_by_premium';
  static GET_INSURER_RATIOS = '/api/v1/dashboard/insurer-ratios';
  static GET_INVENTORY_DISTRIBUTION =
    '/api/v1/dashboard/get_inventory_distribution';
  static GET_SPARTE_GROUP = '/api/v1/get_sparte_group';
  static POST_SPARTE_GROUP = '/api/v1/post_sparte_group';
  static DELETE_SPARTE_GROUP = '/api/v1/delete_sparte_group';
  static GET_CUSTOMER_STRUCTURE_BY_INDUSTRY =
    '/api/v1/dashboard/get_customer_structure_by_industry';
  static ADD_NEW_CUSTOMER = '/api/v1/add_customer';
  static GET_USER_ROLE_ID = '/api/v1/get_user_role_id';
  static GET_LANGUAGE_ID = '/api/v1/get_language_id';
  static IS_UNIQUE = '/api/v1/is_unique';
  static GET_AUTO_COMPLETE = 'https://atlas.microsoft.com/search/address/json';
  static GET_PAYMENT = '/api/v1/dashboard/get_due_payments';
  static GET_NEW_DOCUMENT = '/api/v1/dashboard/get_new_documents';
  static GET_NEW_CLAIMS = '/api/v1/dashboard/get_new_claims';
  static GET_CONTRACT_FOR_COMPANY = '/api/v1/get_contracts_for_customer';
  static GET_CONTRACTS_FOR_CUSTOMER = '/api/v1/get_contracts_for_user';
  static GET_SUB_CUSTOMER_DETAILS = '/api/v1/get_customer_details';
  static GET_UNIQUE_SUBSIDIARIES = '/api/v1/get_unique_associated_customers';
  static DELETE_USER = '/api/v1/delete_user';
  static MASTER_DATA = '/api/v1/master_data';
  static GET_CONTACT_PERSON = '/api/v1/dashboard/get_contact_persons';
  static GET_CLAIM_REPORT = '/api/v1/register_claim';
  static GET_MONITORING = '/api/v1/dashboard/get_monitoring';
  static GET_REVERSELOGGING = '/api/v1/dashboard/get_reverse_logging';
  static GET_ZURS_PRICE = '/api/v1/riskzoning/getZursPrice';
  static GET_ZURS_ANALYSIS = '/api/v1/riskzoning/getZursAnalysis';
  static GET_ZURS_ZONE_VALUE = '/api/v1/riskzoning/getZursZoneValue';
  static GET_CLAIM_PAYMENT_BREAKUP = '/api/v1/claim_pmt_breakup';
  static UPDATE_SIMPLEYGDPR_VISITS_COUNT = '/api/v1/dashboard/click_info';
  static GET_SIMPLYGDPR_VISITED_COUNT = '/api/v1/dashboard/click_info';
  static GET_OBJECT_MODULE = '/api/v1/object_module_data';
  static GET_OBJECT_MODULE_V2 = '/api/v2/object_module_data';

  static GET_OBJECT_LIST = '/api/v1/object_module_data';

  static NEW_OBJECT_ADDRESS_LIST = '/api/v1/address';
  static SAVE_NEW_ADDRESS = '/api/v1/add_address';
  static SAVE_NEW_OBJECT = '/api/v2/add_update_object';
  static GET_OBJECT_TYPE = '/api/v1/get_object_types';

  static GET_CONST_TYPE = '/api/v1/construction_type';
  static GET_VALUE_TYPE = '/api/v1/object_value_types';

  static COPY_OBJECT_MODULE = '/api/v2/object_module_data/copy';
  static GET_OBJECT_DATA = '/api/v2/object_data';
  static GET_TYPE_OF_USE = '/api/v1/building_types';
  static GET_SEQUENCE_NUMBER = '/api/v2/sequence_number';
  static GET_EXTINGUISHING_SYSTEMS = '/api/v1/extinguishing_systems';
  static OBJ_LOOKUP_DATA = '/api/v1/obj_lookup_data';
  static GET_LOOKUP_DATA = '/api/v1/obj_lookup_data';
  static GET_CURRENCY_TYPE = '/api/v1/currency_types';

  static GET_CURRENCY_VALUES = '/api/v1/currency_types';
  static UPLOAD_OBJ_DOC = '/api/v2/upload_obj_doc';
  static OBJ_DOC = '/api/v2/obj_doc';
  static OBJ_DOC_DELETE = '/api/v1/obj_doc';
  static UPDATE_DOC = '/api/v2/update_desc';
  static ADD_CRITERIA = '/api/v1/criteria';
  static FACTORS = '/api/v1/factors';
  static GET_INSURER = '/api/v1/insurer/insurers';
  static GET_PRODUCTS = '/api/v1/product/products';
  static PRODUCT_LOOKUP_DATA = '/api/v1/product/lookup_data';
  static GETYEARS = '/api/v1/factor_years';
  static GET_INSURER_LIST = '/api/v1/insurer/insurers';
  static GET_PRODUCT_MODULE = '/api/v2/product/product_module_data';
  static ADD_UPDATE_PRODUCT = '/api/v2/product/add_update';
  static DELETE_PRODUCT_MODULE = '/api/v2/product';
  static ADD_UPDATE_CLAUSE_EXCLUSION =
    '/api/v1/product/add_update_clause_exclusion';
  static GET_CLAUSES_EXCLUSIONS = '/api/v1/product/clauses_exclusions';
  static DELETE_CLAUSE_EXCLUSION = '/api/v1/product/clause_exclusion';
  static PRODUCT_LOOKUP_DATA_2 = '/api/v1/product/lookup_data_2';
  static GET_CUSTOMER_BANK_DETAILS = '/api/v1/customer/bank_details';
  static GET_STEPPER_LOOKUP_DATA = '/api/v1/product/stepper_dropdown_data';
  static GET_VALUE_CUSTOMER = '/api/v1/value-module/customer';
  static GET_VALUE_QUESTIONS = '/api/v1/value-module/questions?valueTabId=';
  static GET_STEPPER_DATA = '/api/v1/product/productId/stepper/';
  static SAVE_STEPPER_DATA = '/api/v1/product/stepper';
  static SAVE_MASK_GENERATOR_STEPPER_DATA =
    '/api/v1/product/stepper/mask_gen_config';
  static GET_QUESTIONS_DATA = '/api/v1/value-module/get_value_module_data'; // ?liztuVnKdnrs={0}&valueTabId={1}';
  static SAVE_QUESTIONS_DATA = '/api/v1/value-module/add_update';
  static SAVE_TREE_SELECTION = '/api/v1/product/stepper/selection';
  static GET_USER_NAME = '/api/v1/get_user?user_id=';
  static GET_INSURER_DETAILS = '/api/v1/contract';
  static GET_INSURER_PARTICIPATIONS = '/api/v1/contract/participations';
  static GET_PRODUCT_MAPPING = '/api/v1/contract/productMapping';
  static INSURER_BASE = '/api/v1/contract/{contract_id}/insurer';
  static GET_CONTRACT_STATUS = '/api/v1/contract/status';
  static GET_CONTRACT_GRAPH = '/api/v1/contract/{contract_id}/graph';
  static COPY_PRODUCT = '/api/v1/product/copy';
  static GET_SELECTABLE_OBJECT_TYPES = '/api/v1/product';
  static GET_INSURED_OBJECTS = '/api/v1/product/insured_objects';
  static ADD_ADDITIONAL_AGREEMENT = '/api/v1/contract/asa';
  static REPLACE_PRODUCT = '/api/v1/contract/replace_product';
  static UPDATE_INSURED_OBJECTS = '/api/v1/product/insured_objects/add_update';
  static UPDATE_PRODUCT_VERSION = '/api/v1/product/state';
  static VALUE_TARGET_ATTRIBUTE = '/api/v1/value_calculator/attribute';
  static GET_DATA_SOURCE_DETAILS = '/api/v1/value_calculator/data_source';
  static ADD_UPDATE_FORMULA = '/api/v1/value_calculator/formula';
  static GET_FORMULA_LIST = '/api/v1/value_calculator/formula';
  static GET_ACTION_BAR = '/api/v1/product/get_action_bar_data';
  static GET_CONTRACT_DOCUMENT_TYPE = '/api/v1/contract_document_types';
  static GET_CALCULATIONS = '/api/v1/value_calculator/calculation';
  static GET_TEMPLATE_REPORTS = '/api/v1/jasper/reports';
  static CREATE_POLICY = '/api/v1/jasper/create_policy';
  static GET_ALL_TEMPLATES = '/api/v1/jasper/get_all_templates';
  static ADD_TEMPLATE_CONFIG = '/api/v1/jasper/add_template_config';
  static GET_PRODUCT_SPECIFIC_TEMPLATE = '/api/v1/jasper/template';
  static GET_TEMPLATE_SPECIFIC_PRODUCTS = '/api/v1/jasper/product/template';
  static GET_CUSTOMER_ACTION_BAR = '/api/v1/customer/action_bar';
  static GET_CUSTOMER_DETAILS = '/api/v1/customer/customer_details';
  static COMMUNICATION_DETAILS = '/api/v1/communication_details';
  static GET_COMMUNICATION_OVERVIEW = '/api/v1/communication_details/overview';
  static GET_CUSTOMER_GENERAL_DATA = '/api/v1/customer/general_data';
  static GET_SALUTATION = '/api/v1/customer/salutation';
  static GET_BANKS = '/api/v1/bank_details';
  static GET_CLASSIFICATION = '/api/v1/customer/classification_lookup';
  static GET_STATUS_REASON = '/api/v1/customer/status_reason';
  static GET_SALES_STATUS = '/api/v1/customer/sales_status';
  static GET_AGENTS = '/api/v1/customer/agent';
  static UPDATE_CUSTOMER_LOCK_STATE = '/api/v1/customer/update_lock_state';
  static GET_LOCKS = '/api/v1/locks';
  static IVMsync = '/api/v1/customer/ivm_sync_flag';
  static GET_DIFFERING_ACCOUNT_HOLDER =
    '/api/v1/customer/differing_account_holder';

  static GET_INSURENCE_DETAILS = '/api/v1/contract/insurer_calculator_data';
  static UPDATE_INSURANCE_CALC_DATA =
    '/api/v1/contract/insurer_calculator_data';
  static GET_ROLES = '/api/v1/customer/customer_roles';
  static ADD_ROLE = '/api/v1/customer/customer_role';
  static GET_CONTACT_PERSON_LIST = '/api/v1/customer/contact_persons';
  static GET_CONTACT_PERSON_DATA = '/api/v1/customer/contact_person';
  static GET_CUSTOMER_RELATIONSHIP = '/api/v1/customer/customer_relationship';
  static GET_RELATIONSHIP = '/api/v1/customer/relationship';
  static GET_CUSTOMERS_BASED_ON_DIVISION = '/api/v1/customer/lookup';
  static GET_VALUE_CALC_MENU = '/api/v1/value_calculator/menu';
  static GET_FORUMULA_LIST =
    '/api/v1/value_calculator/get_custom_created_attribute';
  static GET_FORM_FIELD_TYPES = '/api/v1/fg/field_type_data';
  static GET_FORM_LIST = '/api/v1/fg/form_details';
  // Stepper for details
  static GET_AVAILABLE_FORMS = '/api/v1/fg/forms';
  static GET_SELECTED_FORMS_FOR_PRODUCT = '/api/v1/fg/product_to_form_mappings';
  static UPLOAD_CSV = '/api/v1/fg/form_details/dropdown_data/upload';
  static GET_DROPDOWN_DATA = '/api/v1/fg/form_details/dropdown_data';
  static POST_FORM_DETAILS = '/api/v1/fg/form_details';
  static GET_FORM_DETAILS_BY_PRODUCT_ID = '/api/v1/contract';
  static UPDATE_CONTRACT_LOCK_STATE = '/api/v1/contract/update_lock_state';

  static EVALUATE_FORMULA = '/api/v1/value_calculator/evaluate/formula';
  static SAVE_FORMULA = '/api/v1/value_calculator/evaluate/formula/update';

  static CLAIM_CONTACT_PERSON = '/api/v1/claims/contact_persons';
  static GET_GENERAL_CLAIMS_DATA = '/api/v1/claims';
  static GET_TYPE_OF_CLAIMS = '/api/v1/claims/type';
  static SAVE_OUTBOUND_ENTRY = '/api/v1/insert_outbound_entry';
  static ACTION_BAR = '/api/v1/action_bar/';
  static RECENT_USER_LOGINS = '/api/v1/user/recent-logins';
  static LOGIN_DETAILS = '/api/v1/user/login-details';
  static GET_COUNTRIES = '/api/v1/countries';
  static GET_SECURE_DOCUMENT = '/api/v1/secure_doc';
  static GET_SECURE_LOGO = '/api/v1/logo';
  static GET_USER_PROFILE_PHOTO = '/api/v1/user_profile/photo';
  static GET_HELP_DOCUMENT = '/api/v1/help_document/';
  static AUTHENTICATION = '/api/v1/authentication';
  static LOGOUT = '/api/v1/logout';

  static EVENTS = '/api/v1/events';
  static ATTENDEES = '/api/v1/attendees';

  static TASKS = '/api/v1/tasks';

  static VIDEOS = '/api/v1/videos';

  static MS_TEMPLATES = '/api/v1/document-templates';

  static DROPDOWN_VALUES = '/api/v1/dropdownvalues';
}
