export const MULTILINGUAL = {
    SELECT: 'new-claims.contact_person.select_role'
};

export const INSURER_OVERVIEW_MULTILINGUAL = {
    SELECT_INSURER: 'insurer_management.overview.select_insurer',
    NUMBER_OF_INSURER: 'insurer_management.overview.number_of_insurer',
    NUMBER_OF_BROKER_COMMISSION: 'insurer_management.broker_commision_tab.number_of_broker'
};

export const ADD_INSURER_MAPPING_MULTILINGUAL = {
    INSURER_NAME: 'insurer_mapping.insurer_name',
    DIVISION: 'insurer_mapping.division',
    IVM_ID: 'insurer_mapping.IVM_ID',
    ADD_INSURER_MAPPING: 'insurer_mapping.add_insurer_mapping',
    FILL_REQUIRED_FIELDS: 'insurer_mapping.fill_required',
    SAVE: 'insurer_mapping.save'
};
