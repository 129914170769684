import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['modal.component.scss']
})

export class ModalComponent implements OnInit {
    @Input() width = 400;
    @Input() noActions: boolean;
    @Output() close: EventEmitter<void> = new EventEmitter();

    constructor() { }

    ngOnInit() {
        this.noActions = this.noActions !== undefined;
    }

    closeModal(): void {
        this.close.emit();
    }
}
