<div>
  <span class="criteria-heading">{{'objectModule.foundary_object.foundary' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="foundaryForm" id="foundaryForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="type_of_firing" class="long-label">{{'objectModule.foundary_object.type_firing' | translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="type_of_firing"
      placeholder="{{'objectModule.select_lable' | translate}}" formControlName="type_of_firing">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option [value]="type.id" *ngFor="let type of firingType">{{type.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_refractory_lining" class="long-label">{{'objectModule.foundary_object.refractory_lining' |
      translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_refractory_lining" id="is_refractory_lining">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.foundary_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.foundary_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_it_possible_to_switch_off_the_fuel_supply_from_a_safe_place"
      class="long-label">{{'objectModule.foundary_object.fuel_supply_switch' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_it_possible_to_switch_off_the_fuel_supply_from_a_safe_place"
        id="is_it_possible_to_switch_off_the_fuel_supply_from_a_safe_place">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.foundary_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.foundary_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_there_redundant_temperature_monitoring_with_emergency_shutdown"
      class="long-label">{{'objectModule.foundary_object.temperature_monitoring' | translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_there_redundant_temperature_monitoring_with_emergency_shutdown"
        id="is_there_redundant_temperature_monitoring_with_emergency_shutdown">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.foundary_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.foundary_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="do_furnaces_have_drip_pans_for_melt"
      class="long-label">{{'objectModule.foundary_object.trays_for_molten_metal' |
      translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="do_furnaces_have_drip_pans_for_melt" id="do_furnaces_have_drip_pans_for_melt">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.foundary_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.foundary_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="where_are_the_filters_for_the_furnace_dedusting_located"
      class="long-label">{{'objectModule.foundary_object.furnace_dedusting_located' | translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher"
      id="where_are_the_filters_for_the_furnace_dedusting_located"
      placeholder="{{'objectModule.select_lable' | translate}}"
      formControlName="where_are_the_filters_for_the_furnace_dedusting_located">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option [value]="dedusting.id" *ngFor="let dedusting of dedustingLocated">{{dedusting.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="are_the_filter_elements_combustible"
      class="long-label">{{'objectModule.foundary_object.filter_elements_combustible' |
      translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="are_the_filter_elements_combustible"
      placeholder="{{'objectModule.select_lable' | translate}}"
      formControlName="are_the_filter_elements_combustible">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option [value]="filter.id" *ngFor="let filter of filterElements">{{filter.desc}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="is_light_metals_are_also_cast" class="long-label">{{'objectModule.foundary_object.light_metals' |
      translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="is_light_metals_are_also_cast" id="is_light_metals_are_also_cast">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.foundary_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.foundary_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="basin_for_finishing" class="long-label">{{'objectModule.foundary_object.finishing_available' |
      translate}}</label>
    <div class="radio-group-align-long">
      <mat-radio-group formControlName="basin_for_finishing" id="basin_for_finishing">
        <mat-radio-button class="radio-pad" value=true>{{'objectModule.foundary_object.Yes' | translate}}
        </mat-radio-button>
        <mat-radio-button class="radio-pad" value=false>{{'objectModule.foundary_object.No' | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
