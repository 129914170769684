import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BankDetailsOverviewComponent } from '@components/insurer-management/bank-details-overview/bank-details-overview.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { MatTimepickerModule } from 'mat-timepicker';
import { FileUploadModule } from 'ng2-file-upload';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { HeaderModule } from '../components/header/header.module';
import { WarningPopupComponent } from '../modules/customer-management/general-customer-data/warning-popup/warning-popup.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { CreateAppointmentPopupComponent } from './components/appointments/create-appointment-popup/create-appointment-popup.component';
import { AvatarGroupComponent } from './components/avatar/avatar-group/avatar-group.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from './components/button/button.component';
import { ClausesExclusionsComponent } from './components/clauses-exclusions/clauses-exclusions.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { DeleteUserComponent } from './components/delete-user/delete-user.component';
import { DocumentsPopupComponent } from './components/documents-popup/documents-popup.component';
import { DocumentsTableComponent } from './components/documents-table/documents-table.component';
import { AddContactPersonComponentNew } from './components/generic-components/add-contact-person/add-contact-person.component';
import { AddressAutocompleteComponent } from './components/generic-components/address-autocomplete/address-autocomplete.component';
import { AttachTemplateComponent } from './components/generic-components/attach-template/attach-template.component';
import { CloseAlertComponent } from './components/generic-components/close-alert-dialog/close-alert-dialog.component';
import { CommunicationComponent } from './components/generic-components/communication/communication.component';
import { ContactPersonOverviewComponent } from './components/generic-components/contact-person-overview/contact-person-overview.component';
import { CreateDoumentPolicyComponent } from './components/generic-components/create-doument-policy/create-doument-policy.component';
import { CustomAutocompleteComponent } from './components/generic-components/custom-autocomplete/custom-autocomplete.component';
import { CustomPdfViewerComponent } from './components/generic-components/custom-pdf-viewer/custom-pdf-viewer.component';
import { CustomWarningPopupComponent } from './components/generic-components/custom-warning-popup/custom-warning-popup.component';
import { DeleteConfirmationComponent } from './components/generic-components/delete-confirmation/delete-confirmation.component';
import { FormulaMakerComponent } from './components/generic-components/formula-maker/formula-maker.component';
import { LHSRHSComponent } from './components/generic-components/formula-maker/lhs-rhs/lhs-rhs.component';
import { NestedIfComponent } from './components/generic-components/formula-maker/nested-if-component/nested-if-maker.component';
import { GeneralIntermediaryDetailsComponent } from './components/generic-components/general-intermediary-details/general-intermediary-details.component';
import { GenerateTableComponent } from './components/generic-components/generate-table/generate-table.component';
import { GenericAlertComponent } from './components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { GenericCommunicationComponent } from './components/generic-components/generic-communication/generic-communication.component';
import { TreeListComponent } from './components/generic-components/generic-tree-component/tree-list.component';
import { GenricAddBankDetailsComponent } from './components/generic-components/genric-add-bank-details/genric-add-bank-details.component';
import { InsuranceDetailsComponent } from './components/generic-components/insurance-details/insurance-details.component';
import { UploadDocumentComponent } from './components/generic-components/upload-document/upload-document.component';
import { ViewTemplateListComponent } from './components/generic-components/view-template-list/view-template-list.component';
import { ModalComponent } from './components/modal/modal.component';
import { PresentComponent } from './components/present/present.component';
import { PriorityComponent } from './components/priority/priority.component';
import { ProductAlertComponent } from './components/product-close-dialog/product-close-alert-dialog.component';
import { GuestOptionComponent } from './components/select-options/guest-option/guest-option.component';
import { PriorityOptionComponent } from './components/select-options/priority-option.component';
import { FilterSectionComponent } from './components/table/filter-section/filter-section.component';
import { PaginationComponent } from './components/table/pagination/pagination.component';
import { TableActionsComponent } from './components/table/table-actions/table-actions.component';
import { CreatedByComponent } from './components/table/table-column/created-by.component';
import { DateAndTimeComponent } from './components/table/table-column/date-and-time.component';
import { DueDateComponent } from './components/table/table-column/due-date.component';
import { GuestsComponent } from './components/table/table-column/guests.component';
import { PriorityColumnComponent } from './components/table/table-column/priority-column.component';
import { ReminderComponent } from './components/table/table-column/reminder.component';
import { StatusComponent } from './components/table/table-column/status.component';
import { UpdatedByComponent } from './components/table/table-column/updated-by.component';
import { TableComponent } from './components/table/table.component';
import { CreateTaskPopupComponent } from './components/tasks/create-task-popup/create-task-popup.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { UploadDocumentPopupComponent } from './components/upload-document-popup/upload-document-popup.component';
import { WriteLetterEmailComponent } from './components/write-letter-email-popup/write-letter-email-popup.component';
import { DecimalDirective } from './directives/decimal.directive';
import { DynamicCellDirective } from './directives/dynamic-cell.directive';
import { NumberDirective } from './directives/numbers-only.directive';
import { PreventSpaceDirective } from './directives/prevent-space.directive';
import { SafariNumberOnlyDirective } from './directives/safari-number-only.directive';
import { CurrencyCustomPipe } from './pipes/currency-custom.pipe';
import { CutStringPipe } from './pipes/cut-string.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { FilterOptionsPipe } from './pipes/filter-options.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { MultilingualPipe } from './pipes/multilingual.pipe';
import { RemoveStringPipe } from './pipes/remove-string.pipe';
import { StringFilterByPipe } from './pipes/stringFilterBy.pipe';
import { TranslateErrorPipe } from './pipes/translate-error.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { ICAcademyWidgetComponent } from './components/ic-academy-widget/ic-academy-widget.component';

const genericComponents = [
  GeneralIntermediaryDetailsComponent,
  AddContactPersonComponentNew,
  ContactPersonOverviewComponent,
  BankDetailsOverviewComponent,
  CommunicationComponent,
  AddressAutocompleteComponent,
  AttachTemplateComponent,
  CloseAlertComponent,
  CreateDoumentPolicyComponent,
  CustomAutocompleteComponent,
  CustomPdfViewerComponent,
  CustomWarningPopupComponent,
  DeleteConfirmationComponent,
  FormulaMakerComponent,
  LHSRHSComponent,
  NestedIfComponent,
  GenerateTableComponent,
  GenericAlertComponent,
  GenericCommunicationComponent,
  TreeListComponent,
  GenricAddBankDetailsComponent,
  InsuranceDetailsComponent,
  UploadDocumentComponent,
  ViewTemplateListComponent,
];

const components = [
  BreadcrumbsComponent,
  WarningPopupComponent,
  DocumentsTableComponent,
  ProductAlertComponent,
  ClausesExclusionsComponent,
  UploadDocumentPopupComponent,
  PresentComponent,
  DeleteUserComponent,
  ButtonComponent,
  ActionBarComponent,
  ModalComponent,
  DocumentsPopupComponent,
  ConfirmPopupComponent,
  AvatarComponent,
  AvatarGroupComponent,
  WriteLetterEmailComponent,
  FilterSectionComponent,
  PaginationComponent,
  TableComponent,
  CreatedByComponent,
  UpdatedByComponent,
  DateAndTimeComponent,
  AppointmentsComponent,
  GuestOptionComponent,
  TableActionsComponent,
  CreateAppointmentPopupComponent,
  TasksComponent,
  PriorityComponent,
  PriorityOptionComponent,
  CreateTaskPopupComponent,
  ReminderComponent,
  GuestsComponent,
  PriorityColumnComponent,
  DueDateComponent,
  StatusComponent,
  ICAcademyWidgetComponent,
  ...genericComponents,
];

const directives = [
  DecimalDirective,
  NumberDirective,
  SafariNumberOnlyDirective,
  DynamicCellDirective,
  PreventSpaceDirective,
];

const pipes = [
  MultilingualPipe,
  RemoveStringPipe,
  CutStringPipe,
  DateFormatPipe,
  CurrencyCustomPipe,
  StringFilterByPipe,
  TranslateErrorPipe,
  TruncateTextPipe,
  FilterOptionsPipe,
  InitialsPipe,
];

const modules = [CommonModule, FormsModule, HeaderModule, ReactiveFormsModule];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [
    TranslateModule,
    MatSelectModule,
    MatTooltipModule,
    FileUploadModule,
    MatIconModule,
    PdfViewerModule,
    NgxLoadingModule,
    MatTableModule,
    NgbModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSortModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    InfiniteScrollModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatDialogModule,
    MatChipsModule,
    MatTimepickerModule,
    MatTreeModule,
    MatRadioModule,
    ...modules,
  ],
  providers: [MultilingualPipe, DateFormatPipe, CurrencyCustomPipe],
  exports: [...modules, ...components, ...directives, ...pipes],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
