import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OnInit, OnDestroy, Component } from '@angular/core';
import { ComponentCanDeactivate } from '@shared/models/component-can-deactivate.model';
import { ShareDataService } from '@core/services/share-data.service';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { BehaviorSubject, forkJoin, iif } from 'rxjs';
import {
  DashboardItem,
  ReorderWidgetsRequest,
  Widget,
  WidgetId,
} from '@shared/models/widget.model';
import { WidgetsApiService } from '@core/services/httpcalls/widgets-api.service';
import { widgetImages } from './cd-dashboard.model';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';

export type FormattedWidget = Widget &
  Record<'imgEN' | 'imgDE', string> & { isSubscribed: boolean } & Record<
    'position' | 'dashboardId',
    number
  >;

export type FormattedDashboardWidget = Widget &
  Record<'position' | 'dashboardId', number>;

@Component({
  selector: 'app-customer-dashboard-configuration',
  templateUrl: './cd-dashboard-configuration.component.html',
  styleUrls: ['./cd-dashboard-configuration.component.css'],
})
export class CustomerDashboardConfigurationComponent
  extends BaseComponent
  implements OnInit, ComponentCanDeactivate, OnDestroy
{
  private readonly _isDirty$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  readonly isDirty$ = this._isDirty$.asObservable();

  private readonly _widgets$: BehaviorSubject<FormattedWidget[]> =
    new BehaviorSubject<FormattedWidget[]>([]);
  private readonly _reloadWidgets$: BehaviorSubject<null> =
    new BehaviorSubject<null>(null);
  readonly widgets$ = this._reloadWidgets$.pipe(
    switchMap(() =>
      forkJoin([
        this._widgetsService.getWidgets(false, +this._userService.getUserId()),
        this._widgetsService.getDashboardItems(this._userService.getUserId()),
      ]).pipe(
        map(items => this._formatWidgets(items[0], items[1])),
        tap((items: FormattedWidget[]) => this._widgets$.next(items)),
        tap(() => this._updateChosenWidget()),
        takeUntil(this._destroy$)
      )
    )
  );

  private readonly _chosenWidget$: BehaviorSubject<FormattedWidget | null> =
    new BehaviorSubject<FormattedWidget | null>(null);
  readonly chosenWidget$ = this._chosenWidget$.asObservable();

  readonly language$ = this._userService.locale$;

  private _userId: string;

  constructor(
    private readonly _widgetsService: WidgetsApiService,
    private readonly _shareService: ShareDataService,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchOnUserId();
    this._listenCloseDialog();
  }

  canDeactivate(): boolean {
    return !this._isDirty$.value;
  }

  isWidgetChosen(widgetId: WidgetId): boolean {
    return this._chosenWidget$.value?.id === widgetId;
  }

  setChosenWidget(widget: FormattedWidget): void {
    this._chosenWidget$.next(widget);
  }

  saveConfiguration(): void {
    this._widgetsService
      .updateOrder(this._getWidgetsForUpdate())
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._isDirty$.next(false);
        this._reloadWidgets$.next(null);
      });
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<string[]>) {
    if (
      previousIndex !== currentIndex &&
      this._widgets$.value[previousIndex].isSubscribed &&
      this._widgets$.value[currentIndex].isSubscribed
    ) {
      moveItemInArray(this._widgets$.value, previousIndex, currentIndex);
      this._isDirty$.next(true);
    }
  }

  changeState(widget: FormattedWidget): void {
    iif(
      () => widget.isSubscribed,
      this._widgetsService.unsubscribe(widget.dashboardId),
      this._widgetsService.subscribe(
        null,
        this._widgets$.value.findIndex(item => item.id === widget.id),
        +this._userId,
        widget.id
      )
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this._reloadWidgets$.next(null));
  }

  private _formatWidgets(
    widgets: Widget[],
    dashboardWidgets: DashboardItem[]
  ): FormattedWidget[] {
    return widgets
      .map(widget => {
        const dashboardItemAsWidget = dashboardWidgets.find(
          dashboardWidget => dashboardWidget.widget.id === widget.id
        );
        return {
          dashboardId: dashboardItemAsWidget?.id || null,
          position: _.isNil(dashboardItemAsWidget)
            ? null
            : dashboardItemAsWidget.position,
          imgEN: widgetImages[widget.id].imgEN,
          imgDE: widgetImages[widget.id].imgDE,
          isSubscribed: !!dashboardItemAsWidget,
          ...widget,
        };
      })
      .sort(this._sort);
  }

  private _updateChosenWidget(): void {
    if (!this._chosenWidget$.value) {
      return;
    }

    const chosenWidgetId = this._chosenWidget$.value.id;
    const updatedWidget = this._widgets$.value.find(
      widget => widget.id === chosenWidgetId
    );
    if (updatedWidget) {
      this._chosenWidget$.next(updatedWidget);
    }
  }

  private _getWidgetsForUpdate(): ReorderWidgetsRequest[] {
    return this._widgets$.value
      .filter((item: FormattedWidget) => item.isSubscribed)
      .map((widget, index) => {
        return {
          position: index,
          id: widget.dashboardId,
          widgetId: widget.id,
          userId: +this._userId,
        };
      });
  }

  private _sort(a: FormattedWidget, b: FormattedWidget): number {
    const posA = a.position;
    const posB = b.position;
    if (_.isNil(posA) && _.isNil(posB)) {
      return 0;
    } else if (_.isNil(posA)) {
      return 1;
    } else if (_.isNil(posB)) {
      return -1;
    } else {
      return posA - posB;
    }
  }

  private _listenCloseDialog(): void {
    this._shareService
      .getClickEvent()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.saveConfiguration());
  }

  private _watchOnUserId(): void {
    this._userService.userId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((userId: string) => (this._userId = userId));
  }
}
