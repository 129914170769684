<div class="appointments">
  <div class="appointments__header">
    <h6 class="appointments__title">{{ 'appointments.title' | translate }}</h6>

    <app-button *ngIf="source" (click)="openCreateAppointmentPopup()">
      <img src="assets/images/form/add.svg" alt="">
      {{ 'appointments.create_new_appointment' | translate }}
    </app-button>
  </div>

  <app-table
    [filters]="filters"
    (filterChanged)="filter($event)"
    (pageSizeChanged)="changePageSize($event)"
    [tableConfig]="tableConfig"
    [pagination]="pagination"
    (pageChanged)="changePage($event)"
    [data]="data"
    (actionClicked)="handleAction($event)"
    (sortChanged)="sort($event)">
  </app-table>
</div>