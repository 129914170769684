import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FilePickerComponent } from 'ngx-awesome-uploader';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { AZURE } from '@shared/constants/azure-service.constants';

@Component({
  selector: 'app-register-claim-step-three',
  templateUrl: './register-claim-step-three.component.html',
  styleUrls: ['./register-claim-step-three.component.css'],
})
export class RegisterClaimStepThreeComponent implements OnInit {
  @ViewChild('uploader', { static: true }) uploader: FilePickerComponent;
  Schadenhergang: any;
  Teile: any;
  Alllocations: any;
  filterlocation;
  filterPositionLat;
  filterPositionLang;
  showActive = false;
  openDocUpload: boolean;
  firstname = false;
  isdisabled = true;
  formData: FormGroup;
  selectedaddress: any;
  submitted: boolean;
  remainingText: number;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private serviceCall: ApiCallsService
  ) {
    if (this.Alllocations) {
      if (
        this.filterPositionLat !== '' &&
        this.filterPositionLat !== null &&
        this.filterPositionLat !== undefined &&
        this.filterPositionLang !== '' &&
        this.filterPositionLang !== null &&
        this.filterPositionLang !== undefined
      ) {
        this.showActive = true;
      }
    }
  }

  ngOnInit(): void {
    this.formData = this.fb.group({
      schadenatum: new FormControl('', [Validators.required]),
      Uhrzeit: new FormControl('', [Validators.required]),
      Kennzeichen: new FormControl(''),
      Schadenort: new FormControl(''),
      Schadenhergang: new FormControl(''),
      Teile: new FormControl(''),
      Angaben: new FormControl(''),
      können: new FormControl(''),
      Geschätze: new FormControl(''),
      Währung: new FormControl(''),
      Vorsteuerabzugsbe: new FormControl(''),
      Aktenzeichen: new FormControl(''),
      Auszahlungskonto: new FormControl(''),
      Polizeiliche: new FormControl(''),
      Bilder: new FormControl(''),
      Wertnachweis: new FormControl(''),
      Rechnung: new FormControl(''),
      AGB: new FormControl(''),
      Mängelanzeige: new FormControl(''),
      Skizzen: new FormControl(''),
      Auftragsunterlagen: new FormControl(''),
      Allgemeiner: new FormControl(''),
      Lieferschein: new FormControl(''),
      Haftbarhaltung: new FormControl(''),
      Frachtunterlagen: new FormControl(''),
      Meldebescheinigung: new FormControl(''),
      Sonstiges: new FormControl(''),
      email: new FormControl(''),
      mobile: new FormControl(''),
    });
    this.formData.controls.Polizeiliche.disable();
  }
  get f() {
    return this.formData.controls;
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formData.invalid) {
      return;
    }
    const controls = this.formData.controls;

    Object.keys(controls).forEach(key => {
      controls[key].markAsTouched();
    });
    // display form values on success
  }
  autoComplete(value) {
    this.Alllocations = [];
    this.filterlocation = [];
    if (value.target.value.length >= 3 && value.key !== undefined) {
      const subscription = AZURE.SUBSCRIPTION_KEY;
      const query = value.target.value;
      const language = 'en-US';
      const countrySet = 'US';
      this.serviceCall
        .getAutocomplete(subscription, query, language, countrySet)
        .subscribe(res => {
          this.Alllocations = res.results;
          this.filterAddressFromResults(this.Alllocations);
        });
    }
  }
  filterAddressFromResults(Alllocations: any) {
    this.filterlocation = Alllocations.map(obj => {
      return obj.address;
    });
    this.filterPositionLat = Alllocations.map(obj => {
      return obj.position.lat;
    });
    this.filterPositionLang = Alllocations.map(obj => {
      return obj.position.lat;
    });
  }

  selectedLocation(e) {
    const val = e.target.value;
    this.selectedaddress = this.filterlocation.filter(obj => {
      return obj.freeformAddress == val;
    });
  }
  navigateStepTwo() {
    this.router.navigateByUrl(ROUTES_PATH.REGISTER_CLAIM_STEP_TWO, {
      skipLocationChange: true,
    });
  }
  enablePolizeiliche() {
    this.formData.controls.Polizeiliche.enable();
  }
  uploadDoc() {
    this.openDocUpload = true;
  }
  hide() {
    this.openDocUpload = false;
  }
  valueChange(value) {
    this.remainingText = 1000 - value;
  }
}
