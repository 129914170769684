<form class="row object-module-html" [formGroup]="vehicleForm" id="vehicleForm" *ngIf="vehicleForm">

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.newObjecttype' | translate}}</label>
    <input type="text" maxlength="100" class="input" title="{{vehicleForm.controls['vehicle_newObjecttype'].value}}" formControlName="vehicle_newObjecttype" placeholder="{{'objectModule.newObjecttype' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!(vehicleForm.controls['vehicle_sequenceNumber']?.touched
                 && (hasFormError('vehicle_sequenceNumber','max') ||  hasFormError('vehicle_sequenceNumber','min'))) "> {{'objectModule.vehicle_sequenceNumber' | translate}}</label>
    <label for="" class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_sequenceNumber']?.touched
                 && (hasFormError('vehicle_sequenceNumber','max') ||  hasFormError('vehicle_sequenceNumber','min'))) "> {{'objectModule.vehicle_DigitFiveInteger' | translate}}</label>

    <input numbersOnly *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="5" class="input" formControlName="vehicle_sequenceNumber" placeholder="{{'objectModule.vehicle_sequenceNumber' | translate}}"
      (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)">
    <input numbersOnly *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)" class="input numberText"
      formControlName="vehicle_sequenceNumber" placeholder="{{'objectModule.vehicle_sequenceNumber' | translate}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_riskIndex' | translate}}</label>
    <mat-select class="dropdownStyle" #riskIndexDrop (click)="focusRiskIndexSearch()" (keyup.enter)="focusRiskIndexSearch()"
        placeholder="{{'objectModule.select_lable' | translate}}" formControlName="vehicle_riskIndex_select"
        (selectionChange)="occupationChanged($event)" (openedChange)="riskIndexControl.setValue('')">
      <input type="text" id="riskIndexSearch" class="searchYear" maxlength="20" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
      [formControl]="riskIndexControl">
      <mat-option style="opacity:0.5" (click)="riskIndexReset()">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let ele of vehicle_risk_indexFilter" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
    </mat-select>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " > {{'objectModule.vehicle_licence' | translate}}</label>
    <input type="text" maxlength="100" title="{{vehicleForm.controls['vehicle_licence'].value}}" class="input" formControlName="vehicle_licence" title="{{vehicleForm.controls['vehicle_licence'].value}}"  placeholder="{{'objectModule.vehicle_licence' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_manufacturer' | translate}}</label>
    <mat-select class="dropdownStyle" #manufacturerDD (click)="focusManufacturerSearch()"
    (keyup.enter)="focusManufacturerSearch()" placeholder="{{'objectModule.select_lable' | translate}}"
    formControlName="vehicle_manufacturer_select" (selectionChange)="manufacturerChanged($event)" (openedChange)="vehicleManufacturerControl.setValue('')">
      <input type="text" id="manufacturerSearch" class="searchYear" maxlength="20" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
      [formControl]="vehicleManufacturerControl">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let ele of vehicle_manufacturerFilter" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
    </mat-select>
  </div>


  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_type_Model' | translate}}</label>
    <input type="text" maxlength="50" title="{{vehicleForm.controls['vehicle_type_Model'].value}}" class="input" formControlName="vehicle_type_Model" placeholder="{{'objectModule.vehicle_type_Model' | translate}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_plainText' | translate}}</label>
    <input type="text" maxlength="100" title="{{vehicleForm.controls['vehicle_plainText'].value}}" class="input" formControlName="vehicle_plainText" placeholder="{{'objectModule.vehicle_plainText_placeholder' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.drive_type' | translate}}</label>
    <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="drive_type">
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let ele of drive_type" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
    </mat-select>

  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!(vehicleForm.controls['vehicle_powerOfEngine']?.touched
                 && (hasFormError('vehicle_powerOfEngine','max') ||  hasFormError('vehicle_powerOfEngine','min'))) "> {{'objectModule.vehicle_powerOfEngine' | translate}}</label>
    <label for="" class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_powerOfEngine']?.touched
                 && (hasFormError('vehicle_powerOfEngine','max') ||  hasFormError('vehicle_powerOfEngine','min'))) "> {{'objectModule.vehicle_DigitFourInteger' | translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" numbersOnly maxlength="4" class="input" formControlName="vehicle_powerOfEngine" (keydown)="typeNumber($event, 'keydown',4)"
      (paste)="typeNumber($event, 'paste',4)" placeholder="{{'objectModule.vehicle_powerOfEngine' | translate}}">
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="4" ng-pattern="[0-9]*" numbersOnly (paste)="typeNumberBrowserSpecific($event, 'paste',4)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',4)" class="input numberText"
      formControlName="vehicle_powerOfEngine" placeholder="{{'objectModule.vehicle_powerOfEngine' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_powerType' | translate}}</label>
    <div style="margin-top:10px">
      <mat-radio-group aria-label="" formControlName="vehicle_powerType">
        <mat-radio-button [value]="'kW'" style="margin-right:30px">kW</mat-radio-button>
        <mat-radio-button [value]="'PS'" style="margin-right:30px">PS</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!(vehicleForm.controls['vehicle_engineCapacity']?.touched
             && (hasFormError('vehicle_engineCapacity','max') ||  hasFormError('vehicle_engineCapacity','min'))) "> {{'objectModule.vehicle_engineCapacity' | translate}}</label>
    <label for="" class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_engineCapacity']?.touched
             && (hasFormError('vehicle_engineCapacity','max') ||  hasFormError('vehicle_engineCapacity','min'))) "> {{'objectModule.vehicle_DigitFiveInteger' | translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" numbersOnly type="number" maxlength="5" class="input" formControlName="vehicle_engineCapacity" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)"
      placeholder="{{'objectModule.vehicle_engineCapacity_placeholder' | translate}}">
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" numbersOnly ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)" class="input numberText"
      formControlName="vehicle_engineCapacity" placeholder="{{'objectModule.vehicle_engineCapacity_placeholder' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!((vehicleForm.controls['vehicle_identificationNumber']?.touched
     && hasFormError('vehicle_identificationNumber','pattern'))) "> {{'objectModule.vehicle_identificationNumber' | translate}}</label>
    <label class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_identificationNumber']?.touched
     && hasFormError('vehicle_identificationNumber','pattern')) " matTooltip="{{'objectModule.Identification_validation' | translate}}">
      {{'objectModule.Identification_validation' | translate}}
    </label>
    <input type="text" maxlength="100" title="{{vehicleForm.controls['vehicle_identificationNumber'].value}}" class="input" formControlName="vehicle_identificationNumber" placeholder="{{'objectModule.vehicle_identificationNumber' | translate}}">
  </div>


  <div class="col-md-3 col-sm-6 col-6" id="datePicker1">
    <label for="" class="label " *ngIf="!isDateError && !isDateInValid"> {{'objectModule.vehicle_firstRegistration' | translate}}</label>
    <label for="" class="touched-warning " *ngIf="isDateError"> {{'objectModule.Invalid_date' | translate}}</label>
    <label for="" class="touched-warning " *ngIf="isDateInValid"> {{'objectModule.Invalid_date' | translate}}</label>
    <mat-form-field class="date-picker" appearance="fill" id="datePicker">
      <span>
        <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
      </span>
      <input matInput [matDatepicker]="datepicker1" class="date-input" autocomplete="off" placeholder="{{'objectModule.vehicle_firstRegistration' | translate}}" formControlName="vehicle_firstRegistration" [max]="today"
        (dateChange)="onFocusOutDate($event)">
      <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker1">
        <mat-icon matDatepickerToggleIcon class="datePickerArrow">
          {{'claims.keyboard-arrow-down_text' | translate}}
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #datepicker1></mat-datepicker>
    </mat-form-field>

  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!(vehicleForm.controls['vehicle_payLoadinKG']?.touched
         && (hasFormError('vehicle_payLoadinKG','max') ||  hasFormError('vehicle_payLoadinKG','min'))) "> {{'objectModule.vehicle_payLoadinKG' | translate}}</label>
    <label for="" class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_payLoadinKG']?.touched
         && (hasFormError('vehicle_payLoadinKG','max') ||  hasFormError('vehicle_payLoadinKG','min'))) "> {{'objectModule.vehicle_DigitFiveInteger' | translate}}</label>
    <div class="smallInput" style="width: 65%;">
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="5" class="input leftbox" formControlName="vehicle_payLoadinKG" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)"
      placeholder="{{'objectModule.vehicle_payLoadinKG' | translate}}" numbersOnly
      >
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)" class="input numberText leftbox"
      formControlName="vehicle_payLoadinKG"
      placeholder="{{'objectModule.vehicle_payLoadinKG' | translate}}" numbersOnly
      >
      </div>
      <div class="smallInput" style="width:35%">
        <mat-select class="dropdownStyle switcherDrpdwn inputy switcher rightbox" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="vehicle_payloadUnit">
          <mat-option *ngFor="let item of pyload_unit" title="{{item.desc}}" [value]="item.id">{{item.desc}}</mat-option>
        </mat-select>
      </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!(vehicleForm.controls['vehicle_permissibleWeight']?.touched
     && (hasFormError('vehicle_permissibleWeight','max') ||  hasFormError('vehicle_permissibleWeight','min'))) "> {{'objectModule.vehicle_permissibleWeight' | translate}}</label>
    <label for="" class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_permissibleWeight']?.touched
     && (hasFormError('vehicle_permissibleWeight','max') ||  hasFormError('vehicle_permissibleWeight','min'))) "> {{'objectModule.vehicle_DigitFiveInteger' | translate}}</label>
    <input type="number" maxlength="100" class="input" title="{{vehicleForm.controls['vehicle_permissibleWeight'].value}}" numbersOnly formControlName="vehicle_permissibleWeight" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)" placeholder="{{'objectModule.vehicle_permissibleWeight' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label" matTooltip="{{'objectModule.vehicle_bodyType' | translate}}"> {{'objectModule.vehicle_bodyType' | translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="vehicle_bodyType" (openedChange)="search.value = ''">
      <input type="text" maxlength="20" class="searchYear" placeholder="{{'header.Suche_tooltip' | translate}}" preventSpace #search>
      <mat-option *ngFor="let bodyType of validation._filter(vehicle_bodyType, 'desc', search.value)" title="{{bodyType.desc}}" [value]="bodyType.id">{{bodyType.desc}}</mat-option>
    </mat-select>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_specialVehicle' | translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="vehicle_specialVehicle">
      <mat-option *ngFor="let special of vehicle_specialVehicle" title="{{special.desc}}" [value]="special.id">{{special.desc}}</mat-option>
    </mat-select>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_isSeasonal' | translate}}</label>
    <div style="margin-top:10px">
      <mat-radio-group aria-label="" formControlName="vehicle_isSeasonal">
        <mat-radio-button [value]="true" style="margin-right:30px" (change)="isSeasonChange(true)">{{'objectModule.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" style="margin-right:30px" (change)="isSeasonChange(false)">{{'objectModule.No' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!(vehicleForm.controls['vehicle_seasonStart']?.touched
     && (hasFormError('vehicle_seasonStart','max') ||  hasFormError('vehicle_seasonStart','min'))) "> {{'objectModule.vehicle_seasonStart' | translate}}</label>
    <label for="" class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_seasonStart']?.touched
     && (hasFormError('vehicle_seasonStart','max') ||  hasFormError('vehicle_seasonStart','min'))) "> {{'objectModule.vehicle_minMaxValue' | translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="2" class="input" formControlName="vehicle_seasonStart" (paste)="typeNumber($event, 'paste',2)" (keydown)="typeNumber($event, 'keydown',2)"
      placeholder="{{'objectModule.vehicle_seasonStart_placeholder' | translate}}">
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="2" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',2)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',2)" class="input numberText"
      formControlName="vehicle_seasonStart" placeholder="{{'objectModule.vehicle_seasonStart_placeholder' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label " *ngIf="!(vehicleForm.controls['vehicle_seasonEnd']?.touched
     && (hasFormError('vehicle_seasonEnd','max') ||  hasFormError('vehicle_seasonEnd','min')))  "> {{'objectModule.vehicle_seasonEnd' | translate}}</label>
    <label for="" class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_seasonEnd']?.touched
     && (hasFormError('vehicle_seasonEnd','max') ||  hasFormError('vehicle_seasonEnd','min'))) "> {{'objectModule.vehicle_minMaxValue' | translate}}</label>

    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="2" class="input" formControlName="vehicle_seasonEnd" (paste)="typeNumber($event, 'paste',2)" (keydown)="typeNumber($event, 'keydown',2)"
      placeholder="{{'objectModule.vehicle_seasonStart_placeholder' | translate}}">

    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="2" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',2)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',2)" class="input numberText"
      formControlName="vehicle_seasonEnd" placeholder="{{'objectModule.vehicle_seasonStart_placeholder' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.vehicle_functionType' | translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="vehicle_functionType">
      <mat-option *ngFor="let Type of vehicle_functionTypes" title="{{Type.desc}}" [value]="Type.id">{{Type.desc}}</mat-option>
    </mat-select>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">
        {{'objectModule.registration_certificate' | translate}}
    </label>
    <div style="display: flex;">
    <div class="documentUploadInput">
        <input class="input" readonly title="{{vehicleForm.controls['registration_certificate'].value}}" formControlName="registration_certificate"
            placeholder="{{'objectModule.registration_certificate' | translate}}">
    </div>
    <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="registration_certificate_popup()">
            <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
    </div>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">
        {{'objectModule.expert_report' | translate}}
    </label>
    <div style="display: flex;">
    <div class="documentUploadInput">
        <input class="input" readonly title="{{vehicleForm.controls['expert_report'].value}}" formControlName="expert_report"
            placeholder="{{'objectModule.expert_report' | translate}}">
    </div>

    <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="expert_report_popup()">
            <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
    </div>
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">
        {{'objectModule.acquisition_invoices' | translate}}
    </label>
    <div style="display: flex;">
    <div class="documentUploadInput">
        <input class="input" readonly title="{{vehicleForm.controls['acquisition_invoices'].value}}" formControlName="acquisition_invoices"
            placeholder="{{'objectModule.acquisition_invoices' | translate}}">
    </div>

    <div class="documentUploadButton">
        <button class="docButton" type="button" (click)="acquisition_invoices_popup()">
            <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
        </button>
    </div>
    </div>
  </div>

  <div class="differentHolder">
    <mat-slide-toggle formControlName="differentHolder_value">{{'objectModule.vehicle_holderType' | translate}}</mat-slide-toggle>
  </div>
  <br>

  <div *ngIf="vehicleForm.value.differentHolder_value" class="row" style="margin:0;width: 100%;">
    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label "> {{'objectModule.vehicle_holderType_name' | translate}}</label>

      <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="vehicle_holderType"
      (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')" (selectionChange)="search.value = ''" (openedChange)="search.value = ''">
        <input [hidden]="vehicle_holderTypes.length <= 6" type="text" maxlength="20" preventSpace class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #search>
        <mat-option *ngFor="let Type of validation._filter(vehicle_holderTypes, 'desc', search.value)" title="{{Type.desc}}" [value]="Type.id"
        [ngClass]="{'active-option': Type.id === vehicleForm.value.vehicle_holderType && !vehicleForm.value.vehicle_holderType}">{{Type.desc}}</mat-option>
      </mat-select>

    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label " *ngIf="!((vehicleForm.controls['vehicle_firstName']?.touched
       && hasFormError('vehicle_firstName','pattern')) )"> {{'objectModule.vehicle_firstName' | translate}}</label>

      <label class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_firstName']?.touched
       && hasFormError('vehicle_firstName','pattern')) ">
        {{'update-user.Nachname_validation' | translate}}
      </label>

      <input type="text" maxlength="25" class="input" (keypress)="nameValidation($event,25)" (paste)="nameValidation($event,25)" formControlName="vehicle_firstName" placeholder="{{'objectModule.vehicle_firstName' | translate}}">
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label " *ngIf="!((vehicleForm.controls['vehicle_lastName']?.touched
       && hasFormError('vehicle_lastName','pattern')) )"> {{'objectModule.vehicle_lastName' | translate}}</label>

      <label class="touched-warning" *ngIf="(vehicleForm.controls['vehicle_lastName']?.touched
       && hasFormError('vehicle_lastName','pattern')) ">
        {{'update-user.Nachname_validation' | translate}}
      </label>

      <input type="text" maxlength="25" class="input" (keypress)="nameValidation($event,25)" (paste)="nameValidation($event,25)" formControlName="vehicle_lastName" placeholder="{{'objectModule.vehicle_lastName' | translate}}">
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label "> {{'objectModule.vehicle_holderstreet' | translate}}</label>
      <input type="text" class="input" formControlName="vehicle_holderstreet" placeholder="{{'objectModule.vehicle_holderstreet' | translate}}">
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label "> {{'objectModule.vehicle_holderHouseName' | translate}}</label>
      <input type="text" class="input" formControlName="vehicle_holderHouseName" placeholder="{{'objectModule.vehicle_holderHouseName' | translate}}">
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label "> {{'objectModule.vehicle_postCode' | translate}}</label>
      <input type="text" class="input" formControlName="vehicle_postCode" placeholder="{{'objectModule.vehicle_postCode' | translate}}">
    </div>

    <div class="col-md-3 col-sm-6 col-6">
      <label for="" class="label "> {{'objectModule.vehicle_place' | translate}}</label>
      <input type="text" class="input" formControlName="vehicle_place" placeholder="{{'objectModule.vehicle_place' | translate}}">
    </div>

    <div class="col-md-3 col-sm-6 col-6">

      <label for="" class="label"> {{'objectModule.vehicle_country' | translate}}</label>

      <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="vehicle_country"
      (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')" (selectionChange)="searchCountry.value = ''" (openedChange)="searchCountry.value = ''">
        <input [hidden]="(countries$ | async).length <= 6" type="text" maxlength="20" preventSpace class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #searchCountry>
        <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
        <mat-option  [ngClass]="{'active-option': country.countryName === vehicleForm.value.vehicle_country && !vehicleForm.value.vehicle_country}"
        *ngFor="let country of validation._filter(countries$ | async, 'countryName', searchCountry.value)" [value]="country.countryName">{{country.countryName}}</mat-option>
      </mat-select>

    </div>
  </div>

</form>
<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray"
                        [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)"></app-upload-document>
