<div class="edit-block contact-edit">
    <div class="contracts-tabs-heading">
        <h6 style="font-weight: 600;">{{'customer-management.contact_person.contact_person' | translate}}</h6>
    </div>
    <div class="form">
        <form class="general-form object-module-html" id="form" [formGroup]="contactPersonForm" *ngIf="contactPersonForm">
            <div class="row">
                <div class="col-md-3 col-sm-3 flex-center" >                    
                    <div *ngIf="locale$ | async as locale" class="smallInput" style="width: 35%;">
                        <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.salutation' | translate}}</label><br/>
                        <mat-select class="dropdownStyle" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="salutation">
                        <mat-option style="opacity: 0.5;">{{'objectModule.select_lable' | translate}}</mat-option>
                        <mat-option [value]="salutation.salutation_id" *ngFor="let salutation of salutations">
                            <span>{{locale === "English(EN)" ? 
                                salutation.salutation_text_en : 
                                salutation.salutation_text_de}}</span>
                        </mat-option>
                        </mat-select>
                    </div>
                    <div class="smallInput" style="width:65%;margin-left: 5px;">
                        <label for="" *ngIf="!isFirstNameError" class="label" style="text-transform: initial;">{{'customer-management.contact_person.first_name' | translate}}</label>
                        <label for="" *ngIf="isFirstNameError" class="label" 
                            [ngClass]="{'label-error' : isFirstNameError}" style="text-transform: initial;"
                            title="{{'update-user.Vorname_validation' | translate}}">
                            {{'update-user.Vorname_validation' | translate}}
                        </label>
                        <input type="text" class="custom-input-field"  maxlength="100" 
                            placeholder="{{'customer-management.contact_person.first_name' | translate}}"
                            formControlName="first_name" title="{{contactPersonForm.controls['first_name'].value}}"
                            (blur)="validateName($event.target.value, 'first_name')">
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" *ngIf="!isLastNameError" class="label" style="text-transform: initial;">{{'customer-management.contact_person.last_name' | translate}}</label>
                    <label for="" *ngIf="isLastNameError" class="label" 
                        [ngClass]="{ 'label-error' : isLastNameError}" style="text-transform: initial;"
                        title="{{'update-user.Nachname_validation' | translate}}">
                        {{'update-user.Nachname_validation' | translate}}
                    </label>
                    <input type="text" class="custom-input-field" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.last_name' | translate}}"
                        formControlName="last_name" title="{{contactPersonForm.controls['last_name'].value}}"
                        (blur)="validateName($event.target.value, 'last_name')">
                </div> 
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.role' | translate}}</label><br/>
                    <app-custom-autocomplete class="autocomplete" [placeholder]="'objectModule.select_lable' | translate" [required]="false"
                        [lookup]="roles_lookup" [control]="contactPersonForm.controls['role']" [is_disabled]="false" (newValueSelected)="targetCustomerRole($event)"></app-custom-autocomplete>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="long-label">{{'customer-management.contact_person.main_contact_person' | translate}}</label><br/>
                    <div  class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="main_contact_person">
                        <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
                        <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
                    </mat-radio-group>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.comments' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.comments' | translate}}"
                        formControlName="comments" title="{{contactPersonForm.controls['comments'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.street' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.street' | translate}}"
                        formControlName="street" title="{{contactPersonForm.controls['street'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.zip' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.zip' | translate}}"
                        formControlName="zip" title="{{contactPersonForm.controls['zip'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.city' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.city' | translate}}"
                        formControlName="city" title="{{contactPersonForm.controls['city'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.phone' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.phone' | translate}}"
                        formControlName="telephone" title="{{contactPersonForm.controls['telephone'].value}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.contact_person.email' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" 
                        placeholder="{{'customer-management.contact_person.email' | translate}}"
                        formControlName="email" title="{{contactPersonForm.controls['email'].value}}">
                </div>
            </div>
        </form>
    </div>    
</div>
<div class="save-container" *ngIf="contactPersonForm">
    <button class="custom-btn cancel-btn" (click)="close()">{{'template-management.cancel_label' | translate}}</button>
    <!-- <button class="custom-btn save-btn" (click)="save()" [disabled]="editCustomer || isFirstNameError || isLastNameError">{{'update-customer.Speichern_text' | translate}}</button> -->
    <button class="custom-btn save-btn" (click)="save()" [disabled]="false">{{'update-customer.Speichern_text' | translate}}</button>
</div>
<app-warning-popup *ngIf="showWarningPopUp" [customerWarningMsg]="customerWarningMsg" (savedata)="saveContactPerson($event)" (handlePopup)="handleWarningPopup($event)" [disableSave]="isFirstNameError || isLastNameError"></app-warning-popup>
