<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic">
    <div *ngIf="sparteManagement ==  false">
        <app-breadcrumbs [isCustomerSelected]="isCustomerSelected" [pageBreadcrumbText]="pageBreadcrumbText"
            [customerName]="customerName"></app-breadcrumbs>
        <div>
            <div class="row mt-5 ml-0">
                <div class="setting_box">
                    <div class="image-tab" (click)="Onuserprocess()">
                        <img alt="" src="../../../assets/images/Group 2613.png"><br>
                    </div>
                    <p style="margin-top:25px; color: #272727;">{{'settings.Persönliche_text' | translate}} </p>
                </div>
                <div class="setting_box" style="margin-left: 39px;" *ngIf="isCustomerAdmin">
                    <div class="image-tab" (click)="Onactionprocess()">
                        <img alt="" style="margin-left: 3px;
                        margin-top: -2px;" src="../../../assets/images/surface1.png">
                    </div>
                    <p style="margin-top:25px; color: #272727;">{{'settings.Firmenlogo_text' | translate}} </p>
                </div>
            </div>
        </div>
    </div>

    <div class="modal upload-logo modelBackground" id="myModal" [style.display]="showModal ? 'block' : 'none'"
        style="z-index: 4;overflow: scroll;">
        <div class="modal-dialog" style="top: 200px;">
            <div class="modal-content" style="height:363px;border: none;
            border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;">

                <!-- Modal Header -->
                <div class="modal-header text-color-bold modal-header-background">
                    <h6>{{'settings.Unternehmenslogo_text' | translate}} </h6>
                    <button style="background: transparent;" type="button" class="close text-color" data-dismiss="modal"
                        (click)="hide()">
                        <img alt="" style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
                    </button>
                </div>
                <form [formGroup]="formControl" (submit)="save()">
                    <!-- Modal body -->
                    <div class="modal-body" style="font-size:12px;height: 254px;">
                        <div style="text-align:center">
                            <div class="upload">
                                <label for="profile-picture">
                                    <input formControlName="file" id="profile-picture" type='file' name="file"
                                        accept="image/x-png,image/jpg,image/jpeg" (change)="onSelectFile($event)"
                                        style="display:none">
                                    <div class="logo-preview" *ngIf="url">
                                        <img alt="" [src]="url" style="height: 100px;width: auto;cursor: pointer;">
                                    </div>
                                    <img alt="" *ngIf="!url" src="../../../assets/images/logo-upload.png"
                                        style="cursor:pointer"><br />
                                    <p *ngIf="!url">{{'settings.choose_file_text' | translate}}</p>
                                </label>
                            </div>
                            <input formControlName="customerId" type='hidden' name="customerId" value="customer_id">
                            <br />
                            <p style="margin-top: -10px;">{{'settings.Unternehmenslogo_text' | translate}}</p>
                            <p style="margin-top: -16px;">{{'settings.DateiDarf_text' | translate}} </p>
                        </div>
                        <div style="text-align: center;
                        color: red;" *ngIf="message_NoLogo == true">{{'settings.Logo_not_present' | translate}}</div>
                    </div>

                    <div class="modal-footer" style="clear:both; display: block;">
                        <button type="button" mat-button (click)="deleteLogo()">{{'settings.LogoLöschen_text' |
                            translate}} </button>
                        <button type="button" mat-button (click)="hide()" color="primary">{{'settings.Abbrechen_text' |
                            translate}} </button>
                        <button type="submit" mat-button (click)="hide()" [disabled]="!this.selectedFile"
                            color="warn">{{'settings.Hochladen_text' |
                            translate}} </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="sparteManagement ==  true" class="" style="width:100%; height:100%; margin:0;padding:0px 38px">
        <h6 style="margin-top:9%;color:#272727;font-size: 18px;" class="user-name m-5">
            <strong><span>/</span>&nbsp;&nbsp;{{'settings.Sparte_hadding' | translate}} </strong>
        </h6>
        <div style="border: solid 2px #272727; border-top-width: 6px; padding-bottom: 98px;">
            <div class="row m-5">
                <div class="setting_box" style="margin-left: 39px;">
                    <div class="image-tab-inside">
                        <img alt="" (click)="sparteManagementOpen()" src="../../../assets/images/Sparete.png"><br>
                    </div>
                    <p style="margin-top:25px; color: #272727">{{'settings.Sparte_Management_text_2' | translate}} </p>
                </div>
            </div>
        </div>
    </div>
    
</div>