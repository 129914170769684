import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FeatherModule } from 'angular-feather';
import { Grid, LogOut, Search, Settings, User, Filter } from 'angular-feather/icons';

const icons = {
  Grid,
  Settings,
  User,
  LogOut,
  Search,
  Filter
}

@NgModule({
  declarations: [],
  imports: [
    FeatherModule.pick(icons),
    FontAwesomeModule
  ],
  exports: [
    FeatherModule,
    FontAwesomeModule
  ]
})
export class IconsModule { }
