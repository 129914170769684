import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@shared/modules/icons/icons.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule, MatFormFieldModule, MatSelectModule,
    NgbModule, ReactiveFormsModule, FormsModule,
    MatToolbarModule,
    RouterModule,
    MatIconModule,
    MatDividerModule,
    MatListModule, MatTooltipModule,
    TranslateModule,
    IconsModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
