import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { StepperLookupConstants } from '@shared/constants/product-management.constants';
import {
  ConsolidateData,
  ProductWarningMsg,
  UpdatedSelectionNodes,
} from '@shared/models/product-management.model';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { ShareDataService } from '@core/services/share-data.service';
import { ProductShareService } from '@core/services/product-share.service';
@Component({
  selector: 'product-base-stepper',
  templateUrl: 'product-base-stepper.component.html',
  styleUrls: ['product-base-stepper.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ProductBaseStepper implements OnInit, OnDestroy {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  productStpperForm = new FormControl('');
  coverBundelStepperForm = new FormControl('');
  coveringStepperForm = new FormControl('');
  damageStepperForm = new FormControl('');
  maskGeneratorStepperForm = new FormControl('');
  sparteDetailsStepperForm = new FormControl('');
  @Output('changeInProductManagement') changeInProduct =
    new EventEmitter<any>();
  allStepsArray = [];
  consolidateData: ConsolidateData;
  currentFormData: any;
  originalFormData: any;
  currentEvent: any;
  currentLookUpId: number;
  isFormValid = false;
  isFormTouched = false;
  customizedFormData: any;
  isProductUpdated = false;
  isFormulaUpdated = false;
  showWarningPopUp = false;
  isProductSaveWarning = false;
  isFormSubmitted = false;
  refreshStepData = false;
  nodeSelectionUpdate: UpdatedSelectionNodes[];
  isStepLoaded = false;
  isNextStep = false;
  productWarningMsg: ProductWarningMsg;
  productStateWarningMsg: ProductWarningMsg;
  selected_product_state_id: number;
  selected_product_version_no: number;
  original_product_state_id: number;
  userDataValue: any;
  isNewProduct = false;
  assignFormTo: any;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private translate: TranslateService,
    private serviceCall: ApiCallsService,
    private dialog: MatDialog,
    private router: Router,
    private _userService: UserService,
    private _shareDataService: ShareDataService,
    private _productShareService: ProductShareService
  ) {
    this.isProductSaveWarning = false;
    this.updateisProductUpdated(false);
    this.isFormulaUpdated = false;
    this.isNewProduct = false;
    this.userDataValue = this._userService.getLoggedInUser();
    this.productWarningMsg = {
      cancel: 'product-management.stepper_common_msg.continue',
      ok: 'product-management.stepper_common_msg.discard',
      save_and_continue:
        'product-management.stepper_common_msg.save_and_continue',
      header: 'objectModule.Delete_popup_heading',
      body: 'product-management.stepper_common_msg.product_form_discard_warning_msg',
    };
  }

  ngOnInit() {
    this.allStepsArray = [
      {
        index: 1,
        name: this.translate.instant('product-management.step.general_info'),
        stepperForm: this.productStpperForm,
        isEditable: true,
      },
      {
        index: 2,
        name: this.translate.instant(
          'product-management.step.product_module_cover_bundle'
        ),
        stepperForm: this.coverBundelStepperForm,
        isEditable: true,
      },
      {
        index: 3,
        name: this.translate.instant('product-management.step.covering'),
        stepperForm: this.coveringStepperForm,
        isEditable: true,
      },
      {
        index: 4,
        name: this.translate.instant('product-management.step.type_of_damage'),
        stepperForm: this.damageStepperForm,
        isEditable: true,
      },
      {
        index: 5,
        name: this.translate.instant('product-management.step.mask_generator'),
        stepperForm: this.maskGeneratorStepperForm,
        isEditable: true,
      },
      {
        index: 6,
        name: this.translate.instant('product-management.step.sparte_details'),
        stepperForm: this.sparteDetailsStepperForm,
        isEditable: true,
      },
      {
        index: 7,
        name: this.translate.instant('product-management.step.summary'),
        isEditable: true,
      },
    ];
    this.isStepLoaded = true;
    if (!this.consolidateData) {
      this.consolidateData = {};
      this.setStepperState(this.allStepsArray[0].stepperForm, true);
    }
  }

  isFormUpdated() {
    return (
      (this.isFormTouched || this.isProductUpdated || this.isFormulaUpdated) &&
      !this.isNewProduct
    );
  }

  setStepperState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ required: true });
    } else {
      control.reset();
    }
  }

  disableCompletedSteps(stepId: number, status: boolean) {
    for (let i = 0; i < stepId; i++) {
      this.allStepsArray[i].isEditable = !status;
    }

    if (!this.isNextStep && !status) {
      for (let i = 0; i < stepId; i++) {
        const lis: any = this.stepper.steps;
        lis._results[i]._editable = true;
      }
    }
  }

  getOriginalFormData(event) {
    this.originalFormData = event;
  }
  // On Form update on stepper component, this method will be invoked and holds the latest data s
  formValues(event) {
    this.currentEvent = event;
    this.currentFormData = event.fromValues;
    // in case of other steppers form is always valid
    this.isFormValid = event.valid;
    this.currentLookUpId = event.stepperLookupId;
    this.updateisFormTouchedValue(event.isFormTouched);
    if (this.stepper.selectedIndex == 0) {
      this.setStepperState(
        this.productStpperForm,
        !this.isFormValid || this.isFormTouched
      );
    }
    if (!this.isProductUpdated && this.isFormTouched) {
      this.updateisProductUpdated(this.isFormTouched);
    }
    if (this.stepper.selectedIndex != 0) {
      this.setStepperState(
        this.allStepsArray[this.stepper.selectedIndex].stepperForm,
        this.isFormTouched
      );
      this.disableCompletedSteps(
        this.stepper.selectedIndex,
        this.isFormTouched
      );
    }
  }

  selectionChange(event) {
    if (event.selectedStep.interacted) {
      event.selectedStep.interacted = false;
    }
    this.isStepLoaded = false;
    // Any data in the previous will be saved from here
    this.saveStep(event.previouslySelectedIndex);
  }

  prevStep(stepId: number) {
    this.isNextStep = false;
    if (!this.checkFormValidaty(stepId)) {
      return;
    }
    this.validateStepStateVersionAndProceed(stepId);
  }

  nextStep(stepId: number) {
    this.isNextStep = true;
    if (!this.checkFormValidaty(stepId)) {
      return;
    }
    this.validateStepStateVersionAndProceed(stepId);
  }

  validateStepStateVersionAndProceed(stepId: number) {
    if (
      this.currentFormData &&
      this.currentFormData.product_state_id == 2 &&
      this.isFormTouched
    ) {
      this.showWarningPopupOnProductSave();
    } else {
      this.proceedFurther();
    }
  }

  proceedFurther() {
    this.isStepLoaded = false;
    this.setStepperState(this.productStpperForm, false);
    this.proceedWithNextStep();
  }

  setVersionDetails(details) {
    this.selected_product_state_id = Number(details.selectedState);
    this.original_product_state_id = Number(details.originalState);
    this.selected_product_version_no = details.selected_product_version_no;
    this.currentFormData = details.product_data;
    this.currentFormData.product_name = this.currentFormData.name;
  }

  submitProduct(stepId: number) {
    const product_state_updated =
      this.original_product_state_id != this.selected_product_state_id
        ? true
        : false;
    if (!this.isFormUpdated() && !product_state_updated) {
      this.rerouteToProductListing();
    }
    if (this.original_product_state_id == 1 && product_state_updated) {
      if (this.selected_product_state_id == 2) {
        this.currentFormData.product_state_id = this.selected_product_state_id;
        this.currentFormData.version_no = this.selected_product_version_no;
        this.currentFormData.document_ids = [];
        this.saveProductStateAndExit();
      } else if (this.selected_product_state_id == 3) {
        this.currentFormData.product_state_id = this.selected_product_state_id;
        this.currentFormData.document_ids = [];
        this.saveProductStateAndExit();
      }
    } else if (
      this.original_product_state_id == 1 &&
      this.isFormUpdated() &&
      !product_state_updated
    ) {
      this.updateProductVersion(false);
    }

    // Else is not requried, if the product state is active any changes in the form will be update on click on next only
    if (this.original_product_state_id == 2 && product_state_updated) {
      if (this.selected_product_state_id == 1) {
        this.updateisProductUpdated(true);
        this.updateProductVersion(false);
      } else if (this.selected_product_state_id == 3) {
        this.currentFormData.product_state_id = this.selected_product_state_id;
        this.currentFormData.document_ids = [];
        this.saveProductStateAndExit();
      }
    }
  }

  rerouteToProductListing() {
    this.changeInProduct.emit(false);
    this._shareDataService.productManagementTouched = false;

    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router
      .navigate([ROUTES_PATH.RCS_SETUP], { queryParams: { active: 'three' } })
      .then(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = prev;
        this.router.onSameUrlNavigation = prevOSN;
      });
  }
  saveStepThree(stepId: number) {
    if (this.isFormTouched) {
      this.showWarningPopUp = true;
    }
    this.saveNodeSelections();
  }

  checkFormValidaty(stepId: number) {
    if (
      stepId + 1 == StepperLookupConstants.GENERAL_INFORMATION &&
      !this.isFormValid
    ) {
      // set the below flag to enable mandatory fields in the form
      this.isFormSubmitted = true;
      this.formValidMessage();
      return false;
    }
    if (
      this.isFormTouched &&
      stepId + 1 != StepperLookupConstants.GENERAL_INFORMATION
    ) {
      this.showWarningPopUp = true;
      return false;
    }
    return true;
  }

  saveStep(stepId: number) {
    if (stepId + 1 == StepperLookupConstants.GENERAL_INFORMATION) {
      this.saveForm();
    } else {
      this.saveNodeSelections();
    }
  }

  handlePopup(event) {
    this.showWarningPopUp = false;
    if (
      this.isProductSaveWarning &&
      !event &&
      this.currentFormData &&
      this.currentFormData.product_state_id == 2
    ) {
      this.isProductSaveWarning = false;
      this.updateProductVersion(true);
    } else if (!event) {
      this.isProductSaveWarning = false;
      this.saveStepperFormData();
    } else {
      this.isProductSaveWarning = false;
    }
  }

  saveStepperFormData() {
    this.updateisFormTouchedValue(false);
    this.setStepperState(
      this.allStepsArray[this.stepper.selectedIndex].stepperForm,
      false
    );
    this.disableCompletedSteps(this.stepper.selectedIndex, false);
    this.proceedWithNextStep();
  }
  saveModalValue(event) {
    this.showWarningPopUp = false;
    if (this.stepper.selectedIndex == 4) {
      this.saveMaskGeneratorInfo();
      this.updateisFormTouchedValue(false);
      this.setStepperState(
        this.allStepsArray[this.stepper.selectedIndex].stepperForm,
        false
      );
      this.disableCompletedSteps(this.stepper.selectedIndex, false);
      this.proceedWithNextStep();
    } else if (this.stepper.selectedIndex == 5) {
      // TO DO Implement Save logic
      this.saveCustomForm(true);
    } else {
      if (
        this.currentEvent &&
        this.currentEvent.fromValues &&
        !this.currentEvent.fromValues.name
      ) {
        this.formValidMessage();
        return false;
      }
      const isRightHandDataSaved = this.validateAndSaveForm(this.currentEvent);
      if (isRightHandDataSaved) {
        this.saveNodeSelections();
        this.updateisFormTouchedValue(false);
        this.setStepperState(
          this.allStepsArray[this.stepper.selectedIndex].stepperForm,
          false
        );
        this.disableCompletedSteps(this.stepper.selectedIndex, false);
        this.proceedWithNextStep();
      }
    }
  }

  saveNodeSelections() {
    if (this.nodeSelectionUpdate && this.nodeSelectionUpdate.length > 0) {
      const request = {
        selections: this.nodeSelectionUpdate,
      };
      this.serviceCall
        .saveTreeSelection(request)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.updateIsInitialProductSave();
          if (!data.HasErrors) {
            this.updateisProductUpdated(true);
            this.isStepLoaded = true;
            this.nodeSelectionUpdate = [];
            this.showToastMessage('product-management.added_success_msg');
          }
        });
    } else {
      this.isStepLoaded = true;
    }
  }

  // Direct call from each stepper to save the right hand form
  saveValues(event) {
    this.formValues(event);
    // make it to false as we already saving the form
    this.updateisFormTouchedValue(false);
    this.validateAndSaveForm(event);
  }

  saveFormulaValue(event) {
    this.updateisFormulaTouchedValue(event);
  }

  validateAndSaveForm(event) {
    if (!this.isFormValid) {
      this.isFormSubmitted = true;
      this.formValidMessage();
      return false;
    }
    if (event.isMaskGenSave) {
      this.saveMaskGeneratorInfo();
    } else {
      this.saveForm();
    }
    return true;
  }

  saveForm() {
    if (this.currentLookUpId == StepperLookupConstants.GENERAL_INFORMATION) {
      this.saveProductInfo();
    } else {
      this.saveStepperInfo();
    }
  }

  saveStepperInfo() {
    const request = {
      data: this.currentFormData,
    };
    this.serviceCall
      .saveStepperData(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.updateIsInitialProductSave();
        if (!data.HasErrors) {
          this.updateisProductUpdated(true);
          this.showToastMessage('product-management.product_module_added_text');
          this.refreshStepData = !this.refreshStepData;
        }
      });
  }

  isProductdeleteSuccess(event) {
    if (event) {
      this.updateIsInitialProductSave();
    }
    this.updateisProductUpdated(event);
  }

  saveMaskGeneratorInfo() {
    const request = {
      data: this.currentFormData,
    };
    this.serviceCall
      .saveMaskGeneratorData(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.updateIsInitialProductSave();
        if (!data.HasErrors) {
          this.updateisProductUpdated(true);
          this.showToastMessage('product-management.added_success_msg');
          this.refreshStepData = !this.refreshStepData;
        }
      });
  }

  updatedNodeSelection(selection) {
    this.nodeSelectionUpdate = selection;
    this.changeInProduct.emit(true);
    this._shareDataService.productManagementTouched = true;
  }

  saveProductInfo() {
    if (this.isFormTouched) {
      if (!this.currentFormData.product_info_id) {
        this.isNewProduct = true;
      }
      !this.currentFormData.product_info_id && (this.currentFormData.is_update = false) ;
      this.serviceCall
        .saveNewProduct(this.currentFormData)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.updateIsInitialProductSave();
          if (!data.hasErrors) {
            if (this.isNewProduct) {
              this.updateisProductUpdated(false);
              this.currentFormData.product_info_id = data.product_info_id;
              this.currentFormData.created_by = data.created_by;
            }
            this.updateisFormTouchedValue(false);
            this._productShareService.productModuleId = data.product_info_id;
            this.isStepLoaded = true;
            this.consolidateData.productId = data.product_info_id
              ? data.product_info_id
              : this.currentFormData.product_info_id;
            this.consolidateData.productName =
              this.currentFormData.product_name;
            this.consolidateData.productStateId =
              this.currentFormData.product_state_id;
            const message = this.currentFormData.product_info_id
              ? 'product-management.product_added_text'
              : 'product-management.product_update_text';
            this.showToastMessage(this.translate.instant(message));
          } else {
            this.showToastMessage(data.message);
          }
        });
    } else if (this.currentFormData && this.currentFormData.product_info_id) {
      this._productShareService.productModuleId =
        this.currentFormData.product_info_id;
      this.isStepLoaded = true;
      this.consolidateData.productId = this.currentFormData.product_info_id;
      this.consolidateData.productName = this.currentFormData.product_name;
      this.consolidateData.productStateId =
        this.currentFormData.product_state_id;
    }
  }

  saveProductStateAndExit() {
    this.currentFormData.is_update = true;
    this.currentFormData.is_form_save = true;
    this.currentFormData.updated_by =
      this.userDataValue.user_profile.user_data.user_id;
    this.serviceCall
      .saveNewProduct(this.currentFormData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.updateIsInitialProductSave();
        if (!data.hasErrors) {
          this.isStepLoaded = true;
          this.showToastMessage(
            this.translate.instant('product-management.product_update_text')
          );
        } else {
          this.showToastMessage(data.message);
        }
        this.rerouteToProductListing();
      });
  }

  formValidMessage() {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(
          'product-management.validation_message'
        ),
      },
      disableClose: false,
    });
  }

  showToastMessage(message) {
    const x = document.getElementById('successToast');
    if (x) {
      x.textContent = this.translate.instant(message);
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }

  showWarningPopupOnProductSave() {
    if (this.currentFormData && this.currentFormData.product_state_id == 2) {
      this.productStateWarningMsg = {
        cancel: 'product-management.stepper_common_msg.continue',
        ok: 'product-management.stepper_common_msg.save_and_continue',
        header: 'objectModule.Delete_popup_heading',
        body: 'product-management.stepper_common_msg.update_product_warning',
      };
      this.isProductSaveWarning = true;
    }
  }

  updateProductVersion(isFromDataSaveEnabled: boolean) {
    if (
      this.currentFormData &&
      (this.currentFormData.product_info_id || this.currentFormData.id) &&
      (this.isFormTouched ||
        this.isFormulaUpdated ||
        (this.isProductUpdated && !isFromDataSaveEnabled))
    ) {
      const product_info_id = this._productShareService.productModuleId;
      const datToUpdateVersion = {
        product_module_id:
          this.currentFormData.product_info_id || product_info_id,
        created_by: this.userDataValue.user_profile.user_data.user_id,
      };
      this.serviceCall
        .updateProductVersion(datToUpdateVersion)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.updateIsInitialProductSave();
          if (!data.HasErrors) {
            if (isFromDataSaveEnabled && data.product_module_id) {
              this.serviceCall
                .getProductData(Number(data.product_module_id))
                .subscribe(data1 => {
                  if (!data1.HasErrors) {
                    this.updateisProductUpdated(false);
                    const draftproductName = this.removeLastWord(
                      data1.product.name
                    );
                    this.currentFormData.product_name =
                      this.currentFormData.product_name == draftproductName
                        ? data1.product.name
                        : this.currentFormData.product_name;
                    this.currentFormData.product_info_id =
                      data.product_module_id;
                    this.currentFormData.product_state_id = 1;
                    this.currentFormData.version_no = 'V0';
                    if (
                      this.originalFormData &&
                      this.originalFormData.document_ids &&
                      this.currentFormData &&
                      this.currentFormData.document_ids
                    ) {
                      this.currentFormData.document_ids =
                        this.currentFormData.document_ids.filter(
                          docId =>
                            this.originalFormData &&
                            !this.originalFormData.document_ids.includes(docId)
                        );
                    }
                    this.proceedFurther();
                  }
                });
            } else {
              this.rerouteToProductListing();
            }
          }
        });
    }
  }

  removeLastWord(str: string) {
    const lastIndexOfSpace = str.lastIndexOf(' ');
    if (lastIndexOfSpace === -1) {
      return str;
    }
    return str.substring(0, lastIndexOfSpace);
  }

  proceedWithNextStep() {
    if (this.isNextStep) {
      this.stepper.next();
    } else {
      this.stepper.previous();
    }
  }

  updateisFormulaTouchedValue(value: boolean) {
    this.isFormulaUpdated = value;
    this.updateProductDetails(value);
  }

  formsUpdated(value: any) {
    this.assignFormTo = value.assignFormTo;
    this.isFormTouched = value.isFormUpdate;
    this.customizedFormData = value.data;
    this.updateProductDetails(value.isFormUpdate);
    this.setStepperState(
      this.allStepsArray[this.stepper.selectedIndex].stepperForm,
      this.isFormTouched
    );
    this.disableCompletedSteps(this.stepper.selectedIndex, this.isFormTouched);
    if (value.saveFrom) {
      this.saveCustomForm(false);
    }
  }

  saveCustomForm(proceedNext: boolean) {
    this.serviceCall
      .updateSelectedForms(
        this.consolidateData.productId,
        this.assignFormTo,
        this.customizedFormData
      )
      .subscribe(value => {
        this.updateIsInitialProductSave();
        if (!value.HasErrors) {
          this.showToastMessage('product-management.form_module_added_text');
          this.isFormTouched = false;
          this.updateisProductUpdated(true);
          this.setStepperState(
            this.allStepsArray[this.stepper.selectedIndex].stepperForm,
            false
          );
          this.disableCompletedSteps(this.stepper.selectedIndex, false);
          if (proceedNext) {
            this.proceedWithNextStep();
          }
        }
      });
  }

  updateisFormTouchedValue(value: boolean) {
    this.isFormTouched = value;
    this.updateProductDetails(value);
  }

  updateisProductUpdated(value: boolean) {
    this.isProductUpdated = value;
    this.updateProductDetails(value);
  }

  updateProductDetails(value: boolean) {
    const isValueUpdated =
      this._shareDataService.productManagementTouched || false;
    if (!isValueUpdated && value) {
      this.changeInProduct.emit(value);
      this._shareDataService.productManagementTouched = value;
    }
  }

  updateIsInitialProductSave() {
    if (!this._shareDataService.isInitialProductSave) {
      this._shareDataService.isInitialProductSave = true;
    }
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
