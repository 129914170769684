<form class="row object-module-html" [formGroup]="inventoryGoodsForm" id="goodsForm" *ngIf="inventoryGoodsForm">

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label "> {{'objectModule.newObjecttype' | translate}}</label>
    <input type="text" maxlength="100" class="input" formControlName="objectType" title="{{inventoryGoodsForm.controls['objectType'].value}}" placeholder="{{'objectModule.newObjecttype' | translate}}">
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for=""  *ngIf="!maxStorageError && !maxStorageError1" class="label"> {{'objectModule.max_Storage_Value' | translate}}</label>
    <label title="{{'objectModule.limitError' | translate}}" class="touched-warning custom-ellipsis"
    *ngIf="maxStorageError || maxStorageError1">
    <span [ngStyle]="{'text-transform' : (selectLang$ | async) === 'English(EN)' ? 'capitalize' : 'inherit'}">
      {{'objectModule.max_Storage_Value' | translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
    <div style="display: flex;">
      <mat-select id="currencyDropdown" (selectionChange)="currancyChanged($event)"
      (click)="currencyTypeOneSearch()" (keyup.enter)="currencyTypeOneSearch()"
      class="dropdownStyle currencyDrop currecnyStyle" formControlName="currency_type_id"
      [disableOptionCentering]="true" panelClass="currencyTypeDropdown" title="{{selectedCurrency}}" #currencyTypemaxStorage>
        <mat-select-trigger>
          <span>{{currancySymbol}}</span>
        </mat-select-trigger>
        <input type="text" class="searchYear" id="currencyTypeOneSearch" maxlength="5" (click)="resetList()" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}"
         matInput [formControl]="currencyType">
        <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()" [value]="ele.id" matTooltip="{{ele.cur_symbol}}">
          <span style="display: flex;">
            <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
            <span style="justify-content: end;">{{ele.cur_symbol}}</span>
          </span>
        </mat-option>
      </mat-select>
      <input  type="text" style="border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      height: 38px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input newValue"  (paste)="onTypeNewValue($event, 'paste','maximum_storage_value')" (keypress)="onTypeNewValue($event, 'keypress','maximum_storage_value')" (blur)="changeNumber($event.target.value, 'maximum_storage_value')" formControlName="maximum_storage_value" placeholder="{{'objectModule.max_Storage_Value' | translate}}">
      <input  type="text" style="border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"  (paste)="onTypeNewValue($event, 'paste','maximum_storage_value')" (keyup)="onTypeNewValue($event, 'keypress','maximum_storage_value')" (blur)="changeNumber($event.target.value, 'maximum_storage_value')" formControlName="maximum_storage_value" placeholder="{{'objectModule.max_Storage_Value' | translate}}">
    </div>

  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for=""  *ngIf="!maxAvgError && !maxAvgError1" class="label" style="text-transform: none;"> {{'objectModule.avg_stock_value' | translate}}</label>
    <label title="{{'objectModule.limitError' | translate}}" class="touched-warning custom-ellipsis"
    *ngIf="maxAvgError || maxAvgError1">
    <span [ngStyle]="{'text-transform' : (selectLang$ | async) === 'English(EN)' ? 'capitalize' : 'inherit'}">
      {{'objectModule.avg_stock_value' | translate}}</span> : {{'objectModule.limitError' | translate}}"</label>

    <div style="display: flex;">
      <mat-select id="currencyDropdown" (selectionChange)="currancyChangedTwo($event)" class="dropdownStyle currencyDrop currecnyStyle"
      (click)="currencyTypeTwoSearch()" (keyup.enter)="currencyTypeTwoSearch()"
      formControlName="currency_type_id" [disableOptionCentering]="true" panelClass="currencyTypeDropdown"
      title="{{selectedCurrency}}"
      #currencyTypeAverageStock>
        <mat-select-trigger>
          <span>{{currancySymbol}}</span>
        </mat-select-trigger>
        <input type="text" class="searchYear" id="currencyTypeTwoSearch" maxlength="5" (click)="resetList()" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}"
        matInput [formControl]="currencyType">
        <mat-option *ngFor="let ele of allCurrencyValues" (click)="resetList()" [value]="ele.id" matTooltip="{{ele.cur_symbol}}">
          <span style="display: flex;">
            <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
            <span style="justify-content: end;">{{ele.cur_symbol}}</span>
          </span>
        </mat-option>
      </mat-select>
      <input  type="text" style="border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      height: 38px;" maxlength="100" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input newValue"  (paste)="onTypeNewValue($event, 'paste','average_stock_value')" (keypress)="onTypeNewValue($event, 'keypress','average_stock_value')" (blur)="changeNumber($event.target.value, 'average_stock_value')" formControlName="average_stock_value" placeholder="{{'objectModule.avg_stock_value' | translate}}">
      <input  type="text" style="border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"  (paste)="onTypeNewValue($event, 'paste','average_stock_value')" (keyup)="onTypeNewValue($event, 'keypress','average_stock_value')" (blur)="changeNumber($event.target.value, 'average_stock_value')" formControlName="average_stock_value" placeholder="{{'objectModule.avg_stock_value' | translate}}">
    </div>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.value_type_label' | translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="value_type_id" (selectionChange)="search.value = ''" (openedChange)="search.value = ''">
      <input type="text" maxlength="20" class="searchYear" preventSpace placeholder="{{'header.Suche_tooltip' | translate}}" #search>
      <mat-option title="{{'objectModule.select_lable' | translate}}" [value]="" style="color: #797979 !important;">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let ele of validationService._filter(allValueTypes, 'name', search.value)" title="{{ele.name}}" [value]="ele.id">{{ele.name}}</mat-option>
    </mat-select>
  </div>

  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.Description_label' | translate}}</label>
    <input type="text" maxlength="100" class="input"  title="{{inventoryGoodsForm.controls['description'].value}}"  formControlName="description" placeholder="{{'objectModule.Description_label' | translate}}">
  </div>
</form>
