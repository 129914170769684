import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeString'
})
export class RemoveStringPipe implements PipeTransform {

  transform(value: string): unknown {
    value = value.replace(/&amp;/g, '&');
    return value;
  }

}
