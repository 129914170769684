export const DOC_POPUP_PAGINATION_TEXT = 'new-contract.subordinate_contracts.pagination';

export const DOC_POPUP_COLUMN_NAMES = {
    TYPE: 'documentType',
    DESCRIPTION: 'description',
    CREATED_BY: 'createdBy',
    SOURCE_TARGET: 'sourceTarget',
    UPLOAD_DATE: 'uploadDate',
    ACTION: 'action'
};

export const DOC_POPUP_COLUMNS = [
    DOC_POPUP_COLUMN_NAMES.TYPE,
    DOC_POPUP_COLUMN_NAMES.DESCRIPTION,
    DOC_POPUP_COLUMN_NAMES.CREATED_BY,
    DOC_POPUP_COLUMN_NAMES.SOURCE_TARGET,
    DOC_POPUP_COLUMN_NAMES.UPLOAD_DATE,
    DOC_POPUP_COLUMN_NAMES.ACTION
];

export const DOC_POPUP_HEADINGS = {
    TYPE: 'document-table.type',
    DESCRIPTION: 'document-table.description',
    CREATED_BY: 'document-table.created_by',
    SOURCE_TARGET: 'document-table.source_target',
    UPLOAD_DATE: 'document-table.uploading_date',
    ACTION: 'contracts.AKTION_text',
};

export const METHOD_CALL = {
    PDF: 'pdf',
};

export const DOC_POPUP_ACTION_TOOLTIPS = {
    PDF: 'contracts.Öffnen_tooltip',
};
