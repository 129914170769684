<div class="communication-edit">
    <div class="contracts-tabs-heading">
        <h6 style="font-weight: 600;" *ngIf="!addCommunication">
            {{'customer-management.communication-details.edit_Communication' | translate}}</h6>
        <h6 style="font-weight: 600;" *ngIf="addCommunication">
            {{'customer-management.communication-details.add_communication' | translate}}</h6>
    </div>
    <div class="form">
        <form class="general-form product-module-html" *ngIf="communicationForm" [formGroup]="communicationForm"
            id="form">
            <div class="row">
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label"
                        style="text-transform: initial;">{{'customer-management.communication-details.communication-type'
                        | translate}}</label><br />
                    <mat-select class="dropdownStyle" placeholder="{{'header.Suche_tooltip' | translate}}"
                        (selectionChange)="selectCommunication($event.value);communicationForm.controls['contact'].reset()"
                        formControlName="communicationType">
                        <mat-option [value]="communication.id" *ngFor="let communication of communication_types">
                            {{communication.desc}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label"
                        style="text-transform: initial;">{{'customer-management.communication-details.contact' |
                        translate}}</label><br />
                    <input type="text" [class.is-invalid]="communicationForm.get('contact').invalid" class="input"
                        maxlength="100" formControlName="contact"
                        title="{{communicationForm.controls['contact'].value}}"
                        placeholder="{{'customer-management.communication-details.contact' | translate}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label"
                        style="text-transform: initial;">{{'customer-management.communication-details.description' |
                        translate}}</label><br />
                    <input type="text" class="input" maxlength="100"
                        title="{{communicationForm.controls['description'].value}}" formControlName="description"
                        placeholder="{{'customer-management.communication-details.description' | translate}}">
                </div>
            </div>
        </form>
    </div>
    
</div>
<div class="save-container">
    <button class="custom-btn save-btn" (click)="saveCommunication()" *ngIf="communicationForm"
        [disabled]="communicationForm.get('contact').invalid || !this.isValueChangedInForm || is_disable">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>