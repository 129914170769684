import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
const moment = require('moment-timezone');

import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css'],
})
export class PropertyComponent implements OnInit {
  liztu: any;
  customer_data: any;
  questions: any;
  decoded_liztu: any;
  formFilter: FormGroup;
  browser: string;
  is_update = false;
  showSuccessModel = false;
  lastUpdatedDate: any;
  decoded_customer: string;
  quesObject: any = {
    31: 1,
    32: 4,
    33: 9,
    34: 8,
    35: 3,
    36: 2,
    37: 10,
  };
  showTable: boolean;
  questionData: any;
  userData: any;
  users: any = [];
  cetOffset = moment().tz('Europe/Berlin')._offset;
  CUSTOMER_TYPES = CUSTOMER_TYPES;
  customer_id: string;
  constructor(
    private fb: FormBuilder,
    private apiCallService: ApiCallsService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private dateFormatPipe: DateFormatPipe,
    private _userService: UserService,
    private _customerSharedService: CustomerSharedService
  ) {
    this.formFilter = this.fb.group({
      show_all_from_organization: [true],
      show_country_wise_data: [false],
    });
  }

  ngOnInit(): void {
    this.userData = this._userService.getLoggedInUser();
    this.browser = this.detectBrowser();
    this.decoded_liztu = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu = atob(this.decoded_liztu);
    this.lastUpdatedDate = '';
    this.getData();
  }
  saveObject() {
    if (this.hasError()) {
      this.formValidMessage('value-module.validation_message');
      return;
    }
    const utcDate = moment().tz('Europe/Berlin').format();
    const payload = {
      data: [],
      user_id: this.getUser.user_id,
      value_module_type: 2,
      is_update: this.is_update,
      is_country_wise_aggregated_data:
        this.formFilter.controls.show_country_wise_data.value,
    };
    this.questions.forEach(customer => {
      const queData = [];
      customer.questions_data.forEach(que => {
        if (!que.read_only) {
          que.updated_by =
            que.created_by && que.isChanged
              ? this.getUser.user_id
              : que.updated_by || '';
          que.updated_at =
            que.created_by && que.isChanged ? utcDate : que.updated_at || '';
          que.updated_by_name =
            que.created_by && que.isChanged
              ? this.getUser.first_name + ' ' + this.getUser.last_name
              : que.updated_by_name || '';
          que.created_by = que.created_by
            ? que.created_by
            : que.isChanged
              ? this.getUser.user_id
              : '';
          que.created_at = que.created_at
            ? que.created_at
            : que.isChanged
              ? utcDate
              : '';
          que.created_by_name = que.created_by_name
            ? que.created_by_name
            : que.isChanged
              ? this.getUser.first_name + ' ' + this.getUser.last_name
              : '';
          queData.push({
            questionId: que.question_id,
            value: que.value,
            updated_by: que.updated_by,
            updated_at: que.updated_at,
            updated_by_name: que.updated_by_name,
            created_by: que.created_by,
            created_at: que.created_at,
            created_by_name: que.created_by_name,
          });
        }
      });
      payload.data.push({
        litzu_vn_kdnr: customer.liztu_vn_kdnr,
        value_module_id: this.is_update ? customer.value_module_id : 0,
        location_ids: customer.loction_ids,
        questions_data: JSON.stringify(queData),
      });
    });
    this.apiCallService.saveQuestionsData(payload).subscribe(
      data => {
        this.customer_data.forEach((customer, index) => {
          customer.value_module_id = data.response_data[index].value_module_id;
        });
        this.showSuccessModel = true;
      },
      err => {
        this.formValidMessage('value-module.error_text');
      }
    );
  }
  showData(event) {
    this.getData();
  }
  getData() {
    this.showTable = false;
    const requests = [];
    const login_customer_liztu =
      this.userData.user_profile.customer_details.liztu_vn_kdnr;

    this._customerSharedService.selectedCustomerType$
      .pipe(
        filterNil(),
        take(1),
        mergeMap(type => {
          const payload = {
            login_customer: login_customer_liztu,
            liztu_vn_kdnr: this.liztu,
            customer_type: type,
            show_all_from_organization:
              this.formFilter.controls.show_all_from_organization.value,
            show_country_wise_data:
              this.formFilter.controls.show_country_wise_data.value,
            value_module_tab_id: 2,
          };
          requests.push(
            this.apiCallService
              .getValueCustomer(payload)
              .pipe(catchError(error => of(error)))
          );
          requests.push(
            this.apiCallService
              .getValueQuestions(2)
              .pipe(catchError(error => of(error)))
          );

          return forkJoin(requests);
        })
      )
      .subscribe((data: any) => {
        this.customer_data =
          !data[0].hasErrors && data[0].customer_data
            ? data[0].customer_data
            : [];
        const customer_data = JSON.parse(JSON.stringify(this.customer_data));
        const questions =
          !data[1].hasErrors && data[1].questions ? data[1].questions : [];
        this.questionData = JSON.parse(JSON.stringify(questions));
        const customerIds = [
          ...new Set(this.customer_data.map(obj => obj.liztu_vn_kdnr)),
        ];
        const getQes = {
          liztu_vn_kdnr: customerIds,
          show_all_from_organization:
            this.formFilter.controls.show_all_from_organization.value,
          show_country_wise_data:
            this.formFilter.controls.show_country_wise_data.value,
          value_module_tab_id: 2,
        };
        this.apiCallService
          .getQuestionsData(getQes)
          .pipe(catchError(error => of(error)))
          .subscribe(resp => {
            const queData = !resp.hasErrors && resp.data ? resp.data : [];
            this.lastUpdatedDate =
              !resp.hasErrors && resp.updated_at ? resp.updated_at : '';
            this.is_update = this.lastUpdatedDate ? true : false;
            queData.forEach(element => {
              element.questions_data = JSON.parse(element.questions_data);
            });
            customer_data.forEach(customer => {
              customer.questions_data = JSON.parse(JSON.stringify(questions));
              customer.questions_data.forEach(element => {
                if (queData && queData.length) {
                  const selectedCustomer = queData.filter(
                    _ =>
                      _.location_ids.join('') == customer.loction_ids.join('')
                  );
                  if (selectedCustomer.length) {
                    const values = selectedCustomer[0].questions_data.filter(
                      _ => _.questionId == element.question_id
                    );
                    if (element.question_id == 38) {
                      element.value =
                        (selectedCustomer[0].questions_data.filter(
                          _ => _.questionId == 31
                        ).length
                          ? selectedCustomer[0].questions_data.filter(
                              _ => _.questionId == 31
                            )[0].value
                          : 0) +
                        (selectedCustomer[0].questions_data.filter(
                          _ => _.questionId == 32
                        ).length
                          ? selectedCustomer[0].questions_data.filter(
                              _ => _.questionId == 32
                            )[0].value
                          : 0) +
                        (selectedCustomer[0].questions_data.filter(
                          _ => _.questionId == 33
                        ).length
                          ? selectedCustomer[0].questions_data.filter(
                              _ => _.questionId == 33
                            )[0].value
                          : 0) +
                        (selectedCustomer[0].questions_data.filter(
                          _ => _.questionId == 34
                        ).length
                          ? selectedCustomer[0].questions_data.filter(
                              _ => _.questionId == 34
                            )[0].value
                          : 0) +
                        (selectedCustomer[0].questions_data.filter(
                          _ => _.questionId == 37
                        ).length
                          ? selectedCustomer[0].questions_data.filter(
                              _ => _.questionId == 37
                            )[0].value
                          : 0);
                    } else {
                      if (element.data_type != 7) {
                        element.value = values.length ? values[0].value : '';
                      } else {
                        const date = values.length ? values[0].value : null;
                        element.value =
                          date && date.toLowerCase() != 'invalid date'
                            ? this.dateFormatPipe.transform(new Date(date))
                            : '';
                      }
                      if (!element.read_only) {
                        element.orignalValue = values.length
                          ? values[0].value
                          : '';
                        element.updated_by =
                          values.length && values[0].updated_by
                            ? values[0].updated_by
                            : '';
                        element.updated_by_name =
                          values.length && values[0].updated_by_name
                            ? values[0].updated_by_name
                            : '';
                        element.updated_at =
                          values.length && values[0].updated_at
                            ? values[0].updated_at
                            : '';
                        element.created_by =
                          values.length && values[0].created_by
                            ? values[0].created_by
                            : '';
                        element.created_by_name =
                          values.length && values[0].created_by_name
                            ? values[0].created_by_name
                            : '';
                        element.created_at =
                          values.length && values[0].created_at
                            ? values[0].created_at
                            : '';
                      }
                    }
                    customer.value_module_id =
                      selectedCustomer[0].value_module_id;
                  } else {
                    element.value = '';
                  }
                }
              });
            });
            this.questions = customer_data;
            this.showTable = true;
          });
      });
  }
  MaxHundredValidation(event, data) {
    if (+event.target.value > 100) {
      data.error = this.translate.instant('value-module.max100Error');
    } else {
      data.error = '';
    }
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  hasError() {
    let hasError = false;
    this.questions.forEach(customer => {
      const errordata = customer.questions_data.filter(_ => _.error);
      if (errordata.length) {
        hasError = true;
      }
    });
    return hasError;
  }
  formValidMessage(key) {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(key),
      },
      disableClose: false,
    });
  }
  redirectToObject(i, j) {
    if (
      Object.keys(this.quesObject).includes(
        this.questions[j].questions_data[i].question_id.toString()
      )
    ) {
      const decoded_loc = btoa(this.questions[j].loction_ids.join(','));
      const decoded_ques = btoa(
        this.quesObject[this.questions[j].questions_data[i].question_id]
      );
      this.router.navigate([ROUTES_PATH.OBJECT_MODULE], {
        queryParams: {
          liztu: this.decoded_liztu,
          location: decoded_loc,
          object: decoded_ques,
        },
      });
    }
  }
  auditValue(que) {
    if (que.value != que.orignalValue) {
      que.isChanged = true;
    } else {
      que.isChanged = false;
    }
  }
  get getUser() {
    return this.userData.user_profile.user_data;
  }
  getUserDetails(que) {
    if (this.users.filter(_ => _.id == que.updated_by).length) {
      que.updated_by_name = this.users.filter(
        _ => _.id == que.updated_by
      )[0].name;
      return;
    }
    this.apiCallService.getUserName(que.updated_by).subscribe(res => {
      if (!res.HasErrors) {
        que.updated_by_name = res.first_name + ' ' + res.last_name;
        this.users.push({
          id: que.updated_by,
          name: res.first_name + ' ' + res.last_name,
        });
      }
    });
  }
}
