import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _moment from 'moment';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { DeleteService } from '@core/services/shared/delete.service';
import { ToastService } from '@core/services/toast.service';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ClaimsComponentService } from './claims.component.service';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { ROLES } from '@shared/constants/roles.constants';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { ShareDataService } from '@core/services/share-data.service';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css'],
})
export class ClaimsComponent
  extends ClaimsComponentService
  implements OnInit, OnChanges, OnDestroy
{
  constructor(
    public dateFormatPipe: DateFormatPipe,
    public translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    public serviceCall: ApiCallsService,
    private fb: FormBuilder,
    private userService: UserService,
    public changeDetectorRefs: ChangeDetectorRef,
    public validationService: ValidationService,
    public _claimsAPIService: ClaimsManagementApiService,
    public _toastService: ToastService,
    public _deleteService: DeleteService,
    public _documentsApiService: DocumentManagementApiService,
    public _customerSharedService: CustomerSharedService,
    public _shareDataService: ShareDataService,
    public _dialog: MatDialog
  ) {
    super(
      serviceCall,
      dateFormatPipe,
      translate,
      changeDetectorRefs,
      _claimsAPIService,
      _deleteService,
      router,
      _toastService,
      _documentsApiService,
      _dialog,
      _customerSharedService
    );
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = ((data, filter) => {
      const date = data.date;
      const Jahr = this.moment(filter.Jahr).format('yyyy-MM-DD');
      const Jahr2 = this.moment(filter.Jahr2).format('yyyy-MM-DD');
      const sparte = data.sparte_id;
      const a = !filter.Schadenart || data.type === filter.Schadenart;
      const b = !filter.Objekt || data.object === filter.Objekt;
      let c;
      if (filter.Jahr !== null && filter.Jahr2 === null) {
        c = !filter.Jahr || date >= Jahr;
      } else if (filter.Jahr === null && filter.Jahr2 !== null) {
        c = !filter.Jahr2 || date <= Jahr2;
      } else if (filter.Jahr !== null && filter.Jahr2 !== null) {
        c = !filter.Jahr2 || (date >= Jahr && date <= Jahr2);
      } else {
        c = !filter.Jahr;
      }
      const d = !filter.einblenden || data.status !== 'Geschlossen';
      const e =
        !filter.KfzVerträge ||
        (sparte.indexOf('5') !== 0 && sparte.length === 3);
      const f =
        !filter.contractNumber ||
        data.contract_number.indexOf(filter.contractNumber) !== -1 ||
        data.contract_number === filter.contractNumber;
      return a && b && c && d && e && f;
    }) as (PeriodicElement, str) => boolean;

    this.formFilter = this.fb.group({
      Schadenart: [''],
      Objekt: [''],
      Jahr: [''],
      Jahr2: [''],
      KfzVerträge: [''],
      einblenden: [''],
      kosten: [''],
      wholeGroup: [''],
      contractNumber: '',
    });
  }
  ngOnInit() {
    this._watchOnLocale();
    this._watchOnUserRole();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.formFilter.reset();

    this.getQueryParams();

    this._customerSharedService.customerGeneralData$
      .pipe(filterNil(), take(1))
      .subscribe(customerGeneralData => {
        this.customer = customerGeneralData.customer_info.customer_id;
        this.type = customerGeneralData.customer_status.type;
        this.is_source_ivm = customerGeneralData.is_source_ivm;

        this.customerTypeChecks();
        this._getLogo();
      });

    this.routernavigate = this.router.events.subscribe(
      (event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['/contract'], {
            queryParams: {
              liztu: this.decoded_liztu,
            },
          });
        }
      }
    );
  }

  userDataReceived(data) {
    this.userData = data;
    this.userDataValue = this.userData;
    this.loggedInUserRole = this.userData.user_profile.user_data.role;
    this.divisionName = data.user_profile.customer_details.name;
    this.getQueryParams();
    if (this.decoded_contract !== null) {
      if (this.decoded_contract !== 'bnVsbA==') {
        this.showContract = true;
      }
    }
    this.callClaimsAPI();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getQueryParams();
    if (this.decoded_contract !== null) {
      if (this.decoded_contract !== 'bnVsbA==') {
        this.showContract = true;
      }
    }
    if (changes.recreateDataValue) {
      this.callClaimsAPI();
    }
    this.customerTypeChecks();

    if (this.decoded_contract !== null) {
      if (this.decoded_contract !== 'bnVsbA==') {
        this.showContract = true;
      }
    }
  }
  resetFormFilter() {
    this.formFilter.controls.Schadenart.reset();
    this.formFilter.controls.Objekt.reset();
    this.formFilter.controls.Jahr.reset();
    this.formFilter.controls.Jahr2.reset();
    this.formFilter.controls.KfzVerträge.reset();
    this.formFilter.controls.einblenden.reset();
    this.formFilter.controls.kosten.reset();
    this.formFilter.controls.wholeGroup.reset();
    this.formFilter.controls.contractNumber.reset();
  }
  showWholeGroup() {
    this.isWholeGroup = !this.isWholeGroup;
  }

  getQueryParams() {
    this.decoded_liztu = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu = atob(this.decoded_liztu);
    this.decoded_contract = this.route.snapshot.queryParamMap.get('contract');
    this.contract = Number(atob(this.decoded_contract));
    this.decoded_date = this.route.snapshot.queryParamMap.get('date');
    this.date = this.decoded_date === null ? null : atob(this.decoded_date);
    this.decoded_damage_id = this.route.snapshot.queryParamMap.get('damage');
    this.damage_id = atob(this.decoded_damage_id);
    this.decoded_liztu_sa_idnr =
      this.route.snapshot.queryParamMap.get('liztu_sa_idnr');
    this.liztu_sa_idnr_parameter = atob(this.decoded_liztu_sa_idnr);
  }
  customerTypeChecks() {
    this.receivedType = this._shareDataService.messageType;
    if (
      this.type === CUSTOMER_TYPES.MUTTER ||
      this.receivedType === CUSTOMER_TYPES.MUTTER
    ) {
      this.showWholeGroupCheck = true;
      this.resetFormFilter();
    } else {
      this.showWholeGroupCheck = false;
    }
    if (
      this.customerTypes.includes(this.type) ||
      this.customerTypes.includes(this.receivedType)
    ) {
      this.resetFormFilter();
      this.uncheck = false;
      this.formFilter.controls.wholeGroup.reset();
      this.isWholeGroup = false;
    }
  }
  addElement(contractNumber, value) {
    this.changeRowContractNumber = contractNumber;
    // this.changeTableRow = !this.changeTableRow;
    this.ClaimData.splice(value, 1);

    const contract = Object.keys(this.groupedRow).find(
      (contractNum: string) => contractNum === contractNumber
    );

    if (contract) {
      for (let k = 0; k < this.groupedRow[contract].length; k++) {
        if (k === this.groupedRow[contract].length - 1) {
          this.groupedRow[contract][k].group_row = true;
          this.groupedRow[contract][k].expand_row = false;
          this.groupedRow[contract][k].grouped_row_item_count =
            this.groupedRow[contract].length;
        }
        this.ClaimData.splice(value, 0, this.groupedRow[contract][k]);
      }
    }

    this.ClaimDataCount = this.ClaimData.length;
    this.displayContractsData();
  }
  collapse(element, value) {
    // this.changeTableRow = !this.changeTableRow;
    this.ClaimData.filter(
      x => x.contract_number === element.contract_number
    ).forEach(x => this.ClaimData.splice(this.ClaimData.indexOf(x), 1));
    element.group_row = true;
    element.expand_row = true;
    this.ClaimData.splice(value, 0, element);
    this.ClaimDataCount = this.ClaimData.length;
    this.displayContractsData();
  }
  sortByDate() {
    this.ClaimData.sort((a, b) =>
      this.dateSorted
        ? a.sortingDate > b.sortingDate
          ? 1
          : -1
        : a.sortingDate < b.sortingDate
          ? 1
          : -1
    );
    this.dateSorted = !this.dateSorted;
    this.renderTableData();
  }
  openStatusSelection() {
    this.openStatusFilter = !this.openStatusFilter;
  }
  checkStatusFieldClicked() {
    let statusVisible = this.statusAvailable.map(obj => {
      if (obj.value) {
        return obj.label;
      }
    });
    statusVisible = statusVisible.filter((e: any) => e !== null);
    this.ClaimData = this.claimsOriginalDataCopy.filter(
      obj => statusVisible.indexOf(obj.status) !== -1
    );
    this.renderTableData();
    this.dataLimit = this.ClaimData.length;
    this.ClaimDataCount = this.ClaimData.length;
  }
  renderTableData() {
    this.dataSource.data = [...this.ClaimData];
    this.changeDetectorRefs.detectChanges();
  }
  showhideGroupedData(contractNumber: string): void {
    // this.changeTableRow = !this.changeTableRow;
    this.showSparteFiveRecords = !this.showSparteFiveRecords;
    let index = -1;
    for (const i in this.ClaimData) {
      if (this.ClaimData[i].contract_number === contractNumber) {
        index = +i;
        break;
      }
    }
    if (this.showSparteFiveRecords) {
      this.ClaimData.splice(index, 1, ...this.sparte_five_records);
    }
  }
  // FUNCTION IS TO EXPORT TABLE DATA TO EXCEL SHEET
  async exportExcelData(value) {
    this.isExportClicked = true;
    let claimData: any;
    claimData = {
      customer_id: this.customer,
      is_export: true,
    };
    if (
      !value.Jahr &&
      !value.Jahr2 &&
      !value.Schadenart &&
      !value.Objekt &&
      !value.KfzVerträge &&
      !value.einblenden &&
      !value.kosten &&
      !value.wholeGroup &&
      !value.contractNumber &&
      this.kostenclicked &&
      this.isWholeGroup
    ) {
      if (this.fromMoredocument) {
        claimData.liztu_sa_idnr = this.liztu_sa_idnr_parameter;
      } else {
        claimData.liztu_vs_idnr = this.contract_id_all;
        claimData.claim_date = this.date;
      }

      this.excelClaimDataCall(claimData);
    } else if (this.isWholeGroup) {
      (claimData.whole_group = this.isWholeGroup),
        (claimData.hide_vehicle = value.KfzVerträge),
        (claimData.hide_closed = value.einblenden),
        (claimData.claim_type = value.Schadenart),
        (claimData.object = value.Objekt),
        (claimData.from = !value.Jahr
          ? null
          : _moment(value.Jahr).format('YYYY-MM-DD')),
        (claimData.to = !value.Jahr2
          ? null
          : _moment(value.Jahr2).format('YYYY-MM-DD')),
        this.excelClaimDataCall(claimData);
    } else {
      if (this.fromMoredocument && !this.isFilterApplied) {
        this.loadDamageData(this.customer, this.liztu_sa_idnr_parameter);
      } else if (this.loadClaimDataFlag && !this.isFilterApplied) {
        this.loadClaimDataFlag = true;
        this.loadUserClaimData(this.contract_id_all, this.damage_date);
      } else if (this.isFilterApplied) {
        this.getFormsValue(this.formFilter.value);
      } else if (this.getUserClaimsData && !this.isFilterApplied) {
        this.getUserClaimsData = true;
        this.loadUserClaimData(
          this.contract_id_all,
          this.damage_date,
          this.customer,
          this.liztu
        );
      }
    }
  }
  resetFilters() {
    if (this.kostenclicked) {
      // this.myCheckbox.checked = false;
      this.clickedKosten();
    }
    this.isWholeGroup = false;
    this.formFilter.reset();
    this.getFormsValue(null);
    this.isFilterApplied = false;
  }
  groupContractsData() {
    let index = -1;
    for (const i in this.ClaimData) {
      if (this.ClaimData[i].contract_number === this.contract_number_grouping) {
        index = +i;
        break;
      }
    }
    let sparteFiveRecords = this.ClaimData.filter(
      obj => obj.contract_number === this.contract_number_grouping
    );
    const sparteNonFiveRecords = this.ClaimData.filter(
      obj => obj.contract_number !== this.contract_number_grouping
    );
    if (sparteFiveRecords.length > 0) {
      sparteFiveRecords = sparteFiveRecords.map(obj => {
        delete obj.is_first;
        return obj;
      });
      sparteFiveRecords[0].is_first = true;
    }
    this.ClaimData = sparteNonFiveRecords;
    if (this.showSparteFiveRecords) {
      if (sparteFiveRecords.length > 0) {
        this.ClaimData.splice(index, 0, ...sparteFiveRecords);
      }
    } else {
      if (sparteFiveRecords.length > 0) {
        this.ClaimData.splice(index, 0, sparteFiveRecords[0]);
      }
    }
    this.displayContractsData();
  }
  sortColumn(key) {
    const _that = this;
    this.columnSorting[key] = !this.columnSorting[key];
    this.ClaimData.sort(
      _that.alphabetically(this.columnSorting[key] ? true : false, key)
    );
    this.sparte_five_records.sort(
      _that.alphabetically(this.columnSorting[key] ? true : false, key)
    );
    this.sparte_Non_five_records.sort(
      _that.alphabetically(this.columnSorting[key] ? true : false, key)
    );
    this.renderTableData();
  }
  alphabetically(ascending, key) {
    return (a, b) => {
      // equal items sort equally
      if (a[key] === b[key]) {
        return 0;
      }
      // nulls sort after anything else
      else if (a[key] === null) {
        return ascending ? -1 : 1;
      } else if (b[key] === null) {
        return ascending ? 1 : -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
        if (isNaN(a[key]) || isNaN(b[key])) {
          return a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1;
        } else {
          return a[key] < b[key] ? -1 : 1;
        }
      }
      // if descending, highest sorts first
      else {
        if (isNaN(a[key]) || isNaN(b[key])) {
          return a[key].toUpperCase() < b[key].toUpperCase() ? 1 : -1;
        } else {
          return a[key] < b[key] ? 1 : -1;
        }
      }
    };
  }
  sortReserve(key2) {
    this.columnSorting[key2] = !this.columnSorting[key2];
    this.ClaimData.sort((a, b) =>
      this.paymentSorted
        ? a.reserve > b.reserve
          ? 1
          : -1
        : a.reserve < b.reserve
          ? 1
          : -1
    );
    this.paymentSorted = !this.paymentSorted;
    this.renderTableData();
  }

  resetContract() {
    this.contract_id_all = null;
    this.showContract = false;
    this.loadClaimDataFlag = true;
    this.loadUserClaimData(this.contract_id_all, this.damage_date);
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.routernavigate.unsubscribe();
  }
  tablePageChanged(event) {
    this.tablePage = event;
    if (this.fromMoredocument && !this.isFilterApplied) {
      this.loadDamageData(this.customer, this.liztu_sa_idnr_parameter);
    } else if (this.loadClaimDataFlag && !this.isFilterApplied) {
      this.loadClaimDataFlag = true;
      this.loadUserClaimData(this.contract_id_all, this.damage_date);
    } else if (this.isFilterApplied) {
      this.getFormsValue(this.formFilter.value);
    } else if (this.getUserClaimsData && !this.isFilterApplied) {
      this.getUserClaimsData = true;
      this.loadUserClaimData(
        this.contract_id_all,
        this.damage_date,
        this.customer,
        this.liztu
      );
    }
  }

  private _watchOnLocale(): void {
    this.userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(locale => {
        this.selectLang = locale;
      });
  }

  private _watchOnUserRole(): void {
    this.userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.user_role = role;
        this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
        this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
      });
  }

  private _getLogo(): void {
    this.serviceCall
      .getLogo(this.customer)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data) {
          this.logo = window.URL.createObjectURL(data);
        }
      });
  }
}
