<div class="custom-form-container">
    <div class="custom-form-heading">
        {{'new-claims.payment_management.add_payment_details' | translate}}
    </div>
    <div class="custom-form">
        <form *ngIf="paymentForm" [formGroup]="paymentForm">
            <div class="row">
                 <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">
                        {{'new-claims.payment_management.payee_list' | translate}}
                    </label>
                    <mat-select class="custom-dropdown" placeholder="{{'objectModule.select_lable' | translate}}" 
                        [formControlName]="formFieldsName.PAYEE_LIST">
                        <input type="text" maxlength="20" class="input searchSelect"
                        preventSpace
                        placeholder="{{'header.Suche_tooltip' | translate}}" #search>
                        <mat-option [value]="null" class="dropdown-select">{{'objectModule.select_lable' | translate}}</mat-option>
                        <mat-option [value]="role.id" *ngFor="let role of _validationService._filter(payeeList, 'desc', search.value) ">
                            {{role.desc}}
                        </mat-option>
                    </mat-select>
                </div> 
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label custom-label-required"
                        [title]="_showError.getBillionErrorTooltip(paymentForm, formFieldsName.AMOUNT, 'new-claims.payment_management.amount')"
                        [ngClass]="_showError.showBillionOrReqLabelError(paymentForm, formFieldsName.AMOUNT)">
                        {{ 'new-claims.payment_management.amount' | translate }} 
                        {{ _showError.getBillionErrorMessage(paymentForm, formFieldsName.AMOUNT) }}
                    </label>
                    <div class="smallInput">
                        <div class="d-flex">
                          <mat-select class="custom-currency-dropdown" [ngClass]="_showError.showInputCurrencyError(paymentForm, formFieldsName.AMOUNT)"
                            (selectionChange)="currancyChanged($event.value)" 
                            [value]="currencyId" title="{{selectedCurrency}}">
                            <mat-select-trigger>
                              <span>{{ currancySymbol }}</span>
                            </mat-select-trigger>
                            <input type="text" class="input searchYear" preventSpace maxlength="5" autocomplete="off"
                              placeholder="{{'header.Suche_tooltip' | translate}}" #currency>
                            <mat-option *ngFor="let ele of _validationService._filter(currencyTypes, 'cur_symbol', currency.value)" [value]="ele.id" matTooltip="{{ele.cur_symbol}}">
                              <span class="d-flex">
                                <span class="currency-symbol">{{ele.cur_code}}</span>
                                <span>{{ele.cur_symbol}}</span>
                              </span>
                            </mat-option>
                          </mat-select>
                            <input class="custom-input-field currencyValueInput" [ngClass]="_showError.showInputError(paymentForm, formFieldsName.AMOUNT)"
                            [formControlName]="formFieldsName.AMOUNT"
                            placeholder="{{'new-claims.payment_management.amount' | translate}}"
                            GermanFormattingRequired BillionCheck>
                        </div>
                      </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label custom-label-required"
                        [ngClass]="_showError.checkInvalidLabel(paymentForm, formFieldsName.PAYMENT_DATE)">
                        {{'new-claims.payment_management.payment_date' | translate}}
                    </label>
                    <mat-form-field appearance="fill" class="custom-date-picker"
                        [ngClass]="_showError.checkInvalid(paymentForm, formFieldsName.PAYMENT_DATE)">
                        <span>
                            <img alt="" [src]="formImages.CALENDER">
                        </span>
                        <input matInput
                            (dateChange)="dateClicked($event)"
                            [matDatepicker]="datepicker1" class="date-input" autocomplete="off"
                            placeholder="{{'claim-reporting.datePlaceHolder' | translate }}" [formControlName]="formFieldsName.PAYMENT_DATE">
                        <mat-datepicker-toggle matSuffix [for]="datepicker1">
                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                {{arrows.DOWN_ARROW}}
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">{{'new-claims.payment_management.remark1' | translate}}</label>
                    <textarea class="custom-textarea" maxlength="5000" [formControlName]="formFieldsName.REMARK1"
                        [title]="paymentForm.controls[formFieldsName.REMARK1].value"
                        placeholder="{{'new-claims.payment_management.remark1' | translate}}">
                    </textarea>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="custom-label">{{'new-claims.payment_management.remark2' | translate}}</label>
                    <textarea class="custom-textarea" maxlength="5000" [formControlName]="formFieldsName.REMARK2"
                        [title]="paymentForm.controls[formFieldsName.REMARK2].value"
                        placeholder="{{'new-claims.payment_management.remark2' | translate}}">
                    </textarea> 
                </div>
            </div>
        </form>
    </div>    
</div>
<div class="save-container" *ngIf="paymentForm">
    <button class="custom-btn save-btn" (click)="savePaymentData()"
        [disabled]="isIvmSynced">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>
<app-warning-popup
    *ngIf="showWarningPopUp" 
    [customerWarningMsg]="customerWarningMsg" 
    (savedata)="savePayment($event)" 
    (handlePopup)="handleWarningPopup($event)">
</app-warning-popup>