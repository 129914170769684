<mat-dialog-content style="margin: -24px -24px 166px -24px; max-height: auto !important;" id="new-openprocess">
  <div class="new-editor-heading" style="border-bottom: 1px solid #d5d5d5;">
    <h2 matDialogTitle style="float: left;
        width: 50%;
        font-size: 17px;color: #272727;;margin-bottom:2px" class="mat-headline center-align">
      {{'contact-manager.New_editor_heading' | translate}}</h2>
    <button style="float: right; outline: no;
        color: #fff;
        margin-top: -2px;" (click)="onNoClick()">
      <mat-icon>
        <img alt="" style="vertical-align: sub;" src="../../../../assets/images/Group 3298.svg">
      </mat-icon>
    </button>
  </div>

  <form name="search" [formGroup]="searchForm">

    <div class="d-flex flex-row row-height">
      <div class="flex-fill">
        <mat-form-field appearance="fill" [ngClass]="{'red': mandant_color === true}" style="float: left; width: 49%;">
          <mat-label>{{'contact-manager.Mandant_text' | translate}}</mat-label>
          <mat-select required [(ngModel)]="data.mandant" name="mandant" *ngIf="divisionPreSelected == false"
            formControlName="mandant" placeholder="{{'contact-manager.Mandant_placeholder' | translate}}"
            (selectionChange)="getMandantId($event);search.value = ''" (openedChange)="search.value = ''">
            <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
              matInput #search>
            <mat-option *ngFor="let manadant of validationService._filter(allDivision, 'division_name', search.value)"
              [value]='manadant.division_id'>
              {{manadant.division_name}}
            </mat-option>
          </mat-select>
          <mat-select required *ngIf="divisionPreSelected == true" [(ngModel)]="selectedDivisionId" name="mandant"
            formControlName="mandant" placeholder="{{'contact-manager.Mandant_placeholder' | translate}}"
            (selectionChange)="getMandantId($event);search.value = ''" (openedChange)="search.value = ''">
            <input type="text" maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}"
              matInput #search>
            <mat-option *ngFor="let manadant of validationService._filter(allDivision, 'division_name', search.value)"
              [value]='manadant.division_id'>
              {{manadant.division_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" [ngClass]="{'red': actor_type_color === true}"
          style="float:right; width: 49%;">
          <mat-label>{{'contact-manager.Empfängerkreis_text' | translate}}</mat-label>
          <mat-select required [(ngModel)]="data.actortype" name="actorType" formControlName="actorType"
            placeholder="{{'contact-manager.Empfängerkreis_placeholder' | translate}}"
            (selectionChange)="actor($event)">
            <mat-option *ngFor="let actorType of actorTypes" [value]='actorType.actorTypeId'>
              {{actorType.actor_type_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex flex-row row-height">
      <div class="flex-fill">
        <mat-form-field appearance="fill" *ngIf="Actor_PreSelected == false" [ngClass]="{'red': actor_color === true}"
          style="float: left;width: 49%;" class="actors">
          <mat-label> {{'contact-manager.Empfängername_text' | translate}}</mat-label>
          <mat-select required [(ngModel)]="data.actor" name="actor" (openedChange)="searchActor.value = ''"
            placeholder="{{'contact-manager.Empfängername_placeholder' | translate}}" formControlName="actor"
            (selectionChange)="saveActor($event);searchActor.value = ''" [disabled]="actor_disable == true">
            <input [hidden]="actors.length < 6" type="text" maxlength="20" class="searchSelect"
              placeholder="{{'header.Suche_tooltip' | translate}}" matInput #searchActor>
            <mat-option *ngFor="let actor of validationService._filter(actors, 'actor_name', searchActor.value)"
              [value]='actor.actor_id'>
              {{actor.actor_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="Actor_PreSelected == true" [ngClass]="{'red': actor_color === true}"
          style="float: left; width: 49%;" class="actors2">
          <mat-label> {{'contact-manager.Empfängername_text' | translate}}</mat-label>
          <mat-select required [(ngModel)]="actor_selected_name" name="actor" (openedChange)="searchActor.value = ''"
            placeholder="{{'contact-manager.Empfängername_placeholder' | translate}}" formControlName="actor"
            (selectionChange)="saveActor($event);searchActor.value = ''" [disabled]="actor_disable == true">
            <input [hidden]="actors.length < 6" type="text" maxlength="20" class="searchSelect"
              placeholder="{{'header.Suche_tooltip' | translate}}" matInput #searchActor>
            <mat-option *ngFor="let actor of validationService._filter(actors, 'actor_name', searchActor.value)"
              [value]='actor.actor_id'>
              {{actor.actor_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="float:right; width: 49%;">
          <mat-label> {{'contact-manager.Tochtergesellschaft_text' | translate}}</mat-label>
          <mat-select required [(ngModel)]="data.subsidiary" name="subsidiary" formControlName="subsidiary"
            [disabled]="subsidiary_disable ==  true"
            (selectionChange)="saveSubsidiary($event); searchSubsidiary.value = ''"
            placeholder="{{'contact-manager.Tochtergesellschaft_placeholder' | translate}}"
            (openedChange)="searchSubsidiary.value = ''">
            <input [hidden]="customers.length < 6" type="text" maxlength="20" class="searchSelect"
              placeholder="{{'header.Suche_tooltip' | translate}}" matInput #searchSubsidiary>
            <mat-option
              *ngFor="let subsidiary of validationService._filter(customers, 'customer_name', searchSubsidiary.value)"
              [value]='subsidiary.liztu_vn_kdnr' [id]="subsidiary.liztu_vn_kdnr">
              {{subsidiary.customer_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <p style="color:red" *ngIf="message !== ''">{{message | multilingual | translate}}</p>
  <p style="color:red" *ngIf="message_companies !== ''">{{message_companies | multilingual | translate}}</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <!-- <button mat-button class="button-process" style="color: #272727;
        background: #ff9000;
        font-size: 15px;
        text-align: center;
        padding: 0px 22px 0px 22px;
    border-radius: 2px;
        font-weight: 600;
        border: none;" (click)="nextStep(data)" color="warn">{{'contact-manager.Next_btn' | translate}}</button> -->
  <!-- Change (font-weight: 600 removed) -->
  <button mat-button class="button-process" style="color: #272727;
        background: #ff9000;
        font-size: 15px;
        text-align: center;
        padding: 0px 22px 0px 22px;
    border-radius: 2px;
        border: none;" (click)="nextStep(data)" color="warn">{{'contact-manager.Next_btn' | translate}}</button>
</mat-dialog-actions>
