import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ERRORS, STATIC_TEXT } from '@shared/constants/string.constants';
import { LENGTH } from '@shared/constants/numeric.constants';
import { REGEX } from '@shared/constants/general/regex.constants';
import { ValidationSharedService } from '@core/services/validation-shared.service';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  actualnumber = 0;
  errorNewValue = false;
  actualneuValue = 0;
  currentYear = new Date().getFullYear();

  constructor(
    private _currencyCustomPipe: CurrencyCustomPipe,
    private readonly _validationSharedService: ValidationSharedService
  ) {}

  NumberValidation(event, key, length) {
    if (key === 'keydown') {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode === '69' || charCode === '189' || charCode === '187') {
        event.preventDefault();
      }
      if (event.target.value.length === 0) {
        if (charCode === '45' || charCode === '48') {
          event.preventDefault();
        }
      }
      if (event.code !== 'Backspace') {
        this.actualnumber = event.target.value.length;
      } else {
        this.actualnumber = this.actualnumber - 1;
      }
    } else {
      if (
        event.clipboardData.getData('text/plain') === 'e' ||
        event.clipboardData.getData('text/plain') === 'E' ||
        event.clipboardData.getData('text/plain').includes('e') ||
        event.clipboardData.getData('text/plain').includes('E') ||
        event.clipboardData.getData('text/plain').includes('+') ||
        event.clipboardData.getData('text/plain').includes('-')
      ) {
        event.preventDefault();
      } else if (
        this.actualnumber === 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      } else {
        this.actualnumber = Number(
          this.actualnumber + event.clipboardData.getData('text/plain').length
        );
      }
    }
  }
  NumberValidationBrowserSpecific(event, key, length) {
    if (key === 'keydown' && !event.metaKey) {
      const reg = new RegExp('^[0-9.]*$');
      event = event || window.event;
      const charCode =
        typeof event.which === 'undefined' ? event.keyCode : event.which;
      const charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9.]+$/)) {
        event.preventDefault();
      }
      if (
        charCode === '69' ||
        charCode === '189' ||
        charCode === '187' ||
        !reg.test(event.target.value)
      ) {
        event.preventDefault();
      }
      if (event.target.value.length === 0) {
        if (charCode === '48' || charCode === '45') {
          event.preventDefault();
        }
      }
      if (event.code !== 'Backspace') {
        this.actualnumber = event.target.value.length;
      } else {
        this.actualnumber = this.actualnumber - 1;
      }
    } else {
      const reg = new RegExp('^[0-9.]*$');
      const clipboardData =
        event.clipboardData || event.originalEvent.clipboardData;
      if (reg.test(clipboardData.getData('text/plain')) === false) {
        event.preventDefault();
      }
    }
  }

  NewNumberValidationBrowserSpecific(event, key, length) {
    const keybord = event.keyCode || event.charCode || 0;
    if (key === 'keydown' && !event.metaKey) {
      const reg = new RegExp('^[0-9.,]*$');
      event = event || window.event;
      const charCode =
        typeof event.which === 'undefined' ? event.keyCode : event.which;
      const charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9.,]+$/)) {
        event.preventDefault();
      }
      if (
        charCode === '69' ||
        charCode === '189' ||
        charCode === '187' ||
        !reg.test(event.target.value)
      ) {
        event.preventDefault();
      }
      if (event.target.value.length === 0) {
        if (charCode === '48' || charCode === '45') {
          event.preventDefault();
        }
      }
      if (event.code !== 'Backspace') {
        this.actualnumber = event.target.value.length;
      } else {
        this.actualnumber = this.actualnumber - 1;
      }
    } else {
      const reg = new RegExp('^[0-9.,]*$');
      if (reg.test(event.clipboardData.getData('text/plain')) === false) {
        event.preventDefault();
      }
    }
  }

  SequenceNumberValidation(event, key, length) {
    if (key === 'keydown') {
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode === '69' ||
        charCode === '189' ||
        charCode === '187' ||
        charCode === '190'
      ) {
        event.preventDefault();
      }
      if (event.target.value.length === 0) {
        if (charCode === '48' || charCode === '45') {
          event.preventDefault();
        }
      }
      const checkSelectedLength =
        document.getSelection().toString().length === length;
      if (
        !checkSelectedLength &&
        event.target.value.length === length &&
        event.code !== 'Backspace' &&
        event.code !== 'Tab' &&
        charCode !== 37 &&
        charCode !== 39
      ) {
        event.preventDefault();
      }
      if (event.code !== 'Backspace' && event.code !== 'Tab') {
        this.actualnumber = event.target.value.length;
      } else {
        this.actualnumber = this.actualnumber - 1;
      }
    } else {
      const checkSelectedLength = document.getSelection().toString().length > 0;
      const rerExp = new RegExp('^[A-Za-z.+-]*$');
      const compareResult = rerExp.test(
        event.clipboardData.getData('text/plain')
      );
      if (compareResult) {
        event.preventDefault();
      } else if (
        this.actualnumber === 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      } else {
        if (!checkSelectedLength) {
          this.actualnumber = Number(
            this.actualnumber + event.clipboardData.getData('text/plain').length
          );
          if (this.actualnumber > length) {
            event.preventDefault();
          }
        }
      }
    }
  }
  SequenceNumberValidationBrowserSpecific(event, key, length) {
    const keybord = event.keyCode || event.charCode || 0;
    if (key === 'keydown' && !event.metaKey) {
      const reg = new RegExp('^[0-9]*$');
      event = event || window.event;
      const charCode =
        typeof event.which === 'undefined' ? event.keyCode : event.which;
      const charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9]+$/)) {
        event.preventDefault();
      }
      if (
        charCode === '69' ||
        charCode === '189' ||
        charCode === '187' ||
        !reg.test(event.target.value)
      ) {
        event.preventDefault();
      }
      if (event.target.value.length === 0) {
        if (charCode === '48' || charCode === '45') {
          event.preventDefault();
        }
      }
      const checkSelectedLength =
        document.getSelection().toString().length === length;
      if (
        !checkSelectedLength &&
        event.target.value.length === length &&
        event.code !== 'Backspace' &&
        event.code !== 'Tab'
      ) {
        event.preventDefault();
      }
      if (event.code !== 'Backspace' && event.code !== 'Tab') {
        this.actualnumber = event.target.value.length;
      } else {
        this.actualnumber = this.actualnumber - 1;
      }
    } else {
      const reg = new RegExp('^[0-9]*$');
      if (reg.test(event.clipboardData.getData('text/plain')) === false) {
        event.preventDefault();
      }
    }
  }

  public UnderOneBillionNumberValidation(event, key, error) {
    this.errorNewValue = error;
    if (key === 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length === 0) {
        if (charCode === '45' || charCode === '48') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        this.errorNewValue = false;
        if (
          charCode !== 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          event.preventDefault();
        }
        this.onTypeDecimalNumber(event);
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualneuValue = event.target.value.length;
      } else {
        this.actualneuValue = this.actualneuValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,.]*$');
      if (reg.test(event.clipboardData.getData('text/plain')) === false) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualneuValue === 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
    return this.errorNewValue;
  }
  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length === 2) {
        event.preventDefault();
      }
    }
  }
  germanNumberFormate(event, name, length) {
    const number = [];
    if (
      event.target.value !== '.' &&
      !event.target.value.toString().includes('.')
    ) {
      number.push(event.target.value);
    } else if (event.target.value.toString().includes('.')) {
      number.push(event.target.value.toString()?.replace('.', ''));
    }
    if (number[0] !== undefined) {
      if (name === 'power') {
        if (number[0].length > length) {
          this._validationSharedService.saveFormErrorPower('true');
          return [true, event.target.value];
        } else {
          this._validationSharedService.saveFormErrorPower('false');
          const powerValue = this._currencyCustomPipe
            .transform(event.target.value.toString()?.replaceAll('.', ''))
            ?.replace('€', '')
            ?.replace(',00', '')
            .trim();
          return [false, powerValue];
        }
      }
      if (name === 'count') {
        if (number[0].length > length) {
          this._validationSharedService.saveFormErrorCount('true');
          return [true, event.target.value];
        } else {
          this._validationSharedService.saveFormErrorCount('false');
          const countValue = this._currencyCustomPipe
            .transform(event.target.value.toString()?.replaceAll('.', ''))
            ?.replace('€', '')
            ?.replace(',00', '')
            .trim();
          return [false, countValue];
        }
      }
    }
  }
  nameValidation(e, length) {
    const regexp = new RegExp(
      '^[A-Za-zäöüäuÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ]*$'
    );
    let searchfind: boolean;
    if (e?.type === 'paste') {
      if (e.clipboardData.getData('text/plain').length >= length + 1) {
        e.preventDefault();
      } else {
        if (regexp.test(e.clipboardData.getData('text/plain')) === true) {
        } else {
          e.preventDefault();
        }
      }
    } else {
      searchfind = regexp.test(e.key);
      if (!searchfind) {
        e.preventDefault();
      }
    }
  }
  dateValidation(e) {
    const birthdayError = false;
    const regexp = new RegExp('^[0-9./]*$');
    let searchfind: boolean;
    if (e.target.value?.length >= 10) {
      e.preventDefault();
    }
    if (e?.type === 'paste') {
      searchfind = regexp.test(e.clipboardData.getData('text/plain'));
      if (!searchfind) {
        e.preventDefault();
      }
    } else {
      searchfind = regexp.test(e.key);
      if (!searchfind) {
        e.preventDefault();
      }
    }
    return birthdayError;
  }
  setSqmValue(str) {
    if (str) {
      str = str.toString();
      if (str.length >= 4) {
        str = this._currencyCustomPipe
          .transform(str.replaceAll('.', ''))
          .replace('€', '')
          .trim();
      }
      return str;
    }
    return null;
  }
  MinutesValidation(event, key, length) {
    if (key === 'keydown') {
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode === '69' ||
        charCode === '189' ||
        charCode === '187' ||
        charCode === '190'
      ) {
        event.preventDefault();
      }
      if (event.target.value.length === 0) {
        if (charCode === '45') {
          event.preventDefault();
        }
      }
      if (
        event.target.value.length === length &&
        event.code !== 'Backspace' &&
        event.code !== 'Tab'
      ) {
        event.preventDefault();
      }
      if (event.code !== 'Backspace' && event.code !== 'Tab') {
        this.actualnumber = event.target.value.length;
      } else {
        this.actualnumber = this.actualnumber - 1;
      }
    } else {
      if (
        event.clipboardData.getData('text/plain') === 'e' ||
        event.clipboardData.getData('text/plain') === 'E' ||
        event.clipboardData.getData('text/plain').includes('e') ||
        event.clipboardData.getData('text/plain').includes('E') ||
        event.clipboardData.getData('text/plain') === '.' ||
        event.clipboardData.getData('text/plain').includes('.') ||
        event.clipboardData.getData('text/plain').includes('+') ||
        event.clipboardData.getData('text/plain').includes('-')
      ) {
        event.preventDefault();
      } else if (
        this.actualnumber === 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      } else {
        this.actualnumber = Number(
          this.actualnumber + event.clipboardData.getData('text/plain').length
        );

        if (this.actualnumber > length) {
          event.preventDefault();
        }
      }
    }
  }
  public sqmValidation(fromCtrl: FormGroup, event) {
    if (event.type === 'blur') {
      fromCtrl.controls[event.target.getAttribute('formControlName')].setValue(
        this._currencyCustomPipe
          .transform(event.target.value)
          ?.replace('€', '')
          .trim()
          .split(',')[0]
      );
    } else if (event.type === 'focus') {
      const value = event.target.value.toString().split('.');
      fromCtrl.controls[event.target.getAttribute('formControlName')].setValue(
        value.join('')
      );
    }
  }
  calculateWert371(event, yearlyFactor) {
    const calculateValue =
      Number(
        event.target.value
          .toString()
          ?.replaceAll('.', '')
          ?.replaceAll(',', '.')
          ?.trim()
      ) / yearlyFactor;
    return calculateValue;
  }
  calculateNewValue(formCtrl: FormGroup, event, yearlyFactor) {
    if (event.target.value !== '') {
      formCtrl.controls[event.target.getAttribute('formControlName')].setValue(
        event.target.value
      );
      const calculatedNewValue =
        yearlyFactor *
        Number(
          event.target.value
            .toString()
            ?.replaceAll('.', '')
            ?.replaceAll(',', '.')
            .trim()
        );
      const result = this._currencyCustomPipe
        .transform(Math.round(calculatedNewValue))
        .replace('€', '')
        .split(',')[0]
        .trim();
      return result;
    }
  }
  public ValidationPowerAndCount(fromCtrl: FormGroup, event) {
    if (event.target.getAttribute('formControlName') === 'count') {
      if (event.type === 'blur') {
        fromCtrl.controls[
          event.target.getAttribute('formControlName')
        ].setValue(
          this._currencyCustomPipe
            .transform(event.target.value)
            ?.replace(',00', '')
            ?.replace('€', '')
            ?.trim()
        );
      } else if (event.type === 'focus') {
        let value = event.target.value.toString();
        value = value.split('.');
        fromCtrl.controls[
          event.target.getAttribute('formControlName')
        ].setValue(value.join(''));
      }
    }
    if (event.target.getAttribute('formControlName') === 'power') {
      if (event.type === 'blur') {
        fromCtrl.controls[
          event.target.getAttribute('formControlName')
        ].setValue(
          event.target.value
            ? this._currencyCustomPipe
                .transform(
                  event.target.value
                    .toString()
                    .replaceAll('.', '')
                    .replaceAll(',', '.')
                )
                .replace('€', '')
                ?.trim()
            : ''
        );
      } else if (event.type === 'focus') {
        let value = event.target.value.toString();
        value = value.split('.');
        fromCtrl.controls[
          event.target.getAttribute('formControlName')
        ].setValue(value.join(''));
      }
    }
  }
  public _filter(items: any = [], key: string, value: string) {
    if (value !== '') {
      value = value.toLowerCase();
      if (key) {
        if (
          items.filter(option => {
            option[key]?.toString().toLowerCase().includes(value) === 0;
          }) !== null
        ) {
          items = items.filter(option =>
            option[key]?.toString().toLowerCase().includes(value)
          );
        }
      } else {
        if (
          items.filter(
            option => option?.toString().toLowerCase().includes(value) === 0
          ) !== null
        ) {
          items = items.filter(option =>
            option?.toString().toLowerCase().includes(value)
          );
        }
      }
    }
    return items;
  }
  public _filterMultiSelect(items: any = [], key: string, value: string) {
    if (value !== '') {
      value = value.toLowerCase();
      if (
        items?.filter(
          option => option[key].toString().toLowerCase().includes(value) === 0
        ) !== null
      ) {
        return items?.map(option => {
          if (option[key].toString().toLowerCase().includes(value)) {
            option.hide = false;
          } else {
            option.hide = true;
          }
          return option;
        });
      }
    }
    return items?.map(option => {
      option.hide = false;
      return option;
    });
  }
  // Validation function for number filed under less than 99 and non decimal(,)
  public validationUnder99NonDecimal(event, key, fromCtrl: FormGroup) {
    if (key === 'keydown') {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode === 69 || charCode === 187 || charCode === 189) {
        event.preventDefault();
      }
    }
  }
  public validationForTwoDecimal(event, key, fromCtrl: FormGroup) {
    if (event.target.value.toString().includes(',')) {
      const value = Number(event.target.value.replace(',', '.'));
      const value2 = value.toFixed(2).replace('.', ',');

      fromCtrl.controls[event.target.getAttribute('formControlName')].setValue(
        value2
      );
    }
  }
  public removeSymbols(value) {
    if (value) {
      value = value?.replaceAll('.', '');
      value = value?.replaceAll(',', '.');
      value = value?.trim();
    }
    return value;
  }
  public setNumberValidation(str) {
    if (str) {
      str = str.toString();
      str = this.removeSymbols(str);
      if (str.length >= LENGTH.FOUR) {
        str = this._currencyCustomPipe
          .transform(str)
          .replace(STATIC_TEXT.EUR_SYMBOL, STATIC_TEXT.EMPTY_STRING)
          .trim();
      }
      return str;
    }
    return null;
  }
  public validateName(value: string) {
    const regexp = new RegExp(REGEX.NAME_VALIDATION);
    const result = regexp.test(value);
    return { isError: !result, errorName: ERRORS.NAME_ERROR };
  }

  public setRemoveError(validate, formGroup, formControl: any) {
    validate?.isError
      ? formGroup
          .get(formControl)
          .setErrors({ [validate?.errorName]: validate?.isError })
      : formGroup.get(formControl).errors
        ? delete formGroup.get(formControl).errors[validate?.errorName]
        : '';
  }
}
