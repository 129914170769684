import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import {
  RowSides,
  IfElseTypes,
} from '../../../../constants/formula.constants';
import { ifelse_input } from '../formula.model';

@Component({
  selector: 'nested-if-test-maker',
  templateUrl: './nested-if-maker.component.html',
  styleUrls: ['./nested-if-maker.component.css'],
})
export class NestedIfComponent implements OnInit {
  @Input('InputValues') inputDataOriginal;
  @Input('stepper_id') stepper_id;
  @Input('formula_id') formula_id;
  @Input('is_calculation') is_calculation;
  @Output('data_sourceData') datSourceData = new EventEmitter<any>();
  @Output('changeMenuItemData') changeMenuItem = new EventEmitter<any>();
  @Output('changeOperatorData') changeOperator = new EventEmitter<any>();
  @Output('getSourceData') getsource = new EventEmitter<any>();
  @Output('changeMenuItemIFElseCondition') changeMenuItemIFElse =
    new EventEmitter<any>();
  @Output('changeOperatorIFElseCondition') changeOperatorIfElse =
    new EventEmitter<any>();
  @Output('changeMenu') changeMenud = new EventEmitter<any>();
  @Output('inputChanged') inputChange = new EventEmitter<any>();

  defaultRow_types = [
    {
      id: 3,
      label: this._translate.instant('value-calculator.Pattern'),
      is_heading: true,
      is_if_else: true,
    },
    {
      id: 1,
      label: this._translate.instant('value-calculator.number'),
      is_heading: false,
      is_if_else: false,
    },
    { id: 2, label: 'LHS-RHS', is_heading: false, is_if_else: true },
    { id: 4, label: 'If...Else', is_heading: false, is_if_else: false },
  ];
  inputData: ifelse_input;
  row: any;
  readonly ROWSIDES = RowSides;
  readonly IfElseTypes = IfElseTypes;

  constructor(
    public validationService: ValidationService,
    public _translate: TranslateService
  ) {}
  ngOnInit() {
    if (this.inputDataOriginal) {
      this.inputData = { ...this.inputDataOriginal };
      this.row = this.inputData.row;
    }
  }

  nestedIfDataExists(inputDataOriginal) {
    return (
      inputDataOriginal &&
      inputDataOriginal.row &&
      inputDataOriginal.row.nested_formula &&
      inputDataOriginal.row.nested_formula.nestedIf
    );
  }
  nestedElseDataExists(inputDataOriginal) {
    return (
      inputDataOriginal &&
      inputDataOriginal.row &&
      inputDataOriginal.row.nested_formula &&
      inputDataOriginal.row.nested_formula.nestedElse
    );
  }

  isIfLeftDataAvailable(inputDataOriginal) {
    return (
      inputDataOriginal &&
      inputDataOriginal.row &&
      inputDataOriginal.row.condition &&
      inputDataOriginal.row.condition.if_formula &&
      inputDataOriginal.row.condition.if_formula.left_data
    );
  }

  isIfRightDataAvailable(inputDataOriginal) {
    return (
      inputDataOriginal &&
      inputDataOriginal.row &&
      inputDataOriginal.row.condition &&
      inputDataOriginal.row.condition.if_formula &&
      inputDataOriginal.row.condition.if_formula.right_data
    );
  }

  isElseLeftDataAvailable(inputDataOriginal) {
    return (
      inputDataOriginal &&
      inputDataOriginal.row &&
      inputDataOriginal.row.condition &&
      inputDataOriginal.row.condition.else_formula &&
      inputDataOriginal.row.condition.else_formula.left_data
    );
  }

  isElseRightDataAvailable(inputDataOriginal) {
    return (
      inputDataOriginal &&
      inputDataOriginal.row &&
      inputDataOriginal.row.condition &&
      inputDataOriginal.row.condition.else_formula &&
      inputDataOriginal.row.condition.else_formula.right_data
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputDataOriginal) {
      this.inputData = { ...this.inputDataOriginal };
      this.row = this.inputData.row;
    }
  }

  data_sourceData(event) {
    this.datSourceData.emit(event);
  }

  changeMenuItemData(event) {
    const currentPath =
      event?.inputDataOriginal?.row?.currentPath ||
      event?.inputDataOriginal?.currentPath;
    event.currentPath = currentPath;
    this.changeMenuItem.emit(event);
  }

  changeOperatorData(event) {
    const currentPath =
      event?.inputDataOriginal?.row?.currentPath ||
      event?.inputDataOriginal?.currentPath;
    event.currentPath = currentPath;
    this.changeOperator.emit(event);
  }

  inputChanged(event) {
    this.inputChange.emit(event);
  }

  getNestedSourceData(event) {
    this.inputDataOriginal.data_source_data = null;
    this.getSourceData(
      event.id,
      event.product_info_id,
      event.level,
      event.inputDataOriginal
    );
  }
  getSourceData(id, product_info_id, level, inputDataOriginal) {
    this.getsource.emit({ id, product_info_id, level, inputDataOriginal });
  }
  focusSearch(key) {
    document.getElementById(key) && document.getElementById(key).focus();
  }

  changeNestedMenuItemIFElseCondition(event) {
    this.changeMenuItemIFElseCondition(
      event.id,
      event.fromWhere,
      event.index,
      event.is_heading,
      event.event,
      event.is_data,
      event.name,
      { currentPath: event.currentPath, i: event.level }
    );
  }
  changeMenuItemIFElseCondition(
    id,
    fromWhere,
    index,
    is_heading,
    event,
    is_data,
    name,
    inputDataOriginal
  ) {
    const currentPath =
      inputDataOriginal?.row?.currentPath || inputDataOriginal?.currentPath;
    this.changeMenuItemIFElse.emit({
      id,
      fromWhere,
      index,
      is_heading,
      event,
      is_data,
      name,
      currentPath,
      level: inputDataOriginal.i,
    });
  }
  changeNestedOperatorIFElseCondition(event) {
    this.changeOperatorIFElseCondition(
      event.id,
      event.index,
      event.is_heading,
      event.event,
      event.name,
      { currentPath: event.currentPath, i: event.level }
    );
  }
  changeOperatorIFElseCondition(
    id,
    index,
    is_heading,
    event,
    name,
    inputDataOriginal
  ) {
    const currentPath =
      inputDataOriginal?.row?.currentPath || inputDataOriginal?.currentPath;
    this.changeOperatorIfElse.emit({
      id,
      index,
      is_heading,
      event,
      name,
      currentPath,
      level: inputDataOriginal.i,
    });
  }
  changeNestedMenu(event) {
    this.changeMenu(
      event.id,
      event.index,
      event.is_heading,
      event.event,
      event.fromMenu,
      { currentPath: event.currentPath, i: event.level }
    );
  }

  changeMenu(id, index, is_heading, event, fromMenu, inputDataOriginal) {
    const currentPath =
      inputDataOriginal?.row?.currentPath || inputDataOriginal?.currentPath;
    this.changeMenud.emit({
      id,
      index,
      is_heading,
      event,
      fromMenu,
      currentPath,
      level: inputDataOriginal.i,
    });
  }
}
