import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '@core/services/sidebar.service';
import {
  animateText,
  onMainContentChange,
  onSideNavChange,
} from '../../animations';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { IC_ICONS } from '@shared/constants/image-paths/ic-icons.constant';
@Component({
  selector: 'app-rcs-setup',
  templateUrl: './rcs-setup.component.html',
  styleUrls: ['./rcs-setup.component.scss'],
  animations: [onSideNavChange, animateText, onMainContentChange],
})
export class RcsSetupComponent implements OnInit {
  active = 'one';
  disabled = true;
  companyName: any;
  userDataValue: any;
  activeLable;
  activeTab: string;
  onSideNavChange: boolean;
  sideNavState = true;
  linkText = true;
  diabledSideBar = false;
  addNewPage = false;
  isCustomerSelected = false;
  pageBreadcrumbText = 'settings.EINSTELLUNGEN_text';
  pageBreadcrumbSubText = 'settings.divisionManagementShort';
  tempActiveLable = 'rcs-setup.Spartenmanagement';
  formGeneratorTab = true;
  editFormId = null;
  createdByRole = '';
  toastMessageForm: any;
  IC_ICONS = IC_ICONS;
  constructor(
    private router: Router,
    private _sidenavService: SidebarService
  ) {
    this._sidenavService.sideNavState$.subscribe(res => {
      this.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
    this.diabledSideBar = false;

    if (
      this.router.url
        .toLowerCase()
        .includes(ROUTES_PATH.PRODUCT_MANAGEMENT_EDIT)
    ) {
      this.addNewPage = true;
    } else {
      this.addNewPage = false;
    }
  }
  userDataReceived(data) {
    this.userDataValue = data;
    this.companyName = this.userDataValue.user_profile.customer_details.name;
  }
  fetchName(lable: string): void {
    if (!this.diabledSideBar) {
      this.activeLable = lable;
    }

    if (lable === 'rcs-setup.Produktmanagement') {
      if (
        this.router.url
          .toLowerCase()
          .includes(ROUTES_PATH.PRODUCT_MANAGEMENT_EDIT)
      ) {
        const prev = this.router.routeReuseStrategy.shouldReuseRoute;
        const prevOSN = this.router.onSameUrlNavigation;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([ROUTES_PATH.RCS_SETUP]).then(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = prev;
          this.router.onSameUrlNavigation = prevOSN;
        });
      } else {
        this.navigateToRCSetup();
      }
    } else {
      this.navigateToRCSetup();
    }
  }
  navigateToRCSetup() {
    this.router.navigate([ROUTES_PATH.RCS_SETUP]);
  }
  changeInProduct(event) {
    this.diabledSideBar = event;
  }
  onSinenavToggle() {
    this.sideNavState = !this.sideNavState;

    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200);
    this._sidenavService.sideNavState$.next(this.sideNavState);
  }
  newFormClick(event) {
    if (event) {
      this.formGeneratorTab = false;
    }
  }
  receiveFormId(event) {
    this.editFormId = event;
  }
  closeAddNewForm(event) {
    this.editFormId = null;
    this.formGeneratorTab = true;
  }
  getCreatedBy(event) {
    this.createdByRole = event;
  }
  toastMessageAddForm(event) {
    this.toastMessageForm = event;
  }
}
