import { LOCK_STATUS } from '@shared/constants/general/lock-status.constants';
import { Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import {
  ACTION_BAR,
  ACTION_BAR_KEYS,
  ACTION_BAR_MULTILINGUAL,
} from '@shared/constants/image-paths/action-bar.constant';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import { ToastService } from '@core/services/toast.service';
import { DocumentManagementService } from '@core/services/document-management.service';
import { LookupConstants } from '@shared/constants/lookup.constants';
import { IvmSyncPost } from './models/table.model';
import { sendResorce } from './models/new-claims-component.model';
import { BaseComponent } from '@shared/components/base.component';
import { ClaimsShareService } from './claims-share.service';
import { ActionBarItem } from '@shared/interfaces/action-bar.interface';
import { CLAIM_TABS } from './constants/claim.constants';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { CLAIM_ROUTES, CLAIM_ROUTES_ASSOCIATION } from '@shared/constants/router.constants';
import { Tab } from '@shared/interfaces/tab.interface';
import { BreadcrumbsContractInfo } from '@shared/components/breadcrumbs/breadcrumbs.model';
import { IC_ICONS } from '@shared/constants/image-paths/ic-icons.constant';

@Directive()
export class NewClaimsComponentBase extends BaseComponent {
  constructor(
    public serviceCall: ApiCallsService,
    public translate: TranslateService,
    public _toastService: ToastService,
    public _claimsAPICall: ClaimsManagementApiService,
    public readonly _documentManagementService: DocumentManagementService,
    public readonly _claimsShareService: ClaimsShareService,
    public readonly _customerSharedService: CustomerSharedService
  ) {
    super();
  }
  links = CLAIM_ROUTES_ASSOCIATION;
  tabs = CLAIM_TABS;
  leftTabs: Tab<CLAIM_ROUTES, CLAIM_TABS>[] = [];
  isCustomerUser: boolean;
  customer_id: any;
  divisionName: string;
  claim_id: any;
  activeLable: string;
  isCustomerSelected = true;
  pageBreadcrumbText = 'claims.claim_text';
  defaultActions: ActionBarItem[] = [];
  extraActions: ActionBarItem[] = [];
  active = CLAIM_TABS.GENERAL_DATA;
  linkText = true;
  sideNavState = true;
  onSideNavChange: boolean;
  ivm_sync = new FormControl();
  lock_state: number;
  state_warning_ivm_hidden = true;
  state_warning_protect_hidden = true;
  user_id: any;
  disable_ivm: boolean;
  pdfLink: any;
  liztu_vn_kdnr: any;
  is_ivm_product: boolean;
  formsPresent = false;
  is_active_product = false;
  ivmSync = false;
  showDocTemplateModal = false;
  avaliable_templates = [];
  showPDFViewer: boolean;
  pdfName: any;
  blob: any;
  isProdMode = false;
  customerName$ = this._customerSharedService.selectedCustomerName$;
  contractInfo: BreadcrumbsContractInfo;

  getFormsData(claimId: string, productInfoId: number): void {
    this.serviceCall
      .getFormDetailsByProductAndContractId(
        claimId,
        productInfoId,
        LookupConstants.CLAIM
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: any) =>
          (this.formsPresent = data?.forms_configurations?.length > 0)
      );
  }

  getIvmSyncStatus(claimId: string): void {
    this._claimsAPICall
      .getClaimIVMSync(claimId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this.lock_state = response.lock_state;
        this.ivmSync = response.is_ivm_sync;
        this._handleLockState();
      });
  }

  postIVMSync(claimId: string, isSync: boolean): void {
    const payload: IvmSyncPost = {
      is_ivm_sync: isSync,
      id: claimId,
    };
    this._claimsAPICall
      .postClaimIVMSync(payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        response => this._handleSyncResponse(response, isSync),
        () => this._handleError(isSync)
      );
  }

  getProductData(productId: number): void {
    this.serviceCall
      .getProductData(productId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          // to check whether product state is active,
          // product_state_id = 2 is for active product
          if (data.product.product_state_id == 2) {
            this.is_active_product = true;
          }
        }
      });
  }

  closeDocModal(event): void {
    this.showDocTemplateModal = event;
  }

  savePolicyTemplate(event): void {
    const data: sendResorce = {
      contract_id: null,
      report_uri: event.selectedTemplateUrl,
      user_id: this.user_id,
      document_type_id: 2,
      customer_id: Number(this._customerSharedService.getSelectedCustomerId()),
      liztu_vs_idnr: null,
      liztu_vn_idnr: this.liztu_vn_kdnr,
      document_description: event.docDescription,
      save_document: event.save_document,
    };
    this.serviceCall
      .createPolicy(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        if (response) {
          if (response.size > 0) {
            if (event.save_document) {
              this._toastService.showToastMessage(
                document,
                this.translate.instant(
                  'new-contract.general_information.create_save_succesfull_message'
                )
              );
            } else {
              this._toastService.showToastMessage(
                document,
                this.translate.instant(
                  'new-contract.general_information.create_succesfull_message'
                )
              );
            }
            const fileURL = window.URL.createObjectURL(response);
            this.showPDFViewer = true;
            this.pdfLink = fileURL;
            this.pdfName = event.selectedTemplateUrl.split('/reports/')[1];
            this.blob = response;
          }
        }
      });
  }

  onClosePdf(event: boolean): void {
    this.showPDFViewer = event;
  }

  getClaimData(id: string): void {
    this.serviceCall
      .getGeneralClaimsData(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.is_ivm_product = data.data?.is_product_ivm;
        this._claimsShareService.setProductInfoId(data.data?.product_info_id);
        this.ivmSync = data.data.is_ivm_sync;
        this._handleLockState();
      });
  }

  protected _handleLockState() {
    switch (this.lock_state) {
      case LOCK_STATUS.UNLOCKED:
        this.ivm_sync.setValue(this.ivmSync);
        this.state_warning_ivm_hidden = true;
        this.state_warning_protect_hidden = true;
        break;
      case LOCK_STATUS.LOCKED_BY_ERP:
        this.ivm_sync.setValue(true);
        this.state_warning_ivm_hidden = false;
        this.state_warning_protect_hidden = true;
        break;
      case LOCK_STATUS.LOCKED_BY_IC:
        this.ivm_sync.setValue(false);
        this.state_warning_ivm_hidden = true;
        this.state_warning_protect_hidden = false;
        break;
    }
    this._setActions();
  }

  protected _setActions(): void {
    this.defaultActions = this._getDefaultActions();
    this.extraActions = this._getExtraActions();
  }

  private _handleSyncResponse(response: any, isSync: boolean) {
    if (!response.HasErrors) {
      this.ivm_sync.setValue(isSync);
      this.ivmSync = isSync;
      this._handleLockState();
    }
  }

  private _handleError(isSync: boolean): void {
    this.ivm_sync.setValue(!isSync);
  }

  private _getDefaultActions(): ActionBarItem[] {
    return [
      {
        imgSrc: IC_ICONS.DOCUMENTS, //ACTION_BAR.CREATE_DOCUMENT_FROM_TEMPLATE,
        desc: this.translate.instant(
          ACTION_BAR_MULTILINGUAL.CREATE_DOCUMENT_FROM_TEMPLATE
        ),
        key: ACTION_BAR_KEYS.CREATE_DOCUMENT_FROM_TEMPLATE,
        disabled: !this.claim_id,
      },
      {
        imgSrc: IC_ICONS.WRITE_LETTER, // ACTION_BAR.WRITE_LETTER,
        desc: this.translate.instant(ACTION_BAR_MULTILINGUAL.WRITE_LETTER),
        key: ACTION_BAR_KEYS.WRITE_LETTER,
        disabled: !this.claim_id,
      },
      {
        imgSrc: IC_ICONS.WRITE_AN_EMAIL, //ACTION_BAR.WRITE_EMAIL,
        desc: this.translate.instant(ACTION_BAR_MULTILINGUAL.WRITE_EMAIL),
        key: ACTION_BAR_KEYS.WRITE_EMAIL,
        disabled: !this.claim_id,
      },
      {
        imgSrc: IC_ICONS.TO_ERP,
        desc: this.translate.instant(
          ACTION_BAR_MULTILINGUAL.PROVIDE_DATA_TO_IVM
        ),
        key: ACTION_BAR_KEYS.PROVIDE_DATA_TO_IVM,
        hidden: this.isProdMode,
        disabled: this.lock_state > 0 || !this.claim_id,
      },
    ];
  }

  private _getExtraActions(): ActionBarItem[] {
    switch (this.active) {
      case CLAIM_TABS.GENERAL_DATA:
        return [
          {
            imgSrc: IC_ICONS.GENERAL_DATA, //ACTION_BAR.TOGGLE_PROTECT_LOCK,
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.TOGGLE_PROTECT_LOCK
            ),
            key: ACTION_BAR_KEYS.TOGGLE_PROTECT_LOCK,
            disabled: this.lock_state === 1 || !this.claim_id,
            hidden: this.isProdMode,
          },
        ];
      case CLAIM_TABS.PAYMENT_MANAGEMENT:
        return [
          {
            desc: this.translate.instant(ACTION_BAR_MULTILINGUAL.ADD_PAYMENT),
            imgSrc: ACTION_BAR.ADD_PAYMENT_DETAILS,
            key: ACTION_BAR_KEYS.ADD_PAYMENT,
            disabled: !this.claim_id,
          },
        ];
      case CLAIM_TABS.CONTACT_PERSON:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.ADD_CONTACT_PERSON
            ),
            imgSrc: IC_ICONS.CONTACT_PERSON, //ACTION_BAR.ADD_CONTACT_PERSON,
            key: ACTION_BAR_KEYS.ADD_CONTACT_PERSON,
            disabled: !this.claim_id,
            hidden: this.isProdMode,
          },
        ];
      case CLAIM_TABS.DOCUMENT_MANAGEMENT:
        return [
          {
            desc: this.translate.instant(
              ACTION_BAR_MULTILINGUAL.UPLOAD_DOCUMENT
            ),
            imgSrc: IC_ICONS.DOCUMENTS, //ACTION_BAR.UPLOAD_DOCUMENT,
            key: ACTION_BAR_KEYS.UPLOAD_DOCUMENT,
            disabled: this.isCustomerUser || !this.claim_id,
          },
        ];
      default:
        return [];
    }
  }
}
