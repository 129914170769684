import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-date-and-time',
    template: `
        <div>
            {{element.date | dateFormat}}
        </div>
        <div class="table-below-text">{{element.date | dateFormat:'HH:mm'}}</div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateAndTimeComponent {
    @Input() element: any;
}
