import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MarkReadService {

  markAllFieldsAsTouched(form: FormGroup) {
    Object.keys(form.controls).forEach((controlName) => {
      const control = form.get(controlName);
      if (control?.validator) {
        const validator = control.validator({} as AbstractControl);
        if (validator?.required) {
          control.markAsTouched();
        }
      }
    });
  }
}
