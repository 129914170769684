import { Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Observable, Subscription } from 'rxjs';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';
import { DateTimeConvertService } from '@core/services/datetime/date-time-convert.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import {
  CONTRACTS_ROUTES,
  ROUTES_PATH,
} from '@shared/constants/router.constants';
import { AddNewContractDialogComponent } from '../../modules/new-contract/add-new-contract-dialog/add-new-contract-dialog.component';
import {
  ContractElement,
  PeriodicElement,
  StatusAvailable,
} from './contracts.component.model';
import { BaseComponent } from '@shared/components/base.component';
import { ShareDataService } from '@core/services/share-data.service';
import {
  ContractInfo,
  ContractListResponse,
} from '@shared/models/contract.model';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Directive()
export class ContractsMainComponentBase extends BaseComponent {
  uncheck: boolean;
  customerFilterToggle = true;
  customExpandedHeight = '45px';
  customFilterExpandedHeight = '48px';
  artusCustomFilterExpandedHeight = '55px';
  userManagementToggle = true;
  isWholeGroup = false;
  @ViewChild('page', { read: ElementRef, static: false }) pdfView: ElementRef;
  selectLang;
  contract;
  contractexport: any;
  public secondaryColour = '#d6d1d170';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('choosefile') choosefile: ElementRef;
  ELEMENT_DATA: PeriodicElement[] = [];
  CONTRACT_DATA: ContractElement[] = [];
  doc_data;
  formFilter: any;
  dataSource = new MatTableDataSource();
  originalSize = true;
  docName;
  user_idnr_local: number;
  contract_id_all: number = null;
  formMsg: FormGroup;
  public showbutton = false;
  public buttonName: any = '../../../assets/images/filter.png';
  subject = new FormControl();
  msg = new FormControl();
  userDataValue: any;
  user_data: any;
  customer_details: any;
  selectedCountOfRows = 25;
  tablePage = 1;
  numberOfRows = [25, 50, 75, 100, 125];
  @Input('recreateDataValue') recreateDataValue;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild('tooltip')
  openStatusFilter = false;
  statusAvailable: StatusAvailable[] = [];
  contractsDataParentObject: any;
  status: any = [];
  showSparteFiveRecords = false;
  PRODUKT_Excel: string;
  isFilterApplied = false;
  displayedColumns: string[] = [
    'expand',
    'id',
    'productName',
    'policyNumber',
    'insurer',
    'state',
    'object',
    'insuredAmount',
    'annualPremium',
    'createdBy',
    'updatedBy',
    'action',
  ];
  positionInfo: any;
  height: any;
  ngheight: any;
  loading = true;
  liztu_vs_idnr: any;
  send_liztu_kdnr: any;
  send_liztu_idnr: any;
  contract_number: any;
  isCustomerUser = false;
  isICUser = false;
  showContract: boolean;
  changeTableRow = false;
  contractGroupCount: number;
  encoded_liztu_vn_kdnr: string;
  liztu_vn_kdnr: any;
  divisionName: string;
  customer_id: any;
  showWholeGroupCheck = false;
  datacarrier: any[];
  user_id: number;
  user_role;
  encoded_customer_id;
  is_source_ivm: boolean;
  type;
  encoded_contract = null;
  encoded_date;
  ContractData = [];
  isShow = false;
  logo;
  data_delivery_date;
  data_delivery_cycle;
  newLogo;
  subscription: Subscription;
  receivedType;
  previousFilters: any = null;
  loadContractDataFlag = false;
  loadUserContractDataFlag = false;
  total_elements: number;
  statusValues = '';
  otherFields: any = {};
  cloudDocId: any;
  pageBreadcrumbText = 'contracts.contract_text';
  isCustomerSelected = true;
  customerTypes = [
    CUSTOMER_TYPES.TOCHTER,
    CUSTOMER_TYPES.AUSLAND,
    CUSTOMER_TYPES.PRIVATKUNDE,
    CUSTOMER_TYPES.BETEILIGUNG,
  ];
  leftAllignItems = {
    vertical: 'middle',
    horizontal: 'left',
    wrapText: true,
  };
  rightAllignItems = {
    vertical: 'middle',
    horizontal: 'right',
    wrapText: true,
  };
  formIcons = FORM_ICONS;
  documentTypeData = [];
  totalContracts: number;
  protected isLoadUserContract: boolean;
  customerName$ = this._customerSharedService.selectedCustomerName$;

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    public router: Router,
    public serviceCall: ApiCallsService,
    public validationService: ValidationService,
    public _dateTimeService: DateTimeConvertService,
    public _shareDataService: ShareDataService,
    public _route: ActivatedRoute,
    public _customerSharedService: CustomerSharedService
  ) {
    super();
  }

  formatContractData(contractItems: ContractListResponse) {
    this.ContractData = [];
    this.total_elements =
      this.tablePage > 1 && contractItems.totalContractCount === 0
        ? this.totalContracts
        : contractItems.totalContractCount;
    if (contractItems.contracts?.length > 0) {
      this.ContractData = this.formatTableData(contractItems.contracts);
      this.dataSource.data = [...this.ContractData];
    } else {
      this.dataSource.data = [];
    }
  }
  formatTableData(contracts: ContractInfo[]): ContractElement[] {
    return contracts.map(contract => {
      const tableData = {
        contract_id: contract.contractId,
        product_name: contract.productName,
        contract_number: contract.contractNumber,
        insurance_sum: contract.insuredAmount,
        insurer: contract.insurer,
        status: contract.state,
        premium: contract.annualPremium,
        currency_symbol: contract.currency,
        object: contract.object,
        is_source_ivm: contract.sourceIvm,
        updated_at: this._dateTimeService.convertToDateTime(contract.updatedAt),
        created_at: this._dateTimeService.convertToDateTime(contract.createdAt),
        created_by: contract.createdBy,
        updated_by: contract.updatedBy,
        childContracts: contract.childContracts,
      };
      if (!contract.currency && contract.annualPremium) {
        tableData.currency_symbol = 'EUR';
      }

      return tableData;
    });
  }
  onNewContract() {
    const contractMatDialog = this.dialog.open(AddNewContractDialogComponent);
    contractMatDialog.afterClosed().subscribe(data => {
      if (data) {
        this.router.navigate(
          [`${ROUTES_PATH.ADD_NEW_CONTRACT}/${CONTRACTS_ROUTES.GENERAL_DATA}`],
          {
            queryParams: {
              liztu: this._route.snapshot.queryParams.liztu,
            },
          }
        );
      }
    });
  }
  navigatToClaims(element) {
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';

    this.router
      .navigate([ROUTES_PATH.CLAIMS], {
        queryParams: {
          contract: btoa(element.contract_id),
          date: btoa('all'),
          liztu: this.encoded_liztu_vn_kdnr,
          via: btoa('contracts'),
        },
      })
      .then(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = prev;
        this.router.onSameUrlNavigation = prevOSN;
      });
  }

  addStatusFilter(value) {
    value.isSelected = !value.isSelected;
    if (this.formFilter.value.status.length > 0) {
      const index = this.formFilter.value.status.indexOf(value);
      if (index > -1) {
        this.formFilter.value.status.splice(index, 1);
      } else {
        this.formFilter.value.status.push(value);
      }
    } else {
      this.formFilter.value.status.push(value);
    }
  }
}
