export enum USER_ROLES {
  CUSTOMER_ADMIN = 'CUSTOMER-ADMIN',
  CUSTOMER_USER = 'CUSTOMER-USER',
  CUSTOMER_USER_GERMAN = 'Kunde - Anwender',
  IC_GLOBAL_ADMIN = 'IC-GLOBAL-ADMIN',
  IC_BRANCH_ADMIN = 'IC-BRANCH-ADMIN',
  IC_USER = 'IC-USER',
}

export class ROLES {
  public static IC_ROLES: USER_ROLES[] = [
    USER_ROLES.IC_GLOBAL_ADMIN,
    USER_ROLES.IC_BRANCH_ADMIN,
    USER_ROLES.IC_USER,
  ];
  public static CUSTOMER_ROLES: USER_ROLES[] = [
    USER_ROLES.CUSTOMER_ADMIN,
    USER_ROLES.CUSTOMER_USER,
  ];
}

export const checkICRole = (role: USER_ROLES): boolean => {
  return ROLES.IC_ROLES.includes(role);
};

export const checkCustomerRole = (role: USER_ROLES): boolean => {
  return ROLES.CUSTOMER_ROLES.includes(role);
};
