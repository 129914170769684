import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  UploadTypeConstants,
} from '@shared/constants/building.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import {
  BuildingObject,
  Criteria,
  OtherIncreasesInRiskObject,
} from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-other-increase-risk',
  templateUrl: './other-increase-risk.component.html',
  styleUrls: ['./other-increase-risk.component.css'],
})
export class OtherIncreaseRiskComponent implements OnInit {
  otherIncreasesInRiskForm: FormGroup;
  @Input('object_data') object_data;
  @Input('objectType') objectType;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  browser: string;
  insertedDielectricData: any = [];
  regularMaintananceByWhomData: any = [];
  patrolCarriedOutData: any = [];
  yesClickText = false;
  showDocumentPlantsPopup = false;
  showDocumentThermalOilPopup = false;
  showDocumentHydraulicSystemPopup = false;
  showDocumentSparkErosionPopup = false;
  selectedPlantsDocumentsArray = {};
  selectedThermalOilDocumentsArray = {};
  selectedHydraulicSystemDocumentsArray = {};
  selectedSparkErosionDocumentsArray = {};
  selectedDocumentsArray = {};
  isFormValueUpdated: boolean;
  uploadObjectDetails: UploadObjectDetails;
  request: BuildingObject;
  plantsDocsIds = [];
  thermalOilDocsIds = [];
  hydraulicSystemDocsIds = [];
  sparkErosionDocsIds = [];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    public validation: ValidationService,
    private serviceCall: ApiCallsService
  ) {
    this.browser = this.detectBrowser();
    this.serviceCall.getLookupData(32).subscribe(data => {
      this.patrolCarriedOutData = data.data;
    });
    this.serviceCall.getLookupData(36).subscribe(data => {
      this.regularMaintananceByWhomData = data.data;
    });
    this.serviceCall.getLookupData(33).subscribe(data => {
      this.insertedDielectricData = data.data;
    });
  }
  ngOnInit(): void {
    this.initializeForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.OTHER_INCREASES_IN_RISK
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;

    const plantsBimschgValidation = new FormControl({
      value: formData ? formData.otherIncreasesInRisk.plants_bimschg : null,
      disabled: false,
    });
    const plantsBimschgDocumentsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.plants_bimschg_documents
        : null,
      disabled: false,
    });
    const notApprovedParkingValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.not_approved_parking
        : null,
      disabled: false,
    });
    const burglarResistantBarsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.burglar_resistant_bars
        : null,
      disabled: false,
    });
    const securedWindowsValidation = new FormControl({
      value: formData ? formData.otherIncreasesInRisk.secured_windows : null,
      disabled: false,
    });
    const areHazardusSubstancesValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.are_hazardus_substances
        : null,
      disabled: false,
    });

    const isThermalOilSystemValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.is_thermal_oil_system
        : false,
      disabled: false,
    });
    const uploadDocumentThermalOilSystemsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thermal_oil_system
            .upload_document_thermal_oil_systems
        : null,
      disabled: false,
    });
    const countValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thermal_oil_system.count
        : null,
      disabled: false,
    });
    const scopeOfApplicationValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thermal_oil_system.scope_of_application
        : null,
      disabled: false,
    });
    const maxOilTempValidation = new FormControl({
      value: formData
        ? this.validation.setSqmValue(
            formData.otherIncreasesInRisk.thermal_oil_system.max_oil_temp
          )
        : null,
      disabled: false,
    });
    const maxAmountOilValidation = new FormControl({
      value: formData
        ? this.validation.setSqmValue(
            formData.otherIncreasesInRisk.thermal_oil_system.max_amount_oil
          )
        : null,
      disabled: false,
    });
    const PressureTemperatureValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thermal_oil_system.pressure_temperature
        : null,
      disabled: false,
    });
    const distanceCumbustibleValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thermal_oil_system.distance_cumbustible
        : null,
      disabled: false,
    });
    const heaterFireproofValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thermal_oil_system.heater_fire_proof
        : null,
      disabled: false,
    });
    const co2ProtectionValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thermal_oil_system.co2Protection
        : null,
      disabled: false,
    });
    const largest_Fire_compartment = new FormControl({
      value: formData
        ? this.validation.setSqmValue(
            formData.otherIncreasesInRisk.thermal_oil_system
              .largest_Fire_compartment
          )
        : null,
      disabled: false,
    });

    const isOilHydraulicSystemValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.is_oil_hydraulic_system
        : false,
      disabled: false,
    });
    const docUploadOilHydraulicValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.oil_hydraulic_system
            .doc_upload_oil_hydraulic
        : null,
      disabled: false,
    });
    const emergencyStopSwitchValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.oil_hydraulic_system
            .emergency_stop_switch
        : null,
      disabled: false,
    });
    const pressureMonitoringValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.oil_hydraulic_system.pressure_monitoring
        : null,
      disabled: false,
    });
    const objectProtectionSystemValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.oil_hydraulic_system
            .object_protection_system
        : null,
      disabled: false,
    });
    const quickShutOffValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.oil_hydraulic_system.quick_shut_off
        : null,
      disabled: false,
    });

    const isSparkErosionSystemValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.is_spark_errosion_system
        : false,
      disabled: false,
    });
    const docUploadSparkErosionValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.spark_errosion_system
            .doc_upload_spark_erosion
        : null,
      disabled: false,
    });
    const fireproofSeperatedValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.spark_errosion_system
            .fireproof_seperated
        : null,
      disabled: false,
    });
    const monitoringValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.spark_errosion_system.monitoring
        : null,
      disabled: false,
    });
    const extinguishingValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.spark_errosion_system.extinguishing
        : null,
      disabled: false,
    });
    const automaticShutdownValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.spark_errosion_system.automatic_shutdown
        : null,
      disabled: false,
    });
    const supervisionDuringValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.spark_errosion_system.supervision_during
        : null,
      disabled: false,
    });
    const insertedDielectricValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.spark_errosion_system
            .inserted_dielectric
        : null,
      disabled: false,
    });

    const isPneumaticMaterialValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.is_pneumatic_material
        : false,
      disabled: false,
    });
    const typeOfMaterialValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.pneumatic_material.type_of_material
        : null,
      disabled: false,
    });
    const automaticShutdownBMAValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.pneumatic_material
            .automatic_shutdown_BMA
        : null,
      disabled: false,
    });
    const sparkExtOrDischargeValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.pneumatic_material
            .spark_ext_or_discharge
        : null,
      disabled: false,
    });
    const filteringSystemsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.pneumatic_material.filtering_systems
        : null,
      disabled: false,
    });
    const isRegularMaintananceValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.pneumatic_material
            .is_regular_maintanance
        : null,
      disabled: false,
    });
    const regularMaintananceByValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.pneumatic_material
            .regular_maintanance_by
        : null,
      disabled: formData
        ? formData.otherIncreasesInRisk.pneumatic_material
            .is_regular_maintanance == false ||
          formData.otherIncreasesInRisk.pneumatic_material
            .is_regular_maintanance == null
          ? true
          : false
        : true,
    });

    const isCheckRoundsValidation = new FormControl({
      value: formData ? formData.otherIncreasesInRisk.is_check_rounds : false,
      disabled: false,
    });
    const whoCarriesPatrolsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.check_rounds.who_carries_patrols
        : null,
      disabled: false,
    });
    const wherePatrolCarriedOutValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.check_rounds.where_patrol_carried_out
        : null,
      disabled: false,
    });
    const captureTimeClockValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.check_rounds.capture_time_clock
        : null,
      disabled: false,
    });

    const isGhostShiftsValidation = new FormControl({
      value: formData ? formData.otherIncreasesInRisk.is_ghost_shifts : false,
      disabled: false,
    });
    const safetyPrecautionsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.ghost_shifts.safety_precautions
        : null,
      disabled: false,
    });
    const annotations = new FormControl({
      value: formData ? formData.otherIncreasesInRisk.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.otherIncreasesInRisk.is_annotation : null,
      disabled: false,
    });

    this.otherIncreasesInRiskForm = new FormGroup({
      plants_bimschg: plantsBimschgValidation,
      plants_bimschg_documents: plantsBimschgDocumentsValidation,
      not_approved_parking: notApprovedParkingValidation,
      burglar_resistant_bars: burglarResistantBarsValidation,
      secured_windows: securedWindowsValidation,
      are_hazardus_substances: areHazardusSubstancesValidation,

      is_thermal_oil_system: isThermalOilSystemValidation,
      upload_document_thermal_oil_systems:
        uploadDocumentThermalOilSystemsValidation,
      count: countValidation,
      scope_of_application: scopeOfApplicationValidation,
      max_oil_temp: maxOilTempValidation,
      max_amount_oil: maxAmountOilValidation,
      pressure_temperature: PressureTemperatureValidation,
      distance_cumbustible: distanceCumbustibleValidation,
      heater_fire_proof: heaterFireproofValidation,
      co2Protection: co2ProtectionValidation,
      largest_Fire_compartment,

      is_oil_hydraulic_system: isOilHydraulicSystemValidation,
      doc_upload_oil_hydraulic: docUploadOilHydraulicValidation,
      emergency_stop_switch: emergencyStopSwitchValidation,
      pressure_monitoring: pressureMonitoringValidation,
      object_protection_system: objectProtectionSystemValidation,
      quick_shut_off: quickShutOffValidation,

      is_spark_errosion_system: isSparkErosionSystemValidation,
      doc_upload_spark_erosion: docUploadSparkErosionValidation,
      fireproof_seperated: fireproofSeperatedValidation,
      monitoring: monitoringValidation,
      extinguishing: extinguishingValidation,
      automatic_shutdown: automaticShutdownValidation,
      supervision_during: supervisionDuringValidation,
      inserted_dielectric: insertedDielectricValidation,

      is_pneumatic_material: isPneumaticMaterialValidation,
      type_of_material: typeOfMaterialValidation,
      automatic_shutdown_BMA: automaticShutdownBMAValidation,
      spark_ext_or_discharge: sparkExtOrDischargeValidation,
      filtering_systems: filteringSystemsValidation,
      is_regular_maintanance: isRegularMaintananceValidation,
      regular_maintanance_by: regularMaintananceByValidation,

      is_check_rounds: isCheckRoundsValidation,
      who_carries_patrols: whoCarriesPatrolsValidation,
      where_patrol_carried_out: wherePatrolCarriedOutValidation,
      capture_time_clock: captureTimeClockValidation,

      is_ghost_shifts: isGhostShiftsValidation,
      safety_precautions: safetyPrecautionsValidation,

      annotations,
      is_annotation,
    });
    this.isFormValueUpdated = false;
    this.otherIncreasesInRiskForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.otherIncreasesInRiskForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
    this.getDocumentUploadData(record_id);
  }
  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.OIIR_PLANTS_ACCORDING_TO_BIMSCHG
              ) {
                this.selectedPlantsDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.OIIR_THERMAL_OIL_SYSTEMS
              ) {
                this.selectedThermalOilDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants['OIIR_OIL-HYDRAULIC_SYSTEMS']
              ) {
                this.selectedHydraulicSystemDocumentsArray[
                  document.document_name
                ] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.OIIR_SPARK_EROSION_SYSTEM
              ) {
                this.selectedSparkErosionDocumentsArray[
                  document.document_name
                ] = document;
              }
            });
            if (data.documents) {
              this.otherIncreasesInRiskForm.controls.plants_bimschg_documents.setValue(
                Object.keys(this.selectedPlantsDocumentsArray).join(', ')
              );
              this.otherIncreasesInRiskForm.controls.upload_document_thermal_oil_systems.setValue(
                Object.keys(this.selectedThermalOilDocumentsArray).join(', ')
              );
              this.otherIncreasesInRiskForm.controls.doc_upload_oil_hydraulic.setValue(
                Object.keys(this.selectedHydraulicSystemDocumentsArray).join(
                  ', '
                )
              );
              this.otherIncreasesInRiskForm.controls.doc_upload_spark_erosion.setValue(
                Object.keys(this.selectedSparkErosionDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  regularMaintananceClicked(value: boolean) {
    if (value) {
      this.otherIncreasesInRiskForm.get('regular_maintanance_by').enable();
    } else {
      this.otherIncreasesInRiskForm.get('regular_maintanance_by').disable();
      this.otherIncreasesInRiskForm.controls.regular_maintanance_by.setValue(
        null
      );
    }
  }
  onYesclick() {
    this.yesClickText = true;
  }
  hideYesclick() {
    this.yesClickText = false;
  }
  uploadPantsDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedPlantsDocumentsArray };
    this.showDocumentPlantsPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.OIIR_PLANTS_ACCORDING_TO_BIMSCHG,
    };
  }
  uploadThermalOilDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedThermalOilDocumentsArray };
    this.showDocumentThermalOilPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.OIIR_THERMAL_OIL_SYSTEMS,
    };
  }
  uploadHydraulicSystemDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedHydraulicSystemDocumentsArray,
    };
    this.showDocumentHydraulicSystemPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants['OIIR_OIL-HYDRAULIC_SYSTEMS'],
    };
  }
  uploadSparkErosionDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedSparkErosionDocumentsArray,
    };
    this.showDocumentSparkErosionPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.OIIR_SPARK_EROSION_SYSTEM,
    };
  }
  showDocumentPopup() {
    return (
      this.showDocumentPlantsPopup ||
      this.showDocumentThermalOilPopup ||
      this.showDocumentHydraulicSystemPopup ||
      this.showDocumentSparkErosionPopup
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showDocumentPlantsPopup) {
      this.plantsDocsIds = [];
      this.selectedPlantsDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentPlantsPopup = false;
      this.otherIncreasesInRiskForm.controls.plants_bimschg_documents.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.plants_bimschg_documents.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.plantsDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentThermalOilPopup) {
      this.thermalOilDocsIds = [];
      this.selectedThermalOilDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentThermalOilPopup = false;
      this.otherIncreasesInRiskForm.controls.upload_document_thermal_oil_systems.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.upload_document_thermal_oil_systems.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.thermalOilDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentHydraulicSystemPopup) {
      this.hydraulicSystemDocsIds = [];
      this.selectedHydraulicSystemDocumentsArray = {
        ...selectedDocumentsArray,
      };
      this.showDocumentHydraulicSystemPopup = false;
      this.otherIncreasesInRiskForm.controls.doc_upload_oil_hydraulic.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.doc_upload_oil_hydraulic.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.hydraulicSystemDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentSparkErosionPopup) {
      this.sparkErosionDocsIds = [];
      this.selectedSparkErosionDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentSparkErosionPopup = false;
      this.otherIncreasesInRiskForm.controls.doc_upload_spark_erosion.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.doc_upload_spark_erosion.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.sparkErosionDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.otherIncreasesInRiskForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }
  buildFormObject() {
    const ThermalOilSystem = {
      upload_document_thermal_oil_systems:
        this.otherIncreasesInRiskForm.value.upload_document_thermal_oil_systems,
      count: this.otherIncreasesInRiskForm.value.count,
      scope_of_application:
        this.otherIncreasesInRiskForm.value.scope_of_application,
      max_oil_temp: this.otherIncreasesInRiskForm.value.max_oil_temp
        ? Number(
            this.validation.removeSymbols(
              this.otherIncreasesInRiskForm.value.max_oil_temp
            )
          )
        : null,
      max_amount_oil: this.otherIncreasesInRiskForm.value.max_amount_oil
        ? Number(
            this.validation.removeSymbols(
              this.otherIncreasesInRiskForm.value.max_amount_oil
            )
          )
        : null,
      pressure_temperature:
        this.otherIncreasesInRiskForm.value.pressure_temperature,
      distance_cumbustible:
        this.otherIncreasesInRiskForm.value.distance_cumbustible,
      heater_fire_proof: this.otherIncreasesInRiskForm.value.heater_fire_proof,
      co2Protection: this.otherIncreasesInRiskForm.value.co2Protection,
      largest_Fire_compartment: this.otherIncreasesInRiskForm.value
        .largest_Fire_compartment
        ? Number(
            this.validation.removeSymbols(
              this.otherIncreasesInRiskForm.value.largest_Fire_compartment
            )
          )
        : null,
    };

    const OilHydraulicSystem = {
      doc_upload_oil_hydraulic:
        this.otherIncreasesInRiskForm.value.doc_upload_oil_hydraulic,
      emergency_stop_switch:
        this.otherIncreasesInRiskForm.value.emergency_stop_switch,
      pressure_monitoring:
        this.otherIncreasesInRiskForm.value.pressure_monitoring,
      object_protection_system:
        this.otherIncreasesInRiskForm.value.object_protection_system,
      quick_shut_off: this.otherIncreasesInRiskForm.value.quick_shut_off,
    };

    const SparkErrosionSystem = {
      doc_upload_spark_erosion:
        this.otherIncreasesInRiskForm.value.doc_upload_spark_erosion,
      fireproof_seperated:
        this.otherIncreasesInRiskForm.value.fireproof_seperated,
      monitoring: this.otherIncreasesInRiskForm.value.monitoring,
      extinguishing: this.otherIncreasesInRiskForm.value.extinguishing,
      automatic_shutdown:
        this.otherIncreasesInRiskForm.value.automatic_shutdown,
      supervision_during:
        this.otherIncreasesInRiskForm.value.supervision_during,
      inserted_dielectric: this.otherIncreasesInRiskForm.value
        .inserted_dielectric
        ? this.otherIncreasesInRiskForm.value.inserted_dielectric
        : null,
    };

    const PneumaticMaterial = {
      type_of_material: this.otherIncreasesInRiskForm.value.type_of_material,
      automatic_shutdown_BMA:
        this.otherIncreasesInRiskForm.value.automatic_shutdown_BMA,
      spark_ext_or_discharge:
        this.otherIncreasesInRiskForm.value.spark_ext_or_discharge,
      filtering_systems: this.otherIncreasesInRiskForm.value.filtering_systems,
      is_regular_maintanance:
        this.otherIncreasesInRiskForm.value.is_regular_maintanance,
      regular_maintanance_by: this.otherIncreasesInRiskForm.value
        .regular_maintanance_by
        ? this.otherIncreasesInRiskForm.value.regular_maintanance_by
        : null,
    };

    const CheckRounds = {
      who_carries_patrols:
        this.otherIncreasesInRiskForm.value.who_carries_patrols,
      where_patrol_carried_out:
        this.otherIncreasesInRiskForm.value.where_patrol_carried_out,
      capture_time_clock:
        this.otherIncreasesInRiskForm.value.capture_time_clock,
    };

    const GhostShifts = {
      safety_precautions:
        this.otherIncreasesInRiskForm.value.safety_precautions,
    };
    const otherIncreasesInRisk: OtherIncreasesInRiskObject = {
      plants_bimschg: this.otherIncreasesInRiskForm.value.plants_bimschg,
      plants_bimschg_documents:
        this.otherIncreasesInRiskForm.value.plants_bimschg_documents,
      not_approved_parking:
        this.otherIncreasesInRiskForm.value.not_approved_parking,
      burglar_resistant_bars:
        this.otherIncreasesInRiskForm.value.burglar_resistant_bars,
      secured_windows: this.otherIncreasesInRiskForm.value.secured_windows,
      are_hazardus_substances:
        this.otherIncreasesInRiskForm.value.are_hazardus_substances,
      is_thermal_oil_system:
        this.otherIncreasesInRiskForm.value.is_thermal_oil_system,
      thermal_oil_system: ThermalOilSystem,
      is_oil_hydraulic_system:
        this.otherIncreasesInRiskForm.value.is_oil_hydraulic_system,
      oil_hydraulic_system: OilHydraulicSystem,
      is_spark_errosion_system:
        this.otherIncreasesInRiskForm.value.is_spark_errosion_system,
      spark_errosion_system: SparkErrosionSystem,
      is_pneumatic_material:
        this.otherIncreasesInRiskForm.value.is_pneumatic_material,
      pneumatic_material: PneumaticMaterial,
      is_check_rounds: this.otherIncreasesInRiskForm.value.is_check_rounds,
      check_rounds: CheckRounds,
      is_ghost_shifts: this.otherIncreasesInRiskForm.value.is_ghost_shifts,
      ghost_shifts: GhostShifts,
      annotations: this.otherIncreasesInRiskForm.value.annotations,
      is_annotation: this.otherIncreasesInRiskForm.value.is_annotation,
    };
    const formValuesToPersist = {
      otherIncreasesInRisk,
    };
    this.request = {
      document_ids: [
        ...new Set([
          ...this.plantsDocsIds,
          ...this.thermalOilDocsIds,
          ...this.hydraulicSystemDocsIds,
          ...this.sparkErosionDocsIds,
        ]),
      ],
      criteria_id: BuildingConstants.OTHER_INCREASES_IN_RISK,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.otherIncreasesInRiskForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
