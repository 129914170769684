import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import { ContactPersonApiService } from '@core/services/httpcalls/contact-person-api.service';
import { FEATURES } from '@shared/constants/string.constants';
import { REGEX } from '@shared/constants/general/regex.constants';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { CustomerManagementSharedService } from '../../customer-management-shared-service';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Component({
  selector: 'app-add-contact-person',
  templateUrl: './add-contact-person.component.html',
  styleUrls: ['./add-contact-person.component.scss'],
})
export class AddContactPersonComponent extends BaseComponent implements OnInit {
  @Input() contactPersonId: string;
  @Input() addContactPerson: boolean;

  @Output() isContactPersonAddEdit = new EventEmitter<boolean>();
  @Output() message = new EventEmitter<string>();
  @Output() isContactPersonAdd = new EventEmitter<boolean>();

  contactPersonForm: FormGroup;
  contactPersonData: any;
  customer_roles = [];
  salutations = [];
  roles_lookup = [];
  readonly locale$ = this._userService.locale$;
  showWarningPopUp = false;
  customerWarningMsg: CustomerWarningMsg;
  isFirstNameError = false;
  isLastNameError = false;
  editCustomer: boolean;
  constructor(
    private _http: ApiCallsService,
    private _cpAPICallService: ContactPersonApiService,
    private readonly _userService: UserService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
    this.customerWarningMsg = {
      cancel: 'product-management.stepper_common_msg.discard',
      ok: 'product-management.stepper_common_msg.cancel_label',
      save_and_continue:
        'product-management.stepper_common_msg.save_and_continue',
      header: 'save-changes.Nicht_gespeicherte',
      body: 'save-changes.object_unsaved_message',
    };
  }
  ngOnInit(): void {
    this._watchOnEditCustomer();
    const requests = [];
    requests.push(
      this._cpAPICallService
        .getContactPersonRoles(FEATURES.CUSTOMER)
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this._http.getSalutation().pipe(catchError(error => of(error)))
    );
    if (this.contactPersonId) {
      requests.push(
        this._cpAPICallService
          .getContactPersonById(+this.contactPersonId, FEATURES.CUSTOMER)
          .pipe(catchError(error => of(error)))
      );
    }
    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (!data[0].hasErrors && data[0].customer_roles) {
          this.setCustomerRoles(data[0]);
        } else {
          this.customer_roles = [];
        }
        this.salutations =
          !data[1].hasErrors && data[1].data ? data[1].data : [];
        this.salutations = this.salutations.filter(
          ele => ele.salutation_id != 3
        );
        if (this.contactPersonId) {
          this.contactPersonData =
            !data[2].hasErrors && data[2].contact_person
              ? data[2].contact_person
              : null;
        }
        this.initializeForm();
      });
  }

  initializeForm(): void {
    const formData = this.contactPersonData ? this.contactPersonData : null;
    this.contactPersonForm = new FormGroup({
      salutation: new FormControl(formData ? formData.salutationId : ''),
      first_name: new FormControl(formData ? formData.firstName : ''),
      last_name: new FormControl(formData ? formData.lastName : ''),
      role: new FormControl(
        formData
          ? formData.roleId
            ? this.customer_roles.filter(
                element => element.id == formData.roleId
              )[0]?.name
            : null
          : null
      ),
      main_contact_person: new FormControl(
        formData ? formData.mainContactPerson : ''
      ),
      comments: new FormControl(formData ? formData.comments : ''),
      street: new FormControl(formData ? formData.street : ''),
      zip: new FormControl(formData ? formData.zip : ''),
      city: new FormControl(formData ? formData.city : ''),
      telephone: new FormControl(formData ? formData.telephone : ''),
      email: new FormControl(formData ? formData.email : ''),
    });
    if (this.addContactPerson) {
      this.isFirstNameError = false;
      this.isLastNameError = false;
      this.contactPersonData = null;
      this.contactPersonForm.reset();
    }
  }
  close(): void {
    this.isContactPersonAddEdit.emit(false);
    this.isContactPersonAdd.emit(false);
    this.contactPersonData = null;
  }
  save(): void {
    const request = {
      id: this.contactPersonData ? this.contactPersonData.id : null,
      salutationId: this.contactPersonForm.controls.salutation.value,
      firstName: this.contactPersonForm.controls.first_name.value,
      lastName: this.contactPersonForm.controls.last_name.value,
      roleId: this.contactPersonForm.controls.role.value
        ? this.customer_roles.filter(
            element =>
              element.name == this.contactPersonForm.controls.role.value
          )[0]?.id
        : null,
      mainContactPerson:
        this.contactPersonForm.controls.main_contact_person.value,
      comments: this.contactPersonForm.controls.comments.value,
      street: this.contactPersonForm.controls.street.value,
      zip: this.contactPersonForm.controls.zip.value,
      city: this.contactPersonForm.controls.city.value,
      telephone: this.contactPersonForm.controls.telephone.value,
      email: this.contactPersonForm.controls.email.value,
      sourceId: +this._customerSharedService.getSelectedCustomerId(),
      sourceType: FEATURES.CUSTOMER,
    };
    this._cpAPICallService
      .addContactPerson(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.isContactPersonAddEdit.emit(false);
        this.message.emit(data.message);
        this.contactPersonData = null;
        if (!request.id) {
          this.isContactPersonAdd.emit(false);
        }
      });
  }

  focusRoleSearch(): void {
    document.getElementById('roleSearch').focus();
  }

  targetCustomerRole(event): void {
    if (event) {
      const data = { data: event };
      if (!this.roles_lookup.includes(event)) {
        this._cpAPICallService
          .addContactPersonRole(FEATURES.CUSTOMER, data)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            if (!data.hasErrors) {
              this._cpAPICallService
                .getContactPersonRoles(FEATURES.CUSTOMER)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  this.setCustomerRoles(data);
                });
            }
          });
      }
    }
  }
  setCustomerRoles(data): void {
    this.customer_roles = data.customer_roles;
    this.roles_lookup = [];
    data.customer_roles.map(element => this.roles_lookup.push(element.name));
  }
  warningPopupGuard(): void {
    this.showWarningPopUp = true;
  }
  handleWarningPopup(event): void {
    this.showWarningPopUp = false;
    !event
      ? this._customerManagementSharedService.IVMSyncToggle$.next(true)
      : this.close();
  }
  saveContactPerson(event): void {
    if (event) {
      this.showWarningPopUp = false;
      this.save();
    }
  }
  validateName(value: any, from: string): void {
    const regexp = new RegExp(REGEX.NAME_VALIDATION);
    if (from == 'first_name') {
      this.isFirstNameError = !regexp.test(value);
    } else if (from == 'last_name') {
      this.isLastNameError = !regexp.test(value);
    }
  }

  private _watchOnEditCustomer(): void {
    this._customerManagementSharedService.isEditCustomerOperation$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.editCustomer = value;
        if (!this.editCustomer && this.contactPersonForm?.dirty) {
          this.warningPopupGuard();
        }
        if (!this.editCustomer && !this.contactPersonForm?.dirty) {
          this.close();
        }
      });
  }
}
