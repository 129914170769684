import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject, of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
} from 'rxjs/operators';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ToastService } from '@core/services/toast.service';
import { NumberValidationsService } from '@core/services/validations/number-validations.service';
import { FORM_METHODS, STATIC_TEXT } from '@shared/constants/string.constants';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import { CLAIM_CP_WARNINIG } from '../constants/claim.constants';
import { ClaimsObject } from '../models/general-claim-data.model';
import { GeneralClaimDataService } from './general-claim-data.service';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { ClaimsShareService } from '../claims-share.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  templateUrl: './general-claim-data.component.html',
  styleUrls: ['./general-claim-data.component.scss'],
})
export class GeneralClaimDataComponent
  extends GeneralClaimDataService
  implements OnInit
{
  contract_id;
  claim_data;
  claimId: string;
  claim_status = [];
  isIvmSynced: boolean;
  assigned_claims_object: any;
  clerk_vm: any;
  type_of_claims: any;
  keyUp = new Subject<string>();
  browser: any;
  is_source_ivm: boolean;
  claim_object: ClaimsObject;
  submitted = false;
  readonly selectLang$ = this._userService.locale$;
  customerWarningMsg: CustomerWarningMsg;
  staticText = STATIC_TEXT;
  private _userId: string;

  constructor(
    public serviceCall: ApiCallsService,
    public _validationService: ValidationService,
    public toastService: ToastService,
    public translate: TranslateService,
    public _currencyCustomPipe: CurrencyCustomPipe,
    public numberValidation: NumberValidationsService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _claimsShareService: ClaimsShareService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router
  ) {
    super(
      serviceCall,
      _validationService,
      _currencyCustomPipe,
      numberValidation
    );
    this.browser = this.detectBrowser();
    this.customerWarningMsg = {
      cancel: CLAIM_CP_WARNINIG.CANCEL,
      ok: CLAIM_CP_WARNINIG.OK,
      save_and_continue: CLAIM_CP_WARNINIG.SAVE,
      header: CLAIM_CP_WARNINIG.HEADING,
      body: CLAIM_CP_WARNINIG.SUBHEADING,
    };
  }

  ngOnInit(): void {
    this.contract_id = atob(this._activatedRoute.snapshot.queryParams.contract);
    this.claimId = atob(this._activatedRoute.snapshot.queryParams.claim || '');
    this._watchOnGeneralData();
    this._watchOnIvmChanging();
    this._watchOnCustomerDivisionId();
    this._watchOnUserId();
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
    this.currencyTypeSearchText.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => this._filterCurrency(value));
  }

  getDropdownData(): void {
    this.getCurrency();
    this.getCauseOfClaim();
    this.serviceCall
      .getProductLookups('claim_status')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.claim_status = data.data));
    this.serviceCall
      .getClaimsAssignedObject(this.contract_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.assigned_claims_object = data.data));
    setTimeout(() => this.initiateForm(), 600);
  }

  getCauseOfClaim(): void {
    this.serviceCall
      .getCauseOfClaim()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.cause_of_claim = data.data;
        this.cause_of_claim.map(element => {
          this.cause_of_claim_lookup.push(element.desc);
        });
      });
  }

  getCurrency(): void {
    this.serviceCall
      .getCurrencies()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.allCurrencyValues = data.currency_types;
        this.currencyTypetemp = data.currency_types;
        this.currencyArray = this.allCurrencyValues;
      });
  }

  initiateForm(): void {
    let formData;
    if (this.claim_data) {
      formData = this.claim_data;
    }
    this.generalClaimsDetailsForm = new FormGroup({
      status: new FormControl(
        {
          value: Object.keys(formData).length ? formData.status_id : null,
          disabled: false,
        },
        Validators.required
      ),
      comment_on_status: new FormControl({
        value: Object.keys(formData).length
          ? formData.comment_on_status
          : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      clerk_vm: new FormControl(
        {
          value: Object.keys(formData).length ? formData.clerk_vm_id : null,
          disabled: false,
        },
        Validators.required
      ),
      notification_date: new FormControl({
        value: Object.keys(formData).length
          ? formData.notification_date
          : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      date_of_claim: new FormControl(
        {
          value: Object.keys(formData).length
            ? formData.claim_date
            : STATIC_TEXT.EMPTY_STRING,
          disabled: false,
        },
        Validators.required
      ),
      type_of_claim_rcs: new FormControl({
        value: Object.keys(formData).length
          ? formData.types_of_claims_id_rcs
          : null,
        disabled: false,
      }),
      type_of_claim_ivm: new FormControl({
        value: Object.keys(formData).length
          ? formData.types_of_claims_id_ivm
          : null,
        disabled: false,
      }),
      claim_location: new FormControl({
        value: Object.keys(formData).length
          ? formData.claim_location
          : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      damage_number_vm: new FormControl({
        value: Object.keys(formData).length
          ? formData.damage_number
          : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      claim_number_vn: new FormControl({
        value: Object.keys(formData).length
          ? formData.claim_number_vn
          : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      reference_vn: new FormControl({
        value: Object.keys(formData).length
          ? formData.reference_vn
          : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      claim_number_vr: new FormControl({
        value: Object.keys(formData).length
          ? formData.claim_number_vr
          : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      amount_of_loss: new FormControl({
        value: Object.keys(formData).length
          ? formData.amount_of_loss
            ? this._currencyCustomPipe.transform(formData.amount_of_loss)
            : null
          : null,
        disabled: false,
      }),
      deductable_amount: new FormControl({
        value: Object.keys(formData).length
          ? formData.deductible_amount
            ? this._currencyCustomPipe.transform(formData.deductible_amount)
            : null
          : null,
        disabled: false,
      }),
      ammount_not_eligible_for_settlement: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.amount_not_eligible_for_settlement
              ? this._currencyCustomPipe.transform(
                  formData.amount_not_eligible_for_settlement
                )
              : null
            : null,
        disabled: false,
      }),
      reserve: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.claim_amount
              ? this._currencyCustomPipe.transform(formData.claim_amount)
              : null
            : null,
        disabled: false,
      }),
      sum_of_payments: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.sum_of_payments
              ? this._currencyCustomPipe.transform(formData.sum_of_payments)
              : null
            : null,
        disabled: true,
      }),
      cause_of_claim: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.cause_of_claim_id
              ? this.cause_of_claim.filter(
                  element => element.id == formData.cause_of_claim_id
                )[0]?.desc
              : STATIC_TEXT.EMPTY_STRING
            : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      police_report: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.is_police_report == true
              ? 1
              : formData.is_police_report == false
                ? 2
                : null
            : null,
        disabled: false,
      }),
      police_fire_number: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.police_file_number
            : STATIC_TEXT.EMPTY_STRING,
        disabled: formData.is_police_report == true ? false : true,
      }),
      assigned_object: new FormControl({
        value:
          Object.keys(formData).length > 0 ? formData.assigned_object_id : null,
        disabled: false,
      }),
      input_tax_deduction_authorization: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.is_input_tax_deduction_authorization == true
              ? 1
              : formData.is_input_tax_deduction_authorization == false
                ? 2
                : null
            : null,
        disabled: false,
      }),
      claim_history: new FormControl({
        value:
          Object.keys(formData).length > 0
            ? formData.claim_history
            : STATIC_TEXT.EMPTY_STRING,
        disabled: false,
      }),
      currency_type: new FormControl({
        value: null,
        disabled: false,
      }),
    });
    if (this.allCurrencyValues.length > 0 && Object.keys(formData).length > 0) {
      this.generalClaimsDetailsForm.controls.currency_type.setValue(
        formData.currency
          ? this.allCurrencyValues.find(
              obj => obj.cur_symbol == formData.currency
            ).cur_code
          : null
      );
      const value = formData.currency
        ? this.allCurrencyValues.find(
            obj => obj.cur_symbol == formData.currency
          ).id
        : this.allCurrencyValues.find(obj => obj.cur_symbol == 'EUR').id;
      this.currancySymbol = this.allCurrencyValues.find(
        obj => obj.id == value
      ).cur_code;
      this.selectedCurrency = this.allCurrencyValues.find(
        obj => obj.id == value
      ).cur_symbol;
    } else {
      this.currancyChanged();
    }
    this.initialFormState = this.setControls();
    this.generalClaimsDetailsForm.controls.police_report.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(obj => {
        if (obj) {
          this.generalClaimsDetailsForm.controls.police_fire_number.enable();
        } else {
          this.generalClaimsDetailsForm.controls.police_fire_number.disable();
          this.generalClaimsDetailsForm.controls.police_fire_number.reset();
        }
      });
    const initialValue = this.generalClaimsDetailsForm.value;
    this.generalClaimsDetailsForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isValueChangedInForm = this.changeDetection();
        this.onCreateGroupFormValueChange(initialValue);
      });

    this.generalClaimsDetailsForm.controls[
      this.isIvmProduct ? 'type_of_claim_rcs' : 'type_of_claim_ivm'
    ].disable();
    if (this._claimsShareService.getIsIvmSynced()) {
      setTimeout(() =>
        this.generalClaimsDetailsForm.disable()
      , 200);
    }
  }

  buildFormObject() {
    this.claim_object = {
      contract_id: this.contract_id,
      claim_id: +this.claimId || null,
      status_id: this.generalClaimsDetailsForm.controls.status.value || null,
      comment_on_status: this.generalClaimsDetailsForm.controls
        .comment_on_status.value
        ? this.generalClaimsDetailsForm.controls.comment_on_status.value
        : null,
      claim_status: null,
      clerk_vm_id: this.generalClaimsDetailsForm.controls.clerk_vm.value
        ? Number(this.generalClaimsDetailsForm.controls.clerk_vm.value)
        : null,
      notification_date: this.generalClaimsDetailsForm.controls
        .notification_date.value
        ? moment(
            this.generalClaimsDetailsForm.controls.notification_date.value
          ).format('YYYY-MM-DD')
        : '',
      claim_date: this.generalClaimsDetailsForm.controls.date_of_claim.value
        ? moment(
            this.generalClaimsDetailsForm.controls.date_of_claim.value
          ).format('YYYY-MM-DD')
        : '',
      types_of_claims_id_rcs: this.generalClaimsDetailsForm.controls
        .type_of_claim_rcs.value
        ? Number(this.generalClaimsDetailsForm.controls.type_of_claim_rcs.value)
        : null,
      types_of_claims_id_ivm: this.generalClaimsDetailsForm.controls
        .type_of_claim_ivm.value
        ? Number(this.generalClaimsDetailsForm.controls.type_of_claim_ivm.value)
        : null,
      claim_location: this.generalClaimsDetailsForm.controls.claim_location
        .value
        ? this.generalClaimsDetailsForm.controls.claim_location.value
        : null,
      damage_number: this.generalClaimsDetailsForm.controls.damage_number_vm
        .value
        ? this.generalClaimsDetailsForm.controls.damage_number_vm.value
        : null,
      claim_number_vn: this.generalClaimsDetailsForm.controls.claim_number_vn
        .value
        ? this.generalClaimsDetailsForm.controls.claim_number_vn.value
        : null,
      claim_number_vr: this.generalClaimsDetailsForm.controls.claim_number_vr
        .value
        ? this.generalClaimsDetailsForm.controls.claim_number_vr.value
        : null,
      reference_vn: this.generalClaimsDetailsForm.controls.reference_vn.value
        ? this.generalClaimsDetailsForm.controls.reference_vn.value
        : null,
      amount_of_loss: this.generalClaimsDetailsForm.controls.amount_of_loss
        .value
        ? this.numberValidation.convertGermanToInt(
            this.generalClaimsDetailsForm.controls.amount_of_loss.value
          )
        : null,
      deductible_amount: this.generalClaimsDetailsForm.controls
        .deductable_amount.value
        ? this.numberValidation.convertGermanToInt(
            this.generalClaimsDetailsForm.controls.deductable_amount.value
          )
        : null,
      amount_not_eligible_for_settlement: this.generalClaimsDetailsForm.controls
        .ammount_not_eligible_for_settlement.value
        ? this.numberValidation.convertGermanToInt(
            this.generalClaimsDetailsForm.controls
              .ammount_not_eligible_for_settlement.value
          )
        : null,
      claim_amount: this.generalClaimsDetailsForm.controls.reserve.value
        ? this.numberValidation.convertGermanToInt(
            this.generalClaimsDetailsForm.controls.reserve.value
          )
        : null,
      sum_of_payments: this.generalClaimsDetailsForm.controls.sum_of_payments
        .value
        ? this.numberValidation.convertGermanToInt(
            this.generalClaimsDetailsForm.controls.sum_of_payments.value
          )
        : null,
      cause_of_claim_id: this.generalClaimsDetailsForm.controls.cause_of_claim
        .value
        ? this.cause_of_claim.filter(
            element =>
              element.desc ==
              this.generalClaimsDetailsForm.controls.cause_of_claim.value
          )[0]?.id
        : null,
      is_police_report: this.generalClaimsDetailsForm.controls.police_report
        .value
        ? this.generalClaimsDetailsForm.controls.police_report.value == 1
          ? true
          : false
        : null,
      police_file_number: this.generalClaimsDetailsForm.controls
        .police_fire_number.value
        ? this.generalClaimsDetailsForm.controls.police_fire_number.value
        : null,
      assigned_object_id: this.generalClaimsDetailsForm.controls.assigned_object
        .value
        ? this.generalClaimsDetailsForm.controls.assigned_object.value
        : null,
      is_input_tax_deduction_authorization: this.generalClaimsDetailsForm
        .controls.input_tax_deduction_authorization.value
        ? this.generalClaimsDetailsForm.controls
            .input_tax_deduction_authorization.value == 1
          ? true
          : false
        : null,
      claim_history: this.generalClaimsDetailsForm.controls.claim_history.value
        ? this.generalClaimsDetailsForm.controls.claim_history.value
        : null,
      currency: this.generalClaimsDetailsForm.controls.currency_type.value
        ? this.allCurrencyValues.find(
            obj =>
              obj.cur_code ==
              this.generalClaimsDetailsForm.controls.currency_type.value
          ).cur_symbol
        : null,
      is_source_ivm: this.is_source_ivm,
      liztu_sa_idnr: +this._claimsShareService.getLiztuSa(),
    };
    Object.keys(this.claim_data).length
      ? (this.claim_object.updated_by = +this._userId)
      : (this.claim_object.created_by = +this._userId);
  }

  saveClaims(): false | undefined {
    this.submitted = true;
    if (
      this.generalClaimsDetailsForm.controls.status.value &&
      this.generalClaimsDetailsForm.controls.clerk_vm.value &&
      this.generalClaimsDetailsForm.controls.date_of_claim.value
    ) {
      this.buildFormObject();
      this.serviceCall
        .saveClaimData(this.claim_object)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.claimId = data.claim_id;
            // navigate
            this.serviceCall
              .getGeneralClaimsData(data.claim_id)
              .pipe(takeUntil(this._destroy$))
              .subscribe(data => {
                this.claim_data = data.data;
                this._updateUrl(this.claimId);
                this.initiateForm();
                this.submitted = false;
              });
          }
          this.toastService.showToastMessage(document, data.message);
        });
    } else {
      this.toastService.showToastMessage(document, this.translate.instant(
        'new-claims.general_details.validation_text'
      ));
      return false;
    }
  }
  handleWarningPopup(event): void {
    this.showWarningPopUp = false;
    if (!event) {
      this._claimsShareService.setEventImvChanging(false);
    } else {
      this._claimsShareService.setEventImvChanging(true);
      this.ngOnInit();
    }
  }

  saveClaimGeneralDetails(): void {
    this.showWarningPopUp = false;
    this.isValueChangedInForm = false;
    const response = this.saveClaims();
    this._claimsShareService.setEventImvChanging(!(response === false));
  }

  private _updateUrl(claimId: string): void {
    this._router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        liztu: this._activatedRoute.snapshot.queryParams.liztu,
        contract: this._activatedRoute.snapshot.queryParams.contract,
        claim: btoa(claimId)
      },
      relativeTo: this._activatedRoute,
    }).then(() => {
      this._claimsShareService.setLiztuSa(this.claim_data?.liztu_sa_idnr);
      this._claimsShareService.setProductInfoId(this.claim_data?.product_info_id);
    });
  }

  private _getTypeOfClaims(divisionId: string): void {
    this.serviceCall
      .getTypeOfClaims(
        divisionId,
        this.contract_id,
        this.isIvmProduct
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.type_of_claims = data.data));
  }

  private _getClerkVm(divisionId: string): void {
    this.serviceCall
      .getClaimsClerkVm(divisionId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.clerk_vm = data.data));
  }

  private _watchOnUserId(): void {
    this._userService.userId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(userId => (this._userId = userId));
  }

  private _watchOnIvmChanging(): void {
    this._claimsShareService.isIvmSynced$.pipe(takeUntil(this._destroy$)).subscribe(value => {
      this.isIvmSynced = value;
      if (value && this.isValueChangedInForm) {
        this.showWarningPopup();
        return;
      }
      if (this.generalClaimsDetailsForm) {
        if (value) {
          this.toggleFormState(FORM_METHODS.DISABLE);
        } else {
          this.toggleFormState(FORM_METHODS.ENABLE);
          this.disableFormControls();
        }
      }
    });
  }

  private _watchOnGeneralData(): void {
    this._claimsShareService.generalData$.pipe(takeUntil(this._destroy$)).subscribe(data => {
      this.claim_data = data;
      this.isIvmProduct = (data as ClaimsObject)?.is_product_ivm || false;
      this.is_source_ivm = (data as ClaimsObject)?.is_source_ivm || false;
      this.getDropdownData();
    });
  }

  private _watchOnCustomerDivisionId(): void {
    this._customerSharedService.selectedCustomerDivisionId$.pipe(
      filterNil(), takeUntil(this._destroy$))
      .subscribe(value => {
        this._getClerkVm(value);
        this._getTypeOfClaims(value);
      });
  }
}
