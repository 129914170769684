<div>
    <div style="padding:0px 10px">
        <div class="table-container"
            style="clear:both;box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);">
            <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10" [scrollWindow]="false"
                class="infiniteScrollDiv">
                <div class="table-component-holder">
                    <mat-table class="table-responsive table table-striped documents template-table" mat-table
                        [dataSource]="dataValuesAvailable" cellpadding="5" cellspacing="0"
                        matSort (matSortChange)="announceSortChange($event)">
                        <ng-container matColumnDef="template_name">
                            <th class="template-table-header" mat-header-cell [attr.colspan]="3" *matHeaderCellDef>
                                <span>
                                  {{'template-management.template_name' | translate}}
                                </span>
                              </th>
                              <td style="font-size: 12px;" mat-cell [attr.colspan]="3" *matCellDef="let element">
                                <span class="ellipsis1 noSafariTooltip main-text" title="{{element.template_name}}">
                                  {{element.template_name}}
                                </span>
                              </td>
                        </ng-container>
                        <ng-container matColumnDef="document_type">
                            <th class="template-table-header" mat-header-cell [attr.colspan]="2" *matHeaderCellDef>
                                <span>
                                  {{'template-management.document_type' | translate}}
                                </span>
                              </th>
                              <td style="font-size: 12px;" mat-cell [attr.colspan]="2" *matCellDef="let element">
                                <span class="ellipsis1 noSafariTooltip main-text" title="{{element.document_type}}">
                                  {{element.document_type}}
                                </span>
                              </td>
                        </ng-container>
                        <ng-container matColumnDef="created_at">
                            <th class="template-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                                <span mat-sort-header>
                                    {{'template-management.created_on' | translate}}
                                    <img alt="" class="arrow-image"
                                        src="../../../assets/images/ic-icons/Sort.svg">
                                </span>
                            </th>
                            <br />
                            <td style="font-size: 12px;" mat-cell [attr.colspan]="2" *matCellDef="let element">
                                <span class="ellipsis1 noSafariTooltip main-text">
                                    {{element.created_at | dateFormat:'dd.MM.yyyy HH:mm'}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="last_updated_on">
                            <th class="template-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                                <span mat-sort-header>
                                    {{'template-management.last_updated_on' | translate}}
                                    <img alt="" class="arrow-image"
                                        src="../../../assets/images/ic-icons/Sort.svg">
                                </span>
                            </th>
                            <td style="font-size: 12px;" mat-cell *matCellDef="let element" [attr.colspan]="2">
                                <span class="ellipsis1 noSafariTooltip main-text">
                                    {{element.last_updated_on | dateFormat:'dd.MM.yyyy HH:mm'}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ACTION">
                            <th mat-header-cell [attr.colspan]="1" *matHeaderCellDef style="padding: 14px 30px !important;">
                                <span>{{'template-management.AKTION_text' | translate}} </span>
                            </th>
                            <td mat-cell [attr.colspan]="1" style="padding: 10px 0 !important;" *matCellDef="let element; let i = index">
                                <div class="control-section" style="position: initial;">
                                    <div class="subscribe">
                                        <button class="myDIV" id="content" style="position: initial;">&#183; &#183;
                                            &#183;</button>
                                        <div class="hide democontent" style="margin-top:-10px">
                                            <button style="width:24px;" type="button" class="edit tool4"
                                            (click)="onProductView(element.id)"
                                                mat-icon-button>
                                                <mat-icon><img alt="" class="imgStyle"
                                                        src='../../../assets/images/Show_linked_products.svg'>
                                                </mat-icon><span
                                                    class="tooltiptextattach">{{'template-management.show_linked_products'
                                                    | translate}}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedAvailableColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedAvailableColumns"></tr>
                    </mat-table>
                </div>
            </div>
        </div>
    </div>
</div>
<product-close-alert-dialog *ngIf="enableDeletePopup" [productwarnmsg]="productWarningMsg" (handlePopup)="handleDeleteTemplatePopup($event)"></product-close-alert-dialog>
<product-linked-template *ngIf="enableProductPopup" [availableProducts]="productListAvailable" (detachProd)="detachProd($event)" (onclose)="handleProductViewPopup()"></product-linked-template>