import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuildingConstants } from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
@Component({
  selector: 'app-mill-installation',
  templateUrl: './mill-installation.component.html',
  styleUrls: ['./mill-installation.component.css'],
})
export class MillInstallationComponent implements OnInit {
  millinstallationsForm: FormGroup;
  driveTypes = [];
  browser: string;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService,
    public validationService: ValidationService
  ) {
    this.browser = this.detectBrowser();
    this.serviceCall
      .getLookupData(25)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.driveTypes = data.data;
      });
  }

  ngOnInit(): void {
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initForm();
    }
  }
  initForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.MILL_INSTALLATIONS
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          BuildingConstants.MILL_INSTALLATIONS
        ].saved_criteria
      );
    this.assignAttributeValues(formData);
    this.isFormValueUpdated = false;
    this.millinstallationsForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.millinstallationsForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
  }
  assignAttributeValues(formData: any) {
    const drive_type = new FormControl(
      {
        value: formData ? formData['mill-installation'].drive_type : null,
        disabled: false,
      },
      []
    );
    const annual_grinding_in_tons = new FormControl(
      {
        value: formData
          ? this.validationService.setSqmValue(
              formData['mill-installation'].annual_grinding_in_tons
            )
          : null,
        disabled: false,
      },
      []
    );
    const is_fireproof_floor_covering = new FormControl(
      {
        value: formData
          ? formData['mill-installation'].is_fireproof_floor_covering
          : null,
        disabled: false,
      },
      []
    );
    const is_single_machine_drive_available = new FormControl(
      {
        value: formData
          ? formData['mill-installation'].is_single_machine_drive_available
          : null,
        disabled: false,
      },
      []
    );
    const is_fire_resistant_cleaning = new FormControl(
      {
        value: formData
          ? formData['mill-installation'].is_fire_resistant_cleaning
          : null,
        disabled: false,
      },
      []
    );
    const is_extraction_of_flammable_vapors = new FormControl(
      {
        value: formData
          ? formData['mill-installation'].is_extraction_of_flammable_vapors
          : null,
        disabled: false,
      },
      []
    );
    const is_speed_monitoring_on_elevators = new FormControl(
      {
        value: formData
          ? formData['mill-installation'].is_speed_monitoring_on_elevators
          : null,
        disabled: false,
      },
      []
    );
    const annotations = new FormControl({
      value: formData ? formData['mill-installation'].annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData['mill-installation'].is_annotation : null,
      disabled: false,
    });
    this.millinstallationsForm = this.fb.group({
      drive_type,
      annual_grinding_in_tons,
      is_fireproof_floor_covering,
      is_single_machine_drive_available,
      is_fire_resistant_cleaning,
      is_extraction_of_flammable_vapors,
      is_speed_monitoring_on_elevators,
      annotations,
      is_annotation,
    });
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  buildFormObject() {
    const formValuesToPersist = {
      'mill-installation': this.millinstallationsForm.value,
    };
    formValuesToPersist['mill-installation'].drive_type = formValuesToPersist[
      'mill-installation'
    ].drive_type
      ? formValuesToPersist['mill-installation'].drive_type
      : null;

    formValuesToPersist['mill-installation'].annual_grinding_in_tons =
      formValuesToPersist['mill-installation'].annual_grinding_in_tons
        ? Number(
            this.validationService.removeSymbols(
              formValuesToPersist['mill-installation'].annual_grinding_in_tons
            )
          )
        : null;

    this.request = {
      criteria_id: BuildingConstants.MILL_INSTALLATIONS,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.millinstallationsForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
