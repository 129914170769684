import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api_URL } from '@shared/constants/url.constants';
import { API_ENDPOINT_URL } from '../../../components/insurer-management/models/insurer-management.constants';
import { getInsurerOverviewRequest } from '../../../components/insurer-management/models/overview.models';
import { STATIC_TEXT } from '@shared/constants/string.constants';

@Injectable({
  providedIn: 'root',
})
export class InsurerApiCallsService {
  constructor(private http: HttpClient) {}

  addUpdateInsurer(data): Observable<any> {
    return this.http.post(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.ADD_UPDATE_INSURER}`,
      data
    );
  }

  getSalutation(): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_SALUTATION}`
    );
  }

  addContactPersonRoles(postData: any, source: string): Observable<any> {
    const params = new HttpParams().append('source_type', source);
    return this.http.post(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_CONTACT_PERSON_ROLES}`,
      postData,
      { params }
    );
  }
  getBaffinDataList(): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_BAFFIN_DATA_LIST}`
    );
  }
  getSingleInsurerData(id): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_INSURER}/${id}`
    );
  }
  checkDuplicateAbbreviation(code): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.CHECK_DUPLICATE_ABBREVIATION}/${code}`
    );
  }
  checkDuplicateBaffinId(id): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.CHECK_DUPLICATE_BAFFIN_ID}/${id}`
    );
  }
  getCommunicationData(data): Observable<any> {
    return this.http.post(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_COMMUNICATION_OVERVIEW_DATA}`,
      data
    );
  }
  getCommunicationType(): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_COMMUNICATION_TYPE}`
    );
  }
  deleteCommunication(id): Observable<any> {
    return this.http.delete(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.DELETE_COMMUNICATION}/${id}`
    );
  }
  getInsurerOverviewData(request: getInsurerOverviewRequest): Observable<any> {
    const params = this._getInsurerOverviewParams(request);
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_INSURER_OVERVIEW}`,
      { params }
    );
  }
  getInsurerLookupData(): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_INSURER_FILTER_LIST}`
    );
  }
  deleteInsurer(insurerId: number): Observable<any> {
    return this.http.delete(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_INSURER}/${insurerId}`
    );
  }

  deleteBrokerCommissionById(id: number): Observable<any> {
    return this.http.delete(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.DELETE_BROKER_COMMISSION}/${id}`
    );
  }
  getBrokerCommissionOverview(request): Observable<any> {
    return this.http.post(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_BROKER_COMMISION}`,
      request
    );
  }
  getAgencyDropdownData(id): Observable<any> {
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_AGENCY_NUMBER}/${id}/filter`
    );
  }
  getAgenecyDropdownDataForAdmins(id, division): Observable<any> {
    const params = new HttpParams().append('division_id', division);
    return this.http.get(
      `${Api_URL.IC_API_URL}${API_ENDPOINT_URL.GET_AGENCY_NUMBER}/${id}/filter`,
      { params }
    );
  }

  private _getInsurerOverviewParams({
    page_number,
    no_of_records,
    insurer_id,
    is_export,
  }: getInsurerOverviewRequest): HttpParams {
    const pageNumber = page_number || 1;
    const recordsNumber = no_of_records || 25;
    const insurerId = insurer_id || STATIC_TEXT.EMPTY_STRING;
    const isExport = is_export || false;

    return new HttpParams()
      .append('page_number', pageNumber.toString(10))
      .append('no_of_records', recordsNumber.toString(10))
      .append('insurer_id', insurerId.toString(10))
      .append('is_export', '' + isExport);
  }
}
