import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FILTER } from '@shared/constants/numeric.constants';
import { BaseComponent } from '../../base.component';
import { skip, takeUntil } from 'rxjs/operators';

export enum FilterType {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  DATE_RANGE = 'DATE_RANGE',
  CHECKBOX = 'CHECKBOX',
  MULTI_SELECT = 'MULTI_SELECT',
}

export interface FilterSelectOption {
  value: string | number;
  label: string;
  data?: any;
}

export interface Filter {
  name: string;
  type: FilterType;
  value?: unknown;
  placeholder?: string;
  options?: FilterSelectOption[];
  startValue?: Date;
  isMultiple?: boolean;
  endValue?: Date;
  label?: string;
  optionComponent?: any;
  valueComponent?: any;
  hidden?: boolean;
}

@Component({
  selector: 'app-filter-section',
  templateUrl: 'filter-section.component.html',
  styleUrls: ['filter-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSectionComponent extends BaseComponent implements OnInit {
  @Input() set filters(value: Filter[]) {
    this._filters = value;
    this._buildFilterForm();
    this._watchOnValueChanges();
  }
  get filters(): Filter[] {
    return this._filters;
  }

  @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
  @Output() load: EventEmitter<any> = new EventEmitter();

  isOpened = true;
  expandedHeight = FILTER.HEIGHT;
  numberOfRows = [25, 50, 75, 100, 125];
  pageSizeControl = new FormControl(25);
  searchControl = new FormControl();
  filterForm: FormGroup;
  filterTypes = FilterType;
  filterDisabled = true;
  _filters: Filter[];

  constructor(private _fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this._watchOnPageSize();
  }

  openAccordion(): void {
    this.isOpened = true;
  }

  closeAccordion(): void {
    this.isOpened = false;
  }

  filter(): void {
    this.load.emit(this.filterForm.value);

    this.filterDisabled = true;
  }

  reset(): void {
    this._resetValuesToDefault();

    this.filter();
  }

  resetSearch(): void {
    this.searchControl.reset();
  }

  getSelectedOption(
    controlName: string,
    value: string | number
  ): FilterSelectOption {
    const filter = this.filters.find(item => item.name === controlName);

    return filter.options.find(option => option.value === value);
  }

  private _resetValuesToDefault(): void {
    this.filters.forEach(filter => {
      if (filter.type === FilterType.DATE_RANGE) {
        this.filterForm.controls[filter.name].patchValue({
          start: filter.startValue || null,
          end: filter.endValue || null,
        });

        return;
      }

      this.filterForm.controls[filter.name].setValue(filter.value || null);
    });
  }

  private _buildFilterForm(): void {
    this.filterForm = this._fb.group({});

    this.filters.forEach(filter => {
      if (filter.type === FilterType.DATE_RANGE) {
        this.filterForm.addControl(
          filter.name,
          this._fb.group({
            start: filter.startValue || null,
            end: filter.endValue || null,
          })
        );

        return;
      }

      this.filterForm.addControl(
        filter.name,
        this._fb.control(filter.value || null)
      );
    });
  }

  private _watchOnPageSize(): void {
    this.pageSizeControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(size => {
        this.pageSizeChanged.emit(size);
      });
  }

  private _watchOnValueChanges(): void {
    this.filterForm.valueChanges
      .pipe(skip(1), takeUntil(this._destroy$))
      .subscribe(() => {
        this.filterDisabled = false;
      });
  }
}
