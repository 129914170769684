import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { StepperLookupConstants } from '@shared/constants/product-management.constants';
import {
  AddProductModuleRequest,
  ConsolidateData,
  ProductSaveRequest,
} from '@shared/models/product-management.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-new-product-dialog',
  templateUrl: './add-new-product-dialog.component.html',
  styleUrls: ['./add-new-product-dialog.component.css'],
})
export class AddNewProductDialogComponent implements OnInit, OnDestroy {
  @Output('hideProductPopup') hideModalValue = new EventEmitter<boolean>();
  @Output('handleNewProduct') handleNewProduct = new EventEmitter<any>();
  @Input('consolidateData') consolidateData: ConsolidateData;
  productId: number;
  productModuleName: string;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(private serviceCall: ApiCallsService) {}
  productForm: FormGroup;
  ProductModuleLookup = [];

  ngOnInit(): void {
    this.initializeForm();
    this.unZipData();
  }

  unZipData() {
    this.productId = this.consolidateData.productId;
    this.productForm.controls.ProductName.setValue(
      this.consolidateData.productName
    );
  }

  initializeForm() {
    this.productForm = new FormGroup({
      ProductName: new FormControl(),
      ProductModule: new FormControl('', Validators.required),
    });
    this.serviceCall
      .stepperDataLookup(2)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.ProductModuleLookup = data.data
            ? data.data.filter(function(e) {
                return e != null;
              })
            : data.data;
        }
      });
  }

  hidePopup() {
    this.hideModalValue.emit(false);
  }

  AddProduct() {
    const fromValues: AddProductModuleRequest = {
      product_info_id: this.productId,
      name:
        this.productForm.controls.ProductModule.value || this.productModuleName,
      stepper_lookup_id: StepperLookupConstants.PRODUCT_MODULE,
      is_selected: true,
      is_deleted: false,
    };

    const request: ProductSaveRequest = {
      stepperLookupId: StepperLookupConstants.PRODUCT_MODULE,
      fromValues,
      valid: this.productForm.valid,
    };
    this.handleNewProduct.emit(request);
  }
  ProductModuleNameSave(event) {
    this.productModuleName = event;
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
