import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { TranslateService } from '@ngx-translate/core';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { StepperLookupConstants } from '@shared/constants/product-management.constants';
import { ConsolidateData, ProductSaveRequest } from '@shared/models/product-management.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-cover-bundles-stepper',
  templateUrl: './cover-bundles-stepper.component.html',
  styleUrls: ['./cover-bundles-stepper.component.css'],
})
export class CoverBundlesStepperComponent implements OnInit, OnDestroy {
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Output('saveFormValue') saveFormValue = new EventEmitter<any>();
  @Output('updatedNodeSelection') emitNodeSelection = new EventEmitter<any>();
  @Input('refreshStepData') refreshStepData: boolean;

  @Input('step') stepIndex;
  @Input('consolidateData') consolidateData: ConsolidateData;
  @Output('isProductdeleteSuccess') isProductdeleteSuccess =
    new EventEmitter<any>();
  coverBundleconsolidateData: ConsolidateData;
  productModuleconsolidateData: ConsolidateData;
  isAddNewStepEnabled: boolean;
  showWarningPopUp: boolean;
  isFormTouched: boolean;
  currentForm: ProductSaveRequest;
  coverBunderData;
  buttonText: string;
  addProductPopUp = false;
  loadTreeData = false;
  isTreeDisabled = false;
  productId: number;
  productName: string;
  isNewProductModuleEnabled = false;
  productMaximumSequenceNumber;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private translate: TranslateService,
    private serviceCall: ApiCallsService,
    private dialog: MatDialog
  ) {
    this.buttonText = this.translate.instant(
      'product-management.product_module_stepper_lables.Add_new_Product_Module_Cover_Bundle'
    );
  }

  ngOnInit(): void {
    this.coverBundleconsolidateData = undefined;
    this.productModuleconsolidateData = undefined;
    this.unZipData();
    this.buildData();
    this.isAddNewStepEnabled = false;
    this.isNewProductModuleEnabled = false;
    this.isFormTouched = false;
    this.showWarningPopUp = false;
    this.loadTreeData = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshStepData && this.productId) {
      this.buildData();
    }
  }

  updatedNodeSelection(selection) {
    this.emitNodeSelection.emit(selection);
  }

  addNewNode(event) {
    this.isAddNewStepEnabled = false;
    this.isNewProductModuleEnabled = false;
    if (event.isResetNode) {
      this.isAddNewStepEnabled = false;
    } else if (event.isUpdate) {
      this.consolidateData.selectedNodeForEditId = event.request.itemId;
      if (event.request.level == 0) {
        this.processProductNode(
          event.request,
          true,
          event.request.sequence_number
        );
      } else {
        this.processNode(
          event.request.parentNode,
          true,
          event.request.sequence_number
        );
      }
    } else {
      this.consolidateData.productCoverBundleId = undefined;
      this.consolidateData.productCoverBundleName = undefined;
      this.processNode(event.request, false, event.request.sequence_number);
    }
  }

  deleteNode(itemId) {
    this.serviceCall
      .deleteStepperNodeData(itemId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.isProductdeleteSuccess.emit(true);
          this.showToastMessage(
            this.translate.instant(
              'product-management.mask_generator.element_deleted_successfully'
            )
          );
        } else {
          this.showToastMessage(
            this.translate.instant(
              'product-management.mask_generator.failed_to_delete_element'
            )
          );
        }
        this.buildData();
      });
  }

  showToastMessage(message) {
    const x = document.getElementById('successToast');
    if (x) {
      x.textContent = message;
      x.className = 'show';
      setTimeout(function () {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }

  processProductNode(event, isUpdate: boolean, sequence_number: number) {
    this.consolidateData.stepperLookUpId =
      StepperLookupConstants.PRODUCT_MODULE;
    this.consolidateData.stepperformDetailId = event.itemId;
    this.consolidateData.productModuleId = event.itemId;
    this.consolidateData.productModuleName = event.item;
    this.consolidateData.isUpdate = isUpdate;
    this.consolidateData.sequence_number = isUpdate
      ? sequence_number
      : (event.children &&
          event.children.length &&
          event.children.filter(child => child.itemId > 0).length) ||
        0;
    this.productModuleconsolidateData = { ...this.consolidateData };
    this.isAddNewStepEnabled = false;
    this.isNewProductModuleEnabled = true;
    this.isFormTouched = false;
  }

  processNode(event, isUpdate: boolean, sequence_number: number) {
    this.consolidateData.stepperLookUpId = StepperLookupConstants.COVER_BUNDLE;
    this.consolidateData.stepperformDetailId = event.itemId;
    this.consolidateData.productModuleId = event.itemId;
    this.consolidateData.productModuleName = event.item;
    this.consolidateData.isUpdate = isUpdate;
    this.consolidateData.sequence_number = isUpdate
      ? sequence_number
      : (event.children &&
          event.children.length &&
          event.children.filter(child => child.itemId > 0).length) ||
        0;
    this.coverBundleconsolidateData = { ...this.consolidateData };
    this.isAddNewStepEnabled = true;
    this.isFormTouched = false;
  }

  unZipData() {
    this.coverBundleconsolidateData = { ...this.consolidateData };
    this.productId = this.consolidateData.productId;
    this.productName = this.consolidateData.productName;
    if (
      this.consolidateData &&
      [2, 3].includes(this.consolidateData.productStateId)
    ) {
      this.isTreeDisabled = true;
    } else {
      this.isTreeDisabled = false;
    }
  }

  buildData() {
    this.serviceCall
      .getProductSpecificStepperData(
        this.productId,
        StepperLookupConstants.PRODUCT_MODULE
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.coverBunderData = data.data;
          this.loadTreeData = true;
          const seqList = data.data.map(value => value.sequence_number);
          this.productMaximumSequenceNumber =
            (seqList && Math.max(...seqList)) || 0;
        }
      });
  }

  formValues(formValue: ProductSaveRequest) {
    this.isFormTouched = formValue.isFormTouched;
    this.currentForm = formValue;
    this.emitFormValue.emit(formValue);
  }

  handlePopup(event) {
    this.showWarningPopUp = false;
    if (this.isAddNewStepEnabled) {
      this.isAddNewStepEnabled = event;
    }
    if (this.isNewProductModuleEnabled) {
      this.isNewProductModuleEnabled = event;
    }
    if (!event) {
      const tempForm = Object.assign(this.currentForm);
      tempForm.isFormTouched = false;
      this.emitFormValue.emit(tempForm);
    }
  }

  closeForm() {
    if (!this.isFormTouched) {
      this.isAddNewStepEnabled = false;
      this.isNewProductModuleEnabled = false;
    } else {
      this.showWarningPopUp = true;
    }
  }

  saveForm() {
    if (this.isFormTouched) {
      if (!this.currentForm.fromValues.name) {
        this.validateForm();
      } else {
        this.currentForm.isFormTouched = false;
        this.saveFormValue.emit(this.currentForm);
        this.isAddNewStepEnabled = false;
        this.isNewProductModuleEnabled = false;
      }
    }
  }
  validateForm() {
    this.formValidMessage();
  }
  formValidMessage() {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(
          'product-management.validation_message'
        ),
      },
      disableClose: false,
    });
  }
  addNewProductModule() {
    this.productModuleconsolidateData = undefined;
    this.isNewProductModuleEnabled = true;
    this.isAddNewStepEnabled = false;
    this.consolidateData.stepperLookUpId =
      StepperLookupConstants.PRODUCT_MODULE;
    this.productModuleconsolidateData = {
      productId: this.consolidateData.productId,
      productName: this.consolidateData.productName,
      isUpdate: false,
      stepperLookUpId: StepperLookupConstants.PRODUCT_MODULE,
      sequence_number: this.productMaximumSequenceNumber,
    };
    this.isFormTouched = false;
  }

  handleNewProduct(event) {
    if (event) {
      this.addProductPopUp = false;
      event.fromValues.sequence_number =
        (this.coverBunderData && this.coverBunderData.length) || 0;
      this.saveFormValue.emit(event);
    }
  }

  hideProductPopup(event) {
    this.addProductPopUp = false;
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
