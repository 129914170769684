export const BANK_API_URLS = {
    BANK_DETAILS: '/api/v1/bank_details'
};

export const BANK_DETAILS_MULTILINGUAL = {
    // CONTACT_PERSON: 'insurer_management.contact_person.contact_person',
    // SALUTATION: 'insurer_management.contact_person.salutation',
    // FIRST_NAME: 'insurer_management.contact_person.first_name',
    // LAST_NAME: 'insurer_management.contact_person.last_name',
    // ROLE: 'insurer_management.contact_person.role',
    // MAIN_CONTACT_PERSON: 'insurer_management.contact_person.main_contact_person',
    // COMMENT: 'insurer_management.contact_person.comments',
    // SELECT_ROLE: 'insurer_management.contact_person.select_role',
    // ADD_CONTACT_PERSON: "insurer_management.contact_person.add_contact_person"
};
