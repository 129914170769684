import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[onlyNumbers]'
})
export class NumbersOnlyDirective {
  @Input() maxDigits: number;
  constructor(
    private el: ElementRef,
    private ngControl: NgControl
  ) { }

  @HostListener('input')
  onInput(): void{
    let inputValue = this.el.nativeElement.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    if (inputValue.length > this.maxDigits) {
      inputValue = inputValue.slice(0, this.maxDigits);
    }
    this.ngControl.control.setValue(inputValue);
    this.el.nativeElement.value = inputValue;
  }

}
