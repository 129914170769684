<div>
  <span class="criteria-heading">{{'objectModule.location.basic_location.basic_location' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="basicLocationForm" id="basicLocationForm">
  <div class="col-md-3 col-sm-6 col-6"  style="position:relative;">
    <label for="address" class="long-label">{{'objectModule.location.basic_location.address' | translate}}</label>
    <input type="text" id="address" class="input pad-right" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.location.basic_location.address' | translate}}" matInput formControlName="address"
      title="{{basicLocationForm.value.address}}"  (focus)="showList = true" (blur)="onFocusOut($event)" (keyup)="keyUp.next($event)">

    <span class="flagIconContainer1">
      <img alt="" class="imgStyle" *ngIf="!isSelectedAddressChange" src="../../../assets/images/ic-icons/Geolocation.svg">
      <img alt="" class="imgStyle" *ngIf="isSelectedAddressChange" src="../../../assets/images/ic-icons/Geolocation.svg">
    </span>

    <ul class="statusFilterList" *ngIf="showList && filterlocation.length">
      <li class="listStyle" *ngFor="let location of filterlocation;index as i" (click)="selectedLocation(location)">
        {{location.freeformAddress}}
      </li>
    </ul>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="address_description" class="long-label">{{'objectModule.location.basic_location.address_description' |
      translate}}</label>
    <input type="text" id="address_description" class="input" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.location.basic_location.address_description' | translate}}" matInput
      formControlName="address_description" title="{{basicLocationForm.value.address_description}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="street" class="long-label">{{'objectModule.location.basic_location.street' |
      translate}}</label>
    <input type="text" id="street" class="input" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.location.basic_location.street' | translate}}" matInput formControlName="street">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="house_no" class="long-label">{{'objectModule.location.basic_location.house_no' |
      translate}}</label>
    <input type="text" id="house_no" class="input" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.location.basic_location.house_no' | translate}}" matInput formControlName="house_no">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="postal_code" class="long-label">{{'objectModule.location.basic_location.postal_code' |
      translate}}</label>
    <input *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="6"
      class="input" formControlName="postal_code"
      placeholder="{{'objectModule.location.basic_location.postal_code' | translate}}"
      (keydown)="validationService.SequenceNumberValidation($event, 'keydown',6)"
      (paste)="validationService.SequenceNumberValidation($event, 'paste',6)"
      numbersOnly>
    <input *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="6" ng-pattern="[0-9]*"
      (paste)="validationService.SequenceNumberValidationBrowserSpecific($event, 'paste',6)"
      (keypress)="validationService.SequenceNumberValidationBrowserSpecific($event, 'keydown',6)"
      class="input numberText" formControlName="postal_code"
      placeholder="{{'objectModule.location.basic_location.postal_code' | translate}}"
      numbersOnly>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="city" class="long-label">{{'objectModule.location.basic_location.city' |
      translate}}</label>
    <input type="text" id="city" class="input" maxlength="100" autocomplete="off"
      placeholder="{{'objectModule.location.basic_location.city' | translate}}" matInput formControlName="city">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="country" class="long-label">{{'objectModule.location.basic_location.country' |
      translate}}</label>
    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" id="country" (openedChange)="search.value = ''"
      placeholder="{{'objectModule.select_lable' | translate}}" formControlName="country"
      (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')" (selectionChange)="search.value = ''">
      <input [hidden]="(countries$ | async).length <= 6" type="text" preventSpace maxlength="20" class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #search>
      <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option  [ngClass]="{'active-option': country.countryName === basicLocationForm.value.country && !basicLocationForm.value.country}"
      *ngFor="let country of validationService._filter(countries$ | async, 'countryName', search.value)" [value]="country.countryName">{{country.countryName}}</mat-option>
    </mat-select>
  </div>


  <div class="col-md-3 col-sm-6 col-6">
    <zurs-common-component  [address]="selectedAddress" (zursAddress)="setZursAddress($event)"
    [isLinkdisabled]="isFormValueUpdated || !selectedAddress || isSelectedAddressChange" [isLocationObject]="true">
    </zurs-common-component>
  </div>

</form>
