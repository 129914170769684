<img
    *ngIf="config.enabled"
    class="present"
    [src]="isBox ? '../../../../assets/images/present1.png' : '../../../../assets/images/present2.png'" 
    alt="present"
    [style.width.px]="width"
    [style.top]="top"
    [style.bottom]="bottom"
    [style.left]="left"
    [style.right]="right">
