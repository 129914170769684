import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { parse, stringify } from 'flatted';
import { Dictionary } from '../../../models/dictionary.model';
import {
  ProductWarningMsg,
  UpdatedSelectionNodes,
} from '../../../models/product-management.model';
import { ItemFlatNode, ItemNodeList } from './tree-list.model';
import { TreeRootStructure } from './TreeRootStructure';

@Component({
  selector: 'tree-list-component',
  templateUrl: 'tree-list.component.html',
  styleUrls: ['tree-list.component.css'],
})
export class TreeListComponent extends TreeRootStructure implements OnInit {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNodeList>();

  flatNodeMapList = new Map<number, ItemFlatNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNodeList, ItemFlatNode>();

  uncheckedNodeList = new Set<number>();

  /** A selected parent node to be inserted */
  selectedParent: ItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNodeList, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNodeList, ItemFlatNode>;
  productWarningMsg: ProductWarningMsg;
  showWarningPopUp: boolean;
  selectedNodeToDelete: any;
  showPrimaryDeleteButton: boolean;
  showSecondaryDeleteButton: boolean;
  selectedNodeId: number;
  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);

  @Input('treeData') treeData;
  @Input('buttonText') buttonText;
  @Output('addNewNode') addNewNode = new EventEmitter<any>();
  @Output('deleteNode') deleteNode = new EventEmitter<any>();

  @Output('updatedNodeSelection') updatedNodeSelection =
    new EventEmitter<any>();
  flatNodeIds = 0;
  flatNodeIdToOpen: number;
  selectedNodes: Dictionary<UpdatedSelectionNodes> = {};
  isEditEnabled: boolean;
  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  flatNodeToExpand: number;

  editEnable() {
    this.isEditEnabled = !this.isEditEnabled;
  }

  ngOnInit(): void {
    this.inializeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inializeData();
  }
  constructor() {
    super();
    this.showWarningPopUp = false;
    this.productWarningMsg = {
      cancel: 'product-management.mask_generator.no_text',
      ok: 'product-management.mask_generator.yes_delete',
      header: 'objectModule.Delete_popup_heading',
      body: 'product-management.mask_generator.delete_popup_warning',
    };
    this.inializeData();
  }

  handlePopup(proceedWithDelete: boolean) {
    this.showWarningPopUp = false;
    if (
      !proceedWithDelete &&
      this.selectedNodeToDelete &&
      this.selectedNodeToDelete.itemId
    ) {
      this.flatNodeToExpand = this.selectedNodeToDelete.isLastElement
        ? this.selectedNodeToDelete.flatNodeIds - 1
        : this.selectedNodeToDelete.flatNodeIds + 1;
      this.deleteNode.emit(this.selectedNodeToDelete.itemId);
    }
  }
  onDelete(selectedNode) {
    this.showWarningPopUp = true;
    this.selectedNodeToDelete = selectedNode;
  }
  inializeData() {
    this.uncheckedNodeList = new Set();
    this.flatNodeIds = 0;
    this.flatNodeIdToOpen = undefined;
    this.showPrimaryDeleteButton = false;
    this.showSecondaryDeleteButton = false;
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
    this.treeData && this.intializeTree(this.treeData);
    this.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    this.flatNodeIdToOpen = this.flatNodeToExpand;
    if (this.flatNodeIdToOpen) {
      this.expandSelectedList();
      this.flatNodeToExpand = null;
    }
    this.expandAll();
  }

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  shouldEnableBackground(node: ItemFlatNode) {
    return (
      this.endNodeLevel != 4 &&
      !this.isDisabled &&
      (node.level == this.endNodeLevel ||
        (this.endNodeLevel == 1 && node.level == this.endNodeLevel - 1))
    );
  }

  getChildren = (node: ItemNodeList): ItemNodeList[] => node.children;

  getChildrenSize = (node: ItemNodeList): number =>
    node.children && node.children.length

  hasChild = (_: number, _nodeData: ItemFlatNode) =>
    _nodeData.expandable && _nodeData.itemId != 0

  showShowButton = (_: number, _nodeData: ItemFlatNode) =>
    _nodeData.level == this.endNodeLevel &&
    _nodeData.isLastElement &&
    this.endNodeLevel != 4 &&
    !this.isDisabled
  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNodeList, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new ItemFlatNode();
    flatNode.item = node.item;
    flatNode.sequence_number = node.sequence_number;
    flatNode.itemId = node.itemId;
    flatNode.level = level;
    flatNode.parentNode = node.parentNode;
    flatNode.isLastElement = node.isLastElement;
    flatNode.expandable = !!node.children;
    flatNode.flatNodeIds = this.flatNodeIds++;
    if (node.isSelected && !this.uncheckedNodeList.has(node.itemId)) {
      this.checklistSelection.toggle(flatNode);
    }
    this.flatNodeMap.set(flatNode, node);
    this.flatNodeMapList.set(flatNode.itemId, flatNode);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  selectedSecondaryTree(node: any) {
    this.selectedNodeId = node.itemId;
    this.showPrimaryDeleteButton = false;
    this.showSecondaryDeleteButton = true;
  }

  selectedPrimaryTree(node: any) {
    this.selectedNodeId = node.itemId;
    this.showPrimaryDeleteButton = true;
    this.showSecondaryDeleteButton = false;
  }
  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.updateNodeSelection([node]);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    this.updateNodeSelection(descendants);
  }

  updateNodeSelection(nodes: ItemFlatNode[], updateSequence = false) {
    let nodeSequence = 0;
    nodes.forEach(node => {
      if (node.itemId === 0) {
        return;
      }
      let tempNode = node;
      if (!node.flatNodeIds) {
        const node1 = this.treeControl.dataNodes.find(
          n => n.itemId === node.itemId
        );
        node1.isSelected = this.checklistSelection.isSelected(node1);
        tempNode = node1;
      } else {
        tempNode.isSelected = this.checklistSelection.isSelected(node);
      }
      if (tempNode.isSelected) {
        this.uncheckedNodeList.delete(tempNode.itemId);
      } else {
        this.uncheckedNodeList.add(tempNode.itemId);
      }

      const child =
        node.parentNode &&
        node.parentNode.children &&
        node.parentNode.children.filter(child => child.itemId == 0);
      if (child && child.length > 0) {
        if (child[0].isSelected !== tempNode.isSelected) {
          child[0].isSelected = tempNode.isSelected;
          this.checklistSelection.toggle(this.nestedNodeMap.get(child[0]));
        }
      }
      this.selectedNodes[tempNode.itemId] = {
        id: tempNode.itemId,
        is_selected: this.checklistSelection.isSelected(tempNode),
        sequence_number: updateSequence ? nodeSequence++ : node.sequence_number,
      };
    });
    this.updatedNodeSelection.emit(Object.values(this.selectedNodes));
  }

  updateNodeSelectionOnDrag(nodes: ItemFlatNode[], updateSequence = false) {
    let nodeSequence = 0;
    nodes.forEach(node => {
      if (node.itemId === 0) {
        return;
      }
      let tempNode = node;
      if (!node.flatNodeIds) {
        const node1 = this.treeControl.dataNodes.find(
          n => n.itemId === node.itemId
        );
        node1.isSelected = this.checklistSelection.isSelected(node1);
        tempNode = node1;
      } else {
        tempNode.isSelected = this.checklistSelection.isSelected(node);
      }
      if (tempNode.isSelected) {
        this.uncheckedNodeList.delete(tempNode.itemId);
      } else {
        this.uncheckedNodeList.add(tempNode.itemId);
      }
      this.selectedNodes[tempNode.itemId] = {
        id: tempNode.itemId,
        is_selected: this.checklistSelection.isSelected(tempNode),
        sequence_number: updateSequence ? nodeSequence++ : node.sequence_number,
      };
    });
    this.updatedNodeSelection.emit(Object.values(this.selectedNodes));
  }

  isNodeSelected(node: ItemFlatNode) {
    return this.checklistSelection.isSelected(node);
  }

  expandSelectedList() {
    if (
      this.flatNodeIdToOpen > 0 &&
      this.treeControl.dataNodes.length > 0 &&
      this.flatNodeIdToOpen < this.treeControl.dataNodes.length
    ) {
      this.treeControl.expand(
        this.treeControl.dataNodes[this.flatNodeIdToOpen]
      );
      let parentNode =
        this.treeControl.dataNodes[this.flatNodeIdToOpen].parentNode;
      while (this.flatNodeIdToOpen >= 0) {
        if (!parentNode) {
          break;
        }
        if (
          parentNode.itemId ==
          this.treeControl.dataNodes[this.flatNodeIdToOpen].itemId
        ) {
          this.treeControl.expand(
            this.treeControl.dataNodes[this.flatNodeIdToOpen]
          );
          parentNode =
            this.treeControl.dataNodes[this.flatNodeIdToOpen].parentNode;
        }
        this.flatNodeIdToOpen--;
      }
    }
  }

  expandAll() {
    this.treeControl.dataNodes &&
      this.treeControl.dataNodes.forEach(nodeToExpand => {
        this.treeControl.expand(nodeToExpand);
      });
  }

  updateNodeItemRoot(node: ItemFlatNode) {
    this.flatNodeToExpand = node.flatNodeIds;
    if (
      this.endNodeLevel == 4 &&
      (node.level == 1 || node.level == 2 || node.level == 0)
    ) {
      this.addNewNode.emit({ isUpdate: true, request: node });
    } else if (this.endNodeLevel == 4) {
      this.addNewNode.emit({ isUpdate: false, request: node });
    } else if (this.endNodeLevel == 1 && node.level == 0) {
      this.addNewNode.emit({ isUpdate: true, request: node });
    } else if (node.level != this.endNodeLevel) {
      this.addNewNode.emit({ isResetNode: true, request: node });
    }
  }

  updateNodeItem(node: ItemFlatNode) {
    this.flatNodeToExpand = node.flatNodeIds;
    if (
      node.level == this.endNodeLevel ||
      (this.endNodeLevel == 1 && node.level == 0)
    ) {
      this.addNewNode.emit({ isUpdate: true, request: node });
    } else if (
      this.endNodeLevel == 4 &&
      (node.level == 1 || node.level == 2 || node.level == 0)
    ) {
      this.addNewNode.emit({ isUpdate: true, request: node });
    } else if (this.endNodeLevel == 4) {
      this.addNewNode.emit({ isUpdate: false, request: node });
    }
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: ItemFlatNode, zeroNode: boolean) {
    this.flatNodeToExpand = node.flatNodeIds;
    const nodeToSend =
      node.level == this.endNodeLevel - 1 ? node : node.parentNode;
    const value = nodeToSend || node;
    value.sequence_number = zeroNode
      ? 0
      : node.parentNode &&
        node.parentNode.children &&
        node.parentNode.children.filter(child => child.itemId > 0).length - 1;
    this.addNewNode.emit({ isUpdate: false, request: value });
  }

  /** Save the node to rootNode */
  saveNode(node: ItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this.updateItem(nestedNode!, itemValue);
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) {
      return;
    }

    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes();

    // deep clone the data source so we can mutate it
    const changedData = parse(stringify(this.dataSource.data));

    // determine where to insert the node
    const nodeAtDest = visibleNodes[event.currentIndex];
    const newSiblings = this.findNodeSiblings(changedData, nodeAtDest.itemId);
    if (!newSiblings) {
      return;
    }
    const insertIndex = newSiblings.findIndex(
      s => s.itemId === nodeAtDest.itemId
    );

    // remove the node from its old place
    const node = event.item.data;
    const siblings = this.findNodeSiblings(changedData, node.itemId);
    const siblingIndex = siblings.findIndex(n => n.itemId === node.itemId);
    const nodeToInsert: ItemNodeList = siblings.splice(siblingIndex, 1)[0];
    if (nodeAtDest.itemId === nodeToInsert.itemId) {
      return;
    }

    if (
      nodeAtDest.parentNode &&
      nodeToInsert.parentNode &&
      nodeAtDest.parentNode.itemId !== nodeToInsert.parentNode.itemId
    ) {
      return;
    }
    // ensure validity of drop - must be same level
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find(
      n => nodeAtDest.itemId === n.itemId
    );
    if (nodeAtDestFlatNode.level !== node.level) {
      return;
    }

    // insert node
    newSiblings.splice(insertIndex, 0, nodeToInsert);

    this.updateNodeSelectionOnDrag(siblings, true);
    // rebuild tree with mutated data
    this.rebuildTreeForData(changedData);
    this.expandAll();
  }

  // recursive find function to find siblings of node
  findNodeSiblings(arr: Array<any>, id: number): Array<any> {
    let result, subResult;
    arr.forEach((item, i) => {
      if (item.itemId === id) {
        result = arr;
      } else if (item.children) {
        subResult = this.findNodeSiblings(item.children, id);
        if (subResult) {
          result = subResult;
        }
      }
    });
    return result;
  }

  visibleNodes(): ItemNodeList[] {
    const result = [];
    this.dataSource.data.forEach(node => {
      this.addExpandedChildren(node, this.checklistSelection.selected, result);
    });
    return result;
  }

  addExpandedChildren(node: ItemNodeList, expanded: ItemFlatNode[], result) {
    result.push(node);
    if (this.treeControl.isExpanded(this.flatNodeMapList.get(node.itemId))) {
      node.children &&
        node.children
          .filter(child => child.itemId != 0)
          .map(child => {
            if (child.children) {
              this.addExpandedChildren(
                child,
                this.checklistSelection.selected,
                result
              );
            } else {
              result.push(child);
            }
          });
    }
  }

  rebuildTreeForData(data: any) {
    this.dataSource.data = data;
    this.checklistSelection.selected.forEach(id => {
      const node = this.treeControl.dataNodes.find(n => n.itemId === id.itemId);
      this.treeControl.expand(node);
    });
  }

  dragStart() {
    this.dragging = true;
  }
  dragEnd() {
    this.dragging = false;
  }
  dragHover(node: ItemFlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node);
      }, this.expandDelay);
    }
  }

  disabledMove(node: ItemFlatNode) {
    if (this.endNodeLevel == 1 && node.level == this.endNodeLevel - 1) {
      return this.isDisabled || false;
    }
    return this.isDisabled || node.level != this.endNodeLevel;
  }

  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }

  checkNodeButton(node: any) {
    return (
      node.level == this.endNodeLevel - 1 &&
      !node.expandable &&
      this.endNodeLevel != 4
    );
  }

  isWindows() {
    return navigator.userAgent.indexOf('Windows') != -1;
  }
}
