import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarSizeEnum } from '../../models/avatar.model';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AvatarComponent {
  @Input() size: keyof typeof AvatarSizeEnum = 'md';
  @Input() src: string;
  @Input() alt: string;
  @Input() initials: string;

  avatarSizes = AvatarSizeEnum;
  background: string;

  ngOnInit(): void {
    this.background = this._getRandomColor();
  }

  private _getRandomColor(): string {
    if (!this.initials) {
      return '#000';
    }

    // get first alphabet in upper case
    const firstAlphabet = this.initials.charAt(0).toLowerCase();

    // get the ASCII code of the character
    const asciiCode = firstAlphabet.charCodeAt(0);

    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

    const num = Math.round(0xffffff * parseInt(colorNum));
    const r = num >> 16 & 255;
    const g = num >> 8 & 255;
    const b = num & 255;

    return 'rgb(' + r + ', ' + g + ', ' + b + ', 0.5)';
  }
}
