import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { StepperLookupConstants } from '@shared/constants/product-management.constants';
import {
  ConsolidateData,
  ProductSaveRequest,
} from '@shared/models/product-management.model';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mask-generator-stepper',
  templateUrl: './mask-generator-stepper.component.html',
  styleUrls: ['./mask-generator-stepper.component.css'],
})
export class MaskGeneratorStepperComponent
  extends BaseComponent
  implements OnInit
{
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Output('saveFormValue') saveFormValue = new EventEmitter<any>();
  @Output('saveFormulaValue') saveFormulaValue = new EventEmitter<any>();

  @Input('refreshStepData') refreshStepData: boolean;

  @Input('step') stepIndex;
  @Input('consolidateData') consolidateData: ConsolidateData;
  @Output('updatedNodeSelection') emitNodeSelection = new EventEmitter<any>();

  maskGenFormData: any;
  isAutoPopulatedSelected: boolean;
  loadTreeData = false;
  productId: number;
  selectedNode: any;

  showWarningPopUp: boolean;
  isFormTouched: boolean;
  coveringData: any;
  buttonText: string;
  currentForm: ProductSaveRequest;
  showFormulaPopup = false;
  isTreeDisabled = false;
  formulaList = [];
  request: any;
  formula: any;
  is_calculation = false;
  targetVariableParentName: any;
  ifElseId = [];
  ifElseDisabled = false;
  configData: any;

  constructor(
    private translate: TranslateService,
    private serviceCall: ApiCallsService,
    private dialog: MatDialog,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private readonly _userService: UserService
  ) {
    super();
    this.buttonText = this.translate.instant(
      'product-management.product_module_stepper_lables.Add_new_Covering'
    );
  }

  ngOnInit(): void {
    this.unZipData();
    this.buildData();
    this.isFormTouched = false;
    this.showWarningPopUp = false;
    this.loadTreeData = false;
  }

  valueCalculatorAPI(request): void {
    this.getFormlaList(request.itemId);
  }

  getFormlaList(itemId: number): void {
    this.serviceCall
      .getFormulaList(itemId, false)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => (this.formulaList = data.data));
  }

  addNewNode(event): void {
    this.request = event.request;
    this.valueCalculatorAPI(this.request);
    if (event.isUpdate) {
      this.processNode(event.request);
      this.callAPIs();
    } else {
      this.selectedNode = undefined;
    }
  }

  updatedNodeSelection(selection): void {
    this.emitNodeSelection.emit(selection);
  }

  processNode(event): void {
    this.selectedNode = event;
    this.isFormTouched = false;
  }

  unZipData(): void {
    this.productId = this.consolidateData.productId;
    if (
      this.consolidateData &&
      [2, 3].includes(this.consolidateData.productStateId)
    ) {
      this.isTreeDisabled = true;
    } else {
      this.isTreeDisabled = false;
    }
  }

  callAPIs(): void {
    if (this.selectedNode && this.selectedNode.itemId) {
      this.serviceCall
        .getStepperNodeData(this.selectedNode.itemId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.isAutoPopulatedSelected =
              data.data && data.data.is_auto_fill_data_enabled;
            if (this.selectedNode.level == 2 && this.isAutoPopulatedSelected) {
              this.serviceCall
                .getStepperNodeData(this.selectedNode.parentNode.itemId)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data1 => {
                  if (!data1.HasErrors) {
                    this.maskGenFormData =
                      data1.data &&
                      data1.data.mask_gen_config_data &&
                      JSON.parse(data1.data.mask_gen_config_data);
                    this.configData = data1.data;
                  }
                });
            } else {
              this.maskGenFormData =
                data.data &&
                data.data.mask_gen_config_data &&
                JSON.parse(data.data.mask_gen_config_data);
              this.configData = data.data;
            }
          }
        });
    }
  }

  buildData(): void {
    this.serviceCall
      .getMaskGeneratorTreeData(
        this.productId,
        StepperLookupConstants.COVERINGS
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.coveringData = data.data;
          this.loadTreeData = true;
        }
      });
  }

  formValues(formValue: ProductSaveRequest): void {
    this.isFormTouched = formValue.isFormTouched;
    this.currentForm = formValue;
    this.emitFormValue.emit(this.getPayLoad(this.currentForm));
  }

  handlePopup(event): void {
    this.showWarningPopUp = false;
    if (!event) {
      const tempForm = Object.assign(this.getPayLoad(this.currentForm));
      tempForm.isFormTouched = false;
      this.selectedNode = undefined;
      this.emitFormValue.emit(tempForm);
    }
  }

  closeForm(): void {
    !this.isFormTouched
      ? (this.selectedNode = undefined)
      : (this.showWarningPopUp = true);
  }

  saveForm(): void {
    if (this.isFormTouched) {
      this.currentForm.isFormTouched = false;
      this.saveFormValue.emit(this.currentForm);
      this.selectedNode = undefined;
    }
  }

  getPayLoad(formData1: ProductSaveRequest): object {
    const formData = Object.assign(formData1);
    const newFormValues = {
      id: this.selectedNode.itemId,
      mask_gen_config_data: JSON.stringify(formData.fromValues),
    };
    formData.fromValues = newFormValues;
    formData.isMaskGenSave = true;
    return formData;
  }

  addValueCalculator(): void {
    if (this.request.level == 2) {
      this.targetVariableParentName =
        this.request.parentNode.parentNode.item +
        '.' +
        this.request.parentNode.item +
        '.' +
        this.request.item;
    }
    if (this.request.level == 1) {
      this.targetVariableParentName =
        this.request.parentNode.item + '.' + this.request.item;
    }
    if (this.request.level == 0) {
      this.targetVariableParentName = this.request.item;
    }
    const item = {
      created_by: null,
      evaluated_formula: null,
      formula_name: null,
      id: null,
      is_validated: null,
      stepper_form_detail_id: this.request.itemId,
      target_variable_attribute: null,
      ui_formula: null,
      updated_by: null,
      product_info_id: this.productId,
    };
    this.showFormulaPopup = true;
    this.formula = item;
  }

  hideFormulaPopup(event): void {
    this.showFormulaPopup = event;
    this.is_calculation = false;
  }

  editFormula(item): void {
    // if (formula[0].condition) {
    //   this.ifElseId.push(item.id);
    //   const draftWarning =
    //     "product-management.stepper_common_msg.if_else_Edit_Alert";
    //   this.showProductStateUpdateAlert(draftWarning);
    // } else {
    if (this.request.level == 2) {
      this.targetVariableParentName =
        this.request.parentNode.parentNode.item +
        '.' +
        this.request.parentNode.item +
        '.' +
        this.request.item;
    }
    if (this.request.level == 1) {
      this.targetVariableParentName =
        this.request.parentNode.item + '.' + this.request.item;
    }

    if (this.request.level == 0) {
      this.targetVariableParentName = this.request.item;
    }
    this.showFormulaPopup = true;
    item.product_info_id = this.productId;
    this.formula = item;
    // }
  }

  showProductStateUpdateAlert(message: string): void {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(message),
      },
      disableClose: false,
    });
  }

  deleteFormula(id): void {
    this.serviceCall
      .deleteFormula(id, this._userService.getUserId())
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          // Emit saveformvalue so that we can use it for versioning.
          this.saveFormulaValue.emit(true);
          this.valueCalculatorAPI(this.request);
          this._toastService.showToastMessage(
            document,
            this._translateService.instant(
              'value-calculator.formulaDeleteMessage'
            )
          );
        } else {
          this._toastService.showToastMessage(document, data.message);
        }
      });
  }

  addNewFormula(event): void {
    this.showFormulaPopup = false;
    if (!event) {
      // Emit saveformvalue so that we can use it for versioning.
      this.saveFormulaValue.emit(true);
      this.valueCalculatorAPI(this.request);
    }
  }

  getMessage(event): void {
    this._toastService.showToastMessage(document, event);
  }

  viewCalculations(item): void {
    this.editFormula(item);
    this.is_calculation = true;
  }
}
