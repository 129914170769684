import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  GrindingFormConstants,
} from '@shared/constants/building.constants';
import { BuildingObject } from '@shared/models/request.model';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
@Component({
  selector: 'app-grinding-polishing',
  templateUrl: './grinding-polishing.component.html',
  styleUrls: ['./grinding-polishing.component.css'],
})
export class GrindingPolishingComponent
  extends BaseComponent
  implements OnInit
{
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;
  grindingForm: FormGroup;
  readonly selectLang$ = this._userService.locale$;
  isFormValueUpdated: boolean;
  request: BuildingObject;
  constructor(
    private readonly _userService: UserService,
    private grindingObjectBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    const formData =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.GRINDING_AND_POLISHING
      ] &&
      JSON.parse(
        this.criteriaRequestDetails.criteriaList[
          BuildingConstants.GRINDING_AND_POLISHING
        ].saved_criteria
      );
    const use_of_iron_lightMetal = new FormControl({
      value: formData ? formData.grinding_and_polishing.is_use_of_iron : null,
      disabled: false,
    });
    const extraction_with_adequate_cleaning = new FormControl({
      value: formData
        ? formData.grinding_and_polishing.is_adequate_cleaning
        : null,
      disabled: false,
    });
    const seperate_extraction_ferrous_nonFerrous = new FormControl({
      value: formData
        ? formData.grinding_and_polishing.is_separate_suction_ferrous
        : null,
      disabled: false,
    });
    const dry_seperator = new FormControl({
      value: formData ? formData.grinding_and_polishing.is_dry_separator : null,
      disabled: false,
    });
    const fireproof_partition = new FormControl({
      value: formData
        ? formData.grinding_and_polishing.is_fireproof_partition
        : null,
      disabled: false,
    });
    const pressure_reliefe = new FormControl({
      value: formData ? formData.grinding_and_polishing.is_pressure_load : null,
      disabled: false,
    });
    const wet_seperator = new FormControl({
      value: formData ? formData.grinding_and_polishing.is_wet_separator : null,
      disabled: false,
    });

    const non_ferrus_metal_seperator = new FormControl({
      value: formData
        ? formData.grinding_and_polishing.non_ferrus_metal_seperator
        : null,
      disabled: false,
    });
    const annotations = new FormControl({
      value: formData ? formData.grinding_and_polishing.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.grinding_and_polishing.is_annotation : null,
      disabled: false,
    });

    this.grindingForm = this.grindingObjectBuilder.group({
      use_of_iron_lightMetal,
      extraction_with_adequate_cleaning,
      seperate_extraction_ferrous_nonFerrous,
      dry_seperator,
      fireproof_partition,
      pressure_reliefe,
      wet_seperator,
      non_ferrus_metal_seperator,
      annotations,
      is_annotation,
    });

    this.isFormValueUpdated = false;
    this.grindingForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isFormValueUpdated = true;
        this.buildFormObject();
        const pristine = this.grindingForm.pristine;
        this.saveCriteriaRequest.emit({
          request: this.request,
          isSave: false,
          pristine,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  buildFormObject() {
    const grindingFromAttributeList = {
      is_use_of_iron:
        this.grindingForm.value[GrindingFormConstants.USE_OF_IRON_LIGHT_METAL],
      is_adequate_cleaning:
        this.grindingForm.value[
          GrindingFormConstants.EXTRACTION_WITH_ADEQUATE_CLEANING
        ],
      is_separate_suction_ferrous:
        this.grindingForm.value[
          GrindingFormConstants.SEPERATE_EXTRACTION_FERROUS_NON_FERROUS
        ],
      is_dry_separator:
        this.grindingForm.value[GrindingFormConstants.DRY_SEPERATOR],
      is_fireproof_partition:
        this.grindingForm.value[GrindingFormConstants.FIRE_PROOF_PARTITION],
      is_pressure_load:
        this.grindingForm.value[GrindingFormConstants.PRESSURE_RELIEFE],
      is_wet_separator:
        this.grindingForm.value[GrindingFormConstants.WET_SEPERATOR],
      non_ferrus_metal_seperator:
        this.grindingForm.value[
          GrindingFormConstants.NON_FERRUS_METAL_SEPERATOR
        ],
      annotations: this.grindingForm.value.annotations,
      is_annotation: this.grindingForm.value.is_annotation,
    };
    const formValuesToPersist = {
      grinding_and_polishing: grindingFromAttributeList,
    };
    this.request = {
      criteria_id: BuildingConstants.GRINDING_AND_POLISHING,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.grindingForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
