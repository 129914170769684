<form>
    <mat-dialog-content>
        <div style="width: 100%;">
            <mat-icon (click)="onNoClick()" style="float: right;">
                <img alt="" style="vertical-align: sub;cursor: pointer;" src="../../../../../assets/images/Group 3298.svg">
            </mat-icon>
        </div>
        <div style="text-align: center;margin-bottom: 10px;"> <img alt="" height="60px"
                src="../../../../../assets/images/Path 91988.svg"></div>
        <h1 style="font-size: 20px;font-weight: 600;text-align: center;">{{ 'zurs-geo.Payment_heading' | translate }}
        </h1>
        <h3 style="font-size: 16px;font-weight: 600;text-align: center;">{{ 'zurs-geo.Payment_subheading' | translate }}
        </h3>
        <table style="width:100%">
            <tr style="background-color: #f1f1f1;border: 1px solid #e3e3e3;">
                <th style="width: 28%;padding: 3px 10px;">{{ 'zurs-geo.service_lable' | translate }}</th>
                <th style="width: 15%;">{{ 'zurs-geo.Details_lable' | translate }}</th>
                <th style="padding: 3px 4px 3px 0px;width: 13%;text-align: right;padding-right: 14px;">{{
                    'zurs-geo.changes_permonth_lable' | translate }}</th>
            </tr>
            <tr style="border-bottom: 1px solid #e3e3e3;">
                <td style="padding:7px 12px 0px 12px;">
                    <mat-checkbox (change)="floodCheckPayment($event)" [checked]="data.flood" id="floodcheck"
                        name="floodPayment" class="example-margin">{{'zurs-geo.Flood_analysis' | translate }}
                    </mat-checkbox>
                </td>
                <td>PDF {{'zurs-geo.Document_lable' | translate}}</td>
                <td style="text-align: right;padding-right: 16px;">{{flood_price | currencyCustom}} <span
                        *ngIf="flood_price !== null && flood_price !== undefined">EUR</span></td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e3e3;">
                <td style="padding:7px 12px 0px 12px;">
                    <mat-checkbox (change)="earthCheckPayment($event)" [checked]="data.earth" id="earthcheck"
                        name="earthPayment" class="example-margin">{{'zurs-geo.Earth_analysis' | translate }}
                    </mat-checkbox>
                </td>
                <td>PDF {{'zurs-geo.Document_lable' | translate}}</td>
                <td style="text-align: right;padding-right: 16px;">{{earthquack_price | currencyCustom}} <span
                        *ngIf="earthquack_price != null && earthquack_price != undefined">EUR</span></td>
            </tr>
            <tr style="border-bottom: 1px solid #e3e3e3;">
                <td style="padding:7px 12px 0px 12px;">
                    <mat-checkbox (change)="snowCheckPayment($event)" [checked]="data.snow" id="snowcheck"
                        name="snowPayment" class="example-margin">{{'zurs-geo.Snow_analysis' | translate }}
                    </mat-checkbox>
                </td>
                <td>PDF {{'zurs-geo.Document_lable' | translate}}</td>
                <td style="text-align: right;padding-right: 16px;">{{snow_price | currencyCustom}} <span *ngIf="snow_price !== null && snow_price !== undefined">EUR</span>
                </td>
            </tr>
        </table>
        <div style="width: 100%;border-bottom: 1.5px solid #b9b9b9;">
            <p style="width: 100%;font-size: 10px;margin-top: 6px;font-weight: 600;margin-bottom: 7px;margin-left:14px">
                <img alt="" style="height: 13px;margin-right: 4px;" src="../../../../assets/images/info-sign.svg"> {{
                'zurs-geo.Taxes_label' | translate }}
            </p>
        </div>
        <table *ngIf="selectLang$ | async as selectLang" style="width:100%">
            <tr style="background-color: #e3e3e3;border-bottom: 1.5px solid #fff;color: #9f9f9f;">
                <td [ngClass]="[selectLang === 'English(EN)' ? 'td--english' : 'td--german']" style="width: 28%;font-weight: 400;"></td>
                <td style="width: 15%;font-weight: 400;">{{ 'zurs-geo.Net_payable_label' | translate }}</td>
                <td style="padding: 3px 4px 3px 0px;width: 13%;text-align: right;padding-right: 14px;font-weight: 400;">
                    {{this.netPrice | currencyCustom}} EUR</td>
            </tr>
            <tr style="background-color: #e3e3e3;color: #9f9f9f;">
                <td [ngClass]="[selectLang === 'English(EN)' ? 'td--english' : 'td--german']" style="width: 28%;font-weight: 400;"></td>
                <td style="width: 15%;font-weight: 400;">19% {{ 'zurs-geo.VAT_text' | translate }}</td>
                <td style="padding: 3px 4px 3px 0px;width: 13%;text-align: right;padding-right: 14px;font-weight: 400;">
                    {{percentage | currencyCustom}} EUR</td>
            </tr>
            <tr style="background-color: #333333;color: #d3cfcf;">
                <td [ngClass]="[selectLang === 'English(EN)' ? 'td--english' : 'td--german']" style="width: 28%;font-weight: 400;"></td>
                <td style="width: 15%;font-weight: 400;">{{ 'zurs-geo.gross_payable_label' | translate }}</td>
                <td style="padding: 3px 4px 3px 0px;width: 13%;text-align: right;padding-right: 14px;font-weight: 400;">
                    {{grossPrice | currencyCustom}} EUR</td>
            </tr>
        </table>
    </mat-dialog-content>

    <mat-dialog-actions align="center" style="margin-top:19px;border-top:1px solid #000;">

        <button type="button" style="font-size: 15px;
    color: #272727;
    border: solid 1px #272727;
    padding: 5px 22px 5px 22px;
    border-radius: 2px;
    font-weight: 600;
    background: #fff;
    margin-right: 7px;margin-bottom: 13px;
    margin-top: 13px;" mat-button color="primary" (click)="onNoClick()">{{'zurs-geo.cancel_label' |
            translate}}</button>
        <button type="submit" style="color: #272727;background: #ff9000;padding: 5px 22px 5px 22px;
    border-radius: 2px;font-size: 15px;font-weight:600;margin-left: 7px;border: 1px solid #ff9000;margin-bottom: 13px;
    margin-top: 13px;" (click)="save();" mat-button color="warn">{{'zurs-geo.continue_label' |
            translate}}</button>

    </mat-dialog-actions>
</form>
