<div class="modal" id="valueCalculatorModel" [style.display]="showFormulaPopup ? 'table' : 'none'">
  <div class="modal-dialog" style="max-width: 900px;">
    <div class="modal-content" style="border: none;">
      <div class="modal-content" style="height: 450px;padding: 1rem;">
          <div class="row" style="margin: 0px;">
            <div class="col-md-11 header-text">
              <span style="font-weight: 600;">{{'product-management.mask_generator.targetVariableAttribute' | translate}}<span style="color: red;">*</span></span>
              <app-custom-autocomplete class="autocomplete" [validate]="validateAttribute" [selected]="selectedAttribute" [placeholder]="'objectModule.select_lable' | translate" [required]="false"
                 [lookup]="targetVariableAttribute" [control]="control" [via]="maskGenerator" [is_disabled]="is_disabled" (newValueSelected)="targetValue($event)"></app-custom-autocomplete>
              <input type="text" id="ownerBuilding" [ngStyle]="{'border' : validateForm === true ? '1px solid red' : '1px solid rgb(0 0 0 / 42%)'}" [(ngModel)]="formulaName" class="input pad-right" maxlength="100" autocomplete="off"
              placeholder="{{'product-management.mask_generator.headingDiscription' | translate}}" (blur)="onTypeName()" matInput
              [ngClass]="is_disabled ? 'disabled-input' : ''" title="{{formulaName}}" [disabled]="is_disabled">
              <div class="col-md-2 col-sm-2 col-2 calculation-checkbox">
                <mat-checkbox  class="checkbox" (change)="handleCheckboxChange($event)" [(ngModel)]="skip_calculation" [disabled]="is_disabled">
                  {{'product-management.mask_generator.skip_calculation' | translate}} </mat-checkbox>
              </div>
            </div>
            <div class="col-md-1" style="padding: 0px;">
              <button type="button" class="close" data-dismiss="modal" (click)="hidePopup();">
                <img alt="" src="../../../../../assets/images/Group 3114.svg" >
              </button>
            </div>
          </div>
          <div class="modal-body modal-body-border" id="modalBody">
              <div *ngIf="!is_disabled" style="font-weight: 600;margin: 10px 0px;">{{targetVariableParentName}}.<span *ngIf="selectedAttribute">{{selectedAttribute}} = </span></div>
              <div *ngIf="is_disabled" style="font-weight: 600;margin: 10px 0px;">{{targetVariableParentName}} = </div>
              <ul class="list-elements" id="formulae_section">
                <li *ngFor="let row of formula_object; let i = index">
                  <div>
                    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{index: i}" title="{{'value-calculator.Select-Pattern' | translate}}" *ngIf="row.row_type_id == null && row.is_plus_button">
                      <img alt="" src="../../../../assets/images/new-icons/Pattern_Icon_MG.svg" >
                    </span>
                  </div>
                  <div class="formula-row" *ngIf="row.row_type_id == ROWTYPES.LHS_RHS && !row.is_plus_button && !ifElseRow">
                    <div>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}"
                          *ngIf="row.left_data.label && !row.left_data.is_number && !row.left_data.is_text">
                          {{'objectModule.select_lable' | translate}}
                      </span>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.left_data.is_data_source" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}" (click)="getSourceData(row.left_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.left_data.data_source}}</span>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.left_data.is_data_source_data" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}" (click)="getSourceData(row.left_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.left_data.value}}</span>
                      <span *ngIf="row.left_data.is_number">
                        <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}" (change)="inputChanged()" class="underlined-input" inputmode="numeric" [(ngModel)]="row.left_data.number" [disabled]="is_disabled">
                      </span>
                      <span *ngIf="row.left_data.is_text">
                        <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged()" class="underlined-input" [(ngModel)]="row.left_data.text" title="{{row.left_data.text}}" [disabled]="is_disabled">
                      </span>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'"  [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}">
                        <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg"  style="margin: 0px 15px;">
                      </span>

                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="operatorSelect" [matMenuTriggerData]="{index: i}" style="margin: 0px 20px; font-size: 17px;" *ngIf="row.operator">
                        {{row.operator.operator}}
                      </span>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.operator == null" [matMenuTriggerData]="{index: i}" [matMenuTriggerFor]="operatorSelect" style="margin: 0px 20px;">
                        <img alt="" src="../../../../assets/images/new-icons/Maths_Operators_Icon.svg" >
                      </span>

                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}"
                        *ngIf="row.right_data.label && !row.right_data.is_number && !row.right_data.is_text">
                        {{'objectModule.select_lable' | translate}}
                      </span>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.right_data.is_data_source" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}" (click)="getSourceData(row.right_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.right_data.data_source}}</span>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.right_data.is_data_source_data" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}" (click)="getSourceData(row.right_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.right_data.value}}</span>
                      <span *ngIf="row.right_data.is_number">
                        <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}" (change)="inputChanged()" class="underlined-input" inputmode="numeric" [(ngModel)]="row.right_data.number" [disabled]="is_disabled">
                      </span>
                      <span *ngIf="row.right_data.is_text">
                        <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged()" class="underlined-input" [(ngModel)]="row.right_data.text" title="{{row.right_data.text}}" [disabled]="is_disabled">
                      </span>
                      <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}">
                        <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg"  style="margin: 0px 15px;">
                      </span>
                    </div>
                    <div style="width: 85px;">
                      <span class="right-logo-section">
                        <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'"  (click)="deleteRow(i)">
                          <img alt="" src="../../../../assets/images/new-icons/Delete icon.svg"  style="height: 14px;">
                          <span class="tooltiptextDeleteFilter">{{'value-calculator.delete-row' | translate}}</span>
                        </span>
                        <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'"  (click)="resetRow(i)">
                          <span class="tooltiptextResetFilter">{{'value-calculator.reset-row' | translate}}</span>
                          <img alt="" src="../../../../assets/images/new-icons/Delete_icon_for_formula.svg"  style="height: 15px;">
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="formula-row" *ngIf="row.row_type_id == ROWTYPES.NUMBER && !row.is_plus_button && !ifElseRow">
                    <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}" (change)="inputChanged()" class="underlined-input"
                      inputmode="numeric" [(ngModel)]="row.number_value" [disabled]="is_disabled">
                    <span class="right-logo-section">
                      <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" (click)="deleteRow(i)">
                        <img alt="" src="../../../../assets/images/new-icons/Delete icon.svg"  style="height: 14px;">
                        <span class="tooltiptextDeleteFilter">{{'value-calculator.delete-row' | translate}}</span>
                      </span>
                      <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" (click)="resetRow(i)">
                        <span class="tooltiptextResetFilter">{{'value-calculator.reset-row' | translate}}</span>
                        <img alt="" src="../../../../assets/images/new-icons/Delete_icon_for_formula.svg"  style="height: 15px;">
                      </span>
                    </span>
                  </div>
                  <div class="formula-row" *ngIf="row.row_type_id == ROWTYPES.TEXT && !row.is_plus_button && !ifElseRow">
                    <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged()" class="underlined-input"
                      [(ngModel)]="row.text_value"  title="{{row.text_value}}"  [disabled]="is_disabled">
                    <span class="right-logo-section">
                      <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" (click)="deleteRow(i)">
                        <img alt="" src="../../../../assets/images/new-icons/Delete icon.svg"  style="height: 14px;">
                        <span class="tooltiptextDeleteFilter">{{'value-calculator.delete-row' | translate}}</span>
                      </span>
                      <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" (click)="resetRow(i)">
                        <span class="tooltiptextResetFilter">{{'value-calculator.reset-row' | translate}}</span>
                        <img alt="" src="../../../../assets/images/new-icons/Delete_icon_for_formula.svg"  style="height: 15px;">
                      </span>
                    </span>
                  </div>
                  <div class="formula-row" *ngIf="(row.row_type_id == ROWTYPES.If_Else && !row.is_plus_button)">
                    <nested-if-test-maker [is_calculation]="is_calculation" [InputValues]="inputDataValues" (changeMenuItemData)="changeMenuItemData($event)"
                     (data_sourceData)="data_sourceData($event)"
                     [stepper_id]="data?.stepper_form_detail_id"
                     [formula_id]="data?.id"
                      (changeOperatorData)="changeOperatorData($event)" (inputChanged)="inputChanged()"
                      (getSourceData)="getSourceData1($event)" (changeMenuItemIFElseCondition)="changeMenuItemIFElseCondition1($event)"
                      (changeOperatorIFElseCondition)="changeOperatorIFElseCondition1($event)"
                      (changeMenu)="changeMenu1($event)"></nested-if-test-maker>
                    <div style="width: 85px;">
                      <span class="right-logo-section">
                        <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'"  (click)="deleteRow(i)">
                          <img alt="" src="../../../../assets/images/new-icons/Delete icon.svg"  style="height: 14px;">
                          <span class="tooltiptextDeleteFilter">{{'value-calculator.delete-row' | translate}}</span>
                        </span>
                        <span class="right-icon tooltipResetFilter" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'"  (click)="resetRow(i)">
                          <span class="tooltiptextResetFilter">{{'value-calculator.reset-row' | translate}}</span>
                          <img alt="" src="../../../../assets/images/new-icons/Delete_icon_for_formula.svg"  style="height: 15px;">
                        </span>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="row.is_operator_row">
                    <span [matMenuTriggerFor]="operatorSelect" [matMenuTriggerData]="{index: i}" *ngIf="row.operator_id_row != null" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" style="font-size:17px">
                      {{row.operator_value}}
                    </span>
                    <span *ngIf="row.operator_id_row == null " [matMenuTriggerFor]="operatorSelect" [matMenuTriggerData]="{index: i}" [ngClass]="is_disabled ? 'no-pointer' : 'pointer'">
                      <img alt="" src="../../../../assets/images/new-icons/Maths_Operators_Icon.svg" >
                    </span>
                  </div>
                  <div (click)="addNewRow()" [ngClass]="is_disabled ? ('no-pointer' + ' ' +'add-new-row-btn' ) : ( 'add-new-row-btn' + ' ' + 'pointer')" *ngIf="row.is_add_row_button">
                    + {{'value-calculator.add-new-row' | translate}}
                  </div>
                </li>
              </ul>
              <div *ngIf="is_disabled" class="container-divider"></div>
              <div *ngIf="is_disabled">
                <div class="row" style="margin: 0px;">
                  <div class="col-md-6 header-text">
                    <span class="values-heading">{{ 'value-calculator.key_values' | translate}}</span>
                  </div>
                  <div class="col-md-4  header-text">
                    <span class="valueAttribute" matTooltip='{{customEvaluatedValue}}'>{{ customEvaluatedValue }}</span>
                  </div>
                  <div class="col-md-2" style="padding: 0px;">
                    <button *ngIf="objectList.size > 0" class="ml-2 calculate-button" type="button" [disabled]="!shouldEnableCalculateCustomButton()" (click)="calculateCustomFormula()" mat-button>
                      {{'value-calculator.show_sum' | translate}}</button>
                  </div>
                </div>
                <table *ngIf="objectList.size > 0" class="result-table">
                  <tr>
                    <td class="result-table-td">
                      <div class="key-pair-container" *ngFor="let item of objectList | keyvalue; let i=index">
                        <div class="values">
                          {{item.key}} &nbsp;:&nbsp;
                        </div>
                        <mat-select class="kind_insurance" placeholder="{{'new-contract.product_mapping.please_select' | translate}}"
                          [(ngModel)]="selectedLable[i]"
                          (selectionChange)="onSelection(i, $event)">
                          <mat-option style="opacity:0.5" [value]="'select_lable'">
                            {{'objectModule.select_lable' | translate}}</mat-option>
                          <mat-option *ngFor="let value1 of item.value; let subIndex=index"
                            [value]="value1.split(':')[1]">{{value1.split(':')[0]}}</mat-option>
                        </mat-select>
                      </div>
                    </td>
                    <td class="result-table-td">
                      <div *ngFor="let row of key_value_pairs">
                        <div class="key-pair-container" *ngIf="row.multipleObjectsExists!=true">
                          <span class="values">
                            {{row.key}} &nbsp; =
                          </span>
                          <span class="values"> &nbsp; {{row.value}}</span>
                        </div>
                      </div>
                      <div *ngFor="let item of selectedResults | keyvalue; let i=index">
                        <div *ngIf="selectedIndexes.has(i)">
                          <div class="key-pair-container" *ngFor="let itemValue of item.value">
                            <span class="values">
                              {{itemValue}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <div *ngIf="objectList.size == 0">
                  <div *ngFor="let row of key_value_pairs">
                    <div class="key-pair-container" *ngIf="row.multipleObjectsExists!=true">
                      <span class="values">
                        {{row.key}} &nbsp; =
                      </span>
                      <span class="values"> &nbsp; {{row.value}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <mat-menu #menu="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" style="padding: 0px;">
                    <span mat-menu-item *ngFor="let item of row_types" [class]="item.is_heading ? 'dropdown-section-heading' : '' " value="item.label" (click)="changeMenu(item.id, index, item.is_heading, $event, '', inputDataValues)" title="{{item.label}}">{{item.label}}</span>
                </ng-template>
              </mat-menu>
              <mat-menu #ifmenu="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" style="padding: 0px;">
                    <span mat-menu-item *ngFor="let item of row_types" [class]="item.is_heading ? 'dropdown-section-heading' : '' " value="item.label" (click)="changeMenu(item.id, index, item.is_heading, $event, 'if', inputDataValues)" title="{{item.label}}">{{item.label}}</span>
                </ng-template>
              </mat-menu>
              <mat-menu #elsemenu="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" style="padding: 0px;">
                    <span mat-menu-item *ngFor="let item of row_types" [class]="item.is_heading ? 'dropdown-section-heading' : '' " value="item.label" (click)="changeMenu(item.id, index, item.is_heading, $event, 'else', inputDataValues)" title="{{item.label}}">{{item.label}}</span>
                </ng-template>
              </mat-menu>
              <mat-menu #leftMenu="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" let-from="from">
                  <span mat-menu-item *ngFor="let item of data_source_types" [class]="item.is_heading ? 'dropdown-section-heading' : '' " (click)="changeMenuItem(item.id,from, index, item.is_heading, $event, false, inputDataValues)" title="{{item.label}}">{{item.label}} </span>
                </ng-template>
              </mat-menu>
              <mat-menu #operatorSelect="matMenu" class='custom-mat-menu' >
                <ng-template matMenuContent let-index="index">
                  <span mat-menu-item  *ngFor="let item of operators" [class]="item.is_heading ? 'dropdown-section-heading' : '' " (click)="changeOperator(item.id, index, item.is_heading, $event)"> {{item.label}} </span>
                </ng-template>
              </mat-menu>
              <mat-menu #dataSourceData="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" let-from="from">
                  <input type="text" maxlength="50"  class="searchSelect" preventSpace style="height: 28px;font-size: 12px;"
                  placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search (click)="$event.stopPropagation()">
                  <span mat-menu-item  *ngFor="let item of validationService._filter(data_source_data, 'dropdown', search.value)" (click)="changeMenuItem(item.id, from, index, false, $event, true, inputDataValues)" title="{{item.dropdown}}"> {{item.dropdown}} </span>
                </ng-template>
              </mat-menu>
              <!--Mat menu for if else condition-->
              <mat-menu #leftMenuIf="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" let-from="from" let-name="name">
                  <span mat-menu-item *ngFor="let item of data_source_types" [class]="item.is_heading ? 'dropdown-section-heading' : '' " (click)="changeMenuItemIFElseCondition(item.id,from, index, item.is_heading, $event, false,name, inputDataValues)" title="{{item.label}}">{{item.label}} </span>
                </ng-template>
              </mat-menu>
              <mat-menu #operatorSelectIf="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" let-name="name">
                  <span mat-menu-item  *ngFor="let item of operators" [class]="item.is_heading ? 'dropdown-section-heading' : '' " (click)="changeOperatorIFElseCondition(item.id, index, item.is_heading, $event,name, inputDataValues)"> {{item.label}} </span>
                </ng-template>
              </mat-menu>
              <mat-menu #dataSourceDataIf="matMenu" class='custom-mat-menu'>
                <ng-template matMenuContent let-index="index" let-from="from" let-name="name">
                  <input type="text" maxlength="50" preventSpace class="searchSelect" style="height: 28px;font-size: 12px;"
                  placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search (click)="$event.stopPropagation()">
                  <span mat-menu-item  *ngFor="let item of validationService._filter(data_source_data, 'dropdown', search.value)" (click)="changeMenuItemIFElseCondition(item.id, from, index, false, $event, true,name, inputDataValues)" title="{{item.dropdown}}"> {{item.dropdown}} </span>
                </ng-template>
              </mat-menu>
          </div>
          <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent; padding: 0px;">
            <button  class="ml-2" type="button" (click)="addUpdate()" style="font-size: 15px;color: #272727;background: #ff9000;;font-weight: 600;padding: 0px 22px 0px 22px;border: solid 1px #ff9000;" mat-button [disabled]="(is_disabled || !isformUpdated) && !customEvaluatedValue">{{'value-calculator.Save' | translate}}</button>
          </div>

        </div>
      </div>
    </div>
</div>
<product-close-alert-dialog *ngIf="showWarningPopUp" [productwarnmsg]="productWarningMsg" (savedata)="addUpdateFormula()" (handlePopup)="handleWarningPopup($event)"></product-close-alert-dialog>
