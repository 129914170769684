import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ProductBaseStepper } from '../../modules/rcs-setup/product-management/product-base-stepper/product-base-stepper.component';
import { SaveProfileChangesDialogComponent } from '../../components/save-profile-changes-dialog/save-profile-changes-dialog.component';
import { ShareDataService } from '../services/share-data.service';

@Injectable({
  providedIn: 'root',
})
export class LeaveProductManagementGuard
  implements CanActivate, CanDeactivate<ProductBaseStepper>
{
  discardObject: Subscription;
  isUserWantToNevigate = false;
  constructor(
    private dialog: MatDialog,
    private _shareDataService: ShareDataService
  ) {
    this.discardObject = this._shareDataService
      .getIsObjectDiscarded()
      .subscribe(() => {
        this._shareDataService.setIsDiscard();
        this.isUserWantToNevigate = true;
      });
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canDeactivate(
    component: ProductBaseStepper,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const data = {
      nextUrl: nextState.url,
      currentUrl: currentState.url,
      enableDiscard: false,
    };

    const changesDetected = this._shareDataService.productManagementTouched;

    if (this.isUserWantToNevigate) {
      this.isUserWantToNevigate = false;
      return true;
    }

    if (nextState.url === '/otp') {
      return false;
    }

    if (
      changesDetected &&
      (this.dialog.openDialogs.length === 0 ||
        (this.dialog.openDialogs.length !== 0 &&
          !(
            this.dialog.openDialogs[0].componentInstance instanceof
            SaveProfileChangesDialogComponent
          )))
    ) {
      data.enableDiscard = !this._shareDataService.isInitialProductSave;
      this.dialog.open(SaveProfileChangesDialogComponent, {
        id: 'exitProduct' + Date.now(),
        width: '600px',
        data,
      });
      return false;
    }
    return !changesDetected;
  }
}
