<div>
    <span class="criteria-heading">{{'objectModule.power_supply_facility' | translate}}</span>
</div>
<form
    *ngIf="selectLang$ | async as locale"
    class="row object-module-html form-align" 
    [formGroup]="powerSupplyFacilityForm" 
    id="powerSupplyFacilityForm">
    <div class="col-md-12 col-main">
        <div class="row">
            <div class="col-md-8 col-inner">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}"> {{'objectModule.power_supply_facility_object.electrical_switch' |
                            translate}}</label>
                        <mat-select multiple class="dropdownStyle"
                            placeholder="{{'objectModule.select_lable' | translate}}"
                            formControlName="electricalSwitch">
                            <mat-option *ngFor="let item of electricalSwitchList" title="item.desc" [value]="item.id">
                                <span>{{item.desc}}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label">
                            {{'objectModule.power_supply_facility_object.electrical_switch_upload' | translate}}
                        </label>
                        <div style="display: flex;">
                        <div class="documentUploadInput">
                            <input class="input" readonly title="{{powerSupplyFacilityForm.controls['AcceptanceProtocolES'].value}}" formControlName="AcceptanceProtocolES"
                                placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
                        </div>

                        <div class="documentUploadButton">
                            <button class="docButton" type="button" (click)="uploadESDocumentPopup()">
                                <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-main">
        <div class="row">
            <div class="col-md-8 col-inner">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}"> {{'objectModule.power_supply_facility_object.old_heating_system' |
                            translate}}</label>
                        <mat-select multiple class="dropdownStyle"
                            placeholder="{{'objectModule.select_lable' | translate}}"
                            formControlName="oldHeatingSystem">
                            <mat-option *ngFor="let item of electricalSwitchList" [value]="item.id">
                                <span>{{item.desc}}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label">
                            {{'objectModule.power_supply_facility_object.old_heating_system_upload' | translate}}
                        </label>
                        <div style="display: flex;">
                        <div class="documentUploadInput">
                            <input class="input" readonly title="{{powerSupplyFacilityForm.controls['AcceptanceProtocolOHS'].value}}" formControlName="AcceptanceProtocolOHS"
                            placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
                        </div>

                        <div class="documentUploadButton">
                            <button class="docButton" type="button" (click)="uploadOHSDocumentPopup()">
                                <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-main">
        <div class="row">
            <div class="col-md-8 col-inner">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}"> {{'objectModule.power_supply_facility_object.type_of_heating' |
                            translate}}</label>
                        <mat-select multiple class="dropdownStyle" (click)="scrollValue('searchSelect')" (keyup.enter)="scrollValue('searchSelect')"  (openedChange)="search.value = ''"
                            placeholder="{{'objectModule.select_lable' | translate}}" formControlName="typeOfHeating">
                            <input [hidden]="typeOfHeatingList.length <= 6" type="text" maxlength="20" id="searchSelect" class="searchSelect" preventSpace placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search>
                            <mat-option [hidden]="item.hide" *ngFor="let item of validationService._filterMultiSelect(typeOfHeatingList, 'desc', search.value)" [value]="item.id"
                            [ngClass]="{'active-option': item.id === powerSupplyFacilityForm.value.typeOfHeating && !powerSupplyFacilityForm.value.typeOfHeating}">
                                <span>{{item.desc}}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                            {{'objectModule.power_supply_facility_object.type_quality_fuel_supply' | translate}}
                        </label>
                        <input type="text" maxlength="100" class="input" title="{{powerSupplyFacilityForm.controls['typeQualityFuel'].value}}" formControlName="typeQualityFuel"
                            placeholder="{{'objectModule.power_supply_facility_object.type_quality_fuel_supply' | translate}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-main">
        <div class="row">
            <div class="col-md-8 col-inner">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}">
                            {{'objectModule.power_supply_facility_object.steam_generating_plants' |
                            translate}}</label>
                        <mat-select multiple class="dropdownStyle"
                            placeholder="{{'objectModule.select_lable' | translate}}"
                            formControlName="steamGeneratingPlants">
                            <mat-option *ngFor="let item of electricalSwitchList" [value]="item.id">
                                <span>{{item.desc}}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label">
                            {{'objectModule.power_supply_facility_object.steam_generating_plants_upload' | translate}}
                        </label>
                        <div style="display: flex;">
                        <div class="documentUploadInput">
                            <input class="input" readonly title="{{powerSupplyFacilityForm.controls['AcceptanceProtocolSGP'].value}}" formControlName="AcceptanceProtocolSGP"
                            placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
                        </div>

                        <div class="documentUploadButton">
                            <button class="docButton" type="button" (click)="uploadSGPDocumentPopup()">
                                <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-main">
        <div class="row">
            <div class="col-md-8 col-inner">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label" [ngStyle]="{'text-transform' : locale === 'English(EN)' ? 'capitalize' : 'inherit'}"> {{'objectModule.power_supply_facility_object.compressed_air_system'
                            |
                            translate}}</label>
                        <mat-select multiple class="dropdownStyle"
                            placeholder="{{'objectModule.select_lable' | translate}}"
                            formControlName="compressedAirSystem">
                            <mat-option *ngFor="let item of electricalSwitchList" [value]="item.id">
                                <span>{{item.desc}}</span>
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="col-md-6 col-sm-6 col-6">
                        <label for="" class="long-label">
                            {{'objectModule.power_supply_facility_object.compressed_air_system_upload' | translate}}
                        </label>
                        <div style="display: flex;">
                        <div class="documentUploadInput">
                            <input class="input" readonly title="{{powerSupplyFacilityForm.controls['AcceptanceProtocolCAS'].value}}" formControlName="AcceptanceProtocolCAS"
                            placeholder="{{'objectModule.power_supply_facility_object.upload' | translate}}">
                        </div>

                        <div class="documentUploadButton">
                            <button class="docButton" type="button" (click)="uploadCASDocumentPopup()">
                                <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray"
                        [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)"></app-upload-document>
