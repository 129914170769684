import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetDocumentsParams } from '@shared/components/documents-table/document-table.model';
import { DocumentSource } from '@shared/models/document-management.model';
import { CustomerManagementSharedService } from '../customer-management-shared-service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { BaseComponent } from '@shared/components/base.component';
import { take } from 'rxjs/operators';
import { filterNil } from '@shared/utils/filter-nil.pipe';

@Component({
  selector: 'app-customer-documents',
  templateUrl: './customer-documents.component.html',
  styleUrls: ['./customer-documents.component.scss'],
})
export class CustomerDocumentsComponent
  extends BaseComponent
  implements OnInit
{
  getDocumentsParams: GetDocumentsParams;
  isEdit$ = this._customerManagementSharedService.isEditCustomerOperation$;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _customerManagementSharedService: CustomerManagementSharedService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this._fillDocumentParams();
  }

  private _fillDocumentParams(): void {
    this._customerSharedService.selectedCustomerId$
      .pipe(filterNil(), take(1))
      .subscribe(customerId => {
        this.getDocumentsParams = {
          source: DocumentSource.CUSTOMER,
          VN_KDNR: atob(this._activatedRoute.snapshot.queryParams.liztu),
          VS_IDNR: null,
          SA_IDNR: null,
          customerId,
          policy: null,
          contractId: null,
          claimId: null,
          insurerId: null,
          intermediaryId: null,
        };
      });
  }
}
