import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  DocumentSource,
  DocumentTypeResponse,
  UploadOfficeDocument,
} from '../../models/document-management.model';
import { LOCALS } from '@shared/constants/local.constants';
import { filterNil } from '../../utils/filter-nil.pipe';
import { BaseComponent } from '../base.component';
import { UserService } from '@core/user.service';
import {
  DIRECTION_OPTIONS,
  TypeOption,
  UploadDocumentData,
} from '../upload-document-popup/upload-document-popup.model';
import {
  PopupType,
  WriteLetterEmailRequiredFields,
} from './write-letter-email-popup.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../utils/utils';
import { DocumentManagementService } from '@core/services/document-management.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'app-write-letter-email-popup',
  styleUrls: [
    'write-letter-email-popup.component.scss',
    '../upload-document-popup/upload-document-popup.component.scss',
  ],
  templateUrl: 'write-letter-email-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WriteLetterEmailComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  form: FormGroup;
  readonly sources = DocumentSource;
  readonly directionOptions = DIRECTION_OPTIONS;
  readonly POPUP_TYPES = PopupType;

  private readonly _title$: BehaviorSubject<string> = new BehaviorSubject('');
  readonly title$: Observable<string> = this._title$.asObservable();

  private readonly _legend$: BehaviorSubject<string> = new BehaviorSubject('');
  readonly legend$: Observable<string> = this._legend$.asObservable();

  private readonly _type$: BehaviorSubject<PopupType | null> =
    new BehaviorSubject(null);
  readonly type$: Observable<PopupType | null> = this._type$.asObservable();

  private readonly _source$: BehaviorSubject<DocumentSource | null> =
    new BehaviorSubject<DocumentSource | null>(null);
  readonly source$ = this._source$.asObservable();

  readonly documentTypes$: Observable<TypeOption[]> = this._source$.pipe(
    filterNil(),
    switchMap((source: DocumentSource) => {
      const locale = this._userService.getLocale();
      return this._documentManagementApiService.getDocumentTypes(source).pipe(
        map((types: DocumentTypeResponse[]) =>
          types
            .map(({ typeNameEn, typeNameGe }) => ({
              label: locale === LOCALS.GERMAN ? typeNameGe : typeNameEn,
              value: typeNameGe,
            }))
            .sort((a: TypeOption, b: TypeOption) =>
              a.label > b.label ? 1 : -1
            )
        )
      );
    })
  );

  constructor(
    private _dialogRef: MatDialogRef<WriteLetterEmailComponent>,
    @Inject(MAT_DIALOG_DATA)
    private _data: Pick<WriteLetterEmailRequiredFields, 'type'> &
      UploadDocumentData,
    private readonly _translateService: TranslateService,
    private readonly _fb: FormBuilder,
    private readonly _userService: UserService,
    private readonly _documentManagementApiService: DocumentManagementApiService,
    private readonly _documentManagementService: DocumentManagementService,
    private readonly _toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this._initData();
  }

  closeDialog(): void {
    this.form.reset();
    this.form.controls.type?.setValue('');
    this._dialogRef.close();
  }

  continue(): void {
    const {
      type,
      VN_KDNR,
      VS_IDNR,
      SA_IDNR,
      source,
      isVisibleForCustomer,
      ...document
    } = this._data;
    const { source: sourceDirection, target: targetDirection } =
      Utils.getSourceAndTargetByLabel(this.form.controls.direction.value);
    const documentToUpload: UploadOfficeDocument = {
      ...document,
      sourceUser: sourceDirection,
      targetUser: targetDirection,
      liztuSaIdnr: SA_IDNR,
      liztuVnKdnr: VN_KDNR,
      liztuVsIdnr: VS_IDNR,
      isVisibleForCustomer: this.form.controls.visibility.value,
      documentSource: source,
      description: this.form.controls.description.value,
      documentType: this.form.controls.type.value,
    };
    const request =
      type === this.POPUP_TYPES.LETTER
        ? this._documentManagementApiService.createWordDraft(documentToUpload)
        : this._documentManagementApiService.createOutlookDraft(
            documentToUpload
          );
    request.pipe(takeUntil(this._destroy$)).subscribe(
      data => {
        window.open(data, '_blank')?.focus();
        this._documentManagementService.refresh();
        this.closeDialog();
      },
      (error: HttpErrorResponse) => {
        const errorMessage = error.error;
        this._toastService.showToastMessage(
          window.document,
          JSON.parse(errorMessage).message
        );
      }
    );
  }

  private _initData(): void {
    this._createForm();
    const { source, type } = this._data;
    this._setTitle(type);
    this._setLegend(type);
    this._source$.next(source);
    this._type$.next(type);
  }

  private _setTitle(type: PopupType): void {
    this._title$.next(
      this._translateService.instant(
        `office.${type === PopupType.EMAIL ? 'email' : 'letter'}.header`
      )
    );
  }

  private _setLegend(type: PopupType): void {
    this._legend$.next(
      this._translateService.instant(
        `office.${type === PopupType.EMAIL ? 'email' : 'letter'}.footer`
      )
    );
  }

  private _createForm(): void {
    this.form = this._fb.group({
      type: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      visibility: new FormControl(false, Validators.required),
      direction: new FormControl('', Validators.required),
    });
  }
}
