import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '@shared/models/component-can-deactivate.model';
import { SaveProfileChangesDialogComponent } from '../../components/save-profile-changes-dialog/save-profile-changes-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DirtycheckGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private dialog: MatDialog) {}
  // METHOD TO ACTIVATE GUARD ON NAVIGATION WITHOUT SAVING CHANGES
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let isUserWantToNevigate = false;
    let nexturlprams;
    const data = { nextUrl: nextState.url, currentUrl: currentState.url };
    if (component.canDeactivate()) {
      return true;
    }
    if (nextState.url && nextState.url.includes(';')) {
      nexturlprams = nextState.url.split(';')[1].split('=');
      isUserWantToNevigate = nexturlprams[1];
    }

    if (isUserWantToNevigate) {
      return true;
    } else {
      this.dialog.open(SaveProfileChangesDialogComponent, {
        width: '800px',
        data,
      });
    }
    return component.canDeactivate();
  }
}
