import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { STATIC_TEXT } from '../../../constants/string.constants';
import { GF_FORM_FIELDS_CONST } from '../../../../components/intermediary-management/constants/intermediary-management-constants';
import { of, forkJoin } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { BAFFIN_CONST } from '../../../../components/insurer-management/models/insurer-management.constants';
import { IntermediaryApiCallsService } from '../../../../components/intermediary-management/intermediary-management/intermediary-management.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ShowErrorService } from '@core/services/show-error.service';
import { ToastService } from '@core/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { filterNil } from '@shared/utils/filter-nil.pipe';

@Component({
  selector: 'app-general-intermediary-details',
  templateUrl: './general-intermediary-details.component.html',
  styleUrls: ['./general-intermediary-details.component.css'],
})
export class GeneralIntermediaryDetailsComponent
  extends BaseComponent
  implements OnInit
{
  @Input() identifier: any;
  isSubmitted = false;
  generalIntermediaryForm: FormGroup;
  formFields = GF_FORM_FIELDS_CONST;
  generalDetailsData: any;
  is_IC_USER = false;
  user_role: USER_ROLES;
  intermediaryId: any;
  intermediaryid_lookup: any;
  isNewLabel = false;
  isOldLabel = true;
  intermedairyIdPresent = false;
  dateOfToError: boolean;
  dateOfFromError: boolean;
  today = new Date();
  initialFormState: any;
  isAbbreviationSame = true;
  isValueChangedInForm = false;
  intermediaryDataObj: any;
  userRoles = USER_ROLES;
  fromDateTrigger: boolean;

  private _userId: string;

  constructor(
    private _intermediaryApiCallsService: IntermediaryApiCallsService,
    public _validationService: ValidationService,
    public _showError: ShowErrorService,
    private toastService: ToastService,
    private _translateService: TranslateService,
    private readonly _userService: UserService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router
  ) {
    super();
    this.intermediaryId = this.identifier;
  }

  ngOnInit(): void {
    if (!this.intermediaryId) {
      this.intermediaryId = atob(
        this._route.snapshot.queryParams.intermediaryId || ''
      );
    }

    this._watchOnUserId();
    this.intermediaryDataObj = {
      intermediaryTypes: [],
      statusList: [],
      divisionList: [],
      mainContact_list: [],
      selectedDivisions: [],
      mainIntermendiaryList: [],
      mainContactList: [],
      tooltTips: {
        mainContact: '',
        mainParent: '',
        status: '',
        intermediaryType: '',
        division: '',
      },
    };
    this.user_role = this._userService.getUserRole();
    this.is_IC_USER = this.user_role === USER_ROLES.IC_USER;
    const requests = [];
    requests.push(
      this._intermediaryApiCallsService
        .getIntermediaryDivision()
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this._intermediaryApiCallsService
        .getIntermediaryStatus()
        .pipe(catchError(error => of(error)))
    );

    requests.push(
      this._intermediaryApiCallsService
        .getIntermediaryType()
        .pipe(catchError(error => of(error)))
    );

    if (this.intermediaryId) {
      requests.push(
        this._intermediaryApiCallsService
          .getIntermediaryById(this.intermediaryId)
          .pipe(catchError(error => of(error)))
      );
    }

    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (!data[0].hasErrors && data[0].data) {
          let divisionList = [];
          const divisionId = this._userService.getDivisionId();
          if (
            this.user_role == USER_ROLES.IC_BRANCH_ADMIN ||
            this.user_role == USER_ROLES.IC_USER
          ) {
            divisionList = data[0].data.filter(
              division => division.id == divisionId && division.id !== 12
            );
          } else {
            divisionList = data[0].data.filter(division => division.id !== 12);
          }
          this.setResponeData(divisionList, 'divisionList', 'desc');
        }
        if (!data[1].hasErrors && data[1].data) {
          this.setResponeData(data[1].data, 'statusList', 'desc');
        }
        if (!data[2].hasErrors && data[2].data) {
          this.setResponeData(data[2].data, 'intermediaryTypes', 'desc');
        }
        if (this.intermediaryId) {
          if (!data[3].hasErrors && data[3].data) {
            this.generalDetailsData = data[3].data;
            if (this.intermediaryId) {
              this.intermediaryDataObj.selectedDivisions =
                this.generalDetailsData.division_id;
              this.callMainIntermediary();
            }
          }
        }
        this.initializeForm();
      });
  }

  setResponeData(data, type, prop): void {
    this.intermediaryDataObj[type] = [];
    data.map(element => {
      const data: BAFFIN_CONST = {
        id: element.id,
        name: element[prop],
      };
      this.intermediaryDataObj[type].push(data);
    });
  }

  initializeForm(): void {
    const formData = this.generalDetailsData || null;
    this.generalIntermediaryForm = new FormGroup({
      [GF_FORM_FIELDS_CONST.STATUS]: new FormControl(
        formData?.status_id || null,
        Validators.required
      ),
      [GF_FORM_FIELDS_CONST.INTERMEDIARY_ID]: new FormControl(
        formData?.intermediary_id || null,
        Validators.required
      ),
      [GF_FORM_FIELDS_CONST.INTERMEDIARY_TYPE]: new FormControl(
        formData?.intermediary_type || null,
        Validators.required
      ),
      [GF_FORM_FIELDS_CONST.INTERMEDIARY_NAME]: new FormControl(
        formData?.intermediary_name || null,
        Validators.required
      ),
      [GF_FORM_FIELDS_CONST.ADDRESS]: new FormControl(
        formData?.address || null
      ),
      [GF_FORM_FIELDS_CONST.STREET]: new FormControl(formData?.street || null),
      [GF_FORM_FIELDS_CONST.HOUSE_NUMBER]: new FormControl(
        formData?.house_number || null
      ),
      [GF_FORM_FIELDS_CONST.POST_CODE]: new FormControl(
        formData?.zipcode || null
      ),
      [GF_FORM_FIELDS_CONST.CITY]: new FormControl(formData?.city || null),
      [GF_FORM_FIELDS_CONST.DIVISION_ID]: new FormControl(
        formData?.division_id || null,
        Validators.required
      ),
      [GF_FORM_FIELDS_CONST.DIVISION]: new FormControl(
        formData?.division_name || null
      ),
      [GF_FORM_FIELDS_CONST.MAIN_INTERMEDIARY]: new FormControl(
        formData?.main_parent_id || null
      ),
      [GF_FORM_FIELDS_CONST.REGISTRATION_ID]: new FormControl(
        formData?.registration_id || null
      ),
      [GF_FORM_FIELDS_CONST.VALID_FROM]: new FormControl(
        formData
          ? formData.valid_from
            ? moment(formData.valid_from).format(
                STATIC_TEXT.DATE_FORMAT_YY_MM_DD
              )
            : null
          : null,
        Validators.required
      ),
      [GF_FORM_FIELDS_CONST.VALID_TO]: new FormControl(null),
      [GF_FORM_FIELDS_CONST.IC_MAIN_CONTACT]: new FormControl(
        formData?.main_contact_id || null
      ),
      [GF_FORM_FIELDS_CONST.DESCRIPTION]: new FormControl(
        formData?.description || null
      ),
    });

    if (
      (this.user_role == USER_ROLES.IC_BRANCH_ADMIN ||
        this.user_role == USER_ROLES.IC_USER) &&
      !formData?.intermediary_id
    ) {
      this.generalIntermediaryForm.controls[
        GF_FORM_FIELDS_CONST.DIVISION_ID
      ].setValue([this.intermediaryDataObj.divisionList[0].id]);
      this.generalIntermediaryForm.controls[
        GF_FORM_FIELDS_CONST.DIVISION
      ].setValue([this.intermediaryDataObj.divisionList[0].name]);
      this.callMainIntermediary();
    } else {
      this.disableField('main_parent_id');
      this.disableField('main_contact_id');
    }
    this._watchOnValidTo();
    this._updateValidTo();
    if (this.is_IC_USER) {
      this.generalIntermediaryForm.disable();
    }

    this.initialFormState = this.generalIntermediaryForm.value;
    this.generalIntermediaryForm?.valueChanges
      ?.pipe(takeUntil(this._destroy$))
      .subscribe(changedValues => {
        this.isAbbreviationSame =
          changedValues.abbreviation === this.initialFormState.abbreviation;
        //  this.bafinIdSame = changedValues.bafin_number === this.initialFormState.bafin_number
        this.isValueChangedInForm = !(
          JSON.stringify(this.initialFormState) ==
          JSON.stringify(this.setControls())
        );
      });
    this.getTooltipValue(formData?.status_id, 'status', 'statusList');
    this.getTooltipValue(
      formData?.intermediary_type,
      'intermediaryType',
      'intermediaryTypes'
    );
    this.getTooltipValue(
      formData?.division_id,
      'division',
      'selectedDivisions'
    );

    if (formData?.intermediary_id) {
      this.generalIntermediaryForm.get('intermediary_id').disable();
    }
  }
  disableField(fieldName) {
    if (
      this.user_role == USER_ROLES.IC_BRANCH_ADMIN ||
      this.user_role == USER_ROLES.IC_USER
    ) {
      if (
        this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.DIVISION_ID]
          .value.length === 1
      ) {
        this.generalIntermediaryForm.get('main_parent_id').enable();
        this.generalIntermediaryForm.get('main_contact_id').enable();
      } else {
        this.generalIntermediaryForm.get('main_parent_id').disable();
        this.generalIntermediaryForm.get('main_contact_id').disable();
      }
    } else {
      if (!this.intermediaryDataObj?.selectedDivisions?.length) {
        this.generalIntermediaryForm.get(fieldName).disable();
      } else {
        this.generalIntermediaryForm.get(fieldName).enable();
      }
      if (!this.intermediaryDataObj?.selectedDivisions?.length) {
        this.generalIntermediaryForm.get(fieldName).disable();
      } else {
        this.generalIntermediaryForm.get(fieldName).enable();
      }
    }
  }
  setControls() {
    const controlValues = {};
    Object.keys(this.generalIntermediaryForm.controls).forEach(
      control =>
        (controlValues[control] =
          this.generalIntermediaryForm.get(control).value)
    );
    return controlValues;
  }

  displayWith(value: any): string {
    return value ? value.toString() : STATIC_TEXT.EMPTY_STRING;
  }

  newValeSelected(): void {
    this.intermedairyIdPresent = false;
    let name;
    let id;
    this.intermediaryid_lookup.filter(val => {
      if (
        val.baffin_id ===
        this.generalIntermediaryForm.controls.intermediary_id.value
      ) {
        name = val.name;
        id = val.id;
      }
    });
    this.generalIntermediaryForm.controls[
      GF_FORM_FIELDS_CONST.INTERMEDIARY_ID
    ].setValue(id);
    this.generalIntermediaryForm.controls[
      GF_FORM_FIELDS_CONST.INTERMEDIARY_NAME
    ].setValue(name);
    this.generalIntermediaryForm.controls[
      GF_FORM_FIELDS_CONST.INTERMEDIARY_TYPE
    ].setValue(this.generalIntermediaryForm.controls.intermediary_id.value);
  }

  getAddress(event): void {
    this.generalIntermediaryForm.controls[
      GF_FORM_FIELDS_CONST.ADDRESS
    ].setValue(event ? event[0].freeformAddress : null);
    this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.STREET].setValue(
      event ? event[0]?.streetName : null
    );
    this.generalIntermediaryForm.controls[
      GF_FORM_FIELDS_CONST.POST_CODE
    ].setValue(event ? event[0]?.postalCode : null);
    this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.CITY].setValue(
      event
        ? event[0].localName
          ? event[0].localName
          : event[0].municipality
        : null
    );
    this.generalIntermediaryForm.controls[
      GF_FORM_FIELDS_CONST.HOUSE_NUMBER
    ].setValue(
      event ? (event[0].streetNumber ? event[0].streetNumber : null) : null
    );
  }

  dateClicked(control) {
    if (control == 'to') {
      this.dateOfToError = false;
      this.fromDateTrigger = false;
    } else {
      this.dateOfFromError = false;
      this.fromDateTrigger = true;
    }
  }

  dateChange(event, control): void {
    control === 'to'
      ? this.dateOfToError
      : (this.dateOfFromError = this._validationService?.dateValidation(event));
    this.setDateError(control);
  }

  setDateError(control): void {
    control === 'to'
      ? this.generalIntermediaryForm.controls.valid_to.setErrors({
          invalid: this.dateOfToError,
        })
      : this.generalIntermediaryForm.controls.valid_from.setErrors({
          invalid: this.dateOfFromError,
        });
  }

  checkValidDates(): boolean {
    if (
      this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.VALID_TO].value
    ) {
      return (
        new Date(
          this.generalIntermediaryForm.controls[
            GF_FORM_FIELDS_CONST.VALID_FROM
          ].value
        ) <
        new Date(
          this.generalIntermediaryForm.controls[
            GF_FORM_FIELDS_CONST.VALID_TO
          ].value
        )
      );
    } else {
      return true;
    }
  }
  checkValidForm() {
    return (
      this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.STATUS]
        .valid &&
      this.generalIntermediaryForm.controls[
        GF_FORM_FIELDS_CONST.INTERMEDIARY_ID
      ].value &&
      this.generalIntermediaryForm.controls[
        GF_FORM_FIELDS_CONST.INTERMEDIARY_TYPE
      ].valid &&
      this.generalIntermediaryForm.controls[
        GF_FORM_FIELDS_CONST.INTERMEDIARY_NAME
      ].valid &&
      this.intermediaryDataObj.selectedDivisions.length &&
      this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.DIVISION]
        .valid &&
      this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.VALID_FROM]
        .valid
    );
  }

  getdivisionNames(divisionIds) {
    const divisionNames = this.intermediaryDataObj.divisionList
      .filter(item => divisionIds.includes(item.id))
      .map(item => item.name);
    return divisionNames;
  }

  saveGeneralData(): void {
    this.isSubmitted = true;
    if (
      this.generalIntermediaryForm.controls.valid_to.value === 'Invalid date'
    ) {
      this.generalIntermediaryForm.controls.valid_to.setValue('');
    }
    const valid = this.checkValidForm();
    const validDates = this.checkValidDates();
    if (valid && validDates) {
      const request: any = {
        id: this.intermediaryId ? this.intermediaryId : null,
        intermediary_id: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.INTERMEDIARY_ID
        ].value
          ? parseInt(
              this.generalIntermediaryForm.controls[
                GF_FORM_FIELDS_CONST.INTERMEDIARY_ID
              ].value
            )
          : null,
        intermediary_type: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.INTERMEDIARY_TYPE
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.INTERMEDIARY_TYPE
            ].value
          : null,
        intermediary_name: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.INTERMEDIARY_NAME
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.INTERMEDIARY_NAME
            ].value
          : null,
        address: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.ADDRESS
        ].value
          ? this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.ADDRESS]
              .value
          : null,
        zipcode: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.POST_CODE
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.POST_CODE
            ].value
          : null,
        street: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.STREET
        ].value
          ? this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.STREET]
              .value
          : null,
        house_number: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.HOUSE_NUMBER
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.HOUSE_NUMBER
            ].value
          : null,
        city: this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.CITY]
          .value
          ? this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.CITY]
              .value
          : null,
        division_id: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.DIVISION_ID
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.DIVISION_ID
            ].value
          : null,
        division_name: this.getdivisionNames(
          this.intermediaryDataObj.selectedDivisions
        ),
        main_parent_id: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.MAIN_INTERMEDIARY
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.MAIN_INTERMEDIARY
            ].value
          : null,
        registration_id: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.REGISTRATION_ID
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.REGISTRATION_ID
            ].value
          : null,
        main_contact_id: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.IC_MAIN_CONTACT
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.IC_MAIN_CONTACT
            ].value
          : null,
        description: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.DESCRIPTION
        ].value
          ? this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.DESCRIPTION
            ].value
          : null,
        status_id: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.STATUS
        ].value
          ? this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.STATUS]
              .value
          : null,
        valid_from: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.VALID_FROM
        ].value
          ? moment(
              this.generalIntermediaryForm.controls[
                GF_FORM_FIELDS_CONST.VALID_FROM
              ].value
            ).format(STATIC_TEXT.DATE_FORMAT_YY_MM_DD)
          : null,
        valid_to: this.generalIntermediaryForm.controls[
          GF_FORM_FIELDS_CONST.VALID_TO
        ].value
          ? moment(
              this.generalIntermediaryForm.controls[
                GF_FORM_FIELDS_CONST.VALID_TO
              ].value
            ).format(STATIC_TEXT.DATE_FORMAT_YY_MM_DD)
          : null,
        created_by: this.intermediaryId ? null : this._userId,
        updated_by: this.intermediaryId ? this._userId : null,
      };
      if (
        this.user_role == USER_ROLES.IC_BRANCH_ADMIN ||
        this.user_role == USER_ROLES.IC_USER
      ) {
        request.division_name = this.generalDetailsData.division_name;
      }
      this._intermediaryApiCallsService
        .addUpdateIntermediary(request)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.hasErrors) {
            if (data.message == 'Please select correct intermediary') {
              this.generalIntermediaryForm.controls[
                GF_FORM_FIELDS_CONST.MAIN_INTERMEDIARY
              ].setErrors({ invalid: true });
            }
            this.isValueChangedInForm = false;
            const intermediaryId = data.record_id
              ? parseInt(data.record_id)
              : parseInt(request.id);
            this._router.navigate([], {
              queryParams: { intermediaryId: btoa(intermediaryId.toString()) },
            });
            this.intermediaryId = intermediaryId;
            this.toastService.showToastMessage(document, data.message);
            this._intermediaryApiCallsService
              .getIntermediaryById(intermediaryId)
              .pipe(takeUntil(this._destroy$))
              .subscribe(data => {
                this.generalDetailsData = data.data;
                if (this.generalDetailsData?.id) {
                  this.generalIntermediaryForm.get('intermediary_id').disable();
                }
              });
          }
        });
    } else {
      this.toastService.showToastMessage(
        document,
        this._translateService.instant(
          'insurer_management.broker_commision_tab.mandatory_text'
        )
      );
    }
  }

  selectDivision(event): void {
    this.intermediaryDataObj.selectedDivisions = event;
  }

  callMainIntermediary(): void {
    const requests = [];
    const main_intermediaryPayload = {
      division_id: this.intermediaryDataObj.selectedDivisions || [],
      id: this.intermediaryId,
    };
    const icMainContactPayload = {
      division_ids: this.intermediaryDataObj.selectedDivisions,
    };

    requests.push(
      this._intermediaryApiCallsService
        .getMainIntermediary(main_intermediaryPayload)
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this._intermediaryApiCallsService
        .getICMainContact(icMainContactPayload)
        .pipe(catchError(error => of(error)))
    );

    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (!data[0].hasErrors && data[0].data) {
          this.setResponeData(
            data[0].data,
            'mainIntermendiaryList',
            'intermediary_name'
          );
        }

        if (!data[1].hasErrors && data[1].agents) {
          this.setResponeData(data[1].agents, 'mainContactList', 'user_name');
        }
        if (this.intermediaryDataObj.selectedDivisions.length) {
          this.getTooltipValue(
            this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.MAIN_INTERMEDIARY
            ].value,
            'mainParent',
            'mainIntermendiaryList'
          );
          this.getTooltipValue(
            this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.IC_MAIN_CONTACT
            ].value,
            'mainContact',
            'mainContactList'
          );
        }
        this.disableField('main_parent_id');
        this.disableField('main_contact_id');
        if (this.is_IC_USER) {
          this.generalIntermediaryForm.disable();
          this.isValueChangedInForm = false;
        }
      });
  }

  getTooltipValue(event, prop, prop2): void {
    if (prop === 'division') {
      this.intermediaryDataObj.tooltTips[prop] = event
        ? this.getdivisionNames(event).join(', ')
        : '';
    } else {
      if (this.intermediaryDataObj[prop2]?.length) {
        const filteredArray = this.intermediaryDataObj[prop2].filter(
          item => item.id == event
        );
        if (filteredArray.length) {
          this.intermediaryDataObj.tooltTips[prop] =
            filteredArray[0].name || '';
        } else {
          this.intermediaryDataObj.tooltTips[prop] = '';
          if (prop === 'mainContact') {
            this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.IC_MAIN_CONTACT
            ].setValue('');
          }
          if (prop === 'mainParent') {
            this.generalIntermediaryForm.controls[
              GF_FORM_FIELDS_CONST.MAIN_INTERMEDIARY
            ].setValue('');
          }
        }
      }
    }
  }

  private _watchOnValidTo(): void {
    this.generalIntermediaryForm.controls[
      GF_FORM_FIELDS_CONST.VALID_TO
    ].valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((date: Date | string | null) => {
        const momentDate = moment(date);
        this._updateStatusConsiderValidTo(
          momentDate.isValid()
            ? momentDate.format(STATIC_TEXT.DATE_FORMAT_YY_MM_DD)
            : null
        );
      });
  }

  private _updateValidTo(): void {
    const validTo = this.generalDetailsData?.valid_to;
    if (validTo) {
      this.generalIntermediaryForm.controls[
        GF_FORM_FIELDS_CONST.VALID_TO
      ].setValue(moment(validTo).format(STATIC_TEXT.DATE_FORMAT_YY_MM_DD));
    }
  }

  private _updateStatusConsiderValidTo(validToValue: string | null): void {
    if (!this.fromDateTrigger) {
      const statusForm =
        this.generalIntermediaryForm.controls[GF_FORM_FIELDS_CONST.STATUS];
      if (
        !validToValue ||
        moment(validToValue).isSameOrAfter(moment(this.today), 'd')
      ) {
        statusForm.setValue(null);
        return;
      }

      statusForm.setValue(this.intermediaryDataObj?.statusList[2].id);
    }
  }

  private _watchOnUserId(): void {
    this._userService.userId$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe((userId: string) => (this._userId = userId));
  }
}
