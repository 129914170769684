import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { BaseComponent } from '@shared/components/base.component';

@Component({
  selector: 'template-repository',
  templateUrl: './template-repository.component.html',
  styleUrls: ['./template-repository.component.css'],
})
export class TemplateRepositoryComponent
  extends BaseComponent
  implements OnInit
{
  defaultTemplateSelected;
  templates_source = [];
  availableValues = [];
  selectedValues = [];
  selectedValuesByUser = [];

  product_template_labels = ['Product', 'Produkt'];
  intialSelectedValues;
  defaultValue = '';

  constructor(
    private serviceCall: ApiCallsService,
    private translate: TranslateService,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTemplateTypes();
  }

  getTemplateTypes(): void {
    this.serviceCall
      .getProductLookups('template_repository')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.templates_source = data.data;
          this.defaultTemplateSelected = this.templates_source[0];
          this.defaultValue = this.templates_source[0].desc;
          this.getTemplatesByTemplateId(this.templates_source[0].id);
        }
      });
  }

  getTemplatesByTemplateId(templateId, isSync = false): void {
    this.serviceCall
      .getTemplatesByTemplateType(templateId, isSync)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          if (!data.HasErrors) {
            this.selectedValues = data.selected_templates;
            this.availableValues = data.available_templates;
            this.intialSelectedValues =
              (this.selectedValues &&
                this.selectedValues.map(user => user.id)) ||
              [];
            if (isSync) {
              this.showToastMessage(
                'template-management.templates_sync_success'
              );
            }
          } else {
            if (isSync) {
              this.showToastMessage('template-management.templates_sync_fail');
            }
          }
        },
        () => this.showToastMessage('template-management.templates_sync_fail')
      );
  }

  saveConfigurations(): void {
    const acutalSelected = [
      ...new Set(this.selectedValuesByUser.map(user => user.id)),
    ];
    const data = {
      template_ids: acutalSelected,
      template_type_id: this.defaultTemplateSelected.id,
      user_id: this._userService.getUserId(),
    };
    this.serviceCall
      .addTemplateConfig(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          this.getTemplatesByTemplateId(this.defaultTemplateSelected.id);
          this.showToastMessage(
            !data.HasErrors
              ? 'template-management.repository_updated_success'
              : 'template-management.repository_updated_failed'
          );
        },
        () =>
          this.showToastMessage('template-management.repository_updated_failed')
      );
  }

  handleDeleteTemplatePopup(templateUrlToDelete: string) {
    this.serviceCall
      .deleteTemplateConfig(templateUrlToDelete)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => {
          if (!data.HasErrors) {
            this.showToastMessage('template-management.delete_temp_success');
            this.getTemplatesByTemplateId(this.defaultTemplateSelected.id);
          } else {
            this.showToastMessage('template-management.delete_temp_failed');
          }
        },
        () => this.showToastMessage('template-management.delete_temp_failed')
      );
  }

  onTemplateSelect(source): void {
    this.selectedValues = [];
    this.availableValues = [];
    this.intialSelectedValues = [];

    this.defaultTemplateSelected = source;
    this.getTemplatesByTemplateId(source.id);
  }

  modifiedSelectedValues(selectedValues): void {
    this.selectedValuesByUser = selectedValues;
  }

  showToastMessage(message): void {
    const x = document.getElementById('successToast');
    if (x) {
      x.textContent = this.translate.instant(message);
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }
}
