<div class="ic-academy">
  <div
    class="ic-academy__video-list video-list"
    *ngIf="videos$ | async as videos">
    <div class="video-list__video-item video-item" *ngFor="let video of videos">
      <div class="video-item__wrapper-item wrapper-item">
        <div class="wrapper-item__image image">
          <img
            class="image__src"
            src="../../../../assets/images/ic-academy.png" />
          <span class="image__name">Academy</span>
        </div>
        <div class="wrapper-item__title">{{ video.name }}</div>
        <app-button
          [fontSize]="ButtonSize.MEDIUM"
          (click)="openVideo(video.fullName)"
          class="wrapper-item__watch-button"
          >{{ 'dashboard.watch' | translate }}</app-button
        >
      </div>
      <div class="video-item__hr"></div>
    </div>
  </div>
</div>
