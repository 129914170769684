import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  UploadTypeConstants,
} from '@shared/constants/building.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { BuildingObject, Criteria } from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
import { ValidationSharedService } from '@core/services/validation-shared.service';

@Component({
  selector: 'app-fire-protection',
  templateUrl: './fire-protection.component.html',
  styleUrls: [
    './fire-protection.component.css',
    '../../../object-module.component.scss',
  ],
})
export class FireProtectionComponent extends BaseComponent implements OnInit {
  FireProtectionForm: FormGroup;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  isFormValueUpdated: boolean;
  @Input('object_data') parentObjectData;
  @Input('objectType') objectType;
  @Input('isresetForm') isresetForm;
  birthdayError = false;
  today = new Date();
  uploadObjectDetails: UploadObjectDetails;

  errorPower = false;

  showFPDocumentPopup = false;
  showFLPDocumentPopup = false;
  showFCDocumentPopup = false;
  showDUPDocumentPopup = false;
  showLODDocumentPopup = false;
  showLAODDocumentPopup = false;
  showUDIDocumentPopup = false;
  showLO2DDocumentPopup = false;
  showUDI2DocumentPopup = false;

  selectedDocumentsArray = {};

  selectedFPDocumentArray = {};
  selectedFLPDocumentArray = {};
  selectedFCDocumentArray = {};
  selectedDUPDocumentArray = {};
  selectedLODDocumentArray = {};
  selectedLAODDocumentArray = {};
  selectedUDIDocumentArray = {};
  selectedLO2DDocumentArray = {};
  selectedUDI2DocumentArray = {};

  fpdDocsIds = [];
  flpdDocsIds = [];
  fcdDocsIds = [];
  dupdDocsIds = [];
  loddDocsIds = [];
  lo2dDocsIds = [];
  loadDocsIds = [];
  udidDocsIds = [];
  udid2DocsIds = [];
  browser: string;

  Typeoffireextinguishers: any = [];
  TypeofIntrusion: any = [];
  TypeOfFireExtingSystems: any = [];

  request: BuildingObject;

  constructor(
    private serviceCall: ApiCallsService,
    public validation: ValidationService,
    private readonly _validationSharedService: ValidationSharedService
  ) {
    super();
    this.browser = this.detectBrowser();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getDropDownData();
  }

  getDropDownData() {
    const requests = [];
    requests.push(
      this.serviceCall.getLookupData('35').pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall.getLookupData('41').pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall.getLookupData('34').pipe(catchError(error => of(error)))
    );

    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.Typeoffireextinguishers =
          !data[0].hasErrors && data[0].data ? data[0].data : [];
        this.TypeOfFireExtingSystems =
          !data[1].hasErrors && data[1].data ? data[1].data : [];
        this.TypeofIntrusion =
          !data[2].hasErrors && data[2].data ? data[2].data : [];
      });
  }

  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.STRUCTURAL_FIRE_PROTECTION_AND_SAFETY_DEVICES
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;

    // this change is to fix the issue IC-6170
    if (
      formData &&
      formData.fire_protection &&
      formData.fire_protection.extinguishing_system.es_date_of_audit ==
        'Invalid date'
    ) {
      formData.fire_protection.extinguishing_system.es_date_of_audit = null;
    }

    this.FireProtectionForm = new FormGroup({
      fire_protection_id: new FormControl(
        {
          value: formData ? formData.fire_protection.fire_protection_id : null,
          disabled: false,
        },
        []
      ),
      floor_plan_id: new FormControl(
        {
          value: formData ? formData.fire_protection.floor_plan_id : null,
          disabled: false,
        },
        []
      ),
      external_door_img_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.external_door_img_id
            : null,
          disabled: false,
        },
        []
      ),
      is_switched_off: new FormControl(
        {
          value: formData ? formData.fire_protection.is_switched_off : null,
          disabled: false,
        },
        []
      ),
      is_fire_compartment: new FormControl(
        {
          value: formData ? formData.fire_protection.is_fire_compartment : null,
          disabled: false,
        },
        []
      ),

      area_in_sqm: new FormControl(
        {
          value: formData
            ? this.validation.setSqmValue(
                formData.fire_protection.fire_compartment.area_in_sqm
              )
            : null,
          disabled: false,
        },
        []
      ),
      No_of_fire_compartments: new FormControl(
        {
          value: formData
            ? this.validation.setSqmValue(
                formData.fire_protection.fire_compartment
                  .No_of_fire_compartments
              )
            : null,
          disabled: false,
        },
        []
      ),
      largest_fire_compartment_in_sqm: new FormControl(
        {
          value: formData
            ? this.validation.setSqmValue(
                formData.fire_protection.fire_compartment
                  .largest_fire_compartment_in_sqm
              )
            : null,
          disabled: false,
        },
        []
      ),

      is_fire_extinguisher_available: new FormControl(
        {
          value: formData
            ? formData.fire_protection.is_fire_extinguisher_available
            : null,
          disabled: false,
        },
        []
      ),
      type_of_fire_extinguisher_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_extinguisher
                .type_of_fire_extinguisher_id
            : null,
          disabled: false,
        },
        []
      ),
      sfpsd_remark: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_extinguisher.sfpsd_remark
            : null,
          disabled: false,
        },
        []
      ),
      is_checked_every_two_years: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_extinguisher
                .is_checked_every_two_years
            : null,
          disabled: false,
        },
        []
      ),
      is_fire_exting_loc_marked: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_extinguisher
                .is_fire_exting_loc_marked
            : null,
          disabled: false,
        },
        []
      ),
      is_fire_exting_accessible: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_extinguisher
                .is_fire_exting_accessible
            : null,
          disabled: false,
        },
        []
      ),
      is_intruder_alarm: new FormControl(
        {
          value: formData
            ? formData.fire_protection.intruder_alarm.is_intruder_alarm
            : null,
          disabled: false,
        },
        []
      ),
      is_vds_approved: new FormControl(
        {
          value: formData
            ? formData.fire_protection.intruder_alarm.is_vds_approved
            : null,
          disabled: false,
        },
        []
      ),
      vds_doc_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.intruder_alarm.vds_doc_id
            : null,
          disabled: false,
        },
        []
      ),
      is_intrude_alarm_overridden: new FormControl(
        {
          value: formData
            ? formData.fire_protection.intruder_alarm
                .is_intrude_alarm_overridden
            : null,
          disabled: false,
        },
        []
      ),
      override_to_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.intruder_alarm.override_to_id
            : null,
          disabled: false,
        },
        []
      ),
      is_perimeter_surveillance: new FormControl(
        {
          value: formData
            ? formData.fire_protection.intruder_alarm.is_perimeter_surveillance
            : null,
          disabled: false,
        },
        []
      ),
      is_interior_surveillance: new FormControl(
        {
          value: formData
            ? formData.fire_protection.intruder_alarm.is_interior_surveillance
            : null,
          disabled: false,
        },
        []
      ),

      is_safety_locks: new FormControl(
        {
          value: formData ? formData.fire_protection.is_safety_locks : null,
          disabled: false,
        },
        []
      ),
      is_safety_locks_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.safety_locks.is_safety_locks_id
            : null,
          disabled: false,
        },
        []
      ),

      is_fire_alarm_system: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_alarm.is_fire_alarm_system
            : null,
          disabled: false,
        },
        []
      ),
      degree_of_protection: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_alarm.degree_of_protection
            : null,
          disabled: false,
        },
        [Validators.min(0), Validators.max(100)]
      ),
      fa_is_vds_approved: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_alarm.fa_is_vds_approved
            : null,
          disabled: false,
        },
        []
      ),
      fa_vds_doc_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_alarm.fa_vds_doc_id
            : null,
          disabled: false,
        },
        []
      ),
      is_intrusion1: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_alarm.is_intrusion
            : null,
          disabled: false,
        },
        []
      ),
      fa_override_to: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_alarm.fa_override_to
            : null,
          disabled: false,
        },
        []
      ),
      fa_remark: new FormControl(
        {
          value: formData
            ? formData.fire_protection.fire_alarm.fa_remark
            : null,
          disabled: false,
        },
        []
      ),

      is_extinguishing_system: new FormControl(
        {
          value: formData
            ? formData.fire_protection.extinguishing_system
                .is_extinguishing_system
            : null,
          disabled: false,
        },
        []
      ),
      type_of_exting_system_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.extinguishing_system
                .type_of_exting_system_id
            : null,
          disabled: false,
        },
        []
      ),
      es_degree_of_protection: new FormControl(
        {
          value: formData
            ? formData.fire_protection.extinguishing_system
                .es_degree_of_protection
            : null,
          disabled: false,
        },
        [Validators.min(0), Validators.max(100)]
      ),
      es_date_of_audit: new FormControl(
        {
          value: formData
            ? formData.fire_protection.extinguishing_system.es_date_of_audit
            : null,
          disabled: false,
        },
        []
      ),
      Upload_Audit_Document: new FormControl(
        {
          value: formData
            ? formData.fire_protection.extinguishing_system
                .Upload_Audit_Document
            : null,
          disabled: false,
        },
        []
      ),
      es_remark: new FormControl(
        {
          value: formData
            ? formData.fire_protection.extinguishing_system.es_remark
            : null,
          disabled: false,
        },
        []
      ),

      is_smoke_heat_extraction: new FormControl(
        {
          value: formData
            ? formData.fire_protection.smoke_heat_extraction
                .is_smoke_heat_extraction
            : null,
          disabled: false,
        },
        []
      ),
      shes_degree_of_protection: new FormControl(
        {
          value: formData
            ? formData.fire_protection.smoke_heat_extraction
                .shes_degree_of_protection
            : null,
          disabled: false,
        },
        [Validators.min(0), Validators.max(100)]
      ),
      upload_doc_inst: new FormControl(
        {
          value: formData ? formData.fire_protection.upload_doc_inst : null,
          disabled: false,
        },
        []
      ),

      is_explosion_protection_area: new FormControl(
        {
          value: formData
            ? formData.fire_protection.is_explosion_protection_area
            : null,
          disabled: false,
        },
        []
      ),
      exp_pro_doc_id: new FormControl(
        {
          value: formData
            ? formData.fire_protection.explosion_protection.exp_pro_doc_id
            : null,
          disabled: false,
        },
        []
      ),

      is_relevant_quantities: new FormControl(
        {
          value: formData
            ? formData.fire_protection.is_relevant_quantities
            : null,
          disabled: false,
        },
        []
      ),
      is_daily_disposal: new FormControl(
        {
          value: formData
            ? formData.fire_protection.combustible_waste.is_daily_disposal
            : null,
          disabled: false,
        },
        []
      ),

      is_oily_cleaning_rags_available: new FormControl(
        {
          value: formData
            ? formData.fire_protection.is_oily_cleaning_rags_available
            : null,
          disabled: false,
        },
        []
      ),
      'is_store_in_non-flammable_containers': new FormControl(
        {
          value: formData
            ? formData.fire_protection.oily_cleaning[
                'is_store_in_non-flammable_containers'
              ]
            : null,
          disabled: false,
        },
        []
      ),
      annotations: new FormControl(
        {
          value: formData ? formData.fire_protection.annotations : null,
          disabled: false,
        },
        []
      ),
      is_annotation: new FormControl(
        {
          value: formData ? formData.fire_protection.is_annotation : null,
          disabled: false,
        },
        []
      ),
    });

    this.isFormValueUpdated = false;

    this.FireProtectionForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isFormValueUpdated = true;
        // Added the code to disable submission when form us is InValid
        if (this.FireProtectionForm.valid) {
          this.buildFormObject();
          const pristine = this.FireProtectionForm.pristine;
          this.saveCriteriaRequest.emit({
            request: this.request,
            isSave: false,
            pristine,
          });
        }
        this._validationSharedService.saveFormError(
          '' + !this.FireProtectionForm.valid
        );
      });
    this.getDocumentUploadData(record_id);
  }

  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.FIRE_PROTECTION_ID
              ) {
                this.selectedFPDocumentArray[document.document_name] = document;
              }
              if (
                document.document_type === UploadTypeConstants.FLOOR_PLAN_ID
              ) {
                this.selectedFLPDocumentArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.EXTERNAL_DOOR_IMG_ID
              ) {
                this.selectedFCDocumentArray[document.document_name] = document;
              }
              if (document.document_type === UploadTypeConstants.VDS_DOC_ID) {
                this.selectedDUPDocumentArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.IS_SAFETY_LOCKS_ID
              ) {
                this.selectedLODDocumentArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.UPLOAD_AUDIT_DOCUMENT
              ) {
                this.selectedLAODDocumentArray[document.document_name] =
                  document;
              }
              if (
                document.document_type === UploadTypeConstants.UPLOAD_DOC_INST
              ) {
                this.selectedUDIDocumentArray[document.document_name] =
                  document;
              }
              if (
                document.document_type === UploadTypeConstants.FA_VDS_DOC_ID
              ) {
                this.selectedLO2DDocumentArray[document.document_name] =
                  document;
              }
              if (
                document.document_type === UploadTypeConstants.EXP_PRO_DOC_ID
              ) {
                this.selectedUDI2DocumentArray[document.document_name] =
                  document;
              }
            });
            if (data.documents) {
              this.FireProtectionForm.controls.fire_protection_id.setValue(
                Object.keys(this.selectedFPDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.floor_plan_id.setValue(
                Object.keys(this.selectedFLPDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.external_door_img_id.setValue(
                Object.keys(this.selectedFCDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.vds_doc_id.setValue(
                Object.keys(this.selectedDUPDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.is_safety_locks_id.setValue(
                Object.keys(this.selectedLODDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.Upload_Audit_Document.setValue(
                Object.keys(this.selectedLAODDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.upload_doc_inst.setValue(
                Object.keys(this.selectedUDIDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.fa_vds_doc_id.setValue(
                Object.keys(this.selectedLO2DDocumentArray).join(', ')
              );
              this.FireProtectionForm.controls.exp_pro_doc_id.setValue(
                Object.keys(this.selectedUDI2DocumentArray).join(', ')
              );
            }
          }
        });
    }
  }

  public hasFormError = (controlName: string, errorName: string): boolean => {
    return this.FireProtectionForm.controls[controlName].hasError(errorName);
  }

  resetValue(controlName) {
    // this.FireProtectionForm.controls[controlName].setValue("")
  }

  showDocumentPopup() {
    return (
      this.showFPDocumentPopup ||
      this.showFLPDocumentPopup ||
      this.showFCDocumentPopup ||
      this.showDUPDocumentPopup ||
      this.showLODDocumentPopup ||
      this.showLAODDocumentPopup ||
      this.showUDIDocumentPopup ||
      this.showLO2DDocumentPopup ||
      this.showUDI2DocumentPopup
    );
  }

  uploadFPDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedFPDocumentArray };
    this.showFPDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.FIRE_PROTECTION_ID,
    };
  }

  uploadFLPDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedFLPDocumentArray };
    this.showFLPDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.FLOOR_PLAN_ID,
    };
  }
  uploadFCDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedFCDocumentArray };
    this.showFCDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.EXTERNAL_DOOR_IMG_ID,
    };
  }
  uploadDUPDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedDUPDocumentArray };
    this.showDUPDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.VDS_DOC_ID,
    };
  }
  uploadLODDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedLODDocumentArray };
    this.showLODDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.IS_SAFETY_LOCKS_ID,
    };
  }
  uploadLAODDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedLAODDocumentArray };
    this.showLAODDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.UPLOAD_AUDIT_DOCUMENT,
    };
  }

  uploadUDIDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedUDIDocumentArray };
    this.showUDIDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.UPLOAD_DOC_INST,
    };
  }

  uploadLO2DDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedLO2DDocumentArray };
    this.showLO2DDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.FA_VDS_DOC_ID,
    };
  }

  uploadUDI2DocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedUDI2DocumentArray };
    this.showUDI2DocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.EXP_PRO_DOC_ID,
    };
  }

  hidePopup(selectedDocumentsArray) {
    if (this.showFPDocumentPopup) {
      this.fpdDocsIds = [];
      this.selectedFPDocumentArray = { ...selectedDocumentsArray };
      this.showFPDocumentPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.fpdDocsIds.push(selectedDocumentsArray[name].upload_document_id);
        }
      });
      this.FireProtectionForm.controls.fire_protection_id.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.fire_protection_id.markAsTouched();
    }

    if (this.showFLPDocumentPopup) {
      this.flpdDocsIds = [];
      this.selectedFLPDocumentArray = { ...selectedDocumentsArray };
      this.showFLPDocumentPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.flpdDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.FireProtectionForm.controls.floor_plan_id.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.floor_plan_id.markAsTouched();
    }

    if (this.showFCDocumentPopup) {
      this.fcdDocsIds = [];
      this.selectedFCDocumentArray = { ...selectedDocumentsArray };
      this.showFCDocumentPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.fcdDocsIds.push(selectedDocumentsArray[name].upload_document_id);
        }
      });
      this.FireProtectionForm.controls.external_door_img_id.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.external_door_img_id.markAsTouched();
    }

    if (this.showDUPDocumentPopup) {
      this.dupdDocsIds = [];
      this.selectedDUPDocumentArray = { ...selectedDocumentsArray };
      this.showDUPDocumentPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.dupdDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.FireProtectionForm.controls.vds_doc_id.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.vds_doc_id.markAsTouched();
    }

    if (this.showLODDocumentPopup) {
      this.loddDocsIds = [];
      this.selectedLODDocumentArray = { ...selectedDocumentsArray };
      this.showLODDocumentPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.loddDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.FireProtectionForm.controls.is_safety_locks_id.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.is_safety_locks_id.markAsTouched();
    }

    if (this.showLAODDocumentPopup) {
      this.loadDocsIds = [];
      this.selectedLAODDocumentArray = { ...selectedDocumentsArray };
      this.showLAODDocumentPopup = false;
      this.FireProtectionForm.controls.Upload_Audit_Document.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.Upload_Audit_Document.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.loadDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }

    if (this.showUDIDocumentPopup) {
      this.udidDocsIds = [];
      this.selectedUDIDocumentArray = { ...selectedDocumentsArray };
      this.showUDIDocumentPopup = false;
      this.FireProtectionForm.controls.upload_doc_inst.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.upload_doc_inst.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.udidDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }

    if (this.showLO2DDocumentPopup) {
      this.lo2dDocsIds = [];
      this.selectedLO2DDocumentArray = { ...selectedDocumentsArray };
      this.showLO2DDocumentPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.lo2dDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.FireProtectionForm.controls.fa_vds_doc_id.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.fa_vds_doc_id.markAsTouched();
    }

    if (this.showUDI2DocumentPopup) {
      this.udid2DocsIds = [];
      this.selectedUDI2DocumentArray = { ...selectedDocumentsArray };
      this.showUDI2DocumentPopup = false;
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.udid2DocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.FireProtectionForm.controls.exp_pro_doc_id.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.FireProtectionForm.controls.exp_pro_doc_id.markAsTouched();
    }

    this.buildFormObject();
    const pristine = !this.FireProtectionForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }
  dateChange(e) {
    this.birthdayError = this.validation?.dateValidation(e);
  }
  dateClicked() {
    this.birthdayError = false;
  }
  onDateBlur(e) {
    const today = moment(this.today, 'DD-MM-YYYY').format('MM/DD/YYYY');
    const enteredDate = moment(e.target.value, 'DD-MM-YYYY').format(
      'MM/DD/YYYY'
    );
    const difference = moment(enteredDate).diff(moment(today), 'days');
    if (difference > 0 || enteredDate === 'Invalid date') {
      e.target.value = '';
      this.birthdayError = true;
      this.FireProtectionForm.controls.es_date_of_audit.setValue(null);
      this.FireProtectionForm.controls.es_date_of_audit.setErrors(null);
    }
  }

  resetDependentValue(controlName) {
    this.FireProtectionForm.controls[controlName].setValue('');
  }

  typeNumber(event, key, length) {
    this.validation.SequenceNumberValidation(event, key, length);
  }

  typeNumberBrowserSpecific(event, key, length?) {
    this.validation.SequenceNumberValidationBrowserSpecific(event, key, length);
  }

  buildFormObject() {
    const formValues = this.FireProtectionForm.getRawValue();
    const newFormValues = {
      fire_protection_id: formValues.fire_protection_id
        ? formValues.fire_protection_id
        : null,
      floor_plan_id: formValues.floor_plan_id ? formValues.floor_plan_id : null,
      external_door_img_id: formValues.external_door_img_id
        ? formValues.external_door_img_id
        : null,
      is_switched_off: formValues.is_switched_off,
      is_fire_compartment: formValues.is_fire_compartment,
      fire_compartment: {
        area_in_sqm: formValues.area_in_sqm
          ? Number(this.validation.removeSymbols(formValues.area_in_sqm))
          : null,
        No_of_fire_compartments: formValues.No_of_fire_compartments
          ? Number(
              this.validation.removeSymbols(formValues.No_of_fire_compartments)
            )
          : null,
        largest_fire_compartment_in_sqm:
          formValues.largest_fire_compartment_in_sqm
            ? Number(
                this.validation.removeSymbols(
                  formValues.largest_fire_compartment_in_sqm
                )
              )
            : null,
      },
      is_fire_extinguisher_available: formValues.is_fire_extinguisher_available,
      fire_extinguisher: {
        type_of_fire_extinguisher_id: formValues.type_of_fire_extinguisher_id,
        sfpsd_remark: formValues.sfpsd_remark,
        is_checked_every_two_years: formValues.is_checked_every_two_years,
        is_fire_exting_loc_marked: formValues.is_fire_exting_loc_marked,
        is_fire_exting_accessible: formValues.is_fire_exting_accessible,
      },
      intruder_alarm: {
        is_intruder_alarm: formValues.is_intruder_alarm,
        is_vds_approved: formValues.is_vds_approved,
        vds_doc_id: formValues.vds_doc_id ? formValues.vds_doc_id : null,
        is_intrude_alarm_overridden: formValues.is_intrude_alarm_overridden,
        override_to_id: formValues.override_to_id
          ? formValues.override_to_id
          : null,
        is_perimeter_surveillance: formValues.is_perimeter_surveillance,
        is_interior_surveillance: formValues.is_interior_surveillance,
      },
      is_safety_locks: formValues.is_safety_locks,
      safety_locks: {
        is_safety_locks_id: formValues.is_safety_locks_id
          ? formValues.is_safety_locks_id
          : null,
      },
      fire_alarm: {
        is_fire_alarm_system: formValues.is_fire_alarm_system,
        degree_of_protection: formValues.degree_of_protection,
        fa_is_vds_approved: formValues.fa_is_vds_approved,
        fa_vds_doc_id: formValues.fa_vds_doc_id
          ? formValues.fa_vds_doc_id
          : null,
        is_intrusion: formValues.is_intrusion1,
        fa_override_to: formValues.fa_override_to
          ? formValues.fa_override_to
          : null,
        fa_remark: formValues.fa_remark,
      },
      extinguishing_system: {
        is_extinguishing_system: formValues.is_extinguishing_system,
        type_of_exting_system_id: formValues.type_of_exting_system_id,
        es_degree_of_protection: formValues.es_degree_of_protection,
        es_date_of_audit: formValues.es_date_of_audit
          ? moment(formValues.es_date_of_audit).format('YYYY-MM-DD')
          : null,
        es_remark: formValues.es_remark,
      },
      smoke_heat_extraction: {
        is_smoke_heat_extraction: formValues.is_smoke_heat_extraction,
        shes_degree_of_protection: formValues.shes_degree_of_protection,
      },
      is_explosion_protection_area: formValues.is_explosion_protection_area,
      explosion_protection: {
        exp_pro_doc_id: formValues.exp_pro_doc_id
          ? formValues.exp_pro_doc_id
          : null,
      },
      is_relevant_quantities: formValues.is_relevant_quantities,
      combustible_waste: {
        is_daily_disposal: formValues.is_daily_disposal,
      },
      is_oily_cleaning_rags_available:
        formValues.is_oily_cleaning_rags_available,
      oily_cleaning: {
        'is_store_in_non-flammable_containers':
          formValues['is_store_in_non-flammable_containers'],
      },
      annotations: formValues.annotations,
      is_annotation: formValues.is_annotation,
    };

    const formValuesToPersist = {
      fire_protection: newFormValues,
    };

    this.request = {
      document_ids: [
        ...new Set([
          ...this.fpdDocsIds,
          ...this.flpdDocsIds,
          ...this.fcdDocsIds,
          ...this.dupdDocsIds,
          ...this.loddDocsIds,
          ...this.lo2dDocsIds,
          ...this.loadDocsIds,
          ...this.udidDocsIds,
          ...this.udid2DocsIds,
        ]),
      ],
      criteria_id:
        BuildingConstants.STRUCTURAL_FIRE_PROTECTION_AND_SAFETY_DEVICES,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }

  ngOnDestroy() {
    if (this.isFormValueUpdated && this.FireProtectionForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
}
