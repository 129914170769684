import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'custom-warning-popup',
    templateUrl: './custom-warning-popup.component.html',
    styleUrls: ['./custom-warning-popup.component.css']
})
export class CustomWarningPopupComponent {
    @Input('cloudDocId') cloudDocId?: string;
    @Input('documentName') name?: string;
    @Input('optionalFields') optionalFields?: any;
    @Output('onHideModal') hideModalValue = new EventEmitter<boolean>();
    @Output('onDelete') deletDoc = new EventEmitter<any>();
    hideModal() {
        this.hideModalValue.emit(false);
    }
    deleteDocument() {
        this.deletDoc.emit({ cloudDocId: this.cloudDocId, name: this.name, optionalFields: this.optionalFields });
    }
}
