<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic claim-reporting">
    <div class="claim-reporting__content">
        <app-breadcrumbs [isCustomerUser]="isCustomerUser$ | async" [isCustomerSelected]="true"
            [pageBreadcrumbText]="pageBreadcrumbText" [divisionName]="divisionName" [customerName]="customerName$ | async">
        </app-breadcrumbs>

        <ng-container>
            <form
                name="search"
                [formGroup]="formData"
                (ngSubmit)="onSubmit(formData.value)"
                class="claim-reporting__form claim-form">
                <div class="col-md-12 p-0">
                    <div class="row ml-0">
                        <div class="col-md-5 pl-0 ml-0 d-flex">
                            <label for="" class="col-md-3 pl-0 claim-form__label claim-form__label-required">Schadendatum</label>
                            <mat-form-field
                                [ngClass]="{'claim-form__error': isDateError}" 
                                class="col-md-9"
                                appearance="fill" 
                                #myLable>
                                <span class="claim-form__placeholder">
                                    <img alt="" src="assets/images/ic-icons/Calendar.svg" class="calendar-icon">
                                </span>
                                <input
                                    (dateChange)="changeClrSchaden()" 
                                    class="claim-form__placeholder" 
                                    matInput
                                    [matDatepicker]="picker1"
                                    placeholder="{{'claim-reporting.datePlaceHolder' | translate}}"
                                    formControlName="schadenatum"
                                    [ngClass]="{ 'is-invalid': submitted && f.schadenatum.errors }"
                                    [max]="today">
                                <div *ngIf="submitted && f.schadenatum.errors" class="invalid-feedback">
                                    <div *ngIf="f.schadenatum.errors.required">Schadendatum ist erforderlich</div>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                    <mat-icon matDatepickerToggleIcon>arrow_drop_down
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-2"></div>
                        <div class="col-md-5 d-flex">
                            <label for="" class="col-md-3 claim-form__label">Uhrzeit</label>
                            <mat-form-field class="col-md-9" appearance="fill">
                                <span class="claim-form__placeholder">
                                    <img alt="" src="../../../assets/images/Icon ionic-md-time.png">&nbsp;
                                </span>
                                <input
                                    class="claim-form__placeholder"
                                    matInput placeholder="00:00"
                                    formControlName="Uhrzeit">
                                <mat-error class="mt-1"
                                    *ngIf="formData.get('Uhrzeit').hasError('required')">
                                    Uhrzeit ist erforderlich!
                                </mat-error>
                                <mat-error class="mt-1"
                                    *ngIf="formData.get('Uhrzeit').hasError('pattern')">
                                    Muss diesem Muster folgen - 00:00
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row ml-0">
                        <div class="col-md-5 pl-0 ml-0 d-flex">
                            <label for="" class="col-md-3 claim-form__label">Kennzeichen</label>
                            <mat-form-field class="col-md-9" appearance="fill">
                                <input class="claim-form__placeholder" matInput placeholder="Kennzeichen"
                                    formControlName="Kennzeichen">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2"></div>

                        <div class="col-md-5 d-flex">
                            <label for="" class="col-md-3 claim-form__label">Schadenort</label>
                            <mat-form-field class="col-md-9" appearance="fill">
                                <input
                                    type="text"
                                    matInput
                                    name="location-list"
                                    #loc
                                    id="location"
                                    placeholder="Kein Schadenort ausgewählt"
                                    (focus)="showList = true"
                                    (focusout)="onFocusOut($event)"
                                    (keyup)="keyUp.next($event)"
                                    formControlName="Schadenort"
                                    autocomplete="off">
                                <ul class="claim-form__status-filter" *ngIf="showList && filterlocation.length">
                                    <li class="claim-form__status-filter-list" *ngFor="let location of filterlocation;index as i"
                                        (click)="selectedLocation(i)">
                                        {{location.freeformAddress}}
                                    </li>
                                </ul>
                                <span class="claim-form__status-filter-flag" *ngIf="!showActive">
                                    <img alt="" src="assets/images/ic-icons/Geolocation.svg" height="25px">
                                </span>
                                <span class="claim-form__status-filter-flag" *ngIf="showActive">
                                    <img alt="" src="assets/images/ic-icons/Geolocation.svg" height="25px">
                                </span>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row ml-0">
                        <div class="col-md-5 pl-0 ml-0 d-flex">
                            <label for="" class="col-md-3 claim-form__label">Ihr Aktenzeichen</label>
                            <mat-form-field class="col-md-9" appearance="fill">
                                <input class="claim-form__placeholder" matInput placeholder="Ihr Aktenzeichen"
                                    formControlName="Aktenzeichen">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2"></div>

                        <div class="col-md-5 d-flex">
                            <label for="" class="col-md-3 claim-form__label claim-form__label-required">Geschätze Schadenhöhe</label>
                            <select class="col-md-3 claim-form__currency-dropdown" placeholder="Währung" formControlName="currency">
                                <option value="EUR" selected>EUR</option>
                            </select>
                            <mat-form-field [ngClass]="{'claim-form__error': isDamageAmountError}"
                                class="col-md-5 claim-form__currency-input ml-auto" appearance="fill">
                                <input 
                                    [(ngModel)]="changedValue"
                                    (keyup)="changeClrGesch($event)"
                                    (keypress)="numberOnly($event)"
                                    (blur)="changeCurrency($event.target.value)"
                                    [ngClass]="{ 'is-invalid': submitted && f.AmountOfDamage.errors }"
                                    placeholder="Geschätze Schadenhöhe"
                                    formControlName="AmountOfDamage"
                                    matInput>
                                <div *ngIf="submitted && f.AmountOfDamage.errors" class="invalid-feedback">
                                    <div *ngIf="f.AmountOfDamage.errors.required">Geschätze Schadenhöhe ist erforderlich
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row ml-0">
                        <div class="col-md-5 pl-0 ml-0 d-flex">
                            <label for="" class="col-md-3 claim-form__label">Upload von Dokumenten</label>
                            <mat-form-field class="col-md-8" appearance="fill">
                                <input
                                    [(ngModel)]="fileNames"
                                    matInput
                                    formControlName="files"
                                    readonly
                                    multiple
                                    placeholder="keine Datei ausgewählt">
                            </mat-form-field>
                            <button type="button" class="col-md-1 claim-form__upload-btn" (click)="uploadDoc()">
                                <img alt="" src="../../../assets/images/upload icon.svg">
                            </button>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-5 d-flex">
                            <label for="" class="col-md-5 claim-form__label claim-form__label-bold">
                                Zusätzlicher abweichender Kontakt
                            </label>
                        </div>
                    </div>

                    <div class="row ml-0">
                        <div class="col-md-5 pl-0 ml-0 d-flex">
                            <label for="" class="col-md-3 claim-form__label claim-form__label-required">Schadenhergang</label>
                            <mat-form-field
                                [ngClass]="{'claim-form__error': isSchadenError }"
                                class="claim-form__textarea col-md-9"
                                appearance="fill">
                                <textarea
                                    class="claim-form__textarea-input"
                                    (keyup)="changeClrSchadenher()"
                                    [(ngModel)]="Schadenhergang"
                                    matInput
                                    placeholder="Schadenhergang"
                                    formControlName="Schadenhergang"
                                    rows="6"
                                    cols="50"
                                    maxlength="2000"
                                    [ngClass]="{ 'is-invalid': submitted && f.Schadenhergang.errors }"></textarea>
                            </mat-form-field>
                            <div class="claim-form__textarea-bottom">
                                <span class="claim-form__textarea-bottom-tip pull-right">
                                    {{ 2000 - Schadenhergang?.length }} Zeichen verbleibend
                                </span>
                                <div *ngIf="submitted && f.Schadenhergang.errors"
                                    class="claim-form__textarea-bottom-error invalid-feedback">
                                    <div *ngIf="f.Schadenhergang.errors.required">Schadenhergang ist erforderlich</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-5">
                            <div>
                                <label for="" class="col-md-3 claim-form__label">Name </label>
                                <mat-form-field class="col-md-9" appearance="fill">
                                    <input
                                        (keyup)="nameFill($event.target.value)"
                                        class="claim-form__placeholder"
                                        matInput
                                        placeholder="Name"
                                        formControlName="name">
                                </mat-form-field>
                            </div>
                            <div>
                                <label [ngClass]="{'claim-form__label-required':  isEmailValid }" for="" class="col-md-3 claim-form__label">
                                    E-Mail
                                </label>
                                <mat-form-field [ngClass]="{'claim-form__error': isEmailValid }" class="col-md-9"
                                    appearance="fill">
                                    <input
                                        (keyup)="emailKeyUp($event.target.value)"
                                        class="claim-form__placeholder"
                                        matInput
                                        placeholder="E-Mail"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                        [required]="isEmailValid">
                                    <div class="invalid-feedback" *ngIf="isEmailValid">
                                        E-Mail oder Telefon ist erforderlich.
                                    </div>

                                    <div class="invalid-feedback"
                                        *ngIf="formData.get('email').hasError('pattern') && !isEmailValid">
                                        Bitte gültige E-Mail eingeben.
                                    </div>
                                </mat-form-field>
                            </div>
                            <div>
                                <label [ngClass]="{'claim-form__label-required': isPhoneValid}" for=""
                                    class="col-md-3 claim-form__label">Telefon</label>
                                <div class="col-md-9 d-inline-block" [ngClass]="{'claim-form__error': isPhoneValid}">
                                    <ngx-mat-intl-tel-input
                                        (keyup)="mobileKeyup($event.target.value)"
                                        formControlName="mobile"
                                        placeholder="MOBILE"
                                        [preferredCountries]="['de']"
                                        [enableSearch]="true"
                                        [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                                        #phone
                                        [required]="isPhoneValid">
                                    </ngx-mat-intl-tel-input>
                                    <div *ngIf="isPhoneValid" class="invalid-feedback claim-form__error-phone">
                                        Telefon oder E-Mail ist erforderlich
                                    </div>
                                    <div *ngIf="f.mobile.errors?.validatePhoneNumber && !isPhoneValid"
                                        class="invalid-feedback claim-form__error-phone">
                                        Bitte gültige Nummer eingeben.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-button class="text-center mt-5 ml-auto mr-auto w-100" type="submit">Speichern</app-button>
                </div>
            </form>
        </ng-container>
    </div>
</div>

<app-claim-reporting-upload-popup (hide)="hide()" (save)="save($event)" *ngIf="openDocUpload"></app-claim-reporting-upload-popup>