<div class="custom-form-container">
  <div class="custom-form-heading">
    {{'customer-management.left-panel.bank-account' | translate}}
  </div>      
  <div class="custom-form">
    <form class="general-form object-module-html"[formGroup]="bankAccountForm" *ngIf="bankAccountForm">
      <div class="row">
        <div class="col-md-3 col-sm-3">
          <label class="custom-label">{{'customer-management.bank_details.description' | translate}}</label>
          <input type="text" class="custom-input-field" maxlength="100"  
              placeholder="{{'customer-management.bank_details.description' | translate}}"
              formControlName="description" [title]="bankAccountForm.controls['description'].value">
        </div>
        <div class="col-md-3 col-sm-3">
          <label class="custom-label">{{'customer-management.bank_details.bank_name' | translate}}</label>
          <input type="text" class="custom-input-field" maxlength="100" 
              placeholder="{{'customer-management.bank_details.bank_name' | translate}}"
              formControlName="bankName" title="{{bankAccountForm.controls['bankName'].value}}">
        </div>
        <div class="col-md-3 col-sm-3">
          <label class="custom-label custom-label-required"
          [ngClass]="_showError.showBillionOrReqLabelError(bankAccountForm, 'iban')"
          >{{'customer-management.bank_details.IBAN' | translate}}</label>
          <input type="text" class="custom-input-field" maxlength="100" 
              placeholder="{{'customer-management.bank_details.IBAN' | translate}}"
              formControlName="iban" title="{{bankAccountForm.controls['iban'].value}}"
              [ngClass]="_showError.showInputError(bankAccountForm, 'iban')">
        </div>
        <div class="col-md-3 col-sm-3">
          <label class="custom-label" >{{'customer-management.bank_details.BIC' | translate}}</label>
          <input type="text" class="custom-input-field" maxlength="100" 
              placeholder="{{'customer-management.bank_details.BIC' | translate}}"
              formControlName="bic" title="{{bankAccountForm.controls['bic'].value}}">
        </div>
        <div class="col-md-3 col-sm-3">
          <label class="custom-label"> {{'customer-management.bank_details.standard_bank_connection' | translate}}</label>
          <div  class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="standardBankConnection">
              <mat-radio-button [value]="true" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-md-3 col-sm-3">
          <label class="custom-label">{{'customer-management.bank_details.differing_account_holder' | translate}}</label>
          <input type="text" class="custom-input-field" maxlength="100" 
              placeholder="{{'customer-management.bank_details.differing_account_holder' | translate}}"
              formControlName="differingAccountHolder" title="{{bankAccountForm.controls['differingAccountHolder'].value}}">
        </div>
        <div class="col-md-3 col-sm-3">
          <label class="custom-label"> {{'customer-management.bank_details.sepa_mandate' | translate}}</label>
          <div  class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="sepaMandate">
              <mat-radio-button [value]="true" (change)="sepaMandateChange(true)" class="checkBox">{{'objectModule.Yes' | translate}}</mat-radio-button>
              <mat-radio-button [value]="false" (change)="sepaMandateChange(false)" class="checkBox">{{'objectModule.No' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6 custom-date-picker-container">
          <label 
            *ngIf="!bankAccountForm.get('sepaMandateDate').hasError(errorConstant.INVALID_DATE)"
            class="custom-label">
            {{'customer-management.bank_details.sepa_mandate_date' | translate}}
          </label>
          <label
              *ngIf="bankAccountForm.get('sepaMandateDate').hasError(errorConstant.INVALID_DATE)"
              title="{{'customer-management.bank_details.sepa_mandate_date' | translate}} : {{'objectModule.Invalid_date' | translate}}" 
              class="custom-label label-error ellipsis">
            {{'customer-management.bank_details.sepa_mandate_date' | translate}} : {{'objectModule.Invalid_date' | translate}}
          </label>
          <mat-form-field  class="custom-date-picker" appearance="fill">
            <span> 
              <img alt="" [src]="formImages.CALENDER">
            </span>
            <input matInput [matDatepicker]="datepicker1" 
              class="date-input" autocomplete="off"
              maxDateToday
              placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" [max]="today"
              formControlName="sepaMandateDate">
            <mat-datepicker-toggle matSuffix [for]="datepicker1">
              <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                {{'claims.keyboard-arrow-down_text' | translate}}
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #datepicker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-3 col-sm-3">
          <label class="custom-label">{{'customer-management.bank_details.sepa_mandate_reference' | translate}}</label>
          <input type="text" class="custom-input-field" maxlength="100" 
              placeholder="{{'customer-management.bank_details.sepa_mandate_reference' | translate}}"
              formControlName="sepaMandateReference" title="{{bankAccountForm.controls['sepaMandateReference'].value}}">
        </div>
        <div class="col-md-3 col-sm-6 col-6">
          <label class="long-label">{{'customer-management.bank_details.sepa_mandate_document' | translate}}</label>
          <div style="display: flex;">
            <div class="documentUploadInput">
              <input class="input" readonly 
                  placeholder="{{'customer-management.bank_details.sepa_mandate_document' | translate}}"
                  formControlName="sepaDocuments" title="{{bankAccountForm.controls['sepaDocuments'].value}}">
            </div>
            <div class="documentUploadButton">
              <button class="docButton flex-center" style="justify-content: center;" type="button" (click)="uploadSepaDocument()" [disabled]="!bankAccountForm.controls['sepaMandate'].value">
                <img alt="" src="../../../../assets/images/ic-icons/Upload.svg">
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
          <mat-label class="long-label">{{ 'customer-management.bank_details.contact_person' | translate }}</mat-label>
          <mat-select 
              class="add-bank__select" 
              formControlName="contactPerson"
              [placeholder]="'customer-management.bank_details.choose_contact_person' | translate">
              <input 
                  type="text" 
                  maxlength="20"
                  class="searchSelect"
                  [formControl]="searchContactPersonControl"
                  preventSpace
                  [placeholder]="'header.Suche_tooltip' | translate">
              <mat-option *ngFor="let contactPerson of (filteredContactPersons$ | async)" [value]="contactPerson.id">
                  {{contactPerson.firstName}} {{contactPerson.lastName}}
              </mat-option>
          </mat-select>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="save-container" *ngIf="bankAccountForm">
    <button class="custom-btn cancel-btn" (click)="close()">
      {{'template-management.cancel_label' | translate}}
    </button>
    <button class="custom-btn save-btn" (click)="save()" [disabled]="!editCustomer">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>
<app-upload-document [object_type]="uploadDocumentDetails"
  [selectedDocumentsArray]="selectedDocumentsArray" [is_disable]="false"
  [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)">
</app-upload-document>
<app-warning-popup *ngIf="showWarningPopUp" [customerWarningMsg]="customerWarningMsg" (savedata)="saveBankData($event)" (handlePopup)="handleWarningPopup($event)"></app-warning-popup>