<span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}"
        *ngIf="row.condition.left_data.label && !row.condition.left_data.is_number">
        {{'objectModule.select_lable' | translate}}
    </span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.condition.left_data.is_data_source" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}" (click)="getSurceData(row.condition.left_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.condition.left_data.data_source}}</span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.condition.left_data.is_data_source_data" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}" (click)="getSurceData(row.condition.left_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.condition.left_data.value}}</span>
    <span *ngIf="row.condition.left_data.is_number">
      <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}" (change)="inputChanged($event)" class="underlined-input" inputmode="numeric" [(ngModel)]="row.condition.left_data.number" [disabled]="is_disabled">
    </span>
    <span *ngIf="row.condition.left_data.is_text">
      <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)" class="underlined-input" title="{{row.condition.left_data.text}}" [(ngModel)]="row.condition.left_data.text" [disabled]="is_disabled">
    </span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'"  [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from:ROWSIDES.LEFT_ROW}">
      <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg"  style="margin: 0px 15px;">
    </span>

    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="operatorSelect" [matMenuTriggerData]="{index: i}" style="margin: 0px 20px; font-size: 17px;" *ngIf="row.condition.operator">
      {{row.condition.operator.operator}}
    </span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.condition.operator == null" [matMenuTriggerData]="{index: i}" [matMenuTriggerFor]="operatorSelect" style="margin: 0px 20px;">
      <img alt="" height="18px" src="../../../../assets/images/Logical_Operator_Icon.svg" >
    </span>

    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}"
      *ngIf="row.condition.right_data.label && !row.condition.right_data.is_number">
      {{'objectModule.select_lable' | translate}}
    </span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.condition.right_data.is_data_source" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}" (click)="getSurceData(row.condition.right_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.condition.right_data.data_source}}</span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" *ngIf="row.condition.right_data.is_data_source_data" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}" (click)="getSurceData(row.condition.right_data.data_source_id, product_info_id)" [matMenuTriggerFor]="dataSourceData" (menuOpened)="focusSearch('searchSelect')">{{row.condition.right_data.value}}</span>
    <span *ngIf="row.condition.right_data.is_number">
      <input type="number" placeholder="{{'value-calculator.enter-number' | translate}}" (change)="inputChanged($event)" class="underlined-input" inputmode="numeric" [(ngModel)]="row.condition.right_data.number" [disabled]="is_disabled">
    </span>
    <span *ngIf="row.condition.right_data.is_text">
      <input type="text" placeholder="{{'value-calculator.input-text' | translate}}" (change)="inputChanged($event)" class="underlined-input" title="{{row.condition.right_data.text}}" [(ngModel)]="row.condition.right_data.text" [disabled]="is_disabled">
    </span>
    <span [ngClass]="is_disabled ? 'no-pointer' : 'pointer'" [matMenuTriggerFor]="leftMenu" [matMenuTriggerData]="{index: i, from: ROWSIDES.RIGHT_ROW}">
      <img alt="" src="../../../../assets/images/new-icons/Select_Menu_MG.svg"  style="margin: 0px 15px;">
    </span>
</span>

  <mat-menu #leftMenu="matMenu" class='custom-mat-menu'>
    <ng-template matMenuContent let-index="index" let-from="from">
      <span mat-menu-item *ngFor="let item of data_source_types" [class]="item.is_heading ? 'dropdown-section-heading' : '' " (click)="changeMenuItem(item.id,from, index, item.is_heading, $event, false)" title="{{item.label}}">{{item.label}} </span>
    </ng-template>
  </mat-menu>
  <mat-menu #operatorSelect="matMenu" class='custom-mat-menu' >
    <ng-template matMenuContent let-index="index">
      <span mat-menu-item  *ngFor="let item of operators" [class]="item.is_heading ? 'dropdown-section-heading' : '' " (click)="changeOperator(item.id, index, item.is_heading, $event,from)"> {{item.label}} </span>
    </ng-template>
  </mat-menu>
  <mat-menu #dataSourceData="matMenu" class='custom-mat-menu'>
    <ng-template matMenuContent let-index="index" let-from="from">
      <input type="text" maxlength="50" preventSpace class="searchSelect" style="height: 28px;font-size: 12px;"
      placeholder="{{'header.Suche_tooltip' | translate}}" matInput #search (click)="$event.stopPropagation()">
      <span mat-menu-item  *ngFor="let item of validationService._filter(data_source_data, 'dropdown', search.value)" (click)="changeMenuItem(item.id, from, index, false, $event, true)" title="{{item.dropdown}}"> {{item.dropdown}} </span>
    </ng-template>
  </mat-menu>
