<app-modal width="600" (close)="closeDialog()">
  <ng-container title>{{ title$ | async }}</ng-container>

  <div content *ngIf="form">
    <form class="document-upload" [formGroup]="form">
      <div class="form__row">
        <label class="form__label">{{
          "upload-document.DOCUMENT_TYPE_LABEL" | translate
        }}</label>
        <select
          class="number form-control mt-2"
          *ngIf="documentTypes$ | async as types"
          formControlName="type"
        >
          <option value="" hidden disabled selected>
            {{ "upload-document.DOCUMENT_TYPE_PLACEHOLDER" | translate }}
          </option>
          <option *ngFor="let type of types" [value]="type.value">
            {{ type.label }}
          </option>
        </select>
      </div>

      <div class="form__row mt-2">
        <label class="form__label">{{
          "upload-document.VISIBLE_FOR_CUSTOMER_LABEL" | translate
        }}</label>
        <mat-checkbox
          formControlName="visibility"
          class="custom-checkBox"
        ></mat-checkbox>
      </div>

      <div class="form__row form__flex-form mt-2">
        <label class="form__label">{{
          "upload-document.DESCRIPTION_LABEL" | translate
        }}</label>
        <textarea
          class="desc"
          placeholder=" {{
            'upload-document.DESCRIPTION_LABEL' | translate
          }} "
          class="number form-control mt-2"
          type="text"
          rows="8"
          formControlName="description"
          pattern="^[^\s]+(\s+[^\s]+)*$"
        ></textarea>
      </div>

      <div class="form__row mt-2">
        <label class="form__label">{{
          "upload-document.SOURCE_TARGET" | translate
        }}</label>
        <select
          formControlName="direction"
          class="number form-control mt-2"
        >
          <option value="" hidden disabled selected>
            {{ "upload-document.SOURCE_TARGET_PLACEHOLDER" | translate }}
          </option>
          <option
            *ngFor="let option of directionOptions"
            [value]="option.label"
          >
            {{ option.label | translate }}
          </option>
        </select>
      </div>

      <div class="form__row--legend mt-2">{{ legend$ | async }}</div>
    </form>
  </div>

  <ng-container actions>
    <app-button secondary (click)="closeDialog()">{{ 'upload-document.CANCEL' | translate }}</app-button>
    <app-button 
      matTooltipPosition="after"
      matTooltip="{{
        'upload-document.REQUIRED_FIELDS_MESSAGE' | translate
      }}"
      [matTooltipDisabled]="form.valid"
      (click)="continue()" 
      [disabled]="form.invalid"
    >{{ 'office.action_button_continue' | translate }}</app-button>
  </ng-container>
</app-modal>
