<form class="row object-module-html" [formGroup]="electronicForm" id="electronicForm">
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label">
      {{'objectModule.newObjecttype' | translate}}</label>
    <input type="text" maxlength="100" class="input" formControlName="object_name" placeholder="{{'objectModule.newObjecttype' | translate}}"
      title="{{electronicForm.controls['object_name'].value}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.LaufendeNummer' | translate}}</label>
    <input  *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" numbersOnly type="number" maxlength="5" pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)"  class="input numberText" formControlName="sequenceNumber" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
    <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" numbersOnly type="text" maxlength="5" ng-pattern="[0-9]*" (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)"   class="input numberText" formControlName="sequenceNumber" placeholder="{{'objectModule.LaufendeNummer' | translate}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label">
      {{'objectModule.Bezeichnung' | translate}}</label>
    <input type="text" maxlength="100" class="input" formControlName="description" placeholder="{{'objectModule.Bezeichnung' | translate}}"
    title="{{electronicForm.controls['description'].value}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label">
      {{'objectModule.Hersteller' | translate}}</label>
    <input  type="text" class="input" maxlength="100" formControlName="manufacturer" placeholder="{{'objectModule.Hersteller' | translate}}"
      title="{{electronicForm.controls['manufacturer'].value}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label">
      {{'objectModule.Modell/Typ' | translate}}</label>
    <input type="text" class="input" maxlength="100" formControlName="model" placeholder="{{'objectModule.Modell/Typ' | translate}}"
    title="{{electronicForm.controls['model'].value}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.Seriennummer' | translate}}</label>
    <input type="text" class="input" maxlength="100" formControlName="serialNumber" placeholder="{{'objectModule.Seriennummer' | translate}}"
    title="{{electronicForm.controls['serialNumber'].value}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.Baujahr' | translate}}</label>
    <mat-select (click)="scroll()" (keyup.enter)="scroll()" class="dropdownStyle switcherDrpdwn inputy switcher" (selectionChange)="yearSelectedCon()"
      placeholder="{{'objectModule.select_lable' | translate}}" formControlName="constructionYear" #yearOfManufacture>
      <input type="text" id="searchYear1" maxlength="4" class="searchYear" placeholder="{{'header.Suche_tooltip' | translate}}" matInput [formControl]="yearControl1">
      <mat-option>{{'objectModule.select_lable' | translate}}</mat-option>
      <mat-option *ngFor="let year of yearsCon" (click)="yearSelectedCon()" [ngClass]="{'active-option': year === currentYear && !electronicForm.value.constructionYear}" [value]="year" >{{year}}</mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.Objektlöschanlagen' | translate}}</label>
    <mat-select  multiple class="dropdownStyle switcherDrpdwn inputy switcher"
      placeholder="{{'objectModule.select_lable' | translate}}" matTooltipClass="testtooltip" [matTooltip]="itemtooltiptext()" formControlName="objectExtSystem">
      <mat-option *ngFor="let ele of objectSystem" [value]="ele.id" ><span>{{ele.name}}</span></mat-option>
    </mat-select>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" *ngIf="!errorWert" class="label">{{'objectModule.wert_371' | translate}}</label>
    <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
      *ngIf="errorWert"><span [ngStyle]="{'text-transform' : selectLang === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'objectModule.wert_371' | translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
    <input  *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" formControlName="wert_371" class="input numberText"
      placeholder="{{'objectModule.wert_371' | translate}}" type="text"
      (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
      (blur)="changeNumber($event.target.value, 'wert371'); calculateNewValue(electronicForm ,$event)"
      >
    <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" formControlName="wert_371" class="input numberText"
      placeholder="{{'objectModule.wert_371' | translate}}" type="text"
      (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
      (blur)="changeNumber($event.target.value, 'wert371'); calculateNewValue(electronicForm ,$event)"
      >
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <div class="smallInput" style="width: 100%;">
      <label for=""  *ngIf="!errorNewValue && !errorNewValue1" class="label"> {{'objectModule.Neuwert' | translate}}</label>
      <label title="{{'objectModule.limitError' | translate}}" class="touched-warning ellipsis"
      *ngIf="errorNewValue || errorNewValue1"><span [ngStyle]="{'text-transform' : selectLang === 'English(EN)' ? 'capitalize' : 'inherit'}">{{'objectModule.Neuwert' | translate}}</span> : {{'objectModule.limitError' | translate}}"</label>
      <div style="display: flex;">
      <mat-select id="currencyDropdown" (selectionChange)="currancyChanged($event)"
        class="dropdownStyle currencyDrop currecnyStyle" formControlName="currency_type_id"
        [disableOptionCentering]="true"  title="{{selectedCurrency}}"
        (click)="focusCurrency()" (keyup.enter)="focusCurrency()"
        panelClass="currencyTypeDropdown"
        #currencyDropdown
        >
        <mat-select-trigger>
            <span>{{currancySymbol}}</span>
        </mat-select-trigger>
        <input type="text" class="searchYear" id="currencyDD" maxlength="5" autocomplete="off" placeholder="{{'header.Suche_tooltip' | translate}}" matInput
            [formControl]="currencyType">
        <mat-option *ngFor="let ele of currencyTypes" [value]="ele.id" matTooltip="{{ele.cur_symbol}}" >
            <span style="display: flex;">
                <span style="width:70px;padding-right: 10px;">{{ele.cur_code}}</span>
                <span style="justify-content: end;">{{ele.cur_symbol}}</span>
            </span>
        </mat-option>
    </mat-select>
      <input  type="text" style="border-radius: 0px;height: 38px;" maxlength="100"
      *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input newValue"
      (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
      (blur)="changeNumber($event.target.value, 'newValue'); calculateValue($event)"
      formControlName="newValue" placeholder="{{'objectModule.Neuwert' | translate}}" GermanFormattingRequired BillionCheck>
      <input  type="text" style="border-radius: 0px;
      height: 38px;" maxlength="100" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input newValue"
      (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
      (blur)="changeNumber($event.target.value, 'newValue'); calculateValue($event)"  formControlName="newValue" placeholder="{{'objectModule.Neuwert' | translate}}" GermanFormattingRequired BillionCheck>
      <mat-select id="valueDropdown" (click)="scrollNewValue()" (keyup.enter)="scrollNewValue()"
        class="dropdownStyle valueTypeStyle ellipsis switcherDrpdwn inputy switcher" (selectionChange)="yearSelected(); yearChange($event.value)"
        placeholder="{{'objectModule.select_lable' | translate}}" formControlName="newValuePerYear" #tempControl>
        <input type="text" maxlength="4" id="searchYear2" class="searchYear" placeholder="{{'header.Suche_tooltip' | translate}}" matInput [formControl]="yearControl">
        <mat-option>{{'objectModule.select_lable' | translate}}</mat-option>
        <mat-option *ngFor="let year of years" [ngClass]="{'active-option': year === currentYear && !electronicForm.value.newValuePerYear}" [value]="year" >{{year}}</mat-option>
      </mat-select>
      </div>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.Anzahl' | translate}}</label>
    <input  *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" type="number" maxlength="5" numbersOnly pattern="[0-9]*" (keydown)="typeNumber($event, 'keydown',5)" (paste)="typeNumber($event, 'paste',5)" (blur)="calculateGesamtwert($event)"  class="input numberText" formControlName="count" placeholder="{{'objectModule.Anzahl' | translate}}">
    <input  *ngIf="browser == 'Safari' || browser == 'Firefox'" type="text" maxlength="5" ng-pattern="[0-9]*" numbersOnly (paste)="typeNumberBrowserSpecific($event, 'paste',5)" (keypress)="typeNumberBrowserSpecific($event, 'keydown',5)"  (blur)="calculateGesamtwert($event)"  class="input numberText" formControlName="count" placeholder="{{'objectModule.Anzahl' | translate}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="label"> {{'objectModule.Gesamtwert' | translate}}</label>
    <input  type="text" style="height: 38px;" readonly class="input" formControlName="Gesamtwert" placeholder="{{'objectModule.Gesamtwert' | translate}}">
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.fire_protection_equipment' | translate}}</label>
    <div style="display: flex;">
    <div class="documentUploadInput">
        <input class="input" readonly placeholder="{{'objectModule.fire_protection_equipment' | translate}}"
        title="{{electronicForm.controls['fire_protection_doc'].value}}" formControlName="fire_protection_doc">
    </div>
    <div class="documentUploadButton">
    <button class="docButton" type="button" (click)="uploadFireDocumentPopup()">
      <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
    </button>
    </div>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.acquisition_invoice' | translate}}</label>
    <div style="display: flex;">
    <div class="documentUploadInput">
        <input class="input" readonly placeholder="{{'objectModule.acquisition_invoice' | translate}}"
        title="{{electronicForm.controls['acquisition_invoice_doc'].value}}" formControlName="acquisition_invoice_doc">
    </div>
    <div class="documentUploadButton">
    <button class="docButton" type="button" (click)="uploadAcquisitionDocumentPopup()">
      <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
    </button>
    </div>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-6">
    <label for="" class="long-label">{{'objectModule.risk_questionnaire' | translate}}</label>
    <div style="display: flex;">
    <div class="documentUploadInput">
        <input class="input" readonly placeholder="{{'objectModule.risk_questionnaire' | translate}}"
        title="{{electronicForm.controls['risk_questionnaire_doc'].value}}" formControlName="risk_questionnaire_doc">
    </div>
    <div class="documentUploadButton">
    <button class="docButton" type="button" (click)="uploadRiskDocumentPopup()">
      <img alt="" style="margin-bottom: 3px;" src="../../../assets/images/ic-icons/Upload.svg">
    </button>
    </div>
    </div>
  </div>
</form>
<app-upload-document [object_data]="object_data" [object_type]="uploadObjectDetails" [selectedDocumentsArray]="selectedDocumentsArray"
[showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)"></app-upload-document>
