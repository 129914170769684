<app-modal width="900" noActions (close)="closeModal()">
    <ng-container title>{{ 'AlleDokumente_title' | multilingual | translate }}</ng-container>

    <ng-container content>
        <app-generate-table
            [tableDetails]="tableStructure"
            [tableData]="tableData"
            [tableConfig]="tableConfig"
            (emitMethodDetails)="callMethod($event)">
        </app-generate-table>
    </ng-container>
</app-modal>

<custom-pdf-viewer
    *ngIf="isPdfDisplayed" 
    [pdfLink]="pdfLink"
    (onClosePdf)="closePDF()"
    [blob]="blob"
    via="policyTemplate"></custom-pdf-viewer>