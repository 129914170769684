<input type="text" class="input" maxlength="100" [disabled]="is_input_disabled ? true : false" placeholder="{{placeholder}}"
  [formControl]="control" [matAutocomplete]="auto" (blur)="checkIsSelected()" [title]="control && control.value || ''"
  [ngStyle]="{'border' : validate === true ? '1px solid red' : '1px solid rgb(0 0 0 / 42%)'}"
  [ngClass]="is_input_disabled ? 'disabled-input' : ''">
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="newValeSelected();optionSelected = true">
  <mat-option *ngFor="let list of filteredStreets | async" [value]="list">
    {{list}}
  </mat-option>
  <mat-option *ngIf="newValue" [value]="newValue" (optionSelected)="optionSelected = true"
    title="{{newValue}} ({{'objectModule.object_details_group.new_value' | translate }})">
    {{newValue}} ({{'generic_message.new_label' | translate }})
  </mat-option>
</mat-autocomplete>