import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ShareDataService } from '@core/services/share-data.service';
import { ROUTES_PATH } from '@shared/constants/router.constants';
export interface SendElementFromConstr {
  first_name: string;
  last_name: string;
  mobile_number: string;
  city: string;
  email: string;
  country: string;
  language: string;
  zip_code: string;
  house: string;
  street: string;
  salutation_id: number;
  user_id: string;
  role: string;
}

@Component({
  selector: 'app-save-profile-changes-dialog',
  templateUrl: './save-profile-changes-dialog.component.html',
  styleUrls: ['./save-profile-changes-dialog.component.css'],
})
export class SaveProfileChangesDialogComponent {
  url: any;
  dasboardConfigEdit = false;
  call: any;
  customerEdit = false;
  profileSection = false;
  objectSection = false;
  productManagementSection = false;
  productManagementSectionDiscard = false;
  profileData: any;
  currentUrl: String;
  salutation_id: number;
  constructor(
    public dialogRef: MatDialogRef<SaveProfileChangesDialogComponent>,
    private router: Router,
    private shareService: ShareDataService,
    public translate: TranslateService,
    private serviceCall: ApiCallsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.url = data.nextUrl;
    this.currentUrl = data.currentUrl;
    if (this.currentUrl.toLowerCase().includes(ROUTES_PATH.CUSTOMER_EDIT)) {
      this.customerEdit = true;
    } else if (
      this.currentUrl.toLowerCase().includes(ROUTES_PATH.USER_PROFILE)
    ) {
      this.profileSection = true;
    } else if (
      this.currentUrl
        .toLowerCase()
        .includes(ROUTES_PATH.CUSTOMER_DASHBOARD_CONFIGURATION) ||
      this.currentUrl
        .toLowerCase()
        .includes(ROUTES_PATH.DASHBOARD_CONFIGURATION)
    ) {
      this.dasboardConfigEdit = true;
    } else if (
      this.currentUrl.toLowerCase().includes(ROUTES_PATH.ADD_NEW_OBJECT)
    ) {
      this.objectSection = true;
    } else if (
      this.currentUrl
        .toLowerCase()
        .includes(ROUTES_PATH.PRODUCT_MANAGEMENT_EDIT)
    ) {
      this.productManagementSection = true;
    }
    this.profileData = data.component;
    this.productManagementSectionDiscard = !!data.enableDiscard;
  }
  onCloseProductManagement() {
    this.dialogRef.close();
    this.customerEdit = false;
    this.profileSection = false;
    this.dasboardConfigEdit = false;
    this.objectSection = false;
    this.productManagementSection = false;
    this.productManagementSectionDiscard = false;
  }

  onCloseClick() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.customerEdit = false;
      this.profileSection = false;
      this.dasboardConfigEdit = false;
      this.objectSection = false;
      this.productManagementSection = false;
      this.productManagementSectionDiscard = false;
      this.router.navigate([this.url, { isUserWantToNevigate: true }]);
    });
  }
  // FUNCTION TO ALLOW USER FOR NAVIGATION
  leaveprofilePage() {
    this.shareService.sendProfileClickEvent();
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(async () => {
      this.customerEdit = false;
      this.profileSection = false;
      this.dasboardConfigEdit = false;
      this.objectSection = false;
      this.productManagementSection = false;
      this.productManagementSectionDiscard = false;
      if (
        this.profileData.formControl.value.salutation == 'Frau' ||
        this.profileData.formControl.value.salutation == 'Mrs'
      ) {
        this.salutation_id = 1;
      } else if (
        this.profileData.formControl.value.salutation == 'Herr' ||
        this.profileData.formControl.value.salutation == 'Mr'
      ) {
        this.salutation_id = 2;
      }
      const updateprofileData: SendElementFromConstr = {
        first_name: this.profileData.formControl.value.first_name,
        last_name: this.profileData.formControl.value.last_name,
        mobile_number: this.profileData.formControl.value.mobile_number,
        city: this.profileData.formControl.value.city,
        email: this.profileData.formControl.value.email,
        country: this.profileData.formControl.value.country,
        language: this.profileData.formControl.value.language,
        zip_code: this.profileData.formControl.value.zip_code,
        house: this.profileData.formControl.value.house,
        street: this.profileData.formControl.value.street,
        salutation_id: this.salutation_id,
        user_id: this.profileData.user_data.user_id,
        role: this.profileData.user_role,
      };
      this.serviceCall.updateProfile(updateprofileData).subscribe(data => {
        if (data.HasErrors == false) {
          this.translate.use(this.profileData.formControl.value.language);
          this.router.navigate([this.url, { isUserWantToNevigate: true }]);
        }
      });
    });
  }
  leaveCustomerChangesPage() {
    this.shareService.sendCustomerClickEvent();
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(result => {
      this.customerEdit = false;
      this.profileSection = false;
      this.objectSection = false;
      this.productManagementSection = false;
      this.productManagementSectionDiscard = false;
      this.dasboardConfigEdit = false;
      this.router.navigate([this.url, { isUserWantToNevigate: true }]);
    });
  }
  leaveDashboardConfigPage() {
    this.shareService.sendClickEvent();
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(result => {
      this.customerEdit = false;
      this.profileSection = false;
      this.objectSection = false;
      this.productManagementSection = false;
      this.productManagementSectionDiscard = false;
      this.dasboardConfigEdit = false;
      this.router.navigate([this.url, { isUserWantToNevigate: true }]);
    });
  }
  leaveObjectPae() {
    this.shareService.sendObjectClickEvent();
    this.dialogRef.close();
    // remove searchItemPath from session to resolve multi discard popup issue
    sessionStorage.removeItem('searchItemPath');
    this.dialogRef.afterClosed().subscribe(result => {
      this.customerEdit = false;
      this.profileSection = false;
      this.objectSection = false;
      this.productManagementSection = false;
      this.productManagementSectionDiscard = false;
      this.dasboardConfigEdit = false;
    });
  }
  onCloseObjectClick() {
    this.shareService.setIsObjectDiscarded();
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(result => {
      this.customerEdit = false;
      this.profileSection = false;
      this.dasboardConfigEdit = false;
      this.objectSection = false;
      this.productManagementSection = false;
      this.productManagementSectionDiscard = false;
      this.router.navigateByUrl(this.url);
    });
  }

  onCloseProductManagementClick() {
    this.shareService.setIsObjectDiscarded();
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(result => {
      this.customerEdit = false;
      this.profileSection = false;
      this.dasboardConfigEdit = false;
      this.objectSection = false;
      this.productManagementSection = false;
      this.productManagementSectionDiscard = false;

      const prev = this.router.routeReuseStrategy.shouldReuseRoute;
      const prevOSN = this.router.onSameUrlNavigation;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl(this.url).then(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = prev;
        this.router.onSameUrlNavigation = prevOSN;
      });
    });
  }
}
