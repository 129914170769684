import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  BuildingConstants,
  UploadTypeConstants,
} from '@shared/constants/building.constants';
import { ObjectTypesID } from '@shared/constants/generic.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import {
  BuildingObject,
  Criteria,
  Objectdetails,
} from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
import { ValidationSharedService } from '@core/services/validation-shared.service';
import { ObjectModuleSharedService } from '../../services/object-module-shared.service';

@Component({
  selector: 'app-object-details',
  templateUrl: './object-details.component.html',
  styleUrls: ['./object-details.component.css'],
})
export class ObjectDetailsComponent extends BaseComponent implements OnInit {
  objectDetailsFormGroup: FormGroup;
  @Input('object_data') parentObjectData;
  @Input('objectType') objectType;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Input('newCustomerFormReq') newCustomerFormReq: any;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @ViewChild('tempControl', { static: true }) newValueDropdown: MatSelect;
  @ViewChild('constructionYearControl', { static: true })
  constructionYearDropdown: MatSelect;
  @ViewChild('operatingModeControl', { static: true })
  operatingModeDropdown: MatSelect;
  @Input('isresetForm') isresetForm;
  yearControl = new FormControl();
  constructionYearFormControl = new FormControl();
  years = [];
  selectedAudiDocumentsArray = {};
  selectedSitePlanDocumentsArray = {};
  selectedDocumentsArray = {};
  isFormValueUpdated: boolean;
  showAuditDocumentPopup = false;
  showsitePlanDocumentPopup = false;
  valueTypes: any[];
  typeOfConstructionList: any[];
  operatingModeList: any[];
  operatingModeGGPList: any[];
  buildingObjectTypesList: any[];
  buildingDefectsList: any[];
  constructionYearList: any[];
  buildingTypesList: any[];
  uploadObjectDetails: UploadObjectDetails;
  currencyTypes: any = [];
  currancySymbol = null;
  currencyType = new FormControl();
  currencyTypetemp: any;
  selectedCurrency: any;
  operatingModetempList: any;
  obdAuditDocsIds = [];
  obdSitePlanDocsIds = [];

  @ViewChild('currencyTypeDrop', { static: true })
  currencyTypeDropdown: MatSelect;
  errorNewValue = false;
  errorRentalValue = false;
  errorNewValue1: boolean;
  operatingModeListFiltered: any = [];
  operatingModeFormControl = new FormControl();
  errorValue = false;
  transformerStations;
  transformerStationsData = [];
  transformerStationsValidation = new FormControl({
    value: null,
    disabled: false,
  });
  today = new Date();
  recentFactoryVisitDateError = false;
  auditDateError = false;
  eliminationDateError = false;
  request: BuildingObject;
  currentYear: number;
  yearsCon: any[];
  browser: string;
  actualnewValue = 0;

  constructor(
    private objectDetailsBuilder: FormBuilder,
    private translate: TranslateService,
    private serviceCall: ApiCallsService,
    public validation: ValidationService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    private readonly _validationSharedService: ValidationSharedService,
    private readonly _objectModuleSharedService: ObjectModuleSharedService
  ) {
    super();
    this.browser = this.detectBrowser();

    this.operatingModeFormControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterModeSearch(value);
      });
    this.currencyType.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterCurrency(value);
      });
    this.yearControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filter(value);
      });
    this.constructionYearFormControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filtercon(value);
      });
  }

  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnInit(): void {
    const year = new Date().getFullYear();
    this.currentYear = year;
    this.yearsCon = [];
    this.years = [];

    for (let i = 100; i >= 1; i--) {
      this.years.push(year - i);
      this.yearsCon.push(year - i);
    }
    this.years.push(year);
    this.yearsCon.push(year);
    this.years.push(year + 1);
    this.yearsCon.push(year + 1);
    this._objectModuleSharedService.saveYears(this.years);
    this._objectModuleSharedService.saveYearsCon(this.yearsCon);
    this.getDropDownData();
    this.initializeForm();
    this._getCurrencyTypes();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initializeForm();
    }
  }
  getDropDownData() {
    const requests = [];
    requests.push(
      this.serviceCall
        .getValueType(ObjectTypesID.BUILDING)
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall.getConstTypes().pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall.getBuildingTypes().pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall
        .getBuildingOperatingMode()
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall
        .getBuildingObjectTypes()
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall.getBuildingDefects().pipe(catchError(error => of(error)))
    );
    requests.push(
      this.serviceCall
        .getBuildingOperatingModeGGP()
        .pipe(catchError(error => of(error)))
    );
    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.valueTypes =
          !data[0].hasErrors && data[0].object_value_types
            ? data[0].object_value_types
            : [];
        this.typeOfConstructionList =
          !data[1].hasErrors && data[1].construction_types
            ? data[1].construction_types
            : [];
        this.buildingTypesList =
          !data[2].hasErrors && data[2].building_types
            ? data[2].building_types
            : [];
        this.operatingModeList =
          !data[3].hasErrors && data[3].data ? data[3].data : [];
        this.buildingObjectTypesList =
          !data[4].hasErrors && data[4].data ? data[4].data : [];
        this.buildingDefectsList =
          !data[5].hasErrors && data[5].data ? data[5].data : [];
        this.operatingModeGGPList =
          !data[6].hasErrors && data[6].data ? data[6].data : [];
        this.operatingModeListFiltered =
          !data[3].hasErrors && data[3].data ? data[3].data : [];
        this.operatingModetempList = this.operatingModeListFiltered;
        this.getConstructionYearList();
      });
  }
  getConstructionYearList() {
    this.constructionYearList = this.serviceCall.getPast100YearList();
  }

  private _getCurrencyTypes() {
    this.serviceCall
      .getCurrencyTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (null !== data.currency_types) {
          this.currencyTypes = data.currency_types;
          this.currencyTypetemp = this.currencyTypes;
          if (this.parentObjectData) {
            if (this.parentObjectData.obd_currency_id) {
              this.currancySymbol = this.currencyTypes.find(
                obj => obj.id == this.parentObjectData.obd_currency_id
              ).cur_code;
              this.selectedCurrency = this.currencyTypes.find(
                obj => obj.id == this.parentObjectData.obd_currency_id
              ).cur_symbol;
            }
          } else {
            this.currancySymbol = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).cur_code;
            this.selectedCurrency = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).cur_symbol;
            const tempId = this.currencyTypes.find(
              obj => obj.cur_symbol == 'EUR'
            ).id;
            this.objectDetailsFormGroup.controls.obd_currency_id.setValue(
              tempId
            );
          }
        }
      });
  }

  initializeForm() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        BuildingConstants.OBJECT_DETAILS
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;

    this.assignAttributeValues(formData);
    this.objectDetailsFormGroup.controls.obd_audit_doc.setValue(
      Object.keys([]).join(', ')
    );
    this.objectDetailsFormGroup.controls.obd_site_plan_docs.setValue(
      Object.keys([]).join(', ')
    );
    this.isFormValueUpdated = false;
    this.objectDetailsFormGroup.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isFormValueUpdated = true;
        this.buildFormObject();
        const pristine = this.objectDetailsFormGroup.pristine;
        this.saveCriteriaRequest.emit({
          request: this.request,
          isSave: false,
          pristine,
        });
      });
    this.getDocumentUploadData(record_id);
  }

  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.OD_REVISION_DATE_DOCUMENT
              ) {
                this.selectedAudiDocumentsArray[document.document_name] =
                  document;
              }
              if (document.document_type === UploadTypeConstants.OD_SITE_PLAN) {
                this.selectedSitePlanDocumentsArray[document.document_name] =
                  document;
              }
            });
            if (data.documents) {
              this.objectDetailsFormGroup.controls.obd_audit_doc.setValue(
                Object.keys(this.selectedAudiDocumentsArray).join(', ')
              );
              this.objectDetailsFormGroup.controls.obd_site_plan_docs.setValue(
                Object.keys(this.selectedSitePlanDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }
  assignAttributeValues(formData) {
    const obd_name = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_name : null,
        disabled: false,
      },
      []
    );
    const obd_defects = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_defects : null,
        disabled: formData
          ? formData.objectDetails.obd_audit_obligation === 'true'
            ? false
            : true
          : true,
      },
      []
    );
    const obd_vacancy = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_vacancy : null,
        disabled: false,
      },
      []
    );
    const obd_type_of_use = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_type_of_use : null,
        disabled: false,
      },
      []
    );
    const obd_rental_value = new FormControl(
      {
        value: formData
          ? this._currencyCustomPipe
              .transform(formData.objectDetails['obd_rental_value'])
              ?.replace('€', '')
              ?.split(',')[0]
              ?.trim()
          : null,
        disabled: false,
      },
      []
    );
    let obd_living_space = new FormControl({
      value: formData
        ? this._currencyCustomPipe
            .transform(formData.objectDetails['obd_living_space'])
            ?.replace('€', '')
            ?.split(',')[0]
            ?.trim()
        : null,
      // ? this.validation.setSqmValue(
      //   formData.objectDetails["obd_living_space"]
      // )
      // : null,
      disabled: formData
        ? formData.objectDetails.obd_type_of_use == 1
          ? false
          : true
        : true,
    });
    const obd_operation_mode = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_operation_mode : null,
        disabled: false,
      },
      []
    );
    const obd_operation_mode_ggp = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_operation_mode_ggp : null,
        disabled: false,
      },
      []
    );
    const obd_audit_obligation = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_audit_obligation : null,
        disabled: false,
      },
      []
    );
    const obd_monument_protection = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_monument_protection : null,
        disabled: false,
      },
      []
    );
    const obd_type_of_construction = new FormControl(
      {
        value: formData
          ? formData.objectDetails.obd_type_of_construction
          : null,
        disabled: false,
      },
      []
    );
    const obd_year_of_construction = new FormControl(
      {
        value: formData
          ? formData.objectDetails.obd_year_of_construction
          : null,
        disabled: false,
      },
      []
    );
    const obd_residentail_units_id = new FormControl(
      {
        value: formData
          ? formData.objectDetails.obd_no_residentail_units
          : null,
        disabled: formData
          ? formData.objectDetails.obd_type_of_use == 1
            ? false
            : true
          : true,
      },
      []
    );
    const sitePlanUploadDocs = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_site_plan_docs : null,
        disabled: false,
      },
      []
    );
    const obd_currency_id = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_currency_id : null,
        disabled: false,
      },
      []
    );
    const obdNewValue = formData?.objectDetails.obd_new_value || null;
    const obd_new_value = new FormControl(
      {
        value:
          typeof obdNewValue === 'number'
            ? this._currencyCustomPipe
                .transform(formData.objectDetails['obd_new_value'])
                ?.replace('€', '')
                ?.split(',')[0]
                ?.trim()
            : obdNewValue,
        disabled: false,
      },
      []
    );
    const obd_new_value_year = new FormControl(
      {
        value: formData ? formData.objectDetails.obd_new_value_year : null,
        disabled: false,
      },
      []
    );
    const obd_value_type = new FormControl(
      {
        value: formData
          ? formData.objectDetails.obd_value_type
            ? formData.objectDetails.obd_value_type
            : 1
          : 1,
        disabled: false,
      },
      []
    );
    const obd_recent_factory_visit = new FormControl(
      {
        value: formData
          ? formData.objectDetails.obd_recent_factory_visit
          : null,
        disabled: false,
      },
      []
    );
    const obd_type_of_factory_tour = new FormControl(
      {
        value: formData
          ? formData.objectDetails.obd_type_of_factory_tour
          : null,
        disabled: false,
      },
      []
    );
    const obd_audit_date = new FormControl({
      value: formData ? formData.objectDetails.obd_audit_date : null,
      disabled: formData
        ? formData.objectDetails.obd_audit_obligation === 'true'
          ? false
          : true
        : true,
    });
    const obd_audit_doc = new FormControl({
      value: formData ? formData.objectDetails.obd_audit_doc : null,
      disabled: formData
        ? formData.objectDetails.obd_audit_obligation === 'true'
          ? false
          : true
        : true,
    });
    const obd_elimination_date = new FormControl({
      value: formData ? formData.objectDetails.obd_elimination_date : null,
      disabled: formData
        ? formData.objectDetails.obd_audit_obligation === 'true'
          ? false
          : true
        : true,
    });
    const annotations = new FormControl({
      value: formData ? formData.objectDetails.annotations : null,
      disabled: false,
    });
    const is_annotation = new FormControl({
      value: formData ? formData.objectDetails.is_annotation : null,
      disabled: false,
    });

    this.objectDetailsFormGroup = this.objectDetailsBuilder.group({
      obd_name,
      obd_currency_id,
      obd_new_value,
      obd_new_value_year,
      obd_value_type,
      obd_type_of_construction,
      obd_year_of_construction,
      obd_monument_protection,
      obd_site_plan_docs: sitePlanUploadDocs,
      obd_type_of_use,
      obd_operation_mode,
      obd_operation_mode_ggp,
      obd_vacancy,
      obd_rental_value,
      obd_living_space,
      obd_no_residentail_units: obd_residentail_units_id,
      obd_recent_factory_visit,
      obd_type_of_factory_tour,
      obd_audit_obligation,
      obd_audit_date,
      obd_audit_doc,
      obd_defects,
      obd_elimination_date,
      annotations,
      is_annotation,
    });
  }
  resetValueType() {
    this.objectDetailsFormGroup.controls.obd_value_type.setValue(1);
  }
  uploadAuditDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedAudiDocumentsArray };
    this.showAuditDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.OD_REVISION_DATE_DOCUMENT,
    };
  }
  uploadsitePlanDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedSitePlanDocumentsArray };
    this.showsitePlanDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 1,
      documentType: UploadTypeConstants.OD_SITE_PLAN,
    };
  }
  showDocumentPopup(): boolean {
    return this.showAuditDocumentPopup || this.showsitePlanDocumentPopup;
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showAuditDocumentPopup) {
      this.obdAuditDocsIds = [];
      this.selectedAudiDocumentsArray = { ...this.selectedDocumentsArray };
      this.showAuditDocumentPopup = false;
      this.objectDetailsFormGroup.controls.obd_audit_doc.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.objectDetailsFormGroup.controls.obd_audit_doc.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.obdAuditDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showsitePlanDocumentPopup) {
      this.obdSitePlanDocsIds = [];
      this.selectedSitePlanDocumentsArray = { ...this.selectedDocumentsArray };
      this.showsitePlanDocumentPopup = false;
      this.objectDetailsFormGroup.controls.obd_site_plan_docs.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.objectDetailsFormGroup.controls.obd_site_plan_docs.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.obdSitePlanDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.objectDetailsFormGroup.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }
  dateClicked(name) {
    if (name == 'recentFactoryVisit') {
      this.recentFactoryVisitDateError = false;
    } else if (name == 'auditDate') {
      this.auditDateError = false;
    } else if (name == 'eliminationDate') {
      this.eliminationDateError = false;
    }
  }
  dateChange(event, name) {
    if (name == 'recentFactoryVisit') {
      this.recentFactoryVisitDateError = this.validation?.dateValidation(event);
    } else if (name == 'auditDate') {
      this.auditDateError = this.validation?.dateValidation(event);
    } else if (name == 'eliminationDate') {
      this.eliminationDateError = this.validation?.dateValidation(event);
    }
  }
  onDateBlur(event, name) {
    const today = moment(this.today, 'DD-MM-YYYY').format('MM/DD/YYYY');
    const enteredDate = event.target.value
      ? moment(event.target.value, 'DD-MM-YYYY').format('MM/DD/YYYY')
      : null;
    const difference = enteredDate
      ? moment(enteredDate).diff(moment(today), 'days')
      : null;

    if (difference == null) {
      switch (name) {
        case 'recentFactoryVisit':
          this.objectDetailsFormGroup.controls.obd_recent_factory_visit.setValue(
            null
          );
          break;
        case 'auditDate':
          this.objectDetailsFormGroup.controls.obd_audit_date.setValue(null);
          break;
        case 'eliminationDate':
          this.objectDetailsFormGroup.controls.obd_elimination_date.setValue(
            null
          );
          break;
      }
    }

    if (difference > 0 || enteredDate === 'Invalid date') {
      event.target.value = '';

      switch (name) {
        case 'recentFactoryVisit':
          this.objectDetailsFormGroup.controls.obd_recent_factory_visit.setValue(
            null
          );
          this.objectDetailsFormGroup.controls.obd_recent_factory_visit.setErrors(
            null
          );
          this.recentFactoryVisitDateError = true;
          break;
        case 'auditDate':
          this.objectDetailsFormGroup.controls.obd_audit_date.setValue(null);
          this.objectDetailsFormGroup.controls.obd_audit_date.setErrors(null);
          this.auditDateError = true;
          break;
        case 'eliminationDate':
          this.objectDetailsFormGroup.controls.obd_elimination_date.setValue(
            null
          );
          this.objectDetailsFormGroup.controls.obd_elimination_date.setErrors(
            null
          );
          this.eliminationDateError = true;
          break;
      }
    }
  }
  currancyChanged(event) {
    this.currancySymbol = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_code;
    this.selectedCurrency = this.currencyTypes.find(
      obj => obj.id == event.value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => {
      this.currencyTypeDropdown.focus();
    }, 200);
  }
  onTypeNewValue(event, key) {
    if (key == 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length == 0) {
        if (charCode == '45' || charCode == '48') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        this.errorNewValue = false;
        if (
          charCode != 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        this.onTypeDecimalNumber(event);
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualnewValue = event.target.value.length;
      } else {
        this.actualnewValue = this.actualnewValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,.]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualnewValue == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }
  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length == 2) {
        event.preventDefault();
      }
    }
  }
  changeNumber(event, name) {
    const count = event.split(',').length - 1;
    if (count > 1) {
      if (name == 'newValue') {
        this.errorNewValue = true;
      } else if (name == 'rentalValue') {
        this.errorRentalValue = true;
      }
    } else {
      if (name == 'newValue') {
        this.errorNewValue = false;
      } else if (name == 'rentalValue') {
        this.errorRentalValue = false;
      }
    }
    const tempValue = event.replaceAll('.', '');
    const splitValue = tempValue.split(',');
    if (splitValue[0].length > 9) {
      if (name == 'newValue') {
        this.errorNewValue = true;
      } else if (name == 'rentalValue') {
        this.errorRentalValue = true;
      }
    } else {
      if (name == 'newValue') {
        this.errorNewValue = false;
      } else if (name == 'rentalValue') {
        this.errorRentalValue = false;
      }
    }
    this._validationSharedService.saveFormError(
      this.errorNewValue || this.errorNewValue1 || this.errorRentalValue
        ? 'true'
        : 'false'
    );
    if (name == 'newValue') {
      this.objectDetailsFormGroup.controls.obd_new_value.setValue(
        this._currencyCustomPipe
          .transform(
            event.toString()?.replaceAll('.', '')?.replaceAll(',', '.')
          )
          ?.replace('€', '')
          .replace(',00', '')
          ?.trim()
      );
    } else if (name == 'rentalValue') {
      this.objectDetailsFormGroup.controls.obd_rental_value.setValue(
        this._currencyCustomPipe
          .transform(
            event.toString()?.replaceAll('.', '')?.replaceAll(',', '.')
          )
          ?.replace('€', '')
          .replace(',00', '')
          ?.trim()
      );
    }
  }
  private _filterModeSearch(value: any) {
    this.operatingModeListFiltered = this.operatingModetempList;
    if (value !== -1) {
      if (
        this.operatingModeList.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.operatingModeListFiltered = this.operatingModeList.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.operatingModeListFiltered = this.operatingModeList;
      }
    } else {
      this.operatingModeListFiltered = this.operatingModeList;
    }
  }
  private _filterCurrency(value: any) {
    this.currencyTypes = this.currencyTypetemp;
    if (value !== '') {
      if (
        this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.currencyTypes = this.currencyTypes.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.currencyTypes = this.currencyTypetemp;
      }
    } else {
      this.currencyTypes = this.currencyTypetemp;
    }
  }
  buildFormObject() {
    const objectDetails: Objectdetails = {
      obd_name: this.objectDetailsFormGroup.value.obd_name,
      obd_currency_id: this.objectDetailsFormGroup.value.obd_currency_id,
      obd_new_value: this.objectDetailsFormGroup.value.obd_new_value
        ? Number(
            this.validation.removeSymbols(
              this.objectDetailsFormGroup.value.obd_new_value
            )
          )
        : null,
      obd_new_value_year: this.objectDetailsFormGroup.value.obd_new_value_year,
      obd_value_type: this.objectDetailsFormGroup.value.obd_value_type,
      obd_type_of_construction: this.objectDetailsFormGroup.value
        .obd_type_of_construction
        ? this.objectDetailsFormGroup.value.obd_type_of_construction
        : null,
      obd_year_of_construction:
        this.objectDetailsFormGroup.value.obd_year_of_construction,
      obd_monument_protection:
        this.objectDetailsFormGroup.value.obd_monument_protection,
      obd_site_plan_docs: this.objectDetailsFormGroup.value.obd_site_plan_docs,
      obd_type_of_use: this.objectDetailsFormGroup.value.obd_type_of_use
        ? this.objectDetailsFormGroup.value.obd_type_of_use
        : null,
      obd_operation_mode: this.objectDetailsFormGroup.value.obd_operation_mode
        ? this.objectDetailsFormGroup.value.obd_operation_mode
        : null,
      obd_operation_mode_ggp: this.objectDetailsFormGroup.value
        .obd_operation_mode_ggp
        ? this.objectDetailsFormGroup.value.obd_operation_mode_ggp
        : null,

      obd_vacancy: this.objectDetailsFormGroup.value.obd_vacancy,
      obd_rental_value: this.objectDetailsFormGroup.value.obd_rental_value
        ? Number(
            this.validation.removeSymbols(
              this.objectDetailsFormGroup.value.obd_rental_value
            )
          )
        : null,
      obd_living_space: this.objectDetailsFormGroup.value.obd_living_space
        ? Number(
            this.validation.removeSymbols(
              this.objectDetailsFormGroup.value.obd_living_space
            )
          )
        : null,
      obd_no_residentail_units: this.objectDetailsFormGroup.value
        .obd_no_residentail_units
        ? Number(this.objectDetailsFormGroup.value.obd_no_residentail_units)
        : null,
      obd_recent_factory_visit: this.objectDetailsFormGroup.value
        .obd_recent_factory_visit
        ? moment(
            this.objectDetailsFormGroup.value.obd_recent_factory_visit
          ).format('YYYY-MM-DD')
        : null,
      obd_type_of_factory_tour: this.objectDetailsFormGroup.value
        .obd_type_of_factory_tour
        ? this.objectDetailsFormGroup.value.obd_type_of_factory_tour
        : null,
      obd_audit_obligation:
        this.objectDetailsFormGroup.value.obd_audit_obligation,
      obd_audit_date: this.objectDetailsFormGroup.value.obd_audit_date
        ? moment(this.objectDetailsFormGroup.value.obd_audit_date).format(
            'YYYY-MM-DD'
          )
        : null,
      obd_audit_doc: this.objectDetailsFormGroup.value.obd_audit_doc,
      obd_defects: this.objectDetailsFormGroup.value.obd_defects
        ? this.objectDetailsFormGroup.value.obd_defects
        : null,
      obd_elimination_date: this.objectDetailsFormGroup.value
        .obd_elimination_date
        ? moment(this.objectDetailsFormGroup.value.obd_elimination_date).format(
            'YYYY-MM-DD'
          )
        : null,
      annotations: this.objectDetailsFormGroup.value.annotations,
      is_annotation: this.objectDetailsFormGroup.value.is_annotation,
    };
    const formValuesToPersist = {
      objectDetails,
    };
    this.request = {
      document_ids: [
        ...new Set([...this.obdAuditDocsIds, ...this.obdSitePlanDocsIds]),
      ],
      criteria_id: BuildingConstants.OBJECT_DETAILS,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.objectDetailsFormGroup.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }

  focusCurrencySearch() {
    document.getElementById('currencySearch').focus();
  }
  focusoperatingModeSearch() {
    document.getElementById('operatingModeSearch').focus();
  }
  valueChanged() {
    this.operatingModeFormControl.reset();
    setTimeout(() => this.operatingModeDropdown.focus(), 150);
  }

  _filter(value: string) {
    this.years = this._objectModuleSharedService.getYears();
    if (value !== '') {
      this.years =
        this.years.filter(option => option.toString().includes(value) === 0) !==
        null
          ? this.years.filter(option => option.toString().includes(value))
          : this._objectModuleSharedService.getYears();
    }
  }

  private _filtercon(value: any) {
    this.yearsCon = this._objectModuleSharedService.getYearsCon();
    if (value !== '') {
      this.yearsCon =
        this.yearsCon.filter(
          option => option.toString().includes(value) === 0
        ) !== null
          ? this.yearsCon.filter(option => option.toString().includes(value))
          : this._objectModuleSharedService.getYearsCon();
    }
  }
  yearSelected() {
    this.yearControl.reset();
    this.years = this._objectModuleSharedService.getYears();
    setTimeout(() => this.newValueDropdown.focus(), 150);
  }
  constructionYearSelected() {
    this.constructionYearFormControl.reset();
    this.yearsCon = this._objectModuleSharedService.getYearsCon();
    setTimeout(() => this.constructionYearDropdown.focus(), 150);
  }
  scrollNewValue() {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById('searchYear1').focus();
  }
  scrollConstructionYearValue() {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById('searchYear2').focus();
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  typeOfUseChanged(event) {
    if (event.value == 1) {
      this.objectDetailsFormGroup.get('obd_living_space').enable();
      this.objectDetailsFormGroup.get('obd_no_residentail_units').enable();
    } else {
      this.objectDetailsFormGroup.get('obd_living_space').disable();
      this.objectDetailsFormGroup.get('obd_no_residentail_units').disable();
      this.objectDetailsFormGroup.controls.obd_living_space.setValue(null);
      this.objectDetailsFormGroup.controls.obd_no_residentail_units.setValue(
        null
      );
    }
  }
  get getValue() {
    if (
      this.objectDetailsFormGroup.value.obd_value_type &&
      this.valueTypes &&
      this.valueTypes.length
    ) {
      return this.valueTypes.filter(
        _ => _.id === this.objectDetailsFormGroup.value.obd_value_type
      )[0]?.name;
    }
    return '';
  }
  showToastMessage(message) {
    const x = document.getElementById('successToast');
    if (x) {
      x.textContent = this.translate.instant(message);
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }
  auditObligationChange(value: boolean) {
    if (value) {
      this.objectDetailsFormGroup.get('obd_defects').enable();
      this.objectDetailsFormGroup.get('obd_audit_date').enable();
      this.objectDetailsFormGroup.get('obd_audit_doc').enable();
      this.objectDetailsFormGroup.get('obd_elimination_date').enable();
    } else {
      this.objectDetailsFormGroup.get('obd_defects').disable();
      this.objectDetailsFormGroup.get('obd_audit_date').disable();
      this.objectDetailsFormGroup.get('obd_audit_doc').disable();
      this.objectDetailsFormGroup.get('obd_elimination_date').disable();
      this.objectDetailsFormGroup.get('obd_defects').setValue(null);
      this.objectDetailsFormGroup.get('obd_audit_date').setValue(null);
      this.objectDetailsFormGroup.get('obd_audit_doc').setValue(null);
      this.objectDetailsFormGroup.get('obd_elimination_date').setValue(null);
    }
  }
}
