import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventSpace]'
})
export class PreventSpaceDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}
