<div class="modal background-theme" id="modal" [style.display]="'block'" style="z-index: 999;overflow: auto;">
    <div class="modal-dialog pdf-dialog" style="width: 1225px;max-width: 2000px;">
        <div class="modal-content" style="border:none">
            <!-- Modal Header -->
            <div class="modal-header"
                style="background: #2175BD;border-top-left-radius: 0;border-top-right-radius: 0;border-bottom: none">
                <button style="background: none;color: #fff;border: none;outline: none;" type="button" type="back"
                    (click)="backPage()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <button style="background: none;color: #fff;border: none;outline: none;" type="button" type="back" *ngIf="via !== 'policyTemplate'">
                    <a style="color: #fff;text-decoration: none;" [href]="pdfLink" target="self" download="pdfLink">
                        <mat-icon>file_download</mat-icon>
                    </a>
                </button>
                <button style="background: none;color: #fff;border: none;outline: none;" type="button" type="back" *ngIf="via == 'policyTemplate'">
                    <a style="color: #fff;text-decoration: none;" target="self" (click)="download(data,pdfName)">
                        <mat-icon>file_download</mat-icon>
                    </a>
                </button>
                <button style="background: transparent;" type="button" class="close" data-dismiss="modal"
                    (click)="closePDF()">
                    <img alt="" style="vertical-align: sub;" src="../../../../assets/images/close icon .svg">
                </button>
            </div>

            <!-- Modal body -->
            <div class="modal-body" style="padding: 0px 1rem 0px 0px;border-right: 32px solid #2175BD;">
                <div class="pages pages-body" id="pages"
                    style="float: left;width: 20%;text-align: center;padding: 0px 53px 53px 53px;background-color: #2175BD;overflow:auto;">
                    <div *ngFor="let item of thumbnail" id="thumbnail{{item.page}}">
                        <img alt="" (click)="pageRendered(item.page)"
                            [ngClass]="[item.page == 1 ? 'pageSideView1' : 'pageSideViewAll']" src="{{item.url}}">
                        <p style="color: #fff;margin-top:10px">{{item.page}}</p>
                    </div>
                </div>
                <div class="pdfView" id="pdfView" style="overflow: scroll;">
                    <pdf-viewer style="display: block;float: left;width: 100%;margin-top: -10px;margin-right: -137px;"
                        [src]="pdfLink" [original-size]="false" [render-text]="false" [fit-to-page]="true"
                        (page-rendered)="pageRendered($event)" [show-all]="true">
                    </pdf-viewer>
                </div>
                <ngx-loading [show]="loading"
                    [config]="{animationType: ngxLoadingAnimationTypes.rectangleBounce, primaryColour: secondaryColour, backdropBorderRadius: '3px',fullScreenBackdrop: true}">
                </ngx-loading>
            </div>
        </div>
    </div>
</div>