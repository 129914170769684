import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
  API_CONST,
  ERRORS,
  FORM_METHODS,
  STATIC_TEXT,
} from '@shared/constants/string.constants';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { LOCALS } from '@shared/constants/local.constants';
import { BrowserService } from '@core/services/validations/browser-service.service';
import { NumberValidationsService } from '@core/services/validations/number-validations.service';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import {
  CLAIM_CONTACT_PERSON_FIELDS,
  CLAIM_CP_WARNINIG,
  ROLES_IDS,
} from '../../constants/claim.constants';
import { BaseComponent } from '@shared/components/base.component';
import { UserService } from '@core/user.service';
import { AddContactPesonRequest } from '@shared/models/contact-person.model';
import { ActivatedRoute } from '@angular/router';
import { ClaimsShareService } from '@modules/new-claims/claims-share.service';

@Component({
  selector: 'app-add-contact-person',
  templateUrl: './add-contact-person.component.html',
  styleUrls: ['./add-contact-person.component.scss'],
})
export class AddContactPersonComponent extends BaseComponent implements OnInit {
  @Input() contactPersonId: string;
  @Input() addContactPerson: boolean;

  @Output() message = new EventEmitter<string>();
  @Output() isContactPersonAdd = new EventEmitter<boolean>();
  @Output() isContactPersonAddEdit =
    new EventEmitter<boolean>();
  contactPersonForm: FormGroup;
  contactPersonData: any;
  customer_roles = [];
  salutations = [];
  readonly locale$ = this._userService.locale$;
  showWarningPopUp = false;
  customerWarningMsg: CustomerWarningMsg;
  currancySymbol = null;
  currencyTypes: any = [];
  selectedCurrency: any;
  currencyId: any;
  errorConstant = ERRORS;
  localsConstant = LOCALS;
  formFieldsName = CLAIM_CONTACT_PERSON_FIELDS;
  isValueChangedInForm = false;
  initialFormState: any;
  isIvmSynced: boolean;

  constructor(
    private _http: ApiCallsService,
    public _validationService: ValidationService,
    public _browserService: BrowserService,
    public _numberValidationService: NumberValidationsService,
    private _claimsApi: ClaimsManagementApiService,
    private readonly _userService: UserService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _claimsSharedService: ClaimsShareService
  ) {
    super();
    this.getCurrencyValue();
    this.customerWarningMsg = {
      cancel: CLAIM_CP_WARNINIG.CANCEL,
      ok: CLAIM_CP_WARNINIG.OK,
      save_and_continue: CLAIM_CP_WARNINIG.SAVE,
      header: CLAIM_CP_WARNINIG.HEADING,
      body: CLAIM_CP_WARNINIG.SUBHEADING,
    };
  }
  ngOnInit(): void {
    this._watchOnIvmChanging();
    const requests = [];
    requests.push(
      this._http
        .getProductLookups(API_CONST.CP_CLAIM_ROLES)
        .pipe(catchError(error => of(error)))
    );
    requests.push(
      this._http.getSalutation().pipe(catchError(error => of(error)))
    );
    if (this.contactPersonId) {
      requests.push(
        this._http
          .getClaimConntactPersonById(+this.contactPersonId)
          .pipe(catchError(error => of(error)))
      );
    }
    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        if (!data[0].hasErrors && data[0].data) {
          this.setCustomerRoles(data[0]);
        } else {
          this.customer_roles = [];
        }
        this.salutations =
          !data[1].hasErrors && data[1].data ? data[1].data : [];
        this.salutations = this.salutations.filter(
          ele => ele.salutation_id != 3
        );
        if (this.contactPersonId) {
          this.contactPersonData =
            !data[2].hasErrors && data[2].data ? data[2].data : null;
        }
        this.initializeForm();
      });
  }
  initializeForm(): void {
    const formData = this.contactPersonData || null;
    if (formData?.currency_type_id) {
      this.currancyChanged(formData?.currency_type_id);
    }
    this.contactPersonForm = new FormGroup({
      [CLAIM_CONTACT_PERSON_FIELDS.SALUTATION]: new FormControl({
        value: formData?.salutation_id || null,
        disabled: formData?.is_payee_present ? true : false,
      }),
      [CLAIM_CONTACT_PERSON_FIELDS.FIRST_NAME]: new FormControl({
        value: formData?.first_name || STATIC_TEXT.EMPTY_STRING,
        disabled: formData?.is_payee_present ? true : false,
      }),
      [CLAIM_CONTACT_PERSON_FIELDS.LAST_NAME]: new FormControl({
        value: formData?.last_name || STATIC_TEXT.EMPTY_STRING,
        disabled: formData?.is_payee_present ? true : false,
      }),
      [CLAIM_CONTACT_PERSON_FIELDS.ROLE]: new FormControl({
        value: formData?.role_id || null,
        disabled: formData?.is_payee_present ? true : false,
      }),
      [CLAIM_CONTACT_PERSON_FIELDS.RECEIVABLE_AMOUNT]: new FormControl(
        (formData &&
          this._numberValidationService.convertIntToGermanFormatWithDecimal(
            formData.receivable_amount
          )) ||
          null
      ),
      [CLAIM_CONTACT_PERSON_FIELDS.RECEIVABLE_AMOUNT_CURRENCY]: new FormControl(
        formData?.currency_type_id || null
      ),
      [CLAIM_CONTACT_PERSON_FIELDS.CLAIM_DESCRIPTION]: new FormControl(
        formData?.claim_description || null
      ),
      [CLAIM_CONTACT_PERSON_FIELDS.REMARK]: new FormControl(
        formData?.remark || STATIC_TEXT.EMPTY_STRING
      ),
    });
    this.initialFormState = this.contactPersonForm.value;
    const nonClaimant = formData && formData?.role_id != ROLES_IDS.CLAIMANT;
    this.roleChanged(formData?.role_id, nonClaimant);
    this.contactPersonForm?.valueChanges
      ?.pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isValueChangedInForm =
          JSON.stringify(this.initialFormState) !=
          JSON.stringify(this.setControls());
      });
    if (this.addContactPerson) {
      this.contactPersonData = null;
    }
  }
  setControls() {
    const controlValues = {};
    Object.keys(this.contactPersonForm.controls).forEach(control => {
      controlValues[control] = this.contactPersonForm.get(control).value;
    });
    return controlValues;
  }
  close(): void {
    this.isContactPersonAddEdit.emit(false);
    this.isContactPersonAdd.emit(false);
    this.message.emit(null);
    this.contactPersonData = null;
  }
  save(): void {
    const userId = +this._userService.getUserId();
    const request: AddContactPesonRequest = {
      id: this.contactPersonData?.id || null,
      salutation_id:
        this.contactPersonForm.controls[CLAIM_CONTACT_PERSON_FIELDS.SALUTATION]
          .value,
      first_name:
        this.contactPersonForm.controls[CLAIM_CONTACT_PERSON_FIELDS.FIRST_NAME]
          .value || STATIC_TEXT.EMPTY_STRING,
      last_name:
        this.contactPersonForm.controls[CLAIM_CONTACT_PERSON_FIELDS.LAST_NAME]
          .value || STATIC_TEXT.EMPTY_STRING,
      name: null,
      role_id:
        this.contactPersonForm.controls[CLAIM_CONTACT_PERSON_FIELDS.ROLE].value,
      currency_type_id: this.currencyId,
      receivable_amount: this._numberValidationService.convertGermanToInt(
        this.contactPersonForm.controls[
          CLAIM_CONTACT_PERSON_FIELDS.RECEIVABLE_AMOUNT
        ].value
      ),
      remark:
        this.contactPersonForm.controls[CLAIM_CONTACT_PERSON_FIELDS.REMARK]
          .value,
      claim_description:
        this.contactPersonForm.controls[
          CLAIM_CONTACT_PERSON_FIELDS.CLAIM_DESCRIPTION
        ].value || STATIC_TEXT.EMPTY_STRING,
      claim_id: +atob(this._activatedRoute.snapshot.queryParams.claim),
      liztu_sa_idnr: +this._claimsSharedService.getLiztuSa(),
      created_by: this.contactPersonData?.id ? null : userId,
      updated_by: this.contactPersonData?.id ? userId : null,
      deleted_by: null,
    };
    this._claimsApi
      .addClaimContactPerson(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this.isContactPersonAddEdit.emit(false);
        this.message.emit(response.message);
        this.contactPersonData = null;
        if (!request.id) {
          this.isContactPersonAdd.emit(false);
        }
      });
  }
  focusRoleSearch(): void {
    document.getElementById('roleSearch').focus();
  }
  setCustomerRoles(data): void {
    this.customer_roles = data.data;
  }
  warningPopupGuard(): void {
    this.showWarningPopUp = true;
  }
  roleChanged(role_id, is_reset): void {
    if (role_id != ROLES_IDS.CLAIMANT) {
      if (is_reset) {
        this.manipulateFields(FORM_METHODS.RESET);
      }
      this.manipulateFields(FORM_METHODS.DISABLE);
      this.currancyChanged(null);
    } else {
      this.manipulateFields(FORM_METHODS.ENABLE);
    }
  }
  manipulateFields(methodName: string) {
    const fields = [
      CLAIM_CONTACT_PERSON_FIELDS.RECEIVABLE_AMOUNT,
      CLAIM_CONTACT_PERSON_FIELDS.RECEIVABLE_AMOUNT_CURRENCY,
      CLAIM_CONTACT_PERSON_FIELDS.CLAIM_DESCRIPTION,
    ];
    fields.forEach(field => {
      if (
        typeof this.contactPersonForm?.get(field)[methodName] ===
        STATIC_TEXT.FUNCTION
      ) {
        this.contactPersonForm?.get(field)[methodName]();
      }
    });
  }
  handleWarningPopup(event: boolean): void {
    this.showWarningPopUp = false;
    !event ? this._claimsSharedService.setEventImvChanging(false) : this.close();
  }
  saveContactPerson(event: boolean): void {
    if (event) {
      this.showWarningPopUp = false;
      this.save();
    }
  }
  getCurrencyValue(): void {
    this._http
      .getCurrencyTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.currencyTypes = data?.currency_types;
        this.currancyChanged(null);
      });
  }
  currancyChanged(event): void {
    this.currencyId =
      event ||
      this.currencyTypes?.find(obj => obj.cur_symbol == STATIC_TEXT.EUR)?.id;
    this.currancySymbol = this.currencyTypes?.find(
      obj => obj.id == this.currencyId
    )?.cur_code;
    this.selectedCurrency = this.currencyTypes?.find(
      obj => obj.id == this.currencyId
    )?.cur_symbol;
  }

  /**
   * @param value actual string value
   * @param from formControlName
   */
  validateName(value: string, from: string) {
    const validate = this._validationService?.validateName(value);
    this._validationService.setRemoveError(
      validate,
      this.contactPersonForm,
      from
    );
  }
  disableSave(): boolean {
    return (
      this.isIvmSynced ||
      !this.contactPersonForm.valid ||
      !this.isValueChangedInForm
    );
  }

  private _watchOnIvmChanging(): void {
    this._claimsSharedService.isIvmSynced$.pipe(takeUntil(this._destroy$)).subscribe(value => {
      this.isIvmSynced = value;
      if (value) {
        this.contactPersonForm?.dirty ? this.warningPopupGuard() : this.close();
      }
    });
  }
}
