import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, takeUntil } from 'rxjs/operators';
import * as _moment from 'moment';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

import { ROUTES_PATH } from '@shared/constants/router.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { ROLES } from '@shared/constants/roles.constants';
import { BaseComponent } from '@shared/components/base.component';
import {
  DocumentInformation,
  DocumentSource,
} from '@shared/models/document-management.model';
import { GetSecuredDocumentRequest } from '@shared/models/upload-document.model';
import { UserService } from '@core/user.service';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';

export interface SendingNewDocumentElement {
  division_id: string;
  customer_id: string;
  role: string;
  source: string;
  fetch_all: boolean;
  parent_id: number;
  is_source_ivm: boolean;
  customer_type: string;
  user_idnr: number;
  liztu_vn_kdnr: number;
  login_customer?: number;
  user_id: number;
  document_source: string;
}
export interface fetchingNewDocumentElement {
  contract_id: any;
  customer_id: any;
  liztu_vn_kdnr: any;
  liztu_vs_idnr: any;
  liztu_sa_idnr: any;
  document_id: any;
  document_type: any;
  document_description: any;
  document_source: any;
  contract_number: any;
  upload_date: any;
  claims_id: any;
  sortingDate: number;
  product_name: any;
  uuid: string;
  content_type: string;
}

@Component({
  selector: 'app-new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.css'],
})
export class NewDocumentComponent extends BaseComponent implements OnInit {
  customerFilterToggle = true;
  customFilterExpandedHeight = '55px';
  sortDir = 1;
  isDisabled = true;
  columnSorting = {
    contract_number: true,
  };
  moment = _moment;
  dateSorted = false;
  filterForm: FormGroup;
  newDate;
  document: any;
  documentData = [];
  newDocument: any;
  newDocumentData = [];
  pageNumber: number;
  showPDF: boolean;
  pdfPageNum;
  pdfPageArray = [];
  loading = true;
  pdfLink;
  pageVariable = 0;
  thumbnail = [];
  element: HTMLCollectionOf<Element>;
  public secondaryColour = '#d6d1d170';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  displayedColumns = [
    'contract_number',
    'product_name',
    'document_type',
    'Date',
    'Documentbeschreibung',
    'ACTION',
  ];
  dataSource = new MatTableDataSource(this.documentData);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pipe: DatePipe;
  documentDataCount: number;
  isCustomerUser = false;
  divisionName: string;
  formattedVon: string;
  formattedBis: string;
  filterValues: boolean;
  is_source_ivm: boolean;
  newDate1;
  readonly selectLang$ = this._userService.locale$;
  user_idnr: number;
  userDataValue: any;
  customer_id: string;
  type: string;
  todate: any;
  pageBreadcrumbText = 'customer-dashboard.Weitere_Dokumente';
  isCustomerSelected = true;
  blob: string;
  customerName$ = this._customerSharedService.selectedCustomerName$;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private route: ActivatedRoute,
    private readonly _documentsApiService: DocumentManagementApiService,
    private readonly _userService: UserService,
    private readonly _serviceCall: ApiCallsService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();

    this.dataSource.filterPredicate = ((data, filter) => {
      const finalDate: any = data;
      this.newDate1 = finalDate.upload_date.split(' ')[0];
      if (
        this.toDate.value == '' ||
        this.toDate.value == null ||
        this.toDate.value == undefined
      ) {
        this.todate = new Date();
      } else {
        this.todate = this.toDate.value;
      }
      this.pipe = new DatePipe('en');
      this.formattedVon = this.pipe.transform(
        this.fromDate.value,
        'yyyy-MM-dd'
      );
      this.formattedBis = this.pipe.transform(this.todate, 'yyyy-MM-dd');
      this.filterValues = data.document_type === filter.docType;

      if (
        filter.docType !== '' &&
        filter.von !== '' &&
        this.formattedBis !== '' &&
        filter.docType !== '1' &&
        filter.docType !== null &&
        filter.von !== null &&
        filter.bis !== null &&
        filter.docType !== null
      ) {
        this.isDisabled = false;
        return (
          this.filterValues &&
          this.newDate1 >= this.formattedVon &&
          this.newDate1 <= this.formattedBis
        );
      } else if (
        filter.von !== '' &&
        this.formattedBis !== '' &&
        filter.docType == '1'
      ) {
        this.isDisabled = false;
        return (
          this.newDate1 >= this.formattedVon &&
          this.newDate1 <= this.formattedBis
        );
      } else if (
        filter.docType !== '' &&
        filter.docType !== '1' &&
        filter.docType !== null &&
        filter.docType !== null
      ) {
        this.isDisabled = false;
        return this.filterValues;
      }
    }) as (PeriodicElement, string) => boolean;
    this.filterForm = this.fb.group({
      contractSelect: ['contract'],
      docType: ['1'],
      bis: [''],
      von: [''],
    });
  }

  ngOnInit(): void {
    this._watchOnRole();
  }

  userDataReceived(data): void {
    this.userDataValue = data;
    this.divisionName = data.user_profile.customer_details.name;

    this._watchOnCustomer();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  renderTableData(): void {
    this.dataSource.data = [...this.documentData];
    this.changeDetectorRefs.detectChanges();
  }

  sortByDate(): void {
    if (this.dateSorted) {
      this.documentData.sort((a, b) =>
        a.sortingDate > b.sortingDate ? 1 : -1
      );
    } else {
      this.documentData.sort((a, b) =>
        a.sortingDate < b.sortingDate ? 1 : -1
      );
    }
    this.dateSorted = !this.dateSorted;

    this.renderTableData();
  }

  get contractSelect(): AbstractControl {
    return this.filterForm.get('contractSelect');
  }

  get docType(): AbstractControl {
    return this.filterForm.get('docType');
  }

  get toDate(): AbstractControl {
    return this.filterForm.get('bis');
  }

  get fromDate(): AbstractControl {
    return this.filterForm.get('von');
  }

  getFormsValue(value): void {
    this.dataSource.filter = '';
    const filter = { ...value } as string;
    this.dataSource.filter = filter;
  }

  getDocumentData(source: DocumentSource, customerId: number): void {
    this.is_source_ivm =
      this.userDataValue.user_profile.user_data.is_source_ivm;
    this.user_idnr = this.userDataValue.user_profile.user_data.user_idnr;

    if (!this.type) {
      this.type = this.userDataValue.user_profile.customer_details.type;
    }

    this._userService.userId$
      .pipe(
        filterNil(),
        mergeMap(userId =>
          this._documentsApiService.customerDocuments(
            +userId,
            customerId,
            source,
            true
          )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(data => {
        this.formateDocumentData(data.documents);
      });
  }

  formateDocumentData(newDocumentData: DocumentInformation[]): void {
    this.documentData = [];
    this.dataSource.data = [];
    if (newDocumentData !== null) {
      for (let i = 0; i < newDocumentData.length; i++) {
        const sortingDate = +newDocumentData[i].uploadDate.split('-').join('');
        const documentData: fetchingNewDocumentElement = {
          contract_id: newDocumentData[i].contractId,
          customer_id: newDocumentData[i].customerId,
          liztu_vn_kdnr: newDocumentData[i].liztuVnKdnr,
          liztu_vs_idnr: newDocumentData[i].liztuVsIdnr,
          liztu_sa_idnr: newDocumentData[i].liztuSaIdnr,
          document_id: newDocumentData[i].documentId,
          document_type: newDocumentData[i].documentType,
          document_description: newDocumentData[i].description,
          document_source: newDocumentData[i].documentSource,
          contract_number: newDocumentData[i].policyNumber,
          upload_date: newDocumentData[i].uploadDate,
          sortingDate,
          claims_id: newDocumentData[i].claimsId,
          product_name: newDocumentData[i].productName,
          uuid: newDocumentData[i].uuid,
          content_type: newDocumentData[i].content_type,
        };
        this.documentData.push(documentData);
        this.newDate = documentData.upload_date;
        this.newDate = this.newDate.split(' ')[0];
      }
      this.dataSource.data = [...this.documentData];
    } else {
      this.documentData = [];
      this.dataSource.data = [];
    }
  }

  doctypeSelected(): void {
    this.isDisabled = false;
  }

  vonSelected(): void {
    this.isDisabled = false;
  }

  bisSelected(): void {
    this.isDisabled = false;
  }

  documentSelected(event): void {
    this.document = event.target.value;
    !this.customer_id
      ? this.getDocumentData(
          this.document,
          this.userDataValue.user_profile.customer_details.customer_id
        )
      : this.getDocumentData(this.document, +this.customer_id);
    if (this.document == event.target.value) {
      this.filterForm.controls.von.reset();
      this.filterForm.controls.bis.reset();
      this.filterForm.controls.docType.reset();
      this.filterForm.get('docType').setValue('1');
    }
  }
  pdfViewer(doc_array) {
    const data: GetSecuredDocumentRequest = {
      uuid: doc_array.uuid,
    };
    this._serviceCall
      .getSecureDocument(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (doc_array.content_type == 'pdf') {
          const fileURL = window.URL.createObjectURL(data);
          this.showPDF = true;
          this.pdfLink = fileURL;
          this.blob = data;
        } else {
          const objectURL = window.URL.createObjectURL(data);
          window.open(objectURL);
        }
      });
  }
  closePDF(eventValue) {
    this.showPDF = eventValue;
  }

  resetFilter(): void {
    this.isDisabled = true;
    this.filterForm.reset();
    this.filterForm.get('contractSelect').setValue('contract');
    this.filterForm.get('docType').setValue('1');

    !this.customer_id
      ? this.getDocumentData(
          DocumentSource.CONTRACT,
          this.userDataValue.user_profile.customer_details.customer_id
        )
      : this.getDocumentData(DocumentSource.CONTRACT, +this.customer_id);
    this.document = 'contract';
    this.dataSource.filter = '';
  }

  navigateToContract(liztu_vn_kdnr, liztu_vs_idnr): void {
    const liztu_vn = liztu_vn_kdnr;
    const enc_liztu_vn_kdnr = btoa(liztu_vn);
    const liztu_vs = liztu_vs_idnr;
    const enccoded_contract = btoa(liztu_vs);
    this.router.navigate([ROUTES_PATH.CONTRACT], {
      queryParams: {
        liztu: enc_liztu_vn_kdnr,
        contract: enccoded_contract,
      },
    });
  }

  navigateToClaims(customer_id, liztusaidnr, liztuvnkdnr, source, date): void {
    const customer = btoa(customer_id);
    const liztu_sa_idnr = btoa(liztusaidnr);
    const liztu_vn_kdnr = btoa(liztuvnkdnr);
    const src = source;
    const enc_src = btoa(src);
    const enc_date = btoa(date);
    this.router.navigate([ROUTES_PATH.CLAIMS], {
      queryParams: {
        customer,
        liztu_sa_idnr,
        contract: liztu_vn_kdnr,
        source: enc_src,
        date: enc_date,
        type: btoa(this.type),
        user: btoa(this.user_idnr?.toString()),
      },
    });
  }
  downloadPdf(doc_array) {
    const data: GetSecuredDocumentRequest = {
      uuid: doc_array.uuid,
    };
    this._serviceCall
      .getSecureDocument(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data) {
          const newBlob = new Blob([data], { type: 'application/pdf' });
          const downloadLink = document.createElement('a');
          downloadLink.target = '_self';
          const fileName = 'document.pdf';
          const data1 = window.URL.createObjectURL(newBlob);
          downloadLink.href = data1;
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      });
  }

  private _watchOnRole(): void {
    this._userService.userRole$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(role => {
        this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(role);
      });
  }

  private _watchOnCustomer(): void {
    this._customerSharedService.customerGeneralData$
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.customer_id = data?.customer_info?.customer_id;
        this.type = data?.customer_status?.type;

        this.dataSource.sort = this.sort;
        !this.customer_id
          ? this.getDocumentData(
              DocumentSource.CONTRACT,
              this.userDataValue.user_profile.customer_details.customer_id
            )
          : this.getDocumentData(DocumentSource.CONTRACT, +this.customer_id);
        this.document = 'contract';
      });
  }
}
