export enum TABLE_ACTIONS_KEYS {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  COMPLETE = 'COMPLETE',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  DOWNLOAD = 'DOWNLOAD'
}

export enum ColumnType {
  STRING = 'STRING',
  ACTION = 'ACTION',
  COMPONENT = 'COMPONENT',
}

export const TABLE_ACTION_LABEL = {
  EDIT: 'table_action.edit',
  DELETE: 'table_action.delete',
  PAYMENT_DETAILS: 'claims.payment_details',
  DOWNLOAD: 'table_action.download',
};
