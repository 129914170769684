import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GenericAlertComponent } from '@shared/components/generic-components/generic-alert-dialog/generic-alert-dialog.component';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { StepperLookupConstants } from '@shared/constants/product-management.constants';
import {
  ConsolidateData,
  ProductSaveRequest,
} from '@shared/models/product-management.model';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-damage-type-stepper',
  templateUrl: './damage-type-stepper.component.html',
  styleUrls: ['./damage-type-stepper.component.css'],
})
export class DamageTypeStepperComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Output() emitFormValue = new EventEmitter<any>();
  @Output() saveFormValue = new EventEmitter<any>();
  @Input() refreshStepData: boolean;
  @Output() isProductdeleteSuccess = new EventEmitter<any>();

  @Input() step;
  @Input() consolidateData: ConsolidateData;
  @Output() updatedNodeSelection = new EventEmitter<any>();

  coverBundleconsolidateData: ConsolidateData;
  loadTreeData = false;
  productId: number;
  productName: string;

  isAddNewStepEnabled: boolean;
  showWarningPopUp: boolean;
  isFormTouched: boolean;
  damageNodeData;
  buttonText: string;
  currentForm: ProductSaveRequest;
  isTreeDisabled = false;

  constructor(
    private translate: TranslateService,
    private serviceCall: ApiCallsService,
    private dialog: MatDialog
  ) {
    super();
    this.buttonText = this.translate.instant(
      'product-management.product_module_stepper_lables.Add_new_Damage'
    );
  }

  ngOnInit(): void {
    this.unZipData();
    this.buildData();
    this.isAddNewStepEnabled = false;
    this.isFormTouched = false;
    this.showWarningPopUp = false;
    this.loadTreeData = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshStepData && this.productId) {
      this.buildData();
    }
  }

  updateNodeSelection(selection) {
    this.updatedNodeSelection.emit(selection);
  }

  addNewNode(event) {
    this.isAddNewStepEnabled = false;
    if (event.isResetNode) {
      this.isAddNewStepEnabled = false;
    } else if (event.isUpdate) {
      this.consolidateData.selectedNodeForEditId = event.request.itemId;
      this.processNode(
        event.request.parentNode,
        true,
        event.request.sequence_number
      );
    } else {
      this.processNode(event.request, false, event.request.sequence_number);
    }
  }

  deleteNode(itemId) {
    this.serviceCall
      .deleteStepperNodeData(itemId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.isProductdeleteSuccess.emit(true);
          this.showToastMessage(
            this.translate.instant(
              'product-management.mask_generator.element_deleted_successfully'
            )
          );
        } else {
          this.showToastMessage(
            this.translate.instant(
              'product-management.mask_generator.failed_to_delete_element'
            )
          );
        }
        this.buildData();
      });
  }

  showToastMessage(message) {
    const x = document.getElementById('successToast');
    if (x) {
      x.textContent = message;
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }

  processNode(event, isUpdate: boolean, sequenceNumber: number) {
    this.consolidateData.stepperLookUpId =
      StepperLookupConstants.TYPES_OF_DAMAGE;
    this.consolidateData.stepperformDetailId = event.itemId;
    this.consolidateData.productCoveringId = event.itemId;
    this.consolidateData.productCoveringName = event.item;
    this.consolidateData.productCoverBundleId = event.parentNode.itemId;
    this.consolidateData.productCoverBundleName = event.parentNode.item;
    this.consolidateData.productModuleId = event.parentNode.parentNode.itemId;
    this.consolidateData.productModuleName = event.parentNode.parentNode.item;
    this.consolidateData.isUpdate = isUpdate;
    this.consolidateData.sequence_number = isUpdate
      ? sequenceNumber
      : (event.children &&
          event.children.length &&
          event.children.filter(child => child.itemId > 0).length) ||
        0;
    this.coverBundleconsolidateData = { ...this.consolidateData };
    this.isAddNewStepEnabled = true;
    this.isFormTouched = false;
  }

  unZipData() {
    this.coverBundleconsolidateData = { ...this.consolidateData };
    this.productId = this.consolidateData.productId;
    this.productName = this.consolidateData.productName;
    if (
      this.consolidateData &&
      [2, 3].includes(this.consolidateData.productStateId)
    ) {
      this.isTreeDisabled = true;
    } else {
      this.isTreeDisabled = false;
    }
  }

  buildData() {
    this.serviceCall
      .getProductSpecificStepperData(
        this.productId,
        StepperLookupConstants.COVERINGS
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.damageNodeData = data.data;
          this.loadTreeData = true;
        }
      });
  }

  formValues(formValue: ProductSaveRequest) {
    this.isFormTouched = formValue.isFormTouched;
    this.currentForm = formValue;
    this.emitFormValue.emit(formValue);
  }

  handlePopup(event) {
    this.showWarningPopUp = false;
    this.isAddNewStepEnabled = event;
    if (!event) {
      const tempForm = Object.assign(this.currentForm);
      tempForm.isFormTouched = false;
      this.emitFormValue.emit(tempForm);
    }
  }

  closeForm() {
    if (!this.isFormTouched) {
      this.isAddNewStepEnabled = false;
    } else {
      this.showWarningPopUp = true;
    }
  }
  saveForm() {
    if (this.isFormTouched) {
      if (!this.currentForm.fromValues.name) {
        this.validateForm();
      } else {
        this.currentForm.isFormTouched = false;
        this.saveFormValue.emit(this.currentForm);
        this.isAddNewStepEnabled = false;
      }
    }
  }

  validateForm() {
    this.formValidMessage();
  }

  formValidMessage() {
    this.dialog.open(GenericAlertComponent, {
      width: '40%',
      panelClass: 'genericAlert',
      data: {
        message: this.translate.instant(
          'product-management.validation_message'
        ),
      },
      disableClose: false,
    });
  }
}
