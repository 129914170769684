import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelect } from '@angular/material/select';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { StepperLookupConstants } from '@shared/constants/product-management.constants';
import {
  AddProductModuleRequest,
  ConsolidateData,
  ProductSaveRequest,
} from '@shared/models/product-management.model';
import { UserService } from '@core/user.service';
import { CurrencyCustomPipe } from '@shared/pipes/currency-custom.pipe';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stepper-form',
  templateUrl: './stepper-form.component.html',
  styleUrls: ['./stepper-form.component.css'],
})
export class StepperFormComponent extends BaseComponent implements OnInit {
  stepperForm: FormGroup;
  @Input('step') stepIndex;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Input('consolidateData') consolidateData: ConsolidateData;

  selectedCoverBundleName: string;
  selectedCoveringName: string;
  selectedDamageType: string;
  productModuleName: string;

  coverBundleTypes = [];
  typeOfDamage = [];
  typeOfDeductible = [];
  ProductModuleLookup = [];
  CoverBundleLookup = [];
  CoveringLookup = [];
  browser: string;
  readonly selectLang$ = this._userService.locale$;
  deductibleAmountError1 = false;
  deductibleAmountError2 = false;
  minDeductibleAmountError1 = false;
  minDeductibleAmountError2 = false;
  allCurrencyValues: any = [];
  allValueTypes: any = [];
  @ViewChild('currencyTypeMinDeductibleAmount', { static: true })
  currencyTypeMinDeductibleAmount: MatSelect;
  @ViewChild('currencyTypeAverageStock', { static: true })
  currencyTypeAverageStockDropdown: MatSelect;
  currancySymbol = null;
  currencyType = new FormControl();
  currencyTypetemp: any;
  selectedCurrency: any;
  minDeductibleAmountError: boolean;
  actualneuValue = 0;
  maxDeductibleAmountError1: boolean;
  maxDeductibleAmountError2: boolean;
  nodeIdToUpdate: number;
  disableField = false;
  formError = false;
  is_auto_fill_data_enabled = false;
  maximizationtype = [];
  SBType = [];
  mask_gen_config_data: any;
  mask_gen_config_data_jsonb: any;

  constructor(
    private serviceCall: ApiCallsService,
    private _currencyCustomPipe: CurrencyCustomPipe,
    public validation: ValidationService,
    private readonly _userService: UserService
  ) {
    super();
    this.browser = this.detectBrowser();
    this.serviceCall
      .getCurrenciesValue()
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.allCurrencyValues = data.currency_types;
          this.currencyTypetemp = Object.assign([], this.allCurrencyValues);
          const currencySelected = this.allCurrencyValues.find(
            obj => obj.cur_symbol == 'EUR'
          );
          this.currancySymbol = currencySelected && currencySelected.cur_code;
          this.selectedCurrency =
            currencySelected && currencySelected.cur_symbol;
          const tempId = currencySelected && currencySelected.id;
          this.stepperForm.controls.currency_type_id.setValue(tempId);
        }
      });
    this.currencyType.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterCurrency(value);
      });
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnInit(): void {
    this.disableField = false;
    this.initializeForm();
    this.unZipData();
    this.callAPIs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consolidateData && this.stepperForm) {
      this.initializeForm();
      this.unZipData();
      this.callAPIs();
    }
  }

  private _filterCurrency(value: any) {
    this.allCurrencyValues = this.currencyTypetemp;

    if (value !== '') {
      if (
        this.allCurrencyValues.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.allCurrencyValues = this.allCurrencyValues.filter(option =>
          option.cur_symbol.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.allCurrencyValues = this.currencyTypetemp;
      }
    } else {
      this.allCurrencyValues = this.currencyTypetemp;
    }
  }
  callAPIs() {
    if (
      this.consolidateData.isUpdate &&
      this.consolidateData.selectedNodeForEditId
    ) {
      this.serviceCall
        .getStepperNodeData(this.consolidateData.selectedNodeForEditId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            const respData: AddProductModuleRequest = data.data;
            this.populateFormData(respData);
          }
        });
    }
    this.serviceCall
      .getProductLookups('type_0f_covering')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.coverBundleTypes = data.data;
        }
      });
    this.serviceCall
      .getProductLookups('deductible_type')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.typeOfDeductible = data.data;
        }
      });
    this.serviceCall
      .stepperDataLookup(2)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.ProductModuleLookup = data.data
            ? data.data.filter(function (e) {
                return e != null;
              })
            : data.data;
        }
      });
    this.serviceCall
      .stepperDataLookup(3)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.CoverBundleLookup = data.data
            ? data.data.filter(function (e) {
                return e != null;
              })
            : data.data;
        }
      });
    this.serviceCall
      .stepperDataLookup(4)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.CoveringLookup = data.data
            ? data.data.filter(function (e) {
                return e != null;
              })
            : data.data;
        }
      });
    this.serviceCall
      .stepperDataLookup(5)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.typeOfDamage = data.data
            ? data.data.filter(function (e) {
                return e != null;
              })
            : data.data;
        }
      });
    this.serviceCall
      .getProductLookups('maximization')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.maximizationtype = data.data;
        }
      });
    this.serviceCall
      .getProductLookups('SB-Typ')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.HasErrors) {
          this.SBType = data.data;
        }
      });
    const currencySelected = this.allCurrencyValues.find(
      obj => obj.cur_symbol == 'EUR'
    );
    this.currancySymbol = currencySelected && currencySelected.cur_code;
    this.selectedCurrency = currencySelected && currencySelected.cur_symbol;
    const tempId = currencySelected && currencySelected.id;
    this.stepperForm.controls.currency_type_id.setValue(tempId);
  }
  initializeForm() {
    this.nodeIdToUpdate = undefined;
    this.stepperForm = new FormGroup({
      ProductName: new FormControl({ value: '', disabled: false }),
      ProductModule: new FormControl({ value: '', disabled: false }),
      CoverBundle: new FormControl({ value: '', disabled: false }),
      CoverBundleType: new FormControl({ value: '', disabled: false }),
      ProductModuleType: new FormControl({ value: '', disabled: false }),
      CoveringType: new FormControl({ value: '', disabled: false }),
      ConditionText: new FormControl({ value: '', disabled: false }),
      CoveringName: new FormControl({ value: '', disabled: false }),
      TypeOfDamage: new FormControl({ value: '', disabled: false }),
      currency_type_id: new FormControl({ value: '', disabled: false }),
      Maximisation: new FormControl({ value: '', disabled: false }),
      DetentionPeriod: new FormControl({ value: '', disabled: false }),
      DeductibleType: new FormControl({ value: '', disabled: false }),
      SBType: new FormControl({ value: '', disabled: false }),
      DeductibleAmount: new FormControl({ value: '', disabled: true }),
      DeductiblePercentage: new FormControl({ value: '', disabled: true }),
      minDeductibleAmount: new FormControl({ value: '', disabled: true }),
      maxDeductibleAmount: new FormControl({ value: '', disabled: true }),
      DeductibleDays: new FormControl({ value: '', disabled: true }),
      autofillData: new FormControl({ value: '', disabled: false }),
      sequenceNumber: new FormControl({ value: '', disabled: false }),
    });
    if (this.stepIndex == 2) {
      this.stepperForm.controls.CoverBundle.setValidators(Validators.required);
    } else if (this.stepIndex == 3) {
      this.stepperForm.controls.CoveringName.setValidators(Validators.required);
    } else if (this.stepIndex == 4) {
      this.stepperForm.controls.TypeOfDamage.setValidators(Validators.required);
    }
    this.stepperForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.emitFormData();
      });
    this.is_auto_fill_data_enabled = false;
  }

  disableFields() {
    this.stepperForm.controls.ProductName.disable();
    this.stepperForm.controls.ProductModule.disable();
    this.stepperForm.controls.CoverBundle.disable();
    this.stepperForm.controls.CoverBundleType.disable();
    this.stepperForm.controls.ProductModuleType.disable();
    this.stepperForm.controls.CoveringType.disable();
    this.stepperForm.controls.ConditionText.disable();
    this.stepperForm.controls.CoveringName.disable();
    this.stepperForm.controls.TypeOfDamage.disable();
    this.stepperForm.controls.currency_type_id.disable();
    this.stepperForm.controls.Maximisation.disable();
    this.stepperForm.controls.DetentionPeriod.disable();
    this.stepperForm.controls.DeductibleType.disable();
    this.stepperForm.controls.SBType.disable();
    this.stepperForm.controls.DeductibleAmount.disable();
    this.stepperForm.controls.DeductiblePercentage.disable();
    this.stepperForm.controls.minDeductibleAmount.disable();
    this.stepperForm.controls.maxDeductibleAmount.disable();
    this.stepperForm.controls.DeductibleDays.disable();
    this.stepperForm.controls.autofillData.disable();
  }
  populateFormData(respData: AddProductModuleRequest) {
    this.mask_gen_config_data = JSON.parse(respData.mask_gen_config_data);
    if (respData) {
      this.is_auto_fill_data_enabled = respData.is_auto_fill_data_enabled;
      this.nodeIdToUpdate = respData.id;
      if (
        this.consolidateData.stepperLookUpId ==
        StepperLookupConstants.PRODUCT_MODULE
      ) {
        this.stepperForm.controls.ProductModule.setValue(respData.name);
        this.stepperForm.controls.ProductModuleType.setValue(
          respData.type_covering_id
        );
      }
      if (
        this.consolidateData.stepperLookUpId ==
        StepperLookupConstants.COVER_BUNDLE
      ) {
        this.stepperForm.controls.CoverBundle.setValue(respData.name);
        this.stepperForm.controls.CoverBundleType.setValue(
          respData.type_covering_id
        );
      }

      if (
        this.consolidateData.stepperLookUpId == StepperLookupConstants.COVERINGS
      ) {
        this.stepperForm.controls.CoveringName.setValue(respData.name);
        this.stepperForm.controls.CoveringType.setValue(
          respData.type_covering_id
        );
      }

      if (
        this.consolidateData.stepperLookUpId ==
        StepperLookupConstants.TYPES_OF_DAMAGE
      ) {
        this.stepperForm.controls.TypeOfDamage.setValue(respData.name);
        this.stepperForm.controls.CoveringType.setValue(
          respData.type_covering_id
        );
      }
      if (respData.currency_type_id) {
        const currencySelected = this.allCurrencyValues.find(
          obj => obj.id == respData.currency_type_id
        );
        this.currancySymbol = currencySelected && currencySelected.cur_code;
        this.selectedCurrency = currencySelected && currencySelected.cur_symbol;
      }
      this.stepperForm.controls.DeductibleDays.setValue(
        respData.day_for_deductibles
      );
      this.stepperForm.controls.sequenceNumber.setValue(
        respData.sequence_number
      );
      this.stepperForm.controls.SBType.setValue(respData.sb_type_id);
      this.stepperForm.controls.DeductibleAmount.setValue(
        respData.deductible_amount
      );
      this.stepperForm.controls.maxDeductibleAmount.setValue(
        this.parseToGermanNumber(respData.max_deductible_amount)
      );
      this.stepperForm.controls.minDeductibleAmount.setValue(
        this.parseToGermanNumber(respData.min_deductible_amount)
      );
      this.stepperForm.controls.DeductibleAmount.setValue(
        this.parseToGermanNumber(respData.deductible_amount)
      );
      this.stepperForm.controls.DeductiblePercentage.setValue(
        respData.deductible_in
      );
      this.stepperForm.controls.DeductibleType.setValue(
        respData.type_deductible_id
      );
      this.stepperForm.controls.DetentionPeriod.setValue(
        respData.period_of_detention
      );
      this.stepperForm.controls.Maximisation.setValue(respData.maximization_id);
      this.stepperForm.controls.ConditionText.setValue(respData.condition_text);
      this.stepperForm.controls.currency_type_id.setValue(
        respData.currency_type_id
      );
      this.stepperForm.controls.SBType.setValue(respData.sb_type_id);
    }
  }

  copyDataFromRoot(event: MatCheckboxChange, index: number) {
    if (event.checked) {
      const nodeId =
        index == 3
          ? this.consolidateData.productCoverBundleId
          : this.consolidateData.productCoveringId;
      this.serviceCall
        .getStepperNodeData(nodeId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            const respData: AddProductModuleRequest = data.data;
            if (respData.currency_type_id) {
              const currencySelected = this.allCurrencyValues.find(
                obj => obj.id == respData.currency_type_id
              );
              this.currancySymbol =
                currencySelected && currencySelected.cur_code;
              this.selectedCurrency =
                currencySelected && currencySelected.cur_symbol;
            }
            this.stepperForm.controls.DeductibleDays.setValue(
              respData.day_for_deductibles
            );
            this.stepperForm.controls.maxDeductibleAmount.setValue(
              respData.max_deductible_amount
            );
            this.stepperForm.controls.minDeductibleAmount.setValue(
              respData.deductible_amount
            );
            this.stepperForm.controls.DeductiblePercentage.setValue(
              respData.deductible_in
            );
            this.stepperForm.controls.DeductibleType.setValue(
              respData.type_deductible_id
            );
            this.stepperForm.controls.DetentionPeriod.setValue(
              respData.period_of_detention
            );
            this.stepperForm.controls.Maximisation.setValue(
              respData.maximization_id
            );
            this.stepperForm.controls.currency_type_id.setValue(
              respData.currency_type_id
            );
            this.stepperForm.controls.SBType.setValue(respData.sb_type_id);
          }
        });
    }
  }
  selectSBType(event) {
    if (event.value !== 35) {
      this.stepperForm.controls.DeductibleAmount.reset();
      if (this.mask_gen_config_data) {
        this.mask_gen_config_data.deductible_amt = false;
      }
    }
    if (event.value !== 36) {
      this.stepperForm.controls.DeductiblePercentage.reset();
      this.stepperForm.controls.maxDeductibleAmount.reset();
      this.stepperForm.controls.minDeductibleAmount.reset();
      if (this.mask_gen_config_data) {
        this.mask_gen_config_data.deductiable_in = false;
        this.mask_gen_config_data.deductible_max = false;
        this.mask_gen_config_data.deductible_min = false;
      }
    }
    if (event.value !== 37) {
      this.stepperForm.controls.DeductibleDays.reset();
      if (this.mask_gen_config_data) {
        this.mask_gen_config_data.days_for_Deductible = false;
      }
    }
    this.emitFormData();
  }
  unZipData() {
    this.consolidateData.productName &&
      this.stepperForm.controls.ProductName.setValue(
        this.consolidateData.productName
      );
    this.consolidateData.productModuleName &&
      this.stepperForm.controls.ProductModule.setValue(
        this.consolidateData.productModuleName
      );
    this.consolidateData.productCoverBundleName &&
      this.stepperForm.controls.CoverBundle.setValue(
        this.consolidateData.productCoverBundleName
      );
    this.consolidateData.productCoveringName &&
      this.stepperForm.controls.CoveringName.setValue(
        this.consolidateData.productCoveringName
      );
    this.disableField =
      this.consolidateData &&
      [2, 3].includes(this.consolidateData.productStateId);
  }

  emitFormData() {
    const formValues = this.stepperForm.value;
    formValues.minDeductibleAmount =
      +this.removeSymbols(formValues.average_stock_value) == 0
        ? null
        : Number(this.removeSymbols(formValues.average_stock_value));
    formValues.maxDeductibleAmount =
      +this.removeSymbols(formValues.maximum_storage_value) == 0
        ? null
        : Number(this.removeSymbols(formValues.maximum_storage_value));

    const fromValues: AddProductModuleRequest = {
      sequence_number:
        this.stepperForm.controls.sequenceNumber.value ||
        this.consolidateData.sequence_number,
      product_info_id: this.consolidateData.productId,
      parent_id: this.consolidateData.stepperformDetailId,
      stepper_lookup_id: this.consolidateData.stepperLookUpId,
      condition_text: this.stepperForm.controls.ConditionText.value,
      maximization_id:
        this.stepperForm.controls.Maximisation.value &&
        Number(this.stepperForm.controls.Maximisation.value),
      period_of_detention: this.stepperForm.controls.DetentionPeriod.value,
      type_deductible_id: this.stepperForm.controls.DeductibleType.value,
      deductible_in: this.stepperForm.controls.DeductiblePercentage.value,
      min_deductible_amount:
        this.stepperForm.controls.minDeductibleAmount.value &&
        Number(
          this.removeSymbols(
            this.stepperForm.controls.minDeductibleAmount.value
          )
        ),
      max_deductible_amount:
        this.stepperForm.controls.maxDeductibleAmount.value &&
        Number(
          this.removeSymbols(
            this.stepperForm.controls.maxDeductibleAmount.value
          )
        ),
      day_for_deductibles: this.stepperForm.controls.DeductibleDays.value,
      currency_type_id: this.stepperForm.controls.currency_type_id.value,
      is_selected: true,
      is_deleted: false,
      is_auto_fill_data_enabled: this.stepperForm.controls.autofillData.value,
      sb_type_id: this.stepperForm.controls.SBType.value,
      deductible_amount:
        this.stepperForm.controls.DeductibleAmount.value &&
        Number(
          this.removeSymbols(this.stepperForm.controls.DeductibleAmount.value)
        ),
      mask_gen_config_data: JSON.stringify(this.mask_gen_config_data),
    };
    if (this.nodeIdToUpdate) {
      fromValues.id = this.nodeIdToUpdate;
    }

    if (
      this.consolidateData.stepperLookUpId ==
      StepperLookupConstants.PRODUCT_MODULE
    ) {
      fromValues.name =
        this.stepperForm.controls.ProductModule.value ||
        this.selectedCoverBundleName;
      fromValues.type_covering_id =
        this.stepperForm.controls.ProductModuleType.value;
      fromValues.parent_id = null;
    }

    if (
      this.consolidateData.stepperLookUpId ==
      StepperLookupConstants.COVER_BUNDLE
    ) {
      fromValues.name =
        this.stepperForm.controls.CoverBundle.value ||
        this.selectedCoverBundleName;
      fromValues.type_covering_id =
        this.stepperForm.controls.CoverBundleType.value;
    }

    if (
      this.consolidateData.stepperLookUpId == StepperLookupConstants.COVERINGS
    ) {
      fromValues.name =
        this.stepperForm.controls.CoveringName.value ||
        this.selectedCoveringName;
      fromValues.type_covering_id =
        this.stepperForm.controls.CoveringType.value;
    }
    if (
      this.consolidateData.stepperLookUpId ==
      StepperLookupConstants.TYPES_OF_DAMAGE
    ) {
      fromValues.name =
        this.stepperForm.controls.TypeOfDamage.value || this.selectedDamageType;
      fromValues.type_covering_id =
        this.stepperForm.controls.CoveringType.value;
    }

    const request: ProductSaveRequest = {
      stepperLookupId: this.consolidateData.stepperLookUpId,
      fromValues,
      valid: true,
      isFormTouched: this.stepperForm && !this.stepperForm.pristine,
    };
    this.emitFormValue.emit(request);
  }
  removeSymbols(message: string): string {
    if (message) {
      message = message.toString();
      message = message.replaceAll('.', '');
      message = message.replaceAll(',', '.');
      message = message.trim();
    }

    return message;
  }

  parseToGermanNumber(numberStr: number) {
    if (numberStr) {
      let transFormedValue = this._currencyCustomPipe.transform(numberStr);
      if (transFormedValue) {
        transFormedValue = transFormedValue
          .replace('€', '')
          ?.replace(',00', '')
          ?.trim();
      }
      return transFormedValue;
    }
  }
  currancyChanged(event?) {
    const value = event
      ? event.value
      : this.allCurrencyValues.find(obj => obj.cur_symbol == 'EUR').id;
    this.currancySymbol = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_code;
    this.selectedCurrency = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => this.currencyTypeMinDeductibleAmount.focus(), 200);
  }
  currancyChangedTwo(event?) {
    const value = event
      ? event.value
      : this.allCurrencyValues.find(obj => obj.cur_symbol == 'EUR').id;
    this.currancySymbol = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_code;
    this.selectedCurrency = this.allCurrencyValues.find(
      obj => obj.id == value
    ).cur_symbol;
    this.currencyType.setValue('');
    setTimeout(() => this.currencyTypeAverageStockDropdown.focus(), 200);
  }
  currencyTypeOneSearch() {
    setTimeout(
      () => document.getElementById('currencyTypeOneSearch').focus(),
      200
    );
  }
  currencyTypeTwoSearch() {
    setTimeout(
      () => document.getElementById('currencyTypeTwoSearch').focus(),
      200
    );
  }

  resetList() {
    this.currencyType.setValue('');
  }
  onTypeNewValue(event, key, name?) {
    if (key == 'keypress') {
      const charCode = event.which ? event.which : event.keyCode;
      if (event.target.value.length == 0) {
        if (charCode == '45' || charCode == '48') {
          event.preventDefault();
        }
      }
      if (
        event.target.value !== 'ä' &&
        event.target.value !== 'ö' &&
        event.target.value !== 'ü' &&
        event.target.value !== 'Ä' &&
        event.target.value !== 'Ü' &&
        event.target.value !== 'Ö' &&
        event.target.value !== 'Ë' &&
        event.target.value !== 'ë'
      ) {
        if (name == 'DeductibleAmount') {
          this.deductibleAmountError1 = false;
        }
        if (name == 'minDeductibleAmount') {
          this.minDeductibleAmountError1 = false;
        }
        if (name == 'maxDeductibleAmount') {
          this.maxDeductibleAmountError1 = false;
        }

        if (
          charCode != 46 &&
          charCode !== 44 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        this.onTypeDecimalNumber(event);
        return true;
      } else {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualneuValue = event.target.value.length;
      } else {
        this.actualneuValue = this.actualneuValue - 1;
      }
    } else {
      const reg = new RegExp('^[0-9,.]*$');
      if (!reg.test(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }
      const value = event.clipboardData.getData('text/plain');
      if (
        value.indexOf('ä') !== -1 ||
        value.indexOf('ö') !== -1 ||
        value.indexOf('ë') !== -1 ||
        value.indexOf('ü') !== -1 ||
        value.indexOf('Ä') !== -1 ||
        value.indexOf('Ö') !== -1 ||
        value.indexOf('Ë') !== -1 ||
        value.indexOf('Ü') !== -1
      ) {
        event.preventDefault();
      }
      if (
        this.actualneuValue == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      }
    }
  }
  onTypeDecimalNumber(event) {
    const value = event.target.value.split(',');
    if (value[1] !== undefined) {
      if (value[1].length == 2) {
        event.preventDefault();
      }
    }
  }
  changeNumber(event, name) {
    const count = event.split(',').length - 1;

    if (name == 'DeductibleAmount') {
      if (count > 1) {
        this.deductibleAmountError2 = true;
      } else {
        this.deductibleAmountError2 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name == 'DeductibleAmount') {
        this.deductibleAmountError1 = true;
      } else {
        this.deductibleAmountError1 = false;
      }

      if (event.toString()) {
        this.stepperForm.controls.DeductibleAmount.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString().replaceAll('.', '').replaceAll(',', '.')
            )
            .replace('€', '')
            ?.replace(',00', '')
            ?.trim()
        );
      }
    }

    if (name == 'minDeductibleAmount') {
      if (count > 1) {
        this.minDeductibleAmountError2 = true;
      } else {
        this.minDeductibleAmountError2 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name == 'minDeductibleAmount') {
        this.minDeductibleAmountError1 = true;
      } else {
        this.minDeductibleAmountError1 = false;
      }

      if (event.toString()) {
        this.stepperForm.controls.minDeductibleAmount.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString().replaceAll('.', '').replaceAll(',', '.')
            )
            .replace('€', '')
            ?.replace(',00', '')
            ?.trim()
        );
      }
    }

    if (name == 'maxDeductibleAmount') {
      if (count > 1) {
        this.maxDeductibleAmountError2 = true;
      } else {
        this.maxDeductibleAmountError2 = false;
      }

      const tempValue = event.replaceAll('.', '');
      const splitValue = tempValue.split(',');

      if (splitValue[0].length > 9 && name == 'maxDeductibleAmount') {
        this.maxDeductibleAmountError1 = true;
      } else {
        this.maxDeductibleAmountError1 = false;
      }

      if (event.toString()) {
        this.stepperForm.controls.maxDeductibleAmount.setValue(
          this._currencyCustomPipe
            .transform(
              event.toString().replaceAll('.', '').replaceAll(',', '.')
            )
            .replace('€', '')
            ?.replace(',00', '')
            ?.trim()
        );
      }
    }

    if (
      this.deductibleAmountError1 ||
      this.deductibleAmountError2 ||
      this.maxDeductibleAmountError1 ||
      this.maxDeductibleAmountError2 ||
      this.minDeductibleAmountError1 ||
      this.minDeductibleAmountError2
    ) {
      this.formError = true;
    } else {
      this.formError = false;
    }

    this.emitFormData();
  }
  typeOfDamageSave(data) {
    if (data) {
      this.selectedDamageType = data;
      this.emitFormData();
    }
  }

  CoverBundleNameSave(data) {
    if (data) {
      this.selectedCoverBundleName = data;
      this.emitFormData();
    }
  }
  CoveringNameSave(data) {
    if (data) {
      this.selectedCoveringName = data;
      this.emitFormData();
    }
  }

  ProductModuleNameSave(data) {
    if (data) {
      this.productModuleName = data;
      this.emitFormData();
    }
  }
}
