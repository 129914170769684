import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { UserService } from '@core/user.service';
import { StepThreeCustomerInviteComponent } from '../step-three-customer-invite/step-three-customer-invite.component';
import { UpdateUserComponent } from '../update-user.component';
import { STATIC_TEXT } from '@shared/constants/string.constants';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { SparteShareService } from '@core/services/sparte-share.service';

export interface Sparte {
  sparte: String;
  contracts: Contract[];
  panelOptionState: boolean;
  isCheckedAll: boolean;
  isFutureSelected: boolean;
  sparte_fixer_code: number;
}
export interface Contract {
  contract_number: String;
  liztu_vs_idnr: number;
  is_authorised: boolean;
  object: String;
  product_name: boolean;
}
export interface Customer {
  name: string;
  id: number;
  liztu_vn_kdnr: number;
  type: string;
  is_selected: boolean;
}
export interface SelectedCustomers {
  allSelectedCustomers: selectedCustomersWithData[];
  lastSelectedCustomer: string;
  customers: Customer[];
  isCheckedAllFutureContractsForThisCustomer: boolean;
  isCheckedAllContractsForThisCustomer: boolean;
}
export interface selectedCustomersWithData {
  liztu_vn_kdnr: number;
  sparte: Sparte[];
  is_changed: boolean;
}

@Component({
  selector: 'app-step-two-customer-invite',
  templateUrl: './step-two-customer-invite.component.html',
  styleUrls: ['./step-two-customer-invite.component.scss'],
})
export class StepTwoCustomerInviteComponent
  extends BaseComponent
  implements OnInit
{
  full_name: String;
  first_name: String;
  last_name: String;
  customerType: string;
  customer: string;
  sparte: Sparte[] = [];
  customers: Customer[] = [];
  searchText;
  is_group = false;
  openConfirmationDialog: boolean;
  openConfirmationDialog1 = false;
  step3Clicked = false;
  step1Clicked = false;
  localData;
  user;
  userData;
  oldLiztuVnKdnr;
  mutterCustomer;
  selectedCustomersWithData: selectedCustomersWithData[] = [];
  lastSelectedCustomer: string;
  allSelectedCustomers = [];
  selectedCustomerLiztuVnKdnr: number;
  isCustomerSelected = false;
  customerId: string;
  customersData;
  isCheckedAllContractsForThisCustomer = false;
  isCheckedAllFutureContractsForThisCustomer = false;

  constructor(
    public dialogRef: MatDialogRef<StepTwoCustomerInviteComponent>,
    private dialog: MatDialog,
    private serviceCall: ApiCallsService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _sparteShareService: SparteShareService
  ) {
    super();
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.customersData = this._userService.selectedCustomers;
    this.userData = this._userService.userToUpdate;
    this.localData = this._sparteShareService.sparte;
    this.oldLiztuVnKdnr = this._userService.customerLiztuVnKdnr;
    this.user = this._userService.userFormData;
    this.first_name = this.user.firstName;
    this.last_name = this.user.lastName;
    this.full_name = this.first_name + ' ' + this.last_name;
    this.customerType = this.user.type;
    this.customer = this.user.customerName;
    this.is_group = this.user?.is_group || false;
    this.customerId = this._customerSharedService.getSelectedCustomerId();
    this.mutterCustomer = this._userService.mutterCustomer;
    if (this.is_group) {
      if (this.customersData !== null) {
        this.customers = this.customersData.customers;
        this.lastSelectedCustomer = this.customersData.lastSelectedCustomer;
        this.selectedCustomersWithData =
          this.customersData.allSelectedCustomers;
        this.isCustomerSelected = this.customers.find(
          obj => obj.name == this.lastSelectedCustomer
        )?.is_selected;
        const liztu = this.customers.find(
          obj => obj.name == this.lastSelectedCustomer
        )?.liztu_vn_kdnr;
        this.selectedCustomerLiztuVnKdnr = liztu;
        if (
          this.selectedCustomersWithData.find(
            obj => obj.liztu_vn_kdnr == liztu
          ) !== undefined
        ) {
          const sparteTemp = this.selectedCustomersWithData.find(
            obj => obj.liztu_vn_kdnr == liztu
          )?.sparte;
          this.sparte = this.formatSparteData(sparteTemp);
          this.isCheckedAllContractsForThisCustomer =
            this.isCheckedAllContractsForCustomer(this.sparte);
          this.isCheckedAllFutureContractsForThisCustomer =
            this.isCheckedAllFutureContractsForCustomer(this.sparte);
        } else {
          this.isCheckedAllContractsForThisCustomer =
            this.customersData.isCheckedAllContractsForThisCustomer;
          this.isCheckedAllFutureContractsForThisCustomer =
            this.customersData.isCheckedAllFutureContractsForThisCustomer;
          this.serviceCall
            .getContractsForCustomer(liztu)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
              this.getContractsData(data.sparteWiseContracts);
            });
        }
      } else {
        this.lastSelectedCustomer = this.customer;
        this.isCustomerSelected = false;
        this.selectedCustomerLiztuVnKdnr = this.user.liztu_vn_kdnr;
        this.getAllCustomers(this.is_group);
        this._userService.customerLiztuVnKdnr =
          this.userData.customer.liztu_vn_kdnr;
        this.getContracts();
      }
    } else {
      if (this.localData == null) {
        this.setData();
      } else if (this.oldLiztuVnKdnr == this.userData.customer.liztu_vn_kdnr) {
        const customer: Customer = {
          name: this.customer,
          id: this.user.customerId,
          is_selected: false,
          liztu_vn_kdnr: this.user.liztu_vn_kdnr,
          type: this.user.type,
        };
        this.customers.push(customer);
        this.lastSelectedCustomer = this.customer;
        this.isCustomerSelected = false;
        this.selectedCustomerLiztuVnKdnr = this.user.liztu_vn_kdnr;
        this.sparte = [];
        this._sparteShareService.sparte = null;
        const sparteData = this.localData;
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(sparteData);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(sparteData);
        for (let i = 0; i < sparteData.length; i++) {
          this.sparte.push(sparteData[i]);
        }
      } else {
        this.setData();
      }
    }
  }
  setData() {
    this.customerId = this.userData.customer_id;
    this.getAllCustomers(this.is_group);
    this.lastSelectedCustomer = this.customer;
    this.isCustomerSelected = false;
    this.selectedCustomerLiztuVnKdnr = this.user.liztu_vn_kdnr;
    this._userService.customerLiztuVnKdnr =
      this.userData.customer.liztu_vn_kdnr;
    this.getContracts();
  }
  getContracts() {
    this.serviceCall
      .getContractsForCustomer(this.userData.liztu_vn_kdnr)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.getContractsData(data.sparteWiseContracts);
        this.isCheckedAllContractsForThisCustomer =
          this.isCheckedAllContractsForCustomer(this.sparte);
        this.isCheckedAllFutureContractsForThisCustomer =
          this.isCheckedAllFutureContractsForCustomer(this.sparte);
      });
  }
  getContractsData(sparteWiseContracts) {
    const data = sparteWiseContracts || [];
    for (const element of data) {
      let isChecked = false;
      isChecked = this.isCheckedAllContracts(element);
      const sparte: Sparte = {
        contracts: element.contracts,
        sparte: element.sparte,
        panelOptionState: false,
        isCheckedAll: isChecked,
        isFutureSelected: false,
        sparte_fixer_code: element.sparte_fixer_code,
      };
      this.sparte.push(sparte);
    }
  }
  isCheckedAllContracts(data) {
    if (data.contracts.every(val => val.is_authorised)) {
      return true;
    } else {
      return false;
    }
  }
  onNoClick() {
    this._sparteShareService.sparte = null;
    this._userService.mutterCustomer = null;
    this._userService.userToUpdate = null;
    this._userService.userFormData = null;
    this._userService.widgets = null;
    this._userService.customerLiztuVnKdnr = null;
    this._userService.showActiveFlag = null;
    this._userService.selectedCustomers = null;
    this._userService.inviteUserContext = null;
    this.dialogRef.close();
  }
  onStep1Click(sparte, selectedCustomerLiztuVnKdnr) {
    if (this.is_group) {
      this.guardCheckForSparteSelection(sparte, selectedCustomerLiztuVnKdnr, 1);
    } else {
      this._sparteShareService.sparte = sparte;
      const user = this._userService.userToUpdate;
      this.dialog.open(UpdateUserComponent, {
        width: '800px',
        data: user,
      });
      this.dialogRef.close();
    }
  }
  onStep3Click(sparte, selectedCustomerLiztuVnKdnr) {
    if (this.is_group) {
      this.guardCheckForSparteSelection(sparte, selectedCustomerLiztuVnKdnr, 3);
    } else if (!this.is_group) {
      this._sparteShareService.sparte = sparte;
      this.dialog.open(StepThreeCustomerInviteComponent, {
        width: '800px',
        height: '700px',
      });
      this.dialogRef.close();
    }
  }
  checkIsAnyContractAuthorised(sparte, whichButtonClicked: number) {
    if (
      sparte?.some(item =>
        item.contracts?.some(contract => contract.is_authorised === true)
      )
    ) {
      if (whichButtonClicked == 3) {
        this.step3Clicked = true;
      } else if (whichButtonClicked == 1) {
        this.step1Clicked = true;
      }
      this.openConfirmationDialog1 = true;
      return;
    }
    return true;
  }
  guardCheckForSparteSelection(
    sparte,
    selectedCustomerLiztuVnKdnr,
    whichButtonClicked: number
  ) {
    if (this.selectedCustomersWithData.length === 0) {
      this.checkIsAnyContractAuthorised(sparte, whichButtonClicked);
      if (!this.openConfirmationDialog1 && this.sparte?.length > 0) {
        this.openConfirmationDialog = true;
      }
    } else if (this.selectedCustomersWithData.length > 0) {
      const index = this.selectedCustomersWithData.findIndex(
        obj => obj.liztu_vn_kdnr === selectedCustomerLiztuVnKdnr
      );

      if (index !== -1) {
        const selectedCustomer = this.selectedCustomersWithData[index];
        const hasSameSparteLength =
          sparte.length === selectedCustomer.sparte.length;

        if (hasSameSparteLength) {
          let hasChanges = false;

          for (let i = 0; i < sparte.length; i++) {
            for (let j = 0; j < sparte[i].contracts.length; j++) {
              const selectedContract = selectedCustomer.sparte[i].contracts[j];
              const currentContract = sparte[i].contracts[j];

              if (
                selectedContract.is_authorised !==
                  currentContract.is_authorised ||
                selectedCustomer.sparte[i].isFutureSelected !==
                  sparte[i].isFutureSelected
              ) {
                hasChanges = true;
                break;
              }
            }

            if (hasChanges) {
              break;
            }
          }

          if (hasChanges) {
            selectedCustomer.is_changed = true;
            if (whichButtonClicked == 3) {
              this.step3Clicked = true;
            } else if (whichButtonClicked == 1) {
              this.step1Clicked = true;
            }
            this.openConfirmationDialog1 = true;
          } else if (!this.openConfirmationDialog1) {
            selectedCustomer.is_changed = false;
            this.chooseBasedOnClose(whichButtonClicked);
          }
        } else {
          this.checkIsAnyContractAuthorised(sparte, whichButtonClicked);
          if (!this.openConfirmationDialog1) {
            // this.closeDialog();
            this.chooseBasedOnClose(whichButtonClicked);
          }
        }
      } else {
        const isAllUnauthorised = this.checkIsAnyContractAuthorised(
          sparte,
          whichButtonClicked
        );
        if (isAllUnauthorised) {
          this.chooseBasedOnClose(whichButtonClicked);
          // this.closeDialog();
        }
      }
    }
  }
  chooseBasedOnClose(whichButtonClicked: number) {
    if (whichButtonClicked == 3) {
      this.closeDialog();
    } else if (whichButtonClicked == 1) {
      this.closeDialog1();
    }
  }
  CheckAllOptions(sparte) {
    if (sparte.contracts.every(val => val.is_authorised == true)) {
      sparte.contracts.forEach(value => {
        value.is_authorised = false;
      });
      sparte.isCheckedAll = false;
    } else {
      sparte.contracts.forEach(value => {
        value.is_authorised = true;
      });
      sparte.isCheckedAll = true;
    }
    this.isCheckedAllContractsForThisCustomer =
      this.isCheckedAllContractsForCustomer(this.sparte);
    this.isCheckedAllFutureContractsForThisCustomer =
      this.isCheckedAllFutureContractsForCustomer(this.sparte);
    this.saveCustomer();
  }
  contractClick(contract, sparte) {
    contract.is_authorised = !contract.is_authorised;
    if (sparte.contracts.every(val => val.is_authorised == true)) {
      sparte.isCheckedAll = true;
    }
    const result = sparte.contracts.find(val => val.is_authorised != true);
    if (result != undefined) {
      sparte.isCheckedAll = false;
    } else {
      sparte.isCheckedAll = true;
    }
    this.isCheckedAllContractsForThisCustomer =
      this.isCheckedAllContractsForCustomer(this.sparte);
    this.isCheckedAllFutureContractsForThisCustomer =
      this.isCheckedAllFutureContractsForCustomer(this.sparte);
    this.saveCustomer();
  }
  searchChanged(event) {
    const keyCode = event.keyCode;
    const isSpecialKey = [32, 8, 9, 13, 27, 37, 38, 39, 40, 91, 92].includes(
      keyCode
    );

    if (
      this.searchText === STATIC_TEXT.EMPTY_STRING ||
      (this.searchText === undefined && !isSpecialKey)
    ) {
      this.sparte.forEach(val => (val.panelOptionState = true));
    } else if (this.searchText !== STATIC_TEXT.EMPTY_STRING && keyCode !== 32) {
      this.sparte.forEach(val => (val.panelOptionState = true));
    } else if (
      this.searchText === ' ' ||
      this.searchText === STATIC_TEXT.EMPTY_STRING
    ) {
      for (const sparteItem of this.sparte) {
        sparteItem.panelOptionState = false;
      }
    }
  }

  getAllCustomers(byGroup) {
    this.serviceCall
      .getUniqueSubsidiaries(
        this.customerId,
        byGroup,
        this._userService.getUserRole()
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        for (let i = 0; i < data.associated_customers.length; i++) {
          const customer: Customer = {
            id: null,
            name: '',
            liztu_vn_kdnr: null,
            type: '',
            is_selected: false,
          };
          customer.id = data.associated_customers[i].customer_id;
          customer.name = data.associated_customers[i].customer_name;
          customer.liztu_vn_kdnr = data.associated_customers[i].liztu_vn_kdnr;
          customer.type = data.associated_customers[i].type;
          customer.is_selected = false;
          this.customers.push(customer);
        }
      });
  }
  customerChange(customer) {
    if (!this.openConfirmationDialog1) {
      this.lastSelectedCustomer = customer.name;
      this.isCustomerSelected = customer.is_selected;
      this.selectedCustomerLiztuVnKdnr = customer.liztu_vn_kdnr;
      const index = this.selectedCustomersWithData.findIndex(
        obj => obj.liztu_vn_kdnr === customer.liztu_vn_kdnr
      );

      if (index === -1) {
        this.getCustomerWiseContracts(customer.liztu_vn_kdnr);
      } else {
        const sparteTemp = this.selectedCustomersWithData[index].sparte;
        this.sparte = this.formatSparteData(sparteTemp);
      }

      this.isCheckedAllContractsForThisCustomer =
        this.isCheckedAllContractsForCustomer(this.sparte);
      this.isCheckedAllFutureContractsForThisCustomer =
        this.isCheckedAllFutureContractsForCustomer(this.sparte);
    }
  }
  getCustomerWiseContracts(liztu_vn_kdnr) {
    this.sparte = [];
    this.serviceCall
      .getContractsForCustomer(liztu_vn_kdnr)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.getContractsData(data.sparteWiseContracts);
      });
  }
  saveCustomer() {
    const customer = this.customers.find(
      obj => obj.liztu_vn_kdnr === this.selectedCustomerLiztuVnKdnr
    );
    if (customer) {
      customer.is_selected = true;
      const spartes = this.formatSparteData(this.sparte);
      const selectedCustomer = {
        liztu_vn_kdnr: this.selectedCustomerLiztuVnKdnr,
        sparte: spartes,
        is_changed: false,
      };

      this.isCustomerSelected = true;

      if (this.selectedCustomersWithData.length !== 0) {
        const index = this.selectedCustomersWithData.findIndex(
          obj => obj.liztu_vn_kdnr === this.selectedCustomerLiztuVnKdnr
        );
        if (index >= 0) {
          this.selectedCustomersWithData[index] = selectedCustomer;
        } else {
          this.selectedCustomersWithData.push(selectedCustomer);
        }
      } else {
        this.selectedCustomersWithData.push(selectedCustomer);
      }

      this.openConfirmationDialog1 = false;

      if (this.step3Clicked) {
        this.closeDialog();
      } else if (this.step1Clicked) {
        this.closeDialog1();
      }
    }
  }
  includeFutureContractsClick(sparte) {
    sparte.isFutureSelected = !sparte.isFutureSelected;
    const result = this.sparte.every(obj => obj.isFutureSelected == true);
    this.isCheckedAllFutureContractsForThisCustomer = result;
    this.saveCustomer();
  }
  hide() {
    this.openConfirmationDialog = false;
  }
  hide1() {
    this.step1Clicked = false;
    this.step3Clicked = false;
    this.openConfirmationDialog1 = false;
  }

  formatSparteData(sparteData) {
    const spartes = sparteData.map(sparteItem => {
      const contracts = sparteItem.contracts.map(contractItem => ({
        contract_number: contractItem.contract_number,
        liztu_vs_idnr: contractItem.liztu_vs_idnr,
        is_authorised: contractItem.is_authorised,
        object: contractItem.object,
        product_name: contractItem.product_name,
      }));

      return {
        contracts,
        sparte: sparteItem.sparte,
        panelOptionState: sparteItem.panelOptionState,
        isCheckedAll: sparteItem.isCheckedAll,
        isFutureSelected: sparteItem.isFutureSelected,
        sparte_fixer_code: sparteItem.sparte_fixer_code,
      };
    });

    return spartes;
  }

  prepareCloseRequest() {
    const selectedCustomers: SelectedCustomers = {
      allSelectedCustomers: this.selectedCustomersWithData,
      lastSelectedCustomer: this.lastSelectedCustomer,
      customers: this.customers,
      isCheckedAllFutureContractsForThisCustomer:
        this.isCheckedAllFutureContractsForThisCustomer,
      isCheckedAllContractsForThisCustomer:
        this.isCheckedAllContractsForThisCustomer,
    };
    this._userService.selectedCustomers = selectedCustomers;
  }

  closeDialog() {
    this.prepareCloseRequest();
    this.dialog.open(StepThreeCustomerInviteComponent, {
      width: '800px',
      height: '700px',
    });
    this.dialogRef.close();
  }

  closeDialog1() {
    this.prepareCloseRequest();
    const user = this._userService.userToUpdate;
    this.dialog.open(UpdateUserComponent, {
      width: '800px',
      data: user,
    });
    this.dialogRef.close();
  }

  // function is called when we click on change company dropdown
  // and it will disabled the entries in the dropdown if unchanged changes are there in the sparte accordion
  customerClick(selectedCustomerLiztuVnKdnr, sparte) {
    if (this.is_group) {
      const customer = this.customers.find(
        obj => obj.liztu_vn_kdnr === selectedCustomerLiztuVnKdnr
      );

      if (customer) {
        if (this.selectedCustomersWithData.length > 0) {
          const index = this.selectedCustomersWithData.findIndex(
            obj => obj.liztu_vn_kdnr === selectedCustomerLiztuVnKdnr
          );

          if (index === -1) {
            // No customer found in selectedCustomersWithData
            const hasAuthorizedContracts = sparte.some(sparteItem =>
              sparteItem.contracts.some(contract => contract.is_authorised)
            );

            if (!hasAuthorizedContracts) {
              this.setLastSelectedCustomer(customer);
            }
          } else {
            if (
              sparte.length ===
              this.selectedCustomersWithData[index].sparte.length
            ) {
              let hasChanges = false;

              for (let i = 0; i < sparte.length; i++) {
                for (let j = 0; j < sparte[i].contracts.length; j++) {
                  const selectedContract =
                    this.selectedCustomersWithData[index].sparte[i].contracts[
                      j
                    ];
                  const currentContract = sparte[i].contracts[j];

                  if (
                    selectedContract.is_authorised !==
                      currentContract.is_authorised ||
                    this.selectedCustomersWithData[index].sparte[i]
                      .isFutureSelected !== sparte[i].isFutureSelected
                  ) {
                    hasChanges = true;
                    this.openConfirmationDialog1 = true;
                    break; // Exit the loop as soon as changes are detected
                  }
                }
                if (hasChanges) {
                  break; // Exit the outer loop as well
                }
              }

              if (!hasChanges) {
                this.selectedCustomersWithData[index].is_changed = false;
                if (!this.selectedCustomersWithData[index].is_changed) {
                  this.setLastSelectedCustomer(customer);
                }
              }
            }
          }
        } else {
          const hasAuthorizedContracts = sparte.some(sparteItem =>
            sparteItem.contracts.some(contract => contract.is_authorised)
          );

          if (!hasAuthorizedContracts) {
            this.setLastSelectedCustomer(customer);
          }
        }
      }
    }
  }
  setLastSelectedCustomer(customer) {
    this.lastSelectedCustomer = customer.name;
    this.isCustomerSelected = customer.is_selected;
    this.selectedCustomerLiztuVnKdnr = customer.liztu_vn_kdnr;
    const index = this.selectedCustomersWithData.findIndex(
      obj => obj.liztu_vn_kdnr == customer.liztu_vn_kdnr
    );
    if (index == -1) {
      this.getCustomerWiseContracts(customer.liztu_vn_kdnr);
    } else {
      const sparteTemp = this.selectedCustomersWithData[index].sparte;
      this.sparte = [];
      this.sparte = this.formatSparteData(sparteTemp);
    }
  }
  checkAllContractsForCustomer(sparte) {
    if (!sparte.length) {
      return;
    }

    const isChecked = !this.isCheckedAllContractsForThisCustomer;

    sparte.forEach(obj => {
      obj.isCheckedAll = isChecked;
      obj.contracts.forEach(contract => {
        contract.is_authorised = isChecked;
      });
    });

    this.isCheckedAllContractsForThisCustomer = isChecked;

    this.saveCustomer();
  }
  checkAllFutureContractsForCustomer(sparte) {
    if (!sparte.length) {
      return;
    }
    const isFutureSelected = !this.isCheckedAllFutureContractsForThisCustomer;

    sparte.forEach(obj => {
      obj.isFutureSelected = isFutureSelected;
    });

    this.isCheckedAllFutureContractsForThisCustomer = isFutureSelected;
    this._userService.isFutureSelected = isFutureSelected;
    this.saveCustomer();
  }

  isCheckedAllContractsForCustomer(sparte) {
    if (sparte.length > 0) {
      return sparte.every(obj =>
        obj.contracts.every(contract => contract.is_authorised === true)
      );
    }
    return false;
  }
  isCheckedAllFutureContractsForCustomer(sparte) {
    if (sparte.length > 0) {
      return sparte.every(obj => obj.isFutureSelected);
    }
    return false;
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
