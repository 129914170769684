export const BuildingConstants = {
    OBJECT_DETAILS: 1,
    POWER_SUPPLY_FACILITIES: 2,
    POWER_SUPPLY: 3,
    STRUCTURAL_FIRE_PROTECTION_AND_SAFETY_DEVICES: 4,
    OTHER_INCREASES_IN_RISK: 5,
    PRINTING_COMPANIES: 6,
    FOIL_PACKAGING: 7,
    ELECTROPLATING: 8,
    FOUNDRY: 9,
    WOODWORKING_AND_PROCESSING: 10,
    PLASTIC: 11,
    WAREHOUSE: 12,
    LITHIUM_BATTERIES: 13,
    SOLDERING: 14,
    MILL_INSTALLATIONS: 15,
    PELLET_PLANT: 16,
    PHOTOVOLTAIC: 17,
    RECYCLING: 18,
    CLEAN_ROOMS: 19,
    GRINDING_AND_POLISHING: 20
};

export const PlasticFormConstants = {
    PLASTIC: 'plastic',
    AUTOMATIC_SHUTDOWN: 'plastic_pm_auto_shutdown',
    TUBULAR_FILMS: 'plastic_tubularfilms',
    FOAMED_PLASTICS: 'plastic_foamed_plastics',
    SELF_IGNITION: 'plastic_self_ignition',
    ORGANIC_PEROXIDES: 'plastic_organic_peroxides',
    USE_OF_EXPLOSIVES: 'plastic_use_of_explosives',
    USE_OF_HIGHLY_FLAMMABLE: 'plastic_use_of_highly_flammable',
    AUTOMATIC_SWITCH_OFF: 'plastic_automatic_switch_off',
    IS_MANUFACTURE_OF_PLASTICS: 'isManufacturersOfPlastics',
    IS_SPRAYING_MACHINES: 'isSprayingMachines'
};
export const GrindingFormConstants = {
    USE_OF_IRON_LIGHT_METAL: 'use_of_iron_lightMetal',
    EXTRACTION_WITH_ADEQUATE_CLEANING: 'extraction_with_adequate_cleaning',
    SEPERATE_EXTRACTION_FERROUS_NON_FERROUS: 'seperate_extraction_ferrous_nonFerrous',
    DRY_SEPERATOR: 'dry_seperator',
    FIRE_PROOF_PARTITION: 'fireproof_partition',
    PRESSURE_RELIEFE: 'pressure_reliefe',
    WET_SEPERATOR: 'wet_seperator',
    NON_FERRUS_METAL_SEPERATOR: 'non_ferrus_metal_seperator'
};
export const powerSupplyFacilityFormConstants = {
    ELECTRICAL_SWITCHGEAR: 'electricalSwitch',
    ELECTRICAL_SWITCHGEAR_IMAGE: 'AcceptanceProtocolES',
    OLD_HEATING_SYSTEM: 'oldHeatingSystem',
    OLD_HEATING_SYSTEM_IMAGE: 'AcceptanceProtocolOHS',
    TYPE_OF_HEATING: 'typeOfHeating',
    TYPE: 'typeQualityFuel',
    STEAM_GENERATING_PLANT: 'steamGeneratingPlants',
    STEAM_GENERATING_PLANT_IMAGE: 'AcceptanceProtocolSGP',
    COMPRESSED_AIR_SYSTEM: 'compressedAirSystem',
    COMPRESSED_AIR_SYSTEM_IMAGE: 'AcceptanceProtocolCAS'
};
export const photovoltaicFormConstants = {
    PERFORMACE_OF_THE_PLANT: 'performace_of_the_plant',
    OWNER: 'owner',
    FIRE_COMPARTMENT_SEPERATION_LIFTED: 'fire_compartment_seperation_lifted',
    REMARK: 'remark',
    PHOTOVOLTAIC_SYSTEM_INCLUDED_IN_EREVISION: 'photovoltaic_system_included_in_erevision',
    ACCPTANCE_PROTOCOL: 'AcceptanceProtocolPhotovoltaic',
    PV_SYSTEM_INCLUDED_IN_ELECTROREVISION: 'PV_system_included_in_electrorivision',
    INVERTOR_FREE_FROM_COMBUSTIBLE_MATERIAL: 'invertor_free_from_combustible_materials'
};

export const UploadTypeConstants = {
    PS_BATTERY_CHARGING_STATIONS : 'ps_battery_charging_stations',
    PS_TRANSFORMER_STATION: 'ps_transformer_station',
    ELECTROPLATING_THERMOGRAPHIC_TEST: 'electroplating_thermographic_test',
    FIRE_PROTECTION_ID: 'sfpsd_fire_protection_concept',
    FLOOR_PLAN_ID: 'sfpsd_floor_plans',
    EXTERNAL_DOOR_IMG_ID: 'sfpsd_external_doors',
    VDS_DOC_ID: 'ias_vds_approved',
    IS_SAFETY_LOCKS_ID: 'lotod_locking_of_outer_doors',
    UPLOAD_AUDIT_DOCUMENT: 'es_audit_doc',
    UPLOAD_DOC_INST: 'shes_degree_of_protection',
    FA_VDS_DOC_ID: 'fas_vds_approved',
    EXP_PRO_DOC_ID: 'epa_protection_doc',
    OIIR_PLANTS_ACCORDING_TO_BIMSCHG: 'oiir_plants_according_to_bimschg',
    OIIR_THERMAL_OIL_SYSTEMS: 'oiir_thermal_oil_systems',
    'OIIR_OIL-HYDRAULIC_SYSTEMS': 'oiir_oil-hydraulic_systems',
    OIIR_SPARK_EROSION_SYSTEM: 'oiir_spark_erosion_system',
    PHOTOVOLTAIC_ACCEPTANCE_REPORT: 'photovoltaic_acceptance_report',
    PSF_OLD_HEATING_SYSTEMS: 'psf_old_heating_systems',
    PSF_ELECTRICAL_SWITCHGEAR: 'psf_electrical_switchgear',
    PSF_STEAM_GENERATION_PLANT: 'psf_steam_generation_plant',
    PSF_COMPRESSED_AIR_SYSTEMS: 'psf_compressed_air_systems',
    OD_REVISION_DATE_DOCUMENT: 'od_revision_date_document',
    OD_SITE_PLAN: 'od_site_plan',
    OIIR_CHARGING_STATION : 'oiir_charging_station',
    OIIR_DANGER_IN_NEIGHBORHOOD : 'oiir_danger_in_neighborhood',
    OIIR_EXPLOSIVE_SUBSTANCE: 'oiir_explosive_substance',
    OIIR_COMPANY_GAS_STATION : 'oiir_company_gas_station',
    FIRE_PROTECTION_EQUIPMENT : 'Brandschutzeinrichtung_elektronik',
    ACQUISITION_INVOICE : 'Anschaffungsrechnung_elektronik',
    RISK_QUESTIONNAIRE : 'Risikofragebogen_elektronik',
    FIRE_PROTECTION_EQUIPMENT_STAT : 'Brandschutzeinrichtung_ma_stationary',
    ACQUISITION_INVOICE_STAT : 'Anschaffungsrechnung_ma_stationary',
    RISK_QUESTIONNAIRE_STAT : 'Risikofragebogen_ma_stationary',
    FIRE_PROTECTION_EQUIPMENT_MOBI : 'Brandschutzeinrichtung_ma_mobile',
    ACQUISITION_INVOICE_MOBI : 'Anschaffungsrechnung_ma_mobile',
    RISK_QUESTIONNAIRE_MOBI : 'Risikofragebogen_ma_mobile',
    PHOTOVOLTAIK: 'Abnahmeprotokoll',
    PHOTOVOLTAIK_ACQUISITION: 'Anschaffungsrechnung',
    PHOTOVOLTAIK_RISK: 'Risikofragebogen'
};
