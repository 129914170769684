import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadTypeConstants } from '@shared/constants/building.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { LocationConstants } from '@shared/constants/location.constants';
import {
  BuildingObject,
  Criteria,
  OtherIncreasesAtRisk,
} from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-other-increses-risk-location',
  templateUrl: './other-increses-risk-location.component.html',
  styleUrls: ['./other-increses-risk-location.component.css'],
})
export class OtherIncresesRiskLocationComponent implements OnInit {
  otherIncreasesInRiskForm: FormGroup;
  @Input('object_data') object_data;
  @Input('objectType') objectType;
  @Input('criteriaRequestDetails') criteriaRequestDetails;
  @Output('saveCriteriaRequest') saveCriteriaRequest = new EventEmitter<any>();
  @Input('isresetForm') isresetForm;

  browser: string;
  isFormValueUpdated: boolean;
  showDocumentChargingStationPopup = false;
  showDocumentIncreasedDangerPopup = false;
  showDocumentFlammablePopup = false;
  showDocumentCompanyGasPopup = false;
  selectedChargingStationDocumentsArray = {};
  selectedIncreasedDangerDocumentsArray = {};
  selectedFlammableDocumentsArray = {};
  selectedCompanyGasDocumentsArray = {};
  selectedDocumentsArray = {};
  uploadObjectDetails: UploadObjectDetails;
  chargingStationDocsIds = [];
  increaseDangerDocsIds = [];
  flammableDocsIds = [];
  companyGasDocsIds = [];
  request: BuildingObject;
  unit = [];
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    public validation: ValidationService,
    private serviceCall: ApiCallsService
  ) {
    this.browser = this.detectBrowser();
  }

  ngOnInit(): void {
    this.initialize();

    this.serviceCall.getLookupData(44).subscribe(data => {
      this.unit = data.data;
    });
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isFormValueUpdated && changes.isresetForm) {
      this.initialize();
    }
  }

  initialize() {
    const criteria: Criteria =
      this.criteriaRequestDetails &&
      this.criteriaRequestDetails.criteriaList[
        LocationConstants.OTHER_INCREASES_IN_RISK
      ];
    const formData = criteria && JSON.parse(criteria.saved_criteria);
    const record_id = criteria && criteria.record_id;

    const isChargingStationValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.is_charging_station
        : null,
      disabled: false,
    });
    const chargingStationDocumentsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.charging_station
            .charging_station_documents
        : null,
      disabled: false,
    });

    const isIncreasedInDangerValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.is_increased_in_danger
        : null,
      disabled: false,
    });
    const increaseInRiskDescriptionValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.increased_danger
            .increase_in_risk_description
        : null,
      disabled: false,
    });
    const increaseInRiskDocumentsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.increased_danger
            .increase_in_risk_documents
        : null,
      disabled: false,
    });

    const operating_mode_of_neighbourhood = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.increased_danger
            .operating_mode_of_neighbourhood
        : null,
      disabled: false,
    });
    const distance_of_hazardours_neighbourhood_in_km = new FormControl({
      value: formData
        ? this.validation.setSqmValue(
            formData.otherIncreasesInRisk.increased_danger
              .distance_of_hazardours_neighbourhood_in_km
          )
        : null,
      disabled: false,
    });
    const areFlammableSubstancesValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.are_flammable_substances
        : null,
      disabled: false,
    });

    const areFlammableSubstancesDescriptionValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.flammable_substance
            .are_flammable_substances_description
        : null,
      disabled: false,
    });
    const areFlammableSubstancesDocumentsValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.flammable_substance
            .are_flammable_substances_documents
        : null,
      disabled: false,
    });
    const type_of_flammable_or_explosive_substance = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.flammable_substance
            .type_of_flammable_or_explosive_substance
        : null,
      disabled: false,
    });
    const quantity_of_flammable_or_explosive_substance = new FormControl({
      value: formData
        ? this.validation.setSqmValue(
            formData.otherIncreasesInRisk.flammable_substance
              .quantity_of_flammable_or_explosive_substance
          )
        : null,
      disabled: false,
    });
    const unit = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.flammable_substance.unit
        : null,
      disabled: false,
    });
    const ls_labeled_safety_cabinets = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.flammable_substance
            .ls_labeled_safety_cabinets
        : null,
      disabled: false,
    });

    const isCompanyGasStationValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.is_company_gas_station
        : null,
      disabled: false,
    });
    const companyGasStationDocumentValidation = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.company_gas.company_gas_station_document
        : null,
      disabled: false,
    });
    const companyGasStationCapacityValidation = new FormControl({
      value: formData
        ? this.validation.setSqmValue(
            formData.otherIncreasesInRisk.company_gas
              .company_gas_station_capacity
          )
        : null,
      disabled: false,
    });

    const third_party_use = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thirdPartyUse?.third_party_use
        : null,
      disabled: false,
    });
    const type_of_Third_party_use = new FormControl({
      value: formData
        ? formData.otherIncreasesInRisk.thirdPartyUse?.type_of_Third_party_use
        : null,
      disabled: false,
    });

    this.otherIncreasesInRiskForm = new FormGroup({
      is_charging_station: isChargingStationValidation,
      charging_station_documents: chargingStationDocumentsValidation,

      is_increased_in_danger: isIncreasedInDangerValidation,
      increase_in_risk_description: increaseInRiskDescriptionValidation,
      increase_in_risk_documents: increaseInRiskDocumentsValidation,
      operating_mode_of_neighbourhood,
      distance_of_hazardours_neighbourhood_in_km,

      are_flammable_substances: areFlammableSubstancesValidation,
      are_flammable_substances_description:
        areFlammableSubstancesDescriptionValidation,
      are_flammable_substances_documents:
        areFlammableSubstancesDocumentsValidation,
      type_of_flammable_or_explosive_substance,
      quantity_of_flammable_or_explosive_substance,
      unit,
      ls_labeled_safety_cabinets,

      is_company_gas_station: isCompanyGasStationValidation,
      company_gas_station_document: companyGasStationDocumentValidation,
      company_gas_station_capacity: companyGasStationCapacityValidation,

      third_party_use,
      type_of_Third_party_use,
    });
    this.isFormValueUpdated = false;
    this.otherIncreasesInRiskForm.valueChanges.subscribe(obj => {
      this.isFormValueUpdated = true;
      this.buildFormObject();
      const pristine = this.otherIncreasesInRiskForm.pristine;
      this.saveCriteriaRequest.emit({
        request: this.request,
        isSave: false,
        pristine,
      });
    });
    this.getDocumentUploadData(record_id);
  }
  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 12)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (
                document.document_type ===
                UploadTypeConstants.OIIR_CHARGING_STATION
              ) {
                this.selectedChargingStationDocumentsArray[
                  document.document_name
                ] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.OIIR_DANGER_IN_NEIGHBORHOOD
              ) {
                this.selectedIncreasedDangerDocumentsArray[
                  document.document_name
                ] = document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.OIIR_EXPLOSIVE_SUBSTANCE
              ) {
                this.selectedFlammableDocumentsArray[document.document_name] =
                  document;
              }
              if (
                document.document_type ===
                UploadTypeConstants.OIIR_COMPANY_GAS_STATION
              ) {
                this.selectedCompanyGasDocumentsArray[document.document_name] =
                  document;
              }
            });
            if (data.documents) {
              this.otherIncreasesInRiskForm.controls.charging_station_documents.setValue(
                Object.keys(this.selectedChargingStationDocumentsArray).join(
                  ', '
                )
              );
              this.otherIncreasesInRiskForm.controls.increase_in_risk_documents.setValue(
                Object.keys(this.selectedIncreasedDangerDocumentsArray).join(
                  ', '
                )
              );
              this.otherIncreasesInRiskForm.controls.increase_in_risk_documents.setValue(
                Object.keys(this.selectedFlammableDocumentsArray).join(', ')
              );
              this.otherIncreasesInRiskForm.controls.increase_in_risk_documents.setValue(
                Object.keys(this.selectedCompanyGasDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }
  buildFormObject() {
    const ChargingStation = {
      charging_station_documents:
        this.otherIncreasesInRiskForm.value.charging_station_documents,
    };

    const IncreasedDanger = {
      increase_in_risk_description:
        this.otherIncreasesInRiskForm.value.increase_in_risk_description,
      increase_in_risk_documents:
        this.otherIncreasesInRiskForm.value.increase_in_risk_documents,
      operating_mode_of_neighbourhood: this.otherIncreasesInRiskForm.value
        .operating_mode_of_neighbourhood
        ? Number(
            this.validation.removeSymbols(
              this.otherIncreasesInRiskForm.value
                .operating_mode_of_neighbourhood
            )
          )
        : null,
      distance_of_hazardours_neighbourhood_in_km: this.otherIncreasesInRiskForm
        .value.distance_of_hazardours_neighbourhood_in_km
        ? Number(
            this.validation.removeSymbols(
              this.otherIncreasesInRiskForm.value
                .distance_of_hazardours_neighbourhood_in_km
            )
          )
        : null,
    };

    const FlammableSubstance = {
      are_flammable_substances_description:
        this.otherIncreasesInRiskForm.value
          .are_flammable_substances_description,
      are_flammable_substances_documents:
        this.otherIncreasesInRiskForm.value.are_flammable_substances_documents,
      type_of_flammable_or_explosive_substance:
        this.otherIncreasesInRiskForm.value
          .type_of_flammable_or_explosive_substance,
      quantity_of_flammable_or_explosive_substance: this
        .otherIncreasesInRiskForm.value
        .quantity_of_flammable_or_explosive_substance
        ? Number(
            this.validation.removeSymbols(
              this.otherIncreasesInRiskForm.value
                .quantity_of_flammable_or_explosive_substance
            )
          )
        : null,
      unit: this.otherIncreasesInRiskForm.value.unit
        ? this.otherIncreasesInRiskForm.value.unit
        : null,
      ls_labeled_safety_cabinets:
        this.otherIncreasesInRiskForm.value.ls_labeled_safety_cabinets,
    };

    const CompanyGas = {
      company_gas_station_document:
        this.otherIncreasesInRiskForm.value.company_gas_station_document,
      company_gas_station_capacity: this.otherIncreasesInRiskForm.value
        .company_gas_station_capacity
        ? Number(
            this.validation.removeSymbols(
              this.otherIncreasesInRiskForm.value.company_gas_station_capacity
            )
          )
        : null,
    };

    const ThirdPartyUse = {
      third_party_use: this.otherIncreasesInRiskForm.value.third_party_use,
      type_of_Third_party_use:
        this.otherIncreasesInRiskForm.value.type_of_Third_party_use,
    };

    const otherIncreasesInRisk: OtherIncreasesAtRisk = {
      is_charging_station:
        this.otherIncreasesInRiskForm.value.is_charging_station,
      charging_station: ChargingStation,
      is_increased_in_danger:
        this.otherIncreasesInRiskForm.value.is_increased_in_danger,
      increased_danger: IncreasedDanger,
      are_flammable_substances:
        this.otherIncreasesInRiskForm.value.are_flammable_substances,
      flammable_substance: FlammableSubstance,
      is_company_gas_station:
        this.otherIncreasesInRiskForm.value.is_company_gas_station,
      company_gas: CompanyGas,
      thirdPartyUse: ThirdPartyUse,
    };

    const formValuesToPersist = {
      otherIncreasesInRisk,
    };

    this.request = {
      document_ids: [
        ...new Set([
          ...this.chargingStationDocsIds,
          ...this.flammableDocsIds,
          ...this.increaseDangerDocsIds,
          ...this.companyGasDocsIds,
        ]),
      ],
      criteria_id: LocationConstants.OTHER_INCREASES_IN_RISK,
      jsonb: JSON.stringify(formValuesToPersist),
    };
  }
  ngOnDestroy() {
    if (this.isFormValueUpdated && this.otherIncreasesInRiskForm.touched) {
      this.isFormValueUpdated = false;
      this.saveCriteriaRequest.emit({ request: this.request, isSave: true });
    }
  }
  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  uploadChargingStationDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedChargingStationDocumentsArray,
    };
    this.showDocumentChargingStationPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.OIIR_CHARGING_STATION,
    };
  }
  uploadIncreasedDangerDocumentPopup() {
    this.selectedDocumentsArray = {
      ...this.selectedIncreasedDangerDocumentsArray,
    };
    this.showDocumentIncreasedDangerPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.OIIR_DANGER_IN_NEIGHBORHOOD,
    };
  }
  uploadFlammablDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedFlammableDocumentsArray };
    this.showDocumentFlammablePopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.OIIR_EXPLOSIVE_SUBSTANCE,
    };
  }
  uploadCompanyGasDocumentPopup() {
    this.selectedDocumentsArray = { ...this.selectedCompanyGasDocumentsArray };
    this.showDocumentCompanyGasPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 12,
      documentType: UploadTypeConstants.OIIR_COMPANY_GAS_STATION,
    };
  }
  showDocumentPopup() {
    return (
      this.showDocumentChargingStationPopup ||
      this.showDocumentIncreasedDangerPopup ||
      this.showDocumentFlammablePopup ||
      this.showDocumentCompanyGasPopup
    );
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showDocumentChargingStationPopup) {
      this.chargingStationDocsIds = [];
      this.selectedChargingStationDocumentsArray = {
        ...selectedDocumentsArray,
      };
      this.showDocumentChargingStationPopup = false;
      this.otherIncreasesInRiskForm.controls.charging_station_documents.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.charging_station_documents.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.chargingStationDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentIncreasedDangerPopup) {
      this.increaseDangerDocsIds = [];
      this.selectedIncreasedDangerDocumentsArray = {
        ...selectedDocumentsArray,
      };
      this.showDocumentIncreasedDangerPopup = false;
      this.otherIncreasesInRiskForm.controls.increase_in_risk_documents.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.increase_in_risk_documents.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.increaseDangerDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentFlammablePopup) {
      this.flammableDocsIds = [];
      this.selectedFlammableDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentFlammablePopup = false;
      this.otherIncreasesInRiskForm.controls.are_flammable_substances_documents.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.are_flammable_substances_documents.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.flammableDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    if (this.showDocumentCompanyGasPopup) {
      this.companyGasDocsIds = [];
      this.selectedCompanyGasDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentCompanyGasPopup = false;
      this.otherIncreasesInRiskForm.controls.company_gas_station_document.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.otherIncreasesInRiskForm.controls.company_gas_station_document.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.companyGasDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
    }
    this.buildFormObject();
    const pristine = !this.otherIncreasesInRiskForm.touched;
    this.saveCriteriaRequest.emit({
      request: this.request,
      isSave: false,
      pristine,
    });
    this.selectedDocumentsArray = {};
  }

  get unitName() {
    if (this.otherIncreasesInRiskForm?.value.unit && this.unit?.length) {
      return this.unit?.filter(
        _ => _.id == this.otherIncreasesInRiskForm?.value.unit
      )[0].desc;
    }
    return '';
  }
}
