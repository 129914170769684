import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  takeUntil,
} from 'rxjs/operators';
import { GenericCriteriaConstants } from '@shared/constants/generic.constants';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
import { Country } from '@shared/interfaces/country.interface';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css', '../new-object-module.component.css'],
})
export class VehicleComponent extends BaseComponent implements OnInit {
  vehicleForm: FormGroup;

  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();

  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  @Input('object_data') object_data;
  @Input('selectedLiztu') selectedliztu;

  vehicle_bodyType: any = [];
  vehicle_specialVehicle: any = [];
  vehicle_functionTypes: any = [];
  vehicle_allRiskIndex: any = [];
  vehicle_holderTypes: any = [];
  vehicle_manufacturer: any = [];
  vehicle_risk_indexFilter: any = [];
  vehicle_manufacturerFilter: any = [];
  pyload_unit: any = [];

  isChecked = false;
  isDateError = false;
  isDateInValid = false;

  today = new Date();
  actualnumber = 0;
  countries$: Observable<Country[]>;
  browser: string;

  riskIndexControl = new FormControl();
  vehicleManufacturerControl = new FormControl();

  disableRiskIndexes = [
    22, 23, 24, 25, 26, 27, 28, 29, 32, 33, 34, 36, 38, 39, 41, 42, 43,
  ];

  // Code added by Umesh
  public keyUp = new Subject<string>();
  showList = false;
  selectedLoc = '';
  // Code end here
  @ViewChild('riskIndexDrop', { static: false }) riskIndexDropdown: MatSelect;
  @ViewChild('manufacturerDD', { static: false })
  manufacturerDropdown: MatSelect;
  showDocumentAcquisitionPopup = false;
  showDocumentRegistrationPopup = false;
  showDocumentExpertPopup = false;
  selectedDocumentsArray = {};
  uploadObjectDetails: UploadObjectDetails;
  selectedAcquisitionDocumentsArray = {};
  selectedRegistrationDocumentsArray = {};
  selectedExpertDocumentsArray = {};
  uploaded_doc_registration = [];
  uploaded_doc_acquisition = [];
  uploaded_doc_expert = [];
  document_ids = [];
  sequence_number: number;
  drive_type = [];
  constructor(
    private serviceCall: ApiCallsService,
    private route: ActivatedRoute,
    public validation: ValidationService
  ) {
    super();

    this.riskIndexControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterOccupation(value);
      });

    this.vehicleManufacturerControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this._filterManufacturer(value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.vehicleForm && changes.selectedliztu) {
      this.vehicleForm.reset();
      this.getDropDownData();
    }
    if (this.vehicleForm && changes.objectType) {
      this.vehicleForm.patchValue({
        objectType: changes.objectType.currentValue,
      });
    }
    if (changes.resetForm) {
      this.onFormReset();
    }
  }

  onFormReset() {
    this.selectedRegistrationDocumentsArray = {};
    this.selectedExpertDocumentsArray = {};
    this.selectedAcquisitionDocumentsArray = {};
    this.selectedDocumentsArray = {};
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getDropDownData();
    this.browser = this.detectBrowser();
    // code added by umesh
    this.keyUp
      .pipe(
        map(event => event),
        debounceTime(1000),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(100))),
        takeUntil(this._destroy$)
      )
      .subscribe(this.autoComplete.bind(this));
    // ends here
  }

  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }

  getDropDownData() {
    this.countries$ = this.serviceCall.getCountries();
    const encoded_liztu = this.route.snapshot.queryParamMap.get('liztu');
    const liztu_vn_kdnr =
      this.selectedliztu != undefined
        ? this.selectedliztu
        : atob(encoded_liztu);
    const requestObject = [];
    requestObject.push(
      this.serviceCall.getLookupData('1').pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getLookupData('2').pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getLookupData('3').pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getLookupData('4').pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getLookupData('5').pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall
        .getSequenceNumber(liztu_vn_kdnr, '6', GenericCriteriaConstants[6])
        .pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getLookupData('7').pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getLookupData('42').pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getLookupData('45').pipe(catchError(error => of(error)))
    );

    forkJoin(requestObject)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.vehicle_specialVehicle =
          !data[0].hasErrors && data[0].data ? data[0].data : [];
        this.vehicle_functionTypes =
          !data[1].hasErrors && data[1].data ? data[1].data : [];
        this.vehicle_allRiskIndex =
          !data[2].hasErrors && data[2].data ? data[2].data : [];
        this.vehicle_bodyType =
          !data[3].hasErrors && data[3].data ? data[3].data : [];
        this.vehicle_holderTypes =
          !data[4].hasErrors && data[4].data ? data[4].data : [];
        this.sequence_number =
          !data[5].hasErrors && data[5].sequence_number
            ? data[5].sequence_number
            : '';
        this.vehicle_manufacturer =
          !data[6].hasErrors && data[6].data ? data[6].data : [];
        this.vehicle_risk_indexFilter =
          !data[2].hasErrors && data[2].data ? data[2].data : [];
        this.vehicle_manufacturerFilter =
          !data[6].hasErrors && data[6].data ? data[6].data : [];
        this.pyload_unit =
          !data[7].hasErrors && data[7].data ? data[7].data : [];
        this.drive_type =
          !data[8].hasErrors && data[8].data ? data[8].data : [];
        this.initializeForm();
      });
  }

  initializeForm() {
    const formData =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb
        ? JSON.parse(this.object_data.criteria_response.jsonb)
        : '';
    if (formData) {
      const record_id = this.object_data.criteria_response.record_id;
      this.getDocumentUploadData(record_id);
    }
    const objectTypeName = new FormControl('vehicle');
    const typeNameValidator = new FormControl(
      {
        value: this.object_data
          ? this.object_data.object_name
          : this.objectType,
        disabled: false,
      },
      []
    );
    const vehicle_sequenceNumberValidation = new FormControl(
      {
        value: formData ? formData.sequence_number : this.sequence_number,
        disabled: false,
      },
      [Validators.min(1), Validators.max(99999)]
    );
    const vehicle_riskIndexValidation = new FormControl(
      { value: formData ? formData.risk_index_tool_id : '', disabled: false },
      []
    );
    const vehicle_riskIndexValidationSelect = new FormControl(
      { value: formData ? formData.risk_index_tool_id : '', disabled: false },
      []
    );
    const vehicle_licenceValidation = new FormControl(
      { value: formData ? formData.license : '', disabled: false },
      []
    );
    const vehicle_manufacturerValidation = new FormControl(
      {
        value: formData ? formData.vehicle_manufacturer_id : '',
        disabled: false,
      },
      []
    );
    const vehicle_manufacturerValidationSelect = new FormControl(
      {
        value: formData ? formData.vehicle_manufacturer_id : '',
        disabled: false,
      },
      []
    );
    const vehicle_type_ModelValidation = new FormControl(
      { value: formData ? formData.model : '', disabled: false },
      []
    );
    const vehicle_plainTextValidation = new FormControl(
      { value: formData ? formData.description : '', disabled: false },
      []
    );
    const vehicle_powerOfEngineValidation = new FormControl(
      { value: formData ? formData.power_of_engine : '', disabled: false },
      [Validators.min(1), Validators.max(9999)]
    );
    const vehicle_powerTypeValidation = new FormControl(
      { value: formData ? formData.power_type : '', disabled: false },
      []
    );
    const vehicle_engineCapacityValidation = new FormControl(
      { value: formData ? formData.engine_capacity : '', disabled: false },
      [Validators.min(1), Validators.max(99999)]
    );
    const vehicle_identificationNumberValidation = new FormControl(
      {
        value: formData ? formData.vehicle_identification_number : '',
        disabled: false,
      },
      [Validators.pattern('[A-HJ-NPR-Z0-9]{17}')]
    );
    const vehicle_firstRegistrationValidation = new FormControl(
      {
        value: formData ? formData.first_registration_date : '',
        disabled: false,
      },
      []
    );
    const vehicle_payLoadinKGValidation = new FormControl(
      { value: formData ? formData.payload_value : '', disabled: true },
      [Validators.min(1), Validators.max(99999)]
    );
    const vehicle_payloadUnit = new FormControl(
      { value: formData ? formData.payload_unit : '', disabled: true },
      []
    );
    const vehicle_permissibleWeightValidation = new FormControl(
      {
        value: formData ? formData.permissible_total_weight : '',
        disabled: true,
      },
      [Validators.min(1), Validators.max(99999)]
    );
    const vehicle_bodyTypeValidation = new FormControl(
      { value: formData ? formData.body_type_id : '', disabled: true },
      []
    );
    const vehicle_specialVehicleValidation = new FormControl(
      { value: formData ? formData.special_vehicle_id : '', disabled: false },
      []
    );
    const vehicle_isSeasonalValidation = new FormControl(
      { value: formData ? formData.is_seasonal_vehicle : '', disabled: false },
      []
    );
    const vehicle_seasonStartValidation = new FormControl(
      {
        value: formData ? formData.season_start_month : '',
        disabled: formData
          ? formData.is_seasonal_vehicle == '' ||
            formData.is_seasonal_vehicle == false
            ? true
            : false
          : true,
      },
      [Validators.min(1), Validators.max(12)]
    );
    const vehicle_seasonEndValidation = new FormControl(
      {
        value: formData ? formData.season_end_month : '',
        disabled: formData ? !formData.is_seasonal_vehicle : true,
      },
      [Validators.min(1), Validators.max(12)]
    );
    const vehicle_functionTypeValidation = new FormControl(
      { value: formData ? formData.type_of_financing_id : '', disabled: false },
      []
    );
    const vehicle_holderTypeValidation = new FormControl(
      {
        value: formData ? formData.different_holder_type_id : '',
        disabled: false,
      },
      []
    );
    const vehicle_firstNameValidation = new FormControl(
      {
        value: formData ? formData.different_holder_first_name : '',
        disabled: false,
      },
      [
        Validators.pattern(
          '^[A-Za-zäöüäuÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ]*$'
        ),
      ]
    );
    const vehicle_lastNameValidation = new FormControl(
      {
        value: formData ? formData.different_holder_last_name : '',
        disabled: false,
      },
      [
        Validators.pattern(
          '^[A-Za-zäöüäuÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇ]*$'
        ),
      ]
    );
    const vehicle_holderstreetValidation = new FormControl(
      { value: formData ? formData.street : '', disabled: false },
      []
    );
    const vehicle_holderHouseNameValidation = new FormControl(
      { value: formData ? formData.house : '', disabled: false },
      []
    );
    const vehicle_postCodeValidation = new FormControl(
      { value: formData ? formData.postal_code : '', disabled: false },
      []
    );
    const vehicle_countryValidation = new FormControl(
      { value: formData ? formData.country : '', disabled: false },
      []
    );
    const vehicle_placeValidation = new FormControl(
      { value: formData ? formData.city : '', disabled: false },
      []
    );
    const differentHolder_value_Validation = new FormControl(
      { value: formData ? formData.is_different_holder : '', disabled: false },
      []
    );
    const registration_certificate = new FormControl({
      value: formData ? formData.registration_certificate : '',
      disabled: false,
    });
    const expert_report = new FormControl({
      value: formData ? formData.expert_report : '',
      disabled: false,
    });
    const acquisition_invoices = new FormControl({
      value: formData ? formData.acquisition_invoices : '',
      disabled: false,
    });
    const doc_id = new FormControl(
      {
        value: formData ? formData.doc_id : null,
        disabled: false,
      },
      []
    );
    const drive_typeValidation = new FormControl(
      { value: formData ? formData.drive_type : '', disabled: false },
      []
    );
    this.vehicleForm = new FormGroup({
      objectTypeName,
      vehicle_ObjecttypeName: objectTypeName,
      vehicle_newObjecttype: typeNameValidator,
      vehicle_sequenceNumber: vehicle_sequenceNumberValidation,
      vehicle_riskIndex: vehicle_riskIndexValidation,
      vehicle_riskIndex_select: vehicle_riskIndexValidationSelect,
      vehicle_licence: vehicle_licenceValidation,
      vehicle_manufacturer: vehicle_manufacturerValidation,
      vehicle_manufacturer_select: vehicle_manufacturerValidationSelect,
      vehicle_type_Model: vehicle_type_ModelValidation,
      vehicle_plainText: vehicle_plainTextValidation,
      vehicle_powerOfEngine: vehicle_powerOfEngineValidation,
      vehicle_powerType: vehicle_powerTypeValidation,
      vehicle_engineCapacity: vehicle_engineCapacityValidation,
      vehicle_identificationNumber: vehicle_identificationNumberValidation,
      vehicle_firstRegistration: vehicle_firstRegistrationValidation,
      vehicle_payLoadinKG: vehicle_payLoadinKGValidation,
      vehicle_payloadUnit,
      vehicle_permissibleWeight: vehicle_permissibleWeightValidation,
      vehicle_bodyType: vehicle_bodyTypeValidation,
      vehicle_specialVehicle: vehicle_specialVehicleValidation,
      vehicle_isSeasonal: vehicle_isSeasonalValidation,
      vehicle_seasonStart: vehicle_seasonStartValidation,
      vehicle_seasonEnd: vehicle_seasonEndValidation,
      vehicle_functionType: vehicle_functionTypeValidation,
      vehicle_holderType: vehicle_holderTypeValidation,
      vehicle_firstName: vehicle_firstNameValidation,
      vehicle_lastName: vehicle_lastNameValidation,
      vehicle_holderstreet: vehicle_holderstreetValidation,
      vehicle_holderHouseName: vehicle_holderHouseNameValidation,
      vehicle_postCode: vehicle_postCodeValidation,
      vehicle_place: vehicle_placeValidation,
      vehicle_country: vehicle_countryValidation,
      differentHolder_value: differentHolder_value_Validation,
      registration_certificate,
      expert_report,
      acquisition_invoices,
      doc_id,
      drive_type: drive_typeValidation,
    });
    this.vehicleForm.setValidators(null);
    if (formData) {
      const data = this.vehicle_risk_indexFilter.find(
        value => value.id == formData.risk_index_tool_id
      );
      if (data) {
        this.vehicleForm.patchValue({
          vehicle_riskIndex: data.id,
        });
        if (!this.disableRiskIndexes.includes(data.id)) {
          this.vehicleForm.get('vehicle_payLoadinKG').disable();
          this.vehicleForm.get('vehicle_payloadUnit').disable();
          this.vehicleForm.get('vehicle_permissibleWeight').disable();
          this.vehicleForm.get('vehicle_bodyType').disable();
        } else {
          this.vehicleForm.get('vehicle_payLoadinKG').enable();
          this.vehicleForm.get('vehicle_payloadUnit').enable();
          this.vehicleForm.get('vehicle_permissibleWeight').enable();
          this.vehicleForm.get('vehicle_bodyType').enable();
        }
      }
    }
    this.emitFormValueIfValid();
    this.vehicleForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(obj => {
        const formValues = this.vehicleForm.value;
        if (this.vehicleForm.invalid) {
          formValues.isInValid = this.vehicleForm.invalid;
          this.emitFormValue.emit(this.vehicleForm);
        } else {
          this.emitFormValueIfValid();
        }
      });
  }
  getDocumentUploadData(record_id) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, 6)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              if (document.document_type === 'Zulassungsbescheinigung') {
                this.selectedRegistrationDocumentsArray[
                  document.document_name
                ] = document;
                this.uploaded_doc_registration.push(
                  document.upload_document_id
                );
              }
              if (document.document_type === 'Gutachten') {
                this.selectedExpertDocumentsArray[document.document_name] =
                  document;
                this.uploaded_doc_expert.push(document.upload_document_id);
              }
              if (document.document_type === 'Anschaffungsrechnungen') {
                this.selectedAcquisitionDocumentsArray[document.document_name] =
                  document;
                this.uploaded_doc_acquisition.push(document.upload_document_id);
              }
              this.document_ids.push(document.upload_document_id);
            });
            this.vehicleForm.controls.doc_id.setValue(this.document_ids);
            if (data.documents) {
              this.vehicleForm.controls.registration_certificate.setValue(
                Object.keys(this.selectedRegistrationDocumentsArray).join(', ')
              );
              this.vehicleForm.controls.expert_report.setValue(
                Object.keys(this.selectedExpertDocumentsArray).join(', ')
              );
              this.vehicleForm.controls.acquisition_invoices.setValue(
                Object.keys(this.selectedAcquisitionDocumentsArray).join(', ')
              );
            }
          }
        });
    }
  }
  autoComplete(value) {
    const filterValue = value.target.value.toLowerCase();
    this.vehicle_risk_indexFilter = this.vehicle_allRiskIndex.filter(obj =>
      obj.desc.toLowerCase().includes(filterValue)
    );
    this.vehicle_manufacturerFilter = this.vehicle_manufacturer.filter(obj =>
      obj.desc.toLowerCase().includes(filterValue)
    );
  }

  onFocusOut(event) {
    const _that = this;
    setTimeout(() => {
      _that.showList = false;
    }, 200);
  }

  private _filterManufacturer(value: any) {
    if (value !== '') {
      if (
        this.vehicle_manufacturer.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.vehicle_manufacturerFilter = this.vehicle_manufacturer.filter(
          option => option.desc.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.vehicle_manufacturerFilter = this.vehicle_manufacturer;
      }
    } else {
      this.vehicle_manufacturerFilter = this.vehicle_manufacturer;
    }
  }
  private _filterOccupation(value: any) {
    if (value !== '') {
      if (
        this.vehicle_allRiskIndex.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.vehicle_risk_indexFilter = this.vehicle_allRiskIndex.filter(
          option => option.desc.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.vehicle_risk_indexFilter = this.vehicle_allRiskIndex;
      }
    } else {
      this.vehicle_risk_indexFilter = this.vehicle_allRiskIndex;
    }
  }
  occupationChanged(event) {
    this.riskIndexControl.setValue('');
    if (event.value == '' || event.value == null || event.value == undefined) {
      this.vehicleForm.patchValue({
        vehicle_riskIndex: '',
        vehicle_riskIndex_select: '',
      });
      this.vehicleForm.get('vehicle_payLoadinKG').enable();
      this.vehicleForm.get('vehicle_payloadUnit').enable();
      this.vehicleForm.get('vehicle_permissibleWeight').enable();
      this.vehicleForm.get('vehicle_bodyType').enable();
    } else {
      const data = this.vehicle_risk_indexFilter.find(
        value => value.id == event.value
      );
      this.vehicleForm.patchValue({
        vehicle_riskIndex: data.id,
      });

      if (!this.disableRiskIndexes.includes(data.id)) {
        this.vehicleForm.get('vehicle_payLoadinKG').disable();
        this.vehicleForm.get('vehicle_payloadUnit').disable();
        this.vehicleForm.get('vehicle_permissibleWeight').disable();
        this.vehicleForm.get('vehicle_bodyType').disable();
        this.vehicleForm.controls.vehicle_payLoadinKG.setValue('');
        this.vehicleForm.get('vehicle_payloadUnit').setValue('');
        this.vehicleForm.controls.vehicle_permissibleWeight.setValue('');
        this.vehicleForm.controls.vehicle_bodyType.setValue(null);
      } else {
        this.vehicleForm.get('vehicle_payLoadinKG').enable();
        this.vehicleForm.get('vehicle_payloadUnit').enable();
        this.vehicleForm.get('vehicle_permissibleWeight').enable();
        this.vehicleForm.get('vehicle_bodyType').enable();
      }
    }
    setTimeout(() => document.getElementById('riskIndexSearch').focus(), 200);
  }
  manufacturerChanged(event) {
    setTimeout(() => {
      this.manufacturerDropdown.focus();
    }, 300);
    if (event.value == '' || event.value == null || event.value == undefined) {
      this.vehicleForm.patchValue({
        vehicle_manufacturer: '',
        vehicle_manufacturer_select: '',
      });
    } else {
      const data = this.vehicle_manufacturerFilter.find(
        value => value.id == event.value
      );
      this.vehicleForm.patchValue({
        vehicle_manufacturer: data.id,
      });
    }
    this.vehicleManufacturerControl.setValue('');
  }

  isSeasonChange(value?: boolean) {
    if (value) {
      this.vehicleForm.get('vehicle_seasonStart').enable();
      this.vehicleForm.get('vehicle_seasonEnd').enable();
    } else {
      this.vehicleForm.get('vehicle_seasonStart').disable();
      this.vehicleForm.get('vehicle_seasonEnd').disable();
      this.vehicleForm.controls.vehicle_seasonStart.setValue(null);
      this.vehicleForm.controls.vehicle_seasonEnd.setValue(null);
    }
  }

  emitFormValueIfValid() {
    const regdate = this.vehicleForm.value.vehicle_firstRegistration;
    let date = regdate;
    if (Object.prototype.toString.call(date) === '[object Date]') {
      date = regdate.toLocaleDateString('pt-br').split('/').reverse().join('-');
    }
    const parsedResp =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb &&
      JSON.parse(this.object_data.criteria_response.jsonb);
    const formValues: any = {
      adderss_id: null,
      address: '',
      body_type_id: this.vehicleForm.value.vehicle_bodyType,
      city: this.vehicleForm.value.vehicle_place,
      country: this.vehicleForm.value.vehicle_country,
      description: this.vehicleForm.value.vehicle_plainText,
      different_address_id: parsedResp ? parsedResp.different_address_id : null,
      different_holder_first_name: this.vehicleForm.value.vehicle_firstName,
      different_holder_last_name: this.vehicleForm.value.vehicle_lastName,
      different_holder_type_id: this.vehicleForm.value.vehicle_holderType
        ? this.vehicleForm.value.vehicle_holderType
        : '',
      engine_capacity: this.vehicleForm.value.vehicle_engineCapacity,
      first_registration_date: date,
      house: this.vehicleForm.value.vehicle_holderHouseName,
      is_different_holder: this.vehicleForm.value.differentHolder_value,
      is_seasonal_vehicle: this.vehicleForm.value.vehicle_isSeasonal,
      license: this.vehicleForm.value.vehicle_licence,
      vehicle_manufacturer_id: this.vehicleForm.value.vehicle_manufacturer,
      model: this.vehicleForm.value.vehicle_type_Model,
      object_vehicle_id: parsedResp ? parsedResp.object_vehicle_id : null,
      payload_value: this.vehicleForm.value.vehicle_payLoadinKG
        ? this.vehicleForm.value.vehicle_payLoadinKG
        : '',
      payload_unit: this.vehicleForm.value.vehicle_payloadUnit
        ? this.vehicleForm.value.vehicle_payloadUnit
        : '',
      permissible_total_weight:
        this.vehicleForm.value.vehicle_permissibleWeight,
      postal_code: this.vehicleForm.value.vehicle_postCode,
      power_of_engine: this.vehicleForm.value.vehicle_powerOfEngine
        ? this.vehicleForm.value.vehicle_powerOfEngine
        : '',
      power_type: this.vehicleForm.value.vehicle_powerType
        ? this.vehicleForm.value.vehicle_powerType
        : '',
      risk_index_tool_id: this.vehicleForm.value.vehicle_riskIndex,
      season_end_month: this.vehicleForm.value.vehicle_seasonEnd
        ? this.vehicleForm.value.vehicle_seasonEnd
        : '',
      season_start_month: this.vehicleForm.value.vehicle_seasonStart
        ? this.vehicleForm.value.vehicle_seasonStart
        : '',
      sequence_number: +this.vehicleForm.value.vehicle_sequenceNumber,
      special_vehicle_id: this.vehicleForm.value.vehicle_specialVehicle,
      street: this.vehicleForm.value.vehicle_holderstreet,
      type_of_financing_id: this.vehicleForm.value.vehicle_functionType,
      vehicle_identification_number:
        this.vehicleForm.value.vehicle_identificationNumber,
      objectType: this.vehicleForm.value.vehicle_newObjecttype,
      objectTypeName: this.vehicleForm.value.vehicle_ObjecttypeName,
      registration_certificate: this.uploaded_doc_registration,
      expert_report: this.uploaded_doc_expert,
      acquisition_invoice: this.uploaded_doc_acquisition,
      document_ids: this.vehicleForm.value.doc_id,
      drive_type: this.vehicleForm.value.drive_type,
    };
    formValues.isInValid = this.vehicleForm.invalid;
    const formVal = {
      value: formValues,
      touched: this.vehicleForm.touched,
      pristine: this.vehicleForm.pristine,
    };
    this.emitFormValue.emit(formVal);
  }

  public hasFormError = (controlName: string, errorName: string): boolean => {
    return this.vehicleForm.controls[controlName].hasError(errorName);
  }

  typeNumber(event, key, length) {
    this.validation?.SequenceNumberValidation(event, key, length);
  }

  typeNumber1(event, key, length?) {
    length = length ? length : 5;
    if (key == 'keydown') {
      const charCode = event.which ? event.which : event.keyCode;
      if (
        charCode == '69' ||
        charCode == '189' ||
        charCode == '187' ||
        charCode == '190'
      ) {
        event.preventDefault();
      }
      if (event.target.value.length == 0) {
        if (charCode == '48' || charCode == '45') {
          event.preventDefault();
        }
      }
      if (event.target.value.length == length && event.code !== 'Backspace') {
        event.preventDefault();
      }
      if (event.code !== 'Backspace') {
        this.actualnumber = event.target.value.length;
      } else {
        this.actualnumber = this.actualnumber - 1;
      }
    } else {
      if (
        event.clipboardData.getData('text/plain') == 'e' ||
        event.clipboardData.getData('text/plain') == 'E' ||
        event.clipboardData.getData('text/plain').includes('e') ||
        event.clipboardData.getData('text/plain').includes('E') ||
        event.clipboardData.getData('text/plain') == '.' ||
        event.clipboardData.getData('text/plain').includes('.')
      ) {
        event.preventDefault();
      } else if (
        this.actualnumber == 0 &&
        event.clipboardData.getData('text/plain') < 1
      ) {
        event.preventDefault();
      } else {
        this.actualnumber = Number(
          this.actualnumber + event.clipboardData.getData('text/plain').length
        );
        if (this.actualnumber > length) {
          event.preventDefault();
        }
      }
    }
  }

  typeNumberBrowserSpecific(event, key, length?) {
    this.validation?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }

  nameValidation(e, length) {
    this.validation?.nameValidation(e, length);
  }

  onFocusOutDate(event) {
    const formValues = this.vehicleForm.value;
    const newDate = event.target.value;
    const todayDate = new Date();
    const earlyDate = new Date('01.01.1980');
    formValues.isInValid = false;

    if (todayDate.getTime() < newDate.getTime()) {
      this.isDateError = true;
      this.vehicleForm.controls.vehicle_firstRegistration.setValue(null);
      formValues.isInValid = false;
      formValues.vehicle_firstRegistration = null;
      this.emitFormValueIfValid();
    } else if (earlyDate.getTime() > newDate.getTime()) {
      this.isDateError = true;
      this.emitFormValueIfValid();
    } else {
      this.isDateError = false;
      formValues.isInValid = this.vehicleForm.invalid;
      this.emitFormValueIfValid();
    }
  }
  focusRiskIndexSearch() {
    setTimeout(() => document.getElementById('riskIndexSearch').focus(), 200);
  }
  focusManufacturerSearch() {
    setTimeout(
      () => document.getElementById('manufacturerSearch').focus(),
      200
    );
  }
  registration_certificate_popup() {
    this.selectedDocumentsArray = {
      ...this.selectedRegistrationDocumentsArray,
    };
    this.showDocumentRegistrationPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 6,
      documentType: 'Zulassungsbescheinigung_fahrzeug',
    };
  }
  expert_report_popup() {
    this.selectedDocumentsArray = { ...this.selectedExpertDocumentsArray };
    this.showDocumentExpertPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 6,
      documentType: 'Gutachten_fahrzeug',
    };
  }
  acquisition_invoices_popup() {
    this.selectedDocumentsArray = { ...this.selectedAcquisitionDocumentsArray };
    this.showDocumentAcquisitionPopup = true;
    this.uploadObjectDetails = {
      documentSource: this.objectType,
      objectTypeId: 6,
      documentType: 'Anschaffungsrechnungen',
    };
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showDocumentRegistrationPopup) {
      this.uploaded_doc_registration = [];
      this.selectedRegistrationDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentRegistrationPopup = false;
      this.vehicleForm.controls.registration_certificate.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.vehicleForm.controls.registration_certificate.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploaded_doc_registration.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.document_ids.push(...this.uploaded_doc_registration);
      this.vehicleForm.controls.doc_id.setValue(this.document_ids);
    }
    if (this.showDocumentExpertPopup) {
      this.uploaded_doc_expert = [];
      this.selectedExpertDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentExpertPopup = false;
      this.vehicleForm.controls.expert_report.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.vehicleForm.controls.expert_report.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploaded_doc_expert.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });

      this.document_ids.push(...this.uploaded_doc_expert);
      this.vehicleForm.controls.doc_id.setValue(this.document_ids);
    }
    if (this.showDocumentAcquisitionPopup) {
      this.uploaded_doc_acquisition = [];
      this.selectedAcquisitionDocumentsArray = { ...selectedDocumentsArray };
      this.showDocumentAcquisitionPopup = false;
      this.vehicleForm.controls.acquisition_invoices.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.vehicleForm.controls.acquisition_invoices.markAsTouched();
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.uploaded_doc_acquisition.push(
            selectedDocumentsArray[name].upload_document_id
          );
        }
      });
      this.document_ids.push(...this.uploaded_doc_acquisition);
      this.vehicleForm.controls.doc_id.setValue(this.document_ids);
    }
  }
  showDocumentPopup() {
    return (
      this.showDocumentAcquisitionPopup ||
      this.showDocumentExpertPopup ||
      this.showDocumentRegistrationPopup
    );
  }

  scrollValue(key) {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById(key).focus();
  }
  riskIndexReset() {
    this.vehicleForm.get('vehicle_payLoadinKG').disable();
    this.vehicleForm.get('vehicle_payloadUnit').disable();
    this.vehicleForm.get('vehicle_permissibleWeight').disable();
    this.vehicleForm.get('vehicle_bodyType').disable();
    this.vehicleForm.controls.vehicle_payLoadinKG.setValue('');
    this.vehicleForm.get('vehicle_payloadUnit').setValue('');
    this.vehicleForm.controls.vehicle_permissibleWeight.setValue('');
    this.vehicleForm.controls.vehicle_bodyType.setValue(null);
  }
}
