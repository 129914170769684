import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationConfig } from '../../../models/table.model';

@Component({
    selector: 'app-pagination',
    templateUrl: 'pagination.component.html',
    styleUrls: ['pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PaginationComponent {
    @Input() pagination: PaginationConfig;
    @Output() pageChanged: EventEmitter<number> = new EventEmitter();

    changePage(page: number): void {
        this.pageChanged.emit(page);
    }
}
