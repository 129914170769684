import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  PRIORITY_ICONS_PATH,
  PRIORITY_LABEL,
  Priorities,
} from '../../constants/priority.constants';

@Component({
  selector: 'app-priority',
  templateUrl: 'priority.component.html',
  styleUrls: ['priority.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityComponent implements OnInit, OnChanges {
  @Input() priority: Priorities = Priorities.HIGH;
  iconSrc = PRIORITY_ICONS_PATH[Priorities.HIGH];
  label = PRIORITY_LABEL[Priorities.HIGH];

  ngOnInit() {
    this._initPriority();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.priority?.currentValue !== changes.priority?.previousValue) {
      this._initPriority();
    }
  }

  private _initPriority(): void {
    this.iconSrc = PRIORITY_ICONS_PATH[this.priority];
    this.label = PRIORITY_LABEL[this.priority];
  }
}
