import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { BaseComponent } from '@shared/components/base.component';
import { GetDocumentsParams } from '@shared/components/documents-table/document-table.model';
import { DocumentSource } from '@shared/models/document-management.model';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClaimsShareService } from '../claims-share.service';

@Component({
  templateUrl: './claim-documents-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimDocumentsPageComponent
  extends BaseComponent
  implements OnInit
{
  private readonly _getDocumentsParams$: BehaviorSubject<GetDocumentsParams | null> =
    new BehaviorSubject<GetDocumentsParams | null>(null);
  readonly getDocumentsParams$ = this._getDocumentsParams$
    .asObservable()
    .pipe(filterNil());
  readonly isIvmSynced$ = this._claimsSharedService.isIvmSynced$;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _claimsSharedService: ClaimsShareService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchOnLiztuSa();
  }

  private _watchOnLiztuSa(): void {
    this._claimsSharedService.liztuSa$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(value => {
        this._getDocumentsParams$.next({
          source: DocumentSource.CLAIMS,
          VN_KDNR: atob(this._activatedRoute.snapshot.queryParams.liztu),
          VS_IDNR: null,
          SA_IDNR: '' + value,
          customerId: this._customerSharedService.getSelectedCustomerId(),
          policy: null,
          contractId: atob(this._activatedRoute.snapshot.queryParams.contract),
          claimId: atob(this._activatedRoute.snapshot.queryParams.claim),
          insurerId: null,
          intermediaryId: null,
        });
      });
  }
}
