<div class="table-actions" (click)="$event.stopPropagation()">
    <button class="table-actions__trigger">&#183; &#183; &#183;</button>
    <div class="table-actions__buttons">
        <button
            class="table-actions__button"
            *ngFor="let action of actions"
            [matTooltip]="action.label | translate"
            matTooltipPosition="above"
            [disabled]="action.disabled"
            (click)="clickAction(action.key)">
            <img alt="" class="table-actions__icon" [src]="action.icon">
        </button>
    </div>
</div>