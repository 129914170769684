import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'app-view-template-list',
  templateUrl: './view-template-list.component.html',
  styleUrls: ['./view-template-list.component.css'],
})
export class ViewTemplateListComponent {
  @Input('showTemplatePopup') showTemplatePopup;
  @Output('hide') hide = new EventEmitter<any>();
  @Input('available_templates') available_templates;
  @Input('selected_templates') selected_templates;
  @Input('product_info_id') product_info_id;
  availableTemplateArray = [];
  selectedTemplateArray = [];
  constructor(
    public _apiCallsService: ApiCallsService,
    public _toastService: ToastService
  ) {}
  ngOnChanges() {
    this.findOnlyNotSelectedtemplates();
    this.availableTemplateArray = this.available_templates;
    this.selectedTemplateArray = this.selected_templates;
  }
  findOnlyNotSelectedtemplates() {
    this.selected_templates.forEach(data => {
      const index = this.available_templates.findIndex(
        item => item.id == data.id
      );
      if (index != -1) {
        this.available_templates.splice(index, 1);
      }
    });
  }
  hidePopup() {
    this.hide.emit();
  }
  addWidget(key) {
    this.availableTemplateArray.forEach((value, index) => {
      if (value == key) {
        this.availableTemplateArray.splice(index, 1);
      }
    });
    this.selectedTemplateArray.push(key);
  }

  removeWidget(key) {
    this.selectedTemplateArray.forEach((value, index) => {
      if (value == key) {
        this.selectedTemplateArray.splice(index, 1);
      }
    });
    this.availableTemplateArray.push(key);
  }
  saveConfigurations() {
    const selected = [];
    this.selectedTemplateArray.forEach(data => {
      selected.push(data.id);
    });
    const data = {
      template_ids: selected,
      product_info_id: this.product_info_id,
    };
    this._apiCallsService.addTemplateConfig(data).subscribe(data => {
      this.showToastMessage(data.message);
      this.hidePopup();
    });
  }
  refreshData() {
    this._apiCallsService
      .getAllTemplates(this.product_info_id, true)
      .subscribe(data => {
        if (!data.HasErrors) {
          this.selected_templates = data.selected_templates;
          this.available_templates = data.available_templates;
          this.findOnlyNotSelectedtemplates();
          this.availableTemplateArray = this.available_templates;
          this.selectedTemplateArray = this.selected_templates;
        }
        this.showToastMessage(data.message);
      });
  }
  showToastMessage(message) {
    this._toastService.showToastMessage(document, message);
  }
}
