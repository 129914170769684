import { ChangeDetectorRef, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _moment from 'moment';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';
import { DocumentManagementApiService } from '@core/services/httpcalls/document-management-api.service';
import { DeleteService } from '@core/services/shared/delete.service';
import { ToastService } from '@core/services/toast.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { DeleteConfirmationResponse } from '@shared/interfaces/delete-confirm.interface';
import { ClaimsComponentFunctionDirective } from './claims.component-function';
import { ClaimElement } from './claims.component.model';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CustomerSharedService } from '@core/services/customer-shared.service';

@Directive()
export class ClaimsComponentService extends ClaimsComponentFunctionDirective {
  constructor(
    public serviceCall: ApiCallsService,
    public dateFormatPipe: DateFormatPipe,
    public translate: TranslateService,
    public changeDetectorRefs: ChangeDetectorRef,
    public claimsAPIService: ClaimsManagementApiService,
    public deleteService: DeleteService,
    public router: Router,
    public toastService: ToastService,
    public documentsApiService: DocumentManagementApiService,
    public dialog: MatDialog,
    public _customerSharedService: CustomerSharedService
  ) {
    super(
      serviceCall,
      dateFormatPipe,
      translate,
      changeDetectorRefs,
      claimsAPIService,
      deleteService,
      router,
      toastService,
      documentsApiService,
      dialog,
      _customerSharedService
    );
  }

  viewPaymentModal(liztu_sa_idnr) {
    this.openPaymentModal = true;
    this.serviceCall.getCliamPaymentBreakup(liztu_sa_idnr).subscribe(data => {
      this.formatClaimpaymentData(data);
    });
  }

  callGetClaims(claimData) {
    claimData.role = this.user_role;
    this.serviceCall
      .getClaims(claimData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (!data.error) {
          this.claim = data.claim_summary;
          if (this.isExportClicked) {
            this.exportExcel();
            this.isExportClicked = false;
          } else {
            if (this.tablePage == 1) {
              this.totalClaims = data.total_element;
              if (this.SchadenartAvailable.length == 0) {
                for (let i = 0; i < data.claim_types.length; i++) {
                  this.SchadenartAvailable.push(data.claim_types[i]);
                }
              }
              if (this.objectAvailable.length == 0) {
                for (let i = 0; i < data.claim_objects.length; i++) {
                  this.objectAvailable.push(data.claim_objects[i]);
                }
              }
              this.total_elements = data.total_element;
            } else {
              this.total_elements = this.totalClaims;
            }
            this.formatClaimData(data);
            this.claim = data.claim_summary;
          }
        }
      });
  }

  excelClaimDataCall(claimData: any) {
    claimData.role = this.user_role;
    this.serviceCall
      .getClaims(claimData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.claim = data.claim_summary;
        if (!data.error) {
          this.exportExcel();
          this.isExportClicked = false;
        }
      });
  }

  formatClaimData(ClaimItem) {
    this.total_elements =
      this.tablePage > 1 && ClaimItem.total_element == 0
        ? this.totalClaims
        : ClaimItem.total_element;
    this.ClaimData = [];
    this.dataLimit = ClaimItem?.claim_summary?.length;
    this.UniqueContract = ClaimItem.claim_summary;
    if (ClaimItem.claim_summary?.length > 0) {
      for (let i = 0; i < ClaimItem.claim_summary?.length; i++) {
        this.liztu_sa_idnr = ClaimItem.claim_summary[i].liztu_sa_idnr;
        const sortingDate = +ClaimItem.claim_summary[i].date
          .split('-')
          .join('');
        if (
          this.contract == ClaimItem.claim_summary[i].liztu_vs_idnr ||
          this.contract == ClaimItem.claim_summary[i].liztu_vn_kdnr
        ) {
          this.contract_number = ClaimItem.claim_summary[i].contract_number;
        }
        // following condition is only for 1 parameter which is excepting in contract variable for liztu_vn_kdnr from document navigation : that was a mistake.
        const tableData2: ClaimElement = {
          customer_name: ClaimItem.claim_summary[i].customer_name,
          customer_type: ClaimItem.claim_summary[i].customer_type,
          contract_id: ClaimItem.claim_summary[i].contract_id,
          damage_id: ClaimItem.claim_summary[i].claim_id,
          liztu_vs_idnr: ClaimItem.claim_summary[i].liztu_vs_idnr,
          liztu_sa_idnr: ClaimItem.claim_summary[i].liztu_sa_idnr,
          liztu_vn_kdnr: ClaimItem.claim_summary[i].liztu_vn_kdnr,
          contract_number: ClaimItem.claim_summary[i].contract_number,
          status: ClaimItem.claim_summary[i].status,
          document_link: ClaimItem.claim_summary[i].document_link,
          tls: ClaimItem.claim_summary[i].tls,
          kosten: ClaimItem.claim_summary[i].kosten,
          date: ClaimItem.claim_summary[i].date,
          sortingDate,
          group_row: '',
          grouped_row_item_count: 0,
          expand_row: '',
          sb: ClaimItem.claim_summary[i].sb,
          type: ClaimItem.claim_summary[i].type,
          location: ClaimItem.claim_summary[i].location,
          reserve: ClaimItem.claim_summary[i].reserve,
          payment: ClaimItem.claim_summary[i].payment,
          internal_number: ClaimItem.claim_summary[i].internal_number,
          external_number: ClaimItem.claim_summary[i].external_number,
          is_expandable: false,
          is_collapsible: false,
          currency_symbol: ClaimItem.claim_summary[i].currency_symbol,
          sparte_id: ClaimItem.claim_summary[i].sparte_id,
          object: ClaimItem.claim_summary[i].object,
          is_source_ivm: ClaimItem.claim_summary[i].is_source_ivm,
        };
        this.ClaimData.push(tableData2);
      }
      this.ClaimDataCount = this.ClaimData.length;
      this.dataSource.data = [...this.ClaimData];
      this.claimsOriginalDataCopy = JSON.parse(JSON.stringify(this.ClaimData));
      this.setAvailableStatusValues();
      this.changeDetectorRefs.detectChanges();
      this.empty = '';
    } else {
      this.ClaimDataCount = 0;
      this.statusAvailable = [];
      this.unique = [];
      this.dataSource.data = [];
      this.empty = '';
    }
  }
  setAvailableStatusValues(data?: Array<any>) {
    const claimsData = data ? data : this.claimsOriginalDataCopy;
    const allYears = [];
    this.unique = [];
    this.unique = [...new Set(claimsData.map(item => item.status))];
    const unique_date = [...new Set(claimsData.map(item => item.date))];
    for (let i = 0; i < unique_date.length; i++) {
      const splittedDate = this.splitDate(unique_date[i]);
      const year = splittedDate[0];
      allYears.push(year);
    }
    const uniqueYear = allYears.filter(this.onlyUnique);
    this.statusAvailable = [];
    this.yearAvailable = [];
    for (const value of this.unique) {
      const newObject = {
        value: true,
        label: value,
      };
      this.statusAvailable.push(newObject);
    }
    for (const year of uniqueYear) {
      const Year = {
        value: year,
        label: year,
      };
      this.yearAvailable.push(Year);
    }
    if (claimsData.length != 0) {
      this.ClaimDataCount = this.ClaimData.length;
    }
  }
  callClaimsAPI() {
    if (
      this.decoded_liztu == null &&
      this.decoded_contract == null &&
      this.decoded_date == null &&
      this.decoded_liztu_sa_idnr == null
    ) {
      this.loadClaimDataFlag = true;
      this.loadUserClaimData(this.contract_id_all, this.damage_date);
    } else if (
      this.decoded_liztu !== null &&
      this.decoded_contract == null &&
      this.decoded_date == null &&
      this.decoded_liztu_sa_idnr == null
    ) {
      this.getUserClaimsData = true;
      this.loadUserClaimData(
        this.contract_id_all,
        this.damage_date,
        this.customer,
        this.liztu
      );
    } else if (
      this.decoded_liztu !== null &&
      this.decoded_contract !== null &&
      this.decoded_date !== null
    ) {
      if (this.date == 'all') {
        this.date = null;
      }
      this.contract_id_all = this.contract;
      this.getUserClaimsData = true;
      this.loadUserClaimData(
        this.contract,
        this.date,
        this.customer,
        this.liztu
      );
    } else if (this.decoded_liztu_sa_idnr !== null) {
      this.loadDamageData(this.customer, this.liztu_sa_idnr_parameter);
    }
  }

  loadUserClaimData(
    allContract: any,
    damageDate: any,
    customer?: any,
    liztu?: any
  ) {
    const user_id = Number(this.userDataValue.user_profile.user_data.user_idnr);
    let claimData: any;
    claimData = {
      user_idnr: user_id,
      customer_id: customer
        ? customer
        : this.userDataValue.user_profile.customer_details.customer_id,
      liztu_vs_idnr: allContract,
      claim_date: damageDate,
      whole_group: this.isWholeGroup,
      is_source_ivm: this.userDataValue.user_profile.user_data.is_source_ivm,
      is_export: false,
    };
    if (liztu) {
      claimData.liztu_vn_kdnr = liztu;
    }
    if (this.isExportClicked) {
      claimData.is_export = true;
    } else {
      claimData.page_number = this.tablePage;
      claimData.no_of_records = this.selectedCountOfRows;
    }
    if (this.isCustomerUser) {
      claimData.login_customer =
        this.userDataValue.user_profile.customer_details.customer_id;
    }

    this.callGetClaims(claimData);
  }
  loadDamageData(customer_id: any, liztu_sa_idnr: string) {
    this.fromMoredocument = true;
    let claimData: any;
    claimData = {
      customer_id,
      liztu_sa_idnr,
    };
    if (this.isExportClicked) {
      claimData.is_export = true;
    } else {
      (claimData.page_number = this.tablePage),
        (claimData.no_of_records = this.selectedCountOfRows);
    }
    if (this.isCustomerUser) {
      claimData.login_customer =
        this.userDataValue.user_profile.customer_details.customer_id;
    }
    this.callGetClaims(claimData);
  }
  deleteClaim(deleteReq: DeleteConfirmationResponse) {
    this.showConfirmPopup = deleteReq?.showPopup;
    if (deleteReq.isAllowed) {
      this.delete(deleteReq.id);
    }
  }
  delete(id) {
    this._claimsAPIService
      .deleteClaim(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this._toastService.showToastMessage(document, response.message);
        this.callClaimsAPI();
      });
  }
  getFormsValue(value) {
    this.isFilterApplied = true;
    let claimData: any;
    if (null !== value) {
      if (
        !value.Jahr &&
        !value.Jahr2 &&
        !value.Schadenart &&
        !value.Objekt &&
        !value.KfzVerträge &&
        !value.einblenden &&
        !value.kosten &&
        !value.wholeGroup &&
        !value.contractNumber
      ) {
        value = null;
      }
      if (this.fromMoredocument) {
        claimData = {
          customer_id: this.customer,
          liztu_sa_idnr: this.liztu_sa_idnr_parameter,
          hide_vehicle: value.KfzVerträge,
          hide_closed: value.einblenden,
          claim_type: value.Schadenart,
          object: value.Objekt,
          from:
            null === value.Jahr || undefined === value.Jahr
              ? null
              : _moment(value.Jahr).format('YYYY-MM-DD'),
          to:
            null === value.Jahr2 || undefined === value.Jahr2
              ? null
              : _moment(value.Jahr2).format('YYYY-MM-DD'),
        };
        if (this.isExportClicked) {
          claimData.is_export = true;
        } else {
          claimData.page_number = this.tablePage;
          claimData.no_of_records = this.selectedCountOfRows;
        }
        if (this.isCustomerUser) {
          claimData.login_customer =
            this.userDataValue.user_profile.customer_details.customer_id;
        }
      } else if (this.loadClaimDataFlag) {
        this.uId = this.userDataValue.user_profile.user_data.user_idnr;
        this.is_source_ivm =
          this.userDataValue.user_profile.user_data.is_source_ivm;
        this.cId = this.userDataValue.user_profile.customer_details.customer_id;
        this.whole_group = this.isWholeGroup;
        claimData = {
          user_idnr: this.uId,
          customer_id: this.cId,
          liztu_vs_idnr: this.contract_id_all,
          claim_date: this.date,
          whole_group: this.isWholeGroup,
          is_source_ivm: this.is_source_ivm,
          hide_vehicle: value.KfzVerträge,
          hide_closed: value.einblenden,
          claim_type: value.Schadenart,
          object: value.Objekt,
          from:
            null === value.Jahr || undefined === value.Jahr
              ? null
              : _moment(value.Jahr).format('YYYY-MM-DD'),
          to:
            null === value.Jahr2 || undefined === value.Jahr2
              ? null
              : _moment(value.Jahr2).format('YYYY-MM-DD'),
        };
        if (this.isExportClicked) {
          claimData.is_export = true;
        } else {
          claimData.page_number = this.tablePage;
          claimData.no_of_records = this.selectedCountOfRows;
        }
        if (this.isCustomerUser) {
          claimData.login_customer =
            this.userDataValue.user_profile.customer_details.customer_id;
        }
      } else if (this.getUserClaimsData) {
        const user_id = Number(
          this.userDataValue.user_profile.user_data.user_idnr
        );
        claimData = {
          user_idnr: user_id,
          customer_id: this.customer,
          liztu_vs_idnr: this.contract_id_all,
          claim_date: this.date,
          whole_group: this.isWholeGroup,
          is_source_ivm:
            this.userDataValue.user_profile.user_data.is_source_ivm,
          liztu_vn_kdnr: this.liztu,
          hide_vehicle: value.KfzVerträge,
          hide_closed: value.einblenden,
          claim_type: value.Schadenart,
          object: value.Objekt,
          from:
            null === value.Jahr || undefined === value.Jahr
              ? null
              : _moment(value.Jahr).format('YYYY-MM-DD'),
          to:
            null === value.Jahr2 || undefined === value.Jahr2
              ? null
              : _moment(value.Jahr2).format('YYYY-MM-DD'),
        };
        if (this.isExportClicked) {
          claimData.is_export = true;
        } else {
          claimData.page_number = this.tablePage;
          claimData.no_of_records = this.selectedCountOfRows;
        }
        if (this.isCustomerUser) {
          claimData.login_customer =
            this.userDataValue.user_profile.customer_details.customer_id;
        }
      }
      this.callGetClaims(claimData);
    } else {
      if (this.fromMoredocument) {
        if (this.isExportClicked) {
          claimData = {
            customer_id: this.customer,
            liztu_sa_idnr: this.liztu_sa_idnr_parameter,
            whole_group: this.isWholeGroup,
            hide_vehicle: value.KfzVerträge,
            hide_closed: value.einblenden,
            claim_type: value.Schadenart,
            object: value.Objekt,
            from:
              null === value.Jahr || undefined === value.Jahr
                ? null
                : _moment(value.Jahr).format('YYYY-MM-DD'),
            to:
              null === value.Jahr2 || undefined === value.Jahr2
                ? null
                : _moment(value.Jahr2).format('YYYY-MM-DD'),
            is_export: true,
          };
        } else {
          claimData = {
            customer_id: this.customer,
            liztu_sa_idnr: this.liztu_sa_idnr_parameter,
            page_number: this.tablePage,
            no_of_records: this.selectedCountOfRows,
          };
        }
        if (this.isCustomerUser) {
          claimData.login_customer =
            this.userDataValue.user_profile.customer_details.customer_id;
        }
      } else if (this.loadClaimDataFlag) {
        this.uId = this.userDataValue.user_profile.user_data.user_idnr;
        this.is_source_ivm =
          this.userDataValue.user_profile.user_data.is_source_ivm;
        this.cId = this.userDataValue.user_profile.customer_details.customer_id;
        this.whole_group = this.isWholeGroup;
        claimData = {
          user_idnr: this.uId,
          customer_id: this.cId,
          liztu_vs_idnr: this.contract_id_all,
          claim_date: this.date,
          whole_group: this.isWholeGroup,
          is_source_ivm: this.is_source_ivm,
        };
        if (this.isExportClicked) {
          claimData.is_export = true;
        } else {
          claimData.page_number = this.tablePage;
          claimData.no_of_records = this.selectedCountOfRows;
        }
        if (this.isCustomerUser) {
          claimData.login_customer =
            this.userDataValue.user_profile.customer_details.customer_id;
        }
      } else if (this.getUserClaimsData) {
        const user_id = Number(
          this.userDataValue.user_profile.user_data.user_idnr
        );
        claimData = {
          user_idnr: user_id,
          customer_id: this.customer,
          liztu_vs_idnr: this.contract_id_all,
          claim_date: this.date,
          is_source_ivm:
            this.userDataValue.user_profile.user_data.is_source_ivm,
          liztu_vn_kdnr: this.liztu,
        };
        if (this.isExportClicked) {
          claimData.whole_group = this.isWholeGroup;
          claimData.is_export = true;
        } else {
          claimData.whole_group = false;
          claimData.page_number = this.tablePage;
          claimData.no_of_records = this.selectedCountOfRows;
        }
        if (this.isCustomerUser) {
          claimData.login_customer =
            this.userDataValue.user_profile.customer_details.customer_id;
        }
      }
      this.callGetClaims(claimData);
    }
  }
}
