<div class="modal modelBackground" [style.display]="'table'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content" style="border-radius: 8px;
  box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
  border: solid 1px #bbb;
  background-color: #fff;width:408px">
            <!-- Modal Header -->
            <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                    <mat-icon (click)="hideModal()">
                        <img alt="" height="65px" src="../../../../assets/images/Group 3235.svg">
                    </mat-icon>
                </div>
            </div>
            <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
                <span style="font-weight: 600;font-size: 21px;">{{'objectModule.Delete_popup_heading' |
                    translate}}</span>
                <br /><br />
                <span style="font-weight: 600;font-size: 16px;margin-top:5px">{{'objectModule.Delete_popup_docuemnt' |
                    translate}}</span>
            </div>
            <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                <button type="button" style="font-size: 15px;color: #272727;border-radius: 2px;padding: 5px 22px 5px 22px;
      border: solid 1px #272727;font-weight: 600;background:#fff;" mat-button (click)="hideModal()">
                    {{'contact-manager.NEIN_text' | translate}}</button>
                <button class="ml-2" type="button"
                    style="font-size: 15px;color: #272727;background: #ff9000;border-radius: 2px;padding: 5px 22px 5px 22px;font-weight: 600;border: solid 1px #ff9000;"
                    (click)="deleteDocument()" mat-button> {{'contact-manager.JAL‎ÖSCHEN_text' | translate}}</button>
            </div>
        </div>
    </div>
</div>