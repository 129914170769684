<div class="users-management">
    <h6 class="customer-management-page-heading">{{'user-data.user_management_label' | translate}}</h6>
    <mat-accordion>
      <mat-expansion-panel class="filter-section" (opened)="customerFilterToggle = true" (closed)="customerFilterToggle = false"
        [expanded]="customerFilterToggle" hideToggle>
        <mat-expansion-panel-header [expandedHeight]="customFilterExpandedHeight" class="filter-section-header p-unset">
          <mat-panel-title class="filter-section-title-alignment filter-title">
            <div class="flex-center g-7">
            <button class="action-btn">
              <mat-icon *ngIf="customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="!customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_right
              </mat-icon>
            </button>
            <span>
              <strong>FILTER</strong>
            </span>
            </div>
            <div class="paginator-dropdown-container" (click)="$event.stopPropagation()">
                <mat-select (selectionChange)="numberOfRowsChanged($event)" panelClass="rowChange"
                  class="paginator-dropdown filter-section-dropdown" [(ngModel)]="selectedCountOfRows"
                  disableOptionCentering>
                  <mat-option *ngFor="let row of numberOfRows" [value]="row">
                    {{row}}
                  </mat-option>
                </mat-select>
                <span class="results-per-page">
                  {{'claims.results_per_page' | translate}}
                </span>
              </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
          <form [formGroup]="filterCustomerForm">
            <div class="flex-common f-13">
              <div class="flex-center dropdowns-gap">
                <mat-select
                  [(ngModel)]="defaultSelectedCustomerValue"
                  placeholder="{{'dashboard.TOCHTERUNTERNEHMEN_text' | translate}}"
                  (openedChange)="searchCompany.value = ''"
                  formControlName="UnternehmenCustomer"
                  (selectionChange)="getSelectedCustomer($event); searchCompany.value = ''"
                  style="width: 230px;">
                  <input type="text" maxlength="20" class="searchSelect"
                    preventSpace
                    placeholder="{{'header.Suche_tooltip' | translate}}" #searchCompany>
                  <mat-option matTooltip="{{sub.customer_name}} - {{sub.type}}" [value]="sub"
                    *ngFor="let sub of validationService._filter(subsidiaries, 'customer_name', searchCompany.value)">
                    <span *ngIf="sub.user_name " class="noSafariTooltip">{{sub.customer_name}} -
                      {{sub.type | uppercase}} -
                      {{sub.user_name}}</span>
                    <span *ngIf="!sub.user_name " class="noSafariTooltip">{{sub.customer_name}} -
                      {{sub.type | uppercase}}</span>
                  </mat-option>
                </mat-select>
                <mat-select
                  (selectionChange)="getStatus($event)"
                  formControlName="Status"
                  [disabled]="statusDisabled"
                  matTooltip="{{'user-data.statusBlockText' | translate}}"
                  [matTooltipDisabled]="!statusDisabled">
                  <mat-option value="Status" selected>All</mat-option>
                  <mat-option [value]="statusValue.label" *ngFor="let statusValue of statusAvailable">
                    {{statusValue.label}}</mat-option>
                </mat-select>
              </div>
              <div class="flex-center dropdowns-gap">
                <button (click)="getCustFormsValue()" class="filterBtn" [disabled]="isDisabled">
                  {{'user-data.Filter_text' | translate}}
                </button>
                <button (click)="resetCustomerFilter()" class="filterBtn"
                  placement="top" ngbTooltip="{{'new-document.Filter_löschen' | translate}}"
                  tooltipClass="generic-tooltip">
                  <img alt="" src="assets/images/filter-section/refresh.png" style="height: 16px;">
                </button>
              </div>
            </div>
          </form>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="table-outer-container margin-top-10">
      <div class="infiniteScrollTable" style="height: 400px;">
        <mat-table class="custom-table" [dataSource]="dataSource">
          <ng-container matColumnDef="NAME">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'user-data.NAME_text' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="custom-ellipsis noSafariTooltip"
                [title]="element.user_name">{{element.user_name}}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef=" ">
            <mat-header-cell *matHeaderCellDef style="width: 52px;"></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="custom-pill byIvm" *ngIf="element.is_source_ivm == true">ERP</span>
              <span class="custom-pill byRcs" *ngIf="element.is_source_ivm == false">ICP</span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="COMPANY">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'user-data.customers_uppercase' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="custom-ellipsis noSafariTooltip"
                title="{{element.customer_name}}"> {{element.customer_name}} </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="COMPANY_TYPE">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'user-data.customer_type_uppercase' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="custom-ellipsis noSafariTooltip" [title]="element.type">{{element.type}}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ROLE">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'user-data.BENUTZERROLLE_text' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.role}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="EMAIL">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'user-data.EMAIL_text'| translate | uppercase}} </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" >
                <span class="custom-ellipsis noSafariTooltip" title="{{element.email}}">{{element.email}}</span> </mat-cell>
          </ng-container>
          <ng-container matColumnDef="STATUS">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'user-data.STATUS_text' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.status}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="LAST_LOGIN">
            <mat-header-cell *matHeaderCellDef>
              <span>{{'user-data.LETZTEANMELDUNG_text' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.last_login}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ACTION">
            <mat-header-cell *matHeaderCellDef style="text-align: center;">
                <span>{{'user-data.AKTION_text' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="control-section" style="text-align: center;">
                <div class="subscribe">
                  <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                  <div class="hide">
                    <div class="action-btn-align">
                      <button
                        [disabled]="!(isEditCustomer$ | async) || element.user_id == userid"
                        (click)="onEditClick(element)"
                        class="action-btn"
                        placement="top"
                        ngbTooltip="{{'user-data.BEARBEITEN_tooltip' | translate}}"
                        tooltipClass="generic-tooltip"
                      >
                          <img alt="" class="action-icon"
                            src='../../../assets/images/edit.svg'>
                      </button>
                      <button
                        class="action-btn"
                        [disabled]="!(isEditCustomer$ | async) || element.invite_user == true || (!isICUser && element.user_id == userid)"
                        (click)="resetInvite(element.user_id, element.role, element.type)"
                        placement="top"
                        ngbTooltip="{{'user-data.EINLADUNGZURÜCKSETZEN_tooltip' | translate}}"
                        tooltipClass="generic-tooltip"
                      >
                          <img alt="" class="action-icon"
                            src='../../../assets/images/reset.svg'>
                      </button>
                      <button
                        class="action-btn"
                        *ngIf="element.is_source_ivm == false"
                        (click)="deleteUser(element.user_id, element.role, element.type)"
                        placement="top"
                        ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                        tooltipClass="generic-tooltip"
                        [disabled]="!(isEditCustomer$ | async)"
                      >
                          <img alt="" class="action-icon"
                            src='../../../assets/images/delete.svg'>
                      </button>
                      <button
                        class="action-btn"
                        *ngIf="element.is_source_ivm == true"
                        (click)="deleteIvmUser()"
                        placement="top"
                        ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                        tooltipClass="generic-tooltip"
                        [disabled]="!(isEditCustomer$ | async)"
                      >
                          <img alt="" class="action-icon"
                            src='../../../assets/images/delete.svg'>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div class="table-footer">
        <div class="paginator-footer">
            <ngb-pagination [collectionSize]="total_elements" (pageChange)="tablePageChanged($event)" [maxSize]=5
              [(page)]="tablePage" [pageSize]="selectedCountOfRows" [boundaryLinks]="false">
              <ng-template ngbPaginationPrevious>
                <i class="fa fa-angle-left"></i>
              </ng-template>
              <ng-template ngbPaginationNext>
                <i class="fa fa-angle-right"></i>
              </ng-template>
            </ngb-pagination>
          <div class="footer-text">
            <span>{{ "customer-management.user_managment.Number_of_User" | translate }}{{ total_elements }}</span>
          </div>
        </div>
      </div>
    </div>
</div>
<app-delete-user *ngIf="showDeleteModal"
  [isIvm]="isIvm" [isResetInvite]="isResetInvite"
  (onHideModal)="onHideModal($event)" (onDelete)="deleteUserProcess($event)"
  (resetInvite)="invitationRevert($event)">
</app-delete-user>
