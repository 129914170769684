<div class="external-container">
    <div class="container-heading">
        <span class="criteria-heading">{{'product-management.step.summary' | translate}}</span>
    </div>
    <div class="container-body">
        <div class="count-container">
            <div class="top-container" id="menu-top-section">
              <button class="top-box">
                <div class="box-heading">
                  <span class="heading-text">{{'product-management.product_module_stepper_lables.Cover_Bundles' | translate}}</span>
                </div>
                <span class="box-count">{{coverBundleCount}}</span>
              </button>
            </div>
            <div style="margin-left: 5%;" class="top-container" id="menu-top-section">
                <button class="top-box">
                  <div class="box-heading">
                    <span class="heading-text">{{'product-management.step.covering' | translate}}</span>
                  </div>
                  <span class="box-count">{{coveringsCount}}</span>
                </button>
              </div>
              <div style="margin-left: 5%;" class="top-container" id="menu-top-section">
                <button class="top-box">
                  <div class="box-heading">
                    <span class="heading-text">{{'product-management.step.type_of_damages' | translate}}</span>
                  </div>
                  <span class="box-count">{{typeOfDamageCount}}</span>
                </button>
              </div>
        </div>
        <div class="product-table-container">
            <div class="product-table-inner" style="margin-right: 5px;">
                <table>
                    <thead>
                        <tr>
                            <th>{{'new-contract.general_information.property' | translate}}</th>
                            <th>{{'new-contract.general_information.available_value' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>{{'product-management.general_information.division' | translate}}</td>
                            <td class="custom-custom-ellipsis" title="{{division}}">{{division}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.sparte' | translate}}</td>
                            <td class="custom-ellipsis" title="{{sparteName}}">{{sparteName}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.product_name' | translate}}</td>
                            <td>{{product_name}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.public_label' | translate}}</td>
                            <td>{{public_label}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.Underwriting_insurer' | translate}}</td>
                            <td class="custom-ellipsis" title="{{Underwriting_insurer}}">{{Underwriting_insurer}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.versioning' | translate}}</td>
                            <td class="col-md-3 col-sm-6 col-6">
                                <div style="display: flex;">
                                    <mat-select class="dropdownStyle versioning-dropdown" [(ngModel)]="selected_product_state_id" id="version-dropdown"
                                        placeholder="{{'objectModule.select_lable' | translate}}" [disabled]="product_data && product_data.product_state_id == 3"
                                        (selectionChange)="onVersionChange()">
                                        <mat-option *ngFor="let item of product_state" title="{{item.desc}}" [value]="item.id">
                                            <span>{{item.desc}}</span>
                                        </mat-option>
                                    </mat-select>
                                    <input type="text" maxlength="5" class="input zursInputDateStyle" [(ngModel)] = "versioning"
                                        placeholder="{{'product-management.general_information.versioning' | translate}}" readonly>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.validity_indicator' | translate}} ( {{'product-management.general_information.from_text' | translate}} )</td>
                            <td>{{valid_from | dateFormat}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.validity_indicator' | translate}} ( {{'product-management.general_information.to_text' | translate}} )</td>
                            <td>{{valid_to | dateFormat}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.automatic_contract_renewal' | translate}}</td>
                            <td>{{automatic_contract_renewal}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.cancellation_period' | translate}}</td>
                            <td>{{cancelation_period_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="product-table-inner" style="margin-left: 5px;">
                <table>
                    <thead>
                        <tr>
                            <th>{{'new-contract.general_information.property' | translate}}</th>
                            <th>{{'new-contract.general_information.available_value' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{'product-management.general_information.country_code' | translate}}</td>
                            <td class="custom-ellipsis" title="{{country_code}}">{{country_code}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.avb' | translate}}</td>
                            <td><a class="addNew" (click)="uploadAVBDocumentPopup()">{{'claims.Dokumente_tooltip' | translate}} <img alt="" height="16px" src="../../../../assets/images/Download_Icon_bold.svg"></a></td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.side_agreements' | translate}}</td>
                            <td>{{side_agreements}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.selection_side_agreements' | translate}}</td>
                            <td><a class="addNew" *ngIf="side_agreements == 'Yes' || side_agreements == 'Ja'" (click)="uploadSideLettersPopup()">{{'claims.Dokumente_tooltip' | translate}} <img alt="" height="16px" src="../../../../assets/images/Download_Icon_bold.svg"></a></td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.area_of_coverage' | translate}}</td>
                            <td class="custom-ellipsis" title="{{area_of_coverage}}">{{area_of_coverage}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.objects_to_be_insured' | translate}}</td>
                            <td class="custom-ellipsis" title="{{insured_object}}">{{insured_object}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.split_declaration' | translate}}</td>
                            <td>{{split_declaration}}</td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.clauses_set_for_product' | translate}}</td>
                            <td><a class="addNew" (click)="addClauses()">{{'product-management.general_information.clauses' | translate}} <img alt="" height="13px" src="../../../../assets/images/Eye_show 2.svg"></a></td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.exclusion_for_product' | translate}}</td>
                            <td><a class="addNew" (click)="addExclusions()">{{'product-management.general_information.exclusions' | translate}} <img alt="" height="13px" src="../../../../assets/images/Eye_show 2.svg"></a></td>
                        </tr>
                        <tr>
                            <td>{{'product-management.general_information.product_description' | translate}}</td>
                            <td class="custom-ellipsis" title="{{product_description}}">{{product_description}}</td>
                        </tr>
                    </tbody>


                </table>
            </div>
        </div>
   </div>
    <span class="tips-class" *ngIf="selected_product_state_id == 2"><img alt="" class="tips-img" src="../../../../assets/images/tip_icon_summary.svg">{{'product-management.stepper_common_msg.update_product_to_active_warning_footer' | translate}}</span>
    <span class="tips-class" *ngIf="selected_product_state_id == 3"><img alt="" class="tips-img" src="../../../../assets/images/tip_icon_summary.svg">{{'product-management.stepper_common_msg.update_product_to_retired_warning_footer' | translate}}</span>
    <span class="tips-class" *ngIf="selected_product_state_id == 1"><img alt="" class="tips-img" src="../../../../assets/images/tip_icon_summary.svg">{{'product-management.stepper_common_msg.update_product_to_draft_warning_footer' | translate}}</span>
</div>
<app-upload-document [object_type]="uploadProductDetails" [is_disable]="is_disable"
    [selectedDocumentsArray]="selectedDocumentsArray" [showDocumentPopup]="showDocumentPopup()" (hide)="hidePopup($event)">
</app-upload-document>
<app-clauses-exclusions [showPopup]="showClausesExclusionPopup()" [comingFrom]="comingFrom" [is_disable]="is_disable" [data]="clausesOrExclusionData"
 (hidePopup)="hideClausesExclusionPopup()" [sparteFixerCode]="sparteFixerCode">
</app-clauses-exclusions>