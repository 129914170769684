<form class="row addressForm" [formGroup]="AddressForm" style="padding: 20px 25px; margin: 0;">
  <div class="col-md-6 col-sm-6 col-6" style="position:relative;">
    <label for="" class="label required" *ngIf="!(!AddressForm.controls['address'].valid &&
        (AddressForm.controls['address']?.touched || isSubmitClicked) && AddressForm.invalid)">
      {{'objectModule.new_selectAddress1' | translate}}</label>

    <label class="touched-warning" *ngIf="!AddressForm.controls['address'].valid
               && (AddressForm.controls['address']?.touched || isSubmitClicked)
               && AddressForm.invalid">
      {{'objectModule.new_selectAddress1_error' | translate}}
    </label>

    <input type="text" formControlName="address" (focus)="showList = true" (blur)="onFocusOut($event)" (keyup)="keyUp.next($event)" class="input" required autocomplete="off" style="padding-right: 30px;"
      placeholder="{{'objectModule.new_selectAddress1' | translate}}">
    <span class="flagIconContainer1">
      <img alt="" class="imgStyle" *ngIf="!isSelectedAddressChange" src="assets/images/ic-icons/Geolocation.svg">

      <img alt="" class="imgStyle" *ngIf="isSelectedAddressChange" src="assets/images/ic-icons/Geolocation.svg">

    </span>
    <ul class="statusFilterList" *ngIf="showList && filterlocation.length">
      <li class="listStyle" *ngFor="let location of filterlocation;index as i" (click)="selectedLocation(location)">
        {{location.freeformAddress}}
      </li>
    </ul>
  </div>
  <div class="col-md-6 col-sm-6 col-6">
    <label for="" class="label required" *ngIf="!(!AddressForm.controls['address_description'].valid &&
    (AddressForm.controls['address_description']?.touched || isSubmitClicked) && AddressForm.invalid)">
      {{'objectModule.new_address_desc' | translate}}</label>

    <label class="touched-warning" *ngIf="!AddressForm.controls['address_description'].valid
           && (AddressForm.controls['address_description']?.touched || isSubmitClicked)
           && AddressForm.invalid">
      {{'objectModule.new_address_desc_error' | translate}}
    </label>

    <input type="text" class="input" formControlName="address_description" placeholder="{{'objectModule.new_address_desc' | translate}}">
  </div>
  <div class="col-md-6 col-sm-6 col-6">

    <label for="" class="label required" *ngIf="!(!AddressForm.controls['house_number'].valid &&
    (AddressForm.controls['house_number']?.touched || isSubmitClicked) && AddressForm.invalid)">
      {{'objectModule.new_housenumber' | translate}}</label>

    <label class="touched-warning" *ngIf="!AddressForm.controls['house_number'].valid
           && (AddressForm.controls['house_number']?.touched || isSubmitClicked)
           && AddressForm.invalid">
      {{'objectModule.hausnummerRequire_validation' | translate}}
    </label>


    <input type="text" class="input" formControlName="house_number" placeholder="{{'objectModule.new_housenumber' | translate}}">
  </div>
  <div class="col-md-6 col-sm-6 col-6">

    <label for="" class="label required" *ngIf="!(!AddressForm.controls['postal_code'].valid &&
    (AddressForm.controls['postal_code']?.touched || isSubmitClicked) && AddressForm.invalid)">
      {{'objectModule.new_postCode' | translate}}</label>
    <label class="touched-warning" *ngIf="!AddressForm.controls['postal_code'].valid
           && (AddressForm.controls['postal_code']?.touched || isSubmitClicked)
           && AddressForm.invalid">
      {{'objectModule.postleitzahlRequire_validation' | translate}}
    </label>

    <input type="text" class="input" formControlName="postal_code" placeholder="{{'objectModule.new_postCode' | translate}}">
  </div>
  <div class="col-md-6 col-sm-6 col-6">

    <label for="" class="label required" *ngIf="!(!AddressForm.controls['street'].valid &&
    (AddressForm.controls['street']?.touched || isSubmitClicked) && AddressForm.invalid)">
      {{'objectModule.new_street' | translate}}</label>
    <label class="touched-warning" *ngIf="!AddressForm.controls['street'].valid
           && (AddressForm.controls['street']?.touched || isSubmitClicked)
           && AddressForm.invalid">
      {{'objectModule.straßeRequire_validation' | translate}}
    </label>

    <input type="text" class="input" formControlName="street" placeholder="{{'objectModule.new_street' | translate}}">
  </div>
  <div class="col-md-6 col-sm-6 col-6">
    <div class="smallInput">
      <label for="" class="label required" *ngIf="!(!AddressForm.controls['city'].valid &&
      (AddressForm.controls['city']?.touched || isSubmitClicked) && AddressForm.invalid)">
        {{'objectModule.new_City_label' | translate}}</label>
      <label class="touched-warning" *ngIf="!AddressForm.controls['city'].valid
             && (AddressForm.controls['city']?.touched || isSubmitClicked)
             && AddressForm.invalid">
        {{'objectModule.ortRequire_validation' | translate}}
      </label>


      <input type="text" class="input" formControlName="city" placeholder=" {{'objectModule.new_City_label' | translate}}">
    </div>
  </div>
  <div class="col-md-6 col-sm-6 col-6">

    <label for="" class="label required" *ngIf="!(!AddressForm.controls['country'].valid &&
          (AddressForm.controls['country']?.touched || isSubmitClicked) && AddressForm.invalid)">
      {{'objectModule.new_Country_label' | translate}}</label>
    <label class="touched-warning" *ngIf="!AddressForm.controls['country'].valid
                 && (AddressForm.controls['country']?.touched || isSubmitClicked)
                 && AddressForm.invalid">
      {{'objectModule.landRequire_validation' | translate}}
    </label>


    <mat-select class="dropdownStyle switcherDrpdwn inputy switcher" style="color: #272727;
                   border-radius: 3px;
                   height: 40px;
                   padding: 7px;
                   background-color: rgba(0, 0, 0, .04);
                   border: 1.5px solid #beb8b8c2;" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="country">
      <mat-option *ngFor="let country of countries$ | async" [value]="country.countryName">{{country.countryName}}</mat-option>
    </mat-select>

  </div>
</form>

<div class="footer">
  <button class="ml-2 " type="button" style="background: #ff9000;
   outline: none;
   border-color: #ff9000;
   box-shadow: none;
   padding: 0px 40px 0px 40px;
   border-radius: 2px;
   border-bottom: none;
   border-right: none;
   color: #312f29;
   font-size: 16px;
   border-style: none;" mat-button (click)="saveFormData()">
    {{'objectModule.new_save_address' | translate}}
  </button>
</div>
