export const ROUTES_PATH = {
  DASHBOARD: 'dashboard',
  CONTACT_MANAGER: 'contact_manager',
  USERS: 'users',
  CUSTOMER_MANAGEMENT: 'customer-management',
  INVITE_USER: 'invite-user',
  SETTINGS: 'settings',
  USER_PROFILE: 'user_profile',
  CLAIMS: 'claims',
  DASHBOARD_CONFIGURATION: 'dashboard-configuration',
  CUSTOMER_DASHBOARD_CONFIGURATION: 'customer-dashboard-configuration',
  SPARTE_MANAGEMENT: 'sparte-management',
  CUSTOMER_EDIT: 'customer-edit',
  MORE_DOCUMENT: 'more-document',
  REGISTER_CLAIM: 'register-claim',
  REGISTER_CLAIM_STEP_TWO: 'register-claim-step-two',
  REGISTER_CLAIM_STEP_THREE: 'register-claim-step-three',
  CONTRACT: 'contract',
  CLAIM_REPORTING: 'claim-reporting',
  OBJECT_MODULE: 'object-module',
  ADD_NEW_OBJECT: 'add-new-object',
  VALUE_MODULE: 'value-module',
  ADD_NEW_CONTRACT: 'add-new-contract',
  RCS_SETUP: 'rcs-setup',
  OTP: 'otp',
  PRODUCT_MANAGEMENT_EDIT: 'product-management-edit',
  CREATE_CLAIMS: 'create-new-claims',
  INSURER_MANAGEMENT: 'insurer-management',
  INSURER_OVERVIEW: '/insurer-management/overview',
  INSURER_MAPPING: '/insurer-management/mapping',
  CREATE_INSURER: '/insurer-management/new-insurer',
  INTERMEDIARY_MANAGEMENT: 'intermediary-management',
  RECENT_LOGINS: 'recent-user-logins',
  APPOINTMENTS_MANAGEMENT: '/todo-management/appointments',
  TASKS_MANAGEMENT: '/todo-management/tasks',
  DEEPLINK: 'deeplink',
};

export const TODO_MANAGEMENT_ROUTES = {
  GENERAL: 'todo-management',
  APPOINTMENTS: 'appointments',
  TASKS: 'tasks',
};

export enum CUSTOMER_MANAGEMENT_ROUTES {
  GENERAL_DATA = 'general-data',
  CUSTOMER_DETAILS = 'customer-details',
  CUSTOMER_RELATIONS = 'customer-relations',
  COMMUNICATION = 'communication',
  USER_MANAGEMENT = 'user-management',
  BANK_ACCOUNT_DETAILS = 'bank-account-details',
  CONTACT_PERSON = 'contact-person',
  DOCUMENT_MANAGEMENT = 'document-management',
  APPOINTMENTS = 'appointments',
  TASKS = 'tasks',
}

export const CUSTOMER_MANAGEMENT_ROUTES_ASSOCIATION = {
  GENERAL_DATA: CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA,
  CUSTOMER_DETAILS: CUSTOMER_MANAGEMENT_ROUTES.CUSTOMER_DETAILS,
  CUSTOMER_RELATIONS: CUSTOMER_MANAGEMENT_ROUTES.CUSTOMER_RELATIONS,
  COMMUNICATION: CUSTOMER_MANAGEMENT_ROUTES.COMMUNICATION,
  USER_MANAGEMENT: CUSTOMER_MANAGEMENT_ROUTES.USER_MANAGEMENT,
  BANK_ACCOUNT_DETAILS: CUSTOMER_MANAGEMENT_ROUTES.BANK_ACCOUNT_DETAILS,
  CONTACT_PERSON: CUSTOMER_MANAGEMENT_ROUTES.CONTACT_PERSON,
  DOCUMENT_MANAGEMENT: CUSTOMER_MANAGEMENT_ROUTES.DOCUMENT_MANAGEMENT,
  APPOINTMENTS: CUSTOMER_MANAGEMENT_ROUTES.APPOINTMENTS,
  TASKS: CUSTOMER_MANAGEMENT_ROUTES.TASKS,
};

export enum CONTRACTS_ROUTES {
  GENERAL_DATA = 'general-data',
  SUBORDINATE_CONTRACTS = 'subordinate-contracts',
  SPARTE_DETAILS = 'sparte-details',
  INSURER_DETAILS = 'insurer-details',
  PRODUCT_DECLARATION = 'product-declaration',
  INSURED_OBJECTS = 'insured-objects',
  DOCUMENT_MANAGEMENT = 'document-management',
  CLAIMS = 'contract-claims',
  CALCULATION = 'calculation',
  TASKS = 'tasks',
  APPOINTMENTS = 'appointments',
  SETTLEMENTS = 'settlements',
  TIME_TRACKING = 'time-tracking',
  HISTORYZATION = 'historyzation',
}

export const CONTRACT_ROUTES_ASSOCIATION = {
  GENERAL_DATA: CONTRACTS_ROUTES.GENERAL_DATA,
  SUBORDINATE_CONTRACTS: CONTRACTS_ROUTES.SUBORDINATE_CONTRACTS,
  SPARTE_DETAILS: CONTRACTS_ROUTES.SPARTE_DETAILS,
  INSURER_DETAILS: CONTRACTS_ROUTES.INSURER_DETAILS,
  PRODUCT_DECLARATION: CONTRACTS_ROUTES.PRODUCT_DECLARATION,
  INSURED_OBJECTS: CONTRACTS_ROUTES.INSURED_OBJECTS,
  DOCUMENT_MANAGEMENT: CONTRACTS_ROUTES.DOCUMENT_MANAGEMENT,
  CLAIMS: CONTRACTS_ROUTES.CLAIMS,
  CALCULATION: CONTRACTS_ROUTES.CALCULATION,
  TASKS: CONTRACTS_ROUTES.TASKS,
  APPOINTMENTS: CONTRACTS_ROUTES.APPOINTMENTS,
  SETTLEMENTS: CONTRACTS_ROUTES.SETTLEMENTS,
  TIME_TRACKING: CONTRACTS_ROUTES.TIME_TRACKING,
  HISTORYZATION: CONTRACTS_ROUTES.HISTORYZATION,
};

export enum CLAIM_ROUTES {
  GENERAL_DATA = 'general-data',
  DAMAGE_DETAILS = 'damage-details',
  DOCUMENT_MANAGEMENT = 'document-management',
  CONTACT_PERSON = 'contact-person',
  PAYMENT_MANAGEMENT = 'payment-management',
  APPOINTMENTS = 'appointments'
}

export const CLAIM_ROUTES_ASSOCIATION = {
  GENERAL_DATA: CLAIM_ROUTES.GENERAL_DATA,
  DAMAGE_DETAILS: CLAIM_ROUTES.GENERAL_DATA,
  DOCUMENT_MANAGEMENT: CLAIM_ROUTES.DOCUMENT_MANAGEMENT,
  CONTACT_PERSON: CLAIM_ROUTES.CONTACT_PERSON,
  PAYMENT_MANAGEMENT: CLAIM_ROUTES.PAYMENT_MANAGEMENT,
  APPOINTMENTS: CLAIM_ROUTES.APPOINTMENTS
};
