<div class="d-flex action-bar flex-grow-1 gap-10">
    <ng-container *ngFor="let action of leftActions">
      <button class="action-bar__button"
        *ngIf="!action.hidden"
        (click)="clickAction(action.key)" [class.action-bar__button--disabled]="action.disabled">
        <img alt="action" [src]="action.imgSrc">
        <span>{{action.desc}}</span>
      </button>
    </ng-container>

    <mat-divider
        *ngIf="rightActions.length"
        [vertical]="true">
    </mat-divider>

    <ng-container *ngFor="let action of rightActions">
      <button class="action-bar__button"
        *ngIf="!action.hidden"
        (click)="clickAction(action.key)" [class.action-bar__button--disabled]="action.disabled">
        <img alt="action" [src]="action.imgSrc">
        <span>{{action.desc}}</span>
      </button>
    </ng-container>
</div>