import { ENVIRONMENT_URLS } from '@shared/constants/string.constants';

// dev
export class URLS {
  public static ic_kc_url: string = ENVIRONMENT_URLS.DEV_KC;
  public static ic_kc_realm = 'artus';
  public static ic_kc_client = 'angular-app';
  public static ic_logout_url = `${ENVIRONMENT_URLS.DEV}/realms/artus/protocol/openid-connect/logout?redirect_uri=${ENVIRONMENT_URLS.DEV}`;
  public static storage_container_url =
    'ass';
  public static help_document_url_artus_employees =
    '/help_documents/artus_employees/artus_employees.pdf';
  public static help_document_url_artus_customers =
    '/help_documents/artus_customers/artus_customers.pdf';
}

export const TASK_OFFICE_URL =
  'https://tasks.office.com/artus-gruppe.com/Home/Task/';

export class Api_URL {
  public static IC_API_URL: string = ENVIRONMENT_URLS.DEV_API_URL;
  // public static IC_API_URL = 'http://localhost:8082';
  public static RESET_PASSWORD = `${ENVIRONMENT_URLS.DEV}/realms/artus/account/credentials/password`;
}
