<div class="company-details">
    <div class="contracts-tabs-heading">
        <h6 class="customer-management-page-heading">{{'customer-management.left-panel.company-details' | translate}}</h6>
    </div>
    <div class="company-details-form">
        <form 
            *ngIf="customerDetailsForm" 
            class="general-form product-module-html" 
            [formGroup]="customerDetailsForm" 
            id="form">
            <div class="row">
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.Homepage_label' | translate}}</label><br/>
                    <input type="text" class="input" formControlName="homePage" placeholder="{{'master-data.Homepage_label' | translate}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.company-details.business-description' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" formControlName="businessDesc" title="{{customerDetailsForm.controls['businessDesc'].value}}" placeholder="{{'customer-management.company-details.business-description' | translate}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.Companydescription(Short)_label' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="300" title="{{customerDetailsForm.controls['customerDescShort'].value}}" formControlName="customerDescShort" placeholder="{{'master-data.Companydescription(Short)_label' | translate}}">
                    <div class="long-character-container">
                        <span class="short-character-left">{{ 300 -
                            customerDetailsForm.controls['customerDescShort'].value?.length }} {{'master-data.characters_left'
                          | translate}}</span>
                      </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.CompanyDescription(Long)_label' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="2000" title="{{customerDetailsForm.controls['customerDescLong'].value}}" formControlName="customerDescLong" placeholder="{{'master-data.CompanyDescription(Long)_label' | translate}}">
                    <div class="long-character-container">
                        <span class="long-character-left">{{ 2000 -
                            customerDetailsForm.controls['customerDescLong'].value?.length }} {{'master-data.characters_left' |
                          translate}}</span>
                      </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'customer-management.company-details.Type-of-business' | translate}}</label><br/>
                    <mat-select class="dropdownStyle"
                        #operatingModeControl (openedChange)="operatingModeFormControl.setValue('')"
                        (click)="focusoperatingModeSearch()" (keyup.enter)="focusoperatingModeSearch()" (selectionChange)="valueChanged()"
                            placeholder="{{'objectModule.select_lable' | translate}}" formControlName="businessType" >
                            <input type="text" id="operatingModeSearch" class="searchYear" maxlength="20" autocomplete="off"
                            placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                            [formControl]="operatingModeFormControl">
                            <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                            <mat-option *ngFor="let ele of operatingModeListFiltered" [value]="ele.id" matTooltip="{{ele.desc}}">{{ele.desc}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.DunsNumber_label' | translate}}</label><br/>
                    <input type="text" class="input" formControlName="duns" placeholder="{{'master-data.DunsNumber_label' | translate}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.BranchName_label' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" title="{{customerDetailsForm.controls['brnachName'].value}}" formControlName="brnachName" placeholder="{{'master-data.BranchName_label' | translate}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.Legalform_label' | translate}}</label><br/>
                    <mat-select class="dropdownStyle" #legalFormControl (openedChange)="LegalFormControl.setValue('')"
                    (click)="focuslegalFormSearch()" (keyup.enter)="focuslegalFormSearch()" (selectionChange)="valueChangedLegalForm()"
                     placeholder="{{'objectModule.select_lable' | translate}}" formControlName="legalForm">
                     <input type="text" id="legalFormSearch" class="searchYear" maxlength="20" autocomplete="off"
                     placeholder="{{'header.Suche_tooltip' | translate}}" matInput
                     [formControl]="LegalFormControl">
                    <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
                    <mat-option [value]="option.desc" *ngFor="let option of legalFormData">{{option.desc}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.Vatnumber_label' | translate}}</label><br/>
                    <input type="text" class="input" formControlName="vatNumber" placeholder="{{'master-data.Vatnumber_label' | translate}}">
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" *ngIf="!turnoverError" style="text-transform: initial;">{{'master-data.Turnover_label'
                        | translate}}</label>
                    <label for="" class="label touched-warning" *ngIf="turnoverError" style="text-transform: initial;"> {{'master-data.Turnover_validation'
                                  | translate}}</label>
                        <br/>
                    <input type="text" class="input" (blur)="turnoverValidation($event)" formControlName="Turnover" placeholder="{{'master-data.Turnover_label'
                    | translate}}">
                </div>
                <div class="col-md-3 col-sm-3 datePicker">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.company_establishment' | translate}}</label><br/>
                    <mat-form-field class="date-picker" appearance="fill" id="datePicker-field">
                        <span>
                          <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                        </span>
                        <input matInput [matDatepicker]="datepicker3"  id="myInput" (keypress)="dateChange($event)"
                        (paste)="dateChange($event)" (blur)="onDateBlur($event)" class="date-input" autocomplete="off" placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" formControlName="comanyEstablishment" [max]="today">
                        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker3">
                          <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                            {{'claims.keyboard-arrow-down_text' | translate}}
                          </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker3></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.Commercialregisternumber_label' | translate}}</label><br/>
                    <input type="text" class="input" maxlength="100" title="{{customerDetailsForm.controls['commercialNumber'].value}}" formControlName="commercialNumber" placeholder="{{'master-data.Commercialregisternumber_label' | translate}}">
                </div>
                <div class="col-md-3 col-sm-3 datePicker">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.Lastannualmeeting_label'
                        | translate}}</label><br/>
                    <mat-form-field class="date-picker" appearance="fill" id="datePicker-field">
                        <span>
                          <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                        </span>
                        <input matInput [matDatepicker]="datepicker1"  id="myInput" (dateChange)="setValidFromDate($event.value)" class="date-input" autocomplete="off" placeholder="{{'claim-reporting.datePlaceHolder' | translate}}" formControlName="lastAnnualMeeting" [max]="today">
                        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker1">
                          <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                            {{'claims.keyboard-arrow-down_text' | translate}}
                          </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker1></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 datePicker">
                    <label for="" class="label" *ngIf="!fiscalYearError" style="text-transform: initial;">{{'master-data.Fiscal_year' | translate}}</label>
                    <label for="" class="label touched-warning" *ngIf="fiscalYearError" style="text-transform: initial;" class="touched-warning">
                              {{'master-data.Fiscal_year' | translate}} : {{'objectModule.Invalid_date' | translate}}
                            </label>
                    <br/>
                    <mat-form-field class="date-picker" appearance="fill" id="datePicker-field">
                        <span>
                          <img alt="" class="calendar-icon" src="assets/images/ic-icons/Calendar.svg">
                        </span>
                        <input
                            matInput
                            pattern="[0-9.]*"
                            id="myInput"
                            (keypress)="fiscalYearValidation($event)"
                            (blur)="onFiscalYearBlur($event)"
                            class="date-input"
                            autocomplete="off"
                            placeholder="{{'claim-reporting.fiscalPlaceholder' | translate}}"
                            [formControl]="fiscalYearDummy">
                         <input
                            matInput
                            [matDatepicker]="datepicker2"
                            formControlName="fiscaYear"
                            class="date-input"
                            [min]="minDate"
                            [max]="maxDate"
                            style="display: none;"
                            (dateChange)="fiscalYearChange(datepicker2)">
                        <mat-datepicker-toggle style="height:0px;width: 0px;" matSuffix [for]="datepicker2">
                          <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                            {{'claims.keyboard-arrow-down_text' | translate}}
                          </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #datepicker2 panelClass="fiscal_year_selector"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 radioButton">
                    <label for="" class="label" style="text-transform: initial;">{{'master-data.Seasonal_operation' | translate}}</label><br/>
                    <mat-radio-group formControlName="seasonalBusiness">
                        <mat-radio-button value="true">
                            <span>
                                {{'objectModule.Yes' | translate}}
                            </span>
                        </mat-radio-button>
                        <mat-radio-button value="false">
                            <span>
                                {{'objectModule.No' | translate}}
                            </span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </form>
    </div>

</div>
<div class="save-container">
    <button class="custom-btn save-btn" (click)="saveCustomer()" [disabled]="!editCustomer">
        {{'update-customer.Speichern_text' | translate}}
    </button>
</div>
<app-warning-popup *ngIf="showWarningPopUp" [customerWarningMsg]="customerWarningMsg" (savedata)="saveCustomerData($event)" (handlePopup)="handleWarningPopup($event)"></app-warning-popup>
