import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { ProductModuleConstants } from '@shared/constants/global-constants.constants';
import { USER_ROLES } from '@shared/constants/roles.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { LookupConstants } from '@shared/constants/lookup.constants';
import {
  LOOKUP,
  StepperLookupConstants,
  UploadTypeConstants,
} from '@shared/constants/product-management.constants';
import { ProductSaveRequest } from '@shared/models/product-management.model';
import { UserService } from '@core/user.service';
import { BaseComponent } from '@shared/components/base.component';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import { ProductShareService } from '@core/services/product-share.service';

export interface Stepper1Request {
  is_ivm_product: boolean;
  product_info_id: number;
  division_id: string;
  division_names: string[];
  sparte_id: number;
  product_name: string;
  public_label: string;
  insurer_id: number;
  insurer_names: string[];
  product_state_id: number;
  version_no: number;
  valid_from: string;
  valid_to: string;
  auto_contract_renew: boolean;
  cancellation_period_id: number;
  country_code: number;
  is_side_agreement: boolean;
  coverage_area: number;
  object_type_id: number;
  is_split_declaration: boolean;
  clauses: number[];
  exclusions: number[];
  product_desc: string;
  is_update: boolean;
  is_form_save: boolean;
  created_by: number;
  updated_by: number;
  document_ids: string[];
  coverage_type: number;
}

export interface ClausesExclusionsData {
  data: [];
  selected_clauses: any[];
  selected_exclusions: any[];
}

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.css'],
})
export class NewProductComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  generalInformationForm: FormGroup;
  user_role: USER_ROLES;
  userRoles = USER_ROLES;
  insurers: any[];
  documents_list: any[];
  userDataValue: LoggedInUser;
  user_id: any;
  divisions: any[];
  division_id: any;
  parent_id: any;
  spartes: any[];
  product_state: any[];
  cancellation_period: any[];
  countries: any[];
  coverage_area: any[];
  object_types: any[];
  coverage_type: any[] = [];
  product_data;
  browser: string;
  showAVBPopup = false;
  comingFrom;
  uploadProductDetails: UploadObjectDetails;
  originalDocumentIds: any[];
  @ViewChild('divisionControl', { static: true }) divisionControl: MatSelect;
  @ViewChild('sparteControl', { static: true }) sparteControl: MatSelect;
  @ViewChild('insurerControl', { static: true }) insurerControl: MatSelect;
  @ViewChild('countryCodeControl', { static: true })
  countryCodeControl: MatSelect;
  @ViewChild('areaOfCoverageControl', { static: true })
  areaOfCoverageControl: MatSelect;
  @ViewChild('objectsToBeInsuredControl', { static: true })
  objectsToBeInsuredControl: MatSelect;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Output('originalFormData') originalFormData = new EventEmitter<any>();
  @Input('isFormSubmitted') isFormSubmitted: boolean;
  product_info_id = null;
  request: { document_ids: unknown[] };
  showAVBDocumentPopup = false;
  showsideLettersPopup = false;
  selectedAVBDocumentsArray = {};
  selectedSideLettersArray = {};
  selectedDocumentsArray = {};
  sideLettersDocsIds = [];
  sideLettersInsurerIds = [];
  avbDocsIds = [];
  clausesIds = [];
  exclusionsIds = [];
  uploadObjectDetails: UploadObjectDetails;
  selected_insurer_list;
  selected_document_list;
  @Input('object_data') parentObjectData;
  error: boolean;
  division_ids_edit_non_IC_USERs;
  addClause = false;
  addExclusion = false;
  clausesOrExclusionData: ClausesExclusionsData;
  sparteChangedDialog = false;
  sparteFixerCode;
  selectedSparte;
  selectedSparteFixerCode;
  clauses;
  exclusions;
  isFormUpdated = false;
  division_list: any;
  constructor(
    private fb: FormBuilder,
    private serviceCall: ApiCallsService,
    public validationService: ValidationService,
    private _apiService: ApiCallsService,
    private _userService: UserService,
    private _productShareService: ProductShareService
  ) {
    super();
    this.browser = this.detectBrowser();
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  ngOnInit(): void {
    this.userDataValue = this._userService.getLoggedInUser();
    this.documents_list = [
      { desc: UploadTypeConstants.AVB },
      { desc: UploadTypeConstants.RVB },
      { desc: UploadTypeConstants.A },
      { desc: UploadTypeConstants.IZD },
    ];
    this.isFormUpdated = false;
    this.user_id = this.userDataValue.user_profile.user_data.user_id;
    this.division_id =
      this.userDataValue.user_profile.customer_details.division_id;
    // previously we were sending parent id and its value was null so sending null here as customer_id.
    this.parent_id = null;
    this.user_role = this._userService.getUserRole();
    this.serviceCall
      .getDivision(this.user_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.divisions = data.divisions;
      });
    this.serviceCall
      .getSparte(this.division_id, this.parent_id, this.user_role)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.sparte.length) {
          this.spartes = data.sparte.filter(
            object => object.is_sparte_group == false
          );
          const indexOfAlle = this.spartes.findIndex(
            data => data.fixer_code == 0
          );
          this.spartes.splice(indexOfAlle, 1);
        }
      });
    this.initializeForm();
    const requestObject = [];
    requestObject.push(
      this.serviceCall
        .getProductLookupData(LookupConstants.PRODUCT_STATE)
        .pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall
        .getProductLookupData(LookupConstants.CANCELLATION_PERIOD)
        .pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall
        .getProductLookupData(LookupConstants.COUNTRY)
        .pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall
        .getProductLookupData(LookupConstants.COVERAGE_AREA)
        .pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getInsurerList().pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall
        .getProductLookups(LOOKUP.COVERING_TYPE)
        .pipe(catchError(error => of(error)))
    );
    requestObject.push(
      this.serviceCall.getDivisionList().pipe(catchError(error => of(error)))
    );

    forkJoin(requestObject).subscribe((data: any) => {
      this.product_state =
        !data[0].HasErrors && data[0].data ? data[0].data : [];
      this.product_state.sort((a, b) => (a.id < b.id ? -1 : 1));
      if (
        this.product_data &&
        this.product_data.product_state_id &&
        this.product_state
      ) {
        this.product_state = this.product_state.filter(
          product => product.id >= this.product_data.product_state_id
        );
      }
      this.cancellation_period =
        !data[1].HasErrors && data[1].data ? data[1].data : [];
      this.countries = !data[2].HasErrors && data[2].data ? data[2].data : [];
      this.coverage_area =
        !data[3].HasErrors && data[3].data ? data[3].data : [];
      this.insurers =
        !data[4].HasErrors && data[4].insurers ? data[4].insurers : [];
      this.coverage_type =
        !data[5].HasErrors && data[5].data ? data[5].data : [];
      this.division_list = data[6].data.filter(division => division.id !== 12);
    });

    this.product_info_id = this._productShareService.productModuleId;
    if (this.product_info_id) {
      this.serviceCall
        .getProductData(Number(this.product_info_id))
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            this.product_data = data.product;
            setTimeout(() => {
              const clauses_names = [];
              const exclusions_names = [];
              this.sparteFixerCode = this.product_data
                ? this.spartes?.filter(
                    data => data.fixer_code == this.product_data.sparte_id
                  )[0].fixer_code
                : null;
              this._apiService
                .getClausesOrExclusions(true, this.sparteFixerCode)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  this.clauses = data.clauses_exclusions;
                  this.product_data
                    ? this.product_data.clauses.map(row => {
                        clauses_names.push(
                          this.clauses.find(data => data.id == row)?.name
                        );
                      })
                    : [];
                  this.generalInformationForm.controls.clauses.setValue(
                    clauses_names
                  );
                });
              this._apiService
                .getClausesOrExclusions(false, this.sparteFixerCode)
                .pipe(takeUntil(this._destroy$))
                .subscribe(data => {
                  this.exclusions = data.clauses_exclusions;
                  this.product_data
                    ? this.product_data.exclusions.map(row => {
                        exclusions_names.push(
                          this.exclusions.find(data => data.id == row)?.name
                        );
                      })
                    : [];
                  this.generalInformationForm.controls.exclusions.setValue(
                    exclusions_names
                  );
                });
            }, 1000);
          }
          this.initializeForm();
          this.getDocumentUploadData(this.product_info_id, data);
        });
    }
    this.serviceCall
      .getObjectType('')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.object_types = data.object_types;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isFormSubmitted) {
      this.generalInformationForm &&
        this.generalInformationForm.markAllAsTouched();
    }
  }

  getdivisionNames(divisionIds) {
    if (divisionIds && divisionIds.length && this.division_list) {
      const divisionNames = this.division_list
        ?.filter(item => divisionIds.includes(item.id))
        .map(item => item.desc);
      return divisionNames;
    }
  }

  initializeForm() {
    const version: any =
      (this.product_data && this.product_data.version_no) || 'V0';
    if (this.product_data && this.product_data.auto_contract_renew) {
      this.booleanChange(true, 'cancellation_period_in_months');
    }
    if (this.product_data && this.product_data.side_agreements) {
      this.booleanChange(true, 'selection_of_side_letters_docs');
    }
    if (
      this.product_data &&
      this.product_data.division_id &&
      this.divisions &&
      this.divisions.length
    ) {
      this.division_ids_edit_non_IC_USERs = this.product_data.division_id;
    }
    this.clausesIds = this.product_data ? this.product_data.clauses : [];
    this.exclusionsIds = this.product_data ? this.product_data.exclusions : [];
    this.selectedSparte = this.product_data
      ? this.product_data.sparte_id
      : null;
    this.selectedSparteFixerCode = this.product_data
      ? this.spartes?.filter(
          data => data.fixer_code == this.product_data.sparte_id
        )[0].fixer_code
      : null;
    this.generalInformationForm = this.fb.group({
      division: [
        USER_ROLES.IC_GLOBAL_ADMIN == this.user_role
          ? this.product_data
            ? this.product_data.division_id
            : null
          : [this.division_id],
        Validators.required,
      ],
      sparte: [
        this.product_data ? this.product_data.sparte_id : null,
        Validators.required,
      ],
      product_name: [
        this.product_data ? this.product_data.name : null,
        Validators.required,
      ],
      public_label: [
        this.product_data ? this.product_data.public_label : null,
        Validators.required,
      ],
      underwriting_insurers: [
        this.product_data ? this.product_data.insurer_id : null,
        Validators.required,
      ],
      versioning: [
        this.product_data ? this.product_data.product_state_id : 1,
        Validators.required,
      ],
      versioning_number: [{ value: version, disabled: true }],
      validity_indicator_from: [
        this.product_data
          ? moment(this.product_data.valid_from).format('YYYY-MM-DD')
          : null,
        Validators.required,
      ],
      validity_indicator_to: [
        this.product_data && this.product_data.valid_to
          ? moment(this.product_data.valid_to).format('YYYY-MM-DD')
          : null,
      ],
      automatic_contract_renewal: [
        this.product_data ? this.product_data.auto_contract_renew : null,
        Validators.required,
      ],
      cancellation_period_in_months: [
        this.product_data ? this.product_data.cancellation_period_id : null,
      ],
      country_code: [
        this.product_data ? this.product_data.country_code : null,
        Validators.required,
      ],
      avb_docs: [null, Validators.required],
      side_agreements: [
        this.product_data ? this.product_data.is_side_agreement : null,
        Validators.required,
      ],
      selection_of_side_letters_docs: [null],
      area_of_coverage: [
        this.product_data ? this.product_data.coverage_area : null,
        Validators.required,
      ],
      objects_to_be_insured: [
        this.product_data ? this.product_data.object_type_id : null,
        Validators.required,
      ],
      split_declaration: [
        this.product_data ? this.product_data.is_split_declaration : null,
        Validators.required,
      ],
      product_description: [
        this.product_data ? this.product_data.product_desc : null,
      ],
      clauses: [this.product_data ? this.product_data.clauses_names : null],
      exclusions: [
        this.product_data ? this.product_data.exclusions_names : null,
      ],
      coverage_type: [
        this.product_data ? this.product_data.coverage_type || 32 : 32,
      ],
    });

    if (this.product_data && this.product_data.is_side_agreement) {
      this.generalInformationForm.controls.selection_of_side_letters_docs.setValidators(
        Validators.required
      );
      this.generalInformationForm.controls.selection_of_side_letters_docs.updateValueAndValidity();
    }
    if (
      this.product_data &&
      this.product_data.product_state_id &&
      this.product_state
    ) {
      this.product_state = this.product_state.filter(
        product => product.id >= this.product_data.product_state_id
      );
    }

    if (this.product_data && this.product_data.product_state_id == 3) {
      this.disableEditModeOnForm();
    }

    if (USER_ROLES.IC_GLOBAL_ADMIN != this.user_role) {
      this.generalInformationForm.controls.division.value
        ? this.generalInformationForm.controls.division.markAsTouched()
        : '';
    }
    this.generalInformationForm.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.emitFormData();
      });
  }
  getDocumentUploadData(record_id, product_data) {
    if (record_id) {
      this.serviceCall
        .getDocumentPhotovoltik(record_id, '')
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            const document_ids_local = [];
            data.documents.forEach(document => {
              document.is_obj_linked = true;
              // if (document.document_type === UploadTypeConstants.AVB) {
              //   this.selectedAVBDocumentsArray[document.document_name] = document;
              // }
              if (document.document_type === UploadTypeConstants.SIDE_LETTERS) {
                this.selectedSideLettersArray[document.document_name] =
                  document;
              } else {
                this.selectedAVBDocumentsArray[document.document_name] =
                  document;
              }
              document_ids_local.push(document.upload_document_id);
            });
            if (data.documents) {
              product_data.product.document_ids = [...document_ids_local];
              this.originalDocumentIds = [...document_ids_local];
              this.generalInformationForm.controls.avb_docs.setValue(
                Object.keys(this.selectedAVBDocumentsArray).join(', ')
              );
              this.generalInformationForm.controls.selection_of_side_letters_docs.setValue(
                Object.keys(this.selectedSideLettersArray).join(', ')
              );
            }
          }
          this.originalFormData.emit(product_data.product);
        });
    }
  }

  scrollValue(key) {
    const el = document.getElementsByClassName('active-option')[0];
    el?.scrollIntoView();
    document.getElementById(key) && document.getElementById(key).focus();
  }
  focusDropdown(key) {
    document.getElementById(key) && document.getElementById(key).focus();
  }
  selectionChanged(key) {
    setTimeout(() => this[key].focus(), 150);
  }
  booleanChange(value, control) {
    if (value) {
      this.generalInformationForm
        .get(control)
        .setValidators(Validators.required);
      this.generalInformationForm.get(control).updateValueAndValidity();
    } else if (this.generalInformationForm.controls[control].enabled) {
      this.generalInformationForm.controls[control].setValue('');
      this.generalInformationForm.get(control).clearValidators();
      this.generalInformationForm.get(control).updateValueAndValidity();
    }
  }
  typeNumber(event, key, length) {
    this.validationService?.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validationService?.SequenceNumberValidationBrowserSpecific(
      event,
      key,
      length
    );
  }
  emitFormData() {
    const division_names = [];
    const insurer_names = [];
    if (
      this.generalInformationForm.controls.division.value &&
      this.generalInformationForm.controls.division.value.length > 0
    ) {
      const division = this.generalInformationForm.controls.division.value;
      division?.map(data => {
        const selectedDivision =
          this.divisions &&
          this.divisions.find(division => division.division_id == data);
        if (selectedDivision) {
          division_names.push(selectedDivision.division_name);
        }
      });
    }
    if (
      this.generalInformationForm.controls.underwriting_insurers.value &&
      this.generalInformationForm.controls.underwriting_insurers.value.length >
        0 &&
      this.insurers &&
      this.insurers.length
    ) {
      const underwriting_insurers =
        this.generalInformationForm.controls.underwriting_insurers.value;
      underwriting_insurers?.map(data => {
        insurer_names.push(
          this.insurers.find(insurer => insurer.id == data).desc
        );
      });
    }
    const fromValues: Stepper1Request = {
      is_ivm_product: false,
      product_info_id:
        this.product_info_id !== 'null' ? this.product_info_id : null,
      division_id:
        this.user_role !== USER_ROLES.IC_GLOBAL_ADMIN
          ? this.product_data
            ? this.division_ids_edit_non_IC_USERs
            : [this.division_id]
          : this.generalInformationForm.controls.division.value,
      division_names,
      sparte_id: this.generalInformationForm.controls.sparte.value,
      product_name: this.generalInformationForm.controls.product_name.value,
      public_label: this.generalInformationForm.controls.public_label.value,
      insurer_id:
        this.generalInformationForm.controls.underwriting_insurers.value,
      insurer_names,
      product_state_id: this.generalInformationForm.controls.versioning.value,
      version_no: this.generalInformationForm.controls.versioning_number.value,
      valid_from: moment(
        this.generalInformationForm.controls.validity_indicator_from.value
      ).format('YYYY-MM-DD'),
      valid_to: this.generalInformationForm.controls.validity_indicator_to.value
        ? moment(
            this.generalInformationForm.controls.validity_indicator_to.value
          ).format('YYYY-MM-DD')
        : null,
      auto_contract_renew:
        this.generalInformationForm.controls.automatic_contract_renewal.value,
      cancellation_period_id:
        this.generalInformationForm.controls.cancellation_period_in_months
          .value,
      country_code: this.generalInformationForm.controls.country_code.value,
      is_side_agreement:
        this.generalInformationForm.controls.side_agreements.value,
      coverage_area:
        this.generalInformationForm.controls.area_of_coverage.value,
      object_type_id:
        this.generalInformationForm.controls.objects_to_be_insured.value,
      is_split_declaration:
        this.generalInformationForm.controls.split_declaration.value,
      clauses: this.clausesIds,
      exclusions: this.exclusionsIds,
      product_desc:
        this.generalInformationForm.controls.product_description.value,
      is_update: this.product_info_id !== 'null' ? true : false,
      is_form_save: true,
      updated_by: this.product_info_id !== 'null' ? this.user_id : null,
      created_by: this.product_info_id !== 'null' ? null : this.user_id,
      document_ids: [
        ...new Set([...this.avbDocsIds, ...this.sideLettersDocsIds]),
      ],
      coverage_type: this.generalInformationForm.controls.coverage_type.value,
    };
    fromValues.division_names = this.getdivisionNames(fromValues.division_id);

    const request: ProductSaveRequest = {
      stepperLookupId: StepperLookupConstants.GENERAL_INFORMATION,
      fromValues,
      isFormTouched:
        (this.generalInformationForm &&
          !this.generalInformationForm.pristine) ||
        this.isFormUpdated,
      valid:
        (this.generalInformationForm.valid && !this.error) ||
        (this.product_data && this.product_data.product_state_id == 3),
    };
    this.emitFormValue.emit(request);
  }
  showDocumentPopup() {
    return this.showAVBDocumentPopup || this.showsideLettersPopup;
  }
  uploadAVBDocumentPopup() {
    this.selected_insurer_list = undefined;
    this.selected_document_list = this.documents_list;
    this.selectedDocumentsArray = { ...this.selectedAVBDocumentsArray };
    this.showAVBDocumentPopup = true;
    this.uploadObjectDetails = {
      documentSource: 'Product Module',
      // documentType: UploadTypeConstants.AVB
    };
  }
  disableEditModeOnForm() {
    this.generalInformationForm.controls.division.disable();
    this.generalInformationForm.controls.sparte.disable();
    this.generalInformationForm.controls.product_name.disable();
    this.generalInformationForm.controls.public_label.disable();
    this.generalInformationForm.controls.underwriting_insurers.disable();
    this.generalInformationForm.controls.versioning.disable();
    this.generalInformationForm.controls.validity_indicator_from.disable();
    this.generalInformationForm.controls.validity_indicator_to.disable();
    this.generalInformationForm.controls.automatic_contract_renewal.disable();
    this.generalInformationForm.controls.cancellation_period_in_months.disable();
    this.generalInformationForm.controls.country_code.disable();
    this.generalInformationForm.controls.avb_docs.disable();
    this.generalInformationForm.controls.side_agreements.disable();
    this.generalInformationForm.controls.selection_of_side_letters_docs.disable();
    this.generalInformationForm.controls.area_of_coverage.disable();
    this.generalInformationForm.controls.objects_to_be_insured.disable();
    this.generalInformationForm.controls.clauses.disable();
    this.generalInformationForm.controls.exclusions.disable();
    this.generalInformationForm.controls.product_description.disable();
    this.generalInformationForm.controls.split_declaration.disable();
  }

  uploadSideLettersPopup() {
    if (
      this.insurers &&
      this.generalInformationForm &&
      this.generalInformationForm.value.underwriting_insurers
    ) {
      this.selected_insurer_list = this.insurers.filter(
        insu =>
          this.generalInformationForm.value.underwriting_insurers.indexOf(
            insu.id
          ) > -1
      );
    }
    this.selected_document_list = undefined;
    this.selectedDocumentsArray = { ...this.selectedSideLettersArray };
    this.showsideLettersPopup = true;
    this.uploadObjectDetails = {
      documentSource: 'Product Module',
      documentType: UploadTypeConstants.SIDE_LETTERS,
      // documentTypeId:79
    };
  }
  hidePopup(selectedDocumentsArray) {
    if (this.showAVBDocumentPopup) {
      this.avbDocsIds = [];
      const tempavbDocsIds = [];
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.avbDocsIds.push(selectedDocumentsArray[name].upload_document_id);
        }
      });

      Object.keys(this.selectedAVBDocumentsArray).forEach(name => {
        if (this.selectedAVBDocumentsArray[name].upload_document_id) {
          tempavbDocsIds.push(
            this.selectedAVBDocumentsArray[name].upload_document_id
          );
        }
      });

      if (
        this.avbDocsIds &&
        tempavbDocsIds &&
        this.avbDocsIds.length == tempavbDocsIds.length
      ) {
        const temparray = this.avbDocsIds.filter(ids =>
          tempavbDocsIds.includes(ids)
        );
        if (temparray && temparray.length == this.avbDocsIds.length) {
          this.selectedDocumentsArray = {};
          this.showAVBDocumentPopup = false;
          return;
        }
      }

      this.selectedAVBDocumentsArray = { ...selectedDocumentsArray };
      this.showAVBDocumentPopup = false;
      this.generalInformationForm.controls.avb_docs.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.generalInformationForm.controls.avb_docs.markAsTouched();
      this.isFormUpdated = true;
    }
    if (this.showsideLettersPopup) {
      this.sideLettersDocsIds = [];
      this.sideLettersInsurerIds = [];
      const tempavbDocsIds = [];
      Object.keys(selectedDocumentsArray).forEach(name => {
        if (selectedDocumentsArray[name].upload_document_id) {
          this.sideLettersDocsIds.push(
            selectedDocumentsArray[name].upload_document_id
          );
          this.sideLettersInsurerIds.push(
            selectedDocumentsArray[name].insurer_id
          );
        }
      });

      Object.keys(this.selectedSideLettersArray).forEach(name => {
        if (this.selectedSideLettersArray[name].upload_document_id) {
          tempavbDocsIds.push(
            this.selectedSideLettersArray[name].upload_document_id
          );
        }
      });

      if (
        this.sideLettersDocsIds &&
        tempavbDocsIds &&
        this.sideLettersDocsIds.length == tempavbDocsIds.length
      ) {
        const temparray = this.sideLettersDocsIds.filter(ids =>
          tempavbDocsIds.includes(ids)
        );
        if (temparray && temparray.length == this.sideLettersDocsIds.length) {
          this.selectedDocumentsArray = {};
          this.showsideLettersPopup = false;
          return;
        }
      }
      this.selectedSideLettersArray = { ...selectedDocumentsArray };
      this.showsideLettersPopup = false;
      this.generalInformationForm.controls.selection_of_side_letters_docs.setValue(
        Object.keys(selectedDocumentsArray).join(', ')
      );
      this.generalInformationForm.controls.selection_of_side_letters_docs.markAsTouched();
      this.isFormUpdated = true;
    }

    this.emitFormData();
    this.selectedDocumentsArray = {};
  }
  compareTwoDates() {
    if (
      this.generalInformationForm.value.validity_indicator_to &&
      this.generalInformationForm.value.validity_indicator_from
    ) {
      if (
        new Date(this.generalInformationForm.value.validity_indicator_to) <
        new Date(this.generalInformationForm.value.validity_indicator_from)
      ) {
        this.error = true;
      } else {
        this.error = false;
      }
    } else {
      this.error = false;
    }
  }
  addClauses() {
    this._apiService
      .getClausesOrExclusions(true, this.sparteFixerCode)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.clausesOrExclusionData = {
          data: data.clauses_exclusions,
          selected_clauses: this.clausesIds,
          selected_exclusions: null,
        };
        this.comingFrom = ProductModuleConstants.clause;
        this.addClause = true;
      });
  }
  addExclusions() {
    this._apiService
      .getClausesOrExclusions(false, this.sparteFixerCode)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.clausesOrExclusionData = {
          data: data.clauses_exclusions,
          selected_clauses: null,
          selected_exclusions: this.exclusionsIds,
        };
        this.comingFrom = ProductModuleConstants.exclusion;
        this.addExclusion = true;
      });
  }
  showClausesExclusionPopup() {
    return this.addClause || this.addExclusion;
  }
  hideClausesExclusionPopup() {
    this.addClause = false;
    this.addExclusion = false;
  }
  sparteChange(value) {
    if (this.clausesIds.length > 0 || this.exclusionsIds.length > 0) {
      this.sparteChangedDialog = true;
    } else {
      this.selectedSparte = value;
      this.selectedSparteFixerCode = value;
    }
    this.sparteFixerCode = value;
  }
  getClausesExclusionsData(data) {
    if (data.comingFrom == ProductModuleConstants.clause) {
      this.generalInformationForm.controls.clauses.setValue(data.names);
      this.isFormUpdated = true;
      this.clausesIds = data.ids;
    } else if (data.comingFrom == ProductModuleConstants.exclusion) {
      this.generalInformationForm.controls.exclusions.setValue(data.names);
      this.isFormUpdated = true;
      this.exclusionsIds = data.ids;
    }
    this.emitFormData();
  }
  closePopup() {
    this.generalInformationForm.controls.sparte.setValue(this.selectedSparte);
    this.sparteFixerCode = this.selectedSparteFixerCode;
    this.sparteChangedDialog = false;
  }
  removeClausesExclusions() {
    this.clausesIds = [];
    this.exclusionsIds = [];
    this.sparteFixerCode = this.spartes.filter(
      data =>
        data.fixer_code == this.generalInformationForm.controls.sparte.value
    )[0]?.fixer_code;
    this.generalInformationForm.controls.clauses.setValue(null);
    this.generalInformationForm.controls.exclusions.setValue(null);
    this.sparteChangedDialog = false;
  }
  searched(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.preventDefault(); // Prevent the default space key action
    }
  }
}
