<div class="modal" *ngIf="isShouldOpenDialog$ | async">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title__wrapper">
          <div class="modal-title">
            {{ title$ | async }}
          </div>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeDialog()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <!-- Modal body -->
      <div *ngIf="form" class="modal-body">
        <form
          class="document-upload"
          [formGroup]="form"
        >
          <div *ngIf="form.controls.type" class="form__row">
            <label class="form__label">{{
              "upload-document.DOCUMENT_TYPE_LABEL" | translate
            }}</label>
            <select
              class="number form-control mt-2"
              *ngIf="documentTypes$ | async as types"
              formControlName="type"
            >
              <option value="" hidden disabled selected>
                {{ "upload-document.DOCUMENT_TYPE_PLACEHOLDER" | translate }}
              </option>
              <option *ngFor="let type of types" [value]="type.value">
                {{ type.label }}
              </option>
            </select>
          </div>

          <div *ngIf="form.controls.visibility && (isShowVisibilityFlag$ | async)" class="form__row mt-2">
            <label class="form__label">{{"upload-document.VISIBLE_FOR_CUSTOMER_LABEL" | translate}}</label>
            <mat-checkbox
              formControlName="visibility"
              class="custom-checkBox"
            ></mat-checkbox>
          </div>

          <div *ngIf="form.controls.description" class="form__row form__flex-form mt-2">
            <label class="form__label">{{
              "upload-document.DESCRIPTION_LABEL" | translate
            }}</label>
            <textarea
              class="desc"
              placeholder=" {{ 'upload-document.DESCRIPTION_LABEL' | translate }} "
              class="number form-control mt-2"
              type="text"
              rows="10"
              formControlName="description"
              pattern="^[^\s]+(\s+[^\s]+)*$"
            ></textarea>
          </div>

          <div *ngIf="form.controls.file" class="input-file__wrapper form__row d-flex mt-2">
            <label class="form__label">{{
              "upload-document.UPLOAD_DOCUMENT" | translate
            }}</label>
            <label *ngIf="!uploadedFile" class="input-file__label" for="inputFile">{{
              "upload-document.CHOOSE_FILE" | translate
            }}</label>
            <div *ngIf="uploadedFile" class="d-flex">
              <span>{{uploadedFile.name | cutString}}</span>
              <button
                type="button"
                class="input-file__close close"
                data-dismiss="modal"
                (click)="removeFile()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div> 
            <input
              class="number mt-2"
              title="{{ 'upload-document.SEARCH' | translate }} "
              formControlName="file"
              type="file"
              (change)="onSelectFile($event)"
              accept="application/pdf,.msg"
              id="inputFile"
            />
          </div>

          <div *ngIf="form.controls.direction" class="form__row  mt-2">
            <label class="form__label">{{"upload-document.SOURCE_TARGET" | translate}}</label>
            <select formControlName="direction" class="number form-control mt-2">
              <option value="" hidden disabled selected>
                {{ "upload-document.SOURCE_TARGET_PLACEHOLDER" | translate }}
              </option>
              <option
                *ngFor="let option of directionOptions"
                [value]="option.label"
              >
                {{ option.label | translate }}
              </option>
            </select>
          </div>

          <div class="mt-4 d-flex action-buttons">
            <div
              matTooltipPosition="after"
              matTooltip="{{ 'upload-document.REQUIRED_FIELDS_MESSAGE' | translate }}"
              [matTooltipDisabled]="form.valid"
              class="ml-auto"
            >
              <button
                *ngIf="!(isUpdateOperation$ | async) && !(isReplaceOperation$ | async)"
                (click)="uploadDocument()"
                class="action-buttons__button"
                [disabled]="form.invalid"
              >
                {{ "upload-document.UPLOAD" | translate }}
              </button>
              <button
                *ngIf="isUpdateOperation$ | async"
                class="action-buttons__button"
                (click)="updateDocument()"
                [disabled]="form.invalid"
              >
                {{ "upload-document.UPDATE" | translate }}
              </button>
              <button
                *ngIf="isReplaceOperation$ | async"
                class="action-buttons__button"
                (click)="replaceDocument()"
                [disabled]="form.invalid"
              >
                {{ "document-table.replace" | translate }}
              </button>
            </div>
            &nbsp;&nbsp;
            <div class="mr-auto">
              <button class="action-buttons__button" (click)="closeDialog()">
                {{ "upload-document.CANCEL" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
