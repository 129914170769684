import { Api } from '@shared/constants/api.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Appointment,
  CreateAppointmentBody,
  GetAppointmentsParams,
  GetAppointmentsResponse,
} from '@shared/models/appointment.model';
import { Api_URL } from '@shared/constants/url.constants';

@Injectable({ providedIn: 'root' })
export class AppointmentApiService {
  constructor(private _http: HttpClient) {}

  getAppointments(
    requestParams: GetAppointmentsParams
  ): Observable<GetAppointmentsResponse> {
    let params = new HttpParams();

    Object.keys(requestParams).forEach(key => {
      params = params.append(key, requestParams[key]);
    });

    return this._http.get<GetAppointmentsResponse>(
      `${Api_URL.IC_API_URL}${Api.EVENTS}`,
      { params }
    );
  }

  getAppointmentById(id: number): Observable<Appointment> {
    return this._http.get<Appointment>(
      `${Api_URL.IC_API_URL}${Api.EVENTS}/${id}`
    );
  }

  createAppointment(body: CreateAppointmentBody): Observable<void> {
    return this._http.post<void>(`${Api_URL.IC_API_URL}${Api.EVENTS}`, body);
  }

  updateAppointment(id: number, body: CreateAppointmentBody): Observable<void> {
    const bodyToUpdate = {
      id,
      ...body,
    };

    return this._http.patch<void>(
      `${Api_URL.IC_API_URL}${Api.EVENTS}`,
      bodyToUpdate
    );
  }

  deleteAppointment(id: string): Observable<void> {
    return this._http.delete<void>(`${Api_URL.IC_API_URL}${Api.EVENTS}/${id}`);
  }
}
