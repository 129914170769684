// THIS CUSTOM PIPE IS FOR MULTILINGUAL FEATURE
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multilingual',
})
export class MultilingualPipe implements PipeTransform {
  transform(value: any): string {
    let rvalue = '';
    if (value !== null) {
      if (value === 'allFieldRequire_validation') {
        rvalue = 'update-user.allFieldRequire_validation';
      }
      if (value === 'email_validation') {
        rvalue = 'update-user.email_validation';
      }
      if (value === 'Nachname_validation') {
        rvalue = 'update-user.Nachname_validation';
      }
      if (value === 'Vorname_validation') {
        rvalue = 'update-user.Vorname_validation';
      }
      if (value === 'Anrede_validation') {
        rvalue = 'update-user.Anrede_validation';
      }
      if (value === 'Hausnummer_validation') {
        rvalue = 'update-user.Hausnummer_validation';
      }
      if (value === 'Straße_validation') {
        rvalue = 'update-user.Straße_validation';
      }
      if (value === 'Postleitzahl_validation') {
        rvalue = 'update-user.Postleitzahl_validation';
      }
      if (value === 'Telefon_validation') {
        rvalue = 'update-user.Telefon_validation';
      }
      if (value === 'Land_validation') {
        rvalue = 'update-user.Land_validation';
      }
      if (value === 'Stadt_validation') {
        rvalue = 'update-user.Stadt_validation';
      }
      // BELOW VALIDATION KEYS ARE FROM VALUES COMPONENT
      if (value === 'Eingegebene_validation') {
        rvalue = 'valuesComponent.Eingegebene_validation';
      }
      if (value === 'dieAnzhal_validation') {
        rvalue = 'valuesComponent.dieAnzhal_validation';
      }
      if (value === 'derMaximale_validation') {
        rvalue = 'valuesComponent.derMaximale_validation';
      }
      if (value === 'bitteGültigen_validation') {
        rvalue = 'valuesComponent.bitteGültigen_validation';
      }
      if (value === 'negativeEingaben_validation') {
        rvalue = 'valuesComponent.negativeEingaben_validation';
      }
      if (value === 'FehlerBei_alert') {
        rvalue = 'valuesComponent.FehlerBei_alert';
      }
      if (value === 'Datensätze_alert') {
        rvalue = 'valuesComponent.Datensätze_alert';
      }

      // BELOW ARE THE KEYS FROM SETTINGS COMPONENT
      if (value === 'dateiDarfNicht_alert') {
        rvalue = 'settings.dateiDarfNicht_alert';
      }
      if (value === 'LogoHochgeladen_info') {
        rvalue = 'settings.LogoHochgeladen_info';
      }
      if (value === 'LogoGelöscht_info') {
        rvalue = 'settings.LogoGelöscht_info';
      }
      if (value === 'deleteGroupText') {
        rvalue = 'settings.deleteGroupText';
      }

      // BELOW ARE THE KEYS FROM SEARCH COMPONENT
      if (value === 'keinSuchergebnis_info') {
        rvalue = 'searchComponent.keinSuchergebnis_info';
      }
      if (value === 'NameAdresse_text') {
        rvalue = 'searchComponent.NameAdresse_text';
      }
      if (value === 'Versicherer_text') {
        rvalue = 'searchComponent.Versicherer_text';
      }
      if (value === 'KFZ_text') {
        rvalue = 'searchComponent.KFZ_text';
      }
      if (value === 'Schadendatum_text') {
        rvalue = 'searchComponent.Schadendatum_text';
      }
      if (value === 'Rechnungsnummer_text') {
        rvalue = 'searchComponent.Rechnungsnummer_text';
      }
      if (value === 'Kundennummer_text') {
        rvalue = 'searchComponent.Kundennummer_text';
      }
      if (value === 'Vertragsnummer_text') {
        rvalue = 'searchComponent.Vertragsnummer_text';
      }
      // BELOW ARE THE KEYS FROM USER-DATA COMPONENT
      if (value === 'Benutzerinformationen_text') {
        rvalue = 'user-data.Benutzerinformationen_text';
      }
      if (value === 'Abbrechen_text') {
        rvalue = 'user-data.Abbrechen_text';
      }
      if (value === 'Aktualisieren_text') {
        rvalue = 'user-data.Aktualisieren_text';
      }
      if (value === 'AktualisierenUnd_text') {
        rvalue = 'user-data.AktualisierenUnd_text';
      }
      if (value === 'Einladungsemail_info') {
        rvalue = 'user-data.Einladungsemail_info';
      }
      if (value === 'Einladungsemail_info') {
        rvalue = 'user-data.Einladungsemail_info';
      }
      if (value === 'Einladungsemail_info') {
        rvalue = 'user-data.Einladungsemail_info';
      }
      if (value === 'Neuen_Benutzer_hinzufügen') {
        rvalue = 'user-data.Neuen_Benutzer_hinzufügen';
      }
      if (value === 'Senden') {
        rvalue = 'user-data.Senden';
      }

      if (value === 'upsBeiDer_alert') {
        rvalue = 'header.upsBeiDer_alert';
      }
      // BELOW ARE THE KEYS FROM CONTRACTS COMPONENT

      if (value === 'AlleDokumente_title') {
        rvalue = 'contracts.AlleDokumente_title';
      }
      if (value === 'DokumenteHochladenContract_title') {
        rvalue = 'contracts.DokumenteHochladenContract_title';
      }
      if (value === 'Nachtragsdokument_text') {
        rvalue = 'contracts.Nachtragsdokument_text';
      }
      if (value === 'Bedingungen_text') {
        rvalue = 'contracts.Bedingungen_text';
      }
      if (value === 'Policendokument_text') {
        rvalue = 'contracts.Policendokument_text';
      }
      if (value === 'Rechnungsdokument_text') {
        rvalue = 'contracts.Rechnungsdokument_text';
      }
      if (value === 'Sicherungsschein_text') {
        rvalue = 'contracts.Sicherungsschein_text';
      }
      if (value === 'Versicherungsbestätigung_text') {
        rvalue = 'contracts.Versicherungsbestätigung_text';
      }
      // BELOW ARE THE KEYS FROM CLAIMS COMPONENT

      if (value === 'AlleDokumente_title') {
        rvalue = 'claims.AlleDokumente_title';
      }
      if (value === 'DokumenteHochladen_title') {
        rvalue = 'claims.DokumenteHochladen_title';
      }

      // BELOW ARE THE KEYS FROM NEW EDITOR COMPONENT

      if (value === 'InformationenAktualisieren_title') {
        rvalue = 'new-editor.InformationenAktualisieren_title';
      }
      if (value === 'von_validation_label') {
        rvalue = 'new-editor.von_validation_label';
      }
      if (value === 'von_validation_label_Kundenendnummer') {
        rvalue = 'new-editor.von_validation_label_Kundenendnummer';
      }
      if (value === 'von_validation_label_Kundennummer') {
        rvalue = 'new-editor.von_validation_label_Kundennummer';
      }
      if (value === 'von_validation_label_Nachname') {
        rvalue = 'new-editor.von_validation_label_Nachname';
      }
      if (value === 'von_validation_label_Firmenname') {
        rvalue = 'new-editor.von_validation_label_Firmenname';
      }
      if (value === 'von_validation_label_Postleitzahl') {
        rvalue = 'new-editor.von_validation_label_Postleitzahl';
      }

      // BELOW ARE THE KEYS FROM CONTACT MANAGER COMPONENT

      if (value === 'KeinAkteur_info') {
        rvalue = 'contact-manager.KeinAkteur_info';
      }
      if (value === 'BittewählenSie_info') {
        rvalue = 'contact-manager.BittewählenSie_info';
      }

      if (value === 'Siekönnen_info') {
        rvalue = 'contact-manager.Siekönnen_info';
      }
      if (value === 'DatensatzErfolgreich_info') {
        rvalue = 'contact-manager.DatensatzErfolgreich_info';
      }
      if (value === 'DatensatznichtAktualisiert_info') {
        rvalue = 'contact-manager.DatensatznichtAktualisiert_info';
      }
      if (value === 'Datensatzhinzugefügt_info') {
        rvalue = 'contact-manager.Datensatzhinzugefügt_info';
      }
      if (value === 'wendenSiesich_info') {
        rvalue = 'contact-manager.wendenSiesich_info';
      }
      if (value === 'DatensatzErfolgreichGelöscht_info') {
        rvalue = 'contact-manager.DatensatzErfolgreichGelöscht_info';
      }

      // BELOW ARE THE KEYS FROM WIDGET DASHBOARD

      // Below Are the keys from zurs Component
      if (value === 'b_brook_zone_text') {
        rvalue = 'zurs-geo.b_brook_zone_text';
      }
      if (value === 'B_brook_zone_text') {
        rvalue = 'zurs-geo.B_brook_zone_text';
      }
      if (value === 'k_costal_zone_text') {
        rvalue = 'zurs-geo.k_costal_zone_text';
      }
      if (value === 'K_costal_zone_text') {
        rvalue = 'zurs-geo.K_costal_zone_text';
      }
      if (value === 'i_island_zone_text') {
        rvalue = 'zurs-geo.i_island_zone_text';
      }
      if (value === 'I_island_zone_text') {
        rvalue = 'zurs-geo.I_island_zone_text';
      }

      // Below are the keys from object module
      if (value === 'object_details') {
        rvalue = 'objectModule.object_details';
      }
      if (value === 'power_supply_facility') {
        rvalue = 'objectModule.power_supply_facility';
      }
      if (value === 'power_supply') {
        rvalue = 'objectModule.power_supply';
      }
      if (value === 'fire_protection') {
        rvalue = 'objectModule.fire_protection';
      }
      if (value === 'other-increase-risk') {
        rvalue = 'objectModule.other-increase-risk';
      }
      if (value === 'printing-companies') {
        rvalue = 'objectModule.printing-companies';
      }
      if (value === 'foil-packaging') {
        rvalue = 'objectModule.foil-packaging';
      }
      if (value === 'electroplating') {
        rvalue = 'objectModule.electroplating';
      }
      if (value === 'foundary') {
        rvalue = 'objectModule.foundary';
      }
      if (value === 'woodworking-processing') {
        rvalue = 'objectModule.woodworking-processing';
      }
      if (value === 'plastic') {
        rvalue = 'objectModule.plastic';
      }
      if (value === 'warehouse') {
        rvalue = 'objectModule.warehouse';
      }
      if (value === 'lithium-batteries') {
        rvalue = 'objectModule.lithium-batteries';
      }
      if (value === 'soldering') {
        rvalue = 'objectModule.soldering';
      }
      if (value === 'mill-installation') {
        rvalue = 'objectModule.mill-installation';
      }
      if (value === 'pellete-plant') {
        rvalue = 'objectModule.pellete-plant';
      }
      if (value === 'photovoltaic-inside') {
        rvalue = 'objectModule.photovoltaic-inside';
      }
      if (value === 'recycling') {
        rvalue = 'objectModule.recycling';
      }
      if (value === 'clean-rooms') {
        rvalue = 'objectModule.clean-rooms';
      }
      if (value === 'grinding-polishing') {
        rvalue = 'objectModule.grinding-polishing';
      }
      if (value === 'basic-location-details') {
        rvalue = 'objectModule.location.basic-location-details';
      }
      if (value === 'additional-general-information') {
        rvalue = 'objectModule.location.additional-general-information';
      }
      if (value === 'organizational-fire-protection') {
        rvalue = 'objectModule.location.organizational-fire-protection';
      }
      if (value === 'protection-factory-premises') {
        rvalue = 'objectModule.location.protection-factory-premises';
      }
      if (value === 'other-increses-risk-location') {
        rvalue = 'objectModule.location.other-increses-risk-location';
      }
      if (value === 'criteria_not_added') {
        rvalue = 'objectModule.criteria_not_added';
      }
      if (value === 'criteria_added') {
        rvalue = 'objectModule.criteria_added';
      }
      // BELOW ARE THE KEYS FOR PRODUCT MODULE
      if (value === 'clause') {
        rvalue = 'product-management.general_information.clause';
      }
      if (value === 'exclusion') {
        rvalue = 'product-management.general_information.exclusion';
      }
      if (value === 'clauses') {
        rvalue = 'product-management.general_information.clauses';
      }
      if (value === 'exclusions') {
        rvalue = 'product-management.general_information.exclusions';
      }
      if (value === 'Pattern') {
        rvalue = 'value-calculator.Pattern';
      }
      if (value === 'Number') {
        rvalue = 'value-calculator.number';
      }
      if (value === 'LHS-RHS') {
        rvalue = 'value-calculator.LHS-RHS';
      }
      if (value === 'Input Field') {
        rvalue = 'value-calculator.Input-Field';
      }
      if (value === 'Data Source') {
        rvalue = 'value-calculator.Data-Source';
      }
      if (value === 'Product Module') {
        rvalue = 'value-calculator.Product-Module';
      }
      if (value === 'Object Module') {
        rvalue = 'value-calculator.Object-Module';
      }
      if (value === 'Value Module') {
        rvalue = 'value-calculator.Value-Module';
      }
      if (value === 'Contract') {
        rvalue = 'value-calculator.Contract';
      }
      if (value === 'Mathematical Operator') {
        rvalue = 'value-calculator.Mathematical-Operator';
      }
    }
    return rvalue;
  }
}
