<form class="general-form product-module-html" [formGroup]="InsuranceForm" id="general-form">
  <div class="contracts-tabs-content general-block-contents row insurer" style="align-items:baseline">
    <div class="col-md-6 col-sm-6 col-12">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.gross_premium' | translate}}</label>
      <input type="text" class="input" formControlName="GrossPremium" placeholder="{{'new-contract.general_information.gross_premium' | translate}}" 
      [attr.disabled]="generaldata?.insurer_count > 1 || IVMSynch ? 'disabled' : null"
      (blur)="validateNumberAsFloatEightDigit($event,'GrossPremium');calculateAndPopulate('GrossPremium');convertToGermanString('GrossPremium')">
    </div>
    <div class="col-md-6 col-sm-4 col-12">
      <div class="row">
        <div class="col-md-4" style="padding-right: 3px;">
          <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.tax_in_%' | translate}}</label><br />
          <input type="text" class="input" formControlName="Tax" placeholder="{{'new-contract.general_information.tax_in_%' | translate}}"
          [attr.disabled]="generaldata?.insurer_count > 1 || IVMSynch ? 'disabled' : null"
          (blur)="calculateAndPopulate('Tax');convertToGermanString('Tax')">
        </div>
        <div class="col-md-8" style="padding-left: 3px;">
          <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.as_amount' | translate}}</label><br />
          <input type="text" class="input" formControlName="amount" placeholder="{{'new-contract.general_information.as_amount' | translate}}"
          disabled>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-12">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.net_premium_annual' | translate}}</label>
      <input type="text" class="input" formControlName="NetPremium" placeholder="{{'new-contract.general_information.net_premium_annual' | translate}}"
      [attr.disabled]="generaldata?.insurer_count > 1 || IVMSynch ? 'disabled' : null"
      (blur)="validateNumberAsFloatEightDigit($event,'NetPremium');calculateAndPopulate('NetPremium');convertToGermanString('NetPremium')">
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.payment_frequency_in_year' | translate}}</label>
      <mat-select [disabled]="IVMSynch" class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="modeofpayment" (selectionChange)="search3.value = '';calculatPaymentFrequence();" (closed)="calculateCommissionModeOfPayment();" (openedChange)="search3.value = ''">
        <input type="text" maxlength="20" preventSpace class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #search3>
        <mat-option style="opacity:0.5">
         {{'objectModule.select_lable' | translate}}
       </mat-option>
        <mat-option *ngFor="let paymentMode of validationService._filter(paymentModes, 'desc', search3.value)" [value]="paymentMode.id">{{paymentMode.desc}}</mat-option>
       </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.net_premium_payment_frequency' | translate}}</label>
      <input type="text" class="input" formControlName="PaymentNetPremium" placeholder="{{'new-contract.general_information.net_premium_frequency_placeholder' | translate}}" disabled>
    </div>
    <div class="col-md-6 col-sm-6 col-6 radioButton">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.payment_type' | translate}}</label>
      <mat-radio-group formControlName="PaymentType" (change)="paymentChange($event)" [(ngModel)]="selected_payment_type" [disabled]="IVMSynch"><br />
        <mat-radio-button [value]="6">
          <span>
            {{'new-contract.general_information.invoice' | translate}}
          </span>
        </mat-radio-button>
        <mat-radio-button [value]="7">
          <span>
            {{'new-contract.general_information.direct' | translate}}
          </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.collection_type' | translate}}</label>
      <mat-select [disabled]="IVMSynch" class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="collectiontype" (selectionChange)="search1.value = ''" (openedChange)="search1.value = ''">
        <input type="text" maxlength="20" preventSpace class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #search1>
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let collectionType of validationService._filter(collectionTypes, 'desc', search1.value)" [value]="collectionType.id" (click)="mapFormValue('collectiontype',collectionType.id);">{{collectionType.desc}}</mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.bank_details_for_collection' | translate}}</label>
      <mat-select [disabled]="IVMSynch" class="dropdownStyle switcherDrpdwn inputy switcher" placeholder="{{'objectModule.select_lable' | translate}}" formControlName="bankdetailsforcollection" (selectionChange)="search2.value = ''" (openedChange)="search2.value = ''">
        <input type="text" maxlength="20" preventSpace class="searchSelect" placeholder="{{'header.Suche_tooltip' | translate}}" #search2>
        <mat-option style="opacity:0.5">
          {{'objectModule.select_lable' | translate}}
        </mat-option>
        <mat-option *ngFor="let bankDetail of validationService._filter(bankDetails, 'desc', search2.value)" matTooltip="{{bankDetail.desc}}" [value]="bankDetail.id" (click)="mapFormValue('bankdetailsforcollection', bankDetail.id);">{{bankDetail.desc}}</mat-option>
      </mat-select>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="commission" style="text-transform: initial;" class="long-label">{{'new-contract.general_information.commission' |
      translate}}</label>
      <input type="text" [attr.disabled]="IVMSynch ? 'disabled' : null" maxlength="4" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input" (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')"
        (blur)="validateNumberAsFloat($event,'commission'); calculateCommissionModeOfPayment(); calculateYearlyCommision($event)" formControlName="commission" placeholder="{{'new-contract.general_information.commission' | translate}}">
      <input type="text" [attr.disabled]="IVMSynch ? 'disabled' : null" maxlength="4" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input" (paste)="onTypeNewValue($event, 'paste')" (keypress)="onTypeNewValue($event, 'keypress')" (blur)="validateNumberAsFloat($event,'commission')"
        formControlName="commission" placeholder="{{'new-contract.general_information.commission' | translate}}">
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label style="text-transform: initial;" for="" class="long-label">{{'new-contract.general_information.commission_according_to_mode_of_payment' | translate}}</label>
      <input type="text" class="input" formControlName="commissionaccordingtomodeofpayment" placeholder="{{'new-contract.general_information.commission_according_to_mode_of_payment' | translate}}" disabled>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <label for="yearly_commission" style="text-transform: initial;" class="long-label">
        {{'new-contract.general_information.yearly_commission' | translate}}
      </label>
      <input type="text" maxlength="9" *ngIf="browser == 'Chrome' || browser == 'Edge' || browser == 'Unknown'" class="input" formControlName="yearly_commission" placeholder="{{'new-contract.general_information.yearly_commission' | translate}}"
        disabled >
      <input type="text" maxlength="9" *ngIf="browser == 'Safari' || browser == 'Firefox'" class="input" 
        formControlName="yearly_commission" placeholder="{{'new-contract.general_information.yearly_commission' | translate}}" disabled>
    </div>
  </div>
</form>
