import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';

import { LookupStrings } from '@shared/constants/lookup.constants';
import { BaseComponent } from '@shared/components/base.component';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { FEATURES } from '@shared/constants/string.constants';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { CustomerManagementSharedService } from '../customer-management-shared-service';
@Component({
  selector: 'app-communication-details',
  templateUrl: './communication-details.component.html',
  styleUrls: ['./communication-details.component.scss'],
})
export class CommunicationDetailsComponent extends BaseComponent {
  isAddCommunication: boolean;
  customer_id;
  is_edit = false;
  communicationForm: FormGroup;
  communication_types: any[] = [];
  customerFormData: any;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = [
    'name',
    'communication_type',
    'contact',
    'desc',
    'action',
  ];
  customerWarningMsg: CustomerWarningMsg;
  showWarningPopUp = false;
  showModal: boolean;
  delete_item_id: any;
  actionButton = ACTION_BUTTON;
  editCustomer: boolean;

  constructor(
    private _httpCallService: ApiCallsService,
    private toastService: ToastService,
    private readonly _customerManagementService: CustomerManagementSharedService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
    this.customerWarningMsg = {
      cancel: 'product-management.stepper_common_msg.discard',
      ok: 'product-management.stepper_common_msg.cancel_label',
      save_and_continue:
        'product-management.stepper_common_msg.save_and_continue',
      header: 'save-changes.Nicht_gespeicherte',
      body: 'save-changes.object_unsaved_message',
    };
  }
  warningPopupGuard() {
    this.showWarningPopUp = true;
  }
  ngOnInit(): void {
    this._watchOnEditCustomerValue();
    this._watchOnAddingCommunication();
    this._httpCallService
      .getProductLookups(LookupStrings.COMMUNICATION_TYPE)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => (this.communication_types = data.data));

    this._customerSharedService.selectedCustomerId$
      .pipe(filterNil(), take(1))
      .subscribe(customerId => {
        this.customer_id = customerId;

        this.getCommunications(this.customer_id);
      });

    this.initiateForm();
    this.communicationForm
      .get('communicationType')
      .valueChanges.subscribe(value => {
        switch (value) {
          case 78:
            this.communicationForm
              .get('contact')
              .setValidators(
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
              );
            break;
          case 77:
          case 76:
          case 75:
            this.communicationForm
              .get('contact')
              .setValidators(Validators.pattern('^[0-9]{0,20}$'));
            break;
          case 79:
          case 80:
            this.communicationForm
              .get('contact')
              .setValidators(
                Validators.pattern("^[0-9a-zA-ZÀ-ž/.,'&*~`!@^()_<>]{0,100}")
              );
        }
      });
  }
  getCommunications(customer_id) {
    const request = {
      source_id: customer_id,
      source_type: FEATURES.CUSTOMER,
    };
    this._httpCallService
      .getCommunicationList(request)
      .subscribe((data: any) => {
        this.dataSource.data = data.communication_details;
      });
  }
  initiateForm() {
    const formData = this.customerFormData;
    this.communicationForm = new FormGroup({
      communicationType: new FormControl(
        formData ? formData.communication_type_id : ''
      ),
      contact: new FormControl(formData ? formData.contact : ''),
      description: new FormControl(formData ? formData.description : ''),
    });
    if (formData) {
      switch (formData?.communication_type_id) {
        case 78:
          this.communicationForm
            .get('contact')
            .setValidators(
              Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
            );
          break;
        case 77:
        case 76:
        case 75:
          this.communicationForm
            .get('contact')
            .setValidators(Validators.pattern('^[0-9]{0,20}$'));
          break;
        case 79:
        case 80:
          this.communicationForm
            .get('contact')
            .setValidators(
              Validators.pattern("^[0-9a-zA-ZÀ-ž/.,'&*~`!@^()_<>]{0,100}")
            );
      }
    }
  }
  editDetails(element) {
    if (element !== null) {
      this.isAddCommunication = false;
    }
    this.is_edit = true;
    this.customerFormData = element;
    this.initiateForm();
  }
  close() {
    this.is_edit = false;
    this.isAddCommunication = false;
    this.customerFormData = null;
    this.communicationForm.markAsPristine();
  }
  saveCommunication() {
    if (this.communicationForm.valid) {
      const communicationTypeId = this.communicationForm.get("communicationType")?.value;
      const contact = this.communicationForm.controls.contact?.value;
      const description = this.communicationForm.controls.description?.value;
  
      if (communicationTypeId && contact && description) {
        const requestData = {
          id: this.customerFormData?.id || null,
          customer_id: this.customerFormData?.customer_id || this.customer_id,
          communication_type_id: communicationTypeId || null,
          parent_id: this.customerFormData?.parent_id || this.customer_id,
          contact: contact || null,
          description: description || null,
          source_id: this.customerFormData?.customer_id || this.customer_id,
          source_type: FEATURES.CUSTOMER,
        };
  
        this._httpCallService
          .postCommunicationDetails(requestData)
          .pipe(takeUntil(this._destroy$))
          .subscribe((data: any) => {
            this.getCommunications(this.customer_id);
            this.toastService.showToastMessage(document, data.message);
            if (!data.HasErrors) {
              this.close();
            }
          });
      } else {
        this.toastService.showToastMessage(document, 'Please fill all the required fields!');
      }
    }
  }
  
  deleteCommunication(communication_details_id) {
    this._httpCallService
      .deleteCommunicationDetails(communication_details_id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.hideModal();
        this.getCommunications(this.customer_id);
        this.toastService.showToastMessage(document, data.message);
      });
  }
  selectCommunication(event) {
    this.communicationForm.controls.contact.reset();
    switch (event.value) {
      case 78:
        this.communicationForm
          .get('contact')
          .setValidators(
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
          );
        break;
      case 77:
      case 76:
      case 75:
        this.communicationForm
          .get('contact')
          .setValidators(Validators.pattern('^[0-9]{0,20}$'));
        break;
      case 79:
      case 80:
        this.communicationForm
          .get('contact')
          .setValidators(
            Validators.pattern("^[0-9a-zA-ZÀ-ž/.,'&*~`!@^()_<>]{0,100}")
          );
    }
  }
  handleWarningPopup(event) {
    this.showWarningPopUp = false;
    if (!event) {
      this._customerManagementService.IVMSyncToggle$.next(true);
    } else {
      this.is_edit = false;
      this.isAddCommunication = false;
    }
  }
  saveCommunicationData(event) {
    if (event) {
      this.showWarningPopUp = false;
      this.saveCommunication();
    }
  }
  openModal(id) {
    this.delete_item_id = id;
    this.showModal = true;
  }
  hideModal() {
    this.delete_item_id = null;
    this.showModal = false;
  }

  private _watchOnEditCustomerValue(): void {
    this._customerManagementService.isEditCustomerOperation$
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        this.editCustomer = value;
        if (!this.editCustomer && this.communicationForm?.dirty) {
          this.warningPopupGuard();
        }
        if (!this.editCustomer && !this.communicationForm?.dirty) {
          this.is_edit = false;
          this.isAddCommunication = false;
        }
      });
  }

  private _watchOnAddingCommunication(): void {
    this._customerManagementService.addCommunication$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.editDetails(null));
  }
}
