export const Operators = {
  MATH_OPERATORS: 1,
  ADD: 2,
  SUB: 3,
  MULTIPLY: 4,
  DIVIDE: 5,
};

export const RowTypes = {
  NUMBER: 1,
  LHS_RHS: 2,
  If_Else: 4,
  TEXT: 5,
};

export const DataSourceTypes = {
  INPUT_FIELD: 1,
  NUMBER: 2,
  TEXT: 5,
  DATA_SOURCE: 3,
  PRODUCT_MODULE: 12,
  OBJECT_MODULE: 11,
  VALUE_MODULE: 13,
  CONTRACT: 14,
  CUSTOM_CREATED_ATTRIBUTE: 92,
};

export const RowSides = {
  LEFT_ROW: 'Left',
  RIGHT_ROW: 'Right',
};

export const IfElseTypes = {
  If: 'if',
  Else: 'else',
};
