<app-header (emitUserData)="userDataReceived($event)"></app-header>
<div class="container-ic">
    <div class="claims-page">
        <app-breadcrumbs [isCustomerUser]="isCustomerUser" [isCustomerSelected]="isCustomerSelected"
            [pageBreadcrumbText]="pageBreadcrumbText" [divisionName]="divisionName" [customerName]="customerName$ | async">
        </app-breadcrumbs>
        <div class="filter-distance">
            <mat-accordion>
                <mat-expansion-panel class="filter-section"
                    (opened)="customerFilterToggle = true"
                    (closed)="customerFilterToggle = false"
                    [expanded]="customerFilterToggle" hideToggle>
                    <mat-expansion-panel-header class="filter-section-header p-unset">
                        <mat-panel-title class="filter-section-title-alignment filter-title">
                            <div class="flex-center g-7">
                                <button class="action-btn">
                                    <mat-icon *ngIf="customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_down</mat-icon>
                                    <mat-icon *ngIf="!customerFilterToggle" class="arrowButtons va-bottom">keyboard_arrow_right</mat-icon>
                                </button>
                                <span>
                                    <strong>FILTER</strong>
                                </span>
                            </div>
                            <div (click)="$event.stopPropagation()" class="paginator-dropdown-container">
                                <mat-select
                                    class="paginator-dropdown filter-section-dropdown"
                                    (selectionChange)="tablePageChanged(DefaultPage)"
                                    [(ngModel)]="selectedCountOfRows"
                                    panelClass="rowChange"
                                    disableOptionCentering>
                                    <mat-option *ngFor="let row of numberOfRows"
                                        [value]="row">
                                        {{row}}
                                    </mat-option>
                                </mat-select>
                                <span class="results-per-page">
                                    {{'claims.results_per_page'| translate}}
                                </span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <form [formGroup]="formFilter" class="f-13">
                        <div class="d-flex gap-3 my-2">
                            <div class="vertically-center g-7">
                                <mat-checkbox formControlName="KfzVerträge" class="custom-checkBox check1">
                                    {{'claims.Kfz-Verträge_text' | translate}}
                                </mat-checkbox>
                            </div>
                            <div class="vertically-center g-7">
                                <mat-select formControlName="Schadenart"
                                    placeholder="{{'claims.FilterSchadenart_text' | translate}}"
                                    [typeaheadDebounceInterval]="750"
                                    (openedChange)="searchSchadenart.value = ''">
                                    <input type="text" maxlength="20" class="searchSelect"
                                        preventSpace
                                        placeholder="{{'header.Suche_tooltip' | translate}}"
                                        #searchSchadenart>
                                    <mat-option *ngFor="let element of validationService._filter(SchadenartAvailable, '', searchSchadenart.value)" [value]="element">
                                        {{element}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="vertically-center g-7">
                                <mat-select formControlName="Objekt"
                                    placeholder="{{'claims.FilterObjekt_text' | translate}}"
                                    [typeaheadDebounceInterval]="750"
                                    (openedChange)="searchobject.value = ''">
                                    <input type="text" maxlength="20" class="searchSelect"
                                        preventSpace
                                        placeholder="{{'header.Suche_tooltip' | translate}}"
                                        #searchobject>
                                    <mat-option *ngFor="let ele of validationService._filter(objectAvailable, '', searchobject.value)" [value]="ele">
                                        {{ele}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="vertically-center g-7">
                                <mat-checkbox formControlName="einblenden" class="custom-checkBox check1">
                                    {{'claims.Geschlossene_text' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="d-flex gap-3 flex-grow-1">
                                <div class="vertically-center g-7">
                                    <mat-checkbox formControlName="kosten"
                                        class="custom-checkBox check1" (click)="clickedKosten()">
                                        {{'claims.Kosten_ausblenden' | translate}}
                                    </mat-checkbox>
                                </div>
                                <div class="vertically-center g-7">
                                    <mat-form-field class="filter-date-picker" appearance="fill">
                                        <input matInput [matDatepicker]="picker"
                                            class="date-input"
                                            placeholder="{{'claims.von_text' | translate}}"
                                            formControlName="Jahr"
                                            [max]="formFilter.get('Jahr2').value">
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                                {{'claims.keyboard-arrow-down_text' | translate}}
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="vertically-center g-7">
                                    <mat-form-field class="filter-date-picker" appearance="fill">
                                        <input matInput [matDatepicker]="picker2"
                                            class="date-input"
                                            placeholder="{{'claims.bis_text' | translate}}"
                                            formControlName="Jahr2"
                                            [min]="formFilter.get('Jahr').value">
                                        <mat-datepicker-toggle matSuffix [for]="picker2">
                                            <mat-icon matDatepickerToggleIcon class="datePickerArrow">
                                                {{'claims.keyboard-arrow-down_text' | translate}}
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="vertically-center g-7" *ngIf="showWholeGroupCheck">
                                    <mat-checkbox
                                        *ngIf="isICUser"
                                        (click)="showWholeGroup()"
                                        formControlName="wholeGroup" class="custom-checkBox check1">
                                        {{'claims.Gruppe_anzeigen' | translate}}
                                    </mat-checkbox>
                                </div>
                                <div class="vertically-center g-7" *ngIf="decoded_contract !== null && isCustomerUser" >
                                    <div *ngIf="decoded_contract !== null && isCustomerUser">
                                        <div class="contract-number-field" *ngIf="showContract">
                                            <span class="bold-text f-13">
                                                {{'contracts.By_Contracts' | translate}} : {{contract_number}}
                                            </span>
                                            <button class="action-btn" (click)="resetContract()">
                                                <mat-icon style="font-size: 16px;height:0px;line-height: 0.6;">
                                                    close
                                                </mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-center filter-btn-gap">
                                <button class="filterBtn" type="submit"
                                    (click)="setPageToOne(); getFormsValue(formFilter.value)">
                                    {{'user-data.Filter_text' | translate}}
                                </button>
                                <button
                                    *ngIf="isICUser"
                                    [class.disabled]="ClaimDataCount == 0"
                                    class="filterBtn"
                                    (click)="exportExcelData(formFilter.value)"
                                    placement="top" tooltipClass="generic-tooltip"
                                    ngbTooltip="{{'contracts.export_excel' | translate}}">
                                    <img alt="" src="assets/images/ic-icons/Export to Excel.svg">
                                </button>
                                <button
                                    class="filterBtn"
                                    placement="top" tooltipClass="generic-tooltip"
                                    ngbTooltip="{{'new-document.Filter_löschen' | translate}}"
                                    (click)="resetFilters()">
                                    <img alt="" height="16px"
                                        src="assets/images/Icon material-refresh.png">
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="table-outer-container">
            <div class="infiniteScrollTable" style="max-height: calc(100vh - 425px);">
                <mat-table [dataSource]="dataSource" class="table custom-table">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef
                            style="width: 60px;"
                            [ngStyle] = "{'display' : isCustomerUser ? 'none' : ''}"></th>
                        <td style="padding-left: 10px;" mat-cell *matCellDef="let element"
                            [ngStyle] = "{'display' : isCustomerUser ? 'none' : ''}">
                            <span class="pill byIvm w-unset" style="padding: unset;" *ngIf="element.is_source_ivm">ERP</span>
                            <span class="pill byRcs" *ngIf="!element.is_source_ivm">ICP</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="SCHADEN-DATUM">
                        <th mat-header-cell *matHeaderCellDef>
                            <span [ngClass] = "{'left-allign' : isCustomerUser}">{{'claims.SCHADENDATUM_text'| translate}}
                                <img (click)="sortByDate()" alt="" class="arrow-image"
                                    src="../../../assets/images/ic-icons/Sort.svg">
                            </span>
                            <span [ngClass] = "{'left-allign' : isCustomerUser}">{{'claims.OBJEKT_text' | translate}}
                            </span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span>
                                <span>
                                    {{element.date | dateFormat}}
                                </span>
                                <span *ngIf="element.object; else object">
                                    {{element.object}}
                                </span>
                                <ng-template #object>
                                    <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                                </ng-template>
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="STATUS/SB">
                        <th mat-header-cell *matHeaderCellDef style="position:relative;">
                            <span style="display: flex; align-items: center;"><span style="width: 80px;">{{'claims.STATUS_text' | translate}}</span>
                                <i class="dropDownIcon fa fa-chevron-down"
                                    (click)="openStatusSelection()">
                                </i>
                            </span>
                            <ul class="statusFilter" *ngIf="openStatusFilter">
                                <img alt="" height="20px" src='../../../assets/images/closeIcon.png'
                                    class="statusFilterCloseIcon" (click)="openStatusSelection()">
                                <li *ngFor="let statusValue of statusAvailable">
                                    <label for="{{statusValue.label + '_status'}}" class="listLabel">
                                        <span style="background-color: #fff;color:#000"
                                            *ngIf="statusValue.label !== '' ">{{statusValue.label}}</span>
                                        <span style="background-color: #fff;color:#000"
                                            *ngIf="statusValue.label === '' "> </span>
                                        <input type="checkbox" class="checkField"
                                            id="{{statusValue.label + '_status'}}" [(ngModel)]="statusValue.value"
                                            (ngModelChange)="checkStatusFieldClicked()">
                                        <span class="mark"></span>
                                    </label>
                                </li>
                            </ul>
                            <span>{{'claims.VERTRAGSNUMMER_text' | translate}} </span>
                        </th>
                        <td mat-cell
                            *matCellDef="let element">
                            <span *ngIf="element.status;else status">{{element.status}}</span>
                            <ng-template #status>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                            <span class="custom-ellipsis" [title]="element.contract_number" *ngIf="element.contract_number;else contract">
                                {{element.contract_number}}
                            </span>
                            <ng-template #contract>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="SCHADENART/SCHADENORT">
                        <th mat-header-cell *matHeaderCellDef>
                            <span>{{'claims.SCHADENART_text' | translate}}
                                <img alt="" (click)="sortColumn('type')"
                                    class="arrow-image" src="../../../assets/images/ic-icons/Sort.svg">
                            </span>
                            <span>{{'claims.SCHADENORT_text' | translate}} </span>
                        </th>
                        <td
                            mat-cell *matCellDef="let element" title="{{element.location}}">
                            <span class="custom-ellipsis" *ngIf="element.type;else type">
                                {{element.type}}
                            </span>
                            <ng-template #type>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                            <span class="custom-ellipsis" *ngIf="element.location;else location">
                                {{element.location}}
                            </span>
                            <ng-template #location>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="VERTRAGSNUMMER">
                        <th mat-header-cell *matHeaderCellDef>
                            <span>{{'claims.SELBSTBETEILIGUNG_text' | translate}}
                                <img alt="" (click)="sortColumn('sb')"
                                    class="arrow-image" src="../../../assets/images/ic-icons/Sort.svg">
                            </span>
                            <span *ngIf="isKosten">{{'claims.KOSTEN_text' | translate}}</span>
                        </th>
                        <td mat-cell
                            *matCellDef="let element">
                            <span *ngIf="element.sb;else sb" style="text-align: right;">
                                {{element.sb | currencyCustom }}
                                {{element.currency_symbol}}
                            </span>
                            <ng-template #sb>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                            <ng-container *ngIf="isKosten">
                                <span *ngIf="element.kosten;else kosten"
                                    style="text-align: right;">
                                    {{element.kosten | currencyCustom }}
                                    {{element.currency_symbol}}
                                </span>
                            </ng-container>
                            <ng-template #kosten>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="RESERVE/ZAHLUNG">
                        <th mat-header-cell *matHeaderCellDef>
                            <span>{{'claims.ZAHLUNG_text' | translate}}
                                <img alt="" (click)="sortReserve('reserve')"
                                    class="arrow-image" src="../../../assets/images/ic-icons/Sort.svg">
                            </span>
                            <span>{{'claims.RESERVE_text' | translate}} </span>
                        </th>
                        <td mat-cell
                            *matCellDef="let element">
                            <span *ngIf="element.payment;else payment">
                                <a style="cursor: pointer;" (click)="viewPaymentModal(element.liztu_sa_idnr)">
                                    {{element.payment | currencyCustom}}
                                    {{element.currency_symbol}}
                                </a>
                            </span>
                            <ng-template #payment>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                            <span *ngIf="element.reserve;else reserve">
                                {{element.reserve | currencyCustom }}
                                {{element.currency_symbol}}</span>
                            <ng-template #reserve>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="SCHADEN-NR-EXTERN/INTERN">

                        <th mat-header-cell *matHeaderCellDef>
                            <span>{{'claims.IHREsCHADENNUMMER_text' |
                                translate}} <img (click)="sortColumn('internal_number')" class="arrow-image"
                                    src="../../../assets/images/ic-icons/Sort.svg" alt="">
                            </span>
                            <span>{{'claims.IC_CHADENNUMMER_text' | translate}} </span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.external_number;else next"
                                style="margin: 0px;">{{element.external_number}}</span>
                            <ng-template #next>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                            <span *ngIf="element.internal_number;else internal"
                                style="margin: 0px">{{element.internal_number}}</span>
                            <ng-template #internal>
                                <span class="hiddentext">{{'claims.KeineDaten_text' | translate}}</span>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th class="pr-0" mat-header-cell style="width:10%;" *matHeaderCellDef>
                            <span style="text-align: center;">{{'claims.AKTION_text' | translate}}</span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="control-section">
                                <div class="subscribe">
                                    <button class="hide-action-button myDIV">&#183; &#183; &#183;</button>
                                    <div class="hide">
                                        <div class="action-btn-align" (click)="$event.stopPropagation()">
                                            <button
                                                class="action-btn"
                                                *ngIf="isCustomerUser"
                                                (click)="loadDocument(element)"
                                                placement="top" tooltipClass="generic-tooltip"
                                                ngbTooltip="{{'claims.Dokumente_tooltip' | translate}}">
                                                <img alt="" src="assets/images/claims/document-copy.svg">
                                            </button>
                                            <button class="action-btn"
                                                (click)="viewPaymentModal(element.liztu_sa_idnr)"
                                                placement="top" tooltipClass="generic-tooltip"
                                                ngbTooltip="{{'claims.payment_details' | translate}}">
                                                <img alt="" src="assets/images/claims/payment-details.svg">
                                            </button>
                                            <button  class="action-btn"
                                                placement="top" tooltipClass="generic-tooltip"
                                                ngbTooltip="{{'product-management.EDIT_text' | translate}}"
                                                (click)="editClaims(element)"
                                                *ngIf="!isCustomerUser">
                                                <img alt="" src='assets/images/action/edit.svg'>
                                            </button>
                                            <span style="all: unset;cursor: pointer;" [ngbTooltip]="deleteTooltipText(element)" placement="bottom"
                                                tooltipClass="delete-tooltip" *ngIf="!isCustomerUser">
                                                <button class="action-btn"
                                                    placement="top" tooltipClass="generic-tooltip"
                                                    ngbTooltip="{{'user-data.Löschen_tooltip' | translate}}"
                                                    (click)="openConfirmPopup(element.damage_id)"
                                                    [disabled]="disableDelete(element)"
                                                    >
                                                    <img alt="" src="assets/images/action/delete.svg" />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                    <tr mat-row (click)="editClaims(row)" [class.row--clickable]="!isCustomerUser" *matRowDef="let row; columns: displayedColumns;"></tr>
                </mat-table>
            </div>
            <div class="table-footer">
                <div class="paginator-footer">
                    <ngb-pagination [collectionSize]="total_elements" (pageChange)="tablePageChanged($event)"
                        [maxSize]=5 [(page)]="tablePage" [pageSize]="selectedCountOfRows"
                        [boundaryLinks]="false">
                        <ng-template ngbPaginationPrevious>
                            <i class="fa fa-angle-left"></i>
                        </ng-template>
                        <ng-template ngbPaginationNext>
                            <i class="fa fa-angle-right"></i>
                        </ng-template>
                    </ngb-pagination>
                    <div class="footer-text">
                        <span>{{'claims.AnzahlSchäden_text' | translate}} {{ total_elements}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal modelBackground popup-index" [style.display]="openPaymentModal ? 'block' : 'none'">
    <div class="modal-popup">
        <div class="modal-popup-content">
            <div class="modal-header modal-popup-header">
                <h6 class="modal-popup-heading m-0">{{'claims.Zahlungen_text' | translate}}</h6>
                <button class="action-btn" data-dismiss="modal" (click)="hidePaymentModal()">
                    <img alt="" [src]="popupImages.CLOSE">
                </button>
            </div>
            <div class="modal-body modal-popup-body">
                <div style="height: 380px;">
                    <table mat-table class="document-table" [dataSource]="paymentDataSource">
                        <ng-container matColumnDef="Zahlungsnummer">
                            <th mat-header-cell *matHeaderCellDef class="table-heading">
                                {{'claims.Payment_number_text' | translate | uppercase}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.pmt_number}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Zahlungsdatum">
                            <th mat-header-cell *matHeaderCellDef class="table-heading">
                                {{'claims.Payment_date_text' | translate | uppercase}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.pmt_date | dateFormat}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Zahlungsbetrag">
                            <th mat-header-cell *matHeaderCellDef class="table-heading text-right">
                                {{'claims.Payment_amount_text' | translate | uppercase}}
                            </th>
                            <td class="text-right" mat-cell  *matCellDef="let element">
                                {{element.pmt_value | currencyCustom }} EUR
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Bemerkung 1">
                            <th mat-header-cell *matHeaderCellDef class="table-heading">
                                {{'claims.Remark_1' | translate | uppercase}}
                            </th>
                            <td mat-cell  *matCellDef="let element">
                                <span class="custom-ellipsis noSafariTooltip" [matTooltip]="element.remark_1">
                                    {{element.remark_1}}
                                </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Bemerkung 2">
                            <th mat-header-cell *matHeaderCellDef class="table-heading">
                                {{'claims.Remark_2' |translate | uppercase}}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="custom-ellipsis noSafariTooltip" [matTooltip]="element.remark_1">
                                    {{element.remark_2}}
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="paymentColumns;sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: paymentColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<app-delete-confirmation *ngIf="showConfirmPopup" [inputData]="sendDeleteReq" (emitData)="deleteClaim($event)">
</app-delete-confirmation>
