import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CLAIM_PAYMENT_FIELDS } from '@shared/constants/claims/payments-management.constant';
import { ARROW, STATIC_TEXT } from '@shared/constants/string.constants';
import { ClaimsManagementApiService } from '@core/services/httpcalls/claims-management-api.service';
import {
  AddEditClaimPayment,
  PayeeList,
} from '@shared/models/payment-management.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import * as moment from 'moment';
import { NumberValidationsService } from '@core/services/validations/number-validations.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ShowErrorService } from '@core/services/show-error.service';
import { CustomerWarningMsg } from '@shared/constants/general-data-customer.constant';
import { ToastService } from '@core/services/toast.service';
import { FORM_ICONS } from '@shared/constants/image-paths/form.constants';
import { MarkReadService } from '@core/services/mark-read.service';
import { BaseComponent } from '@shared/components/base.component';
import { ActivatedRoute } from '@angular/router';
import { ClaimsShareService } from '../../claims-share.service';
import { CLAIM_CP_WARNINIG } from '@modules/new-claims/constants/claim.constants';

@Component({
  selector: 'add-payment-details',
  templateUrl: './add-payment-details.component.html'
})
export class AddPaymentDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() payment_id;
  isIvmSynced: boolean;
  claimId: string;
  paymentForm: FormGroup;
  showWarningPopUp = false;
  paymentData: AddEditClaimPayment;
  initialFormState: any;
  isValueChangedInForm = false;
  formFieldsName = CLAIM_PAYMENT_FIELDS;
  currencyTypes: any = [];
  currancySymbol = null;
  selectedCurrency: any;
  currencyId: any;
  payeeList: PayeeList[] = [];
  formImages = FORM_ICONS;
  customerWarningMsg: CustomerWarningMsg;
  arrows = ARROW;
  @Output() isPaymentAdd = new EventEmitter<any>();
  @Output() isPaymentAddEdit = new EventEmitter<any>();
  @Output() message = new EventEmitter<any>();

  constructor(
    private _claimsAPI: ClaimsManagementApiService,
    private _http: ApiCallsService,
    public _validationService: ValidationService,
    private _numberValidationService: NumberValidationsService,
    private _translateService: TranslateService,
    public _showError: ShowErrorService,
    private _toastService: ToastService,
    private _markReadService: MarkReadService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _claimsSharedService: ClaimsShareService
  ) {
    super();
    this.getCurrencyValue();
    this.customerWarningMsg = {
      cancel: CLAIM_CP_WARNINIG.CANCEL,
      ok: CLAIM_CP_WARNINIG.OK,
      save_and_continue: CLAIM_CP_WARNINIG.SAVE,
      header: CLAIM_CP_WARNINIG.HEADING,
      body: CLAIM_CP_WARNINIG.SUBHEADING,
    };
  }

  ngOnInit(): void {
    this._watchOnIvmSync();
    this.claimId = atob(this._activatedRoute.snapshot.queryParams.claim);
    const requests = [];
    requests.push(
      this._claimsAPI
        .getPayeeList(+this.claimId)
        .pipe(catchError(error => of(error)))
    );
    if (this.payment_id) {
      requests.push(
        this._claimsAPI
          .gatPaymentDetails(this.payment_id)
          .pipe(catchError(error => of(error)))
      );
    }
    forkJoin(requests).pipe(takeUntil(this._destroy$)).subscribe((result: any) => {
      if (!result[0].HasErrors && result[0].data) {
        this.payeeList = result[0].data;
      }
      if (this.payment_id) {
        this.paymentData =
          !result[1].HasErrors && result[1].data ? result[1].data : null;
      }
      this.initializeForm();
    });
  }
  warningPopupGuard() {
    this.showWarningPopUp = true;
  }
  handleWarningPopup(event) {
    this.showWarningPopUp = false;
    !event ? this._claimsSharedService.setEventImvChanging(false) : this.close();
  }
  close() {
    this.isPaymentAddEdit.emit(false);
    this.paymentData = null;
    this.isPaymentAdd.emit(false);
    this.message.emit(null);
  }
  savePayment(event) {
    if (event) {
      this.showWarningPopUp = false;
      this.savePaymentData();
      this._claimsSharedService.setEventImvChanging(true)
    }
  }
  initializeForm(): void {
    const formData = this.paymentData || null;
    this.paymentForm = new FormGroup({
      [CLAIM_PAYMENT_FIELDS.PAYEE_LIST]: new FormControl(
        (formData && formData.payee_id) || null
      ),
      [CLAIM_PAYMENT_FIELDS.AMOUNT]: new FormControl(
        (formData &&
          this._numberValidationService.convertIntToGermanFormatWithDecimal(
            formData.payment_amount
          )) ||
          STATIC_TEXT.EMPTY_STRING,
        [Validators.required]
      ),
      [CLAIM_PAYMENT_FIELDS.PAYMENT_DATE]: new FormControl(
        (formData && formData.payment_date) || STATIC_TEXT.EMPTY_STRING,
        [Validators.required]
      ),
      [CLAIM_PAYMENT_FIELDS.REMARK1]: new FormControl(
        (formData && formData.remark_1) || null
      ),
      [CLAIM_PAYMENT_FIELDS.REMARK2]: new FormControl(
        (formData && formData.remark_2) || null
      )
    });
    this.initialFormState = this.paymentForm.value;
    this.paymentForm?.valueChanges?.subscribe(() => {
      this.isValueChangedInForm =
        JSON.stringify(this.initialFormState) !=
        JSON.stringify(this.setControls());
    });
  }
  checkFormInvalid(): boolean {
    return this.paymentForm.invalid;
  }
  getCurrencyValue() {
    this._http.getCurrencyTypes().pipe(takeUntil(this._destroy$)).subscribe(data => {
      this.currencyTypes = data?.currency_types;
      this.currancyChanged(null);
    });
  }
  currancyChanged(event) {
    this.currencyId =
      event ||
      this.currencyTypes?.find(obj => obj.cur_symbol == STATIC_TEXT.EUR)?.id;
    this.currancySymbol = this.currencyTypes?.find(
      obj => obj.id == this.currencyId
    )?.cur_code;
    this.selectedCurrency = this.currencyTypes?.find(
      obj => obj.id == this.currencyId
    )?.cur_symbol;
  }
  savePaymentData() {
    if (this.checkFormInvalid()) {
      this._markReadService.markAllFieldsAsTouched(this.paymentForm);
      this._toastService.showToastMessage(
        document,
        this._translateService.instant(
          'customer-management.general-data.mandatory_text'
        )
      );
      return;
    }
    const request: AddEditClaimPayment = {
      payment_management_id:
        (this.paymentData && this.paymentData.payment_management_id) || null,
      claim_id: this.paymentData ? this.paymentData.claim_id : +this.claimId,
      payee_id:
        this.paymentForm.controls[CLAIM_PAYMENT_FIELDS.PAYEE_LIST].value,
      payment_amount: this._numberValidationService.convertGermanToInt(
        this.paymentForm.controls[CLAIM_PAYMENT_FIELDS.AMOUNT].value
      ),
      payment_date:
        this.paymentForm.controls[CLAIM_PAYMENT_FIELDS.PAYMENT_DATE].value,
      remark_1: this.paymentForm.controls[CLAIM_PAYMENT_FIELDS.REMARK1].value,
      remark_2: this.paymentForm.controls[CLAIM_PAYMENT_FIELDS.REMARK2].value,
      liztu_sa_idnr: +this._claimsSharedService.getLiztuSa(),
      currency: this.selectedCurrency,
    };
    this._claimsAPI
      .addPaymentDetails(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this.message.emit(response.message);
        this.paymentData = null;
        if (!request.payment_management_id) {
          this.isPaymentAddEdit.emit(false);
          this.isPaymentAdd.emit(false);
        } else {
          this._toastService.showToastMessage(document, response.message);
        }
      });
  }
  dateClicked(event) {
    this.paymentForm.controls[CLAIM_PAYMENT_FIELDS.PAYMENT_DATE].setValue(
      moment(event.target.value).format('YYYY-MM-DD')
    );
  }
  setControls() {
    const controlValues = {};
    Object.keys(this.paymentForm.controls).forEach(control => {
      controlValues[control] = this.paymentForm.get(control).value;
    });
    return controlValues;
  }

  private _watchOnIvmSync(): void {
    this._claimsSharedService.isIvmSynced$.pipe(takeUntil(this._destroy$)).subscribe(value => {
      this.isIvmSynced = value;
      if (value) {
        this.isValueChangedInForm ? this.warningPopupGuard() : this.close();
      }
    });
  }
}
