
export const APPOINTMENT_COLUMN_HEADINGS = {
    NAME: 'appointments.appointment_name',
    DESCRIPTION: 'appointments.description',
    LOCATION: 'appointments.location',
    DIVISION: 'appointments.division',
    DATE: 'appointments.date_and_time',
    GUESTS: 'appointments.guests',
    REMINDER: 'appointments.reminder.label',
    CREATED_BY: 'appointments.created_by',
    UPDATED_BY: 'appointments.updated_by',
    ACTION: 'appointments.action'
};

export enum AppointmentsColumns {
    SUBJECT = 'subject',
    DESCRIPTION = 'description',
    LOCATION = 'location',
    DIVISION = 'divisionName',
    DATE = 'date',
    GUESTS = 'guests',
    REMINDER = 'reminderInMinutesBeforeStart',
    CREATED_BY = 'createdBy',
    UPDATED_BY = 'updatedBy',
    ACTION = 'action'
}

export const APPOINTMENTS_COLUMNS = [
    AppointmentsColumns.SUBJECT,
    AppointmentsColumns.DESCRIPTION,
    AppointmentsColumns.LOCATION,
    AppointmentsColumns.DATE,
    AppointmentsColumns.GUESTS,
    AppointmentsColumns.REMINDER,
    AppointmentsColumns.CREATED_BY,
    AppointmentsColumns.UPDATED_BY,
    AppointmentsColumns.ACTION
];
