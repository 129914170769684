<app-header (emitUserData)="userDataReceived($event)"></app-header>

<div class="container-ic value-module">
  <div style="width:100%; height:100%; margin:0 auto" class="contact-manager">
    <app-breadcrumbs [isCustomerUser]="isCustomerUser" [isCustomerSelected]="isCustomerSelected"
      [pageBreadcrumbText]="pageBreadcrumbText" [divisionName]="divisionName" [customerName]="customerName$ | async">
    </app-breadcrumbs>
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #leftSidenav mode="side" opened>
        <div class="sidenav_container" [@onSideNavChange]="sideNavState ? 'open' : 'close'">
          <div class="d-flex flex-column" style="height: 100%;">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical"
              style="margin-top:0px">
              <li ngbNavItem="one" (click)="fetchName('value-module.general')">
                <a ngbNavLink>
                  <span class="flex-center">
                    <img alt="" title="{{'value-module.general' | translate}}"
                      src="../../../assets/images/General_Value_Module_Default.svg" style="padding-right:5px;">
                    <span [@animateText]="linkText ? 'show' : 'hide'">{{'value-module.general' |
                      translate}}
                    </span>
                  </span>
                </a>
                <ng-template ngbNavContent>
                  <app-general></app-general>
                </ng-template>
              </li>
              <li ngbNavItem="two" (click)="fetchName('value-module.property')">
                <a ngbNavLink>
                  <span class="flex-center">
                    <img alt="" title="{{'value-module.property' | translate}}"
                      src="../../../assets/images/Property_Value_Module_Default.svg" style="padding-right:5px;">
                    <span [@animateText]="linkText ? 'show' : 'hide'">{{'value-module.property' |
                      translate}}
                    </span>
                  </span>
                </a>
                <ng-template ngbNavContent>
                  <app-property></app-property>
                </ng-template>
              </li>
            </ul>
            <div class="sideNav-btn">
              <button mat-icon-button (click)="onSinenavToggle()" style="background: #ff9000;margin-top: 325px;">
                <mat-icon *ngIf="sideNavState">arrow_left</mat-icon>
                <mat-icon *ngIf="!sideNavState">arrow_right</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content [@onMainContentChange]="onSideNavChange ? 'open': 'close'">
        <div [ngbNavOutlet]="nav" style="margin-top: 51px;"></div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>