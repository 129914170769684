<div class="edit-tags" *ngIf="createdUpdateString">
    <span>Created By : <span>{{createdBy}}</span> | <span>{{createdAt |
        dateFormat:'dd.MM.yyyy HH:mm'}}</span></span>
    <span style="margin-left:auto" *ngIf="updatedBy && updatedAt">Updated By : <span>{{updatedBy}}</span> |
        <span>{{updatedAt | dateFormat:'dd.MM.yyyy HH:mm'}}</span></span>
</div>
<div class="form-generator">
    <div class="form-body">
        <div class="form-heading">
            <div class="form-name">
                <div class="input-placeholder">
                    <input type="text" required title="{{formName.value}}" [formControl]="formName">
                    <div class="placeholder" [ngStyle]="{'display' : is_disable || isFormInUse ? 'none' : ''}">
                        {{'form_generator.form_name_placeholder' | translate}}<span>*</span>
                    </div>
                </div>
            </div>
            <div class="form-desc">
                <div class="input-placeholder">
                    <input type="text" required title="{{formDesc.value}}" [formControl]="formDesc">
                    <div class="placeholder" [ngStyle]="{'display' : is_disable || isFormInUse ? 'none' : ''}">
                        {{'form_generator.form_desc_placeholder' | translate}}<span>*</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectLang$ | async as selectLang" class="ribbons">
            <div class="ribbon-body" *ngFor="let ribbon of ribbonData"
                (click)="is_disable ? $event.stopPropagation() : ribbonSelected(ribbon)" [class.disabled]="is_disable">
                <img src="{{ribbon.icon_url}}"> 
                <span>{{ selectLang === 'English(EN)' ? ribbon.name : ribbon.name_de }}</span>
            </div>
        </div>
        <div cdkDropListGroup class="frame-body row" *ngIf="formFields.length > 0">
            <div cdkDropList (cdkDropListDropped)="is_disable ? '' : dropListDropped()"
                [cdkDropListEnterPredicate]="dropListEnterPredicate"></div>
            <div cdkDropList (cdkDropListDropped)="is_disable ? '' : dropListDropped()" class="form-field"
                *ngFor="let item of formFields; let i = index" [cdkDropListEnterPredicate]="dropListEnterPredicate">
                <app-generic-form-field-frame (cdkDragMoved)="is_disable ? '' : dragMoved($event);" cdkDrag
                    [formField]="item" (deleteField)="deleteField($event)" (removed_fields)="removeFields($event)"
                    (formDirty)="formDirty($event)" (outputField)="saveField($event)" [isFormInUse]="isFormInUse"
                    [is_disabled]="is_disable">
                </app-generic-form-field-frame>
            </div>

        </div>
        <div class="empty-body" cdkDropListGroup *ngIf="formFields.length == 0">
            <p>{{'form_generator.empty-body-text' | translate}}</p>
        </div>
    </div>

</div>
<div class="save-container">
    <button class="custom-btn cancel-btn" *ngIf="!isICUserRole"
        (click)="close()">{{'template-management.cancel_label' |
        translate}}</button>
    <button  class="custom-btn save-btn" *ngIf="!isICUserRole"
        (click)="close()">{{'product-management.product_module_stepper_lables.Back' |
        translate}}</button>
    <button class="custom-btn save-btn" *ngIf="!isICUserRole" (click)="saveForm()"
        [disabled]="!form_dirty || is_disable">{{'update-customer.Speichern_text' |
        translate}}</button>
</div>

<div class="modal" id="formModels" [style.display]="showConfirmPopup ? 'table' : 'none'">
    <div class="modal-dialog spacing">
        <div class="modal-content">
            <div class="modal-content">
                <div class="modal-header header-content">
                    <div class="text-center">
                        <mat-icon (click)="closeConfirmPopup()">
                            <img alt="" height="65px" src="../../../assets/images/Group 3235.svg">
                        </mat-icon>
                    </div>
                </div>
                <div class="modal-body body-text">
                    <span class="delete-heading">{{'objectModule.Delete_popup_heading' | translate}}</span>
                    <br /><br />
                    <span class="delete-text">{{'form_generator.cancel_form' | translate}}</span>
                </div>
                <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                    <button type="button" mat-button (click)="closeConfirmPopup()" class="cancel-btn">
                        {{'objectModule.No' | translate}}</button>&nbsp;&nbsp;&nbsp;
                    <button class="ml-2 save-btn" type="button" mat-button (click)="saveConfirm()">
                        {{'objectModule.Yes' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>