import { NumberSymbol } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BuildingConstants } from '@shared/constants/building.constants';
import {
  BuildingObject,
  CriteriaRequestDetails,
  GetCriteriaObjectRequest,
  NewCustomerFormRequest,
  NewFormObjectRequest,
  NewFormObjectRequestList,
} from '@shared/models/request.model';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { MultilingualPipe } from '@shared/pipes/multilingual.pipe';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { Subject } from 'rxjs';
import { UserService } from '@core/user.service';

interface savedCriteria {
  id: string;
  switchCriteria: any;
  display: any;
  number: any;
}
interface sendCriteria {
  data: any;
  object_module_id: any;
}

@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: [
    './building-form.component.css',
    '../new-object-module.component.css',
  ],
})
export class BuildingFormComponent extends BaseComponent implements OnInit {
  @Input('object_data') object_data;
  @Input('newCustomerFormReq') newCustomerFormReq: NewCustomerFormRequest;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();
  @Output('emitBuldingFormValue') emitBuldingFormValue =
    new EventEmitter<any>();
  @ViewChild('toggleElement') ref: ElementRef;
  @ViewChild('nav') nav;
  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;
  @Input('selectedLiztu') selectedLiztu;
  @Input('buildingParentListener') buildingParentListener: Subject<boolean> =
    new Subject();
  allCriteriaArray = [];
  active = String(BuildingConstants.OBJECT_DETAILS);
  searchText = '';
  showModal = false;
  changeCriteria = [];
  updatedCriteria = [];
  criterias = [];
  parentCriteriaObject = [];
  locallist = [];
  buildingForm: FormGroup;
  buildFormRequest: NewFormObjectRequestList;
  isFormSave: boolean;
  message_added = '';
  message_not_added = '';
  showtoastMsg = true;
  criteriaRequestDetails: CriteriaRequestDetails = {
    criteriaList: {},
  };
  selectedCriterias = [];
  isresetForm = false;
  constructor(
    private serviceCall: ApiCallsService,
    private translate: TranslateService,
    private multilingual: MultilingualPipe,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.allCriteriaArray = [
      {
        name: 'object_details',
        id: 'one',
        display: 'block',
        selector: 'app-object-details',
        requiredCriteria: true,
        switchCriteria: false,
        number: BuildingConstants.OBJECT_DETAILS,
      },
      {
        name: 'power_supply_facility',
        id: 'two',
        display: 'block',
        selector: 'app-power-supply-facility',
        requiredCriteria: true,
        switchCriteria: false,
        number: BuildingConstants.POWER_SUPPLY_FACILITIES,
      },
      {
        name: 'power_supply',
        id: 'three',
        display: 'block',
        selector: 'app-power-supply',
        requiredCriteria: true,
        switchCriteria: false,
        number: BuildingConstants.POWER_SUPPLY,
      },
      {
        name: 'fire_protection',
        id: 'four',
        display: 'block',
        selector: 'app-fire-protection',
        requiredCriteria: true,
        switchCriteria: false,
        number: BuildingConstants.STRUCTURAL_FIRE_PROTECTION_AND_SAFETY_DEVICES,
      },
      {
        name: 'other-increase-risk',
        id: 'five',
        display: 'block',
        selector: 'app-other-increase-risk',
        requiredCriteria: true,
        switchCriteria: false,
        number: BuildingConstants.OTHER_INCREASES_IN_RISK,
      },
      {
        name: 'printing-companies',
        id: 'six',
        display: 'none',
        selector: 'app-printing-companies',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.PRINTING_COMPANIES,
      },
      {
        name: 'foil-packaging',
        id: 'seven',
        display: 'none',
        selector: 'app-foil-packaging',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.FOIL_PACKAGING,
      },
      {
        name: 'electroplating',
        id: 'eight',
        display: 'none',
        selector: 'app-electroplating',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.ELECTROPLATING,
      },
      {
        name: 'foundary',
        id: 'nine',
        display: 'none',
        selector: 'app-foundary',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.FOUNDRY,
      },
      {
        name: 'woodworking-processing',
        id: 'ten',
        display: 'none',
        selector: 'app-woodworking-processing',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.WOODWORKING_AND_PROCESSING,
      },
      {
        name: 'plastic',
        id: 'eleven',
        display: 'none',
        selector: 'app-plastic',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.PLASTIC,
      },
      {
        name: 'warehouse',
        id: 'twelve',
        display: 'none',
        selector: 'app-warehouse',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.WAREHOUSE,
      },
      {
        name: 'lithium-batteries',
        id: 'thirteen',
        display: 'none',
        selector: 'app-lithium-batteries',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.LITHIUM_BATTERIES,
      },
      {
        name: 'soldering',
        id: 'fourteen',
        display: 'none',
        selector: 'app-soldering',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.SOLDERING,
      },
      {
        name: 'mill-installation',
        id: 'fifteen',
        display: 'none',
        selector: 'app-mill-installation',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.MILL_INSTALLATIONS,
      },
      {
        name: 'pellete-plant',
        id: 'sixteen',
        display: 'none',
        selector: 'app-pellete-plant',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.PELLET_PLANT,
      },
      {
        name: 'photovoltaic-inside',
        id: 'seventeen',
        display: 'none',
        selector: 'app-photovoltaic-inside',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.PHOTOVOLTAIC,
      },
      {
        name: 'recycling',
        id: 'eighteen',
        display: 'none',
        selector: 'app-recycling',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.RECYCLING,
      },
      {
        name: 'clean-rooms',
        id: 'nineteen',
        display: 'none',
        selector: 'app-clean-rooms',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.CLEAN_ROOMS,
      },
      {
        name: 'grinding-polishing',
        id: 'twenty',
        display: 'none',
        selector: 'app-grinding-polishing',
        requiredCriteria: false,
        switchCriteria: false,
        number: BuildingConstants.GRINDING_AND_POLISHING,
      },
    ];
    this.initializeForm();
    if (this.object_data) {
      this.criteriaRequestDetails.object_module_id =
        this.object_data.object_module_id;
      if (
        this.object_data.criteria_response &&
        this.object_data.criteria_response.criteria_list
      ) {
        if (this.object_data.criteria_response.jsonb) {
          this.criteriaRequestDetails.criteriaList[1] = {
            record_id: this.object_data.criteria_response.record_id,
            saved_criteria: this.object_data.criteria_response.jsonb,
          };
        }
        this.enableSavedCriteria(
          this.object_data.criteria_response.criteria_list
        );
      }
    }
    this.buildingParentListener
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.showtoastMsg = false;
        const criteria_ToSave =
          this.buildFormRequest && Object.assign(this.buildFormRequest);
        const criteriaId = criteria_ToSave ? criteria_ToSave.criteriaId : -1;
        this.addEmptyCriteria(criteriaId);
        this.saveCriteria(criteria_ToSave, true);
        this.emitBuldingFormValue.emit({ HasErrors: false, message: '' });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.buildFormRequest && changes.resetForm) {
      this.isresetForm = !this.isresetForm;
      this.getSelectedCriteria(this.buildFormRequest.criteriaId, false);
    }
  }
  initializeForm() {
    const objectTypeName = new FormControl('building');
    this.buildingForm = new FormGroup({
      objectTypeName,
    });
    this.emitFormValue.emit(this.buildingForm);
  }
  showCriteriaModal() {
    this.showtoastMsg = false;
    if (
      !this.criteriaRequestDetails.object_module_id &&
      this.buildFormRequest &&
      this.buildFormRequest.criteriaId
    ) {
      this.saveCriteria(this.buildFormRequest);
    }
    this.criterias = [...this.allCriteriaArray];
    this.parentCriteriaObject = [...this.allCriteriaArray];
    this.parentCriteriaObject = this.parentCriteriaObject.map(obj => {
      const multi = this.multilingual.transform(obj.name);
      const text = this.translate.instant(multi);
      obj.text = text;
      return obj;
    });
    this.showModal = true;
  }
  onClick(changeEvent: NgbNavChangeEvent) {
    changeEvent.preventDefault();
    this.buildFormRequest = undefined;
    this.getSelectedCriteria(changeEvent.nextId, true);
  }
  hide() {
    this.showModal = false;
    this.showtoastMsg = true;
    this.locallist = [];
    this.changeCriteria = [];
    this.searchText = '';
    for (let i = 0; i < this.allCriteriaArray.length; i++) {
      if (this.allCriteriaArray[i].requiredCriteria == false) {
        this.locallist.push(this.allCriteriaArray[i]);
      }
    }
    for (let j = 0; j < this.locallist.length; j++) {
      const number = j + 6;
      const a = document.getElementById('toggle' + number) as HTMLInputElement;
      if (this.locallist[j].switchCriteria == false) {
        a.classList.remove('mat-checked');
      } else if (this.locallist[j].switchCriteria == true) {
        a.classList.add('mat-checked');
      }
    }
  }
  enableSavedCriteria(criteriaList) {
    this.selectedCriterias = criteriaList.map(criteria => criteria.criteria_id);
    this.allCriteriaArray.forEach(criteria => {
      if (this.selectedCriterias.indexOf(criteria.number) !== -1) {
        criteria.display = 'block';
        criteria.switchCriteria = true;
      }
    });
  }

  addEmptyCriteria(criteria_ToSave) {
    for (let i = 0; i < this.changeCriteria.length; i++) {
      if (this.changeCriteria[i].switchCriteria) {
        if (
          this.selectedCriterias.indexOf(this.changeCriteria[i].number) == -1 &&
          criteria_ToSave !== this.changeCriteria[i].number
        ) {
          const buildEmptyObj: BuildingObject = {
            criteria_id: this.changeCriteria[i].number,
            jsonb: null,
          };
          this.saveCriteria(
            this.buildCriteriaRequest(buildEmptyObj, false),
            false
          );
        }
      }
    }
    this.changeCriteria = [];
  }

  addNewCriteria() {
    this.showtoastMsg = true;
    this.showModal = false;
    this.searchText = '';
    let criteriaId_Selected = -1;
    for (let i = 0; i < this.allCriteriaArray.length; i++) {
      for (let j = 0; j < this.changeCriteria.length; j++) {
        if (this.allCriteriaArray[i].id == this.changeCriteria[j].id) {
          if (
            this.allCriteriaArray[i].switchCriteria ==
            this.changeCriteria[j].switchCriteria
          ) {
            this.changeCriteria.splice(j, 1);
          }
        }
      }
    }
    if (this.changeCriteria.length > 0) {
      const selectedCriteriaList = [];
      this.showtoastMsg = false;
      for (let i = 0; i < this.changeCriteria.length; i++) {
        const row = {
          criteria_id: this.changeCriteria[i].number,
          is_selected: this.changeCriteria[i].switchCriteria,
        };
        selectedCriteriaList.push(row);
        // Get the criteria details on last selected criteria
        if (
          this.changeCriteria[i].switchCriteria &&
          i == this.changeCriteria.length - 1
        ) {
          criteriaId_Selected = this.changeCriteria[i].number;
        }
        // if the current tab focus is on de-selected criteria then change the tab focus to object details
        if (
          this.changeCriteria[i].number == this.nav.activeId &&
          !this.changeCriteria[i].switchCriteria
        ) {
          this.nav.select(String(BuildingConstants.OBJECT_DETAILS));
        }
      }
      this.showtoastMsg = true;

      const sendRow: sendCriteria = {
        data: selectedCriteriaList,
        object_module_id:
          this.criteriaRequestDetails.object_module_id !== undefined
            ? this.criteriaRequestDetails.object_module_id
            : null,
      };
      this.serviceCall
        .addNewCriterias(sendRow)
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          this.showToastMessage(
            data.HasErrors
              ? 'objectModule.criteria_not_added'
              : 'objectModule.criteria_selection_success'
          );
          if (criteriaId_Selected > 0) {
            this.getSelectedCriteria(criteriaId_Selected, true);
          }
        });
    }
    for (let i = 0; i < this.allCriteriaArray.length; i++) {
      for (let j = 0; j < this.changeCriteria.length; j++) {
        if (this.allCriteriaArray[i].id == this.changeCriteria[j].id) {
          this.allCriteriaArray[i].display = this.changeCriteria[j].display;
          this.allCriteriaArray[i].switchCriteria =
            this.changeCriteria[j].switchCriteria;
        }
      }
    }
    this.updatedCriteria = this.allCriteriaArray;
  }
  changeState(id, event, number) {
    const row: savedCriteria = {
      id,
      display: event.checked == true ? 'block' : 'none',
      switchCriteria: event.checked,
      number,
    };
    if (this.changeCriteria.length > 0) {
      for (let i = 0; i < this.changeCriteria.length; i++) {
        if (row.id == this.changeCriteria[i].id) {
          if (this.changeCriteria[i].switchCriteria !== row.switchCriteria) {
            this.changeCriteria[i].switchCriteria = row.switchCriteria;
            this.changeCriteria[i].display = row.display;
            break;
          }
        } else if (this.changeCriteria.length == i + 1) {
          this.changeCriteria.push(row);
        }
      }
    } else {
      this.changeCriteria.push(row);
    }
    // emit a event to record criteria change
    this.buildingForm.markAsTouched();
    this.emitFormValue.emit(this.buildingForm);
  }
  filter(itemList) {
    return itemList.filter(item => item.requiredCriteria === false);
  }
  getSelectedCriteria(criteriaId: NumberSymbol, isSwitchTabEnable = false) {
    if (this.criteriaRequestDetails.object_module_id) {
      const value: GetCriteriaObjectRequest = {
        liztu_vn_kdnr: this.object_data
          ? this.object_data.liztu_vn_kdnr
          : this.newCustomerFormReq.customerName,
        object_type: 'Building/Property',
        object_type_no: 1,
        object_module_id: this.criteriaRequestDetails.object_module_id,
        criteria_id: criteriaId,
      };
      this.serviceCall
        .getObjectData(value)
        .pipe(takeUntil(this._destroy$))
        .subscribe(res => {
          if (!res.HasErrors && res.criteria_response) {
            if (res.criteria_response.record_id) {
              this.criteriaRequestDetails.criteriaList[criteriaId] = {
                record_id: res.criteria_response.record_id,
                saved_criteria: res.criteria_response.jsonb,
              };
              this.enableSavedCriteria(res.criteria_response.criteria_list);
            }
            this.criteriaRequestDetails = { ...this.criteriaRequestDetails };
            if (isSwitchTabEnable) {
              this.nav.select(String(criteriaId));
            }
          }
        });
    } else {
      if (isSwitchTabEnable) {
        this.nav.select(String(criteriaId));
      }
    }
  }
  buildEmptyCriteriaRequest(): NewFormObjectRequest {
    const userId = +this._userService.getUserId();
    const liztu = this.object_data
      ? this.newCustomerFormReq.customerName || this.object_data.liztu_vn_kdnr
      : this.newCustomerFormReq.customerName;
    const objectName =
      this.criteriaRequestDetails.criteriaList[1] &&
      this.criteriaRequestDetails.criteriaList[1].saved_criteria &&
      JSON.parse(this.criteriaRequestDetails.criteriaList[1].saved_criteria)
        .objectDetails &&
      JSON.parse(this.criteriaRequestDetails.criteriaList[1].saved_criteria)
        .objectDetails.obd_name;
    const value: NewFormObjectRequest = {
      liztu_vn_kdnr: liztu,
      address_id: this.newCustomerFormReq.addressId,
      object_type_id: 1,
      created_by: userId,
      record_id: 0,
      is_update: false,
      criteria: null,
    };

    if (objectName) {
      value.object_name = objectName;
    }
    if (this.criteriaRequestDetails.object_module_id) {
      value.object_module_id = this.criteriaRequestDetails.object_module_id;
    }
    if (this.object_data) {
      value.is_update = true;
      value.updated_by = userId;
    }
    return value;
  }
  buildCriteriaRequest(buildingObject: BuildingObject, isCommonBuild = true) {
    const value = this.buildEmptyCriteriaRequest();
    let newBuildObject;
    value.criteria = buildingObject;

    if (buildingObject && buildingObject.criteria_id == 1) {
      const objectName =
        buildingObject.jsonb &&
        JSON.parse(buildingObject.jsonb).objectDetails &&
        JSON.parse(buildingObject.jsonb).objectDetails.obd_name;
      value.object_name = objectName;
    }

    if (this.criteriaRequestDetails.object_module_id) {
      buildingObject.object_module_id =
        this.criteriaRequestDetails.object_module_id;
      value.object_module_id = this.criteriaRequestDetails.object_module_id;
    }

    if (
      this.criteriaRequestDetails.criteriaList &&
      this.criteriaRequestDetails.criteriaList[buildingObject.criteria_id]
    ) {
      value.record_id =
        this.criteriaRequestDetails.criteriaList[
          buildingObject.criteria_id
        ].record_id;
    }

    newBuildObject = {
      criteriaId: buildingObject.criteria_id,
      request: value,
    };
    if (isCommonBuild) {
      this.buildFormRequest = newBuildObject;
    }
    return newBuildObject;
  }
  updateCriteriaRequest(updateObject: NewFormObjectRequestList) {
    updateObject.request.liztu_vn_kdnr = this.object_data
      ? this.newCustomerFormReq.customerName || this.object_data.liztu_vn_kdnr
      : this.newCustomerFormReq.customerName;
    updateObject.request.address_id =
      this.newCustomerFormReq.addressId || updateObject.request.address_id;
    return updateObject;
  }
  saveCriteria(
    buildFormRequestList: NewFormObjectRequestList,
    isFormSave = false,
    isSwitchTabEnable = false
  ) {
    this.buildingForm.markAsUntouched();
    this.emitFormValue.emit(this.buildingForm);
    if (buildFormRequestList && buildFormRequestList.criteriaId) {
      let requestToSave: NewFormObjectRequestList = buildFormRequestList;
      requestToSave = this.updateCriteriaRequest(requestToSave);
      if (isFormSave) {
        requestToSave.request.is_form_save = isFormSave;
        this.isFormSave = isFormSave;
      }
      this.save(buildFormRequestList, isSwitchTabEnable);
      this.buildFormRequest = undefined;
    } else {
      const emptyFormToSave = this.buildEmptyCriteriaRequest();
      if (isFormSave) {
        emptyFormToSave.is_form_save = isFormSave;
        this.isFormSave = isFormSave;
      }
      this.saveEmptyCriteria(emptyFormToSave);
    }
  }
  saveEmptyCriteria(requestToSave: NewFormObjectRequest) {
    this.serviceCall
      .saveNewObject(requestToSave)
      .pipe(takeUntil(this._destroy$))
      .subscribe(res => {
        if (!res.HasErrors) {
          if (!this.criteriaRequestDetails.object_module_id) {
            this.criteriaRequestDetails.object_module_id = res.object_module_id;
          }
        }
      });
  }
  save(requestToSave: NewFormObjectRequestList, isSwitchTabEnable = false) {
    this.serviceCall
      .saveNewObject(requestToSave.request)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        res => {
          if (!res.HasErrors) {
            this.showToastMessage(
              this.object_data
                ? 'objectModule.criteria_updated'
                : 'objectModule.criteria_added'
            );
            if (!this.criteriaRequestDetails.object_module_id) {
              this.criteriaRequestDetails.object_module_id =
                res.object_module_id;
            }
            if (
              this.criteriaRequestDetails.criteriaList[requestToSave.criteriaId]
            ) {
              this.criteriaRequestDetails.criteriaList[
                requestToSave.criteriaId
              ].saved_criteria = requestToSave.request.criteria.jsonb;
            }
            if (
              !this.criteriaRequestDetails.criteriaList[
                requestToSave.criteriaId
              ] ||
              !this.criteriaRequestDetails.criteriaList[
                requestToSave.criteriaId
              ].record_id
            ) {
              this.getSelectedCriteria(
                requestToSave.criteriaId,
                isSwitchTabEnable
              );
            }
          } else {
            this.showToastMessage(
              this.object_data
                ? 'objectModule.criteria_update_failed'
                : 'objectModule.criteria_addition_failed'
            );
          }
        },
        err => {
          this.showToastMessage(
            this.object_data
              ? 'objectModule.criteria_update_failed'
              : 'objectModule.criteria_addition_failed'
          );
        }
      );
  }
  saveCriteriaRequest(buildingObject) {
    const criteriaRequestToSave = this.buildCriteriaRequest(
      buildingObject.request
    );

    if (buildingObject.pristine === false) {
      this.buildingForm.markAsTouched();
      this.emitFormValue.emit(this.buildingForm);
    }
    if (
      buildingObject.isSave &&
      !this.isFormSave &&
      this.newCustomerFormReq.isDiscard !== true
    ) {
      this.saveCriteria(criteriaRequestToSave);
    }
  }
  showToastMessage(message) {
    const x = document.getElementById('successToast');
    if (x && this.showtoastMsg) {
      x.textContent = this.translate.instant(message);
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }
  defaultSearch(event) {
    const searchText = event.target.value.toLowerCase();
    this.criterias = this.parentCriteriaObject.filter(obj =>
      obj.text.toLowerCase().includes(searchText)
    );
  }
}
