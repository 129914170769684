<mat-dialog-content style="margin: -24px -24px 0px -24px; height: 500px" id="new-sparte">
  <div class="new-editor-heading" style="border-bottom: 1px solid #d5d5d5;">
    <h2 matDialogTitle style="
        float: left;
        width: 50%;
        font-size: 17px;
        color: #272727;
        margin-bottom: 2px;
      " class="mat-headline center-align">
      {{ "settings.addGroupSparteText" | translate }}
    </h2>
    <button style="float: right; color: #272727; margin-top: -2px" (click)="onNoClick()">
      <mat-icon>
        <img alt="" style="vertical-align: sub;" src="../../../../assets/images/Group 3298.svg">
      </mat-icon>
    </button>
  </div>
  <div class="row-height col-md-12" style="padding-right: 6px; padding-left: 4px">
    <div class="row">
      <div class="col-md-6" style="padding-right: 6px; padding-left: 4px">
        <div class="col-md-12" style="padding-right: 6px; padding-left: 4px">
          <!-- <label class="col-md-2" style="color: #272727; font-size: 13px; font-weight: 600">{{ "settings.group_text" |
            translate }}</label>

          <input (blur)="typeGroupName($event.target.value)" [(ngModel)]="groupName" style="height: 42.6px;
    border-radius: 1px;
    border: solid 1px #272727;
    background-color: rgba(255, 255, 255, 0.79);
              padding: 4px 7px;
              font-size: 13px;
              font-weight: 600;
            " type="text" placeholder="" class="col-md-10 input" /> -->
          <label class="col-md-2" style="color: #272727; font-size: 13px;">{{ "settings.group_text" |
            translate }}</label>

          <input (blur)="typeGroupName($event.target.value)" [(ngModel)]="groupName" style="height: 42.6px;
    border-radius: 1px;
    border: solid 1px #272727;
    background-color: rgba(255, 255, 255, 0.79);
              padding: 4px 7px;
              font-size: 13px;
            " type="text" placeholder="" class="col-md-10 input" />
        </div>
      </div>
      <div class="col-md-6" style="padding-right: 6px; padding-left: 4px">
        <div class="col-md-12" style="padding-right: 6px; padding-left: 4px">
          <!-- <label class="col-md-2" style="color: #272727; font-size: 13px; font-weight: 600">
            {{ "settings.Spartetext" | translate }}</label>
          <mat-form-field class="col-md-10" appearance="fill" placeholder="Sparte name" style="
            height: 42.6px;
    border-radius: 1px;
    border: solid 1px #272727;
    background-color: rgba(255, 255, 255, 0.79);
          "> -->
          <label class="col-md-2" style="color: #272727; font-size: 13px;">
            {{ "settings.Spartetext" | translate }}</label>
          <mat-form-field class="col-md-10" appearance="fill" placeholder="Sparte name" style="
            height: 42.6px;
    border-radius: 1px;
    border: solid 1px #272727;
    background-color: rgba(255, 255, 255, 0.79);
          ">
            <mat-select multiple class="sparte-select" style="margin-top: 2px;">
              <input type="text" placeholder="Search" style="padding: 10px;" matInput [formControl]="sparteControl">
              <mat-option *ngFor="let sparte of sparte_list" class="hide-checkbox" [value]="sparte.sparte_name">
                <span> {{ sparte.sparte_name }} </span>
                <span *ngIf="sparte.is_add_button_show == true" style="float:right"><button style="background: none;"
                    (click)="addSparte(sparte.sparte_id);"><img alt="" height="14px" style="vertical-align: sub;"
                      src="../../../../assets/images/Icon ionic-md-close.svg"></button></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <p class="error" *ngIf="sparte_group_message == true"> {{ "settings.sparteGroupNameError" | translate }}</p>
    <p class="error" *ngIf="sparte_group_duplicate_message == true"> {{ "settings.sparteGroupDuplicateNameError" |
      translate }}</p>
    <p class="error" *ngIf="message == true"> {{ "settings.errorMessageText" | translate }}</p>
    <p class="error" *ngIf="groupNameError == true">{{ "settings.groupNameErrorText" | translate }}</p>
  </div>

  <div class="table-container sparte-table-add" style="clear: both; margin-top: 46px">
    <table>
      <thead>
        <!-- <tr style="
            border: solid 1px #ddd;
    background-color: #ddd;
    height: 54px;
            color: #272727;
            font-size: 12px;
            font-weight: 600;
          "> -->
        <tr style="
            border: solid 1px #ddd;
    background-color: #ddd;
    height: 54px;
            color: #272727;
            font-size: 12px;
          ">
          <th>{{ "settings.sparte_name_text" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of sparte_table_data">
          <td>
            <span>{{row.sparte_name}}</span>
            <button (click)="removeSparte(row.sparte_id)" style="float: right;background-color: none;">
              <img alt="" height="9px" src="../../../../assets/images/ionic-md-close.png" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <!-- <button [disabled]="button_disable == true" (click)="save()" style="color: #272727; background: #ff9000; padding: 0px 22px 0px 22px;
    font-size: 15px;
    border-radius: 2px;font-weight:600" mat-button type="submit" color="warn">
    {{ "settings.addGroupButtonText" | translate }}
  </button> -->
  <button [disabled]="button_disable == true" (click)="save()" style="color: #272727; background: #ff9000; padding: 0px 22px 0px 22px;
    font-size: 15px;
    border-radius: 2px;" mat-button type="submit" color="warn">
    {{ "settings.addGroupButtonText" | translate }}
  </button>
</mat-dialog-actions>
<div class="modal modelBackground" id="deleteDocumentModel"
  [style.display]="showModal ? 'table' : 'none'">
  <div class="modal-dialog" style="margin-top:13%;width:32%">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header" style="
      padding: 0px 13px 0px 16px;
      border-bottom: 1px solid #d5d5d5;">
        <h2 matDialogTitle style="float: left;
        width: 50%;
        font-size: 17px;
    color: #272727;
    margin: 4px 4px;" class="mat-headline center-align"> {{'contact-manager.Warnung_text' | translate}}</h2>
        <button style="float: right;background: transparent;
      color: #fff;" type="button" class="close" data-dismiss="modal" (click)="hideModal()">
          <mat-icon>
            <img alt="" style="margin-top: -6px;" src="../../../../assets/images/close icon .svg">

          </mat-icon>
        </button>
      </div>
      <!-- <div class="modal-body" style="    font-weight: 600;
      font-size: 13px; overflow: auto;margin:26px;text-align: center;color: #272727;">
        {{ "settings.discard_changes_description" | translate }}
      </div> -->
      <!-- Change (font-weight: 600 removed) -->
      <div class="modal-body" style="font-size: 13px; overflow: auto;margin:26px;text-align: center;color: #272727;">
        {{ "settings.discard_changes_description" | translate }}
      </div>
      <div class="modal-footer"
        style="text-align: center;border:none;display: block;padding-top:0;padding-bottom: 19px;">
        <!-- <button type="button"
          style="color: #272727;border: solid 1px #272727;border-radius: 2px;padding: 0px 22px 0px 22px;font-weight: 600;"
          mat-button (click)="hideModal()">
          {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp; -->
          <!-- Change (font-weight: 600 removed) -->
          <button type="button"
          style="color: #272727;border: solid 1px #272727;border-radius: 2px;padding: 0px 22px 0px 22px;"
          mat-button (click)="hideModal()">
          {{'contact-manager.NEIN_text' | translate}}</button>&nbsp;&nbsp;&nbsp;
        <!-- <button (click)="discard()" type="button"
          style="color: #272727;background: #ff9000;margin-top: 4px;border-radius: 2px;border: 1px solid #ff9000;font-weight: 600;padding: 0px 22px 0px 22px;"
          mat-button> {{
          "settings.discard_changes_text" | translate }} </button> -->
          <!-- Change (font-weight: 600 removed) -->
          <button (click)="discard()" type="button"
          style="color: #272727;background: #ff9000;margin-top: 4px;border-radius: 2px;border: 1px solid #ff9000;padding: 0px 22px 0px 22px;"
          mat-button> {{
          "settings.discard_changes_text" | translate }} </button>
      </div>
    </div>
  </div>
</div>
