import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ERRORS, STATIC_TEXT } from '@shared/constants/string.constants';
import { REGEX } from '../constants/general/regex.constants';

@Directive({
  selector: '[NameValidation]',
})
export class NameValidationDirective {
  constructor(
    private el: ElementRef,
    private ngControl: NgControl
  ) {}

  @HostListener(STATIC_TEXT.BLUR)
  onBlur(): void {
    const value = this.el.nativeElement.value;
    const control = this.ngControl.control;
    if (this.validateName(value)) {
      if (control.errors) {
        delete control.errors[ERRORS.NAME_ERROR];
      }
    } else {
      control.setErrors({ [ERRORS.NAME_ERROR]: true });
    }
  }

  validateName(value: string) {
    return new RegExp(REGEX.NAME_VALIDATION).test(value);
  }
}
