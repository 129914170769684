import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ROUTES_PATH } from '@shared/constants/router.constants';
import { UserService } from '@core/user.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { filterNil } from '@shared/utils/filter-nil.pipe';
@Component({
  selector: 'app-login',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OTPComponent extends BaseComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  x;
  messageShow;
  mfa_mode;
  preferedLanguage;
  is_source_ivm: boolean;
  isLoggedUser$ = this._userService.loggedInUser$;

  constructor(
    private readonly _router: Router,
    private readonly _fb: FormBuilder,
    private serviceCall: ApiCallsService,
    private readonly _userService: UserService
  ) {
    super();
    this.loginForm = this._fb.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]],
      otp5: ['', [Validators.required]],
      otp6: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this._watchOnUserLogging();
  }
  resend(): void {
    clearInterval(this.x);
    this._getOTP();
  }
  onsubmit(value): void {
    this.loginForm.reset();
    let otp;
    otp = value.otp1;
    otp = otp.concat(value.otp2);
    otp = otp.concat(value.otp3);
    otp = otp.concat(value.otp4);
    otp = otp.concat(value.otp5);
    otp = otp.concat(value.otp6);
    const formData = new FormData();
    formData.append('otp', otp);
    this.serviceCall
      .sendOTP(formData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.HasErrors) {
          const x = document.getElementById('snackbar');
          x.className = 'show';
          setTimeout(() => {
            x.className = x.className.replace('show', '');
          }, 3000);
          if (data.message === 'OTP is Invalid') {
            this.messageShow =
              'Der eingegebenen Sicherheitscode ist nicht richtig';
          } else if (data.message === 'OTP is Expired') {
            this.messageShow = 'Der eingegebene Sicherheitscode ist abgelaufen';
          } else {
            this.messageShow = data.message;
          }
        } else {
          this._router.navigateByUrl(ROUTES_PATH.DASHBOARD);
        }
      });
  }
  changeinput(value, next, prev): void {
    if (value.key === 'Backspace') {
      prev.focus();
    } else {
      next.focus();
    }
  }
  onPaste(event, last): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const textArray = pastedText.split('');
    for (let i = 0; i <= textArray.length; i++) {
      const j = i + 1;
      this.loginForm.controls['otp' + j].setValue(textArray[i]);
      last.focus();
    }
  }

  private _getOTP(): void {
    this.serviceCall
      .getOTP(this.mfa_mode, this.preferedLanguage)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.HasErrors === false) {
          let distance = data.expiration_time;
          this.x = setInterval(() => {
            distance = distance - 1000;
            const minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            document.getElementById('demo').innerHTML = '';
            document.getElementById('demo').innerHTML =
              minutes + 'm ' + seconds + 's ';
            if (distance < 0) {
              clearInterval(this.x);
              document.getElementById('demo').innerHTML = 'EXPIRED';
            }
          }, 1000);
        }
      });
  }

  private _watchOnUserLogging(): void {
    this._userService.loggedInUser$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(value => {
        const { mfa_mode, is_source_ivm, language_preference } =
          value.user_profile.user_data;
        this.mfa_mode = mfa_mode;
        this.is_source_ivm = is_source_ivm;
        this.preferedLanguage = language_preference;
        this._getOTP();
      });
  }
}
