import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableStructure } from '../../../models/column-details.model';
import { FILTER } from '../../../constants/numeric.constants';
import {
  PaginationConfig,
} from '@modules/new-claims/models/table.model';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { LOCALS } from '@shared/constants/local.constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { METHOD_NAMES } from '../../../constants/claims/claims.constants';
import { MatSort } from '@angular/material/sort';
import {
  ACTION_BUTTON,
  TABLE,
} from '@shared/constants/image-paths/action.constant';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { STATIC_TEXT } from '../../../constants/string.constants';
import { DropdownTitleService } from '@core/services/shared/dropdown-title.service';
import { UserService } from '@core/user.service';
import { FilterSectionConfig, TableConfiguration } from '@shared/models/table.model';
@Component({
  selector: 'app-generate-table',
  templateUrl: './generate-table.component.html',
  styleUrls: ['./generate-table.component.scss'],
})
export class GenerateTableComponent {
  dataSource = new MatTableDataSource();
  @Input() tableDetails: TableStructure;
  @Input() tableData: any;
  @Input() tableConfig: TableConfiguration;
  @Input() filterSection: FilterSectionConfig;
  @Input() paginationObject: PaginationConfig = {
    totalElements: 0,
    pageSize: FILTER.DEFAULT_SELECTED,
    currentPage: 1,
  };
  @Output() emitMethodDetails = new EventEmitter<any>();
  @Output() emitFilterMethodDetails = new EventEmitter<any>();
  @Output() emitPaginationChange = new EventEmitter<PaginationConfig>();
  @Output() exportExcel = new EventEmitter<any>();
  @ViewChild(MatSort) genericTableSort: MatSort;
  tableObj: TableStructure;
  filterToggle = true;
  customFilterExpandedHeight: string = FILTER.HEIGHT;
  readonly locale$ = this._userService.locale$;
  localeConstants = LOCALS;
  methodNames = METHOD_NAMES;
  filterForm: FormGroup;
  tableIcons = TABLE;
  actionIcons = ACTION_BUTTON;
  documentsArray: any = {};
  selectedDocumentsArray = {};
  showDocumentPopup = false;
  @ViewChild('SearchRow') searchInput: ElementRef;

  constructor(
    public validationService: ValidationService,
    private formBuilder: FormBuilder,
    private _apiCallsService: ApiCallsService,
    public _dropdownTitle: DropdownTitleService,
    private readonly _userService: UserService
  ) {
    this.filterForm = this.formBuilder.group({});
  }

  ngOnChanges(): void {
    if (this.filterSection?.isFirstTimeLoad) {
      this.generateFilterForm();
      this.filterSection.isFirstTimeLoad = false;
    }
    if (this.tableData) {
      this.setTable();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.genericTableSort;
  }

  setTable(): void {
    this.tableObj = this.tableDetails;
    this.dataSource.data = this.tableData;
  }
  methodToBeCalledInParentComponent(methodName, elementData): void {
    if (!methodName) {
      return;
    }

    const methodDetails = {
      methodName,
      element: elementData,
    };
    this.emitMethodDetails.emit(methodDetails);
  }

  toggle() {
    this.filterToggle = !this.filterToggle;
  }

  filterBtnClicked(methodName, isReset?: boolean) {
    const filterData = this.filterForm.value;
    const req = {
      methodName,
      data: filterData,
      isReset,
    };
    this.emitFilterMethodDetails.emit(req);
  }
  clearFilterSection() {
    this.filterForm.reset();
    this.filterBtnClicked(METHOD_NAMES.FILTERDATA, true);
  }
  generateFilterForm() {
    if (this.filterSection) {
      this.filterForm = this.formBuilder.group({});
      for (const el of this.filterSection?.elements) {
        const value = el.value || null;
        this.filterForm.addControl(
          el.controlName,
          this.formBuilder.control(value)
        );
        if (el.isPreSelected) {
          this.filterForm.controls[el.controlName].setValue(
            el.preSelectedValue
          );
        }
      }
    }
  }
  exportExcelClicked() {
    const filterData = this.filterForm.value;
    const req = {
      methodName: '',
      data: filterData,
      isReset: '',
    };
    this.exportExcel.emit(req);
  }

  numberOfRowsChanged(value) {
    this.paginationObject.pageSize = value.value;
    this.paginationObject.currentPage = 1;
    this.paginationObject.filterSectionForm = this.tableConfig.isFilterSection
      ? this.filterForm.value
      : null;
    this.emitPaginationChange.emit(this.paginationObject);
    this.paginationObject.filterSectionForm = null;
  }

  tablePageChanged(pageNumber: number) {
    this.paginationObject.currentPage = pageNumber;
    this.paginationObject.filterSectionForm = this.tableConfig.isFilterSection
      ? this.filterForm.value
      : null;
    this.emitPaginationChange.emit(this.paginationObject);
  }

  showDocument(id) {
    this.documentsArray = {};
    this.selectedDocumentsArray = {};
    if (id) {
      this._apiCallsService.getDocumentPhotovoltik(id, '').subscribe(data => {
        if (!data.HasErrors) {
          data.documents?.forEach(document => {
            document.is_obj_linked = true;
            this.documentsArray[document.document_name] = document;
            this.selectedDocumentsArray = this.documentsArray;
          });
        }
      });
    }
    this.showDocumentPopup = true;
  }
  hidePopup(event) {
    this.showDocumentPopup = false;
  }
  resetSearchString() {
    if (!this.searchInput) {
      return;
    }

    this.searchInput.nativeElement.value = STATIC_TEXT.EMPTY_STRING;
  }
}
