<div class="ic-pagination" *ngIf="pagination">
  <ngb-pagination
    [collectionSize]="pagination.total" 
    (pageChange)="changePage($event)" 
    [maxSize]="5"
    [(page)]="pagination.page" 
    [pageSize]="pagination.size" 
    [boundaryLinks]="false">
    <ng-template ngbPaginationPrevious>
      <i class="fa fa-angle-left"></i>
    </ng-template>
    <ng-template ngbPaginationNext>
      <i class="fa fa-angle-right"></i>
    </ng-template>
  </ngb-pagination>
  <div class="ic-pagination__total">
    <span>{{ pagination.label }}: {{ pagination.total }}</span>
  </div>
</div>