import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as atlas from 'azure-maps-control';
import { AZURE } from '@shared/constants/azure-service.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ZursGeoPaymentComponent } from '../zurs-geo-payment/zurs-geo-payment.component';
import { LOCALS } from '@shared/constants/local.constants';
import { UserService } from '@core/user.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base.component';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { GetSecuredDocumentRequest } from '@shared/models/upload-document.model';
export interface analysisData {
  user_id: number;
  division_id: number;
  is_snow: any;
  is_flood: any;
  is_earthquake: any;
  snow_price: any;
  flood_price: any;
  earthquake_price: any;
  gross_price: any;
  vat_percent: any;
  net_price: any;
  lat: any;
  lon: any;
  zip_code: any;
  city: any;
  street: any;
  house_number: any;
}
@Component({
  selector: 'app-calamities-tabs',
  templateUrl: './calamities-tabs.component.html',
  styleUrls: ['./calamities-tabs.component.css'],
})
export class CalamitiesTabsComponent extends BaseComponent implements OnInit {
  showFlood = false;
  showEarth = false;
  showSnow = false;
  activeSnow: boolean;
  activeFlood: boolean;
  activeEarth: boolean;
  groupClass: boolean;
  riskHazard: number;
  zone: any;
  brook_zone: any;
  costal_zone: any;
  island_zone: any;
  b_zone = false;
  i_zone = false;
  c_zone = false;
  intenseRainRisk: number;
  lat: any;
  lon: any;
  pdfLink: any;
  showFloodScreen: boolean;
  showFloodProcessing: boolean;
  showNullFloodScreen: boolean;
  processing_id: any;
  processing_desc: any;
  currentDate: Date;
  selectLang: LOCALS;
  showEarthScreen: boolean;
  showEarthProcessing: boolean;
  showNullEarthScreen: boolean;
  showSnowScreen: boolean;
  showSnowProcessing: boolean;
  showNullSnowScreen: boolean;
  analysisData: any;
  pdfLink2: any;
  ems_class: any;
  ems_intensity: any;
  earthquake_zone: any;
  pdfName: string;
  pdfLink3: any;
  corrected_zone: any;
  snowload_zone: any;
  processing_id_flood: any;
  processing_desc_flood: any;
  processing_id_earth: any;
  processing_desc_earth: any;
  processing_id_snow: any;
  processing_desc_snow: any;
  constructor(
    public dialogRef: MatDialogRef<ZursGeoPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private serviceCall: ApiCallsService,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchOnLocale();
    this.currentDate = new Date();
    if (this.data.is_flood == true) {
      this.showFlood = true;
    } else if (this.data.is_earthquake == true && this.data.is_flood == false) {
      this.showEarth = true;
    } else if (
      this.data.is_flood == false &&
      this.data.is_earthquake == false &&
      this.data.is_snow == true
    ) {
      this.showSnow = true;
      this.activeSnow = true;
      this.groupClass = true;
    }

    if (
      this.data.is_flood == true &&
      this.data.is_earthquake == false &&
      this.data.is_snow == false
    ) {
      this.activeFlood = true;
      this.groupClass = true;
    } else if (
      this.data.is_flood == false &&
      this.data.is_earthquake == true &&
      this.data.is_snow == false
    ) {
      this.activeEarth = true;
      this.groupClass = true;
    }
    this.getZursAnalysis();
  }
  getZursAnalysis() {
    if (this.data.zip_code.indexOf(',') != -1) {
      this.data.zip_code = this.data.zip_code.split(',')[0];
    }
    const data: analysisData = {
      user_id: parseInt(this.data.user_id),
      division_id: parseInt(this.data.division_id),
      is_snow: this.data.is_snow,
      is_flood: this.data.is_flood,
      is_earthquake: this.data.is_earthquake,
      snow_price: this.data.snow_price,
      flood_price: this.data.flood_price,
      earthquake_price: this.data.earthhquake_price,
      gross_price: this.data.gross_price,
      vat_percent: this.data.vat_percent,
      net_price: this.data.net_price,
      lat: this.data.lat,
      lon: this.data.lon,
      zip_code: this.data.zip_code,
      city: this.data.city,
      street: this.data.street,
      house_number: this.data.house_number,
    };
    this.serviceCall
      .getZursAnalysis(data)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => this.formatZursData(data));
  }
  formatZursData(data) {
    this.analysisData = data;
    if (data.flood_analysis !== null) {
      this.data.lat = data.flood_analysis.lat;
      this.data.lon = data.flood_analysis.lon;
      this.data.x = data.flood_analysis.x;
      this.data.y = data.flood_analysis.y;
      this.riskHazard = data.flood_analysis.gk;
      this.intenseRainRisk = 1;
      if (data.flood_analysis.pdfLink !== null) {
        this.pdfLink = data.flood_analysis.pdfLink;
        this.downloadPdf(this.pdfLink, 'flood');
      }
      this.processing_id_flood = data.flood_analysis.processing_id;
      this.processing_desc_flood = data.flood_analysis.processing_desc;
      if (
        data.flood_analysis.processing_id !== 23 &&
        data.flood_analysis.processing_id !== 46 &&
        data.flood_analysis.processing_id !== 92 &&
        data.flood_analysis.processing_id !== null
      ) {
        this.showFloodScreen = false;
        this.showFloodProcessing = true;
      } else if (data.flood_analysis.processing_id == null) {
        this.showFloodScreen = false;
        this.showNullFloodScreen = true;
      } else {
        this.showFloodScreen = true;
      }
      if (this.showFloodScreen == true && this.showFlood == true) {
        this.showMap();
      }
      if (data.flood_analysis.brook_zone !== null) {
        if (data.flood_analysis.brook_zone == 'b') {
          this.brook_zone =
            this.selectLang == LOCALS.ENGLISH
              ? 'Brook Zone - The site touches a stream zone'
              : 'Bachzone - Der Standort berührt eine Bachzone';
        } else if (data.flood_analysis.brook_zone == 'B') {
          this.brook_zone =
            this.selectLang === LOCALS.ENGLISH
              ? 'Brook Zone - The site is located in a stream zone'
              : 'Bachzone - Der Standort befindet sich in einer Bachzone';
        } else if (data.flood_analysis.brook_zone == '') {
          this.brook_zone = '';
        }
      }
      if (data.flood_analysis.costal_zone !== null) {
        if (data.flood_analysis.costal_zone == 'k') {
          this.costal_zone =
            this.selectLang === LOCALS.ENGLISH
              ? 'Costal Zone - The site is touches a coastal zone'
              : 'Küstenzone - Der Standort berührt eine Küstenzone';
        } else if (data.flood_analysis.costal_zone == 'K') {
          this.costal_zone =
            this.selectLang === LOCALS.ENGLISH
              ? 'Costal Zone - The site is located in a coastal zone'
              : 'Küstenzone - Der Standort liegt in einer Küstenzone';
        } else if (data.flood_analysis.costal_zone == '') {
          this.costal_zone = '';
        }
      }
      if (data.flood_analysis.island_zone !== null) {
        if (data.flood_analysis.island_zone == 'i') {
          this.island_zone =
            this.selectLang === LOCALS.ENGLISH
              ? 'Island Zone - The site touches an island zone'
              : 'Inselzone - Der Standort berührt eine Inselzone';
        } else if (data.flood_analysis.island_zone == 'I') {
          this.island_zone =
            this.selectLang === LOCALS.ENGLISH
              ? 'Island Zone - The site is located in an island zone'
              : 'Inselzone - Der Standort befindet sich in einer Inselzone';
        } else if (data.flood_analysis.island_zone == '') {
          this.island_zone = '';
        }
      }
      if (
        this.brook_zone !== '' &&
        this.brook_zone !== undefined &&
        this.brook_zone !== null
      ) {
        this.b_zone = true;
        this.zone = this.brook_zone;
      }
      if (
        this.island_zone !== '' &&
        this.island_zone !== undefined &&
        this.island_zone !== null
      ) {
        this.i_zone = true;
        if (this.b_zone == true) {
          this.zone = this.brook_zone + ',' + this.island_zone;
        } else {
          this.zone = this.island_zone;
        }
      }
      if (
        this.costal_zone !== '' &&
        this.costal_zone !== undefined &&
        this.costal_zone !== null
      ) {
        this.c_zone = true;
        if (this.b_zone == true && this.i_zone == true) {
          this.zone = this.zone + ',' + this.costal_zone;
        } else if (this.b_zone == true && this.i_zone == false) {
          this.zone = this.brook_zone + ',' + this.costal_zone;
        } else if (this.b_zone == false && this.i_zone == true) {
          this.zone = this.island_zone + ',' + this.costal_zone;
        } else {
          this.zone = this.costal_zone;
        }
      }
    }
    if (data.earthquake_analysis !== null) {
      this.processing_id_earth = data.earthquake_analysis.processing_id;
      this.processing_desc_earth = data.earthquake_analysis.processing_desc;
      if (
        data.earthquake_analysis.processing_id !== 23 &&
        data.earthquake_analysis.processing_id !== 46 &&
        data.earthquake_analysis.processing_id !== 92 &&
        data.earthquake_analysis.processing_id !== null
      ) {
        this.showEarthScreen = false;
        this.showEarthProcessing = true;
      } else if (data.earthquake_analysis.processing_id == null) {
        this.showEarthScreen = false;
        this.showNullEarthScreen = true;
      } else {
        this.showEarthScreen = true;
      }
      if (this.showEarthScreen == true && this.showEarth == true) {
        this.showMap();
      }
      this.ems_class = data.earthquake_analysis.ems_class;
      this.ems_intensity = data.earthquake_analysis.ems_intensity;
      this.earthquake_zone = data.earthquake_analysis.earthquake_zone;
      if (this.analysisData.earthquake_analysis.pdfLink !== null) {
        this.pdfLink2 = this.analysisData.earthquake_analysis.pdfLink;
        this.downloadPdf(this.pdfLink2, 'earth');
      }
    }
    if (data.snow_analysis !== null) {
      this.processing_id_snow = data.snow_analysis.processing_id;
      this.processing_desc_snow = data.snow_analysis.processing_desc;
      if (
        data.snow_analysis.processing_id !== 23 &&
        data.snow_analysis.processing_id !== 46 &&
        data.snow_analysis.processing_id !== 92 &&
        data.snow_analysis.processing_id !== null
      ) {
        this.showSnowScreen = false;
        this.showSnowProcessing = true;
      } else if (data.snow_analysis.processing_id == null) {
        this.showSnowScreen = false;
        this.showNullSnowScreen = true;
      } else {
        this.showSnowScreen = true;
      }
      if (this.showSnowScreen == true && this.showSnow == true) {
        this.showMap();
      }
      this.corrected_zone = data.snow_analysis.corrected_snow_load_zone;
      this.snowload_zone = data.snow_analysis.snow_load_zone;
      if (this.analysisData.snow_analysis.pdfLink !== null) {
        this.pdfLink3 = this.analysisData.snow_analysis.pdfLink;
        this.downloadPdf(this.pdfLink3, 'snow');
      }
    }
  }

  downloadPdf(pdfLink: string, riskName: string) {
    const data: GetSecuredDocumentRequest = {
      uuid: pdfLink,
    };
    this.serviceCall
      .getSecureDocument(data)
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(data => {
        const newBlob = new Blob([data], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.target = '_self';
        const fileName = this.getFilename(riskName);
        const data1 = window.URL.createObjectURL(newBlob);
        downloadLink.href = data1;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  private getFilename(riskName): string {
    let name: string;
    switch (riskName) {
      case 'flood':
        name =
          this.selectLang === LOCALS.ENGLISH
            ? 'Zürs_Flood_analysis.pdf'
            : 'Zürs_Hochwassergefährdungsanalyse.pdf';
        break;
      case 'earth':
        name =
          this.selectLang === LOCALS.ENGLISH
            ? 'Zürs_Earthquake_analysis.pdf'
            : 'Zürs_Seismischegefährdungsabschätzung.pdf';
        break;
      case 'snow':
        name =
          this.selectLang === LOCALS.ENGLISH
            ? 'Zürs_SnowLoad_analysis.pdf'
            : 'Zürs_Schneelastzonenermittlung.pdf';
    }
    return name;
  }

  showMap() {
    const map = new atlas.Map('showMap', {
      center: [this.data.lon, this.data.lat],
      showCopyright: false,
      showLogo: true,
      zoom: 7,
      minZoom: 2,
      view: 'Auto',
      enableAccessibility: false,
      showFeedbackLink: false,
      style: 'terra',
      mapTypeControl: true,
      scaleControl: true,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: AZURE.SUBSCRIPTION_KEY,
        getToken(resolve, reject, map) {
          // URL to your authentication service that retrieves an Azure Active Directory Token.
          const tokenServiceUrl =
            'https://adtokens.azurewebsites.net/api/HttpTrigger1?code=dv9Xz4tZQthdufbocOV9RLaaUhQoegXQJSeQQckm6DZyG/1ymppSoQ==';

          fetch(tokenServiceUrl)
            .then(r => r.text())
            .then(token => resolve(token));
        },
      },
    });

    const lat = this.data.lat;
    const lon = this.data.lon;
    map.events.add('ready', () => {
      //   /*Create a data source and add it to the map*/
      const dataSource = new atlas.source.DataSource();
      map.sources.add(dataSource);
      dataSource.add(
        new atlas.data.Feature(new atlas.data.Point([lon, lat]), {
          image: 'red',
        })
      );
      map.layers.add(new atlas.layer.SymbolLayer(dataSource, null));
      map.controls.add(new atlas.control.ZoomControl(), {
        position: atlas.ControlPosition.BottomRight,
      });
      map.controls.add(
        new atlas.control.StyleControl({
          mapStyles: [
            'road',
            'grayscale_dark',
            'night',
            'road_shaded_relief',
            'satellite',
            'satellite_road_labels',
          ],
        }),
        {
          position: atlas.ControlPosition.TopRight,
        }
      );
    });
  }
  positionOnCircle(angle) {
    const radius = 20;
    // Calculate the position on a circle for an angle of animation.
    return [Math.cos(angle) * radius, Math.sin(angle) * radius];
  }
  onNoClick() {
    this.dialogRef.close();
  }
  onTabChanged(item) {
    if (item.tab.textLabel == 'Flood') {
      this.showFlood = true;
      this.showEarth = false;
      this.showSnow = false;
      if (this.showFloodScreen !== true) {
        document.getElementById('showMap').style.display = 'none';
      } else {
        document.getElementById('showMap').style.display = 'block';
        this.showMap();
      }
    }
    if (item.tab.textLabel == 'Earth') {
      this.showEarth = true;
      this.showFlood = false;
      this.showSnow = false;
      if (this.showEarthScreen !== true) {
        document.getElementById('showMap').style.display = 'none';
      } else {
        document.getElementById('showMap').style.display = 'block';
        this.showMap();
      }
    }
    if (item.tab.textLabel == 'Snow') {
      this.showSnow = true;
      this.showFlood = false;
      this.showEarth = false;
      if (this.showSnowScreen !== true) {
        document.getElementById('showMap').style.display = 'none';
      } else {
        document.getElementById('showMap').style.display = 'block';
        this.showMap();
      }
    }
  }

  private _watchOnLocale(): void {
    this._userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(locale => (this.selectLang = locale));
  }
}
