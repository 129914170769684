import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowErrorService } from '@core/services/show-error.service';
import { GermanFormatingDirective } from './directives/german-formating.directive';
import { BillionCheckDirective } from './directives/billion-check.directive';
import { GermanFormattingRequiredDirective } from './directives/german-formatting-required.directive';
import { NameValidationDirective } from './directives/name-validation.directive';
import { MaxDateTodayDirective } from './directives/max-date-today.directive';
import { NumbersOnlyDirective } from './directives/numbers.directive';

@NgModule({
  declarations: [
    GermanFormatingDirective,
    BillionCheckDirective,
    GermanFormattingRequiredDirective,
    NameValidationDirective,
    MaxDateTodayDirective,
    NumbersOnlyDirective,
  ],
  imports: [CommonModule],
  exports: [
    GermanFormatingDirective,
    BillionCheckDirective,
    GermanFormattingRequiredDirective,
    NameValidationDirective,
    MaxDateTodayDirective,
    NumbersOnlyDirective,
  ],
  providers: [ShowErrorService],
})
export class ValidationSharedModule {}
