import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { UserService } from '@core/user.service';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';
import { BaseComponent } from '@shared/components/base.component';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { LOCALS } from '@shared/constants/local.constants';

@Component({
  selector: 'app-add-customer-relation',
  templateUrl: './add-customer-relation.component.html',
  styleUrls: ['./add-customer-relation.component.scss'],
})
export class AddCustomerRelationComponent
  extends BaseComponent
  implements OnInit
{
  customerRelationForm: FormGroup;
  customers = [];
  customers2 = [];
  relationships = [];
  liztu_vn_kdnr = null;
  division_id = null;
  selectField = 'name1';
  customer_id: any;
  liztu_vn_kdnr2: any[];
  customer2_id: any;
  relationship_id: any;
  mapping_customer_relationship_id: number;
  constructor(
    public dialogRef: MatDialogRef<AddCustomerRelationComponent>,
    private _apicalls: ApiCallsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public supported_languages: any,
    public validationService: ValidationService,
    private readonly _userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this._watchOnLocale();
    this.liztu_vn_kdnr = this.data?.liztu_vn_kdnr;
    this.division_id = this.data?.division_id;
    this.customer_id = this.data?.customer_id;
    this.customer2_id = this.data?.customer2_id;
    this.relationship_id = this.data?.relationship_id;
    this.mapping_customer_relationship_id = this.data?.customer_relationship_id;
    this.apiCalls();
  }

  apiCalls(): void {
    const requests = [];
    requests.push(
      this._apicalls.getCustomerRelations().pipe(catchError(error => of(error)))
    );
    requests.push(
      this._apicalls
        .getCustomersBasedOnDivision(
          this.division_id,
          this._userService.getUserRole()
        )
        .pipe(catchError(error => of(error)))
    );
    forkJoin(requests)
      .pipe(takeUntil(this._destroy$))
      .subscribe((data: any) => {
        this.relationships =
          !data[0].hasErrors && data[0].data ? data[0].data : [];
        
        let relationships_dummy = this.relationships.filter((element) => 
          element.relationshipId == 1 || element.relationshipId == 2
        );
        this.relationships = relationships_dummy;
        this.customers = !data[1].hasErrors && data[1].data ? data[1].data : [];
        this.customers2 = this.customers.filter(
          element => element.liztu_vn_kdnr != this.liztu_vn_kdnr
        );
        this.initializeForm();
      });
  }

  initializeForm(): void {
    this.customerRelationForm = new FormGroup({
      entity_one: new FormControl(this.customer_id),
      relationship: new FormControl(this.relationship_id),
      entity_two: new FormControl({
        value: this.customer2_id ? this.customer2_id : null,
        disabled: this.customer2_id ? true : false,
      }),
    });
  }

  close(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    this.dialogRef.close({
      entity_1: this.customerRelationForm.controls.entity_one.value,
      entity_2: this.customerRelationForm.controls.entity_two.value,
      liztu_vn_kdnr_1: this.liztu_vn_kdnr,
      liztu_vn_kdnr_2: this.liztu_vn_kdnr2,
      mapping_customer_relationship_id: this.mapping_customer_relationship_id
        ? this.mapping_customer_relationship_id
        : null,
      relationship_id: this.customerRelationForm.controls.relationship.value,
    });
  }

  focusSearch(id): void {
    document.getElementById(id).focus();
  }

  selectCustomer2(customer): void {
    this.liztu_vn_kdnr2 = this.customers2.find(
      item => item.customer_id == customer.value
    ).liztu_vn_kdnr;
  }

  private _watchOnLocale(): void {
    this._userService.locale$
      .pipe(filterNil(), takeUntil(this._destroy$))
      .subscribe(
        locale =>
          (this.selectField = locale === LOCALS.ENGLISH ? 'nameEn1' : 'name1')
      );
  }
}
