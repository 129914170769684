import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddBankDetailsRequest } from '@components/insurer-management/models/bank-details.models';
import { BANK_API_URLS } from '@shared/constants/general/bank-details.constants';
import { CP_API_URLS } from '@shared/constants/general/contact-person.constants';
import { Api_URL } from '@shared/constants/url.constants';

@Injectable({
  providedIn: 'root',
})
export class BankDetailsApiService {
  constructor(private http: HttpClient) {}

  getBankDetailsOverview(
    sourceId: number,
    sourceType: string
  ): Observable<any> {
    const params = new HttpParams()
      .append('sourceId', sourceId)
      .append('sourceType', sourceType);

    return this.http.get(Api_URL.IC_API_URL + BANK_API_URLS.BANK_DETAILS, {
      params,
    });
  }

  getBankDetailsById(id: number, sourceType: string): Observable<any> {
    const params = new HttpParams().append('sourceType', sourceType);

    return this.http.get(
      `${Api_URL.IC_API_URL}${BANK_API_URLS.BANK_DETAILS}/${id}`,
      { params }
    );
  }

  deleteBankdetailsById(id: number): Observable<any> {
    return this.http.delete(
      `${Api_URL.IC_API_URL}${BANK_API_URLS.BANK_DETAILS}/${id}`
    );
  }

  addBankDetails(bankRequest: AddBankDetailsRequest): Observable<any> {
    return this.http.post(
      `${Api_URL.IC_API_URL}${BANK_API_URLS.BANK_DETAILS}`,
      bankRequest
    );
  }
}
