import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TaskStatuses } from './status.component';

@Component({
  selector: 'app-priority-column',
  template: `<span
    class="due-date"
    [class.due-date--expired]="
      element.status !== statuses.COMPLETED && isExpired
    ">
    {{ element.dueDate | dateFormat }}
  </span>`,
  styles: [
    `
      .due-date {
        padding: 3px 10px;
      }

      .due-date--expired {
        color: var(--error-red);
        background: var(--light-red);
      }
    `,
  ],
})
export class DueDateComponent implements OnInit {
  @Input() element: { status: TaskStatuses; dueDate: string };
  statuses = TaskStatuses;
  isExpired = false;

  ngOnInit() {
    this.isExpired = moment(this.element.dueDate).isBefore(moment(), 'dates');
  }
}
