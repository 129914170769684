import { DIRECTION_OPTIONS } from '../components/upload-document-popup/upload-document-popup.model';
import { SourceTargetOptions } from '../models/document-management.model';

export class Utils {
  static getSourceAndTargetByLabel(
    label: string
  ): Partial<Record<'source' | 'target', SourceTargetOptions>> {
    const searchedObject = DIRECTION_OPTIONS.find(
      (option) => option.label === label
    ).value;
    return searchedObject || { source: null, target: null };
  }

  static compareDates(
    firstDate: string,
    secondDate: string,
    isAsc: boolean
  ): number {
    return (
      (new Date(firstDate).getTime() < new Date(secondDate).getTime()
        ? -1
        : 1) * (isAsc ? 1 : -1)
    );
  }

  static getFileExtension(documentName: string): string {
    const parts = documentName.split('.');
    return parts[parts.length - 1];
  }

  static downloadFile(blobData: Blob, fileName: string): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blobData);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
    link.remove();
  }
}
