import { HttpClient } from '@angular/common/http';
import { Injectable, enableProdMode } from '@angular/core';
import { Observable } from 'rxjs';
import { Api_URL } from '@shared/constants/url.constants';
import { environment } from '../../../../environments/environment';
import { API_ENDPOINT_URL } from '../constants/intermediary-management-constants';
import { getIntermediaryRequest } from '../models/intermediary.models';
if (environment.production) {
  enableProdMode();
}

@Injectable({
  providedIn: 'root',
})
export class IntermediaryApiCallsService {
  constructor(private http: HttpClient) {}

  getIntermediaryTableData(payload: getIntermediaryRequest): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_INTERMEDIARY_TABLE_DATA,
      payload
    );
  }

  getIntermediaryLookupData(): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_INTERMEDIARY_FILTER_LIST
    );
  }

  getIntermediaryStatus(): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_STATUS_TYPE
    );
  }

  getIntermediaryDivision(): Observable<any> {
    return this.http.get(Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_DIVISION);
  }

  getIntermediaryDivisionByRole(role, userId): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        API_ENDPOINT_URL.GET_DIVISION_BY_ROLE +
        role +
        '/' +
        userId
    );
  }

  deleteIntermediary(intermediaryId: number): Observable<any> {
    return this.http.delete(
      Api_URL.IC_API_URL +
        API_ENDPOINT_URL.GET_INTERMEDIARY_DATA_BY_ID +
        intermediaryId
    );
  }

  addUpdateIntermediary(payload: number): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.UPDATE_ADD_INTERMEDIARY,
      payload
    );
  }

  getIntermediaryById(intermediaryId): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL +
        API_ENDPOINT_URL.GET_INTERMEDIARY_DATA_BY_ID +
        intermediaryId
    );
  }

  getMainIntermediary(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_MAIN_INTERMEDIARY,
      data
    );
  }

  getIntermediaryActionBar(): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_INTERMEDIARY_ACTION_BAR
    );
  }

  getIntermediaryType(): Observable<any> {
    return this.http.get(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_INTERMEDIARY_TYPE
    );
  }

  getICMainContact(data): Observable<any> {
    return this.http.post(
      Api_URL.IC_API_URL + API_ENDPOINT_URL.GET_IC_MAIN_CONTACT_LIST,
      data
    );
  }
}
