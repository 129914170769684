<div class="update-heading">
    <h2 matDialogTitle class="mat-headline center-align heading">
        {{'update-user.invite_new_user' | translate}}
    </h2>
    <button class="close-button" style="background: transparent;" (click)="onNoClick()">
        <img alt="" style="vertical-align: sub;" src="assets/images/close icon .svg">
    </button>
</div>
<mat-dialog-content>
    <div>
        <p class="static-heading">{{'invite-user.ZusammenfassungDerEinladungen_text' | translate}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-content style="margin-bottom: 3px;">
    <div>
        <input value="{{full_name}}" class="staticInput" disabled>
        <input value="{{customer}}-{{customerType | uppercase}}" class="staticInput" disabled style="margin-left: 2%;">
    </div>
    <div style="margin-top: 10px;">
        <img alt="" src="assets/images/info-sign.svg" class="infoImage">
        <span class="infoMessage"> {{'invite-user.Summary_text' | translate}}</span>
    </div>
</mat-dialog-content>
<mat-dialog-content>
    <div style="height: 488px;margin-top: 16px;">
        <div *ngIf="customerWiseSparte !== null">
            <div *ngFor="let customer of customerWiseSparte" style="margin-bottom: 10px;">
                <div class="companyheader">
                    {{customer.customer_name}}
                </div>
                <div *ngFor="let sparte of customer.sparte" style="border: 1px solid rgba(0, 0, 0, 0.1); border-top: none;">
                    <div class="row" style="margin: 0px 0px 0px;">
                        <div class="col-md-3 col-sm-3 col-4" style="font-size: 14px; margin-top: 7px;">
                            {{'invite-user.Spartenname_text' | translate}}
                        </div>
                        <div class="col-md-9 col-sm-9 col-8" style="padding-left: 0px; margin-top: 7px;margin-left:-8px">
                            <div class="row" style="margin-bottom:15px;"><input value="{{sparte.sparte_name}}" disabled
                                    style="font-size: 12px; padding: 4px 8px; width: 31%;"></div>
                        </div>
                    </div>
                    <div class="row" style="margin-left: 0px; margin-right: 0px;">
                        <div class="col-md-3 col-sm-3 col-4" style="font-size: 14px;">
                            {{'invite-user.Vertragsdetails_text' | translate}}
                        </div>
                        <div class="col-md-9 col-sm-9 col-8" style="padding-left: 0px;margin-left:-8px">
                            <div class="row">
                                <div class="col-md-3 col-sm-3 col-4" *ngFor="let contract of sparte.contracts"
                                    style="padding-left: 0px;">
                                    <input style="font-size: 12px; width: 100%; padding: 4px 6px; margin-bottom: 10px;"
                                        value="{{contract}}" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-md-3 col-sm-3 col-4" style="font-size: 14px; font-weight: 600;">
                    Widgets
                </div>
                <div class="col-md-9 col-sm-9 col-8" style="padding-left: 0px;">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-6" *ngFor="let widget of selectedWidgets"
                            style="padding-left: 0px;">
                            <input style="font-size: 12px; width: 100%; padding: 4px 6px; margin-bottom: 10px;"
                                value="{{widget.name}}" disabled>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>

        <div>
            <div class="row">
                <div class="col-md-3 col-sm-3 col-4" style="font-size: 14px;">
                    {{'update-user.OTP_mode' | translate}}
                </div>
                <div class="col-md-9 col-sm-9 col-8" style="padding-left: 0px;">
                    <div class="row">
                        <div class="col-md-4 col-sm-4 col-6" style="padding-left: 0px;">
                            <input style="font-size: 12px; width: 100%; padding: 4px 6px; margin-bottom: 10px;font-weight: 600;"
                                value="{{user.otpTo}}" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-content>
    <div style="display: flex;justify-content: center;height: 5vh;padding-top: 8px;">
        <button class="navButton" (click)="onStepOneClick()">1</button>
        <hr class="horizontal">
        <button class="navButton" (click)="onStepTwoClick()">2</button>
        <hr class="horizontal">
        <button class="navButton" (click)="onBackClick()">3</button>
        <hr class="horizontal">
        <button class="navButton" style="border: 1px solid #ff9000;box-shadow: 0 0 0 2px #fff inset;margin-top: -3px;height: 30px;width: 30px;">4</button>
    </div>
</mat-dialog-content>

<hr class="hr" style="margin: 0px;">
<mat-dialog-actions class="save-container mt-0">
    <button class="custom-btn cancel-btn" (click)="onBackClick()">{{'invite-user.Zuruck_text' | translate}}</button>
    <button class="custom-btn save-btn" (click)="onSaveClick()">{{'invite-user.SpeichernUndEinladen_text' | translate}}</button>
</mat-dialog-actions>
