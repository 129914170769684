import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-claim-reporting-upload-popup',
    templateUrl: 'claim-reporting-upload-popup.component.html',
    styleUrls: ['claim-reporting-upload-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ClaimReportingUploadPopupComponent implements OnInit {
    @Output() hide: EventEmitter<void> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();

    fileSizeExceed: string;
    exceedMaxLimit: string;
    exceedMaxFiles: string;
    exceedMaxBulkFiles: string;
    files: File[] = [];
    names = '';

    constructor() { }

    ngOnInit() { }

    clickHide(): void {
        this.hide.emit();
    }

    clickSave(): void {
        this.save.emit({ files: this.files, names: this.names });
    }

    clickFile(event: Event & { target: HTMLInputElement }): void {
        event.target.value = '';
    }

    selectFile(event: Event & { target: HTMLInputElement }): void {
        this.fileSizeExceed = '';
        this.exceedMaxLimit = '';
        this.exceedMaxBulkFiles = '';
        this.exceedMaxFiles = '';
        for (let i = 0; i < event.target.files.length; i++) {
          if (i <= 5) {
            if (event.target.files[i].size > 2097152) {
              this.fileSizeExceed = 'Die Dateigröße sollte weniger als 2 MB betragen!';
            }
            else {
              if (this.files.length < 6) {
                const check_duplicate_index = this.files.findIndex(obj => obj.name == event.target.files[i].name);
                if (check_duplicate_index == -1) {
                  this.files.push(event.target.files[i]);
                  this.names = this.names == '' ? '\"' + event.target.files[i].name + '\"' : this.names + ', ' + '"' + event.target.files[i].name + '"';
                }
                else {
                  this.exceedMaxLimit = 'Datei ' + event.target.files[i].name + ' bereits vorhanden';
                }
              }
              else {
                this.exceedMaxFiles = 'Sie können jeweils nur 6 Bilder auf einmal hochladen!';
              }
            }
          }
          else {
            this.files = [];
            this.names = '';
            this.exceedMaxBulkFiles = 'Sie können jeweils nur 6 Bilder auf einmal hochladen!';
          }
        }
    }

    deleteDocument(file: File): void {
        const index = this.files.findIndex(obj => obj.name == file.name);
        if (index != -1) {
            this.files.splice(index, 1);
            this.names = '';
            for (let i = 0; i < this.files.length; i++) {
            this.names = this.names + ' ' + '"' + this.files[i].name;
            this.names = this.files.length - 1 == i ? this.names + '"' : this.names + '" ,';
            }
        }
    }
}
