import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { FilePickerModule } from 'ngx-awesome-uploader';
import { RegisterClaimStepOneComponent } from './register-claim-step-one/register-claim-step-one.component';
import { RegisterClaimStepThreeComponent } from './register-claim-step-three/register-claim-step-three.component';
import { RegisterClaimStepTwoComponent } from './register-claim-step-two/register-claim-step-two.component';
import { RegisterClaimComponent } from './register-claim.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    RegisterClaimStepOneComponent,
    RegisterClaimStepTwoComponent,
    RegisterClaimStepThreeComponent,
    RegisterClaimComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    FilePickerModule,
    SharedModule,
  ],
})
export class RegisterClaimModule {}
