import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CUSTOMER_TYPES } from '@shared/constants/global-constants.constants';
import { ROLES, USER_ROLES } from '@shared/constants/roles.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import {
  CUSTOMER_MANAGEMENT_ROUTES,
  ROUTES_PATH,
} from '@shared/constants/router.constants';
import { UserService } from '@core/user.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { BaseComponent } from '@shared/components/base.component';
import { takeUntil } from 'rxjs/operators';
import { LoggedInUser } from '@shared/interfaces/logged-in-user.interface';
import { ShareDataService } from '@core/services/share-data.service';
import { IC_ICONS } from '@shared/constants/image-paths/ic-icons.constant';
export interface SendingElement {
  type: string;
  user_id: string;
  customer_id: string;
  role: USER_ROLES;
  search_category: any;
  search_string: string;
  division_id: string;
  is_source_ivm: boolean;
}
export interface Response {
  customer_id: string;
  liztu_vs_idnr: number;
  liztu_vn_kdnr: number;
  search_result_label: string;
  user_idnr: number;
  user_role: string;
  is_source_ivm: boolean;
  customer_type: string;
  division_id?: any;
  damage_date?: string;
  is_customer_source_ivm?: any;
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent extends BaseComponent implements OnInit {
  userRoles = USER_ROLES;
  showOne = true;
  showTwo = true;
  showThree = true;
  showFour = true;
  showSix = true;
  showSeven = true;
  showButtons = false;
  showResult = false;
  showMessage = false;
  searchText = '';
  selected_count = 0;
  selected_games;
  search_categories = [];
  user_id;
  type;
  customer_id;
  division_id;
  isICUser;
  isCustomerUser;
  global_user_id;
  global_customer_id;
  user_role: USER_ROLES;
  one = [];
  two = [];
  three = [];
  four = [];
  five = [];
  six = [];
  seven = [];
  encoded_user_idnr;
  encoded_type;
  is_source_ivm;
  datasourceSeven = [];
  datasourceTwo = [];
  datasourceSix = [];
  datasourceOne = [];
  datasourceFour = [];
  datasourceThree = [];
  message: string;
  isGlobalSearch;
  games = [
    {
      name: 'NameAdresse_text',
      id: 'one',
      selected: false,
    },
    {
      name: 'Versicherer_text',
      id: 'two',
      selected: false,
    },
    {
      name: 'KFZ_text',
      id: 'three',
      selected: false,
    },
    {
      name: 'Schadendatum_text',
      id: 'four',
      selected: false,
    },
    {
      name: 'Rechnungsnummer_text',
      id: 'five',
      selected: false,
    },
    {
      name: 'Kundennummer_text',
      id: 'six',
      selected: false,
    },
    {
      name: 'Vertragsnummer_text',
      id: 'seven',
      selected: false,
    },
  ];
  searchQuery: any;
  searchName: any;
  public messageimg: any;
  customer_name: any;
  customer_array: any;
  division_name: any;
  enter_key_pressed: boolean;
  _ROLES = USER_ROLES;
  IC_ICONS = IC_ICONS;
  CUSTOMER_TYPES = CUSTOMER_TYPES;
  roles: USER_ROLES[] = [
    USER_ROLES.CUSTOMER_ADMIN,
    USER_ROLES.CUSTOMER_USER,
    USER_ROLES.IC_USER,
    USER_ROLES.IC_GLOBAL_ADMIN,
    USER_ROLES.IC_BRANCH_ADMIN,
  ];
  non_mutter_customers = [
    CUSTOMER_TYPES.AUSLAND,
    CUSTOMER_TYPES.BETEILIGUNG,
    CUSTOMER_TYPES.PRIVATKUNDE,
    CUSTOMER_TYPES.TOCHTER,
  ];
  userDataValue: LoggedInUser;

  constructor(
    public dialogRef: MatDialogRef<SearchComponent>,
    public serviceCall: ApiCallsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private readonly _userService: UserService,
    private readonly _customerSharedService: CustomerSharedService,
    private readonly _shareDataService: ShareDataService
  ) {
    super();
  }
  ngOnInit(): void {
    this.userDataValue = this._userService.getLoggedInUser();
    const user_profile = this.userDataValue.user_profile;
    const customer_details = user_profile?.customer_details;
    const user_data = user_profile?.user_data;
    this.user_role = this._userService.getUserRole();
    this._customerSharedService.saveCustomerName(null);
    this.customer_id = this._customerSharedService.getSelectedCustomerId();
    this.encoded_user_idnr = this.route.snapshot.queryParamMap.get('user');
    this.encoded_type = this.route.snapshot.queryParamMap.get('type');
    this.isICUser = ROLES.IC_ROLES.includes(this.user_role);
    this.isCustomerUser = ROLES.CUSTOMER_ROLES.includes(this.user_role);
    this.division_id = customer_details.division_id;
    this.type = customer_details.type;
    this.is_source_ivm = user_data.is_source_ivm;
    if (this.isICUser) {
      if (this.customer_id && this.encoded_user_idnr && this.encoded_type) {
        if (atob(this.encoded_user_idnr) !== 'null') {
          this.user_id = atob(this.encoded_user_idnr);
        } else {
          this.user_id = user_data.user_id;
        }
      } else {
        this.global_customer_id = customer_details.customer_id;
        this.global_user_id = user_data.user_id;
        this.user_id = this.global_user_id;
      }
    } else {
      if (this.customer_id && this.encoded_user_idnr) {
        this.user_id = user_data.user_id;
      } else {
        this.global_customer_id = customer_details.customer_id;
        this.global_user_id = user_data.user_id;
        this.customer_id = this.global_customer_id;
        this.user_id = this.global_user_id;
      }
    }
    if (!this.customer_id && !this.encoded_user_idnr && this.isICUser) {
      this.isGlobalSearch = true;
    } else {
      this.isGlobalSearch = false;
    }
  }
  getSelected(
    searchString: string,
    event: { target: { checked: boolean; value: string }; type: string },
    i: number
  ) {
    this._shareDataService.type = null;
    this.searchQuery = searchString;
    if (event.type === 'change' && i === 0) {
      this.enter_key_pressed = false;
    }
    this.searchName = this.enter_key_pressed ? 'one' : event.target.value;
    const selectedListItem = document.getElementById('list' + i);
    selectedListItem.className =
      selectedListItem.className === 'game-item ng-star-inserted' ||
      selectedListItem.className === 'game-item'
        ? 'game-item-changed'
        : 'game-item';
    if (this.enter_key_pressed) {
      document.getElementById('list0').style.background = '#ff9000';
      const firstButton = document.getElementById('list0');
      firstButton.className = 'game-item-changed';
    }
    this.selected_games = this.games.filter(s => {
      return s.selected;
    });
    this.selected_count = this.selected_games.length;
    if (i === 0) {
      if (this.enter_key_pressed || !event.target.checked) {
        document.getElementById('list0').style.background = '#ff9000';
        const indexOne = this.search_categories.findIndex(obj => obj === 'one');
        if (indexOne === -1) {
          this.search_categories.push('one');
        }
      } else {
        document.getElementById('list0').style.removeProperty('background');
        document.getElementById('list0').style.background = '#f3f3f3';
        const index = this.search_categories.indexOf(event.target.value);
        if (index > -1) {
          this.search_categories.splice(index, 1);
        }
      }
    } else {
      if (event.target.checked) {
        this.search_categories.push(event.target.value);
      } else {
        const index = this.search_categories.indexOf(event.target.value);
        if (index > -1) {
          this.search_categories.splice(index, 1);
        }
      }
    }
    const searchData: SendingElement = {
      type: this.type,
      user_id: this.user_id,
      customer_id: this.customer_id,
      role: this.user_role,
      search_category: this.search_categories,
      search_string: searchString,
      division_id: this.division_id,
      is_source_ivm: this.isCustomerUser ? this.is_source_ivm : true,
    };
    this.serviceCall
      .getSearchResult(searchData)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.formatSearchData(data);
      });
  }
  formatSearchData(search) {
    this.message = null;
    this.messageimg = null;
    this.datasourceSeven = [];
    this.datasourceTwo = [];
    this.datasourceSix = [];
    this.datasourceOne = [];
    this.datasourceFour = [];
    this.datasourceThree = [];
    this.showResult = search ? true : false;
    this.one = search.one;
    if (this.one) {
      if (this.one.length > 0) {
        this.one.map(data => {
          this.datasourceOne.push(this.createModel(data));
        });
      } else {
        this.fadeInOut();
      }
    }
    this.two = search.two;
    if (this.two) {
      if (this.two.length > 0) {
        this.two.map(data => {
          this.datasourceTwo.push(this.createModel(data));
        });
      } else {
        this.fadeInOut();
      }
    }
    this.three = search.three;
    if (this.three) {
      if (this.three.length > 0) {
        this.three.map(data => {
          this.datasourceThree.push(this.createModel(data));
        });
      } else {
        this.fadeInOut();
      }
    }
    this.four = search.four;
    if (this.four) {
      if (this.four.length > 0) {
        this.four.map(data => {
          const fourData: Response = this.createModel(data);
          fourData.damage_date = data.damage_date;
          this.datasourceFour.push(fourData);
        });
      } else {
        this.fadeInOut();
      }
    }
    this.five = search.five;
    this.six = search.six;
    if (this.six) {
      if (this.six.length > 0) {
        this.six.map(data => {
          this.datasourceSix.push(this.createModel(data));
        });
      } else {
        this.fadeInOut();
      }
    }
    this.seven = search.seven;
    if (this.seven) {
      if (this.seven.length > 0) {
        this.seven.map(data => {
          this.datasourceSeven.push(this.createModel(data));
        });
      } else {
        this.fadeInOut();
      }
    }
    if (
      !this.seven &&
      !this.one &&
      !this.two &&
      !this.three &&
      !this.four &&
      !this.five &&
      !this.six
    ) {
      this.showResult = false;
      const snackbar = document.getElementById('showMessage');
      snackbar.classList.add('hide');
    }
  }
  // Clearing All Selections
  clearSelection() {
    this.searchText = '';
    this.games = this.games.filter(g => {
      g.selected = false;
      return true;
    });
  }
  // Clear term types by user
  clearFilter() {
    this.searchText = '';
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  close(data) {
    this.dialogRef.close(data);
  }
  showbutton() {
    this.showButtons = true;
  }
  navigatTo(
    customerId: string,
    userIdnr: string,
    liztuVsIdnr: string,
    source: string,
    liztuVnKdnr: string,
    name: string,
    divisionId: string
  ): void {
    this._customerSharedService.selectCustomerDivisionId(divisionId);
    this.customer_array = name.split('|');
    if (this.customer_array.length === 3) {
      this.division_name = name.split('|')[1];
    }
    const encodedUserIdnr = btoa(userIdnr);
    const encodedContractIdnr = btoa(liztuVsIdnr);
    const encodedSource = btoa(source);
    const encodedLiztuVnKdnr = btoa(liztuVnKdnr);
    const encodedType = btoa(this.type);
    const currentUrl =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CONTRACT) !== -1
        ? true
        : false;
    const params: any = this.setQueryParams(
      encodedUserIdnr,
      encodedSource,
      encodedLiztuVnKdnr,
      encodedType
    );
    params.contract = encodedContractIdnr;
    if (!currentUrl) {
      if (this.roles.includes(this.user_role)) {
        this.router.navigate([ROUTES_PATH.CONTRACT], { queryParams: params });
      }
    } else {
      const prev = this.router.routeReuseStrategy.shouldReuseRoute;
      const prevOSN = this.router.onSameUrlNavigation;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router
          .navigate([ROUTES_PATH.CONTRACT], { queryParams: params })
          .then(() => {
            this.router.routeReuseStrategy.shouldReuseRoute = prev;
            this.router.onSameUrlNavigation = prevOSN;
          });
      });
    }
    this.dialogRef.close();
  }
  navigatToCustomerResult(
    customerId: string,
    userIdnr: string,
    source: string,
    liztuVnKdnr: string,
    type: string,
    name: string,
    divisionId: string,
    isCustomerSourceIvm: string
  ) {
    this._customerSharedService.selectCustomerDivisionId(divisionId);
    this._customerSharedService.selectCustomerId(customerId);
    this.customer_array = name.split('|');
    if (this.customer_array.length === 2) {
      this.customer_name = name.split('|')[0];
    }
    if (this.customer_array.length === 3) {
      this.customer_name = name.split('|')[1];
      this.division_name = name.split('|')[0];
    }
    this._customerSharedService.saveCustomerName(null);
    const encodedUserIdnr = btoa(userIdnr);
    const encodedSource = btoa(source);
    const encodedLiztuVnKdnr = btoa(liztuVnKdnr);
    const encodedType = btoa(type);
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router
        .navigate(
          this.isICUser
            ? [
                `${ROUTES_PATH.CUSTOMER_MANAGEMENT}/${CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA}`,
              ]
            : [ROUTES_PATH.USERS],
          {
            queryParams: this.isICUser
              ? {
                  liztu: encodedLiztuVnKdnr,
                }
              : this.setQueryParams(
                  encodedUserIdnr,
                  encodedSource,
                  encodedLiztuVnKdnr,
                  encodedType,
                  btoa(isCustomerSourceIvm)
                ),
          }
        )
        .then(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = prev;
          this.router.onSameUrlNavigation = prevOSN;
        });
    });
    this.dialogRef.close();
  }
  navigatToClaims(
    customerId: string,
    userIdnr: string,
    liztuVsIdnr: string,
    date: string,
    source: string,
    liztuVnKdnr: string,
    type: string,
    name: string,
    divisionId: string
  ) {
    this._customerSharedService.selectCustomerDivisionId(divisionId);
    this.customer_array = name.split('|');
    if (this.customer_array.length === 3) {
      this.division_name = name.split('|')[1];
    }
    const encodedUserIdnr = btoa(userIdnr);
    const encodedContractIdnr = btoa(liztuVsIdnr);
    const encodedDate = btoa(date);
    let encodedSource = btoa(source);
    const encodedLiztuVnKdnr = btoa(liztuVnKdnr);
    let encodedType = btoa(type);
    const encodedDivisionId = btoa(divisionId);
    if (type === '') {
      encodedType = btoa(this._customerSharedService.getSelectedCustomerType());
    }
    if (source) {
      encodedSource = btoa(this.is_source_ivm);
    }
    const currentUrl =
      this.router.url.toLocaleLowerCase().indexOf(ROUTES_PATH.CLAIMS) !== -1
        ? true
        : false;
    const params: any = this.setQueryParams(
      encodedUserIdnr,
      encodedSource,
      encodedLiztuVnKdnr,
      encodedType
    );
    params.date = encodedDate;
    params.contract = encodedContractIdnr;
    if (!currentUrl) {
      if (
        this.user_role === this._ROLES.IC_USER ||
        this.user_role === this._ROLES.IC_GLOBAL_ADMIN ||
        this.user_role === this._ROLES.IC_BRANCH_ADMIN
      ) {
        this.router.navigate([ROUTES_PATH.CLAIMS], { queryParams: params });
      } else {
        params.via = btoa('search');
        this.router.navigate([ROUTES_PATH.CLAIMS], { queryParams: params });
      }
    } else {
      const prev = this.router.routeReuseStrategy.shouldReuseRoute;
      const prevOSN = this.router.onSameUrlNavigation;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router
          .navigate([ROUTES_PATH.CLAIMS], { queryParams: params })
          .then(() => {
            this.router.routeReuseStrategy.shouldReuseRoute = prev;
            this.router.onSameUrlNavigation = prevOSN;
          });
      });
    }
    this.dialogRef.close();
  }
  fadeInOut() {
    this.message = this.translate.instant(
      'searchComponent.keinSuchergebnis_info'
    );
    this.messageimg = '../../../assets/images/Group 3334.svg';
    const snackbar = document.getElementById('showMessage');
    snackbar.classList.remove('hide');
  }
  defaultSearch(
    searchValue: string,
    event: { target: { checked: boolean; value: string }; type: string },
    i: number
  ) {
    event.target.checked = true;
    this.enter_key_pressed = true;
    this.getSelected(searchValue, event, i);
  }
  createModel(data) {
    return {
      customer_id: data.customer_id,
      liztu_vs_idnr: data.liztu_vs_idnr,
      liztu_vn_kdnr: data.liztu_vn_kdnr,
      search_result_label: data.search_result_label,
      user_idnr: data.user_idnr,
      user_role: data.user_role,
      is_source_ivm: data.is_source_ivm,
      customer_type: data.customer_type,
      is_customer_source_ivm: data.is_customer_source_ivm,
      division_id: data.division_id,
    };
  }
  setQueryParams(
    encodedUserIdnr: string,
    encodedSource: string,
    encodedLiztuVnKdnr: string,
    encodedType: string,
    encodedIsCustomerSourceIvm?: string
  ) {
    return {
      user: encodedUserIdnr,
      source: encodedSource,
      liztu: encodedLiztuVnKdnr,
      type: encodedType,
      is_customer_source_ivm: encodedIsCustomerSourceIvm,
    };
  }
}
