import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateUserComponent } from '../update-user/update-user.component';

export interface Element {
  ID: string;
  NAME: string;
  DIVISION: string;
  TELEFON: string;
  EMAIL: string;
  CHILDCOMANY: string;
}
export interface InviteUserDialogData {
  FirstName: string;
  LastName: string;
  Telefon: string;
  email: string;
  company: string;
  division: string;
  status: boolean;
  role: string;
  customer: string;
  childcompany: string;
  otp: string;
  house: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  viaemail: string;
  vialetter: string;
  title: string;
  buttonCancelName: string;
  buttonConfirmName: string;
}
@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('elementToFocus', { static: true }) _input: ElementRef;
  searchForm: FormGroup;
  dataSource = new MatTableDataSource();
  show = false;
  data = [];
  USER_DATA: InviteUserDialogData[] = [];
  displayedColumns: string[] = ['NAME', 'DIVISION', 'CUSTOMER', 'CHILDCOMPANY', 'TELEFON', 'EMAIL', 'action'];
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      division: new FormControl(''),
      chlidcompany: new FormControl(''),
      customer: new FormControl(''),
      status: new FormControl('')
    });

    const tableData: Element = {
      ID: '1587',
      NAME: '28.09.2020',
      DIVISION: 'Angebotsanfrage',
      CHILDCOMANY: '1',
      TELEFON: 'Erinnerung',
      EMAIL: 'Insure Core'
    };
    this.data.push(tableData);

    this.dataSource.data = this.data;
  }
  toggle() {
    this.show = !this.show;
  }

  Onactionprocess() {
    const updateUserData: InviteUserDialogData = {
      FirstName: 'string',
      LastName: 'string',
      Telefon: 'string',
      email: 'string',
      company: 'string',
      division: 'string',
      status: true,
      role: 'string',
      customer: 'string',
      childcompany: 'string',
      otp: 'string',
      house: 'string',
      street: 'string',
      zip: 'string',
      city: 'string',
      country: 'string',
      viaemail: null,
      vialetter: null,
      title: 'EDIT USER INFORMATION',
      buttonCancelName: 'Cancel',
      buttonConfirmName: 'Update',
    };
    this.dialog.open(UpdateUserComponent, {
      panelClass: 'updateUser',
      width: '700px',
      data: updateUserData,
      disableClose: true
    });
  }
}
