<div class="modal modelBackground" [style.display]="'table'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content modal-content-inner">
            <div class="modal-span-content">
                <span class="product-criteria-heading">{{'template-management.product_linked_with_templates' |
                    translate}}</span>
                    <button (click)="closeButton()" class="close-product-button">
                        <img alt="" class="product-button-close" src="../../../../assets/images/closeIcon.png">
                </button>
            </div>
            <div class="template-product">
                <div class="template-product-inner">
                    <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="10"
                        [scrollWindow]="false" class="infiniteScrollDiv">
                        <div class="table-component-holder">
                            <mat-table class="table-responsive table table-striped documents" mat-table
                                [dataSource]="availableValues" cellpadding="5" cellspacing="0" style="border-spacing: 0 0em;
                      width: 100%;display: table;
                      background-color: #fff;table-layout: fixed;">
                                <ng-container matColumnDef="productName">
                                    <th class="template-table-header" mat-header-cell [attr.colspan]="2"
                                        *matHeaderCellDef>
                                        <span>
                                            {{'template-management.product_name' | translate}}
                                        </span>
                                    </th>
                                    <td style="font-size: 12px;" mat-cell [attr.colspan]="2" *matCellDef="let element">
                                        <span class="custom-ellipsis noSafariTooltip main-text"
                                            title="{{element.productName}}">
                                            {{element.productName}}
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ACTION">
                                    <th mat-header-cell [attr.colspan]="1" *matHeaderCellDef style="padding: 14px 30px !important;">
                                        <span>{{'template-management.AKTION_text' | translate}} </span>
                                    </th>
                                    <td mat-cell [attr.colspan]="1" *matCellDef="let element; let i = index">
                                        <div class="control-section" style="position: initial;">
                                                <div class="democontent" style="margin-top:-10px">
                                                    <button (click)="detachProduct(element)" type="button" class="edit tool4"
                                                        mat-icon-button>
                                                        <mat-icon [ngClass]="isWindows() ? 'unlink-windows' : 'unlink-mac'"><img alt="" class="imgStyle"
                                                                src='../../../assets/images/unlink_icon.svg'>
                                                        </mat-icon><span
                                                            class="tooltiptextattach">{{'template-management.detach_product'
                                                            | translate}}</span>
                                                    </button>
                                                </div>
                                           
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedAvailableColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedAvailableColumns"></tr>
                            </mat-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<product-close-alert-dialog *ngIf="enableDeletePopup" [productwarnmsg]="productMsg" (handlePopup)="handleDetachProductPopup($event)"></product-close-alert-dialog>
