import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { UploadObjectDetails } from '@shared/models/generic.model';
import { ACTION_BUTTON } from '@shared/constants/image-paths/action.constant';
import { FEATURES } from '@shared/constants/string.constants';
import { BaseComponent } from '@shared/components/base.component';
import { filterNil } from '@shared/utils/filter-nil.pipe';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
import { CustomerSharedService } from '@core/services/customer-shared.service';
import { CustomerManagementSharedService } from '../../customer-management-shared-service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],
})
export class BankDetailsComponent extends BaseComponent implements OnDestroy {
  @Output() isBankAddEdit = new EventEmitter<boolean>();
  @Output() bankId = new EventEmitter<string>();

  dataSource = new MatTableDataSource();
  actualBankData = [];
  displayedColumns: string[] = [
    'bank',
    'iban',
    'bic',
    'valid_from',
    'sepa_mandant',
    'action',
  ];
  readonly isEditCustomer$ =
    this._customerManagementSharedService.isEditCustomerOperation$;
  showModal: boolean;
  delete_item_id: any;
  selectedSEPADocumentsArray: any = {};
  selectedDocumentsArray = {};
  showDocumentPopup = false;
  uploadDocumentDetails: UploadObjectDetails;
  actionButton = ACTION_BUTTON;

  constructor(
    private _apiCallsService: ApiCallsService,
    private toastService: ToastService,
    private readonly _customerManagementSharedService: CustomerManagementSharedService,
    private readonly _customerSharedService: CustomerSharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getBanks();
  }

  getBanks(): void {
    this._customerSharedService.selectedCustomerId$
      .pipe(
        filterNil(),
        take(1),
        mergeMap(customerId => {
          const request = {
            sourceId: +customerId,
            sourceType: FEATURES.CUSTOMER,
          };
          return this._apiCallsService.getBanks(request);
        })
      )
      .subscribe((data: any) => {
        if (!data.HasErrors) {
          this.actualBankData = data.bankDetails;
          this.dataSource.data = this.actualBankData;
        }
      });
  }
  edit(id): void {
    this.bankId.emit(id);
    this.isBankAddEdit.emit(true);
  }
  deleteBank(id: string): void {
    this._apiCallsService
      .deleteBank(id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        this.toastService.showToastMessage(document, data.message);
        this.hideModal();
        this.getBanks();
      });
  }
  openModal(id): void {
    this.delete_item_id = id;
    this.showModal = true;
  }
  hideModal(): void {
    this.delete_item_id = null;
    this.showModal = false;
  }
  showDocument(id): void {
    this.selectedSEPADocumentsArray = {};
    this.selectedDocumentsArray = {};
    if (id) {
      this._apiCallsService
        .getDocumentPhotovoltik(id, '')
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (!data.HasErrors) {
            data.documents?.forEach(document => {
              document.is_obj_linked = true;
              this.selectedSEPADocumentsArray[document.document_name] =
                document;

              this.selectedDocumentsArray = this.selectedSEPADocumentsArray;
            });
          }
        });
    }
    this.showDocumentPopup = true;
  }
  hidePopup(): void {
    this.showDocumentPopup = false;
  }
}
