import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CONTRACTS_ROUTES,
  CUSTOMER_MANAGEMENT_ROUTES,
  ROUTES_PATH,
} from '@shared/constants/router.constants';
import { BreadcrumbsContractInfo } from './breadcrumbs.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  @Input() isCustomerUser: boolean;
  @Input() isCustomerSelected: boolean;

  @Input() divisionName: string;
  @Input() customerName: string;
  @Input() contractInfo: BreadcrumbsContractInfo;

  @Input() pageBreadcrumbText: string;
  @Input() pageBreadcrumbSubText: string;
  @Input() activeLable: string;
  @Input() tempActiveLable: string;

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute
  ) {}

  navigateToContract(): void {
    this._router.navigate(
      [`${ROUTES_PATH.ADD_NEW_CONTRACT}/${CONTRACTS_ROUTES.GENERAL_DATA}`],
      {
        queryParams: {
          liztu: this._activatedRoute.snapshot.queryParams.liztu,
          contract: btoa(this.contractInfo.contractId),
        },
      }
    );
  }

  navigateToDashboard(): void {
    this._router.navigateByUrl(ROUTES_PATH.DASHBOARD);
  }

  navigateToCustomer(): void {
    this._router.navigate(
      [
        `${ROUTES_PATH.CUSTOMER_MANAGEMENT}/${CUSTOMER_MANAGEMENT_ROUTES.GENERAL_DATA}`,
      ],
      {
        queryParams: {
          liztu: this._activatedRoute.snapshot.queryParams.liztu,
        },
      }
    );
  }
}
