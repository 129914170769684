import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GenericCriteriaConstants } from '@shared/constants/generic.constants';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ValidationService } from '@core/services/object-module/validation-service.service';

@Component({
  selector: 'app-group-of-people',
  templateUrl: './group-of-people.component.html',
  styleUrls: ['./group-of-people.component.css'],
})
export class GroupOfPeopleComponent implements OnInit {
  groupOfPeopleForm: FormGroup;
  @Output('emitFormValue') emitFormValue = new EventEmitter<any>();

  @Input('resetForm') resetForm;
  @Input('objectType') objectType;
  @Input('isSubmitClicked') isSubmitClicked;

  @Input('object_data') object_data;
  @Input('selectedLiztu') selectedliztu;
  birthdayError = false;
  today = new Date();
  encoded_liztu_vn_kdnr: any;
  liztu_vn_kdnr: string;
  ObjectTypes: any;
  object_type_id: any;
  sequence_number: any;
  occupationControl = new FormControl();
  occupation: any = [];
  valueTypestemp: any;
  professionGroup = '';
  occ_id = '';
  actualnumber = 0;
  browser: string;
  @ViewChild('occupationDD', { static: true }) occupationDropdown: MatSelect;
  private readonly _destroy$: Subject<null> = new Subject<null>();

  constructor(
    private serviceCall: ApiCallsService,
    private route: ActivatedRoute,
    private validation: ValidationService
  ) {
    this.serviceCall.getLookupData(6).subscribe(data => {
      this.occupation = data.data;
      this.valueTypestemp = this.occupation;
    });
    this.occupationControl.valueChanges.subscribe(value => {
      this._filterOccupation(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetForm) {
      this.onFormReset();
    }
    if (this.groupOfPeopleForm && changes.isSubmitClicked !== undefined) {
      this.groupOfPeopleForm.reset();
    }
    if (this.groupOfPeopleForm && changes.objectType) {
      this.groupOfPeopleForm.patchValue({
        objectType: changes.objectType.currentValue,
      });
      this.initializeForm();
    }
  }
  onFormReset() {
    this.initializeForm();
  }
  ngOnInit(): void {
    this.encoded_liztu_vn_kdnr = this.route.snapshot.queryParamMap.get('liztu');
    this.liztu_vn_kdnr = atob(this.encoded_liztu_vn_kdnr);
    this.initializeForm();
    this.loadDataFromAPI();
    this.browser = this.detectBrowser();
  }
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('Edge') != -1) {
      return 'Edge';
    } else {
      return 'Unknown';
    }
  }
  initializeForm() {
    const formData =
      this.object_data &&
      this.object_data.criteria_response &&
      this.object_data.criteria_response.jsonb
        ? JSON.parse(this.object_data.criteria_response.jsonb)
        : '';
    const objectTypeValidation = new FormControl({
      value: this.object_data ? this.object_data.object_type : this.objectType,
      disabled: false,
    });
    const objectNameValidation = new FormControl({
      value: this.object_data ? this.object_data.object_name : this.objectType,
      disabled: false,
    });
    const SequenceNumberValidation = new FormControl(
      {
        value: formData ? formData.sequence_number : '',
        disabled: false,
      },
      []
    );
    const GroupDescriptionValidation = new FormControl(
      {
        value: formData ? formData.group_description : '',
        disabled: false,
      },
      []
    );
    const numberOfPeopleValidation = new FormControl(
      {
        value: formData ? formData.number_of_people : '',
        disabled: false,
      },
      []
    );
    const occupationValidation = new FormControl(
      {
        value: formData ? formData.occupation_id : '',
        disabled: false,
      },
      []
    );
    const occupationGroupValidation = new FormControl(
      {
        value: formData ? formData.professional_group : '',
        disabled: false,
      },
      []
    );
    const objectTypeName = new FormControl('group_of_people');
    this.groupOfPeopleForm = new FormGroup({
      objectTypeName,
      objectType: objectTypeValidation,
      object_name: objectNameValidation,
      sequence_number: SequenceNumberValidation,
      group_description: GroupDescriptionValidation,
      number_of_people: numberOfPeopleValidation,
      occupation_id: occupationValidation,
      professional_group: occupationGroupValidation,
    });

    this.groupOfPeopleForm.valueChanges.subscribe(obj => {
      const formValues = this.groupOfPeopleForm.value;
      formValues.isInValid = this.groupOfPeopleForm.invalid;
      this.emitFormValue.emit(this.groupOfPeopleForm);
    });
    if (!this.groupOfPeopleForm.touched) {
      this.emitFormValue.emit(this.groupOfPeopleForm);
    }
  }
  sequenceNumberValidation(e) {
    const regexp = new RegExp('^[0-9]*$');
    let searchfind: boolean;
    searchfind = regexp.test(e.key);
    if (!searchfind) {
      e.preventDefault();
    }
    if (e?.type == 'paste') {
      let searchfind: boolean;
      const regexp = new RegExp('^([1-9]{1})[0-9]{1,4}$');
      if (e.clipboardData.getData('text/plain').length > 5) {
        e.preventDefault();
      } else {
        searchfind = regexp.test(e.clipboardData.getData('text/plain'));
        if (!searchfind) {
          e.preventDefault();
        }
      }
    }
  }
  loadDataFromAPI() {
    this.serviceCall
      .getObjectType('')
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => {
        if (data.object_types !== null) {
          this.ObjectTypes = data.object_types;
          for (let i = 0; i < this.ObjectTypes.length; i++) {
            if (this.ObjectTypes[i].object_name == this.objectType) {
              this.object_type_id = this.ObjectTypes[i].object_type_id;
              const liztu_vn_kdnr =
                this.selectedliztu != undefined
                  ? this.selectedliztu
                  : this.liztu_vn_kdnr;
              if (!this.object_data) {
                this.serviceCall
                  .getSequenceNumber(
                    liztu_vn_kdnr,
                    this.object_type_id,
                    GenericCriteriaConstants[7]
                  )
                  .pipe(takeUntil(this._destroy$))
                  .subscribe(data => {
                    if (data.HasErrors == false) {
                      this.sequence_number = data.sequence_number;
                      this.groupOfPeopleForm.controls.sequence_number.setValue(
                        this.sequence_number
                      );
                    }
                  });
              }
            }
          }
        }
      });
  }
  private _filterOccupation(value: any) {
    this.occupation = this.valueTypestemp;
    if (value !== '') {
      if (
        this.occupation.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        this.occupation = this.occupation.filter(option =>
          option.desc.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.occupation = this.valueTypestemp;
      }
    } else {
      this.occupation = this.valueTypestemp;
    }
  }
  occupationChanged(event) {
    setTimeout(() => this.occupationDropdown.focus(), 200);
    if (event.value == '' || event.value == null || event.value == undefined) {
      this.groupOfPeopleForm.controls.professional_group.setValue('');
      this.groupOfPeopleForm.controls.occupation_id.setValue('');
      this.occupationControl.setValue('');
      this.professionGroup = '';
    } else {
      const data = this.valueTypestemp.find(value => value.id == event.value);
      this.groupOfPeopleForm.controls.professional_group.setValue(data.info);
      this.occupationControl.setValue('');
      this.professionGroup = data.info;
    }
  }
  typeNumber(event, key, length) {
    this.validation.SequenceNumberValidation(event, key, length);
  }
  typeNumberBrowserSpecific(event, key, length) {
    this.validation.SequenceNumberValidationBrowserSpecific(event, key, length);
  }
  focusOccupation() {
    document.getElementById('occupationSearch').focus();
  }
  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
