import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiCallsService } from '@core/services/httpcalls/api-calls.service';
import { ToastService } from '@core/services/toast.service';
export interface sendResorce {
  contract_id: any;
  report_uri: any;
  user_id: any;
  document_type_id: any;
  customer_id: any;
  liztu_vs_idnr: any;
  liztu_vn_idnr: any;
  document_description: any;
  save_document: any;
  date_from?: any;
  date_to?: any;
}
@Component({
  selector: 'app-create-doument-policy',
  templateUrl: './create-doument-policy.component.html',
  styleUrls: ['./create-doument-policy.component.css'],
})
export class CreateDoumentPolicyComponent implements OnInit {
  @Input('avaliable_templates') avaliable_templates;
  @Input('ui_mask_id') ui_mask_id;
  @Output('closeDocModal') closeDocModal = new EventEmitter<any>();
  @Output('userDataValue') userDataValue = new EventEmitter<any>();
  @Input('customer_id') customer_id;
  @Input('user_id') user_id;
  @Input('liztu_vn_kdnr') liztu_vn_kdnr;
  @Output('save_document') save_document = new EventEmitter<any>();
  policyTemplate = 'policyTemplate';
  selectedTemplateUrl: any;
  docDescription = new FormControl();
  showPDFViewer: boolean;
  pdfLink: any;
  pdfName: any;
  blob: any;
  selected_template = null;
  constructor(
    private serviceCall: ApiCallsService,
    private _toastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}
  closeModal() {
    this.closeDocModal.emit(false);
  }
  scrollValue(key) {
    document.getElementById(key) && document.getElementById(key).focus();
  }
  selectedTemplate(event) {
    this.selectedTemplateUrl = event.value;
    this.docDescription.reset();
  }
  saveDocTemplate(save_document) {
    this.save_document.emit({
      save_document,
      selectedTemplateUrl: this.selectedTemplateUrl,
      docDescription: this.docDescription.value,
    });
    this.closeDocModal.emit(false);
    this.docDescription.reset();
  }
}
