<div>
    <span class="criteria-heading">{{'objectModule.plastic_group.plastic' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="plasticFormGroup" id="plasticForm">
    <div class="col-md-3 col-sm-6 col-6">
        <label class="long-label">
            {{'objectModule.plastic_group.pressure_monitoring_with_automatic_shut-down' | translate}}
        </label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="plastic_pm_auto_shutdown">
                <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}</mat-radio-button>
                <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label class="long-label">
            {{'objectModule.plastic_group.production_of_tubular_films' | translate}}
        </label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="plastic_tubularfilms">
                <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}</mat-radio-button>
                <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-6">
        <label class="long-label">
            {{'objectModule.plastic_group.production_of_foamed_plastics' | translate}}
        </label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="plastic_foamed_plastics">
                <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}</mat-radio-button>
                <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <!-- Section one started -->
    <div
        [ngClass]="plasticFormGroup.value.isManufacturersOfPlastics ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
            <mat-slide-toggle formControlName="isManufacturersOfPlastics">
                {{'objectModule.plastic_group.manufacturers_of_plastics' | translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="plasticFormGroup.value.isManufacturersOfPlastics" class="row toggle-section-content form-align">
            <div class="col-md-3 col-sm-6 col-6">
                <label class="long-label">
                    {{'objectModule.plastic_group.use_of_substances_prone_to_selfignition' | translate}}
                </label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="plastic_self_ignition">
                        <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="col-md-3 col-sm-6 col-6">
                <label class="long-label"
                    title="{{'objectModule.plastic_group.production_of_organic_peroxides' | translate}}">
                    {{'objectModule.plastic_group.production_of_organic_peroxides' | translate}}
                </label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="plastic_organic_peroxides">
                        <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="col-md-3 col-sm-6 col-6">
                <label class="long-label">
                    {{'objectModule.plastic_group.production_use_of_explosives' | translate}}
                </label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="plastic_use_of_explosives">
                        <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="col-md-3 col-sm-6 col-6">
                <label class="long-label"
                    title="{{'objectModule.plastic_group.production_use_of_highly_flammable_or_explosive_substances' | translate}}">
                    {{'objectModule.plastic_group.production_use_of_highly_flammable_or_explosive_substances' |
                    translate}}
                </label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="plastic_use_of_highly_flammable">
                        <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>

    <!-- Section two started -->
    <div [ngClass]="plasticFormGroup.value.isSprayingMachines ? 'toggleSectionContainer' : 'toggleSectionOffContainer'">
        <div class="toggleSectionHeader">
            <mat-slide-toggle formControlName="isSprayingMachines">{{'objectModule.plastic_group.spraying_machines' |
                translate}}</mat-slide-toggle>
        </div>
        <div *ngIf="plasticFormGroup.value.isSprayingMachines" class="row toggle-section-content form-align">
            <div class="col-md-3 col-sm-6 col-6">
                <label class="long-label"
                    title="{{'objectModule.plastic_group.safety_temperature_limiter_with_automatic_switch-off' | translate}}">
                    {{'objectModule.plastic_group.safety_temperature_limiter_with_automatic_switch-off' | translate}}
                </label>
                <div class="radio-group-align-long">
                    <mat-radio-group aria-label="" formControlName="plastic_automatic_switch_off">
                        <mat-radio-button value="true">{{'objectModule.plastic_group.yes' | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="false">{{'objectModule.plastic_group.no' | translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
</form>