<div class="form-frame">
    <form [formGroup]="formGenerator">
        <div class="head-container">
            <span class="form-field-name"><input type="text" title="{{formGenerator.controls['label'].value}}"
                    class="label-input ellipsis w-100" formControlName="label"></span>
            <span class="frame-icon">
                <span *ngIf="formField.type_id == 12">
                    <span class="upload-csv">
                        <label for="{{ 'file-upload-' + formField.id }}" class="invisible-input-file">
                            <img height="12px" src="../../../../../assets/images/new-icons/upload_csv_dropdown.svg">
                        </label>
                        <input id="{{ 'file-upload-' + formField.id }}" style="display: none;" type="file"
                            (change)="uploadCSV($event)" (click)="$event.target.value = null" accept=".csv">
                    </span>
                    <span class="upload-csv" (click)="is_disabled ? '' : downloadCSV()"
                        [style.backgroundColor]="dropdownData.length > 0 ? '#ff9000': 'transparent'">
                        <img height="12px" src="../../../../../assets/images/new-icons/download_csv_dropdown.svg">
                    </span>
                </span>

                <span style="margin-right: 7px;" *ngIf="formField.type_id != 12">
                    <button class="tooltipValidation" disabled><img height="12px"
                            src="../../../../../assets/images/form_field_Validation_icon.svg">
                        <span class="tooltipvalidationText">{{ "form_generator.validation_tooltip" | translate }}</span>
                    </button>
                </span>

                <span>
                    <img height="9px" (click)="is_disabled ? '' : cancelField(formField.id)"
                        src="../../../../../assets/images/remove_form_field_icon.svg">
                </span>
            </span>
        </div>
        <div class="frame-middle-body">
            <input formControlName="placeholder" title="{{formGenerator.controls['placeholder'].value}}"
                *ngIf="formField.type_id == 1 || formField.type_id == 3 || formField.type_id == 8"
                placeholder="{{placeholder}}" class="placeholder-class ellipsis">
            <input formControlName="placeholder" *ngIf="formField.type_id == 12"
                title="{{formGenerator.controls['placeholder'].value}}"
                placeholder="{{'objectModule.select_lable' | translate}}" class="placeholder-class ellipsis">
            <div *ngIf="formField.type_id == 9" class="placeholder-class checkbox">
                <div class="flex-center" id="form-checkbox">
                    <mat-checkbox class="custom-checkBox check1" style="z-index: 1;" disabled>
                    </mat-checkbox>
                    <input type="text" class="label-input ellipsis"
                        title="{{formGenerator.controls['placeholder'].value}}" formControlName="placeholder"
                        placeholder="Label To Check">
                </div>
            </div>
            <div *ngIf="formField.type_id == 5" class="placeholder-class">
                <div class="flex-frame">
                    <span class="flex-item">
                        <div class="radio-border"></div>
                        <input type="text" class="label-input ellipsis"
                            title="{{formGenerator.controls['radio_label_first'].value}}"
                            formControlName="radio_label_first" placeholder="{{'objectModule.Yes' | translate}}">
                    </span>
                    <span class="flex-item">
                        <div class="radio-border"></div>
                        <input type="text" class="label-input ellipsis"
                            title="{{formGenerator.controls['radio_label_second'].value}}"
                            formControlName="radio_label_second" placeholder="{{'objectModule.No' | translate}}">
                    </span>
                </div>
            </div>
        </div>

        <div class="form-below">
            <!-- first row -->
            <span class="other-form-lable">
                <span class="number-info" *ngIf="formField.type_id == 1">
                    <mat-checkbox formControlName="is_currency"> {{
                        "form_generator.currency_text" | translate }}
                    </mat-checkbox>
                </span>

                <span class="number-info" *ngIf="formField.type_id != 1 && formField.type_id != 12">
                    <mat-checkbox formControlName="is_hidden" >
                        {{ "form_generator.hide" | translate }}
                    </mat-checkbox>
                </span>

                <span class="dropdown-info" *ngIf="formField.type_id == 12">
                    <mat-radio-group formControlName="is_single">
                        <mat-radio-button class="radio-pad" [value]="true" style="margin-right:7px">{{
                            "form_generator.single_select" | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="radio-pad" [value]="false">{{ "form_generator.multi_select" | translate
                            }}
                        </mat-radio-button>
                    </mat-radio-group>
                </span>

                <span class="form-mandatory" id="toggleSection">
                    <mat-slide-toggle formControlName="is_mandatory" [labelPosition]="'after'">
                        <span
                            [className]="formGenerator.controls['is_mandatory'].value == true ? 'red-text' : 'gray-text'">{{
                            "form_generator.mandatory_text" | translate }}</span>
                    </mat-slide-toggle>
                </span>

            </span>

            
            <!-- <span class="form-mandatory" id="toggleSection">
                <mat-slide-toggle formControlName="is_mandatory" [labelPosition]="'after'">
                    <span
                        [className]="formGenerator.controls['is_mandatory'].value == true ? 'red-text' : 'gray-text'">{{
                        "form_generator.mandatory_text" | translate }}</span>
                </mat-slide-toggle>
            </span> -->

            <!-- second row -->
            <span class="other-form-lable-2" [ngClass]="{'form-float-right':(formField.type_id != 1 && formField.type_id != 12)} ">
                <span class="number-info" *ngIf="!(formField.type_id != 1 && formField.type_id != 12)">
                    <mat-checkbox formControlName="is_hidden" >
                      {{ "form_generator.hide" | translate }}
                    </mat-checkbox>
                </span>
                <span class="number-info" *ngIf="(formField.type_id != 1 && formField.type_id != 12)">
                   
                </span>

                <span class="number-info">
                    <mat-checkbox formControlName="is_readonly" > {{
                        "form_generator.read_only" | translate }}
                    </mat-checkbox>
                </span>
            </span>
          
            <span style="display: block;margin-bottom: 13px;" *ngIf="(formField.type_id != 1 && formField.type_id != 12)"></span>
            <br *ngIf="formField.type_id!=1 && formField.type_id!=6 && formField.type_id!=12"/> 
            <span class="other-form-lable-2" >
                
                <span class="default-value-checkbox">
                    <mat-checkbox formControlName="is_default_value"> {{
                        "form_generator.add_default_value" | translate }}
                    </mat-checkbox>
                </span>

                <span class="middle-checkbox-size">

                </span>

                <div class="default-value-field" *ngIf="formField.type_id==1">
                    <input formControlName="default_value" 
                    type="number"
                    style="font-size: 13px;"
                    placeholder="{{placeholder}}" class="placeholder-class ellipsis"
                    [value]="formGenerator.controls['default_value'].value" 
                    >
                </div>

                <div class="default-value-field" *ngIf="formField.type_id==3">
                    <input formControlName="default_value" 
                    type="text"
                    style="font-size: 13px;"
                    placeholder="{{placeholder}}" class="placeholder-class ellipsis"
                    [value]="formGenerator.controls['default_value'].value || ''"
                    >
                </div>

                <span class="default-value-field" *ngIf="formField.type_id==5">
                    <div class="col-md-12 col-sm-12 col-12">
                        <div class="radio-group-align-long">
                            <mat-radio-group aria-label=""  formControlName="default_value">
                                <mat-radio-button [value]="'first'">
                                    {{formGenerator.controls['radio_label_first'].value?formGenerator.controls['radio_label_first'].value:"Yes"}}
                                </mat-radio-button>
                                &nbsp;
                                <mat-radio-button [value]="'second'">
                                    {{formGenerator.controls['radio_label_second'].value?formGenerator.controls['radio_label_second'].value:"No"}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </span>

                <span class="default-value-field" *ngIf="formField.type_id==9">
                    <div class="col-md-12 col-sm-12 col-12">
                        <div class="radio-group-align-long">
                            <mat-radio-group aria-label=""  formControlName="default_value">
                                <mat-radio-button [value]="true">
                                    {{"form_generator.Checked" | translate }}
                                </mat-radio-button>
                                &nbsp;
                                <mat-radio-button [value]="false">
                                    {{"form_generator.Unchecked" | translate }}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </span>

                <div class="default-value-field" *ngIf="formField.type_id==8" style="width: 50%;">
                    <mat-form-field
                        style="height: 35px;background-color:#ffffff;"
                        class="date-picker" appearance="fill">
                        <input matInput [matDatepicker]="picker"
                            placeholder="{{placeholder}}"
                            formControlName="default_value"
                            style="  font-size: 13px;  font-weight: 600;"
                           >
                        <mat-datepicker-toggle
                            style="height:0px;width: 0px;font-weight:600" matSuffix
                            [for]="picker">
                            <mat-icon matDatepickerToggleIcon>
                                {{'claims.keyboard-arrow-down_text' | translate}}
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                </span>
        </div>
    </form>
</div>

<div class="modal modelBackground" [style.display]="showWarningPopup ? 'table' : 'none'">
    <div class="modal-dialog" style="margin-top:13%;width:32%">
        <div class="modal-content" style="border-radius: 8px;
  box-shadow: 0px 8px 21px 0 rgba(107, 107, 107, 0.23);
  border: solid 1px #bbb;
  background-color: #fff;width:408px">
            <!-- Modal Header -->
            <div class="modal-header" style="border-bottom: 1px solid transparent; justify-content: center;">
                <div class="text-center" style="margin-right: 2.5rem;cursor: pointer;">
                    <mat-icon (click)="hideModal()">
                        <img alt="" height="65px" src="../../../../assets/images/Group 3235.svg">
                    </mat-icon>
                </div>
            </div>
            <div class="modal-body" style="font-size:12px; overflow: auto;color:#272727;text-align: center;">
                <span style="font-weight: 600;font-size: 21px;">{{'objectModule.Delete_popup_heading' |
                    translate}}</span>
                <br /><br />
                <span style="font-weight: 600;font-size: 16px;margin-top:5px">{{ "form_generator.field_delete_text" |
                    translate }}</span>
            </div>
            <div class="modal-footer ml-auto mr-auto" style="border-top: 1px solid transparent;">
                <button type="button" style="font-size: 15px;color: #272727;border-radius: 2px;padding: 5px 22px 5px 22px;
      border: solid 1px #272727;font-weight: 600;background:#fff;" mat-button (click)="hideModal()">
                    {{'contact-manager.NEIN_text' | translate}}</button>
                <button class="ml-2" type="button"
                    style="font-size: 15px;color: #272727;background: #ff9000;border-radius: 2px;padding: 5px 22px 5px 22px;font-weight: 600;border: solid 1px #ff9000;"
                    (click)="deleteFormField(remove_id)" mat-button> {{'contact-manager.JAL‎ÖSCHEN_text' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>