<div>
    <span class="criteria-heading">{{'objectModule.soldering_section.soldering' | translate}}</span>
</div>
<form class="row object-module-html form-align" [formGroup]="solderingForm" id="solderingForm">
    <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="label"> {{'objectModule.soldering_section.soldering' | translate}} </label>
        <mat-select class="dropdownStyle"
          placeholder="{{'objectModule.select_lable' | translate}}" formControlName="soldering">
          <mat-option style="opacity:0.5">{{'objectModule.select_lable' | translate}}</mat-option>
          <mat-option *ngFor="let item of solderingTypeData" [value]="item.id" >
              <span>{{item.desc}}</span>
            </mat-option>
        </mat-select>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.soldering_section.safety_temperature_limiter' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="safety_temperature" >
            <mat-radio-button [value]="true">
                {{'objectModule.power_supply_object.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.power_supply_object.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.soldering_section.suction' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="suction" >
            <mat-radio-button [value]="true">
                {{'objectModule.power_supply_object.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.power_supply_object.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 col-6">
        <label for="" class="long-label">{{'objectModule.soldering_section.Automatic_Soldering_Line' | translate}}</label>
        <div class="radio-group-align-long">
            <mat-radio-group aria-label="" formControlName="Automatic_Soldering_Line" >
            <mat-radio-button [value]="true">
                {{'objectModule.power_supply_object.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button [value]="false">
                {{'objectModule.power_supply_object.no' | translate}}
            </mat-radio-button>
            </mat-radio-group>
        </div>
      </div>
</form>
